import React from "react";
import { Card, CardHeader, Grid } from "@mui/material";
import { useState, useRef, useCallback } from "react";
import { MapRef } from "react-map-gl";
import TimeSeriesComponent from "./Components/TimeSeriesComponent";

import binDataWithTrend from "../../../../shared/Data/dump_loc_img_arr.json";

import "./styles.scss";
import MapboxComponent from "./Components/MapboxComponent";
import CorrelationMatrixComponent from "./Components/CorrelationMatrixComponent";
import { BinCoordinateWithTrend } from "../../../../shared/types/BinCoordinateWithTrend";
import CaptionComponent from "../../../../shared/components/CaptionComponent";

export interface Coordinate {
	longitude: number;
	latitude: number;
}

const DistanceCorrelationComponent = () => {
	const defaultBinDatas: BinCoordinateWithTrend[] = [
		binDataWithTrend[0],
		binDataWithTrend[1],
	];

	const [selectedBin, setSelectedBin]: [
		BinCoordinateWithTrend[],
		(coordinate: BinCoordinateWithTrend[]) => void
	] = useState<BinCoordinateWithTrend[]>(defaultBinDatas);

	const handleClick = (key1: any, key2: any) => {
		const selected: any = [
			binDataWithTrend.find((el) => el.hexid === key1),
			binDataWithTrend.find((el) => el.hexid === key2),
		];
		setSelectedBin(selected);
	};

	const mapRef: any = useRef<MapRef>();

	const onSelectCity = useCallback(({ longitude, latitude }: Coordinate) => {
		mapRef.current?.flyTo({
			center: [longitude, latitude],
			duration: 2000,
		});
	}, []);

	return (
		<>
			<Card
				style={{
					padding: "1%",
				}}
				variant="outlined"
			>
				<Grid container spacing={2}>
					<Grid item xs={7}>
						<Card>
							<CardHeader title={"Distance Correlation Matrix"} />
							<CorrelationMatrixComponent
								selectedBin={selectedBin}
								handleClick={handleClick}
								onSelectCity={onSelectCity}
							/>
						</Card>
					</Grid>
					<Grid item xs={5}>
						<Grid container direction="column" spacing={2}>
							<Grid item xs={6}>
								<TimeSeriesComponent
									timestamp_1={selectedBin[0].timestamp}
									values_1={selectedBin[0].values}
									label_1={selectedBin[0].hexid}
									timestamp_2={selectedBin[1].timestamp}
									values_2={selectedBin[1].values}
									label_2={selectedBin[1].hexid}
								/>
							</Grid>
							<Grid item xs={6}>
								<MapboxComponent
									latitude_1={selectedBin[0].location[1]}
									longitude_1={selectedBin[0].location[0]}
									label_1={selectedBin[0].hexid}
									latitude_2={selectedBin[1].location[1]}
									longitude_2={selectedBin[1].location[0]}
									label_2={selectedBin[1].hexid}
									mapRef={mapRef}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Card>
			<CaptionComponent
				title="Widget 2:"
				text="Dumping location correlation matrix showing site correlation and time series trend of dumping activity"
			/>
		</>
	);
};

export default DistanceCorrelationComponent;
