import chroma from "chroma-js";
import React from "react";

import binDataWithTrend from "../../../../../shared/Data/dump_loc_img_arr.json";
import locationNames from "../../../../../shared/Data/locationNames.json";

import { array } from "../../../../../shared/utils/correlationMatrix";

interface Props {
	selectedBin: any;
	handleClick: any;
	onSelectCity: any;
}

const CorrelationMatrixComponent = ({
	selectedBin,
	handleClick,
	onSelectCity,
}: Props) => {
	const scale = chroma.scale([
		"red",
		"orange",
		"lightgreen",
		"green",
		"darkgreen",
	]);

	return (
		<div className="correlationMatrix">
			{array.map((el_1, index_1) => (
				<div key={el_1.key_1} className="row">
					{index_1 === 0 || index_1 === 1 || index_1 === 2 ? (
						" "
					) : (
						<p className="leftLabel">
							{locationNames
								.find((loc) => loc.hex_id === el_1.key_1)
								?.locationName.slice(0, 20)}
							...
						</p>
					)}
					{el_1.array2.map((el_2, index_2) => {
						return el_1.array2.length - index_2 >=
							array.length - index_1 &&
							el_1.key_1 !== el_2.key_2 &&
							binDataWithTrend.find(
								(bin) => bin.hexid === el_2.key_2
							) &&
							binDataWithTrend.find(
								(bin) => bin.hexid === el_1.key_1
							) ? (
							<div
								className="coordinate"
								onClick={() => {
									handleClick(el_1.key_1, el_2.key_2);
									onSelectCity({
										latitude:
											(selectedBin[0].location[1] +
												selectedBin[1].location[1]) /
											2,
										longitude:
											(selectedBin[0].location[0] +
												selectedBin[1].location[0]) /
											2,
									});
								}}
								key={el_2.key_2}
								style={
									selectedBin[0].hexid === el_1.key_1 &&
									selectedBin[1].hexid === el_2.key_2
										? {
												backgroundColor: "#093664",
										  }
										: {
												backgroundColor: `${scale(
													el_2.val_2
												).hex()}`,
										  }
								}
							>
								{el_2.val_2.toFixed(2)}
							</div>
						) : (
							""
						);
					})}
				</div>
			))}
			<div className="bottomLabel">
				{array.map((el, index) =>
					index === 0 || index === 1 || index === array.length - 1 ? (
						""
					) : (
						<p key={el.key_1} className="leftLabel">
							{locationNames
								.find((loc) => loc.hex_id === el.key_1)
								?.locationName.slice(0, 15)}
							...
						</p>
					)
				)}
			</div>
		</div>
	);
};

export default CorrelationMatrixComponent;
