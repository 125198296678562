import { Card } from "@mui/material";
import ReactEcharts from "echarts-for-react";

import dataArray from "../../../../../shared/Data/dump_loc_img_arr.json";
import redistribute from "../../../../../shared/Data/redistribute.json";
import { useDispatch } from "react-redux";
import { selectRedistributable } from "../../../../../../store/redistribute/redistributeSlice";

const TimeSeriesComponent = () => {
	const dispatch = useDispatch();

	const legend: string[] = [];
	const timeSeries: any[] = [];

	const markArea: any = {
		label: { show: false },
		itemStyle: {
			color: "lightblue",
			opacity: 1,
		},
		data: [],
	};

	redistribute.map((red) => {
		markArea.data.push([
			{
				xAxis: +new Date(red.actionTime),
			},
			{
				xAxis: +new Date(red.actionTime).setHours(
					+new Date(red.actionTime).getHours() + 6
				),
			},
		]);
		return null;
	});

	dataArray.map((location, index) => {
		legend.push(location.hexid);
		const trendData: [number, number][] = [];
		location.timestamp.map((time, index) => {
			trendData.push([
				+new Date(time).setHours(0, 0, 0),
				location.values[index],
			]);
			return null;
		});
		timeSeries.push({
			name: location.hexid,
			type: "line",
			smooth: 0.3,
			showSymbol: false,
			data: trendData,
			markArea: index === 0 ? markArea : undefined,
			lineStyle: {
				width: 0.5,
			},
		});
		return null;
	});

	const onAreaClick = (params: any) => {
		dispatch(selectRedistributable(params.data.xAxis));
	};

	const option: echarts.EChartsOption = {
		title: [
			{
				left: "center",
				text: "Number of vehicles dumping garbage over time",
			},
		],
		xAxis: [
			{
				type: "time",
				splitLine: {
					show: false,
				},
			},
		],
		yAxis: [
			{
				splitLine: {
					show: false,
				},
				axisLine: {
					show: true,
				},
			},
		],
		dataZoom: [
			{
				type: "inside",
				start: 15,
				end: 45,
			},
			{
				start: 15,
				end: 45,
			},
		],
		series: timeSeries,
		graphic: [
			{
				type: "text",
				rotation: 1.5706,
				z: 100,
				left: "1.5%",
				top: "middle",
				style: {
					fill: "#333",
					width: 220,
					overflow: "break",
					text: "Influx",
					font: "23px Microsoft YaHei",
				},
			},
			{
				type: "text",
				z: 100,
				left: "center",
				top: "bottom",
				style: {
					fill: "#333",
					width: 220,
					overflow: "break",
					text: "Time",
					font: "20px Microsoft YaHei",
				},
			},
		],
	};

	return (
		<Card variant="outlined">
			<ReactEcharts
				option={option}
				style={{
					height: "400px",
				}}
				onEvents={{
					click: onAreaClick,
				}}
				className="echart"
			/>
		</Card>
	);
};

export default TimeSeriesComponent;
