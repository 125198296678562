import { Card, CardHeader, CardMedia, Grid } from "@mui/material";
import React from "react";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import { handleImageError } from "../../../../../shared/utils/handleImageError";
interface Props {
	image: string;
}

const ImageComponent = ({ image }: Props) => {
	return (
		<Grid
			container
			direction="column"
			alignContent="center"
			alignItems="center"
			justifyContent="center"
		>
			<Card
				variant="outlined"
				style={{
					width: "100%",
				}}
			>
				<CardHeader title="Street View" />
				<Zoom>
					<CardMedia
						component="img"
						image={image}
						style={{
							objectFit: "contain",
							height: "200px",
							backgroundColor: "black",
						}}
						onError={(err: any) => handleImageError(err)}
						alt="varanasi_swm"
					/>
				</Zoom>
			</Card>
		</Grid>
	);
};

export default ImageComponent;
