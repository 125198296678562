import type { FillLayer } from "react-map-gl";

export const dataLayer: FillLayer = {
	id: "data",
	type: "fill",
	paint: {
		"fill-color": {
			property: "LOST_AREA_SQ_KM",
			stops: [[0.05, "#F0F0F0"]],
		},
		// "fill-color": "red",
		"fill-outline-color": "black",
		"fill-opacity": 0.6,
	},
};
