import React from "react";
import "./styles.scss";
import { Container, Grid } from "@mui/material";
import CardComponent from "./Components/CardComponent";

import { dropdownMenuItems } from "../../shared/components/NavbarComponent/constants/constants";

const Gallery = () => {
	return (
		<Container>
			<Grid container spacing={2}>
				{dropdownMenuItems.map((el) => (
					<Grid item xs={4}>
						<div className="kGalleryCard">
							<CardComponent title={el.title} />
						</div>
					</Grid>
				))}
			</Grid>
		</Container>
	);
};

export default Gallery;
