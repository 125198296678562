import { Button, Menu, MenuItem } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { dropdownMenuItems } from "../constants/constants";
import { MenuItems } from "./MenuItems";
import { AmbulanceDropdown } from "./AmbulanceDropdown";

const DataStoriesLinks = () => {
	return (
		<div>
			<PopupState variant="popover" popupId="demo-popup-menu">
				{(popupState) => (
					<>
						<Button
							{...bindTrigger(popupState)}
							style={{
								color: "white",
								fontFamily: "Fira Sans",
								fontWeight: 400,
								fontSize: "1rem",
								textTransform: "capitalize",
							}}
						>
							Data Stories
						</Button>
						<Menu {...bindMenu(popupState)}>
							{dropdownMenuItems.map((el) => (
								<MenuItems
									httpLink={el.httpLink}
									onClick={popupState.close}
									redirectTo={el.redirectTo}
									title={el.title}
								/>
							))}

							<MenuItem>
								<AmbulanceDropdown />
							</MenuItem>
						</Menu>
					</>
				)}
			</PopupState>
		</div>
	);
};

export default DataStoriesLinks;
