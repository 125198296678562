import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootStore";

import redistribute from "../../views/shared/Data/redistribute.json";

interface RedistributableState {
	value: number;
	status: string;
}

const initialState: RedistributableState = {
	value: +new Date(redistribute[0].actionTime),
	status: "idle",
};

export const redistributableSlice = createSlice({
	name: "redistributable",
	initialState,
	reducers: {
		selectRedistributable: (state, action: PayloadAction<number>) => {
			state.value = action.payload;
		},
	},
});

export const { selectRedistributable } = redistributableSlice.actions;
export const selectedRedistributable = (state: RootState) =>
	state.redistributable.value;
export default redistributableSlice.reducer;
