import React from "react";
import ReactEcharts from "echarts-for-react";
import wardsTimeSeries from "../../../../shared/Data/wards/wardtimeseries.json";
import { useSelector } from "react-redux";
import {
	selectedWard,
	selectedWardNo,
} from "../../../../../store/wards/wardsSlice";

const EchartsComponent = () => {
	let wardNo: string = useSelector(selectedWardNo);
	let wardName: any = useSelector(selectedWard);

	wardNo = wardNo + ".0";

	// eslint-disable-next-line @typescript-eslint/no-unused-expressions
	const wardData = wardsTimeSeries[wardNo as keyof typeof wardsTimeSeries];

	const option: echarts.EChartsOption = {
		// Make gradient line here
		visualMap: [
			{
				show: false,
				type: "continuous",
				seriesIndex: 0,
				min: Math.min(...wardData.lostarea),
				max: Math.max(...wardData.lostarea),
				inRange: {
					color: ["green", "orange", "red"],
				},
			},
		],
		title: [
			{
				left: "center",
				text: `Area lost over time in ${
					wardName ? wardName.properties.WARD_NAME : ""
				}`,
			},
		],
		tooltip: {
			trigger: "axis",
		},
		xAxis: [
			{
				type: "category",
				splitLine: {
					show: false,
				},
				data: wardData.time,
			},
		],
		yAxis: [
			{
				splitLine: {
					show: false,
				},
				axisLine: {
					show: true,
				},
			},
		],
		series: [
			{
				type: "line",
				showSymbol: false,
				data: wardData.lostarea,
				smooth: true,
			},
		],
		graphic: [
			{
				type: "text",
				z: 100,
				left: "center",
				top: 260,
				style: {
					fill: "#333",
					width: 220,
					overflow: "break",
					text: "Time",
					font: "15px Microsoft YaHei",
				},
			},
			{
				type: "text",
				z: 100,
				left: "center",
				top: "180px",
				style: {
					fill: "red",
					width: 220,
					overflow: "break",
					text: `${(
						wardData.lostarea.reduce((a, b) => a + b, 0) /
						wardData.lostarea.length
					)
						.toFixed(2)
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
					font: "20px Microsoft YaHei",
				},
			},
		],
	};

	return (
		<div>
			<ReactEcharts
				option={option}
				onEvents={{}}
				style={{
					backgroundColor: "white",
					height: "300px",
					width: "100%",
				}}
			/>
		</div>
	);
};

export default EchartsComponent;
