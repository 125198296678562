import {
	Card,
	CardHeader,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from "@mui/material";

import React from "react";

import { Coordinate } from "../../DistanceCorrelationComponent";
import { getCoordinates } from "./MapboxComponent/getCoordinates";
import { VehicleData } from "../../../../../shared/types/VehicleData";

interface Props {
	selectedVehicle: string | undefined;
	setSelectedVehicle: React.Dispatch<React.SetStateAction<string>>;
	onSelectCity: ({ longitude, latitude }: Coordinate) => void;
	redistributableRoutes: VehicleData[];
}

const ListSidebarComponent = ({
	selectedVehicle,
	setSelectedVehicle,
	onSelectCity,
	redistributableRoutes,
}: Props) => {
	const routes = redistributableRoutes;

	routes.sort((a, b) => {
		if (
			a["Real Distance"] - a["Optimized Distance"] <
			b["Real Distance"] - b["Optimized Distance"]
		) {
			return 1;
		}
		if (
			a["Real Distance"] - a["Optimized Distance"] >
			b["Real Distance"] - b["Optimized Distance"]
		) {
			return -1;
		}

		return 0;
	});

	return (
		<Grid item xs={2}>
			<Card variant="outlined">
				<CardHeader title="License Plate" />
				<List
					sx={{
						width: "100%",
						bgcolor: "background.paper",
						position: "relative",
						overflow: "auto",
						maxHeight: 400,
						"& ul": { padding: 0 },
					}}
					subheader={<li />}
				>
					{routes.map((route, index) => (
						<ListItem
							selected={route.Vehicle === selectedVehicle}
							key={index}
							style={{
								padding: "0",
							}}
						>
							<ListItemButton
								onClick={() => {
									setSelectedVehicle(route.Vehicle);
									onSelectCity({
										latitude:
											(Number(
												`${
													getCoordinates(
														route["Start Point"]
													)[0]
												}`
											) +
												Number(
													`${
														getCoordinates(
															route["End Point"]
														)[0]
													}`
												)) /
											2,
										longitude:
											(Number(
												`${
													getCoordinates(
														route["Start Point"]
													)[1]
												}`
											) +
												Number(
													`${
														getCoordinates(
															route["End Point"]
														)[1]
													}`
												)) /
											2,
									});
								}}
							>
								<ListItemText primary={route.Vehicle} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Card>
		</Grid>
	);
};

export default ListSidebarComponent;
