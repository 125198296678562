interface Props{
    message:string;
    title:string;
    width:string;
    height:string;
    src:string;
	TableComponent:JSX.Element;
}

export default function ColumnComponent({message, title, width, height, src, TableComponent}:Props)
{
    return (
        <div className="iframe-col">
			<div className="iframe-container__data">
				<div className="iframe-container__data__iframe">
					<p className="iframe-container__data__title">
						{message}
					</p>
					<iframe
						title={title}
						width={width}
						height={height}
						seamless
						frameBorder="0"
						scrolling="no"
						src={src}
					></iframe>
					{TableComponent}
				</div>
			</div>
		</div>

    );
}