import { createTheme } from "@mui/material";

export const theme = createTheme({
	typography: {
		fontFamily: "Roboto",
	},
	components: {
		MuiCardHeader: {
			styleOverrides: {
				root: {
					backgroundColor: "#093664",
					color: "white",
					padding: 10,
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: "#093664",
				},
			},
		},
		MuiTypography: {
			variants: [
				{
					props: { variant: "h3" },
					style: {
						margin: "20px 0 20px 0",
					},
				},
				{
					props: { variant: "h4" },
					style: {
						margin: "15px 0 15px 0",
					},
				},
			],
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					margin: "30px 0 30px 0",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: "none",
				},
			},
		},
	},
});
