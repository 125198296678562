import { Card, Grid } from "@mui/material";
import DeckGLComponent from "./Components/DeckGLComponent";
import TimeSeriesComponent from "./Components/TimeSeriesComponent";
import CaptionComponent from "../../../../shared/components/CaptionComponent";

const RedistributableComponentV2 = () => {
	return (
		<>
			<Card
				style={{
					padding: "1%",
				}}
				variant="outlined"
			>
				<Grid container spacing={2}>
					<Grid item lg={7}>
						<TimeSeriesComponent />
					</Grid>
					<Grid item lg={5}>
						<DeckGLComponent />
					</Grid>
				</Grid>
			</Card>
			<CaptionComponent
				title="Widget 3:"
				text="Waste redistribution strategy during action timestamps"
			/>
		</>
	);
};

export default RedistributableComponentV2;
