import React from "react";
import "./styles.scss";

interface Props {
	title: string;
	text: string;
}

const CaptionComponent = ({ title, text }: Props) => {
	return (
		<div className="captionText">
			<span className="captionTitle">{title}</span> {text}
		</div>
	);
};

export default CaptionComponent;
