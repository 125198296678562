import React, { useEffect } from "react";
import L from "leaflet";
import "@asymmetrik/leaflet-d3";

import data from "../../../shared/Data/predictiveAmbulance/hotspotsAfterKNN.json";
import { HotspotIcon } from "../../../shared/assets/icons/HotspotIcon";
import geojson2h3 from "geojson2h3";
import jp from "jsonpath";

import "./styles.scss";

const HotspotsAfterKNN = () => {
	useEffect(() => {
		const map = L.map("hotspotsAfterKnn").setView(
			[12.905852626662887, 77.5872041000448],
			11
		);
		L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
			maxZoom: 18,
		}).addTo(map);

		const feature = geojson2h3.h3SetToFeatureCollection(
			jp.query(data, "$.hexagons..h3_hex"),
			(h3Index) => {
				return {
					type: data.hexagons.filter(
						(fil) => fil.h3_hex === h3Index
					)[0].Local_Moran,
				};
			}
		);

		L.geoJSON(feature, {
			style: function (feature: any) {
				switch (feature.properties.type) {
					case 1:
						return { color: "red" };
					case 2:
						return { color: "grey" };
					case 3:
						return { color: "blue" };
					case 4:
						return { color: "gray" };
				}
			} as any,
		}).addTo(map);

		data.hotspot_centers.map((point) =>
			L.circleMarker([point.latitude, point.longitude], {
				color: "yellow",
				radius: 10,
			}).addTo(map)
		);

		var info = new L.Control({ position: "topright" });

		info.onAdd = function (map: any) {
			var div = L.DomUtil.create("div", "info"); // create a div with a class "info"
			div.innerHTML = `
			<div class="legend">
				<h4>Legend</h4>
				<div class="legendList">
					<div class="legendItem">
						<div class="colorBox" id="redBox"></div> <span>Hot Spot</span>
					</div>
					<div class="legendItem">
						<div class="colorBox" id="blueBox"></div> <span>Cold Spot</span>
					</div>
					<div class="legendItem">
						<div class="colorBox" id="greyBox"></div> <span>Other</span>
					</div>
					<div class="legendItem">
						<div class="colorBox" id="hotspotBox"></div> <span>Cluster Centre</span>
					</div>
				</div>
			</div>
			`;
			return div;
		};

		info.addTo(map);

		return () => {
			map.off();
			map.remove();
		};
	});

	return (
		<>
			<div
				style={{
					width: "100%",
					height: "800px",
				}}
				id="hotspotsAfterKnn"
			/>
		</>
	);
};

export default HotspotsAfterKNN;
