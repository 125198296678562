import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VehicleData } from "../../views/shared/types/VehicleData";
import axios from "axios";

interface RealAndOptimalRoutes {
	selectedVehicle: string;
	vehicles: VehicleData[];
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null | undefined;
}

const initialState: RealAndOptimalRoutes = {
	selectedVehicle: "",
	vehicles: [],
	status: "idle",
	error: null,
};

export const fetchRealAndOptimalRoutes = createAsyncThunk(
	"realAndOptimalRoutes/fetchrealAndOptimalRoutes",
	async () => {
		const response = await axios.get(
			"https://storiesdata.s3.ap-south-1.amazonaws.com/swm/Comparison.json"
		);
		return response.data;
	}
);

export const realAndOptimalRoutesSlice = createSlice({
	name: "realAndOptimalRoutes",
	initialState,
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(fetchRealAndOptimalRoutes.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(fetchRealAndOptimalRoutes.fulfilled, (state, action) => {
				console.log(action.payload);
				state.status = "succeeded";
				state.vehicles = action.payload;
				state.selectedVehicle = action.payload[0];
			})
			.addCase(fetchRealAndOptimalRoutes.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export default realAndOptimalRoutesSlice.reducer;
