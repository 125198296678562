import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import EchartsComponent from "../Applets/MapboxSliderComponent/EchartsComponent";
import MapboxSliderComponent from "../Applets/MapboxSliderComponent/MapboxSliderComponent";
import WardsComponent from "../Applets/WardsComponent/WardsComponent";
import TabPanel from "../../../shared/components/TabPanelComponent/TabPanel";

interface Props {
	wardsMapRef: any;
	onSelectWard: any;
}

const tabs = ["2018_2019", "2019_2020", "2020_2021", "2021_2022", "2022_2023"];

export default function BasicTabs({ wardsMapRef, onSelectWard }: Props) {
	const [value, setValue] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					{tabs.map((tab, index) => (
						<Tab
							label={`${tab.replace("_", "-")}`}
							id={index.toString()}
						/>
					))}
				</Tabs>
			</Box>
			{tabs.map((tab, index) => (
				<TabPanel value={value} index={index}>
					<MapboxSliderComponent
						year={tab}
						wardsMapRef={wardsMapRef}
					/>
					<Grid
						sx={{
							marginTop: "20px",
						}}
						alignItems="center"
						container
						spacing={2}
						direction="row"
					>
						<Grid item xs={6}>
							<EchartsComponent />
						</Grid>
						<Grid item xs={6}>
							<WardsComponent
								year={tab}
								onSelectWard={onSelectWard}
							/>
						</Grid>
					</Grid>
				</TabPanel>
			))}
		</Box>
	);
}
