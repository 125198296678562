import { Route, Routes } from "react-router-dom";
import VaranasiSWM from "../pages/VaranasiSWM";
import Navbar from "../shared/components/NavbarComponent/Navbar";
import DashboardsPage from "../pages/Dashboards";
import UrbanDeforestation from "../pages/BangaloreDeforestation";
import WindFire from "../pages/PuneAQM";
import PredictiveAmbulance from "../pages/PredictiveAmbulance";
import Gallery from "../pages/Gallery";

const Router = () => {
	return (
		<>
			<Navbar />
			<Routes>
				<Route path="/" element={<DashboardsPage />} />
				<Route path="dashboards" element={<DashboardsPage />} />
				<Route path="varanasi_swm" element={<VaranasiSWM />} />
				<Route
					path="urban_deforestation"
					element={<UrbanDeforestation />}
				/>
				<Route path="wind_fire" element={<WindFire />} />
				<Route path="gallery" element={<Gallery />} />
				<Route
					path="predictive_ambulance"
					element={<PredictiveAmbulance />}
				/>
			</Routes>
		</>
	);
};

export default Router;
