import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../rootStore";

interface AmbulanceAndHopitalDataState {
	ambulanceData: boolean;
	hospitalData: boolean;
}

const initialState: AmbulanceAndHopitalDataState = {
	ambulanceData: true,
	hospitalData: true,
};

export const ambulanceAndHopitalDataSlice = createSlice({
	name: "ambulanceAndHospitalData",
	initialState,
	reducers: {
		setAmbulanceData: (state, action: PayloadAction<boolean>) => {
			state.ambulanceData = action.payload;
		},
		setHospitalData: (state, action: PayloadAction<boolean>) => {
			state.hospitalData = action.payload;
		},
	},
});

export const { setAmbulanceData, setHospitalData } =
	ambulanceAndHopitalDataSlice.actions;

export const ambulanceDataValue = (state: RootState) =>
	state.ambulanceAndHospitalData.ambulanceData;

export const hospitalDataValue = (state: RootState) =>
	state.ambulanceAndHospitalData.hospitalData;

export default ambulanceAndHopitalDataSlice.reducer;
