import { Card, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import { MapRef } from "react-map-gl";
import { Coordinate } from "../VaranasiSWM/Applets/DistanceCorrelationComponent";

import BasicTabs from "./Components/BasicTabs";

const BangaloreDeforestation = () => {
	const wardsMapRef: any = useRef<MapRef>();
	const onSelectWard = useCallback(({ longitude, latitude }: Coordinate) => {
		wardsMapRef.current?.flyTo({
			center: [longitude, latitude],
			duration: 2000,
		});
	}, []);
	return (
		<div>
			<Container
				fixed
				sx={{
					maxWidth: "80%",
				}}
			>
				<Helmet title="Urban Deforestation in Bangalore | IUDX Data Stories" />

				<Typography variant="h3">
					Urban Deforestation in Bangalore
				</Typography>
				<Typography paragraph id="paragraph">
					India is one of the few countries to have a scientific
					system of periodic forest cover assessment that provides
					“valuable inputs for planning, policy formulation and
					evidence-based decision-making”. Since 19.53% in the early
					1980s, India’s forest cover has increased to 21.71% in 2021.
					Adding to this a notional 2.91% tree cover estimated in
					2021, the country’s total green cover now stands at 24.62%,
					on paper <sup>[1]</sup>. Though forest cover on an average
					in the country may be increasing, it is with no-doubt that
					as the population becomes more "Urban", the toll is most
					borne by urban forest cover. As cities constantly expand in
					size and population, there is an inadvertent effect on the
					loss of vegetation cover in the city. We observe such marked
					loss in vegetation cover in the city of Bangalore using the
					ESA's Sentinel 2 Satellite imagery <sup>[2]</sup> through
					Google Earth Engine
					<sup>[3]</sup> hosted on IUDX <sup>[4]</sup> and simple land
					use classification to identify patches of vegetation that
					have gone missing and replaced with other built up areas for
					consecutive years between 2018 to 2023. You may choose the
					year of observation from the given tabs below and
					additionally.
					<br />
					<br />
					Click on the respective "Wards" from the Choropleth maps to
					get a detailed view on a ward level.
				</Typography>
				<Card
					style={{
						padding: "1%",
					}}
					variant="outlined"
				>
					<Grid container spacing={2}>
						<BasicTabs
							wardsMapRef={wardsMapRef}
							onSelectWard={onSelectWard}
						/>
					</Grid>
				</Card>
				<br />
				<br />
				<Typography variant="h5">References</Typography>
				<ol>
					<li>
						<a href="https://indianexpress.com/article/explained/explained-climate/india-forest-tree-cover-data-deforestation-explained-8474163/">
							https://indianexpress.com/article/explained/explained-climate/india-forest-tree-cover-data-deforestation-explained-8474163/
						</a>
					</li>
					<li>
						<a href="https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Sentinel-2">
							https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Sentinel-2
						</a>
					</li>
					<li>
						<a href="https://developers.google.com/earth-engine/datasets/catalog/COPERNICUS_S2_SR_HARMONIZED">
							https://developers.google.com/earth-engine/datasets/catalog/COPERNICUS_S2_SR_HARMONIZED
						</a>
					</li>
					<li>
						<a href="https://catalogue.gsx.iudx.io/dataset/datakaveri.org-c13ea5d5cd8707cd8ca261ca46f98983df2607e0-gsx.iudx.io-bangalore_vegetation">
							https://catalogue.gsx.iudx.io/dataset/datakaveri.org-c13ea5d5cd8707cd8ca261ca46f98983df2607e0-gsx.iudx.io-bangalore_vegetation
						</a>
					</li>
				</ol>
			</Container>
		</div>
	);
};

export default BangaloreDeforestation;
