import { Card, Grid } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton } from "@mui/material";
import React from "react";
import ColumnComponent from "../../../shared/components/ColumnComponent/ColumnComponent";
interface Props {
	showIcon1: boolean;
	setShowIcon1: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AirQualityComponent({
	showIcon1,
	setShowIcon1,
}: Props) {
	return (
		<Grid item lg={6} md={6} sm={12}>
			<Card
				role="button"
				sx={{
					marginLeft: "30px",
					position: "relative",
				}}
				onMouseEnter={() => {
					setShowIcon1(true);
				}}
				onMouseLeave={() => {
					setShowIcon1(false);
				}}
			>
				<div className="iframe-container">
					<div className="iframe-row">
						<ColumnComponent
							message="Air Quality Index"
							title="Air Quality Index"
							width="100%"
							height="400px"
							src="https://analytics.iudx.org.in:13000/superset/explore/p/g7onY17enzJ/?standalone=1&height=400"
							TableComponent={<></>}
						/>
						<ColumnComponent
							message="Air Quality Categories"
							title="Air Quality Categories"
							width="100%"
							height="400px"
							src="https://analytics.iudx.org.in:13000/superset/explore/p/8mDn6RxanNW/?standalone=1&height=400"
							TableComponent={<></>}
						/>
					</div>
				</div>
				{showIcon1 && (
					<div
						style={{
							position: "absolute",
							bottom: 0,
							right: 0,
						}}
					>
						<IconButton
							onClick={() => {
								window.open(
									"https://analytics.iudx.org.in:13000/superset/dashboard/p/mYDA34wpbeM/?standalone=true",
									"_blank"
								);
							}}
						>
							<LaunchIcon />
						</IconButton>
					</div>
				)}
				<div className="card-container__title">
					<p>Air Quality</p>
				</div>
			</Card>
		</Grid>
	);
}
