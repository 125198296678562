import { Fragment, useState } from "react";
import {
	Container,
	Grid,
	Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import "./styles.scss";

import DashboardTheme from "./dashboardTheme";
import AirQualityComponent from "./Components/AirQualityComponent";
import CitizenGrievencesComponent from "./Components/CitizenGrievencesComponent";
import SolidWasteManagementComponent from "./Components/SolidWasteManagementComponent";
import TransitManagementComponent from "./Components/TransitManagementComponent";

export default function DashboardLayout()
{
    const [showIcon1, setShowIcon1] = useState(false);
	const [showIcon2, setShowIcon2] = useState(false);
	const [showIcon3, setShowIcon3] = useState(false);

	return (
		<Fragment>
			<ThemeProvider theme={DashboardTheme}>
				<Container maxWidth={false}>
					<Helmet>
						<title>Dashboards | IUDX Data Stories</title>
					</Helmet>

					<Typography variant="h4">
						<p className="dashboard-title">Amalgamated City</p>
					</Typography>
					<Grid container rowSpacing={4} columnSpacing={{ md: 4 }}>
						<AirQualityComponent showIcon1={showIcon1} setShowIcon1={setShowIcon1} />
						<TransitManagementComponent showIcon2={showIcon2} setShowIcon2={setShowIcon2} />
						<SolidWasteManagementComponent showIcon3={showIcon3} setShowIcon3={setShowIcon3} />
						<CitizenGrievencesComponent />
					</Grid>
				</Container>
			</ThemeProvider>
		</Fragment>
	);
}