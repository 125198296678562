import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Grid } from "@mui/material";
import ListSidebarComponent from "./Components/ListSidebarComponent";
import MapboxComponent from "./Components/MapboxComponent/MapboxComponent";
import { MapRef } from "react-map-gl";
import { Coordinate } from "../DistanceCorrelationComponent";
import CaptionComponent from "../../../../shared/components/CaptionComponent";
import axios from "axios";

const RoutePlanningComponent = () => {
	const [dataLoaded, setDataLoaded] = useState(false);

	const [selectedVehicle, setSelectedVehicle] = useState<any>();
	const [redistributableRoutes, setRedistributableRoutes] = useState<any>();

	useEffect(() => {
		axios
			.get(
				"https://storiesdata.s3.ap-south-1.amazonaws.com/swm/Comparison.json"
			)
			.then((res) => {
				setSelectedVehicle(res.data.Routes[0].Vehicle);
				setRedistributableRoutes(res.data.Routes);
				setDataLoaded(true);
			});
	}, []);

	const mapRef: any = useRef<MapRef>();

	const onSelectCity = useCallback(({ longitude, latitude }: Coordinate) => {
		mapRef.current?.flyTo({
			center: [longitude, latitude],
			duration: 2000,
		});
	}, []);

	return (
		<>
			<Card
				style={{
					padding: "1%",
				}}
				variant="outlined"
			>
				{dataLoaded ? (
					<Grid container spacing={2}>
						<ListSidebarComponent
							onSelectCity={onSelectCity}
							selectedVehicle={selectedVehicle}
							setSelectedVehicle={setSelectedVehicle}
							redistributableRoutes={redistributableRoutes}
						/>
						<MapboxComponent
							mapRef={mapRef}
							selectedVehicle={selectedVehicle}
							redistributableRoutes={redistributableRoutes}
						/>
					</Grid>
				) : (
					"loading"
				)}
			</Card>
			<CaptionComponent
				title="Widget 4:"
				text="Comparison between actual vehicle routes and optimized vehicle routes"
			/>
		</>
	);
};

export default RoutePlanningComponent;
