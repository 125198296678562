import { TableRow, TableCell } from "@mui/material";
interface Props{
    backgroundColor:string;
    onHoverBackgroundColor:string;
    rowContent:string;
    onClickRender:string;

}

export default function TableBodyRowComponent({backgroundColor="", onHoverBackgroundColor="", rowContent="", onClickRender}:Props)
{
    return (
        <TableRow
            sx={{
                backgroundColor:{backgroundColor},
                ":hover":
                    {
                        cursor: "pointer",
                        backgroundColor:{onHoverBackgroundColor},
                    },
            }}
            onClick={(
                e
            ) => {
                window.open(
                    `${onClickRender}`,
                    "_blank"
                );
            }}
        >
            <TableCell>
                {rowContent}
            </TableCell>
        </TableRow>

    );
}