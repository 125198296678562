import { Table,
        TableHead,
        TableRow,
        TableCell,
        TableBody,
        } from "@mui/material";

import TableBodyRowComponent from "../TableBodyRowComponent/TableBodyRowComponent";        
export default function SWMTableComponent()
{
    return (
        <div className="iframe-container__swm-panel__table-container">
            <Table aria-label="simple table">
                <TableHead
                    sx={{
                        backgroundColor:
                            "#e8e8e8",
                    }}
                >
                    <TableRow>
                        <TableCell
                            sx={{
                                backgroundColor:
                                    "rgba(0, 0, 0, 0.04)",
                            }}
                        >
                            SWM
                            Data
                            Stories
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableBodyRowComponent 
                        backgroundColor="rgba(0, 0, 0, 0.04)" 
                        onHoverBackgroundColor="rgba(0, 0, 0, 0.06)"
                        onClickRender="/varanasi_swm"
                        rowContent="Dumping Analysis Data Story"
                    />
                    <TableBodyRowComponent 
                        backgroundColor="rgba(0, 0, 0, 0.04)" 
                        onHoverBackgroundColor="rgba(0, 0, 0, 0.06)"
                        onClickRender="/varanasi_swm"
                        rowContent="Dumping Scheduling Data Story"
                    />    
                    <TableBodyRowComponent 
                        backgroundColor="rgba(0, 0, 0, 0.04)" 
                        onHoverBackgroundColor="rgba(0, 0, 0, 0.06)"
                        onClickRender="/varanasi_swm"
                        rowContent="Employee Activity Data Story"
                    />
                </TableBody>
            </Table>
        </div>

    );
}