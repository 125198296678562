import { Button, Menu } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { ambulanceDataStories } from "../constants/constants";
import { MenuItems } from "./MenuItems";

export const AmbulanceDropdown = () => {
	return (
		<div>
			<PopupState variant="popover" popupId="demo-popup-menu">
				{(popupState) => {
					return (
						<>
							<Button
								disableTouchRipple
								disableElevation
								disableFocusRipple
								{...bindTrigger(popupState)}
								style={{
									padding: 0,
									margin: 0,
									color: "black",
									fontFamily: "Fira Sans",
									fontWeight: 400,
									fontSize: "1rem",
									textTransform: "capitalize",
								}}
							>
								Ambulance Data Stories
							</Button>
							<Menu {...bindMenu(popupState)}>
								{ambulanceDataStories.map((el) => {
									return (
										<>
											<MenuItems
												httpLink={true}
												onClick={popupState.close}
												redirectTo={`https://ambulancestories.iudx.org.in/${el}/`}
												title={`${
													el.charAt(0).toUpperCase() +
													el.slice(1)
												} Data Stories`}
											/>
										</>
									);
								})}
							</Menu>
						</>
					);
				}}
			</PopupState>
		</div>
	);
};
