import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import TabPanel from "../../shared/components/TabPanelComponent/TabPanel";
import LeafletComponent from "./LeafletComponent";

const tabs = ["15Nov2021", "17Jan2022", "24Feb2022"];

export default function Tabx() {
	const [value, setValue] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					{tabs.map((tab, index) => (
						<Tab
							key={index}
							label={`${tab.replace("_", "-")}`}
							id={index.toString()}
						/>
					))}
				</Tabs>
			</Box>
			{tabs.map((tab, index) => (
				<TabPanel key={index} value={value} index={index}>
					<LeafletComponent timeRange={tab} />
				</TabPanel>
			))}
		</Box>
	);
}
