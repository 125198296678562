import React from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Card, CardHeader } from "@mui/material";
import FmdGoodRoundedIcon from "@mui/icons-material/FmdGoodRounded";

import "./styles.scss";
import locationNames from "../../../../../shared/Data/locationNames.json";
import env from "../../../../../../environments";

interface Props {
	longitude_1: number;
	latitude_1: number;
	label_1: string;
	longitude_2: number;
	latitude_2: number;
	label_2: string;
	mapRef: any;
}

const MapboxComponent = (props: Props) => {
	return (
		<Card variant="outlined">
			<CardHeader title="Satellite View" />

			<Map
				attributionControl={false}
				mapboxAccessToken={env.MAPBOX_API_KEY}
				initialViewState={{
					latitude: (props.latitude_1 + props.latitude_2) / 2,
					longitude: (props.longitude_1 + props.longitude_2) / 2,
					zoom: 11,
				}}
				ref={props.mapRef}
				logoPosition="top-right"
				dragRotate={false}
				style={{
					width: "100%",
					height: "300px",
				}}
				minZoom={9}
				dragPan={true}
				mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
			>
				<Marker
					longitude={props.longitude_1}
					latitude={props.latitude_1}
				>
					<div className="markerRow">
						<span className="markerLabel">
							{locationNames
								.find((loc) => loc.hex_id === props.label_1)
								?.locationName.slice(0, 25)}
							...
						</span>
						<FmdGoodRoundedIcon color="success" fontSize="large" />
					</div>
				</Marker>

				<Marker
					longitude={props.longitude_2}
					latitude={props.latitude_2}
				>
					<div className="markerRow">
						<span className="markerLabel">
							{locationNames
								.find((loc) => loc.hex_id === props.label_2)
								?.locationName.slice(0, 25)}
							...
						</span>
						<FmdGoodRoundedIcon color="primary" fontSize="large" />
					</div>
				</Marker>
			</Map>
		</Card>
	);
};

export default MapboxComponent;
