import React from "react";
import ReactEcharts from "echarts-for-react";
import { Card, CardHeader } from "@mui/material";

import locationNames from "../../../../../shared/Data/locationNames.json";

interface Props {
	timestamp_1: string[];
	values_1: number[];
	label_1: string;
	timestamp_2: string[];
	values_2: number[];
	label_2: string;
}

const TimeSeriesComponent = ({
	timestamp_1,
	values_1,
	label_1,
	// timestamp_2,
	values_2,
	label_2,
}: Props) => {
	const data_1: any = [];

	const data_2: any = [];

	timestamp_1.map((el, index) => {
		data_1.push([+new Date(el), values_1[index]]);
		data_2.push([+new Date(el), values_2[index]]);
		return null;
	});

	const option: echarts.EChartsOption = {
		tooltip: {
			trigger: "axis",
		},
		legend: {
			orient: "vertical",
			right: "right",
			top: 5,
			icon: "square",
			formatter: function (name) {
				return (
					(locationNames
						.find((loc) => loc.hex_id === name)
						?.locationName.slice(0, 20) as string) + "..."
				);
			},
			data: [
				{
					name: label_1,
				},
				{
					name: label_2,
				},
			],
		},
		xAxis: [
			{
				type: "time",
				splitLine: {
					show: false,
				},
			},
		],
		yAxis: [
			{
				splitLine: {
					show: false,
				},
				axisLine: {
					show: true,
				},
			},
		],
		series: [
			{
				name: label_1,
				type: "line",
				showSymbol: false,
				data: data_1,
			},
			{
				name: label_2,
				type: "line",
				showSymbol: false,
				data: data_2,
			},
		],
		graphic: [
			{
				type: "text",
				rotation: 1.5706,
				z: 100,
				left: "1%",
				top: "middle",
				style: {
					fill: "#333",
					width: 220,
					overflow: "break",
					text: "Influx",
					font: "18px Microsoft YaHei",
				},
			},

			{
				type: "text",
				z: 100,
				left: "center",
				top: 210,
				style: {
					fill: "#333",
					width: 220,
					overflow: "break",
					text: "Time",
					font: "20px Microsoft YaHei",
				},
			},
		],
	};

	return (
		<Card variant="outlined">
			<CardHeader title="Number of vehicles dumping garbage over time" />
			<ReactEcharts
				option={option}
				style={{
					height: "250px",
				}}
				className="echart"
			/>
		</Card>
	);
};

export default TimeSeriesComponent;
