import { Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import HotspotsAfterKNN from "./Applets/HotspotsAfterKNN";
import { KMeansDataAndDistortionScore } from "./Applets/KMeansDataAndDistortionScore";
import HeatmapLegendData from "./Applets/HeatmapLegendData";
import AmbulanceLocationData from "./Applets/AmbulanceLocationData";
import AmbulanceAssignment from "./Applets/AmbulanceAssignment/AmbulanceAssignment";
import Input from "./Applets/AmbulanceAssignment/Input";

const PredictiveAmbulance = () => {
	return (
		<>
			<Container
				fixed
				sx={{
					maxWidth: "80%",
				}}
			>
				<Helmet>
					<title>
          Optimal Ambulance Placement and Emergency response routing
					</title>
				</Helmet>
				<Typography variant="h3">
          Optimal Ambulance Placement and Emergency response routing
				</Typography>
				<Typography paragraph id="paragraph">
          It is a common occurrence in cities like Bangalore to find an Ambulance unable to cross a Traffic signal because of high density traffic backups. To improve emergency response and integrated approach including Ambulance live locations [1] and Traffic signals integrated with IUDX. Such approaches have been implemented in cities like Agartala and have proven to be successful.
          In lieu of a control channel to the traffic signalling, it is still possible to reap benefits of having Ambulance live locations flowing through IUDX through intelligent Ambulance placement and routing considering historic case responses, hospital networks and traffic hotspots which are analysed outputs from the data flowing through IUDX.
          Machine learning techniques can be employed in forecasting areas of high emergency demand by observing long-term historical movement patterns of ambulances and operations research strategies can be employed in intelligently placing ambulances, assigning their parking locations and also assigning pickups to ambulances. 
          We further show how we can extend this framework to also route ambulances to the emergency cases considering distance to the case from the ambulance, positions of the hospitals and also provide a framework to include dynamic parameters such as hospital capacities and live traffic.
				</Typography>
				<Typography variant="h4">
					Visualizing Emergency response demand hotspots
				</Typography>
				<Typography paragraph id="paragraph">
We bin Ambulance live locations using a Hexagonal binning technique called H3 [2] to give spatial insights at a region level, where a region is 0.1sq.km in area. 
On one month's worth of Ambulance positioning data from IUDX, we employ Local Moran’s I [3] which is an Autocorrelation technique to identify local spatial emergency response clusters. To focus on the hotspots, it normalizes their coordinates and finds the best number of clusters using the Elbow method, a technique to 	determine how many groups to split the data into. Widget 1 below shows these hotspots on an interactive map (hotspot centers are in yellow).
				</Typography>
				<HotspotsAfterKNN />


				<Typography variant="h4">
					Optimal Ambulance Placement / Parking
				</Typography>
				<Typography paragraph id="paragraph">
Given the emergency response demand from above, we can now find out the optimal parking locations for ambulances to reduce time to service. For the purpose of illustration, we choose 4 random multi-speciality hospitals in Bangalore and come up with a strategy to identify the best parking locations for the same. 
It is assumed that an agency like Manipal Ambulance Response Services (MARS) which operates across multiple hospitals is available in a city. We employ a linear optimization strategy, setting our goal to distribute a fixed number of ambulances in a way that maximizes coverage and improves response time.
We set up constraints like ensuring each hospital area had at least one ambulance nearby and avoiding placing ambulances in adjacent hexagons. Solving this puzzle gives us the best spots to station our ambulances. 
				</Typography>
				<AmbulanceLocationData />



				<Typography variant="h4">
					Optimization of Ambulance Services Allocation
				</Typography>
				<Typography paragraph id="paragraph">
Based on the optimal ambulance placement from above, we now move on to a comprehensive strategy to assign ambulances to an emergency response case and a hospital. This involves analysing geographical distribution of ambulance stations and the frequency of emergency incidents. Our primary goal is to minimize the total travel distances for ambulances, thereby ensuring an expedited and efficient response to emergency situations.
The widget below illustrates two paths of the end to end emergency response process flow. <br />
1. Assignment of an ambulance to an emergency response case <br />
2. Assignment of the ambulance from the site of the emergency response to the hospital satisfying the specified constraints (travel time, traffic, capacity of hospitals, etc) and routing using Graphopper [4] <br />
Widget below shows the above two paths, where the Green Marker is the location of the parked ambulance, the red marker is the location of the Emergency response case and the blue marker is the location of the assigned hospital. 
At each of the green markers, 3 ambulances are parked. 
The scenario simulated is a case where there are three emergency response demands from random locations in the vicinity.

				</Typography>
				<Input />
				<AmbulanceAssignment />




				<Typography variant="h4">Conclusion</Typography>
				<Typography paragraph id="paragraph">
        Through this data story, we have made a case in point for the requirement of data sharing of Ambulance Live Locations not only for Emergency Green Corridor applications but also for an operations optimization strategy which optimizes emergency response and redressal as a prerequisite for Green Corridor applications. By employing our strategies, cities can benefit in better case outcomes and also effectively utilize their fleet of ambulances. 
				</Typography>

				<Typography variant="h5">References</Typography>
				<ol type="1">
					<li>
						<a href="https://catalogue.cos.iudx.org.in/dataset/c6e27321-b0f3-43c3-9e70-3512b5ed1638">
							IUDX - Realtime Location Info of Ambulances in Bengaluru City
						</a>
					</li>


					<li>
						<a href="https://h3geo.org/">
							H3 - Hexagonal hierarchical geospatial indexing system
						</a>
					</li>


					<li>
						<a href="https://en.wikipedia.org/wiki/Moran%27s_I">
							Local Moran's I
						</a>
					</li>

					<li>
						<a href="https://www.graphhopper.com/products/">
							Graphhopper routing API
						</a>
					</li>


				</ol>
			</Container>
		</>
	);
};

export default PredictiveAmbulance;
