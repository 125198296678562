import React, { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet.timeline/dist/TimelineSliderControl";
import "leaflet.timeline/dist/Timeline";
import chroma from "chroma-js";
import axios from "axios";
import "./timeline.css";

interface Props {
	timeRange: string;
}

const LeafletComponent = ({ timeRange }: Props) => {
	const [winds, setWinds] = useState();
	const [points, setPoints] = useState();
	const [fires, setFires] = useState();
	const [dataLoaded, setDataLoaded] = useState(false);

	useEffect(() => {
		const getData = async () => {
			const windsData = await axios.get(
				`https://storiesdata.s3.ap-south-1.amazonaws.com/windfireaqm/winds${timeRange.replace(
					" ",
					""
				)}.json`
			);
			await setWinds(windsData.data);

			const pointsData = await axios.get(
				`https://storiesdata.s3.ap-south-1.amazonaws.com/windfireaqm/Aqm${timeRange.replace(
					" ",
					""
				)}.json`
			);
			await setPoints(pointsData.data);

			const firesData = await axios.get(
				`https://storiesdata.s3.ap-south-1.amazonaws.com/windfireaqm/fires${timeRange.replace(
					" ",
					""
				)}.json`
			);
			await setFires(firesData.data);
		};

		getData().then(() => {
			setDataLoaded(true);
		});
	}, [timeRange]);

	useEffect(() => {
		let map = L.map("map").setView(
			[18.52138147025615, 73.85539152532453],
			9
		);
		L.tileLayer(
			"https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png",
			{
				maxZoom: 18,
				attribution:
					'Map data &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="http://mapbox.com">Mapbox</a>',
			}
		).addTo(map);

		var slider = L.timelineSliderControl({
			formatOutput: function (date) {
				return new Date(date).toDateString();
				// return moment(date).format("YYYY-MM-DD");
			},
			showTicks: false,
		});
		map.addControl(slider);

		var polygonTimeline = L.timeline(winds);
		polygonTimeline.addTo(map);

		function coToRGBValues(co: any) {
			const scale = chroma
				.scale(["yellow", "red"])
				.domain([-700, 8000], 7, "log");
			return scale(+co).hex();
		}

		var pointTimeline = L.timeline(points, {
			pointToLayer: function (data, latlng) {
				return L.circleMarker(latlng, {
					radius: data.properties.co / 100,
					color: coToRGBValues(data.properties.co),
					fillColor: coToRGBValues(data.properties.co),
				});
			},
		});
		pointTimeline.addTo(map);
		// slider.addTimelines(polygonTimeline, pointTimeline);

		var fireTimeline = L.timeline(fires, {
			style: (_) => {
				return {
					color: "red",
				};
			},
		});
		fireTimeline.addTo(map);

		// fireTimeline.on("change", (e) => {
		// 	console.log(e);
		// });

		slider.addTimelines(polygonTimeline, pointTimeline, fireTimeline);

		return () => {
			map?.off();
			map?.remove();
		};
	}, [dataLoaded, fires, points, winds]);

	return (
		<div
			style={{
				width: "100%",
				height: "700px",
			}}
			id="map"
		></div>
	);
};

export default LeafletComponent;
