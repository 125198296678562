import { DeckGL } from "@deck.gl/react/typed";
import { ArcLayer } from "@deck.gl/layers/typed";
import StaticMap from "react-map-gl";
import { useSelector } from "react-redux";
import { selectedRedistributable } from "../../../../../../store/redistribute/redistributeSlice";

import redistribute from "../../../../../shared/Data/redistribute.json";
import dataArray from "../../../../../shared/Data/dump_loc_img_arr.json";
import locationNames from "../../../../../shared/Data/locationNames.json";

import env from "../../../../../../environments/index";

const DeckGLComponent = () => {
	const redistributable = useSelector(selectedRedistributable);

	const redistributableElement = redistribute.find(
		(red) => +new Date(red.actionTime) === redistributable
	);

	const geoJsonData: any = [];

	redistributableElement?.redistribute.map((red) => {
		geoJsonData.push({
			amount: red.amount,
			from: {
				name: red.from,
				coordinates: dataArray.find((data) => data.hexid === red.from)
					?.location,
			},
			to: {
				name: red.to,
				coordinates: dataArray.find((data) => data.hexid === red.to)
					?.location,
			},
		});
		return null;
	});

	const layer = new ArcLayer({
		id: "arc-layer",
		data: geoJsonData,
		pickable: true,
		getWidth: 3,
		getSourcePosition: (d) => d.from.coordinates,
		getTargetPosition: (d) => d.to.coordinates,
		getTargetColor: (d) => [255 - d.amount * 10, 0, 0],
	});

	return (
		<>
			<DeckGL
				layers={[layer]}
				initialViewState={{
					longitude: 82.97897143542947,
					latitude: 25.31922401227444,
					zoom: 13,
					pitch: 60,
					bearing: 30,
				}}
				style={{
					width: "100%",
					height: "400px",
					position: "relative",
				}}
				controller={true}
				getTooltip={({ object }) =>
					object &&
					`${object.amount} units from ${locationNames
						.find((loc) => object.from.name === loc.hex_id)
						?.locationName.slice(0, 20)}... to ${locationNames
						.find((loc) => loc.hex_id === object.to.name)
						?.locationName.slice(0, 20)}...`
				}
			>
				<StaticMap
					mapboxAccessToken={env.MAPBOX_API_KEY}
					reuseMaps
					mapStyle="mapbox://styles/mapbox/dark-v10"
				></StaticMap>
			</DeckGL>
		</>
	);
};

export default DeckGLComponent;
