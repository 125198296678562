import { Card, Grid } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton } from "@mui/material";
import React from "react";
import ColumnComponent from "../../../shared/components/ColumnComponent/ColumnComponent";
import SWMTableComponent from "../../../shared/components/TableComponent/SWMTableComponent";
interface Props {
	showIcon3: boolean;
	setShowIcon3: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SolidWasteManagementComponent({
	showIcon3,
	setShowIcon3,
}: Props) {
	return (
		<Grid item lg={6} md={6} sm={12}>
			<Card
				role="button"
				sx={{
					marginLeft: "30px",
					position: "relative",
				}}
				onMouseEnter={() => {
					setShowIcon3(true);
				}}
				onMouseLeave={() => {
					setShowIcon3(false);
				}}
			>
				<div className="iframe-container">
					<div className="iframe-row">
						<ColumnComponent
							message="Wardwise Vehicle Position"
							title="Surat ITMS"
							width="100%"
							height="400px"
							src="https://analytics.iudx.org.in:13000/superset/explore/p/XJ5lN30nx8p/?standalone=1&height=400"
							TableComponent={<></>}
						/>
						<ColumnComponent
							message="Vehicle Running Status"
							title="Surat ITMS"
							width="100%"
							height="190px"
							src="https://analytics.iudx.org.in:13000/superset/explore/p/VpWbem4bejK/?standalone=1&height=190"
							TableComponent={<SWMTableComponent />}
						/>
					</div>
				</div>

				{showIcon3 && (
					<div
						style={{
							position: "absolute",
							bottom: 0,
							right: 0,
						}}
					>
						<IconButton
							onClick={(e) => {
								window.open(
									"https://swmdashboards.iudx.org.in/varanasi/",
									"_blank"
								);
							}}
						>
							<LaunchIcon />
						</IconButton>
					</div>
				)}
				<div className="card-container__title">
					<p>Solid Waste Management</p>
				</div>
			</Card>
		</Grid>
	);
}
