import { MenuItem } from "@mui/material";
import { NavLink } from "react-router-dom";

interface Props {
	redirectTo: string;
	title: string;
	onClick: () => void;
	httpLink: boolean;
}

export function MenuItems({ redirectTo, title, onClick, httpLink }: Props) {
	return (
		<MenuItem onClick={onClick}>
			<NavLink
				className="dropDownLink"
				to={!httpLink ? redirectTo : ""}
				onClick={() => {
					httpLink && window.open(redirectTo, "_blank");
				}}
			>
				{title}
			</NavLink>
		</MenuItem>
	);
}
