import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import Map, { Layer, Source, ViewStateChangeEvent } from "react-map-gl";
import { useSelector } from "react-redux";
import env from "../../../../../environments";
import { selectedWard } from "../../../../../store/wards/wardsSlice";
import { dataLayer } from "./map-style";

import { Mode } from "./ControlPanel";
import { Grid, Typography } from "@mui/material";

const LeftMapStyle: CSSProperties = {
	width: "50%",
	height: "400px",
	borderRight: "2.5px solid black",
	border: "1px solid black",
};
const RightMapStyle: CSSProperties = {
	width: "50%",
	height: "400px",
	borderLeft: "2.5px solid black",
	border: "1px solid black",
};

interface Props {
	wardsMapRef: any;
	year: string;
}

const MapboxSliderComponent = ({ wardsMapRef, year }: Props) => {
	const [viewState, setViewState] = useState({
		longitude: 77.47460896692512,
		latitude: 13.11495651374481,
		zoom: 13,
	});

	const [mode, setMode] = useState<Mode>("split-screen");

	const [activeMap, setActiveMap] = useState<"left" | "right">("left");

	const onLeftMoveStart = useCallback(() => setActiveMap("left"), []);
	const onRightMoveStart = useCallback(() => setActiveMap("right"), []);
	const onMove: any = useCallback(
		(e: ViewStateChangeEvent) => setViewState(e.viewState),
		[]
	);

	const width = typeof window === "undefined" ? 100 : window.innerWidth;

	const leftMapPadding = useMemo(() => {
		return {
			left: mode === "split-screen" ? width / 2 : 0,
			top: 0,
			right: 0,
			bottom: 0,
		};
	}, [width, mode]);
	const rightMapPadding = useMemo(() => {
		return {
			right: mode === "split-screen" ? width / 2 : 0,
			top: 0,
			left: 0,
			bottom: 0,
		};
	}, [width, mode]);

	const demoGeoJson: any = useSelector(selectedWard);

	return (
		<Grid item xs={12}>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					flexDirection: "row",
				}}
			>
				<div
					style={{
						marginTop: "5px",
						position: "absolute",
						zIndex: 4,
						display: "flex",
						flexDirection: "row",
						padding: "2px",
						width: "10%",
						backgroundColor: "#093664",
						color: "white",
						justifyContent: "space-evenly",
						border: "1px solid black",
					}}
				>
					<div
						style={{
							width: "100px",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Typography variant="h6">Before</Typography>
					</div>

					<div
						style={{
							width: "100px",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Typography variant="h6">After</Typography>
					</div>
				</div>
				<Map
					id="left-map"
					maxZoom={14}
					{...viewState}
					ref={wardsMapRef}
					padding={leftMapPadding}
					onMoveStart={onLeftMoveStart}
					onMove={activeMap === "left" && onMove}
					style={LeftMapStyle}
					mapStyle="mapbox://styles/mapbox/light-v9"
					mapboxAccessToken={env.MAPBOX_API_KEY}
				>
					<Source type="geojson" data={demoGeoJson}>
						<Layer {...dataLayer} />
					</Source>
					<Source
						id="radar"
						type="raster"
						tiles={[
							`https://tiles-gsx.iudx.io/services/bangalore_trees/${year}_before/tiles/{z}/{x}/{y}.png`,
						]}
						tileSize={256}
						maxzoom={16}
						minzoom={6}
					>
						<Layer
							id="radar-layer"
							type="raster"
							source="radar"
							paint={{
								"raster-fade-duration": 0,
							}}
						/>
					</Source>
				</Map>

				<Map
					maxZoom={14}
					id="right-map"
					{...viewState}
					ref={wardsMapRef}
					padding={rightMapPadding}
					onMoveStart={onRightMoveStart}
					onMove={activeMap === "right" && onMove}
					style={RightMapStyle}
					mapStyle="mapbox://styles/mapbox/light-v9"
					mapboxAccessToken={env.MAPBOX_API_KEY}
				>
					<Source type="geojson" data={demoGeoJson}>
						<Layer {...dataLayer} />
					</Source>
					<Source
						id="radar"
						type="raster"
						tiles={[
							`https://tiles-gsx.iudx.io/services/bangalore_trees/${year}_after/tiles/{z}/{x}/{y}.png`,
						]}
						tileSize={256}
						maxzoom={16}
						minzoom={6}
					>
						<Layer
							id="radar-layer"
							type="raster"
							source="radar"
							paint={{
								"raster-fade-duration": 0,
							}}
						/>
					</Source>
				</Map>
			</div>
		</Grid>
	);
};

export default MapboxSliderComponent;
