export const pages = [
	{
		title: "Dashboards",
		path: "dashboards",
	},
	{
		title: "Data Stories",
		type: "dropdown",
		path: "varanasi_swm",
	},
];

export const dropdownMenuItems = [
	{
		title: "Urban Deforestation in Bangalore",
		redirectTo: "/urban_deforestation",
		httpLink: false,
	},
	{
		title: "Forest Fires leading to Pollution in Pune",
		redirectTo: "/wind_fire",
		httpLink: false,
	},
	{
		title: "Solid Waste Management in Varanasi",
		redirectTo: "/varanasi_swm",
		httpLink: false,
	},
	{
		title: "Predictive Ambulance Positioning & Pathfinding",
		redirectTo: "/predictive_ambulance",
		httpLink: false,
	},
	{
		title: "Urban Heat Islands",
		redirectTo: "https://heatislandstories.iudx.org.in/",
		httpLink: true,
	},
	{
		title: "Bengaluru Lakes",
		redirectTo: "https://lakestories.iudx.org.in/",
		httpLink: true,
	},
];

export const ambulanceDataStories = ["bengaluru", "chandigarh", "vadodara"];
