import * as React from "react";
import { useState, useCallback } from "react";
import Map, { Source, Layer } from "react-map-gl";
import { useDispatch } from "react-redux";

import { dataLayer } from "./map-style";
import env from "../../../../../environments";
import {
	selectWard,
	selectWardNo,
} from "../../../../../store/wards/wardsSlice";
import axios from "axios";

const MAPBOX_TOKEN = env.MAPBOX_API_KEY; // Set your mapbox token here

interface Props {
	onSelectWard: ({ latitude, longitude }: any) => void;
	year: string;
}

const WardsComponent = ({ onSelectWard, year }: Props) => {
	const [wardsData, setWardsData] = useState<any>();

	React.useEffect(() => {
		axios
			.get(
				`https://storiesdata.s3.ap-south-1.amazonaws.com/deforestation/bangalore_forests_${year.replace(
					"_",
					"-"
				)}.geojson`
			)
			.then((res) => {
				return res.data;
			})
			.then((res) => setWardsData(res));
	}, []);

	const dispatch = useDispatch();

	const [hoverInfo, setHoverInfo] = useState<any>(null);

	const onHover = useCallback((event: any) => {
		const {
			features,
			point: { x, y },
		} = event;
		const hoveredFeature = features && features[0];

		setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
	}, []);

	const onClick = useCallback((event: any) => {
		const {
			features,
			point: { x, y },
		} = event;

		const hoveredFeature = features && features[0];
		const selectedWardNo = hoveredFeature.properties.WARD_NO;

		axios
			.get(
				`https://storiesdata.s3.ap-south-1.amazonaws.com/deforestation/bangalore_forests_${year.replace(
					"_",
					"-"
				)}.geojson`
			)
			.then((res) => res.data)
			.then((res) => {
				const selectedWard = res.features.find(
					(ward: any) => ward.properties.WARD_NO === selectedWardNo
				);
				onSelectWard({
					latitude: selectedWard.properties.LAT,
					longitude: selectedWard.properties.LON,
				});

				dispatch(selectWard(selectedWard));
				dispatch(selectWardNo(selectedWardNo));

				setHoverInfo(
					hoveredFeature ? { feature: hoveredFeature, x, y } : null
				);
			});
	}, []);

	return (
		<>
			<Map
				style={{
					width: "100%",
					height: "400px",
				}}
				initialViewState={{
					latitude: 12.972212754415434,
					longitude: 77.59280829782698,
					zoom: 9.6,
				}}
				mapStyle="mapbox://styles/mapbox/light-v9"
				mapboxAccessToken={MAPBOX_TOKEN}
				interactiveLayerIds={["data"]}
				// onMouseMove={onHover}
				onClick={onClick}
			>
				<Source type="geojson" data={wardsData}>
					<Layer {...dataLayer} />
				</Source>
				{hoverInfo ? (
					<div
						className="tooltip"
						style={{
							padding: "5px",
							backgroundColor: "black",
							color: "white",
							width: "150px",
							position: "relative",
							left: hoverInfo.x,
							top: hoverInfo.y,
						}}
					>
						<div>
							Ward: {hoverInfo.feature.properties.WARD_NAME}
						</div>
					</div>
				) : (
					""
				)}
			</Map>
		</>
	);
};

export default WardsComponent;
