import { createTheme } from "@mui/material/styles";

export default function DashboardTheme()
{
    return (
        createTheme({
            components: {
                MuiContainer: {
                    styleOverrides: {
                        root: {
                            marginTop: "40px",
                            marginBottom: "40px",
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            boxShadow:
                                "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px",
                            borderRadius: "18px",
                            padding: "30px",
                            // margin: "20px",
                            // "&:hover": {
                            //   cursor: "pointer",
                            //   transform: "scale(1.06)",
                            //   transition: "0.3s ease-in-out",
                            // },
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        contained: {
                            backgroundColor: "#205d9d",
                        },
                    },
                },
            },
        })

    );
}