import L from "leaflet";
import React, { useEffect } from "react";
import data from "../../../shared/Data/predictiveAmbulance/ambulance_location_data.json";
import jp from "jsonpath";
import geojson2h3 from "geojson2h3";

const AmbulanceLocationData = () => {
	useEffect(() => {
		const map = L.map("ambulanceLocationData").setView(
			[12.999852626662887, 77.5872041000448],
			12
		);
		L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
			maxZoom: 18,
		}).addTo(map);

		const feature = geojson2h3.h3SetToFeatureCollection(
			jp.query(data, "$.hexagon_data..hex_id"),
			(h3Index) => {
				return {
					type: data.hexagon_data.filter(
						(fil) => fil.hex_id === h3Index
					)[0].type,
				};
			}
		);

		L.geoJSON(feature, {
			style: function (feature: any) {
				switch (feature?.properties.type) {
					case "hospital":
						return {
							color: data.color_scheme.hospital.color,
						};
					case "possible_parking":
						return {
							color: data.color_scheme.possible_parking.color,
						};
					case "optimal_parking":
						return {
							color: data.color_scheme.optimal_parking.color,
						};
				}
			} as any,
		}).addTo(map);

		var info = new L.Control({ position: "topright" });

		info.onAdd = function (map: any) {
			var div = L.DomUtil.create("div", "info"); // create a div with a class "info"
			div.innerHTML = `
			<div class="legend">
				<h4>Legend</h4>
				<div class="legendList">
					<div class="legendItem">
						<div class="colorBox" id="redBox"></div> <span>Hospitals</span>
					</div>
					<div class="legendItem">
						<div class="colorBox" id="blueBox"></div> <span>Possible Parking Locations</span>
					</div>
					<div class="legendItem">
						<div class="colorBox" id="greenBox"></div> <span>Optimal Parking</span>
					</div>
				</div>
			</div>
			`;
			return div;
		};

		info.addTo(map);

		return () => {
			map.off();
			map.remove();
		};
	});

	return (
		<>
			<div
				style={{
					width: "100%",
					height: "800px",
				}}
				id="ambulanceLocationData"
			/>
		</>
	);
};

export default AmbulanceLocationData;
