import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { red, blue } from "@mui/material/colors";
import "./MapboxLegend.scss";

interface Props {
	optimizedRoute: boolean;
	setOptimizedRoute: any;
	realRoute: boolean;
	setRealRoute: any;
	vehicle: any;
}

const MapboxLegend = ({
	optimizedRoute,
	realRoute,
	setOptimizedRoute,
	setRealRoute,
	vehicle,
}: Props) => {
	return (
		<div className="mapboxLegendContainer">
			<div className="routeSelector">
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								value={optimizedRoute}
								onChange={() => {
									setOptimizedRoute(!optimizedRoute);
								}}
								sx={{
									color: blue[800],
									"&.Mui-checked": {
										color: blue[600],
									},
								}}
								defaultChecked
							/>
						}
						label="Optimized Route"
					/>

					<FormControlLabel
						color="danger"
						control={
							<Checkbox
								value={realRoute}
								onChange={() => {
									setRealRoute(!realRoute);
								}}
								sx={{
									color: red[800],
									"&.Mui-checked": {
										color: red[600],
									},
								}}
								defaultChecked
							/>
						}
						label="Real Route"
					/>
				</FormGroup>
			</div>
			<div
				style={{
					fontWeight: "bolder",
					textAlign: "right",
					width: "30%",
					margin: "5px 0 0 10px",
					padding: "1px",
					lineHeight: 1.5,
				}}
			>
				Vehicle Type: {vehicle["Vehicle Type"]}
				<br />
				Optimized Distance:{" "}
				{(+vehicle["Optimized Distance"] / 1000).toFixed(2)} km
				<br />
				Real Distance: {(+vehicle["Real Distance"] / 1000).toFixed(
					2
				)}{" "}
				km <br />
				Difference:{" "}
				{(
					(+vehicle["Real Distance"].toFixed(2) -
						+vehicle["Optimized Distance"].toFixed(2)) /
					1000
				).toFixed(2)}{" "}
				km
			</div>
		</div>
	);
};

export default MapboxLegend;
