import { Card, Grid } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton } from "@mui/material";
import React from "react";
import ColumnComponent from "../../../shared/components/ColumnComponent/ColumnComponent";
interface Props {
	showIcon2: boolean;
	setShowIcon2: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TransitManagementComponent({
	showIcon2,
	setShowIcon2,
}: Props) {
	return (
		<Grid item lg={6} md={6} sm={12}>
			<Card
				sx={{
					marginRight: "30px",
					position: "relative",
				}}
				onMouseEnter={() => {
					setShowIcon2(true);
				}}
				onMouseLeave={() => {
					setShowIcon2(false);
				}}
			>
				<div className="iframe-container">
					<div className="iframe-row">
						<ColumnComponent
							message="Deviated Vehicles"
							title="Live Trips"
							width="100%"
							height="400px"
							src="https://analytics.iudx.org.in:13000/superset/explore/p/8mDn6RxgnNW/?standalone=1&height=400"
							TableComponent={<></>}
						/>
						<ColumnComponent
							message="Trip Status"
							title="Surat ITMS"
							width="100%"
							height="400px"
							src="https://analytics.iudx.org.in:13000/superset/explore/p/MJYGLNqWn9B/?standalone=1&height=400"
							TableComponent={<></>}
						/>
					</div>
				</div>
				{showIcon2 && (
					<div
						style={{
							position: "absolute",
							bottom: 0,
							right: 0,
						}}
					>
						<IconButton
							onClick={() => {
								window.open(
									"https://analytics.iudx.org.in:13000/superset/dashboard/p/z0GAzlKDdwL/?standalone=true",
									"_blank"
								);
							}}
						>
							<LaunchIcon />
						</IconButton>
					</div>
				)}
				<div className="card-container__title">
					<p>Transit Management</p>
				</div>
			</Card>
		</Grid>
	);
}
