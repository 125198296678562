import React from "react";
import { Card, Grid } from "@mui/material";
import { useState } from "react";
import { useRef, useCallback } from "react";
import { MapRef } from "react-map-gl";
import ImageComponent from "./Components/ImageComponent";
import ListSidebarComponent from "./Components/ListSidebarComponent";
import MapboxComponent from "./Components/MapboxComponent";
import TimeSeriesComponent from "./Components/TimeSeriesComponent";

import binDataWithTrend from "../../../../shared/Data/dump_loc_img_arr.json";
import { Coordinate } from "../DistanceCorrelationComponent";
import { BinCoordinateWithTrend } from "../../../../shared/types/BinCoordinateWithTrend";
import CaptionComponent from "../../../../shared/components/CaptionComponent";

const StreetSatelliteComponent = () => {
	const defaultBinData: BinCoordinateWithTrend = binDataWithTrend[0];

	const [selectedBin, setSelectedBin] =
		useState<BinCoordinateWithTrend>(defaultBinData);

	const mapRef: any = useRef<MapRef>();

	const onSelectCity = useCallback(({ longitude, latitude }: Coordinate) => {
		mapRef.current?.flyTo({
			center: [longitude, latitude],
			duration: 2000,
		});
	}, []);

	return (
		<>
			<Card
				style={{
					padding: "1%",
				}}
				variant="outlined"
			>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<ListSidebarComponent
							binDataArray={binDataWithTrend}
							selectedBin={selectedBin}
							setSelectedBin={setSelectedBin}
							onSelectCity={onSelectCity}
						/>
					</Grid>
					<Grid item xs={9}>
						<Grid container spacing={2} direction="column">
							<Grid item>
								<Grid container spacing={2} direction="row">
									<Grid item xs={6}>
										<ImageComponent
											image={selectedBin.streetview}
										/>
									</Grid>
									<Grid item xs={6}>
										<MapboxComponent
											latitude={selectedBin.location[1]}
											longitude={selectedBin.location[0]}
											mapRef={mapRef}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								{selectedBin.timestamp && selectedBin.values ? (
									<TimeSeriesComponent
										timestamp={selectedBin.timestamp}
										values={selectedBin.values}
									/>
								) : (
									<p>No timeseries data</p>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Card>
			<CaptionComponent
				title="Widget 1:"
				text="Varanasi Solid Waste dumping area locator with dumping trends"
			/>
		</>
	);
};

export default StreetSatelliteComponent;
