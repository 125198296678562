import React from "react";
import "leaflet/dist/leaflet.css";
import { Card, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import Tabs from "./Tabs";

const WindFireAQM = () => {
	return (
		<>
			<Container
				fixed
				sx={{
					maxWidth: "80%",
				}}
			>
				<Helmet>
					<title>
						Forest Fires Leading to Pollution in Pune | IUDX Data
						Stories
					</title>
				</Helmet>
				<Typography variant="h3">
					Forest Fires Leading to Pollution in Pune
				</Typography>
				<Typography paragraph id="paragraph">
					India stands 8th amongst the most polluted countries in the
					world with the PM2.5 concentration being currently above the
					WHO recommended limits <sup>[1]</sup>. It has been found
					according to a survey conducted by National Institute of
					Environmental Health Sciences <sup>[2]</sup> that almost 17%
					of total pollution is contributed from agricultural waste
					burning, including forest fires. Forest fires also release
					the carbon locked in its biomass, making them a source of
					carbon emissions including toxic gases like Carbon Monoxide.
					These effluents turn out to be pervasive and can travel a
					very long distance aided by wind and threaten large cities.
					We see below major forest fires and stubble burning
					incidents captured by Fire Information for Resource
					Management System (FIRMS) dataset containing LANCE fire
					detection product by NASA <sup>[3]</sup>
					<sup>[4]</sup>. We additionally capture the prevailing winds
					from Global Forecast Service by NOAA <sup>[5]</sup> (from
					Google Earth Engine <sup>[6]</sup>) blowing from the
					direction of the fires towards the city and monitor data
					from Air Quality Monitoring stations provided by IUDX{" "}
					<sup>[7]</sup> to observe for anomalous increase in
					hazardous gases, namely Carbon Monoxide.
					<br />
					<br /> Click on the tabs for viewing this animation on the
					respective dates, and click the play button to interactively
					view the effect of fires on a cities air quality level.
				</Typography>
				<Card
					style={{
						padding: "1%",
					}}
					variant="outlined"
				>
					<Tabs />
				</Card>
				<br />
				<br />

				<Typography variant="h5">References</Typography>
				<ol type="1">
					<li>
						<a href="https://www.iqair.com/in-en/india">
							https://www.iqair.com/in-en/india
						</a>
					</li>
					<li>
						<a href="https://factor.niehs.nih.gov/2018/9/feature/3-feature-india">
							https://factor.niehs.nih.gov/2018/9/feature/3-feature-india
						</a>
					</li>
					<li>
						<a href="https://firms.modaps.eosdis.nasa.gov/map/#d:24hrs;@0.0,0.0,3z">
							https://firms.modaps.eosdis.nasa.gov/map/#d:24hrs;@0.0,0.0,3z
						</a>
					</li>
					<li>
						<a href="https://developers.google.com/earth-engine/datasets/catalog/FIRMS">
							https://developers.google.com/earth-engine/datasets/catalog/FIRMS
						</a>
					</li>
					<li>
						<a href="https://www.ncei.noaa.gov/products/weather-climate-models/global-forecast">
							https://www.ncei.noaa.gov/products/weather-climate-models/global-forecast
						</a>
					</li>
					<li>
						<a href="https://developers.google.com/earth-engine/datasets/catalog/NOAA_GFS0P25">
							https://developers.google.com/earth-engine/datasets/catalog/NOAA_GFS0P25
						</a>
					</li>
					<li>
						<a href="https://catalogue.iudx.org.in/pune/dataset/datakaveri.org-04a15c9960ffda227e9546f3f46e629e1fe4132b-rs.iudx.org.in-pune-env-aqm">
							https://catalogue.iudx.org.in/pune/dataset/datakaveri.org-04a15c9960ffda227e9546f3f46e629e1fe4132b-rs.iudx.org.in-pune-env-aqm
						</a>
					</li>
				</ol>
			</Container>
		</>
	);
};

export default WindFireAQM;
