import React from "react";
import {
	Card,
	CardHeader,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from "@mui/material";
import { BinCoordinateWithTrend } from "../../../../../shared/types/BinCoordinateWithTrend";

import { Coordinate } from "../../DistanceCorrelationComponent/index";

import locationNames from "../../../../../shared/Data/locationNames.json";

interface Props {
	binDataArray: BinCoordinateWithTrend[];
	selectedBin: BinCoordinateWithTrend;
	setSelectedBin: React.Dispatch<
		React.SetStateAction<BinCoordinateWithTrend>
	>;
	onSelectCity: ({ latitude, longitude }: Coordinate) => void;
}

const ListSidebarComponent = ({
	binDataArray,
	selectedBin,
	setSelectedBin,
	onSelectCity,
}: Props) => {
	const handleChange = (index: any) => {
		setSelectedBin(binDataArray[index]);
	};

	return (
		<Card variant="outlined">
			<CardHeader title="Location" />
			<List
				sx={{
					width: "100%",
					bgcolor: "background.paper",
					position: "relative",
					overflow: "auto",
					maxHeight: 500,
					"& ul": { padding: 0 },
				}}
				subheader={<li />}
			>
				{binDataArray.map((el, index) => (
					<ListItem
						selected={el.hexid === selectedBin.hexid}
						key={index}
						style={{
							padding: "0",
						}}
					>
						<ListItemButton
							onClick={() => {
								handleChange(index);
								onSelectCity({
									latitude: Number(`${el.location[1]}`),
									longitude: Number(`${el.location[0]}`),
								});
							}}
						>
							<ListItemText
								primary={`${
									locationNames.find(
										(loc) => loc.hex_id === el.hexid
									)?.locationName
								}`}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Card>
	);
};

export default ListSidebarComponent;
