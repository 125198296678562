import React from "react";
import { BrowserRouter } from "react-router-dom";

import Router from "./Router";
import "./App.scss";
import { ThemeProvider } from "@mui/material";

import { Provider } from "react-redux";

import { store } from "../../store/rootStore";
import { theme } from "./MaterialTheme";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<BrowserRouter>
					<Router />
				</BrowserRouter>
			</Provider>
		</ThemeProvider>
	);
}

export default App;
