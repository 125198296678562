import { Card, CardHeader, Grid } from "@mui/material";
import { decode } from "@googlemaps/polyline-codec";
import Map, { Layer, Marker, Source } from "react-map-gl";
import DeleteIcon from "@mui/icons-material/Delete";

import env from "../../../../../../../environments";
import { getCoordinates } from "./getCoordinates";
import { optimizedDataLayer, realDataLayer } from "./dataLayers";
import { useState } from "react";
import MapboxLegend from "./MapboxLegend/MapboxLegend";
import { VehicleData } from "../../../../../../shared/types/VehicleData";

interface Props {
	selectedVehicle: string;
	mapRef: any;
	redistributableRoutes: VehicleData[];
}

const MapboxComponent = ({
	selectedVehicle,
	mapRef,
	redistributableRoutes,
}: Props) => {
	const [realRoute, setRealRoute] = useState<boolean>(true);
	const [optimizedRoute, setOptimizedRoute] = useState<boolean>(true);

	const vehicle =
		redistributableRoutes[
			redistributableRoutes.indexOf(
				redistributableRoutes.find(
					(route) => route.Vehicle === selectedVehicle
				) as any
			)
		];

	const bins: any = [];

	const realRouteGeoJson: any = {
		type: "LineString",
		coordinates: [],
	};

	const optimizedRouteGeoJson: any = {
		type: "LineString",
		coordinates: [],
	};

	decode(vehicle.Bins, 5).map((point) => {
		bins.push(point.reverse());
		return null;
	});

	decode(vehicle["Real Route"], 5).map((point) => {
		realRouteGeoJson.coordinates.push(point.reverse());
		return null;
	});

	decode(vehicle["Optimized Route"], 5).map((point) => {
		optimizedRouteGeoJson.coordinates.push(point.reverse());
		return null;
	});

	return (
		<Grid item xs={10}>
			<Card variant="outlined">
				<CardHeader title="Satellite View" />
				<MapboxLegend
					realRoute={realRoute}
					optimizedRoute={optimizedRoute}
					setRealRoute={setRealRoute}
					setOptimizedRoute={setOptimizedRoute}
					vehicle={vehicle}
				/>
				<Map
					attributionControl={false}
					mapboxAccessToken={env.MAPBOX_API_KEY}
					initialViewState={{
						latitude: 25.31332,
						longitude: 82.98504,
						zoom: 13,
					}}
					ref={mapRef}
					logoPosition="bottom-right"
					dragRotate={false}
					style={{
						width: "100%",
						height: "400px",
					}}
					minZoom={9}
					dragPan={true}
					mapStyle="mapbox://styles/mapbox/light-v11"
				>
					{realRoute ? (
						<Source
							id="realRoute"
							type="geojson"
							data={realRouteGeoJson}
						>
							<Layer {...realDataLayer} />
						</Source>
					) : null}
					{optimizedRoute ? (
						<Source
							id="optimizedRoute"
							type="geojson"
							data={optimizedRouteGeoJson}
						>
							<Layer {...optimizedDataLayer} />
						</Source>
					) : null}
					{bins.map((bin: any, index: number) => (
						<Marker
							key={index}
							latitude={bin[0]}
							longitude={bin[1]}
						>
							<DeleteIcon color="success" />
						</Marker>
					))}
					<Marker
						latitude={getCoordinates(vehicle["Start Point"])[0]}
						longitude={getCoordinates(vehicle["Start Point"])[1]}
					/>
					<Marker
						latitude={getCoordinates(vehicle["End Point"])[0]}
						longitude={getCoordinates(vehicle["End Point"])[1]}
					/>
				</Map>
			</Card>
		</Grid>
	);
};

export default MapboxComponent;
