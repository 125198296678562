import ReactEcharts from "echarts-for-react";
import { Card } from "@mui/material";

interface Props {
	timestamp: string[];
	values: number[];
}

const TimeSeriesComponent = ({ timestamp, values }: Props) => {
	const data: any = [];

	timestamp.map((el, index) => {
		data.push([+new Date(el), values[index]]);
		return null;
	});
	const option: echarts.EChartsOption = {
		// Make gradient line here
		visualMap: [
			{
				show: false,
				type: "continuous",
				seriesIndex: 0,
				min: Math.min(...values),
				max: Math.max(...values),
				inRange: {
					color: ["green", "orange", "red"],
				},
			},
		],
		title: [
			{
				left: "center",
				text: "Number of vehicles dumping garbage over time",
			},
		],
		tooltip: {
			trigger: "axis",
			formatter: function (params: any) {
				return `${new Date(
					params[0].data[0]
				).toDateString()} <br /> <b>${params[0].data[1]}</b>`;
			},
		},
		xAxis: [
			{
				type: "time",
				splitLine: {
					show: false,
				},
			},
		],
		yAxis: [
			{
				splitLine: {
					show: false,
				},
				axisLine: {
					show: true,
				},
			},
		],
		series: [
			{
				type: "line",
				showSymbol: false,
				data: data,
			},
		],
		graphic: [
			{
				type: "text",
				rotation: 1.5706,
				z: 100,
				left: "1.5%",
				top: "middle",
				style: {
					fill: "#333",
					width: 220,
					overflow: "break",
					text: "Influx",
					font: "23px Microsoft YaHei",
				},
			},
			{
				type: "text",
				z: 100,
				left: "left",
				top: "190px",
				style: {
					fill: "#333",
					width: 220,
					overflow: "break",
					text: "per day",
					font: "15px Microsoft YaHei",
				},
			},
			{
				type: "text",
				z: 100,
				left: "center",
				top: 260,
				style: {
					fill: "#333",
					width: 220,
					overflow: "break",
					text: "Time",
					font: "20px Microsoft YaHei",
				},
			},
			{
				type: "text",
				z: 100,
				left: "center",
				top: "180px",
				style: {
					fill: "red",
					width: 220,
					overflow: "break",
					text: `${(values.reduce((a, b) => a + b, 0) / values.length)
						.toFixed(2)
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
					font: "20px Microsoft YaHei",
				},
			},
			{
				type: "text",
				z: 100,
				left: "center",
				top: "200px",
				style: {
					fill: "#333",
					width: 170,
					overflow: "break",
					text: "Average number of readings per day",
					font: "12px Microsoft YaHei",
				},
			},
		],
	};

	return (
		<Card variant="outlined">
			<ReactEcharts
				option={option}
				style={{
					height: "300px",
				}}
				className="echart"
			/>
		</Card>
	);
};

export default TimeSeriesComponent;
