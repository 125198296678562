import { GeoJsonLayerProps } from "@deck.gl/layers/typed";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootStore";

import wardsData from "../../views/shared/Data/wards/Wards_Features";

interface WardsState {
	value: GeoJsonLayerProps;
	status: string;
	wardNo: string;
}

const initialState: WardsState = {
	value: wardsData.features[0],
	status: "idle",
	wardNo: "1",
};

export const wardsSlice = createSlice({
	name: "wards",
	initialState,
	reducers: {
		selectWard: (state: any, action: PayloadAction<GeoJsonLayerProps>) => {
			state.value = action.payload;
		},
		selectWardNo: (state, action: PayloadAction<string>) => {
			state.wardNo = action.payload;
		},
	},
});

export const { selectWard, selectWardNo } = wardsSlice.actions;
export const selectedWard = (state: RootState) => state.wards.value;
export const selectedWardNo = (state: RootState) => state.wards.wardNo;

export default wardsSlice.reducer;
