import { Container, Divider, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";

import DistanceCorrelationComponent from "./Applets/DistanceCorrelationComponent";
import StreetSatelliteComponent from "./Applets/StreetSatelliteComponent";
import RedistributableComponentV2 from "./Applets/RedistributableComponentV2";

import "./styles.scss";
import RoutePlanningComponent from "./Applets/RoutePlanningComponent";
import TableComponent from "./Applets/TableComponent";

const VaranasiSWM: React.FC = () => {
	return (
		<div>
			<Container
				fixed
				sx={{
					maxWidth: "80%",
				}}
			>
				<Helmet>
					<title>
						Varanasi Solid Waste Management | IUDX Data Stories
					</title>
				</Helmet>
				<Typography variant="h3">
					Data Driven Solid Waste Management in Varanasi
				</Typography>
				<Typography paragraph id="paragraph">
					In the context of smart cities, effective solid waste
					management involves using advanced technologies to track and
					manage waste collection and disposal. By equipping waste
					collection bins and vehicles with sensors, real-time
					monitoring of waste levels and service history can be
					achieved. The data collected from these sensors can then be
					analyzed to gain further operational insights into waste
					collection and disposal processes, ensuring timely servicing
					of bins, optimal scheduling of vehicle fleets, and efficient
					management of dumping sites. Here, we will discuss some of
					the curated techniques in this domain and explain how smart
					cities can benefit from instrumenting vehicles and
					equipment, as well as highlight potential savings that can
					be achieved by employing these techniques.
				</Typography>
				<Typography paragraph id="paragraph">
					Solid waste management in Varanasi is a challenging issue
					that affects the environment and human well-being of the
					historic cultural capital of India. The city generates about
					550-650 tonnes per day (TPD) of municipal solid waste (MSW),
					mainly organic fraction, paper, plastic, and metal. The
					current practices of waste collection, transportation, and
					disposal are inadequate and inefficient, leading to open
					dumping, pollution, and health hazards. The Varanasi
					Municipal Corporation (VMC) has initiated some measures to
					improve the waste management system, such as door-to-door
					collection, source segregation, composting, anaerobic
					digestion, and waste-to-energy gasification. However, these
					efforts need to be scaled up and supported by scientific
					research and stakeholder participation.
				</Typography>
				<Typography paragraph id="paragraph">
					The dataset gathered from Varanasi, India, through IUDX
					<sup>[1]</sup> consists of information on the capacity and
					location of garbage bins, as well as real-time GPS data that
					provides the location, license plate number, type, and
					operational status of garbage collection vehicles.
				</Typography>
				<ul className="unorderedList">
					<li> Number of available vehicles: 144 </li>
					<li> Number of garbage bins: 816 </li>
					<li> Number of confirmed dump sites: 15 </li>
					<li>
						Average garbage generated in the city per day: 897 TPD
					</li>
				</ul>

				<Divider light />

				<Typography variant="h4">
					Identification of dumping sites from vehicle locations:
				</Typography>
				<Typography paragraph id="paragraph">
					By analyzing the movement patterns of waste collection
					vehicles in the city, seventeen locations were identified as
					potential dumping sites. To confirm these locations, the
					Google Maps Street View feature was used as shown in Widget
					1. This analysis was performed by binning vehicle locations
					on hexagons, generated using a Hexagonal Geospatial Indexing
					system, and examining the patterns of vehicles entering and
					leaving the identified locations. This process is also
					called influx analysis. Upon closer examination, it was
					discovered that some of the identified locations were
					illegal dumping sites.
				</Typography>
				<StreetSatelliteComponent />

				<Divider light />

				<Typography variant="h4">
					Analysis of dumping correlation between two sites:
				</Typography>
				<Typography paragraph id="paragraph">
					After analyzing the trends of dumping activity among
					different sites, it was discovered that certain dumping
					locations show a positive correlation in dumping patterns,
					meaning that an increase in dumping at one location
					corresponds to a parallel increase in dumping at another. On
					the other hand, some locations exhibit negative correlation,
					where an increase in dumping activity at one site
					corresponds to a decrease in the other. Widget 2 shows a
					dumping location matrix where a high correlation of dumping
					trends between two locations is indicated by a dark green
					whereas lower or negative correlation is indicated with
					shades of red. This information can be useful in identifying
					locations with abnormal dumping activity due to events such
					as large public gatherings and help redistributing excess
					garbage to the nearest negatively correlated site, thereby
					reducing excessive dumping at some sites.
				</Typography>
				<DistanceCorrelationComponent />

				<Divider light />

				<Typography variant="h4">
					City wide garbage redistribution strategy:
				</Typography>
				<Typography paragraph id="paragraph">
					Additionally waste dump site capacities can be effectively
					managed by employing operational optimization strategies,
					such as linear programming, to analyze the waste management
					system as a whole at specific "action points." These points
					indicate a specific time during the week when the deviation
					of excess garbage levels in all dump sites surpasses a
					predefined threshold. During such times, the algorithms can
					determine the most efficient redistribution and rerouting of
					waste dumping vehicles from locations with excess garbage to
					locations with lower than usual capacities. Widget 3 shows a
					redistribution strategy for vehicles to be rerouted during
					select action timestamps indicated in blue. Clicking on the
					action points will show the redistribution strategy to be
					employed.
				</Typography>
				<RedistributableComponentV2 />

				<Divider light />

				<Typography variant="h4">
					Estimation of daily garbage generation per ward and average
					garbage bin occupancy
				</Typography>
				<Typography paragraph id="paragraph">
					Further, after analyzing the data collected from Varanasi
					city, it was observed that the bin occupancy data
					<sup>[3]</sup> was not reliable, as the values remained
					constant for all the bins. To address this, average bin
					occupancies were estimated using the data provided in this
					document<sup>[2]</sup>, which includes the total garbage
					generated for every ward in Varanasi city. With this
					information, the average bin occupancy for all bins was
					calculated. Additionally, it was noted that some wards did
					not have a digital record of their garbage bins.
				</Typography>

				<Divider light />

				<Typography className="subheadingh5" variant="h5">
					Route planning for garbage collection vehicles
				</Typography>
				<Typography paragraph id="paragraph">
					Efficiently collecting and disposing of garbage is a
					significant challenge in managing solid waste. Typically,
					this task is carried out by garbage collection vehicles,
					which aim to minimize fuel and driver costs by collecting
					garbage from all bins while travelling the shortest possible
					distance. To achieve this, a Capacitated Vehicle Routing
					Problem (CVRP) is formulated, which considers various
					constraints such as vehicle capacity and route optimization.
					Specifically, the CVRP requires each vehicle to start at a
					designated location, collect as much garbage as possible,
					visit nearby dumping sites, and return to the starting
					location. Using available data on garbage bin locations,
					capacities, and real-time GPS data on vehicle locations,
					license plate numbers, types, and operational status, the
					CVRP is solved to optimize route planning.
				</Typography>

				<Typography paragraph id="paragraph">
					The results of the optimized waste collection strategy are
					compared to the actual vehicle fleet statistics to highlight
					the benefits of using the Capacitated Vehicle Routing
					Problem (CVRP) model. The current fleet of waste collection
					vehicles traveled over three times the distance compared to
					the optimized solution. However, the optimized solution used
					almost double the number of vehicles but collected almost
					three times more garbage than the current fleet operations.
					Furthermore, with a reduction in distance by a factor of
					three, fuel costs are also reduced by a factor of three.
					Based on the analysis, it is more advantageous to use
					vehicles with higher capacities instead of smaller ones with
					lower capacities. This is because the larger vehicles can
					cover more bins in a single trip, resulting in less distance
					travelled overall.
				</Typography>

				<Typography paragraph id="paragraph">
					The results of the actual and optimized route planning are
					statistically compared and presented in the table below.
				</Typography>

				<TableComponent />

				<Divider light />

				<Typography className="subheadingh5" variant="h5">
					Comparison between actual and optimized vehicle routes:
				</Typography>
				<Typography paragraph id="paragraph">
					Furthermore, a comparison was made between the actual
					vehicle routes and an optimized route obtained using the
					Google OR-Tools package. This optimization was also
					formulated as a CVRP with relevant constraints. The
					optimized and actual routes are displayed using
					openstreetmap, along with relevant summary statistics.
				</Typography>

				<RoutePlanningComponent />

				<Divider light />

				<Typography variant="h4">Conclusion</Typography>
				<Typography paragraph id="paragraph">
					Based on the above analysis, it becomes apparent that the
					use of a well-equipped fleet of waste collection vehicles
					and reliable data is essential for efficient solid waste
					management in smart cities. By incorporating data analytics
					in SWM, cities can not only improve the performance of their
					fleet operations but also identify illegal activities such
					as unethical dumping by waste collection organizations.
					Moreover, data analytics can help cities identify unexpected
					spikes in waste generation and reassign waste collection
					vehicles to ensure that all dumping sites remain within
					manageable capacities. This approach can prevent operators
					from hurriedly dumping excess waste in unmarked or single
					areas, enabling fair and equitable waste management.
					Additionally, optimization techniques such as the
					Capacitated Vehicle Routing Problem can be utilised to
					reduce fuel and operational expenses in waste management by
					optimizing vehicle routing. This technique also enables
					cities to determine the number of vehicles needed, the types
					of vehicles required, their capacities, and the best routes
					for each vehicle to take. By combining this optimization
					with traffic data, cities can optimize vehicle scheduling
					for even greater efficiency in waste collection and
					transportation.
				</Typography>

				<Divider light />

				<Typography variant="h5">References</Typography>
				<ol type="1">
					<li>
						<a href="https://catalogue.iudx.org.in/dataset/varanasismartcity.gov.in-62d1f729edd3d2a1a090cb1c6c89356296963d55-rs.iudx.org.in-varanasi-swm-vehicle-live">
							“IUDX.” IUDX Catalogue,
							https://catalogue.iudx.org.in/dataset/varanasismartcity.gov.in-62d1f729edd3d2a1a090cb1c6c89356296963d55-rs.iudx.org.in-varanasi-swm-vehicle-live.
						</a>
					</li>
					<li>
						<a href="https://nnvns.org.in:449/nnvns/images/SWM-plan-24th-march-2019.pdf">
							“Varanasi Nager Nigam.” https://nnvns.org.in/,
							https://nnvns.org.in:449/nnvns/images/SWM-plan-24th-march-2019.pdf.
						</a>
					</li>
					<li>
						<a href="https://dataspace.mobi/dataset/dustbin">
							“Dustbins in Varanasi City - Datasets.” DataSpace:
							Mobility, 3 December 2021,
							https://dataspace.mobi/dataset/dustbin. Accessed 2
							May 2023.
						</a>
					</li>
				</ol>
			</Container>
		</div>
	);
};

export default VaranasiSWM;
