import L, { LatLngExpression } from "leaflet";
import { useEffect } from "react";

import ambulanceAssignmentData from "../../../../shared/Data/predictiveAmbulance/ambulance_assignment_data.json";
import { AmbulanceIcon } from "../../../../shared/assets/icons/AmbulanceIcon";
import { AccidentIcon } from "../../../../shared/assets/icons/AccidentIcon";
import {
	ambulanceDataValue,
	hospitalDataValue,
} from "../../../../../store/ambulanceAndHospitalData/ambulanceAndHospitalDataSlice";
import { useSelector } from "react-redux";

import data from "../../../../shared/Data/predictiveAmbulance/accident_hospital_assignment_data.json";

const AmbulanceAssignment = () => {
	const hospitalData = useSelector(hospitalDataValue);
	const ambulanceData = useSelector(ambulanceDataValue);

	useEffect(() => {
		const map = L.map("ambulanceAssignment").setView(
			[12.955852626662887, 77.5872041000448],
			12
		);
		L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
			maxZoom: 18,
		}).addTo(map);

		if (ambulanceData) {
			ambulanceAssignmentData.ambulance_locations.map((point) =>
				L.marker([point.latitude, point.longitude], {
					icon: AmbulanceIcon,
				}).addTo(map)
			);

			ambulanceAssignmentData.accident_locations.map((point) =>
				L.marker([point.latitude, point.longitude], {
					icon: AccidentIcon,
				}).addTo(map)
			);

			ambulanceAssignmentData.assignments.map((assignment) =>
				L.polyline([
					assignment.accident_location as LatLngExpression,
					assignment.ambulance_location as LatLngExpression,
				]).addTo(map)
			);

			var info = new L.Control({ position: "topright" });

			info.onAdd = function (map: any) {
				var div = L.DomUtil.create("div", "info"); // create a div with a class "info"
				div.innerHTML = `
			<div class="legend">
				<h4>Legend - (Ambulance Assignment Data)</h4>
				<div class="legendList">
					<div class="legendItem">
						<div class="colorBox" id="redBox"></div> <span>Emergency Location</span>
					</div>
					<div class="legendItem">
						<div class="colorBox" id="blueBox"></div> <span>Assigned Path</span>
					</div>
					<div class="legendItem">
						<div class="colorBox" id="greenBox"></div> <span>Ambulance Location</span>
					</div>
				</div>
			</div>
			`;
				return div;
			};

			info.addTo(map);
		}

		if (hospitalData) {
			data.assignments.map((assignment) => {
				L.circleMarker(
					[
						assignment.accident_location.latitude,
						assignment.accident_location.longitude,
					],
					{ color: "red", radius: 5 }
				).addTo(map);
				L.circleMarker(
					[
						assignment.hospital_location.latitude,
						assignment.hospital_location.longitude,
					],
					{ color: "blue", radius: 5 }
				).addTo(map);
				L.polyline(
					[
						[
							assignment.accident_location.latitude,
							assignment.accident_location.longitude,
						],
						[
							assignment.hospital_location.latitude,
							assignment.hospital_location.longitude,
						],
					],
					{
						color: "green",
					}
				).addTo(map);
			});

			var info_2 = new L.Control({ position: "topright" });

			info_2.onAdd = function (map: any) {
				var div = L.DomUtil.create("div", "info"); // create a div with a class "info"
				div.innerHTML = `
			<div class="legend">
				<h4>Legend - (Hospital Assigned Data)</h4>
				<div class="legendList">
					<div class="legendItem">
						<div class="colorBox" id="redBox"></div> <span>Accident Location</span>
					</div>
					<div class="legendItem">
						<div class="colorBox" id="blueBox"></div> <span>Hospital Location</span>
					</div>
					<div class="legendItem">
						<div class="colorBox" id="greenBox"></div> <span>Assigned Path</span>
					</div>
				</div>
			</div>
			`;
				return div;
			};

			info_2.addTo(map);
		}

		return () => {
			map.off();
			map.remove();
		};
	});

	return (
		<div
			id="ambulanceAssignment"
			style={{
				width: "100%",
				height: "800px",
			}}
		/>
	);
};

export default AmbulanceAssignment;
