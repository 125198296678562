import { LineLayer } from "react-map-gl";

export const realDataLayer: LineLayer = {
	id: "realRoute",
	type: "line",
	source: "route",
	layout: {
		"line-join": "round",
		"line-cap": "round",
	},
	paint: {
		"line-color": "red",
		"line-width": 3,
	},
};

export const optimizedDataLayer: LineLayer = {
	id: "optimizedRoute",
	type: "line",
	source: "route",
	layout: {
		"line-join": "round",
		"line-cap": "round",
	},
	paint: {
		"line-color": "blue",
		"line-width": 3,
	},
};
