import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(
	name: string,
	distance: number,
	vehiclesRequired: number,
	wasteVolume: number
) {
	return { name, distance, vehiclesRequired, wasteVolume };
}

const rows = [
	createData("Actual Route", 4049201, 93, 558),
	createData("Optimized Route", 856765, 173, 1040),
];

export default function TableComponent() {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell align="right">Distance (km.)</TableCell>
						<TableCell align="right">Vehicles Required</TableCell>
						<TableCell align="right">
							Volume of Waste (m<sup>3</sup>)
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow
							key={row.name}
							sx={{
								"&:last-child td, &:last-child th": {
									border: 0,
								},
							}}
						>
							<TableCell component="th" scope="row">
								{row.name}
							</TableCell>
							<TableCell align="right">
								{row.distance.toLocaleString()}
							</TableCell>
							<TableCell align="right">
								{row.vehiclesRequired}
							</TableCell>
							<TableCell align="right">
								{row.wasteVolume}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
