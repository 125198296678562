const wardsData: any = {
	type: "FeatureCollection",
	features: [
		{
			geometry: {
				coordinates: [
					[
						[77.56782414486979, 13.127196628487415],
						[77.56862234191918, 13.127049502266171],
						[77.57063788030759, 13.126541195192386],
						[77.57192657472946, 13.125912405836676],
						[77.57174817328199, 13.125221261589894],
						[77.5717704734783, 13.12344653100655],
						[77.5720201479253, 13.122135526760188],
						[77.5720201479253, 13.12022706812166],
						[77.5721985489442, 13.118974087997227],
						[77.5721762488322, 13.116936226748741],
						[77.57259094274504, 13.114630928377114],
						[77.57271136285645, 13.113154968578518],
						[77.57263554280125, 13.111638827489392],
						[77.57235018958922, 13.109587679099516],
						[77.57225652921481, 13.10805371231488],
						[77.5726756828368, 13.108107260999132],
						[77.57325985429236, 13.107928873733],
						[77.57351844539471, 13.108521956936487],
						[77.57406247297135, 13.108343598850334],
						[77.57503456267358, 13.1079511954625],
						[77.5757301316303, 13.107839681337063],
						[77.57624738940386, 13.107728206853128],
						[77.57672450622759, 13.107549865496646],
						[77.57729527732137, 13.10730460680966],
						[77.57730419696323, 13.107331368268543],
						[77.57802213837921, 13.108352505710313],
						[77.57871777641604, 13.109150636617942],
						[77.57924394001921, 13.108731508058872],
						[77.58002430513078, 13.10827666340018],
						[77.58068425335996, 13.107875347425276],
						[77.58098742425807, 13.107558794061108],
						[77.58108107740551, 13.10736256712494],
						[77.58129514145129, 13.107068266733059],
						[77.58183467199116, 13.106421725031662],
						[77.58252582696373, 13.105614577993945],
						[77.58319913935962, 13.104901169587727],
						[77.58410434471855, 13.104022684058908],
						[77.58506305701917, 13.103170966751097],
						[77.58591027420964, 13.102506576924629],
						[77.5865123025881, 13.1020338937771],
						[77.58690464673487, 13.101726245663215],
						[77.58711869975343, 13.101556846682007],
						[77.58733275236655, 13.101373981898885],
						[77.58908074609117, 13.100058576521157],
						[77.59094456674302, 13.09842207470329],
						[77.59229572130566, 13.097195789505],
						[77.59300919574127, 13.09884119595438],
						[77.59359780875718, 13.10024137667149],
						[77.59282636834669, 13.101918039633723],
						[77.59357105368665, 13.102078509818542],
						[77.59424430026597, 13.102337108293607],
						[77.59460995016752, 13.102381712261739],
						[77.5946991328907, 13.102604693914595],
						[77.59473926509314, 13.102818733459591],
						[77.59473926509314, 13.10313087056707],
						[77.59470805115915, 13.103407309181687],
						[77.59472142856049, 13.103657082754957],
						[77.59505140395856, 13.103652564756109],
						[77.59543934677419, 13.103483138494628],
						[77.5957826973495, 13.103456393628813],
						[77.59648723162371, 13.10344744714649],
						[77.59684395616159, 13.103563436160265],
						[77.59723643928318, 13.103657077516933],
						[77.59703132352489, 13.104053867973665],
						[77.59672802081045, 13.104174319862844],
						[77.59628657357574, 13.104192158043164],
						[77.59608591517107, 13.104236709722239],
						[77.59601011079204, 13.104539904512713],
						[77.59597443812547, 13.104673698466598],
						[77.59549285602345, 13.10504385348279],
						[77.59548839692032, 13.105052700791875],
						[77.59532786909011, 13.105329251170051],
						[77.59533232819958, 13.105485280798352],
						[77.59535462374433, 13.106002490543826],
						[77.59530557354007, 13.106582188236603],
						[77.59510045427324, 13.107210935442097],
						[77.59481061119561, 13.107572106345822],
						[77.59461886844299, 13.108093859641192],
						[77.59418195955007, 13.108713680348842],
						[77.59377171656106, 13.109324524868788],
						[77.59338376801549, 13.110020189731072],
						[77.59259003005654, 13.110711327712938],
						[77.59177399027143, 13.111255315481012],
						[77.59112739718388, 13.11180382542113],
						[77.59092672961098, 13.111777064142965],
						[77.59074844557286, 13.11184393279376],
						[77.59065034114151, 13.111942064674572],
						[77.59062804466797, 13.11208920340641],
						[77.5906592597297, 13.112338910166056],
						[77.59048980643371, 13.112552963517143],
						[77.58970942613183, 13.112740248781078],
						[77.58956672743648, 13.112102608791911],
						[77.59042737620794, 13.111487249245332],
						[77.5904630506269, 13.111112688565928],
						[77.5897183447943, 13.110987829103879],
						[77.58946416263787, 13.111549624067136],
						[77.58851886580919, 13.111224141841982],
						[77.58834494991751, 13.111549679162637],
						[77.5883494093027, 13.111558614875795],
						[77.58834049053216, 13.111558612850134],
						[77.5883315717609, 13.111571991293966],
						[77.58823792462034, 13.111745873026353],
						[77.58784549766344, 13.112387961089924],
						[77.5874264002573, 13.113150473722293],
						[77.58724802325567, 13.113556252933378],
						[77.58705626766489, 13.114109174182639],
						[77.58698045722531, 13.114448033379754],
						[77.58690018729266, 13.114974266372714],
						[77.58677978228678, 13.115874939956507],
						[77.58666383660432, 13.116766771982348],
						[77.58654351878694, 13.117707691418314],
						[77.58643649178362, 13.118510272582512],
						[77.58631608628372, 13.119562653088868],
						[77.58628932948852, 13.119767768343426],
						[77.58681545785744, 13.120062117918687],
						[77.58744423794197, 13.120690818627839],
						[77.58792130752269, 13.121703055795203],
						[77.58794360453044, 13.123785470141632],
						[77.58778306597644, 13.125667183703534],
						[77.58777860656892, 13.126666046545079],
						[77.58806400829624, 13.127174302217222],
						[77.58831819360273, 13.12927008779081],
						[77.58788563230114, 13.130300201145566],
						[77.58661478262593, 13.130821885578703],
						[77.58509873313173, 13.131628971237976],
						[77.58324819482033, 13.134812754102013],
						[77.5831456242873, 13.135401352126665],
						[77.58308319000426, 13.136342283932802],
						[77.58297170012733, 13.136623161570153],
						[77.58296278093243, 13.136797052008115],
						[77.5829137253479, 13.137461485158841],
						[77.58286921678663, 13.138143757408073],
						[77.58282016116151, 13.138464808962102],
						[77.58278894393442, 13.138527181712668],
						[77.58265961533043, 13.13903557321197],
						[77.58255704426344, 13.139347715215653],
						[77.58247677118959, 13.139664312093311],
						[77.5824455538677, 13.13974455851228],
						[77.58212454835751, 13.140658669660489],
						[77.581821293064, 13.14127403246635],
						[77.58141555229898, 13.141751169046842],
						[77.58140663298131, 13.141804700136005],
						[77.58111675477461, 13.142321938061352],
						[77.58091606942699, 13.142593899578078],
						[77.58005998283325, 13.143476835567789],
						[77.57949805771193, 13.143557105829181],
						[77.57947129930318, 13.143579373393793],
						[77.57842789131782, 13.143659645101984],
						[77.57804889713077, 13.143664128035365],
						[77.57742907187543, 13.143628455584414],
						[77.57707237349398, 13.143655179212454],
						[77.57695195802279, 13.143664070571916],
						[77.57676910465483, 13.143664130985657],
						[77.57657741874094, 13.143664105782177],
						[77.57620279077098, 13.143579344822028],
						[77.57583716876681, 13.143494677484844],
						[77.57577027056836, 13.143507992254444],
						[77.5725106625996, 13.142816903753296],
						[77.57255526268743, 13.141666393921692],
						[77.57166789253891, 13.140569460200485],
						[77.57119075439888, 13.140061112052033],
						[77.56959885100464, 13.13742578172741],
						[77.56899244737447, 13.136418064219129],
						[77.56890770452608, 13.136275356865097],
						[77.56859112077512, 13.134375768149285],
						[77.56839050066932, 13.133194138244532],
						[77.56825669543174, 13.131437250308318],
						[77.5681318104007, 13.130616767824398],
						[77.56799809231215, 13.129332510332228],
						[77.56789550795537, 13.128248954027082],
						[77.56782414486979, 13.127196628487415],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000000",
			properties: {
				AREA_SQ_KM: 7.695925044680025,
				ASS_CONST1: "Yelahanka",
				ASS_CONST_: "150",
				LAT: 13.121709445639,
				LON: 77.58042209477,
				LOST_AREA_SQ_KM: 0.32795862120693414,
				OBJECTID: 1,
				POP_F: 9224.0,
				POP_M: 10402.0,
				POP_SC: 2630.0,
				POP_ST: 286.0,
				POP_TOTAL: 19626.0,
				RESERVATIO: "General",
				WARD_NAME: "Chowdeswari Ward",
				WARD_NO: 2.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56862234191918, 13.127049502266171],
						[77.56675395555756, 13.127379437976911],
						[77.5661698379953, 13.12733489552765],
						[77.56616091747618, 13.127330402939883],
						[77.56611185460838, 13.127325938353227],
						[77.56609847382258, 13.127330379349168],
						[77.56606279171935, 13.12733045219177],
						[77.56468945948609, 13.12756675520418],
						[77.56441737978156, 13.127615815662272],
						[77.56318221191837, 13.127861005421979],
						[77.5631509892751, 13.127869928378347],
						[77.56313769559803, 13.127869970548044],
						[77.56311539370209, 13.127870004941643],
						[77.56298158223431, 13.127450817517486],
						[77.56298158223431, 13.127419565922587],
						[77.56130945031613, 13.122527963123362],
						[77.56123362262893, 13.12241204076949],
						[77.56126038534785, 13.122398670505907],
						[77.56038193515631, 13.12002642825732],
						[77.55959714907601, 13.116989726004071],
						[77.55824604135726, 13.112495026959415],
						[77.55675226493399, 13.109440475217701],
						[77.55486601501487, 13.104785214346018],
						[77.55465198847855, 13.102823202022474],
						[77.55458953848544, 13.102265788222429],
						[77.55427746293469, 13.100486591784998],
						[77.54947499478594, 13.101110889563433],
						[77.54625547976978, 13.101266974174353],
						[77.54429347880806, 13.101284798233976],
						[77.54176522046417, 13.100678355608121],
						[77.54050767462093, 13.099073115091254],
						[77.5393840417888, 13.096812321441803],
						[77.5381622479624, 13.094582780951416],
						[77.537092041306, 13.091885009142732],
						[77.53691813395966, 13.091546094882277],
						[77.53694044100337, 13.09150153114702],
						[77.53677545625504, 13.091077964147305],
						[77.53671745784814, 13.090957513015345],
						[77.53858581397375, 13.088768125206549],
						[77.54058797742172, 13.086324561262103],
						[77.54110075932779, 13.086012393682081],
						[77.54240728427408, 13.085374732482416],
						[77.54331692677589, 13.084928806210637],
						[77.54349974663238, 13.084915447755865],
						[77.54369148848154, 13.084924356292609],
						[77.5446412706609, 13.08482624804737],
						[77.54503367236316, 13.084790563111719],
						[77.54510951087734, 13.084772769387937],
						[77.54513181631305, 13.084424965887424],
						[77.54514966065845, 13.084170748165826],
						[77.54513181631305, 13.083564352195324],
						[77.54646060007109, 13.08357771601208],
						[77.54650521040739, 13.083626746198687],
						[77.54719194496127, 13.08360892691642],
						[77.54751296198188, 13.083595562759463],
						[77.54749511800782, 13.083466201182992],
						[77.54747727403097, 13.083377053005973],
						[77.54727215556619, 13.08231134370091],
						[77.5471696399302, 13.081990259115784],
						[77.54805693943325, 13.081816342588809],
						[77.5481996904408, 13.081785150278487],
						[77.54844941680234, 13.081687024091451],
						[77.54851624365847, 13.081713835406918],
						[77.54877935215472, 13.08205266149554],
						[77.54883288346295, 13.082106180856542],
						[77.54893985853433, 13.08212847464948],
						[77.54921188790196, 13.082155265111826],
						[77.54938577642952, 13.082164161269418],
						[77.54986737978385, 13.082190970016365],
						[77.55008587623666, 13.082204280719647],
						[77.55019285012725, 13.082257812613534],
						[77.55026868519548, 13.082373773066053],
						[77.55054071137864, 13.082868711547084],
						[77.55068783266329, 13.083078321102793],
						[77.55083941461976, 13.08319425894325],
						[77.55096877955697, 13.083270023659335],
						[77.55112482198953, 13.083377031489608],
						[77.55126310829334, 13.083501901277176],
						[77.55146821954689, 13.083818494051101],
						[77.55183383271702, 13.0842465422597],
						[77.55210585514878, 13.084491834248455],
						[77.55227081763061, 13.084603333513684],
						[77.55236003371918, 13.084634514395141],
						[77.55244024067225, 13.084652398532251],
						[77.55256068219542, 13.084656793920079],
						[77.55268549691425, 13.084679092963052],
						[77.55287276246824, 13.084754915356656],
						[77.55343464474173, 13.085151788281065],
						[77.55377357456221, 13.085361332244107],
						[77.55425069856778, 13.08557095762549],
						[77.5548169472586, 13.08578940817043],
						[77.55540558400426, 13.086048041336312],
						[77.55548587626863, 13.086048065520538],
						[77.55550809220104, 13.086048024827173],
						[77.55554377762664, 13.086043634334567],
						[77.5556463731627, 13.08601238192164],
						[77.55609226470438, 13.085793912419742],
						[77.55703756996674, 13.085410404476715],
						[77.55756820743058, 13.085196400334912],
						[77.55781791317429, 13.085102747868328],
						[77.55801417887905, 13.085031348999907],
						[77.55804540293711, 13.08501802481641],
						[77.5581702116212, 13.0849689523981],
						[77.55855373289172, 13.084808455677672],
						[77.55862055379681, 13.084803939682189],
						[77.55868746212438, 13.084826266760231],
						[77.55879005481661, 13.084964502945354],
						[77.55884358140182, 13.084991270650786],
						[77.55889255995436, 13.084991237092856],
						[77.55894608649115, 13.0849778758845],
						[77.55987361339798, 13.084353578684224],
						[77.56005194608444, 13.08426887838598],
						[77.56008763008022, 13.084277776925374],
						[77.56012331406478, 13.08432240305449],
						[77.56036409320615, 13.084607768299605],
						[77.56097946394414, 13.085138408657208],
						[77.56182668649714, 13.085856295032626],
						[77.56195595144395, 13.086061418272006],
						[77.56215220998529, 13.086271008815867],
						[77.56213882873189, 13.08634233256157],
						[77.56209868496225, 13.086378006941885],
						[77.56212098705826, 13.08640032169272],
						[77.56232607867652, 13.086618800135719],
						[77.56184452821711, 13.086899759030487],
						[77.56107313364272, 13.087359067497385],
						[77.5605379646401, 13.087675646155887],
						[77.56037747466904, 13.087773729276412],
						[77.56006978808374, 13.087952073493451],
						[77.56001626207743, 13.087996675893013],
						[77.56001626207743, 13.088005564914157],
						[77.5591022054125, 13.088888482317662],
						[77.55848682448526, 13.089526165050719],
						[77.5585982510122, 13.093664226173622],
						[77.559298381433, 13.099893535206393],
						[77.56115779489102, 13.098948278123006],
						[77.56502835566498, 13.097713105241443],
						[77.56503718881635, 13.097708615500583],
						[77.56517991845385, 13.097726436973808],
						[77.56547421030677, 13.097739810139851],
						[77.56627242391487, 13.097833462630552],
						[77.56706608421948, 13.09788692979728],
						[77.56792226910088, 13.097971660461635],
						[77.56861788175627, 13.098060831970503],
						[77.56938931241912, 13.098127797390898],
						[77.56978617607297, 13.098123283789322],
						[77.56987983849093, 13.098118864214273],
						[77.57021425904114, 13.098083174529776],
						[77.57264892281468, 13.09856921072769],
						[77.57574797116007, 13.099104286272661],
						[77.57867763852367, 13.100013990373522],
						[77.58407312784607, 13.101431968019293],
						[77.5865123025881, 13.1020338937771],
						[77.58591027420964, 13.102506576924629],
						[77.58506305701917, 13.103170966751097],
						[77.58410434471855, 13.104022684058908],
						[77.58319913935962, 13.104901169587727],
						[77.58252582696373, 13.105614577993945],
						[77.58183467199116, 13.106421725031662],
						[77.58129514145129, 13.107068266733059],
						[77.58108107740551, 13.10736256712494],
						[77.58098742425807, 13.107558794061108],
						[77.58068425335996, 13.107875347425276],
						[77.58002430513078, 13.10827666340018],
						[77.57924394001921, 13.108731508058872],
						[77.57871777641604, 13.109150636617942],
						[77.57802213837921, 13.108352505710313],
						[77.57730419696323, 13.107331368268543],
						[77.57729527732137, 13.10730460680966],
						[77.57672450622759, 13.107549865496646],
						[77.57624738940386, 13.107728206853128],
						[77.5757301316303, 13.107839681337063],
						[77.57503456267358, 13.1079511954625],
						[77.57406247297135, 13.108343598850334],
						[77.57351844539471, 13.108521956936487],
						[77.57325985429236, 13.107928873733],
						[77.5726756828368, 13.108107260999132],
						[77.57225652921481, 13.10805371231488],
						[77.57235018958922, 13.109587679099516],
						[77.57263554280125, 13.111638827489392],
						[77.57271136285645, 13.113154968578518],
						[77.57259094274504, 13.114630928377114],
						[77.5721762488322, 13.116936226748741],
						[77.5721985489442, 13.118974087997227],
						[77.5720201479253, 13.12022706812166],
						[77.5720201479253, 13.122135526760188],
						[77.5717704734783, 13.12344653100655],
						[77.57174817328199, 13.125221261589894],
						[77.57192657472946, 13.125912405836676],
						[77.57063788030759, 13.126541195192386],
						[77.56862234191918, 13.127049502266171],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000001",
			properties: {
				AREA_SQ_KM: 9.926660803506266,
				ASS_CONST1: "Yelahanka",
				ASS_CONST_: "150",
				LAT: 13.1028048689685,
				LON: 77.560037759808,
				LOST_AREA_SQ_KM: 0.614098785476864,
				OBJECTID: 2,
				POP_F: 10891.0,
				POP_M: 13129.0,
				POP_SC: 2921.0,
				POP_ST: 665.0,
				POP_TOTAL: 24020.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Atturu",
				WARD_NO: 3.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5865123025881, 13.1020338937771],
						[77.58407312784607, 13.101431968019293],
						[77.57867763852367, 13.100013990373522],
						[77.57574797116007, 13.099104286272661],
						[77.57264892281468, 13.09856921072769],
						[77.57021425904114, 13.098083174529776],
						[77.56987983849093, 13.098118864214273],
						[77.56978617607297, 13.098123283789322],
						[77.56938931241912, 13.098127797390898],
						[77.56861788175627, 13.098060831970503],
						[77.56792226910088, 13.097971660461635],
						[77.56706608421948, 13.09788692979728],
						[77.56627242391487, 13.097833462630552],
						[77.56547421030677, 13.097739810139851],
						[77.56517991845385, 13.097726436973808],
						[77.56503718881635, 13.097708615500583],
						[77.56502835566498, 13.097713105241443],
						[77.5650505697275, 13.097677418531568],
						[77.56737829947647, 13.097472298128267],
						[77.56909503073767, 13.09741427731618],
						[77.56959885100464, 13.097356361977559],
						[77.56975495524975, 13.097244847877306],
						[77.56976387548583, 13.09722258219554],
						[77.56969251357754, 13.096442233393429],
						[77.56950081567963, 13.094975145103547],
						[77.56931349013925, 13.093311939406341],
						[77.56905934957841, 13.091514928079286],
						[77.56918423358667, 13.091131416099659],
						[77.56896122633245, 13.089263026370885],
						[77.57072262186121, 13.089013372140057],
						[77.5720915083666, 13.088843874034632],
						[77.5732865267195, 13.089000003887636],
						[77.5757301316303, 13.088678950134739],
						[77.57693857851808, 13.088549610401534],
						[77.57703223501781, 13.088589719188509],
						[77.57754056721629, 13.088478226941298],
						[77.58011341192058, 13.087831725221857],
						[77.57987267476967, 13.086913128748002],
						[77.57984145672891, 13.086783793902901],
						[77.57907446960951, 13.085200821477825],
						[77.5798057789575, 13.084968968849486],
						[77.5807822789974, 13.084705868016519],
						[77.58145560177545, 13.084527463560269],
						[77.58206211351062, 13.084380334535117],
						[77.5824410942496, 13.084268847237377],
						[77.58298507891838, 13.084108322924555],
						[77.5834890122278, 13.083983481418601],
						[77.58423813121729, 13.08376050268177],
						[77.58443435112902, 13.08370253765094],
						[77.58446556790176, 13.083689208612299],
						[77.5844744869781, 13.083680234374693],
						[77.58448786559129, 13.083666902474677],
						[77.58449232512866, 13.08364908131519],
						[77.58449232512866, 13.08362672846103],
						[77.58449232512866, 13.083608949746033],
						[77.58435853893077, 13.083203125717889],
						[77.58414894023572, 13.082534280863225],
						[77.58389920511298, 13.081727219890224],
						[77.58372974167979, 13.08112963362916],
						[77.58353360798769, 13.080558890855983],
						[77.58326157357864, 13.079702779818033],
						[77.58302075568673, 13.078873390788383],
						[77.58296724052998, 13.07870388835397],
						[77.58294494254052, 13.078628070013831],
						[77.58284691877785, 13.078280269222246],
						[77.58284691877785, 13.078266892699528],
						[77.58288259558981, 13.078240145046458],
						[77.58291818494736, 13.078222368357133],
						[77.58297170012735, 13.078213401201806],
						[77.5830162960913, 13.078213407906441],
						[77.58304751325561, 13.078213455744313],
						[77.58308764959705, 13.078204529698002],
						[77.5831411646968, 13.078195562494669],
						[77.5834622547634, 13.078097486949266],
						[77.58426488849804, 13.077843272905577],
						[77.58515670679064, 13.077589153861156],
						[77.58627149162156, 13.07726809091944],
						[77.58639189716897, 13.077232421633546],
						[77.58735059006601, 13.076947016428411],
						[77.58850994705203, 13.076599191846412],
						[77.58956672743648, 13.076296008443487],
						[77.58982982801582, 13.076224624651518],
						[77.58982982801582, 13.076220223326207],
						[77.58987888059845, 13.076153302827692],
						[77.58991901451384, 13.076113220771393],
						[77.59002603821851, 13.076073016724118],
						[77.59003941617446, 13.076068573741582],
						[77.5902579225641, 13.076576952124901],
						[77.5904496727211, 13.077045113728728],
						[77.59065480043569, 13.077522297578906],
						[77.59077074202264, 13.077798735253582],
						[77.59119874556833, 13.078824310087832],
						[77.59165804971431, 13.079898967009763],
						[77.59213072970071, 13.081040480076839],
						[77.59255881552842, 13.082039338095004],
						[77.59295122611162, 13.08298020419551],
						[77.59337484964244, 13.083970148521685],
						[77.59344173742308, 13.084121754959247],
						[77.59350416598261, 13.084300073200035],
						[77.59365131887921, 13.084696975824661],
						[77.59493546676644, 13.08755964726926],
						[77.59587187914629, 13.089816019324646],
						[77.5963044098611, 13.091782493248791],
						[77.59611266964562, 13.093994139747988],
						[77.59559541534698, 13.09580902392416],
						[77.59522530952353, 13.0963574663326],
						[77.59444050279939, 13.095171368453432],
						[77.59360672711261, 13.095849124885563],
						[77.59229572130566, 13.097195789505],
						[77.59094456674302, 13.09842207470329],
						[77.58908074609117, 13.100058576521157],
						[77.58733275236655, 13.101373981898885],
						[77.58711869975343, 13.101556846682007],
						[77.58690464673487, 13.101726245663215],
						[77.5865123025881, 13.1020338937771],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000002",
			properties: {
				AREA_SQ_KM: 4.918173524904308,
				ASS_CONST1: "Yelahanka",
				ASS_CONST_: "150",
				LAT: 13.0909866591769,
				LON: 77.5839254307448,
				LOST_AREA_SQ_KM: 0.38050636580894315,
				OBJECTID: 3,
				POP_F: 12325.0,
				POP_M: 13457.0,
				POP_SC: 3687.0,
				POP_ST: 601.0,
				POP_TOTAL: 25782.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Yelahanka Satellite Town",
				WARD_NO: 4.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59229572130566, 13.097195789505],
						[77.59360672711261, 13.095849124885563],
						[77.59444050279939, 13.095171368453432],
						[77.59522530952353, 13.0963574663326],
						[77.59559541534698, 13.09580902392416],
						[77.59611266964562, 13.093994139747988],
						[77.5963044098611, 13.091782493248791],
						[77.59587187914629, 13.089816019324646],
						[77.59493546676644, 13.08755964726926],
						[77.59365131887921, 13.084696975824661],
						[77.59412844967952, 13.084625613362835],
						[77.59415520461796, 13.08462112853169],
						[77.5942041678876, 13.084603307550145],
						[77.59427551432819, 13.084572072573971],
						[77.59449401252206, 13.084514142533555],
						[77.59515396368312, 13.084308978434482],
						[77.59576040188931, 13.084077136916338],
						[77.59602794712109, 13.083992411772618],
						[77.5965987081629, 13.08384971941024],
						[77.5971963079685, 13.083720409896184],
						[77.59738358731497, 13.08368469738366],
						[77.59787853837753, 13.083671325745627],
						[77.59821742253787, 13.083666870784748],
						[77.5983244383771, 13.083662479399695],
						[77.59833781534986, 13.083662437532789],
						[77.59870345199153, 13.08359558355672],
						[77.5994079680337, 13.083430541110236],
						[77.59973347079541, 13.08333688271199],
						[77.59995195842389, 13.083292323770221],
						[77.6004871158098, 13.083238863035328],
						[77.60135659907607, 13.083149648987959],
						[77.60156616583548, 13.083122866989765],
						[77.602252916088, 13.083029279894502],
						[77.60231533977382, 13.083336889219169],
						[77.60241343406746, 13.083796233801129],
						[77.6025873282874, 13.08459888346064],
						[77.60270771643648, 13.085365802331458],
						[77.60274784579094, 13.085544166356181],
						[77.60285039852084, 13.08597223313247],
						[77.602863774957, 13.086021302026623],
						[77.60287269258022, 13.086052547348281],
						[77.60301091565002, 13.086190785954997],
						[77.60321156174024, 13.08631116342601],
						[77.60334532560209, 13.086413712230671],
						[77.60366198687016, 13.086507346874717],
						[77.60384033793844, 13.086560874327702],
						[77.6040409825531, 13.086560847055646],
						[77.60417474543122, 13.086525210801897],
						[77.6041435341072, 13.08680162189701],
						[77.60411678153686, 13.086904244664495],
						[77.6040811114332, 13.086988913887373],
						[77.60396518351841, 13.08710482165651],
						[77.60375562121618, 13.087327791940506],
						[77.60357727001394, 13.087510672721454],
						[77.60352376459828, 13.087559693601417],
						[77.60349255309409, 13.087595381104324],
						[77.60346134158125, 13.087644447016588],
						[77.60344350642718, 13.087697959157744],
						[77.6034167536908, 13.08783614304334],
						[77.60336324819897, 13.088201844831874],
						[77.60328736128143, 13.088714575599628],
						[77.60321156174024, 13.088964315887907],
						[77.60314913855042, 13.089138231493205],
						[77.60296186877368, 13.089539571840277],
						[77.60284148089586, 13.089780332487315],
						[77.6026631282481, 13.090101402894323],
						[77.60241343406746, 13.090467004756333],
						[77.60208348019525, 13.09089060202885],
						[77.60171330822114, 13.091390018374812],
						[77.60151265946583, 13.09166210423004],
						[77.60144131760016, 13.091751259783116],
						[77.60137889343068, 13.091840416295097],
						[77.6012897159858, 13.092000952346051],
						[77.60115594968632, 13.0923354084524],
						[77.60099542991757, 13.092736670231787],
						[77.60091071105865, 13.093026551173248],
						[77.60087503994112, 13.093222742519735],
						[77.60084828659558, 13.09341004463061],
						[77.60082153324367, 13.093731050456345],
						[77.60079477988545, 13.094105659494204],
						[77.60073681425418, 13.094399948439275],
						[77.60062534180264, 13.094828022538321],
						[77.60053170485826, 13.095104527335145],
						[77.60043806783612, 13.09533191814226],
						[77.60030430052689, 13.095702029866095],
						[77.60016598671093, 13.096130099797085],
						[77.60013031535794, 13.096237129158848],
						[77.60007680830734, 13.096304018594935],
						[77.60002330123133, 13.09637979875951],
						[77.59988507450092, 13.096589403858966],
						[77.59972901185982, 13.096821276783952],
						[77.59969779930572, 13.09687025818659],
						[77.59968888143152, 13.09689261339014],
						[77.59968442249415, 13.096914926018128],
						[77.59972901185982, 13.09695947193887],
						[77.59978251907538, 13.096977346743424],
						[77.59992074593144, 13.097004080133873],
						[77.60009018507236, 13.097012992774031],
						[77.60016598671093, 13.097012959355887],
						[77.60028200595991, 13.097075386560737],
						[77.60046928018548, 13.097262721415763],
						[77.60073235535823, 13.097485672723762],
						[77.60090625216957, 13.097570416659837],
						[77.60101772434355, 13.0976684458407],
						[77.60124958611262, 13.097668475022303],
						[77.60152603606062, 13.097650641448308],
						[77.60160183673446, 13.097637228220327],
						[77.60178019106027, 13.097610491274065],
						[77.60200767984503, 13.097628348202228],
						[77.6023242574404, 13.097623854739593],
						[77.60242235172628, 13.097641734598923],
						[77.60253828122687, 13.09781119336894],
						[77.60254274005143, 13.09802522310905],
						[77.6024357282132, 13.098216926071489],
						[77.60236884576274, 13.09827937023303],
						[77.60228412793523, 13.098359594063131],
						[77.60217711585166, 13.098622683722377],
						[77.60220832771985, 13.098841206672711],
						[77.6023242574404, 13.098957149035533],
						[77.602373304594, 13.099006228173439],
						[77.60219495120597, 13.099353990523086],
						[77.60170439050626, 13.099313871264993],
						[77.60133876358921, 13.099354049291524],
						[77.59950606485526, 13.099822213994704],
						[77.59935446064054, 13.099840060979824],
						[77.5995149827439, 13.10044199503477],
						[77.59972455292404, 13.10121791972366],
						[77.59984048519692, 13.101641515037063],
						[77.59999654768383, 13.102207851110506],
						[77.6000946439937, 13.10262695912372],
						[77.60014815103585, 13.102863317523925],
						[77.60042023220407, 13.102876698189224],
						[77.60046036237226, 13.102907909602923],
						[77.60120053847044, 13.103072892143254],
						[77.6013967289112, 13.10312195133577],
						[77.60231088094027, 13.102921338308766],
						[77.60310455053654, 13.1021855415875],
						[77.6032026441438, 13.10219004314831],
						[77.6034791767325, 13.102176616804858],
						[77.60379575019782, 13.102158831647849],
						[77.60405881761268, 13.102145441734715],
						[77.60475892889954, 13.102109752087467],
						[77.60658717104427, 13.102016056990045],
						[77.60795160157008, 13.102051777770178],
						[77.60827270849998, 13.102060707719849],
						[77.60850455535252, 13.102069623607553],
						[77.60850455535252, 13.102207828505634],
						[77.6085000967637, 13.102372836775201],
						[77.60850455535252, 13.102368391548222],
						[77.6085000967637, 13.102430760378653],
						[77.6085000967637, 13.10246649865169],
						[77.6084956381747, 13.10304612437936],
						[77.60846888663703, 13.103117512075332],
						[77.60844659368409, 13.10360356242283],
						[77.60837079761119, 13.104486447581543],
						[77.60835296323367, 13.104575620107504],
						[77.60863385435161, 13.104584500216271],
						[77.60890136905125, 13.104589019029648],
						[77.60887015903566, 13.10503933583674],
						[77.60877207035904, 13.105592332137636],
						[77.60875423604502, 13.105989174422858],
						[77.60872302598871, 13.106720405392858],
						[77.608696274505, 13.107001398443138],
						[77.60870073308604, 13.107224340378052],
						[77.60868735734233, 13.107447280582079],
						[77.60874086030765, 13.107451776088771],
						[77.6092447660749, 13.107527507066015],
						[77.60942310831189, 13.107710371439946],
						[77.60980217205075, 13.108102740455482],
						[77.60989580129728, 13.108227626073672],
						[77.60989580129728, 13.10831231471721],
						[77.60986013302205, 13.108602203357524],
						[77.60973083542976, 13.110020194796203],
						[77.60983338180823, 13.11002914262485],
						[77.60982892327198, 13.110229730328973],
						[77.60976650374589, 13.11040816739017],
						[77.60973975250984, 13.110474983794752],
						[77.60973975250984, 13.11053740071924],
						[77.60982000619893, 13.110582043633205],
						[77.61002064017164, 13.110666715789417],
						[77.61007860103058, 13.110818362899295],
						[77.6102747760243, 13.111139452012692],
						[77.61045766256468, 13.111522919174078],
						[77.61064046138661, 13.111942038590787],
						[77.61084109389861, 13.112490487561692],
						[77.61092134680338, 13.112673349405092],
						[77.61107748137593, 13.113302090453185],
						[77.6111532756285, 13.113681145060228],
						[77.61117110956286, 13.114122569422824],
						[77.61112206623656, 13.115085745837849],
						[77.61102397951989, 13.11544246582499],
						[77.61096147323434, 13.115941898379036],
						[77.61093918077447, 13.116766782989192],
						[77.61106410591431, 13.117556084567987],
						[77.61123798679162, 13.118648565591702],
						[77.61127811310982, 13.119237173409486],
						[77.61131823941373, 13.120026432967459],
						[77.61141632587425, 13.120695307794339],
						[77.61147874448648, 13.121261610981497],
						[77.61141186740062, 13.121645074653713],
						[77.61145645212893, 13.121783310098252],
						[77.61149211989888, 13.12190816181214],
						[77.61157237233999, 13.122202418151224],
						[77.6117998413668, 13.12272416396353],
						[77.61200047181447, 13.123134439000038],
						[77.61226360750275, 13.1236204502089],
						[77.61286112430003, 13.125176674068175],
						[77.61321333864224, 13.126059534772219],
						[77.61351213862812, 13.126764100364086],
						[77.61395806422492, 13.127740634142256],
						[77.6143682337896, 13.12848533215998],
						[77.6145466553357, 13.129078381143332],
						[77.6146358222935, 13.129466344525392],
						[77.61464473898539, 13.129644649314052],
						[77.61493453108774, 13.130732698221573],
						[77.61515307667476, 13.131169694774893],
						[77.61517090997594, 13.131205358891316],
						[77.61511295173678, 13.1312187763679],
						[77.61457786377896, 13.131860845788184],
						[77.61443065076634, 13.131985717915667],
						[77.61393131398404, 13.132400444902993],
						[77.61366818219105, 13.132623387159432],
						[77.61366818219105, 13.132614407029914],
						[77.6131063369332, 13.133042501039812],
						[77.61245086182757, 13.133492897488168],
						[77.61246423712457, 13.133501836915373],
						[77.61232156720797, 13.133595411778499],
						[77.6117998413668, 13.133871926191603],
						[77.6114475351847, 13.134045803562628],
						[77.61098376568981, 13.134050287758729],
						[77.61059141782934, 13.134090450526822],
						[77.61026585898654, 13.134112704137664],
						[77.61009643513503, 13.134117161216352],
						[77.60972637688944, 13.134077066652],
						[77.60843321791023, 13.134090442750935],
						[77.60785351209411, 13.134094896590462],
						[77.6076573328861, 13.13409485619651],
						[77.60653366705675, 13.134108259930187],
						[77.60574885156407, 13.13411715458236],
						[77.60563292536378, 13.134121619609356],
						[77.60555703997353, 13.134121642160398],
						[77.60545003099546, 13.134121619003695],
						[77.60541436131352, 13.134130504943952],
						[77.60533856320195, 13.134143915345541],
						[77.60532072834476, 13.134161785157612],
						[77.60501753534105, 13.134268788427796],
						[77.60480797499437, 13.13441142969751],
						[77.60401868872465, 13.134616624503467],
						[77.60364861052868, 13.134741440860704],
						[77.60346580036932, 13.13474593248916],
						[77.6019184154688, 13.134750358311088],
						[77.60054062266582, 13.13478158957194],
						[77.59987615664153, 13.134790447032449],
						[77.59972455292404, 13.134799389830631],
						[77.59903341574875, 13.134790455238152],
						[77.59778935816621, 13.134808318271755],
						[77.59651398600316, 13.134808331502201],
						[77.59585850188125, 13.134799396095012],
						[77.59579607462352, 13.13480386956616],
						[77.59571135186137, 13.134808293965763],
						[77.59568013819613, 13.134826202164952],
						[77.595136127216, 13.13478159065832],
						[77.59432902412905, 13.134817250065916],
						[77.59317418606278, 13.13479936825992],
						[77.5923313951345, 13.134808330013048],
						[77.5916179164168, 13.134812792017103],
						[77.5912567160975, 13.135120459972907],
						[77.59099361884155, 13.135098151358541],
						[77.5906191260773, 13.135098134326643],
						[77.59013306182177, 13.135120486049075],
						[77.5898030720527, 13.135124879760225],
						[77.58943740658812, 13.135080306095142],
						[77.58901831508672, 13.135022337269286],
						[77.58862143146597, 13.134986689714896],
						[77.58817549315019, 13.134973346373844],
						[77.58756910165586, 13.134977744505116],
						[77.58703397048205, 13.134982244735015],
						[77.58646324854394, 13.134986650589568],
						[77.58582108454752, 13.134959945504432],
						[77.58521022091092, 13.134933209474124],
						[77.58485345963044, 13.134866269304723],
						[77.58431840304054, 13.13483510824769],
						[77.58362725902623, 13.13482612897199],
						[77.58324819482033, 13.134812754102013],
						[77.58509873313173, 13.131628971237976],
						[77.58661478262593, 13.130821885578703],
						[77.58788563230114, 13.130300201145566],
						[77.58831819360273, 13.12927008779081],
						[77.58806400829624, 13.127174302217222],
						[77.58777860656892, 13.126666046545079],
						[77.58778306597644, 13.125667183703534],
						[77.58794360453044, 13.123785470141632],
						[77.58792130752269, 13.121703055795203],
						[77.58744423794197, 13.120690818627839],
						[77.58681545785744, 13.120062117918687],
						[77.58628932948852, 13.119767768343426],
						[77.58631608628372, 13.119562653088868],
						[77.58643649178362, 13.118510272582512],
						[77.58654351878694, 13.117707691418314],
						[77.58666383660432, 13.116766771982348],
						[77.58677978228678, 13.115874939956507],
						[77.58690018729266, 13.114974266372714],
						[77.58698045722531, 13.114448033379754],
						[77.58705626766489, 13.114109174182639],
						[77.58724802325567, 13.113556252933378],
						[77.5874264002573, 13.113150473722293],
						[77.58784549766344, 13.112387961089924],
						[77.58823792462034, 13.111745873026353],
						[77.5883315717609, 13.111571991293966],
						[77.58834049053216, 13.111558612850134],
						[77.5883494093027, 13.111558614875795],
						[77.58834494991751, 13.111549679162637],
						[77.58851886580919, 13.111224141841982],
						[77.58946416263787, 13.111549624067136],
						[77.5897183447943, 13.110987829103879],
						[77.5904630506269, 13.111112688565928],
						[77.59042737620794, 13.111487249245332],
						[77.58956672743648, 13.112102608791911],
						[77.58970942613183, 13.112740248781078],
						[77.59048980643371, 13.112552963517143],
						[77.5906592597297, 13.112338910166056],
						[77.59062804466797, 13.11208920340641],
						[77.59065034114151, 13.111942064674572],
						[77.59074844557286, 13.11184393279376],
						[77.59092672961098, 13.111777064142965],
						[77.59112739718388, 13.11180382542113],
						[77.59177399027143, 13.111255315481012],
						[77.59259003005654, 13.110711327712938],
						[77.59338376801549, 13.110020189731072],
						[77.59377171656106, 13.109324524868788],
						[77.59418195955007, 13.108713680348842],
						[77.59461886844299, 13.108093859641192],
						[77.59481061119561, 13.107572106345822],
						[77.59510045427324, 13.107210935442097],
						[77.59530557354007, 13.106582188236603],
						[77.59535462374433, 13.106002490543826],
						[77.59533232819958, 13.105485280798352],
						[77.59532786909011, 13.105329251170051],
						[77.59548839692032, 13.105052700791875],
						[77.59549285602345, 13.10504385348279],
						[77.59597443812547, 13.104673698466598],
						[77.59601011079204, 13.104539904512713],
						[77.59608591517107, 13.104236709722239],
						[77.59628657357574, 13.104192158043164],
						[77.59672802081045, 13.104174319862844],
						[77.59703132352489, 13.104053867973665],
						[77.59723643928318, 13.103657077516933],
						[77.59684395616159, 13.103563436160265],
						[77.59648723162371, 13.10344744714649],
						[77.5957826973495, 13.103456393628813],
						[77.59543934677419, 13.103483138494628],
						[77.59505140395856, 13.103652564756109],
						[77.59472142856049, 13.103657082754957],
						[77.59470805115915, 13.103407309181687],
						[77.59473926509314, 13.10313087056707],
						[77.59473926509314, 13.102818733459591],
						[77.5946991328907, 13.102604693914595],
						[77.59460995016752, 13.102381712261739],
						[77.59424430026597, 13.102337108293607],
						[77.59357105368665, 13.102078509818542],
						[77.59282636834669, 13.101918039633723],
						[77.59359780875718, 13.10024137667149],
						[77.59300919574127, 13.09884119595438],
						[77.59229572130566, 13.097195789505],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b9",
			properties: {
				AREA_SQ_KM: 10.26633869981394,
				ASS_CONST1: "Yelahanka",
				ASS_CONST_: "150",
				LAT: 13.116188361395,
				LON: 77.5997134995341,
				LOST_AREA_SQ_KM: 0.5281714583877264,
				OBJECTID: 186,
				POP_F: 10376.0,
				POP_M: 11490.0,
				POP_SC: 1912.0,
				POP_ST: 430.0,
				POP_TOTAL: 21866.0,
				RESERVATIO: "Backward Category - B",
				WARD_NAME: "Kempegowda Ward",
				WARD_NO: 1.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.66224114042247, 13.013279961178231],
						[77.66224559687922, 13.013074843659668],
						[77.66227687945299, 13.012557582655575],
						[77.66229470527324, 13.012388134750111],
						[77.66234372626428, 13.011844120440168],
						[77.66237046497764, 13.011260044265503],
						[77.66242848621603, 13.010559969445909],
						[77.66243294266532, 13.010515321911349],
						[77.66247750714852, 13.010011476899981],
						[77.66249533293683, 13.009592277174129],
						[77.66250870227621, 13.009329187959562],
						[77.66253098450495, 13.009003721772649],
						[77.66255335411053, 13.008691546281941],
						[77.66258900566018, 13.008303659518429],
						[77.66264693940415, 13.007844376008284],
						[77.66275398131079, 13.00727357375888],
						[77.66285211025041, 13.006627033439656],
						[77.66299925980817, 13.005748541824438],
						[77.66312849610708, 13.005003861042246],
						[77.66312849610708, 13.00498160391114],
						[77.66317760411394, 13.00452673959792],
						[77.66318206053343, 13.004473294847704],
						[77.66323108113608, 13.003683970419143],
						[77.66326227605386, 13.003099887058555],
						[77.66327564530167, 13.003064209851136],
						[77.66338268660738, 13.00154364074549],
						[77.66344516373033, 13.000250503708466],
						[77.6635610303025, 12.9987076184708],
						[77.66360122531461, 12.998346462615217],
						[77.66374391749106, 12.998029901322024],
						[77.66385532736159, 12.997811388592956],
						[77.66396236811318, 12.997646408390438],
						[77.66396682450143, 12.997632992446556],
						[77.6640382140695, 12.997521511866083],
						[77.66442163731124, 12.997735531760714],
						[77.66550522903206, 12.998346448244307],
						[77.66662891681212, 12.99889942091299],
						[77.66709717497658, 12.99909559644158],
						[77.66783734916936, 12.999358630793141],
						[77.6688361527897, 12.999742147445929],
						[77.66956303436214, 12.999969606121688],
						[77.66969680661937, 13.000000773738357],
						[77.67035229710565, 13.000165804979163],
						[77.67111481766352, 13.000339672307998],
						[77.67237667423116, 13.000535905510956],
						[77.67532418360258, 13.000758864632695],
						[77.67711231016389, 13.000740972629254],
						[77.67707212000516, 13.00159267787721],
						[77.67699637025366, 13.002141173829248],
						[77.67698745851482, 13.002368584977624],
						[77.67698300264512, 13.002542463865051],
						[77.6769027095903, 13.002707448553785],
						[77.67688934197055, 13.00296164982815],
						[77.67689379784399, 13.003140011039944],
						[77.6769027095903, 13.003220237809462],
						[77.67699637025366, 13.003362928622328],
						[77.67706320827236, 13.003469999352458],
						[77.6771033984343, 13.003554657642015],
						[77.6771345894848, 13.00364388869594],
						[77.67715241293834, 13.003773199367547],
						[77.67716578052661, 13.004031789345223],
						[77.67715686880126, 13.00437515258148],
						[77.67715241293834, 13.004642725135945],
						[77.67712567775698, 13.004990498957975],
						[77.67649241904324, 13.005106482159558],
						[77.67601983166443, 13.005235767895305],
						[77.67566309633007, 13.005266990503218],
						[77.67565418448496, 13.00541417406087],
						[77.67515477053936, 13.005405173450962],
						[77.67507447617169, 13.005596990289026],
						[77.67490506273016, 13.00623013123654],
						[77.6748247681832, 13.006421862703098],
						[77.67476675337127, 13.006528907367786],
						[77.6746820901354, 13.006618110982378],
						[77.67446357306635, 13.006823234925983],
						[77.67442346932756, 13.006845469542398],
						[77.67443683724208, 13.00688562277875],
						[77.67451704469568, 13.007019392166418],
						[77.67460625139292, 13.007224508987994],
						[77.67460625139292, 13.007295856425989],
						[77.67454386787264, 13.007492070802162],
						[77.67451258872754, 13.00755898627312],
						[77.6744903088842, 13.007616929260392],
						[77.6744903088842, 13.007772996907656],
						[77.67457060367131, 13.008027208954429],
						[77.67478466457395, 13.008691591870868],
						[77.67507893211743, 13.00853996150345],
						[77.67536874295254, 13.008375003556559],
						[77.6758369645387, 13.008107387207573],
						[77.67598855293215, 13.008290217358745],
						[77.67603319938812, 13.008352661928946],
						[77.67605547892404, 13.008383925273014],
						[77.67607775845553, 13.008464192168855],
						[77.6760866702669, 13.008535536313945],
						[77.67607775845553, 13.008624661654757],
						[77.6760599348307, 13.008691532710339],
						[77.67604211120302, 13.008794121262852],
						[77.6760822143613, 13.008936842273952],
						[77.67613131668368, 13.009115194626181],
						[77.67618033161385, 13.009235566659417],
						[77.67627844878031, 13.009427311363323],
						[77.67633637543744, 13.009543279429687],
						[77.67643003657523, 13.009788474211494],
						[77.67649696230286, 13.009913328884071],
						[77.67667082921486, 13.01038599360835],
						[77.6767287556687, 13.010653548989314],
						[77.67675557831514, 13.010751635626546],
						[77.67676449007247, 13.010796235848701],
						[77.67677340182908, 13.010831949685496],
						[77.67679568121753, 13.01088098666054],
						[77.67682687235389, 13.01090327382602],
						[77.67687597434923, 13.01088543826319],
						[77.67691607720842, 13.010840857325098],
						[77.67695618005324, 13.01079179004476],
						[77.6769740035352, 13.010742776741056],
						[77.6769472683112, 13.010582230688808],
						[77.67693835656843, 13.01047076696926],
						[77.67692944482496, 13.01034144434102],
						[77.67691162133589, 13.010252244251724],
						[77.67692498895296, 13.01011406778862],
						[77.67692053308079, 13.010007045525798],
						[77.67692944482496, 13.009886601048212],
						[77.67693835656843, 13.009797388252005],
						[77.67697854677525, 13.009650317063839],
						[77.67702756133403, 13.009436201908946],
						[77.67705429653883, 13.009324721774322],
						[77.6771078542992, 13.009226691617368],
						[77.67713904534845, 13.009195487985416],
						[77.67717914811328, 13.009146421113623],
						[77.67722379409382, 13.009115171944684],
						[77.67725944096958, 13.009101781818764],
						[77.67748240842484, 13.009026017438197],
						[77.67758943614018, 13.009048313918843],
						[77.67764736211807, 13.009052728423283],
						[77.67768746470057, 13.009075010599407],
						[77.67772765463809, 13.009119642126098],
						[77.67795507699064, 13.009244507099263],
						[77.67846784586047, 13.009440674692241],
						[77.67888704074569, 13.009556609404484],
						[77.67900297869508, 13.009538783043617],
						[77.67917684170955, 13.009516484460404],
						[77.67924376579278, 13.009485266632115],
						[77.6794354515222, 13.009418440754276],
						[77.67956475620414, 13.009436282234375],
						[77.6796316800551, 13.009467441437284],
						[77.67969851649812, 13.009570032637455],
						[77.67971197114979, 13.009632450527832],
						[77.67973424995256, 13.009677000724256],
						[77.6797387057126, 13.009690414357806],
						[77.67981445360577, 13.009721651254464],
						[77.67992147908825, 13.009784065354715],
						[77.68008206080354, 13.009855429448093],
						[77.68014444123355, 13.009904489056586],
						[77.6802113647374, 13.009949021136201],
						[77.6803050226285, 13.009989139679252],
						[77.68037631440345, 13.010029308404535],
						[77.6804387820365, 13.010078372262475],
						[77.68050561799686, 13.010127386570414],
						[77.68059036418975, 13.010185368822302],
						[77.68070184465078, 13.010274569646489],
						[77.68068402176324, 13.01101923241199],
						[77.68012216251256, 13.01129126507993],
						[77.678846851225, 13.011697007248292],
						[77.67807101590664, 13.011692530791922],
						[77.67695618005324, 13.01148743373511],
						[77.67682687235389, 13.01146510337422],
						[77.67634974312328, 13.011393750628116],
						[77.67572547922597, 13.011398256729715],
						[77.67382593065362, 13.011514185746027],
						[77.67225181729502, 13.011563221499719],
						[77.67086955694869, 13.011630159067447],
						[77.66957640285749, 13.011777282635586],
						[77.66898329448205, 13.011884291820477],
						[77.66838135791534, 13.011982420980571],
						[77.66741374399734, 13.012084923597326],
						[77.66646394687089, 13.01225436237129],
						[77.66639255884955, 13.01226776156864],
						[77.66549186005045, 13.012432735186579],
						[77.6634719021807, 13.012963381990577],
						[77.66265139584476, 13.013150652916657],
						[77.66224114042247, 13.013279961178231],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000003",
			properties: {
				AREA_SQ_KM: 2.059360023832145,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 13.0060632642652,
				LON: 77.6695653043393,
				LOST_AREA_SQ_KM: 0.05588654568101472,
				OBJECTID: 4,
				POP_F: 16969.0,
				POP_M: 18118.0,
				POP_SC: 6454.0,
				POP_ST: 228.0,
				POP_TOTAL: 35087.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Vijnanapura",
				WARD_NO: 51.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.69572902136407, 13.008348206113498],
						[77.6957646623335, 13.008308065766553],
						[77.69582712135784, 13.008285773810154],
						[77.69596086217203, 13.008241180475075],
						[77.6963398955222, 13.008129720125929],
						[77.69638007874723, 13.008120771052],
						[77.69660300802843, 13.008013749768331],
						[77.69686166483746, 13.007862191610545],
						[77.69724960524928, 13.007576786033082],
						[77.69762417917397, 13.007300317530284],
						[77.6980032068718, 13.007113014642574],
						[77.6983286857244, 13.006965858176498],
						[77.6986318885996, 13.006769665096062],
						[77.69886826572301, 13.006595814602335],
						[77.69903764281408, 13.006524451242814],
						[77.69956830944551, 13.006305915947392],
						[77.69997406025253, 13.006172171709444],
						[77.69976895755335, 13.00556126563541],
						[77.69985814408007, 13.005271408509994],
						[77.69984923416582, 13.005231294792134],
						[77.6998135945017, 13.004673963931946],
						[77.69973331786358, 13.003898033514904],
						[77.69957276441396, 13.003304972696473],
						[77.69956385447689, 13.00281889896133],
						[77.69951030748724, 13.002408686665238],
						[77.6993899358571, 13.00170858955185],
						[77.6993899358571, 13.00169521692689],
						[77.69937657092933, 13.00163280522665],
						[77.6992249269309, 13.000803446186767],
						[77.69914919220699, 13.0005001961645],
						[77.69897081794814, 12.999782327886779],
						[77.69914019488343, 12.999777827022719],
						[77.7008792854179, 12.999719879358183],
						[77.70402292691686, 12.999528088395207],
						[77.70432174687546, 12.999510269398002],
						[77.70432174687546, 12.999514755681657],
						[77.70434847554361, 12.999505852127404],
						[77.70449565780082, 12.99947017715525],
						[77.70538293995466, 12.999367619580342],
						[77.70606966598577, 12.999278428269292],
						[77.70639966332843, 13.000749918015666],
						[77.70647548047289, 13.001084322796357],
						[77.7065958444511, 13.001793313563773],
						[77.70676975182711, 13.00229719712274],
						[77.70694811360528, 13.002774377365784],
						[77.70706402240404, 13.003148925165748],
						[77.70716665443489, 13.003773220119564],
						[77.70723347438715, 13.004152242366581],
						[77.70724692571909, 13.004205687665413],
						[77.70724692571909, 13.00424140712291],
						[77.7072201103999, 13.004285940100848],
						[77.7072379290492, 13.004281528387844],
						[77.70760364746194, 13.004241366247141],
						[77.70853108370913, 13.004259184397764],
						[77.7090438867799, 13.004268150489185],
						[77.70964141161743, 13.004268134154993],
						[77.71035929316064, 13.00429043070577],
						[77.7109568109405, 13.004317157581788],
						[77.71154541653513, 13.004361748822937],
						[77.71203147890739, 13.004410846211403],
						[77.71232582292427, 13.004428699379828],
						[77.71266916506572, 13.004504456741437],
						[77.71316858580703, 13.004580246961835],
						[77.71353419740215, 13.004607024103992],
						[77.71394443908343, 13.00459365420738],
						[77.71451521140055, 13.004535662299645],
						[77.71524651188385, 13.004473253879453],
						[77.71554975605885, 13.004455462727154],
						[77.71624978229946, 13.004446519034314],
						[77.71649948491729, 13.004446532940488],
						[77.7172085010486, 13.004486678437239],
						[77.71779261934526, 13.00451335362801],
						[77.71818502843468, 13.004531221885715],
						[77.7182652917748, 13.00470960162883],
						[77.71871123698205, 13.005650487193634],
						[77.7189118506061, 13.006065162509598],
						[77.71894757149644, 13.006127611084374],
						[77.71896093407594, 13.006444218876222],
						[77.71904119685627, 13.006952551417116],
						[77.71913927632316, 13.007590214355593],
						[77.71922399315729, 13.008428459710359],
						[77.71926862230892, 13.00869160302749],
						[77.71932215979984, 13.008829799582873],
						[77.71933997651216, 13.009115219745476],
						[77.71931770562132, 13.009271282603319],
						[77.71929089320957, 13.009391665347346],
						[77.71926862230892, 13.009525466765695],
						[77.71923744304048, 13.00977515650955],
						[77.71921953897483, 13.010020389698681],
						[77.71923744304048, 13.010056017924551],
						[77.71925525976637, 13.010078353906719],
						[77.71937560992822, 13.01009169348615],
						[77.719585218027, 13.010064943569839],
						[77.71983936731701, 13.010047136690332],
						[77.72008906187868, 13.010033776300348],
						[77.72013369068324, 13.010033749889905],
						[77.72032539346587, 13.010399438887351],
						[77.72063307792469, 13.01062233927922],
						[77.72090958273265, 13.01173717314679],
						[77.72357608086699, 13.012245440543644],
						[77.72477559990269, 13.012570973648629],
						[77.72802633185215, 13.01211173652373],
						[77.72796834472021, 13.01249960495805],
						[77.72792371906043, 13.012816232129667],
						[77.72792817289421, 13.0133022612977],
						[77.72799061387838, 13.013846286330923],
						[77.7279995215404, 13.014604351388819],
						[77.7283429026338, 13.0157904330351],
						[77.72992137658866, 13.018537266368275],
						[77.72998381645277, 13.019353323202518],
						[77.72993482517774, 13.01994634076069],
						[77.72899394398613, 13.020606290470344],
						[77.72857030906077, 13.021533774207011],
						[77.72857921667631, 13.022398812696467],
						[77.7283116385802, 13.022889323741277],
						[77.72536867366304, 13.026715219294402],
						[77.72516798435424, 13.028775379207074],
						[77.72529286938881, 13.030237952017774],
						[77.72547120148165, 13.031103030868806],
						[77.7254756554137, 13.031754057884518],
						[77.72543111608512, 13.032730616488815],
						[77.72506100230747, 13.034090607581227],
						[77.72441884586635, 13.03599022257138],
						[77.72387039434398, 13.036378096081423],
						[77.72298753890985, 13.036387057280571],
						[77.7198706337531, 13.034523103909125],
						[77.71642812889236, 13.033149761292693],
						[77.7139890703525, 13.03257895305162],
						[77.71326667044981, 13.03226235698723],
						[77.71253535695946, 13.030969280492332],
						[77.71150978061195, 13.02952000903043],
						[77.71102817047274, 13.028953719630428],
						[77.71144733037593, 13.027602592993409],
						[77.71163013914983, 13.02750001509827],
						[77.71177285730555, 13.026541355259583],
						[77.71189775737739, 13.025698608090456],
						[77.71208501987995, 13.024155717837564],
						[77.71342274975217, 13.022416655213904],
						[77.71320422119926, 13.022287380289644],
						[77.71442158223262, 13.020369986391186],
						[77.71406488233018, 13.02040565986059],
						[77.71395334787047, 13.019919594044245],
						[77.71379726931205, 13.019687687760449],
						[77.71374381650419, 13.019598495623503],
						[77.71364573228183, 13.019442451353667],
						[77.7133870270884, 13.019259658915644],
						[77.71274943238389, 13.01882714938574],
						[77.71206720200612, 13.018381203566474],
						[77.7119066663282, 13.018291984764911],
						[77.71182194392391, 13.018158240319325],
						[77.71172385800833, 13.01798882344729],
						[77.71162568466346, 13.017814915621873],
						[77.71159004876596, 13.017600852775656],
						[77.71155441285707, 13.017431402085023],
						[77.71151868959382, 13.017186185340117],
						[77.71163013914983, 13.01682944283889],
						[77.71181303496633, 13.01658863710751],
						[77.71165249892192, 13.016205175367753],
						[77.71157223081293, 13.016098137348365],
						[77.71136706211904, 13.016138291557926],
						[77.7113046991461, 13.015884124712748],
						[77.71119761642481, 13.015803849051354],
						[77.71117088940002, 13.01570126904732],
						[77.71123779429315, 13.015134965703044],
						[77.71098807987214, 13.01482732209965],
						[77.71074281941299, 13.014742600103673],
						[77.71072054680181, 13.014345735648785],
						[77.71046192215515, 13.014278840807835],
						[77.7098688562767, 13.014145050820835],
						[77.70939169361668, 13.01399341659277],
						[77.70825018087291, 13.013819515569153],
						[77.70784437285052, 13.013882001322207],
						[77.70756792293142, 13.01380174095704],
						[77.7073361062619, 13.013676847368211],
						[77.70704620372692, 13.01332457560919],
						[77.70653784614983, 13.013204150011642],
						[77.70594484672552, 13.013052583541661],
						[77.70567284681752, 13.013253220420873],
						[77.705414210451, 13.013382534194639],
						[77.70260943913077, 13.013395957236588],
						[77.70256925815056, 13.012776068653595],
						[77.70248898349672, 13.011251054652144],
						[77.70248452864482, 13.010345911428667],
						[77.70232406650746, 13.010444020637953],
						[77.70227497570399, 13.010524245298889],
						[77.70223933681534, 13.010573275077896],
						[77.70218133624668, 13.010600096432698],
						[77.70196287299055, 13.010586716340084],
						[77.70185132641276, 13.010559898079643],
						[77.70139657859838, 13.01045290560353],
						[77.70071873361846, 13.010292361689645],
						[77.70025052900937, 13.010131833998676],
						[77.69987150895011, 13.009953483343633],
						[77.69869434601833, 13.009387178492526],
						[77.69819940277267, 13.00913745200353],
						[77.69773564262698, 13.009431745936235],
						[77.69744134742247, 13.009204384921777],
						[77.69729415585195, 13.009302433412902],
						[77.69684375717497, 13.00948977290412],
						[77.6967189275844, 13.009293554413727],
						[77.69585830717926, 13.008392784710342],
						[77.69578257016923, 13.008383898766908],
						[77.69572902136407, 13.008348206113498],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000004",
			properties: {
				AREA_SQ_KM: 6.454555993370297,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 13.016847032689,
				LON: 77.7154564678998,
				LOST_AREA_SQ_KM: 0.4081240124818091,
				OBJECTID: 5,
				POP_F: 10518.0,
				POP_M: 11494.0,
				POP_SC: 4115.0,
				POP_ST: 325.0,
				POP_TOTAL: 22012.0,
				RESERVATIO: "General",
				WARD_NAME: "Basavanapura",
				WARD_NO: 53.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[
							[77.6816828853857, 13.022933899021412],
							[77.6825523516383, 13.023049874005238],
							[77.68674835725463, 13.023081092825603],
							[77.68876387607766, 13.024766588978743],
							[77.69171138999376, 13.027598194270697],
							[77.69339687171279, 13.027406442477504],
							[77.69434224686279, 13.027259302082632],
							[77.69436006757043, 13.027259292879814],
							[77.69295991035709, 13.029965925841696],
							[77.69028001217691, 13.032672596792764],
							[77.68819760204418, 13.034402732370257],
							[77.68526799224897, 13.034853089254844],
							[77.68238740518434, 13.034723784045875],
							[77.68234276126644, 13.033265688451161],
							[77.68227147074604, 13.030648207396768],
							[77.68206633551453, 13.026224722758817],
							[77.68171853083935, 13.023237177431414],
							[77.6816828853857, 13.022933899021412],
						],
					],
					[
						[
							[77.66286102311511, 13.051472221720418],
							[77.66287884884238, 13.050482253865745],
							[77.664488570222, 13.050442153417688],
							[77.66634799592453, 13.049755398464407],
							[77.66734681283546, 13.049657326691564],
							[77.66963433298552, 13.049822300913581],
							[77.67119948359948, 13.050999557255595],
							[77.67173901992156, 13.051779880616682],
							[77.67269331568927, 13.051445418913646],
							[77.67306788460122, 13.051521250303926],
							[77.67474892953747, 13.051757582006411],
							[77.67597964111299, 13.052377378695036],
							[77.67731291126192, 13.053688331604503],
							[77.67796398865235, 13.054143221562423],
							[77.6778569612956, 13.054468721975399],
							[77.6778569612956, 13.054490989381057],
							[77.67786141713046, 13.055012676321747],
							[77.67787032879964, 13.055610272365714],
							[77.67787478463396, 13.055989295594648],
							[77.67787478463396, 13.056368278458086],
							[77.67833853990959, 13.058205410802206],
							[77.67843665518194, 13.059177501263026],
							[77.67864616551086, 13.061237573786038],
							[77.67866853189474, 13.06297216984755],
							[77.67834745154072, 13.064327748636023],
							[77.67670202038603, 13.065375703417642],
							[77.67649241904324, 13.066405723910092],
							[77.67610894979217, 13.066789161932359],
							[77.67406672890071, 13.066918507574922],
							[77.67070004976354, 13.06749817515486],
							[77.66981721025172, 13.067507118514973],
							[77.66914834808605, 13.067351055113328],
							[77.66837244549012, 13.067141500886482],
							[77.66686081800275, 13.065687785925695],
							[77.66683853663565, 13.065669968392799],
							[77.66654870370368, 13.064287616070908],
							[77.6660225112774, 13.063641097959687],
							[77.66531797576519, 13.06262884495755],
							[77.6649567499596, 13.061188550729812],
							[77.66476503941908, 13.059320235640305],
							[77.66438152997344, 13.058789539784957],
							[77.6642343822321, 13.05822327291304],
							[77.66436370448538, 13.057032684786297],
							[77.66437707360171, 13.055561214007508],
							[77.6637260919015, 13.05423687344752],
							[77.66339605584082, 13.05296596792693],
							[77.66301708551343, 13.052377358365087],
							[77.66286102311511, 13.051472221720418],
						],
					],
					[
						[
							[77.64615270076777, 13.0553649769992],
							[77.64626421551742, 13.055017211762973],
							[77.64639801559193, 13.054664911768066],
							[77.64654955643726, 13.05444636867961],
							[77.64671455569471, 13.054263598144539],
							[77.64687064057405, 13.054094119625685],
							[77.64704455348172, 13.053701726220783],
							[77.64718726673675, 13.053336049969873],
							[77.64724520844126, 13.053122059098847],
							[77.647254122547, 13.053104193271352],
							[77.647254122547, 13.053077440649222],
							[77.6472853219115, 13.052016179215581],
							[77.64711586643527, 13.051280438906078],
							[77.64655410091159, 13.0505848303802],
							[77.6466209570991, 13.049648455523936],
							[77.64697769754659, 13.049215866048074],
							[77.64768663061054, 13.049095459372326],
							[77.6482039077154, 13.049050906913633],
							[77.64895303329665, 13.048769936117043],
							[77.64986269154693, 13.048448885248508],
							[77.65176229705706, 13.047690857232432],
							[77.65275224295505, 13.047641780349153],
							[77.65338983060094, 13.047672992780406],
							[77.65398293416489, 13.04766857917211],
							[77.6543441079673, 13.047820156650438],
							[77.6546295157188, 13.048631712713089],
							[77.65499514418316, 13.049407612682476],
							[77.65480341659507, 13.050031898675513],
							[77.65461168867905, 13.050736445089768],
							[77.65461614543925, 13.051084272531957],
							[77.65474093465299, 13.051552414349969],
							[77.65446898487144, 13.052136600850691],
							[77.65443333073804, 13.052644917918839],
							[77.65443778750534, 13.052876762850005],
							[77.65479895984231, 13.053300458547287],
							[77.65483907061075, 13.053572425919837],
							[77.65468745357839, 13.05400051442872],
							[77.65449126869905, 13.054276983287073],
							[77.65456257691764, 13.054477616610038],
							[77.65469636709261, 13.054482074870466],
							[77.65473647789774, 13.054682759638691],
							[77.65474539140806, 13.054883409465033],
							[77.65475430491765, 13.055177697547853],
							[77.65480787334765, 13.055686009815608],
							[77.65483907061075, 13.05598033758318],
							[77.65464734275571, 13.055971448478306],
							[77.65451800928633, 13.055949166911487],
							[77.65450909575794, 13.055966947477108],
							[77.65451800928633, 13.056011563816586],
							[77.65455366339279, 13.05631473716791],
							[77.65455366339279, 13.056350466625618],
							[77.65460277515811, 13.056577824428308],
							[77.65467408330576, 13.056774060683528],
							[77.6547141941189, 13.056934618442325],
							[77.654718650875, 13.057077234000277],
							[77.65465625627311, 13.05719324132523],
							[77.65457149044177, 13.057211079427184],
							[77.65452692281401, 13.05723784439511],
							[77.65453583634097, 13.057420633585505],
							[77.6545402931042, 13.057491962636908],
							[77.6545402931042, 13.057585602377864],
							[77.65453137957758, 13.057665867224358],
							[77.65451355252223, 13.057741646459524],
							[77.65449126869905, 13.057813068449896],
							[77.65446007133914, 13.057844274893489],
							[77.65437530535947, 13.05787548997516],
							[77.65431736733855, 13.058107360056706],
							[77.65436193504961, 13.058343650729766],
							[77.6544154162795, 13.05841501176822],
							[77.65446007133914, 13.058450649108376],
							[77.65450463899349, 13.058477392877872],
							[77.65454920663011, 13.058508624491289],
							[77.65458486072663, 13.058602256518904],
							[77.65461168867905, 13.058695894972562],
							[77.6546339724783, 13.058722687285726],
							[77.65466071303153, 13.05873605814357],
							[77.6547052806061, 13.05874053534439],
							[77.65482124360433, 13.058740511823189],
							[77.6548479841129, 13.05877174777837],
							[77.6548479841129, 13.0588297446736],
							[77.65478113282951, 13.058909981108206],
							[77.65474984816294, 13.059030336672365],
							[77.65477667607587, 13.059155217980965],
							[77.65480787334765, 13.059306805642365],
							[77.65481678685228, 13.059360312797038],
							[77.65497731720157, 13.06231670501873],
							[77.65515121753828, 13.065397931086778],
							[77.655164587754, 13.06551837347002],
							[77.65520915512823, 13.06600886359699],
							[77.65528946374413, 13.067186041371064],
							[77.6553875118348, 13.06849260501368],
							[77.6554410799622, 13.069531540850145],
							[77.65548119050088, 13.070262820211811],
							[77.65549901740233, 13.070976284991582],
							[77.65545890687005, 13.07102536269006],
							[77.65544999341648, 13.07105212183662],
							[77.65544999341648, 13.071065500496914],
							[77.65546336359657, 13.071065454595722],
							[77.65570411395201, 13.071061007257562],
							[77.65564171989864, 13.072670786884185],
							[77.65532957433705, 13.073451131822072],
							[77.65525372248474, 13.073803353949973],
							[77.65553912792025, 13.074151160045075],
							[77.65566400349311, 13.074770978188345],
							[77.65567737364766, 13.075123277639838],
							[77.65568628708316, 13.07540415156216],
							[77.65525372248474, 13.075729680809866],
							[77.65476776256806, 13.07676423646732],
							[77.65407652661688, 13.079372762080498],
							[77.65361284559924, 13.082467426830133],
							[77.65361730239916, 13.083011383594135],
							[77.65362175919888, 13.08317189893676],
							[77.65345677010235, 13.083390445861077],
							[77.65251585571492, 13.083399329512476],
							[77.65013919682943, 13.08326112572216],
							[77.6488147792786, 13.082917764323822],
							[77.64886835054601, 13.08250307673782],
							[77.64889954946158, 13.082213251622468],
							[77.64902443242887, 13.081183182163658],
							[77.64931422329953, 13.078534498037275],
							[77.64992963315383, 13.077009450443866],
							[77.65021050781574, 13.076643814487326],
							[77.65064754878915, 13.076518941059001],
							[77.65072331636718, 13.076398559911153],
							[77.65078571315772, 13.075992775899278],
							[77.65084819730392, 13.075867956260634],
							[77.65091059402496, 13.075685128038494],
							[77.6509195078394, 13.075520090248622],
							[77.65082145584141, 13.075359559410249],
							[77.65070103179073, 13.07504741501303],
							[77.65042452789332, 13.074414244831663],
							[77.65021496475087, 13.073660705820283],
							[77.65019713700926, 13.072336307365397],
							[77.65023724942391, 13.07209552898807],
							[77.65039332940194, 13.072010788827654],
							[77.65042007096652, 13.071948379528823],
							[77.65045126945049, 13.071435544704817],
							[77.65046027069214, 13.071132330448766],
							[77.65046472761736, 13.07104761376347],
							[77.65046472761736, 13.070570478513023],
							[77.65066537645917, 13.070561598768807],
							[77.65093287855977, 13.069941745708213],
							[77.65092842165316, 13.069384388441394],
							[77.65096407690108, 13.06890725236559],
							[77.65101319023871, 13.068840399184062],
							[77.65099981952793, 13.068389987755086],
							[77.65096407690108, 13.068332075856745],
							[77.65086156803277, 13.067930708057201],
							[77.65085265421371, 13.067315404008644],
							[77.6509373354662, 13.066735687408563],
							[77.65092842165316, 13.066490397459821],
							[77.65085265421371, 13.065406845648646],
							[77.65099090571985, 13.06541135323198],
							[77.65099981952793, 13.065134905028259],
							[77.65096407690108, 13.065112554144113],
							[77.6509373354662, 13.065094780018706],
							[77.6509195078394, 13.065076917782635],
							[77.65089276639391, 13.06506354539104],
							[77.65086602494203, 13.065036795199983],
							[77.65082145584141, 13.064978805002905],
							[77.65079017007, 13.064524006277829],
							[77.65075451476677, 13.064029038290409],
							[77.65073223019652, 13.062874123081833],
							[77.65072777328194, 13.062486132517595],
							[77.65076342859365, 13.062084886772393],
							[77.65082145584141, 13.062035813681437],
							[77.65083482657344, 13.061870836670945],
							[77.65081254201915, 13.061643381947434],
							[77.65080799771734, 13.061572046869877],
							[77.65087493876003, 13.060818459506722],
							[77.65090168020978, 13.06050188620378],
							[77.65092842165316, 13.060158472871942],
							[77.65093287855977, 13.060118383300017],
							[77.65094179237246, 13.060033630039866],
							[77.65092396474638, 13.059846352713613],
							[77.65090613711746, 13.059819602171695],
							[77.65086156803277, 13.059810722522759],
							[77.65057169376934, 13.05985526708215],
							[77.65037995861725, 13.059882015551798],
							[77.65005442758329, 13.05985976736378],
							[77.65004551370008, 13.059859769273416],
							[77.6493053093575, 13.059957864983373],
							[77.64876129537761, 13.059984634114542],
							[77.64842238862796, 13.05995336747636],
							[77.64842238862796, 13.059730444296237],
							[77.64837781855636, 13.05878957225809],
							[77.6483644475314, 13.058299071822745],
							[77.6483822755643, 13.058192052569188],
							[77.64779368680448, 13.05810732817893],
							[77.64743694895147, 13.057295808414425],
							[77.64721400906564, 13.056907838530876],
							[77.64690184004516, 13.05656450243424],
							[77.64660758586479, 13.056207791372168],
							[77.64636681598071, 13.05587333596686],
							[77.64626867260934, 13.055717234682804],
							[77.64615270076777, 13.0553649769992],
						],
					],
					[
						[
							[77.70606966598577, 12.999278428269292],
							[77.70754119503557, 12.998921646853633],
							[77.70857126253948, 12.998649720418328],
							[77.7085355383193, 12.998471323164033],
							[77.70849990143297, 12.99830190297036],
							[77.7084909922096, 12.998185955535657],
							[77.70848653759765, 12.99809670726732],
							[77.70847317376074, 12.998056641602329],
							[77.70845972257688, 12.998011999225191],
							[77.70845972257688, 12.997962995224544],
							[77.7084641771899, 12.997927317687598],
							[77.70847762837323, 12.997896124643352],
							[77.70849544682137, 12.997864881312228],
							[77.70851326526667, 12.997802406601336],
							[77.70850435604439, 12.99770880474812],
							[77.70847762837323, 12.997619614915381],
							[77.708432994895, 12.997557177978177],
							[77.70837945216661, 12.997512605829675],
							[77.70829926904399, 12.997481428288701],
							[77.7082546354943, 12.99747248144439],
							[77.70821899851809, 12.997441274055088],
							[77.70811191282985, 12.997334271117236],
							[77.70800491438436, 12.99725841737287],
							[77.70783100893655, 12.997106812609868],
							[77.70772401022126, 12.996981955526111],
							[77.70776410199683, 12.996941854560006],
							[77.70789791583611, 12.996808049199812],
							[77.7078890065646, 12.996790196529211],
							[77.70775073807324, 12.996665399750254],
							[77.70766601250732, 12.996589574774122],
							[77.70761701140317, 12.996540561169798],
							[77.70754564968533, 12.996500401349882],
							[77.70740738076779, 12.996451352802415],
							[77.70725583504195, 12.99639338806057],
							[77.7071175657647, 12.996348739389699],
							[77.70700611169298, 12.99633539412993],
							[77.70680093458756, 12.996335432051145],
							[77.70664493335119, 12.996322030047946],
							[77.70654684287625, 12.996286374134163],
							[77.70629711793806, 12.996210521181062],
							[77.70612766453075, 12.996170422205605],
							[77.70581103054033, 12.996112424451008],
							[77.70561930276256, 12.99608126919311],
							[77.70542757465486, 12.99605891360101],
							[77.70524030095918, 12.996023225845594],
							[77.70514220924764, 12.995746783812262],
							[77.70504411744976, 12.99544359825173],
							[77.70496384457851, 12.995260750841142],
							[77.70481666293968, 12.994119254783312],
							[77.70478547962388, 12.993022303340137],
							[77.70509757443314, 12.992986650486875],
							[77.70613211923704, 12.992928636940947],
							[77.70740738076779, 12.992870721901413],
							[77.70882989157667, 12.99282612576641],
							[77.71003830399283, 12.992817199373263],
							[77.71063582349502, 12.992763671419143],
							[77.71085872426282, 12.992710122825775],
							[77.71114861687445, 12.992643293018958],
							[77.71164804443643, 12.99252287938972],
							[77.71137151661577, 12.991680100959826],
							[77.71114861687445, 12.99089527389303],
							[77.71110843897394, 12.990743678195466],
							[77.71137151661577, 12.990690147660336],
							[77.71155441285707, 12.990645559158223],
							[77.71188884842586, 12.990560838233954],
							[77.71306604664908, 12.990293313694503],
							[77.71354756063168, 12.99015064471331],
							[77.71449293954406, 12.990948797539234],
							[77.71535350337057, 12.991755937948595],
							[77.71535795770778, 12.991755891606978],
							[77.71578609728822, 12.992148319251044],
							[77.71608034403754, 12.992197381276188],
							[77.71626314520296, 12.992197387144214],
							[77.7163835859405, 12.992192865516888],
							[77.71652629800174, 12.992206315522644],
							[77.71652629800174, 12.99220182935139],
							[77.71653520658212, 12.99220182290406],
							[77.71715050827525, 12.992188465417184],
							[77.717886333015, 12.992166187916032],
							[77.71813157773427, 12.992161664643492],
							[77.71831437552989, 12.992161668775708],
							[77.71862206555106, 12.99217504824328],
							[77.7191259137651, 12.992166119336078],
							[77.71927307648939, 12.992148290329249],
							[77.71941578484443, 12.992121535215652],
							[77.71953168066267, 12.99206805084997],
							[77.71953613483262, 12.992059118286686],
							[77.71966548035692, 12.992005585001943],
							[77.72004006624289, 12.991796030303133],
							[77.72057517427235, 12.991461613057156],
							[77.72083822953626, 12.99130557748682],
							[77.72119490772508, 12.991100429580458],
							[77.72119944916378, 12.991104873466677],
							[77.7214357783916, 12.990971115490549],
							[77.72169437753666, 12.990850669643805],
							[77.72195743015341, 12.990748121691835],
							[77.72208231879412, 12.990703519265999],
							[77.7222963758514, 12.990658939968132],
							[77.72242117677706, 12.990632191630667],
							[77.7225237946481, 12.990609907263554],
							[77.72284483513775, 12.990502875313753],
							[77.72294291125048, 12.990471660670222],
							[77.72294736528383, 12.99048955892631],
							[77.7231302424988, 12.990422614452756],
							[77.72374105371014, 12.990248727458711],
							[77.72436985213818, 12.990065946931574],
							[77.72437430611436, 12.990065900382904],
							[77.72438321406618, 12.99005700743924],
							[77.72472651899913, 12.989941025765656],
							[77.72504318651171, 12.989784951227064],
							[77.72532404695946, 12.989637867702287],
							[77.72576105602118, 12.989334651093221],
							[77.72589039430491, 12.989267741007835],
							[77.72614452918431, 12.989196338237537],
							[77.7266796078366, 12.98906257358894],
							[77.72693382824241, 12.989009118619899],
							[77.72698282084052, 12.988995751187073],
							[77.7274242772345, 12.98888426849041],
							[77.72868628266615, 12.988638983255939],
							[77.73050996915879, 12.988300091332466],
							[77.73155344704597, 12.98813513947013],
							[77.7316069786207, 12.988126214442442],
							[77.73159361756123, 12.98790325165497],
							[77.73158016917296, 12.987760519184693],
							[77.73158016917296, 12.987608937290325],
							[77.73158907654718, 12.98742611759167],
							[77.7316069786207, 12.987221036745005],
							[77.73158907654718, 12.987069379498081],
							[77.73158907654718, 12.986859823330878],
							[77.7316069786207, 12.986726004450643],
							[77.73164260810475, 12.98661011177148],
							[77.73166487652648, 12.986511958957582],
							[77.73167387122105, 12.986494183806393],
							[77.73166941753743, 12.986489744392504],
							[77.732137577304, 12.985794079525329],
							[77.73225800084582, 12.985696028129468],
							[77.73252102846138, 12.98566481144027],
							[77.73328356051161, 12.985629078013876],
							[77.7333906219676, 12.985624685021056],
							[77.73342625087949, 12.985620169625294],
							[77.7334886887735, 12.985580092330057],
							[77.73351095682511, 12.985504284026751],
							[77.73350650321515, 12.985285717676373],
							[77.7335154104349, 12.984929062929682],
							[77.7344607942262, 12.984902287521827],
							[77.73603926856647, 12.9849156481182],
							[77.73603926856647, 12.98492013427615],
							[77.73706933410011, 12.984924554629131],
							[77.73705597369785, 12.985076189929012],
							[77.73732353072815, 12.985058296234955],
							[77.73787200315968, 12.985049456781702],
							[77.73792998512111, 12.985071713656735],
							[77.73803695477467, 12.985129689333236],
							[77.73845164676736, 12.98514752497351],
							[77.73848736137693, 12.986338073392327],
							[77.73962437231677, 12.986730462487488],
							[77.74000788493781, 12.98689106533962],
							[77.7401996407513, 12.986949021550636],
							[77.7404047562342, 12.987006923211917],
							[77.74055634410392, 12.987131815444897],
							[77.74058760471029, 12.98721207210452],
							[77.74059651136189, 12.987261155099983],
							[77.74058760471029, 12.987314653272316],
							[77.74059205803617, 12.987399328632664],
							[77.73966453991207, 12.987582181131971],
							[77.7367706021076, 12.987501904373703],
							[77.73345751349375, 12.987867601218229],
							[77.73324793155393, 12.989321264199411],
							[77.73297591107142, 12.990948834751268],
							[77.73274851366241, 12.992536274592945],
							[77.7326191833615, 12.99335224994219],
							[77.73240069253129, 12.993860629256831],
							[77.73178530058077, 12.994788134908585],
							[77.73158907654718, 12.995051166689482],
							[77.73150436913586, 12.995269643079778],
							[77.73143747643343, 12.995421326027408],
							[77.73138403210437, 12.99542576805195],
							[77.73131268563155, 12.995461452730419],
							[77.73132159302723, 12.995635297566025],
							[77.73124579281368, 12.99619267210147],
							[77.73122789068196, 12.99646472062311],
							[77.73598582645194, 12.997490284139575],
							[77.74144368783703, 12.99855602549632],
							[77.74319172200579, 12.998649700008386],
							[77.74409240421738, 12.998636324777712],
							[77.7475616432503, 12.998226032304467],
							[77.75181116469949, 12.997392213376134],
							[77.75201625870584, 12.997374359013657],
							[77.75338520127141, 12.997976352044947],
							[77.75368842862362, 12.998997483825022],
							[77.75374640231601, 13.000054330159283],
							[77.75421455623241, 13.001601580704849],
							[77.75477193794988, 13.001405386910962],
							[77.75590904383454, 13.001173519783766],
							[77.75685441136955, 13.0012492947469],
							[77.7574117798032, 13.001111079275207],
							[77.75717989252495, 13.002346250149781],
							[77.75794688229072, 13.002734196552645],
							[77.75946740087811, 13.002653956012539],
							[77.75963240766822, 13.003331771691595],
							[77.75982412953454, 13.004165584817294],
							[77.76002929600917, 13.005316050065204],
							[77.7599534281103, 13.005356191926335],
							[77.75986428981057, 13.005445321214461],
							[77.75965467047028, 13.005614753820522],
							[77.75937826218706, 13.005828815636121],
							[77.75930239384206, 13.00590459650855],
							[77.75927567839405, 13.005967096576496],
							[77.7592667732433, 13.006029494728185],
							[77.75926232066764, 13.006109794952513],
							[77.75927122581876, 13.006140939184348],
							[77.75928013096917, 13.006203408081763],
							[77.75930239384206, 13.006283684978346],
							[77.75935591202409, 13.006457524580405],
							[77.75941833531954, 13.00672507218298],
							[77.75943623290125, 13.006805400692029],
							[77.75949865615141, 13.007072949187544],
							[77.75949420358509, 13.00713978821301],
							[77.75948521114665, 13.007193294503084],
							[77.75948975101858, 13.007264703564768],
							[77.75944959060668, 13.007550068092334],
							[77.7593916198995, 13.007710579920046],
							[77.759329196589, 13.007790798257243],
							[77.7591864522432, 13.007857702502317],
							[77.75915528418724, 13.007866661226469],
							[77.75905715277175, 13.007871053093396],
							[77.75899027668606, 13.00785328724793],
							[77.75889668502055, 13.007830971037466],
							[77.75885207180139, 13.007839894046255],
							[77.75878519559187, 13.007880032039553],
							[77.75871386674423, 13.007915729695128],
							[77.75861573493751, 13.007964735821636],
							[77.75858447941496, 13.007995954389449],
							[77.75858002681187, 13.008022666551657],
							[77.75853096086007, 13.008071763659759],
							[77.75845072663226, 13.008129733067635],
							[77.7583704923463, 13.008192189788685],
							[77.75834814197991, 13.008218913268632],
							[77.75834368936732, 13.008245625454395],
							[77.7583347841416, 13.008334862318732],
							[77.75829916323154, 13.008397284598875],
							[77.75811634396868, 13.00856669830991],
							[77.75789336342767, 13.008646956216749],
							[77.75761241079901, 13.00871832807229],
							[77.7574117798032, 13.008807501877921],
							[77.75720215582008, 13.00890556737643],
							[77.7571665345457, 13.008923461134277],
							[77.75708175935885, 13.008985873894199],
							[77.75704613804574, 13.009039407590612],
							[77.75697480807793, 13.009217750852693],
							[77.75690793073468, 13.009315878786394],
							[77.75684550602405, 13.009422848218472],
							[77.75677854129536, 13.00949870778515],
							[77.75661807024228, 13.009605670639022],
							[77.75649322045909, 13.009743928261825],
							[77.75618554808497, 13.00992232720548],
							[77.75594475282138, 13.010060502299522],
							[77.75568605884942, 13.010167535278859],
							[77.7554631607238, 13.010247794287459],
							[77.75535612057145, 13.010292405215495],
							[77.75528033677949, 13.01029242205814],
							[77.7551866546701, 13.010283475043918],
							[77.75511977624717, 13.010256732619085],
							[77.75506180327348, 13.010171991975467],
							[77.75497266103635, 13.009792967201745],
							[77.7549191407361, 13.00960566133016],
							[77.75491468798566, 13.009587845151353],
							[77.75491468798566, 13.009569982053353],
							[77.75490132973323, 13.009485294718223],
							[77.75490132973323, 13.009436279118523],
							[77.75490578248422, 13.009360465162272],
							[77.75490578248422, 13.009333713715934],
							[77.75490578248422, 13.009311363318629],
							[77.75490578248422, 13.009293586557757],
							[77.75490578248422, 13.009284611883377],
							[77.75490132973323, 13.00926679575761],
							[77.75487452591467, 13.009217712470734],
							[77.75479874179327, 13.009119612168291],
							[77.75475412692334, 13.009052767353369],
							[77.75471850486166, 13.009008183212773],
							[77.75471405210314, 13.008999255490716],
							[77.75471850486166, 13.00898583288948],
							[77.75472741037815, 13.008976936980678],
							[77.75473631589394, 13.008968041071713],
							[77.75473631589394, 13.008950178076773],
							[77.75472741037815, 13.008927921617003],
							[77.75471850486166, 13.008901177842185],
							[77.75470505927659, 13.008883278894231],
							[77.75468724823928, 13.008887781330909],
							[77.75466498443863, 13.008901132740801],
							[77.75464708608585, 13.00890114369826],
							[77.7546381805629, 13.00890115125446],
							[77.75462036951485, 13.008887790705197],
							[77.75459365293742, 13.008865463078312],
							[77.75457130180885, 13.00884765798565],
							[77.75454458521956, 13.008811954717869],
							[77.7545177813147, 13.008798597577515],
							[77.75449106471251, 13.008794132924477],
							[77.75445980802596, 13.008798599366486],
							[77.75442863863981, 13.008825333934313],
							[77.75441082755808, 13.008838724677682],
							[77.75439738193565, 13.008856551656459],
							[77.75437511807674, 13.008869903023738],
							[77.7543573069864, 13.008878892743413],
							[77.7543394085839, 13.008883304696422],
							[77.75431269193871, 13.0088878146416],
							[77.75429933361369, 13.008883295494856],
							[77.75428152251116, 13.008883310579463],
							[77.75426362409641, 13.00888780881967],
							[77.75425026576548, 13.0088966653158],
							[77.75422354909878, 13.008945703211694],
							[77.75418783955709, 13.008999231812282],
							[77.75412541331353, 13.009115174006189],
							[77.75412096053117, 13.009132997610353],
							[77.75410760218304, 13.009177580085437],
							[77.75407634538828, 13.009253412145444],
							[77.75405408146487, 13.009275738135406],
							[77.75401837186855, 13.00934264248113],
							[77.7539559455295, 13.009418371458613],
							[77.7539068775292, 13.009462979888676],
							[77.7538712551951, 13.009489761322975],
							[77.75382663995165, 13.009529921801636],
							[77.7537062398215, 13.00962351525232],
							[77.75367061742288, 13.009650296656885],
							[77.75362600209866, 13.009690370808384],
							[77.7535457643177, 13.009761799972113],
							[77.75351005455754, 13.009792978239764],
							[77.75346098635978, 13.009815236200344],
							[77.75336739002194, 13.009846506222763],
							[77.753318321761, 13.00987325148177],
							[77.75328706474357, 13.009895494441732],
							[77.75324698939271, 13.009931211043165],
							[77.75322463799246, 13.009949045478889],
							[77.75320682671716, 13.009971324527566],
							[77.75318010979885, 13.010006986771796],
							[77.75314439992084, 13.010051627009966],
							[77.7530908787374, 13.010109571612828],
							[77.753032904702, 13.010171964138477],
							[77.75289914521548, 13.010247834727567],
							[77.75279210258579, 13.010323596600676],
							[77.75271631703993, 13.010372628191323],
							[77.75269405284321, 13.010417218245811],
							[77.75268514716328, 13.01043957613887],
							[77.75268514716328, 13.01046632765667],
							[77.75264498428407, 13.01053767997039],
							[77.75256474579271, 13.01061342363519],
							[77.75251122432927, 13.010640215568625],
							[77.75240863417616, 13.010662513629626],
							[77.75235511263733, 13.010658066618994],
							[77.75230167838353, 13.010649136400588],
							[77.75226151536535, 13.010662498219663],
							[77.75223925107726, 13.010671448307898],
							[77.75223471090816, 13.010684780474392],
							[77.75221689947374, 13.010729409955422],
							[77.75219463517668, 13.010751649515374],
							[77.7521678307033, 13.010765043349537],
							[77.75215001925814, 13.010773946576732],
							[77.7521188492222, 13.010782904054736],
							[77.75208759186643, 13.010787370013613],
							[77.75205196894741, 13.010791800677357],
							[77.75196718984657, 13.010827459454005],
							[77.75186468650105, 13.01087211184173],
							[77.75161043588554, 13.010831958505914],
							[77.74856488377662, 13.01043061674374],
							[77.74718706286724, 13.010551035353984],
							[77.74711127348897, 13.008981374651611],
							[77.7463398429415, 13.00882977348309],
							[77.74136344123941, 13.008718316630903],
							[77.74059205803617, 13.00894129143604],
							[77.74015502000643, 13.009324736042666],
							[77.73667245087702, 13.009578963376716],
							[77.73601254751242, 13.009801884939257],
							[77.7359590180613, 13.010020408912244],
							[77.73596792508431, 13.010564379145526],
							[77.73608834447249, 13.011589998696786],
							[77.73582523798247, 13.012785024042508],
							[77.73388112978196, 13.011995755034258],
							[77.73289120471772, 13.012111715067118],
							[77.72902066672874, 13.012044848869678],
							[77.72840979719942, 13.012053754440135],
							[77.72802633185215, 13.01211173652373],
							[77.72477559990269, 13.012570973648629],
							[77.72357608086699, 13.012245440543644],
							[77.72090958273265, 13.01173717314679],
							[77.72063307792469, 13.01062233927922],
							[77.72032539346587, 13.010399438887351],
							[77.72013369068324, 13.010033749889905],
							[77.72008906187868, 13.010033776300348],
							[77.71983936731701, 13.010047136690332],
							[77.719585218027, 13.010064943569839],
							[77.71937560992822, 13.01009169348615],
							[77.71925525976637, 13.010078353906719],
							[77.71923744304048, 13.010056017924551],
							[77.71921953897483, 13.010020389698681],
							[77.71923744304048, 13.00977515650955],
							[77.71926862230892, 13.009525466765695],
							[77.71929089320957, 13.009391665347346],
							[77.71931770562132, 13.009271282603319],
							[77.71933997651216, 13.009115219745476],
							[77.71932215979984, 13.008829799582873],
							[77.71926862230892, 13.00869160302749],
							[77.71922399315729, 13.008428459710359],
							[77.71913927632316, 13.007590214355593],
							[77.71904119685627, 13.006952551417116],
							[77.71896093407594, 13.006444218876222],
							[77.71894757149644, 13.006127611084374],
							[77.7189118506061, 13.006065162509598],
							[77.71871123698205, 13.005650487193634],
							[77.7182652917748, 13.00470960162883],
							[77.71818502843468, 13.004531221885715],
							[77.71779261934526, 13.00451335362801],
							[77.7172085010486, 13.004486678437239],
							[77.71649948491729, 13.004446532940488],
							[77.71624978229946, 13.004446519034314],
							[77.71554975605885, 13.004455462727154],
							[77.71524651188385, 13.004473253879453],
							[77.71451521140055, 13.004535662299645],
							[77.71394443908343, 13.00459365420738],
							[77.71353419740215, 13.004607024103992],
							[77.71316858580703, 13.004580246961835],
							[77.71266916506572, 13.004504456741437],
							[77.71232582292427, 13.004428699379828],
							[77.71203147890739, 13.004410846211403],
							[77.71154541653513, 13.004361748822937],
							[77.7109568109405, 13.004317157581788],
							[77.71035929316064, 13.00429043070577],
							[77.70964141161743, 13.004268134154993],
							[77.7090438867799, 13.004268150489185],
							[77.70853108370913, 13.004259184397764],
							[77.70760364746194, 13.004241366247141],
							[77.7072379290492, 13.004281528387844],
							[77.7072201103999, 13.004285940100848],
							[77.70724692571909, 13.00424140712291],
							[77.70724692571909, 13.004205687665413],
							[77.70723347438715, 13.004152242366581],
							[77.70716665443489, 13.003773220119564],
							[77.70706402240404, 13.003148925165748],
							[77.70694811360528, 13.002774377365784],
							[77.70676975182711, 13.00229719712274],
							[77.7065958444511, 13.001793313563773],
							[77.70647548047289, 13.001084322796357],
							[77.70639966332843, 13.000749918015666],
							[77.70606966598577, 12.999278428269292],
						],
					],
				],
				type: "MultiPolygon",
			},
			id: "00000000000000000005",
			properties: {
				AREA_SQ_KM: 15.446109363940757,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 13.022375521429,
				LON: 77.7054933252136,
				LOST_AREA_SQ_KM: 1.3793889913900046,
				OBJECTID: 6,
				POP_F: 9624.0,
				POP_M: 11076.0,
				POP_SC: 4364.0,
				POP_ST: 297.0,
				POP_TOTAL: 20700.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Hudi",
				WARD_NO: 54.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.67711231016389, 13.000740972629254],
						[77.67754924615295, 13.000763274999953],
						[77.67720142742088, 13.000562609623376],
						[77.67665300568456, 13.00026391395505],
						[77.67706766413885, 13.000317413985915],
						[77.677611715366, 13.00035304010436],
						[77.678846851225, 13.000370854155245],
						[77.68059481991564, 13.000433312212964],
						[77.68100063969536, 13.000384240457034],
						[77.68115222108649, 13.000299533224739],
						[77.68212880220285, 12.998930602870812],
						[77.6836715872723, 12.996379988599925],
						[77.68393472964, 12.995943041036398],
						[77.6846125018482, 12.99502446194415],
						[77.68488001019061, 12.994667693517101],
						[77.68600822088607, 12.993062419524545],
						[77.68743065520368, 12.99119853700586],
						[77.68763578058378, 12.991350162617014],
						[77.68776953121427, 12.991399171848593],
						[77.6880102994177, 12.99151954868576],
						[77.68849192162799, 12.99207249974205],
						[77.68854984195339, 12.992143849672988],
						[77.68867922344653, 12.992308849675991],
						[77.68890216811198, 12.992736947503188],
						[77.68903591722544, 12.993396893235975],
						[77.68916521079483, 12.993691118389682],
						[77.68948626005775, 12.99452945430125],
						[77.68966464948046, 12.99500658825538],
						[77.68989204753302, 12.995572853512982],
						[77.69028892285543, 12.996259633821875],
						[77.69056096028686, 12.996825884224739],
						[77.69065460949972, 12.99728966020553],
						[77.69098902041333, 12.997120171465806],
						[77.69164001809557, 12.996870491625243],
						[77.69166229451913, 12.996866035480817],
						[77.6921795430878, 12.99670994513617],
						[77.69233565192141, 12.996652016410824],
						[77.6925318577898, 12.996567276802445],
						[77.69264778157577, 12.996460237256374],
						[77.69286626307861, 12.996380013032766],
						[77.69309365460829, 12.996308668908824],
						[77.69369117758718, 12.996108014834755],
						[77.69386956028903, 12.996027684311246],
						[77.69407021864856, 12.995947469439413],
						[77.6944626239405, 12.995769100431351],
						[77.69505122929064, 12.99551496019401],
						[77.69516715045914, 12.995465892070767],
						[77.69546590679636, 12.995385619403889],
						[77.69557291727808, 12.99536330002095],
						[77.69587167252864, 12.995305370454634],
						[77.69644690516552, 12.995220623890512],
						[77.69752608076072, 12.995073514137006],
						[77.6978693811528, 12.994984323754416],
						[77.69891281567908, 12.994609730908788],
						[77.70005433671732, 12.994275274874246],
						[77.70127612165373, 12.99399433727391],
						[77.7015525871817, 12.996883903689774],
						[77.70192269177727, 12.996955245839594],
						[77.70229279514405, 12.997160309772866],
						[77.70261389397768, 12.997236125941745],
						[77.70297508538502, 12.997468026537014],
						[77.70315345323715, 12.997695421577202],
						[77.70334518525742, 12.997704321661839],
						[77.70344327855074, 12.997793555163328],
						[77.70358591984635, 12.997873787487691],
						[77.70374646751613, 12.998096708760164],
						[77.70384010565851, 12.998288481695168],
						[77.70388919577263, 12.99845345267515],
						[77.70396055983234, 12.998957337174332],
						[77.70397392421053, 12.999122328047477],
						[77.70399174337896, 12.999193665427892],
						[77.70402292691686, 12.999528088395207],
						[77.7008792854179, 12.999719879358183],
						[77.69914019488343, 12.999777827022719],
						[77.69897081794814, 12.999782327886779],
						[77.69914919220699, 13.0005001961645],
						[77.6992249269309, 13.000803446186767],
						[77.69937657092933, 13.00163280522665],
						[77.6993899358571, 13.00169521692689],
						[77.6993899358571, 13.00170858955185],
						[77.69951030748724, 13.002408686665238],
						[77.69956385447689, 13.00281889896133],
						[77.69957276441396, 13.003304972696473],
						[77.69973331786358, 13.003898033514904],
						[77.6998135945017, 13.004673963931946],
						[77.69984923416582, 13.005231294792134],
						[77.69985814408007, 13.005271408509994],
						[77.69976895755335, 13.00556126563541],
						[77.69997406025253, 13.006172171709444],
						[77.69956830944551, 13.006305915947392],
						[77.69903764281408, 13.006524451242814],
						[77.69886826572301, 13.006595814602335],
						[77.6986318885996, 13.006769665096062],
						[77.6983286857244, 13.006965858176498],
						[77.6980032068718, 13.007113014642574],
						[77.69762417917397, 13.007300317530284],
						[77.69724960524928, 13.007576786033082],
						[77.69686166483746, 13.007862191610545],
						[77.69660300802843, 13.008013749768331],
						[77.69638007874723, 13.008120771052],
						[77.6963398955222, 13.008129720125929],
						[77.69596086217203, 13.008241180475075],
						[77.69582712135784, 13.008285773810154],
						[77.6957646623335, 13.008308065766553],
						[77.69572902136407, 13.008348206113498],
						[77.69563983153532, 13.008334880345279],
						[77.69536780648497, 13.008334809383353],
						[77.69485057269542, 13.008352687474085],
						[77.69464546057424, 13.008343737738716],
						[77.6940479427057, 13.008334823167473],
						[77.69377591377395, 13.008316976877374],
						[77.6936020734863, 13.008312542200592],
						[77.69306246801408, 13.008254542864771],
						[77.69262105010303, 13.008196581605311],
						[77.6922063620244, 13.00811634487991],
						[77.69190759695967, 13.00806287896303],
						[77.69153300384204, 13.007995948679318],
						[77.69066352014833, 13.007808705171316],
						[77.69001242941098, 13.007648131434381],
						[77.68977166552526, 13.007576819409326],
						[77.68910283540234, 13.007282529752667],
						[77.68834471825251, 13.00691235778584],
						[77.68817978035258, 13.006832164552453],
						[77.68815304780983, 13.006814320151108],
						[77.68733254782947, 13.006404064588073],
						[77.68631582537147, 13.005837750536053],
						[77.68575844996353, 13.005512216849453],
						[77.68526799224897, 13.005231298484533],
						[77.68511187353276, 13.00515101037402],
						[77.6845544921166, 13.005998284457712],
						[77.68247660560095, 13.009440733962176],
						[77.68210643651148, 13.009949032010724],
						[77.68128152378213, 13.010631310167552],
						[77.68099172827577, 13.010872031174186],
						[77.68076876782085, 13.01097911839871],
						[77.68068402176324, 13.01101923241199],
						[77.68070184465078, 13.010274569646489],
						[77.68059036418975, 13.010185368822302],
						[77.68050561799686, 13.010127386570414],
						[77.6804387820365, 13.010078372262475],
						[77.68037631440345, 13.010029308404535],
						[77.6803050226285, 13.009989139679252],
						[77.6802113647374, 13.009949021136201],
						[77.68014444123355, 13.009904489056586],
						[77.68008206080354, 13.009855429448093],
						[77.67992147908825, 13.009784065354715],
						[77.67981445360577, 13.009721651254464],
						[77.6797387057126, 13.009690414357806],
						[77.67973424995256, 13.009677000724256],
						[77.67971197114979, 13.009632450527832],
						[77.67969851649812, 13.009570032637455],
						[77.6796316800551, 13.009467441437284],
						[77.67956475620414, 13.009436282234375],
						[77.6794354515222, 13.009418440754276],
						[77.67924376579278, 13.009485266632115],
						[77.67917684170955, 13.009516484460404],
						[77.67900297869508, 13.009538783043617],
						[77.67888704074569, 13.009556609404484],
						[77.67846784586047, 13.009440674692241],
						[77.67795507699064, 13.009244507099263],
						[77.67772765463809, 13.009119642126098],
						[77.67768746470057, 13.009075010599407],
						[77.67764736211807, 13.009052728423283],
						[77.67758943614018, 13.009048313918843],
						[77.67748240842484, 13.009026017438197],
						[77.67725944096958, 13.009101781818764],
						[77.67722379409382, 13.009115171944684],
						[77.67717914811328, 13.009146421113623],
						[77.67713904534845, 13.009195487985416],
						[77.6771078542992, 13.009226691617368],
						[77.67705429653883, 13.009324721774322],
						[77.67702756133403, 13.009436201908946],
						[77.67697854677525, 13.009650317063839],
						[77.67693835656843, 13.009797388252005],
						[77.67692944482496, 13.009886601048212],
						[77.67692053308079, 13.010007045525798],
						[77.67692498895296, 13.01011406778862],
						[77.67691162133589, 13.010252244251724],
						[77.67692944482496, 13.01034144434102],
						[77.67693835656843, 13.01047076696926],
						[77.6769472683112, 13.010582230688808],
						[77.6769740035352, 13.010742776741056],
						[77.67695618005324, 13.01079179004476],
						[77.67691607720842, 13.010840857325098],
						[77.67687597434923, 13.01088543826319],
						[77.67682687235389, 13.01090327382602],
						[77.67679568121753, 13.01088098666054],
						[77.67677340182908, 13.010831949685496],
						[77.67676449007247, 13.010796235848701],
						[77.67675557831514, 13.010751635626546],
						[77.6767287556687, 13.010653548989314],
						[77.67667082921486, 13.01038599360835],
						[77.67649696230286, 13.009913328884071],
						[77.67643003657523, 13.009788474211494],
						[77.67633637543744, 13.009543279429687],
						[77.67627844878031, 13.009427311363323],
						[77.67618033161385, 13.009235566659417],
						[77.67613131668368, 13.009115194626181],
						[77.6760822143613, 13.008936842273952],
						[77.67604211120302, 13.008794121262852],
						[77.6760599348307, 13.008691532710339],
						[77.67607775845553, 13.008624661654757],
						[77.6760866702669, 13.008535536313945],
						[77.67607775845553, 13.008464192168855],
						[77.67605547892404, 13.008383925273014],
						[77.67603319938812, 13.008352661928946],
						[77.67598855293215, 13.008290217358745],
						[77.6758369645387, 13.008107387207573],
						[77.67536874295254, 13.008375003556559],
						[77.67507893211743, 13.00853996150345],
						[77.67478466457395, 13.008691591870868],
						[77.67457060367131, 13.008027208954429],
						[77.6744903088842, 13.007772996907656],
						[77.6744903088842, 13.007616929260392],
						[77.67451258872754, 13.00755898627312],
						[77.67454386787264, 13.007492070802162],
						[77.67460625139292, 13.007295856425989],
						[77.67460625139292, 13.007224508987994],
						[77.67451704469568, 13.007019392166418],
						[77.67443683724208, 13.00688562277875],
						[77.67442346932756, 13.006845469542398],
						[77.67446357306635, 13.006823234925983],
						[77.6746820901354, 13.006618110982378],
						[77.67476675337127, 13.006528907367786],
						[77.6748247681832, 13.006421862703098],
						[77.67490506273016, 13.00623013123654],
						[77.67507447617169, 13.005596990289026],
						[77.67515477053936, 13.005405173450962],
						[77.67565418448496, 13.00541417406087],
						[77.67566309633007, 13.005266990503218],
						[77.67601983166443, 13.005235767895305],
						[77.67649241904324, 13.005106482159558],
						[77.67712567775698, 13.004990498957975],
						[77.67715241293834, 13.004642725135945],
						[77.67715686880126, 13.00437515258148],
						[77.67716578052661, 13.004031789345223],
						[77.67715241293834, 13.003773199367547],
						[77.6771345894848, 13.00364388869594],
						[77.6771033984343, 13.003554657642015],
						[77.67706320827236, 13.003469999352458],
						[77.67699637025366, 13.003362928622328],
						[77.6769027095903, 13.003220237809462],
						[77.67689379784399, 13.003140011039944],
						[77.67688934197055, 13.00296164982815],
						[77.6769027095903, 13.002707448553785],
						[77.67698300264512, 13.002542463865051],
						[77.67698745851482, 13.002368584977624],
						[77.67699637025366, 13.002141173829248],
						[77.67707212000516, 13.00159267787721],
						[77.67711231016389, 13.000740972629254],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000006",
			properties: {
				AREA_SQ_KM: 3.4696062897126447,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 13.0017973536303,
				LON: 77.6891215903739,
				LOST_AREA_SQ_KM: 0.12200430909283208,
				OBJECTID: 7,
				POP_F: 10453.0,
				POP_M: 11604.0,
				POP_SC: 3225.0,
				POP_ST: 377.0,
				POP_TOTAL: 22057.0,
				RESERVATIO: "Backward Category - B (Woman)",
				WARD_NAME: "Devasandra",
				WARD_NO: 55.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.6640382140695, 12.997521511866083],
						[77.66404267045473, 12.99751706725415],
						[77.66369035333346, 12.997334212758174],
						[77.66338268660738, 12.997178169410262],
						[77.66291004385828, 12.996892762213998],
						[77.66278517636148, 12.996825876593922],
						[77.66321771188294, 12.996094577003808],
						[77.66333366607108, 12.995907339660164],
						[77.6633648609603, 12.995853799863783],
						[77.66360568171724, 12.995434638093563],
						[77.66379739424276, 12.995113617711572],
						[77.66380630703222, 12.995104728620172],
						[77.66406495237827, 12.994676588327001],
						[77.66412734174054, 12.994569595441702],
						[77.66412734174054, 12.99456510989031],
						[77.66413179812223, 12.994560665346699],
						[77.66418981844804, 12.994453635685675],
						[77.6646891939551, 12.99361090470235],
						[77.66486762294878, 12.993338849348568],
						[77.66512172212184, 12.992933093802813],
						[77.66520647997083, 12.992803796762303],
						[77.66555870494253, 12.992353401961273],
						[77.66570139462435, 12.99218840843626],
						[77.66606707433199, 12.99179153518739],
						[77.66636582109668, 12.991519550553308],
						[77.66656652884386, 12.991350089960028],
						[77.66687418682085, 12.991113811535762],
						[77.66707926254317, 12.990966642017815],
						[77.66721303776926, 12.99087298848441],
						[77.66781952423156, 12.990511798010761],
						[77.66794438611359, 12.99040477842622],
						[77.6683144273321, 12.990217547443294],
						[77.66812272254188, 12.99001687422001],
						[77.6681717409812, 12.989985666911258],
						[77.66798449217491, 12.989793919627756],
						[77.66786854280367, 12.989776032634863],
						[77.66778387434745, 12.98975377791719],
						[77.66769911844993, 12.989691322775194],
						[77.66755197506922, 12.989419379068504],
						[77.66744939399935, 12.988977917612448],
						[77.66741374399736, 12.988549841137644],
						[77.6674226564989, 12.987947850048284],
						[77.6674092877463, 12.987368170656785],
						[77.66744939399935, 12.986311300564996],
						[77.66744493774972, 12.986092861169702],
						[77.6674672189961, 12.986034842981933],
						[77.66773031212276, 12.985959112404261],
						[77.66782398046628, 12.985825329279386],
						[77.66793547365357, 12.985633573489617],
						[77.66795329857291, 12.985611224144145],
						[77.66799340463102, 12.985593391579012],
						[77.66805587918093, 12.985624673837009],
						[77.66823867164977, 12.985727183234125],
						[77.66828323382207, 12.985749500081848],
						[77.6683635330642, 12.985820881763889],
						[77.66836798927724, 12.985820836309442],
						[77.66837244549012, 12.985825276131743],
						[77.66841700760914, 12.9858208538364],
						[77.66842146382007, 12.98582080838085],
						[77.66857306226255, 12.985896638115339],
						[77.66860434306835, 12.985910038707033],
						[77.66880495942506, 12.986003654565096],
						[77.66884060898393, 12.986017005511224],
						[77.66916617281223, 12.986128505466773],
						[77.66923301551002, 12.986150853746715],
						[77.66925983995397, 12.986155242932778],
						[77.66935341964137, 12.986173091846114],
						[77.66943371811561, 12.986186509768443],
						[77.66948273598071, 12.986190926160562],
						[77.6697949294732, 12.9862043165493],
						[77.66982166640689, 12.986204302442932],
						[77.6699376137545, 12.98619984640311],
						[77.6699999998436, 12.986195414384426],
						[77.67016496457265, 12.986182004804668],
						[77.67020961335314, 12.986177586336442],
						[77.67055291032865, 12.986137433021062],
						[77.67084282026542, 12.986079456742493],
						[77.6709988715358, 12.986048282429024],
						[77.67117274699505, 12.986222129614697],
						[77.67129760513552, 12.986173073545233],
						[77.67149830403781, 12.986066059878748],
						[77.671761387687, 12.985945729014459],
						[77.6719040694605, 12.985999221724851],
						[77.67214041578231, 12.986088421513628],
						[77.67219834458277, 12.986110687614811],
						[77.67261747559827, 12.986275655638146],
						[77.67271113985068, 12.986306877332671],
						[77.67302332433454, 12.986431707329983],
						[77.67312135689782, 12.986467450872432],
						[77.67350483639348, 12.98661458265467],
						[77.67373226733474, 12.986699301514435],
						[77.67448139694562, 12.98662344266622],
						[77.67427179144042, 12.986752805776725],
						[77.67414248063685, 12.986922238904958],
						[77.6746642662881, 12.986935646751895],
						[77.67600192065959, 12.98747958743741],
						[77.6758369645387, 12.988572099817365],
						[77.67585478819908, 12.98997674458753],
						[77.67584587636925, 12.990119424070462],
						[77.6756764640964, 12.990123911122868],
						[77.67574330290414, 12.990667877236568],
						[77.67579240537532, 12.991064784612318],
						[77.67547577269119, 12.991225258980574],
						[77.67556497862365, 12.991510653640049],
						[77.67601983166443, 12.991385777619776],
						[77.67628290467808, 12.991301064423038],
						[77.67663072626769, 12.99256747244955],
						[77.6763541990182, 12.992647746554955],
						[77.67591280244653, 12.992652154682537],
						[77.67587706777056, 12.992687882194321],
						[77.67587706777056, 12.992741364830142],
						[77.6758637000282, 12.992835009788994],
						[77.67580131720942, 12.992991091931877],
						[77.67578349354051, 12.993115923353182],
						[77.67581022904281, 12.993271958202431],
						[77.67585924411374, 12.993441481528016],
						[77.6759618174725, 12.993602009449777],
						[77.67619369931836, 12.993865082927696],
						[77.67650141819192, 12.994203989613077],
						[77.6767466665571, 12.994480385326511],
						[77.67697400353521, 12.9948594414838],
						[77.67712567775698, 12.995077962549017],
						[77.67726389682826, 12.99502885157243],
						[77.67755370200008, 12.994926352033822],
						[77.6775938919857, 12.994926291553439],
						[77.67782131461036, 12.994872823231972],
						[77.67799517946274, 12.994859405967054],
						[77.67825379200929, 12.994854961652422],
						[77.67861051907728, 12.994810355084821],
						[77.67910100601588, 12.994730138278774],
						[77.67957812350396, 12.994734596325923],
						[77.68039413734007, 12.994779165522097],
						[77.68066619887283, 12.994797014605691],
						[77.68121023258466, 12.994823736085955],
						[77.68198604611139, 12.99486387515563],
						[77.68212880220285, 12.994912975362862],
						[77.68212880220285, 12.995006573522259],
						[77.68227592640491, 12.994997653447475],
						[77.68296261994006, 12.99502886771047],
						[77.68299826498543, 12.995109161245448],
						[77.68304290864141, 12.995162625305692],
						[77.6830651867801, 12.995225112517906],
						[77.68307855366118, 12.995363346403982],
						[77.68308300928784, 12.995439128722952],
						[77.68307855366118, 12.99549705897618],
						[77.68307855366118, 12.995550630313687],
						[77.68308300928784, 12.995617527324653],
						[77.68308746491435, 12.995693309810978],
						[77.68310528741853, 12.995773527588096],
						[77.68314101978356, 12.995893939830228],
						[77.68319003163975, 12.995987554773102],
						[77.68330150950712, 12.996121339378327],
						[77.68342189848971, 12.99622389057909],
						[77.68348436442014, 12.996272945685883],
						[77.6836715872723, 12.996379988599925],
						[77.68212880220285, 12.998930602870812],
						[77.68115222108649, 13.000299533224739],
						[77.68100063969536, 13.000384240457034],
						[77.68059481991564, 13.000433312212964],
						[77.678846851225, 13.000370854155245],
						[77.677611715366, 13.00035304010436],
						[77.67706766413885, 13.000317413985915],
						[77.67665300568456, 13.00026391395505],
						[77.67720142742088, 13.000562609623376],
						[77.67754924615295, 13.000763274999953],
						[77.67711231016389, 13.000740972629254],
						[77.67532418360258, 13.000758864632695],
						[77.67237667423116, 13.000535905510956],
						[77.67111481766352, 13.000339672307998],
						[77.67035229710565, 13.000165804979163],
						[77.66969680661937, 13.000000773738357],
						[77.66956303436214, 12.999969606121688],
						[77.6688361527897, 12.999742147445929],
						[77.66783734916936, 12.999358630793141],
						[77.66709717497658, 12.99909559644158],
						[77.66662891681212, 12.99889942091299],
						[77.66550522903206, 12.998346448244307],
						[77.66442163731124, 12.997735531760714],
						[77.6640382140695, 12.997521511866083],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000007",
			properties: {
				AREA_SQ_KM: 2.148346124611605,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 12.9944744122469,
				LON: 77.6725826643836,
				LOST_AREA_SQ_KM: 0.16124753197437355,
				OBJECTID: 8,
				POP_F: 14014.0,
				POP_M: 15406.0,
				POP_SC: 5827.0,
				POP_ST: 118.0,
				POP_TOTAL: 29420.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "A Narayanapura",
				WARD_NO: 56.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.6836715872723, 12.996379988599925],
						[77.68348436442014, 12.996272945685883],
						[77.68342189848971, 12.99622389057909],
						[77.68330150950712, 12.996121339378327],
						[77.68319003163975, 12.995987554773102],
						[77.68314101978356, 12.995893939830228],
						[77.68310528741853, 12.995773527588096],
						[77.68308746491435, 12.995693309810978],
						[77.68308300928784, 12.995617527324653],
						[77.68307855366118, 12.995550630313687],
						[77.68307855366118, 12.99549705897618],
						[77.68308300928784, 12.995439128722952],
						[77.68307855366118, 12.995363346403982],
						[77.6830651867801, 12.995225112517906],
						[77.68304290864141, 12.995162625305692],
						[77.68299826498543, 12.995109161245448],
						[77.68296261994006, 12.99502886771047],
						[77.68227592640491, 12.994997653447475],
						[77.68212880220285, 12.995006573522259],
						[77.68212880220285, 12.994912975362862],
						[77.68198604611139, 12.99486387515563],
						[77.68121023258466, 12.994823736085955],
						[77.68066619887283, 12.994797014605691],
						[77.68039413734007, 12.994779165522097],
						[77.67957812350396, 12.994734596325923],
						[77.67910100601588, 12.994730138278774],
						[77.67861051907728, 12.994810355084821],
						[77.67825379200929, 12.994854961652422],
						[77.67799517946274, 12.994859405967054],
						[77.67782131461036, 12.994872823231972],
						[77.6775938919857, 12.994926291553439],
						[77.67755370200008, 12.994926352033822],
						[77.67726389682826, 12.99502885157243],
						[77.67712567775698, 12.995077962549017],
						[77.67697400353521, 12.9948594414838],
						[77.6767466665571, 12.994480385326511],
						[77.67650141819192, 12.994203989613077],
						[77.67619369931836, 12.993865082927696],
						[77.6759618174725, 12.993602009449777],
						[77.67585924411374, 12.993441481528016],
						[77.67581022904281, 12.993271958202431],
						[77.67578349354051, 12.993115923353182],
						[77.67580131720942, 12.992991091931877],
						[77.6758637000282, 12.992835009788994],
						[77.67587706777056, 12.992741364830142],
						[77.67587706777056, 12.992687882194321],
						[77.67591280244653, 12.992652154682537],
						[77.6763541990182, 12.992647746554955],
						[77.67663072626769, 12.99256747244955],
						[77.67628290467808, 12.991301064423038],
						[77.67601983166443, 12.991385777619776],
						[77.67556497862365, 12.991510653640049],
						[77.67547577269119, 12.991225258980574],
						[77.67579240537532, 12.991064784612318],
						[77.67574330290414, 12.990667877236568],
						[77.6756764640964, 12.990123911122868],
						[77.67584587636925, 12.990119424070462],
						[77.67585478819908, 12.98997674458753],
						[77.6758369645387, 12.988572099817365],
						[77.67600192065959, 12.98747958743741],
						[77.6746642662881, 12.986935646751895],
						[77.67414248063685, 12.986922238904958],
						[77.67427179144042, 12.986752805776725],
						[77.67448139694562, 12.98662344266622],
						[77.67488715154678, 12.986431697858126],
						[77.6757031996243, 12.985936805831066],
						[77.6765058740808, 12.985450759244118],
						[77.67701864959767, 12.985120770014426],
						[77.67758943614018, 12.984835388785275],
						[77.67792825463198, 12.984643627885381],
						[77.67820477798814, 12.984451906209157],
						[77.67848566910142, 12.9843003299978],
						[77.6786283422955, 12.984139728456608],
						[77.67907427130055, 12.984032702863827],
						[77.6798055420916, 12.983662608435358],
						[77.67980999784878, 12.983640308484189],
						[77.67980999784878, 12.983635909392],
						[77.67988137730723, 12.983560052485501],
						[77.67997057972427, 12.983466416326406],
						[77.68006423781715, 12.983341595806193],
						[77.6800865165497, 12.98330143038709],
						[77.68014444123355, 12.983207765133946],
						[77.68025146640001, 12.98304726983251],
						[77.68030056689106, 12.982966983774123],
						[77.6803629471991, 12.982873359127659],
						[77.68037185866885, 12.98284661445614],
						[77.68040313617465, 12.98274848386505],
						[77.68039859307379, 12.982663821859342],
						[77.68038968160619, 12.982628117048518],
						[77.68037631440345, 12.98254786360184],
						[77.6803451242575, 12.982431910705285],
						[77.68025592213941, 12.982128683335278],
						[77.68010433953253, 12.981615892975872],
						[77.67993938946509, 12.981085301456083],
						[77.67985464277857, 12.980791002842611],
						[77.67981890936261, 12.980626014700976],
						[77.67980999784878, 12.980581426389481],
						[77.6798055420916, 12.980550161777606],
						[77.67968514921272, 12.979908109328365],
						[77.67964059158315, 12.979685083222567],
						[77.67957812350396, 12.979413071945768],
						[77.6794265399778, 12.978739778222408],
						[77.67937752647089, 12.978485663105628],
						[77.67933288135053, 12.97809323575834],
						[77.6793016909134, 12.97759829527587],
						[77.67929277935833, 12.977464523575303],
						[77.67927941202441, 12.977317342518134],
						[77.67926158891001, 12.977054285035491],
						[77.67926158891001, 12.97704979994448],
						[77.67926158891001, 12.97700969288817],
						[77.67926604468889, 12.976813424594008],
						[77.67931505824467, 12.976617216138287],
						[77.67932396979796, 12.97650128890061],
						[77.67932396979796, 12.976407706208203],
						[77.67930614669066, 12.97629619404912],
						[77.6793016909134, 12.976269415730124],
						[77.67873536891369, 12.974748922595447],
						[77.67840537712584, 12.973767848303538],
						[77.67826270364719, 12.973357644086631],
						[77.67823596873136, 12.973281847609625],
						[77.67821814545059, 12.97319267725358],
						[77.67819141052411, 12.973032184814029],
						[77.6781869547024, 12.973032144388903],
						[77.67812002998643, 12.972809185375343],
						[77.67810220668719, 12.97274226748208],
						[77.67768300885879, 12.972809174165436],
						[77.67667082921484, 12.973018771606561],
						[77.67630072826738, 12.973094576661728],
						[77.67597518520313, 12.973179324250582],
						[77.67584587636925, 12.973197120916211],
						[77.67554715491707, 12.973246149334225],
						[77.67495407819786, 12.973375462809486],
						[77.67458842753354, 12.973473556859823],
						[77.67443683724208, 12.973518145615808],
						[77.67406672890071, 12.973643021186625],
						[77.67387049060056, 12.973705484660968],
						[77.6734825563494, 12.973821399639139],
						[77.67329086041416, 12.97387933604358],
						[77.67281817213262, 12.974044326457692],
						[77.67235885001648, 12.97420038126216],
						[77.67140463876991, 12.974512598052353],
						[77.67124413196716, 12.974557189163214],
						[77.6710345203957, 12.974619613985862],
						[77.67065103243173, 12.97473107848097],
						[77.67045933164438, 12.97480238023662],
						[77.66988405256063, 12.975020933282556],
						[77.66944263045632, 12.975176974687209],
						[77.66921073461518, 12.975257219984119],
						[77.66914389190408, 12.975288435850125],
						[77.66893427639819, 12.975377595011537],
						[77.66880050322942, 12.975422255279028],
						[77.66853741261845, 12.975462368667042],
						[77.66826095273814, 12.97550244595542],
						[77.66795775480229, 12.975551507344257],
						[77.66791319250041, 12.975560415419285],
						[77.66785071787085, 12.97556044560989],
						[77.66752969383971, 12.975578294489527],
						[77.6674761314934, 12.975578319674309],
						[77.66735572534236, 12.975569368646532],
						[77.66730670654566, 12.975564867530755],
						[77.66721303776926, 12.975555988020671],
						[77.66693657461764, 12.975511368441717],
						[77.6667849739586, 12.975466770918128],
						[77.6667849739586, 12.975462372304047],
						[77.66662446052982, 12.975417779599603],
						[77.66653524746853, 12.975377632822415],
						[77.66650850974973, 12.97535979423205],
						[77.66650850974973, 12.975341941065874],
						[77.66651296603665, 12.975328613448204],
						[77.666553159989, 12.975074382478583],
						[77.6665843539812, 12.9747756493393],
						[77.6665843539812, 12.974753311425795],
						[77.66661109168189, 12.974476876216816],
						[77.6666155479647, 12.974463548679084],
						[77.66662000424735, 12.974459018290787],
						[77.66663782937617, 12.974454609939071],
						[77.66674041118979, 12.974450116171367],
						[77.66674932374498, 12.974450111277799],
						[77.66683853663565, 12.974441183101737],
						[77.66687864309321, 12.974427835958162],
						[77.66689646818084, 12.974409973112229],
						[77.6669098369947, 12.97439664066228],
						[77.66691874953639, 12.974383267539919],
						[77.66691874953639, 12.974365414499509],
						[77.66692320580698, 12.974338632492085],
						[77.66692320580698, 12.97429852785452],
						[77.66691874953639, 12.974262867377732],
						[77.66691429326563, 12.97423609029865],
						[77.66686081800275, 12.973874920126274],
						[77.66682962408757, 12.973678684295573],
						[77.66681171161135, 12.973576143459884],
						[77.6667849739586, 12.973433421251961],
						[77.66674041118979, 12.973295193864818],
						[77.66668247956385, 12.973152532407866],
						[77.66668247956385, 12.973143649109732],
						[77.6666734796253, 12.973125797016946],
						[77.66682962408757, 12.973041069049223],
						[77.66697676841788, 12.972862679085626],
						[77.66699013722216, 12.972728948119011],
						[77.66699013722216, 12.972635285666556],
						[77.66682516781327, 12.972563918856473],
						[77.666553159989, 12.972465871167014],
						[77.66643275284514, 12.971828196348323],
						[77.66635245221784, 12.971743458344651],
						[77.66635245221784, 12.971738973630805],
						[77.66623204485809, 12.971587375458743],
						[77.66617411296905, 12.97152940832139],
						[77.6661250936549, 12.971511626092019],
						[77.6660804432449, 12.971511560401128],
						[77.66604033650137, 12.971516024231475],
						[77.66604033650137, 12.971373376327099],
						[77.66590655987383, 12.970775862389612],
						[77.66573713255048, 12.969826068843885],
						[77.66546957841089, 12.968581990395737],
						[77.66541155874826, 12.968180606288364],
						[77.66556324864617, 12.968104809281963],
						[77.66550077270504, 12.967832834477399],
						[77.66555870494253, 12.967694600025503],
						[77.66551414168558, 12.967427018659036],
						[77.6654874037229, 12.96657531960927],
						[77.66549186005045, 12.966178482137314],
						[77.66554979229255, 12.96592882717055],
						[77.6659689482099, 12.965643405010914],
						[77.66647285944808, 12.965424902766419],
						[77.66657989769713, 12.965277806421328],
						[77.66658881026508, 12.96536248882648],
						[77.6669321183476, 12.966294427815576],
						[77.66739591899209, 12.966191896230894],
						[77.66806924785631, 12.965991233549282],
						[77.668524043999, 12.965906465661869],
						[77.66866672990074, 12.964867534221757],
						[77.67025863087835, 12.965072630580197],
						[77.67028982383776, 12.966111600770981],
						[77.66975919284272, 12.966151783134597],
						[77.67002673672827, 12.967654485705001],
						[77.67108353755951, 12.967422553225981],
						[77.671605250106, 12.967306653332558],
						[77.6726621234, 12.967092654358728],
						[77.67275124420343, 12.967003426030008],
						[77.67347364433051, 12.967297709751552],
						[77.67354057182747, 12.967123850836602],
						[77.67356285186001, 12.966918708626224],
						[77.6735762198774, 12.966379123407858],
						[77.67359849990282, 12.965902060727597],
						[77.67359849990282, 12.96521531679695],
						[77.67356730786598, 12.964769424420114],
						[77.67355839585386, 12.964622302335256],
						[77.67372781133534, 12.964599952934712],
						[77.67391959389407, 12.964644560260552],
						[77.6744190133557, 12.96471593782136],
						[77.67515477053936, 12.964814041615174],
						[77.67566755225236, 12.964822926466368],
						[77.67621152292188, 12.964818466373046],
						[77.67665300568456, 12.964773923143197],
						[77.67734864547899, 12.964640096864414],
						[77.67756706954037, 12.964680282225784],
						[77.67809329503648, 12.964925504537355],
						[77.67852577138318, 12.965135029935078],
						[77.67908763865903, 12.964943322722789],
						[77.67954247736758, 12.964702519299857],
						[77.68039413734007, 12.964332410838717],
						[77.68067956604091, 12.964274457394408],
						[77.68108975385223, 12.964247729144292],
						[77.68169179675017, 12.96417634394133],
						[77.68215108052381, 12.96411392439847],
						[77.68209752518005, 12.96348076048811],
						[77.68186565558162, 12.962789585402604],
						[77.68162487413291, 12.961661436481736],
						[77.68636937869286, 12.962062760084093],
						[77.6879657451086, 12.962160831352655],
						[77.68896454361658, 12.962241080950282],
						[77.68863458204349, 12.966450476787202],
						[77.68856320817805, 12.966945489821404],
						[77.68814413696082, 12.967163962459495],
						[77.68792555885378, 12.969072450525465],
						[77.68733700328644, 12.969085841833026],
						[77.68665033664342, 12.969259712819605],
						[77.68639165616811, 12.969487133095457],
						[77.6862712703863, 12.9697814431251],
						[77.68639611166262, 12.970004404134501],
						[77.68621326151622, 12.969995501948286],
						[77.68605723148423, 12.970044545295629],
						[77.68606168699208, 12.970267537829457],
						[77.68624453738666, 12.970655417316838],
						[77.68643629846787, 12.971444697135498],
						[77.68659678345675, 12.971890594570436],
						[77.6867082579241, 12.972323113804377],
						[77.686775177496, 12.97292514612426],
						[77.68685092061847, 12.973161467529474],
						[77.68639611166262, 12.973544956272871],
						[77.68609733104853, 12.973758994603193],
						[77.6859190232895, 12.973923930301138],
						[77.68574062788264, 12.974093433051955],
						[77.68573171684115, 12.974151399598144],
						[77.685696072668, 12.974316334907964],
						[77.68548648819437, 12.97470428962952],
						[77.68468824643735, 12.975333060877372],
						[77.68411304056673, 12.97575219669327],
						[77.68408630705052, 12.97620253623992],
						[77.68429589415142, 12.976826840179031],
						[77.68472843385709, 12.977593767397119],
						[77.68544184551496, 12.978293902990412],
						[77.68665924761164, 12.979292757270878],
						[77.68692675105176, 12.979484430330652],
						[77.68870150046094, 12.97850791849764],
						[77.68887980377691, 12.978824538969583],
						[77.69032456556242, 12.981870098935323],
						[77.69180949351983, 12.9817630737436],
						[77.6924382101521, 12.981892384603743],
						[77.6931561151279, 12.98198153037845],
						[77.69345933206269, 12.981486565673556],
						[77.69372245136258, 12.981575814355734],
						[77.69428424218681, 12.981522247190261],
						[77.69433779168543, 12.981446481065513],
						[77.6944002515086, 12.98137062285798],
						[77.69452508369379, 12.9813216365236],
						[77.6948550278523, 12.981241384385372],
						[77.69509141297988, 12.981156664697],
						[77.69514041958998, 12.981348342947719],
						[77.69516269531474, 12.981433077813863],
						[77.69519396867766, 12.981544551402266],
						[77.69526525094699, 12.981740744342224],
						[77.69543917599879, 12.982075195867717],
						[77.69544363113216, 12.98207963530988],
						[77.69553282111778, 12.98228479279934],
						[77.6955372762474, 12.982293717762294],
						[77.69557737240613, 12.982414067062935],
						[77.69563983153532, 12.982703950247405],
						[77.69584048671094, 12.984732813666],
						[77.69589403546257, 12.986779532095902],
						[77.69583603159342, 12.990190762635642],
						[77.69546145166387, 12.990288894682235],
						[77.69481938659227, 12.990369156570598],
						[77.69448935497205, 12.990342346139002],
						[77.69419059601977, 12.99030227816361],
						[77.69357971009295, 12.990369102293752],
						[77.6927681604815, 12.990685735026673],
						[77.6922063620244, 12.991011194665159],
						[77.69188077795128, 12.991203016383036],
						[77.6913367962176, 12.991421493397526],
						[77.69081954374323, 12.991613210226905],
						[77.69027110149766, 12.991693446419355],
						[77.68977166552526, 12.991666725782359],
						[77.68925440579831, 12.991782697214186],
						[77.6887683314775, 12.991911976501],
						[77.68856775094686, 12.992036874427303],
						[77.68849192162799, 12.99207249974205],
						[77.6880102994177, 12.99151954868576],
						[77.68776953121427, 12.991399171848593],
						[77.68763578058378, 12.991350162617014],
						[77.68743065520368, 12.99119853700586],
						[77.68600822088607, 12.993062419524545],
						[77.68488001019061, 12.994667693517101],
						[77.6846125018482, 12.99502446194415],
						[77.68393472964, 12.995943041036398],
						[77.6836715872723, 12.996379988599925],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000008",
			properties: {
				AREA_SQ_KM: 5.758816614628866,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 12.9784929770063,
				LON: 77.6817701253513,
				LOST_AREA_SQ_KM: 0.2677273883449779,
				OBJECTID: 9,
				POP_F: 11539.0,
				POP_M: 13218.0,
				POP_SC: 3761.0,
				POP_ST: 234.0,
				POP_TOTAL: 24757.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Vijnana Nagar",
				WARD_NO: 81.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.66541155874826, 12.968180606288364],
						[77.66546957841089, 12.968581990395737],
						[77.66573713255048, 12.969826068843885],
						[77.66590655987383, 12.970775862389612],
						[77.66604033650137, 12.971373376327099],
						[77.66604033650137, 12.971516024231475],
						[77.66599131712864, 12.971520492995458],
						[77.6659199288059, 12.971538380893824],
						[77.66589319094015, 12.971551677319157],
						[77.665844171503, 12.971569600144715],
						[77.66571930727824, 12.971623050187182],
						[77.66563454981747, 12.971663239498971],
						[77.66550522903206, 12.971725618134363],
						[77.66539373342418, 12.971779103697285],
						[77.66531351943071, 12.97180139889573],
						[77.66525104335024, 12.971810312211419],
						[77.66520202363192, 12.971810382298633],
						[77.66514409120987, 12.97181037103017],
						[77.6650727023469, 12.971810319789725],
						[77.66504596427879, 12.971805849778844],
						[77.66501031351137, 12.971801470904127],
						[77.66474721399207, 12.971765772336989],
						[77.6645866976416, 12.97174791725561],
						[77.66419873120627, 12.971676577253069],
						[77.66383304539636, 12.971609666198201],
						[77.6634227943029, 12.971578483755133],
						[77.66314640917258, 12.971560604400494],
						[77.66303936764103, 12.971547290348214],
						[77.66292341314816, 12.971560630299347],
						[77.66281637140347, 12.971578450030051],
						[77.66280300210082, 12.971582898783575],
						[77.66252652805956, 12.971654276415604],
						[77.66225450979219, 12.971734577423932],
						[77.6621921193865, 12.971756861701342],
						[77.66189781795664, 12.971868351109762],
						[77.66171947161288, 12.9719575316119],
						[77.6616569935272, 12.971984209935467],
						[77.66165253704703, 12.971984255433416],
						[77.66161242871753, 12.971975350619855],
						[77.6615812333402, 12.971953073323208],
						[77.6615589509225, 12.971930705037343],
						[77.6615589509225, 12.971921821969055],
						[77.66154558146975, 12.971881682809908],
						[77.66154558146975, 12.97182372729612],
						[77.66154112498515, 12.9717478787128],
						[77.66152329904496, 12.971694417422812],
						[77.66150092923367, 12.971658763571533],
						[77.66148310328708, 12.971640920746722],
						[77.66145190787374, 12.97162752657771],
						[77.6614429948969, 12.971618648289004],
						[77.6614251689411, 12.971609688511323],
						[77.66140734298244, 12.97160521337531],
						[77.66132258225795, 12.97160072656542],
						[77.661086300477, 12.97158735107956],
						[77.66101936553278, 12.9715874255594],
						[77.66090786545855, 12.971582952809706],
						[77.66057345195279, 12.971565053791227],
						[77.66051106061074, 12.971560602192136],
						[77.66005623192018, 12.971551688025531],
						[77.65974409865522, 12.971547273042647],
						[77.6595612923191, 12.971538352504645],
						[77.65857131917303, 12.971542807518576],
						[77.65868727817107, 12.971324299695292],
						[77.65767955813595, 12.970793634806276],
						[77.65762599107846, 12.96990183811382],
						[77.65756359809505, 12.969429185430707],
						[77.65738078820137, 12.968889628132464],
						[77.65733167765329, 12.968470480545234],
						[77.65728711112305, 12.968301042936055],
						[77.65810756936555, 12.968220774278173],
						[77.65866945177677, 12.967427089065096],
						[77.65866499517774, 12.96742704832215],
						[77.65866053857854, 12.967427093818445],
						[77.65872293095117, 12.967172913457105],
						[77.65992690469307, 12.967012326525374],
						[77.66154558146975, 12.96682508528354],
						[77.66162134168088, 12.966829571313502],
						[77.66197812177862, 12.966258740862655],
						[77.66192455677646, 12.966160625883084],
						[77.66191564383722, 12.966129412197926],
						[77.66189336148605, 12.966071514874177],
						[77.66181760145892, 12.965924303416784],
						[77.66176403637998, 12.965812821739247],
						[77.66172838456772, 12.965737037417222],
						[77.66170164570109, 12.965679099669376],
						[77.66168381978645, 12.96564340664299],
						[77.66167936330734, 12.965634483388461],
						[77.66163916760547, 12.96554977178031],
						[77.66124682184476, 12.96557205686784],
						[77.66120662598765, 12.965460611388671],
						[77.6611264089949, 12.965139503701495],
						[77.66071169146922, 12.965228674059073],
						[77.66052888671202, 12.964987950221968],
						[77.66085884383908, 12.964912146410764],
						[77.66086330035083, 12.964809563823918],
						[77.66089895243843, 12.964631204123629],
						[77.66106393057841, 12.964332432604175],
						[77.66109966998457, 12.964220997986272],
						[77.66111749599216, 12.964198652565335],
						[77.66113086549602, 12.96418971956217],
						[77.66114869149865, 12.96418970967931],
						[77.66118434349542, 12.964189776146844],
						[77.66120662598765, 12.964194205025295],
						[77.6614251689411, 12.964238806392563],
						[77.66157232037365, 12.964265505644148],
						[77.66159014630603, 12.96426558196717],
						[77.6615946027887, 12.964265536369368],
						[77.66160351575346, 12.964261047046648],
						[77.66184434029788, 12.96430567884319],
						[77.66184434029788, 12.963757207235831],
						[77.66179968818088, 12.963137354720583],
						[77.66176849285571, 12.962834206426487],
						[77.66171501513521, 12.962691514684922],
						[77.66162134168088, 12.962468512198813],
						[77.66154558146975, 12.962192037893798],
						[77.66145636436188, 12.962143015413478],
						[77.66130921277698, 12.96214744876771],
						[77.66116651749846, 12.962169773516791],
						[77.66097925697655, 12.962214372021874],
						[77.6609748004694, 12.962200964800669],
						[77.6609302353883, 12.962009287056336],
						[77.6609302353883, 12.962004802786831],
						[77.66091232196837, 12.961937889516433],
						[77.66084547430277, 12.961616828094082],
						[77.65999375290681, 12.961732791151585],
						[77.65996255707869, 12.96163023143194],
						[77.65995364398336, 12.961585652690006],
						[77.65985559988779, 12.961251200767487],
						[77.65979757731667, 12.961077249684617],
						[77.66020784168889, 12.960894473660998],
						[77.6599179915949, 12.960292493903975],
						[77.659837686296, 12.960292448446484],
						[77.65981540353147, 12.960194369334786],
						[77.65981094697804, 12.960158713941732],
						[77.65977975109902, 12.959922406571634],
						[77.65977529454418, 12.959877782922112],
						[77.65973072898588, 12.959561156818454],
						[77.65971290275759, 12.959436300298988],
						[77.65970844620009, 12.959431861766646],
						[77.6597039896424, 12.959364990022504],
						[77.65969498914299, 12.959293675614756],
						[77.65969053258476, 12.95929363500513],
						[77.65968607602636, 12.959253495760427],
						[77.65969053258476, 12.95919998530989],
						[77.65969053258476, 12.95915988670627],
						[77.65971735931494, 12.95909744275634],
						[77.65974855521132, 12.959079617963113],
						[77.65977975109902, 12.959079643499145],
						[77.65980649042443, 12.959084112569174],
						[77.65981986008474, 12.959084061915005],
						[77.65993136124189, 12.95910638085732],
						[77.6604709518725, 12.95921341053518],
						[77.66076516966875, 12.95926240123388],
						[77.66077417008216, 12.959262400352337],
						[77.66101936553278, 12.959293611312154],
						[77.66113086549602, 12.959302563560831],
						[77.66113532199694, 12.959302604141433],
						[77.6611397784977, 12.959302558487924],
						[77.66127356081996, 12.959298088591131],
						[77.66129138679989, 12.959298078433084],
						[77.66159905927117, 12.95926242598894],
						[77.6621073592559, 12.959199972759953],
						[77.66212972894607, 12.959195523086048],
						[77.66223668396555, 12.95918218159331],
						[77.66228579236345, 12.959173232356163],
						[77.662727242689, 12.959101890645378],
						[77.66274506843756, 12.959097482409305],
						[77.66275843774714, 12.959093033626699],
						[77.66309730115202, 12.95902610082784],
						[77.66331584041622, 12.958981528825658],
						[77.66332920965763, 12.9589770800002],
						[77.66356548670673, 12.958932493181976],
						[77.66358339970233, 12.958914636476303],
						[77.66358785610566, 12.95889234229984],
						[77.66357894329882, 12.958812063321353],
						[77.66357003049126, 12.958807584323354],
						[77.66355657389809, 12.958794264727297],
						[77.66355211749352, 12.958767491578499],
						[77.66348972781076, 12.958549010494496],
						[77.6634719021807, 12.958495469520006],
						[77.66336040454809, 12.95811200504297],
						[77.66333812248436, 12.958027293426108],
						[77.66327564530167, 12.957795357338052],
						[77.6631998862096, 12.9575100122503],
						[77.66313749633059, 12.957291533872228],
						[77.66307056261222, 12.957068527167875],
						[77.66303936764103, 12.956908023006356],
						[77.66304382406602, 12.956832264938454],
						[77.66304382406602, 12.956774230338763],
						[77.66307056261222, 12.956698416184265],
						[77.66317760411394, 12.956386282136037],
						[77.66329355834364, 12.95607862748965],
						[77.66339159942984, 12.955828927649533],
						[77.66340942507266, 12.955762001064855],
						[77.66300817266116, 12.955739722558507],
						[77.66276735061929, 12.955739769462225],
						[77.66261128787295, 12.95574869560897],
						[77.66193792618397, 12.955721948621402],
						[77.66193346971497, 12.955753123952153],
						[77.66134932121507, 12.955721929535429],
						[77.66122899585773, 12.955717472256934],
						[77.66122453936052, 12.955717431727836],
						[77.66113532199694, 12.95570851119991],
						[77.66112195249363, 12.955708562069798],
						[77.6610416480578, 12.95570851817004],
						[77.66101936553278, 12.955708574199383],
						[77.66100153950956, 12.955704100498405],
						[77.66099708300331, 12.955699575934844],
						[77.6609837134835, 12.955690658737412],
						[77.66097925697653, 12.955690704435055],
						[77.66097034396209, 12.955686225569563],
						[77.66093914840594, 12.955659468818054],
						[77.6609302353883, 12.955650592157532],
						[77.66092577887922, 12.955641669800702],
						[77.66090786545855, 12.95561934205028],
						[77.6608900394176, 12.955570286799434],
						[77.660876669885, 12.955485572402024],
						[77.66087221337379, 12.955445434388425],
						[77.66192455677646, 12.955458820979347],
						[77.6619290132458, 12.955186801700405],
						[77.66192010030692, 12.955012878693994],
						[77.66249087649003, 12.954972807954244],
						[77.66250424582992, 12.954946025300377],
						[77.66250870227621, 12.954914850107258],
						[77.66251315872232, 12.954896954518201],
						[77.66251315872232, 12.954870222863986],
						[77.66250424582994, 12.95485237826957],
						[77.66247305070101, 12.954736458967982],
						[77.66245522490914, 12.954660672426254],
						[77.66242402976654, 12.954361857807243],
						[77.6624195733169, 12.954290504524455],
						[77.66241511686707, 12.954263818761467],
						[77.66241511686708, 12.954254850772918],
						[77.66231253109063, 12.954263745714872],
						[77.66229024881844, 12.954263801970528],
						[77.66221440167824, 12.954268283331729],
						[77.66218320646857, 12.95427274258941],
						[77.6614474513854, 12.954317260555673],
						[77.6614385384082, 12.95432174975873],
						[77.6610505610666, 12.954344082019588],
						[77.6610505610666, 12.9542504358804],
						[77.6610505610666, 12.954232586210402],
						[77.66104610456229, 12.954134501908369],
						[77.66104610456229, 12.95411216828605],
						[77.66104164805782, 12.954009600121154],
						[77.66103719155316, 12.953376459803511],
						[77.66103273504834, 12.95314903193423],
						[77.66102827854333, 12.953122260300134],
						[77.66102827854333, 12.95309552916965],
						[77.66102382203813, 12.95289034900883],
						[77.66102382203815, 12.952863617931449],
						[77.66099708300331, 12.952868117577198],
						[77.66005623192018, 12.952917147223136],
						[77.66003394919912, 12.952917117109973],
						[77.65992690469307, 12.952921572989332],
						[77.65960140138301, 12.952921624691804],
						[77.65954337868024, 12.952921611078885],
						[77.65936948515912, 12.952930546304435],
						[77.65911537293532, 12.952943925527057],
						[77.65901278413696, 12.952952819142308],
						[77.65900387096605, 12.952952824299677],
						[77.65842416379853, 12.952930550137301],
						[77.65816559281188, 12.952917156813875],
						[77.65801398027963, 12.952908185698183],
						[77.65783554036791, 12.952899311872969],
						[77.65722917460728, 12.952859204985312],
						[77.65707756080808, 12.952845749409812],
						[77.65697942681025, 12.952836833376049],
						[77.6567966159636, 12.952819040879762],
						[77.65646664687368, 12.952801198239808],
						[77.6563284021198, 12.952792261591666],
						[77.65609656650815, 12.952778937101773],
						[77.65588692658066, 12.952765552508064],
						[77.6555926959752, 12.952743208849464],
						[77.65556141153513, 12.952738781524015],
						[77.6554856472265, 12.952734297248648],
						[77.65537414164567, 12.95272543122362],
						[77.65535185799358, 12.952725400647452],
						[77.65517795796814, 12.952716483397243],
						[77.65507090882335, 12.95270757161558],
						[77.65499514418316, 12.952707571019772],
						[77.65473202114234, 12.952685247757454],
						[77.654718650875, 12.952680814526843],
						[77.65444224427246, 12.95265854573861],
						[77.6540275020089, 12.952631732099025],
						[77.65366187038852, 12.952605024052202],
						[77.6535236221755, 12.952600569616587],
						[77.65340320102675, 12.952596105220806],
						[77.65314461845678, 12.952578222668151],
						[77.65310450708208, 12.952578287957166],
						[77.6529707150074, 12.952573787615105],
						[77.65296180135599, 12.952573792536674],
						[77.65294843087754, 12.952573756804863],
						[77.65277898395712, 12.952578243628983],
						[77.65264964833239, 12.95256929995436],
						[77.65260507994063, 12.952560443127812],
						[77.6525694252145, 12.95254701136725],
						[77.6525515104581, 12.95253365184262],
						[77.65253813993074, 12.952520250932066],
						[77.65252476940178, 12.952506936250096],
						[77.65250694202736, 12.95247124817613],
						[77.65249802833908, 12.952417706277316],
						[77.65249357149469, 12.95240878426574],
						[77.65248911465011, 12.952386497116805],
						[77.65240889141737, 12.952150194196092],
						[77.65238651978667, 12.952069882341343],
						[77.65237760608882, 12.95204764083545],
						[77.65232412388686, 12.951882633083283],
						[77.65232412388686, 12.951878149316798],
						[77.65230629648053, 12.951851429003039],
						[77.65229292592393, 12.951824663126553],
						[77.65228401221864, 12.95180681921303],
						[77.65225281424455, 12.951784546939297],
						[77.6522126151615, 12.951757791643507],
						[77.6521234780129, 12.951717659381538],
						[77.65189609057364, 12.951646255813701],
						[77.65180686578377, 12.951601721827915],
						[77.65170435768586, 12.95153930750362],
						[77.65161067586394, 12.951481281644432],
						[77.65139665804992, 12.951338605116375],
						[77.65130743286268, 12.951280620004122],
						[77.65110678516956, 12.95111564709027],
						[77.65109787137, 12.951106770759695],
						[77.65061626296924, 12.950660806587141],
						[77.65060289221113, 12.950647492151385],
						[77.65048701224077, 12.95054046425584],
						[77.65043344174643, 12.950495824884433],
						[77.65038887247388, 12.950428938859863],
						[77.6501926800734, 12.950205978855985],
						[77.65012582601445, 12.95012129987271],
						[77.65010354131927, 12.950094539376192],
						[77.64998311649775, 12.949947372937785],
						[77.64975572471874, 12.94968877745479],
						[77.64963975648179, 12.949554930789997],
						[77.64944801973657, 12.94930968155349],
						[77.64940345007247, 12.949233915335228],
						[77.64936770693689, 12.949175988398922],
						[77.64932313724086, 12.949104619683299],
						[77.64927411055479, 12.949006481094377],
						[77.64925182569038, 12.94897083990508],
						[77.64924736871697, 12.948939586382364],
						[77.64924736871697, 12.948935188993305],
						[77.64918497107064, 12.948698799019715],
						[77.64918497107064, 12.948694401640495],
						[77.64917596972664, 12.948658705968278],
						[77.64916705577362, 12.948623014487325],
						[77.64916259879685, 12.94861409283741],
						[77.64911357204059, 12.948275220455557],
						[77.64910020110335, 12.947994271149332],
						[77.64909574412391, 12.947954136987558],
						[77.64910020110335, 12.947936329587012],
						[77.64910911506169, 12.947887264080286],
						[77.64912694297624, 12.947806981165845],
						[77.64916705577362, 12.947704483785998],
						[77.64918942804654, 12.947673220103928],
						[77.64919834199779, 12.947664334299574],
						[77.64925628266361, 12.94757510541707],
						[77.6493587929991, 12.947468090212618],
						[77.64939453613752, 12.947427981387571],
						[77.64941682097357, 12.947410164205579],
						[77.64944801973657, 12.947387858547682],
						[77.64947921849092, 12.947370036440976],
						[77.64951041723658, 12.947352214329163],
						[77.64953715901167, 12.947343232451185],
						[77.64955953121077, 12.947329902987587],
						[77.64964867039726, 12.947316489280114],
						[77.64982257899831, 12.94731652662122],
						[77.64998757344193, 12.947329847004385],
						[77.65063417803478, 12.947401198515726],
						[77.65129851907905, 12.947463653336484],
						[77.65218587402435, 12.947543923642822],
						[77.65330069438821, 12.947646445039936],
						[77.65418803348847, 12.947708946255084],
						[77.65460723191867, 12.947717858291814],
						[77.65477667607585, 12.94770000302131],
						[77.65486581111507, 12.947673222338315],
						[77.65492374885255, 12.947646502694223],
						[77.6549684037097, 12.947619751801247],
						[77.65502188465025, 12.947601872637916],
						[77.65531174740862, 12.947508203968324],
						[77.6557576819281, 12.947401213139026],
						[77.65597169141724, 12.947374449822753],
						[77.65607873970174, 12.947369994341793],
						[77.65646664687368, 12.947396807889685],
						[77.6574119847483, 12.947450255751404],
						[77.65843753362378, 12.94750822225738],
						[77.65923570056293, 12.94756178893683],
						[77.65986451299092, 12.947597433393772],
						[77.65996255707869, 12.947588493481886],
						[77.66003840574369, 12.947575130462704],
						[77.66011416697417, 12.947552795823738],
						[77.66056008238242, 12.947396731568135],
						[77.66126464782893, 12.947147049911669],
						[77.66181760145892, 12.946950827076405],
						[77.66240166013513, 12.94673237625305],
						[77.66250424582992, 12.946683298584],
						[77.66257563633039, 12.946647648018704],
						[77.66260683143075, 12.94659860989043],
						[77.66260683143075, 12.9465717938913],
						[77.66262465719848, 12.946036757583483],
						[77.66261128787295, 12.945497216148196],
						[77.66259346210309, 12.94504239498591],
						[77.66258454921709, 12.94463654512266],
						[77.66259346210309, 12.944591961980363],
						[77.66260683143075, 12.944565267481595],
						[77.6626202007568, 12.944538486762344],
						[77.66262911363997, 12.944520632951248],
						[77.66264248296338, 12.944502819486884],
						[77.66266030872544, 12.944489443768816],
						[77.66267367804511, 12.944471630304331],
						[77.66271387337572, 12.944449320778736],
						[77.66289221813598, 12.944449300356002],
						[77.66398019366513, 12.944449340213689],
						[77.66409614706374, 12.94445375534287],
						[77.66414525464626, 12.944453771857296],
						[77.66418536206866, 12.944458187206408],
						[77.66422546947669, 12.944467172418413],
						[77.66426557687036, 12.944489436116845],
						[77.6642789459984, 12.944502835546313],
						[77.66428794612945, 12.944511715189392],
						[77.66430131525482, 12.94453399569493],
						[77.66432805350074, 12.944578642945705],
						[77.66433250987444, 12.944609810145833],
						[77.6644484629082, 12.945109238821052],
						[77.66457778491426, 12.945604220956211],
						[77.6646223485439, 12.945738014136808],
						[77.6646624557954, 12.94580037282039],
						[77.66469365031442, 12.945840577278338],
						[77.66471147574995, 12.94586729589475],
						[77.66473830127751, 12.945880648216255],
						[77.66478286484326, 12.945885104012834],
						[77.66484971015862, 12.945898470142904],
						[77.66505487696887, 12.945907403579161],
						[77.66542947144852, 12.945925288174056],
						[77.66662000424735, 12.946014459900306],
						[77.6666155479647, 12.946143758242929],
						[77.66661109168189, 12.946215112938145],
						[77.66660663539886, 12.946286467674698],
						[77.66660217911569, 12.946438098842705],
						[77.66663337309423, 12.946438035980595],
						[77.66676714885321, 12.946438041688676],
						[77.66679388651022, 12.946424659702096],
						[77.66682962408757, 12.946442490066207],
						[77.66735572534236, 12.946473727330986],
						[77.66812717876452, 12.946531678824375],
						[77.66840809518676, 12.946549571852662],
						[77.66839472655185, 12.946687758567839],
						[77.66835907685096, 12.946990954705036],
						[77.66862216788115, 12.94699101034821],
						[77.6686266240839, 12.946999931982836],
						[77.67061538344964, 12.947093562612327],
						[77.67092748640721, 12.947111389737332],
						[77.67093194251817, 12.94734769391266],
						[77.67093194251817, 12.947352177758859],
						[77.67093639862894, 12.947445775191794],
						[77.67093194251817, 12.9480567473334],
						[77.67118611529806, 12.948047838768614],
						[77.67121285189931, 12.948038853658499],
						[77.67147148013048, 12.948047834254304],
						[77.67206457531645, 12.948052254503231],
						[77.67257737119756, 12.948096817961765],
						[77.67258628328786, 12.948101296018349],
						[77.6728716445486, 12.948159235540754],
						[77.67300095682144, 12.948172649666654],
						[77.67314809303653, 12.948186052006383],
						[77.67324175684462, 12.94822173654961],
						[77.67337106868958, 12.94827076312636],
						[77.67338889274296, 12.948275235304205],
						[77.67339780476858, 12.948270745520446],
						[77.6734112601785, 12.948270784000208],
						[77.67383038664913, 12.94838667107806],
						[77.67434763040971, 12.948529400657938],
						[77.67499418174636, 12.948676488792078],
						[77.67529299204705, 12.948756798326919],
						[77.67534200734471, 12.948765690493435],
						[77.67537765482041, 12.9487657529806],
						[77.67544012524675, 12.948747865971253],
						[77.67556943454986, 12.948716698051765],
						[77.67572102330598, 12.948680988082502],
						[77.67638993354169, 12.948556135490822],
						[77.67716132466403, 12.948422372703044],
						[77.67743330669583, 12.948377745604969],
						[77.67764736211807, 12.948346566193322],
						[77.67797290031335, 12.948306390217915],
						[77.67820923380913, 12.948266270195095],
						[77.67851685976626, 12.948244037187154],
						[77.67866853189474, 12.94824402837028],
						[77.67928832358054, 12.948226163760195],
						[77.67954247736759, 12.948226127155472],
						[77.67958703503626, 12.948235064543166],
						[77.67964504734691, 12.948252921788008],
						[77.6797565287509, 12.948257376493876],
						[77.67997949122528, 12.948252919964967],
						[77.6801132510229, 12.948252918259453],
						[77.68023809918074, 12.948297504004845],
						[77.68032284557651, 12.94834207428864],
						[77.6805011622674, 12.948480356294338],
						[77.68054580692109, 12.948520427184281],
						[77.68056808555764, 12.948524938937016],
						[77.68061709854243, 12.948529345990698],
						[77.68121023258466, 12.948565048641516],
						[77.6819192110365, 12.948618605337328],
						[77.68244087302341, 12.948649776001645],
						[77.68246323864787, 12.948654205535423],
						[77.6824721499501, 12.948649801486873],
						[77.68248551690212, 12.94865423308076],
						[77.6824944282026, 12.948654226851904],
						[77.68250333950235, 12.948658704675653],
						[77.68251670645064, 12.94866313626875],
						[77.68253452904588, 12.948663123808618],
						[77.68298489809474, 12.948698827203845],
						[77.68345308777683, 12.948734517250331],
						[77.68414431702526, 12.948783513930927],
						[77.68471952273484, 12.948837066538335],
						[77.6850539514242, 12.94886378966448],
						[77.68553104349263, 12.948912835528409],
						[77.68591011226222, 12.94894849816297],
						[77.68639165616811, 12.948993098255784],
						[77.68677963297522, 12.949024355325426],
						[77.68682864323483, 12.948975296600539],
						[77.68738601330145, 12.949019887586466],
						[77.68792110342028, 12.949104635120472],
						[77.68847409998335, 12.94923395578078],
						[77.68866131447093, 12.949305225893882],
						[77.6888218836227, 12.94938997203645],
						[77.68901809566765, 12.94956385839644],
						[77.689196398478, 12.949786821167805],
						[77.68932132384425, 12.949969642153016],
						[77.68937924373935, 12.950090026681925],
						[77.68941497409708, 12.95016589146875],
						[77.68943279559151, 12.950237194647451],
						[77.68945061708311, 12.950330832693663],
						[77.68946398319993, 12.950428915153077],
						[77.68947734931515, 12.950544934578199],
						[77.6894996261703, 12.950870412866825],
						[77.68952199038121, 12.951285063782754],
						[77.68950853691113, 12.951802354322373],
						[77.68948180468654, 12.95230625113992],
						[77.68942834021816, 12.952877002648345],
						[77.68940597598849, 12.95303754417265],
						[77.68938815448979, 12.95313121015525],
						[77.68935251148382, 12.953233771375853],
						[77.6892900488356, 12.953336261397274],
						[77.68920539660346, 12.95346115006628],
						[77.6890715603325, 12.95359938908526],
						[77.68881297282708, 12.95383568245772],
						[77.6880013885573, 12.954589335521268],
						[77.68776953121427, 12.954830093610475],
						[77.68768033502452, 12.954919235747315],
						[77.6876714241378, 12.95495942880769],
						[77.6876714241378, 12.955008411865006],
						[77.6876714241378, 12.955026263089907],
						[77.68768479046764, 12.955048546163143],
						[77.68771152312247, 12.955061980459194],
						[77.68773834313251, 12.955079817052576],
						[77.68777844209318, 12.955088714455314],
						[77.68783199471315, 12.955097649729089],
						[77.68793901251462, 12.95511551608224],
						[77.68808612906365, 12.955146677900721],
						[77.68911620156081, 12.955329549852431],
						[77.68962900656275, 12.955427648061924],
						[77.68958882090661, 12.955512315629473],
						[77.68990095824252, 12.955561348564498],
						[77.68977166552526, 12.961095118894123],
						[77.68979394232167, 12.961188806266348],
						[77.68969138166126, 12.961545531109756],
						[77.68953981185857, 12.96211181902098],
						[77.68896454361658, 12.962218830146359],
						[77.68896454361658, 12.962241080950282],
						[77.6879657451086, 12.962160831352655],
						[77.68636937869286, 12.962062760084093],
						[77.68162487413291, 12.961661436481736],
						[77.68186565558162, 12.962789585402604],
						[77.68209752518005, 12.96348076048811],
						[77.68215108052381, 12.96411392439847],
						[77.68169179675017, 12.96417634394133],
						[77.68108975385223, 12.964247729144292],
						[77.68067956604091, 12.964274457394408],
						[77.68039413734007, 12.964332410838717],
						[77.67954247736758, 12.964702519299857],
						[77.67908763865903, 12.964943322722789],
						[77.67852577138318, 12.965135029935078],
						[77.67809329503648, 12.964925504537355],
						[77.67756706954037, 12.964680282225784],
						[77.67734864547899, 12.964640096864414],
						[77.67665300568456, 12.964773923143197],
						[77.67621152292188, 12.964818466373046],
						[77.67566755225236, 12.964822926466368],
						[77.67515477053936, 12.964814041615174],
						[77.6744190133557, 12.96471593782136],
						[77.67391959389407, 12.964644560260552],
						[77.67372781133534, 12.964599952934712],
						[77.67355839585386, 12.964622302335256],
						[77.67356730786598, 12.964769424420114],
						[77.67359849990282, 12.96521531679695],
						[77.67359849990282, 12.965902060727597],
						[77.6735762198774, 12.966379123407858],
						[77.67356285186001, 12.966918708626224],
						[77.67354057182747, 12.967123850836602],
						[77.67347364433051, 12.967297709751552],
						[77.67275124420343, 12.967003426030008],
						[77.6726621234, 12.967092654358728],
						[77.671605250106, 12.967306653332558],
						[77.67108353755951, 12.967422553225981],
						[77.67002673672827, 12.967654485705001],
						[77.66975919284272, 12.966151783134597],
						[77.67028982383776, 12.966111600770981],
						[77.67025863087835, 12.965072630580197],
						[77.66866672990074, 12.964867534221757],
						[77.668524043999, 12.965906465661869],
						[77.66806924785631, 12.965991233549282],
						[77.66739591899209, 12.966191896230894],
						[77.6669321183476, 12.966294427815576],
						[77.66658881026508, 12.96536248882648],
						[77.66657989769713, 12.965277806421328],
						[77.66647285944808, 12.965424902766419],
						[77.6659689482099, 12.965643405010914],
						[77.66554979229255, 12.96592882717055],
						[77.66549186005045, 12.966178482137314],
						[77.6654874037229, 12.96657531960927],
						[77.66551414168558, 12.967427018659036],
						[77.66555870494253, 12.967694600025503],
						[77.66550077270504, 12.967832834477399],
						[77.66556324864617, 12.968104809281963],
						[77.66541155874826, 12.968180606288364],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000009",
			properties: {
				AREA_SQ_KM: 6.83773338754966,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 12.9565365691412,
				LON: 77.6715015074689,
				LOST_AREA_SQ_KM: 0.45436028634529857,
				OBJECTID: 10,
				POP_F: 15472.0,
				POP_M: 17594.0,
				POP_SC: 5487.0,
				POP_ST: 464.0,
				POP_TOTAL: 33066.0,
				RESERVATIO: "General",
				WARD_NAME: "HAL Airport",
				WARD_NO: 87.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.68068402176324, 13.01101923241199],
						[77.68076876782085, 13.01097911839871],
						[77.68099172827577, 13.010872031174186],
						[77.68128152378213, 13.010631310167552],
						[77.68210643651148, 13.009949032010724],
						[77.68247660560095, 13.009440733962176],
						[77.6845544921166, 13.005998284457712],
						[77.68511187353276, 13.00515101037402],
						[77.68526799224897, 13.005231298484533],
						[77.68575844996353, 13.005512216849453],
						[77.68631582537147, 13.005837750536053],
						[77.68733254782947, 13.006404064588073],
						[77.68815304780983, 13.006814320151108],
						[77.68817978035258, 13.006832164552453],
						[77.68834471825251, 13.00691235778584],
						[77.68910283540234, 13.007282529752667],
						[77.68977166552526, 13.007576819409326],
						[77.69001242941098, 13.007648131434381],
						[77.69066352014833, 13.007808705171316],
						[77.69153300384204, 13.007995948679318],
						[77.69190759695967, 13.00806287896303],
						[77.6922063620244, 13.00811634487991],
						[77.69262105010303, 13.008196581605311],
						[77.69306246801408, 13.008254542864771],
						[77.6936020734863, 13.008312542200592],
						[77.69377591377395, 13.008316976877374],
						[77.6940479427057, 13.008334823167473],
						[77.69464546057424, 13.008343737738716],
						[77.69485057269542, 13.008352687474085],
						[77.69536780648497, 13.008334809383353],
						[77.69563983153532, 13.008334880345279],
						[77.69572902136407, 13.008348206113498],
						[77.69578257016923, 13.008383898766908],
						[77.69585830717926, 13.008392784710342],
						[77.6967189275844, 13.009293554413727],
						[77.69684375717497, 13.00948977290412],
						[77.69729415585195, 13.009302433412902],
						[77.69744134742247, 13.009204384921777],
						[77.69773564262698, 13.009431745936235],
						[77.69819940277267, 13.00913745200353],
						[77.69869434601833, 13.009387178492526],
						[77.69987150895011, 13.009953483343633],
						[77.70025052900937, 13.010131833998676],
						[77.70071873361846, 13.010292361689645],
						[77.70139657859838, 13.01045290560353],
						[77.70185132641276, 13.010559898079643],
						[77.70196287299055, 13.010586716340084],
						[77.70218133624668, 13.010600096432698],
						[77.70223933681534, 13.010573275077896],
						[77.70227497570399, 13.010524245298889],
						[77.70232406650746, 13.010444020637953],
						[77.70248452864482, 13.010345911428667],
						[77.70248898349672, 13.011251054652144],
						[77.70256925815056, 13.012776068653595],
						[77.70260943913077, 13.013395957236588],
						[77.705414210451, 13.013382534194639],
						[77.70567284681752, 13.013253220420873],
						[77.70594484672552, 13.013052583541661],
						[77.70653784614983, 13.013204150011642],
						[77.70704620372692, 13.01332457560919],
						[77.7073361062619, 13.013676847368211],
						[77.70756792293142, 13.01380174095704],
						[77.70784437285052, 13.013882001322207],
						[77.70825018087291, 13.013819515569153],
						[77.70939169361668, 13.01399341659277],
						[77.7098688562767, 13.014145050820835],
						[77.71046192215515, 13.014278840807835],
						[77.71072054680181, 13.014345735648785],
						[77.71074281941299, 13.014742600103673],
						[77.71098807987214, 13.01482732209965],
						[77.71123779429315, 13.015134965703044],
						[77.71117088940002, 13.01570126904732],
						[77.71119761642481, 13.015803849051354],
						[77.7113046991461, 13.015884124712748],
						[77.71136706211904, 13.016138291557926],
						[77.71157223081293, 13.016098137348365],
						[77.71165249892192, 13.016205175367753],
						[77.71181303496633, 13.01658863710751],
						[77.71163013914983, 13.01682944283889],
						[77.71151868959382, 13.017186185340117],
						[77.71155441285707, 13.017431402085023],
						[77.71159004876596, 13.017600852775656],
						[77.71162568466346, 13.017814915621873],
						[77.71172385800833, 13.01798882344729],
						[77.71182194392391, 13.018158240319325],
						[77.7119066663282, 13.018291984764911],
						[77.71206720200612, 13.018381203566474],
						[77.71274943238389, 13.01882714938574],
						[77.7133870270884, 13.019259658915644],
						[77.71364573228183, 13.019442451353667],
						[77.71374381650419, 13.019598495623503],
						[77.71379726931205, 13.019687687760449],
						[77.71395334787047, 13.019919594044245],
						[77.71406488233018, 13.02040565986059],
						[77.71442158223262, 13.020369986391186],
						[77.71320422119926, 13.022287380289644],
						[77.71342274975217, 13.022416655213904],
						[77.71208501987995, 13.024155717837564],
						[77.71189775737739, 13.025698608090456],
						[77.71177285730555, 13.026541355259583],
						[77.71163013914983, 13.02750001509827],
						[77.71144733037593, 13.027602592993409],
						[77.71102817047274, 13.028953719630428],
						[77.70939614819243, 13.027665056891788],
						[77.7084909922096, 13.027598187391222],
						[77.70788455192859, 13.027437653127468],
						[77.70783546357472, 13.027477761739812],
						[77.70736283420695, 13.027879116903545],
						[77.70653784614983, 13.02859256908982],
						[77.70610093628937, 13.0287040360939],
						[77.70532502836981, 13.028712979174262],
						[77.70460265961651, 13.028503392390384],
						[77.70388474097669, 13.028240255644246],
						[77.70227052084353, 13.027335064498429],
						[77.7014902187149, 13.027170077683232],
						[77.70149912849801, 13.027343955524147],
						[77.70147231179571, 13.028409704399389],
						[77.70146785690318, 13.028614873352021],
						[77.7012672118528, 13.028614844715243],
						[77.70047353790515, 13.028619297134416],
						[77.6999606953948, 13.028552367869942],
						[77.69891281567908, 13.028454291537708],
						[77.69597431486426, 13.027834530461341],
						[77.69471683055015, 13.027321687261901],
						[77.69436006757043, 13.027259292879814],
						[77.69434224686279, 13.027259302082632],
						[77.69420404892547, 13.026585903095128],
						[77.69420404892547, 13.025466704043664],
						[77.69418614083636, 13.023999685658797],
						[77.69345487685008, 13.023968457053599],
						[77.69307137847045, 13.024093328565472],
						[77.69286626307861, 13.02420033956325],
						[77.69270133185974, 13.024200291403245],
						[77.69244266540531, 13.024137938920093],
						[77.69204579774107, 13.024093299485049],
						[77.6920368872028, 13.022452349943512],
						[77.69162665223932, 13.0224300248748],
						[77.69101575227697, 13.022456848893702],
						[77.6909801097907, 13.021065568807947],
						[77.69110494580181, 13.02106559442616],
						[77.6911941392554, 13.019785837016101],
						[77.68995896519674, 13.019799157652537],
						[77.69011053443033, 13.018675475217815],
						[77.6886390374485, 13.01865762913327],
						[77.68871486666745, 13.017386844918901],
						[77.68817086950571, 13.017351125895548],
						[77.68675735557738, 13.017275308386324],
						[77.68631136987376, 13.017270855674136],
						[77.6862400818861, 13.016954320368018],
						[77.68619098400539, 13.016570772151608],
						[77.68568716162294, 13.016651039003154],
						[77.68558905271706, 13.015826142430505],
						[77.68551767690502, 13.015389158718397],
						[77.68537946804261, 13.015041296207544],
						[77.68505840697206, 13.014626609391222],
						[77.68439400419314, 13.0143769255063],
						[77.68347545319864, 13.014274339343473],
						[77.68266828578513, 13.014087096808199],
						[77.68269956264815, 13.01158556178568],
						[77.68253452904588, 13.011340306737296],
						[77.68246323864787, 13.011268905245059],
						[77.68240968345373, 13.011215436251799],
						[77.68228038206358, 13.011166362902662],
						[77.68213771353177, 13.011117339478018],
						[77.68191475536342, 13.01108163930413],
						[77.68176308764042, 13.011068248597942],
						[77.68162041844809, 13.011072716849108],
						[77.6815133945927, 13.01107276682552],
						[77.6814063706348, 13.011077216884052],
						[77.68119677811336, 13.011103930809789],
						[77.68100955111424, 13.011135167017125],
						[77.68086233787784, 13.011161935416087],
						[77.6807285789768, 13.011211003790848],
						[77.68068402176324, 13.01101923241199],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000ba",
			properties: {
				AREA_SQ_KM: 5.1748461097912895,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 13.0177984033219,
				LON: 77.6984440627829,
				LOST_AREA_SQ_KM: 0.19676953779605047,
				OBJECTID: 187,
				POP_F: 12573.0,
				POP_M: 13912.0,
				POP_SC: 2483.0,
				POP_ST: 491.0,
				POP_TOTAL: 26485.0,
				RESERVATIO: "General",
				WARD_NAME: "K R Puram",
				WARD_NO: 52.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.68068402176324, 13.01101923241199],
						[77.6807285789768, 13.011211003790848],
						[77.68086233787784, 13.011161935416087],
						[77.68100955111424, 13.011135167017125],
						[77.68119677811336, 13.011103930809789],
						[77.6814063706348, 13.011077216884052],
						[77.6815133945927, 13.01107276682552],
						[77.68162041844809, 13.011072716849108],
						[77.68176308764042, 13.011068248597942],
						[77.68191475536342, 13.01108163930413],
						[77.68213771353177, 13.011117339478018],
						[77.68228038206358, 13.011166362902662],
						[77.68240968345373, 13.011215436251799],
						[77.68246323864787, 13.011268905245059],
						[77.68253452904588, 13.011340306737296],
						[77.68269956264815, 13.01158556178568],
						[77.68266828578513, 13.014087096808199],
						[77.68347545319864, 13.014274339343473],
						[77.68439400419314, 13.0143769255063],
						[77.68505840697206, 13.014626609391222],
						[77.68537946804261, 13.015041296207544],
						[77.68551767690502, 13.015389158718397],
						[77.68558905271706, 13.015826142430505],
						[77.68568716162294, 13.016651039003154],
						[77.68619098400539, 13.016570772151608],
						[77.6862400818861, 13.016954320368018],
						[77.68631136987376, 13.017270855674136],
						[77.68675735557738, 13.017275308386324],
						[77.68817086950571, 13.017351125895548],
						[77.68871486666745, 13.017386844918901],
						[77.6886390374485, 13.01865762913327],
						[77.69011053443033, 13.018675475217815],
						[77.68995896519674, 13.019799157652537],
						[77.6911941392554, 13.019785837016101],
						[77.69110494580181, 13.02106559442616],
						[77.6909801097907, 13.021065568807947],
						[77.69101575227697, 13.022456848893702],
						[77.69162665223932, 13.0224300248748],
						[77.6920368872028, 13.022452349943512],
						[77.69204579774107, 13.024093299485049],
						[77.69244266540531, 13.024137938920093],
						[77.69270133185974, 13.024200291403245],
						[77.69286626307861, 13.02420033956325],
						[77.69307137847045, 13.024093328565472],
						[77.69345487685008, 13.023968457053599],
						[77.69418614083636, 13.023999685658797],
						[77.69420404892547, 13.025466704043664],
						[77.69420404892547, 13.026585903095128],
						[77.69434224686279, 13.027259302082632],
						[77.69339687171279, 13.027406442477504],
						[77.69171138999376, 13.027598194270697],
						[77.68876387607766, 13.024766588978743],
						[77.68674835725463, 13.023081092825603],
						[77.6825523516383, 13.023049874005238],
						[77.6816828853857, 13.022933899021412],
						[77.68171853083935, 13.023237177431414],
						[77.68206633551453, 13.026224722758817],
						[77.68227147074604, 13.030648207396768],
						[77.68234276126644, 13.033265688451161],
						[77.68238740518434, 13.034723784045875],
						[77.68241413910708, 13.035620117927536],
						[77.6825122508014, 13.036498568712503],
						[77.68281104100491, 13.036850755481023],
						[77.6834308097155, 13.038152856224453],
						[77.68378306465912, 13.040154963578667],
						[77.68408630705052, 13.042861645019979],
						[77.68413086290731, 13.046870374289037],
						[77.68384107479133, 13.050669509633776],
						[77.68335497690873, 13.051922555256528],
						[77.68276194161696, 13.05232836684206],
						[77.68090698238723, 13.052881289972285],
						[77.6798189093626, 13.053233530204682],
						[77.67796398865235, 13.054143221562423],
						[77.67731291126192, 13.053688331604503],
						[77.67597964111299, 13.052377378695036],
						[77.67474892953747, 13.051757582006411],
						[77.67306788460122, 13.051521250303926],
						[77.67269331568927, 13.051445418913646],
						[77.6726219316419, 13.051338422048445],
						[77.67230537735539, 13.050995075747002],
						[77.67218943246156, 13.05105750894953],
						[77.67204229498547, 13.05108866761411],
						[77.67184159705911, 13.051093172335227],
						[77.67170337128616, 13.050968290639478],
						[77.67147148013048, 13.050794373220683],
						[77.67127978074889, 13.050571469536417],
						[77.67110144935198, 13.050366297087177],
						[77.67090966196163, 13.050152279365635],
						[77.67074024221212, 13.050054206380068],
						[77.67067331303977, 13.049938277225035],
						[77.67064212018727, 13.049764345039604],
						[77.67055736645452, 13.049706417486393],
						[77.67049498067618, 13.049635066587815],
						[77.6703656655067, 13.049501294534608],
						[77.67024080632622, 13.049389830239416],
						[77.67016496457265, 13.049224799083262],
						[77.67008921014305, 13.04912225493866],
						[77.66999554369552, 13.049010737358579],
						[77.6699509822051, 13.048783347568275],
						[77.66989296486547, 13.04852916202389],
						[77.66984840333419, 13.048292884244683],
						[77.66981721025172, 13.048043182624964],
						[77.66969680661937, 13.047646278723226],
						[77.66960759600707, 13.047347532412667],
						[77.66951392915641, 13.04716473289042],
						[77.66937124433487, 13.046874895231078],
						[77.66934896346756, 13.046598354703583],
						[77.66919736607615, 13.046210485472264],
						[77.66913052335707, 13.045880456447879],
						[77.66910815506569, 13.045572760321672],
						[77.66896992591585, 13.045438997235069],
						[77.66873366027329, 13.04531415685989],
						[77.66833679578212, 13.045291885901943],
						[77.66804242312698, 13.045033251038449],
						[77.66769466221024, 13.044854896487884],
						[77.667351269089, 13.044859358599963],
						[77.66703469989153, 13.045019842177282],
						[77.66690538072359, 13.045305226385592],
						[77.66658881026508, 13.045412290639177],
						[77.6662722389113, 13.045519268025384],
						[77.66571485095972, 13.045523707328575],
						[77.66545175309605, 13.0454747250134],
						[77.66515746023299, 13.045349853719046],
						[77.6646802812359, 13.045251742697838],
						[77.66449302658931, 13.045068934916074],
						[77.66406940876243, 13.044970810166951],
						[77.6637260919015, 13.04500202272768],
						[77.66301262908739, 13.04503321083635],
						[77.66261128787295, 13.039637731636365],
						[77.66293678243642, 13.035459521806853],
						[77.66328910192858, 13.033872157945048],
						[77.66357003049126, 13.03285994330274],
						[77.66428794612945, 13.03254332970732],
						[77.6646624557954, 13.03239616680198],
						[77.66500585716464, 13.032155352838753],
						[77.66521539264814, 13.031914579228486],
						[77.66539373342418, 13.031718384887812],
						[77.66558553026707, 13.031424037293334],
						[77.6661384625609, 13.030144290459164],
						[77.66882278420593, 13.026960485931733],
						[77.66978147362836, 13.025707484139991],
						[77.6710389765024, 13.02393722900284],
						[77.6726977717299, 13.02227846935961],
						[77.67283154023902, 13.022438988676205],
						[77.67548022862094, 13.022171472629987],
						[77.67552041935188, 13.020548344318653],
						[77.67548022862094, 13.020548315035228],
						[77.67516368242427, 13.020450216277359],
						[77.67481140031505, 13.020182670996643],
						[77.67453941190558, 13.0199954230114],
						[77.67509675589864, 13.019750106145432],
						[77.67550250826706, 13.01958069374235],
						[77.6761223175052, 13.019223959839687],
						[77.67583250862317, 13.018514980121193],
						[77.67564972856214, 13.018167131573584],
						[77.67556943454986, 13.017707892316402],
						[77.67550250826706, 13.017248605743372],
						[77.67589052288217, 13.017141585834912],
						[77.67586815594251, 13.016735774063322],
						[77.67583250862317, 13.01645486380906],
						[77.67580131720942, 13.01626760853508],
						[77.675787949458, 13.016004505794756],
						[77.67576112657943, 13.015875181483267],
						[77.67568092001815, 13.015607667434633],
						[77.67563627342155, 13.015335646784235],
						[77.67516813836647, 13.015451594764357],
						[77.67502991670447, 13.014787181246797],
						[77.67511012373268, 13.014747024552053],
						[77.67593508200645, 13.01441704220186],
						[77.6763541990182, 13.014189600535895],
						[77.67733973376824, 13.01387308971836],
						[77.67818249888052, 13.013583207677529],
						[77.6779237987998, 13.013275566407811],
						[77.67762062705508, 13.012958892667317],
						[77.67733519054286, 13.012718131136984],
						[77.67720588328186, 13.012606675910598],
						[77.6771078542992, 13.012472907344435],
						[77.67692944482496, 13.011924436859825],
						[77.67682687235389, 13.01146510337422],
						[77.67695618005324, 13.01148743373511],
						[77.67807101590664, 13.011692530791922],
						[77.678846851225, 13.011697007248292],
						[77.68012216251256, 13.01129126507993],
						[77.68068402176324, 13.01101923241199],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000c2",
			properties: {
				AREA_SQ_KM: 7.757695705763567,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 13.033613125226,
				LON: 77.6765386686356,
				LOST_AREA_SQ_KM: 0.47963674479771035,
				OBJECTID: 195,
				POP_F: 10669.0,
				POP_M: 11330.0,
				POP_SC: 3489.0,
				POP_ST: 247.0,
				POP_TOTAL: 21999.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Ramamurthy Nagar",
				WARD_NO: 26.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.66224114042247, 13.013279961178231],
						[77.66265139584476, 13.013150652916657],
						[77.6634719021807, 13.012963381990577],
						[77.66549186005045, 13.012432735186579],
						[77.66639255884955, 13.01226776156864],
						[77.66646394687089, 13.01225436237129],
						[77.66741374399734, 13.012084923597326],
						[77.66838135791534, 13.011982420980571],
						[77.66898329448205, 13.011884291820477],
						[77.66957640285749, 13.011777282635586],
						[77.67086955694869, 13.011630159067447],
						[77.67225181729502, 13.011563221499719],
						[77.67382593065362, 13.011514185746027],
						[77.67572547922597, 13.011398256729715],
						[77.67634974312328, 13.011393750628116],
						[77.67682687235389, 13.01146510337422],
						[77.67692944482496, 13.011924436859825],
						[77.6771078542992, 13.012472907344435],
						[77.67720588328186, 13.012606675910598],
						[77.67733519054286, 13.012718131136984],
						[77.67762062705508, 13.012958892667317],
						[77.6779237987998, 13.013275566407811],
						[77.67818249888052, 13.013583207677529],
						[77.67733973376824, 13.01387308971836],
						[77.6763541990182, 13.014189600535895],
						[77.67593508200645, 13.01441704220186],
						[77.67511012373268, 13.014747024552053],
						[77.67502991670447, 13.014787181246797],
						[77.67516813836647, 13.015451594764357],
						[77.67563627342155, 13.015335646784235],
						[77.67568092001815, 13.015607667434633],
						[77.67576112657943, 13.015875181483267],
						[77.675787949458, 13.016004505794756],
						[77.67580131720942, 13.01626760853508],
						[77.67583250862317, 13.01645486380906],
						[77.67586815594251, 13.016735774063322],
						[77.67589052288217, 13.017141585834912],
						[77.67550250826706, 13.017248605743372],
						[77.67556943454986, 13.017707892316402],
						[77.67564972856214, 13.018167131573584],
						[77.67583250862317, 13.018514980121193],
						[77.6761223175052, 13.019223959839687],
						[77.67550250826706, 13.01958069374235],
						[77.67509675589864, 13.019750106145432],
						[77.67453941190558, 13.0199954230114],
						[77.67481140031505, 13.020182670996643],
						[77.67516368242427, 13.020450216277359],
						[77.67548022862094, 13.020548315035228],
						[77.67552041935188, 13.020548344318653],
						[77.67548022862094, 13.022171472629987],
						[77.67283154023902, 13.022438988676205],
						[77.6726977717299, 13.02227846935961],
						[77.6710389765024, 13.02393722900284],
						[77.66978147362836, 13.025707484139991],
						[77.66882278420593, 13.026960485931733],
						[77.6661384625609, 13.030144290459164],
						[77.66558553026707, 13.031424037293334],
						[77.66539373342418, 13.031718384887812],
						[77.66521539264814, 13.031914579228486],
						[77.66500585716464, 13.032155352838753],
						[77.6646624557954, 13.03239616680198],
						[77.66428794612945, 13.03254332970732],
						[77.66357003049126, 13.03285994330274],
						[77.66328910192858, 13.033872157945048],
						[77.66293678243642, 13.035459521806853],
						[77.66261128787295, 13.039637731636365],
						[77.66301262908739, 13.04503321083635],
						[77.6637260919015, 13.04500202272768],
						[77.66406940876243, 13.044970810166951],
						[77.66449302658931, 13.045068934916074],
						[77.6646802812359, 13.045251742697838],
						[77.66515746023299, 13.045349853719046],
						[77.66545175309605, 13.0454747250134],
						[77.66571485095972, 13.045523707328575],
						[77.6662722389113, 13.045519268025384],
						[77.66658881026508, 13.045412290639177],
						[77.66690538072359, 13.045305226385592],
						[77.66703469989153, 13.045019842177282],
						[77.667351269089, 13.044859358599963],
						[77.66769466221024, 13.044854896487884],
						[77.66804242312698, 13.045033251038449],
						[77.66833679578212, 13.045291885901943],
						[77.66873366027329, 13.04531415685989],
						[77.66896992591585, 13.045438997235069],
						[77.66910815506569, 13.045572760321672],
						[77.66913052335707, 13.045880456447879],
						[77.66919736607615, 13.046210485472264],
						[77.66934896346756, 13.046598354703583],
						[77.66937124433487, 13.046874895231078],
						[77.66951392915641, 13.04716473289042],
						[77.66960759600707, 13.047347532412667],
						[77.66969680661937, 13.047646278723226],
						[77.66981721025172, 13.048043182624964],
						[77.66984840333419, 13.048292884244683],
						[77.66989296486547, 13.04852916202389],
						[77.6699509822051, 13.048783347568275],
						[77.66999554369552, 13.049010737358579],
						[77.67008921014305, 13.04912225493866],
						[77.67016496457265, 13.049224799083262],
						[77.67024080632622, 13.049389830239416],
						[77.6703656655067, 13.049501294534608],
						[77.67049498067618, 13.049635066587815],
						[77.67055736645452, 13.049706417486393],
						[77.67064212018727, 13.049764345039604],
						[77.67067331303977, 13.049938277225035],
						[77.67074024221212, 13.050054206380068],
						[77.67090966196163, 13.050152279365635],
						[77.67110144935198, 13.050366297087177],
						[77.67127978074889, 13.050571469536417],
						[77.67147148013048, 13.050794373220683],
						[77.67170337128616, 13.050968290639478],
						[77.67184159705911, 13.051093172335227],
						[77.67204229498547, 13.05108866761411],
						[77.67218943246156, 13.05105750894953],
						[77.67230537735539, 13.050995075747002],
						[77.6726219316419, 13.051338422048445],
						[77.67269331568927, 13.051445418913646],
						[77.67173901992156, 13.051779880616682],
						[77.67119948359948, 13.050999557255595],
						[77.66963433298552, 13.049822300913581],
						[77.66734681283546, 13.049657326691564],
						[77.66634799592453, 13.049755398464407],
						[77.664488570222, 13.050442153417688],
						[77.66287884884238, 13.050482253865745],
						[77.66286102311511, 13.051472221720418],
						[77.66301708551343, 13.052377358365087],
						[77.66339605584082, 13.05296596792693],
						[77.6637260919015, 13.05423687344752],
						[77.66437707360171, 13.055561214007508],
						[77.66436370448538, 13.057032684786297],
						[77.6642343822321, 13.05822327291304],
						[77.66438152997344, 13.058789539784957],
						[77.66476503941908, 13.059320235640305],
						[77.6649567499596, 13.061188550729812],
						[77.66531797576519, 13.06262884495755],
						[77.6660225112774, 13.063641097959687],
						[77.66654870370368, 13.064287616070908],
						[77.66683853663565, 13.065669968392799],
						[77.66686081800275, 13.065687785925695],
						[77.66659326654879, 13.067364405294738],
						[77.6646891939551, 13.068167092653967],
						[77.66006960155069, 13.0688403917038],
						[77.65880769364263, 13.070102338498707],
						[77.65788019406494, 13.072768870380077],
						[77.65646664687368, 13.07465949260757],
						[77.65567737364766, 13.075123277639838],
						[77.65566400349311, 13.074770978188345],
						[77.65553912792025, 13.074151160045075],
						[77.65525372248474, 13.073803353949973],
						[77.65532957433705, 13.073451131822072],
						[77.65564171989864, 13.072670786884185],
						[77.65570411395201, 13.071061007257562],
						[77.65546336359657, 13.071065454595722],
						[77.65544999341648, 13.071065500496914],
						[77.65544999341648, 13.07105212183662],
						[77.65545890687005, 13.07102536269006],
						[77.65549901740233, 13.070976284991582],
						[77.65548119050088, 13.070262820211811],
						[77.6554410799622, 13.069531540850145],
						[77.6553875118348, 13.06849260501368],
						[77.65528946374413, 13.067186041371064],
						[77.65520915512823, 13.06600886359699],
						[77.655164587754, 13.06551837347002],
						[77.65515121753828, 13.065397931086778],
						[77.65497731720157, 13.06231670501873],
						[77.65481678685228, 13.059360312797038],
						[77.65480787334765, 13.059306805642365],
						[77.65477667607587, 13.059155217980965],
						[77.65474984816294, 13.059030336672365],
						[77.65478113282951, 13.058909981108206],
						[77.6548479841129, 13.0588297446736],
						[77.6548479841129, 13.05877174777837],
						[77.65482124360433, 13.058740511823189],
						[77.6547052806061, 13.05874053534439],
						[77.65466071303153, 13.05873605814357],
						[77.6546339724783, 13.058722687285726],
						[77.65461168867905, 13.058695894972562],
						[77.65458486072663, 13.058602256518904],
						[77.65454920663011, 13.058508624491289],
						[77.65450463899349, 13.058477392877872],
						[77.65446007133914, 13.058450649108376],
						[77.6544154162795, 13.05841501176822],
						[77.65436193504961, 13.058343650729766],
						[77.65431736733855, 13.058107360056706],
						[77.65437530535947, 13.05787548997516],
						[77.65446007133914, 13.057844274893489],
						[77.65449126869905, 13.057813068449896],
						[77.65451355252223, 13.057741646459524],
						[77.65453137957758, 13.057665867224358],
						[77.6545402931042, 13.057585602377864],
						[77.6545402931042, 13.057491962636908],
						[77.65453583634097, 13.057420633585505],
						[77.65452692281401, 13.05723784439511],
						[77.65457149044177, 13.057211079427184],
						[77.65465625627311, 13.05719324132523],
						[77.654718650875, 13.057077234000277],
						[77.6547141941189, 13.056934618442325],
						[77.65467408330576, 13.056774060683528],
						[77.65460277515811, 13.056577824428308],
						[77.65455366339279, 13.056350466625618],
						[77.65455366339279, 13.05631473716791],
						[77.65451800928633, 13.056011563816586],
						[77.65450909575794, 13.055966947477108],
						[77.65451800928633, 13.055949166911487],
						[77.65464734275571, 13.055971448478306],
						[77.65483907061075, 13.05598033758318],
						[77.65480787334765, 13.055686009815608],
						[77.65475430491765, 13.055177697547853],
						[77.65474539140806, 13.054883409465033],
						[77.65473647789774, 13.054682759638691],
						[77.65469636709261, 13.054482074870466],
						[77.65456257691764, 13.054477616610038],
						[77.65449126869905, 13.054276983287073],
						[77.65468745357839, 13.05400051442872],
						[77.65483907061075, 13.053572425919837],
						[77.65479895984231, 13.053300458547287],
						[77.65443778750534, 13.052876762850005],
						[77.65443333073804, 13.052644917918839],
						[77.65446898487144, 13.052136600850691],
						[77.65474093465299, 13.051552414349969],
						[77.65461614543925, 13.051084272531957],
						[77.65461168867905, 13.050736445089768],
						[77.65480341659507, 13.050031898675513],
						[77.65499514418316, 13.049407612682476],
						[77.6546295157188, 13.048631712713089],
						[77.6543441079673, 13.047820156650438],
						[77.65398293416489, 13.04766857917211],
						[77.65338983060094, 13.047672992780406],
						[77.65275224295505, 13.047641780349153],
						[77.65176229705706, 13.047690857232432],
						[77.64986269154693, 13.048448885248508],
						[77.64895303329665, 13.048769936117043],
						[77.6482039077154, 13.049050906913633],
						[77.64768663061054, 13.049095459372326],
						[77.64697769754659, 13.049215866048074],
						[77.6466209570991, 13.049648455523936],
						[77.64655410091159, 13.0505848303802],
						[77.64711586643527, 13.051280438906078],
						[77.6472853219115, 13.052016179215581],
						[77.647254122547, 13.053077440649222],
						[77.647254122547, 13.053104193271352],
						[77.64724520844126, 13.053122059098847],
						[77.64718726673675, 13.053336049969873],
						[77.64704455348172, 13.053701726220783],
						[77.64687064057405, 13.054094119625685],
						[77.64671455569471, 13.054263598144539],
						[77.64654955643726, 13.05444636867961],
						[77.64639801559193, 13.054664911768066],
						[77.64626421551742, 13.055017211762973],
						[77.64615270076777, 13.0553649769992],
						[77.64626867260934, 13.055717234682804],
						[77.64636681598071, 13.05587333596686],
						[77.64660758586479, 13.056207791372168],
						[77.64690184004516, 13.05656450243424],
						[77.64721400906564, 13.056907838530876],
						[77.64743694895147, 13.057295808414425],
						[77.64779368680448, 13.05810732817893],
						[77.6483822755643, 13.058192052569188],
						[77.6483644475314, 13.058299071822745],
						[77.64837781855636, 13.05878957225809],
						[77.64842238862796, 13.059730444296237],
						[77.64842238862796, 13.05995336747636],
						[77.64876129537761, 13.059984634114542],
						[77.6493053093575, 13.059957864983373],
						[77.65004551370008, 13.059859769273416],
						[77.65005442758329, 13.05985976736378],
						[77.65037995861725, 13.059882015551798],
						[77.65057169376934, 13.05985526708215],
						[77.65086156803277, 13.059810722522759],
						[77.65090613711746, 13.059819602171695],
						[77.65092396474638, 13.059846352713613],
						[77.65094179237246, 13.060033630039866],
						[77.65093287855977, 13.060118383300017],
						[77.65092842165316, 13.060158472871942],
						[77.65090168020978, 13.06050188620378],
						[77.65087493876003, 13.060818459506722],
						[77.65080799771734, 13.061572046869877],
						[77.65081254201915, 13.061643381947434],
						[77.65083482657344, 13.061870836670945],
						[77.65082145584141, 13.062035813681437],
						[77.65076342859365, 13.062084886772393],
						[77.65072777328194, 13.062486132517595],
						[77.65073223019652, 13.062874123081833],
						[77.65075451476677, 13.064029038290409],
						[77.65079017007, 13.064524006277829],
						[77.65082145584141, 13.064978805002905],
						[77.65086602494203, 13.065036795199983],
						[77.65089276639391, 13.06506354539104],
						[77.6509195078394, 13.065076917782635],
						[77.6509373354662, 13.065094780018706],
						[77.65096407690108, 13.065112554144113],
						[77.65099981952793, 13.065134905028259],
						[77.65099090571985, 13.06541135323198],
						[77.65085265421371, 13.065406845648646],
						[77.65092842165316, 13.066490397459821],
						[77.6509373354662, 13.066735687408563],
						[77.65085265421371, 13.067315404008644],
						[77.65086156803277, 13.067930708057201],
						[77.65096407690108, 13.068332075856745],
						[77.65099981952793, 13.068389987755086],
						[77.65101319023871, 13.068840399184062],
						[77.65096407690108, 13.06890725236559],
						[77.65092842165316, 13.069384388441394],
						[77.65093287855977, 13.069941745708213],
						[77.65066537645917, 13.070561598768807],
						[77.65046472761736, 13.070570478513023],
						[77.65046472761736, 13.07104761376347],
						[77.65046027069214, 13.071132330448766],
						[77.65045126945049, 13.071435544704817],
						[77.65042007096652, 13.071948379528823],
						[77.65039332940194, 13.072010788827654],
						[77.65023724942391, 13.07209552898807],
						[77.65019713700926, 13.072336307365397],
						[77.65021496475087, 13.073660705820283],
						[77.65042452789332, 13.074414244831663],
						[77.65070103179073, 13.07504741501303],
						[77.65082145584141, 13.075359559410249],
						[77.6509195078394, 13.075520090248622],
						[77.65091059402496, 13.075685128038494],
						[77.65084819730392, 13.075867956260634],
						[77.65078571315772, 13.075992775899278],
						[77.65072331636718, 13.076398559911153],
						[77.65064754878915, 13.076518941059001],
						[77.65021050781574, 13.076643814487326],
						[77.64992963315383, 13.077009450443866],
						[77.64931422329953, 13.078534498037275],
						[77.64902443242887, 13.081183182163658],
						[77.64889954946158, 13.082213251622468],
						[77.64886835054601, 13.08250307673782],
						[77.64650052853911, 13.085227584195657],
						[77.64514049834061, 13.082971249477351],
						[77.64485961098578, 13.082503038486843],
						[77.64464558029233, 13.082409403719009],
						[77.64460100872049, 13.082387138732107],
						[77.64412382960704, 13.081580058555279],
						[77.64374033693709, 13.080942376953919],
						[77.64278614415693, 13.079234516097145],
						[77.6426434253052, 13.07897592528087],
						[77.64251407799564, 13.078703911364766],
						[77.64207263548111, 13.077771963450298],
						[77.64201023384527, 13.077776459529243],
						[77.6407126390291, 13.07785664698395],
						[77.63928122081957, 13.076398546153971],
						[77.63850091628782, 13.075524572297992],
						[77.63825121419669, 13.075208001824407],
						[77.63817543819854, 13.075114318304983],
						[77.63791682053733, 13.074521292962906],
						[77.6377919252116, 13.073986170789944],
						[77.63768048944686, 13.073491231807632],
						[77.63766257231359, 13.073419850437249],
						[77.637667029747, 13.073419893075036],
						[77.6370294401693, 13.06948696737753],
						[77.63694466102208, 13.069005333112651],
						[77.63695357594574, 13.069000930298962],
						[77.63663255084734, 13.067021088024312],
						[77.63682430948363, 13.065175036025916],
						[77.63711413185145, 13.06236127840739],
						[77.63712750421648, 13.062004544130172],
						[77.637149791488, 13.061407026268066],
						[77.63720328092158, 13.060104984329627],
						[77.63713641912561, 13.058825283859232],
						[77.63704727000244, 13.05712182906662],
						[77.63700269541431, 13.053416386485663],
						[77.6366236358982, 13.05112879537884],
						[77.6366236358982, 13.05111990703748],
						[77.63657906114199, 13.05049117449321],
						[77.63648536669479, 13.049242647805084],
						[77.63646307929146, 13.048894847792793],
						[77.6364051320221, 13.048096608688656],
						[77.63632935477862, 13.047351965752096],
						[77.63603953038618, 13.044542780265777],
						[77.63606627537047, 13.044177080023827],
						[77.6366236358982, 13.042946365939134],
						[77.6366459232697, 13.04289730631335],
						[77.63665038074345, 13.042883973814483],
						[77.63666821063674, 13.04284824669891],
						[77.63669495547137, 13.042785854603906],
						[77.63668158305485, 13.0425094323199],
						[77.63680647961512, 13.042603033802132],
						[77.6369848655741, 13.042701111346807],
						[77.63715870639535, 13.0427813670311],
						[77.63739949602767, 13.0428750309723],
						[77.63743961301607, 13.042910703072875],
						[77.63762245540481, 13.043053434318592],
						[77.63783204205986, 13.043160436468956],
						[77.63810403216505, 13.043254050751598],
						[77.6384786292844, 13.043316453909982],
						[77.63880856388319, 13.04334771134243],
						[77.63905835213475, 13.043347666585214],
						[77.63933033927022, 13.043316505130859],
						[77.63953992273821, 13.043249632873032],
						[77.6397851646149, 13.043178265553182],
						[77.6399590885008, 13.043115801320114],
						[77.64033804936145, 13.043026628310137],
						[77.64060111876405, 13.042924105357198],
						[77.64077504138615, 13.042861640317064],
						[77.64086427494848, 13.042817099159295],
						[77.64106494095697, 13.042736852183182],
						[77.64119420253819, 13.04267887959048],
						[77.641310179503, 13.042638732948914],
						[77.64138149610011, 13.042553980392496],
						[77.64144389808541, 13.042478203976692],
						[77.64148410102675, 13.04241128152296],
						[77.64152421655605, 13.042362216798155],
						[77.6415955330172, 13.042308701041252],
						[77.64166684943305, 13.042264159365963],
						[77.64171596684018, 13.042232958705185],
						[77.64177836863958, 13.042188332744091],
						[77.64172488138507, 13.042005497869367],
						[77.64158216118419, 13.041742430763799],
						[77.64149747287145, 13.041630921698953],
						[77.64143498351821, 13.04148380927565],
						[77.64143052623433, 13.041457017620917],
						[77.64142606895028, 13.041448087755073],
						[77.64140823981234, 13.041323232024782],
						[77.64139486795702, 13.04107804598903],
						[77.6414037825274, 13.040988821615825],
						[77.64142606895028, 13.040779265075143],
						[77.6414394408019, 13.04065444596876],
						[77.64147064178277, 13.040253069612497],
						[77.64147509906505, 13.040190639250413],
						[77.64147064178279, 13.039887468116879],
						[77.64147509906505, 13.039610958491073],
						[77.64152421655605, 13.039557496026285],
						[77.64166684943305, 13.039508450966103],
						[77.64169813774826, 13.0394861424613],
						[77.64186305674043, 13.03937017603996],
						[77.64187642854, 13.039361242102087],
						[77.64188980033796, 13.039338933615754],
						[77.6420191483668, 13.039173968608052],
						[77.64211729545411, 13.03911605048257],
						[77.642166325267, 13.039089246478763],
						[77.64233578821656, 13.039040198638755],
						[77.64264788254091, 13.039017951345548],
						[77.64295560617083, 13.038982204884324],
						[77.64303137894318, 13.038973341862786],
						[77.64308932279339, 13.03897328450718],
						[77.64341041683004, 13.038968899555693],
						[77.64362444987884, 13.038977824745922],
						[77.64375370851303, 13.038986723440145],
						[77.64379836781721, 13.039000091156453],
						[77.64388305439418, 13.039031263086544],
						[77.64386522564645, 13.038790525106837],
						[77.6438563112715, 13.038652294813053],
						[77.64386522564645, 13.038518460877533],
						[77.64390088313908, 13.038407055058846],
						[77.64399902858115, 13.038228678986744],
						[77.6440213144888, 13.038179619138713],
						[77.64405697193185, 13.038059239670906],
						[77.6441416583137, 13.037590982463996],
						[77.6441461154899, 13.037550901155736],
						[77.64415057266596, 13.037537482441346],
						[77.64418177289332, 13.037336814967924],
						[77.6441817728933, 13.037265456017781],
						[77.64418177289332, 13.037207557795904],
						[77.64417731571851, 13.037162905707138],
						[77.64417731571851, 13.03712278253042],
						[77.64417731571851, 13.037073771872732],
						[77.64417731571851, 13.037002413081591],
						[77.644208603334, 13.03688199680648],
						[77.64425763223345, 13.036708074431132],
						[77.64427100374778, 13.036672391684613],
						[77.64429774677163, 13.0366188874241],
						[77.64432003261997, 13.036560940597377],
						[77.64434677563214, 13.03651192323534],
						[77.64436014713584, 13.0364941017208],
						[77.64436460430339, 13.036445046998445],
						[77.64437797580496, 13.036346893311736],
						[77.64440926336451, 13.036271087302236],
						[77.64444492068431, 13.036213180257612],
						[77.64448949231813, 13.036173045771074],
						[77.64467678038207, 13.036066006788163],
						[77.64489972530879, 13.035958958249585],
						[77.6449576681945, 13.035914376432476],
						[77.64497103962542, 13.035847458357788],
						[77.64437797580496, 13.035851958560132],
						[77.64323649829056, 13.035927731675367],
						[77.64320529780045, 13.035753831814997],
						[77.64315172382942, 13.035401625921596],
						[77.64317409730168, 13.035120638050874],
						[77.64321421222708, 13.03468363975913],
						[77.64324986992652, 13.034157469586082],
						[77.64324986992652, 13.034153069102683],
						[77.64326769877201, 13.034081707614018],
						[77.64326769877201, 13.034077220849248],
						[77.64326769877201, 13.034068333604154],
						[77.64325878434961, 13.034063849167119],
						[77.64324095550272, 13.034063853820484],
						[77.64319638337312, 13.0340638654475],
						[77.64309823722924, 13.034059400026164],
						[77.64254982331434, 13.034050516987808],
						[77.64237590344153, 13.034028166915656],
						[77.64230450007905, 13.034001433103507],
						[77.64218415428456, 13.03399693409167],
						[77.64217523977615, 13.033996936376328],
						[77.64205480644584, 13.034037127980604],
						[77.64201469110611, 13.034050469043756],
						[77.64171596684017, 13.03414411539751],
						[77.64171150956747, 13.034144159670097],
						[77.64169368047484, 13.034148564654712],
						[77.64127897847753, 13.034282357274865],
						[77.64114517230075, 13.03434476963602],
						[77.64110505663602, 13.034362597237957],
						[77.64107831285158, 13.034362603928642],
						[77.64104702436364, 13.03434030322356],
						[77.64103810976492, 13.034326931503717],
						[77.64101582326504, 13.034291258841154],
						[77.64100245136297, 13.034264557444173],
						[77.6408999333943, 13.034054957590914],
						[77.640873189561, 13.033992495192788],
						[77.64080624255166, 13.033836464588157],
						[77.64067698052379, 13.033591151228455],
						[77.64057437485957, 13.03347525169711],
						[77.64040945397453, 13.033328137803299],
						[77.64026227477136, 13.033149781190016],
						[77.64014183938916, 13.032953557781356],
						[77.64011063803903, 13.032917887858558],
						[77.64006606466667, 13.032864317729896],
						[77.63987431156495, 13.03263247825497],
						[77.63986093952695, 13.032601290657857],
						[77.63981190870736, 13.032503157213773],
						[77.63969593019503, 13.032199987875847],
						[77.63969593019503, 13.03219550123075],
						[77.63962015517164, 13.031963638571982],
						[77.63961569781574, 13.031954709560043],
						[77.63961569781574, 13.031950222925309],
						[77.63955775217282, 13.031852178650645],
						[77.6394596028482, 13.031673769365003],
						[77.63945068812347, 13.03166039801687],
						[77.63930805243139, 13.031455257361397],
						[77.6388263934339, 13.031009379955313],
						[77.63851874588737, 13.03072848039429],
						[77.63832699014371, 13.030523306592732],
						[77.63805500057842, 13.030255772857101],
						[77.6379613947627, 13.030135392753133],
						[77.63791236311381, 13.030046234930202],
						[77.63789890344175, 13.03000158436111],
						[77.63789890344175, 13.029992697565739],
						[77.63776518063816, 13.029667198571712],
						[77.63763137027468, 13.029225737498377],
						[77.63756450873353, 13.028703983580767],
						[77.63750656203233, 13.028235808638271],
						[77.637430698131, 13.02798609165146],
						[77.63703389762784, 13.027883559824836],
						[77.6365968910466, 13.027816622935381],
						[77.63635164220848, 13.027812236423959],
						[77.63618662772102, 13.027834489559682],
						[77.63613759529747, 13.027843431410034],
						[77.63583439789298, 13.027923653693339],
						[77.63561143496706, 13.028017315172495],
						[77.6352100831049, 13.028186767060873],
						[77.63513876258912, 13.028213530334876],
						[77.63486230776009, 13.028329463383887],
						[77.63455464935745, 13.028405241232061],
						[77.63438517466732, 13.028445396341416],
						[77.63427373552342, 13.028472125640986],
						[77.63380551510494, 13.028583654796599],
						[77.6336672424335, 13.02862824520808],
						[77.6336137513165, 13.028646030843738],
						[77.63347993600865, 13.028717409223692],
						[77.63345764800917, 13.028686138819474],
						[77.63319456187742, 13.028382974066881],
						[77.63308757916795, 13.02825366950578],
						[77.63301179968715, 13.028168919922912],
						[77.6329537632336, 13.028088648469854],
						[77.63276654302409, 13.027830034109257],
						[77.63272642436719, 13.027772108652963],
						[77.63272642436719, 13.02776313588734],
						[77.63273533962551, 13.027758647438612],
						[77.63265501487058, 13.027633819684679],
						[77.63269959118345, 13.027665041440805],
						[77.63277100065176, 13.02766502909877],
						[77.63284232267027, 13.027656126040265],
						[77.63335958075898, 13.027415301896232],
						[77.63350231140761, 13.02734849441664],
						[77.63430493849485, 13.027018480401],
						[77.63472849393905, 13.026880210195095],
						[77.63535726892815, 13.026670632460855],
						[77.63627140744029, 13.02643432554275],
						[77.63726577262953, 13.0262425983485],
						[77.63820664008632, 13.026055279910004],
						[77.63933033927022, 13.025854668466648],
						[77.63941502921743, 13.025841315978408],
						[77.6397940793131, 13.02574316860277],
						[77.6399233423573, 13.025694085157493],
						[77.64036479332222, 13.02552025473422],
						[77.6418452276719, 13.024869172315032],
						[77.64355304731055, 13.02410667525825],
						[77.64541701526146, 13.023299561054035],
						[77.64732106571054, 13.022456825582541],
						[77.64978246643537, 13.021404463739064],
						[77.65186934936102, 13.020485920130826],
						[77.65354144938827, 13.019754575606262],
						[77.653902624613, 13.019620858012543],
						[77.65477667607585, 13.019317570103915],
						[77.65551684430096, 13.01909015481284],
						[77.65576659535718, 13.018992062716693],
						[77.65600288835643, 13.018902900545973],
						[77.65677878926853, 13.01855511580726],
						[77.6575011176917, 13.018238489209036],
						[77.65821907222677, 13.017935281495124],
						[77.65946761706375, 13.01739569894259],
						[77.66004286228807, 13.017137139349943],
						[77.66005623192018, 13.01713269093026],
						[77.66020338515105, 13.017070217111167],
						[77.66060464761078, 13.016891822318955],
						[77.66120662598765, 13.016655507658687],
						[77.66152329904497, 13.016521796309032],
						[77.66161242871753, 13.016472669807747],
						[77.6616569935272, 13.016450392852878],
						[77.66169718922269, 13.016428078722047],
						[77.66170164570109, 13.016423633734137],
						[77.66173284104488, 13.016401318958962],
						[77.6618041446552, 13.016343399346358],
						[77.66186662265879, 13.016263142032924],
						[77.66187553560192, 13.016249765710448],
						[77.66191118736734, 13.016191774083428],
						[77.66196020852625, 13.016098102080159],
						[77.66199149117973, 13.016035673267574],
						[77.66200040411293, 13.016017896894212],
						[77.6620093170454, 13.015982175182195],
						[77.66204496876824, 13.01586629267598],
						[77.66207170755291, 13.015674491415133],
						[77.66209844633121, 13.015442658686034],
						[77.66216092417064, 13.014871927849402],
						[77.66218766292764, 13.014595406248047],
						[77.66222777105114, 13.013743732153262],
						[77.66223222750844, 13.013538613846258],
						[77.66224114042247, 13.013279961178231],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000c3",
			properties: {
				AREA_SQ_KM: 17.761158990530678,
				ASS_CONST1: "K.R. Puram",
				ASS_CONST_: "151",
				LAT: 13.0445613371022,
				LON: 77.6532708062347,
				LOST_AREA_SQ_KM: 0.9512864574280981,
				OBJECTID: 196,
				POP_F: 13471.0,
				POP_M: 14696.0,
				POP_SC: 5677.0,
				POP_ST: 666.0,
				POP_TOTAL: 28167.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Horamavu",
				WARD_NO: 25.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54796334645714, 13.05565925709328],
						[77.5483111270707, 13.055333732589103],
						[77.54879273498415, 13.05491463186855],
						[77.5491360521277, 13.05462477977335],
						[77.54937239367, 13.054464231444875],
						[77.55010818069478, 13.053920214879737],
						[77.55057193749364, 13.05358134136429],
						[77.55072798047182, 13.053429745973473],
						[77.55091078839645, 13.05325132536251],
						[77.55178030282408, 13.05210540016183],
						[77.55215938488816, 13.051498935230908],
						[77.55242239747277, 13.05115108769529],
						[77.55251161344216, 13.051026270005927],
						[77.55255622140055, 13.050986162589556],
						[77.5526008293414, 13.050954941224406],
						[77.5530467328531, 13.050517968878852],
						[77.55446918658102, 13.048729854670096],
						[77.55469659559543, 13.048292887238205],
						[77.55496415046372, 13.047864770828797],
						[77.55529852756334, 13.047298500780677],
						[77.55549917084288, 13.047021993474363],
						[77.55570882257402, 13.046656375083838],
						[77.55614133190898, 13.045893860769176],
						[77.556413344013, 13.045630738545308],
						[77.55652477257722, 13.045452391390464],
						[77.55675663810055, 13.04497078530144],
						[77.55683692940853, 13.044703278490775],
						[77.55688599629099, 13.044217205073158],
						[77.55692614190632, 13.04378021868877],
						[77.55699305123359, 13.043102464323768],
						[77.55707325491113, 13.042638697899633],
						[77.55708217614547, 13.04262532786795],
						[77.55709555799568, 13.042616401333316],
						[77.55710447922827, 13.042616402769992],
						[77.55715800660909, 13.042616411382403],
						[77.55725614007495, 13.042656541570063],
						[77.5573452647801, 13.042710037568227],
						[77.55740325265171, 13.042736832970496],
						[77.55745231928917, 13.042736797682451],
						[77.55773771002337, 13.042549551630387],
						[77.55811222414175, 13.042295371174486],
						[77.55829956819926, 13.0421303703591],
						[77.55899961300268, 13.041693389945399],
						[77.5593474472504, 13.041416950541848],
						[77.55947671502422, 13.04111369299328],
						[77.55958822803952, 13.040797066207881],
						[77.55965959631176, 13.040676734221492],
						[77.55971749553882, 13.040600866640126],
						[77.55980670577038, 13.040534022623017],
						[77.55992713947165, 13.040502768019484],
						[77.56008316958138, 13.040467114514868],
						[77.56033733027563, 13.040458217298387],
						[77.56043992147498, 13.040467160212607],
						[77.56061379279264, 13.040507251088654],
						[77.5610106871856, 13.040105944730286],
						[77.56105083134057, 13.040163878303876],
						[77.56123808308251, 13.040422527641335],
						[77.56126930625275, 13.040467088801275],
						[77.56136297571184, 13.040632087402743],
						[77.56148332030011, 13.040837200005605],
						[77.56169287350788, 13.041207355164584],
						[77.56177762175275, 13.04135898230689],
						[77.56213436831375, 13.042001034032916],
						[77.56290575566578, 13.043307540955961],
						[77.56297266146419, 13.04342353959291],
						[77.563124314461, 13.043677711084397],
						[77.56379756735646, 13.044886050968248],
						[77.56419891093775, 13.045514846543629],
						[77.5643193399722, 13.045666399102728],
						[77.56517991845385, 13.04753477814178],
						[77.56635716873487, 13.049675132909222],
						[77.56765474481624, 13.051547960108385],
						[77.56794903024007, 13.052065227804903],
						[77.56795349042932, 13.052114277057663],
						[77.56833697859327, 13.05285001579037],
						[77.56886756315473, 13.05376415771663],
						[77.5692510482236, 13.054624755315995],
						[77.56920653428793, 13.054651499858847],
						[77.56913517202841, 13.054691695934808],
						[77.56907719015945, 13.054718391157692],
						[77.56905488943269, 13.054727317895786],
						[77.56888540376598, 13.054803133236758],
						[77.5685554394571, 13.054892302629947],
						[77.56811396967073, 13.054968124756616],
						[77.56781968467544, 13.055079558154107],
						[77.56732031670809, 13.055364989714967],
						[77.56692790464335, 13.05567269251721],
						[77.56631702645961, 13.056270221061304],
						[77.56616091747618, 13.056408373211562],
						[77.56606725198286, 13.056461894764707],
						[77.56597367386833, 13.05651990707259],
						[77.56569713696975, 13.056667055505526],
						[77.56522898172523, 13.057063917736256],
						[77.56468499916832, 13.057612403897078],
						[77.56447090370948, 13.057821942167102],
						[77.56398044167275, 13.05828570920648],
						[77.56375305127786, 13.058495292070086],
						[77.56347204859414, 13.058718234396084],
						[77.56337400796693, 13.0587762016331],
						[77.56307078989705, 13.058901085337412],
						[77.56288791411286, 13.058958989370486],
						[77.56281217494032, 13.05898576985218],
						[77.5627809521951, 13.059021441244786],
						[77.56229485579739, 13.059128472695267],
						[77.56192481592221, 13.05919092189658],
						[77.56135405481432, 13.059173047675657],
						[77.56036855369395, 13.059021403759226],
						[77.55928499984273, 13.058905547226928],
						[77.5593206840799, 13.059146275667581],
						[77.55933406566595, 13.059445023651172],
						[77.5592805393123, 13.059663561083713],
						[77.55973533759075, 13.059819639913625],
						[77.56025704144618, 13.059766112368207],
						[77.5604086980763, 13.060568729662423],
						[77.56089025463542, 13.060506349434934],
						[77.56091255696919, 13.060608899485734],
						[77.56113103214788, 13.060577661396747],
						[77.56132283166743, 13.061353589694594],
						[77.56119793899389, 13.061393686066584],
						[77.56134959436528, 13.062013483488006],
						[77.56145655763382, 13.062013500696702],
						[77.56172855698713, 13.063052443663373],
						[77.56184898864666, 13.06302567691035],
						[77.56242866807597, 13.06269574959256],
						[77.56315544965324, 13.062151732541135],
						[77.56389569507506, 13.061402581987378],
						[77.56448874501316, 13.06096113066931],
						[77.5647518164591, 13.060510821887766],
						[77.56479641960247, 13.060171912573404],
						[77.5649837526129, 13.05990438479562],
						[77.56526020379594, 13.059569913734151],
						[77.56504164912023, 13.059306845161178],
						[77.56518437875214, 13.059208767814164],
						[77.56522006113202, 13.05906157752417],
						[77.56533156849672, 13.058959051200942],
						[77.56556341600721, 13.058874272158631],
						[77.56563478051699, 13.058887658046036],
						[77.56564816135757, 13.058918851139417],
						[77.56557679685622, 13.059418324107009],
						[77.56542960743022, 13.05974828421184],
						[77.56531818761874, 13.059962291933028],
						[77.56527358468077, 13.060443912697899],
						[77.56525574350066, 13.060675751145052],
						[77.565269124386, 13.061072611886585],
						[77.56530034644565, 13.061277755708915],
						[77.5654206868528, 13.061491798969104],
						[77.56571051780294, 13.061808378243633],
						[77.56600043546786, 13.062116075522447],
						[77.5662501226359, 13.062388083752655],
						[77.56645074653262, 13.062588683512564],
						[77.5666023948744, 13.062651180609313],
						[77.56699480806677, 13.062771526942717],
						[77.56713744778266, 13.0628205576889],
						[77.56742727434066, 13.06289640573535],
						[77.56758784178261, 13.062918695371355],
						[77.56772610800856, 13.062918676341866],
						[77.56806945528814, 13.0628830527053],
						[77.56822101400834, 13.062887475093179],
						[77.56829237684389, 13.063074726337453],
						[77.568100676576, 13.06318622128684],
						[77.56728909520514, 13.06352958932509],
						[77.56635270848277, 13.06382392491759],
						[77.56615645721635, 13.06389524685095],
						[77.56613861617528, 13.063962114727927],
						[77.56622336109537, 13.064131551897784],
						[77.56631256620592, 13.06436341790059],
						[77.56629918544381, 13.064408068299505],
						[77.56625904314802, 13.064457114323007],
						[77.56620105980674, 13.06445706041519],
						[77.56608063277237, 13.064434819431247],
						[77.56605387119177, 13.06443032758726],
						[77.56591569038099, 13.06441248802667],
						[77.56583094523076, 13.06441251530842],
						[77.56572835891144, 13.064439287751116],
						[77.56567938331277, 13.064497223582748],
						[77.5656035585495, 13.064706840451853],
						[77.56548759116632, 13.065143770394542],
						[77.5654206868528, 13.065371164738433],
						[77.56540284569586, 13.065415771296406],
						[77.56538500453611, 13.065451490377638],
						[77.56536270308247, 13.065478191686406],
						[77.56534048908114, 13.0654960960389],
						[77.56531372732573, 13.065509465250573],
						[77.56528696556401, 13.065518347570464],
						[77.56524682290954, 13.065522783553945],
						[77.56517991845385, 13.065522813745313],
						[77.56511747425957, 13.065522801638386],
						[77.56507287124258, 13.065545141120197],
						[77.56502389536057, 13.065589702686026],
						[77.56492576861896, 13.065763636787091],
						[77.56483210210453, 13.065941929338269],
						[77.56476073708917, 13.066098007622207],
						[77.56458241181144, 13.06641464858327],
						[77.56435948295525, 13.066927411293578],
						[77.56414092654218, 13.067435728147782],
						[77.56403396580706, 13.067685512664958],
						[77.56400720374306, 13.06775238001898],
						[77.56398490201825, 13.067788055343634],
						[77.56398936236356, 13.067819249236168],
						[77.56398490201825, 13.067854928254903],
						[77.56398044167275, 13.067886120324175],
						[77.56396260028903, 13.067908465147926],
						[77.56394029855542, 13.067935166578328],
						[77.56391799681744, 13.067953066662541],
						[77.5639001554239, 13.06797981215945],
						[77.56383325017306, 13.068198235809557],
						[77.56373967021348, 13.06847024325763],
						[77.56369952701087, 13.068617399161985],
						[77.56367722522549, 13.068662048589468],
						[77.56365046307725, 13.06869767997199],
						[77.56362816128222, 13.068715493785113],
						[77.56359693876182, 13.068755654357847],
						[77.56357017659461, 13.068773510370004],
						[77.56353895405822, 13.06879580927937],
						[77.56336954759739, 13.068942985361824],
						[77.56267836311453, 13.069018736244406],
						[77.56184898864666, 13.068996478562081],
						[77.56125592489514, 13.068969736160819],
						[77.56094378022907, 13.068907284808486],
						[77.56072530468951, 13.068858185618302],
						[77.56051566223265, 13.06875118520247],
						[77.56029718586801, 13.068340974031136],
						[77.55799633655629, 13.068876063694864],
						[77.55802310003936, 13.068938455426563],
						[77.55864285657707, 13.070352021897476],
						[77.55814790874801, 13.070606159349571],
						[77.55824158078596, 13.07104318901096],
						[77.55797403364889, 13.071676400277301],
						[77.55758158922343, 13.072184722030697],
						[77.55692614190632, 13.072568168537742],
						[77.55658722150491, 13.072621672227159],
						[77.55610118601588, 13.071671906105914],
						[77.55554377762664, 13.07050805205026],
						[77.55528514550113, 13.070057744413425],
						[77.55517817641109, 13.06983475632152],
						[77.55486155431063, 13.06933089648619],
						[77.55449595089638, 13.068581763832187],
						[77.55415256250177, 13.068688804772801],
						[77.55316708626641, 13.066619764815027],
						[77.55311355700242, 13.066650986072393],
						[77.5513656202327, 13.067342135029234],
						[77.55039350243506, 13.065384564452891],
						[77.5506432239705, 13.065067969545087],
						[77.55044703298209, 13.06445264695487],
						[77.55060753700363, 13.064216272590052],
						[77.55062091961751, 13.064198458898469],
						[77.55062091961751, 13.064185085268674],
						[77.55078597172573, 13.063757042575373],
						[77.55094647526796, 13.063462687882039],
						[77.55095985784189, 13.06332891258417],
						[77.55094201440964, 13.06308369789275],
						[77.55088848409605, 13.06283847476451],
						[77.55083049289452, 13.062240925679355],
						[77.55112928284066, 13.062160625897173],
						[77.55116943049303, 13.061951104683363],
						[77.55113374369164, 13.061415988530493],
						[77.55111143943506, 13.060983421052219],
						[77.55112036113822, 13.060760477758793],
						[77.55138346359836, 13.060140666269225],
						[77.55157073130098, 13.059614493835165],
						[77.55156627046719, 13.05930238101409],
						[77.551477141222, 13.058593332210476],
						[77.55145929787108, 13.057790776038258],
						[77.55134777686425, 13.057781820317977],
						[77.55096877955697, 13.057692658942205],
						[77.55046041561491, 13.057576677611872],
						[77.55006357177417, 13.057483024682616],
						[77.54980046625441, 13.05750535465442],
						[77.54951068210886, 13.057527682064645],
						[77.54935901090892, 13.057527645387266],
						[77.54862776744726, 13.057527656540579],
						[77.54806140040496, 13.057541027389256],
						[77.54796334645714, 13.05565925709328],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000000a",
			properties: {
				AREA_SQ_KM: 4.243599833567142,
				ASS_CONST1: "Byatarayanapura",
				ASS_CONST_: "152",
				LAT: 13.0565951296542,
				LON: 77.558692495766,
				LOST_AREA_SQ_KM: 0.14859125438124712,
				OBJECTID: 11,
				POP_F: 10313.0,
				POP_M: 11327.0,
				POP_SC: 3188.0,
				POP_ST: 286.0,
				POP_TOTAL: 21640.0,
				RESERVATIO: "General",
				WARD_NAME: "Dodda Bommasandra",
				WARD_NO: 10.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53671745784814, 13.090957513015345],
						[77.53644102461232, 13.09035997666363],
						[77.53629388487536, 13.089878430595963],
						[77.53540203203808, 13.088469317372674],
						[77.53411336012894, 13.087394682248712],
						[77.53311896187846, 13.086828434642399],
						[77.5321557861105, 13.086213057763882],
						[77.53116145822919, 13.085637843662633],
						[77.53077347028749, 13.085624446176919],
						[77.53026965262941, 13.085490631601074],
						[77.52981036214501, 13.085347942766573],
						[77.52933768468787, 13.085053677742073],
						[77.52885608181678, 13.084674672149424],
						[77.52869108534169, 13.084322412838224],
						[77.52865092974766, 13.084072715399575],
						[77.52828532947186, 13.08383191219592],
						[77.52845032653524, 13.083492987946604],
						[77.52971666665852, 13.082503043490194],
						[77.53020272755995, 13.081972421273186],
						[77.5307868552374, 13.0812367256903],
						[77.53095631031663, 13.080327051589796],
						[77.53108561040867, 13.078360567873299],
						[77.53135768251751, 13.075395249587753],
						[77.53121937198658, 13.073870258016008],
						[77.53138882642159, 13.072608342722829],
						[77.53162074325937, 13.072135683146543],
						[77.53138436479541, 13.071841335183095],
						[77.5308939347799, 13.071743240909743],
						[77.5304168001601, 13.071770039243866],
						[77.53009127317387, 13.071836923716104],
						[77.52978359201389, 13.071903814319588],
						[77.52973005173274, 13.07149352604981],
						[77.52973005173274, 13.07144451830156],
						[77.52971220496676, 13.071141319769614],
						[77.52970774327483, 13.070797963339428],
						[77.52974789849593, 13.069941774215652],
						[77.52977466863545, 13.069665342839027],
						[77.52988612333165, 13.069241702654123],
						[77.52992181680625, 13.068858203188531],
						[77.52999311623795, 13.068697659158621],
						[77.5301848808681, 13.068488109404633],
						[77.53039003017165, 13.068309754357402],
						[77.53086716490373, 13.067810315074277],
						[77.5312506034115, 13.067391210675169],
						[77.53170542646816, 13.066931925471252],
						[77.53209787282982, 13.066508255203525],
						[77.5325839235578, 13.066013304022318],
						[77.53275337596011, 13.06584390818559],
						[77.53282913518689, 13.06578142161745],
						[77.53287375087419, 13.065772550308292],
						[77.5330208950365, 13.06578592648696],
						[77.53315019278183, 13.065799296475625],
						[77.53352032644851, 13.065808171541642],
						[77.53380568984757, 13.065803773561147],
						[77.53380568984757, 13.065839406471387],
						[77.53406874492792, 13.065843844127699],
						[77.53424265663229, 13.065313244707003],
						[77.53438979902357, 13.064831643733834],
						[77.53451918269307, 13.06455961040158],
						[77.53459940224386, 13.064323318872065],
						[77.5347331596896, 13.063917510695967],
						[77.53512113412454, 13.063306659110996],
						[77.53478223612304, 13.062459409441512],
						[77.53450579818238, 13.061549723859581],
						[77.53416689834708, 13.060439438116054],
						[77.53392160212124, 13.059944451605546],
						[77.53418474441419, 13.059529769686362],
						[77.53451025968612, 13.058909945326569],
						[77.53497845416491, 13.058232187119447],
						[77.53541095497508, 13.05761679404258],
						[77.53512113412454, 13.057536523052162],
						[77.53486245548825, 13.057465186456076],
						[77.53487137846757, 13.057344839190302],
						[77.53494722376361, 13.057228869384051],
						[77.53510774968467, 13.057175339352487],
						[77.53514790299951, 13.057028214308279],
						[77.53513005708356, 13.056881114817395],
						[77.53513898004188, 13.056751795727267],
						[77.53517913334571, 13.056551096286675],
						[77.53519689177404, 13.056350429233632],
						[77.53515682595645, 13.056163168654141],
						[77.53512113412454, 13.05602495083086],
						[77.53509436524322, 13.055913480023344],
						[77.53509436524322, 13.055748442962472],
						[77.53516128743465, 13.05550323729498],
						[77.5353931091004, 13.054834353757576],
						[77.53610659198584, 13.054740685756519],
						[77.53711880971696, 13.054575704128313],
						[77.5371678851207, 13.054571275373936],
						[77.537274871177, 13.054553443968075],
						[77.53752899546146, 13.05451326328657],
						[77.53818009340242, 13.05440628797558],
						[77.53875971876877, 13.054317057943987],
						[77.53892023930669, 13.054303726741278],
						[77.53915214078432, 13.054308187239197],
						[77.53984329360753, 13.054450839699971],
						[77.54139957487466, 13.054749602764439],
						[77.54172953070726, 13.054807571972093],
						[77.54234928874114, 13.05491456941104],
						[77.54277292662057, 13.054954747088152],
						[77.5432545580207, 13.054959179186888],
						[77.5442577898454, 13.054936940615407],
						[77.54452983042962, 13.054945849329703],
						[77.54467249839841, 13.054950281583087],
						[77.54477064266038, 13.054968165235417],
						[77.54486869936505, 13.055008216844643],
						[77.5449757656435, 13.05507070604993],
						[77.54514966065845, 13.055208913047188],
						[77.54542161163447, 13.05545860655295],
						[77.54579179281069, 13.05580193715873],
						[77.546523054537, 13.056524308807557],
						[77.54666125897533, 13.05667596758851],
						[77.54668802513193, 13.056702719584752],
						[77.54671925230663, 13.056756261117119],
						[77.54671479128218, 13.056769589491514],
						[77.54671925230663, 13.05682312431299],
						[77.54671925230663, 13.056849869601853],
						[77.54674155742616, 13.056827573105293],
						[77.54697790395304, 13.05658230508371],
						[77.5474594300513, 13.056118598132407],
						[77.5479187366937, 13.055699450218269],
						[77.54796334645714, 13.05565925709328],
						[77.54806140040496, 13.057541027389256],
						[77.54862776744726, 13.057527656540579],
						[77.54935901090892, 13.057527645387266],
						[77.54951068210886, 13.057527682064645],
						[77.54980046625441, 13.05750535465442],
						[77.55006357177417, 13.057483024682616],
						[77.55046041561491, 13.057576677611872],
						[77.55096877955697, 13.057692658942205],
						[77.55134777686425, 13.057781820317977],
						[77.55145929787108, 13.057790776038258],
						[77.551477141222, 13.058593332210476],
						[77.55156627046719, 13.05930238101409],
						[77.55157073130098, 13.059614493835165],
						[77.55138346359836, 13.060140666269225],
						[77.55112036113822, 13.060760477758793],
						[77.55111143943506, 13.060983421052219],
						[77.55113374369164, 13.061415988530493],
						[77.55116943049303, 13.061951104683363],
						[77.55112928284066, 13.062160625897173],
						[77.55083049289452, 13.062240925679355],
						[77.55088848409605, 13.06283847476451],
						[77.55094201440964, 13.06308369789275],
						[77.55095985784189, 13.06332891258417],
						[77.55094647526796, 13.063462687882039],
						[77.55078597172573, 13.063757042575373],
						[77.55062091961751, 13.064185085268674],
						[77.55062091961751, 13.064198458898469],
						[77.55060753700363, 13.064216272590052],
						[77.55044703298209, 13.06445264695487],
						[77.5506432239705, 13.065067969545087],
						[77.55039350243506, 13.065384564452891],
						[77.5513656202327, 13.067342135029234],
						[77.55311355700242, 13.066650986072393],
						[77.55316708626641, 13.066619764815027],
						[77.55415256250177, 13.068688804772801],
						[77.55449595089638, 13.068581763832187],
						[77.55486155431063, 13.06933089648619],
						[77.55517817641109, 13.06983475632152],
						[77.55128086420756, 13.071417761065554],
						[77.55170009540457, 13.072269441455909],
						[77.55237787692847, 13.073776640880565],
						[77.5530467328531, 13.075074176094354],
						[77.55342126245965, 13.075965996911616],
						[77.55199879559429, 13.076911322708261],
						[77.55157519213459, 13.077272552105438],
						[77.55199433477732, 13.077923562875027],
						[77.55224851359752, 13.078266945411144],
						[77.55255622140055, 13.07879311229705],
						[77.55232434729217, 13.078935776485158],
						[77.55304227207732, 13.080349328422445],
						[77.55317154703728, 13.080304705218524],
						[77.55330090931594, 13.081245565615195],
						[77.55343910550208, 13.081954643699595],
						[77.554500411615, 13.081606825449436],
						[77.55542342673459, 13.081285755796387],
						[77.55581579116365, 13.081165305140468],
						[77.55614579256289, 13.081089541103282],
						[77.55614133190898, 13.081120735546918],
						[77.55614579256289, 13.081151932522985],
						[77.55650693002013, 13.08143287437334],
						[77.55681016564552, 13.081678121526343],
						[77.5568324687818, 13.081776202988033],
						[77.55677001998914, 13.081954558984785],
						[77.55672550115099, 13.082003653029973],
						[77.55668535546467, 13.082231074902868],
						[77.55655153640758, 13.08266804110638],
						[77.55718477029001, 13.083149662274433],
						[77.55802310003936, 13.083823005978566],
						[77.55818805391661, 13.08398351892793],
						[77.55816129047244, 13.084023639849102],
						[77.55810330298844, 13.084054820118094],
						[77.55792496723717, 13.084126142110756],
						[77.557648498164, 13.084206410908829],
						[77.55772878884059, 13.084411563936307],
						[77.55801417887905, 13.085031348999907],
						[77.55781791317429, 13.085102747868328],
						[77.55756820743058, 13.085196400334912],
						[77.55703756996674, 13.085410404476715],
						[77.55609226470438, 13.085793912419742],
						[77.5556463731627, 13.08601238192164],
						[77.55554377762664, 13.086043634334567],
						[77.55550809220104, 13.086048024827173],
						[77.55548587626863, 13.086048065520538],
						[77.55540558400426, 13.086048041336312],
						[77.5548169472586, 13.08578940817043],
						[77.55425069856778, 13.08557095762549],
						[77.55377357456221, 13.085361332244107],
						[77.55343464474173, 13.085151788281065],
						[77.55287276246824, 13.084754915356656],
						[77.55268549691425, 13.084679092963052],
						[77.55256068219542, 13.084656793920079],
						[77.55244024067225, 13.084652398532251],
						[77.55236003371918, 13.084634514395141],
						[77.55227081763061, 13.084603333513684],
						[77.55210585514878, 13.084491834248455],
						[77.55183383271702, 13.0842465422597],
						[77.55146821954689, 13.083818494051101],
						[77.55126310829334, 13.083501901277176],
						[77.55112482198953, 13.083377031489608],
						[77.55096877955697, 13.083270023659335],
						[77.55083941461976, 13.08319425894325],
						[77.55068783266329, 13.083078321102793],
						[77.55054071137864, 13.082868711547084],
						[77.55026868519548, 13.082373773066053],
						[77.55019285012725, 13.082257812613534],
						[77.55008587623666, 13.082204280719647],
						[77.54986737978385, 13.082190970016365],
						[77.54938577642952, 13.082164161269418],
						[77.54921188790196, 13.082155265111826],
						[77.54893985853433, 13.08212847464948],
						[77.54883288346295, 13.082106180856542],
						[77.54877935215472, 13.08205266149554],
						[77.54851624365847, 13.081713835406918],
						[77.54844941680234, 13.081687024091451],
						[77.5481996904408, 13.081785150278487],
						[77.54805693943325, 13.081816342588809],
						[77.5471696399302, 13.081990259115784],
						[77.54727215556619, 13.08231134370091],
						[77.54747727403097, 13.083377053005973],
						[77.54749511800782, 13.083466201182992],
						[77.54751296198188, 13.083595562759463],
						[77.54719194496127, 13.08360892691642],
						[77.54650521040739, 13.083626746198687],
						[77.54646060007109, 13.08357771601208],
						[77.54513181631305, 13.083564352195324],
						[77.54514966065845, 13.084170748165826],
						[77.54513181631305, 13.084424965887424],
						[77.54510951087734, 13.084772769387937],
						[77.54503367236316, 13.084790563111719],
						[77.5446412706609, 13.08482624804737],
						[77.54369148848154, 13.084924356292609],
						[77.54349974663238, 13.084915447755865],
						[77.54331692677589, 13.084928806210637],
						[77.54240728427408, 13.085374732482416],
						[77.54110075932779, 13.086012393682081],
						[77.54058797742172, 13.086324561262103],
						[77.53858581397375, 13.088768125206549],
						[77.53671745784814, 13.090957513015345],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000000b",
			properties: {
				AREA_SQ_KM: 7.5634295702144465,
				ASS_CONST1: "Byatarayanapura",
				ASS_CONST_: "152",
				LAT: 13.0720798190444,
				LON: 77.5418693523628,
				LOST_AREA_SQ_KM: 0.2618338352096258,
				OBJECTID: 12,
				POP_F: 11806.0,
				POP_M: 13489.0,
				POP_SC: 4961.0,
				POP_ST: 942.0,
				POP_TOTAL: 25295.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Kuvempu Nagar",
				WARD_NO: 11.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59935446064054, 13.099840060979824],
						[77.59950606485526, 13.099822213994704],
						[77.60133876358921, 13.099354049291524],
						[77.60170439050626, 13.099313871264993],
						[77.60219495120597, 13.099353990523086],
						[77.602373304594, 13.099006228173439],
						[77.6023242574404, 13.098957149035533],
						[77.60220832771985, 13.098841206672711],
						[77.60217711585166, 13.098622683722377],
						[77.60228412793523, 13.098359594063131],
						[77.60236884576274, 13.09827937023303],
						[77.6024357282132, 13.098216926071489],
						[77.60254274005143, 13.09802522310905],
						[77.60253828122687, 13.09781119336894],
						[77.60242235172628, 13.097641734598923],
						[77.6023242574404, 13.097623854739593],
						[77.60200767984503, 13.097628348202228],
						[77.60178019106027, 13.097610491274065],
						[77.60160183673446, 13.097637228220327],
						[77.60152603606062, 13.097650641448308],
						[77.60124958611262, 13.097668475022303],
						[77.60101772434355, 13.0976684458407],
						[77.60090625216957, 13.097570416659837],
						[77.60073235535823, 13.097485672723762],
						[77.60046928018548, 13.097262721415763],
						[77.60028200595991, 13.097075386560737],
						[77.60016598671093, 13.097012959355887],
						[77.60009018507236, 13.097012992774031],
						[77.59992074593144, 13.097004080133873],
						[77.59978251907538, 13.096977346743424],
						[77.59972901185982, 13.09695947193887],
						[77.59968442249415, 13.096914926018128],
						[77.59968888143152, 13.09689261339014],
						[77.59969779930572, 13.09687025818659],
						[77.59972901185982, 13.096821276783952],
						[77.59988507450092, 13.096589403858966],
						[77.60002330123133, 13.09637979875951],
						[77.60007680830734, 13.096304018594935],
						[77.60013031535794, 13.096237129158848],
						[77.60016598671093, 13.096130099797085],
						[77.60030430052689, 13.095702029866095],
						[77.60043806783612, 13.09533191814226],
						[77.60053170485826, 13.095104527335145],
						[77.60062534180264, 13.094828022538321],
						[77.60073681425418, 13.094399948439275],
						[77.60079477988545, 13.094105659494204],
						[77.60082153324367, 13.093731050456345],
						[77.60084828659558, 13.09341004463061],
						[77.60087503994112, 13.093222742519735],
						[77.60091071105865, 13.093026551173248],
						[77.60099542991757, 13.092736670231787],
						[77.60115594968632, 13.0923354084524],
						[77.6012897159858, 13.092000952346051],
						[77.60137889343068, 13.091840416295097],
						[77.60144131760016, 13.091751259783116],
						[77.60151265946583, 13.09166210423004],
						[77.60171330822114, 13.091390018374812],
						[77.60208348019525, 13.09089060202885],
						[77.60241343406746, 13.090467004756333],
						[77.6026631282481, 13.090101402894323],
						[77.60284148089586, 13.089780332487315],
						[77.60296186877368, 13.089539571840277],
						[77.60314913855042, 13.089138231493205],
						[77.60321156174024, 13.088964315887907],
						[77.60328736128143, 13.088714575599628],
						[77.60336324819897, 13.088201844831874],
						[77.6034167536908, 13.08783614304334],
						[77.60344350642718, 13.087697959157744],
						[77.60346134158125, 13.087644447016588],
						[77.60349255309409, 13.087595381104324],
						[77.60352376459828, 13.087559693601417],
						[77.60357727001394, 13.087510672721454],
						[77.60375562121618, 13.087327791940506],
						[77.60396518351841, 13.08710482165651],
						[77.6040811114332, 13.086988913887373],
						[77.60411678153686, 13.086904244664495],
						[77.6041435341072, 13.08680162189701],
						[77.60417474543122, 13.086525210801897],
						[77.6040409825531, 13.086560847055646],
						[77.60384033793844, 13.086560874327702],
						[77.60366198687016, 13.086507346874717],
						[77.60334532560209, 13.086413712230671],
						[77.60321156174024, 13.08631116342601],
						[77.60301091565002, 13.086190785954997],
						[77.60287269258022, 13.086052547348281],
						[77.602863774957, 13.086021302026623],
						[77.60285039852084, 13.08597223313247],
						[77.60287269258022, 13.08593654538975],
						[77.60343458884908, 13.085156259091434],
						[77.60465191916408, 13.084228731749063],
						[77.60755032590147, 13.081673716692757],
						[77.60757707765715, 13.080371579796417],
						[77.60767962599512, 13.0801085603618],
						[77.60775988115105, 13.07982314350114],
						[77.60773312943877, 13.07961807525506],
						[77.60762612252609, 13.079337139916163],
						[77.60741656702784, 13.079234555129698],
						[77.60740319113175, 13.079238999407549],
						[77.60737189327719, 13.079212281792906],
						[77.60715787866445, 13.079047253649703],
						[77.60661392302849, 13.078436351423461],
						[77.60642657158063, 13.077994882536828],
						[77.60600745573525, 13.077022803904805],
						[77.605677512378, 13.07670622306737],
						[77.60521817786123, 13.076242527336158],
						[77.60483918613451, 13.075207941033122],
						[77.60441551821204, 13.074106561057754],
						[77.60411678153686, 13.072684159471251],
						[77.60381358529618, 13.07150251256692],
						[77.6036887395484, 13.07087372291994],
						[77.60351484702653, 13.070146942112597],
						[77.60346134158125, 13.069518162649805],
						[77.6034256712703, 13.068822591972708],
						[77.60580235592401, 13.067966437397258],
						[77.6081031942287, 13.067194952736658],
						[77.60821920531245, 13.067137050599094],
						[77.60918234622528, 13.06682491006482],
						[77.60963711862375, 13.066673280292024],
						[77.60968179147662, 13.066659907871275],
						[77.60970408418524, 13.06665986454834],
						[77.60973529396989, 13.0666554199136],
						[77.60976650374589, 13.066655462811289],
						[77.60981554766215, 13.066659906795694],
						[77.60984675741594, 13.066677727241697],
						[77.60990471836432, 13.066726787653268],
						[77.60999834752565, 13.067012134531844],
						[77.61002509870032, 13.067221755077647],
						[77.61019898118086, 13.06781035968241],
						[77.6102480249089, 13.067953056096751],
						[77.61029706861562, 13.068011006159997],
						[77.61035065823866, 13.068042250618188],
						[77.61039970190069, 13.06805109603552],
						[77.61061371035802, 13.068028829064744],
						[77.61140295045283, 13.067926257877268],
						[77.61235277626767, 13.067846036843507],
						[77.61286112430003, 13.067770174031873],
						[77.61295475100478, 13.067752351191928],
						[77.61304391922268, 13.067730083920514],
						[77.61312417055844, 13.067698841541002],
						[77.61326683945863, 13.06762746810832],
						[77.61343188756874, 13.067507122820919],
						[77.61357455607923, 13.067355489839645],
						[77.61378855850617, 13.066909571558186],
						[77.61396252259777, 13.066481480413756],
						[77.61424339973239, 13.065848335214977],
						[77.6143637754328, 13.065540631235539],
						[77.61438606721507, 13.065527297279267],
						[77.61504607680834, 13.065683312423173],
						[77.61583974414968, 13.065723502766645],
						[77.6167806160311, 13.065710052911596],
						[77.6171864044877, 13.06573243405601],
						[77.61728894407952, 13.06587063910218],
						[77.61740494570465, 13.06603564422424],
						[77.61753869272019, 13.066378941578058],
						[77.61763677376393, 13.066606339628041],
						[77.61774377116879, 13.066677705665551],
						[77.618127265117, 13.06672679765649],
						[77.61834134630841, 13.066726791842248],
						[77.61847955040565, 13.066713365419803],
						[77.61867133999762, 13.066566260078893],
						[77.61882737641186, 13.066459195832426],
						[77.61903691068505, 13.066276442744783],
						[77.61939365128508, 13.066249674815742],
						[77.61981726288901, 13.06614708702115],
						[77.62003579899904, 13.065812619377134],
						[77.62043711760649, 13.065580795741313],
						[77.62055748668551, 13.065411336822859],
						[77.62111938217929, 13.065380155326935],
						[77.62226982560436, 13.065357825310702],
						[77.62288957940201, 13.065397958747079],
						[77.62324630779047, 13.065384560141196],
						[77.62381264784152, 13.065977634856683],
						[77.62429865431751, 13.066535071972897],
						[77.62482932553768, 13.067021054169503],
						[77.6259886468234, 13.067079057334881],
						[77.62617150783981, 13.067930734756962],
						[77.62636328432252, 13.0688760589798],
						[77.6266620494039, 13.070383233726565],
						[77.62696518414054, 13.070392186709917],
						[77.6271391278679, 13.070735460476543],
						[77.62749138494398, 13.071547022681822],
						[77.62780797832748, 13.072514724857971],
						[77.62783472527046, 13.07265738972568],
						[77.62822718785259, 13.072643991383185],
						[77.6284857404044, 13.075132172183288],
						[77.62901193409292, 13.075199034127404],
						[77.63095166204701, 13.074953839080749],
						[77.63126378800014, 13.076697301460348],
						[77.63217787240626, 13.07659474672012],
						[77.6331811890443, 13.0764520491126],
						[77.63365832724911, 13.076447570797352],
						[77.63367170002545, 13.07673296106184],
						[77.63377431199484, 13.077045105392383],
						[77.63699378049456, 13.075355109287711],
						[77.63786324404302, 13.075372936872634],
						[77.63825121419669, 13.075208001824407],
						[77.63850091628782, 13.075524572297992],
						[77.63928122081957, 13.076398546153971],
						[77.6407126390291, 13.07785664698395],
						[77.64201023384527, 13.077776459529243],
						[77.64207263548111, 13.077771963450298],
						[77.64251407799564, 13.078703911364766],
						[77.6426434253052, 13.07897592528087],
						[77.64278614415693, 13.079234516097145],
						[77.64374033693709, 13.080942376953919],
						[77.64412382960704, 13.081580058555279],
						[77.64460100872049, 13.082387138732107],
						[77.64464558029233, 13.082409403719009],
						[77.64485961098578, 13.082503038486843],
						[77.64514049834061, 13.082971249477351],
						[77.64650052853911, 13.085227584195657],
						[77.64654509935649, 13.086944360199752],
						[77.64199240480009, 13.087653323570617],
						[77.6485383581235, 13.099340664189425],
						[77.6491046580826, 13.10479857535338],
						[77.63712750421648, 13.106742785709319],
						[77.63940165712475, 13.114006586583743],
						[77.63005985634757, 13.125034004695781],
						[77.62066448131435, 13.131254416795048],
						[77.61515307667476, 13.131169694774893],
						[77.61493453108774, 13.130732698221573],
						[77.61464473898539, 13.129644649314052],
						[77.6146358222935, 13.129466344525392],
						[77.6145466553357, 13.129078381143332],
						[77.6143682337896, 13.12848533215998],
						[77.61395806422492, 13.127740634142256],
						[77.61351213862812, 13.126764100364086],
						[77.61321333864224, 13.126059534772219],
						[77.61286112430003, 13.125176674068175],
						[77.61226360750275, 13.1236204502089],
						[77.61200047181447, 13.123134439000038],
						[77.6117998413668, 13.12272416396353],
						[77.61157237233999, 13.122202418151224],
						[77.61149211989888, 13.12190816181214],
						[77.61145645212893, 13.121783310098252],
						[77.61141186740062, 13.121645074653713],
						[77.61147874448648, 13.121261610981497],
						[77.61141632587425, 13.120695307794339],
						[77.61131823941373, 13.120026432967459],
						[77.61127811310982, 13.119237173409486],
						[77.61123798679162, 13.118648565591702],
						[77.61106410591431, 13.117556084567987],
						[77.61093918077447, 13.116766782989192],
						[77.61096147323434, 13.115941898379036],
						[77.61102397951989, 13.11544246582499],
						[77.61112206623656, 13.115085745837849],
						[77.61117110956286, 13.114122569422824],
						[77.6111532756285, 13.113681145060228],
						[77.61107748137593, 13.113302090453185],
						[77.61092134680338, 13.112673349405092],
						[77.61084109389861, 13.112490487561692],
						[77.61064046138661, 13.111942038590787],
						[77.61045766256468, 13.111522919174078],
						[77.6102747760243, 13.111139452012692],
						[77.61007860103058, 13.110818362899295],
						[77.61002064017164, 13.110666715789417],
						[77.60982000619893, 13.110582043633205],
						[77.60973975250984, 13.11053740071924],
						[77.60973975250984, 13.110474983794752],
						[77.60976650374589, 13.11040816739017],
						[77.60982892327198, 13.110229730328973],
						[77.60983338180823, 13.11002914262485],
						[77.60973083542976, 13.110020194796203],
						[77.60986013302205, 13.108602203357524],
						[77.60989580129728, 13.10831231471721],
						[77.60989580129728, 13.108227626073672],
						[77.60980217205075, 13.108102740455482],
						[77.60942310831189, 13.107710371439946],
						[77.6092447660749, 13.107527507066015],
						[77.60874086030765, 13.107451776088771],
						[77.60868735734233, 13.107447280582079],
						[77.60870073308604, 13.107224340378052],
						[77.608696274505, 13.107001398443138],
						[77.60872302598871, 13.106720405392858],
						[77.60875423604502, 13.105989174422858],
						[77.60877207035904, 13.105592332137636],
						[77.60887015903566, 13.10503933583674],
						[77.60890136905125, 13.104589019029648],
						[77.60863385435161, 13.104584500216271],
						[77.60835296323367, 13.104575620107504],
						[77.60837079761119, 13.104486447581543],
						[77.60844659368409, 13.10360356242283],
						[77.60846888663703, 13.103117512075332],
						[77.6084956381747, 13.10304612437936],
						[77.6085000967637, 13.10246649865169],
						[77.6085000967637, 13.102430760378653],
						[77.60850455535252, 13.102368391548222],
						[77.6085000967637, 13.102372836775201],
						[77.60850455535252, 13.102207828505634],
						[77.60850455535252, 13.102069623607553],
						[77.60827270849998, 13.102060707719849],
						[77.60795160157008, 13.102051777770178],
						[77.60658717104427, 13.102016056990045],
						[77.60475892889954, 13.102109752087467],
						[77.60405881761268, 13.102145441734715],
						[77.60379575019782, 13.102158831647849],
						[77.6034791767325, 13.102176616804858],
						[77.6032026441438, 13.10219004314831],
						[77.60310455053654, 13.1021855415875],
						[77.60231088094027, 13.102921338308766],
						[77.6013967289112, 13.10312195133577],
						[77.60120053847044, 13.103072892143254],
						[77.60046036237226, 13.102907909602923],
						[77.60042023220407, 13.102876698189224],
						[77.60014815103585, 13.102863317523925],
						[77.6000946439937, 13.10262695912372],
						[77.59999654768383, 13.102207851110506],
						[77.59984048519692, 13.101641515037063],
						[77.59972455292404, 13.10121791972366],
						[77.5995149827439, 13.10044199503477],
						[77.59935446064054, 13.099840060979824],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000000c",
			properties: {
				AREA_SQ_KM: 24.27425761266518,
				ASS_CONST1: "Byatarayanapura",
				ASS_CONST_: "152",
				LAT: 13.0962502934223,
				LON: 77.6233139550485,
				LOST_AREA_SQ_KM: 1.4816878557534006,
				OBJECTID: 13,
				POP_F: 10058.0,
				POP_M: 10906.0,
				POP_SC: 3470.0,
				POP_ST: 327.0,
				POP_TOTAL: 20964.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Jakkuru",
				WARD_NO: 5.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.60976650374589, 13.066655462811289],
						[77.60970854272642, 13.066468151906932],
						[77.60964157716758, 13.065861686130814],
						[77.60957469899144, 13.06529095516463],
						[77.60945431817434, 13.064479372250638],
						[77.60936960567041, 13.063926425837716],
						[77.60922247327544, 13.063427073228066],
						[77.60913776059725, 13.063427031370878],
						[77.60892366191422, 13.063449294688766],
						[77.60857143416366, 13.063498358711797],
						[77.6082860842929, 13.063529601213435],
						[77.60820137091096, 13.063449303593128],
						[77.60812548724955, 13.063342336253612],
						[77.60801402210127, 13.063226398868267],
						[77.60793376712626, 13.063128281367254],
						[77.60785351209411, 13.063074778837365],
						[77.60769300185841, 13.063012302370357],
						[77.60750128099957, 13.062945468236338],
						[77.60724705146913, 13.062914270335533],
						[77.60698399149246, 13.06279384713532],
						[77.60674322419614, 13.062579836764616],
						[77.60657825371477, 13.062499540305721],
						[77.6062526832807, 13.062347960965731],
						[77.60600745573525, 13.062254289206205],
						[77.60583356678892, 13.062214119743958],
						[77.60573993416827, 13.062125020984373],
						[77.60555258126814, 13.062022413285458],
						[77.60494619569229, 13.061719176766795],
						[77.60406327637715, 13.061206415316335],
						[77.60380020897267, 13.061077104316238],
						[77.60362185784093, 13.060925486847083],
						[77.60331411404852, 13.060582165504833],
						[77.60301091565002, 13.06027893242283],
						[77.60275230460724, 13.060252180713592],
						[77.60226183375953, 13.060198676699342],
						[77.60204780944855, 13.060180812673424],
						[77.60148144740515, 13.06017635928786],
						[77.60094638216489, 13.060198616386367],
						[77.60043806783612, 13.060207583588465],
						[77.6001793634654, 13.060145100149466],
						[77.60012139751794, 13.059989041021526],
						[77.59997425305606, 13.05979285526453],
						[77.59979143694217, 13.059618932425286],
						[77.59961753841262, 13.059418346137813],
						[77.59949268802097, 13.059132894186032],
						[77.5993901322388, 13.058963460952851],
						[77.59923852786831, 13.058950083410924],
						[77.5989531543831, 13.05896345340262],
						[77.59884613914008, 13.058909951404251],
						[77.59877479558835, 13.058655825375064],
						[77.59878817250774, 13.058370421359548],
						[77.59870791096765, 13.057884349942688],
						[77.59856076466255, 13.057447421387945],
						[77.59835119232105, 13.05694793441408],
						[77.59804352158271, 13.0564396471283],
						[77.59785624333131, 13.056015969586028],
						[77.59792312845677, 13.055851031161936],
						[77.59843145411484, 13.055668193326657],
						[77.59889518780572, 13.0554095929512],
						[77.59943472172077, 13.055186585477045],
						[77.59924744577809, 13.054785260961818],
						[77.59955957217653, 13.054709501634985],
						[77.60085274548693, 13.054343818157614],
						[77.60131646922667, 13.05424130587369],
						[77.6019407097124, 13.054107554696674],
						[77.60223508074286, 13.054031708822334],
						[77.602774598686, 13.053795402816169],
						[77.60335424318724, 13.053594688083749],
						[77.60384479671154, 13.053376202316977],
						[77.6043085081507, 13.05318446076496],
						[77.6043530956886, 13.052948118133763],
						[77.60425946183865, 13.052658324475146],
						[77.60514237961779, 13.05239970137985],
						[77.60608325339716, 13.052100905096818],
						[77.60681010405224, 13.051913681854113],
						[77.60702857797239, 13.051895768524956],
						[77.60713112680929, 13.051775436623252],
						[77.60742102565952, 13.051485546539034],
						[77.60772867081943, 13.051333969467379],
						[77.60806752538619, 13.05118234725253],
						[77.60825041550827, 13.05102180554422],
						[77.60832621166213, 13.050901388266],
						[77.60874086030765, 13.050807789069518],
						[77.60908871638605, 13.050749790150178],
						[77.60950782077559, 13.050647258244787],
						[77.60982892327198, 13.050544735230167],
						[77.61023464934883, 13.050384174269228],
						[77.61032827823605, 13.050277129490526],
						[77.61038186784424, 13.050058651756054],
						[77.61042645298008, 13.049724201283679],
						[77.61058695932309, 13.049719790555537],
						[77.61100168707237, 13.049617216245046],
						[77.61130040550263, 13.04952353211374],
						[77.61176417369427, 13.049474493723789],
						[77.61203613941221, 13.048747638802892],
						[77.61223239841836, 13.047909358545073],
						[77.61155899693712, 13.047351953141069],
						[77.61136728265467, 13.046901602536872],
						[77.61148320295746, 13.046674233177606],
						[77.61173296447154, 13.046357639477057],
						[77.61207180699866, 13.045786840643217],
						[77.61222348153551, 13.045042201043238],
						[77.61226360750275, 13.044386658362143],
						[77.61255786415916, 13.0436375224766],
						[77.6128967916253, 13.04311583017586],
						[77.61335154903264, 13.042678852293598],
						[77.61367264057537, 13.042134815461695],
						[77.61373951631924, 13.042103617765111],
						[77.61397143934298, 13.041996608699703],
						[77.61417652428766, 13.041867250849892],
						[77.6143058167783, 13.041787038298224],
						[77.61448423842324, 13.041697808245452],
						[77.61462244725432, 13.041635368611022],
						[77.61485873937913, 13.041564031286027],
						[77.61532249292205, 13.041407987188089],
						[77.6157594947487, 13.041265318590014],
						[77.61576841135162, 13.04126083092214],
						[77.61603153823808, 13.041185006562129],
						[77.61616082859922, 13.041153800806248],
						[77.61637482611219, 13.041100281243644],
						[77.6167583247228, 13.041011111224844],
						[77.61699915803561, 13.040957591975197],
						[77.61713736378034, 13.040935357788065],
						[77.61728894407952, 13.040930852856173],
						[77.61745844452993, 13.04093532321021],
						[77.61751640156197, 13.040939759687152],
						[77.61808268302919, 13.040988831973117],
						[77.61827447329723, 13.04099774250707],
						[77.61839038649136, 13.040975467096375],
						[77.61850184137317, 13.040935288568253],
						[77.61877387823705, 13.04081493734198],
						[77.6192331574917, 13.040632092863543],
						[77.61934015337938, 13.04059195752745],
						[77.61937136049411, 13.040574136109896],
						[77.61994217818145, 13.040279845615045],
						[77.6200045920685, 13.040235229209376],
						[77.62013387786727, 13.040146038877435],
						[77.62014725225178, 13.040137193118992],
						[77.61991988749912, 13.039165042950195],
						[77.62054857046185, 13.039133854784351],
						[77.62057086101969, 13.038933203773714],
						[77.62057086101969, 13.038843988708365],
						[77.62092313866115, 13.03879049112711],
						[77.62136903490867, 13.038732535711228],
						[77.62144482223479, 13.039303321975213],
						[77.62159648414709, 13.039263183025394],
						[77.62171239392038, 13.03924530571993],
						[77.62182384551288, 13.03924533252818],
						[77.62183721969656, 13.038607664525214],
						[77.62203791967387, 13.038563074321846],
						[77.62232778015915, 13.038554191045153],
						[77.6225105597134, 13.038522972477326],
						[77.62248826953977, 13.03834458852285],
						[77.62311256755682, 13.038357960780914],
						[77.62348266922642, 13.038349063283341],
						[77.62354062315657, 13.038411479124983],
						[77.62359411906559, 13.03863442344833],
						[77.62363869897034, 13.038781613829997],
						[77.62364315695983, 13.03890202003269],
						[77.62369674023162, 13.039049127336678],
						[77.62384831169747, 13.039321152574233],
						[77.62397759308051, 13.039535156815],
						[77.62396867712782, 13.039597627006668],
						[77.62425407467425, 13.040324436636949],
						[77.6247847461047, 13.041733511983312],
						[77.62479366199271, 13.041755772066098],
						[77.62541348929848, 13.042995475160305],
						[77.62581033082324, 13.043530521627488],
						[77.62608680791267, 13.043909539979852],
						[77.6267824118236, 13.04473891899343],
						[77.62680915901049, 13.044770150934982],
						[77.62741560169098, 13.04542119346623],
						[77.62748246926981, 13.045492500264634],
						[77.62754933680887, 13.04551928242779],
						[77.62760737605237, 13.045434585915558],
						[77.6277990626784, 13.04526512954881],
						[77.62789267695842, 13.045059965281542],
						[77.62792396910935, 13.044984160697398],
						[77.62797746293376, 13.044823656957957],
						[77.62797300511603, 13.044689868594855],
						[77.62796854729812, 13.044676537861529],
						[77.62798192075132, 13.044609619696368],
						[77.62804878799341, 13.044453558030565],
						[77.62813794425433, 13.044368809469033],
						[77.62814685987655, 13.044364407379424],
						[77.62818698016773, 13.044346582582097],
						[77.62826285030887, 13.044315295410005],
						[77.62836537980769, 13.044293059851698],
						[77.62844116242063, 13.0442841168399],
						[77.6284901982018, 13.04427077747708],
						[77.62851257459393, 13.044261847321795],
						[77.62853486357388, 13.044244025338564],
						[77.62865522398917, 13.044128078546898],
						[77.62867751294083, 13.044101282662242],
						[77.62869534409897, 13.044065643050939],
						[77.628704259677, 13.044056667703044],
						[77.628704259677, 13.04404778010362],
						[77.628704259677, 13.043985394351601],
						[77.62869980188808, 13.043927366872136],
						[77.62864630840727, 13.043878364492828],
						[77.6284901982018, 13.04378023418994],
						[77.6283564642028, 13.043673260105239],
						[77.62828959714366, 13.043597381639124],
						[77.62824056127502, 13.043539447989362],
						[77.62822273004477, 13.043521589518528],
						[77.62811119738349, 13.04333880495467],
						[77.62810673957105, 13.043263002841883],
						[77.62811565519573, 13.043209503675364],
						[77.62816023330853, 13.04317826062077],
						[77.6282494768891, 13.0431470144428],
						[77.6284055000209, 13.04306678531531],
						[77.6284500780188, 13.043044515995541],
						[77.62849920120387, 13.042999858776623],
						[77.62853932136933, 13.042928536283087],
						[77.62855715254939, 13.042870548786732],
						[77.62855269475465, 13.042843843868948],
						[77.62855269475465, 13.042794746885555],
						[77.62854377916462, 13.042710015141697],
						[77.62851703239028, 13.042522778380842],
						[77.62854823695972, 13.042388986435737],
						[77.62855269475465, 13.042317670224296],
						[77.62858389931418, 13.042290873140828],
						[77.62862847724135, 13.042286465028367],
						[77.62876221091683, 13.042322078570752],
						[77.62885591180255, 13.042362233122116],
						[77.62891832074467, 13.042384570651622],
						[77.6290030185393, 13.042473733107439],
						[77.62917695911457, 13.042571858457727],
						[77.62919033242406, 13.042571813033577],
						[77.62924382564603, 13.042576290796537],
						[77.62929731884257, 13.042576281652131],
						[77.62932852319547, 13.042562945053746],
						[77.62936864306504, 13.04252277183645],
						[77.6293999348052, 13.042487091256037],
						[77.62944451240887, 13.042406837239344],
						[77.6295024632672, 13.042286414656552],
						[77.62960053388248, 13.04223738705618],
						[77.62967640306984, 13.042219559988489],
						[77.62974326930427, 13.042219591493815],
						[77.62987700165384, 13.042322076383758],
						[77.62993941002921, 13.042366675582171],
						[77.62997961708741, 13.0424068391504],
						[77.63001527898788, 13.042464817473254],
						[77.6300776872865, 13.042513817348587],
						[77.63019804605025, 13.042589685401248],
						[77.63031403436662, 13.042625305708226],
						[77.63032294981628, 13.042629791054171],
						[77.630363069331, 13.04263871468605],
						[77.63045222375688, 13.042643185910087],
						[77.63057266952585, 13.042656586490942],
						[77.63079109733644, 13.042589632310595],
						[77.63090262733935, 13.042554023335532],
						[77.63096949284447, 13.042545080696993],
						[77.63100961212847, 13.042540629743499],
						[77.6310408160061, 13.042558442397672],
						[77.63107201987509, 13.042652101281169],
						[77.63118354959929, 13.042839371610917],
						[77.63130836486478, 13.042990955901105],
						[77.63130836486478, 13.042995442839953],
						[77.63132619560567, 13.043008814177675],
						[77.63135294171171, 13.042999921793673],
						[77.63137085985107, 13.042995435871228],
						[77.63144218275805, 13.042977561594554],
						[77.63166069628446, 13.042933045315072],
						[77.63167406929847, 13.042932999752402],
						[77.63169189998136, 13.042932996525787],
						[77.63173201900752, 13.042933032404228],
						[77.631758765017, 13.042928540614009],
						[77.63177659568645, 13.04292405043752],
						[77.63180334168533, 13.042919644928189],
						[77.6318211723477, 13.042901780206776],
						[77.63183008767783, 13.042892890989476],
						[77.6318211723477, 13.04285276900019],
						[77.6318211723477, 13.04284828205975],
						[77.63182563001286, 13.04284832439273],
						[77.63183454534263, 13.042790337707547],
						[77.63183454534263, 13.042785850769286],
						[77.63186574899127, 13.04275465223689],
						[77.6319549896305, 13.042656531577668],
						[77.63200402387909, 13.042598580740364],
						[77.63205305810628, 13.042545030547865],
						[77.63209317700353, 13.042513830362637],
						[77.63229831632623, 13.042224044673896],
						[77.63249899763626, 13.041992115307195],
						[77.63281557691863, 13.041956466565352],
						[77.63314998576085, 13.041786982860224],
						[77.63327042863907, 13.04233993506311],
						[77.63342644480248, 13.042339948498972],
						[77.63355580257767, 13.042353345703534],
						[77.63373410629397, 13.042353311515928],
						[77.6338367182064, 13.042353339148553],
						[77.63402848161437, 13.042331001031267],
						[77.63418895429679, 13.04232208229127],
						[77.63433614145761, 13.042273089670312],
						[77.63453236157113, 13.042174946549752],
						[77.63460814002659, 13.042130364143153],
						[77.63473295148887, 13.041983219349133],
						[77.63479544457093, 13.041902963890129],
						[77.63492917090946, 13.041787053341789],
						[77.63496928877998, 13.041604159405795],
						[77.63500940663619, 13.04148382387228],
						[77.63514322012269, 13.041323307141626],
						[77.63521899816618, 13.04126086314281],
						[77.63530377861564, 13.041229657433417],
						[77.6353706415023, 13.04118947724214],
						[77.63549099459802, 13.0411672338347],
						[77.63562034999651, 13.041113670534928],
						[77.63572287278426, 13.041015584316082],
						[77.6357585328624, 13.040935330094971],
						[77.63574961784393, 13.040872860191554],
						[77.6357853653157, 13.040797097168976],
						[77.63585222791578, 13.04074362865548],
						[77.63592800548108, 13.040716820907011],
						[77.63602615789165, 13.0407034303144],
						[77.63614205279141, 13.040730241438661],
						[77.63624011761321, 13.040805980956224],
						[77.63631152483156, 13.04087732967124],
						[77.63643187691937, 13.040908583621869],
						[77.63653002888971, 13.040953177781041],
						[77.6366013485223, 13.040984398812327],
						[77.63666821063674, 13.041033439095452],
						[77.63671278535759, 13.041095815463255],
						[77.63673961758299, 13.041198409743615],
						[77.63673961758299, 13.041305406171386],
						[77.63672170029963, 13.041434750684955],
						[77.63670387041483, 13.041541751033778],
						[77.63665483821704, 13.041671063323871],
						[77.63658351861841, 13.041849434942176],
						[77.63655677375725, 13.041943062800872],
						[77.63655231627978, 13.042063478538708],
						[77.63657014618863, 13.042219569935789],
						[77.63658797609465, 13.04236228688025],
						[77.63668158305485, 13.0425094323199],
						[77.63669495547137, 13.042785854603906],
						[77.63666821063674, 13.04284824669891],
						[77.63665038074345, 13.042883973814483],
						[77.6366459232697, 13.04289730631335],
						[77.6366236358982, 13.042946365939134],
						[77.63606627537047, 13.044177080023827],
						[77.63603953038618, 13.044542780265777],
						[77.63632935477862, 13.047351965752096],
						[77.6364051320221, 13.048096608688656],
						[77.63646307929146, 13.048894847792793],
						[77.63648536669479, 13.049242647805084],
						[77.63657906114199, 13.05049117449321],
						[77.6366236358982, 13.05111990703748],
						[77.6366236358982, 13.05112879537884],
						[77.63700269541431, 13.053416386485663],
						[77.63704727000244, 13.05712182906662],
						[77.63713641912561, 13.058825283859232],
						[77.63720328092158, 13.060104984329627],
						[77.637149791488, 13.061407026268066],
						[77.63712750421648, 13.062004544130172],
						[77.63711413185145, 13.06236127840739],
						[77.63682430948363, 13.065175036025916],
						[77.63663255084734, 13.067021088024312],
						[77.63695357594574, 13.069000930298962],
						[77.63694466102208, 13.069005333112651],
						[77.6370294401693, 13.06948696737753],
						[77.637667029747, 13.073419893075036],
						[77.63766257231359, 13.073419850437249],
						[77.63768048944686, 13.073491231807632],
						[77.6377919252116, 13.073986170789944],
						[77.63791682053733, 13.074521292962906],
						[77.63817543819854, 13.075114318304983],
						[77.63825121419669, 13.075208001824407],
						[77.63786324404302, 13.075372936872634],
						[77.63699378049456, 13.075355109287711],
						[77.63377431199484, 13.077045105392383],
						[77.63367170002545, 13.07673296106184],
						[77.63365832724911, 13.076447570797352],
						[77.6331811890443, 13.0764520491126],
						[77.63217787240626, 13.07659474672012],
						[77.63126378800014, 13.076697301460348],
						[77.63095166204701, 13.074953839080749],
						[77.62901193409292, 13.075199034127404],
						[77.6284857404044, 13.075132172183288],
						[77.62822718785259, 13.072643991383185],
						[77.62783472527046, 13.07265738972568],
						[77.62780797832748, 13.072514724857971],
						[77.62749138494398, 13.071547022681822],
						[77.6271391278679, 13.070735460476543],
						[77.62696518414054, 13.070392186709917],
						[77.6266620494039, 13.070383233726565],
						[77.62636328432252, 13.0688760589798],
						[77.62617150783981, 13.067930734756962],
						[77.6259886468234, 13.067079057334881],
						[77.62482932553768, 13.067021054169503],
						[77.62429865431751, 13.066535071972897],
						[77.62381264784152, 13.065977634856683],
						[77.62324630779047, 13.065384560141196],
						[77.62288957940201, 13.065397958747079],
						[77.62226982560436, 13.065357825310702],
						[77.62111938217929, 13.065380155326935],
						[77.62055748668551, 13.065411336822859],
						[77.62043711760649, 13.065580795741313],
						[77.62003579899904, 13.065812619377134],
						[77.61981726288901, 13.06614708702115],
						[77.61939365128508, 13.066249674815742],
						[77.61903691068505, 13.066276442744783],
						[77.61882737641186, 13.066459195832426],
						[77.61867133999762, 13.066566260078893],
						[77.61847955040565, 13.066713365419803],
						[77.61834134630841, 13.066726791842248],
						[77.618127265117, 13.06672679765649],
						[77.61774377116879, 13.066677705665551],
						[77.61763677376393, 13.066606339628041],
						[77.61753869272019, 13.066378941578058],
						[77.61740494570465, 13.06603564422424],
						[77.61728894407952, 13.06587063910218],
						[77.6171864044877, 13.06573243405601],
						[77.6167806160311, 13.065710052911596],
						[77.61583974414968, 13.065723502766645],
						[77.61504607680834, 13.065683312423173],
						[77.61438606721507, 13.065527297279267],
						[77.6143637754328, 13.065540631235539],
						[77.61424339973239, 13.065848335214977],
						[77.61396252259777, 13.066481480413756],
						[77.61378855850617, 13.066909571558186],
						[77.61357455607923, 13.067355489839645],
						[77.61343188756874, 13.067507122820919],
						[77.61326683945863, 13.06762746810832],
						[77.61312417055844, 13.067698841541002],
						[77.61304391922268, 13.067730083920514],
						[77.61295475100478, 13.067752351191928],
						[77.61286112430003, 13.067770174031873],
						[77.61235277626767, 13.067846036843507],
						[77.61140295045283, 13.067926257877268],
						[77.61061371035802, 13.068028829064744],
						[77.61039970190069, 13.06805109603552],
						[77.61035065823866, 13.068042250618188],
						[77.61029706861562, 13.068011006159997],
						[77.6102480249089, 13.067953056096751],
						[77.61019898118086, 13.06781035968241],
						[77.61002509870032, 13.067221755077647],
						[77.60999834752565, 13.067012134531844],
						[77.60990471836432, 13.066726787653268],
						[77.60984675741594, 13.066677727241697],
						[77.60981554766215, 13.066659906795694],
						[77.60976650374589, 13.066655462811289],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000000d",
			properties: {
				AREA_SQ_KM: 10.133063538594543,
				ASS_CONST1: "Byatarayanapura",
				ASS_CONST_: "152",
				LAT: 13.0565248615012,
				LON: 77.6224107410182,
				LOST_AREA_SQ_KM: 0.5166099133302875,
				OBJECTID: 14,
				POP_F: 10018.0,
				POP_M: 10384.0,
				POP_SC: 3924.0,
				POP_ST: 147.0,
				POP_TOTAL: 20402.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Thanisandra",
				WARD_NO: 6.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59002603821851, 13.076073016724118],
						[77.58995914841498, 13.075859027555708],
						[77.58950875604, 13.074802193261442],
						[77.58916538638945, 13.073995130115001],
						[77.58877750946068, 13.073112217041372],
						[77.58838954376142, 13.072354179810027],
						[77.58771180287421, 13.071382062976852],
						[77.58690464673487, 13.070499210469492],
						[77.58628932948852, 13.070008644658508],
						[77.58509427361828, 13.069206061669913],
						[77.58404191096497, 13.068501529143237],
						[77.58331508859608, 13.067966388097734],
						[77.58211562909578, 13.06721727872859],
						[77.5812817624603, 13.066664353808143],
						[77.58389920511298, 13.064693447725265],
						[77.58708748371347, 13.062347893813723],
						[77.58710086201737, 13.06224533961798],
						[77.58740410314749, 13.060889846672334],
						[77.58773401248389, 13.059061593640664],
						[77.58793022632632, 13.057915627580146],
						[77.58854562207645, 13.05780858522274],
						[77.58868386268972, 13.057781801775205],
						[77.58894250596352, 13.057737207321527],
						[77.5887819688288, 13.056711596597234],
						[77.58735950891469, 13.056863246846568],
						[77.58719451010028, 13.055480902589595],
						[77.58662370153267, 13.055632555042003],
						[77.58353360798769, 13.057005954448197],
						[77.583181301005, 13.05664478017753],
						[77.58193724371344, 13.05426804804446],
						[77.58235636147197, 13.053701720039404],
						[77.58351576968583, 13.053157707532705],
						[77.58357820373001, 13.053122077475365],
						[77.58372974167979, 13.053452027450138],
						[77.58520576140187, 13.052435328648333],
						[77.5851388687449, 13.05227928354966],
						[77.58472859291626, 13.051307210951403],
						[77.58464386185308, 13.050995084383466],
						[77.58459034746465, 13.050736411469812],
						[77.58475980960773, 13.050620460251242],
						[77.58494710957557, 13.050517969683822],
						[77.58504967847405, 13.050415344754699],
						[77.58504967847405, 13.050312843507507],
						[77.58503184041145, 13.05019239503461],
						[77.58502292137908, 13.049973932817624],
						[77.586262572687, 13.049523523410013],
						[77.58755126399089, 13.049073150380977],
						[77.5886125127169, 13.048769997399955],
						[77.58970496680034, 13.048613903916953],
						[77.59032927149775, 13.048604948170455],
						[77.59117198992945, 13.04859603454701],
						[77.59193898239752, 13.048368613906305],
						[77.59215302587762, 13.048168014739566],
						[77.59172939776354, 13.047343037455203],
						[77.5914707608706, 13.04688376553631],
						[77.59106496731052, 13.046072172715444],
						[77.59082425348417, 13.045519299122741],
						[77.59070831195214, 13.04495296677561],
						[77.59063250396302, 13.044248464401361],
						[77.59059237030112, 13.043664323355557],
						[77.59045413202321, 13.043173794641932],
						[77.59043629481374, 13.043120302927836],
						[77.59032927149775, 13.042826012652817],
						[77.59026238187379, 13.04263426288444],
						[77.59022670739145, 13.042527193783751],
						[77.59016427702029, 13.042371119765495],
						[77.5901419804508, 13.04230869887667],
						[77.59000374162173, 13.041849408489718],
						[77.58993685180499, 13.041604169803554],
						[77.58995468909333, 13.041599769879866],
						[77.59022224808038, 13.041541794429268],
						[77.59075290486315, 13.041425799192652],
						[77.59100699668292, 13.041367861453569],
						[77.59137265706661, 13.041296511701127],
						[77.5918364197529, 13.041189529112646],
						[77.59192114542255, 13.041167226585435],
						[77.59197465633905, 13.041158253725184],
						[77.592139648172, 13.04112706438594],
						[77.59228234361693, 13.041104804552399],
						[77.59268813280306, 13.04105575423252],
						[77.59288879724642, 13.04105570952871],
						[77.59300027733865, 13.041055751716673],
						[77.59314743089189, 13.041060193583172],
						[77.59343281905463, 13.04107804982644],
						[77.59349970680093, 13.041082492314077],
						[77.59358889040104, 13.041086977668337],
						[77.59377171656106, 13.04111367795498],
						[77.59418641870481, 13.041167206912737],
						[77.59458765447572, 13.041220688081626],
						[77.59471696942688, 13.041238588385987],
						[77.59478831554344, 13.041247473698595],
						[77.59502464923249, 13.041260885688999],
						[77.59522085040983, 13.041269768661907],
						[77.59545718319343, 13.04126083456786],
						[77.59679044755278, 13.04122512902055],
						[77.59742817758973, 13.041207299706661],
						[77.59798555453811, 13.041193953173297],
						[77.59940350908523, 13.041149384953771],
						[77.6010756898305, 13.041104756728071],
						[77.60241343406746, 13.041064662483498],
						[77.60424608556802, 13.041011113932058],
						[77.60520480170432, 13.040984354579308],
						[77.60520480170432, 13.04097995435086],
						[77.60651583238861, 13.040939753596327],
						[77.60673876553766, 13.04091747751977],
						[77.60696169824587, 13.040895201188734],
						[77.60706870578923, 13.040886306522303],
						[77.6070999163036, 13.040877374291892],
						[77.60767516737366, 13.040734671938115],
						[77.60779109147406, 13.040694542999773],
						[77.60806752538619, 13.04059202125638],
						[77.60821474671233, 13.040516217224909],
						[77.60850901394116, 13.040360113831294],
						[77.60856251699114, 13.040333362810355],
						[77.6085892685066, 13.040315500765466],
						[77.60903075501803, 13.039989979036449],
						[77.60947215237758, 13.039544092854472],
						[77.60947661092796, 13.039539649085404],
						[77.60972637688944, 13.039236446442924],
						[77.60985121595148, 13.039035750377614],
						[77.60994484515726, 13.038888593273958],
						[77.60999388899592, 13.038794933176238],
						[77.6100295572288, 13.03871917718444],
						[77.61013210333546, 13.038505153536684],
						[77.61023019082845, 13.038264341097538],
						[77.61025694194808, 13.038201873013733],
						[77.6102614004674, 13.038206402192735],
						[77.6102614004674, 13.038210802386038],
						[77.61026140046741, 13.03821528885781],
						[77.6102926100977, 13.038286594882637],
						[77.61031044416828, 13.038322312407802],
						[77.61033273675254, 13.03837135953295],
						[77.6104130774412, 13.038531920303651],
						[77.61046657958727, 13.038634414141463],
						[77.61050224767055, 13.038701362967458],
						[77.61065383689852, 13.039004532253967],
						[77.6107117974318, 13.039111555814882],
						[77.61085446938671, 13.039330087244903],
						[77.61095701474272, 13.039499486885479],
						[77.61099722858235, 13.039570883498154],
						[77.61125136223261, 13.039891862439536],
						[77.61128257158875, 13.039940909504962],
						[77.61131823941373, 13.039994485859733],
						[77.61137174113004, 13.040137187725781],
						[77.61138957502983, 13.040177306217485],
						[77.6114475351847, 13.040324451346528],
						[77.61150995377963, 13.040498300014612],
						[77.61156791387255, 13.040672278830755],
						[77.6116527121448, 13.040926414310483],
						[77.61167500446354, 13.040993322851717],
						[77.61171958908771, 13.041225155099024],
						[77.61171958908771, 13.041229641704513],
						[77.61173742293246, 13.041323254814543],
						[77.61197372110877, 13.042411284116643],
						[77.61199601336396, 13.042536175047896],
						[77.61226806594267, 13.042478215852222],
						[77.61249098771381, 13.042424700175687],
						[77.61291016686937, 13.042322075177097],
						[77.61332925704565, 13.042215130876437],
						[77.61356563930262, 13.042161573623204],
						[77.61367264057537, 13.042134815461695],
						[77.61335154903264, 13.042678852293598],
						[77.6128967916253, 13.04311583017586],
						[77.61255786415916, 13.0436375224766],
						[77.61226360750275, 13.044386658362143],
						[77.61222348153551, 13.045042201043238],
						[77.61207180699866, 13.045786840643217],
						[77.61173296447154, 13.046357639477057],
						[77.61148320295746, 13.046674233177606],
						[77.61136728265467, 13.046901602536872],
						[77.61155899693712, 13.047351953141069],
						[77.61223239841836, 13.047909358545073],
						[77.61203613941221, 13.048747638802892],
						[77.61176417369427, 13.049474493723789],
						[77.61130040550263, 13.04952353211374],
						[77.61100168707237, 13.049617216245046],
						[77.61058695932309, 13.049719790555537],
						[77.61042645298008, 13.049724201283679],
						[77.61038186784424, 13.050058651756054],
						[77.61032827823605, 13.050277129490526],
						[77.61023464934883, 13.050384174269228],
						[77.60982892327198, 13.050544735230167],
						[77.60950782077559, 13.050647258244787],
						[77.60908871638605, 13.050749790150178],
						[77.60874086030765, 13.050807789069518],
						[77.60832621166213, 13.050901388266],
						[77.60825041550827, 13.05102180554422],
						[77.60806752538619, 13.05118234725253],
						[77.60772867081943, 13.051333969467379],
						[77.60742102565952, 13.051485546539034],
						[77.60713112680929, 13.051775436623252],
						[77.60702857797239, 13.051895768524956],
						[77.60681010405224, 13.051913681854113],
						[77.60608325339716, 13.052100905096818],
						[77.60514237961779, 13.05239970137985],
						[77.60425946183865, 13.052658324475146],
						[77.6043530956886, 13.052948118133763],
						[77.6043085081507, 13.05318446076496],
						[77.60384479671154, 13.053376202316977],
						[77.60335424318724, 13.053594688083749],
						[77.602774598686, 13.053795402816169],
						[77.60223508074286, 13.054031708822334],
						[77.6019407097124, 13.054107554696674],
						[77.60131646922667, 13.05424130587369],
						[77.60085274548693, 13.054343818157614],
						[77.59955957217653, 13.054709501634985],
						[77.59924744577809, 13.054785260961818],
						[77.59943472172077, 13.055186585477045],
						[77.59889518780572, 13.0554095929512],
						[77.59843145411484, 13.055668193326657],
						[77.59792312845677, 13.055851031161936],
						[77.59785624333131, 13.056015969586028],
						[77.59804352158271, 13.0564396471283],
						[77.59835119232105, 13.05694793441408],
						[77.59856076466255, 13.057447421387945],
						[77.59870791096765, 13.057884349942688],
						[77.59878817250774, 13.058370421359548],
						[77.59877479558835, 13.058655825375064],
						[77.59884613914008, 13.058909951404251],
						[77.5989531543831, 13.05896345340262],
						[77.59923852786831, 13.058950083410924],
						[77.5993901322388, 13.058963460952851],
						[77.59949268802097, 13.059132894186032],
						[77.59961753841262, 13.059418346137813],
						[77.59979143694217, 13.059618932425286],
						[77.59997425305606, 13.05979285526453],
						[77.60012139751794, 13.059989041021526],
						[77.6001793634654, 13.060145100149466],
						[77.60043806783612, 13.060207583588465],
						[77.60094638216489, 13.060198616386367],
						[77.60148144740515, 13.06017635928786],
						[77.60204780944855, 13.060180812673424],
						[77.60226183375953, 13.060198676699342],
						[77.60275230460724, 13.060252180713592],
						[77.60301091565002, 13.06027893242283],
						[77.60331411404852, 13.060582165504833],
						[77.60362185784093, 13.060925486847083],
						[77.60380020897267, 13.061077104316238],
						[77.60406327637715, 13.061206415316335],
						[77.60494619569229, 13.061719176766795],
						[77.60555258126814, 13.062022413285458],
						[77.60573993416827, 13.062125020984373],
						[77.60583356678892, 13.062214119743958],
						[77.60600745573525, 13.062254289206205],
						[77.6062526832807, 13.062347960965731],
						[77.60657825371477, 13.062499540305721],
						[77.60674322419614, 13.062579836764616],
						[77.60698399149246, 13.06279384713532],
						[77.60724705146913, 13.062914270335533],
						[77.60750128099957, 13.062945468236338],
						[77.60769300185841, 13.063012302370357],
						[77.60785351209411, 13.063074778837365],
						[77.60793376712626, 13.063128281367254],
						[77.60801402210127, 13.063226398868267],
						[77.60812548724955, 13.063342336253612],
						[77.60820137091096, 13.063449303593128],
						[77.6082860842929, 13.063529601213435],
						[77.60857143416366, 13.063498358711797],
						[77.60892366191422, 13.063449294688766],
						[77.60913776059725, 13.063427031370878],
						[77.60922247327544, 13.063427073228066],
						[77.60936960567041, 13.063926425837716],
						[77.60945431817434, 13.064479372250638],
						[77.60957469899144, 13.06529095516463],
						[77.60964157716758, 13.065861686130814],
						[77.60970854272642, 13.066468151906932],
						[77.60976650374589, 13.066655462811289],
						[77.60973529396989, 13.0666554199136],
						[77.60970408418524, 13.06665986454834],
						[77.60968179147662, 13.066659907871275],
						[77.60963711862375, 13.066673280292024],
						[77.60918234622528, 13.06682491006482],
						[77.60821920531245, 13.067137050599094],
						[77.6081031942287, 13.067194952736658],
						[77.60580235592401, 13.067966437397258],
						[77.6034256712703, 13.068822591972708],
						[77.60346134158125, 13.069518162649805],
						[77.60351484702653, 13.070146942112597],
						[77.6036887395484, 13.07087372291994],
						[77.60381358529618, 13.07150251256692],
						[77.60411678153686, 13.072684159471251],
						[77.60441551821204, 13.074106561057754],
						[77.60483918613451, 13.075207941033122],
						[77.60521817786123, 13.076242527336158],
						[77.605677512378, 13.07670622306737],
						[77.60600745573525, 13.077022803904805],
						[77.60642657158063, 13.077994882536828],
						[77.60661392302849, 13.078436351423461],
						[77.60715787866445, 13.079047253649703],
						[77.60737189327719, 13.079212281792906],
						[77.60740319113175, 13.079238999407549],
						[77.60741656702784, 13.079234555129698],
						[77.60762612252609, 13.079337139916163],
						[77.60773312943877, 13.07961807525506],
						[77.60775988115105, 13.07982314350114],
						[77.60767962599512, 13.0801085603618],
						[77.60757707765715, 13.080371579796417],
						[77.60755032590147, 13.081673716692757],
						[77.60465191916408, 13.084228731749063],
						[77.60343458884908, 13.085156259091434],
						[77.60287269258022, 13.08593654538975],
						[77.60285039852084, 13.08597223313247],
						[77.60274784579094, 13.085544166356181],
						[77.60270771643648, 13.085365802331458],
						[77.6025873282874, 13.08459888346064],
						[77.60241343406746, 13.083796233801129],
						[77.60231533977382, 13.083336889219169],
						[77.602252916088, 13.083029279894502],
						[77.60156616583548, 13.083122866989765],
						[77.60135659907607, 13.083149648987959],
						[77.6004871158098, 13.083238863035328],
						[77.59995195842389, 13.083292323770221],
						[77.59973347079541, 13.08333688271199],
						[77.5994079680337, 13.083430541110236],
						[77.59870345199153, 13.08359558355672],
						[77.59833781534986, 13.083662437532789],
						[77.5983244383771, 13.083662479399695],
						[77.59821742253787, 13.083666870784748],
						[77.59787853837753, 13.083671325745627],
						[77.59738358731497, 13.08368469738366],
						[77.5971963079685, 13.083720409896184],
						[77.5965987081629, 13.08384971941024],
						[77.59602794712109, 13.083992411772618],
						[77.59576040188931, 13.084077136916338],
						[77.59515396368312, 13.084308978434482],
						[77.59449401252206, 13.084514142533555],
						[77.59427551432819, 13.084572072573971],
						[77.5942041678876, 13.084603307550145],
						[77.59415520461796, 13.08462112853169],
						[77.59412844967952, 13.084625613362835],
						[77.59365131887921, 13.084696975824661],
						[77.59350416598261, 13.084300073200035],
						[77.59344173742308, 13.084121754959247],
						[77.59337484964244, 13.083970148521685],
						[77.59295122611162, 13.08298020419551],
						[77.59255881552842, 13.082039338095004],
						[77.59213072970071, 13.081040480076839],
						[77.59165804971431, 13.079898967009763],
						[77.59119874556833, 13.078824310087832],
						[77.59077074202264, 13.077798735253582],
						[77.59065480043569, 13.077522297578906],
						[77.5904496727211, 13.077045113728728],
						[77.5902579225641, 13.076576952124901],
						[77.59003941617446, 13.076068573741582],
						[77.59002603821851, 13.076073016724118],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000000e",
			properties: {
				AREA_SQ_KM: 9.60674915011103,
				ASS_CONST1: "Byatarayanapura",
				ASS_CONST_: "152",
				LAT: 13.0610706104047,
				LON: 77.5973709782568,
				LOST_AREA_SQ_KM: 0.3665203091538673,
				OBJECTID: 15,
				POP_F: 14735.0,
				POP_M: 16665.0,
				POP_SC: 3085.0,
				POP_ST: 1383.0,
				POP_TOTAL: 31400.0,
				RESERVATIO: "Scheduled Tribe (Woman)",
				WARD_NAME: "Byatarayanapura",
				WARD_NO: 7.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5643193399722, 13.045666399102728],
						[77.56444414174868, 13.045822477539362],
						[77.56446198305659, 13.045840338248862],
						[77.56456457052249, 13.045911655983128],
						[77.56472505456465, 13.046027623187179],
						[77.56489900676559, 13.046148039575653],
						[77.56502835566498, 13.046223847266095],
						[77.56520668024088, 13.046326358670463],
						[77.56524682290954, 13.04634417933656],
						[77.56556341600721, 13.046469097072828],
						[77.56573281918811, 13.046540462925744],
						[77.56592461091944, 13.046620678921787],
						[77.56603603013454, 13.046634018595043],
						[77.56622782135256, 13.046660831591865],
						[77.56657563341707, 13.046714362041977],
						[77.56834589894103, 13.046883789308714],
						[77.56839496084119, 13.046888238396546],
						[77.56875168656715, 13.046915022294188],
						[77.56919315386769, 13.046950658810777],
						[77.56950081567963, 13.046990765124239],
						[77.5698084766557, 13.047035357343631],
						[77.56984861769355, 13.04703980511576],
						[77.57045501673896, 13.047124587456079],
						[77.57123089494671, 13.047169183245845],
						[77.57136023661512, 13.047178040574181],
						[77.57253296264571, 13.047231600311909],
						[77.57276042286512, 13.047244953371669],
						[77.57366116434329, 13.047294040305802],
						[77.57396435406143, 13.047311838653378],
						[77.57396881401374, 13.04731188222724],
						[77.57411599234091, 13.047320739800751],
						[77.57516381254663, 13.047369787842962],
						[77.5754670856882, 13.047401047525453],
						[77.57548938515183, 13.04740100641935],
						[77.57567215313907, 13.047418834731152],
						[77.57586392803512, 13.047436667946897],
						[77.5761849513129, 13.047463437777338],
						[77.57645254288876, 13.047494693145213],
						[77.57654619979083, 13.047503630852214],
						[77.57686276129485, 13.047534842661301],
						[77.57704561451145, 13.04755267422408],
						[77.57712589144002, 13.047561567450574],
						[77.5778838847287, 13.04764625873233],
						[77.57818269079357, 13.047664098816988],
						[77.57848140862264, 13.047641815566129],
						[77.57857506384514, 13.047619520294512],
						[77.57859736269621, 13.047615078765938],
						[77.57888270015548, 13.047548145587353],
						[77.57891391846084, 13.047539218300408],
						[77.57891837821806, 13.047539261776544],
						[77.57927515822489, 13.047414445822987],
						[77.57954711477818, 13.047276202771483],
						[77.57993957054227, 13.046990826944871],
						[77.58009557307916, 13.046870434316805],
						[77.58036315540484, 13.046665286494434],
						[77.58055046265612, 13.04652259736591],
						[77.58104985969831, 13.046152498727016],
						[77.58146898074587, 13.04578239427605],
						[77.5814912790264, 13.045760093173268],
						[77.58164290721733, 13.045626287556733],
						[77.58166520546355, 13.045603986442995],
						[77.58167412476081, 13.045599586868262],
						[77.58224487087834, 13.045117934795035],
						[77.58224933050421, 13.04511797816952],
						[77.58226716900586, 13.04510460633946],
						[77.58230284600074, 13.045077862675113],
						[77.58230730562431, 13.045077819771901],
						[77.58291818494736, 13.04467204782073],
						[77.58310548796645, 13.044547215722371],
						[77.5839304220335, 13.044016609657128],
						[77.5844165129693, 13.043704441149726],
						[77.58455029909858, 13.043615237410991],
						[77.58478210723923, 13.043459169480828],
						[77.58484008106052, 13.043427983009687],
						[77.58493819053652, 13.043374495750061],
						[77.5850095428292, 13.043338780238743],
						[77.58517454483355, 13.043267479302441],
						[77.58532170858015, 13.043209464032763],
						[77.58560702907121, 13.043129232900142],
						[77.58580324660664, 13.043071261903807],
						[77.58607081542414, 13.043017692821008],
						[77.58690910617688, 13.042861638751653],
						[77.58762707404755, 13.042732371187576],
						[77.58781874123038, 13.04269220957339],
						[77.58803725191493, 13.042652052495182],
						[77.58814427740218, 13.042634281693225],
						[77.58819779010777, 13.042625309993428],
						[77.58827805911866, 13.042611938690568],
						[77.58836278745719, 13.042607496969907],
						[77.5884787313964, 13.04259861253576],
						[77.58862143146597, 13.04261198762469],
						[77.58867940331774, 13.042616431755516],
						[77.58888007488267, 13.042665482696812],
						[77.5893170042859, 13.042785839753018],
						[77.58937051643606, 13.042794813184104],
						[77.58970942613183, 13.042857195133063],
						[77.59013306182177, 13.042861642968308],
						[77.59032927149775, 13.042826012652817],
						[77.59043629481374, 13.043120302927836],
						[77.59045413202321, 13.043173794641932],
						[77.59059237030112, 13.043664323355557],
						[77.59063250396302, 13.044248464401361],
						[77.59070831195214, 13.04495296677561],
						[77.59082425348417, 13.045519299122741],
						[77.59106496731052, 13.046072172715444],
						[77.5914707608706, 13.04688376553631],
						[77.59172939776354, 13.047343037455203],
						[77.59215302587762, 13.048168014739566],
						[77.59193898239752, 13.048368613906305],
						[77.59117198992945, 13.04859603454701],
						[77.59032927149775, 13.048604948170455],
						[77.58970496680034, 13.048613903916953],
						[77.5886125127169, 13.048769997399955],
						[77.58755126399089, 13.049073150380977],
						[77.586262572687, 13.049523523410013],
						[77.58502292137908, 13.049973932817624],
						[77.58503184041145, 13.05019239503461],
						[77.58504967847405, 13.050312843507507],
						[77.58504967847405, 13.050415344754699],
						[77.58494710957557, 13.050517969683822],
						[77.58475980960773, 13.050620460251242],
						[77.58459034746465, 13.050736411469812],
						[77.58464386185308, 13.050995084383466],
						[77.58472859291626, 13.051307210951403],
						[77.5851388687449, 13.05227928354966],
						[77.58520576140187, 13.052435328648333],
						[77.58372974167979, 13.053452027450138],
						[77.58357820373001, 13.053122077475365],
						[77.58351576968583, 13.053157707532705],
						[77.58235636147197, 13.053701720039404],
						[77.58193724371344, 13.05426804804446],
						[77.583181301005, 13.05664478017753],
						[77.58353360798769, 13.057005954448197],
						[77.58662370153267, 13.055632555042003],
						[77.58719451010028, 13.055480902589595],
						[77.58735950891469, 13.056863246846568],
						[77.5887819688288, 13.056711596597234],
						[77.58894250596352, 13.057737207321527],
						[77.58868386268972, 13.057781801775205],
						[77.58854562207645, 13.05780858522274],
						[77.58793022632632, 13.057915627580146],
						[77.58773401248389, 13.059061593640664],
						[77.58740410314749, 13.060889846672334],
						[77.58710086201737, 13.06224533961798],
						[77.58708748371347, 13.062347893813723],
						[77.58389920511298, 13.064693447725265],
						[77.5812817624603, 13.066664353808143],
						[77.58109445642025, 13.066543954391822],
						[77.57949359797757, 13.06548717638432],
						[77.57771887208597, 13.064243011535753],
						[77.57598880453688, 13.063012329759772],
						[77.57479372726596, 13.062231967644315],
						[77.57301455511869, 13.060831877244384],
						[77.57198446768778, 13.05944061914167],
						[77.57136915672474, 13.058357081245218],
						[77.5707047815394, 13.057090674476626],
						[77.5692510482236, 13.054624755315995],
						[77.56886756315473, 13.05376415771663],
						[77.56833697859327, 13.05285001579037],
						[77.56795349042932, 13.052114277057663],
						[77.56794903024007, 13.052065227804903],
						[77.56765474481624, 13.051547960108385],
						[77.56635716873487, 13.049675132909222],
						[77.56517991845385, 13.04753477814178],
						[77.5643193399722, 13.045666399102728],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000000f",
			properties: {
				AREA_SQ_KM: 3.8519355382144904,
				ASS_CONST1: "Byatarayanapura",
				ASS_CONST_: "152",
				LAT: 13.0538455989976,
				LON: 77.5790877633288,
				LOST_AREA_SQ_KM: 0.14123820395073988,
				OBJECTID: 16,
				POP_F: 10110.0,
				POP_M: 11525.0,
				POP_SC: 2724.0,
				POP_ST: 372.0,
				POP_TOTAL: 21635.0,
				RESERVATIO: "Backward Category - B",
				WARD_NAME: "Kodigehalli",
				WARD_NO: 8.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.55801417887905, 13.085031348999907],
						[77.55772878884059, 13.084411563936307],
						[77.557648498164, 13.084206410908829],
						[77.55792496723717, 13.084126142110756],
						[77.55810330298844, 13.084054820118094],
						[77.55816129047244, 13.084023639849102],
						[77.55818805391661, 13.08398351892793],
						[77.55802310003936, 13.083823005978566],
						[77.55718477029001, 13.083149662274433],
						[77.55655153640758, 13.08266804110638],
						[77.55668535546467, 13.082231074902868],
						[77.55672550115099, 13.082003653029973],
						[77.55677001998914, 13.081954558984785],
						[77.5568324687818, 13.081776202988033],
						[77.55681016564552, 13.081678121526343],
						[77.55650693002013, 13.08143287437334],
						[77.55614579256289, 13.081151932522985],
						[77.55614133190898, 13.081120735546918],
						[77.55614579256289, 13.081089541103282],
						[77.55581579116365, 13.081165305140468],
						[77.55542342673459, 13.081285755796387],
						[77.554500411615, 13.081606825449436],
						[77.55343910550208, 13.081954643699595],
						[77.55330090931594, 13.081245565615195],
						[77.55317154703728, 13.080304705218524],
						[77.55304227207732, 13.080349328422445],
						[77.55232434729217, 13.078935776485158],
						[77.55255622140055, 13.07879311229705],
						[77.55224851359752, 13.078266945411144],
						[77.55199433477732, 13.077923562875027],
						[77.55157519213459, 13.077272552105438],
						[77.55199879559429, 13.076911322708261],
						[77.55342126245965, 13.075965996911616],
						[77.5530467328531, 13.075074176094354],
						[77.55237787692847, 13.073776640880565],
						[77.55170009540457, 13.072269441455909],
						[77.55128086420756, 13.071417761065554],
						[77.55517817641109, 13.06983475632152],
						[77.55528514550113, 13.070057744413425],
						[77.55554377762664, 13.07050805205026],
						[77.55610118601588, 13.071671906105914],
						[77.55658722150491, 13.072621672227159],
						[77.55692614190632, 13.072568168537742],
						[77.55758158922343, 13.072184722030697],
						[77.55797403364889, 13.071676400277301],
						[77.55824158078596, 13.07104318901096],
						[77.55814790874801, 13.070606159349571],
						[77.55864285657707, 13.070352021897476],
						[77.55802310003936, 13.068938455426563],
						[77.55799633655629, 13.068876063694864],
						[77.56029718586801, 13.068340974031136],
						[77.56051566223265, 13.06875118520247],
						[77.56072530468951, 13.068858185618302],
						[77.56094378022907, 13.068907284808486],
						[77.56125592489514, 13.068969736160819],
						[77.56184898864666, 13.068996478562081],
						[77.56267836311453, 13.069018736244406],
						[77.56336954759739, 13.068942985361824],
						[77.56353895405822, 13.06879580927937],
						[77.56357017659461, 13.068773510370004],
						[77.56359693876182, 13.068755654357847],
						[77.56362816128222, 13.068715493785113],
						[77.56365046307725, 13.06869767997199],
						[77.56367722522549, 13.068662048589468],
						[77.56369952701087, 13.068617399161985],
						[77.56373967021348, 13.06847024325763],
						[77.56383325017306, 13.068198235809557],
						[77.5639001554239, 13.06797981215945],
						[77.56391799681744, 13.067953066662541],
						[77.56394029855542, 13.067935166578328],
						[77.56396260028903, 13.067908465147926],
						[77.56398044167275, 13.067886120324175],
						[77.56398490201825, 13.067854928254903],
						[77.56398936236356, 13.067819249236168],
						[77.56398490201825, 13.067788055343634],
						[77.56400720374306, 13.06775238001898],
						[77.56403396580706, 13.067685512664958],
						[77.56414092654218, 13.067435728147782],
						[77.56435948295525, 13.066927411293578],
						[77.56458241181144, 13.06641464858327],
						[77.56476073708917, 13.066098007622207],
						[77.56483210210453, 13.065941929338269],
						[77.56492576861896, 13.065763636787091],
						[77.56502389536057, 13.065589702686026],
						[77.56507287124258, 13.065545141120197],
						[77.56511747425957, 13.065522801638386],
						[77.56517991845385, 13.065522813745313],
						[77.56524682290954, 13.065522783553945],
						[77.56528696556401, 13.065518347570464],
						[77.56531372732573, 13.065509465250573],
						[77.56534048908114, 13.0654960960389],
						[77.56536270308247, 13.065478191686406],
						[77.56538500453611, 13.065451490377638],
						[77.56540284569586, 13.065415771296406],
						[77.5654206868528, 13.065371164738433],
						[77.56548759116632, 13.065143770394542],
						[77.5656035585495, 13.064706840451853],
						[77.56567938331277, 13.064497223582748],
						[77.56572835891144, 13.064439287751116],
						[77.56583094523076, 13.06441251530842],
						[77.56591569038099, 13.06441248802667],
						[77.56605387119177, 13.06443032758726],
						[77.56608063277237, 13.064434819431247],
						[77.56620105980674, 13.06445706041519],
						[77.56625904314802, 13.064457114323007],
						[77.56629918544381, 13.064408068299505],
						[77.56631256620592, 13.06436341790059],
						[77.56622336109537, 13.064131551897784],
						[77.56613861617528, 13.063962114727927],
						[77.56615645721635, 13.06389524685095],
						[77.56635270848277, 13.06382392491759],
						[77.56728909520514, 13.06352958932509],
						[77.568100676576, 13.06318622128684],
						[77.56829237684389, 13.063074726337453],
						[77.56822101400834, 13.062887475093179],
						[77.56806945528814, 13.0628830527053],
						[77.56772610800856, 13.062918676341866],
						[77.56758784178261, 13.062918695371355],
						[77.56742727434066, 13.06289640573535],
						[77.56713744778266, 13.0628205576889],
						[77.56699480806677, 13.062771526942717],
						[77.5666023948744, 13.062651180609313],
						[77.56645074653262, 13.062588683512564],
						[77.5662501226359, 13.062388083752655],
						[77.56600043546786, 13.062116075522447],
						[77.56571051780294, 13.061808378243633],
						[77.5654206868528, 13.061491798969104],
						[77.56530034644565, 13.061277755708915],
						[77.565269124386, 13.061072611886585],
						[77.56525574350066, 13.060675751145052],
						[77.56527358468077, 13.060443912697899],
						[77.56531818761874, 13.059962291933028],
						[77.56542960743022, 13.05974828421184],
						[77.56557679685622, 13.059418324107009],
						[77.56564816135757, 13.058918851139417],
						[77.56563478051699, 13.058887658046036],
						[77.56556341600721, 13.058874272158631],
						[77.56533156849672, 13.058959051200942],
						[77.56522006113202, 13.05906157752417],
						[77.56518437875214, 13.059208767814164],
						[77.56504164912023, 13.059306845161178],
						[77.56526020379594, 13.059569913734151],
						[77.5649837526129, 13.05990438479562],
						[77.56479641960247, 13.060171912573404],
						[77.5647518164591, 13.060510821887766],
						[77.56448874501316, 13.06096113066931],
						[77.56389569507506, 13.061402581987378],
						[77.56315544965324, 13.062151732541135],
						[77.56242866807597, 13.06269574959256],
						[77.56184898864666, 13.06302567691035],
						[77.56172855698713, 13.063052443663373],
						[77.56145655763382, 13.062013500696702],
						[77.56134959436528, 13.062013483488006],
						[77.56119793899389, 13.061393686066584],
						[77.56132283166743, 13.061353589694594],
						[77.56113103214788, 13.060577661396747],
						[77.56091255696919, 13.060608899485734],
						[77.56089025463542, 13.060506349434934],
						[77.5604086980763, 13.060568729662423],
						[77.56025704144618, 13.059766112368207],
						[77.55973533759075, 13.059819639913625],
						[77.5592805393123, 13.059663561083713],
						[77.55933406566595, 13.059445023651172],
						[77.5593206840799, 13.059146275667581],
						[77.55928499984273, 13.058905547226928],
						[77.56036855369395, 13.059021403759226],
						[77.56135405481432, 13.059173047675657],
						[77.56192481592221, 13.05919092189658],
						[77.56229485579739, 13.059128472695267],
						[77.5627809521951, 13.059021441244786],
						[77.56281217494032, 13.05898576985218],
						[77.56288791411286, 13.058958989370486],
						[77.56307078989705, 13.058901085337412],
						[77.56337400796693, 13.0587762016331],
						[77.56347204859414, 13.058718234396084],
						[77.56375305127786, 13.058495292070086],
						[77.56398044167275, 13.05828570920648],
						[77.56447090370948, 13.057821942167102],
						[77.56468499916832, 13.057612403897078],
						[77.56522898172523, 13.057063917736256],
						[77.56569713696975, 13.056667055505526],
						[77.56597367386833, 13.05651990707259],
						[77.56606725198286, 13.056461894764707],
						[77.56616091747618, 13.056408373211562],
						[77.56631702645961, 13.056270221061304],
						[77.56692790464335, 13.05567269251721],
						[77.56732031670809, 13.055364989714967],
						[77.56781968467544, 13.055079558154107],
						[77.56811396967073, 13.054968124756616],
						[77.5685554394571, 13.054892302629947],
						[77.56888540376598, 13.054803133236758],
						[77.56905488943269, 13.054727317895786],
						[77.56907719015945, 13.054718391157692],
						[77.56913517202841, 13.054691695934808],
						[77.56920653428793, 13.054651499858847],
						[77.5692510482236, 13.054624755315995],
						[77.5707047815394, 13.057090674476626],
						[77.57136915672474, 13.058357081245218],
						[77.57198446768778, 13.05944061914167],
						[77.57301455511869, 13.060831877244384],
						[77.57479372726596, 13.062231967644315],
						[77.57598880453688, 13.063012329759772],
						[77.57771887208597, 13.064243011535753],
						[77.57949359797757, 13.06548717638432],
						[77.58109445642025, 13.066543954391822],
						[77.5812817624603, 13.066664353808143],
						[77.58211562909578, 13.06721727872859],
						[77.58331508859608, 13.067966388097734],
						[77.58404191096497, 13.068501529143237],
						[77.58509427361828, 13.069206061669913],
						[77.58628932948852, 13.070008644658508],
						[77.58690464673487, 13.070499210469492],
						[77.58771180287421, 13.071382062976852],
						[77.58838954376142, 13.072354179810027],
						[77.58877750946068, 13.073112217041372],
						[77.58916538638945, 13.073995130115001],
						[77.58950875604, 13.074802193261442],
						[77.58995914841498, 13.075859027555708],
						[77.59002603821851, 13.076073016724118],
						[77.58991901451384, 13.076113220771393],
						[77.58987888059845, 13.076153302827692],
						[77.58982982801582, 13.076220223326207],
						[77.58982982801582, 13.076224624651518],
						[77.58956672743648, 13.076296008443487],
						[77.58850994705203, 13.076599191846412],
						[77.58735059006601, 13.076947016428411],
						[77.58639189716897, 13.077232421633546],
						[77.58627149162156, 13.07726809091944],
						[77.58515670679064, 13.077589153861156],
						[77.58426488849804, 13.077843272905577],
						[77.5834622547634, 13.078097486949266],
						[77.5831411646968, 13.078195562494669],
						[77.58308764959705, 13.078204529698002],
						[77.58304751325561, 13.078213455744313],
						[77.5830162960913, 13.078213407906441],
						[77.58297170012735, 13.078213401201806],
						[77.58291818494736, 13.078222368357133],
						[77.58288259558981, 13.078240145046458],
						[77.58284691877785, 13.078266892699528],
						[77.58284691877785, 13.078280269222246],
						[77.58294494254052, 13.078628070013831],
						[77.58296724052998, 13.07870388835397],
						[77.58302075568673, 13.078873390788383],
						[77.58326157357864, 13.079702779818033],
						[77.58353360798769, 13.080558890855983],
						[77.58372974167979, 13.08112963362916],
						[77.58389920511298, 13.081727219890224],
						[77.58414894023572, 13.082534280863225],
						[77.58435853893077, 13.083203125717889],
						[77.58449232512866, 13.083608949746033],
						[77.58449232512866, 13.08362672846103],
						[77.58449232512866, 13.08364908131519],
						[77.58448786559129, 13.083666902474677],
						[77.5844744869781, 13.083680234374693],
						[77.58446556790176, 13.083689208612299],
						[77.58443435112902, 13.08370253765094],
						[77.58423813121729, 13.08376050268177],
						[77.5834890122278, 13.083983481418601],
						[77.58298507891838, 13.084108322924555],
						[77.5824410942496, 13.084268847237377],
						[77.58206211351062, 13.084380334535117],
						[77.58145560177545, 13.084527463560269],
						[77.5807822789974, 13.084705868016519],
						[77.5798057789575, 13.084968968849486],
						[77.57907446960951, 13.085200821477825],
						[77.57984145672891, 13.086783793902901],
						[77.57987267476967, 13.086913128748002],
						[77.58011341192058, 13.087831725221857],
						[77.57754056721629, 13.088478226941298],
						[77.57703223501781, 13.088589719188509],
						[77.57693857851808, 13.088549610401534],
						[77.5757301316303, 13.088678950134739],
						[77.5732865267195, 13.089000003887636],
						[77.5720915083666, 13.088843874034632],
						[77.57072262186121, 13.089013372140057],
						[77.56896122633245, 13.089263026370885],
						[77.56918423358667, 13.091131416099659],
						[77.56905934957841, 13.091514928079286],
						[77.56931349013925, 13.093311939406341],
						[77.56950081567963, 13.094975145103547],
						[77.56969251357754, 13.096442233393429],
						[77.56976387548583, 13.09722258219554],
						[77.56975495524975, 13.097244847877306],
						[77.56959885100464, 13.097356361977559],
						[77.56909503073767, 13.09741427731618],
						[77.56737829947647, 13.097472298128267],
						[77.5650505697275, 13.097677418531568],
						[77.56502835566498, 13.097713105241443],
						[77.56115779489102, 13.098948278123006],
						[77.559298381433, 13.099893535206393],
						[77.5585982510122, 13.093664226173622],
						[77.55848682448526, 13.089526165050719],
						[77.5591022054125, 13.088888482317662],
						[77.56001626207743, 13.088005564914157],
						[77.56001626207743, 13.087996675893013],
						[77.56006978808374, 13.087952073493451],
						[77.56037747466904, 13.087773729276412],
						[77.5605379646401, 13.087675646155887],
						[77.56107313364272, 13.087359067497385],
						[77.56184452821711, 13.086899759030487],
						[77.56232607867652, 13.086618800135719],
						[77.56212098705826, 13.08640032169272],
						[77.56209868496225, 13.086378006941885],
						[77.56213882873189, 13.08634233256157],
						[77.56215220998529, 13.086271008815867],
						[77.56195595144395, 13.086061418272006],
						[77.56182668649714, 13.085856295032626],
						[77.56097946394414, 13.085138408657208],
						[77.56036409320615, 13.084607768299605],
						[77.56012331406478, 13.08432240305449],
						[77.56008763008022, 13.084277776925374],
						[77.56005194608444, 13.08426887838598],
						[77.55987361339798, 13.084353578684224],
						[77.55894608649115, 13.0849778758845],
						[77.55889255995436, 13.084991237092856],
						[77.55884358140182, 13.084991270650786],
						[77.55879005481661, 13.084964502945354],
						[77.55868746212438, 13.084826266760231],
						[77.55862055379681, 13.084803939682189],
						[77.55855373289172, 13.084808455677672],
						[77.5581702116212, 13.0849689523981],
						[77.55804540293711, 13.08501802481641],
						[77.55801417887905, 13.085031348999907],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000010",
			properties: {
				AREA_SQ_KM: 10.025018112882488,
				ASS_CONST1: "Byatarayanapura",
				ASS_CONST_: "152",
				LAT: 13.0770924126944,
				LON: 77.5694540248082,
				LOST_AREA_SQ_KM: 0.7307982721128576,
				OBJECTID: 17,
				POP_F: 10868.0,
				POP_M: 12268.0,
				POP_SC: 1982.0,
				POP_ST: 505.0,
				POP_TOTAL: 23136.0,
				RESERVATIO: "General",
				WARD_NAME: "Vidyaranyapura",
				WARD_NO: 9.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.47110183656984, 12.949644141135531],
						[77.47139610892249, 12.949639722371854],
						[77.47230579259109, 12.94955055286055],
						[77.47495445130431, 12.948997578627395],
						[77.47615399057665, 12.948578433332464],
						[77.47616738193287, 12.948533839746188],
						[77.4756010049706, 12.9469865519011],
						[77.47542717893413, 12.94636222110535],
						[77.47532906251006, 12.946050160071191],
						[77.47514622088536, 12.945448147993941],
						[77.47503917656273, 12.945064648892126],
						[77.47446841769663, 12.943454899727039],
						[77.47398238202665, 12.942768227200132],
						[77.47360785394385, 12.942366940857596],
						[77.47337153038235, 12.942170726663946],
						[77.47317975783025, 12.941965557302144],
						[77.47311288681465, 12.941845206740831],
						[77.47307271167, 12.941769409188105],
						[77.47307271167, 12.941764928260433],
						[77.47284531483416, 12.941341311061638],
						[77.47261791754697, 12.941042557509633],
						[77.47251087086177, 12.94082409305655],
						[77.47252872657091, 12.940650118467502],
						[77.47274273228011, 12.940440589069832],
						[77.47291664991785, 12.940324592580128],
						[77.47418754482283, 12.939999139498925],
						[77.47452189637393, 12.939825172113112],
						[77.47492775583284, 12.939606760198572],
						[77.4753646855212, 12.939227680888143],
						[77.47584187525574, 12.938893293109118],
						[77.47633683059247, 12.938558817639986],
						[77.4765552929572, 12.938300211506323],
						[77.47690311639101, 12.937979111818144],
						[77.47726432999632, 12.937805235674915],
						[77.47771910567008, 12.9376446826282],
						[77.47831216684507, 12.937310304177634],
						[77.47878041693076, 12.937047170191619],
						[77.47919956477556, 12.936913409326703],
						[77.47971236018728, 12.93690895901045],
						[77.4802697020406, 12.937047209326835],
						[77.48091184961476, 12.937167594393012],
						[77.48207561803927, 12.9377606488292],
						[77.4845593405165, 12.937533263759777],
						[77.48381472829453, 12.93420678773513],
						[77.48376116643871, 12.933823241492357],
						[77.4836229732341, 12.933025092939843],
						[77.4834803163627, 12.93260147703135],
						[77.48343121785231, 12.932347317773536],
						[77.48342675435032, 12.93207972417851],
						[77.4835070098399, 12.931776510342273],
						[77.48368983811139, 12.931415361759045],
						[77.48376116643871, 12.931116628417143],
						[77.4837567029496, 12.930791047353093],
						[77.48396631171141, 12.930603788446563],
						[77.4839439943041, 12.930536876873685],
						[77.48381026480752, 12.930318433539119],
						[77.48351147333875, 12.92977439236209],
						[77.4832349986397, 12.929386445990302],
						[77.48313688890721, 12.928936050335425],
						[77.48288273092173, 12.928369793380616],
						[77.48265535356789, 12.927839129501255],
						[77.48252153504491, 12.927611727115853],
						[77.48237887680033, 12.92764738586186],
						[77.48217819223252, 12.927348677752823],
						[77.48207115448442, 12.927348665775396],
						[77.48197313127486, 12.926898283900297],
						[77.48188394758536, 12.926354242208525],
						[77.48184823908176, 12.926100143414919],
						[77.48170111642857, 12.925908375548273],
						[77.48152274855613, 12.925734482055809],
						[77.4813443804057, 12.925507082303213],
						[77.48123734187776, 12.925319754724127],
						[77.48124626905233, 12.925136920954035],
						[77.48124626905235, 12.924971921567028],
						[77.48118386633692, 12.924775727011221],
						[77.48104111890343, 12.924570646383856],
						[77.48105004609333, 12.92436101780848],
						[77.48108575484598, 12.924142518948168],
						[77.4811303032497, 12.923982003666968],
						[77.48116601197731, 12.923888352164525],
						[77.48139339229024, 12.923759088247646],
						[77.48172334675236, 12.923638665863422],
						[77.48197313127486, 12.923558390934728],
						[77.48227630278697, 12.923446924461683],
						[77.48233870490674, 12.923634242319505],
						[77.48247698718274, 12.92410689351138],
						[77.48273114606938, 12.92481589625875],
						[77.48290058501354, 12.925288510153344],
						[77.48385927563594, 12.924967513402684],
						[77.48511228284612, 12.924633095748577],
						[77.4854868608102, 12.925654155564908],
						[77.48625386662717, 12.927741028988999],
						[77.48673092344737, 12.929034195142767],
						[77.48715905640088, 12.93028270246958],
						[77.48750238426516, 12.931549093582714],
						[77.48777438531835, 12.932342858593334],
						[77.48790819769687, 12.932601465701314],
						[77.48814449124649, 12.932744139012065],
						[77.4886216276961, 12.93272190966734],
						[77.4893172001568, 12.932588063839782],
						[77.48994599471986, 12.932338360493233],
						[77.49044088905256, 12.932267008133145],
						[77.49055684541668, 12.932347328626518],
						[77.49079322100673, 12.932588064980303],
						[77.49102504538877, 12.932958181248926],
						[77.4913639859664, 12.933363987179812],
						[77.49137737554256, 12.933038480820423],
						[77.49130150125693, 12.932097626776766],
						[77.49118116996249, 12.931562466593954],
						[77.49082437599066, 12.930849045072904],
						[77.4906370959032, 12.930202511466266],
						[77.49052569035983, 12.929132264038772],
						[77.49046766841956, 12.928137937251739],
						[77.49024476937963, 12.927361999851438],
						[77.49002178239054, 12.926568314204802],
						[77.48973193366047, 12.925854893664859],
						[77.4894420841956, 12.925306395977557],
						[77.48929050803551, 12.924722283990459],
						[77.48912991760051, 12.923522709651712],
						[77.48899619416699, 12.92337562439696],
						[77.48851013267317, 12.92289398969294],
						[77.48820242684968, 12.922019989686227],
						[77.48811324801625, 12.921467089573838],
						[77.48832284879502, 12.921039014122433],
						[77.48893817145053, 12.921288741293933],
						[77.49037402812199, 12.921921933424276],
						[77.49157804396991, 12.922483773788304],
						[77.49278642276056, 12.923054528443593],
						[77.49419545260753, 12.923683237385674],
						[77.49565810889919, 12.924325396336663],
						[77.49705380193465, 12.924989823573943],
						[77.49816857641667, 12.92553382615445],
						[77.49853418577409, 12.925738911909479],
						[77.49853864868626, 12.925743349810755],
						[77.49873484163295, 12.925850374393411],
						[77.49893996003577, 12.926002034121877],
						[77.49871699001392, 12.926880415222918],
						[77.49859220361861, 12.927709865619308],
						[77.49851633412369, 12.928655170535048],
						[77.49853418577409, 12.929212513004318],
						[77.4986322822934, 12.929872488750208],
						[77.49863674520174, 12.930255988039347],
						[77.49856988906652, 12.930835696502154],
						[77.49910499957537, 12.930750929511806],
						[77.4993145801952, 12.931825619379206],
						[77.49967572301645, 12.93398825448529],
						[77.49980059573497, 12.934813199120903],
						[77.49993885688978, 12.935455332782857],
						[77.49908268512345, 12.935727335029329],
						[77.49959547887791, 12.937903327988368],
						[77.49947506881068, 12.93820213474517],
						[77.49937697301526, 12.93838042693944],
						[77.49912722651528, 12.938549917149466],
						[77.49895334872419, 12.938661375656155],
						[77.498449477918, 12.938955676042397],
						[77.49788321128695, 12.939383804126741],
						[77.4972142937268, 12.939878748452738],
						[77.49719197890549, 12.939896541238728],
						[77.49716528862332, 12.939932219383607],
						[77.49716528862332, 12.939963415359076],
						[77.49716975158898, 12.940012493565051],
						[77.49745958145546, 12.940610024673655],
						[77.4976334615206, 12.941011322848555],
						[77.49937251013586, 12.943584246234233],
						[77.49989869116648, 12.943392538315821],
						[77.50010380747892, 12.944311064700914],
						[77.49919863271819, 12.944658858966754],
						[77.49920755849041, 12.944676699508719],
						[77.4996044046191, 12.94638454767709],
						[77.49888202987572, 12.946571841751183],
						[77.49889541857091, 12.946629800987496],
						[77.49882401217842, 12.94666995225985],
						[77.49808386828943, 12.946839405215584],
						[77.49753536414347, 12.94687507215205],
						[77.497147436759, 12.946906274924794],
						[77.49658553915212, 12.946973178245509],
						[77.49664802097244, 12.947084620393673],
						[77.4966970262978, 12.947191618114214],
						[77.49674611911158, 12.94732542280114],
						[77.49642504647665, 12.947320993091058],
						[77.49580521359624, 12.947325450722994],
						[77.49535042231729, 12.947378921856977],
						[77.4945477729881, 12.947499313554173],
						[77.49346866806309, 12.947601916576016],
						[77.49284435601143, 12.947637519044005],
						[77.49237170033823, 12.947619708473438],
						[77.49192135856538, 12.947584018157222],
						[77.49125249361775, 12.947570661953764],
						[77.49058808797918, 12.947561742841724],
						[77.49051230068392, 12.947552814114847],
						[77.49027592451301, 12.947490429464013],
						[77.48947332706275, 12.948190455020951],
						[77.48863501759342, 12.948984236660765],
						[77.48871526942884, 12.949140235467434],
						[77.48870634283706, 12.949189357104794],
						[77.48871080613304, 12.949274031082734],
						[77.48870634283706, 12.949354308185978],
						[77.48866170986773, 12.949407818401658],
						[77.48815788119967, 12.949706581800179],
						[77.48746667751516, 12.950192583993084],
						[77.48674877693861, 12.950678668966907],
						[77.48636973971968, 12.95096848166774],
						[77.4859996280484, 12.951195899792433],
						[77.48565183225811, 12.951329685061488],
						[77.48547793396644, 12.951356416613317],
						[77.48543785067903, 12.951369813724916],
						[77.48513460002553, 12.951396565066762],
						[77.48480911884819, 12.951445593452167],
						[77.48467083939066, 12.951583847038712],
						[77.48481804574415, 12.951722067229904],
						[77.48489830025778, 12.951766722515517],
						[77.48496070094758, 12.9518736837524],
						[77.4849740912735, 12.951949483936984],
						[77.485081126306, 12.952034219863947],
						[77.48508558974346, 12.952110018269597],
						[77.48507219943058, 12.952386532150472],
						[77.48505880911615, 12.952555921410692],
						[77.48509442909945, 12.952645124081707],
						[77.48519700058645, 12.95273429817243],
						[77.48531742569612, 12.952814599326333],
						[77.4853887530087, 12.952863648601769],
						[77.48549578765325, 12.952903742511646],
						[77.48554479775959, 12.952979546915241],
						[77.48562067586495, 12.9532337609299],
						[77.48563844201153, 12.953505755755927],
						[77.48558943194531, 12.953893705610664],
						[77.48558943194531, 12.954067624859965],
						[77.48556265143597, 12.954393136544729],
						[77.48562513928135, 12.954678506709147],
						[77.48562513928135, 12.954758744497093],
						[77.48562513928135, 12.954830105325541],
						[77.48566522250314, 12.955044149589114],
						[77.48501863816345, 12.955418723881403],
						[77.485049882239, 12.956399734396586],
						[77.48551810475784, 12.95786227077498],
						[77.48590160820446, 12.96091227698234],
						[77.48608880852987, 12.963146353545184],
						[77.48623154966612, 12.963231023457253],
						[77.4863073397985, 12.963770551070695],
						[77.48622262288048, 12.964537510511539],
						[77.48600855485145, 12.965959984403415],
						[77.48592383771215, 12.966793842830256],
						[77.48547793396644, 12.967988912513398],
						[77.48475109400758, 12.968965466973165],
						[77.48427849239972, 12.969643201827155],
						[77.48352486383428, 12.970191704623002],
						[77.48228969342703, 12.970628643378001],
						[77.48175012816034, 12.97093636140223],
						[77.48127751415795, 12.971172684383406],
						[77.47874024377423, 12.971404567016044],
						[77.47688972512086, 12.971328801903338],
						[77.47598900543868, 12.971293054835524],
						[77.47511051028027, 12.971462577966905],
						[77.47408942733689, 12.971462520174844],
						[77.4741295145982, 12.971043412573126],
						[77.47408496347067, 12.970517192728112],
						[77.47399577363676, 12.970151538093672],
						[77.47370150796111, 12.969745752366634],
						[77.47344732902964, 12.969562999033359],
						[77.47321100513699, 12.969571856638765],
						[77.47310395900593, 12.969571912564572],
						[77.47301476840826, 12.969576369999073],
						[77.47288986644378, 12.969527290601153],
						[77.47280522712346, 12.96942026437737],
						[77.47274273228011, 12.969299834159273],
						[77.47272496413215, 12.969166063055122],
						[77.47266246924502, 12.969099244333263],
						[77.47245292731587, 12.968880736883648],
						[77.47192670754859, 12.968434811050255],
						[77.47187769161225, 12.96839915942624],
						[77.47183760356299, 12.968363424519467],
						[77.47182867565492, 12.968341184380007],
						[77.47176171632218, 12.967877413771854],
						[77.47171270031524, 12.967543026642756],
						[77.47170377239743, 12.967262043934825],
						[77.47168591655972, 12.967177313426257],
						[77.47159681238688, 12.966932083086776],
						[77.47159234842354, 12.966704670198622],
						[77.47149869268218, 12.966637804393269],
						[77.47143628465501, 12.966570905557644],
						[77.47141842877562, 12.966544181102524],
						[77.47141842877562, 12.966486175632618],
						[77.47143182068542, 12.966441576926865],
						[77.47144512506486, 12.966392578324987],
						[77.47149869268218, 12.966236506688347],
						[77.4715789565325, 12.966107158111855],
						[77.4715655646399, 12.965518526545745],
						[77.4716592203266, 12.964568799015643],
						[77.47221213743288, 12.960087327900396],
						[77.47220767349351, 12.960029367239711],
						[77.47222106531107, 12.959293656425784],
						[77.47227454503746, 12.958419675829287],
						[77.47217196197238, 12.95700611710227],
						[77.47191340322509, 12.954821179276095],
						[77.47148530078019, 12.952404334336292],
						[77.47218981773433, 12.952034264820957],
						[77.47272934052353, 12.951566042122588],
						[77.47238605587604, 12.951004216203751],
						[77.47187322765986, 12.95054490382155],
						[77.47119549263556, 12.950486932942828],
						[77.47110183656984, 12.949644141135531],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000011",
			properties: {
				AREA_SQ_KM: 8.99183285278363,
				ASS_CONST1: "Yeshwantpura",
				ASS_CONST_: "153",
				LAT: 12.9467161449535,
				LON: 77.4846181877041,
				LOST_AREA_SQ_KM: 0.5819017462900199,
				OBJECTID: 18,
				POP_F: 9864.0,
				POP_M: 10468.0,
				POP_SC: 3751.0,
				POP_ST: 207.0,
				POP_TOTAL: 20332.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Ullalu",
				WARD_NO: 130.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.48116601197731, 12.923888352164525],
						[77.48121056034978, 12.923772468326897],
						[77.48127305057196, 12.923629795445017],
						[77.48121056034978, 12.923562839758967],
						[77.48100996125478, 12.923290858490477],
						[77.48092970401403, 12.922969818763853],
						[77.48043021739139, 12.923148204813304],
						[77.47958746552732, 12.923513827730998],
						[77.4789498617235, 12.923750143920513],
						[77.47879372045661, 12.923402339800278],
						[77.47941355801979, 12.92319273524804],
						[77.47925750490816, 12.92263539763507],
						[77.47919063744133, 12.922020001109736],
						[77.47903012035238, 12.922229610082603],
						[77.47853508913406, 12.922568462497846],
						[77.47830770314346, 12.922902952857903],
						[77.47816950335424, 12.92316602711944],
						[77.47785730600292, 12.92356284097447],
						[77.47708149146136, 12.923861645533677],
						[77.47614059921888, 12.92410239465331],
						[77.47546280191476, 12.924222850024302],
						[77.47479392827714, 12.924361053310461],
						[77.47459778178641, 12.923643154461008],
						[77.47433914098734, 12.922568469480776],
						[77.47431235784585, 12.922519441037194],
						[77.47415183391885, 12.922550697082091],
						[77.47386640886711, 12.92273348710695],
						[77.47378614661928, 12.922746831753912],
						[77.47353651929053, 12.92278695430907],
						[77.47316636612476, 12.923076869820191],
						[77.47272050021276, 12.923437989100243],
						[77.47266693316666, 12.923482609234428],
						[77.47254658227727, 12.923469198086664],
						[77.4724797108921, 12.923469233133085],
						[77.47240828800828, 12.923433550592591],
						[77.47233695260806, 12.923313179031553],
						[77.47223445712704, 12.922911888950981],
						[77.47180189192652, 12.921440313128853],
						[77.4711508528381, 12.919429273450604],
						[77.4709992524549, 12.918996759197237],
						[77.47094577213328, 12.91887191636441],
						[77.4708922042575, 12.91876929723254],
						[77.47084318787861, 12.918675686340029],
						[77.47074953152496, 12.918501772163605],
						[77.47056668259148, 12.918015712105538],
						[77.47050873806248, 12.917864119120113],
						[77.47050873806248, 12.91777943033976],
						[77.47051766607338, 12.917592134648423],
						[77.47051766607338, 12.91758765468101],
						[77.46606302741739, 12.910189992513763],
						[77.46544320515801, 12.909258119903924],
						[77.4651533817375, 12.909093074937292],
						[77.46501516608208, 12.908758686219588],
						[77.46476543211634, 12.908254793653995],
						[77.46466284233104, 12.907692902222763],
						[77.46465837809768, 12.907260414455568],
						[77.46465391386415, 12.906756515171443],
						[77.46438194542708, 12.906087626189336],
						[77.4640519410181, 12.905565906786684],
						[77.46383791908346, 12.905325115396442],
						[77.46332067587554, 12.905048713917425],
						[77.46295504155594, 12.904977347601692],
						[77.46251806456068, 12.904981777627587],
						[77.46169759023678, 12.904914921779659],
						[77.46152365557899, 12.904897111422926],
						[77.46064076024014, 12.904286178820495],
						[77.46010118056493, 12.90384029142521],
						[77.4602216320557, 12.900451381264313],
						[77.46210778436428, 12.897588592800481],
						[77.46270083879487, 12.897954254268173],
						[77.46340987401608, 12.898217340246376],
						[77.46395390239005, 12.898524986304574],
						[77.46464507293058, 12.899064571873732],
						[77.46518900791173, 12.899550608158469],
						[77.4655635635061, 12.899911820006595],
						[77.46616561595003, 12.900308675474022],
						[77.46695034516203, 12.900754597246015],
						[77.46801166865258, 12.901365508320431],
						[77.46868942250447, 12.901851482368613],
						[77.46921119308078, 12.902301916894295],
						[77.46970162549655, 12.902828020823026],
						[77.47015651872404, 12.903661919377637],
						[77.47074060353211, 12.904696415427937],
						[77.47124897284063, 12.905200314202753],
						[77.47227454503746, 12.905846823608233],
						[77.47287656223212, 12.906315114661082],
						[77.47345625681156, 12.906729778935325],
						[77.47408942733689, 12.90699730907327],
						[77.47448180924992, 12.907086498432298],
						[77.47481624746831, 12.90708200001987],
						[77.47526219274916, 12.907148882893036],
						[77.47567242586379, 12.907327321177156],
						[77.47642155482828, 12.907831161205488],
						[77.47718844635065, 12.908281503000872],
						[77.47786176972211, 12.908691741635442],
						[77.47894539804675, 12.909231319876797],
						[77.47991296155637, 12.90956576358081],
						[77.48077356544765, 12.909886850037031],
						[77.481799227392, 12.910261404629207],
						[77.48243235178279, 12.910346119871217],
						[77.48335988931932, 12.910265873307324],
						[77.48402424943086, 12.910279210825944],
						[77.48426510199239, 12.910350557416544],
						[77.4843720501693, 12.910479872052214],
						[77.48444346559019, 12.910640379081995],
						[77.48468422975051, 12.911554548667262],
						[77.48497855471511, 12.912477519093832],
						[77.48508558974346, 12.912749597231281],
						[77.48528618169365, 12.912950211866395],
						[77.48554033434006, 12.913119644133037],
						[77.48573208618681, 12.913266847376088],
						[77.48609773532597, 12.913708232275944],
						[77.48654810003596, 12.914332572832706],
						[77.4870029263353, 12.914809634948975],
						[77.48743998496192, 12.915291268295162],
						[77.48780562864114, 12.915549838779773],
						[77.48823367005552, 12.915768348273131],
						[77.48871080613304, 12.91599129923911],
						[77.48902297387235, 12.916169658327908],
						[77.4894956433912, 12.916513014073898],
						[77.48995938446211, 12.916999047513873],
						[77.49025807157246, 12.917360273475735],
						[77.49073073598528, 12.917953321141978],
						[77.49098496404564, 12.918408124387243],
						[77.49127034640303, 12.918880806328097],
						[77.49147101501826, 12.91916621898895],
						[77.49175193298515, 12.919447154648498],
						[77.49206400490773, 12.919723566033152],
						[77.49242079501032, 12.919959921279355],
						[77.49269278436246, 12.920182872037556],
						[77.49289345048051, 12.920481643619866],
						[77.49311643186785, 12.920802690723152],
						[77.49331700972999, 12.921181676745443],
						[77.4935533796122, 12.921493809536548],
						[77.49381644007622, 12.921658862010558],
						[77.49421776801408, 12.921819397902667],
						[77.49467711437534, 12.92203340224676],
						[77.49506058710001, 12.922300951229843],
						[77.49549752741048, 12.922586336458007],
						[77.49588099738172, 12.922813734264722],
						[77.4962600905747, 12.922951999601946],
						[77.49677289700112, 12.923161523748774],
						[77.497147436759, 12.923326538266691],
						[77.49758436908816, 12.923714461223149],
						[77.49804816484507, 12.92418269779956],
						[77.49849410706489, 12.924606301490755],
						[77.49884632668088, 12.924918444693931],
						[77.49895334872419, 12.925052177613077],
						[77.49913615229308, 12.92522162756877],
						[77.49901127884806, 12.9260465832401],
						[77.49893996003577, 12.926002034121877],
						[77.49873484163295, 12.925850374393411],
						[77.49853864868626, 12.925743349810755],
						[77.49853418577409, 12.925738911909479],
						[77.49816857641667, 12.92553382615445],
						[77.49705380193465, 12.924989823573943],
						[77.49565810889919, 12.924325396336663],
						[77.49419545260753, 12.923683237385674],
						[77.49278642276056, 12.923054528443593],
						[77.49157804396991, 12.922483773788304],
						[77.49037402812199, 12.921921933424276],
						[77.48893817145053, 12.921288741293933],
						[77.48832284879502, 12.921039014122433],
						[77.48811324801625, 12.921467089573838],
						[77.48820242684968, 12.922019989686227],
						[77.48851013267317, 12.92289398969294],
						[77.48899619416699, 12.92337562439696],
						[77.48912991760051, 12.923522709651712],
						[77.48929050803551, 12.924722283990459],
						[77.4894420841956, 12.925306395977557],
						[77.48973193366047, 12.925854893664859],
						[77.49002178239054, 12.926568314204802],
						[77.49024476937963, 12.927361999851438],
						[77.49046766841956, 12.928137937251739],
						[77.49052569035983, 12.929132264038772],
						[77.4906370959032, 12.930202511466266],
						[77.49082437599066, 12.930849045072904],
						[77.49118116996249, 12.931562466593954],
						[77.49130150125693, 12.932097626776766],
						[77.49137737554256, 12.933038480820423],
						[77.4913639859664, 12.933363987179812],
						[77.49102504538877, 12.932958181248926],
						[77.49079322100673, 12.932588064980303],
						[77.49055684541668, 12.932347328626518],
						[77.49044088905256, 12.932267008133145],
						[77.48994599471986, 12.932338360493233],
						[77.4893172001568, 12.932588063839782],
						[77.4886216276961, 12.93272190966734],
						[77.48814449124649, 12.932744139012065],
						[77.48790819769687, 12.932601465701314],
						[77.48777438531835, 12.932342858593334],
						[77.48750238426516, 12.931549093582714],
						[77.48715905640088, 12.93028270246958],
						[77.48673092344737, 12.929034195142767],
						[77.48625386662717, 12.927741028988999],
						[77.4854868608102, 12.925654155564908],
						[77.48511228284612, 12.924633095748577],
						[77.48385927563594, 12.924967513402684],
						[77.48290058501354, 12.925288510153344],
						[77.48273114606938, 12.92481589625875],
						[77.48247698718274, 12.92410689351138],
						[77.48233870490674, 12.923634242319505],
						[77.48227630278697, 12.923446924461683],
						[77.48197313127486, 12.923558390934728],
						[77.48172334675236, 12.923638665863422],
						[77.48139339229024, 12.923759088247646],
						[77.48116601197731, 12.923888352164525],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000012",
			properties: {
				AREA_SQ_KM: 4.913808992800819,
				ASS_CONST1: "Yeshwantpura",
				ASS_CONST_: "153",
				LAT: 12.9150685525484,
				LON: 77.4775276168771,
				LOST_AREA_SQ_KM: 0.426086513976991,
				OBJECTID: 19,
				POP_F: 11991.0,
				POP_M: 12879.0,
				POP_SC: 2440.0,
				POP_ST: 220.0,
				POP_TOTAL: 24870.0,
				RESERVATIO: "General",
				WARD_NAME: "Kengeri",
				WARD_NO: 159.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.46736507216235, 12.986882120279784],
						[77.46744533890745, 12.986676984733641],
						[77.4677753335504, 12.986391595276256],
						[77.46942520498557, 12.985722704081242],
						[77.47154324481542, 12.984844260556875],
						[77.47217642591312, 12.984594561993248],
						[77.47244399945575, 12.984496463864858],
						[77.47254658227727, 12.984465249180007],
						[77.47346063317501, 12.984652553261636],
						[77.47403139706256, 12.984670404227614],
						[77.47427227064851, 12.984416269783356],
						[77.47490097282898, 12.984750631225436],
						[77.47570804876814, 12.985352626555482],
						[77.47643932240283, 12.985807493417447],
						[77.47683178379003, 12.986119592482556],
						[77.47783507492835, 12.98665467367887],
						[77.47810709896157, 12.987033761869585],
						[77.47834341275156, 12.98724775356367],
						[77.47864659387743, 12.987461808133492],
						[77.47867783969262, 12.98966015247356],
						[77.47862873912206, 12.99253180972533],
						[77.47841474441084, 12.992665542042655],
						[77.47841920810828, 12.993004480327928],
						[77.4785039308033, 12.993379050259298],
						[77.47862873912206, 12.993717897658673],
						[77.47869123075367, 12.99401664315576],
						[77.47904788752216, 12.994177194226475],
						[77.47947596170033, 12.994047841445441],
						[77.4803009474128, 12.993798134777615],
						[77.48084051951145, 12.994618656964791],
						[77.48113476684125, 12.995733394131626],
						[77.4815806875128, 12.996050025884346],
						[77.48377902039344, 12.996727797521968],
						[77.48544231410256, 12.997499204926235],
						[77.48715012968762, 12.998382102131789],
						[77.48833623872733, 12.999336366056493],
						[77.48887577293074, 12.999791197399146],
						[77.48918794021967, 13.000246008561893],
						[77.4895223354646, 13.000928314867144],
						[77.4900707905573, 13.001672941987765],
						[77.4903339465506, 13.001949402673345],
						[77.49089578738247, 13.002564754009068],
						[77.4914085303673, 13.003068651198435],
						[77.49173854345285, 13.003184546543663],
						[77.49667917436028, 13.008557765884897],
						[77.49680851333467, 13.008700467271515],
						[77.49747297031738, 13.009422860476104],
						[77.49818642812143, 13.010198775788856],
						[77.49827113617262, 13.010292400708417],
						[77.49975596709862, 13.01190656738592],
						[77.49987191400977, 13.012022494760188],
						[77.49987191400977, 13.012116140783979],
						[77.49984513684679, 13.01222762769898],
						[77.49981398432246, 13.012441688586183],
						[77.49969357448562, 13.01262002064406],
						[77.4995196975568, 13.012981234511118],
						[77.49902466752809, 13.013917596954284],
						[77.4987526932492, 13.014648889826269],
						[77.49845394083347, 13.01543821069171],
						[77.49827113617262, 13.016049069583858],
						[77.49769585525902, 13.01763656984951],
						[77.49766023920213, 13.017730211172436],
						[77.49760222088422, 13.017774730137944],
						[77.49737924825091, 13.017837178045575],
						[77.49717859001065, 13.017904074760198],
						[77.49706272787448, 13.017846092341617],
						[77.49695570405972, 13.017819325472123],
						[77.49683082823087, 13.01783722112136],
						[77.49670148928175, 13.017872836075632],
						[77.49652760779799, 13.01793525761924],
						[77.49638041652138, 13.018006652793527],
						[77.49622438656031, 13.018060112247742],
						[77.49602372629153, 13.01811364143926],
						[77.49591223848881, 13.018135982381237],
						[77.4957428188252, 13.018220696171985],
						[77.49564471982457, 13.018291979452671],
						[77.49560008956446, 13.018349998229953],
						[77.49559116351035, 13.018439160579911],
						[77.49535934839021, 13.019299788864414],
						[77.4952701751402, 13.019830446700912],
						[77.4950917409207, 13.020097970190541],
						[77.49494909835748, 13.020204942223595],
						[77.49480190504725, 13.020343168365509],
						[77.49469041605232, 13.0204189739047],
						[77.49421776801408, 13.020762374997433],
						[77.49406173509972, 13.020891633113107],
						[77.49390115136754, 13.020998679600671],
						[77.49380751388277, 13.020998690854812],
						[77.49377189661377, 13.020998681343514],
						[77.49370057453062, 13.021003142267546],
						[77.49361577557876, 13.021065586836126],
						[77.49354891650529, 13.021150291217891],
						[77.49353999029094, 13.021297491278014],
						[77.49370941322064, 13.021676467045385],
						[77.49381197697959, 13.021774595664684],
						[77.4940260303984, 13.021908335643424],
						[77.49422669417548, 13.022037629404322],
						[77.4943916530131, 13.022242797300388],
						[77.49469041605232, 13.022675332506317],
						[77.49479297893079, 13.022817960234041],
						[77.49489107866272, 13.023112320265685],
						[77.49498025220856, 13.02329508683279],
						[77.49508727787388, 13.023482431236861],
						[77.49516761269007, 13.023852507144053],
						[77.49540835426832, 13.024913746511647],
						[77.49572496673858, 13.02603750680294],
						[77.49607273188468, 13.027219111598736],
						[77.49629570706811, 13.028159990233354],
						[77.49631355906556, 13.02822692033992],
						[77.49656777470678, 13.029172261999946],
						[77.49683529120958, 13.0301487901533],
						[77.49694231513716, 13.030550116243402],
						[77.49718751594072, 13.031383958473647],
						[77.4972410715066, 13.031651507911954],
						[77.49730346545932, 13.032592366141682],
						[77.49748180871558, 13.034920020123073],
						[77.4974951975749, 13.035009161141378],
						[77.49752197528885, 13.035383740431946],
						[77.49748627166886, 13.03605705451002],
						[77.49745958145546, 13.037015773673911],
						[77.49738817416532, 13.037216442429424],
						[77.49711619598968, 13.037573191779463],
						[77.49701363519675, 13.037840737579224],
						[77.49654545975919, 13.038701362620357],
						[77.49630017006773, 13.039231912156792],
						[77.49621992355773, 13.039445952118513],
						[77.49620207154564, 13.039949899412544],
						[77.49623331256494, 13.040600883181968],
						[77.49626901657653, 13.040783748904389],
						[77.49660339110427, 13.04080600040598],
						[77.49676397103863, 13.041666588212761],
						[77.49637157803795, 13.041898535093804],
						[77.49623777556701, 13.041996634309953],
						[77.4962467015706, 13.042023376052683],
						[77.49622438656031, 13.042036692043862],
						[77.49515422355798, 13.042696690027112],
						[77.49474397278874, 13.044172634890812],
						[77.49435603592615, 13.046602869844104],
						[77.49440504223456, 13.0497643512003],
						[77.49440504223456, 13.04978220623298],
						[77.49424008341627, 13.049840144168185],
						[77.49428025112924, 13.0499962438501],
						[77.49446306217612, 13.050745331275708],
						[77.4945834775264, 13.051240284361148],
						[77.49449421615974, 13.051262582589157],
						[77.4943024790079, 13.051307224201123],
						[77.49364255420194, 13.051454351882985],
						[77.49300494122855, 13.051570305975545],
						[77.49255451443808, 13.051655024834137],
						[77.49197482908636, 13.051761985374938],
						[77.49188565319538, 13.051240351248685],
						[77.49176978569243, 13.050749849397395],
						[77.49193474807625, 13.050678457826336],
						[77.49225574581506, 13.050566980902728],
						[77.49225574581506, 13.050451052621117],
						[77.49218888594598, 13.049728674998956],
						[77.49216657014325, 13.04921591911457],
						[77.49210417338551, 13.048618405239461],
						[77.49208185756626, 13.048132306948927],
						[77.49206846807263, 13.048034186237647],
						[77.49188119002335, 13.047525896293303],
						[77.4916225882874, 13.046763390588268],
						[77.49135059638867, 13.04596961775341],
						[77.49110529554662, 13.045260645318587],
						[77.49102950859421, 13.045095656829544],
						[77.49098942725267, 13.045077831103775],
						[77.49069511775748, 13.045184829311472],
						[77.48941084131992, 13.045697641769816],
						[77.48907644574892, 13.045875995643474],
						[77.48851013267317, 13.04604544685974],
						[77.48783232110904, 13.046228257983573],
						[77.48732848878696, 13.046357628926012],
						[77.48645445652872, 13.046540432637729],
						[77.48627163267541, 13.046620693478223],
						[77.48627163267541, 13.046674169579589],
						[77.4863073397985, 13.046816847970533],
						[77.48533527940798, 13.047115614215603],
						[77.48506773599262, 13.0471335183098],
						[77.48314581593363, 13.047873656343839],
						[77.48194634991907, 13.048252728561877],
						[77.48182145769674, 13.048324083809197],
						[77.48148257636201, 13.048292872043397],
						[77.4814067830336, 13.048306248519266],
						[77.4811481576149, 13.04833743422443],
						[77.48100996125478, 13.048404344091448],
						[77.48087614080787, 13.048390908535103],
						[77.48085828640025, 13.048266093073021],
						[77.48082266509826, 13.047784537258913],
						[77.48074240772618, 13.047579394099348],
						[77.48054618390334, 13.047485716761862],
						[77.48018051702377, 13.047472343947023],
						[77.47993081611145, 13.047499134470133],
						[77.47970343289306, 13.047035386840243],
						[77.47959639283059, 13.046634098587603],
						[77.47953398921568, 13.046192643157529],
						[77.47973012725119, 13.045786843604006],
						[77.47980600921224, 13.045751133153079],
						[77.47992188883426, 13.045724362046544],
						[77.48006910129668, 13.045679801024841],
						[77.48030541103668, 13.045461313034206],
						[77.4804927080396, 13.045291836250582],
						[77.48066661390764, 13.044953002232406],
						[77.48105004609333, 13.044110183646875],
						[77.4811303032497, 13.044025524687788],
						[77.48119279351569, 13.043985365030183],
						[77.48124180546513, 13.043945237323564],
						[77.48125073263938, 13.043909535043017],
						[77.48124626905233, 13.043864985429451],
						[77.48119725710481, 13.043633085846675],
						[77.48120609676118, 13.043561758875295],
						[77.4812016331724, 13.043539461429978],
						[77.48119279351569, 13.043534976219611],
						[77.48117493915746, 13.04351271503747],
						[77.48115262120575, 13.043526028930001],
						[77.48113476684125, 13.043539474482504],
						[77.48110360921811, 13.043584009899805],
						[77.48100103406176, 13.043744598744109],
						[77.4809742524785, 13.043771321932974],
						[77.48095639808618, 13.043780196318428],
						[77.48092524041448, 13.043784712542953],
						[77.48090292241407, 13.04376689078405],
						[77.48089399521271, 13.043749032798642],
						[77.48082712870182, 13.043325395157776],
						[77.48082712870182, 13.043303143195597],
						[77.480849446717, 13.043289743111618],
						[77.48088953161175, 13.043276347919905],
						[77.48096978888069, 13.043258531564252],
						[77.48112583965796, 13.04318274805582],
						[77.48125073263938, 13.04307568786063],
						[77.48134884398891, 13.042999921897684],
						[77.4814380280954, 13.042924065094248],
						[77.48146472204898, 13.042892852774157],
						[77.48150935782633, 13.042848285417094],
						[77.48155836964021, 13.04280367293069],
						[77.48154506643566, 13.042750153680792],
						[77.48154944248996, 13.042710089630948],
						[77.48156729678976, 13.04264765541329],
						[77.48161639609987, 13.042536119098488],
						[77.48166094429706, 13.042050133419497],
						[77.48172781032079, 13.041853907600087],
						[77.48184377551803, 13.041639896758195],
						[77.48189287470953, 13.0415908038533],
						[77.48197759483354, 13.041541810885553],
						[77.48202214289013, 13.041506122835376],
						[77.48205785133948, 13.041439213188571],
						[77.48214703489346, 13.041296515210288],
						[77.48214257134137, 13.041220745183368],
						[77.48208454514848, 13.041158316593032],
						[77.48175905529493, 13.041073605294823],
						[77.48133098965502, 13.041033392508643],
						[77.48115262120575, 13.041020028967193],
						[77.48117940274727, 13.04044926557497],
						[77.48116154838695, 13.040369047904809],
						[77.48073794411933, 13.039356808985893],
						[77.48065322307767, 13.039111533270843],
						[77.48031433828397, 13.038790463700325],
						[77.4802563111642, 13.038741490815239],
						[77.48018051702377, 13.03834908859792],
						[77.48006910129668, 13.03830449607184],
						[77.47981484897606, 13.038349008048563],
						[77.47981047285543, 13.038023568636412],
						[77.47980600921224, 13.037698039610143],
						[77.47980600921224, 13.037292264328062],
						[77.47975690912553, 13.03688650905989],
						[77.47946265825375, 13.036280006769449],
						[77.47916831910274, 13.036039256574432],
						[77.47871346166202, 13.035963415950782],
						[77.47804907072299, 13.035762733188507],
						[77.47746493565472, 13.0353658869004],
						[77.4770056952191, 13.03512511055037],
						[77.47683624754919, 13.034799646658266],
						[77.47629219279841, 13.034157509539197],
						[77.47561439639146, 13.032819798435689],
						[77.47569019355457, 13.03228916244367],
						[77.47622085981688, 13.031553401870736],
						[77.47745600818526, 13.030362788110265],
						[77.47765670103193, 13.029997187551482],
						[77.47761206375235, 13.029591419579472],
						[77.47744261697974, 13.029145500855664],
						[77.47701899895117, 13.028053004792547],
						[77.47691650765962, 13.026180152643411],
						[77.4761940771154, 13.024320729866739],
						[77.47622532359983, 13.023669731350026],
						[77.47651511901995, 13.023379809143771],
						[77.47680053747106, 13.02301422023059],
						[77.47717067889234, 13.022519275863615],
						[77.47716175139992, 13.02187270722832],
						[77.47694766642235, 13.021261755136713],
						[77.47588642569991, 13.018510505695394],
						[77.47519969924619, 13.016441448955637],
						[77.47401809298347, 13.014055836942019],
						[77.47284531483416, 13.013048131766075],
						[77.47234141654238, 13.01240603582539],
						[77.47192224359812, 13.011879818886518],
						[77.47149422871502, 13.01062235686169],
						[77.47136932509298, 13.010301301401148],
						[77.47132477289212, 13.01013634393449],
						[77.47091006021887, 13.010100609582832],
						[77.47058007460082, 13.010105131299726],
						[77.47024562401681, 13.009984673245565],
						[77.47016089521351, 13.009659198372399],
						[77.47015651872404, 13.009213311584318],
						[77.47014759068499, 13.008522167791583],
						[77.47014759068499, 13.008236780693927],
						[77.47030812022207, 13.008031642736656],
						[77.47064248309428, 13.008111914113229],
						[77.4711419248765, 13.008227821034911],
						[77.47272050021277, 13.008865442835315],
						[77.4731529744177, 13.008441878097926],
						[77.47337590674903, 13.008348242081782],
						[77.47366579690562, 13.008080685434196],
						[77.47366579690562, 13.007674933015839],
						[77.47345179292068, 13.007353855314673],
						[77.4730370879461, 13.007032828660336],
						[77.47294789729635, 13.006747407841964],
						[77.47294343338567, 13.006301524045087],
						[77.47306378385818, 13.005770848657985],
						[77.47346509706556, 13.0043439189555],
						[77.47339376232335, 13.0039961776492],
						[77.47354982342557, 13.003960498690635],
						[77.47336706648817, 13.00326927445631],
						[77.47311735071874, 13.002680666968734],
						[77.4731529744177, 13.00235521174325],
						[77.47331796364064, 13.002029678332596],
						[77.47368811631661, 13.001659563155386],
						[77.47368365243476, 13.001293964166734],
						[77.4736390136067, 13.001008572821691],
						[77.47355428731264, 13.000642860548046],
						[77.47325555660441, 13.000161287146746],
						[77.47300584059191, 12.999875912628069],
						[77.47234141654238, 12.999519217496687],
						[77.4720872345934, 12.999033181136328],
						[77.47212294614093, 12.998502553938668],
						[77.47236820014466, 12.998176973793427],
						[77.47269818061326, 12.997887159573656],
						[77.47294343338567, 12.997682018383497],
						[77.47293896947478, 12.997481356893779],
						[77.47285870657717, 12.997316403999005],
						[77.47231472046198, 12.996955240639874],
						[77.47222999318856, 12.996714463643873],
						[77.47193117149891, 12.996107985674971],
						[77.47101710839958, 12.99583148374899],
						[77.47076738750857, 12.995506034553875],
						[77.47059793061085, 12.99514483817717],
						[77.47054891410609, 12.994739074957183],
						[77.47050427405675, 12.994333267709251],
						[77.47050427405675, 12.994244040898273],
						[77.47037052886363, 12.99425296021497],
						[77.47031258423543, 12.994221775927626],
						[77.47025901606366, 12.99356182305253],
						[77.4701431266652, 12.992398056474595],
						[77.46994688468244, 12.991956601232134],
						[77.46984429953365, 12.991898589207787],
						[77.46979081867308, 12.991720208434613],
						[77.4694831500624, 12.99133229722626],
						[77.46926913826583, 12.991011274713912],
						[77.4691086072735, 12.990369152063435],
						[77.46974180182312, 12.990074806675738],
						[77.46975965796375, 12.9900391244714],
						[77.46973733778754, 12.989981141889153],
						[77.46924681799409, 12.989553130554594],
						[77.46874290387898, 12.98896007575213],
						[77.46779765410867, 12.988028136226346],
						[77.4677530129878, 12.987965706399761],
						[77.467570159274, 12.987765001151189],
						[77.46738739280049, 12.987564386781312],
						[77.46736953629033, 12.987408264704632],
						[77.46736507216235, 12.986882120279784],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000013",
			properties: {
				AREA_SQ_KM: 13.127403202070981,
				ASS_CONST1: "Yeshwantpura",
				ASS_CONST_: "153",
				LAT: 13.0180758679946,
				LON: 77.4845431002247,
				LOST_AREA_SQ_KM: 0.8031028866457474,
				OBJECTID: 20,
				POP_F: 9145.0,
				POP_M: 10204.0,
				POP_SC: 3055.0,
				POP_ST: 1256.0,
				POP_TOTAL: 19349.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Dodda Bidarakallu",
				WARD_NO: 40.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.48623154966612, 12.963231023457253],
						[77.48718128566418, 12.962834166640958],
						[77.48860377449722, 12.962281218953553],
						[77.48992814172777, 12.96204042579445],
						[77.4900485618557, 12.962990234531741],
						[77.49018228405842, 12.962990256088819],
						[77.49049444778026, 12.962878780912439],
						[77.49092247912898, 12.962767322256463],
						[77.49104289820956, 12.9627449777088],
						[77.49150216981747, 12.96263796340434],
						[77.49179201405936, 12.962593409095474],
						[77.49179201405936, 12.962464035084716],
						[77.49178308770779, 12.962343709431957],
						[77.49179647723487, 12.962285748373333],
						[77.49183664580666, 12.96219650550279],
						[77.49188119002335, 12.962147467765687],
						[77.49197482908636, 12.96211623906619],
						[77.49202392392891, 12.96212965232875],
						[77.4920417765937, 12.96220990058729],
						[77.49204623975946, 12.96238827542374],
						[77.49208632073046, 12.962522006498872],
						[77.49224244385469, 12.962646884789079],
						[77.49240294240472, 12.962740515252918],
						[77.49272848948031, 12.962843097496759],
						[77.49292469240439, 12.962860972382938],
						[77.49313865997289, 12.96286091859053],
						[77.49438727745019, 12.962517562715679],
						[77.49492232004047, 12.962325866943772],
						[77.49522554473378, 12.96239271413076],
						[77.49542620640442, 12.962718252895847],
						[77.49580521359624, 12.9637081997859],
						[77.49633587405842, 12.964350301814372],
						[77.49644736144774, 12.964417163683915],
						[77.49652314480726, 12.96442609572125],
						[77.49659000214041, 12.964426061726652],
						[77.49667917436028, 12.964430557363265],
						[77.49675950805714, 12.964470653808833],
						[77.49679958737497, 12.96453310556195],
						[77.49680851333467, 12.964680241736994],
						[77.49678173545348, 12.964809528642373],
						[77.4966524839583, 12.965050382987123],
						[77.49655438573875, 12.96514845742596],
						[77.49654099676916, 12.96516185805855],
						[77.49646521342149, 12.965224295313668],
						[77.49636265204413, 12.96530448330952],
						[77.49632248506322, 12.965442775293207],
						[77.49646075042831, 12.966129475629744],
						[77.49674165612939, 12.966829561858788],
						[77.49679512439486, 12.966918695699281],
						[77.49682190227291, 12.967074803044008],
						[77.49681743929366, 12.967128287088627],
						[77.49682636525198, 12.967217502426944],
						[77.49681297631426, 12.967311152349538],
						[77.49673273016445, 12.967583090820078],
						[77.4967104152491, 12.96768122136561],
						[77.4970448759941, 12.96803352296444],
						[77.49721875669053, 12.968207367726354],
						[77.49747743327102, 12.968354552208709],
						[77.49792329021051, 12.968599769720257],
						[77.4982488215578, 12.968626588324708],
						[77.49837369583646, 12.968684534730306],
						[77.49880616057332, 12.96946043130817],
						[77.49805709070722, 12.9697636439524],
						[77.49846732957884, 12.97020056942083],
						[77.49814626178183, 12.970263009394783],
						[77.49776279941452, 12.970280874854488],
						[77.49707602927373, 12.970205071007669],
						[77.49641165749189, 12.970004415762064],
						[77.49610397293934, 12.970044573017427],
						[77.49556447285418, 12.970142616632717],
						[77.495533231652, 12.970142652811685],
						[77.49537711302345, 12.970178351632972],
						[77.49441843145446, 12.970374495083105],
						[77.49372726562216, 12.970454746192583],
						[77.4930405587366, 12.970481509980724],
						[77.4930405587366, 12.970490474576826],
						[77.4931431230958, 12.971747915393413],
						[77.49307180062027, 12.972541625479192],
						[77.49316543870785, 12.973473594149016],
						[77.49316543870785, 12.97373219521281],
						[77.49313419684977, 12.973874892521541],
						[77.49303163248256, 12.974414445075409],
						[77.49303609560967, 12.974525947109571],
						[77.49283096660712, 12.97885128897773],
						[77.49309411624623, 12.98084893218399],
						[77.49383429246102, 12.983140898105068],
						[77.49502488271078, 12.986685868270392],
						[77.4956179416706, 12.988005766972984],
						[77.49573835580381, 12.98821093656204],
						[77.49661678006655, 12.988482922803412],
						[77.49819089104719, 12.988857489603053],
						[77.49888202987572, 12.98891993229594],
						[77.49912276362612, 12.988915499930286],
						[77.49934573285577, 12.988910980656645],
						[77.49933680709435, 12.989802857069668],
						[77.49930565443135, 12.991318889823722],
						[77.49929226578425, 12.991791560520898],
						[77.49935019573623, 12.992139408080913],
						[77.49934573285577, 12.99226868714778],
						[77.49928334001865, 12.992371271026062],
						[77.49925656271765, 12.992433683899595],
						[77.49925656271765, 12.992536280669396],
						[77.49934573285577, 12.992808225846817],
						[77.4995999417486, 12.993628765190179],
						[77.49988530258892, 12.994654297586553],
						[77.50009488177675, 12.995452509000263],
						[77.50027322067417, 12.99598762153901],
						[77.50026429498524, 12.9960321940699],
						[77.50014397313006, 12.996081270374763],
						[77.50020636549698, 12.996188281008568],
						[77.50027322067417, 12.996326461121875],
						[77.50067015060597, 12.996968590493482],
						[77.50087080271179, 12.99722277038433],
						[77.50082171167094, 12.997311950878379],
						[77.50055420450532, 12.997485879651478],
						[77.50029553489344, 12.997659720133166],
						[77.49979167000909, 12.998025385079126],
						[77.49986745114973, 12.998373171441232],
						[77.49987637686966, 12.99843559748273],
						[77.49987191400977, 12.998489182111557],
						[77.49984067398567, 12.998524825193668],
						[77.49943490292426, 12.998676429054974],
						[77.49906483355878, 12.998801314152187],
						[77.49911383784726, 12.998899399982982],
						[77.49912722651528, 12.999024208038877],
						[77.49871252710874, 12.999095622565473],
						[77.4979366790181, 12.999202597241727],
						[77.49733024321822, 12.999465670422396],
						[77.49640719449663, 12.999844752487025],
						[77.49563133074837, 13.000076564107365],
						[77.49535042231729, 13.000143438738744],
						[77.49503827185805, 13.00022371031239],
						[77.49506951319557, 13.000370855249532],
						[77.49513190833429, 13.000642896563761],
						[77.49513190833429, 13.000696439592216],
						[77.4955956265375, 13.002078692581259],
						[77.49513637137937, 13.002185783283439],
						[77.4949891783104, 13.00224372422087],
						[77.49415090929278, 13.002350749844233],
						[77.4938655341277, 13.002355152594273],
						[77.49288006108195, 13.002854565820659],
						[77.49173854345285, 13.003184546543663],
						[77.4914085303673, 13.003068651198435],
						[77.49089578738247, 13.002564754009068],
						[77.4903339465506, 13.001949402673345],
						[77.4900707905573, 13.001672941987765],
						[77.4895223354646, 13.000928314867144],
						[77.48918794021967, 13.000246008561893],
						[77.48887577293074, 12.999791197399146],
						[77.48833623872733, 12.999336366056493],
						[77.48715012968762, 12.998382102131789],
						[77.48544231410256, 12.997499204926235],
						[77.48377902039344, 12.996727797521968],
						[77.4815806875128, 12.996050025884346],
						[77.48113476684125, 12.995733394131626],
						[77.48084051951145, 12.994618656964791],
						[77.4803009474128, 12.993798134777615],
						[77.47947596170033, 12.994047841445441],
						[77.47904788752216, 12.994177194226475],
						[77.47869123075367, 12.99401664315576],
						[77.47862873912206, 12.993717897658673],
						[77.4785039308033, 12.993379050259298],
						[77.47841920810828, 12.993004480327928],
						[77.47841474441084, 12.992665542042655],
						[77.47862873912206, 12.99253180972533],
						[77.47867783969262, 12.98966015247356],
						[77.47864659387743, 12.987461808133492],
						[77.47834341275156, 12.98724775356367],
						[77.47810709896157, 12.987033761869585],
						[77.47783507492835, 12.98665467367887],
						[77.47683178379003, 12.986119592482556],
						[77.47643932240283, 12.985807493417447],
						[77.47570804876814, 12.985352626555482],
						[77.47490097282898, 12.984750631225436],
						[77.47427227064851, 12.984416269783356],
						[77.47403139706256, 12.984670404227614],
						[77.47346063317501, 12.984652553261636],
						[77.47254658227727, 12.984465249180007],
						[77.4726089896997, 12.984429580110172],
						[77.47261791754697, 12.984389499105745],
						[77.47254658227727, 12.984202158399192],
						[77.47239944767276, 12.983849932116966],
						[77.47207393028853, 12.983091897464876],
						[77.47301030450018, 12.982735142078337],
						[77.47249301514985, 12.981820987216103],
						[77.47317083002676, 12.98151777097819],
						[77.47382185763725, 12.977794489219738],
						[77.47429896627276, 12.975596114221416],
						[77.47433467713087, 12.974945083357547],
						[77.47433021327421, 12.97449914589521],
						[77.4738173937606, 12.972916239031791],
						[77.47381292988379, 12.97247030997959],
						[77.47405371640232, 12.971819264988852],
						[77.47408942733689, 12.971462520174844],
						[77.47511051028027, 12.971462577966905],
						[77.47598900543868, 12.971293054835524],
						[77.47688972512086, 12.971328801903338],
						[77.47874024377423, 12.971404567016044],
						[77.48127751415795, 12.971172684383406],
						[77.48175012816034, 12.97093636140223],
						[77.48228969342703, 12.970628643378001],
						[77.48352486383428, 12.970191704623002],
						[77.48427849239972, 12.969643201827155],
						[77.48475109400758, 12.968965466973165],
						[77.48547793396644, 12.967988912513398],
						[77.48592383771215, 12.966793842830256],
						[77.48600855485145, 12.965959984403415],
						[77.48622262288048, 12.964537510511539],
						[77.4863073397985, 12.963770551070695],
						[77.48623154966612, 12.963231023457253],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000014",
			properties: {
				AREA_SQ_KM: 7.800831157134791,
				ASS_CONST1: "Yeshwantpura",
				ASS_CONST_: "153",
				LAT: 12.9828072657752,
				LON: 77.4871585444258,
				LOST_AREA_SQ_KM: 0.3862251769660201,
				OBJECTID: 21,
				POP_F: 9247.0,
				POP_M: 10421.0,
				POP_SC: 2026.0,
				POP_ST: 549.0,
				POP_TOTAL: 19668.0,
				RESERVATIO: "Backward Category - B",
				WARD_NAME: "Herohalli",
				WARD_NO: 72.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[
							[77.49853418577409, 12.925738911909479],
							[77.49853864868626, 12.925743349810755],
							[77.49853418577409, 12.925743392574809],
							[77.49853418577409, 12.925738911909479],
						],
					],
					[
						[
							[77.50903091036454, 12.933176692397216],
							[77.50895959781266, 12.93310534052835],
							[77.50880796005421, 12.932953756308237],
							[77.50865185957757, 12.932811004998149],
							[77.50849138386491, 12.932672782059043],
							[77.50832189536712, 12.932539035707299],
							[77.5081524941182, 12.93241416967589],
							[77.50806333085981, 12.932351779580971],
							[77.50804548069961, 12.93234281661971],
							[77.50729629645744, 12.93187014200744],
							[77.50675229916358, 12.931531302037843],
							[77.50670329815405, 12.9315045469855],
							[77.50661850885751, 12.931442110228643],
							[77.50653380699993, 12.931375196742474],
							[77.50645803028476, 12.931303888950582],
							[77.5063822535193, 12.931223619242646],
							[77.50631531441786, 12.931143345841782],
							[77.50625738800036, 12.931058596232003],
							[77.50619937405123, 12.930964966700536],
							[77.50615483541871, 12.930871299107755],
							[77.50611913449835, 12.930786589770426],
							[77.50611020926652, 12.930768751625937],
							[77.50605674536315, 12.930630532706894],
							[77.5059942686915, 12.930492309289942],
							[77.50592295424156, 12.930354089836186],
							[77.50584708961827, 12.930224784911212],
							[77.50576684981766, 12.93009544107411],
							[77.50567768469496, 12.929970577876329],
							[77.50567322206186, 12.929966139805144],
							[77.50562859572133, 12.929899268320481],
							[77.50557513159232, 12.929836795395504],
							[77.50551711729636, 12.929774361136133],
							[77.50545472783203, 12.929720849652417],
							[77.50557066895522, 12.929596075281987],
							[77.50619937405123, 12.92951581253417],
							[77.50697971607337, 12.929159025964125],
							[77.50760850090677, 12.92901189344777],
							[77.50820595703952, 12.929118916469594],
							[77.50848692134187, 12.929145674786977],
							[77.50892389776996, 12.929132316791744],
							[77.50983354537344, 12.92909663726978],
							[77.50985139525383, 12.928909299808574],
							[77.51097059467587, 12.928904849983887],
							[77.51095729489577, 12.92954696818934],
							[77.51096613224983, 12.929738698676875],
							[77.51096613224983, 12.929890363025201],
							[77.51091267062223, 12.930086581780564],
							[77.51090820819392, 12.930661745488951],
							[77.51091267062223, 12.931036299489426],
							[77.51093052033374, 12.931923721351437],
							[77.51094837004247, 12.932231365225807],
							[77.51093498276119, 12.932481056203997],
							[77.51081904708964, 12.932556890158233],
							[77.51064509961195, 12.932663874118983],
							[77.51051577631523, 12.932753107599709],
							[77.51031514116752, 12.932828899119798],
							[77.51009219336738, 12.932909127514232],
							[77.5099405571146, 12.933002797688731],
							[77.50974884590288, 12.933029555371562],
							[77.50960167167322, 12.93306518659785],
							[77.50922708527719, 12.933127659443096],
							[77.50903091036454, 12.933176692397216],
						],
					],
					[
						[
							[77.46210778436428, 12.897588592800481],
							[77.46245565164485, 12.896861812101116],
							[77.46499284498215, 12.893116141887353],
							[77.465474454574, 12.89238042298565],
							[77.46557695609994, 12.892242139836412],
							[77.46606749159606, 12.891546561459045],
							[77.46667838217154, 12.890735011484264],
							[77.46720900263777, 12.890012604860354],
							[77.46773515653457, 12.88929024169278],
							[77.46819443413082, 12.888723903992254],
							[77.46826139554777, 12.8886659871134],
							[77.46839067911401, 12.88848757010965],
							[77.46913092757214, 12.891243320454173],
							[77.46994242065485, 12.893330131258038],
							[77.46995581273714, 12.89338814467841],
							[77.47031258423543, 12.894070357645809],
							[77.47033928078227, 12.894128325587285],
							[77.47035267281818, 12.89419521148332],
							[77.47059793061085, 12.894543084618302],
							[77.47133370083945, 12.895417021591246],
							[77.47201143504628, 12.896241991312255],
							[77.4724484633859, 12.896848427211069],
							[77.47286763440499, 12.896817180015066],
							[77.47336260259381, 12.896571923889237],
							[77.47381292988379, 12.896241988209683],
							[77.47488311748957, 12.895296663222322],
							[77.47520862689156, 12.894966636670349],
							[77.47539146841663, 12.894783815994703],
							[77.47538700460116, 12.894694620091208],
							[77.47538254078552, 12.894605510395857],
							[77.47538254078552, 12.894556412001906],
							[77.47542717893413, 12.894315661483683],
							[77.47553413536845, 12.894181890328479],
							[77.4756590344497, 12.894106095175095],
							[77.47573929538518, 12.894106011107294],
							[77.47582402006303, 12.894101577424015],
							[77.47592651233231, 12.89411497128477],
							[77.47609158508557, 12.894146204577773],
							[77.4761762219776, 12.894141680396178],
							[77.47622978738264, 12.894132812323017],
							[77.47626987389485, 12.894128375067936],
							[77.47631897547693, 12.894163996627219],
							[77.47631451169762, 12.894293331775241],
							[77.47639030839736, 12.894400359314911],
							[77.4765642204969, 12.894480601352036],
							[77.47664001703136, 12.894485038943593],
							[77.47673366856932, 12.894453902932858],
							[77.47680946499165, 12.894351271198934],
							[77.47709488270911, 12.893949964093013],
							[77.47727316995638, 12.893673548982857],
							[77.4777369605673, 12.893111686325632],
							[77.47794202911375, 12.892888678302654],
							[77.47810263525194, 12.89278170226062],
							[77.47816950335424, 12.892754956748224],
							[77.4785841897448, 12.892469546657086],
							[77.47874917114355, 12.892389311623338],
							[77.47895870155344, 12.892188655619114],
							[77.47911484259532, 12.891925555923063],
							[77.4791549280975, 12.891582171013374],
							[77.47918171010642, 12.890819696234578],
							[77.4791772464387, 12.890088362629475],
							[77.47918171010642, 12.889985818919062],
							[77.4792351865826, 12.889892188476942],
							[77.47931098134895, 12.889789644177867],
							[77.47943587631057, 12.889709368144032],
							[77.47954291652313, 12.889682667159848],
							[77.4796900419504, 12.889678143532292],
							[77.4798594853968, 12.889718281880242],
							[77.48000214678166, 12.88974058895447],
							[77.48033210525443, 12.889878833974874],
							[77.48052386582904, 12.889972506248219],
							[77.48064429585682, 12.890030433409729],
							[77.48075579854573, 12.890119627433592],
							[77.48091184961476, 12.890204340153536],
							[77.48100549765836, 12.890195424852102],
							[77.48104111890342, 12.890186464008176],
							[77.48110360921811, 12.8901241043209],
							[77.4811303032497, 12.890061652794465],
							[77.48150935782633, 12.889700441215124],
							[77.48179476382636, 12.889437381414826],
							[77.48207561803927, 12.889044984900103],
							[77.48214703489346, 12.888772972549177],
							[77.48221390063318, 12.88817988911426],
							[77.48222729128055, 12.888050558467988],
							[77.48225844859778, 12.887961449043674],
							[77.48232093824777, 12.887934663066238],
							[77.4823520955395, 12.887881127193976],
							[77.4823520955395, 12.887849946535106],
							[77.48232540179289, 12.887823162190637],
							[77.48236102262715, 12.887698350333785],
							[77.48236102262715, 12.887618073198006],
							[77.4824056580549, 12.8874976588315],
							[77.48245020594486, 12.88741289983801],
							[77.4827579272415, 12.886703969994834],
							[77.48280693852067, 12.88652554114011],
							[77.48285603729892, 12.886266926524687],
							[77.48284711024988, 12.886204565790596],
							[77.48278015736001, 12.886097543759185],
							[77.48270882842115, 12.885999440756624],
							[77.48265535356789, 12.885968177502836],
							[77.48257063394561, 12.885892425858996],
							[77.4825126079698, 12.88587903090299],
							[77.48245020594486, 12.885870075910324],
							[77.48237887680033, 12.885883429136365],
							[77.48230308406553, 12.885914654820064],
							[77.48219604643424, 12.885968231236955],
							[77.48210239936482, 12.88597266905892],
							[77.48199536154581, 12.885959233927158],
							[77.48190171431216, 12.885945928288855],
							[77.48171897070472, 12.885959243824017],
							[77.4815896146606, 12.885981596119533],
							[77.48141124661439, 12.886012779818405],
							[77.48127305057196, 12.886017220257402],
							[77.48105450968802, 12.886079711739937],
							[77.48087614080787, 12.886110894925512],
							[77.48079588347328, 12.886110896706976],
							[77.48060858696644, 12.886070761529895],
							[77.48057296558677, 12.886052935182773],
							[77.48052386582904, 12.886035061122554],
							[77.48045699910195, 12.886030627394844],
							[77.48034995974413, 12.886048457612553],
							[77.48017158976606, 12.886084119058397],
							[77.47993974338794, 12.886159920459868],
							[77.47957853822335, 12.886271423011406],
							[77.47936008162692, 12.886342788320674],
							[77.47903012035238, 12.886351664113946],
							[77.47888737023305, 12.886351662669552],
							[77.4788026478218, 12.886311568879393],
							[77.47869123075367, 12.88618672410825],
							[77.47865998494211, 12.88610640459713],
							[77.47863766650009, 12.886066309195952],
							[77.47868230337983, 12.88591023970286],
							[77.47885621199819, 12.885540086200852],
							[77.47889183391209, 12.885473245074945],
							[77.4789007612696, 12.885375141404506],
							[77.47889629759092, 12.885272601543598],
							[77.47892754333797, 12.884955940318296],
							[77.47895870155344, 12.884782081168229],
							[77.47888290655386, 12.884568003789692],
							[77.47884282095643, 12.884536784122053],
							[77.47872238903344, 12.884465510763873],
							[77.47870015812684, 12.884371846853165],
							[77.47867337600526, 12.884304922261965],
							[77.47863320281115, 12.884291527525951],
							[77.47857526236258, 12.884300530387957],
							[77.47852178557815, 12.884291575125557],
							[77.47846822124528, 12.884251436305433],
							[77.4783968896193, 12.88414885637174],
							[77.47842367180556, 12.884126549489798],
							[77.47843259919958, 12.884095370526694],
							[77.47840581701543, 12.884055232738758],
							[77.4783879622225, 12.884037403088215],
							[77.47831216684507, 12.883983874528017],
							[77.47821851659812, 12.883760926959498],
							[77.47814272110854, 12.883720834876451],
							[77.4780937078322, 12.883694093930739],
							[77.47804014329907, 12.88361381871588],
							[77.47778597399636, 12.883390788639495],
							[77.47752734039739, 12.88314558049611],
							[77.47695659393146, 12.882521323645225],
							[77.4761806857623, 12.88175883551759],
							[77.47611381649862, 12.881709787147864],
							[77.47598900543868, 12.881665176380844],
							[77.47598900543868, 12.881299476850547],
							[77.47598007785415, 12.880652916372314],
							[77.47626541011361, 12.8802426850759],
							[77.47647056882035, 12.87991273042504],
							[77.47680053747106, 12.879587249868734],
							[77.47708595521078, 12.879382091119293],
							[77.47836564372743, 12.879003052483801],
							[77.48006463766357, 12.878904943480645],
							[77.4808538227979, 12.878775675042997],
							[77.48142910093576, 12.878606197796236],
							[77.48167433500913, 12.878320803378994],
							[77.48175459172772, 12.87813803758337],
							[77.48164309001186, 12.878022071593934],
							[77.48154506643566, 12.877910631650602],
							[77.48152274855613, 12.877776833647475],
							[77.48139339229024, 12.877157028473672],
							[77.48140231945266, 12.87710793652816],
							[77.48146025847029, 12.87704553387565],
							[77.48153167570847, 12.876987570926973],
							[77.48183484839004, 12.87689393440644],
							[77.48227183923993, 12.876742329626198],
							[77.48288273092173, 12.876506008867022],
							[77.48311019534366, 12.876425685718942],
							[77.48312796188009, 12.87639002509925],
							[77.48312796188009, 12.876309755571349],
							[77.48309680479962, 12.876242883237358],
							[77.48304324260772, 12.876135915334084],
							[77.48295852327263, 12.876002123904893],
							[77.48285157377448, 12.87587277258996],
							[77.48276685429748, 12.875796944049915],
							[77.4823654861707, 12.875516023608215],
							[77.48224068192634, 12.875431372065806],
							[77.48216033802802, 12.875382281425628],
							[77.48208008159398, 12.875337673526014],
							[77.48205338778392, 12.875297543694817],
							[77.48200875221876, 12.874994384363337],
							[77.4820533877839, 12.874931943478048],
							[77.48219604643424, 12.874740133846053],
							[77.48253938919305, 12.874312116816856],
							[77.4826687441636, 12.874124833279618],
							[77.48271775548096, 12.874026689675643],
							[77.48285603729892, 12.873415848222356],
							[77.48288273092173, 12.87330879160765],
							[77.48287826739835, 12.87322856741106],
							[77.48286934035107, 12.873157171031341],
							[77.48272668254008, 12.87285841959542],
							[77.48268213475775, 12.872755851733114],
							[77.48264642650322, 12.872684542328622],
							[77.48254831626609, 12.872541842290776],
							[77.48241012159671, 12.872412539579546],
							[77.48236102262715, 12.872327838576881],
							[77.48236102262715, 12.872296575960558],
							[77.48269543783013, 12.872296595522329],
							[77.48319045105525, 12.872372395121866],
							[77.4834803163627, 12.872327806954596],
							[77.48377009341642, 12.872122680474336],
							[77.48410012854474, 12.871913121364708],
							[77.48463521927548, 12.871828421255408],
							[77.48500971128317, 12.871864043327749],
							[77.48575440324532, 12.87193990204325],
							[77.48707880347338, 12.871765950950728],
							[77.48945101072994, 12.872675636187527],
							[77.49011542297661, 12.872791540034497],
							[77.49053015358479, 12.872787065417745],
							[77.49106521423163, 12.8726220798497],
							[77.49139514079478, 12.87241697900304],
							[77.49180986676035, 12.872327840170449],
							[77.492099710222, 12.872247497375875],
							[77.49247426529764, 12.872363467564144],
							[77.49326800295499, 12.873045757923839],
							[77.49368272212493, 12.873326614903858],
							[77.49397702392744, 12.873406938498128],
							[77.49426686189314, 12.873402470275508],
							[77.49459677921429, 12.87340243012655],
							[77.49505166100376, 12.873397987874093],
							[77.49538603909429, 12.873554051795791],
							[77.49551091650235, 12.873719058294988],
							[77.49580967661502, 12.874445898597058],
							[77.49589438642866, 12.874891817079089],
							[77.49610397293935, 12.875132565677717],
							[77.49651868181634, 12.875132561273382],
							[77.4968486801447, 12.875128131143917],
							[77.49726338631828, 12.875123646624724],
							[77.49776279941452, 12.875119193361945],
							[77.49813733592664, 12.875114753461188],
							[77.49855203742169, 12.875150402723378],
							[77.49879732227761, 12.875190552923359],
							[77.49938143589449, 12.875386723579785],
							[77.4997961328721, 12.875422457191675],
							[77.50029553489344, 12.875663210835326],
							[77.50070130289672, 12.875961986230863],
							[77.50080386037813, 12.875921878897529],
							[77.50088856648786, 12.875930781393052],
							[77.50103575195467, 12.875961985886892],
							[77.50116954880478, 12.875984277048719],
							[77.50129441988967, 12.875979782357216],
							[77.50138805123726, 12.875979812397539],
							[77.50149953369488, 12.875966404320497],
							[77.50165555644928, 12.8759976034246],
							[77.50247600686392, 12.877268445279816],
							[77.50310035377788, 12.878418921690356],
							[77.50432208624089, 12.880470119531378],
							[77.50469222606905, 12.881094422035325],
							[77.50475907861923, 12.881103326555818],
							[77.5048839457776, 12.881045341485445],
							[77.50569999785782, 12.880273934990834],
							[77.50593634211101, 12.880046549403954],
							[77.50624400016856, 12.879836895779198],
							[77.50661404626122, 12.879636274976843],
							[77.50698417865537, 12.879466838419864],
							[77.5074836367298, 12.879315255419073],
							[77.50803209307763, 12.878637421960317],
							[77.50831743283744, 12.87818706583205],
							[77.50867863467451, 12.876965245415994],
							[77.50883919762393, 12.876434640869183],
							[77.50920486030402, 12.875823727463436],
							[77.51141211149559, 12.872996706808712],
							[77.51296834402987, 12.870784965904896],
							[77.51333845580389, 12.870499587470885],
							[77.51362378228752, 12.870374725360165],
							[77.51449778159859, 12.870526309164644],
							[77.51583103543412, 12.871163958110822],
							[77.52232349093762, 12.874231807517473],
							[77.52498113856036, 12.874695551433009],
							[77.52601567698063, 12.874766972218177],
							[77.5263055210271, 12.874601908667003],
							[77.52638574636927, 12.874236325782254],
							[77.52625197910972, 12.873259753296994],
							[77.52637236090172, 12.872894122588937],
							[77.52661758591705, 12.872564120898087],
							[77.5269877408351, 12.872318848185383],
							[77.52834770643858, 12.871734720998957],
							[77.52875801130024, 12.87116397654244],
							[77.52908354210072, 12.870588730734857],
							[77.52924398881215, 12.870102717075175],
							[77.52923952710185, 12.869652369131016],
							[77.52923069116528, 12.868880881782177],
							[77.52917268891106, 12.867681460493424],
							[77.5295116036252, 12.867520904702609],
							[77.52985935321834, 12.867360341846142],
							[77.5299441252222, 12.867199814086439],
							[77.52962752027435, 12.866883239304821],
							[77.52931983786036, 12.866597842763795],
							[77.52865092974766, 12.865879954643002],
							[77.52779926038046, 12.864947964498805],
							[77.52720173199705, 12.864368307781069],
							[77.52626982641831, 12.863534455966754],
							[77.52601121514378, 12.863289235617],
							[77.52597998228094, 12.863244586035995],
							[77.52598890595692, 12.863186619902505],
							[77.52630989536566, 12.862174445251748],
							[77.52688074510324, 12.860261506649605],
							[77.52692081383555, 12.860145523793202],
							[77.52694758464008, 12.860123210311883],
							[77.52700558802833, 12.860123238340975],
							[77.52708135108219, 12.860123257522122],
							[77.52718834662546, 12.860132182567657],
							[77.52738903954821, 12.860207959831719],
							[77.52747372590639, 12.860274810262752],
							[77.52752280547149, 12.860288190031314],
							[77.52756742323959, 12.860288264500168],
							[77.52765657123756, 12.860283758409263],
							[77.52772795957422, 12.860248083554389],
							[77.52778587507915, 12.860207972501595],
							[77.5278483398052, 12.8601767784934],
							[77.52792410229806, 12.860167840297217],
							[77.52800887574358, 12.860145554391732],
							[77.52875801130024, 12.86002070989037],
							[77.52906123351606, 12.859940436680438],
							[77.52906123351606, 12.859877995283146],
							[77.5289541522488, 12.859717485327215],
							[77.52832994694101, 12.858776581682259],
							[77.5280623293491, 12.85831726821765],
							[77.52806679110554, 12.858295003801839],
							[77.52806679110554, 12.858272697531161],
							[77.52808463812958, 12.858241515616445],
							[77.52815602619773, 12.858156749766163],
							[77.5283700151631, 12.857942750052876],
							[77.52884269663976, 12.857652885596574],
							[77.52958736501076, 12.857313974049562],
							[77.530216112577, 12.857104455158359],
							[77.53034095184314, 12.857068760088708],
							[77.5304123384958, 12.857073218280052],
							[77.53049256094295, 12.857082148180973],
							[77.53055502418323, 12.857126742831126],
							[77.53059071744799, 12.857269440895776],
							[77.53055948584192, 12.85754586669427],
							[77.53049256094295, 12.858214774979523],
							[77.5304836376201, 12.858366357909333],
							[77.5305059459259, 12.858504625905562],
							[77.530630784874, 12.858776571121298],
							[77.53088947313432, 12.859276024514736],
							[77.53130860460644, 12.860029586998603],
							[77.53183472570879, 12.861135469350158],
							[77.53188826499948, 12.86125586106668],
							[77.53197303549159, 12.861331673061763],
							[77.53201310243077, 12.861211294013216],
							[77.53200417922719, 12.860564650722125],
							[77.53216917089776, 12.860274824948272],
							[77.53241447090303, 12.85998945526664],
							[77.53265968289742, 12.85982448680367],
							[77.53311896187846, 12.85986012814219],
							[77.53336872138395, 12.859940408544368],
							[77.53353817261709, 12.86038632317911],
							[77.53358716208503, 12.860529061439738],
							[77.53397960195421, 12.861309346451279],
							[77.53465294023317, 12.8624062891107],
							[77.53480008209358, 12.862606967409908],
							[77.534942762277, 12.862731810185062],
							[77.53513005708356, 12.86271840194171],
							[77.53540649350666, 12.8625712901139],
							[77.53583908016817, 12.862598071366438],
							[77.53588360719522, 12.86296367597036],
							[77.5356873907153, 12.863940198002],
							[77.5357186209133, 12.864065121841806],
							[77.53583461871656, 12.864087396450985],
							[77.53588806864491, 12.864123035090357],
							[77.53589253009443, 12.864207743669441],
							[77.53591037589071, 12.864332628646734],
							[77.53590145299292, 12.86458680450753],
							[77.53597729760186, 12.864591304692684],
							[77.53602182457479, 12.86463142493959],
							[77.5362715777048, 12.864707230870772],
							[77.53643656318432, 12.864667084931526],
							[77.53668622792448, 12.864582337037241],
							[77.53683345463234, 12.864511020479952],
							[77.53713219392007, 12.864519925785668],
							[77.53730610093896, 12.864537743054328],
							[77.53735071486973, 12.864533337278964],
							[77.53736847295887, 12.864577857945768],
							[77.53739970269511, 12.86497918027365],
							[77.53743985520055, 12.866058278765356],
							[77.53785458863705, 12.866004790900393],
							[77.53790803761572, 12.86704818999489],
							[77.53803741728164, 12.867048195803987],
							[77.53836738287586, 12.8670437853034],
							[77.53853236531377, 12.867043776277566],
							[77.5388266388026, 12.867284598994235],
							[77.53883110013678, 12.867569917285712],
							[77.53908084709488, 12.868015845139265],
							[77.53941527097126, 12.86805152845805],
							[77.53955348405681, 12.86805152697593],
							[77.5395980971055, 12.868301219227906],
							[77.53954902275098, 12.868363638391582],
							[77.5392680476073, 12.86845730997289],
							[77.5393885031011, 12.868938900617323],
							[77.53940634834858, 12.868974555563373],
							[77.53944195135846, 12.868992371117939],
							[77.53949548706733, 12.868983483693999],
							[77.53970508087444, 12.868970110854908],
							[77.54059243868677, 12.86887202345955],
							[77.54067720268938, 12.869456129710207],
							[77.5408511042842, 12.869438287348064],
							[77.54112752679467, 12.869384793029967],
							[77.54112306555062, 12.869264418970786],
							[77.54116321674067, 12.86899682532897],
							[77.5413460400628, 12.869117228137302],
							[77.54138172994017, 12.869117217535175],
							[77.54143071602813, 12.869126199979847],
							[77.54218877304689, 12.869054807772228],
							[77.54270163520279, 12.868996889885445],
							[77.54310733963106, 12.868983445617799],
							[77.5435220523842, 12.868961216091652],
							[77.5435220523842, 12.868698069109051],
							[77.54347297972441, 12.868247721027622],
							[77.54350420778309, 12.867975737087546],
							[77.54375840561093, 12.867476281912548],
							[77.54382978383882, 12.867413897425743],
							[77.54388769114271, 12.867404960014829],
							[77.54422664946686, 12.867346966051368],
							[77.54508720543726, 12.866990285407228],
							[77.5454260727097, 12.866829697526562],
							[77.54547068345238, 12.86682976926937],
							[77.54548852774455, 12.866843114517417],
							[77.54548406667179, 12.867382627145846],
							[77.54683069038951, 12.866753884652166],
							[77.54732568758506, 12.866637989124742],
							[77.54827097840739, 12.866910015109038],
							[77.54827990033378, 12.866914491206987],
							[77.54842265106097, 12.866758364316944],
							[77.54846717315671, 12.866829752096017],
							[77.54856977509077, 12.866901081541409],
							[77.54869013325157, 12.866927868272708],
							[77.54883288346295, 12.866950129460951],
							[77.54904692097416, 12.866950139001453],
							[77.54926541900622, 12.866994722524634],
							[77.5494928384488, 12.867092793798188],
							[77.54971579652926, 12.867186430526102],
							[77.54987630158479, 12.867280088560566],
							[77.55032221580139, 12.86765023157165],
							[77.55047379824613, 12.867833045363389],
							[77.55055409400042, 12.86803371865125],
							[77.5505674766206, 12.868278905460976],
							[77.55057193749364, 12.868577673772375],
							[77.55057193749364, 12.86876502442801],
							[77.55056301574739, 12.869001305056992],
							[77.550491641752, 12.869237607283155],
							[77.55045149385988, 12.8693847887834],
							[77.55029098961766, 12.86968357647889],
							[77.54989414518458, 12.870098261608584],
							[77.54987184068442, 12.870142846027026],
							[77.5498406143768, 12.870214222702211],
							[77.54980046625441, 12.87071362896119],
							[77.54980492715761, 12.871248684490668],
							[77.54980492715761, 12.871297784266607],
							[77.54989860608408, 12.87147610645474],
							[77.55001450194125, 12.871574177798212],
							[77.5502285372245, 12.871757065201827],
							[77.55032221580139, 12.871881894985782],
							[77.55042472859056, 12.872011197117269],
							[77.55065660658018, 12.872381349827963],
							[77.55083495375723, 12.87264436185891],
							[77.55112482198953, 12.873259786413557],
							[77.55119619558673, 12.873473825493832],
							[77.55120065643507, 12.873589728087033],
							[77.55114266539304, 12.873843909432452],
							[77.55110697858322, 12.87391972626695],
							[77.55104907496106, 12.874013342986006],
							[77.55102230983267, 12.874089156969124],
							[77.55100892726611, 12.874178361776922],
							[77.55101338812182, 12.874280912930242],
							[77.55108021346848, 12.874584065966108],
							[77.55108021346848, 12.874762467728862],
							[77.55108021346848, 12.874869457232037],
							[77.55108467432137, 12.875021110564528],
							[77.55107129176217, 12.875128147699371],
							[77.55103569239765, 12.875261857636866],
							[77.55099554469797, 12.87538225579076],
							[77.55095985784189, 12.87546703231169],
							[77.55091971011538, 12.875542808314414],
							[77.55086617979129, 12.875689958503205],
							[77.55087064065258, 12.87579699043405],
							[77.55092863183361, 12.875828198936556],
							[77.55100000555419, 12.875828176081795],
							[77.55110697858322, 12.875841575975414],
							[77.55118727388954, 12.87588169317366],
							[77.55121403897903, 12.875908475285414],
							[77.55122296067411, 12.875912951896243],
							[77.55125418660141, 12.87597983252707],
							[77.55126756913904, 12.876077903019686],
							[77.55124526490877, 12.876153673754647],
							[77.55120065643507, 12.876233974226052],
							[77.55116050879374, 12.876300877897558],
							[77.55112482198953, 12.876372216579579],
							[77.55111590028673, 12.876430194407332],
							[77.55113820454243, 12.876563926124513],
							[77.55113820454243, 12.876581757980762],
							[77.551329933493, 12.876760140603983],
							[77.5513165509627, 12.876791372228954],
							[77.55122742152139, 12.876987555860449],
							[77.55114712624348, 12.877188212181233],
							[77.55107129176217, 12.87738891038324],
							[77.55100446641023, 12.877594003589618],
							[77.55094201440964, 12.87779913452941],
							[77.55088848409605, 12.878008656347014],
							[77.55084387548213, 12.878218261818883],
							[77.55080381518252, 12.878427827335086],
							[77.5507725891313, 12.878641865776629],
							[77.55074582393772, 12.878855946256646],
							[77.55072798047182, 12.87906993818738],
							[77.55071905873781, 12.879284013880296],
							[77.55071905873781, 12.879498001061865],
							[77.5507235196049, 12.879712116485873],
							[77.55073690220512, 12.879926143427094],
							[77.55075474566961, 12.88014012633972],
							[77.5507725891313, 12.880265033770714],
							[77.55078597172573, 12.880389899637088],
							[77.55079489345447, 12.880514723934397],
							[77.55079489345447, 12.880644030742971],
							[77.55078597172573, 12.880768860753276],
							[77.5507725891313, 12.880893735319436],
							[77.55075474566961, 12.881018568288242],
							[77.55072798047182, 12.881143404078347],
							[77.55069675439975, 12.881263804726613],
							[77.55065660658018, 12.88138420816502],
							[77.5506119978751, 12.881504656108852],
							[77.55056301574739, 12.881620586912831],
							[77.55050502437955, 12.881732036577167],
							[77.55044257210413, 12.881843530703208],
							[77.55037574638202, 12.881950507634798],
							[77.55030437226888, 12.882053131256383],
							[77.5502285372245, 12.88211108843367],
							[77.55016171137638, 12.882173526834636],
							[77.55009925891206, 12.88224039633257],
							[77.5500412673073, 12.88231170113602],
							[77.54999219746473, 12.88238308945224],
							[77.54994767596712, 12.88246331085771],
							[77.54990752788258, 12.88254356984917],
							[77.54987184068442, 12.882628350345193],
							[77.54984953617985, 12.882717477439313],
							[77.54982723167093, 12.882802211013779],
							[77.54981384896347, 12.882891421743528],
							[77.54980938806061, 12.8829850234986],
							[77.54980938806061, 12.88307427351102],
							[77.54981830986611, 12.88316343483288],
							[77.54983615347501, 12.883252593619003],
							[77.54985845798221, 12.883341794252583],
							[77.54988522338503, 12.8834264708403],
							[77.54993875417178, 12.883506746082938],
							[77.54998327567289, 12.8835959793111],
							[77.55002342373064, 12.883680695289645],
							[77.55005911088115, 12.88377432909504],
							[77.55008141534451, 12.883867923818856],
							[77.5501037198035, 12.883961604777804],
							[77.5501171024768, 12.884059682045592],
							[77.55012156336754, 12.884153368370566],
							[77.55012156336754, 12.884251406627047],
							[77.55011264158587, 12.884349533705922],
							[77.55009479802044, 12.8844431836598],
							[77.55007249355968, 12.884536791894044],
							[77.55004572820101, 12.884630444553283],
							[77.55001004104629, 12.884719620046834],
							[77.5499654320871, 12.884808798155062],
							[77.54991644968037, 12.88489354505108],
							[77.54986291888311, 12.88497385232504],
							[77.54980492715761, 12.885049638011086],
							[77.54973801359134, 12.885120946448174],
							[77.549666726546, 12.885187823615547],
							[77.5495908910756, 12.88525026110532],
							[77.54951068210886, 12.885308267302443],
							[77.54942592469858, 12.885357267835047],
							[77.54934116722501, 12.885406354480482],
							[77.54925203623252, 12.885442050177167],
							[77.54898000696109, 12.885611459620758],
							[77.54870351609152, 12.885771953372654],
							[77.54842265106097, 12.885928101567139],
							[77.54814169786563, 12.886075199344788],
							[77.54785628299543, 12.886217861168962],
							[77.54757095487818, 12.886360612897917],
							[77.54723646753958, 12.886003830365675],
							[77.54719194496127, 12.885999366892323],
							[77.54718302294935, 12.885999369318593],
							[77.54710718581991, 12.885928100704207],
							[77.54671925230663, 12.88549552672451],
							[77.54637592785515, 12.885125400833243],
							[77.54611727483761, 12.884871248854758],
							[77.5460102096353, 12.884768674352303],
							[77.54597906973723, 12.88470627210309],
							[77.54438270116562, 12.881094410067217],
							[77.54437824004941, 12.881094368217381],
							[77.54342399451701, 12.881116702127906],
							[77.54193019796934, 12.88106316383209],
							[77.5413951136413, 12.88106762388201],
							[77.54103838935605, 12.881170237493947],
							[77.54099377687278, 12.88122374506507],
							[77.54087332308046, 12.881299540677047],
							[77.54081095298388, 12.881321786370336],
							[77.5407574178947, 12.881317320593661],
							[77.54074403411848, 12.881304014630302],
							[77.54072618908103, 12.881303933070244],
							[77.53914321814096, 12.881286152446437],
							[77.53859919800415, 12.882325120453912],
							[77.53795711267875, 12.88323033618104],
							[77.53725256419891, 12.883689590174274],
							[77.53602182457479, 12.884581427354957],
							[77.53338210602848, 12.886182241661167],
							[77.5325839235578, 12.886900159144032],
							[77.53244570199983, 12.887002663178437],
							[77.53239216297149, 12.887158772455065],
							[77.53140667292459, 12.887020512709904],
							[77.52979251539162, 12.886530010410413],
							[77.52886054354211, 12.886289219484738],
							[77.52817833495985, 12.885950342941843],
							[77.5267558145548, 12.885821035615274],
							[77.52430336863121, 12.885602560268328],
							[77.52188201638823, 12.885624865194899],
							[77.52129346848439, 12.885508943809267],
							[77.51773954392094, 12.89320977138305],
							[77.51764146383019, 12.893178601478013],
							[77.51736944643739, 12.893111661414439],
							[77.51731143814128, 12.893102751773494],
							[77.51723566858787, 12.893102721560597],
							[77.51719104677187, 12.893120559169759],
							[77.51714205024791, 12.893133964889309],
							[77.51712420150893, 12.893156279117676],
							[77.51710189058132, 12.893218695535067],
							[77.51706173090055, 12.893258801757328],
							[77.51703058307741, 12.893321223419122],
							[77.51700380994173, 12.893379203781087],
							[77.51690126702401, 12.893508570195905],
							[77.51686110727256, 12.893562029322037],
							[77.51680309871769, 12.893615576569195],
							[77.51666494549454, 12.893669054894739],
							[77.516593550223, 12.893673542384358],
							[77.51654009123428, 12.893686905338543],
							[77.51650885577428, 12.893718137301958],
							[77.51650885577428, 12.893740449578326],
							[77.51649100693614, 12.893758284109],
							[77.51644192261685, 12.893762726036934],
							[77.51639738798444, 12.893767128610559],
							[77.51634830362485, 12.893767176978912],
							[77.51626807121255, 12.89375823046683],
							[77.51623237345807, 12.893758234298398],
							[77.51617882680537, 12.893758240034865],
							[77.51614767874092, 12.893753810981647],
							[77.51602282391401, 12.89376270172442],
							[77.51592465485102, 12.893767191814478],
							[77.51588458225021, 12.893758283993108],
							[77.51584888437561, 12.893744848732478],
							[77.51582657319831, 12.893727061604775],
							[77.51581764872616, 12.893704750319614],
							[77.51580872425332, 12.893588796673335],
							[77.51580872425332, 12.89355313161526],
							[77.51577311384983, 12.893486203050516],
							[77.51575972713508, 12.893437229678035],
							[77.51572402922137, 12.893281134546472],
							[77.5157151047412, 12.893196366638161],
							[77.51574187817961, 12.89312503393142],
							[77.51582657319831, 12.892830785384591],
							[77.51583995990522, 12.892732619532998],
							[77.51588458225021, 12.892661285084012],
							[77.51588904448376, 12.892585518482985],
							[77.51589796895031, 12.892505228737347],
							[77.51588012001649, 12.892474045585782],
							[77.51585334661056, 12.892429424544023],
							[77.51583103543412, 12.892416031185542],
							[77.51580426201664, 12.892402681389951],
							[77.51578650056301, 12.892402687556242],
							[77.51575526489647, 12.892402647897185],
							[77.51570171801961, 12.892420486085717],
							[77.51563040963968, 12.89244281003305],
							[77.51557240045841, 12.892456212116999],
							[77.5153360761735, 12.89247403527568],
							[77.51529591587054, 12.892509661172106],
							[77.51526922982261, 12.892572124514112],
							[77.51526030530682, 12.892647934497418],
							[77.51507297780937, 12.892830761783314],
							[77.51497935769294, 12.892915501126348],
							[77.51492581045157, 12.892951171462153],
							[77.51480104178182, 12.892982377951503],
							[77.51479211722939, 12.892986858504193],
							[77.51477426812241, 12.892986860342965],
							[77.5144888570224, 12.892991291475946],
							[77.51423021900129, 12.892969014086361],
							[77.51402066577647, 12.89295116809247],
							[77.51383779855573, 12.893022476717185],
							[77.51383779855573, 12.893156262397497],
							[77.5141008997707, 12.894025753177159],
							[77.51414106049468, 12.894132787456082],
							[77.51415444739953, 12.89424878377711],
							[77.5141187489831, 12.894404800506349],
							[77.51398050501007, 12.89474368004913],
							[77.51395373115793, 12.894823972396354],
							[77.51394043172577, 12.894904224674592],
							[77.51390027093112, 12.894984475274079],
							[77.5138690347477, 12.895038019176488],
							[77.5138690347477, 12.895073684476552],
							[77.5138824216845, 12.89510043222174],
							[77.51393150710597, 12.895149489042096],
							[77.51398496731814, 12.895189624920114],
							[77.51404743961288, 12.89522528449042],
							[77.51411428668025, 12.895234190398622],
							[77.51414552279647, 12.895229750852952],
							[77.51422129440417, 12.895247615323592],
							[77.51427037967973, 12.895296672203262],
							[77.5142882288656, 12.895332335956857],
							[77.51431054034401, 12.895515097817725],
							[77.51429715345749, 12.895608785617084],
							[77.51426145508574, 12.895760410524936],
							[77.51412321128576, 12.896081460847988],
							[77.51410536207405, 12.896108168621318],
							[77.51403405269546, 12.89633113281328],
							[77.5139760427018, 12.896429218709985],
							[77.51396265577601, 12.896478281824884],
							[77.51394043172577, 12.896674492840473],
							[77.51398050501007, 12.896754733186263],
							[77.51398050501007, 12.896803838327266],
							[77.51398050501007, 12.897000000649385],
							[77.51397158039339, 12.897093646003762],
							[77.51393150710597, 12.897227401012904],
							[77.51386457243508, 12.897370113784755],
							[77.51376648900977, 12.897570769301964],
							[77.5136951794192, 12.897673297928607],
							[77.51364609389599, 12.8977223644336],
							[77.51357478423012, 12.897811539828679],
							[77.51345885124256, 12.897882885828682],
							[77.51343207726791, 12.897923033947523],
							[77.5133651423038, 12.89796314226411],
							[77.51331614413523, 12.898003339479402],
							[77.51327598312065, 12.898047925406031],
							[77.51325813377629, 12.8981102995454],
							[77.51327152078483, 12.898181673903355],
							[77.51328937012708, 12.898261964506672],
							[77.51332953113696, 12.898311023890729],
							[77.51341422794799, 12.898364469174878],
							[77.51346322607442, 12.898453669789609],
							[77.5135524726077, 12.898618640921711],
							[77.51357924655407, 12.898747950930225],
							[77.5135480102827, 12.898859475569843],
							[77.51350338702305, 12.898926332132415],
							[77.5134276149382, 12.899037778452184],
							[77.51323582209191, 12.899265192974157],
							[77.51295495698575, 12.899519415622155],
							[77.51290587114383, 12.899617502361014],
							[77.51287017233643, 12.899675484758282],
							[77.51283456101498, 12.899728991517682],
							[77.51277208805564, 12.899782496024176],
							[77.51267400369046, 12.89984048718832],
							[77.51258484396682, 12.899947497764478],
							[77.51246890997267, 12.899974217174337],
							[77.5124198239215, 12.900045597044473],
							[77.51237975008867, 12.90022837434495],
							[77.51237975008867, 12.900308667564984],
							[77.5124198239215, 12.90037555725675],
							[77.51254914505891, 12.900442440768382],
							[77.51265615425186, 12.900500409155777],
							[77.51272755198949, 12.900545030759515],
							[77.51279886218525, 12.900611875344644],
							[77.51288802174153, 12.90068319861313],
							[77.5130218046937, 12.900861605806133],
							[77.51307981517289, 12.900955206033032],
							[77.51311542641773, 12.901057806629737],
							[77.51316004982924, 12.901120177762314],
							[77.51323582209191, 12.901187065511019],
							[77.51335621763896, 12.901262909992923],
							[77.51358808370492, 12.901396679956992],
							[77.51365948085896, 12.901401154759984],
							[77.51374417742488, 12.901418934951806],
							[77.5138422608694, 12.901459070794976],
							[77.51393596941595, 12.901508127994738],
							[77.5140072788559, 12.901583933101103],
							[77.51405190191834, 12.901713245462734],
							[77.51405190191834, 12.90175787274179],
							[77.51399835424132, 12.901873838697156],
							[77.51396711808478, 12.901967446361219],
							[77.51387349706013, 12.902034355240884],
							[77.5138021875365, 12.902056592053594],
							[77.51342315260831, 12.9020700280204],
							[77.51331614413523, 12.90206107967615],
							[77.51323582209191, 12.902061085164618],
							[77.51318236152845, 12.902083406668908],
							[77.51313327578443, 12.902101200601933],
							[77.5130708904857, 12.902105688990009],
							[77.51303519172997, 12.902101211429521],
							[77.5129772687251, 12.902092302693596],
							[77.51291479584394, 12.902087826906017],
							[77.51287463468798, 12.9020878726537],
							[77.51271416491709, 12.902092285500585],
							[77.51254468269465, 12.9020967807407],
							[77.51247337233991, 12.9021056633284],
							[77.51237975008867, 12.902119070347293],
							[77.51231281450679, 12.902145825095422],
							[77.51216564358594, 12.902145795565024],
							[77.51199624804596, 12.902203795474167],
							[77.5119203874617, 12.902203757092721],
							[77.51182230236387, 12.902199287401702],
							[77.51167959320827, 12.9021457994701],
							[77.5113630249895, 12.902145788081468],
							[77.51120246559299, 12.902275116897567],
							[77.51103753104564, 12.90240440700869],
							[77.51097059467587, 12.902484662112744],
							[77.51093944518846, 12.90254717195234],
							[77.51094837004247, 12.902609546114325],
							[77.51103753104564, 12.902676477696291],
							[77.51108206777185, 12.902756679395235],
							[77.51111330471875, 12.902886036477053],
							[77.5110909926147, 12.90304210419666],
							[77.51099736922849, 12.903238327674183],
							[77.51098844437833, 12.903305183176197],
							[77.51097951952745, 12.903385478650577],
							[77.51094837004247, 12.903496924083273],
							[77.51090374576545, 12.903773393420046],
							[77.51089928333677, 12.903920501221744],
							[77.5118089151852, 12.904014154096139],
							[77.51221472974652, 12.904014161541813],
							[77.51224596638315, 12.90452699405984],
							[77.51224596638315, 12.905485640577599],
							[77.51222811687755, 12.906297227590976],
							[77.51215671882719, 12.906627208805759],
							[77.51208987061207, 12.90666284187895],
							[77.51193823701547, 12.906667333570557],
							[77.511514659351, 12.906676287581641],
							[77.5110553807376, 12.906707470553968],
							[77.51080565979318, 12.906703009732531],
							[77.51075211059165, 12.906734200438967],
							[77.51063617473422, 12.906810026717727],
							[77.5105202387589, 12.906890333038229],
							[77.51046231447653, 12.906939319268183],
							[77.51044446469173, 12.906957154271165],
							[77.5104176900093, 12.907001784137607],
							[77.51039537776916, 12.90704637075443],
							[77.51038199042297, 12.907095437139265],
							[77.51037315302365, 12.907148944621023],
							[77.51037315302365, 12.907197967435605],
							[77.51038199042297, 12.907251465820467],
							[77.51039537776916, 12.907291570990713],
							[77.5104489271382, 12.907345158053195],
							[77.51054255097463, 12.907447719208644],
							[77.51063171229507, 12.90755023757097],
							[77.51064063717317, 12.907563591467293],
							[77.51069418642705, 12.907643887198091],
							[77.51076103546032, 12.907733095457228],
							[77.51083243438453, 12.90781330447064],
							[77.51091267062223, 12.907893595133963],
							[77.51091713305037, 12.90789803203029],
							[77.51093052033374, 12.907906948724744],
							[77.51096613224983, 12.907938218001854],
							[77.51099736922849, 12.907973842606603],
							[77.51102414377483, 12.908018470796426],
							[77.51104199346888, 12.90806301320534],
							[77.5110553807376, 12.908107598890219],
							[77.51105984316017, 12.908156665111829],
							[77.5110553807376, 12.908205731706841],
							[77.51104645589197, 12.908250361407173],
							[77.51102860619861, 12.908361763233094],
							[77.5109929068035, 12.908504527064462],
							[77.51095729489577, 12.908642728914925],
							[77.51090820819392, 12.908780970246315],
							[77.51087250876112, 12.908879018731707],
							[77.51083243438453, 12.908954886088422],
							[77.5107699603283, 12.90905293561694],
							[77.51070757373658, 12.909146595353404],
							[77.51067187424098, 12.909186746055633],
							[77.51066741180324, 12.909191183329186],
							[77.51061832497662, 12.909226897369052],
							[77.51056486318599, 12.909258092491697],
							[77.51051131387138, 12.90928040913762],
							[77.5104489271382, 12.90929376988644],
							[77.5103909153206, 12.909302689126571],
							[77.51032852852155, 12.909302695398372],
							[77.51031514116752, 12.909302652756796],
							[77.51025275432701, 12.909316013440076],
							[77.51019027995324, 12.90932945603905],
							[77.51013235550329, 12.909356214049854],
							[77.51007880598561, 12.909387404800231],
							[77.51002534394206, 12.909423079954356],
							[77.5098737076004, 12.909547931269513],
							[77.50979784560425, 12.90961483498611],
							[77.50976223332339, 12.909650509555487],
							[77.50971760858226, 12.90968169995398],
							[77.50967307132322, 12.909704020313445],
							[77.50962398406865, 12.909726293487985],
							[77.50957935927343, 12.909735168990819],
							[77.5095035845812, 12.909761927303014],
							[77.5094278098386, 12.909779811332973],
							[77.50934748505651, 12.909793167947337],
							[77.50928063519473, 12.90979761106977],
							[77.50927617270256, 12.909860032482785],
							[77.50940549740481, 12.910283706656491],
							[77.50962844654721, 12.91098372977467],
							[77.50979347063178, 12.911434117183148],
							[77.50990494487829, 12.91157235265712],
							[77.51008326844637, 12.911795320261758],
							[77.51042661490415, 12.912205548340541],
							[77.51048016425851, 12.912254571988633],
							[77.51049792653882, 12.912285843368831],
							[77.51053808853183, 12.912437440835493],
							[77.51064509961195, 12.912945779370851],
							[77.5107654978944, 12.913570049551106],
							[77.51089928333678, 12.914087267692636],
							[77.51095283246921, 12.91428793651515],
							[77.51123370250691, 12.914225493942029],
							[77.51165290631384, 12.914354800438499],
							[77.51183568954099, 12.914354830748804],
							[77.5122013426139, 12.914345934300037],
							[77.51339191629417, 12.914301355402808],
							[77.51338745396288, 12.915068244195119],
							[77.51341422794799, 12.916120639536262],
							[77.51241536155217, 12.916214270174853],
							[77.51242428629067, 12.916245461104618],
							[77.51242428629067, 12.916298968538213],
							[77.512361900604, 12.916450621631052],
							[77.5122013426139, 12.917057048677853],
							[77.5120764834648, 12.917297797021282],
							[77.51176437874285, 12.917788346736698],
							[77.51159935751015, 12.918002342160731],
							[77.51156812069637, 12.918109401828008],
							[77.51154143377586, 12.918198586602248],
							[77.51149680973091, 12.918697997671924],
							[77.51143879844636, 12.919130503542837],
							[77.5114521856685, 12.919295552974836],
							[77.51154143377586, 12.919732497538073],
							[77.51157704550118, 12.919768165679834],
							[77.51159935751015, 12.919790439373083],
							[77.5116350567154, 12.919812756068701],
							[77.51166629351084, 12.919830635259924],
							[77.51170636759441, 12.919839548929376],
							[77.51175545395272, 12.919857341834012],
							[77.51181783997117, 12.919879654124736],
							[77.51184461432486, 12.919919807224474],
							[77.51188477584361, 12.920334480988851],
							[77.51188477584361, 12.920387990204638],
							[77.5118892382337, 12.92044593698269],
							[77.5118892382337, 12.92049057112039],
							[77.5118892382337, 12.920517282678123],
							[77.51187585106288, 12.920561873830149],
							[77.51186246389048, 12.92058862855462],
							[77.51185800149935, 12.920610988752225],
							[77.51184015193301, 12.920637700421729],
							[77.51179999039853, 12.920664455279011],
							[77.51177776592677, 12.920673377826207],
							[77.51169744279973, 12.920682253305774],
							[77.51163059431536, 12.920691176009743],
							[77.51167513080996, 12.921074698876348],
							[77.5117242171817, 12.921315491805485],
							[77.51169744279973, 12.921382357367458],
							[77.5117152923884, 12.921756925517563],
							[77.5117554539527, 12.921997719976623],
							[77.51174652916185, 12.922211759940764],
							[77.5116350567154, 12.922782495955259],
							[77.51149234732546, 12.92371897610723],
							[77.51107323042677, 12.923736740704694],
							[77.5109929068035, 12.923736740033107],
							[77.5105247012024, 12.923794786816508],
							[77.51048462670359, 12.923799228272642],
							[77.51045785203058, 12.923821459414944],
							[77.5104400022451, 12.923834901480816],
							[77.51045785203058, 12.92393744211194],
							[77.5105202387589, 12.924169318217382],
							[77.5105247012024, 12.924476983853777],
							[77.51042661490415, 12.924784696169008],
							[77.5103909153206, 12.924780214862466],
							[77.51021259223351, 12.924708872726082],
							[77.50780914081523, 12.924824765373431],
							[77.50578470033435, 12.925141359824972],
							[77.50535663717467, 12.925279660355706],
							[77.50513814233871, 12.92539999391485],
							[77.50512921703013, 12.925502619846728],
							[77.50504005140924, 12.925712181637325],
							[77.5048839457776, 12.925894985474248],
							[77.50474569061166, 12.926055472453116],
							[77.50464759934489, 12.926220485095756],
							[77.50447819264105, 12.926447884900643],
							[77.50439795187795, 12.926550512352412],
							[77.50425969612328, 12.92660845849688],
							[77.50399657220854, 12.926702076492528],
							[77.50358189048775, 12.9270052573464],
							[77.50340801948047, 12.927152476206992],
							[77.50286400365191, 12.927611748211305],
							[77.50258748825917, 12.927839141026727],
							[77.50248046962201, 12.927723241303347],
							[77.50229767140713, 12.927544825801657],
							[77.50207470794173, 12.927388768997101],
							[77.50185620682336, 12.92730849798278],
							[77.50113384632512, 12.926813531441145],
							[77.50043825828693, 12.926332010678756],
							[77.50000571222358, 12.92599305994292],
							[77.49964002006973, 12.925671990075381],
							[77.49931011731337, 12.925377725619484],
							[77.49913615229308, 12.92522162756877],
							[77.49895334872419, 12.925052177613077],
							[77.49884632668088, 12.924918444693931],
							[77.49849410706489, 12.924606301490755],
							[77.49804816484507, 12.92418269779956],
							[77.49758436908816, 12.923714461223149],
							[77.497147436759, 12.923326538266691],
							[77.49677289700112, 12.923161523748774],
							[77.4962600905747, 12.922951999601946],
							[77.49588099738172, 12.922813734264722],
							[77.49549752741048, 12.922586336458007],
							[77.49506058710001, 12.922300951229843],
							[77.49467711437534, 12.92203340224676],
							[77.49421776801408, 12.921819397902667],
							[77.49381644007622, 12.921658862010558],
							[77.4935533796122, 12.921493809536548],
							[77.49331700972999, 12.921181676745443],
							[77.49311643186785, 12.920802690723152],
							[77.49289345048051, 12.920481643619866],
							[77.49269278436246, 12.920182872037556],
							[77.49242079501032, 12.919959921279355],
							[77.49206400490773, 12.919723566033152],
							[77.49175193298515, 12.919447154648498],
							[77.49147101501826, 12.91916621898895],
							[77.49127034640303, 12.918880806328097],
							[77.49098496404564, 12.918408124387243],
							[77.49073073598528, 12.917953321141978],
							[77.49025807157246, 12.917360273475735],
							[77.48995938446211, 12.916999047513873],
							[77.4894956433912, 12.916513014073898],
							[77.48902297387235, 12.916169658327908],
							[77.48871080613304, 12.91599129923911],
							[77.48823367005552, 12.915768348273131],
							[77.48780562864114, 12.915549838779773],
							[77.48743998496192, 12.915291268295162],
							[77.4870029263353, 12.914809634948975],
							[77.48654810003596, 12.914332572832706],
							[77.48609773532597, 12.913708232275944],
							[77.48573208618681, 12.913266847376088],
							[77.48554033434006, 12.913119644133037],
							[77.48528618169365, 12.912950211866395],
							[77.48508558974346, 12.912749597231281],
							[77.48497855471511, 12.912477519093832],
							[77.48468422975051, 12.911554548667262],
							[77.48444346559019, 12.910640379081995],
							[77.4843720501693, 12.910479872052214],
							[77.48426510199239, 12.910350557416544],
							[77.48402424943086, 12.910279210825944],
							[77.48335988931932, 12.910265873307324],
							[77.48243235178279, 12.910346119871217],
							[77.481799227392, 12.910261404629207],
							[77.48077356544765, 12.909886850037031],
							[77.47991296155637, 12.90956576358081],
							[77.47894539804675, 12.909231319876797],
							[77.47786176972211, 12.908691741635442],
							[77.47718844635065, 12.908281503000872],
							[77.47642155482828, 12.907831161205488],
							[77.47567242586379, 12.907327321177156],
							[77.47526219274916, 12.907148882893036],
							[77.47481624746831, 12.90708200001987],
							[77.47448180924992, 12.907086498432298],
							[77.47408942733689, 12.90699730907327],
							[77.47345625681156, 12.906729778935325],
							[77.47287656223212, 12.906315114661082],
							[77.47227454503746, 12.905846823608233],
							[77.47124897284063, 12.905200314202753],
							[77.47074060353211, 12.904696415427937],
							[77.47015651872404, 12.903661919377637],
							[77.46970162549655, 12.902828020823026],
							[77.46921119308078, 12.902301916894295],
							[77.46868942250447, 12.901851482368613],
							[77.46801166865258, 12.901365508320431],
							[77.46695034516203, 12.900754597246015],
							[77.46616561595003, 12.900308675474022],
							[77.4655635635061, 12.899911820006595],
							[77.46518900791173, 12.899550608158469],
							[77.46464507293058, 12.899064571873732],
							[77.46395390239005, 12.898524986304574],
							[77.46340987401608, 12.898217340246376],
							[77.46270083879487, 12.897954254268173],
							[77.46210778436428, 12.897588592800481],
						],
					],
				],
				type: "MultiPolygon",
			},
			id: "000000000000000000c5",
			properties: {
				AREA_SQ_KM: 28.55563730610206,
				ASS_CONST1: "Yeshwantpura",
				ASS_CONST_: "153",
				LAT: 12.8919033428845,
				LON: 77.5050129669143,
				LOST_AREA_SQ_KM: 1.7568318131148697,
				OBJECTID: 198,
				POP_F: 11385.0,
				POP_M: 12926.0,
				POP_SC: 3997.0,
				POP_ST: 319.0,
				POP_TOTAL: 24311.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Hemmigepura",
				WARD_NO: 198.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54796334645714, 13.05565925709328],
						[77.5479187366937, 13.055699450218269],
						[77.5474594300513, 13.056118598132407],
						[77.54697790395304, 13.05658230508371],
						[77.54674155742616, 13.056827573105293],
						[77.54671925230663, 13.056849869601853],
						[77.54671925230663, 13.05682312431299],
						[77.54671479128218, 13.056769589491514],
						[77.54671925230663, 13.056756261117119],
						[77.54668802513193, 13.056702719584752],
						[77.54666125897533, 13.05667596758851],
						[77.546523054537, 13.056524308807557],
						[77.54579179281069, 13.05580193715873],
						[77.54542161163447, 13.05545860655295],
						[77.54514966065845, 13.055208913047188],
						[77.5449757656435, 13.05507070604993],
						[77.54486869936505, 13.055008216844643],
						[77.54477064266038, 13.054968165235417],
						[77.54467249839841, 13.054950281583087],
						[77.54452983042962, 13.054945849329703],
						[77.5442577898454, 13.054936940615407],
						[77.5432545580207, 13.054959179186888],
						[77.54277292662057, 13.054954747088152],
						[77.54234928874114, 13.05491456941104],
						[77.54172953070726, 13.054807571972093],
						[77.54139957487466, 13.054749602764439],
						[77.53984329360753, 13.054450839699971],
						[77.53915214078432, 13.054308187239197],
						[77.53892023930669, 13.054303726741278],
						[77.53875971876877, 13.054317057943987],
						[77.53818009340242, 13.05440628797558],
						[77.53752899546146, 13.05451326328657],
						[77.537274871177, 13.054553443968075],
						[77.5371678851207, 13.054571275373936],
						[77.53711880971696, 13.054575704128313],
						[77.53610659198584, 13.054740685756519],
						[77.5353931091004, 13.054834353757576],
						[77.53467524772128, 13.054887863046174],
						[77.53433634839097, 13.0548878552743],
						[77.53382799750187, 13.054878913647567],
						[77.53352478799091, 13.054852171752968],
						[77.53337764448048, 13.054812058292631],
						[77.53313680811002, 13.054731757206818],
						[77.53290052027816, 13.05466487663165],
						[77.53273999124161, 13.054615829898559],
						[77.53235200868369, 13.054611361214066],
						[77.53186595696477, 13.054575722011116],
						[77.53139328804761, 13.054504413662498],
						[77.53125952667418, 13.054446402639263],
						[77.53117484313344, 13.054334963344822],
						[77.53100985002075, 13.054036208726956],
						[77.53067093976937, 13.053389612725546],
						[77.53047025263453, 13.053157707594414],
						[77.53030525850002, 13.053028386942001],
						[77.52995304858736, 13.05281438683521],
						[77.52944467810985, 13.05244423634458],
						[77.5287535495709, 13.051873509636954],
						[77.5283700151631, 13.051623825869683],
						[77.52805340583569, 13.05152120495946],
						[77.52733995992543, 13.051324998759616],
						[77.5268940430247, 13.051191240472154],
						[77.52663543316832, 13.051017339093072],
						[77.52608251702543, 13.050566955989073],
						[77.52559206416116, 13.050196907705145],
						[77.52501237169723, 13.049782232443604],
						[77.52434790017288, 13.049416571385127],
						[77.52393766690334, 13.049135659169297],
						[77.52377275078771, 13.048934920727017],
						[77.52351850828688, 13.048484589659514],
						[77.5232064349023, 13.047695367965739],
						[77.52299234874131, 13.047151332480446],
						[77.52259549650137, 13.04650032697145],
						[77.52219864287851, 13.045889350851649],
						[77.52758973211706, 13.042192780694817],
						[77.53151375188371, 13.03933455241977],
						[77.5359282216842, 13.036070436070666],
						[77.54073511160011, 13.03249423897764],
						[77.54155116922956, 13.036476251773735],
						[77.54190789190497, 13.038179603302074],
						[77.54199256745156, 13.038393672687416],
						[77.54205502437422, 13.038465023733943],
						[77.54260348915321, 13.03894210920991],
						[77.54292005796958, 13.03921859318894],
						[77.54292005796958, 13.039236449287579],
						[77.54293790266246, 13.039280964008341],
						[77.54300035906542, 13.039321175309555],
						[77.54351313008401, 13.039789372008666],
						[77.54388769114271, 13.040181763977104],
						[77.54448968042941, 13.040703466559973],
						[77.5448107925613, 13.040975431570729],
						[77.54496238236422, 13.041020057565444],
						[77.54513181631305, 13.04106908671075],
						[77.54532801648624, 13.04110035118379],
						[77.54545730023142, 13.041127072652793],
						[77.54555098274507, 13.041158276537889],
						[77.54556882702462, 13.041189507650486],
						[77.54668356410626, 13.041515014276019],
						[77.54853408747204, 13.042148229847559],
						[77.54989414518458, 13.042620903747197],
						[77.55174461621478, 13.043142567682375],
						[77.55275686952989, 13.043521579145633],
						[77.55367989883436, 13.043775744231192],
						[77.55469659559543, 13.044056710114589],
						[77.55541004468711, 13.044061135580174],
						[77.55617701713535, 13.04397645264181],
						[77.55692614190632, 13.04378021868877],
						[77.55688599629099, 13.044217205073158],
						[77.55683692940853, 13.044703278490775],
						[77.55675663810055, 13.04497078530144],
						[77.55652477257722, 13.045452391390464],
						[77.556413344013, 13.045630738545308],
						[77.55614133190898, 13.045893860769176],
						[77.55570882257402, 13.046656375083838],
						[77.55549917084288, 13.047021993474363],
						[77.55529852756334, 13.047298500780677],
						[77.55496415046372, 13.047864770828797],
						[77.55469659559543, 13.048292887238205],
						[77.55446918658102, 13.048729854670096],
						[77.5530467328531, 13.050517968878852],
						[77.5526008293414, 13.050954941224406],
						[77.55255622140055, 13.050986162589556],
						[77.55251161344216, 13.051026270005927],
						[77.55242239747277, 13.05115108769529],
						[77.55215938488816, 13.051498935230908],
						[77.55178030282408, 13.05210540016183],
						[77.55091078839645, 13.05325132536251],
						[77.55072798047182, 13.053429745973473],
						[77.55057193749364, 13.05358134136429],
						[77.55010818069478, 13.053920214879737],
						[77.54937239367, 13.054464231444875],
						[77.5491360521277, 13.05462477977335],
						[77.54879273498415, 13.05491463186855],
						[77.5483111270707, 13.055333732589103],
						[77.54796334645714, 13.05565925709328],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000015",
			properties: {
				AREA_SQ_KM: 5.172387167338877,
				ASS_CONST1: "Rajarajeswari Nagar",
				ASS_CONST_: "154",
				LAT: 13.046443698847,
				LON: 77.539338786534,
				LOST_AREA_SQ_KM: 0.11697790116122377,
				OBJECTID: 22,
				POP_F: 13950.0,
				POP_M: 15087.0,
				POP_SC: 6257.0,
				POP_ST: 1156.0,
				POP_TOTAL: 29037.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Jalahalli",
				WARD_NO: 16.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.50258748825917, 12.927839141026727],
						[77.50286400365191, 12.927611748211305],
						[77.50340801948047, 12.927152476206992],
						[77.50358189048775, 12.9270052573464],
						[77.50399657220854, 12.926702076492528],
						[77.50425969612328, 12.92660845849688],
						[77.50439795187795, 12.926550512352412],
						[77.50447819264105, 12.926447884900643],
						[77.50464759934489, 12.926220485095756],
						[77.50474569061166, 12.926055472453116],
						[77.5048839457776, 12.925894985474248],
						[77.50504005140924, 12.925712181637325],
						[77.50512921703013, 12.925502619846728],
						[77.50513814233871, 12.92539999391485],
						[77.50535663717467, 12.925279660355706],
						[77.50578470033435, 12.925141359824972],
						[77.50780914081523, 12.924824765373431],
						[77.51021259223351, 12.924708872726082],
						[77.5103909153206, 12.924780214862466],
						[77.51042661490415, 12.924784696169008],
						[77.5105247012024, 12.924476983853777],
						[77.5105202387589, 12.924169318217382],
						[77.51045785203058, 12.92393744211194],
						[77.5104400022451, 12.923834901480816],
						[77.51045785203058, 12.923821459414944],
						[77.51048462670359, 12.923799228272642],
						[77.5105247012024, 12.923794786816508],
						[77.5109929068035, 12.923736740033107],
						[77.51107323042677, 12.923736740704694],
						[77.51149234732546, 12.92371897610723],
						[77.5116350567154, 12.922782495955259],
						[77.51174652916185, 12.922211759940764],
						[77.5117554539527, 12.921997719976623],
						[77.5117152923884, 12.921756925517563],
						[77.51169744279973, 12.921382357367458],
						[77.5117242171817, 12.921315491805485],
						[77.51167513080996, 12.921074698876348],
						[77.51163059431536, 12.920691176009743],
						[77.51169744279973, 12.920682253305774],
						[77.51177776592677, 12.920673377826207],
						[77.51179999039853, 12.920664455279011],
						[77.51184015193301, 12.920637700421729],
						[77.51185800149935, 12.920610988752225],
						[77.51186246389048, 12.92058862855462],
						[77.51187585106288, 12.920561873830149],
						[77.5118892382337, 12.920517282678123],
						[77.5118892382337, 12.92049057112039],
						[77.5118892382337, 12.92044593698269],
						[77.51188477584361, 12.920387990204638],
						[77.51188477584361, 12.920334480988851],
						[77.51184461432486, 12.919919807224474],
						[77.51181783997117, 12.919879654124736],
						[77.51175545395272, 12.919857341834012],
						[77.51170636759441, 12.919839548929376],
						[77.51166629351084, 12.919830635259924],
						[77.5116350567154, 12.919812756068701],
						[77.51159935751015, 12.919790439373083],
						[77.51157704550118, 12.919768165679834],
						[77.51154143377586, 12.919732497538073],
						[77.5114521856685, 12.919295552974836],
						[77.51143879844636, 12.919130503542837],
						[77.51149680973091, 12.918697997671924],
						[77.51154143377586, 12.918198586602248],
						[77.51156812069637, 12.918109401828008],
						[77.51159935751015, 12.918002342160731],
						[77.51176437874285, 12.917788346736698],
						[77.5120764834648, 12.917297797021282],
						[77.5122013426139, 12.917057048677853],
						[77.512361900604, 12.916450621631052],
						[77.51242428629067, 12.916298968538213],
						[77.51242428629067, 12.916245461104618],
						[77.51241536155217, 12.916214270174853],
						[77.51341422794799, 12.916120639536262],
						[77.51338745396288, 12.915068244195119],
						[77.51339191629417, 12.914301355402808],
						[77.5122013426139, 12.914345934300037],
						[77.51183568954099, 12.914354830748804],
						[77.51165290631384, 12.914354800438499],
						[77.51123370250691, 12.914225493942029],
						[77.51095283246921, 12.91428793651515],
						[77.51089928333678, 12.914087267692636],
						[77.5107654978944, 12.913570049551106],
						[77.51064509961195, 12.912945779370851],
						[77.51053808853183, 12.912437440835493],
						[77.51049792653882, 12.912285843368831],
						[77.51048016425851, 12.912254571988633],
						[77.51042661490415, 12.912205548340541],
						[77.51008326844637, 12.911795320261758],
						[77.50990494487829, 12.91157235265712],
						[77.50979347063178, 12.911434117183148],
						[77.50962844654721, 12.91098372977467],
						[77.50940549740481, 12.910283706656491],
						[77.50927617270256, 12.909860032482785],
						[77.50928063519473, 12.90979761106977],
						[77.50934748505651, 12.909793167947337],
						[77.5094278098386, 12.909779811332973],
						[77.5095035845812, 12.909761927303014],
						[77.50957935927343, 12.909735168990819],
						[77.50962398406865, 12.909726293487985],
						[77.50967307132322, 12.909704020313445],
						[77.50971760858226, 12.90968169995398],
						[77.50976223332339, 12.909650509555487],
						[77.50979784560425, 12.90961483498611],
						[77.5098737076004, 12.909547931269513],
						[77.51002534394206, 12.909423079954356],
						[77.51007880598561, 12.909387404800231],
						[77.51013235550329, 12.909356214049854],
						[77.51019027995324, 12.90932945603905],
						[77.51025275432701, 12.909316013440076],
						[77.51031514116752, 12.909302652756796],
						[77.51032852852155, 12.909302695398372],
						[77.5103909153206, 12.909302689126571],
						[77.5104489271382, 12.90929376988644],
						[77.51051131387138, 12.90928040913762],
						[77.51056486318599, 12.909258092491697],
						[77.51061832497662, 12.909226897369052],
						[77.51066741180324, 12.909191183329186],
						[77.51067187424098, 12.909186746055633],
						[77.51070757373658, 12.909146595353404],
						[77.5107699603283, 12.90905293561694],
						[77.51083243438453, 12.908954886088422],
						[77.51087250876112, 12.908879018731707],
						[77.51090820819392, 12.908780970246315],
						[77.51095729489577, 12.908642728914925],
						[77.5109929068035, 12.908504527064462],
						[77.51102860619861, 12.908361763233094],
						[77.51104645589197, 12.908250361407173],
						[77.5110553807376, 12.908205731706841],
						[77.51105984316017, 12.908156665111829],
						[77.5110553807376, 12.908107598890219],
						[77.51104199346888, 12.90806301320534],
						[77.51102414377483, 12.908018470796426],
						[77.51099736922849, 12.907973842606603],
						[77.51096613224983, 12.907938218001854],
						[77.51093052033374, 12.907906948724744],
						[77.51091713305037, 12.90789803203029],
						[77.51091267062223, 12.907893595133963],
						[77.51083243438453, 12.90781330447064],
						[77.51076103546032, 12.907733095457228],
						[77.51069418642705, 12.907643887198091],
						[77.51064063717317, 12.907563591467293],
						[77.51063171229507, 12.90755023757097],
						[77.51054255097463, 12.907447719208644],
						[77.5104489271382, 12.907345158053195],
						[77.51039537776916, 12.907291570990713],
						[77.51038199042297, 12.907251465820467],
						[77.51037315302365, 12.907197967435605],
						[77.51037315302365, 12.907148944621023],
						[77.51038199042297, 12.907095437139265],
						[77.51039537776916, 12.90704637075443],
						[77.5104176900093, 12.907001784137607],
						[77.51044446469173, 12.906957154271165],
						[77.51046231447653, 12.906939319268183],
						[77.5105202387589, 12.906890333038229],
						[77.51063617473422, 12.906810026717727],
						[77.51075211059165, 12.906734200438967],
						[77.51080565979318, 12.906703009732531],
						[77.5110553807376, 12.906707470553968],
						[77.511514659351, 12.906676287581641],
						[77.51193823701547, 12.906667333570557],
						[77.51208987061207, 12.90666284187895],
						[77.51215671882719, 12.906627208805759],
						[77.51222811687755, 12.906297227590976],
						[77.51224596638315, 12.905485640577599],
						[77.51224596638315, 12.90452699405984],
						[77.51221472974652, 12.904014161541813],
						[77.5118089151852, 12.904014154096139],
						[77.51089928333677, 12.903920501221744],
						[77.51090374576545, 12.903773393420046],
						[77.51094837004247, 12.903496924083273],
						[77.51097951952745, 12.903385478650577],
						[77.51098844437833, 12.903305183176197],
						[77.51099736922849, 12.903238327674183],
						[77.5110909926147, 12.90304210419666],
						[77.51111330471875, 12.902886036477053],
						[77.51108206777185, 12.902756679395235],
						[77.51103753104564, 12.902676477696291],
						[77.51094837004247, 12.902609546114325],
						[77.51093944518846, 12.90254717195234],
						[77.51097059467587, 12.902484662112744],
						[77.51103753104564, 12.90240440700869],
						[77.51120246559299, 12.902275116897567],
						[77.5113630249895, 12.902145788081468],
						[77.51167959320827, 12.9021457994701],
						[77.51182230236387, 12.902199287401702],
						[77.5119203874617, 12.902203757092721],
						[77.51199624804596, 12.902203795474167],
						[77.51216564358594, 12.902145795565024],
						[77.51231281450679, 12.902145825095422],
						[77.51237975008867, 12.902119070347293],
						[77.51247337233991, 12.9021056633284],
						[77.51254468269465, 12.9020967807407],
						[77.51271416491709, 12.902092285500585],
						[77.51287463468798, 12.9020878726537],
						[77.51291479584394, 12.902087826906017],
						[77.5129772687251, 12.902092302693596],
						[77.51303519172997, 12.902101211429521],
						[77.5130708904857, 12.902105688990009],
						[77.51313327578443, 12.902101200601933],
						[77.51318236152845, 12.902083406668908],
						[77.51323582209191, 12.902061085164618],
						[77.51331614413523, 12.90206107967615],
						[77.51342315260831, 12.9020700280204],
						[77.5138021875365, 12.902056592053594],
						[77.51387349706013, 12.902034355240884],
						[77.51396711808478, 12.901967446361219],
						[77.51399835424132, 12.901873838697156],
						[77.51405190191834, 12.90175787274179],
						[77.51405190191834, 12.901713245462734],
						[77.5140072788559, 12.901583933101103],
						[77.51393596941595, 12.901508127994738],
						[77.5138422608694, 12.901459070794976],
						[77.51374417742488, 12.901418934951806],
						[77.51365948085896, 12.901401154759984],
						[77.51358808370492, 12.901396679956992],
						[77.51335621763896, 12.901262909992923],
						[77.51323582209191, 12.901187065511019],
						[77.51316004982924, 12.901120177762314],
						[77.51311542641773, 12.901057806629737],
						[77.51307981517289, 12.900955206033032],
						[77.5130218046937, 12.900861605806133],
						[77.51288802174153, 12.90068319861313],
						[77.51279886218525, 12.900611875344644],
						[77.51272755198949, 12.900545030759515],
						[77.51265615425186, 12.900500409155777],
						[77.51254914505891, 12.900442440768382],
						[77.5124198239215, 12.90037555725675],
						[77.51237975008867, 12.900308667564984],
						[77.51237975008867, 12.90022837434495],
						[77.5124198239215, 12.900045597044473],
						[77.51246890997267, 12.899974217174337],
						[77.51258484396682, 12.899947497764478],
						[77.51267400369046, 12.89984048718832],
						[77.51277208805564, 12.899782496024176],
						[77.51283456101498, 12.899728991517682],
						[77.51287017233643, 12.899675484758282],
						[77.51290587114383, 12.899617502361014],
						[77.51295495698575, 12.899519415622155],
						[77.51323582209191, 12.899265192974157],
						[77.5134276149382, 12.899037778452184],
						[77.51350338702305, 12.898926332132415],
						[77.5135480102827, 12.898859475569843],
						[77.51357924655407, 12.898747950930225],
						[77.5135524726077, 12.898618640921711],
						[77.51346322607442, 12.898453669789609],
						[77.51341422794799, 12.898364469174878],
						[77.51332953113696, 12.898311023890729],
						[77.51328937012708, 12.898261964506672],
						[77.51327152078483, 12.898181673903355],
						[77.51325813377629, 12.8981102995454],
						[77.51327598312065, 12.898047925406031],
						[77.51331614413523, 12.898003339479402],
						[77.5133651423038, 12.89796314226411],
						[77.51343207726791, 12.897923033947523],
						[77.51345885124256, 12.897882885828682],
						[77.51357478423012, 12.897811539828679],
						[77.51364609389599, 12.8977223644336],
						[77.5136951794192, 12.897673297928607],
						[77.51376648900977, 12.897570769301964],
						[77.51386457243508, 12.897370113784755],
						[77.51393150710597, 12.897227401012904],
						[77.51397158039339, 12.897093646003762],
						[77.51398050501007, 12.897000000649385],
						[77.51398050501007, 12.896803838327266],
						[77.51398050501007, 12.896754733186263],
						[77.51394043172577, 12.896674492840473],
						[77.51396265577601, 12.896478281824884],
						[77.5139760427018, 12.896429218709985],
						[77.51403405269546, 12.89633113281328],
						[77.51410536207405, 12.896108168621318],
						[77.51412321128576, 12.896081460847988],
						[77.51426145508574, 12.895760410524936],
						[77.51429715345749, 12.895608785617084],
						[77.51431054034401, 12.895515097817725],
						[77.5142882288656, 12.895332335956857],
						[77.51427037967973, 12.895296672203262],
						[77.51422129440417, 12.895247615323592],
						[77.51414552279647, 12.895229750852952],
						[77.51411428668025, 12.895234190398622],
						[77.51404743961288, 12.89522528449042],
						[77.51398496731814, 12.895189624920114],
						[77.51393150710597, 12.895149489042096],
						[77.5138824216845, 12.89510043222174],
						[77.5138690347477, 12.895073684476552],
						[77.5138690347477, 12.895038019176488],
						[77.51390027093112, 12.894984475274079],
						[77.51394043172577, 12.894904224674592],
						[77.51395373115793, 12.894823972396354],
						[77.51398050501007, 12.89474368004913],
						[77.5141187489831, 12.894404800506349],
						[77.51415444739953, 12.89424878377711],
						[77.51414106049468, 12.894132787456082],
						[77.5141008997707, 12.894025753177159],
						[77.51383779855573, 12.893156262397497],
						[77.51383779855573, 12.893022476717185],
						[77.51402066577647, 12.89295116809247],
						[77.51423021900129, 12.892969014086361],
						[77.5144888570224, 12.892991291475946],
						[77.51477426812241, 12.892986860342965],
						[77.51479211722939, 12.892986858504193],
						[77.51480104178182, 12.892982377951503],
						[77.51492581045157, 12.892951171462153],
						[77.51497935769294, 12.892915501126348],
						[77.51507297780937, 12.892830761783314],
						[77.51526030530682, 12.892647934497418],
						[77.51526922982261, 12.892572124514112],
						[77.51529591587054, 12.892509661172106],
						[77.5153360761735, 12.89247403527568],
						[77.51557240045841, 12.892456212116999],
						[77.51563040963968, 12.89244281003305],
						[77.51570171801961, 12.892420486085717],
						[77.51575526489647, 12.892402647897185],
						[77.51578650056301, 12.892402687556242],
						[77.51580426201664, 12.892402681389951],
						[77.51583103543412, 12.892416031185542],
						[77.51585334661056, 12.892429424544023],
						[77.51588012001649, 12.892474045585782],
						[77.51589796895031, 12.892505228737347],
						[77.51588904448376, 12.892585518482985],
						[77.51588458225021, 12.892661285084012],
						[77.51583995990522, 12.892732619532998],
						[77.51582657319831, 12.892830785384591],
						[77.51574187817961, 12.89312503393142],
						[77.5157151047412, 12.893196366638161],
						[77.51572402922137, 12.893281134546472],
						[77.51575972713508, 12.893437229678035],
						[77.51577311384983, 12.893486203050516],
						[77.51580872425332, 12.89355313161526],
						[77.51580872425332, 12.893588796673335],
						[77.51581764872616, 12.893704750319614],
						[77.51582657319831, 12.893727061604775],
						[77.51584888437561, 12.893744848732478],
						[77.51588458225021, 12.893758283993108],
						[77.51592465485102, 12.893767191814478],
						[77.51602282391401, 12.89376270172442],
						[77.51614767874092, 12.893753810981647],
						[77.51617882680537, 12.893758240034865],
						[77.51623237345807, 12.893758234298398],
						[77.51626807121255, 12.89375823046683],
						[77.51634830362485, 12.893767176978912],
						[77.51639738798444, 12.893767128610559],
						[77.51644192261685, 12.893762726036934],
						[77.51649100693614, 12.893758284109],
						[77.51650885577428, 12.893740449578326],
						[77.51650885577428, 12.893718137301958],
						[77.51654009123428, 12.893686905338543],
						[77.516593550223, 12.893673542384358],
						[77.51666494549454, 12.893669054894739],
						[77.51680309871769, 12.893615576569195],
						[77.51686110727256, 12.893562029322037],
						[77.51690126702401, 12.893508570195905],
						[77.51700380994173, 12.893379203781087],
						[77.51703058307741, 12.893321223419122],
						[77.51706173090055, 12.893258801757328],
						[77.51710189058132, 12.893218695535067],
						[77.51712420150893, 12.893156279117676],
						[77.51714205024791, 12.893133964889309],
						[77.51719104677187, 12.893120559169759],
						[77.51723566858787, 12.893102721560597],
						[77.51731143814128, 12.893102751773494],
						[77.51736944643739, 12.893111661414439],
						[77.51764146383019, 12.893178601478013],
						[77.51773954392094, 12.89320977138305],
						[77.51788224549009, 12.893258854825238],
						[77.51817211023636, 12.893374724503863],
						[77.51840396650533, 12.893428272830931],
						[77.5184752731498, 12.89343273987168],
						[77.51856451575853, 12.893388104569572],
						[77.51864474655395, 12.89330340723508],
						[77.51869829204794, 12.893214237626347],
						[77.51873390153735, 12.893089400747975],
						[77.51879637122899, 12.892942252650638],
						[77.51885429127664, 12.892844162131425],
						[77.51894799570225, 12.892803962749754],
						[77.51902376411704, 12.8928396574832],
						[77.51918431249685, 12.892884258076398],
						[77.5193849320344, 12.892946725120762],
						[77.51949193480944, 12.892951101140978],
						[77.51961241123965, 12.892942255927483],
						[77.51973280005062, 12.892968985579643],
						[77.52004488295297, 12.893125034053336],
						[77.52019650576457, 12.8932320929913],
						[77.52060229046151, 12.893472856823852],
						[77.52066913368388, 12.893486240155712],
						[77.5207092920931, 12.893521943647917],
						[77.52077176070154, 12.893557601211509],
						[77.520843065864, 12.893562067549276],
						[77.52091445847272, 12.893539745795117],
						[77.52097683947778, 12.89353525376209],
						[77.52105715606511, 12.893579868452282],
						[77.52111953699203, 12.893731478274551],
						[77.52112399902056, 12.893798372418361],
						[77.52113292307709, 12.893896495081075],
						[77.52113292307709, 12.894021325237173],
						[77.52112399902056, 12.894427089917286],
						[77.52114184713291, 12.89451185880399],
						[77.52115077118805, 12.894672354462978],
						[77.52114184713291, 12.89484181218855],
						[77.52101253574581, 12.895372473582956],
						[77.52098130151188, 12.895483912767476],
						[77.52095899133957, 12.895581997632123],
						[77.52094560523408, 12.8956667281567],
						[77.52091892050929, 12.895715755234004],
						[77.52090553439908, 12.895769299369139],
						[77.52090999643599, 12.895903047480365],
						[77.52090553439908, 12.895929797774107],
						[77.52089661032474, 12.895956591691785],
						[77.5208921482873, 12.895983341995708],
						[77.52088322421191, 12.896027882952747],
						[77.52087430013582, 12.896068030239961],
						[77.52086091402036, 12.896117094675908],
						[77.52074052640161, 12.896277655323047],
						[77.5206246007037, 12.896491672104645],
						[77.52055320791331, 12.896759179412173],
						[77.52056213201455, 12.896799324777913],
						[77.5205710561151, 12.896857303449833],
						[77.52066475912864, 12.897044628338607],
						[77.52070036800339, 12.897115953265487],
						[77.52072714027044, 12.897169450155328],
						[77.52081629362418, 12.897276435626326],
						[77.52084752790336, 12.89733445504293],
						[77.5208921482873, 12.897374596459063],
						[77.52094560523408, 12.897468232834578],
						[77.52095899133957, 12.897512814788065],
						[77.52098576354582, 12.897535124955304],
						[77.52101253574581, 12.897561828869199],
						[77.52105269403397, 12.897597534085476],
						[77.52108830278746, 12.89764654609152],
						[77.52115523321534, 12.897664414741962],
						[77.52120869003865, 12.897668884120911],
						[77.52123546218623, 12.897664401061004],
						[77.52127115837321, 12.897664396843517],
						[77.52132024061204, 12.89765995420902],
						[77.52139154543114, 12.89764658797115],
						[77.52146293769609, 12.897633225918524],
						[77.52152531840076, 12.89762873434717],
						[77.5216010851013, 12.897628764133012],
						[77.52169478726748, 12.89760648258456],
						[77.52179732587079, 12.897561882323107],
						[77.52185533188282, 12.897530645194134],
						[77.52191325037514, 12.89749053017923],
						[77.52198464231309, 12.897459334456812],
						[77.52204702273201, 12.89745036279964],
						[77.52210502861686, 12.897450398766866],
						[77.52213180055375, 12.897450395480721],
						[77.52214509902986, 12.897441472890081],
						[77.52229225706321, 12.897374553481592],
						[77.52249742059139, 12.897329983163397],
						[77.522537578356, 12.897325541316565],
						[77.52261780635314, 12.897330006954183],
						[77.52270249626044, 12.897379055715609],
						[77.5228228818054, 12.897477120598252],
						[77.52292104492447, 12.897584109359792],
						[77.52296557700672, 12.897642080058992],
						[77.5230325063314, 12.897659948238479],
						[77.52308604976282, 12.897655461545112],
						[77.52319304906096, 12.897628736501227],
						[77.52327773854047, 12.897628764574382],
						[77.52340704735565, 12.897624306975812],
						[77.52357205148978, 12.89759305126944],
						[77.5237415173108, 12.897526170772727],
						[77.52404475288975, 12.89737906036375],
						[77.52418744655994, 12.897379037295885],
						[77.52442375248371, 12.89737008064727],
						[77.52455752189145, 12.89731664392646],
						[77.52475367007824, 12.897236314849673],
						[77.5248562934151, 12.897156049829414],
						[77.52490974850106, 12.897071350412661],
						[77.52495882917165, 12.896946465741408],
						[77.52498113856036, 12.896812711156436],
						[77.52497667668298, 12.896692313995537],
						[77.5249231341406, 12.89647383961876],
						[77.52482506023541, 12.896282083191178],
						[77.52475813196438, 12.896166175205284],
						[77.52466451980686, 12.896059148906314],
						[77.52455305999744, 12.89605025185661],
						[77.5244683714665, 12.896027911422507],
						[77.52442821438278, 12.895961020274436],
						[77.52442375248371, 12.895849583946559],
						[77.52450844104787, 12.895680151276759],
						[77.52466005791706, 12.895501792700623],
						[77.52477597950717, 12.895434832660605],
						[77.52494990541493, 12.895390306716191],
						[77.52513275480008, 12.895363526488575],
						[77.5253066801628, 12.89537689387851],
						[77.52540029179231, 12.895430419267399],
						[77.52548944148754, 12.895515089069086],
						[77.52556083118334, 12.895653352006091],
						[77.52564998075285, 12.895760335294963],
						[77.5257837487198, 12.895831731577157],
						[77.52592644021044, 12.895791559959594],
						[77.52600229146957, 12.89566675809727],
						[77.52604236051107, 12.895541871153252],
						[77.52618058984734, 12.895430409498694],
						[77.52635005178561, 12.895399193335788],
						[77.5266398949807, 12.895301058051421],
						[77.5269877408351, 12.895252063016452],
						[77.52721511736708, 12.895243103264518],
						[77.52750049658088, 12.895238657307317],
						[77.52776802800825, 12.8952520471819],
						[77.52799995222597, 12.895256486739992],
						[77.52820947973414, 12.895256488530052],
						[77.52832994694101, 12.895256512634475],
						[77.52872231746058, 12.895211897068117],
						[77.52893193112352, 12.895211902349581],
						[77.5290879163325, 12.895194078362762],
						[77.52915930377128, 12.895194066829143],
						[77.52930645273787, 12.895122747797052],
						[77.52946698661934, 12.895051383380697],
						[77.52968106060374, 12.894948822918398],
						[77.5299619719518, 12.894944331143007],
						[77.53022949759246, 12.89484184303947],
						[77.5304836376201, 12.894739227568373],
						[77.53066201646051, 12.89460100494437],
						[77.53094738703017, 12.894560886904335],
						[77.53111238023345, 12.894556417430914],
						[77.53123275688402, 12.894667916584924],
						[77.53128183482784, 12.894917621036367],
						[77.53128183482784, 12.895229752189918],
						[77.53151812602206, 12.895640006841345],
						[77.53176788904256, 12.896077015868794],
						[77.531941804265, 12.896433701353045],
						[77.53222717162436, 12.897044563168825],
						[77.53236985503558, 12.89740130064082],
						[77.53253484617575, 12.897655468175904],
						[77.53264629816789, 12.89778480674746],
						[77.53278005791064, 12.897954246944481],
						[77.5328469814639, 12.898056803405558],
						[77.5330208950365, 12.89817277781296],
						[77.5331234234366, 12.898239655843946],
						[77.53329733658651, 12.898377858565357],
						[77.53351140336318, 12.898337755271944],
						[77.53362285439839, 12.898413505827273],
						[77.53370316206251, 12.898498269305184],
						[77.53375215145938, 12.898574030737727],
						[77.53405536036419, 12.898797027922592],
						[77.5341178216481, 12.898957526174481],
						[77.5343318868804, 12.89902442836164],
						[77.53457263323975, 12.899077967330866],
						[77.53454140272697, 12.899363366518061],
						[77.53442549108503, 12.900558390026568],
						[77.53444779861775, 12.902136932723927],
						[77.53448795216563, 12.903273925452602],
						[77.53441656807072, 12.903363112131496],
						[77.5340508988426, 12.903438913277737],
						[77.53409551405063, 12.904709767156607],
						[77.53364962362606, 12.904794544247634],
						[77.53259284671589, 12.904928324208443],
						[77.53216470930217, 12.905030839925645],
						[77.53215132451439, 12.90502640461692],
						[77.53199079442052, 12.904874790224127],
						[77.53184364892566, 12.904736571995347],
						[77.5315939735542, 12.904460043506225],
						[77.53138436479541, 12.904326315151577],
						[77.53125506504291, 12.904277270117989],
						[77.53117038149887, 12.904277243394832],
						[77.53109899532184, 12.90428173388751],
						[77.53096969524502, 12.904312903154745],
						[77.53085377996328, 12.904393224390184],
						[77.53072894125982, 12.904464586231528],
						[77.53019826588725, 12.904758864206075],
						[77.52981482383292, 12.904914877834402],
						[77.52938667596472, 12.905030828152528],
						[77.52914591862992, 12.905075412855284],
						[77.5288828521661, 12.905084373216164],
						[77.52870893226782, 12.905030851964336],
						[77.52798656694824, 12.904723205367903],
						[77.52651505165453, 12.904232671818347],
						[77.525908592848, 12.904081037685483],
						[77.52588628364103, 12.90408995769831],
						[77.52583282901358, 12.90412563768186],
						[77.52573475589206, 12.904192512123393],
						[77.52569459925505, 12.904290607732593],
						[77.52569906110429, 12.904616155699044],
						[77.52569906110429, 12.904870322812831],
						[77.52562767149519, 12.905316201773545],
						[77.52555636932865, 12.905565902422449],
						[77.52457983135882, 12.906319543651765],
						[77.52450397915196, 12.90646666865072],
						[77.52455752189145, 12.906689643485905],
						[77.52652397528855, 12.906671834411119],
						[77.52656859344819, 12.906667348974748],
						[77.52684058887048, 12.90667183253962],
						[77.52702789701593, 12.908112067903774],
						[77.52702343521877, 12.908281504485705],
						[77.52710366005495, 12.908660515549954],
						[77.52718388483459, 12.909146627297444],
						[77.52723742631363, 12.909552358953592],
						[77.5273622688475, 12.910221221168266],
						[77.52752726724908, 12.910854454846003],
						[77.52778587507915, 12.91161696734062],
						[77.5279776434289, 12.912201093259727],
						[77.52811587041491, 12.912736200319028],
						[77.52847263523967, 12.913824164886375],
						[77.52811140866038, 12.913868800468677],
						[77.52920392089779, 12.919598709203196],
						[77.52919499747388, 12.919625508922122],
						[77.52916822719799, 12.919661185732908],
						[77.5289853842955, 12.919924240620333],
						[77.5288248497346, 12.92013382497005],
						[77.52864646801412, 12.920494985064588],
						[77.52850377993343, 12.920989966455585],
						[77.52848155872022, 12.92110591566207],
						[77.52847263523967, 12.921154947757044],
						[77.52844140305224, 12.921181706375641],
						[77.52841017085626, 12.921195108456102],
						[77.52825855898196, 12.921212961989411],
						[77.52817833495985, 12.92126639857128],
						[77.52819172021502, 12.921418015826484],
						[77.5282050179833, 12.921467085141426],
						[77.52823178848578, 12.921511719842663],
						[77.52826302073072, 12.921565186792023],
						[77.52828979121955, 12.921591984048652],
						[77.52834333217835, 12.921596374667113],
						[77.5283744769075, 12.921591930206489],
						[77.5288917756145, 12.921400231426675],
						[77.52981036214501, 12.921025628776675],
						[77.53042572348818, 12.920793756975868],
						[77.53110791859642, 12.92069117291239],
						[77.5342114260289, 12.92051283344759],
						[77.53631173060865, 12.920521810986504],
						[77.53759591621984, 12.92052619059355],
						[77.53761822313056, 12.920468236445455],
						[77.53805071389888, 12.920401342882993],
						[77.53855467204072, 12.92029437289915],
						[77.53889355879703, 12.920196255737162],
						[77.53896039127503, 12.92018732391265],
						[77.53897377526131, 12.920196241103088],
						[77.53899608190162, 12.92020515713409],
						[77.53899608190162, 12.920227476007895],
						[77.53899608190162, 12.920236351854644],
						[77.53901838853754, 12.920428127743765],
						[77.53902284986422, 12.920593106297032],
						[77.53902731119071, 12.92061098673331],
						[77.53905407914594, 12.920789276520022],
						[77.53906300179628, 12.920842789251639],
						[77.53908522094214, 12.92087402015965],
						[77.53912091152955, 12.920918653598486],
						[77.53920121531021, 12.92109702323397],
						[77.53921013794901, 12.921114860101271],
						[77.5392680476073, 12.921257509573714],
						[77.53931266076803, 12.921400208266595],
						[77.53939742572518, 12.921690044816197],
						[77.53943311621455, 12.921872902613018],
						[77.53944641266851, 12.921886210676686],
						[77.53945979659757, 12.921899609129898],
						[77.53949548706733, 12.921904085676418],
						[77.53953563883243, 12.92190412365627],
						[77.53961139354016, 12.921895190698395],
						[77.53969169697427, 12.921895180431461],
						[77.53971846477303, 12.921908534079517],
						[77.53973184867004, 12.921917451446017],
						[77.53986560007719, 12.922060135497118],
						[77.53989682912734, 12.922113689171036],
						[77.53999042875009, 12.922193944880792],
						[77.54002611905321, 12.922341041085637],
						[77.54007519320173, 12.922488265052163],
						[77.54014648626742, 12.92282269825525],
						[77.54022678932344, 12.923094658219682],
						[77.54028478593972, 12.923286435073557],
						[77.54028924721666, 12.923322068232627],
						[77.54032484994997, 12.9236833094159],
						[77.54035607887393, 12.923977554396437],
						[77.54036946269585, 12.924147018720024],
						[77.54033377250055, 12.924218377319198],
						[77.54028924721666, 12.92425845915458],
						[77.54025355699616, 12.924276302111135],
						[77.54019556036394, 12.924289709711847],
						[77.54015987011402, 12.924303071467454],
						[77.53985221619592, 12.92449033606303],
						[77.5396693904705, 12.924583993196547],
						[77.53952671621919, 12.924610729207362],
						[77.53941973228235, 12.924633065877181],
						[77.53935727391125, 12.924633073264646],
						[77.53911198888375, 12.924686578884376],
						[77.5390451564949, 12.92472670610479],
						[77.53902731119071, 12.924766866478292],
						[77.53894254595751, 12.924806991525013],
						[77.53890248145997, 12.924815919661452],
						[77.53888463613337, 12.92481592172188],
						[77.53885786813824, 12.924811443626249],
						[77.5388177161337, 12.924784690423037],
						[77.5388087934641, 12.924775729087646],
						[77.53873295074422, 12.924677625855047],
						[77.53863935008587, 12.924575047820078],
						[77.53850559723568, 12.924427964473345],
						[77.53827815579483, 12.92410685965068],
						[77.53819793883963, 12.923915131822946],
						[77.53813101843565, 12.92385270519454],
						[77.53785012726453, 12.923598570989856],
						[77.53773859289475, 12.923518311350083],
						[77.53762268451217, 12.923442494512182],
						[77.53743093242278, 12.92341128197242],
						[77.537274871177, 12.923388941337869],
						[77.5371678851207, 12.92338447645967],
						[77.53710096411034, 12.9233890081346],
						[77.53699843929651, 12.923438014317451],
						[77.53688244268064, 12.923549537763218],
						[77.53683791604477, 12.92367432856208],
						[77.53682007039407, 12.923732326461293],
						[77.5368066861542, 12.923759085348276],
						[77.53671745784814, 12.924026583402302],
						[77.53667730508757, 12.924173732410159],
						[77.53673530351496, 12.924485897618526],
						[77.5367308420985, 12.9247355927541],
						[77.5367308420985, 12.924945172945366],
						[77.5367308420985, 12.925145877353593],
						[77.5367308420985, 12.925551596031662],
						[77.53678437908425, 12.925957396819982],
						[77.53681114756766, 12.92623384992883],
						[77.53681114756766, 12.92641671790406],
						[77.53674868776322, 12.92654599012448],
						[77.5366104712673, 12.926661959779496],
						[77.53628942344159, 12.926800142180703],
						[77.53578554273733, 12.926956227437111],
						[77.53551348122042, 12.927041012671907],
						[77.53527273685293, 12.92712566957257],
						[77.53509882672388, 12.927312996253887],
						[77.53500076158916, 12.92737092057833],
						[77.53497845416491, 12.9275939069875],
						[77.53499630010467, 12.927745491954338],
						[77.53497399267953, 12.928044229450197],
						[77.53499183861999, 12.928285095544066],
						[77.53506313487375, 12.928606099191795],
						[77.53508990376241, 12.928895915209713],
						[77.53514344152079, 12.929466759265967],
						[77.5348446970077, 12.929275001494013],
						[77.53483577402628, 12.929288359956796],
						[77.53485799399833, 12.929453343396249],
						[77.53482685104416, 12.929591538239935],
						[77.53471977520407, 12.9297832955276],
						[77.53459047924319, 12.92997949718824],
						[77.53444779861775, 12.930140064828478],
						[77.5343318868804, 12.930336222491693],
						[77.53424711814635, 12.930585934303002],
						[77.53424711814635, 12.930768807638803],
						[77.53425157966025, 12.931067549867949],
						[77.53420696451344, 12.931379697527843],
						[77.53420696451344, 12.931616003907163],
						[77.5342158875442, 12.931758718062765],
						[77.53422034905932, 12.931896908241573],
						[77.53422034905932, 12.932088660224268],
						[77.53422034905932, 12.932235857015355],
						[77.53417582138098, 12.93241865410635],
						[77.53407766796953, 12.9325078583297],
						[77.53408659101042, 12.93267728969341],
						[77.53409551405063, 12.932797684183457],
						[77.5340598218856, 12.93290024234038],
						[77.5340508988426, 12.932931440583726],
						[77.53404643732082, 12.932953718760405],
						[77.53402421719021, 12.933002804777455],
						[77.5340019095744, 12.933011726067706],
						[77.53398852500281, 12.93301168388758],
						[77.53397514042965, 12.933038444109886],
						[77.53397514042965, 12.933042925541251],
						[77.53396175585492, 12.933083043883748],
						[77.53393944822685, 12.933096446599487],
						[77.53389483295759, 12.933158844951796],
						[77.53377892065663, 12.933377326997789],
						[77.53369870052711, 12.93361812798607],
						[77.53368977745576, 12.933618128564287],
						[77.53369423899153, 12.933751925476553],
						[77.53368085438372, 12.933863402572419],
						[77.53364962362606, 12.93392136190504],
						[77.53359162362482, 12.93398380440705],
						[77.53354263415879, 12.934023929428436],
						[77.53277113476723, 12.935495427292256],
						[77.53362285439839, 12.93541516447079],
						[77.53374322839225, 12.936043903294667],
						[77.53433634839097, 12.936494295500443],
						[77.53429619478956, 12.936534416520344],
						[77.53426942571407, 12.936570098277349],
						[77.53426496420087, 12.936579018586725],
						[77.53425157966025, 12.936596816374326],
						[77.53424711814635, 12.936601255113198],
						[77.53419366744669, 12.936677104428947],
						[77.53418920593053, 12.93669490170498],
						[77.53409105253061, 12.936958027662872],
						[77.53392160212124, 12.93751987777569],
						[77.53386368973965, 12.937622401784024],
						[77.53380568984757, 12.937725007794993],
						[77.53337764448048, 12.938402800744166],
						[77.5331234234366, 12.938835251692401],
						[77.53298520253425, 12.939076107531365],
						[77.53290498184488, 12.939196516583744],
						[77.53267306762538, 12.939562130611991],
						[77.53250807668572, 12.939816260742852],
						[77.53238770138465, 12.940012472244534],
						[77.53212018081908, 12.94045841829087],
						[77.53147359728585, 12.941564274762563],
						[77.53143344267382, 12.941622236727257],
						[77.53142005779998, 12.941644516471465],
						[77.53141113454988, 12.941662357452282],
						[77.53093400209916, 12.942523006330175],
						[77.5308805498426, 12.94261212981758],
						[77.53086270325709, 12.942647811919143],
						[77.53043018515197, 12.943437099325042],
						[77.53038556850629, 12.943508463947003],
						[77.53034987517717, 12.943566383061503],
						[77.5301804191947, 12.943816077668236],
						[77.5301091198804, 12.94392312901549],
						[77.52997535699717, 12.944119300229328],
						[77.52968543481254, 12.94452512751235],
						[77.52961859688365, 12.944583094494114],
						[77.52961859688365, 12.944587576306931],
						[77.52959628840388, 12.944614251994054],
						[77.52945806321607, 12.944739102273],
						[77.52940006107777, 12.944779309992503],
						[77.52928414419692, 12.944855075411681],
						[77.52925291223222, 12.944872873720673],
						[77.52922622945447, 12.944886323668715],
						[77.52919499747388, 12.944904121968037],
						[77.52896307569159, 12.944993333732137],
						[77.52894522880533, 12.944997729511334],
						[77.52867770014525, 12.945113750145383],
						[77.52831209995544, 12.945247443795747],
						[77.52828979121955, 12.945260846263752],
						[77.52828086772398, 12.945274205576261],
						[77.52824963548395, 12.945296571720894],
						[77.52821840323531, 12.945323247305002],
						[77.52818725846346, 12.945341135843591],
						[77.52817833495985, 12.945350013332325],
						[77.52817387320776, 12.945350056443235],
						[77.52812925567743, 12.945381256980367],
						[77.52810248515082, 12.945358934148175],
						[77.52797318166894, 12.945238576108826],
						[77.52748264946526, 12.944774809685956],
						[77.52702789701593, 12.944342249555204],
						[77.52692973743775, 12.944244167985111],
						[77.52658644070715, 12.943909730136768],
						[77.52593982573043, 12.943017927534015],
						[77.52528883270321, 12.942090413566048],
						[77.52495882917165, 12.941559778104573],
						[77.52447274587561, 12.940717032286905],
						[77.52419190846827, 12.940355889734205],
						[77.52349182416492, 12.939584436888747],
						[77.52295227862642, 12.93901361956233],
						[77.5226311922621, 12.938786277600698],
						[77.52211841458609, 12.938483060487117],
						[77.52161000912045, 12.938099561302517],
						[77.52107045466671, 12.937622431386375],
						[77.52064244889431, 12.937131903187568],
						[77.52037936278192, 12.936971398400317],
						[77.5198041058634, 12.936743937669211],
						[77.51908177154141, 12.936498687412358],
						[77.51833257232335, 12.936396145303794],
						[77.51759238000642, 12.936360480834649],
						[77.51665602108804, 12.936329299716782],
						[77.51575972713508, 12.93619551556829],
						[77.51497935769294, 12.93607956667446],
						[77.51431937743818, 12.935959177007634],
						[77.51392704479579, 12.93582095166216],
						[77.5137798759586, 12.935767391429536],
						[77.51282117395515, 12.935495458054517],
						[77.51165736871302, 12.935156563325153],
						[77.51147449770188, 12.93507180502385],
						[77.51129617630912, 12.934982613034137],
						[77.51112222955916, 12.934880102725083],
						[77.51094837004247, 12.934777510528365],
						[77.51078334762893, 12.934666038374838],
						[77.51061832497662, 12.934550085147245],
						[77.51045785203058, 12.93442521721579],
						[77.51030621626396, 12.93430034558803],
						[77.5102215171444, 12.934224554270948],
						[77.51009665582762, 12.934126484759586],
						[77.50986924513144, 12.933943661139164],
						[77.50964629645972, 12.933756399988498],
						[77.50942780983861, 12.93356461516864],
						[77.50921824778838, 12.933363955081195],
						[77.50910668537078, 12.93325696363293],
						[77.50903091036454, 12.933176692397216],
						[77.50922708527719, 12.933127659443096],
						[77.50960167167322, 12.93306518659785],
						[77.50974884590288, 12.933029555371562],
						[77.5099405571146, 12.933002797688731],
						[77.51009219336738, 12.932909127514232],
						[77.51031514116752, 12.932828899119798],
						[77.51051577631523, 12.932753107599709],
						[77.51064509961195, 12.932663874118983],
						[77.51081904708964, 12.932556890158233],
						[77.51093498276119, 12.932481056203997],
						[77.51094837004247, 12.932231365225807],
						[77.51093052033374, 12.931923721351437],
						[77.51091267062223, 12.931036299489426],
						[77.51090820819392, 12.930661745488951],
						[77.51091267062223, 12.930086581780564],
						[77.51096613224983, 12.929890363025201],
						[77.51096613224983, 12.929738698676875],
						[77.51095729489577, 12.92954696818934],
						[77.51097059467587, 12.928904849983887],
						[77.50985139525383, 12.928909299808574],
						[77.50983354537344, 12.92909663726978],
						[77.50892389776996, 12.929132316791744],
						[77.50848692134187, 12.929145674786977],
						[77.50820595703952, 12.929118916469594],
						[77.50760850090677, 12.92901189344777],
						[77.50697971607337, 12.929159025964125],
						[77.50619937405123, 12.92951581253417],
						[77.50557066895522, 12.929596075281987],
						[77.50545472783203, 12.929720849652417],
						[77.50538787568934, 12.929671861958026],
						[77.50531201071033, 12.929627264322402],
						[77.50526747173146, 12.929604947842282],
						[77.5051247543756, 12.929538075649512],
						[77.50495526087684, 12.929466721128353],
						[77.50478585462966, 12.929399851713766],
						[77.50461198545644, 12.929341900718837],
						[77.5044335658332, 12.929288382996605],
						[77.50433993698357, 12.929261625977405],
						[77.50424630805713, 12.929243744524442],
						[77.50411251481307, 12.929212547398214],
						[77.50397872141218, 12.929176869352437],
						[77.50384947806305, 12.92913227710038],
						[77.50372460977292, 12.929078762210757],
						[77.50359974134619, 12.929020852625237],
						[77.5035417260461, 12.928989612465987],
						[77.50352396268285, 12.928976259243624],
						[77.50343033308707, 12.92889599032914],
						[77.50334107863455, 12.928815674425032],
						[77.50325637434447, 12.928726530642757],
						[77.50318059545319, 12.92863290663076],
						[77.5031092792451, 12.928530278220146],
						[77.50304233822396, 12.928432169871414],
						[77.50302002454154, 12.928392056510015],
						[77.50301556180453, 12.928378743015942],
						[77.5028952428488, 12.928218167117647],
						[77.50277483626141, 12.928057673432317],
						[77.50264550404424, 12.927906054929199],
						[77.50258748825917, 12.927839141026727],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000016",
			properties: {
				AREA_SQ_KM: 11.461969464154835,
				ASS_CONST1: "Rajarajeswari Nagar",
				ASS_CONST_: "154",
				LAT: 12.9187572603313,
				LON: 77.5221415116967,
				LOST_AREA_SQ_KM: 0.6838123412451949,
				OBJECTID: 23,
				POP_F: 11190.0,
				POP_M: 12092.0,
				POP_SC: 2605.0,
				POP_ST: 306.0,
				POP_TOTAL: 23282.0,
				RESERVATIO: "General",
				WARD_NAME: "Rajarajeshwari Nagar",
				WARD_NO: 160.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.55692614190632, 13.04378021868877],
						[77.55617701713535, 13.04397645264181],
						[77.55541004468711, 13.044061135580174],
						[77.55469659559543, 13.044056710114589],
						[77.55367989883436, 13.043775744231192],
						[77.55275686952989, 13.043521579145633],
						[77.55174461621478, 13.043142567682375],
						[77.54989414518458, 13.042620903747197],
						[77.54853408747204, 13.042148229847559],
						[77.54668356410626, 13.041515014276019],
						[77.54556882702462, 13.041189507650486],
						[77.54555098274507, 13.041158276537889],
						[77.54545730023142, 13.041127072652793],
						[77.54532801648624, 13.04110035118379],
						[77.54513181631305, 13.04106908671075],
						[77.54496238236422, 13.041020057565444],
						[77.5448107925613, 13.040975431570729],
						[77.54448968042941, 13.040703466559973],
						[77.54388769114271, 13.040181763977104],
						[77.54351313008401, 13.039789372008666],
						[77.54300035906542, 13.039321175309555],
						[77.54293790266246, 13.039280964008341],
						[77.54292005796958, 13.039236449287579],
						[77.54292005796958, 13.03921859318894],
						[77.54292898031636, 13.039205224607414],
						[77.54335261603414, 13.038808375775316],
						[77.54368256619465, 13.03840257876654],
						[77.54430240104696, 13.03777829586244],
						[77.54498022673626, 13.036672389853683],
						[77.5455955934387, 13.03543277346909],
						[77.54610389169284, 13.034527568536777],
						[77.54653197660076, 13.033644697025641],
						[77.54760218181008, 13.031638080707086],
						[77.54795888548158, 13.03095144177125],
						[77.54834235379901, 13.030264722853111],
						[77.54872135987566, 13.029586932396189],
						[77.54896216321761, 13.029172248718723],
						[77.5494526902034, 13.029181115003565],
						[77.54973364015427, 13.029163342359253],
						[77.55003234551931, 13.029091916650616],
						[77.55025530254007, 13.029020612632719],
						[77.55045149385988, 13.02904737727456],
						[77.5507056761355, 13.029225745477076],
						[77.55102230983267, 13.029841098642619],
						[77.55133885517898, 13.030336036513795],
						[77.55186505847625, 13.031018316837306],
						[77.55277471267678, 13.031740674420101],
						[77.55359960529178, 13.032275798700836],
						[77.55381809455888, 13.032240071749271],
						[77.55404996561471, 13.032182134724184],
						[77.55414810176948, 13.032119697124262],
						[77.55430413983008, 13.031896692189662],
						[77.55446918658102, 13.0316069284693],
						[77.55467875275079, 13.031321479880038],
						[77.55478572231173, 13.03109411257342],
						[77.55485709360622, 13.030813178447293],
						[77.5549552290628, 13.030429687478797],
						[77.55509788392892, 13.029948123954629],
						[77.55520940124994, 13.02978308434125],
						[77.55549033694834, 13.029417513523736],
						[77.55562853046744, 13.029207878141762],
						[77.55570882257402, 13.029060733228432],
						[77.55570882257402, 13.028944803441728],
						[77.55575334181123, 13.028797650025787],
						[77.55586485848785, 13.028730771523469],
						[77.5560030515507, 13.02871297233136],
						[77.5560788827358, 13.028677228103616],
						[77.55628844593177, 13.02862821238398],
						[77.55632413111186, 13.028391872323564],
						[77.55654707576963, 13.028360669949533],
						[77.55714016415162, 13.0283919138782],
						[77.55737202841684, 13.028365110748059],
						[77.55746124049368, 13.028289300980745],
						[77.55761727399664, 13.02819568251848],
						[77.55788482197543, 13.028110917937628],
						[77.55769310410247, 13.027495586564395],
						[77.5584065343454, 13.027272678870148],
						[77.55853589065386, 13.027620437497221],
						[77.55878559426671, 13.028195710165457],
						[77.55882573920955, 13.028284861724403],
						[77.55887471776981, 13.028405235379427],
						[77.5592626971888, 13.029319393668224],
						[77.55939205252055, 13.029631534652108],
						[77.55949455711405, 13.029881217937689],
						[77.55951239920107, 13.029916931119429],
						[77.55984685035169, 13.030755188964916],
						[77.55992267896647, 13.030946909721798],
						[77.55992713947165, 13.030960323623908],
						[77.55978886372968, 13.031138650254015],
						[77.5586562382431, 13.032766265505828],
						[77.5582772653515, 13.033314716596962],
						[77.55754144384012, 13.034376032162776],
						[77.55751030710606, 13.034433956941522],
						[77.55738094962769, 13.03467922485925],
						[77.55737648902235, 13.034683666739912],
						[77.5573452647801, 13.034759443557911],
						[77.55733634356645, 13.034777298558721],
						[77.55734080417335, 13.034808482849867],
						[77.55734080417335, 13.034915533956077],
						[77.55735418599305, 13.03507611262828],
						[77.55735418599305, 13.035080511997007],
						[77.55741663446403, 13.035325764081218],
						[77.55743893748105, 13.035410519916516],
						[77.55748354350193, 13.035593316226851],
						[77.55752806204254, 13.035798450656099],
						[77.55755482563615, 13.035999101583428],
						[77.55754590443898, 13.036110552001837],
						[77.55755928623445, 13.03615959435258],
						[77.55751476770612, 13.036667941089842],
						[77.55739433144262, 13.037711412642816],
						[77.55738094962769, 13.038019071846893],
						[77.55734526478011, 13.038567536116616],
						[77.55780007082065, 13.038567508028933],
						[77.5578580584597, 13.038567559588854],
						[77.55790266431573, 13.038571965566517],
						[77.55792496723717, 13.03857201193189],
						[77.55814344817283, 13.038580967667937],
						[77.558228199071, 13.038594307778416],
						[77.55855819345076, 13.03864783499435],
						[77.5588791783162, 13.038696870554958],
						[77.55919132866673, 13.038808364832935],
						[77.55935190777821, 13.038866271299558],
						[77.55961053062943, 13.03899112914124],
						[77.5599137579556, 13.039142735520123],
						[77.56023919947634, 13.039352354622746],
						[77.56037747466904, 13.039463786107671],
						[77.56056026704317, 13.039611020023017],
						[77.56070746279337, 13.039767052705997],
						[77.56071192326768, 13.0397670101783],
						[77.56072084421574, 13.03978038268559],
						[77.56081442666782, 13.039874033417528],
						[77.56082334760782, 13.039883006346352],
						[77.5610106871856, 13.040105944730286],
						[77.56061379279264, 13.040507251088654],
						[77.56043992147498, 13.040467160212607],
						[77.56033733027563, 13.040458217298387],
						[77.56008316958138, 13.040467114514868],
						[77.55992713947165, 13.040502768019484],
						[77.55980670577038, 13.040534022623017],
						[77.55971749553882, 13.040600866640126],
						[77.55965959631176, 13.040676734221492],
						[77.55958822803952, 13.040797066207881],
						[77.55947671502422, 13.04111369299328],
						[77.5593474472504, 13.041416950541848],
						[77.55899961300268, 13.041693389945399],
						[77.55829956819926, 13.0421303703591],
						[77.55811222414175, 13.042295371174486],
						[77.55773771002337, 13.042549551630387],
						[77.55745231928917, 13.042736797682451],
						[77.55740325265171, 13.042736832970496],
						[77.5573452647801, 13.042710037568227],
						[77.55725614007495, 13.042656541570063],
						[77.55715800660909, 13.042616411382403],
						[77.55710447922827, 13.042616402769992],
						[77.55709555799568, 13.042616401333316],
						[77.55708217614547, 13.04262532786795],
						[77.55707325491113, 13.042638697899633],
						[77.55699305123359, 13.043102464323768],
						[77.55692614190632, 13.04378021868877],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000017",
			properties: {
				AREA_SQ_KM: 2.054696711178278,
				ASS_CONST1: "Rajarajeswari Nagar",
				ASS_CONST_: "154",
				LAT: 13.0366283169315,
				LON: 77.5524237413753,
				LOST_AREA_SQ_KM: 0.08987008490582635,
				OBJECTID: 24,
				POP_F: 16545.0,
				POP_M: 18629.0,
				POP_SC: 3344.0,
				POP_ST: 736.0,
				POP_TOTAL: 35174.0,
				RESERVATIO: "General",
				WARD_NAME: "J P Park",
				WARD_NO: 17.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				geometries: [
					{
						coordinates: [
							[77.55387164241093, 13.019166000129077],
							[77.55386272420883, 13.019157081926968],
						],
						type: "LineString",
					},
					{
						coordinates: [
							[
								[77.54896216321761, 13.029172248718723],
								[77.54888186633724, 13.029172236065628],
								[77.54930994077142, 13.028200110482183],
								[77.54997435388036, 13.026692941614344],
								[77.5508216586365, 13.025043102973232],
								[77.55157965296803, 13.023567144234127],
								[77.55266319296267, 13.021293017719575],
								[77.55339449789076, 13.019928518675984],
								[77.55353278142871, 13.019665428022927],
								[77.55354616369765, 13.019652017597934],
								[77.5538716234905, 13.0191659829682],
								[77.55393407387878, 13.01925517165061],
								[77.55426408075205, 13.019736735243038],
								[77.5543264434591, 13.019830405233733],
								[77.55465198847855, 13.019825906552798],
								[77.5551870977945, 13.019843806256379],
								[77.55530744893728, 13.019843770943822],
								[77.55546803354814, 13.019843786906106],
								[77.55555715965835, 13.019883898011425],
								[77.55559730574399, 13.019928536674623],
								[77.55570882257402, 13.020173715460517],
								[77.55589608313767, 13.020673209731074],
								[77.55598529637547, 13.020905062302496],
								[77.5562929065799, 13.021618516147687],
								[77.55660060341351, 13.022394426374893],
								[77.55671211925713, 13.022697573313188],
								[77.55682800815491, 13.023014216024052],
								[77.5569261419063, 13.023281783926057],
								[77.55709109737913, 13.023723199621342],
								[77.5571936915156, 13.024044290530753],
								[77.55733634356645, 13.024458925279273],
								[77.557407713256, 13.024704240562423],
								[77.557519228306, 13.025056473548434],
								[77.55756820743058, 13.025225922356134],
								[77.557648498164, 13.025493494288346],
								[77.5577154070651, 13.025725312618183],
								[77.55776447356749, 13.025836803053204],
								[77.55800525771801, 13.026385324924737],
								[77.5584065343454, 13.027272678870148],
								[77.55769310410247, 13.027495586564395],
								[77.55788482197543, 13.028110917937628],
								[77.55761727399664, 13.02819568251848],
								[77.55746124049368, 13.028289300980745],
								[77.55737202841684, 13.028365110748059],
								[77.55714016415162, 13.0283919138782],
								[77.55654707576963, 13.028360669949533],
								[77.55632413111186, 13.028391872323564],
								[77.55628844593177, 13.02862821238398],
								[77.5560788827358, 13.028677228103616],
								[77.5560030515507, 13.02871297233136],
								[77.55586485848785, 13.028730771523469],
								[77.55575334181123, 13.028797650025787],
								[77.55570882257402, 13.028944803441728],
								[77.55570882257402, 13.029060733228432],
								[77.55562853046744, 13.029207878141762],
								[77.55549033694834, 13.029417513523736],
								[77.55520940124994, 13.02978308434125],
								[77.55509788392892, 13.029948123954629],
								[77.5549552290628, 13.030429687478797],
								[77.55485709360622, 13.030813178447293],
								[77.55478572231173, 13.03109411257342],
								[77.55467875275079, 13.031321479880038],
								[77.55446918658102, 13.0316069284693],
								[77.55430413983008, 13.031896692189662],
								[77.55414810176948, 13.032119697124262],
								[77.55404996561471, 13.032182134724184],
								[77.55381809455888, 13.032240071749271],
								[77.55359960529178, 13.032275798700836],
								[77.55277471267678, 13.031740674420101],
								[77.55186505847625, 13.031018316837306],
								[77.55133885517898, 13.030336036513795],
								[77.55102230983267, 13.029841098642619],
								[77.5507056761355, 13.029225745477076],
								[77.55045149385988, 13.02904737727456],
								[77.55025530254007, 13.029020612632719],
								[77.55003234551931, 13.029091916650616],
								[77.54973364015427, 13.029163342359253],
								[77.5494526902034, 13.029181115003565],
								[77.54896216321761, 13.029172248718723],
							],
						],
						type: "Polygon",
					},
				],
				type: "GeometryCollection",
			},
			id: "00000000000000000018",
			properties: {
				AREA_SQ_KM: 0.7788427056873904,
				ASS_CONST1: "Rajarajeswari Nagar",
				ASS_CONST_: "154",
				LAT: 13.0260290008921,
				LON: 77.5538567686232,
				LOST_AREA_SQ_KM: 0.025882118210078218,
				OBJECTID: 25,
				POP_F: 17111.0,
				POP_M: 18861.0,
				POP_SC: 3555.0,
				POP_ST: 560.0,
				POP_TOTAL: 35972.0,
				RESERVATIO: "General",
				WARD_NAME: "Yeshwanthpura",
				WARD_NO: 37.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52219864287851, 13.045889350851649],
						[77.52214063704133, 13.045800181208762],
						[77.52149854632131, 13.044783499503769],
						[77.520901072362, 13.043869405169671],
						[77.52033028013767, 13.043040029658634],
						[77.51984426457757, 13.042259726779227],
						[77.51930023966041, 13.041452628328395],
						[77.51873836365931, 13.04059645112058],
						[77.51838611796403, 13.039936534400196],
						[77.51833257232336, 13.039726916298289],
						[77.5182523413081, 13.039191854178664],
						[77.51824341702591, 13.038505092409116],
						[77.51824341702591, 13.037755976058103],
						[77.51829250056926, 13.03672151176308],
						[77.51829250056925, 13.036141806559279],
						[77.51826126558957, 13.035932246063892],
						[77.51800263612922, 13.035018072621314],
						[77.51785101039795, 13.034153013745218],
						[77.51783316177003, 13.033332591029986],
						[77.51788670764539, 13.033033836571313],
						[77.51856451575853, 13.031250208008188],
						[77.51886321551098, 13.030617002650692],
						[77.51928685336, 13.029582436795934],
						[77.51958563871061, 13.028761997023883],
						[77.5195990249759, 13.028726337547274],
						[77.51971048963375, 13.028458782863332],
						[77.5199423427721, 13.0278924557128],
						[77.52028565953363, 13.027031841027354],
						[77.52059336636341, 13.026233666648523],
						[77.52086091402036, 13.025578177933408],
						[77.52113292307708, 13.024878147017704],
						[77.52138262139488, 13.024200320754334],
						[77.52150747034848, 13.023963953367828],
						[77.52156547650814, 13.023883718291087],
						[77.5216903252613, 13.023749982081878],
						[77.52190878837753, 13.02356715021073],
						[77.52284965357353, 13.022978533441588],
						[77.52299681069647, 13.02288934751484],
						[77.5238128206139, 13.022519240812167],
						[77.52468682925327, 13.022211542353812],
						[77.52566336630534, 13.02188156604354],
						[77.52663543316832, 13.021582846770247],
						[77.52672020757366, 13.021551603287511],
						[77.5268093562352, 13.021515914990005],
						[77.52707688928712, 13.021417823472639],
						[77.52750049658088, 13.021270733725732],
						[77.52778141331169, 13.021185968969348],
						[77.52812925567743, 13.021056673906468],
						[77.52832102344858, 13.021020965116383],
						[77.52840124737013, 13.020994243382747],
						[77.52843694131049, 13.02099864993357],
						[77.52860639988936, 13.021355391080885],
						[77.5288248497346, 13.021814728787835],
						[77.52903446320869, 13.022269499881082],
						[77.52922176774348, 13.022666354011259],
						[77.52942236959599, 13.023067736491562],
						[77.52959628840388, 13.023451179515849],
						[77.52979251539162, 13.023883746664396],
						[77.52996643363377, 13.024258309454941],
						[77.5301581108251, 13.024717568492699],
						[77.53038556850629, 13.025199180347176],
						[77.53059509162183, 13.025676301310485],
						[77.53080916348372, 13.026126649682602],
						[77.53100985002075, 13.026608262311008],
						[77.53276676067706, 13.025711969974765],
						[77.53401529414441, 13.024998498993344],
						[77.53787234664748, 13.02287595376754],
						[77.53789019213299, 13.023937245144966],
						[77.5380151104532, 13.024311762597677],
						[77.53827815579483, 13.025056440185894],
						[77.53839406350905, 13.025457779634614],
						[77.53840744756202, 13.025845692905467],
						[77.53840744756202, 13.026131095916512],
						[77.53866165679182, 13.026220281648142],
						[77.53890694279119, 13.026300581708123],
						[77.53906746312119, 13.026362968132432],
						[77.53915214078432, 13.026501197647056],
						[77.53939296441322, 13.027272673006133],
						[77.53988790654246, 13.028503361295119],
						[77.53992805816891, 13.028837814760742],
						[77.54064151259071, 13.028418661818234],
						[77.54132819511992, 13.028021754352032],
						[77.5420907140289, 13.027629386634002],
						[77.54287107252377, 13.027174572039543],
						[77.5436112753477, 13.026684081844678],
						[77.54365579932976, 13.026657260875785],
						[77.54421326609932, 13.02634964318439],
						[77.54608158644805, 13.025315097936847],
						[77.54790089278342, 13.024316282264943],
						[77.5500412673073, 13.023058819494974],
						[77.55157965296803, 13.022068866039271],
						[77.55180706777371, 13.021819149704514],
						[77.55184721518631, 13.02174338308059],
						[77.55224405279037, 13.021047766354775],
						[77.55259636854812, 13.020285220360568],
						[77.55313586086548, 13.019125854813895],
						[77.55337219407853, 13.018269723513345],
						[77.5535817622746, 13.017533996897988],
						[77.5537289670824, 13.016463803053904],
						[77.55382701604924, 13.015906390968215],
						[77.55393853461949, 13.01564778928546],
						[77.55396083832039, 13.015513979062098],
						[77.55399652423272, 13.015380213326637],
						[77.55400544570904, 13.015331139662472],
						[77.55486601501487, 13.015429306325599],
						[77.55493738625881, 13.01542931261742],
						[77.55501313069165, 13.015447125093472],
						[77.55520047986829, 13.015482761575868],
						[77.55542788741673, 13.015576398556135],
						[77.55560622709443, 13.015665589556777],
						[77.55566867652784, 13.01571466977017],
						[77.55581579116365, 13.015821668315555],
						[77.55582025183035, 13.015812742056967],
						[77.55583363382945, 13.015817184952878],
						[77.55579794849504, 13.015852888471029],
						[77.55570436190293, 13.015964359909558],
						[77.55564191248915, 13.016044651228482],
						[77.55551701355849, 13.016227485848777],
						[77.55536543785075, 13.016454826384996],
						[77.55535205579643, 13.016477120315846],
						[77.55532529168305, 13.016526193083335],
						[77.55503543418128, 13.017047929728488],
						[77.55473674198565, 13.017583039298334],
						[77.55442011865311, 13.018171618311316],
						[77.55424623783937, 13.018506036326785],
						[77.55416148396583, 13.018666580848656],
						[77.55386270200364, 13.019157098325236],
						[77.5538716234905, 13.0191659829682],
						[77.55354616369765, 13.019652017597934],
						[77.55353278142871, 13.019665428022927],
						[77.55339449789076, 13.019928518675984],
						[77.55266319296267, 13.021293017719575],
						[77.55157965296803, 13.023567144234127],
						[77.5508216586365, 13.025043102973232],
						[77.54997435388036, 13.026692941614344],
						[77.54930994077142, 13.028200110482183],
						[77.54888186633724, 13.029172236065628],
						[77.54896216321761, 13.029172248718723],
						[77.54872135987566, 13.029586932396189],
						[77.54834235379901, 13.030264722853111],
						[77.54795888548158, 13.03095144177125],
						[77.54760218181008, 13.031638080707086],
						[77.54653197660076, 13.033644697025641],
						[77.54610389169284, 13.034527568536777],
						[77.5455955934387, 13.03543277346909],
						[77.54498022673626, 13.036672389853683],
						[77.54430240104696, 13.03777829586244],
						[77.54368256619465, 13.03840257876654],
						[77.54335261603414, 13.038808375775316],
						[77.54292898031636, 13.039205224607414],
						[77.54292005796958, 13.03921859318894],
						[77.54260348915321, 13.03894210920991],
						[77.54205502437422, 13.038465023733943],
						[77.54199256745156, 13.038393672687416],
						[77.54190789190497, 13.038179603302074],
						[77.54155116922956, 13.036476251773735],
						[77.54073511160011, 13.03249423897764],
						[77.5359282216842, 13.036070436070666],
						[77.53151375188371, 13.03933455241977],
						[77.52758973211706, 13.042192780694817],
						[77.52219864287851, 13.045889350851649],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000019",
			properties: {
				AREA_SQ_KM: 5.262642066449498,
				ASS_CONST1: "Rajarajeswari Nagar",
				ASS_CONST_: "154",
				LAT: 13.0319047414786,
				LON: 77.5317054301638,
				LOST_AREA_SQ_KM: 0.26061748085461783,
				OBJECTID: 26,
				POP_F: 13483.0,
				POP_M: 16281.0,
				POP_SC: 3761.0,
				POP_ST: 942.0,
				POP_TOTAL: 29764.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "HMT Ward",
				WARD_NO: 38.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5268093562352, 13.021515914990005],
						[77.52658197889266, 13.021368780406982],
						[77.52632774266516, 13.021163679509856],
						[77.52561428593849, 13.02068211943345],
						[77.52553406005265, 13.020633032086248],
						[77.52432567814839, 13.019843787398369],
						[77.52311273397986, 13.019112452903784],
						[77.52304589219162, 13.019072381563554],
						[77.52300127265144, 13.019063401495147],
						[77.52295227862642, 13.01903221741183],
						[77.52284072965153, 13.018987646305805],
						[77.52222095280801, 13.018430267979202],
						[77.52200686479442, 13.018314311698113],
						[77.5219444843535, 13.018269714064346],
						[77.52193109836384, 13.018193948427578],
						[77.5219489463497, 13.018109178453415],
						[77.5220291747615, 13.016954267235306],
						[77.52215402300638, 13.015942102726614],
						[77.52226111067012, 13.015433750825204],
						[77.52250188256593, 13.015464931693934],
						[77.52317520127006, 13.015558595146413],
						[77.52389759712108, 13.015674561157393],
						[77.52441929058448, 13.01567901932799],
						[77.52518620975624, 13.01558533952091],
						[77.52605128418216, 13.01548279565773],
						[77.52640805547836, 13.015750295335803],
						[77.52699666443206, 13.015714617451446],
						[77.52774580665138, 13.015589795624312],
						[77.52829425296709, 13.015442639786356],
						[77.52827640597593, 13.01534458098408],
						[77.52824517373466, 13.01529106244767],
						[77.52808463812958, 13.015094837002431],
						[77.52789741920876, 13.01484514197583],
						[77.52770565072383, 13.014568665080736],
						[77.5275763467911, 13.014359123726747],
						[77.52749157302341, 13.014211938801441],
						[77.52735780706342, 13.014060306722396],
						[77.5271972702067, 13.013873052630448],
						[77.52706350390082, 13.01375263946074],
						[77.52696543183961, 13.013614382587834],
						[77.52683612706596, 13.013462795500166],
						[77.52674689093895, 13.013333505460217],
						[77.52674242913076, 13.013235408559035],
						[77.52671574576442, 13.013115018747751],
						[77.52671574576442, 13.012999028592409],
						[77.52671574576442, 13.012820688560451],
						[77.52671128395502, 13.012682491535015],
						[77.52671128395502, 13.012566501805164],
						[77.52671128395502, 13.0124684496092],
						[77.5267335930003, 13.012339141034149],
						[77.52674242913076, 13.012298951967981],
						[77.52674689093895, 13.012241044435122],
						[77.52676473816994, 13.01199130474224],
						[77.5267558145548, 13.011897649261263],
						[77.52676473816994, 13.011826332959657],
						[77.52681827984615, 13.01171483939652],
						[77.52692081383553, 13.011581059434175],
						[77.52700112623029, 13.011469571435676],
						[77.52724188810241, 13.01119313643591],
						[77.52744258093074, 13.010863187772031],
						[77.52760311744146, 13.010515337414914],
						[77.52780818391382, 13.010769508699578],
						[77.52854839731694, 13.011509720012787],
						[77.52909683976479, 13.012174143229721],
						[77.52957397991975, 13.012874177814785],
						[77.53001542463991, 13.01351634397716],
						[77.53043018515197, 13.014292185237036],
						[77.53065309315096, 13.014782668317968],
						[77.53104108150318, 13.015843972526442],
						[77.53134429763477, 13.016865125399718],
						[77.53166089780528, 13.017921952685903],
						[77.53190611142414, 13.018885115759566],
						[77.53203541043665, 13.019585136820236],
						[77.53218701727832, 13.020410092693389],
						[77.53232970073991, 13.021154758595399],
						[77.53237877821046, 13.021355419011824],
						[77.53252592301311, 13.021935060624573],
						[77.53256161565949, 13.022037700977785],
						[77.53257946197849, 13.022073324394205],
						[77.53259730829468, 13.022086696312098],
						[77.5326239902818, 13.022095667311595],
						[77.53264629816789, 13.022104512246754],
						[77.53299858722391, 13.022028724162816],
						[77.53372100820239, 13.021886032970777],
						[77.53385476668126, 13.021859319774062],
						[77.53435865594118, 13.021783469531377],
						[77.53468417071531, 13.021743378812513],
						[77.534942762277, 13.021721084140344],
						[77.53515236447807, 13.021667519293514],
						[77.53529504421947, 13.02158733397201],
						[77.53566963236356, 13.021391101864008],
						[77.53603074746313, 13.021185935663913],
						[77.53687798126998, 13.02069990458427],
						[77.53754684100089, 13.020311989549073],
						[77.53775643840127, 13.020187095925166],
						[77.5380685593564, 13.01999541867032],
						[77.53889355879703, 13.019518305251431],
						[77.5395088709906, 13.019165993521513],
						[77.53958917449717, 13.019121418066218],
						[77.53974969386363, 13.019027778026272],
						[77.53977646164873, 13.019023297757489],
						[77.53980314195113, 13.019027782808752],
						[77.53988790654246, 13.01915712280319],
						[77.54015987011402, 13.019607408206944],
						[77.54023125060267, 13.019727820221084],
						[77.54054782602842, 13.020258497944061],
						[77.54110522057272, 13.021208292086708],
						[77.54156901413744, 13.021948457012071],
						[77.5423626723283, 13.023357534846083],
						[77.54267932928987, 13.023897120699447],
						[77.54269271283815, 13.023914933631232],
						[77.54324563569948, 13.024829098500478],
						[77.54381193928606, 13.025689668918869],
						[77.54421326609932, 13.02634964318439],
						[77.54365579932976, 13.026657260875785],
						[77.5436112753477, 13.026684081844678],
						[77.54287107252377, 13.027174572039543],
						[77.5420907140289, 13.027629386634002],
						[77.54132819511992, 13.028021754352032],
						[77.54064151259071, 13.028418661818234],
						[77.53992805816891, 13.028837814760742],
						[77.53988790654246, 13.028503361295119],
						[77.53939296441322, 13.027272673006133],
						[77.53915214078432, 13.026501197647056],
						[77.53906746312119, 13.026362968132432],
						[77.53890694279119, 13.026300581708123],
						[77.53866165679182, 13.026220281648142],
						[77.53840744756202, 13.026131095916512],
						[77.53840744756202, 13.025845692905467],
						[77.53839406350905, 13.025457779634614],
						[77.53827815579483, 13.025056440185894],
						[77.5380151104532, 13.024311762597677],
						[77.53789019213299, 13.023937245144966],
						[77.53787234664748, 13.02287595376754],
						[77.53401529414441, 13.024998498993344],
						[77.53276676067706, 13.025711969974765],
						[77.53100985002075, 13.026608262311008],
						[77.53080916348372, 13.026126649682602],
						[77.53059509162183, 13.025676301310485],
						[77.53038556850629, 13.025199180347176],
						[77.5301581108251, 13.024717568492699],
						[77.52996643363377, 13.024258309454941],
						[77.52979251539162, 13.023883746664396],
						[77.52959628840388, 13.023451179515849],
						[77.52942236959599, 13.023067736491562],
						[77.52922176774348, 13.022666354011259],
						[77.52903446320869, 13.022269499881082],
						[77.5288248497346, 13.021814728787835],
						[77.52860639988936, 13.021355391080885],
						[77.52843694131049, 13.02099864993357],
						[77.52840124737013, 13.020994243382747],
						[77.52832102344858, 13.021020965116383],
						[77.52812925567743, 13.021056673906468],
						[77.52778141331169, 13.021185968969348],
						[77.52750049658088, 13.021270733725732],
						[77.52707688928712, 13.021417823472639],
						[77.5268093562352, 13.021515914990005],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000001a",
			properties: {
				AREA_SQ_KM: 1.5000859881095456,
				ASS_CONST1: "Rajarajeswari Nagar",
				ASS_CONST_: "154",
				LAT: 13.0206027348153,
				LON: 77.5324717737587,
				LOST_AREA_SQ_KM: 0.03811020617265887,
				OBJECTID: 27,
				POP_F: 12153.0,
				POP_M: 13425.0,
				POP_SC: 4568.0,
				POP_ST: 704.0,
				POP_TOTAL: 25578.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Lakshmi Devi Nagar",
				WARD_NO: 42.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52226111067012, 13.015433750825204],
						[77.52238149668165, 13.01483178804578],
						[77.52256426270156, 13.01426993870779],
						[77.52286303945525, 13.013485108017305],
						[77.52301019656088, 13.013025795027083],
						[77.52302804437764, 13.012976730216383],
						[77.52301912046961, 13.012918776941364],
						[77.52294781666953, 13.012798378280111],
						[77.52292104492447, 13.01275378776785],
						[77.52292550688242, 13.012620078677026],
						[77.52291212100805, 13.012633399219917],
						[77.52288981121394, 13.012562033306919],
						[77.52283180572881, 13.012459527960589],
						[77.52276050181143, 13.01245063367877],
						[77.5226935723269, 13.012450575387227],
						[77.52247511071606, 13.012437210638078],
						[77.52232349093762, 13.012432782213073],
						[77.52207379468254, 13.012428335414436],
						[77.52176162984873, 13.01239265638817],
						[77.52174378183348, 13.012392652265307],
						[77.52173931982924, 13.01240157668517],
						[77.5217215593008, 13.01245504328728],
						[77.52149854632131, 13.012383678479226],
						[77.5214762362503, 13.012383716417167],
						[77.52145401366542, 13.012406007530403],
						[77.52137815937648, 13.012481834606886],
						[77.5212399242102, 13.012660186234145],
						[77.52120422801345, 13.012651209298378],
						[77.52116861929619, 13.01262447193737],
						[77.52115523321534, 13.012620027632556],
						[77.52110168887617, 13.012597766126994],
						[77.5210659926361, 13.012584391137722],
						[77.5208921482873, 13.012530849194697],
						[77.5207092920931, 13.012495151698692],
						[77.52042389585796, 13.012477327977019],
						[77.52024112640353, 13.012481820358598],
						[77.52002257259724, 13.012517517179617],
						[77.51991557032059, 13.01255759544806],
						[77.5197684092167, 13.012611159901224],
						[77.51961678583528, 13.012655711504259],
						[77.51945186346309, 13.012677972012987],
						[77.51911738091096, 13.012700277302082],
						[77.51888552609374, 13.01270479954559],
						[77.5185778146511, 13.012713657747451],
						[77.51857344001554, 13.012651269430808],
						[77.51863582230251, 13.012579877504322],
						[77.5186536708047, 13.012513049353172],
						[77.51862689805036, 13.012432757550762],
						[77.518609049544, 13.012348069997657],
						[77.51860458741695, 13.012223157026146],
						[77.51864474655395, 13.012053757740464],
						[77.51868044355274, 13.011964513075716],
						[77.5187205151704, 13.011879792631092],
						[77.5187428257811, 13.011750488639576],
						[77.51876513638746, 13.011639035392813],
						[77.51885429127664, 13.011581102902477],
						[77.5188989124413, 13.011469612020763],
						[77.51891676090227, 13.011389331737242],
						[77.51895245781583, 13.011273354626708],
						[77.51899252933782, 13.011202087075242],
						[77.51901037778413, 13.011117322729346],
						[77.51901037778413, 13.011023672005255],
						[77.51901037778413, 13.010930021355293],
						[77.51901930200624, 13.010823006581697],
						[77.51903715044836, 13.010724962393379],
						[77.51904161255842, 13.010649120406452],
						[77.5190505367781, 13.01055098798842],
						[77.51898360511362, 13.010493065835881],
						[77.5189033745568, 13.010417251209937],
						[77.51885875339389, 13.010359291326301],
						[77.51883206818026, 13.010332556666459],
						[77.51876959850819, 13.010189824681115],
						[77.51872943941521, 13.010109574564373],
						[77.51868044355274, 13.010006992111947],
						[77.51861797379753, 13.009882110954713],
						[77.51856897788713, 13.00980186322091],
						[77.51852881872342, 13.009726097566421],
						[77.51849312167714, 13.009614589300766],
						[77.51840842864023, 13.00943180199462],
						[77.51838165582828, 13.009360394578971],
						[77.51831481126376, 13.009244528798574],
						[77.51828803842983, 13.009186573865746],
						[77.51823895488458, 13.009101838355503],
						[77.5181007159091, 13.008941287378246],
						[77.51808732946776, 13.008910024832582],
						[77.51791348057364, 13.008758438137244],
						[77.51784208608434, 13.008682622775828],
						[77.5174318293835, 13.008424013165659],
						[77.51734267338131, 13.008357166172953],
						[77.51731590031896, 13.008325943750055],
						[77.51729813910092, 13.008299211785348],
						[77.5172802903864, 13.00827238934812],
						[77.5172802903864, 13.008259023380178],
						[77.5172802903864, 13.008218925485412],
						[77.51730251378541, 13.008134203630421],
						[77.51737390861278, 13.00792010682446],
						[77.51738283296307, 13.007790847505763],
						[77.5174318293835, 13.007527719005722],
						[77.5174318293835, 13.007367242194148],
						[77.51743629155645, 13.007291402719797],
						[77.51743629155645, 13.007220089388353],
						[77.51745414024654, 13.007113080543283],
						[77.5174318293835, 13.006899006422755],
						[77.51739621948717, 13.006809839405747],
						[77.51735605991013, 13.006778657503466],
						[77.51732482467378, 13.006725143882848],
						[77.51731590031896, 13.006667108388204],
						[77.51729367692253, 13.006613601163291],
						[77.51731143814128, 13.006569019743765],
						[77.51733821120469, 13.00651107889142],
						[77.5173649842618, 13.006470901590902],
						[77.51740960600969, 13.006390717387577],
						[77.51744967807427, 13.0063327323243],
						[77.51750768630002, 13.006261389900525],
						[77.51756560700272, 13.00620340897757],
						[77.5176057665059, 13.006167761899178],
						[77.51762807733489, 13.006123142716884],
						[77.51764592599494, 13.00607856562985],
						[77.5176548503239, 13.006011652794706],
						[77.51769046013942, 13.005266972373542],
						[77.51769046013942, 13.005249122770513],
						[77.51767269897975, 13.004687336128688],
						[77.5176993844649, 13.003978274450901],
						[77.51773508175985, 13.00387575614806],
						[77.5177797033628, 13.003679509109977],
						[77.51787332117891, 13.003474430522944],
						[77.51801602258051, 13.003251470513904],
						[77.51814533736888, 13.003055281647674],
						[77.51822110631743, 13.002867962729825],
						[77.51823895488458, 13.002658431872687],
						[77.51818549666774, 13.002529082246184],
						[77.5180338711798, 13.002381948505368],
						[77.5179401660024, 13.002288327728744],
						[77.51810964020245, 13.002248178103349],
						[77.51858227677917, 13.002261542187915],
						[77.51921108512019, 13.00231059376329],
						[77.5198798737088, 13.00235520287063],
						[77.52003149674006, 13.00282784942885],
						[77.52016080924082, 13.003389697647576],
						[77.52097683947778, 13.003278218721523],
						[77.52100807371292, 13.003568085650404],
						[77.52134246322304, 13.003523431971866],
						[77.52129346848439, 13.00324701728726],
						[77.52129346848439, 13.003073092613445],
						[77.52236811074336, 13.002778845355001],
						[77.52350958441728, 13.002480073204268],
						[77.52364781682864, 13.002439869494316],
						[77.52344274286199, 13.001650669413992],
						[77.52406251305588, 13.00171754976562],
						[77.52628321189792, 13.00070978535568],
						[77.52615836818056, 13.000442199846706],
						[77.52616720435566, 13.000437799495774],
						[77.52735334527918, 12.999880413617852],
						[77.52784387804017, 12.999693107439382],
						[77.52786172509917, 12.999800120098225],
						[77.52786172509917, 12.999893764469595],
						[77.52785726333468, 12.999982967395624],
						[77.5278483398052, 13.000098857485245],
						[77.52787064862761, 13.0002460108649],
						[77.52787064862761, 13.000361988922112],
						[77.52787511039158, 13.000500171848383],
						[77.52788403391897, 13.000660732186514],
						[77.52787957215536, 13.000812365765505],
						[77.52786618686348, 13.000955030166509],
						[77.5278304927441, 13.001124465264587],
						[77.52782603097839, 13.001231518641054],
						[77.52782603097839, 13.001325164136553],
						[77.5278483398052, 13.00141877037249],
						[77.52791071701141, 13.00147672318787],
						[77.5279954904669, 13.001530243208359],
						[77.52814264093837, 13.00159713520399],
						[77.52840570911329, 13.001699741730897],
						[77.52865539148102, 13.001842399960122],
						[77.52887392871703, 13.001993982198238],
						[77.52904338664518, 13.002118868067559],
						[77.5291414569158, 13.00226598977117],
						[77.52920392089779, 13.002368528629697],
						[77.52921284432098, 13.002493392520085],
						[77.52918607404928, 13.002604884775945],
						[77.52911914834249, 13.002725295311812],
						[77.52904338664518, 13.00288132213244],
						[77.52900323117548, 13.003037437088548],
						[77.52899430773584, 13.00313996467482],
						[77.52897199913369, 13.003313932867986],
						[77.52897199913369, 13.00346104394194],
						[77.52899876945574, 13.0035769438864],
						[77.52903000149018, 13.003634940072072],
						[77.52906569523334, 13.003679528006707],
						[77.52910130148068, 13.003724111720063],
						[77.52916822719799, 13.003790995976468],
						[77.5292350653914, 13.003862360099225],
						[77.52929306761382, 13.003915876958809],
						[77.5293555315126, 13.003964953652895],
						[77.52949821852529, 13.004067503739732],
						[77.52957844161693, 13.004161161378727],
						[77.52960075010019, 13.004201220082251],
						[77.52960075010019, 13.004263652311831],
						[77.52960075010019, 13.00430383660964],
						[77.52960521179632, 13.004339494477922],
						[77.52960075010019, 13.004384119013757],
						[77.52957397991975, 13.004473278850648],
						[77.52954720973301, 13.004642721303464],
						[77.52953391212156, 13.004772029168173],
						[77.52953391212156, 13.004896894237634],
						[77.52955613312929, 13.005008349830018],
						[77.52958290331392, 13.00511088543811],
						[77.52961413518806, 13.005204583029942],
						[77.52963644366432, 13.005275858548496],
						[77.52966767552375, 13.005342824002938],
						[77.52969881989044, 13.005436431337332],
						[77.52974343680539, 13.005507754004308],
						[77.52978359201389, 13.005574634931895],
						[77.52983267058282, 13.005646001592119],
						[77.52985935321834, 13.005699552977948],
						[77.52990397007036, 13.005744143440092],
						[77.52989950838595, 13.00578868230023],
						[77.52989504670134, 13.00578872464328],
						[77.52989058501659, 13.005891298748812],
						[77.52988612333165, 13.005975936327768],
						[77.52986827659015, 13.006248000868363],
						[77.52992181680625, 13.006667107803374],
						[77.53000203959925, 13.007010502933396],
						[77.53003327135832, 13.007304784051254],
						[77.53001096295986, 13.007385021516818],
						[77.53001988631978, 13.00750540690457],
						[77.53006896478678, 13.007625842699978],
						[77.53011358155659, 13.007683800563342],
						[77.53017595752114, 13.007737359409896],
						[77.53023395926394, 13.00777302794519],
						[77.53028749930797, 13.0078086525776],
						[77.53034095184314, 13.007839874981856],
						[77.53039003017165, 13.007969193449002],
						[77.53039003017166, 13.008062845015928],
						[77.53039895350183, 13.008129765267427],
						[77.5304123384958, 13.008232258491367],
						[77.5304168001601, 13.008325954188534],
						[77.53039895350183, 13.008379417054247],
						[77.53035433684393, 13.008606812515396],
						[77.5303275668408, 13.008664844365844],
						[77.53028749930797, 13.008709382153416],
						[77.5302607292891, 13.008745078944644],
						[77.53020272755995, 13.008816428605305],
						[77.53002434799947, 13.008874350904973],
						[77.52969881989044, 13.008927895324499],
						[77.52943129300206, 13.008936822924735],
						[77.52917715062398, 13.008968044604782],
						[77.52904784836316, 13.00896358354832],
						[77.52886054354211, 13.00894130387658],
						[77.52880700282665, 13.008941293813917],
						[77.5287624730294, 13.00895017192647],
						[77.52871785572982, 13.008976982785422],
						[77.52867770014525, 13.008999267107887],
						[77.52861978509416, 13.009088471130546],
						[77.52857962947499, 13.009150855019252],
						[77.52840570911329, 13.00930247163257],
						[77.52820055623226, 13.009485256378253],
						[77.52804456980701, 13.009645848998687],
						[77.52799102870767, 13.009757255434721],
						[77.52793748758313, 13.00984201499502],
						[77.52793748758313, 13.009957916253153],
						[77.52791071701141, 13.010060532018773],
						[77.52786172509917, 13.010198720172124],
						[77.52780372214723, 13.01030128683034],
						[77.52773688311315, 13.010403856008383],
						[77.52760311744146, 13.010515337414914],
						[77.52744258093074, 13.010863187772031],
						[77.52724188810241, 13.01119313643591],
						[77.52700112623029, 13.011469571435676],
						[77.52692081383553, 13.011581059434175],
						[77.52681827984615, 13.01171483939652],
						[77.52676473816994, 13.011826332959657],
						[77.5267558145548, 13.011897649261263],
						[77.52676473816994, 13.01199130474224],
						[77.52674689093895, 13.012241044435122],
						[77.52674242913076, 13.012298951967981],
						[77.5267335930003, 13.012339141034149],
						[77.52671128395502, 13.0124684496092],
						[77.52671128395502, 13.012566501805164],
						[77.52671128395502, 13.012682491535015],
						[77.52671574576442, 13.012820688560451],
						[77.52671574576442, 13.012999028592409],
						[77.52671574576442, 13.013115018747751],
						[77.52674242913076, 13.013235408559035],
						[77.52674689093895, 13.013333505460217],
						[77.52683612706596, 13.013462795500166],
						[77.52696543183961, 13.013614382587834],
						[77.52706350390082, 13.01375263946074],
						[77.5271972702067, 13.013873052630448],
						[77.52735780706342, 13.014060306722396],
						[77.52749157302341, 13.014211938801441],
						[77.5275763467911, 13.014359123726747],
						[77.52770565072383, 13.014568665080736],
						[77.52789741920876, 13.01484514197583],
						[77.52808463812958, 13.015094837002431],
						[77.52824517373466, 13.01529106244767],
						[77.52827640597593, 13.01534458098408],
						[77.52829425296709, 13.015442639786356],
						[77.52774580665138, 13.015589795624312],
						[77.52699666443206, 13.015714617451446],
						[77.52640805547836, 13.015750295335803],
						[77.52605128418216, 13.01548279565773],
						[77.52518620975624, 13.01558533952091],
						[77.52441929058448, 13.01567901932799],
						[77.52389759712108, 13.015674561157393],
						[77.52317520127006, 13.015558595146413],
						[77.52250188256593, 13.015464931693934],
						[77.52226111067012, 13.015433750825204],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000001b",
			properties: {
				AREA_SQ_KM: 1.5746193972498694,
				ASS_CONST1: "Rajarajeswari Nagar",
				ASS_CONST_: "154",
				LAT: 13.0076873581854,
				LON: 77.5238999866999,
				LOST_AREA_SQ_KM: 0.027796337790874405,
				OBJECTID: 28,
				POP_F: 11945.0,
				POP_M: 13425.0,
				POP_SC: 1403.0,
				POP_ST: 454.0,
				POP_TOTAL: 25370.0,
				RESERVATIO: "General",
				WARD_NAME: "Laggere",
				WARD_NO: 69.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5179401660024, 13.002288327728744],
						[77.51786439686704, 13.002212478093693],
						[77.51784654824122, 13.002123314711193],
						[77.51783762392726, 13.00200293893662],
						[77.51790901841937, 13.001695208346556],
						[77.5179000941103, 13.001414277810944],
						[77.51786439686704, 13.001222587203186],
						[77.51798924967636, 13.00079897674696],
						[77.5182523413081, 13.000321802504715],
						[77.51825680344892, 13.000317362776656],
						[77.51840842864023, 13.000205899467108],
						[77.51857344001554, 13.000112244596968],
						[77.5187919091093, 13.000018596409864],
						[77.5188453670416, 13.000014119449856],
						[77.51895245781583, 13.000063204402911],
						[77.51903715044836, 13.000058776946696],
						[77.51906838521532, 13.000032010032037],
						[77.51909069575791, 12.999956178411717],
						[77.51909953248142, 12.999853652768616],
						[77.51910399458907, 12.999742119890815],
						[77.51913969144395, 12.999626239060794],
						[77.51921108512019, 12.999501398047952],
						[77.51930916385982, 12.999380955794704],
						[77.51936262154942, 12.999300685888311],
						[77.51941616670602, 12.999198173931187],
						[77.51941616670602, 12.999095565034942],
						[77.51941616670602, 12.99901089120616],
						[77.51941170461059, 12.998903840950259],
						[77.51936262154942, 12.998810233069317],
						[77.51930916385982, 12.998765647905806],
						[77.51923330814263, 12.998734375896783],
						[77.51916200197259, 12.998729968294017],
						[77.51911291880384, 12.998756731466406],
						[77.51907284732418, 12.998796865639418],
						[77.51901930200624, 12.998836949747776],
						[77.518970218776, 12.998810166774922],
						[77.51895245781583, 12.998761191282563],
						[77.51892122301707, 12.99868086589457],
						[77.51890783667214, 12.998605114122562],
						[77.51885875339389, 12.998564966284732],
						[77.51881421970603, 12.998515899283944],
						[77.51880529546786, 12.998435621715716],
						[77.51880529546786, 12.998337497384593],
						[77.51880529546786, 12.998288521474775],
						[77.51880975758704, 12.998203806167341],
						[77.51880975758704, 12.998114563207224],
						[77.51880975758704, 12.998061103688524],
						[77.51881868182485, 12.99793176802984],
						[77.51878744698944, 12.997829197289237],
						[77.51871605304773, 12.997753391409892],
						[77.5186536708047, 12.997686472762817],
						[77.51858673890708, 12.997606227319064],
						[77.5184752731498, 12.997503645093069],
						[77.51828803842982, 12.99737436553582],
						[77.51805618192495, 12.997325351890828],
						[77.51737837078801, 12.997374382933737],
						[77.51740514383569, 12.998426758720763],
						[77.51743629155645, 12.99857830136071],
						[77.51752107280944, 12.998587243312269],
						[77.51768153581322, 12.998533722422238],
						[77.51769492230225, 12.998622925055356],
						[77.51760130433958, 12.998725474645646],
						[77.5174318293835, 12.99878787053801],
						[77.51738729513792, 12.998792349154858],
						[77.5172802903864, 12.998725506487682],
						[77.51715989898409, 12.998716561148157],
						[77.51706173090055, 12.998738872778215],
						[77.51695472584332, 12.998859224616924],
						[77.51686556946787, 12.998952936663516],
						[77.51684325848959, 12.998970737420235],
						[77.51677641302265, 12.998970770551198],
						[77.51668716901355, 12.998935054358133],
						[77.51644638482857, 12.998903884160011],
						[77.5162859200856, 12.998894890816924],
						[77.51617882680539, 12.998921683749696],
						[77.5160405853308, 12.998988525604373],
						[77.51597820162313, 12.999082242885718],
						[77.51592911708299, 12.999153583448372],
						[77.51583103543412, 12.999220476990015],
						[77.51571064250085, 12.999269473905645],
						[77.51563040963968, 12.999380949632322],
						[77.51553232773375, 12.999470175356715],
						[77.51538516096903, 12.999532617292367],
						[77.51517114760588, 12.999652948719264],
						[77.51507297780937, 12.999773383533979],
						[77.51504182944272, 12.999911643107266],
						[77.51506405327822, 13.0001568259479],
						[77.51509975139862, 13.00026383991748],
						[77.51507297780937, 13.000366442455219],
						[77.51505512874637, 13.000451112111651],
						[77.5149882822307, 13.000509088106995],
						[77.514899124317, 13.000567102153614],
						[77.51481434111358, 13.000576007327659],
						[77.5146894848261, 13.000589348216682],
						[77.51455579132674, 13.00058941247497],
						[77.51443530955063, 13.000571579384133],
						[77.51431491514319, 13.000500204377127],
						[77.51420790750717, 13.000415510622103],
						[77.51410982437724, 13.000330732750161],
						[77.51402959038931, 13.00024156154538],
						[77.51394043172577, 13.000188085635084],
						[77.51376202669314, 13.000152351615602],
						[77.513521236329, 13.000139025847584],
						[77.5133206064693, 13.00012566604692],
						[77.51327152078483, 13.000094398070718],
						[77.51320913556174, 12.999661883913864],
						[77.51301288000197, 12.998738897651773],
						[77.51273201434661, 12.997971929769156],
						[77.51032406607037, 12.998573891118896],
						[77.51007434352466, 12.997713249821787],
						[77.51004756875535, 12.997561664448476],
						[77.50800978037083, 12.998145785592092],
						[77.50793400463596, 12.998177026385278],
						[77.50724729568154, 12.998431147502654],
						[77.50727407110811, 12.998520394367615],
						[77.5059764182076, 12.998921685684387],
						[77.50597195558618, 12.998886031620692],
						[77.50595856772091, 12.998778983277342],
						[77.50582486398667, 12.998163677359486],
						[77.50559744477523, 12.997396644751456],
						[77.5054502651902, 12.996986415109756],
						[77.50530308541538, 12.996491432581305],
						[77.50514260499271, 12.995880616914658],
						[77.50504897672477, 12.995514896452027],
						[77.50495972353801, 12.99536778323244],
						[77.50486609512002, 12.995283052993958],
						[77.50475907861923, 12.995238454443408],
						[77.50470115141178, 12.995216156145316],
						[77.5046520620181, 12.995211703257553],
						[77.50459413475632, 12.995216132890477],
						[77.50452273192793, 12.995216200744604],
						[77.50449158067998, 12.995216153841932],
						[77.50416606713102, 12.995242939697476],
						[77.5040679753661, 12.99524740187903],
						[77.5040501245809, 12.995242913854273],
						[77.50402334839787, 12.995238423510758],
						[77.50401888570008, 12.995202770782827],
						[77.50400549760568, 12.99495312041751],
						[77.50402334839787, 12.994667741037771],
						[77.50412590289491, 12.994493817236512],
						[77.50420614384926, 12.994315447572612],
						[77.50424184536807, 12.994279762287544],
						[77.50429977280865, 12.99427972976103],
						[77.50444249119616, 12.994279761954813],
						[77.50446480460052, 12.993954250291218],
						[77.50462091080544, 12.99297773849257],
						[77.50464759934489, 12.99212600075542],
						[77.50464759934489, 12.991791565734944],
						[77.50464759934489, 12.991760355296714],
						[77.5046520620181, 12.991412515752955],
						[77.50475015328102, 12.99120294356345],
						[77.50535217452902, 12.990409233229093],
						[77.50562859572133, 12.989967775382217],
						[77.5056955352256, 12.989753761060403],
						[77.50567768469496, 12.989713666671483],
						[77.50571784838496, 12.989642332845518],
						[77.50622168711209, 12.989410380539486],
						[77.50685931379513, 12.989218715730138],
						[77.50766642663834, 12.989031393194244],
						[77.50824165730691, 12.988977917153035],
						[77.50850923395535, 12.988910978176264],
						[77.50871433479396, 12.988772724860162],
						[77.50905768537248, 12.988393740395566],
						[77.50927171021021, 12.988175222080011],
						[77.5094233473522, 12.988126193751194],
						[77.50979784560425, 12.98810832355886],
						[77.5101679676686, 12.98813067550692],
						[77.51061832497662, 12.98827779339636],
						[77.51086358390118, 12.988375906333838],
						[77.51118024100477, 12.988331346574022],
						[77.5118089151852, 12.988126212516391],
						[77.51224150400702, 12.987970106677572],
						[77.51210772013933, 12.987421666257882],
						[77.51194716179131, 12.98689098375901],
						[77.51175991634786, 12.986413928515557],
						[77.51156365829371, 12.986039378366858],
						[77.51138533704035, 12.985709323865176],
						[77.51123816492249, 12.985459666743527],
						[77.51105984316017, 12.985276867024098],
						[77.51088143362037, 12.98498698634922],
						[77.51079673492802, 12.98481312480534],
						[77.51075211059167, 12.984549993614557],
						[77.51072542348018, 12.984211089767687],
						[77.51075657302607, 12.983827660966472],
						[77.51077888519556, 12.98363140271761],
						[77.51080119736068, 12.98352885747625],
						[77.51085465904055, 12.983310401800603],
						[77.51092159547835, 12.98313199872019],
						[77.51100629407797, 12.982855572062729],
						[77.51104645589197, 12.982654921766551],
						[77.51104199346888, 12.982235717260329],
						[77.51100183165332, 12.981620377554593],
						[77.51098844437833, 12.980884610154666],
						[77.51097059467587, 12.980692875121958],
						[77.51095283246921, 12.98045657404689],
						[77.51086804633124, 12.980215731016665],
						[77.51078781006214, 12.979841225597339],
						[77.51065402448899, 12.979511195829355],
						[77.51057378806931, 12.979034104315916],
						[77.51053808853183, 12.978895818856529],
						[77.51032852852155, 12.978093231768025],
						[77.51020366732192, 12.97743777499468],
						[77.5100074940889, 12.9765860205241],
						[77.50969975868092, 12.97569421087424],
						[77.50944119729678, 12.9749941862958],
						[77.50927617270256, 12.974566108649903],
						[77.50906661037375, 12.974129064127762],
						[77.508816885075, 12.97366085175276],
						[77.50852262152134, 12.973206086105355],
						[77.50816141919029, 12.97263084154489],
						[77.50764411386002, 12.971837096470576],
						[77.50712243110925, 12.971092453662507],
						[77.50682370059693, 12.97066437902493],
						[77.5067122233395, 12.970463729617762],
						[77.50656504519232, 12.970102481317891],
						[77.50617259837247, 12.969081377389543],
						[77.50589617849792, 12.96842587690535],
						[77.50547704103847, 12.967529606506778],
						[77.50511136641092, 12.966677913729624],
						[77.5049374977336, 12.966111614052485],
						[77.50484824445965, 12.965531919364347],
						[77.5048349439658, 12.964979008643708],
						[77.50490625909565, 12.964319092566505],
						[77.50508012782058, 12.963596656540233],
						[77.50510690375569, 12.963471848757152],
						[77.50507120250747, 12.963186448255865],
						[77.50481263063381, 12.96212070018593],
						[77.50455843334771, 12.961162055912252],
						[77.50441125242274, 12.960609059924092],
						[77.50438456382801, 12.960470891514797],
						[77.50451389407475, 12.960412855366396],
						[77.50471007675382, 12.960359350077123],
						[77.50494642305715, 12.960345973720464],
						[77.50525408378353, 12.960372769691915],
						[77.50822380717462, 12.96080525311744],
						[77.51005649367914, 12.961059486364533],
						[77.51099736922849, 12.961144171572007],
						[77.51142549872085, 12.961113008266901],
						[77.51178222832105, 12.961050546351455],
						[77.51228604026302, 12.960881080042771],
						[77.5133651423038, 12.960444064093661],
						[77.51448439473405, 12.960056134373014],
						[77.51547431850257, 12.959614704469397],
						[77.51550109199755, 12.959645910053279],
						[77.51558132494976, 12.95977081182871],
						[77.515657183098, 12.959895588270648],
						[77.51572849146119, 12.960024971710672],
						[77.51576865161175, 12.960105181409961],
						[77.51579533754276, 12.960163190027915],
						[77.51585334661056, 12.960288051426117],
						[77.5159024311833, 12.960417308138425],
						[77.51594250377785, 12.960546646102145],
						[77.51597820162313, 12.960680427165975],
						[77.51600497499975, 12.960814207519306],
						[77.51600943722858, 12.960845454387865],
						[77.51600497499975, 12.960925660322939],
						[77.51600943722858, 12.961104045813634],
						[77.5160183616857, 12.961282388930409],
						[77.51603621059786, 12.9614563371573],
						[77.51605843423947, 12.961634634858768],
						[77.5160807453714, 12.961755098393986],
						[77.51608966982293, 12.961763977669834],
						[77.51612536762212, 12.961799667176694],
						[77.51616990236082, 12.961826388797352],
						[77.51621452457664, 12.961848718566813],
						[77.51626360899385, 12.961862127311093],
						[77.51631706811227, 12.961866524588553],
						[77.51636615248533, 12.961866572645034],
						[77.51637507691451, 12.961866573545224],
						[77.51650885577428, 12.96185313589423],
						[77.51677641302265, 12.961830915008582],
						[77.51703950745458, 12.961813046327945],
						[77.51708404183606, 12.961813046446549],
						[77.51715097461634, 12.961799649161044],
						[77.51723120640706, 12.961786292010945],
						[77.51731143814128, 12.961781813227978],
						[77.51739621948717, 12.961786260578629],
						[77.5174764511052, 12.961795228640458],
						[77.51755668266676, 12.961813075088598],
						[77.51763253950017, 12.96183536453597],
						[77.5176993844649, 12.961866527310972],
						[77.5177083087897, 12.961875492774475],
						[77.51774846824257, 12.961915621874528],
						[77.5177797033628, 12.961964628574025],
						[77.51780647631621, 12.962018160291043],
						[77.51782423745502, 12.962071686957952],
						[77.51783762392726, 12.962125174364457],
						[77.51784208608434, 12.962183143272911],
						[77.51784208608434, 12.962223311786214],
						[77.51779755199911, 12.962517593913525],
						[77.51763700166525, 12.962999175751014],
						[77.51762361516944, 12.963030335392874],
						[77.5175478458236, 12.963445079610606],
						[77.51753892148625, 12.963659111912154],
						[77.51753892148625, 12.963690316140145],
						[77.51756114483483, 12.963721561498787],
						[77.51758345567255, 12.963761689660531],
						[77.51760130433958, 12.963779534784395],
						[77.51771277095182, 12.96386871536906],
						[77.51777077904363, 12.963895486230951],
						[77.51784208608434, 12.963917728714998],
						[77.5179000941103, 12.96395337815462],
						[77.51796247676592, 12.964015875076383],
						[77.51818995881119, 12.964252210911495],
						[77.51833703446103, 12.964617835639157],
						[77.51834595873588, 12.964662402164386],
						[77.51843966357956, 12.965010187928973],
						[77.5184752731498, 12.965313441930281],
						[77.5184752731498, 12.965380333904282],
						[77.5184752731498, 12.965424899841063],
						[77.51843073931205, 12.965496273998308],
						[77.51823449274305, 12.965621077184334],
						[77.5181408752237, 12.96564783711567],
						[77.51806501872869, 12.965647872286905],
						[77.51792240488166, 12.96570587909495],
						[77.51757006917045, 12.965919844308864],
						[77.51744967807427, 12.966093746464296],
						[77.51740514383569, 12.966294422545417],
						[77.51740960600969, 12.966370237199651],
						[77.51741406818353, 12.966441569425633],
						[77.51744967807427, 12.966486221518641],
						[77.51750322412984, 12.96652631114631],
						[77.51763253950017, 12.966637822975711],
						[77.5180249468805, 12.966909820070308],
						[77.5181007159091, 12.966999000119689],
						[77.51817657238033, 12.96769013594934],
						[77.51806064407347, 12.967774864530655],
						[77.51794462815545, 12.967904155619248],
						[77.51764146383019, 12.968243083391187],
						[77.51755230799202, 12.968416948710143],
						[77.51753892148623, 12.96849724528434],
						[77.51753892148625, 12.968595344405326],
						[77.5176057665059, 12.96911261105681],
						[77.51761022867205, 12.969215150721492],
						[77.51763253950017, 12.969304331013582],
						[77.51770384662738, 12.969424761802829],
						[77.51819433346147, 12.969968750124588],
						[77.51844850035354, 12.970387854672124],
						[77.51849312167714, 12.97060638901317],
						[77.51849312167714, 12.970650956931483],
						[77.51847081101752, 12.970753581252106],
						[77.51847081101754, 12.970784787450523],
						[77.51846634888508, 12.970847156239621],
						[77.51844412571305, 12.970922975161955],
						[77.51839058009962, 12.971065638236206],
						[77.51838611796403, 12.971145937837997],
						[77.51837719369233, 12.971275244665948],
						[77.5183281101855, 12.971373383520962],
						[77.51820771989023, 12.971520482647382],
						[77.51814533736888, 12.971609701906896],
						[77.51808732946776, 12.971734563494401],
						[77.51801602258051, 12.971921797533724],
						[77.51799371182749, 12.972024422676569],
						[77.51799371182749, 12.9720912321604],
						[77.51798924967636, 12.97218041154664],
						[77.51805171977627, 12.97233205560995],
						[77.5181007159091, 12.972430203103753],
						[77.51818995881119, 12.972559437299477],
						[77.51822110631743, 12.972626376117859],
						[77.51823895488458, 12.972666464275626],
						[77.5182523413081, 12.972728922434582],
						[77.5182523413081, 12.9727646118669],
						[77.51823449274305, 12.972835902310226],
						[77.51816764809222, 12.972987578367064],
						[77.51810964020245, 12.973054424165717],
						[77.5180249468805, 12.973094546746166],
						[77.51796247676592, 12.97309902149952],
						[77.5177083087897, 12.97315257460367],
						[77.51769046013942, 12.973170330836329],
						[77.51761915300382, 12.973179291295018],
						[77.51750768630002, 12.973255099643708],
						[77.5174764511052, 12.973299707448707],
						[77.51746306459053, 12.973362076155565],
						[77.51749429978899, 12.973763415798127],
						[77.51752999714819, 12.973870489546867],
						[77.5176548503239, 12.974303003238479],
						[77.5177930898403, 12.974789012454805],
						[77.51784208608434, 12.974887162345272],
						[77.51786885902307, 12.974940614794978],
						[77.51787778333458, 12.974958461020783],
						[77.51792240488166, 12.975025364341189],
						[77.5179714010701, 12.97508782435748],
						[77.51802940903023, 12.975145720821109],
						[77.51808732946776, 12.975194819853554],
						[77.51815426165874, 12.975243838131293],
						[77.51822110631743, 12.975283972745759],
						[77.51826572773005, 12.975301823801493],
						[77.5182969627085, 12.975315233347041],
						[77.51831481126376, 12.975324115175443],
						[77.51834149659854, 12.975332993954611],
						[77.51839504223503, 12.975359811856636],
						[77.51844412571305, 12.97539098268918],
						[77.51848865954555, 12.975431157474615],
						[77.51852881872342, 12.975475689446725],
						[77.51856005362974, 12.97552478930426],
						[77.51858673890708, 12.97557832409612],
						[77.51860458741695, 12.975631775761581],
						[77.51861797379753, 12.975689752814478],
						[77.51862243592403, 12.975747728712216],
						[77.51861797379753, 12.975805703450602],
						[77.51861351167084, 12.975823504952144],
						[77.51858673890708, 12.975961780213504],
						[77.51856451575853, 12.976100017344404],
						[77.51855112937166, 12.97623825158301],
						[77.51854666724238, 12.976380883843746],
						[77.51854666724238, 12.976434419704473],
						[77.51856451575853, 12.976559253023664],
						[77.518609049544, 12.976795640824305],
						[77.5186581329298, 12.97702750799935],
						[77.51868936780069, 12.977143421145096],
						[77.5187205151704, 12.97729502104085],
						[77.51877852274916, 12.977584909899484],
						[77.51883206818026, 12.977874755835154],
						[77.51883644280588, 12.97791927982623],
						[77.51883644280588, 12.97800402457973],
						[77.51884090492383, 12.978128900974538],
						[77.51885429127664, 12.978249295803646],
						[77.5188721397446, 12.978374131237397],
						[77.5188989124413, 12.978494571319898],
						[77.51893460936044, 12.978610443621529],
						[77.51899699144967, 12.97871303909909],
						[77.51907284732418, 12.978820080673414],
						[77.51915307776166, 12.978922592672923],
						[77.51924223234727, 12.979025192245976],
						[77.51933147435525, 12.979118830190075],
						[77.5193849320344, 12.979163404712299],
						[77.5193849320344, 12.979167887702745],
						[77.51942955299123, 12.9792124652347],
						[77.51946971183744, 12.979265965046853],
						[77.51950532108575, 12.979319503131872],
						[77.51953209363367, 12.979377441024724],
						[77.51954994199544, 12.979439860704792],
						[77.51956332826494, 12.979502322903162],
						[77.51956779035442, 12.979564697673895],
						[77.51956332826494, 12.979627157435763],
						[77.51954994199544, 12.979694012772397],
						[77.51953209363367, 12.979752030798377],
						[77.51950978317751, 12.979814401912835],
						[77.51949639690172, 12.979832288980392],
						[77.51949193480944, 12.979841211277414],
						[77.5194384771805, 12.979930351024358],
						[77.51938046993774, 12.980015132216304],
						[77.5193180880585, 12.980095390892187],
						[77.51924669444932, 12.980171161025524],
						[77.51917092618281, 12.980242494823138],
						[77.51916646407778, 12.980246934101405],
						[77.5191218430179, 12.980282619458917],
						[77.51905499888767, 12.980345074674],
						[77.51899699144967, 12.980411923757664],
						[77.51894799570225, 12.980483261417469],
						[77.5188989124413, 12.980559077885816],
						[77.51886321551098, 12.980634939443037],
						[77.51884090492383, 12.980688430898466],
						[77.51883206818026, 12.98078654348733],
						[77.51881421970603, 12.980906893410078],
						[77.51878744698944, 12.981022845275458],
						[77.51875621214543, 12.98113875346146],
						[77.51871605304773, 12.9812502635525],
						[77.51867151930408, 12.981361734177465],
						[77.51866259505474, 12.981384061947631],
						[77.5186536708047, 12.981401906654897],
						[77.51855112937166, 12.981598072769295],
						[77.51845742461967, 12.981798719237117],
						[77.51836826941994, 12.981999413921812],
						[77.51836380728346, 12.982008336299376],
						[77.51835488301002, 12.982039544098422],
						[77.51833703446103, 12.982115322765834],
						[77.5183281101855, 12.982195585939495],
						[77.51832364804746, 12.98227589295902],
						[77.5183281101855, 12.982351632101063],
						[77.51834149659854, 12.982431941989564],
						[77.51835934514683, 12.982507726420016],
						[77.51838611796403, 12.98258351229055],
						[77.51842181504385, 12.982654902707743],
						[77.51846188675246, 12.982726246523963],
						[77.51850204593975, 12.982784231498421],
						[77.51853774298326, 12.982846655827514],
						[77.51867151930408, 12.98309634792619],
						[77.51879637122899, 12.983350522429408],
						[77.51880975758704, 12.983377294043718],
						[77.51885429127664, 12.983448595986548],
						[77.51893907147456, 12.983586853986823],
						[77.51901483989526, 12.98372510655855],
						[77.51908623364974, 12.983872199883018],
						[77.51914415355003, 12.984010450082685],
						[77.51916200197259, 12.984077355600832],
						[77.51922884604004, 12.98430475383475],
						[77.51930023966041, 12.984527717434279],
						[77.5193225501576, 12.984594580886311],
						[77.51935378484633, 12.984648082403988],
						[77.51942062880127, 12.984741761703669],
						[77.51949639690172, 12.984826476081528],
						[77.51957225244372, 12.984911194773217],
						[77.5196390962705, 12.984973578039446],
						[77.51966586878696, 12.985013758745211],
						[77.51970602754987, 12.985085108561437],
						[77.51973726213346, 12.985165337262401],
						[77.51976394713506, 12.985245604196413],
						[77.51978179546052, 12.985325874032831],
						[77.51979518170276, 12.985410583354735],
						[77.51980856794344, 12.985490895722824],
						[77.51982195418255, 12.985602332187188],
						[77.5198264162619, 12.985713853570545],
						[77.51982195418255, 12.985825287431856],
						[77.51981303002333, 12.985936763794722],
						[77.51980856794344, 12.985981380090593],
						[77.51979964378316, 12.986012589029714],
						[77.51979071962218, 12.98606164479012],
						[77.5197907196222, 12.986110701991429],
						[77.51979964378316, 12.986159760637669],
						[77.51981303002333, 12.98620429364688],
						[77.5198353404201, 12.986248914317883],
						[77.51986657496822, 12.986289053167013],
						[77.51987103704583, 12.986297977307498],
						[77.5198887978628, 12.98632470309792],
						[77.51993788069728, 12.986391572028523],
						[77.51999588765622, 12.986458528647601],
						[77.5200582691643, 12.986520955396784],
						[77.52011181399394, 12.986565538090742],
						[77.52016080924082, 12.98661903931203],
						[77.52021435402227, 12.98669483262652],
						[77.52025897465423, 12.98677062457935],
						[77.52029904571668, 12.986850851827413],
						[77.52033028013767, 12.986926684877783],
						[77.52033028013767, 12.98693116817639],
						[77.52033474219711, 12.986984666724911],
						[77.52033474219711, 12.987033724391816],
						[77.52032135601826, 12.987082736828482],
						[77.52030350777734, 12.98713179167428],
						[77.52030350777734, 12.987136274980314],
						[77.52025451259183, 12.987189769264393],
						[77.52020096782927, 12.987252182095673],
						[77.52015634717459, 12.987319079678839],
						[77.52012073812976, 12.987390466271155],
						[77.5200895036513, 12.98746628957566],
						[77.52006273123439, 12.987542070526832],
						[77.5200582691643, 12.987568840386718],
						[77.5200493450236, 12.987600049736535],
						[77.52004488295297, 12.987666910852004],
						[77.52004488295297, 12.98772933251587],
						[77.52005380709404, 12.9877917556715],
						[77.52006719330433, 12.987854222702065],
						[77.5200895036513, 12.987916605012755],
						[77.52011627606193, 12.987961184079303],
						[77.52016973337281, 12.988063788052138],
						[77.52024112640355, 12.98820649075046],
						[77.52030350777734, 12.988353585090268],
						[77.5203615145501, 12.988500726269153],
						[77.5204105096896, 12.988652345325532],
						[77.52042389585797, 12.988705846158172],
						[77.52043728202477, 12.988745983129125],
						[77.52050867488781, 12.988982320441792],
						[77.52057551816512, 12.98922309360839],
						[77.52063352479935, 12.989468353504115],
						[77.52068251982186, 12.989713608255098],
						[77.52070483004833, 12.98983850085579],
						[77.52071821618213, 12.989905366335801],
						[77.5207539125312, 12.990030218289789],
						[77.52079407091055, 12.990159511461576],
						[77.520843065864, 12.990279921406588],
						[77.520901072362, 12.990404820674534],
						[77.52096345337438, 12.990520706612658],
						[77.52097237744348, 12.990538555665317],
						[77.52101699777852, 12.990605469957599],
						[77.52112846104892, 12.990788314418447],
						[77.52123100016206, 12.990975554977442],
						[77.5213291646532, 12.991162842505128],
						[77.52142277955255, 12.9913545657786],
						[77.5214272415692, 12.991363490382113],
						[77.52145401366542, 12.99141255453609],
						[77.52150747034848, 12.991524042924658],
						[77.52155655248549, 12.991635491821617],
						[77.52159671058186, 12.991751422842007],
						[77.52163231916525, 12.991867392114056],
						[77.52164570519004, 12.991929775452892],
						[77.52165016719796, 12.991965514933474],
						[77.5216546292057, 12.992001168204428],
						[77.52167247723492, 12.992041264150526],
						[77.52169478726748, 12.992072523173789],
						[77.52172155930079, 12.992099256336516],
						[77.52175270584145, 12.992121545614108],
						[77.52179286386865, 12.992139357126858],
						[77.52182855988094, 12.992148330183616],
						[77.52184640788289, 12.992148333192983],
						[77.52205148472422, 12.992175087896523],
						[77.52206040870806, 12.992179486693194],
						[77.52212287657548, 12.992197345029744],
						[77.5221852569187, 12.99222407994529],
						[77.52224772471766, 12.992255302627644],
						[77.5223011810282, 12.992295486640623],
						[77.52235026282315, 12.992340028363305],
						[77.52239488261839, 12.992389096006235],
						[77.52243495292942, 12.992442599114616],
						[77.52246172478874, 12.992487180294127],
						[77.52252865440954, 12.992589752468104],
						[77.5226044204426, 12.992683441124731],
						[77.52268027391483, 12.992777047891838],
						[77.52276942573972, 12.99286621229234],
						[77.5228228818054, 12.992933125467497],
						[77.52287196337551, 12.993004482640794],
						[77.52291212100805, 12.993080235726945],
						[77.52294335471245, 12.993160557179793],
						[77.52297003896292, 12.993245227953775],
						[77.52299234874131, 12.993325548051173],
						[77.52300127265144, 12.993410220222499],
						[77.52300573460626, 12.99349941840827],
						[77.52300573460626, 12.9935127829411],
						[77.52300573460626, 12.993579691850778],
						[77.52300573460626, 12.993691178043541],
						[77.52301465851534, 12.993802665843493],
						[77.52302358242372, 12.993878457398452],
						[77.5230325063314, 12.993927519839554],
						[77.52305481609756, 12.994030086037114],
						[77.52308604976282, 12.994132567620241],
						[77.52312611983065, 12.994230735407438],
						[77.5231707393219, 12.994328778954173],
						[77.52321982074207, 12.99442246906746],
						[77.52327773854047, 12.994511672914182],
						[77.52329558631531, 12.994538405254193],
						[77.52334912962301, 12.994632053216565],
						[77.52340258541656, 12.994743545354718],
						[77.52345166673682, 12.99486387905634],
						[77.52349182416492, 12.994984297615384],
						[77.52352743215579, 12.995104668207723],
						[77.52354527989148, 12.995171581161928],
						[77.52354527989148, 12.995198310651782],
						[77.52356312762439, 12.995274104761782],
						[77.52358543728658, 12.995349942799775],
						[77.5236166708063, 12.99542129874979],
						[77.5236522787581, 12.995488210714214],
						[77.52369243611548, 12.995555084615695],
						[77.52372366960586, 12.995595227441488],
						[77.52385297791464, 12.995746784955468],
						[77.52391981922914, 12.995813659358266],
						[77.52419628288801, 12.995983129646364],
						[77.524463909569, 12.996250640839445],
						[77.52462436279235, 12.996433543306397],
						[77.52474037190676, 12.996701034332418],
						[77.52478044139242, 12.996923973366535],
						[77.52482952211876, 12.997200464945468],
						[77.52486967906093, 12.997467988194828],
						[77.52490528662086, 12.997592845638213],
						[77.52501237169723, 12.997735568569757],
						[77.52510152169624, 12.997829221528418],
						[77.5251684497654, 12.997878252752518],
						[77.52526652337478, 12.997896114167084],
						[77.52536905876187, 12.997882719159009],
						[77.5255385219082, 12.997824713146787],
						[77.52574359210006, 12.997744462258794],
						[77.525908592848, 12.99771322850619],
						[77.52602897500314, 12.99771768502208],
						[77.52614944451787, 12.997744478433768],
						[77.52618951350759, 12.997802468342316],
						[77.52620736082598, 12.9978782646627],
						[77.52622966997004, 12.997962986263927],
						[77.52620289899664, 12.998087838413763],
						[77.52616720435566, 12.99823941957135],
						[77.52612267352562, 12.998382122226904],
						[77.5260735933568, 12.99846243460187],
						[77.52595767308797, 12.998560459617895],
						[77.52593982573043, 12.998591670865682],
						[77.52741134846066, 12.998935059729078],
						[77.52819172021502, 12.999162505725417],
						[77.52809802339577, 12.999305155375643],
						[77.52791964053601, 12.999412140059578],
						[77.52781710744645, 12.999519180126978],
						[77.52783495450963, 12.999581612468166],
						[77.52784387804017, 12.999693107439382],
						[77.52735334527918, 12.999880413617852],
						[77.52616720435566, 13.000437799495774],
						[77.52615836818056, 13.000442199846706],
						[77.52628321189792, 13.00070978535568],
						[77.52406251305588, 13.00171754976562],
						[77.52344274286199, 13.001650669413992],
						[77.52364781682864, 13.002439869494316],
						[77.52350958441728, 13.002480073204268],
						[77.52236811074336, 13.002778845355001],
						[77.52129346848439, 13.003073092613445],
						[77.52129346848439, 13.00324701728726],
						[77.52134246322304, 13.003523431971866],
						[77.52100807371292, 13.003568085650404],
						[77.52097683947778, 13.003278218721523],
						[77.52016080924082, 13.003389697647576],
						[77.52003149674006, 13.00282784942885],
						[77.5198798737088, 13.00235520287063],
						[77.51921108512019, 13.00231059376329],
						[77.51858227677917, 13.002261542187915],
						[77.51810964020245, 13.002248178103349],
						[77.5179401660024, 13.002288327728744],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000001c",
			properties: {
				AREA_SQ_KM: 5.879128079114055,
				ASS_CONST1: "Rajarajeswari Nagar",
				ASS_CONST_: "154",
				LAT: 12.9824564568696,
				LON: 77.5140904058987,
				LOST_AREA_SQ_KM: 0.2115693056648404,
				OBJECTID: 29,
				POP_F: 13288.0,
				POP_M: 15812.0,
				POP_SC: 2299.0,
				POP_ST: 431.0,
				POP_TOTAL: 29100.0,
				RESERVATIO: "General",
				WARD_NAME: "Kottegepalya",
				WARD_NO: 73.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.49893996003577, 12.926002034121877],
						[77.49901127884806, 12.9260465832401],
						[77.49913615229308, 12.92522162756877],
						[77.49931011731337, 12.925377725619484],
						[77.49964002006973, 12.925671990075381],
						[77.50000571222358, 12.92599305994292],
						[77.50043825828693, 12.926332010678756],
						[77.50113384632512, 12.926813531441145],
						[77.50185620682336, 12.92730849798278],
						[77.50207470794173, 12.927388768997101],
						[77.50229767140713, 12.927544825801657],
						[77.50248046962201, 12.927723241303347],
						[77.50258748825917, 12.927839141026727],
						[77.50264550404424, 12.927906054929199],
						[77.50277483626141, 12.928057673432317],
						[77.5028952428488, 12.928218167117647],
						[77.50301556180453, 12.928378743015942],
						[77.50302002454154, 12.928392056510015],
						[77.50304233822396, 12.928432169871414],
						[77.5031092792451, 12.928530278220146],
						[77.50318059545319, 12.92863290663076],
						[77.50325637434447, 12.928726530642757],
						[77.50334107863455, 12.928815674425032],
						[77.50343033308707, 12.92889599032914],
						[77.50352396268285, 12.928976259243624],
						[77.5035417260461, 12.928989612465987],
						[77.50359974134619, 12.929020852625237],
						[77.50372460977292, 12.929078762210757],
						[77.50384947806305, 12.92913227710038],
						[77.50397872141218, 12.929176869352437],
						[77.50411251481307, 12.929212547398214],
						[77.50424630805713, 12.929243744524442],
						[77.50433993698357, 12.929261625977405],
						[77.5044335658332, 12.929288382996605],
						[77.50461198545644, 12.929341900718837],
						[77.50478585462966, 12.929399851713766],
						[77.50495526087684, 12.929466721128353],
						[77.5051247543756, 12.929538075649512],
						[77.50526747173146, 12.929604947842282],
						[77.50531201071033, 12.929627264322402],
						[77.50538787568934, 12.929671861958026],
						[77.50545472783203, 12.929720849652417],
						[77.50551711729636, 12.929774361136133],
						[77.50557513159232, 12.929836795395504],
						[77.50562859572133, 12.929899268320481],
						[77.50567322206186, 12.929966139805144],
						[77.50567768469496, 12.929970577876329],
						[77.50576684981766, 12.93009544107411],
						[77.50584708961827, 12.930224784911212],
						[77.50592295424156, 12.930354089836186],
						[77.5059942686915, 12.930492309289942],
						[77.50605674536315, 12.930630532706894],
						[77.50611020926652, 12.930768751625937],
						[77.50611913449835, 12.930786589770426],
						[77.50615483541871, 12.930871299107755],
						[77.50619937405123, 12.930964966700536],
						[77.50625738800036, 12.931058596232003],
						[77.50631531441786, 12.931143345841782],
						[77.5063822535193, 12.931223619242646],
						[77.50645803028476, 12.931303888950582],
						[77.50653380699993, 12.931375196742474],
						[77.50661850885751, 12.931442110228643],
						[77.50670329815405, 12.9315045469855],
						[77.50675229916358, 12.931531302037843],
						[77.50729629645744, 12.93187014200744],
						[77.50804548069961, 12.93234281661971],
						[77.50806333085981, 12.932351779580971],
						[77.5081524941182, 12.93241416967589],
						[77.50832189536712, 12.932539035707299],
						[77.50849138386491, 12.932672782059043],
						[77.50865185957757, 12.932811004998149],
						[77.50880796005421, 12.932953756308237],
						[77.50895959781266, 12.93310534052835],
						[77.50903091036454, 12.933176692397216],
						[77.50910668537078, 12.93325696363293],
						[77.50921824778838, 12.933363955081195],
						[77.50942780983861, 12.93356461516864],
						[77.50964629645972, 12.933756399988498],
						[77.50986924513144, 12.933943661139164],
						[77.51009665582762, 12.934126484759586],
						[77.5102215171444, 12.934224554270948],
						[77.51030621626396, 12.93430034558803],
						[77.51045785203058, 12.93442521721579],
						[77.51061832497662, 12.934550085147245],
						[77.51078334762893, 12.934666038374838],
						[77.51094837004247, 12.934777510528365],
						[77.51112222955916, 12.934880102725083],
						[77.51129617630912, 12.934982613034137],
						[77.51147449770188, 12.93507180502385],
						[77.51165736871302, 12.935156563325153],
						[77.51282117395515, 12.935495458054517],
						[77.5137798759586, 12.935767391429536],
						[77.51392704479579, 12.93582095166216],
						[77.5138824216845, 12.935896786984825],
						[77.51382003679569, 12.936017180105102],
						[77.51376648900977, 12.936146445727426],
						[77.51371740351725, 12.936271273403287],
						[77.51370401656108, 12.936311475169685],
						[77.51361485764293, 12.936467545472047],
						[77.513521236329, 12.936619091320855],
						[77.51342315260831, 12.936766285047883],
						[77.51333399347051, 12.936886677574025],
						[77.51331168180099, 12.936913435224762],
						[77.51321806023809, 12.93703378451117],
						[77.51312881344302, 12.93716313545712],
						[77.51304411641996, 12.937292447759047],
						[77.51296834402987, 12.93742624178477],
						[77.51294156994004, 12.937533275094529],
						[77.51289694644302, 12.937729417040336],
						[77.51285687277787, 12.937925606763855],
						[77.5128256363086, 12.938108435101505],
						[77.51282117395515, 12.938135193646328],
						[77.5128256363086, 12.938175310424754],
						[77.51283009866188, 12.9382155133975],
						[77.51284794807324, 12.93825119255197],
						[77.51287017233643, 12.938286824600539],
						[77.51289694644302, 12.93831802283584],
						[77.51292818289278, 12.938344783024498],
						[77.51296388168201, 12.938362623814866],
						[77.51296834402987, 12.938362667102789],
						[77.51301288000197, 12.938376022712282],
						[77.51310650173333, 12.938393818734117],
						[77.51320021088466, 12.938411705136971],
						[77.51329829479717, 12.938416100326785],
						[77.51334283064037, 12.93841609801413],
						[77.51336067997147, 12.938416098777825],
						[77.51344100192682, 12.938425064906866],
						[77.51351677400278, 12.938442860039515],
						[77.51359254602836, 12.938465222695799],
						[77.51366840550006, 12.938491984749602],
						[77.5137352527897, 12.93852761874497],
						[77.5138021875365, 12.938572219705055],
						[77.51386457243508, 12.938621254547177],
						[77.51392258248545, 12.938674731737496],
						[77.5139760427018, 12.93873272900986],
						[77.51402066577647, 12.938795125377373],
						[77.51406082652872, 12.938862046074295],
						[77.51408751285955, 12.938924437568327],
						[77.51417675890413, 12.93903595879208],
						[77.51437738725711, 12.939316864486504],
						[77.51457355297214, 12.939597813923859],
						[77.51469394710644, 12.939776166511777],
						[77.51471179622598, 12.939816327704301],
						[77.51473856990008, 12.939878724057415],
						[77.51475641901264, 12.939945601516031],
						[77.51476980584522, 12.940012521925546],
						[77.51477426812241, 12.940083837264247],
						[77.51476980584522, 12.940150757018603],
						[77.51476088129034, 12.940217633531638],
						[77.51474303217847, 12.940284509702714],
						[77.51472072078471, 12.94034694735805],
						[77.5146894848261, 12.94040490320588],
						[77.51465833635466, 12.940436146961858],
						[77.51463156266173, 12.940471825039316],
						[77.51461371352961, 12.940516380227013],
						[77.5145958643947, 12.940560935425307],
						[77.51459140211054, 12.940610015766925],
						[77.51459140211054, 12.940654571776644],
						[77.5146003266787, 12.940703695837062],
						[77.51461371352961, 12.94074820938435],
						[77.51462710037896, 12.940774969468064],
						[77.51465833635466, 12.940806211810703],
						[77.51469394710644, 12.94084636997033],
						[77.51471625850544, 12.940890970160845],
						[77.51473856990008, 12.940935570372147],
						[77.51474749445671, 12.940984608391965],
						[77.51474749445671, 12.940989003681038],
						[77.51474749445671, 12.940993485152417],
						[77.51476980584522, 12.941158481981237],
						[77.51478319267625, 12.941327960144251],
						[77.5147876549529, 12.94149735197527],
						[77.5147876549529, 12.941671268438542],
						[77.51478319267625, 12.941733707579617],
						[77.51477873039941, 12.94175159028317],
						[77.51477426812241, 12.941791708132959],
						[77.51477873039941, 12.941836307916601],
						[77.51479211722939, 12.941880821953625],
						[77.51480987883797, 12.941916498249618],
						[77.51483665248901, 12.9419521792059],
						[77.51486788840728, 12.941983421964967],
						[77.51490358658913, 12.94201014033843],
						[77.51491697340442, 12.942010184054858],
						[77.51502844264208, 12.942027981785937],
						[77.5151399117707, 12.942041384141156],
						[77.51525138079035, 12.942045823412764],
						[77.51536284970096, 12.942041385773635],
						[77.51542969599696, 12.942036946098439],
						[77.51549662974882, 12.942063708917816],
						[77.51556347596637, 12.942094862846814],
						[77.51562148515217, 12.94213058832433],
						[77.51567940681352, 12.942175186456806],
						[77.51572849146119, 12.942224183794966],
						[77.51577311384983, 12.94227770558925],
						[77.51581318648984, 12.942335661436346],
						[77.51584888437561, 12.942398145984985],
						[77.51587119554856, 12.942464982258244],
						[77.51588012001649, 12.942478341094935],
						[77.51590689341614, 12.942594345366292],
						[77.51594250377785, 12.942750421370404],
						[77.51596927716287, 12.942910896818772],
						[77.51599158831222, 12.943067020017104],
						[77.51600497499975, 12.943227538427154],
						[77.51600497499975, 12.943258737081685],
						[77.516022823914, 12.943347938516975],
						[77.51605843423947, 12.943477212317559],
						[77.51610305649893, 12.943606490923617],
						[77.51615214096417, 12.943731417586386],
						[77.51620560013487, 12.943851815694062],
						[77.51625468455593, 12.943945457617156],
						[77.51626360899385, 12.943972175295736],
						[77.5162859200856, 12.944007813727106],
						[77.51631706811227, 12.944034571324227],
						[77.51634830362485, 12.944061333156927],
						[77.51638400134301, 12.94408365668348],
						[77.51642407376823, 12.944092574443735],
						[77.51646869588453, 12.944097058112172],
						[77.51650885577428, 12.944097016861866],
						[77.51655339036311, 12.944088137626876],
						[77.51658908801706, 12.94407029897624],
						[77.51659801242879, 12.944065817766258],
						[77.5166024746344, 12.944065861060686],
						[77.51665602108804, 12.94404354158916],
						[77.51671394222362, 12.944034619822647],
						[77.51677195082387, 12.94403018388214],
						[77.51682987190054, 12.944034620743468],
						[77.51688788044176, 12.944043543437099],
						[77.51694133926735, 12.9440613818406],
						[77.51699042337154, 12.944083662801734],
						[77.51700380994173, 12.944092583532987],
						[77.51703504526608, 12.944123826965162],
						[77.517066193088, 12.944172820306173],
						[77.51709296620903, 12.94422186079967],
						[77.51711081495289, 12.944275382559079],
						[77.51711973932377, 12.94433338558905],
						[77.51712420150893, 12.94439134536216],
						[77.51712420150893, 12.944444866425508],
						[77.51711081495289, 12.944502825466447],
						[77.51709742839526, 12.944547425796536],
						[77.51707511746238, 12.944600902837024],
						[77.51703950745458, 12.944685625743766],
						[77.51699042337154, 12.944765905294336],
						[77.51694133926735, 12.944841703215985],
						[77.51688341824733, 12.9449130233155],
						[77.5168164853099, 12.944979943302094],
						[77.51674963982727, 12.945042385854164],
						[77.51667378240616, 12.94510034208037],
						[77.51659801242879, 12.945153820855111],
						[77.516531166818, 12.945189545786382],
						[77.51650885577428, 12.945207342038056],
						[77.51640631241119, 12.945287623181459],
						[77.51631260589548, 12.945376777638236],
						[77.5162234490177, 12.945470461285153],
						[77.51613875429393, 12.945568497537701],
						[77.51605843423947, 12.945671054555074],
						[77.5159871260827, 12.945778097958602],
						[77.5159603527019, 12.945818259202484],
						[77.51587565778262, 12.945925258824794],
						[77.51564825861259, 12.94620169674863],
						[77.515416396737, 12.946473695846041],
						[77.51538069871577, 12.946513856610332],
						[77.51534946293802, 12.946571815125608],
						[77.51529591587054, 12.946656533226003],
						[77.51523799401431, 12.946732335008468],
						[77.51517552237203, 12.94680369369297],
						[77.51510421366288, 12.94687057436327],
						[77.51502844264208, 12.946928534434857],
						[77.5149525840754, 12.94698649025167],
						[77.51492143567584, 12.94700437627015],
						[77.51445315871068, 12.947218403758399],
						[77.51374417742488, 12.947530506707938],
						[77.51364163157464, 12.947584068856877],
						[77.51347215073089, 12.947664301022082],
						[77.51330275713195, 12.947735660026705],
						[77.51312881344302, 12.947802575698356],
						[77.51311542641773, 12.947807013342153],
						[77.51311096407562, 12.947806969924539],
						[77.51306642814184, 12.947815934325737],
						[77.5130262670393, 12.947833728948948],
						[77.5129905682696, 12.947856048693238],
						[77.51295495698575, 12.94788285437223],
						[77.51292818289278, 12.94791844752155],
						[77.51291033349398, 12.947954127520306],
						[77.51289694644302, 12.947994246475712],
						[77.51288802174153, 12.948038890581069],
						[77.51288802174153, 12.948078967462552],
						[77.51289694644302, 12.948123612928946],
						[77.51291033349398, 12.948163733934143],
						[77.51293264524205, 12.94819937390061],
						[77.51295049463735, 12.948221697658266],
						[77.51295049463735, 12.948270738058937],
						[77.51295049463735, 12.948337619200787],
						[77.51293710759114, 12.948404542466628],
						[77.51291925819372, 12.948471422333476],
						[77.51289694644302, 12.948538258797427],
						[77.51286570998474, 12.948596303509904],
						[77.5128256363086, 12.948654265596561],
						[77.51278101276621, 12.948707784469766],
						[77.51273201434661, 12.948756782396195],
						[77.51267846604968, 12.948801423278649],
						[77.51262045536627, 12.948837057224202],
						[77.51258930632953, 12.948850461146137],
						[77.51250452140832, 12.948899451997184],
						[77.51235297586062, 12.948993134104894],
						[77.51220580499162, 12.949091250875746],
						[77.51206309631597, 12.949193806559657],
						[77.5119248498504, 12.949305282925415],
						[77.51187138867226, 12.949349841663006],
						[77.51191146268376, 12.949389961066235],
						[77.51174206676617, 12.949532682002594],
						[77.51149234732546, 12.949764514030116],
						[77.51125155216818, 12.949960751090781],
						[77.51116239133233, 12.950018751863018],
						[77.51111776713905, 12.950156993248731],
						[77.51111330471875, 12.950277354334089],
						[77.51112669197911, 12.950362035275774],
						[77.51115346649506, 12.950571647256401],
						[77.51118024100477, 12.950767814254402],
						[77.51124262733788, 12.95089718452459],
						[77.5113451753457, 12.951093397900166],
						[77.51138979945, 12.951249403551223],
						[77.51141211149559, 12.951392091217706],
						[77.51151019694623, 12.951557147845433],
						[77.51156365829371, 12.951699791485757],
						[77.51155919589087, 12.95189600087089],
						[77.51147449770188, 12.952132371613402],
						[77.51140318667787, 12.952301817725663],
						[77.5113407129343, 12.952462296874863],
						[77.51131393846231, 12.95255598252436],
						[77.51129171389579, 12.952645147927143],
						[77.51127832665483, 12.952743273752745],
						[77.51126940182664, 12.95283687511128],
						[77.51126940182664, 12.952930477403035],
						[77.51127832665483, 12.953028648708996],
						[77.51127832665483, 12.95309553221794],
						[77.51128278906866, 12.953184696359292],
						[77.51129617630912, 12.953278343336114],
						[77.51131393846231, 12.953367461606009],
						[77.51134071293431, 12.953452189350923],
						[77.51137641222054, 12.953536918043916],
						[77.51141657390419, 12.953621690344468],
						[77.51146557288905, 12.953701977451708],
						[77.51151912175558, 12.953773262373351],
						[77.51155473348786, 12.953822304153269],
						[77.51169744279973, 12.953978405465397],
						[77.51190700029453, 12.954201350508363],
						[77.51212110728291, 12.954415379928339],
						[77.51217010596504, 12.954459984488388],
						[77.51219241785796, 12.954495626790917],
						[77.51222365450072, 12.954549197791838],
						[77.51225042875913, 12.954611603014833],
						[77.51226819076369, 12.954674003173631],
						[77.51228157788844, 12.954736450264363],
						[77.51228604026302, 12.95480329229239],
						[77.51228157788844, 12.954865737709047],
						[77.51227265313878, 12.954932621592008],
						[77.51225489113492, 12.954995113082488],
						[77.51222811687755, 12.955053031315874],
						[77.51219688023602, 12.95511099221812],
						[77.51216118120665, 12.955164513807938],
						[77.51211664490188, 12.955213556768362],
						[77.51209433299414, 12.955231439354723],
						[77.5120764834648, 12.955244797311451],
						[77.51201855997289, 12.95527604028732],
						[77.51196947372786, 12.955316157679665],
						[77.5119203874617, 12.955360757043675],
						[77.51188031345333, 12.955409757331468],
						[77.51184461432486, 12.95546776090517],
						[77.51181783997116, 12.955525679209686],
						[77.51179552800494, 12.955583641083589],
						[77.51178222832105, 12.955646090117376],
						[77.5117733035323, 12.955712974300459],
						[77.51177776592675, 12.95577542100075],
						[77.51178222832105, 12.955837867735852],
						[77.51179999039853, 12.955900268561146],
						[77.51182230236387, 12.955962717217508],
						[77.51184907671653, 12.956011763456313],
						[77.51186246389048, 12.956029563576212],
						[77.51191592507281, 12.956096450230756],
						[77.51196054895377, 12.956167822254788],
						[77.51200517281725, 12.956239194332586],
						[77.51203632201356, 12.956319395591919],
						[77.51206309631597, 12.956399729977017],
						[77.5120809458474, 12.956462135396405],
						[77.5120764834648, 12.956488897798238],
						[77.51205863393267, 12.956578019339052],
						[77.51203185962922, 12.956662744161891],
						[77.51200071043169, 12.956747515890248],
						[77.51196054895377, 12.956827800444707],
						[77.51191592507281, 12.956903559417182],
						[77.5118892382337, 12.956943726787916],
						[77.51188031345333, 12.957001647613705],
						[77.51187138867226, 12.957086374545941],
						[77.51187138867226, 12.957171102489845],
						[77.51188031345333, 12.957255831452366],
						[77.51189370062363, 12.957331639944323],
						[77.51189816301338, 12.957340561439374],
						[77.51194716179131, 12.957603668134222],
						[77.51199178566004, 12.957866736139199],
						[77.51202739724471, 12.958129799586004],
						[77.51203632201356, 12.958227889381437],
						[77.51204078439774, 12.958232415059447],
						[77.51205863393267, 12.958285857360277],
						[77.51206755869907, 12.958343867001629],
						[77.51207202108203, 12.958401833086917],
						[77.51207202108203, 12.958459755613962],
						[77.51205863393267, 12.95851780598451],
						[77.51204078439774, 12.958575726593217],
						[77.51201855997289, 12.958629211952886],
						[77.51198732327396, 12.958678296186301],
						[77.51195162417898, 12.958727336831823],
						[77.51190700029453, 12.958767498455906],
						[77.51189370062363, 12.958780818235248],
						[77.51188477584361, 12.95878978145904],
						[77.51184461432486, 12.958829900477792],
						[77.51181337757826, 12.95887898472477],
						[77.51179106561118, 12.958927983790788],
						[77.5117733035323, 12.958981512822222],
						[77.51175991634786, 12.959039477162989],
						[77.51175545395272, 12.959097442539782],
						[77.51175991634786, 12.959150926834349],
						[77.51176437874285, 12.959168812739541],
						[77.51177776592677, 12.959191095592871],
						[77.51179552800494, 12.959213417820857],
						[77.51181783997117, 12.959235701698947],
						[77.51184015193301, 12.959253503453459],
						[77.51187138867226, 12.959266910289156],
						[77.51190262540297, 12.959275834994198],
						[77.51193377462728, 12.959275791200415],
						[77.5119650113409, 12.959275837832148],
						[77.51199624804596, 12.959266920196791],
						[77.51202302235775, 12.959253563019148],
						[77.51203185962923, 12.959244595528148],
						[77.51208540822982, 12.959222277094446],
						[77.51217903072273, 12.959168799479254],
						[77.51226819076369, 12.959110796095041],
						[77.51235297586062, 12.959048443577077],
						[77.51243321102847, 12.958981474882554],
						[77.51248675944058, 12.958928040035131],
						[77.51256253215071, 12.958887834268106],
						[77.51263384244967, 12.958843275153074],
						[77.51270077784311, 12.95878979861562],
						[77.51276762570008, 12.95873183570398],
						[77.51282117395515, 12.95866935035804],
						[77.51287017233643, 12.958606989592072],
						[77.51291033349398, 12.958540063803001],
						[77.51296388168201, 12.958459822398238],
						[77.5130262670393, 12.958379491521402],
						[77.51309320220234, 12.958308172669778],
						[77.51316451216944, 12.958236806946061],
						[77.51320467322329, 12.9582056518662],
						[77.51325813377629, 12.95818781096473],
						[77.51333399347051, 12.958165537579617],
						[77.51341422794799, 12.958152095311702],
						[77.51349446236901, 12.958147703421423],
						[77.51356585958169, 12.958147624542319],
						[77.51358362138131, 12.958134261997708],
						[77.51376648900977, 12.958000545741074],
						[77.51394043172577, 12.957880102369456],
						[77.51415890970081, 12.957799825564567],
						[77.51436400037844, 12.957724072388029],
						[77.51439077413421, 12.957710628677905],
						[77.51443093475609, 12.957706193569214],
						[77.51447547015681, 12.957706193658828],
						[77.51451563074886, 12.957710636551173],
						[77.51455132904101, 12.95772408631867],
						[77.5145869398262, 12.95774632367955],
						[77.5146181758129, 12.957773090054886],
						[77.51464494950898, 12.957804295021777],
						[77.51466279863621, 12.9578399812526],
						[77.51467618547979, 12.957880106084398],
						[77.51468056026494, 12.957920225837645],
						[77.51467618547979, 12.957942510933979],
						[77.51472518306382, 12.958018319593016],
						[77.51476534356787, 12.958098613808612],
						[77.51479657950568, 12.958183303161492],
						[77.51482326566426, 12.958268031018429],
						[77.51484557703797, 12.95835720178571],
						[77.51485450158621, 12.958446414425664],
						[77.51485896386008, 12.958535583604965],
						[77.51485896386008, 12.958553425981604],
						[77.51488573749961, 12.958664878871945],
						[77.51492143567584, 12.95877641895321],
						[77.51496150861527, 12.958883430108196],
						[77.5150105935721, 12.958990446490624],
						[77.51506405327822, 12.959093020127725],
						[77.5151265249816, 12.959195512791247],
						[77.51517114760588, 12.959258009014533],
						[77.51522014497715, 12.959307011854431],
						[77.51531822715171, 12.95941407240083],
						[77.51541193448496, 12.959529971943372],
						[77.51547431850257, 12.959614704469397],
						[77.51448439473405, 12.960056134373014],
						[77.5133651423038, 12.960444064093661],
						[77.51228604026302, 12.960881080042771],
						[77.51178222832105, 12.961050546351455],
						[77.51142549872085, 12.961113008266901],
						[77.51099736922849, 12.961144171572007],
						[77.51005649367914, 12.961059486364533],
						[77.50822380717462, 12.96080525311744],
						[77.50525408378353, 12.960372769691915],
						[77.50494642305715, 12.960345973720464],
						[77.50471007675382, 12.960359350077123],
						[77.50451389407475, 12.960412855366396],
						[77.50438456382801, 12.960470891514797],
						[77.50441125242274, 12.960609059924092],
						[77.50455843334771, 12.961162055912252],
						[77.50481263063381, 12.96212070018593],
						[77.50507120250747, 12.963186448255865],
						[77.50510690375569, 12.963471848757152],
						[77.50508012782058, 12.963596656540233],
						[77.50490625909565, 12.964319092566505],
						[77.5048349439658, 12.964979008643708],
						[77.50484824445965, 12.965531919364347],
						[77.5049374977336, 12.966111614052485],
						[77.50511136641092, 12.966677913729624],
						[77.50547704103847, 12.967529606506778],
						[77.50589617849792, 12.96842587690535],
						[77.50617259837247, 12.969081377389543],
						[77.50656504519232, 12.970102481317891],
						[77.5067122233395, 12.970463729617762],
						[77.50682370059693, 12.97066437902493],
						[77.50712243110925, 12.971092453662507],
						[77.50764411386002, 12.971837096470576],
						[77.50816141919029, 12.97263084154489],
						[77.50852262152134, 12.973206086105355],
						[77.508816885075, 12.97366085175276],
						[77.50906661037375, 12.974129064127762],
						[77.50927617270256, 12.974566108649903],
						[77.50944119729678, 12.9749941862958],
						[77.50969975868092, 12.97569421087424],
						[77.5100074940889, 12.9765860205241],
						[77.51020366732192, 12.97743777499468],
						[77.51032852852155, 12.978093231768025],
						[77.51053808853183, 12.978895818856529],
						[77.51057378806931, 12.979034104315916],
						[77.51065402448899, 12.979511195829355],
						[77.51078781006214, 12.979841225597339],
						[77.51086804633124, 12.980215731016665],
						[77.51095283246921, 12.98045657404689],
						[77.51097059467587, 12.980692875121958],
						[77.51098844437833, 12.980884610154666],
						[77.51100183165332, 12.981620377554593],
						[77.51104199346888, 12.982235717260329],
						[77.51104645589197, 12.982654921766551],
						[77.51100629407797, 12.982855572062729],
						[77.51092159547835, 12.98313199872019],
						[77.51085465904055, 12.983310401800603],
						[77.51080119736068, 12.98352885747625],
						[77.51077888519556, 12.98363140271761],
						[77.51075657302607, 12.983827660966472],
						[77.51072542348018, 12.984211089767687],
						[77.51075211059167, 12.984549993614557],
						[77.51079673492802, 12.98481312480534],
						[77.51088143362037, 12.98498698634922],
						[77.51105984316017, 12.985276867024098],
						[77.51123816492249, 12.985459666743527],
						[77.51138533704035, 12.985709323865176],
						[77.51156365829371, 12.986039378366858],
						[77.51175991634786, 12.986413928515557],
						[77.51194716179131, 12.98689098375901],
						[77.51210772013933, 12.987421666257882],
						[77.51224150400702, 12.987970106677572],
						[77.5118089151852, 12.988126212516391],
						[77.51118024100477, 12.988331346574022],
						[77.51086358390118, 12.988375906333838],
						[77.51061832497662, 12.98827779339636],
						[77.5101679676686, 12.98813067550692],
						[77.50979784560425, 12.98810832355886],
						[77.5094233473522, 12.988126193751194],
						[77.50927171021021, 12.988175222080011],
						[77.50905768537248, 12.988393740395566],
						[77.50871433479396, 12.988772724860162],
						[77.50850923395535, 12.988910978176264],
						[77.50824165730691, 12.988977917153035],
						[77.50766642663834, 12.989031393194244],
						[77.50685931379513, 12.989218715730138],
						[77.50622168711209, 12.989410380539486],
						[77.50571784838496, 12.989642332845518],
						[77.50567768469496, 12.989713666671483],
						[77.50565090889378, 12.989718057359749],
						[77.50557513159232, 12.989722570049784],
						[77.50487948311347, 12.99003470235642],
						[77.50449604335928, 12.990177399697181],
						[77.50437117577651, 12.990204100454967],
						[77.50428647225087, 12.990208610418916],
						[77.50413482828193, 12.990213060930538],
						[77.50396095811695, 12.990208586834065],
						[77.50281491346811, 12.99001681920981],
						[77.50144151732746, 12.989526312624545],
						[77.50054527883835, 12.989165186991396],
						[77.50009488177675, 12.988977875050224],
						[77.49985852542908, 12.988919930009088],
						[77.49954638728879, 12.988906498437036],
						[77.49934573285577, 12.988910980656645],
						[77.49912276362612, 12.988915499930286],
						[77.49888202987572, 12.98891993229594],
						[77.49819089104719, 12.988857489603053],
						[77.49661678006655, 12.988482922803412],
						[77.49573835580381, 12.98821093656204],
						[77.4956179416706, 12.988005766972984],
						[77.49502488271078, 12.986685868270392],
						[77.49383429246102, 12.983140898105068],
						[77.49309411624623, 12.98084893218399],
						[77.49283096660712, 12.97885128897773],
						[77.49303609560967, 12.974525947109571],
						[77.49303163248256, 12.974414445075409],
						[77.49313419684977, 12.973874892521541],
						[77.49316543870785, 12.97373219521281],
						[77.49316543870785, 12.973473594149016],
						[77.49307180062027, 12.972541625479192],
						[77.4931431230958, 12.971747915393413],
						[77.4930405587366, 12.970490474576826],
						[77.4930405587366, 12.970481509980724],
						[77.49372726562216, 12.970454746192583],
						[77.49441843145446, 12.970374495083105],
						[77.49537711302345, 12.970178351632972],
						[77.495533231652, 12.970142652811685],
						[77.49556447285418, 12.970142616632717],
						[77.49610397293934, 12.970044573017427],
						[77.49641165749189, 12.970004415762064],
						[77.49707602927373, 12.970205071007669],
						[77.49776279941452, 12.970280874854488],
						[77.49814626178183, 12.970263009394783],
						[77.49846732957884, 12.97020056942083],
						[77.49805709070722, 12.9697636439524],
						[77.49880616057332, 12.96946043130817],
						[77.49837369583646, 12.968684534730306],
						[77.4982488215578, 12.968626588324708],
						[77.49792329021051, 12.968599769720257],
						[77.49747743327102, 12.968354552208709],
						[77.49721875669053, 12.968207367726354],
						[77.4970448759941, 12.96803352296444],
						[77.4967104152491, 12.96768122136561],
						[77.49673273016445, 12.967583090820078],
						[77.49681297631426, 12.967311152349538],
						[77.49682636525198, 12.967217502426944],
						[77.49681743929366, 12.967128287088627],
						[77.49682190227291, 12.967074803044008],
						[77.49679512439486, 12.966918695699281],
						[77.49674165612939, 12.966829561858788],
						[77.49646075042831, 12.966129475629744],
						[77.49632248506322, 12.965442775293207],
						[77.49636265204413, 12.96530448330952],
						[77.49646521342149, 12.965224295313668],
						[77.49654099676916, 12.96516185805855],
						[77.49655438573875, 12.96514845742596],
						[77.4966524839583, 12.965050382987123],
						[77.49678173545348, 12.964809528642373],
						[77.49680851333467, 12.964680241736994],
						[77.49679958737497, 12.96453310556195],
						[77.49675950805714, 12.964470653808833],
						[77.49667917436028, 12.964430557363265],
						[77.49659000214041, 12.964426061726652],
						[77.49652314480726, 12.96442609572125],
						[77.49644736144774, 12.964417163683915],
						[77.49633587405842, 12.964350301814372],
						[77.49580521359624, 12.9637081997859],
						[77.49542620640442, 12.962718252895847],
						[77.49522554473378, 12.96239271413076],
						[77.49492232004047, 12.962325866943772],
						[77.49438727745019, 12.962517562715679],
						[77.49313865997289, 12.96286091859053],
						[77.49292469240439, 12.962860972382938],
						[77.49272848948031, 12.962843097496759],
						[77.49240294240472, 12.962740515252918],
						[77.49224244385469, 12.962646884789079],
						[77.49208632073046, 12.962522006498872],
						[77.49204623975946, 12.96238827542374],
						[77.4920417765937, 12.96220990058729],
						[77.49202392392891, 12.96212965232875],
						[77.49197482908636, 12.96211623906619],
						[77.49188119002335, 12.962147467765687],
						[77.49183664580666, 12.96219650550279],
						[77.49179647723487, 12.962285748373333],
						[77.49178308770779, 12.962343709431957],
						[77.49179201405936, 12.962464035084716],
						[77.49179201405936, 12.962593409095474],
						[77.49150216981747, 12.96263796340434],
						[77.49104289820956, 12.9627449777088],
						[77.49092247912898, 12.962767322256463],
						[77.49049444778026, 12.962878780912439],
						[77.49018228405842, 12.962990256088819],
						[77.4900485618557, 12.962990234531741],
						[77.48992814172777, 12.96204042579445],
						[77.48860377449722, 12.962281218953553],
						[77.48718128566418, 12.962834166640958],
						[77.48623154966612, 12.963231023457253],
						[77.48608880852987, 12.963146353545184],
						[77.48590160820446, 12.96091227698234],
						[77.48551810475784, 12.95786227077498],
						[77.485049882239, 12.956399734396586],
						[77.48501863816345, 12.955418723881403],
						[77.48566522250314, 12.955044149589114],
						[77.48562513928135, 12.954830105325541],
						[77.48562513928135, 12.954758744497093],
						[77.48562513928135, 12.954678506709147],
						[77.48556265143597, 12.954393136544729],
						[77.48558943194531, 12.954067624859965],
						[77.48558943194531, 12.953893705610664],
						[77.48563844201153, 12.953505755755927],
						[77.48562067586495, 12.9532337609299],
						[77.48554479775959, 12.952979546915241],
						[77.48549578765325, 12.952903742511646],
						[77.4853887530087, 12.952863648601769],
						[77.48531742569612, 12.952814599326333],
						[77.48519700058645, 12.95273429817243],
						[77.48509442909945, 12.952645124081707],
						[77.48505880911615, 12.952555921410692],
						[77.48507219943058, 12.952386532150472],
						[77.48508558974346, 12.952110018269597],
						[77.485081126306, 12.952034219863947],
						[77.4849740912735, 12.951949483936984],
						[77.48496070094758, 12.9518736837524],
						[77.48489830025778, 12.951766722515517],
						[77.48481804574415, 12.951722067229904],
						[77.48467083939066, 12.951583847038712],
						[77.48480911884819, 12.951445593452167],
						[77.48513460002553, 12.951396565066762],
						[77.48543785067903, 12.951369813724916],
						[77.48547793396644, 12.951356416613317],
						[77.48565183225811, 12.951329685061488],
						[77.4859996280484, 12.951195899792433],
						[77.48636973971968, 12.95096848166774],
						[77.48674877693861, 12.950678668966907],
						[77.48746667751516, 12.950192583993084],
						[77.48815788119967, 12.949706581800179],
						[77.48866170986773, 12.949407818401658],
						[77.48870634283706, 12.949354308185978],
						[77.48871080613304, 12.949274031082734],
						[77.48870634283706, 12.949189357104794],
						[77.48871526942884, 12.949140235467434],
						[77.48863501759342, 12.948984236660765],
						[77.48947332706275, 12.948190455020951],
						[77.49027592451301, 12.947490429464013],
						[77.49051230068392, 12.947552814114847],
						[77.49058808797918, 12.947561742841724],
						[77.49125249361775, 12.947570661953764],
						[77.49192135856538, 12.947584018157222],
						[77.49237170033823, 12.947619708473438],
						[77.49284435601143, 12.947637519044005],
						[77.49346866806309, 12.947601916576016],
						[77.4945477729881, 12.947499313554173],
						[77.49535042231729, 12.947378921856977],
						[77.49580521359624, 12.947325450722994],
						[77.49642504647665, 12.947320993091058],
						[77.49674611911158, 12.94732542280114],
						[77.4966970262978, 12.947191618114214],
						[77.49664802097244, 12.947084620393673],
						[77.49658553915212, 12.946973178245509],
						[77.497147436759, 12.946906274924794],
						[77.49753536414347, 12.94687507215205],
						[77.49808386828943, 12.946839405215584],
						[77.49882401217842, 12.94666995225985],
						[77.49889541857091, 12.946629800987496],
						[77.49888202987572, 12.946571841751183],
						[77.4996044046191, 12.94638454767709],
						[77.49920755849041, 12.944676699508719],
						[77.49919863271819, 12.944658858966754],
						[77.50010380747892, 12.944311064700914],
						[77.49989869116648, 12.943392538315821],
						[77.49937251013586, 12.943584246234233],
						[77.4976334615206, 12.941011322848555],
						[77.49745958145546, 12.940610024673655],
						[77.49716975158898, 12.940012493565051],
						[77.49716528862332, 12.939963415359076],
						[77.49716528862332, 12.939932219383607],
						[77.49719197890549, 12.939896541238728],
						[77.4972142937268, 12.939878748452738],
						[77.49788321128695, 12.939383804126741],
						[77.498449477918, 12.938955676042397],
						[77.49895334872419, 12.938661375656155],
						[77.49912722651528, 12.938549917149466],
						[77.49937697301526, 12.93838042693944],
						[77.49947506881068, 12.93820213474517],
						[77.49959547887791, 12.937903327988368],
						[77.49908268512345, 12.935727335029329],
						[77.49993885688978, 12.935455332782857],
						[77.49980059573497, 12.934813199120903],
						[77.49967572301645, 12.93398825448529],
						[77.4993145801952, 12.931825619379206],
						[77.49910499957537, 12.930750929511806],
						[77.49856988906652, 12.930835696502154],
						[77.49863674520174, 12.930255988039347],
						[77.4986322822934, 12.929872488750208],
						[77.49853418577409, 12.929212513004318],
						[77.49851633412369, 12.928655170535048],
						[77.49859220361861, 12.927709865619308],
						[77.49871699001392, 12.926880415222918],
						[77.49893996003577, 12.926002034121877],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000bb",
			properties: {
				AREA_SQ_KM: 12.26984871761289,
				ASS_CONST1: "Rajarajeswari Nagar",
				ASS_CONST_: "154",
				LAT: 12.9593058007956,
				LON: 77.5017879872547,
				LOST_AREA_SQ_KM: 0.42447921334219524,
				OBJECTID: 188,
				POP_F: 12131.0,
				POP_M: 13758.0,
				POP_SC: 3732.0,
				POP_ST: 649.0,
				POP_TOTAL: 25889.0,
				RESERVATIO: "Backward Category - B",
				WARD_NAME: "Jnana Bharathi ward",
				WARD_NO: 129.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52828532947186, 13.08383191219592],
						[77.5281649497031, 13.083733797587929],
						[77.52782603097839, 13.084005821228743],
						[77.52794194934447, 13.084295627558776],
						[77.52794194934447, 13.084447239274965],
						[77.52787511039158, 13.084572120262418],
						[77.52781710744645, 13.084808401669454],
						[77.52773688311315, 13.084893108977791],
						[77.52768334186905, 13.0849332975342],
						[77.52759419389204, 13.084942237992392],
						[77.5274781876859, 13.084937701416859],
						[77.52737565419862, 13.084933304204071],
						[77.52709473646637, 13.085017972712881],
						[77.52639913183525, 13.085414888800003],
						[77.52610482619396, 13.085548606021883],
						[77.52597105860427, 13.08553077620109],
						[77.52533782572284, 13.085521887397354],
						[77.52514167854245, 13.085646758051809],
						[77.5243212162453, 13.085887515844378],
						[77.5241071321802, 13.085949985276773],
						[77.52410267026856, 13.086097065083743],
						[77.52164570519004, 13.087011180704629],
						[77.52064691094154, 13.085227581458925],
						[77.5186536708047, 13.082507506076038],
						[77.51730251378541, 13.080951326530753],
						[77.51733821120469, 13.080090654860342],
						[77.51765038815951, 13.078636994093106],
						[77.51749876195952, 13.077375130698305],
						[77.51708850402262, 13.075921457731665],
						[77.51699488556177, 13.075435356458048],
						[77.516870031663, 13.075025127116247],
						[77.51678087522129, 13.07496271091271],
						[77.51654446594789, 13.074953852244526],
						[77.51443093475609, 13.075711893204245],
						[77.51245998523767, 13.07641638713661],
						[77.51170190519716, 13.076541247448153],
						[77.50993609464824, 13.077031744773226],
						[77.50802763053662, 13.077945883859064],
						[77.50726068339561, 13.078788663658848],
						[77.5067701495261, 13.079542269177853],
						[77.50626631322069, 13.080019384974877],
						[77.50618598621264, 13.080313649634972],
						[77.50607450833219, 13.080603459621775],
						[77.50592741686488, 13.080964697331968],
						[77.50574899929819, 13.08115192770378],
						[77.50562859572133, 13.081214381102033],
						[77.50533432394468, 13.081325890510264],
						[77.50494196039547, 13.081423978981737],
						[77.5047056140829, 13.08144625458877],
						[77.5045628960244, 13.081441784042168],
						[77.50385394076744, 13.0805410541173],
						[77.50384947806305, 13.080523234766519],
						[77.50384055265371, 13.08052323010623],
						[77.50329653888654, 13.079827631421345],
						[77.50169572155458, 13.077990442252922],
						[77.50167787039841, 13.077986032686878],
						[77.50162431691318, 13.077990491084268],
						[77.50087080271179, 13.077103060801939],
						[77.5008574142482, 13.077027258831842],
						[77.49866798555517, 13.074512401508025],
						[77.4985832777983, 13.074516804207335],
						[77.4986903000881, 13.074218085613266],
						[77.49884632668088, 13.07313001974313],
						[77.49899351477995, 13.07283576784099],
						[77.49953746154306, 13.072202514885879],
						[77.50105360321123, 13.070093416330044],
						[77.50273913428376, 13.067645352413638],
						[77.50396095811695, 13.065750265902997],
						[77.50530308541538, 13.063569728053556],
						[77.50551711729636, 13.06323086772526],
						[77.50605674536315, 13.062379152335053],
						[77.50638671612465, 13.061879710111223],
						[77.50666759740531, 13.061589920569105],
						[77.5068503886212, 13.061326834079487],
						[77.50698417865537, 13.061059287217345],
						[77.50712689368568, 13.060849716447082],
						[77.50752817484366, 13.060203131244851],
						[77.50804548069961, 13.059395969279814],
						[77.50868309719004, 13.059329149721504],
						[77.50933409758731, 13.059280061720814],
						[77.50968637125308, 13.059204242651322],
						[77.5101545802957, 13.05901252527417],
						[77.51058717539298, 13.058927766929578],
						[77.51094837004247, 13.058869806457201],
						[77.51126047699776, 13.058807381842056],
						[77.5113273256991, 13.059097220852957],
						[77.51138087463055, 13.059409359466745],
						[77.51147896010804, 13.05989991581014],
						[77.51148788491983, 13.05997566296777],
						[77.51164398151498, 13.059922195484015],
						[77.51167959320827, 13.059899862876991],
						[77.51201409758785, 13.05978840774497],
						[77.51226819076369, 13.059944517975866],
						[77.51295049463735, 13.060314572889936],
						[77.51337406696796, 13.06061783872358],
						[77.51350784934979, 13.060711406021927],
						[77.51394489403543, 13.061063726870005],
						[77.51415890970081, 13.061233154682865],
						[77.51433722661638, 13.061219762159899],
						[77.51471625850543, 13.061063714820932],
						[77.51516222308241, 13.060898765189213],
						[77.51543862049948, 13.06082294819339],
						[77.51573295370085, 13.060773885929013],
						[77.51609859427377, 13.060729247624895],
						[77.51724013076851, 13.060519687509558],
						[77.51769046013942, 13.06037254369635],
						[77.51798032537359, 13.060283403613886],
						[77.51839950437027, 13.060078233305198],
						[77.51879637122899, 13.059797351444686],
						[77.51926454285582, 13.059489662285687],
						[77.5195990249759, 13.059164157617118],
						[77.52010288985741, 13.059124002545161],
						[77.5203615145501, 13.059173093924093],
						[77.52010735192576, 13.05870037730813],
						[77.51969710338156, 13.05788438709288],
						[77.5197460988068, 13.057532123448595],
						[77.51979518170276, 13.057429525644931],
						[77.51977287129813, 13.057349244445618],
						[77.51967033087242, 13.057206565255797],
						[77.51947417393059, 13.056997040484196],
						[77.5193581594519, 13.056961367901456],
						[77.51926454285582, 13.056577833000542],
						[77.51954547990525, 13.056252313218803],
						[77.51979518170276, 13.055583481252501],
						[77.5194384771805, 13.054874506830258],
						[77.51884090492383, 13.053777515436161],
						[77.52005380709404, 13.053237987227197],
						[77.5199512669212, 13.052667266343784],
						[77.51936262154942, 13.050076476330066],
						[77.51859566316236, 13.048426634021869],
						[77.51922884604004, 13.047953951156893],
						[77.52045066819, 13.047093363657433],
						[77.52189986438181, 13.046076661442173],
						[77.52219864287851, 13.045889350851649],
						[77.52259549650137, 13.04650032697145],
						[77.52299234874131, 13.047151332480446],
						[77.5232064349023, 13.047695367965739],
						[77.52351850828688, 13.048484589659514],
						[77.52377275078771, 13.048934920727017],
						[77.52393766690334, 13.049135659169297],
						[77.52434790017288, 13.049416571385127],
						[77.52501237169723, 13.049782232443604],
						[77.52559206416116, 13.050196907705145],
						[77.52608251702543, 13.050566955989073],
						[77.52663543316832, 13.051017339093072],
						[77.5268940430247, 13.051191240472154],
						[77.52733995992543, 13.051324998759616],
						[77.52805340583569, 13.05152120495946],
						[77.5283700151631, 13.051623825869683],
						[77.5287535495709, 13.051873509636954],
						[77.52944467810985, 13.05244423634458],
						[77.52995304858736, 13.05281438683521],
						[77.53030525850002, 13.053028386942001],
						[77.53047025263453, 13.053157707594414],
						[77.53067093976937, 13.053389612725546],
						[77.53100985002075, 13.054036208726956],
						[77.53117484313344, 13.054334963344822],
						[77.53125952667418, 13.054446402639263],
						[77.53139328804761, 13.054504413662498],
						[77.53186595696477, 13.054575722011116],
						[77.53235200868369, 13.054611361214066],
						[77.53273999124161, 13.054615829898559],
						[77.53290052027816, 13.05466487663165],
						[77.53313680811002, 13.054731757206818],
						[77.53337764448048, 13.054812058292631],
						[77.53352478799091, 13.054852171752968],
						[77.53382799750187, 13.054878913647567],
						[77.53433634839097, 13.0548878552743],
						[77.53467524772128, 13.054887863046174],
						[77.5353931091004, 13.054834353757576],
						[77.53516128743465, 13.05550323729498],
						[77.53509436524322, 13.055748442962472],
						[77.53509436524322, 13.055913480023344],
						[77.53512113412454, 13.05602495083086],
						[77.53515682595645, 13.056163168654141],
						[77.53519689177404, 13.056350429233632],
						[77.53517913334571, 13.056551096286675],
						[77.53513898004188, 13.056751795727267],
						[77.53513005708356, 13.056881114817395],
						[77.53514790299951, 13.057028214308279],
						[77.53510774968467, 13.057175339352487],
						[77.53494722376361, 13.057228869384051],
						[77.53487137846757, 13.057344839190302],
						[77.53486245548825, 13.057465186456076],
						[77.53512113412454, 13.057536523052162],
						[77.53541095497508, 13.05761679404258],
						[77.53497845416491, 13.058232187119447],
						[77.53451025968612, 13.058909945326569],
						[77.53418474441419, 13.059529769686362],
						[77.53392160212124, 13.059944451605546],
						[77.53416689834708, 13.060439438116054],
						[77.53450579818238, 13.061549723859581],
						[77.53478223612304, 13.062459409441512],
						[77.53512113412454, 13.063306659110996],
						[77.5347331596896, 13.063917510695967],
						[77.53459940224386, 13.064323318872065],
						[77.53451918269307, 13.06455961040158],
						[77.53438979902357, 13.064831643733834],
						[77.53424265663229, 13.065313244707003],
						[77.53406874492792, 13.065843844127699],
						[77.53380568984757, 13.065839406471387],
						[77.53380568984757, 13.065803773561147],
						[77.53352032644851, 13.065808171541642],
						[77.53315019278183, 13.065799296475625],
						[77.5330208950365, 13.06578592648696],
						[77.53287375087419, 13.065772550308292],
						[77.53282913518689, 13.06578142161745],
						[77.53275337596011, 13.06584390818559],
						[77.5325839235578, 13.066013304022318],
						[77.53209787282982, 13.066508255203525],
						[77.53170542646816, 13.066931925471252],
						[77.5312506034115, 13.067391210675169],
						[77.53086716490373, 13.067810315074277],
						[77.53039003017165, 13.068309754357402],
						[77.5301848808681, 13.068488109404633],
						[77.52999311623795, 13.068697659158621],
						[77.52992181680625, 13.068858203188531],
						[77.52988612333165, 13.069241702654123],
						[77.52977466863545, 13.069665342839027],
						[77.52974789849593, 13.069941774215652],
						[77.52970774327483, 13.070797963339428],
						[77.52971220496676, 13.071141319769614],
						[77.52973005173274, 13.07144451830156],
						[77.52973005173274, 13.07149352604981],
						[77.52978359201389, 13.071903814319588],
						[77.53009127317387, 13.071836923716104],
						[77.5304168001601, 13.071770039243866],
						[77.5308939347799, 13.071743240909743],
						[77.53138436479541, 13.071841335183095],
						[77.53162074325937, 13.072135683146543],
						[77.53138882642159, 13.072608342722829],
						[77.53121937198658, 13.073870258016008],
						[77.53135768251751, 13.075395249587753],
						[77.53108561040867, 13.078360567873299],
						[77.53095631031663, 13.080327051589796],
						[77.5307868552374, 13.0812367256903],
						[77.53020272755995, 13.081972421273186],
						[77.52971666665852, 13.082503043490194],
						[77.52845032653524, 13.083492987946604],
						[77.52828532947186, 13.08383191219592],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000001d",
			properties: {
				AREA_SQ_KM: 9.124481447601815,
				ASS_CONST1: "Dasarahalli",
				ASS_CONST_: "155",
				LAT: 13.0679966887335,
				LON: 77.5194091911426,
				LOST_AREA_SQ_KM: 0.518091485620207,
				OBJECTID: 30,
				POP_F: 13286.0,
				POP_M: 15023.0,
				POP_SC: 6418.0,
				POP_ST: 522.0,
				POP_TOTAL: 28309.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Shettihalli",
				WARD_NO: 12.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.50804548069961, 13.059395969279814],
						[77.50860285938391, 13.058562122694703],
						[77.50923154777128, 13.057523163842458],
						[77.5098290829029, 13.05651547047847],
						[77.51062724985576, 13.055177677593342],
						[77.51124708975313, 13.054236812104763],
						[77.51151019694623, 13.053813226035292],
						[77.51169744279973, 13.053550103075002],
						[77.51182230236387, 13.053394098524777],
						[77.51204524678172, 13.053184473282256],
						[77.5116840556064, 13.051360706996688],
						[77.51135410016795, 13.051445470227003],
						[77.51121585284285, 13.050901410203265],
						[77.51155027108466, 13.050816691953136],
						[77.51167513080996, 13.050736427635917],
						[77.5117554539527, 13.050696347748302],
						[77.51184907671653, 13.050638369584105],
						[77.51147003529556, 13.049247061197923],
						[77.51174652916185, 13.049184708203146],
						[77.51159935751015, 13.048658511413521],
						[77.51181337757826, 13.04862278816132],
						[77.51249568417349, 13.048319610782793],
						[77.51344546425602, 13.04796735126793],
						[77.51385564780932, 13.047811272269314],
						[77.51448439473405, 13.04761063006152],
						[77.51422575670281, 13.047062103126962],
						[77.51423914359772, 13.047057665740853],
						[77.51415444739953, 13.046790105104535],
						[77.51500166903607, 13.046540408711124],
						[77.51523799401431, 13.046883756365746],
						[77.51531376489582, 13.046955073501008],
						[77.51554125222823, 13.046981849906864],
						[77.51566164534043, 13.046995216784559],
						[77.51609413204845, 13.04699082863995],
						[77.51677641302265, 13.047334154070782],
						[77.51707065527529, 13.04744560962132],
						[77.51719997113648, 13.047356415406135],
						[77.5173337490279, 13.047534759752184],
						[77.51733821120469, 13.047534804484075],
						[77.51774400608184, 13.047971805379259],
						[77.51831481126378, 13.048631719823263],
						[77.51859566316236, 13.048426634021869],
						[77.51936262154942, 13.050076476330066],
						[77.5199512669212, 13.052667266343784],
						[77.52005380709404, 13.053237987227197],
						[77.51884090492383, 13.053777515436161],
						[77.5194384771805, 13.054874506830258],
						[77.51979518170276, 13.055583481252501],
						[77.51954547990525, 13.056252313218803],
						[77.51926454285582, 13.056577833000542],
						[77.5193581594519, 13.056961367901456],
						[77.51947417393059, 13.056997040484196],
						[77.51967033087242, 13.057206565255797],
						[77.51977287129813, 13.057349244445618],
						[77.51979518170276, 13.057429525644931],
						[77.5197460988068, 13.057532123448595],
						[77.51969710338156, 13.05788438709288],
						[77.52010735192576, 13.05870037730813],
						[77.5203615145501, 13.059173093924093],
						[77.52010288985741, 13.059124002545161],
						[77.5195990249759, 13.059164157617118],
						[77.51926454285582, 13.059489662285687],
						[77.51879637122899, 13.059797351444686],
						[77.51839950437027, 13.060078233305198],
						[77.51798032537359, 13.060283403613886],
						[77.51769046013942, 13.06037254369635],
						[77.51724013076851, 13.060519687509558],
						[77.51609859427377, 13.060729247624895],
						[77.51573295370085, 13.060773885929013],
						[77.51543862049948, 13.06082294819339],
						[77.51516222308241, 13.060898765189213],
						[77.51471625850543, 13.061063714820932],
						[77.51433722661638, 13.061219762159899],
						[77.51415890970081, 13.061233154682865],
						[77.51394489403543, 13.061063726870005],
						[77.51350784934979, 13.060711406021927],
						[77.51337406696796, 13.06061783872358],
						[77.51295049463735, 13.060314572889936],
						[77.51226819076369, 13.059944517975866],
						[77.51201409758785, 13.05978840774497],
						[77.51167959320827, 13.059899862876991],
						[77.51164398151498, 13.059922195484015],
						[77.51148788491983, 13.05997566296777],
						[77.51147896010804, 13.05989991581014],
						[77.51138087463055, 13.059409359466745],
						[77.5113273256991, 13.059097220852957],
						[77.51126047699776, 13.058807381842056],
						[77.51094837004247, 13.058869806457201],
						[77.51058717539298, 13.058927766929578],
						[77.5101545802957, 13.05901252527417],
						[77.50968637125308, 13.059204242651322],
						[77.50933409758731, 13.059280061720814],
						[77.50868309719004, 13.059329149721504],
						[77.50804548069961, 13.059395969279814],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000001e",
			properties: {
				AREA_SQ_KM: 1.318828515220898,
				ASS_CONST1: "Dasarahalli",
				ASS_CONST_: "155",
				LAT: 13.0544363601233,
				LON: 77.5151259164154,
				LOST_AREA_SQ_KM: 0.024816380198549762,
				OBJECTID: 31,
				POP_F: 12187.0,
				POP_M: 14066.0,
				POP_SC: 2749.0,
				POP_ST: 289.0,
				POP_TOTAL: 26253.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Mallasandra",
				WARD_NO: 13.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.49440504223456, 13.04978220623298],
						[77.49551091650235, 13.049376410674522],
						[77.49805709070722, 13.048493473581205],
						[77.49959101600705, 13.047931703851669],
						[77.50137466283431, 13.047280658704741],
						[77.5028907801069, 13.04672325565407],
						[77.50416606713102, 13.04626839291655],
						[77.50525408378353, 13.045884959194705],
						[77.50603443227006, 13.045688712533],
						[77.50729183388763, 13.04533197949542],
						[77.50730968416576, 13.04532310219823],
						[77.50736323498334, 13.045528241395182],
						[77.50747024904189, 13.045769022127997],
						[77.5077600527586, 13.046500283034732],
						[77.50804548069961, 13.047030959117357],
						[77.50809001859412, 13.047209267759293],
						[77.50809448113269, 13.047320758004346],
						[77.50808109351645, 13.04744561120496],
						[77.50798746765967, 13.047918316292867],
						[77.5078448412068, 13.048582717545862],
						[77.50774220255094, 13.04898848136232],
						[77.50769766452065, 13.049380860915075],
						[77.50773773999859, 13.049800051537796],
						[77.50780021571559, 13.050571414389646],
						[77.50828628262545, 13.050451048322637],
						[77.50886597267689, 13.050339556470181],
						[77.50876779745198, 13.049421019313494],
						[77.50906214787321, 13.04938533178868],
						[77.50991378231389, 13.049153470597714],
						[77.51040430266573, 13.049046427264877],
						[77.51037752797389, 13.048859146600858],
						[77.51080565979318, 13.04879227869988],
						[77.511198003176, 13.048734276373866],
						[77.51159935751015, 13.048658511413521],
						[77.51174652916185, 13.049184708203146],
						[77.51147003529556, 13.049247061197923],
						[77.51184907671653, 13.050638369584105],
						[77.5117554539527, 13.050696347748302],
						[77.51167513080996, 13.050736427635917],
						[77.51155027108466, 13.050816691953136],
						[77.51121585284285, 13.050901410203265],
						[77.51135410016795, 13.051445470227003],
						[77.5116840556064, 13.051360706996688],
						[77.51204524678172, 13.053184473282256],
						[77.51182230236387, 13.053394098524777],
						[77.51169744279973, 13.053550103075002],
						[77.51151019694623, 13.053813226035292],
						[77.51124708975313, 13.054236812104763],
						[77.51062724985576, 13.055177677593342],
						[77.5098290829029, 13.05651547047847],
						[77.50923154777128, 13.057523163842458],
						[77.50860285938391, 13.058562122694703],
						[77.50804548069961, 13.059395969279814],
						[77.50752817484366, 13.060203131244851],
						[77.50712689368568, 13.060849716447082],
						[77.50698417865537, 13.061059287217345],
						[77.5068503886212, 13.061326834079487],
						[77.50666759740531, 13.061589920569105],
						[77.50638671612465, 13.061879710111223],
						[77.50605674536315, 13.062379152335053],
						[77.50551711729636, 13.06323086772526],
						[77.50530308541538, 13.063569728053556],
						[77.50396095811695, 13.065750265902997],
						[77.50273913428376, 13.067645352413638],
						[77.50105360321123, 13.070093416330044],
						[77.49953746154306, 13.072202514885879],
						[77.49899351477995, 13.07283576784099],
						[77.49884632668088, 13.07313001974313],
						[77.4986903000881, 13.074218085613266],
						[77.4985832777983, 13.074516804207335],
						[77.49839154750917, 13.074628308351484],
						[77.49726338631828, 13.074846764130802],
						[77.49672380419885, 13.075016242040949],
						[77.49631355906556, 13.075060814565466],
						[77.49597909617948, 13.07502514422949],
						[77.49505166100376, 13.074815552727646],
						[77.49457455139287, 13.074855732815092],
						[77.49432925747016, 13.074913712634954],
						[77.4941910770371, 13.074944919678158],
						[77.4940037149544, 13.074949393410707],
						[77.49380305078579, 13.074944934484677],
						[77.49330817100963, 13.074895812182886],
						[77.49300494122855, 13.074851225730564],
						[77.4929068398775, 13.074828916841037],
						[77.49279981217009, 13.074797716754174],
						[77.49271063692278, 13.074762040325302],
						[77.4926169984626, 13.07302751291993],
						[77.49275518079888, 13.069839203180564],
						[77.49279534903377, 13.069808038171077],
						[77.4927819596237, 13.069616295379127],
						[77.4926972475028, 13.068706582006657],
						[77.49265707923344, 13.068283021277953],
						[77.49277303334945, 13.065380156612258],
						[77.49278642276056, 13.06471569331565],
						[77.49277749648667, 13.06443480491904],
						[77.4930227062278, 13.06259767475864],
						[77.49308072687117, 13.062160643193232],
						[77.49287559794874, 13.061924353121801],
						[77.49231823000291, 13.060778374565444],
						[77.49097603763106, 13.056912301435604],
						[77.49110091985789, 13.056796340411577],
						[77.49164936737758, 13.055895647937728],
						[77.49226913528676, 13.054874484757896],
						[77.49241633185918, 13.054544482571606],
						[77.49241633185918, 13.054504372082205],
						[77.4924341844627, 13.053853385216653],
						[77.49242525816128, 13.053594691459157],
						[77.49242525816128, 13.05356795134419],
						[77.49259914588764, 13.053527875058613],
						[77.49260360903165, 13.053505579444677],
						[77.49257236701999, 13.053313768662255],
						[77.49250997048377, 13.05303737111774],
						[77.49251443363124, 13.05289465942254],
						[77.49250997048377, 13.05248885735838],
						[77.49255451443808, 13.051655024834137],
						[77.49300494122855, 13.051570305975545],
						[77.49364255420194, 13.051454351882985],
						[77.4943024790079, 13.051307224201123],
						[77.49449421615974, 13.051262582589157],
						[77.4945834775264, 13.051240284361148],
						[77.49446306217612, 13.050745331275708],
						[77.49428025112924, 13.0499962438501],
						[77.49424008341627, 13.049840144168185],
						[77.49440504223456, 13.04978220623298],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000001f",
			properties: {
				AREA_SQ_KM: 4.436917614882313,
				ASS_CONST1: "Dasarahalli",
				ASS_CONST_: "155",
				LAT: 13.0588925021938,
				LON: 77.5002800050134,
				LOST_AREA_SQ_KM: 0.14589148919973935,
				OBJECTID: 32,
				POP_F: 13147.0,
				POP_M: 15159.0,
				POP_SC: 1627.0,
				POP_ST: 641.0,
				POP_TOTAL: 28306.0,
				RESERVATIO: "General",
				WARD_NAME: "Bagalakunte",
				WARD_NO: 14.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52219864287851, 13.045889350851649],
						[77.52189986438181, 13.046076661442173],
						[77.52045066819, 13.047093363657433],
						[77.51922884604004, 13.047953951156893],
						[77.51859566316236, 13.048426634021869],
						[77.51831481126378, 13.048631719823263],
						[77.51774400608184, 13.047971805379259],
						[77.51733821120469, 13.047534804484075],
						[77.5173337490279, 13.047534759752184],
						[77.51719997113648, 13.047356415406135],
						[77.51707065527529, 13.04744560962132],
						[77.51677641302265, 13.047334154070782],
						[77.51609413204845, 13.04699082863995],
						[77.51566164534043, 13.046995216784559],
						[77.51554125222823, 13.046981849906864],
						[77.51531376489582, 13.046955073501008],
						[77.51523799401431, 13.046883756365746],
						[77.51500166903607, 13.046540408711124],
						[77.51415444739953, 13.046790105104535],
						[77.51423914359772, 13.047057665740853],
						[77.51422575670281, 13.047062103126962],
						[77.51448439473405, 13.04761063006152],
						[77.51385564780932, 13.047811272269314],
						[77.51344546425602, 13.04796735126793],
						[77.51249568417349, 13.048319610782793],
						[77.51181337757826, 13.04862278816132],
						[77.51159935751015, 13.048658511413521],
						[77.511198003176, 13.048734276373866],
						[77.51080565979318, 13.04879227869988],
						[77.51037752797389, 13.048859146600858],
						[77.51040430266573, 13.049046427264877],
						[77.50991378231389, 13.049153470597714],
						[77.50906214787321, 13.04938533178868],
						[77.50876779745198, 13.049421019313494],
						[77.50886597267689, 13.050339556470181],
						[77.50828628262545, 13.050451048322637],
						[77.50780021571559, 13.050571414389646],
						[77.50773773999859, 13.049800051537796],
						[77.50769766452065, 13.049380860915075],
						[77.50774220255094, 13.04898848136232],
						[77.5078448412068, 13.048582717545862],
						[77.50798746765967, 13.047918316292867],
						[77.50808109351645, 13.04744561120496],
						[77.50809448113269, 13.047320758004346],
						[77.50809001859412, 13.047209267759293],
						[77.50804548069961, 13.047030959117357],
						[77.5077600527586, 13.046500283034732],
						[77.50747024904189, 13.045769022127997],
						[77.50736323498334, 13.045528241395182],
						[77.50730968416576, 13.04532310219823],
						[77.50734092214574, 13.045300817362746],
						[77.5074657864789, 13.04526955172902],
						[77.50824611983954, 13.045010931947234],
						[77.50897744782984, 13.044685402048414],
						[77.5098647826623, 13.044243948948449],
						[77.51035084077478, 13.044007646066682],
						[77.51101968135087, 13.04365982990809],
						[77.5114521856685, 13.043432388290384],
						[77.5122370416307, 13.043178284998774],
						[77.51358808370492, 13.042727909992005],
						[77.51509975139862, 13.042232900634275],
						[77.51592019261888, 13.041943079126835],
						[77.51634830362485, 13.041773635346848],
						[77.51680309871769, 13.041448158117849],
						[77.51683433409706, 13.041421386311127],
						[77.51725351730937, 13.040993341933115],
						[77.5180249468805, 13.04020403465941],
						[77.51838611796403, 13.039936534400196],
						[77.51873836365931, 13.04059645112058],
						[77.51930023966041, 13.041452628328395],
						[77.51984426457757, 13.042259726779227],
						[77.52033028013767, 13.043040029658634],
						[77.520901072362, 13.043869405169671],
						[77.52149854632131, 13.044783499503769],
						[77.52214063704133, 13.045800181208762],
						[77.52219864287851, 13.045889350851649],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000020",
			properties: {
				AREA_SQ_KM: 0.8842419373848805,
				ASS_CONST1: "Dasarahalli",
				ASS_CONST_: "155",
				LAT: 13.045358023191,
				LON: 77.5147357511792,
				LOST_AREA_SQ_KM: 0.01666619486864876,
				OBJECTID: 33,
				POP_F: 11687.0,
				POP_M: 14363.0,
				POP_SC: 1364.0,
				POP_ST: 187.0,
				POP_TOTAL: 26050.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "T Dasarahalli",
				WARD_NO: 15.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.49631355906556, 13.02822692033992],
						[77.49653653377894, 13.028293750777479],
						[77.49673273016445, 13.028387391578512],
						[77.49711619598968, 13.028730766936867],
						[77.49778065118268, 13.028989426321292],
						[77.49828898786134, 13.02919003069074],
						[77.49942160179971, 13.029586880960323],
						[77.50018405126026, 13.029872303306112],
						[77.50127656867078, 13.03034050647789],
						[77.50212826121569, 13.030799829881477],
						[77.50280152523251, 13.031156546147498],
						[77.50367551995922, 13.03158461536358],
						[77.50425077074603, 13.031508782466178],
						[77.5049954248211, 13.031401780560083],
						[77.50560637004718, 13.031290337134482],
						[77.50612805972949, 13.031107448048553],
						[77.5073453847136, 13.030688293270051],
						[77.50831297030759, 13.030394021949512],
						[77.50923154777128, 13.03019335262859],
						[77.51022597959958, 13.02999714595092],
						[77.51050685142737, 13.031968096804437],
						[77.51237975008867, 13.031531131631617],
						[77.51537177420872, 13.030866724878914],
						[77.51510867592697, 13.0294219625816],
						[77.51479211722939, 13.027660599797505],
						[77.51461371352961, 13.02645216955132],
						[77.51517998463332, 13.026692987628639],
						[77.51608966982293, 13.027067538143168],
						[77.51770384662738, 13.02771851887617],
						[77.51780647631621, 13.027758696970421],
						[77.51897468088872, 13.028200150221055],
						[77.51971048963375, 13.028458782863332],
						[77.5195990249759, 13.028726337547274],
						[77.51958563871061, 13.028761997023883],
						[77.51928685336, 13.029582436795934],
						[77.51886321551098, 13.030617002650692],
						[77.51856451575853, 13.031250208008188],
						[77.51788670764539, 13.033033836571313],
						[77.51783316177003, 13.033332591029986],
						[77.51785101039795, 13.034153013745218],
						[77.51800263612922, 13.035018072621314],
						[77.51826126558957, 13.035932246063892],
						[77.51829250056925, 13.036141806559279],
						[77.51829250056926, 13.03672151176308],
						[77.51824341702591, 13.037755976058103],
						[77.51824341702591, 13.038505092409116],
						[77.5182523413081, 13.039191854178664],
						[77.51833257232336, 13.039726916298289],
						[77.51838611796403, 13.039936534400196],
						[77.5180249468805, 13.04020403465941],
						[77.51725351730937, 13.040993341933115],
						[77.51683433409706, 13.041421386311127],
						[77.51680309871769, 13.041448158117849],
						[77.51634830362485, 13.041773635346848],
						[77.51592019261888, 13.041943079126835],
						[77.51509975139862, 13.042232900634275],
						[77.51358808370492, 13.042727909992005],
						[77.5122370416307, 13.043178284998774],
						[77.5114521856685, 13.043432388290384],
						[77.51101968135087, 13.04365982990809],
						[77.51035084077478, 13.044007646066682],
						[77.5098647826623, 13.044243948948449],
						[77.50897744782984, 13.044685402048414],
						[77.50824611983954, 13.045010931947234],
						[77.5074657864789, 13.04526955172902],
						[77.50734092214574, 13.045300817362746],
						[77.50730968416576, 13.04532310219823],
						[77.50729183388763, 13.04533197949542],
						[77.50603443227006, 13.045688712533],
						[77.50525408378353, 13.045884959194705],
						[77.50416606713102, 13.04626839291655],
						[77.5028907801069, 13.04672325565407],
						[77.50137466283431, 13.047280658704741],
						[77.49959101600705, 13.047931703851669],
						[77.49805709070722, 13.048493473581205],
						[77.49551091650235, 13.049376410674522],
						[77.49440504223456, 13.04978220623298],
						[77.49440504223456, 13.0497643512003],
						[77.49435603592615, 13.046602869844104],
						[77.49474397278874, 13.044172634890812],
						[77.49515422355798, 13.042696690027112],
						[77.49622438656031, 13.042036692043862],
						[77.4962467015706, 13.042023376052683],
						[77.49623777556701, 13.041996634309953],
						[77.49637157803795, 13.041898535093804],
						[77.49676397103863, 13.041666588212761],
						[77.49660339110427, 13.04080600040598],
						[77.49626901657653, 13.040783748904389],
						[77.49623331256494, 13.040600883181968],
						[77.49620207154564, 13.039949899412544],
						[77.49621992355773, 13.039445952118513],
						[77.49630017006773, 13.039231912156792],
						[77.49654545975919, 13.038701362620357],
						[77.49701363519675, 13.037840737579224],
						[77.49711619598968, 13.037573191779463],
						[77.49738817416532, 13.037216442429424],
						[77.49745958145546, 13.037015773673911],
						[77.49748627166886, 13.03605705451002],
						[77.49752197528885, 13.035383740431946],
						[77.4974951975749, 13.035009161141378],
						[77.49748180871558, 13.034920020123073],
						[77.49730346545932, 13.032592366141682],
						[77.4972410715066, 13.031651507911954],
						[77.49718751594072, 13.031383958473647],
						[77.49694231513716, 13.030550116243402],
						[77.49683529120958, 13.0301487901533],
						[77.49656777470678, 13.029172261999946],
						[77.49631355906556, 13.02822692033992],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000021",
			properties: {
				AREA_SQ_KM: 4.02309551299246,
				ASS_CONST1: "Dasarahalli",
				ASS_CONST_: "155",
				LAT: 13.0380504145944,
				LON: 77.5063066860491,
				LOST_AREA_SQ_KM: 0.19041799841398355,
				OBJECTID: 34,
				POP_F: 13296.0,
				POP_M: 16821.0,
				POP_SC: 2571.0,
				POP_ST: 383.0,
				POP_TOTAL: 30117.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Chokkasandra",
				WARD_NO: 39.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.49747297031738, 13.009422860476104],
						[77.49778065118268, 13.009262351062647],
						[77.49800799845684, 13.009164243422967],
						[77.49851633412369, 13.009003747279646],
						[77.49894442293208, 13.008713886478134],
						[77.49941713892206, 13.00852216600411],
						[77.49997000938004, 13.008374954759718],
						[77.50065229928947, 13.00806726998172],
						[77.50112492070346, 13.007692738498477],
						[77.50170464713162, 13.007447493755153],
						[77.5019944654891, 13.007331551160007],
						[77.50221296634284, 13.008330342444536],
						[77.50235568733949, 13.009199910074146],
						[77.50244485505739, 13.009570028134291],
						[77.50311811720704, 13.009628023577504],
						[77.50401442300212, 13.009708241925022],
						[77.50471007675382, 13.009770684958701],
						[77.50528523482335, 13.009819681343307],
						[77.50556620631795, 13.009828681439568],
						[77.50598980607077, 13.009895489185734],
						[77.50607450833219, 13.009899950845375],
						[77.50611020926652, 13.009886595690086],
						[77.50612805972949, 13.009877719237537],
						[77.50623953755762, 13.009926769806995],
						[77.50652934440029, 13.01039942909008],
						[77.50663635924097, 13.010537598192776],
						[77.50698417865537, 13.0110103207153],
						[77.50728737131767, 13.011108442277493],
						[77.50783591610995, 13.011371463053981],
						[77.50828628262545, 13.011567711772548],
						[77.50857162174947, 13.011746023732002],
						[77.5091156103682, 13.012414893799859],
						[77.50957489679296, 13.013003508014442],
						[77.51005649367914, 13.013685739351162],
						[77.51042661490415, 13.014189657521129],
						[77.51093944518846, 13.01523750100497],
						[77.51115346649506, 13.015661143973288],
						[77.511322863287, 13.016017856604801],
						[77.51141211149559, 13.016200703246966],
						[77.5116350567154, 13.01613383997567],
						[77.51221472974652, 13.015879674519764],
						[77.5125759192409, 13.015728031866354],
						[77.51280332453959, 13.015634392012789],
						[77.51339637862529, 13.015371301004064],
						[77.51363716925313, 13.015308838191938],
						[77.51390027093112, 13.015233057709205],
						[77.51393596941595, 13.015135016978604],
						[77.51419014580479, 13.015201868330903],
						[77.51447993244552, 13.015277693619792],
						[77.51488127522678, 13.013690186383997],
						[77.51509528913418, 13.01362331618684],
						[77.51579533754276, 13.013386974635493],
						[77.51643299819288, 13.013137251966297],
						[77.51641077462432, 13.013021393299494],
						[77.51801602258051, 13.012780544642604],
						[77.51804725762742, 13.012776111297823],
						[77.5185778146511, 13.012713657747451],
						[77.51888552609374, 13.01270479954559],
						[77.51911738091096, 13.012700277302082],
						[77.51945186346309, 13.012677972012987],
						[77.51961678583528, 13.012655711504259],
						[77.5197684092167, 13.012611159901224],
						[77.51991557032059, 13.01255759544806],
						[77.52002257259724, 13.012517517179617],
						[77.52024112640353, 13.012481820358598],
						[77.52042389585796, 13.012477327977019],
						[77.5207092920931, 13.012495151698692],
						[77.5208921482873, 13.012530849194697],
						[77.5210659926361, 13.012584391137722],
						[77.52110168887617, 13.012597766126994],
						[77.52115523321534, 13.012620027632556],
						[77.52116861929619, 13.01262447193737],
						[77.52120422801345, 13.012651209298378],
						[77.5212399242102, 13.012660186234145],
						[77.52137815937648, 13.012481834606886],
						[77.52145401366542, 13.012406007530403],
						[77.5214762362503, 13.012383716417167],
						[77.52149854632131, 13.012383678479226],
						[77.5217215593008, 13.01245504328728],
						[77.52173931982924, 13.01240157668517],
						[77.52174378183348, 13.012392652265307],
						[77.52176162984873, 13.01239265638817],
						[77.52207379468254, 13.012428335414436],
						[77.52232349093762, 13.012432782213073],
						[77.52247511071606, 13.012437210638078],
						[77.5226935723269, 13.012450575387227],
						[77.52276050181143, 13.01245063367877],
						[77.52283180572881, 13.012459527960589],
						[77.52288981121394, 13.012562033306919],
						[77.52291212100805, 13.012633399219917],
						[77.52292550688242, 13.012620078677026],
						[77.52292104492447, 13.01275378776785],
						[77.52294781666953, 13.012798378280111],
						[77.52301912046961, 13.012918776941364],
						[77.52302804437764, 13.012976730216383],
						[77.52301019656088, 13.013025795027083],
						[77.52286303945525, 13.013485108017305],
						[77.52256426270156, 13.01426993870779],
						[77.52238149668165, 13.01483178804578],
						[77.52226111067012, 13.015433750825204],
						[77.52215402300638, 13.015942102726614],
						[77.5220291747615, 13.016954267235306],
						[77.5219489463497, 13.018109178453415],
						[77.52193109836384, 13.018193948427578],
						[77.5219444843535, 13.018269714064346],
						[77.52200686479442, 13.018314311698113],
						[77.52222095280801, 13.018430267979202],
						[77.52284072965153, 13.018987646305805],
						[77.52295227862642, 13.01903221741183],
						[77.52300127265144, 13.019063401495147],
						[77.52304589219162, 13.019072381563554],
						[77.52311273397986, 13.019112452903784],
						[77.52432567814839, 13.019843787398369],
						[77.52553406005265, 13.020633032086248],
						[77.52561428593849, 13.02068211943345],
						[77.52632774266516, 13.021163679509856],
						[77.52658197889266, 13.021368780406982],
						[77.5268093562352, 13.021515914990005],
						[77.52672020757366, 13.021551603287511],
						[77.52663543316832, 13.021582846770247],
						[77.52566336630534, 13.02188156604354],
						[77.52468682925327, 13.022211542353812],
						[77.5238128206139, 13.022519240812167],
						[77.52299681069647, 13.02288934751484],
						[77.52284965357353, 13.022978533441588],
						[77.52190878837753, 13.02356715021073],
						[77.5216903252613, 13.023749982081878],
						[77.52156547650814, 13.023883718291087],
						[77.52150747034848, 13.023963953367828],
						[77.52138262139488, 13.024200320754334],
						[77.52113292307708, 13.024878147017704],
						[77.52086091402036, 13.025578177933408],
						[77.52059336636341, 13.026233666648523],
						[77.52028565953363, 13.027031841027354],
						[77.5199423427721, 13.0278924557128],
						[77.51971048963375, 13.028458782863332],
						[77.51897468088872, 13.028200150221055],
						[77.51780647631621, 13.027758696970421],
						[77.51770384662738, 13.02771851887617],
						[77.51608966982293, 13.027067538143168],
						[77.51517998463332, 13.026692987628639],
						[77.51461371352961, 13.02645216955132],
						[77.51479211722939, 13.027660599797505],
						[77.51510867592697, 13.0294219625816],
						[77.51537177420872, 13.030866724878914],
						[77.51237975008867, 13.031531131631617],
						[77.51050685142737, 13.031968096804437],
						[77.51022597959958, 13.02999714595092],
						[77.50923154777128, 13.03019335262859],
						[77.50831297030759, 13.030394021949512],
						[77.5073453847136, 13.030688293270051],
						[77.50612805972949, 13.031107448048553],
						[77.50560637004718, 13.031290337134482],
						[77.5049954248211, 13.031401780560083],
						[77.50425077074603, 13.031508782466178],
						[77.50367551995922, 13.03158461536358],
						[77.50280152523251, 13.031156546147498],
						[77.50212826121569, 13.030799829881477],
						[77.50127656867078, 13.03034050647789],
						[77.50018405126026, 13.029872303306112],
						[77.49942160179971, 13.029586880960323],
						[77.49828898786134, 13.02919003069074],
						[77.49778065118268, 13.028989426321292],
						[77.49711619598968, 13.028730766936867],
						[77.49673273016445, 13.028387391578512],
						[77.49653653377894, 13.028293750777479],
						[77.49631355906556, 13.02822692033992],
						[77.49629570706811, 13.028159990233354],
						[77.49607273188468, 13.027219111598736],
						[77.49572496673858, 13.02603750680294],
						[77.49540835426832, 13.024913746511647],
						[77.49516761269007, 13.023852507144053],
						[77.49508727787388, 13.023482431236861],
						[77.49498025220856, 13.02329508683279],
						[77.49489107866272, 13.023112320265685],
						[77.49479297893079, 13.022817960234041],
						[77.49469041605232, 13.022675332506317],
						[77.4943916530131, 13.022242797300388],
						[77.49422669417548, 13.022037629404322],
						[77.4940260303984, 13.021908335643424],
						[77.49381197697959, 13.021774595664684],
						[77.49370941322064, 13.021676467045385],
						[77.49353999029094, 13.021297491278014],
						[77.49354891650529, 13.021150291217891],
						[77.49361577557876, 13.021065586836126],
						[77.49370057453062, 13.021003142267546],
						[77.49377189661377, 13.020998681343514],
						[77.49380751388277, 13.020998690854812],
						[77.49390115136754, 13.020998679600671],
						[77.49406173509972, 13.020891633113107],
						[77.49421776801408, 13.020762374997433],
						[77.49469041605232, 13.0204189739047],
						[77.49480190504725, 13.020343168365509],
						[77.49494909835748, 13.020204942223595],
						[77.4950917409207, 13.020097970190541],
						[77.4952701751402, 13.019830446700912],
						[77.49535934839021, 13.019299788864414],
						[77.49559116351035, 13.018439160579911],
						[77.49560008956446, 13.018349998229953],
						[77.49564471982457, 13.018291979452671],
						[77.4957428188252, 13.018220696171985],
						[77.49591223848881, 13.018135982381237],
						[77.49602372629153, 13.01811364143926],
						[77.49622438656031, 13.018060112247742],
						[77.49638041652138, 13.018006652793527],
						[77.49652760779799, 13.01793525761924],
						[77.49670148928175, 13.017872836075632],
						[77.49683082823087, 13.01783722112136],
						[77.49695570405972, 13.017819325472123],
						[77.49706272787448, 13.017846092341617],
						[77.49717859001065, 13.017904074760198],
						[77.49737924825091, 13.017837178045575],
						[77.49760222088422, 13.017774730137944],
						[77.49766023920213, 13.017730211172436],
						[77.49769585525902, 13.01763656984951],
						[77.49827113617262, 13.016049069583858],
						[77.49845394083347, 13.01543821069171],
						[77.4987526932492, 13.014648889826269],
						[77.49902466752809, 13.013917596954284],
						[77.4995196975568, 13.012981234511118],
						[77.49969357448562, 13.01262002064406],
						[77.49981398432246, 13.012441688586183],
						[77.49984513684679, 13.01222762769898],
						[77.49987191400977, 13.012116140783979],
						[77.49987191400977, 13.012022494760188],
						[77.49975596709862, 13.01190656738592],
						[77.49827113617262, 13.010292400708417],
						[77.49818642812143, 13.010198775788856],
						[77.49747297031738, 13.009422860476104],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000022",
			properties: {
				AREA_SQ_KM: 5.61564093994134,
				ASS_CONST1: "Dasarahalli",
				ASS_CONST_: "155",
				LAT: 13.0209017997376,
				LON: 77.5086112760292,
				LOST_AREA_SQ_KM: 0.29118033132372084,
				OBJECTID: 35,
				POP_F: 12212.0,
				POP_M: 15255.0,
				POP_SC: 1595.0,
				POP_ST: 1595.0,
				POP_TOTAL: 27467.0,
				RESERVATIO: "General",
				WARD_NAME: "Peenya Industrial Area",
				WARD_NO: 41.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5172802903864, 13.008218925485412],
						[77.5172802903864, 13.008259023380178],
						[77.5172802903864, 13.00827238934812],
						[77.51729813910092, 13.008299211785348],
						[77.51731590031896, 13.008325943750055],
						[77.51734267338131, 13.008357166172953],
						[77.5174318293835, 13.008424013165659],
						[77.51784208608434, 13.008682622775828],
						[77.51791348057364, 13.008758438137244],
						[77.51808732946776, 13.008910024832582],
						[77.5181007159091, 13.008941287378246],
						[77.51823895488458, 13.009101838355503],
						[77.51828803842983, 13.009186573865746],
						[77.51831481126376, 13.009244528798574],
						[77.51838165582828, 13.009360394578971],
						[77.51840842864023, 13.00943180199462],
						[77.51849312167714, 13.009614589300766],
						[77.51852881872342, 13.009726097566421],
						[77.51856897788713, 13.00980186322091],
						[77.51861797379753, 13.009882110954713],
						[77.51868044355274, 13.010006992111947],
						[77.51872943941521, 13.010109574564373],
						[77.51876959850819, 13.010189824681115],
						[77.51883206818026, 13.010332556666459],
						[77.51885875339389, 13.010359291326301],
						[77.5189033745568, 13.010417251209937],
						[77.51898360511362, 13.010493065835881],
						[77.5190505367781, 13.01055098798842],
						[77.51904161255842, 13.010649120406452],
						[77.51903715044836, 13.010724962393379],
						[77.51901930200624, 13.010823006581697],
						[77.51901037778413, 13.010930021355293],
						[77.51901037778413, 13.011023672005255],
						[77.51901037778413, 13.011117322729346],
						[77.51899252933782, 13.011202087075242],
						[77.51895245781583, 13.011273354626708],
						[77.51891676090227, 13.011389331737242],
						[77.5188989124413, 13.011469612020763],
						[77.51885429127664, 13.011581102902477],
						[77.51876513638746, 13.011639035392813],
						[77.5187428257811, 13.011750488639576],
						[77.5187205151704, 13.011879792631092],
						[77.51868044355274, 13.011964513075716],
						[77.51864474655395, 13.012053757740464],
						[77.51860458741695, 13.012223157026146],
						[77.518609049544, 13.012348069997657],
						[77.51862689805036, 13.012432757550762],
						[77.5186536708047, 13.012513049353172],
						[77.51863582230251, 13.012579877504322],
						[77.51857344001554, 13.012651269430808],
						[77.5185778146511, 13.012713657747451],
						[77.51804725762742, 13.012776111297823],
						[77.51801602258051, 13.012780544642604],
						[77.51641077462432, 13.013021393299494],
						[77.51643299819288, 13.013137251966297],
						[77.51579533754276, 13.013386974635493],
						[77.51509528913418, 13.01362331618684],
						[77.51488127522678, 13.013690186383997],
						[77.51447993244552, 13.015277693619792],
						[77.51419014580479, 13.015201868330903],
						[77.51393596941595, 13.015135016978604],
						[77.51390027093112, 13.015233057709205],
						[77.51363716925313, 13.015308838191938],
						[77.51339637862529, 13.015371301004064],
						[77.51280332453959, 13.015634392012789],
						[77.5125759192409, 13.015728031866354],
						[77.51221472974652, 13.015879674519764],
						[77.5116350567154, 13.01613383997567],
						[77.51141211149559, 13.016200703246966],
						[77.511322863287, 13.016017856604801],
						[77.51115346649506, 13.015661143973288],
						[77.51093944518846, 13.01523750100497],
						[77.51042661490415, 13.014189657521129],
						[77.51005649367914, 13.013685739351162],
						[77.50957489679296, 13.013003508014442],
						[77.5091156103682, 13.012414893799859],
						[77.50857162174947, 13.011746023732002],
						[77.50828628262545, 13.011567711772548],
						[77.50783591610995, 13.011371463053981],
						[77.50728737131767, 13.011108442277493],
						[77.50698417865537, 13.0110103207153],
						[77.50663635924097, 13.010537598192776],
						[77.50652934440029, 13.01039942909008],
						[77.50623953755762, 13.009926769806995],
						[77.50612805972949, 13.009877719237537],
						[77.50609235880074, 13.009824159717315],
						[77.5060031939324, 13.009619082366026],
						[77.50572231101631, 13.008415118266322],
						[77.50550819201743, 13.007474218361077],
						[77.50533432394468, 13.006586847061927],
						[77.50515153030022, 13.005686115490292],
						[77.50498203684126, 13.00462488111308],
						[77.50497757418096, 13.004575860291018],
						[77.50583816436516, 13.004379602631644],
						[77.50633762745605, 13.004303845581129],
						[77.50719374480953, 13.004210146544402],
						[77.50710011822454, 13.003527946985363],
						[77.50679246247533, 13.002439926710053],
						[77.50658735818166, 13.001280504504004],
						[77.50642679206307, 13.000375375079015],
						[77.50640894164682, 13.00025491629512],
						[77.5063910912278, 13.000179121294485],
						[77.50637332830804, 13.000063236810208],
						[77.5062885387663, 12.999974016600161],
						[77.50619937405123, 12.99988484265401],
						[77.5061325223448, 12.999795631568535],
						[77.50611467188253, 12.999715439563051],
						[77.50609235880074, 12.999568208234056],
						[77.50606112047893, 12.999354238456023],
						[77.5060031939324, 12.999042059030787],
						[77.5059764182076, 12.998921685684387],
						[77.50727407110811, 12.998520394367615],
						[77.50724729568154, 12.998431147502654],
						[77.50793400463596, 12.998177026385278],
						[77.50800978037083, 12.998145785592092],
						[77.51004756875535, 12.997561664448476],
						[77.51007434352466, 12.997713249821787],
						[77.51032406607037, 12.998573891118896],
						[77.51273201434661, 12.997971929769156],
						[77.51301288000197, 12.998738897651773],
						[77.51320913556174, 12.999661883913864],
						[77.51327152078483, 13.000094398070718],
						[77.5133206064693, 13.00012566604692],
						[77.513521236329, 13.000139025847584],
						[77.51376202669314, 13.000152351615602],
						[77.51394043172577, 13.000188085635084],
						[77.51402959038931, 13.00024156154538],
						[77.51410982437724, 13.000330732750161],
						[77.51420790750717, 13.000415510622103],
						[77.51431491514319, 13.000500204377127],
						[77.51443530955063, 13.000571579384133],
						[77.51455579132674, 13.00058941247497],
						[77.5146894848261, 13.000589348216682],
						[77.51481434111358, 13.000576007327659],
						[77.514899124317, 13.000567102153614],
						[77.5149882822307, 13.000509088106995],
						[77.51505512874637, 13.000451112111651],
						[77.51507297780937, 13.000366442455219],
						[77.51509975139862, 13.00026383991748],
						[77.51506405327822, 13.0001568259479],
						[77.51504182944272, 12.999911643107266],
						[77.51507297780937, 12.999773383533979],
						[77.51517114760588, 12.999652948719264],
						[77.51538516096903, 12.999532617292367],
						[77.51553232773375, 12.999470175356715],
						[77.51563040963968, 12.999380949632322],
						[77.51571064250085, 12.999269473905645],
						[77.51583103543412, 12.999220476990015],
						[77.51592911708299, 12.999153583448372],
						[77.51597820162313, 12.999082242885718],
						[77.5160405853308, 12.998988525604373],
						[77.51617882680539, 12.998921683749696],
						[77.5162859200856, 12.998894890816924],
						[77.51644638482857, 12.998903884160011],
						[77.51668716901355, 12.998935054358133],
						[77.51677641302265, 12.998970770551198],
						[77.51684325848959, 12.998970737420235],
						[77.51686556946787, 12.998952936663516],
						[77.51695472584332, 12.998859224616924],
						[77.51706173090055, 12.998738872778215],
						[77.51715989898409, 12.998716561148157],
						[77.5172802903864, 12.998725506487682],
						[77.51738729513792, 12.998792349154858],
						[77.5174318293835, 12.99878787053801],
						[77.51760130433958, 12.998725474645646],
						[77.51769492230225, 12.998622925055356],
						[77.51768153581322, 12.998533722422238],
						[77.51752107280944, 12.998587243312269],
						[77.51743629155645, 12.99857830136071],
						[77.51740514383569, 12.998426758720763],
						[77.51737837078801, 12.997374382933737],
						[77.51805618192495, 12.997325351890828],
						[77.51828803842982, 12.99737436553582],
						[77.5184752731498, 12.997503645093069],
						[77.51858673890708, 12.997606227319064],
						[77.5186536708047, 12.997686472762817],
						[77.51871605304773, 12.997753391409892],
						[77.51878744698944, 12.997829197289237],
						[77.51881868182485, 12.99793176802984],
						[77.51880975758704, 12.998061103688524],
						[77.51880975758704, 12.998114563207224],
						[77.51880975758704, 12.998203806167341],
						[77.51880529546786, 12.998288521474775],
						[77.51880529546786, 12.998337497384593],
						[77.51880529546786, 12.998435621715716],
						[77.51881421970603, 12.998515899283944],
						[77.51885875339389, 12.998564966284732],
						[77.51890783667214, 12.998605114122562],
						[77.51892122301707, 12.99868086589457],
						[77.51895245781583, 12.998761191282563],
						[77.518970218776, 12.998810166774922],
						[77.51901930200624, 12.998836949747776],
						[77.51907284732418, 12.998796865639418],
						[77.51911291880384, 12.998756731466406],
						[77.51916200197259, 12.998729968294017],
						[77.51923330814263, 12.998734375896783],
						[77.51930916385982, 12.998765647905806],
						[77.51936262154942, 12.998810233069317],
						[77.51941170461059, 12.998903840950259],
						[77.51941616670602, 12.99901089120616],
						[77.51941616670602, 12.999095565034942],
						[77.51941616670602, 12.999198173931187],
						[77.51936262154942, 12.999300685888311],
						[77.51930916385982, 12.999380955794704],
						[77.51921108512019, 12.999501398047952],
						[77.51913969144395, 12.999626239060794],
						[77.51910399458907, 12.999742119890815],
						[77.51909953248142, 12.999853652768616],
						[77.51909069575791, 12.999956178411717],
						[77.51906838521532, 13.000032010032037],
						[77.51903715044836, 13.000058776946696],
						[77.51895245781583, 13.000063204402911],
						[77.5188453670416, 13.000014119449856],
						[77.5187919091093, 13.000018596409864],
						[77.51857344001554, 13.000112244596968],
						[77.51840842864023, 13.000205899467108],
						[77.51825680344892, 13.000317362776656],
						[77.5182523413081, 13.000321802504715],
						[77.51798924967636, 13.00079897674696],
						[77.51786439686704, 13.001222587203186],
						[77.5179000941103, 13.001414277810944],
						[77.51790901841937, 13.001695208346556],
						[77.51783762392726, 13.00200293893662],
						[77.51784654824122, 13.002123314711193],
						[77.51786439686704, 13.002212478093693],
						[77.5179401660024, 13.002288327728744],
						[77.5180338711798, 13.002381948505368],
						[77.51818549666774, 13.002529082246184],
						[77.51823895488458, 13.002658431872687],
						[77.51822110631743, 13.002867962729825],
						[77.51814533736888, 13.003055281647674],
						[77.51801602258051, 13.003251470513904],
						[77.51787332117891, 13.003474430522944],
						[77.5177797033628, 13.003679509109977],
						[77.51773508175985, 13.00387575614806],
						[77.5176993844649, 13.003978274450901],
						[77.51767269897975, 13.004687336128688],
						[77.51769046013942, 13.005249122770513],
						[77.51769046013942, 13.005266972373542],
						[77.5176548503239, 13.006011652794706],
						[77.51764592599494, 13.00607856562985],
						[77.51762807733489, 13.006123142716884],
						[77.5176057665059, 13.006167761899178],
						[77.51756560700272, 13.00620340897757],
						[77.51750768630002, 13.006261389900525],
						[77.51744967807427, 13.0063327323243],
						[77.51740960600969, 13.006390717387577],
						[77.5173649842618, 13.006470901590902],
						[77.51733821120469, 13.00651107889142],
						[77.51731143814128, 13.006569019743765],
						[77.51729367692253, 13.006613601163291],
						[77.51731590031896, 13.006667108388204],
						[77.51732482467378, 13.006725143882848],
						[77.51735605991013, 13.006778657503466],
						[77.51739621948717, 13.006809839405747],
						[77.5174318293835, 13.006899006422755],
						[77.51745414024654, 13.007113080543283],
						[77.51743629155645, 13.007220089388353],
						[77.51743629155645, 13.007291402719797],
						[77.5174318293835, 13.007367242194148],
						[77.5174318293835, 13.007527719005722],
						[77.51738283296307, 13.007790847505763],
						[77.51737390861278, 13.00792010682446],
						[77.51730251378541, 13.008134203630421],
						[77.5172802903864, 13.008218925485412],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000023",
			properties: {
				AREA_SQ_KM: 2.1705323447863996,
				ASS_CONST1: "Dasarahalli",
				ASS_CONST_: "155",
				LAT: 13.0061996149424,
				LON: 77.5122492470683,
				LOST_AREA_SQ_KM: 0.06900698572028666,
				OBJECTID: 36,
				POP_F: 12913.0,
				POP_M: 15691.0,
				POP_SC: 2364.0,
				POP_ST: 606.0,
				POP_TOTAL: 28604.0,
				RESERVATIO: "General",
				WARD_NAME: "Rajagopal Nagar",
				WARD_NO: 70.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.49173854345285, 13.003184546543663],
						[77.49288006108195, 13.002854565820659],
						[77.4938655341277, 13.002355152594273],
						[77.49415090929278, 13.002350749844233],
						[77.4949891783104, 13.00224372422087],
						[77.49513637137937, 13.002185783283439],
						[77.4955956265375, 13.002078692581259],
						[77.49513190833429, 13.000696439592216],
						[77.49513190833429, 13.000642896563761],
						[77.49506951319557, 13.000370855249532],
						[77.49503827185805, 13.00022371031239],
						[77.49535042231729, 13.000143438738744],
						[77.49563133074837, 13.000076564107365],
						[77.49640719449663, 12.999844752487025],
						[77.49733024321822, 12.999465670422396],
						[77.4979366790181, 12.999202597241727],
						[77.49871252710874, 12.999095622565473],
						[77.49912722651528, 12.999024208038877],
						[77.49911383784726, 12.998899399982982],
						[77.49906483355878, 12.998801314152187],
						[77.49943490292426, 12.998676429054974],
						[77.49984067398567, 12.998524825193668],
						[77.49987191400977, 12.998489182111557],
						[77.49987637686966, 12.99843559748273],
						[77.49986745114973, 12.998373171441232],
						[77.49979167000909, 12.998025385079126],
						[77.50029553489344, 12.997659720133166],
						[77.50055420450532, 12.997485879651478],
						[77.50082171167094, 12.997311950878379],
						[77.50087080271179, 12.99722277038433],
						[77.50067015060597, 12.996968590493482],
						[77.50027322067417, 12.996326461121875],
						[77.50020636549698, 12.996188281008568],
						[77.50014397313006, 12.996081270374763],
						[77.50026429498524, 12.9960321940699],
						[77.50027322067417, 12.99598762153901],
						[77.50009488177675, 12.995452509000263],
						[77.49988530258892, 12.994654297586553],
						[77.4995999417486, 12.993628765190179],
						[77.49934573285577, 12.992808225846817],
						[77.49925656271765, 12.992536280669396],
						[77.49925656271765, 12.992433683899595],
						[77.49928334001865, 12.992371271026062],
						[77.49934573285577, 12.99226868714778],
						[77.49935019573623, 12.992139408080913],
						[77.49929226578425, 12.991791560520898],
						[77.49930565443135, 12.991318889823722],
						[77.49933680709435, 12.989802857069668],
						[77.49934573285577, 12.988910980656645],
						[77.49954638728879, 12.988906498437036],
						[77.49985852542908, 12.988919930009088],
						[77.50009488177675, 12.988977875050224],
						[77.50054527883835, 12.989165186991396],
						[77.50144151732746, 12.989526312624545],
						[77.50281491346811, 12.99001681920981],
						[77.50396095811695, 12.990208586834065],
						[77.50413482828193, 12.990213060930538],
						[77.50428647225087, 12.990208610418916],
						[77.50437117577651, 12.990204100454967],
						[77.50449604335928, 12.990177399697181],
						[77.50487948311347, 12.99003470235642],
						[77.50557513159232, 12.989722570049784],
						[77.50565090889378, 12.989718057359749],
						[77.50567768469496, 12.989713666671483],
						[77.5056955352256, 12.989753761060403],
						[77.50562859572133, 12.989967775382217],
						[77.50535217452902, 12.990409233229093],
						[77.50475015328102, 12.99120294356345],
						[77.5046520620181, 12.991412515752955],
						[77.50464759934489, 12.991760355296714],
						[77.50464759934489, 12.991791565734944],
						[77.50464759934489, 12.99212600075542],
						[77.50462091080544, 12.99297773849257],
						[77.50446480460052, 12.993954250291218],
						[77.50444249119616, 12.994279761954813],
						[77.50429977280865, 12.99427972976103],
						[77.50424184536807, 12.994279762287544],
						[77.50420614384926, 12.994315447572612],
						[77.50412590289491, 12.994493817236512],
						[77.50402334839787, 12.994667741037771],
						[77.50400549760568, 12.99495312041751],
						[77.50401888570008, 12.995202770782827],
						[77.50402334839787, 12.995238423510758],
						[77.5040501245809, 12.995242913854273],
						[77.5040679753661, 12.99524740187903],
						[77.50416606713102, 12.995242939697476],
						[77.50449158067998, 12.995216153841932],
						[77.50452273192793, 12.995216200744604],
						[77.50459413475632, 12.995216132890477],
						[77.5046520620181, 12.995211703257553],
						[77.50470115141178, 12.995216156145316],
						[77.50475907861923, 12.995238454443408],
						[77.50486609512002, 12.995283052993958],
						[77.50495972353801, 12.99536778323244],
						[77.50504897672477, 12.995514896452027],
						[77.50514260499271, 12.995880616914658],
						[77.50530308541538, 12.996491432581305],
						[77.5054502651902, 12.996986415109756],
						[77.50559744477523, 12.997396644751456],
						[77.50582486398667, 12.998163677359486],
						[77.50595856772091, 12.998778983277342],
						[77.50597195558618, 12.998886031620692],
						[77.5059764182076, 12.998921685684387],
						[77.5060031939324, 12.999042059030787],
						[77.50606112047893, 12.999354238456023],
						[77.50609235880074, 12.999568208234056],
						[77.50611467188253, 12.999715439563051],
						[77.5061325223448, 12.999795631568535],
						[77.50619937405123, 12.99988484265401],
						[77.5062885387663, 12.999974016600161],
						[77.50637332830804, 13.000063236810208],
						[77.5063910912278, 13.000179121294485],
						[77.50640894164682, 13.00025491629512],
						[77.50642679206307, 13.000375375079015],
						[77.50658735818166, 13.001280504504004],
						[77.50679246247533, 13.002439926710053],
						[77.50710011822454, 13.003527946985363],
						[77.50719374480953, 13.004210146544402],
						[77.50633762745605, 13.004303845581129],
						[77.50583816436516, 13.004379602631644],
						[77.50497757418096, 13.004575860291018],
						[77.50498203684126, 13.00462488111308],
						[77.50515153030022, 13.005686115490292],
						[77.50533432394468, 13.006586847061927],
						[77.50550819201743, 13.007474218361077],
						[77.50572231101631, 13.008415118266322],
						[77.5060031939324, 13.009619082366026],
						[77.50609235880074, 13.009824159717315],
						[77.50612805972949, 13.009877719237537],
						[77.50611020926652, 13.009886595690086],
						[77.50607450833219, 13.009899950845375],
						[77.50598980607077, 13.009895489185734],
						[77.50556620631795, 13.009828681439568],
						[77.50528523482335, 13.009819681343307],
						[77.50471007675382, 13.009770684958701],
						[77.50401442300212, 13.009708241925022],
						[77.50311811720704, 13.009628023577504],
						[77.50244485505739, 13.009570028134291],
						[77.50235568733949, 13.009199910074146],
						[77.50221296634284, 13.008330342444536],
						[77.5019944654891, 13.007331551160007],
						[77.50170464713162, 13.007447493755153],
						[77.50112492070346, 13.007692738498477],
						[77.50065229928947, 13.00806726998172],
						[77.49997000938004, 13.008374954759718],
						[77.49941713892206, 13.00852216600411],
						[77.49894442293208, 13.008713886478134],
						[77.49851633412369, 13.009003747279646],
						[77.49800799845684, 13.009164243422967],
						[77.49778065118268, 13.009262351062647],
						[77.49747297031738, 13.009422860476104],
						[77.49680851333467, 13.008700467271515],
						[77.49667917436028, 13.008557765884897],
						[77.49173854345285, 13.003184546543663],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000024",
			properties: {
				AREA_SQ_KM: 1.9444578819195888,
				ASS_CONST1: "Dasarahalli",
				ASS_CONST_: "155",
				LAT: 13.0010055251581,
				LON: 77.5009754398035,
				LOST_AREA_SQ_KM: 0.03336824508867452,
				OBJECTID: 37,
				POP_F: 14123.0,
				POP_M: 16766.0,
				POP_SC: 1879.0,
				POP_ST: 527.0,
				POP_TOTAL: 30889.0,
				RESERVATIO: "General",
				WARD_NAME: "Hegganahalli",
				WARD_NO: 71.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52033028013767, 12.986926684877783],
						[77.52043728202477, 12.98688660665332],
						[77.5204952887294, 12.986864242477921],
						[77.52095006726941, 12.986650219612638],
						[77.52169478726748, 12.986271172693812],
						[77.52195787034161, 12.986132954630815],
						[77.52220310486476, 12.986057154739065],
						[77.52241710501968, 12.985945699133685],
						[77.52243495292942, 12.985932338103366],
						[77.52266688801164, 12.98581640577466],
						[77.52299234874131, 12.985584473112246],
						[77.52327327659633, 12.985325896879628],
						[77.5234472047995, 12.985160854931442],
						[77.52355420375828, 12.985067234190785],
						[77.52362113273699, 12.98503159285093],
						[77.52366566454548, 12.985018231194887],
						[77.52371474575233, 12.985018194873799],
						[77.52377721271229, 12.985013720223002],
						[77.52381728253691, 12.985013764626027],
						[77.52387528752004, 12.9850137294967],
						[77.52393766690334, 12.98502270038498],
						[77.52418744655994, 12.985111878591495],
						[77.52457536946568, 12.98524559808807],
						[77.52470467680723, 12.98529471197922],
						[77.52505244108717, 12.985370444635377],
						[77.52535567317476, 12.985446261518904],
						[77.52545383411028, 12.985499815561367],
						[77.52552067448497, 12.985548834845845],
						[77.52560536223315, 12.985624670202002],
						[77.52573475589206, 12.985780698291274],
						[77.52581051979176, 12.985847565987356],
						[77.52587298560185, 12.98589663219423],
						[77.52593090205062, 12.985927889576734],
						[77.52597998228094, 12.985941216679297],
						[77.52601567698063, 12.985954585138954],
						[77.5260735933568, 12.985959114912605],
						[77.52611374986013, 12.985959077030254],
						[77.52615836818056, 12.985959082829034],
						[77.52619843716712, 12.985954643569272],
						[77.52644375004381, 12.98586987546169],
						[77.52680489442947, 12.985588967090997],
						[77.52710366005495, 12.98534814602276],
						[77.5273711924151, 12.985120727668003],
						[77.5276253388262, 12.984893350556098],
						[77.52795087286663, 12.98456338270205],
						[77.5281649497031, 12.984278023524015],
						[77.52819172021502, 12.984242332609467],
						[77.52830317646162, 12.98411297203537],
						[77.52847263523967, 12.983956933788333],
						[77.52867323841275, 12.983836545526158],
						[77.52870893226782, 12.983827582923682],
						[77.52872231746058, 12.983827627526395],
						[77.52872677919115, 12.983827584915634],
						[77.52875801130024, 12.983983684810442],
						[77.52877585821588, 12.984028261188731],
						[77.52885608181678, 12.984407278274906],
						[77.52903000149018, 12.984986979944761],
						[77.52911468662728, 12.985401652396856],
						[77.52911468662728, 12.985508735800952],
						[77.5291057631964, 12.985597884798391],
						[77.5290879163325, 12.985789633105396],
						[77.5290746186674, 12.985910040343711],
						[77.52907461866738, 12.986021521361344],
						[77.52908354210072, 12.986106189419742],
						[77.52910130148068, 12.986155245886755],
						[77.52912807177232, 12.986213274442946],
						[77.52939559937359, 12.986565502890334],
						[77.52955167143125, 12.986761709369729],
						[77.52971666665852, 12.98696240072063],
						[77.5298548915322, 12.987140758401777],
						[77.52988612333165, 12.987203142195076],
						[77.52992181680625, 12.987274536545584],
						[77.53003327135832, 12.987510831695612],
						[77.53024288260637, 12.987849741325734],
						[77.53025180594808, 12.987867590028761],
						[77.53026519095935, 12.987889879617972],
						[77.53031864350501, 12.988001364949529],
						[77.53045686764737, 12.988148512638093],
						[77.53049256094295, 12.988184126024663],
						[77.53058625579051, 12.988326875743436],
						[77.53062632321812, 12.988416071872365],
						[77.530791316887, 12.988803953255776],
						[77.5308760881965, 12.989053703834358],
						[77.53098308017184, 12.989285562406861],
						[77.53098754181376, 12.989298970426043],
						[77.53099646509708, 12.989312335862449],
						[77.53109453368428, 12.98946392131453],
						[77.53117038149887, 12.98965564500832],
						[77.53120607457058, 12.989749286509538],
						[77.53122829525171, 12.98978054046934],
						[77.53128183482784, 12.989878581542213],
						[77.53133091275046, 12.989985632429294],
						[77.53135322089011, 12.990181834358408],
						[77.5314022112991, 12.990654465971033],
						[77.53142898104937, 12.99088192550056],
						[77.53147805890853, 12.991292138585697],
						[77.53154489574506, 12.991800473805878],
						[77.53156274222356, 12.991952143770437],
						[77.53158951193606, 12.99213494192969],
						[77.53165197457407, 12.992558569284853],
						[77.53165197457407, 12.99256296672839],
						[77.53165197457407, 12.992567450396482],
						[77.53164751295822, 12.9925674929513],
						[77.5314512891698, 12.992589718454711],
						[77.5302473442773, 12.992759200161323],
						[77.53025626761868, 12.992888537649234],
						[77.53028749930797, 12.993156052856717],
						[77.5290121546144, 12.993325516328463],
						[77.52890516078575, 12.993361202452277],
						[77.52840570911329, 12.993579636770944],
						[77.52781264568021, 12.993842767530746],
						[77.52761641527852, 12.9939230177467],
						[77.5267558145548, 12.994150416890461],
						[77.52681827984617, 12.994422464270956],
						[77.52651951347164, 12.994520507167142],
						[77.52642590276248, 12.994542872161908],
						[77.52517291163528, 12.99491291634388],
						[77.5252263665726, 12.99501104417369],
						[77.52496329104974, 12.995153754484651],
						[77.52435236207492, 12.995461448878354],
						[77.52385297791464, 12.995746784955468],
						[77.52372366960586, 12.995595227441488],
						[77.52369243611548, 12.995555084615695],
						[77.5236522787581, 12.995488210714214],
						[77.5236166708063, 12.99542129874979],
						[77.52358543728658, 12.995349942799775],
						[77.52356312762439, 12.995274104761782],
						[77.52354527989148, 12.995198310651782],
						[77.52354527989148, 12.995171581161928],
						[77.52352743215579, 12.995104668207723],
						[77.52349182416492, 12.994984297615384],
						[77.52345166673682, 12.99486387905634],
						[77.52340258541656, 12.994743545354718],
						[77.52334912962301, 12.994632053216565],
						[77.52329558631531, 12.994538405254193],
						[77.52327773854047, 12.994511672914182],
						[77.52321982074207, 12.99442246906746],
						[77.5231707393219, 12.994328778954173],
						[77.52312611983065, 12.994230735407438],
						[77.52308604976282, 12.994132567620241],
						[77.52305481609756, 12.994030086037114],
						[77.5230325063314, 12.993927519839554],
						[77.52302358242372, 12.993878457398452],
						[77.52301465851534, 12.993802665843493],
						[77.52300573460626, 12.993691178043541],
						[77.52300573460626, 12.993579691850778],
						[77.52300573460626, 12.9935127829411],
						[77.52300573460626, 12.99349941840827],
						[77.52300127265144, 12.993410220222499],
						[77.52299234874131, 12.993325548051173],
						[77.52297003896292, 12.993245227953775],
						[77.52294335471245, 12.993160557179793],
						[77.52291212100805, 12.993080235726945],
						[77.52287196337551, 12.993004482640794],
						[77.5228228818054, 12.992933125467497],
						[77.52276942573972, 12.99286621229234],
						[77.52268027391483, 12.992777047891838],
						[77.5226044204426, 12.992683441124731],
						[77.52252865440954, 12.992589752468104],
						[77.52246172478874, 12.992487180294127],
						[77.52243495292942, 12.992442599114616],
						[77.52239488261839, 12.992389096006235],
						[77.52235026282315, 12.992340028363305],
						[77.5223011810282, 12.992295486640623],
						[77.52224772471766, 12.992255302627644],
						[77.5221852569187, 12.99222407994529],
						[77.52212287657548, 12.992197345029744],
						[77.52206040870806, 12.992179486693194],
						[77.52205148472422, 12.992175087896523],
						[77.52184640788289, 12.992148333192983],
						[77.52182855988094, 12.992148330183616],
						[77.52179286386865, 12.992139357126858],
						[77.52175270584145, 12.992121545614108],
						[77.52172155930079, 12.992099256336516],
						[77.52169478726748, 12.992072523173789],
						[77.52167247723492, 12.992041264150526],
						[77.5216546292057, 12.992001168204428],
						[77.52165016719796, 12.991965514933474],
						[77.52164570519004, 12.991929775452892],
						[77.52163231916525, 12.991867392114056],
						[77.52159671058186, 12.991751422842007],
						[77.52155655248549, 12.991635491821617],
						[77.52150747034848, 12.991524042924658],
						[77.52145401366542, 12.99141255453609],
						[77.5214272415692, 12.991363490382113],
						[77.52142277955255, 12.9913545657786],
						[77.5213291646532, 12.991162842505128],
						[77.52123100016206, 12.990975554977442],
						[77.52112846104892, 12.990788314418447],
						[77.52101699777852, 12.990605469957599],
						[77.52097237744348, 12.990538555665317],
						[77.52096345337438, 12.990520706612658],
						[77.520901072362, 12.990404820674534],
						[77.520843065864, 12.990279921406588],
						[77.52079407091055, 12.990159511461576],
						[77.5207539125312, 12.990030218289789],
						[77.52071821618213, 12.989905366335801],
						[77.52070483004833, 12.98983850085579],
						[77.52068251982186, 12.989713608255098],
						[77.52063352479935, 12.989468353504115],
						[77.52057551816512, 12.98922309360839],
						[77.52050867488781, 12.988982320441792],
						[77.52043728202477, 12.988745983129125],
						[77.52042389585797, 12.988705846158172],
						[77.5204105096896, 12.988652345325532],
						[77.5203615145501, 12.988500726269153],
						[77.52030350777734, 12.988353585090268],
						[77.52024112640355, 12.98820649075046],
						[77.52016973337281, 12.988063788052138],
						[77.52011627606193, 12.987961184079303],
						[77.5200895036513, 12.987916605012755],
						[77.52006719330433, 12.987854222702065],
						[77.52005380709404, 12.9877917556715],
						[77.52004488295297, 12.98772933251587],
						[77.52004488295297, 12.987666910852004],
						[77.5200493450236, 12.987600049736535],
						[77.5200582691643, 12.987568840386718],
						[77.52006273123439, 12.987542070526832],
						[77.5200895036513, 12.98746628957566],
						[77.52012073812976, 12.987390466271155],
						[77.52015634717459, 12.987319079678839],
						[77.52020096782927, 12.987252182095673],
						[77.52025451259183, 12.987189769264393],
						[77.52030350777734, 12.987136274980314],
						[77.52030350777734, 12.98713179167428],
						[77.52032135601826, 12.987082736828482],
						[77.52033474219711, 12.987033724391816],
						[77.52033474219711, 12.986984666724911],
						[77.52033028013767, 12.98693116817639],
						[77.52033028013767, 12.986926684877783],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000025",
			properties: {
				AREA_SQ_KM: 0.9970009282173076,
				ASS_CONST1: "Mahalakshmi Layout",
				ASS_CONST_: "156",
				LAT: 12.9897724158581,
				LON: 77.5258790256208,
				LOST_AREA_SQ_KM: 0.010594365265012253,
				OBJECTID: 38,
				POP_F: 15685.0,
				POP_M: 18654.0,
				POP_SC: 1187.0,
				POP_ST: 333.0,
				POP_TOTAL: 34339.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Vrisabhavathi Nagar",
				WARD_NO: 102.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52760311744146, 13.010515337414914],
						[77.52773688311315, 13.010403856008383],
						[77.52780372214723, 13.01030128683034],
						[77.52786172509917, 13.010198720172124],
						[77.52791071701141, 13.010060532018773],
						[77.52793748758313, 13.009957916253153],
						[77.52793748758313, 13.00984201499502],
						[77.52799102870767, 13.009757255434721],
						[77.52804456980701, 13.009645848998687],
						[77.52820055623226, 13.009485256378253],
						[77.52840570911329, 13.00930247163257],
						[77.52857962947499, 13.009150855019252],
						[77.52861978509416, 13.009088471130546],
						[77.52867770014525, 13.008999267107887],
						[77.52871785572982, 13.008976982785422],
						[77.5287624730294, 13.00895017192647],
						[77.52880700282665, 13.008941293813917],
						[77.52886054354211, 13.00894130387658],
						[77.52904784836316, 13.00896358354832],
						[77.52917715062398, 13.008968044604782],
						[77.52943129300206, 13.008936822924735],
						[77.52969881989044, 13.008927895324499],
						[77.53002434799947, 13.008874350904973],
						[77.53020272755995, 13.008816428605305],
						[77.5302607292891, 13.008745078944644],
						[77.53028749930797, 13.008709382153416],
						[77.5303275668408, 13.008664844365844],
						[77.53035433684393, 13.008606812515396],
						[77.53039895350183, 13.008379417054247],
						[77.5304168001601, 13.008325954188534],
						[77.5304123384958, 13.008232258491367],
						[77.53039895350183, 13.008129765267427],
						[77.53039003017166, 13.008062845015928],
						[77.53039003017165, 13.007969193449002],
						[77.53034095184314, 13.007839874981856],
						[77.53028749930797, 13.0078086525776],
						[77.53023395926394, 13.00777302794519],
						[77.53017595752114, 13.007737359409896],
						[77.53011358155659, 13.007683800563342],
						[77.53006896478678, 13.007625842699978],
						[77.53001988631978, 13.00750540690457],
						[77.53001096295986, 13.007385021516818],
						[77.53003327135832, 13.007304784051254],
						[77.53000203959925, 13.007010502933396],
						[77.52992181680625, 13.006667107803374],
						[77.52986827659015, 13.006248000868363],
						[77.52988612333165, 13.005975936327768],
						[77.52989058501659, 13.005891298748812],
						[77.52989504670134, 13.00578872464328],
						[77.52989950838595, 13.00578868230023],
						[77.52990397007036, 13.005744143440092],
						[77.52997535699717, 13.00575752199922],
						[77.53006896478678, 13.005770857034303],
						[77.53016257249936, 13.0057753099736],
						[77.53030972016853, 13.00577528791292],
						[77.53036772184315, 13.00578422303876],
						[77.53063524652976, 13.005721827002903],
						[77.53077793193764, 13.005672779962932],
						[77.53093400209916, 13.005596959488853],
						[77.53110345695922, 13.005530066303859],
						[77.53129075808808, 13.005489908370878],
						[77.53155381898466, 13.005494384495956],
						[77.53179465870677, 13.00560583405499],
						[77.53199971762513, 13.005753060688132],
						[77.53236539344788, 13.005944724322308],
						[77.53257946197849, 13.00605177172571],
						[77.53282913518689, 13.00622125454737],
						[77.5330521259668, 13.006430836733369],
						[77.53307443376892, 13.006462014235318],
						[77.53321265456283, 13.00666714218723],
						[77.53332856744092, 13.006832124479185],
						[77.5334132493783, 13.006943592988007],
						[77.53352478799091, 13.007064038657846],
						[77.5336139313211, 13.007184433533977],
						[77.53376999759159, 13.007295913904603],
						[77.53387261279735, 13.007367204171842],
						[77.53396621738, 13.007407357673518],
						[77.53409105253061, 13.007434106467354],
						[77.5342872717651, 13.007514332836966],
						[77.53435419443149, 13.007536635448226],
						[77.53443441409865, 13.0076035630971],
						[77.53446118313529, 13.007652635681152],
						[77.53451918269307, 13.00771952100339],
						[77.5345503257315, 13.0077328451935],
						[77.53457709474087, 13.007746216037793],
						[77.53462170974247, 13.007723974129883],
						[77.53467078622401, 13.007715056411149],
						[77.53471977520407, 13.007697165869265],
						[77.53477777462996, 13.007679367190374],
						[77.53484023551708, 13.007652643739682],
						[77.53494722376361, 13.007572370288255],
						[77.53516128743465, 13.007327057373027],
						[77.53535741734264, 13.00713977429049],
						[77.53552686561251, 13.007086329503839],
						[77.53570969800046, 13.007086309792903],
						[77.53588360719522, 13.007220040856694],
						[77.53601290168574, 13.00740731815278],
						[77.53607090045205, 13.007469805139143],
						[77.53609766910344, 13.007527760058107],
						[77.53614228350844, 13.00753216481999],
						[77.53617351358152, 13.007527728325847],
						[77.53620465616684, 13.007514405226726],
						[77.53624034765865, 13.007483192958246],
						[77.53643210175616, 13.00734493711456],
						[77.5366817665061, 13.007188877559129],
						[77.53678884049857, 13.007126458081437],
						[77.53683345463234, 13.007095247020922],
						[77.53683791604476, 13.007081837917626],
						[77.53682007039407, 13.007006033598477],
						[77.53679330191275, 13.00691237714131],
						[77.53704296586952, 13.006751832959935],
						[77.53739077991422, 13.006524469389625],
						[77.53745770075396, 13.006493218251242],
						[77.53785905000939, 13.006725069074868],
						[77.53736401156728, 13.00695694033748],
						[77.53744431658916, 13.00735381244017],
						[77.53754684100089, 13.008013751349587],
						[77.53763160727488, 13.008722765199739],
						[77.5376717596984, 13.009190999960786],
						[77.53673976493121, 13.009315882862467],
						[77.53696720945004, 13.00932923704782],
						[77.53747108491719, 13.00936487352376],
						[77.53820686155719, 13.009726089193741],
						[77.53862150471798, 13.010131848086669],
						[77.5388712521366, 13.0100025217593],
						[77.53896485260395, 13.010194283752561],
						[77.53922798322449, 13.01078285880187],
						[77.5393840417888, 13.011304585603336],
						[77.53959363580142, 13.011255584520354],
						[77.53985667748985, 13.01113069537698],
						[77.5402134054847, 13.01228118773692],
						[77.54020448292468, 13.012330171346882],
						[77.54018217652154, 13.012356945936476],
						[77.54012872861229, 13.012356942336764],
						[77.539892367835, 13.012290031918036],
						[77.53987452266382, 13.012486227597558],
						[77.53987006137059, 13.012530856707262],
						[77.5388890974653, 13.011763883155576],
						[77.538813254799, 13.01170596139754],
						[77.53871073152949, 13.011670289837042],
						[77.53861704337555, 13.011634615370863],
						[77.53864381142742, 13.011509743758594],
						[77.5378812693906, 13.011594461272617],
						[77.53783674314595, 13.011808506240783],
						[77.53766283693885, 13.012089410540618],
						[77.53741308686517, 13.01235697888449],
						[77.53783674314595, 13.01294558684919],
						[77.53818009340242, 13.013409308191243],
						[77.53826477172663, 13.013502932395207],
						[77.53854574935048, 13.013908696286235],
						[77.53873295074422, 13.014327853439077],
						[77.53877756411498, 13.01450181105274],
						[77.5388534068051, 13.014706864338205],
						[77.53896485260395, 13.015076987989838],
						[77.5390585404712, 13.015317836133386],
						[77.53916552474803, 13.015558553410383],
						[77.53925475113248, 13.015763740399255],
						[77.53945979659757, 13.016343407941271],
						[77.53951779360524, 13.01657528401201],
						[77.53958025188813, 13.01693199981372],
						[77.53962477744977, 13.017369003316743],
						[77.53838514080618, 13.017533983688411],
						[77.53724364140646, 13.01763653251535],
						[77.53722133442224, 13.017583014112393],
						[77.53714111672129, 13.017627592413504],
						[77.5371589623216, 13.017676668473497],
						[77.53730610093896, 13.017890705315864],
						[77.53743093242278, 13.018051223978867],
						[77.53760037760235, 13.018238486617511],
						[77.53789019213299, 13.018733445581363],
						[77.53801957181925, 13.019223988660167],
						[77.5380685593564, 13.01999541867032],
						[77.53775643840127, 13.020187095925166],
						[77.53754684100089, 13.020311989549073],
						[77.53687798126998, 13.02069990458427],
						[77.53603074746313, 13.021185935663913],
						[77.53566963236356, 13.021391101864008],
						[77.53529504421947, 13.02158733397201],
						[77.53515236447807, 13.021667519293514],
						[77.534942762277, 13.021721084140344],
						[77.53468417071531, 13.021743378812513],
						[77.53435865594118, 13.021783469531377],
						[77.53385476668126, 13.021859319774062],
						[77.53372100820239, 13.021886032970777],
						[77.53299858722391, 13.022028724162816],
						[77.53264629816789, 13.022104512246754],
						[77.5326239902818, 13.022095667311595],
						[77.53259730829468, 13.022086696312098],
						[77.53257946197849, 13.022073324394205],
						[77.53256161565949, 13.022037700977785],
						[77.53252592301311, 13.021935060624573],
						[77.53237877821046, 13.021355419011824],
						[77.53232970073991, 13.021154758595399],
						[77.53218701727832, 13.020410092693389],
						[77.53203541043665, 13.019585136820236],
						[77.53190611142414, 13.018885115759566],
						[77.53166089780528, 13.017921952685903],
						[77.53134429763477, 13.016865125399718],
						[77.53104108150318, 13.015843972526442],
						[77.53065309315096, 13.014782668317968],
						[77.53043018515197, 13.014292185237036],
						[77.53001542463991, 13.01351634397716],
						[77.52957397991975, 13.012874177814785],
						[77.52909683976479, 13.012174143229721],
						[77.52854839731694, 13.011509720012787],
						[77.52780818391382, 13.010769508699578],
						[77.52760311744146, 13.010515337414914],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000026",
			properties: {
				AREA_SQ_KM: 1.4184711974547521,
				ASS_CONST1: "Mahalakshmi Layout",
				ASS_CONST_: "156",
				LAT: 13.013449936266,
				LON: 77.5341416116112,
				LOST_AREA_SQ_KM: 0.061686363335194304,
				OBJECTID: 39,
				POP_F: 16205.0,
				POP_M: 18113.0,
				POP_SC: 4421.0,
				POP_ST: 548.0,
				POP_TOTAL: 34318.0,
				RESERVATIO: "General",
				WARD_NAME: "Nandini Layout",
				WARD_NO: 43.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54421326609932, 13.02634964318439],
						[77.54381193928606, 13.025689668918869],
						[77.54324563569948, 13.024829098500478],
						[77.54269271283815, 13.023914933631232],
						[77.54267932928987, 13.023897120699447],
						[77.5423626723283, 13.023357534846083],
						[77.54156901413744, 13.021948457012071],
						[77.54110522057272, 13.021208292086708],
						[77.54054782602842, 13.020258497944061],
						[77.54023125060267, 13.019727820221084],
						[77.54015987011402, 13.019607408206944],
						[77.53988790654246, 13.01915712280319],
						[77.53980314195113, 13.019027782808752],
						[77.53977646164873, 13.019023297757489],
						[77.53974969386363, 13.019027778026272],
						[77.53958917449717, 13.019121418066218],
						[77.5395088709906, 13.019165993521513],
						[77.53889355879703, 13.019518305251431],
						[77.5380685593564, 13.01999541867032],
						[77.53801957181925, 13.019223988660167],
						[77.53789019213299, 13.018733445581363],
						[77.53760037760235, 13.018238486617511],
						[77.53743093242278, 13.018051223978867],
						[77.53730610093896, 13.017890705315864],
						[77.5371589623216, 13.017676668473497],
						[77.53714111672129, 13.017627592413504],
						[77.53722133442224, 13.017583014112393],
						[77.53724364140646, 13.01763653251535],
						[77.53838514080618, 13.017533983688411],
						[77.53962477744977, 13.017369003316743],
						[77.53958025188813, 13.01693199981372],
						[77.53951779360524, 13.01657528401201],
						[77.53945979659757, 13.016343407941271],
						[77.53925475113248, 13.015763740399255],
						[77.53916552474803, 13.015558553410383],
						[77.5390585404712, 13.015317836133386],
						[77.53896485260395, 13.015076987989838],
						[77.5388534068051, 13.014706864338205],
						[77.53877756411498, 13.01450181105274],
						[77.53873295074422, 13.014327853439077],
						[77.53854574935048, 13.013908696286235],
						[77.53826477172663, 13.013502932395207],
						[77.53818009340242, 13.013409308191243],
						[77.53783674314595, 13.01294558684919],
						[77.53741308686517, 13.01235697888449],
						[77.53766283693885, 13.012089410540618],
						[77.53783674314595, 13.011808506240783],
						[77.5378812693906, 13.011594461272617],
						[77.53864381142742, 13.011509743758594],
						[77.53861704337555, 13.011634615370863],
						[77.53871073152949, 13.011670289837042],
						[77.538813254799, 13.01170596139754],
						[77.5388890974653, 13.011763883155576],
						[77.53987006137059, 13.012530856707262],
						[77.53987452266382, 13.012486227597558],
						[77.53994590333168, 13.012508574608145],
						[77.54006627063085, 13.01252639694593],
						[77.54021786676446, 13.012562032630846],
						[77.54036946269585, 13.012535315811144],
						[77.54040961415215, 13.012517512850174],
						[77.540521058425, 13.012472894985073],
						[77.5406325900643, 13.012437250379373],
						[77.54068603773656, 13.012437253868589],
						[77.54077972418493, 13.012437224242685],
						[77.54095362562285, 13.012472905755326],
						[77.5411186043064, 13.012512984219734],
						[77.5412256741192, 13.012535335992261],
						[77.54136834623748, 13.012588821741506],
						[77.54174291436742, 13.012481840154145],
						[77.54215308342302, 13.012258863534996],
						[77.54242512904749, 13.0119823630542],
						[77.5427952325151, 13.011911039395125],
						[77.54338384412593, 13.012089365692422],
						[77.54400814173917, 13.012200858353465],
						[77.54537708834461, 13.012628978416844],
						[77.54677278458615, 13.013070379644901],
						[77.54679508969518, 13.013074910056812],
						[77.54680838528749, 13.013070379624507],
						[77.54681284630824, 13.013057055555821],
						[77.54679062867373, 13.01267797522886],
						[77.54671479128218, 13.012628937192183],
						[77.54658550896856, 13.01156326989885],
						[77.54643383386089, 13.010796303583376],
						[77.54642937282524, 13.010774009632716],
						[77.54727215556619, 13.010555506563657],
						[77.54900231163651, 13.010171962810805],
						[77.54949729936408, 13.010056050657836],
						[77.54956412560335, 13.010131902620289],
						[77.55012156336754, 13.010751641256523],
						[77.55053178962993, 13.011170811647705],
						[77.55066552831909, 13.011268964654459],
						[77.55149935793932, 13.011683640486346],
						[77.55281485974704, 13.01233466455458],
						[77.55348817385425, 13.012664639310856],
						[77.55365313432648, 13.012731532138469],
						[77.55402774939678, 13.01356537921579],
						[77.55404113160732, 13.014024682234703],
						[77.55405888708684, 13.014947687262866],
						[77.55403667087064, 13.015170680300951],
						[77.55400544570904, 13.015331139662472],
						[77.55399652423272, 13.015380213326637],
						[77.55396083832039, 13.015513979062098],
						[77.55393853461949, 13.01564778928546],
						[77.55382701604924, 13.015906390968215],
						[77.5537289670824, 13.016463803053904],
						[77.5535817622746, 13.017533996897988],
						[77.55337219407853, 13.018269723513345],
						[77.55313586086548, 13.019125854813895],
						[77.55259636854812, 13.020285220360568],
						[77.55224405279037, 13.021047766354775],
						[77.55184721518631, 13.02174338308059],
						[77.55180706777371, 13.021819149704514],
						[77.55157965296803, 13.022068866039271],
						[77.5500412673073, 13.023058819494974],
						[77.54790089278342, 13.024316282264943],
						[77.54608158644805, 13.025315097936847],
						[77.54421326609932, 13.02634964318439],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000027",
			properties: {
				AREA_SQ_KM: 2.0389355926948145,
				ASS_CONST1: "Mahalakshmi Layout",
				ASS_CONST_: "156",
				LAT: 13.0174803547776,
				LON: 77.5462421542391,
				LOST_AREA_SQ_KM: 0.07855012218466367,
				OBJECTID: 40,
				POP_F: 15894.0,
				POP_M: 18108.0,
				POP_SC: 5202.0,
				POP_ST: 376.0,
				POP_TOTAL: 34002.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Marappana Palya",
				WARD_NO: 44.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54727215556619, 13.010555506563657],
						[77.54695559887992, 13.008905648115562],
						[77.5467549404958, 13.00800041349517],
						[77.54566688304017, 13.00820556982504],
						[77.54416865486274, 13.00846415356209],
						[77.54369148848154, 13.008553320525975],
						[77.54330354330115, 13.008629129961411],
						[77.54314302895516, 13.008620232139764],
						[77.54302712608475, 13.008303638961188],
						[77.54296020852459, 13.007768563114524],
						[77.54337492181486, 13.007657054305717],
						[77.54314749011988, 13.006956993351078],
						[77.54282199958277, 13.00617215893453],
						[77.54269271283815, 13.005864497307268],
						[77.54250989168291, 13.005583563894067],
						[77.54248312454172, 13.005538976428127],
						[77.54241620666114, 13.005605845301455],
						[77.54231814783944, 13.005703975610299],
						[77.54217538943925, 13.005797612124157],
						[77.54199702866147, 13.005864515140692],
						[77.54174737558711, 13.005967029500637],
						[77.54142179356369, 13.006047324095626],
						[77.54123450912375, 13.006083007536697],
						[77.54111414306203, 13.00609192251167],
						[77.54105614686902, 13.005543407878735],
						[77.54098039312437, 13.004910214422688],
						[77.54089116809479, 13.004214627528837],
						[77.54083772051901, 13.003630468879939],
						[77.54075295663614, 13.003032982241724],
						[77.5406816639509, 13.002475560346094],
						[77.54067274142764, 13.002435460527643],
						[77.54066828016576, 13.002417652707024],
						[77.54065935764145, 13.002373068746937],
						[77.54064151259071, 13.00233736588301],
						[77.54089116809479, 13.001307325531565],
						[77.54112752679467, 13.000348566325277],
						[77.54110968181746, 12.999345252845542],
						[77.5418097451697, 12.998935058328174],
						[77.54212640367236, 12.998747746415708],
						[77.54227799705751, 12.998658593113285],
						[77.54235821113275, 12.99860953067561],
						[77.54259902796713, 12.998475803760716],
						[77.54261241152486, 12.99846688003301],
						[77.54267486810676, 12.99843118593732],
						[77.54279077133656, 12.998364276672726],
						[77.54311626196314, 12.99823499350857],
						[77.54314302895516, 12.998221630017548],
						[77.54351313008401, 12.998074413947409],
						[77.54467249839841, 12.997628582809448],
						[77.54473495384767, 12.997606253876569],
						[77.54487762155878, 12.997548313477298],
						[77.54499807110548, 12.997521548131681],
						[77.54501582799953, 12.9975170612654],
						[77.54505597781376, 12.997508139461745],
						[77.54511397196482, 12.997494735007095],
						[77.54513627739966, 12.997485811787886],
						[77.54527894460666, 12.99745460283861],
						[77.5453681661901, 12.997454609725947],
						[77.54541715055906, 12.997454652380462],
						[77.54568472730152, 12.997450184483743],
						[77.54622425246754, 12.99742343691353],
						[77.54683961242911, 12.997427866095512],
						[77.54686637854365, 12.99742786797182],
						[77.5468886836343, 12.997427912648876],
						[77.54835127571982, 12.99750817654667],
						[77.54951068210886, 12.997566088082463],
						[77.5501883892393, 12.997619660314673],
						[77.55038466814301, 12.997637435101334],
						[77.55044257210413, 12.997641961259935],
						[77.55047825912285, 12.997650845199695],
						[77.55048271999942, 12.997650802324722],
						[77.55126310829336, 12.99782472001046],
						[77.55178030282408, 12.997931749001673],
						[77.55191412750908, 12.997958487957401],
						[77.55246254466768, 12.998069958444061],
						[77.55255622140055, 12.998087856303817],
						[77.55300212508753, 12.998181431866897],
						[77.55301104664206, 12.998185916435222],
						[77.5530868798271, 12.998199329181881],
						[77.55306011517935, 12.998261761540762],
						[77.5530556544041, 12.99826620240425],
						[77.5530556544041, 12.998288450827111],
						[77.55301996819587, 12.998386583799242],
						[77.55277917346307, 12.99910447904681],
						[77.5526988792831, 12.99934532895568],
						[77.55268549691425, 12.999541469692893],
						[77.55268549691425, 12.999599505877148],
						[77.55269441849367, 12.999670822744903],
						[77.55280147739187, 13.000250502171136],
						[77.55284608523631, 13.000500242970908],
						[77.55300658586488, 13.00094608699216],
						[77.55311355700242, 13.00124041274921],
						[77.55319385088895, 13.001677377618849],
						[77.55322061550515, 13.001864685370695],
						[77.55321169396713, 13.001864684884687],
						[77.5532429193472, 13.00209213653247],
						[77.5532786054853, 13.002341794987304],
						[77.5533588117891, 13.002685192408277],
						[77.55336327255242, 13.00270300070346],
						[77.55339449789076, 13.002885783017096],
						[77.55357284076497, 13.00367503887136],
						[77.55370666333592, 13.004098736202822],
						[77.55375127082449, 13.004228010605996],
						[77.55376465306765, 13.004281522539689],
						[77.55386270200364, 13.004607075990686],
						[77.55404550487839, 13.005213474251784],
						[77.55419716981504, 13.005962562610485],
						[77.55425962002413, 13.006301490665052],
						[77.55431306128222, 13.006582375068854],
						[77.55435766853236, 13.006818763629862],
						[77.55436658998029, 13.006885600822255],
						[77.55438443287404, 13.006974775021554],
						[77.55439335431986, 13.007041698551665],
						[77.5543978150425, 13.00706847665727],
						[77.55443796153844, 13.007719471047267],
						[77.55443350081737, 13.008299144061448],
						[77.55443350081737, 13.008312511552118],
						[77.55442904009614, 13.00856222487045],
						[77.55443350081737, 13.008856526583314],
						[77.55444242225934, 13.010024857134127],
						[77.55444242225934, 13.010314720651781],
						[77.55442457937471, 13.011193110970508],
						[77.55443350081737, 13.011973449764069],
						[77.55443796153844, 13.012156331828896],
						[77.55444688298006, 13.012646807087194],
						[77.55444242225934, 13.012718174515955],
						[77.55438443287404, 13.013132793694293],
						[77.55424177711075, 13.013471730643756],
						[77.55407673002898, 13.013868576064914],
						[77.55405442635087, 13.013966678791318],
						[77.55404113160732, 13.014024682234703],
						[77.55402774939678, 13.01356537921579],
						[77.55365313432648, 13.012731532138469],
						[77.55348817385425, 13.012664639310856],
						[77.55281485974704, 13.01233466455458],
						[77.55149935793932, 13.011683640486346],
						[77.55066552831909, 13.011268964654459],
						[77.55053178962993, 13.011170811647705],
						[77.55012156336754, 13.010751641256523],
						[77.54956412560335, 13.010131902620289],
						[77.54949729936408, 13.010056050657836],
						[77.54900231163651, 13.010171962810805],
						[77.54727215556619, 13.010555506563657],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000028",
			properties: {
				AREA_SQ_KM: 1.797686266196262,
				ASS_CONST1: "Mahalakshmi Layout",
				ASS_CONST_: "156",
				LAT: 13.0041261575396,
				LON: 77.5481305165233,
				LOST_AREA_SQ_KM: 0.07453223315423704,
				OBJECTID: 41,
				POP_F: 17285.0,
				POP_M: 18268.0,
				POP_SC: 2520.0,
				POP_ST: 320.0,
				POP_TOTAL: 35553.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Nagapura",
				WARD_NO: 67.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53307443376892, 13.006462014235318],
						[77.53313234655238, 13.006426361185342],
						[77.5333285674409, 13.00629703602319],
						[77.53306997220884, 13.005909119733781],
						[77.5327087602256, 13.00544982994596],
						[77.53257946197849, 13.005298171060621],
						[77.53438979902357, 13.003831132534676],
						[77.53507651931886, 13.003305019648623],
						[77.53566517090532, 13.002801143561797],
						[77.53584345414016, 13.002636109829693],
						[77.53589699154375, 13.002573684558993],
						[77.53611105342678, 13.00283232200758],
						[77.53637856460453, 13.001953896134056],
						[77.53660154842444, 13.001213617136333],
						[77.53734625347744, 13.001392066225069],
						[77.53909414359003, 13.001811152266313],
						[77.53945979659757, 13.001891470176094],
						[77.53970954217414, 13.001976176335852],
						[77.54008857705672, 13.002132251392576],
						[77.54040069160754, 13.002297243901],
						[77.54067274142764, 13.002435460527643],
						[77.5406816639509, 13.002475560346094],
						[77.54075295663614, 13.003032982241724],
						[77.54083772051901, 13.003630468879939],
						[77.54089116809479, 13.004214627528837],
						[77.54098039312437, 13.004910214422688],
						[77.54105614686902, 13.005543407878735],
						[77.54111414306203, 13.00609192251167],
						[77.54123450912375, 13.006083007536697],
						[77.54142179356369, 13.006047324095626],
						[77.54174737558711, 13.005967029500637],
						[77.54199702866147, 13.005864515140692],
						[77.54217538943925, 13.005797612124157],
						[77.54231814783944, 13.005703975610299],
						[77.54241620666114, 13.005605845301455],
						[77.54248312454172, 13.005538976428127],
						[77.54250989168291, 13.005583563894067],
						[77.54269271283815, 13.005864497307268],
						[77.54282199958277, 13.00617215893453],
						[77.54314749011988, 13.006956993351078],
						[77.54337492181486, 13.007657054305717],
						[77.54296020852459, 13.007768563114524],
						[77.54302712608475, 13.008303638961188],
						[77.54314302895516, 13.008620232139764],
						[77.54330354330115, 13.008629129961411],
						[77.54369148848154, 13.008553320525975],
						[77.54416865486274, 13.00846415356209],
						[77.54566688304017, 13.00820556982504],
						[77.5467549404958, 13.00800041349517],
						[77.54695559887992, 13.008905648115562],
						[77.54727215556619, 13.010555506563657],
						[77.54642937282524, 13.010774009632716],
						[77.54643383386089, 13.010796303583376],
						[77.54658550896856, 13.01156326989885],
						[77.54671479128218, 13.012628937192183],
						[77.54679062867373, 13.01267797522886],
						[77.54681284630824, 13.013057055555821],
						[77.54680838528749, 13.013070379624507],
						[77.54679508969518, 13.013074910056812],
						[77.54677278458615, 13.013070379644901],
						[77.54537708834461, 13.012628978416844],
						[77.54400814173917, 13.012200858353465],
						[77.54338384412593, 13.012089365692422],
						[77.5427952325151, 13.011911039395125],
						[77.54242512904749, 13.0119823630542],
						[77.54215308342302, 13.012258863534996],
						[77.54174291436742, 13.012481840154145],
						[77.54136834623748, 13.012588821741506],
						[77.5412256741192, 13.012535335992261],
						[77.5411186043064, 13.012512984219734],
						[77.54095362562285, 13.012472905755326],
						[77.54077972418493, 13.012437224242685],
						[77.54068603773656, 13.012437253868589],
						[77.5406325900643, 13.012437250379373],
						[77.540521058425, 13.012472894985073],
						[77.54040961415215, 13.012517512850174],
						[77.54036946269585, 13.012535315811144],
						[77.54021786676446, 13.012562032630846],
						[77.54006627063085, 13.01252639694593],
						[77.53994590333168, 13.012508574608145],
						[77.53987452266382, 13.012486227597558],
						[77.539892367835, 13.012290031918036],
						[77.54012872861229, 13.012356942336764],
						[77.54018217652154, 13.012356945936476],
						[77.54020448292468, 13.012330171346882],
						[77.5402134054847, 13.01228118773692],
						[77.53985667748985, 13.01113069537698],
						[77.53959363580142, 13.011255584520354],
						[77.5393840417888, 13.011304585603336],
						[77.53922798322449, 13.01078285880187],
						[77.53896485260395, 13.010194283752561],
						[77.5388712521366, 13.0100025217593],
						[77.53862150471798, 13.010131848086669],
						[77.53820686155719, 13.009726089193741],
						[77.53747108491719, 13.00936487352376],
						[77.53696720945004, 13.00932923704782],
						[77.53673976493121, 13.009315882862467],
						[77.5376717596984, 13.009190999960786],
						[77.53763160727488, 13.008722765199739],
						[77.53754684100089, 13.008013751349587],
						[77.53744431658916, 13.00735381244017],
						[77.53736401156728, 13.00695694033748],
						[77.53785905000939, 13.006725069074868],
						[77.53745770075396, 13.006493218251242],
						[77.53739077991422, 13.006524469389625],
						[77.53704296586952, 13.006751832959935],
						[77.53679330191275, 13.00691237714131],
						[77.53682007039407, 13.007006033598477],
						[77.53683791604476, 13.007081837917626],
						[77.53683345463234, 13.007095247020922],
						[77.53678884049857, 13.007126458081437],
						[77.5366817665061, 13.007188877559129],
						[77.53643210175616, 13.00734493711456],
						[77.53624034765865, 13.007483192958246],
						[77.53620465616684, 13.007514405226726],
						[77.53617351358152, 13.007527728325847],
						[77.53614228350844, 13.00753216481999],
						[77.53609766910344, 13.007527760058107],
						[77.53607090045205, 13.007469805139143],
						[77.53601290168574, 13.00740731815278],
						[77.53588360719522, 13.007220040856694],
						[77.53570969800046, 13.007086309792903],
						[77.53552686561251, 13.007086329503839],
						[77.53535741734264, 13.00713977429049],
						[77.53516128743465, 13.007327057373027],
						[77.53494722376361, 13.007572370288255],
						[77.53484023551708, 13.007652643739682],
						[77.53477777462996, 13.007679367190374],
						[77.53471977520407, 13.007697165869265],
						[77.53467078622401, 13.007715056411149],
						[77.53462170974247, 13.007723974129883],
						[77.53457709474087, 13.007746216037793],
						[77.5345503257315, 13.0077328451935],
						[77.53451918269307, 13.00771952100339],
						[77.53446118313529, 13.007652635681152],
						[77.53443441409865, 13.0076035630971],
						[77.53435419443149, 13.007536635448226],
						[77.5342872717651, 13.007514332836966],
						[77.53409105253061, 13.007434106467354],
						[77.53396621738, 13.007407357673518],
						[77.53387261279735, 13.007367204171842],
						[77.53376999759159, 13.007295913904603],
						[77.5336139313211, 13.007184433533977],
						[77.53352478799091, 13.007064038657846],
						[77.5334132493783, 13.006943592988007],
						[77.53332856744092, 13.006832124479185],
						[77.53321265456283, 13.00666714218723],
						[77.53307443376892, 13.006462014235318],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000029",
			properties: {
				AREA_SQ_KM: 0.9475889722153599,
				ASS_CONST1: "Mahalakshmi Layout",
				ASS_CONST_: "156",
				LAT: 13.0073496521638,
				LON: 77.540042587153,
				LOST_AREA_SQ_KM: 0.022082527870208132,
				OBJECTID: 42,
				POP_F: 16774.0,
				POP_M: 19202.0,
				POP_SC: 1258.0,
				POP_ST: 311.0,
				POP_TOTAL: 35976.0,
				RESERVATIO: "Backward Category - B",
				WARD_NAME: "Mahalakshimpuram",
				WARD_NO: 68.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52385297791464, 12.995746784955468],
						[77.52435236207492, 12.995461448878354],
						[77.52496329104974, 12.995153754484651],
						[77.5252263665726, 12.99501104417369],
						[77.52517291163528, 12.99491291634388],
						[77.52642590276248, 12.994542872161908],
						[77.52651951347164, 12.994520507167142],
						[77.52681827984617, 12.994422464270956],
						[77.5267558145548, 12.994150416890461],
						[77.52761641527852, 12.9939230177467],
						[77.52781264568021, 12.993842767530746],
						[77.52840570911329, 12.993579636770944],
						[77.52890516078575, 12.993361202452277],
						[77.5290121546144, 12.993325516328463],
						[77.53028749930797, 12.993156052856717],
						[77.53025626761868, 12.992888537649234],
						[77.5302473442773, 12.992759200161323],
						[77.5314512891698, 12.992589718454711],
						[77.53164751295822, 12.9925674929513],
						[77.53165197457407, 12.992567450396482],
						[77.53248130718937, 12.992478284623314],
						[77.53277559633902, 12.992447098226169],
						[77.53355155724168, 12.992322232917775],
						[77.53404643732082, 12.992255319019364],
						[77.53442995259192, 12.992201807915343],
						[77.5358123114559, 12.991983342531606],
						[77.53706081148522, 12.991778218825058],
						[77.53834953746525, 12.99156865405398],
						[77.53835846017091, 12.99156865486726],
						[77.53838514080618, 12.99157305056741],
						[77.53841190891266, 12.99160426661701],
						[77.53843421566324, 12.991648890336137],
						[77.53902731119071, 12.993285375898711],
						[77.53911645020673, 12.993530609473908],
						[77.53924136717927, 12.993873977918254],
						[77.53945979659757, 12.994471504201401],
						[77.53970954217414, 12.99503778354264],
						[77.53978975806251, 12.995247408239704],
						[77.53927697024085, 12.995318725170705],
						[77.53805071389888, 12.995283061589861],
						[77.53769406659423, 12.995336584502894],
						[77.53685567421401, 12.995465852930817],
						[77.53567409382165, 12.99565316941386],
						[77.53551794268462, 12.995613012287457],
						[77.53540649350666, 12.994899601589143],
						[77.53478223612304, 12.994966458017988],
						[77.53427388722709, 12.995073503940493],
						[77.53343555710957, 12.995287523450994],
						[77.53344448020086, 12.99552387259126],
						[77.53348463410298, 12.995844944420483],
						[77.53234754709528, 12.996157012291985],
						[77.53235200868369, 12.996174905028127],
						[77.53255715407931, 12.99683482700932],
						[77.5318125051461, 12.997195998078764],
						[77.53216024770643, 12.997722197057572],
						[77.53187041857207, 12.997851464489331],
						[77.53135322089011, 12.998119060868133],
						[77.53122383361924, 12.998096752271262],
						[77.53117484313344, 12.998096706333783],
						[77.5310500047823, 12.998110144155818],
						[77.5309072322324, 12.99809227421509],
						[77.53083593337355, 12.998074418837746],
						[77.53074678786633, 12.998043281709707],
						[77.53063524652976, 12.998034340806255],
						[77.53051933090725, 12.998025360323224],
						[77.53038110684078, 12.99799417278217],
						[77.5302830376386, 12.997967431863108],
						[77.53023395926394, 12.997931769353189],
						[77.53008234981957, 12.99794071881583],
						[77.53000203959925, 12.997940706870716],
						[77.52986827659015, 12.997945174984224],
						[77.52977913032475, 12.99788713455214],
						[77.52971220496676, 12.997806889613809],
						[77.52969435819797, 12.997762307227779],
						[77.52958290331392, 12.997735521054025],
						[77.52948037172294, 12.997748918245831],
						[77.52939559937359, 12.997780162952505],
						[77.52934214639431, 12.99782025492398],
						[77.5292796824882, 12.997891641919406],
						[77.52920392089779, 12.997954020317756],
						[77.52910130148068, 12.998043207167628],
						[77.52904338664518, 12.998123523632865],
						[77.52896307569159, 12.998185940157251],
						[77.52889623733842, 12.998292985868623],
						[77.52882038800787, 12.998368725259175],
						[77.52873570265176, 12.998466886741706],
						[77.52868662360972, 12.998551598018505],
						[77.52863763203149, 12.998627345846032],
						[77.52860193815407, 12.998694253182476],
						[77.52854839731694, 12.998770039209322],
						[77.52845032653524, 12.998872639357353],
						[77.52830317646162, 12.999024252380057],
						[77.52824517373466, 12.99907774770289],
						[77.52819172021502, 12.999162505725417],
						[77.52741134846066, 12.998935059729078],
						[77.52593982573043, 12.998591670865682],
						[77.52595767308797, 12.998560459617895],
						[77.5260735933568, 12.99846243460187],
						[77.52612267352562, 12.998382122226904],
						[77.52616720435566, 12.99823941957135],
						[77.52620289899664, 12.998087838413763],
						[77.52622966997004, 12.997962986263927],
						[77.52620736082598, 12.9978782646627],
						[77.52618951350759, 12.997802468342316],
						[77.52614944451787, 12.997744478433768],
						[77.52602897500314, 12.99771768502208],
						[77.525908592848, 12.99771322850619],
						[77.52574359210006, 12.997744462258794],
						[77.5255385219082, 12.997824713146787],
						[77.52536905876187, 12.997882719159009],
						[77.52526652337478, 12.997896114167084],
						[77.5251684497654, 12.997878252752518],
						[77.52510152169624, 12.997829221528418],
						[77.52501237169723, 12.997735568569757],
						[77.52490528662086, 12.997592845638213],
						[77.52486967906093, 12.997467988194828],
						[77.52482952211876, 12.997200464945468],
						[77.52478044139242, 12.996923973366535],
						[77.52474037190676, 12.996701034332418],
						[77.52462436279235, 12.996433543306397],
						[77.524463909569, 12.996250640839445],
						[77.52419628288801, 12.995983129646364],
						[77.52391981922914, 12.995813659358266],
						[77.52385297791464, 12.995746784955468],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000002a",
			properties: {
				AREA_SQ_KM: 0.7026786744630582,
				ASS_CONST1: "Mahalakshmi Layout",
				ASS_CONST_: "156",
				LAT: 12.9950480331202,
				LON: 77.5315839709294,
				LOST_AREA_SQ_KM: 0.009329239936349905,
				OBJECTID: 43,
				POP_F: 16577.0,
				POP_M: 17953.0,
				POP_SC: 5595.0,
				POP_ST: 366.0,
				POP_TOTAL: 34530.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Shakthi Ganapathi Nagar",
				WARD_NO: 74.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52784387804017, 12.999693107439382],
						[77.52783495450963, 12.999581612468166],
						[77.52781710744645, 12.999519180126978],
						[77.52791964053601, 12.999412140059578],
						[77.52809802339577, 12.999305155375643],
						[77.52819172021502, 12.999162505725417],
						[77.52824517373466, 12.99907774770289],
						[77.52830317646162, 12.999024252380057],
						[77.52845032653524, 12.998872639357353],
						[77.52854839731694, 12.998770039209322],
						[77.52860193815407, 12.998694253182476],
						[77.52863763203149, 12.998627345846032],
						[77.52868662360972, 12.998551598018505],
						[77.52873570265176, 12.998466886741706],
						[77.52882038800787, 12.998368725259175],
						[77.52889623733842, 12.998292985868623],
						[77.52896307569159, 12.998185940157251],
						[77.52904338664518, 12.998123523632865],
						[77.52910130148068, 12.998043207167628],
						[77.52920392089779, 12.997954020317756],
						[77.5292796824882, 12.997891641919406],
						[77.52934214639431, 12.99782025492398],
						[77.52939559937359, 12.997780162952505],
						[77.52948037172294, 12.997748918245831],
						[77.52958290331392, 12.997735521054025],
						[77.52969435819797, 12.997762307227779],
						[77.52971220496676, 12.997806889613809],
						[77.52977913032475, 12.99788713455214],
						[77.52986827659015, 12.997945174984224],
						[77.53000203959925, 12.997940706870716],
						[77.53008234981957, 12.99794071881583],
						[77.53023395926394, 12.997931769353189],
						[77.5302830376386, 12.997967431863108],
						[77.53038110684078, 12.99799417278217],
						[77.53051933090725, 12.998025360323224],
						[77.53063524652976, 12.998034340806255],
						[77.53074678786633, 12.998043281709707],
						[77.53083593337355, 12.998074418837746],
						[77.5309072322324, 12.99809227421509],
						[77.5310500047823, 12.998110144155818],
						[77.53117484313344, 12.998096706333783],
						[77.53122383361924, 12.998096752271262],
						[77.53135322089011, 12.998119060868133],
						[77.53187041857207, 12.997851464489331],
						[77.53216024770643, 12.997722197057572],
						[77.5318125051461, 12.997195998078764],
						[77.53255715407931, 12.99683482700932],
						[77.53235200868369, 12.996174905028127],
						[77.53234754709528, 12.996157012291985],
						[77.53348463410298, 12.995844944420483],
						[77.53344448020086, 12.99552387259126],
						[77.53343555710957, 12.995287523450994],
						[77.53427388722709, 12.995073503940493],
						[77.53478223612304, 12.994966458017988],
						[77.53540649350666, 12.994899601589143],
						[77.53551794268462, 12.995613012287457],
						[77.53567409382165, 12.99565316941386],
						[77.53685567421401, 12.995465852930817],
						[77.53769406659423, 12.995336584502894],
						[77.53805071389888, 12.995283061589861],
						[77.53927697024085, 12.995318725170705],
						[77.53978975806251, 12.995247408239704],
						[77.53987452266382, 12.995483725821245],
						[77.54007073191637, 12.996009909298287],
						[77.5402357118817, 12.996451372023435],
						[77.54036500142205, 12.9968749836406],
						[77.54042299796778, 12.997053355896252],
						[77.54060582248086, 12.997641934085392],
						[77.54099377687278, 12.99889494358762],
						[77.54110968181746, 12.9993408550223],
						[77.54110968181746, 12.999345252845542],
						[77.54112752679467, 13.000348566325277],
						[77.54089116809479, 13.001307325531565],
						[77.54064151259071, 13.00233736588301],
						[77.54065935764145, 13.002373068746937],
						[77.54066828016576, 13.002417652707024],
						[77.54067274142764, 13.002435460527643],
						[77.54040069160754, 13.002297243901],
						[77.54008857705672, 13.002132251392576],
						[77.53970954217414, 13.001976176335852],
						[77.53945979659757, 13.001891470176094],
						[77.53909414359003, 13.001811152266313],
						[77.53734625347744, 13.001392066225069],
						[77.53660154842444, 13.001213617136333],
						[77.53637856460453, 13.001953896134056],
						[77.53611105342678, 13.00283232200758],
						[77.53589699154375, 13.002573684558993],
						[77.53584345414016, 13.002636109829693],
						[77.53566517090532, 13.002801143561797],
						[77.53507651931886, 13.003305019648623],
						[77.53438979902357, 13.003831132534676],
						[77.53257946197849, 13.005298171060621],
						[77.5327087602256, 13.00544982994596],
						[77.53306997220884, 13.005909119733781],
						[77.5333285674409, 13.00629703602319],
						[77.53313234655238, 13.006426361185342],
						[77.53307443376892, 13.006462014235318],
						[77.5330521259668, 13.006430836733369],
						[77.53282913518689, 13.00622125454737],
						[77.53257946197849, 13.00605177172571],
						[77.53236539344788, 13.005944724322308],
						[77.53199971762513, 13.005753060688132],
						[77.53179465870677, 13.00560583405499],
						[77.53155381898466, 13.005494384495956],
						[77.53129075808808, 13.005489908370878],
						[77.53110345695922, 13.005530066303859],
						[77.53093400209916, 13.005596959488853],
						[77.53077793193764, 13.005672779962932],
						[77.53063524652976, 13.005721827002903],
						[77.53036772184315, 13.00578422303876],
						[77.53030972016853, 13.00577528791292],
						[77.53016257249936, 13.0057753099736],
						[77.53006896478678, 13.005770857034303],
						[77.52997535699717, 13.00575752199922],
						[77.52990397007036, 13.005744143440092],
						[77.52985935321834, 13.005699552977948],
						[77.52983267058282, 13.005646001592119],
						[77.52978359201389, 13.005574634931895],
						[77.52974343680539, 13.005507754004308],
						[77.52969881989044, 13.005436431337332],
						[77.52966767552375, 13.005342824002938],
						[77.52963644366432, 13.005275858548496],
						[77.52961413518806, 13.005204583029942],
						[77.52958290331392, 13.00511088543811],
						[77.52955613312929, 13.005008349830018],
						[77.52953391212156, 13.004896894237634],
						[77.52953391212156, 13.004772029168173],
						[77.52954720973301, 13.004642721303464],
						[77.52957397991975, 13.004473278850648],
						[77.52960075010019, 13.004384119013757],
						[77.52960521179632, 13.004339494477922],
						[77.52960075010019, 13.00430383660964],
						[77.52960075010019, 13.004263652311831],
						[77.52960075010019, 13.004201220082251],
						[77.52957844161693, 13.004161161378727],
						[77.52949821852529, 13.004067503739732],
						[77.5293555315126, 13.003964953652895],
						[77.52929306761382, 13.003915876958809],
						[77.5292350653914, 13.003862360099225],
						[77.52916822719799, 13.003790995976468],
						[77.52910130148068, 13.003724111720063],
						[77.52906569523334, 13.003679528006707],
						[77.52903000149018, 13.003634940072072],
						[77.52899876945574, 13.0035769438864],
						[77.52897199913369, 13.00346104394194],
						[77.52897199913369, 13.003313932867986],
						[77.52899430773584, 13.00313996467482],
						[77.52900323117548, 13.003037437088548],
						[77.52904338664518, 13.00288132213244],
						[77.52911914834249, 13.002725295311812],
						[77.52918607404928, 13.002604884775945],
						[77.52921284432098, 13.002493392520085],
						[77.52920392089779, 13.002368528629697],
						[77.5291414569158, 13.00226598977117],
						[77.52904338664518, 13.002118868067559],
						[77.52887392871703, 13.001993982198238],
						[77.52865539148102, 13.001842399960122],
						[77.52840570911329, 13.001699741730897],
						[77.52814264093837, 13.00159713520399],
						[77.5279954904669, 13.001530243208359],
						[77.52791071701141, 13.00147672318787],
						[77.5278483398052, 13.00141877037249],
						[77.52782603097839, 13.001325164136553],
						[77.52782603097839, 13.001231518641054],
						[77.5278304927441, 13.001124465264587],
						[77.52786618686348, 13.000955030166509],
						[77.52787957215536, 13.000812365765505],
						[77.52788403391897, 13.000660732186514],
						[77.52787511039158, 13.000500171848383],
						[77.52787064862761, 13.000361988922112],
						[77.52787064862761, 13.0002460108649],
						[77.5278483398052, 13.000098857485245],
						[77.52785726333468, 12.999982967395624],
						[77.52786172509917, 12.999893764469595],
						[77.52786172509917, 12.999800120098225],
						[77.52784387804017, 12.999693107439382],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000002b",
			properties: {
				AREA_SQ_KM: 1.0867665833603255,
				ASS_CONST1: "Mahalakshmi Layout",
				ASS_CONST_: "156",
				LAT: 12.9999842826119,
				LON: 77.5344077697553,
				LOST_AREA_SQ_KM: 0.01867153449563419,
				OBJECTID: 44,
				POP_F: 16877.0,
				POP_M: 18802.0,
				POP_SC: 1280.0,
				POP_ST: 308.0,
				POP_TOTAL: 35679.0,
				RESERVATIO: "General",
				WARD_NAME: "Shankar Matt",
				WARD_NO: 75.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56056918800317, 13.017079116772631],
						[77.56077883036103, 13.016949799043333],
						[77.56221911622852, 13.016156066676372],
						[77.56332048352091, 13.015500654961151],
						[77.56458241181144, 13.01480496527437],
						[77.56612969565367, 13.013926596644303],
						[77.56727125434246, 13.01319082662932],
						[77.5681808723936, 13.01261555459968],
						[77.56873384593484, 13.012267805404596],
						[77.56897460678007, 13.012098326306418],
						[77.56905934957841, 13.011991336225657],
						[77.56909503073767, 13.011937776419547],
						[77.5690994908818, 13.01183966898184],
						[77.5691084111695, 13.011639055685585],
						[77.5691128713131, 13.010573335060446],
						[77.56915301259751, 13.00853105768087],
						[77.56919315386769, 13.007612499112614],
						[77.5692153671135, 13.00663150222671],
						[77.56923766780864, 13.005507784109694],
						[77.57074492225951, 13.005454282972966],
						[77.57214056864389, 13.005400726029768],
						[77.57360318479215, 13.005400763186412],
						[77.57358088495687, 13.00631931755818],
						[77.57308145494605, 13.00634158516882],
						[77.57304577504306, 13.007014991511262],
						[77.5728941353298, 13.007157645231068],
						[77.57247498251668, 13.007478664531382],
						[77.57225652921481, 13.007701661447662],
						[77.57218962889993, 13.007768546318516],
						[77.57208258831389, 13.007848843019975],
						[77.57200676783755, 13.007929096137515],
						[77.5719221146967, 13.007982617247585],
						[77.5718239939315, 13.008031606328101],
						[77.5717704734783, 13.008058343303462],
						[77.57165897245282, 13.008080639235308],
						[77.57160991196683, 13.008107419234559],
						[77.57159207178483, 13.008379440395423],
						[77.57170357287619, 13.008374997565307],
						[77.57173033312178, 13.008379395853959],
						[77.57242146237122, 13.007920159539717],
						[77.57309483490678, 13.007670417587269],
						[77.57393759434396, 13.007607992136371],
						[77.57465992959582, 13.007692746355602],
						[77.57533774871251, 13.00779087242039],
						[77.57584162864529, 13.007781926460611],
						[77.57627860843644, 13.007737318321333],
						[77.57687168097081, 13.007456429276484],
						[77.57749596909314, 13.007059577809969],
						[77.57818715057961, 13.006711752735407],
						[77.57863304084879, 13.006439704892518],
						[77.57882927048679, 13.006341632635548],
						[77.58014017017742, 13.005637086253946],
						[77.58159831071168, 13.004803198423918],
						[77.58327495233537, 13.003853410762828],
						[77.58361833988305, 13.003612627632084],
						[77.58393488159287, 13.003296031677673],
						[77.58406420873806, 13.002917013531412],
						[77.58405083007473, 13.00120026267715],
						[77.58417123798772, 12.99933639335172],
						[77.5842202930266, 12.99928735944764],
						[77.58423367166988, 12.999229359676166],
						[77.58424259076452, 12.999082228196757],
						[77.58429610531756, 12.998431176427513],
						[77.5843540793881, 12.997780170691735],
						[77.5853886011289, 12.997686486871462],
						[77.58611095069213, 12.99761516878077],
						[77.5862491942839, 12.997606227531683],
						[77.58636514039175, 12.997583964194868],
						[77.58644095124411, 12.997566146729882],
						[77.5866192420794, 12.997490315506301],
						[77.58673072835872, 12.997427906572218],
						[77.58695370058742, 12.997267384157196],
						[77.58730153638574, 12.996981968172728],
						[77.58771626228439, 12.996656484262136],
						[77.58825576217691, 12.996330980090859],
						[77.5890852054473, 12.995920709016257],
						[77.58932592297936, 12.995809249188758],
						[77.58956226809934, 12.995599607461854],
						[77.5897451007775, 12.99534995650157],
						[77.59062358537273, 12.995381186950187],
						[77.59143508676993, 12.995416860698914],
						[77.59173831626653, 12.99542578710553],
						[77.59279961309339, 12.995474792319321],
						[77.59372266566913, 12.995506004414938],
						[77.59400805237803, 12.995488195194163],
						[77.59415966377375, 12.995483687462364],
						[77.59430226923179, 12.99545252769221],
						[77.59436023816784, 12.995434623369214],
						[77.59444050279939, 12.995416844954821],
						[77.59456981791911, 12.995363311193623],
						[77.59467683721651, 12.995341043889615],
						[77.594881957253, 12.99526968891523],
						[77.59511829074602, 12.995167151931016],
						[77.59515842279947, 12.995149250164033],
						[77.59568013819613, 12.99488177046804],
						[77.5961617194992, 12.994649871070072],
						[77.59613496503626, 12.994912916519464],
						[77.59596106087258, 12.99577352397635],
						[77.59588079732208, 12.996348783193561],
						[77.59575594279676, 12.997240569338263],
						[77.59551961063858, 12.998110083918483],
						[77.59522085040983, 12.999046539326715],
						[77.59481061119561, 13.000433352428344],
						[77.5942353819609, 13.002270456190885],
						[77.59338376801549, 13.005124327120633],
						[77.59325445153743, 13.005561241540684],
						[77.59321431880724, 13.005690578315614],
						[77.59320094122734, 13.00573961310986],
						[77.59308500213514, 13.006042882621218],
						[77.59303149174475, 13.006181061978262],
						[77.59273718414437, 13.006711709239056],
						[77.59248300878133, 13.007108620894758],
						[77.59231355822148, 13.007367220069236],
						[77.59184979749443, 13.008120847321463],
						[77.59113185645923, 13.009217741419638],
						[77.59064588184715, 13.009944597475105],
						[77.59061466678172, 13.009989149829785],
						[77.59018211427271, 13.01065355107892],
						[77.58977631608323, 13.011259988948066],
						[77.5890673680217, 13.012316786986222],
						[77.5883494093027, 13.013235404405366],
						[77.58814427740218, 13.013498495088326],
						[77.58788117289765, 13.0137303224969],
						[77.58766720876284, 13.013868607403422],
						[77.58726586096851, 13.0140826316799],
						[77.58684221452806, 13.014256504877714],
						[77.58657018808165, 13.014332296323952],
						[77.58598162588869, 13.014417037401197],
						[77.58465724044622, 13.014510656667527],
						[77.58399285584868, 13.014524106437221],
						[77.58399731540557, 13.015442673678862],
						[77.5839705580615, 13.016642140804052],
						[77.58394826026992, 13.017083556149618],
						[77.58392596247396, 13.018033369080042],
						[77.58389920511298, 13.019121417214988],
						[77.58389920511298, 13.019598508423227],
						[77.58389920511298, 13.019825970592262],
						[77.58390812423401, 13.02018264775203],
						[77.58392150291424, 13.020383327404558],
						[77.58394826026992, 13.020525998137405],
						[77.5839705580615, 13.020704423407754],
						[77.58409096605997, 13.0211681044359],
						[77.58453246095716, 13.022715461496121],
						[77.58459034746465, 13.022898284621826],
						[77.58451462281292, 13.022938358728004],
						[77.58426934804422, 13.023188131888851],
						[77.5828558379819, 13.024405435210273],
						[77.58268191337615, 13.024561526806654],
						[77.5824455538677, 13.024708647773236],
						[77.58192386479843, 13.024962825061815],
						[77.58142884382987, 13.025163514521497],
						[77.58129514145129, 13.025221486790622],
						[77.58123716581224, 13.0252526704105],
						[77.58118810947906, 13.025297224488613],
						[77.58114797246336, 13.025332979887226],
						[77.58112121444496, 13.025359720947517],
						[77.58109891609148, 13.025395390046038],
						[77.5810364806783, 13.025600519735743],
						[77.58100080328387, 13.025689627881164],
						[77.5809651258782, 13.025734225102765],
						[77.58092498878334, 13.025778865455434],
						[77.58089377103306, 13.025810049003804],
						[77.58085809359362, 13.02583239073366],
						[77.5807822789974, 13.02586357422893],
						[77.58063073709741, 13.025970628865842],
						[77.5804924866353, 13.026073150470857],
						[77.58027396136663, 13.02629609302307],
						[77.58016692842793, 13.026429884524756],
						[77.58007336196879, 13.02647892805397],
						[77.57999754684653, 13.026527967427594],
						[77.57993511082535, 13.026554708173366],
						[77.57980131923529, 13.026585933966713],
						[77.57949359797757, 13.026648341748677],
						[77.5789049989459, 13.026759876011596],
						[77.57859736269621, 13.026822286514475],
						[77.578356534872, 13.026844538495386],
						[77.57809349503594, 13.026862437832138],
						[77.57770112031409, 13.026898061229405],
						[77.57769666050889, 13.027121004288825],
						[77.57768774089797, 13.027241425502305],
						[77.57763868302534, 13.027250352631066],
						[77.57730419696323, 13.027303871139395],
						[77.57654619979083, 13.027428682473364],
						[77.57532436901694, 13.027660613363768],
						[77.57398665382115, 13.027905820155178],
						[77.57281394285032, 13.028137697325057],
						[77.57185521418421, 13.028347318148242],
						[77.57125319524492, 13.028485524780221],
						[77.5711684540884, 13.028788771116023],
						[77.57106149998916, 13.029176686611157],
						[77.56987091826397, 13.03224898895466],
						[77.56964345223949, 13.032561116939107],
						[77.56919315386769, 13.0331720544049],
						[77.56872492561762, 13.033840870012853],
						[77.56822993436525, 13.034518655712711],
						[77.56706608421948, 13.035825175256424],
						[77.5667048929466, 13.03615515277897],
						[77.5659602930662, 13.036939978152514],
						[77.56546528973287, 13.037599907784799],
						[77.56495699077323, 13.038246512547069],
						[77.56470730075536, 13.038117175553927],
						[77.56439953846663, 13.03805921302663],
						[77.56426581597269, 13.038032459532976],
						[77.56388677437688, 13.03798337796852],
						[77.56393137786077, 13.037546446774439],
						[77.56395813994266, 13.037203026721203],
						[77.56393583820818, 13.036297887863006],
						[77.56334278537649, 13.03635135726713],
						[77.56332940426367, 13.036297828561322],
						[77.56318667229527, 13.035624519443804],
						[77.56331156277744, 13.035477366904994],
						[77.56381094841402, 13.035044885647084],
						[77.56423905396346, 13.034688139811717],
						[77.56403396580706, 13.033194305148498],
						[77.56397152098124, 13.032627986884759],
						[77.56386447262837, 13.031968073310834],
						[77.56384217087545, 13.031910146500623],
						[77.56298158223431, 13.032021566468567],
						[77.56297266146419, 13.031575681283641],
						[77.56293251798988, 13.030808689573604],
						[77.56223249747244, 13.030888983893165],
						[77.56222357664333, 13.030839900846965],
						[77.56217005165401, 13.030532291509582],
						[77.56205854117839, 13.029988196121735],
						[77.5618757512203, 13.029421932114131],
						[77.56157698958235, 13.028686161636463],
						[77.56179546348044, 13.028391907670256],
						[77.56188467207676, 13.028262528598024],
						[77.56191143464197, 13.028222421007813],
						[77.5619292763486, 13.028195682616413],
						[77.56194265762672, 13.02816890067514],
						[77.5619471180524, 13.028159973362058],
						[77.5619514910186, 13.02814215720761],
						[77.5619514910186, 13.028119902568687],
						[77.56195595144395, 13.028097605226026],
						[77.5619514910186, 13.02807530704512],
						[77.5619514910186, 13.028044081562781],
						[77.5619514910186, 13.028012856088658],
						[77.5619471180524, 13.027945966687295],
						[77.56183560735748, 13.027549126334979],
						[77.56178654261696, 13.027125467207007],
						[77.56180884477436, 13.026501182094997],
						[77.56203177864792, 13.025992885714327],
						[77.56242420766922, 13.02510102295962],
						[77.56251787617391, 13.0247844276381],
						[77.5626025363431, 13.024494653544593],
						[77.56263375913748, 13.024405424437907],
						[77.56287899333536, 13.023460122768707],
						[77.56309755218219, 13.022425642128614],
						[77.56315544965324, 13.022233855426869],
						[77.56319113267202, 13.021997521341687],
						[77.56319113267202, 13.02181914788612],
						[77.56317329116403, 13.021631889316003],
						[77.56314215597669, 13.021449076443828],
						[77.56310647294251, 13.021324178651241],
						[77.56303064645752, 13.021199321394453],
						[77.5629548199218, 13.021056696097475],
						[77.56276757101594, 13.020708828165143],
						[77.56269620470042, 13.020575044829359],
						[77.56251341577068, 13.020142521959198],
						[77.56241528685523, 13.01993740680045],
						[77.56217451207074, 13.019625247686253],
						[77.561599291781, 13.018943008219868],
						[77.56141641362255, 13.018684457204275],
						[77.56120239944887, 13.018336596080921],
						[77.56097054301642, 13.01787729922],
						[77.56068516041925, 13.017257489487687],
						[77.56056918800317, 13.017079116772631],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000002c",
			properties: {
				AREA_SQ_KM: 7.490183235538021,
				ASS_CONST1: "Malleswaram",
				ASS_CONST_: "157",
				LAT: 13.0153491711673,
				LON: 77.5770224615771,
				LOST_AREA_SQ_KM: 0.28030447455716606,
				OBJECTID: 45,
				POP_F: 14176.0,
				POP_M: 16221.0,
				POP_SC: 2376.0,
				POP_ST: 319.0,
				POP_TOTAL: 30397.0,
				RESERVATIO: "General",
				WARD_NAME: "Aramane Nagara",
				WARD_NO: 35.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5610106871856, 13.040105944730286],
						[77.56082334760782, 13.039883006346352],
						[77.56081442666782, 13.039874033417528],
						[77.56072084421574, 13.03978038268559],
						[77.56071192326768, 13.0397670101783],
						[77.56070746279337, 13.039767052705997],
						[77.56056026704317, 13.039611020023017],
						[77.56037747466904, 13.039463786107671],
						[77.56023919947634, 13.039352354622746],
						[77.5599137579556, 13.039142735520123],
						[77.55961053062943, 13.03899112914124],
						[77.55935190777821, 13.038866271299558],
						[77.55919132866673, 13.038808364832935],
						[77.5588791783162, 13.038696870554958],
						[77.55855819345076, 13.03864783499435],
						[77.558228199071, 13.038594307778416],
						[77.55814344817283, 13.038580967667937],
						[77.55792496723717, 13.03857201193189],
						[77.55790266431573, 13.038571965566517],
						[77.5578580584597, 13.038567559588854],
						[77.55780007082065, 13.038567508028933],
						[77.55734526478011, 13.038567536116616],
						[77.55738094962769, 13.038019071846893],
						[77.55739433144262, 13.037711412642816],
						[77.55751476770612, 13.036667941089842],
						[77.55755928623445, 13.03615959435258],
						[77.55754590443898, 13.036110552001837],
						[77.55755482563615, 13.035999101583428],
						[77.55752806204254, 13.035798450656099],
						[77.55748354350193, 13.035593316226851],
						[77.55743893748105, 13.035410519916516],
						[77.55741663446403, 13.035325764081218],
						[77.55735418599305, 13.035080511997007],
						[77.55735418599305, 13.03507611262828],
						[77.55734080417335, 13.034915533956077],
						[77.55734080417335, 13.034808482849867],
						[77.55733634356645, 13.034777298558721],
						[77.5573452647801, 13.034759443557911],
						[77.55737648902235, 13.034683666739912],
						[77.55738094962769, 13.03467922485925],
						[77.55751030710606, 13.034433956941522],
						[77.55754144384012, 13.034376032162776],
						[77.5582772653515, 13.033314716596962],
						[77.5586562382431, 13.032766265505828],
						[77.55978886372968, 13.031138650254015],
						[77.55992713947165, 13.030960323623908],
						[77.55992267896647, 13.030946909721798],
						[77.55984685035169, 13.030755188964916],
						[77.55951239920107, 13.029916931119429],
						[77.55949455711405, 13.029881217937689],
						[77.55939205252055, 13.029631534652108],
						[77.5592626971888, 13.029319393668224],
						[77.55887471776981, 13.028405235379427],
						[77.55882573920955, 13.028284861724403],
						[77.55878559426671, 13.028195710165457],
						[77.55853589065386, 13.027620437497221],
						[77.5584065343454, 13.027272678870148],
						[77.55891932322592, 13.026996142973836],
						[77.55966405682729, 13.026715230051217],
						[77.55967735091173, 13.026710784943695],
						[77.55941872821293, 13.026095410062643],
						[77.5591601049242, 13.025069807984782],
						[77.55956592544524, 13.025047546132306],
						[77.56011885356732, 13.024980655808806],
						[77.56000734107391, 13.024690784160374],
						[77.55962391218125, 13.023914980120264],
						[77.55944995188423, 13.023402138574935],
						[77.5593206840799, 13.022996348932413],
						[77.55989591593175, 13.022813576800637],
						[77.56051120175063, 13.022661896483577],
						[77.56102406857217, 13.022537082280282],
						[77.56114441352022, 13.022496936622055],
						[77.56120239944887, 13.022358719370205],
						[77.5612826876088, 13.022260655148523],
						[77.56135851526317, 13.022175916789323],
						[77.56145655763382, 13.022068879343331],
						[77.56156806870166, 13.021903927812161],
						[77.56161267309807, 13.021845882550094],
						[77.56155022693818, 13.021671990583744],
						[77.56142087406894, 13.021221609087219],
						[77.56176424045513, 13.021096779190934],
						[77.56276757101594, 13.020708828165143],
						[77.5629548199218, 13.021056696097475],
						[77.56303064645752, 13.021199321394453],
						[77.56310647294251, 13.021324178651241],
						[77.56314215597669, 13.021449076443828],
						[77.56317329116403, 13.021631889316003],
						[77.56319113267202, 13.02181914788612],
						[77.56319113267202, 13.021997521341687],
						[77.56315544965324, 13.022233855426869],
						[77.56309755218219, 13.022425642128614],
						[77.56287899333536, 13.023460122768707],
						[77.56263375913748, 13.024405424437907],
						[77.5626025363431, 13.024494653544593],
						[77.56251787617391, 13.0247844276381],
						[77.56242420766922, 13.02510102295962],
						[77.56203177864792, 13.025992885714327],
						[77.56180884477436, 13.026501182094997],
						[77.56178654261696, 13.027125467207007],
						[77.56183560735748, 13.027549126334979],
						[77.5619471180524, 13.027945966687295],
						[77.5619514910186, 13.028012856088658],
						[77.5619514910186, 13.028044081562781],
						[77.5619514910186, 13.02807530704512],
						[77.56195595144395, 13.028097605226026],
						[77.5619514910186, 13.028119902568687],
						[77.5619514910186, 13.02814215720761],
						[77.5619471180524, 13.028159973362058],
						[77.56194265762672, 13.02816890067514],
						[77.5619292763486, 13.028195682616413],
						[77.56191143464197, 13.028222421007813],
						[77.56188467207676, 13.028262528598024],
						[77.56179546348044, 13.028391907670256],
						[77.56157698958235, 13.028686161636463],
						[77.5618757512203, 13.029421932114131],
						[77.56205854117839, 13.029988196121735],
						[77.56217005165401, 13.030532291509582],
						[77.56222357664333, 13.030839900846965],
						[77.56223249747244, 13.030888983893165],
						[77.56293251798988, 13.030808689573604],
						[77.56297266146419, 13.031575681283641],
						[77.56298158223431, 13.032021566468567],
						[77.56384217087545, 13.031910146500623],
						[77.56386447262837, 13.031968073310834],
						[77.56397152098124, 13.032627986884759],
						[77.56403396580706, 13.033194305148498],
						[77.56423905396346, 13.034688139811717],
						[77.56381094841402, 13.035044885647084],
						[77.56331156277744, 13.035477366904994],
						[77.56318667229527, 13.035624519443804],
						[77.56332940426367, 13.036297828561322],
						[77.56334278537649, 13.03635135726713],
						[77.56393583820818, 13.036297887863006],
						[77.56395813994266, 13.037203026721203],
						[77.56393137786077, 13.037546446774439],
						[77.56388677437688, 13.03798337796852],
						[77.56426581597269, 13.038032459532976],
						[77.56439953846663, 13.03805921302663],
						[77.56470730075536, 13.038117175553927],
						[77.56495699077323, 13.038246512547069],
						[77.5644842846875, 13.038852917219643],
						[77.5642925779756, 13.039089227059977],
						[77.56385109157715, 13.039637707523822],
						[77.56341415128483, 13.04024420780911],
						[77.56221465581353, 13.041880700525862],
						[77.56214328914987, 13.0419832639527],
						[77.56213436831375, 13.042001034032916],
						[77.56177762175275, 13.04135898230689],
						[77.56169287350788, 13.041207355164584],
						[77.56148332030011, 13.040837200005605],
						[77.56136297571184, 13.040632087402743],
						[77.56126930625275, 13.040467088801275],
						[77.56123808308251, 13.040422527641335],
						[77.56105083134057, 13.040163878303876],
						[77.5610106871856, 13.040105944730286],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000002d",
			properties: {
				AREA_SQ_KM: 0.95385519901862,
				ASS_CONST1: "Malleswaram",
				ASS_CONST_: "157",
				LAT: 13.0325895561164,
				LON: 77.5610338494586,
				LOST_AREA_SQ_KM: 0.016379094985273773,
				OBJECTID: 46,
				POP_F: 16790.0,
				POP_M: 19092.0,
				POP_SC: 4459.0,
				POP_ST: 830.0,
				POP_TOTAL: 35882.0,
				RESERVATIO: "General",
				WARD_NAME: "Mattikere",
				WARD_NO: 36.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5584065343454, 13.027272678870148],
						[77.55800525771801, 13.026385324924737],
						[77.55776447356749, 13.025836803053204],
						[77.5577154070651, 13.025725312618183],
						[77.557648498164, 13.025493494288346],
						[77.55756820743058, 13.025225922356134],
						[77.557519228306, 13.025056473548434],
						[77.557407713256, 13.024704240562423],
						[77.55733634356645, 13.024458925279273],
						[77.5571936915156, 13.024044290530753],
						[77.55709109737913, 13.023723199621342],
						[77.5569261419063, 13.023281783926057],
						[77.55682800815491, 13.023014216024052],
						[77.55671211925713, 13.022697573313188],
						[77.55660060341351, 13.022394426374893],
						[77.5562929065799, 13.021618516147687],
						[77.55598529637547, 13.020905062302496],
						[77.55589608313767, 13.020673209731074],
						[77.55570882257402, 13.020173715460517],
						[77.55559730574399, 13.019928536674623],
						[77.55555715965835, 13.019883898011425],
						[77.55546803354814, 13.019843786906106],
						[77.55530744893728, 13.019843770943822],
						[77.5551870977945, 13.019843806256379],
						[77.55465198847855, 13.019825906552798],
						[77.5543264434591, 13.019830405233733],
						[77.55426408075205, 13.019736735243038],
						[77.55393407387878, 13.01925517165061],
						[77.55386270200364, 13.019157098325236],
						[77.55416148396583, 13.018666580848656],
						[77.55424623783937, 13.018506036326785],
						[77.55442011865311, 13.018171618311316],
						[77.55473674198565, 13.017583039298334],
						[77.55503543418128, 13.017047929728488],
						[77.55532529168305, 13.016526193083335],
						[77.55535205579643, 13.016477120315846],
						[77.55536543785075, 13.016454826384996],
						[77.55551701355849, 13.016227485848777],
						[77.55564191248915, 13.016044651228482],
						[77.55570436190293, 13.015964359909558],
						[77.55579794849504, 13.015852888471029],
						[77.55583363382945, 13.015817184952878],
						[77.55582025183035, 13.015812742056967],
						[77.55581579116365, 13.015821668315555],
						[77.55566867652784, 13.01571466977017],
						[77.55560622709443, 13.015665589556777],
						[77.55603427616248, 13.01521971777795],
						[77.5563375130515, 13.014867418134958],
						[77.55677894124736, 13.014385883802625],
						[77.55703756996674, 13.014096023384415],
						[77.55737202841684, 13.013730314463073],
						[77.55777785533719, 13.013257664782587],
						[77.55809438183442, 13.012842962406113],
						[77.55837531038675, 13.012441716571686],
						[77.55855373289172, 13.012102781562653],
						[77.55881235756353, 13.01159894615618],
						[77.55903975786975, 13.011090582179468],
						[77.5591734865292, 13.010698170594925],
						[77.55927607878169, 13.010475190159564],
						[77.55946779397827, 13.009895503120903],
						[77.55966851734263, 13.009266836156437],
						[77.5599137579556, 13.008450762225035],
						[77.56017237952518, 13.007590148516453],
						[77.56030610684871, 13.007117539459827],
						[77.56041761904824, 13.006787536880681],
						[77.56073868610976, 13.00581102514818],
						[77.56094378022907, 13.005828793196804],
						[77.56168395263632, 13.005846654391105],
						[77.56183560735748, 13.005837775624187],
						[77.56238406400067, 13.0058154877265],
						[77.56239298481714, 13.005726227348859],
						[77.56305294837013, 13.005699498748342],
						[77.56381094841402, 13.00568613313506],
						[77.5643193399722, 13.005663800411392],
						[77.56514423606133, 13.005641504906984],
						[77.56674503508442, 13.005579099049356],
						[77.56818533257372, 13.005507802558745],
						[77.56925550836158, 13.005467680638315],
						[77.56923766780864, 13.005507784109694],
						[77.5692153671135, 13.00663150222671],
						[77.56919315386769, 13.007612499112614],
						[77.56915301259751, 13.00853105768087],
						[77.5691128713131, 13.010573335060446],
						[77.5691084111695, 13.011639055685585],
						[77.5690994908818, 13.01183966898184],
						[77.56909503073767, 13.011937776419547],
						[77.56905934957841, 13.011991336225657],
						[77.56897460678007, 13.012098326306418],
						[77.56873384593484, 13.012267805404596],
						[77.5681808723936, 13.01261555459968],
						[77.56727125434246, 13.01319082662932],
						[77.56612969565367, 13.013926596644303],
						[77.56458241181144, 13.01480496527437],
						[77.56332048352091, 13.015500654961151],
						[77.56221911622852, 13.016156066676372],
						[77.56077883036103, 13.016949799043333],
						[77.56056918800317, 13.017079116772631],
						[77.56068516041925, 13.017257489487687],
						[77.56097054301642, 13.01787729922],
						[77.56120239944887, 13.018336596080921],
						[77.56141641362255, 13.018684457204275],
						[77.561599291781, 13.018943008219868],
						[77.56217451207074, 13.019625247686253],
						[77.56241528685523, 13.01993740680045],
						[77.56251341577068, 13.020142521959198],
						[77.56269620470042, 13.020575044829359],
						[77.56276757101594, 13.020708828165143],
						[77.56176424045513, 13.021096779190934],
						[77.56142087406894, 13.021221609087219],
						[77.56155022693818, 13.021671990583744],
						[77.56161267309807, 13.021845882550094],
						[77.56156806870166, 13.021903927812161],
						[77.56145655763382, 13.022068879343331],
						[77.56135851526317, 13.022175916789323],
						[77.5612826876088, 13.022260655148523],
						[77.56120239944887, 13.022358719370205],
						[77.56114441352022, 13.022496936622055],
						[77.56102406857217, 13.022537082280282],
						[77.56051120175063, 13.022661896483577],
						[77.55989591593175, 13.022813576800637],
						[77.5593206840799, 13.022996348932413],
						[77.55944995188423, 13.023402138574935],
						[77.55962391218125, 13.023914980120264],
						[77.56000734107391, 13.024690784160374],
						[77.56011885356732, 13.024980655808806],
						[77.55956592544524, 13.025047546132306],
						[77.5591601049242, 13.025069807984782],
						[77.55941872821293, 13.026095410062643],
						[77.55967735091173, 13.026710784943695],
						[77.55966405682729, 13.026715230051217],
						[77.55891932322592, 13.026996142973836],
						[77.5584065343454, 13.027272678870148],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000002e",
			properties: {
				AREA_SQ_KM: 1.7610276588203915,
				ASS_CONST1: "Malleswaram",
				ASS_CONST_: "157",
				LAT: 13.0140735271848,
				LON: 77.5616732070745,
				LOST_AREA_SQ_KM: 0.07523128811714322,
				OBJECTID: 47,
				POP_F: 17327.0,
				POP_M: 18994.0,
				POP_SC: 3493.0,
				POP_ST: 367.0,
				POP_TOTAL: 36321.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Malleswaram",
				WARD_NO: 45.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57882927048679, 13.006341632635548],
						[77.57882035096517, 13.006297043459023],
						[77.57873115571034, 13.006082983485333],
						[77.57863750061706, 13.005842230923943],
						[77.57861966154289, 13.005721831846282],
						[77.5786018224659, 13.005623684439882],
						[77.5785706040744, 13.004580240148593],
						[77.57854830521805, 13.003719643743278],
						[77.57683154242346, 13.003737469121944],
						[77.57682262274433, 13.002774381894167],
						[77.57441472090612, 13.002810044735426],
						[77.5731171348378, 13.002841195808605],
						[77.57220746898776, 13.002872452940196],
						[77.57220746898776, 13.002854600725694],
						[77.57225206919505, 13.0022035559016],
						[77.57225206919503, 13.001860226550688],
						[77.57253296264571, 13.001864654377963],
						[77.57255972269525, 13.001864653463457],
						[77.57255526268743, 13.001632792374743],
						[77.57255972269525, 13.001253803338052],
						[77.57256864271035, 13.001008532436668],
						[77.57353628527314, 13.001017498387522],
						[77.5737236038267, 13.001017490571543],
						[77.57380825562035, 13.001017439638245],
						[77.5738350153684, 13.000567085391287],
						[77.57388853484551, 12.999786773881715],
						[77.57388853484551, 12.999653014508892],
						[77.57391083462016, 12.99892165157146],
						[77.57408031276401, 12.998921643133261],
						[77.57486954587549, 12.998917238507751],
						[77.57515489273607, 12.998926101508522],
						[77.57563201416622, 12.998926113594008],
						[77.57602894338318, 12.99892164910024],
						[77.57649268157057, 12.998917218684324],
						[77.5767289660711, 12.99893949312814],
						[77.57688060064606, 12.998948452692199],
						[77.57705007434231, 12.998984145738934],
						[77.57717932191319, 12.999019793935329],
						[77.57760746436807, 12.999149127941834],
						[77.57800875900107, 12.99926060717525],
						[77.5780979548255, 12.999269484041706],
						[77.57815593207367, 12.999265038679809],
						[77.57823620821439, 12.99922493091092],
						[77.57833869575353, 12.999189258787482],
						[77.5783654544302, 12.999180287824746],
						[77.57839221310053, 12.99917140309947],
						[77.57840559243333, 12.999153593301399],
						[77.57841451198765, 12.999126771577561],
						[77.57841005221059, 12.99909559559414],
						[77.57837437398767, 12.998854812177575],
						[77.57829855772664, 12.997811345982795],
						[77.5782851783812, 12.997222800179708],
						[77.57906109035584, 12.997200486675865],
						[77.57906109035584, 12.997142533241473],
						[77.57940895043733, 12.997142505679339],
						[77.57942232964986, 12.997347627348104],
						[77.57960955101356, 12.997338725390849],
						[77.5796630677593, 12.997334236577618],
						[77.57981023867954, 12.997334267784488],
						[77.57998862741704, 12.9973342532109],
						[77.58041221209587, 12.997298553268264],
						[77.58085809359362, 12.997267378138842],
						[77.58104540002515, 12.99747244274526],
						[77.58165182651634, 12.998132390412385],
						[77.58204873461038, 12.998556018590401],
						[77.5825035288872, 12.998970713145377],
						[77.58286475718523, 12.999211513366777],
						[77.58315008387764, 12.99931411236221],
						[77.58328387150566, 12.999345320138746],
						[77.58360942073917, 12.999376553337036],
						[77.58387690730733, 12.999389891887972],
						[77.58405528962935, 12.999380992059987],
						[77.58417123798772, 12.99933639335172],
						[77.58405083007473, 13.00120026267715],
						[77.58406420873806, 13.002917013531412],
						[77.58393488159287, 13.003296031677673],
						[77.58361833988305, 13.003612627632084],
						[77.58327495233537, 13.003853410762828],
						[77.58159831071168, 13.004803198423918],
						[77.58014017017742, 13.005637086253946],
						[77.57882927048679, 13.006341632635548],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000002f",
			properties: {
				AREA_SQ_KM: 0.7250489001209074,
				ASS_CONST1: "Malleswaram",
				ASS_CONST_: "157",
				LAT: 13.0013721395302,
				LON: 77.5790601846204,
				LOST_AREA_SQ_KM: 0.023959387938675226,
				OBJECTID: 48,
				POP_F: 16721.0,
				POP_M: 17243.0,
				POP_SC: 3922.0,
				POP_ST: 250.0,
				POP_TOTAL: 33964.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Rajamahal Guttahalli",
				WARD_NO: 64.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56923766780864, 13.005507784109694],
						[77.56925550836158, 13.005467680638315],
						[77.56818533257372, 13.005507802558745],
						[77.56674503508442, 13.005579099049356],
						[77.56514423606133, 13.005641504906984],
						[77.5643193399722, 13.005663800411392],
						[77.56381094841402, 13.00568613313506],
						[77.56305294837013, 13.005699498748342],
						[77.56239298481714, 13.005726227348859],
						[77.56238406400067, 13.0058154877265],
						[77.56183560735748, 13.005837775624187],
						[77.56168395263632, 13.005846654391105],
						[77.56094378022907, 13.005828793196804],
						[77.56073868610976, 13.00581102514818],
						[77.56084118948571, 13.005556826915303],
						[77.56093039883304, 13.0052312681231],
						[77.56105529180135, 13.004887945335279],
						[77.56118901808335, 13.004598088590853],
						[77.56125146444225, 13.004397493904488],
						[77.56135851526317, 13.004156626149575],
						[77.56144317629831, 13.00398271971585],
						[77.56140749272922, 13.003947101539591],
						[77.56122024126711, 13.003229151489718],
						[77.56086795229727, 13.00179330576753],
						[77.56051566223265, 13.000544788390929],
						[77.56036855369395, 13.000063196189021],
						[77.56006086708445, 12.998943969148518],
						[77.56047114486505, 12.998863684069912],
						[77.56091255696919, 12.99877895296581],
						[77.56097946394414, 12.998667492792846],
						[77.56162605441358, 12.998538177357963],
						[77.56226809332271, 12.998440116213526],
						[77.56343645312198, 12.99827064766351],
						[77.56408294211694, 12.998177021523444],
						[77.56426581597269, 12.998752266041228],
						[77.56443522109367, 12.998774510070863],
						[77.56494360985103, 12.99809227761308],
						[77.56534940966488, 12.997575061562248],
						[77.56594245199422, 12.996830345929943],
						[77.56616091747618, 12.996683174741332],
						[77.56621444058044, 12.996705508881906],
						[77.56640168379036, 12.996781302628783],
						[77.56656225268604, 12.996839250167325],
						[77.56674949532108, 12.996870459437837],
						[77.56694574556012, 12.996870454871338],
						[77.56714190800386, 12.996848196791003],
						[77.56719543064463, 12.996843711145786],
						[77.56744957538787, 12.996817010101443],
						[77.56808729602511, 12.996785813536365],
						[77.56854651912583, 12.996781349969316],
						[77.56864018256906, 12.996781303896189],
						[77.56918423358667, 12.996776883607161],
						[77.57013397721045, 12.996767910722038],
						[77.57139591704936, 12.996834809381731],
						[77.57140037710285, 12.99733425744667],
						[77.57136023661512, 12.999416631213117],
						[77.57132009611314, 13.002114374815717],
						[77.57129333577059, 13.003349537002574],
						[77.57135577656004, 13.003349535501203],
						[77.57148511808577, 13.003358458458957],
						[77.57173033312178, 13.003367374155392],
						[77.57197563507823, 13.003389661464803],
						[77.57206474820639, 13.00339413945344],
						[77.5721093484699, 13.003385255208283],
						[77.57216286876292, 13.003354033783873],
						[77.57218070885496, 13.00333618100007],
						[77.57218516887752, 13.00331837171323],
						[77.57218962889993, 13.003278225520479],
						[77.57220746898776, 13.002872452940196],
						[77.5731171348378, 13.002841195808605],
						[77.57441472090612, 13.002810044735426],
						[77.57682262274433, 13.002774381894167],
						[77.57683154242346, 13.003737469121944],
						[77.57854830521805, 13.003719643743278],
						[77.5785706040744, 13.004580240148593],
						[77.5786018224659, 13.005623684439882],
						[77.57861966154289, 13.005721831846282],
						[77.57863750061706, 13.005842230923943],
						[77.57873115571034, 13.006082983485333],
						[77.57882035096517, 13.006297043459023],
						[77.57882927048679, 13.006341632635548],
						[77.57863304084879, 13.006439704892518],
						[77.57818715057961, 13.006711752735407],
						[77.57749596909314, 13.007059577809969],
						[77.57687168097081, 13.007456429276484],
						[77.57627860843644, 13.007737318321333],
						[77.57584162864529, 13.007781926460611],
						[77.57533774871251, 13.00779087242039],
						[77.57465992959582, 13.007692746355602],
						[77.57393759434396, 13.007607992136371],
						[77.57309483490678, 13.007670417587269],
						[77.57242146237122, 13.007920159539717],
						[77.57173033312178, 13.008379395853959],
						[77.57170357287619, 13.008374997565307],
						[77.57159207178483, 13.008379440395423],
						[77.57160991196683, 13.008107419234559],
						[77.57165897245282, 13.008080639235308],
						[77.5717704734783, 13.008058343303462],
						[77.5718239939315, 13.008031606328101],
						[77.5719221146967, 13.007982617247585],
						[77.57200676783755, 13.007929096137515],
						[77.57208258831389, 13.007848843019975],
						[77.57218962889993, 13.007768546318516],
						[77.57225652921481, 13.007701661447662],
						[77.57247498251668, 13.007478664531382],
						[77.5728941353298, 13.007157645231068],
						[77.57304577504306, 13.007014991511262],
						[77.57308145494605, 13.00634158516882],
						[77.57358088495687, 13.00631931755818],
						[77.57360318479215, 13.005400763186412],
						[77.57214056864389, 13.005400726029768],
						[77.57074492225951, 13.005454282972966],
						[77.56923766780864, 13.005507784109694],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000030",
			properties: {
				AREA_SQ_KM: 1.367487689664633,
				ASS_CONST1: "Malleswaram",
				ASS_CONST_: "157",
				LAT: 13.0023854255042,
				LON: 77.5684910900638,
				LOST_AREA_SQ_KM: 0.06008460686836991,
				OBJECTID: 49,
				POP_F: 17083.0,
				POP_M: 16970.0,
				POP_SC: 612.0,
				POP_ST: 138.0,
				POP_TOTAL: 34053.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Kadu Malleshwar Ward",
				WARD_NO: 65.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.55400544570904, 13.015331139662472],
						[77.55403667087064, 13.015170680300951],
						[77.55405888708684, 13.014947687262866],
						[77.55404113160732, 13.014024682234703],
						[77.55405442635087, 13.013966678791318],
						[77.55407673002898, 13.013868576064914],
						[77.55424177711075, 13.013471730643756],
						[77.55438443287404, 13.013132793694293],
						[77.55444242225934, 13.012718174515955],
						[77.55444688298006, 13.012646807087194],
						[77.55443796153844, 13.012156331828896],
						[77.55443350081737, 13.011973449764069],
						[77.55442457937471, 13.011193110970508],
						[77.55444242225934, 13.010314720651781],
						[77.55444242225934, 13.010024857134127],
						[77.55443350081737, 13.008856526583314],
						[77.55442904009614, 13.00856222487045],
						[77.55443350081737, 13.008312511552118],
						[77.55443350081737, 13.008299144061448],
						[77.55443796153844, 13.007719471047267],
						[77.5543978150425, 13.00706847665727],
						[77.55439335431986, 13.007041698551665],
						[77.55438443287404, 13.006974775021554],
						[77.55436658998029, 13.006885600822255],
						[77.55435766853236, 13.006818763629862],
						[77.55431306128222, 13.006582375068854],
						[77.55425962002413, 13.006301490665052],
						[77.55419716981504, 13.005962562610485],
						[77.55404550487839, 13.005213474251784],
						[77.55386270200364, 13.004607075990686],
						[77.55376465306765, 13.004281522539689],
						[77.55375127082449, 13.004228010605996],
						[77.55370666333592, 13.004098736202822],
						[77.55357284076497, 13.00367503887136],
						[77.55339449789076, 13.002885783017096],
						[77.55336327255242, 13.00270300070346],
						[77.5533588117891, 13.002685192408277],
						[77.5532786054853, 13.002341794987304],
						[77.5532429193472, 13.00209213653247],
						[77.55321169396713, 13.001864684884687],
						[77.55322061550515, 13.001864685370695],
						[77.55331429161221, 13.001851280617128],
						[77.55348371309583, 13.00182006770996],
						[77.55390730943087, 13.00172647110835],
						[77.5546207634863, 13.001570404467158],
						[77.55484371149193, 13.001521341751411],
						[77.55553485627128, 13.001378680351795],
						[77.5558247124969, 13.001307326956995],
						[77.5560253548457, 13.001267187420655],
						[77.55629736722784, 13.001240417183592],
						[77.5570064330943, 13.001227024917382],
						[77.55734972538666, 13.001209220219886],
						[77.55760389220801, 13.001151229636301],
						[77.55790266431573, 13.001088841951862],
						[77.5582817259214, 13.000995151858204],
						[77.55880789701449, 13.000848036396986],
						[77.55977102168619, 13.0006696161833],
						[77.56051566223265, 13.000544788390929],
						[77.56086795229727, 13.00179330576753],
						[77.56122024126711, 13.003229151489718],
						[77.56140749272922, 13.003947101539591],
						[77.56144317629831, 13.00398271971585],
						[77.56135851526317, 13.004156626149575],
						[77.56125146444225, 13.004397493904488],
						[77.56118901808335, 13.004598088590853],
						[77.56105529180135, 13.004887945335279],
						[77.56093039883304, 13.0052312681231],
						[77.56084118948571, 13.005556826915303],
						[77.56073868610976, 13.00581102514818],
						[77.56041761904824, 13.006787536880681],
						[77.56030610684871, 13.007117539459827],
						[77.56017237952518, 13.007590148516453],
						[77.5599137579556, 13.008450762225035],
						[77.55966851734263, 13.009266836156437],
						[77.55946779397827, 13.009895503120903],
						[77.55927607878169, 13.010475190159564],
						[77.5591734865292, 13.010698170594925],
						[77.55903975786975, 13.011090582179468],
						[77.55881235756353, 13.01159894615618],
						[77.55855373289172, 13.012102781562653],
						[77.55837531038675, 13.012441716571686],
						[77.55809438183442, 13.012842962406113],
						[77.55777785533719, 13.013257664782587],
						[77.55737202841684, 13.013730314463073],
						[77.55703756996674, 13.014096023384415],
						[77.55677894124736, 13.014385883802625],
						[77.5563375130515, 13.014867418134958],
						[77.55603427616248, 13.01521971777795],
						[77.55560622709443, 13.015665589556777],
						[77.55542788741673, 13.015576398556135],
						[77.55520047986829, 13.015482761575868],
						[77.55501313069165, 13.015447125093472],
						[77.55493738625881, 13.01542931261742],
						[77.55486601501487, 13.015429306325599],
						[77.55400544570904, 13.015331139662472],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000031",
			properties: {
				AREA_SQ_KM: 0.944039105025873,
				ASS_CONST1: "Malleswaram",
				ASS_CONST_: "157",
				LAT: 13.0070401322849,
				LON: 77.5571089856109,
				LOST_AREA_SQ_KM: 0.01818824269804113,
				OBJECTID: 50,
				POP_F: 17007.0,
				POP_M: 17876.0,
				POP_SC: 1040.0,
				POP_ST: 294.0,
				POP_TOTAL: 34883.0,
				RESERVATIO: "General",
				WARD_NAME: "Subramanya Nagar",
				WARD_NO: 66.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5530868798271, 12.998199329181881],
						[77.55374234932818, 12.998342019045124],
						[77.55389392720454, 12.998377635865852],
						[77.55416148396583, 12.998431194044365],
						[77.55486155431063, 12.998573883539729],
						[77.55539666263805, 12.998658576994933],
						[77.55563745181543, 12.99868091589457],
						[77.55606104010857, 12.998658629464938],
						[77.5563910407943, 12.998627418236092],
						[77.55693506315224, 12.998498072302082],
						[77.5572204551881, 12.998435641182311],
						[77.557648498164, 12.998301892066706],
						[77.5581702116212, 12.99812800124938],
						[77.55957484648349, 12.997588384865226],
						[77.56011439306968, 12.997365420551137],
						[77.56017684002055, 12.9973119551716],
						[77.56017684002055, 12.997289620213696],
						[77.56017684002055, 12.997254005019288],
						[77.56017237952518, 12.997222744680808],
						[77.56014561654942, 12.997151427887887],
						[77.56007870908233, 12.997035484119737],
						[77.55992713947165, 12.996879401614482],
						[77.55986915289071, 12.996812525914274],
						[77.55984685035169, 12.996781351804673],
						[77.55982900831731, 12.996709949185625],
						[77.55978440321908, 12.99654049749766],
						[77.5597397981033, 12.996442448331162],
						[77.55969073245566, 12.996344356111],
						[77.55961945166416, 12.996250709115863],
						[77.55951239920107, 12.996139207186564],
						[77.55942318873792, 12.99604555601699],
						[77.55939205252055, 12.995960834878671],
						[77.55932960513744, 12.995782502258445],
						[77.55922255240067, 12.99540341733671],
						[77.5591734865292, 12.995274109600714],
						[77.55916456545938, 12.995216160564162],
						[77.55914226278175, 12.995091337390255],
						[77.5591378022457, 12.995028861226531],
						[77.55914226278175, 12.9950021720124],
						[77.5591734865292, 12.994984278776046],
						[77.55934744725042, 12.994926287797561],
						[77.55972641656514, 12.994796973906158],
						[77.56008763008022, 12.994689997865034],
						[77.56026150193819, 12.994623119713738],
						[77.560323948808, 12.99460078524958],
						[77.56035517223002, 12.994574095993485],
						[77.56037301418158, 12.994556245667138],
						[77.56038193515631, 12.994507178861703],
						[77.56037747466904, 12.994449186937013],
						[77.56013669555608, 12.994065712558323],
						[77.56008763008022, 12.993923040236727],
						[77.56032840929738, 12.993914067346138],
						[77.56179992391192, 12.993856175174557],
						[77.56212544747693, 12.993838274249441],
						[77.562722967074, 12.99374469517067],
						[77.56329372128842, 12.99370897734169],
						[77.56347204859414, 12.993708970077918],
						[77.56401612443177, 12.993637640398516],
						[77.56472505456465, 12.993539521282665],
						[77.56489454645609, 12.99352175302778],
						[77.5655411145887, 12.993481585830821],
						[77.56662023584245, 12.993450323880948],
						[77.56768150601863, 12.993410261149647],
						[77.56775724193622, 12.993405812981095],
						[77.56838604049729, 12.99337900808768],
						[77.56802485343341, 12.993717886001726],
						[77.56765474481624, 12.9942173334609],
						[77.5673247769221, 12.994614203087947],
						[77.5670973057839, 12.994912972615042],
						[77.56696804670214, 12.995225110181162],
						[77.56671381342291, 12.995724465447982],
						[77.5663838427879, 12.996210504253312],
						[77.56604941092773, 12.996634151606846],
						[77.56616091747618, 12.996683174741332],
						[77.56594245199422, 12.996830345929943],
						[77.56534940966488, 12.997575061562248],
						[77.56494360985103, 12.99809227761308],
						[77.56443522109367, 12.998774510070863],
						[77.56426581597269, 12.998752266041228],
						[77.56408294211694, 12.998177021523444],
						[77.56343645312198, 12.99827064766351],
						[77.56226809332271, 12.998440116213526],
						[77.56162605441358, 12.998538177357963],
						[77.56097946394414, 12.998667492792846],
						[77.56091255696919, 12.99877895296581],
						[77.56047114486505, 12.998863684069912],
						[77.56006086708445, 12.998943969148518],
						[77.56036855369395, 13.000063196189021],
						[77.56051566223265, 13.000544788390929],
						[77.55977102168619, 13.0006696161833],
						[77.55880789701449, 13.000848036396986],
						[77.5582817259214, 13.000995151858204],
						[77.55790266431573, 13.001088841951862],
						[77.55760389220801, 13.001151229636301],
						[77.55734972538666, 13.001209220219886],
						[77.5570064330943, 13.001227024917382],
						[77.55629736722784, 13.001240417183592],
						[77.5560253548457, 13.001267187420655],
						[77.5558247124969, 13.001307326956995],
						[77.55553485627128, 13.001378680351795],
						[77.55484371149193, 13.001521341751411],
						[77.5546207634863, 13.001570404467158],
						[77.55390730943087, 13.00172647110835],
						[77.55348371309583, 13.00182006770996],
						[77.55331429161221, 13.001851280617128],
						[77.55322061550515, 13.001864685370695],
						[77.55319385088895, 13.001677377618849],
						[77.55311355700242, 13.00124041274921],
						[77.55300658586488, 13.00094608699216],
						[77.55284608523631, 13.000500242970908],
						[77.55280147739187, 13.000250502171136],
						[77.55269441849367, 12.999670822744903],
						[77.55268549691425, 12.999599505877148],
						[77.55268549691425, 12.999541469692893],
						[77.5526988792831, 12.99934532895568],
						[77.55277917346307, 12.99910447904681],
						[77.55301996819587, 12.998386583799242],
						[77.5530556544041, 12.998288450827111],
						[77.5530556544041, 12.99826620240425],
						[77.55306011517935, 12.998261761540762],
						[77.5530868798271, 12.998199329181881],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000032",
			properties: {
				AREA_SQ_KM: 0.648022375210939,
				ASS_CONST1: "Malleswaram",
				ASS_CONST_: "157",
				LAT: 12.9974360955686,
				LON: 77.5605137788374,
				LOST_AREA_SQ_KM: 0.00544089613312366,
				OBJECTID: 51,
				POP_F: 17029.0,
				POP_M: 18360.0,
				POP_SC: 980.0,
				POP_ST: 226.0,
				POP_TOTAL: 35389.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Gayithri Nagar",
				WARD_NO: 76.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56213436831375, 13.042001034032916],
						[77.56214328914987, 13.0419832639527],
						[77.56221465581353, 13.041880700525862],
						[77.56341415128483, 13.04024420780911],
						[77.56385109157715, 13.039637707523822],
						[77.5642925779756, 13.039089227059977],
						[77.5644842846875, 13.038852917219643],
						[77.56495699077323, 13.038246512547069],
						[77.56546528973287, 13.037599907784799],
						[77.5659602930662, 13.036939978152514],
						[77.5667048929466, 13.03615515277897],
						[77.56706608421948, 13.035825175256424],
						[77.56822993436525, 13.034518655712711],
						[77.56872492561762, 13.033840870012853],
						[77.56919315386769, 13.0331720544049],
						[77.56964345223949, 13.032561116939107],
						[77.56987091826397, 13.03224898895466],
						[77.57106149998916, 13.029176686611157],
						[77.5711684540884, 13.028788771116023],
						[77.57125319524492, 13.028485524780221],
						[77.57185521418421, 13.028347318148242],
						[77.57281394285032, 13.028137697325057],
						[77.57398665382115, 13.027905820155178],
						[77.57532436901694, 13.027660613363768],
						[77.57654619979083, 13.027428682473364],
						[77.57730419696323, 13.027303871139395],
						[77.57763868302534, 13.027250352631066],
						[77.57768774089797, 13.027241425502305],
						[77.57769666050889, 13.027121004288825],
						[77.57770112031409, 13.026898061229405],
						[77.57809349503594, 13.026862437832138],
						[77.578356534872, 13.026844538495386],
						[77.57859736269621, 13.026822286514475],
						[77.5789049989459, 13.026759876011596],
						[77.57949359797757, 13.026648341748677],
						[77.57980131923529, 13.026585933966713],
						[77.57993511082535, 13.026554708173366],
						[77.57999754684653, 13.026527967427594],
						[77.57987267476967, 13.026804436052075],
						[77.57972550393067, 13.02701400850761],
						[77.57961847047294, 13.027236995260791],
						[77.57960955101356, 13.02741978508222],
						[77.57962293020236, 13.02759813291711],
						[77.57954711477818, 13.027673999931489],
						[77.57937773226858, 13.027937016780069],
						[77.57900757332526, 13.02865053483558],
						[77.57860628223541, 13.029297074857787],
						[77.57823620821439, 13.029849977459106],
						[77.57820944950714, 13.029881204294236],
						[77.57785712593812, 13.030246824579468],
						[77.57774563090925, 13.03036280341725],
						[77.57764314283284, 13.030501042873952],
						[77.57737555407279, 13.031045017233646],
						[77.57721946033716, 13.0312858194768],
						[77.57703223501781, 13.031580152263622],
						[77.57675126528605, 13.032079572502138],
						[77.576506061128, 13.032489800991739],
						[77.57602448351209, 13.033359340958558],
						[77.57580148973254, 13.033818610279106],
						[77.5756676932539, 13.034108458004287],
						[77.57549830493606, 13.034514250630997],
						[77.5754269466426, 13.034683674402462],
						[77.57494982434785, 13.034532039779824],
						[77.57440134110142, 13.03456777086652],
						[77.57436566161448, 13.034683710706854],
						[77.57427200290776, 13.03499137443968],
						[77.57383055541082, 13.035049280143172],
						[77.5737146839026, 13.035236561797419],
						[77.57283178283977, 13.035183114743083],
						[77.57169911283464, 13.035084961053219],
						[77.57164113227857, 13.03698010435786],
						[77.57167681262429, 13.03743939499408],
						[77.57259094274504, 13.037408184044745],
						[77.57264892281468, 13.038286613929683],
						[77.57242592238431, 13.038295568705179],
						[77.57253296264571, 13.039334512592456],
						[77.57259540275146, 13.039842816990882],
						[77.5726310827964, 13.04012820010747],
						[77.57207366826049, 13.040190660281374],
						[77.57233234952388, 13.040743586406121],
						[77.57255526268743, 13.041287622491712],
						[77.57266230282653, 13.041648761232281],
						[77.57278718286089, 13.041934156695008],
						[77.57282732284267, 13.042117012087335],
						[77.57317957462132, 13.04255844676646],
						[77.5732865267195, 13.042553965677468],
						[77.57352736533427, 13.04254510036222],
						[77.57384393528301, 13.042495991070984],
						[77.57420956372688, 13.042433556255281],
						[77.57449499970112, 13.042415721121927],
						[77.5747402082169, 13.042429151783422],
						[77.57497658382599, 13.043289694652564],
						[77.57537788778976, 13.043218374903228],
						[77.57548938515183, 13.04381595381241],
						[77.5751504328305, 13.043909532797922],
						[77.57449499970113, 13.044181548920434],
						[77.57437458148728, 13.044217212533681],
						[77.57448161990591, 13.044342059258588],
						[77.57456635858219, 13.04442683019483],
						[77.57468222921851, 13.044680997869747],
						[77.57513705311277, 13.045693200836709],
						[77.57544924611413, 13.046527074857142],
						[77.57558304285106, 13.046848117271342],
						[77.5757435112779, 13.047026458880735],
						[77.57581486937171, 13.047182537980417],
						[77.5758594681575, 13.04732964225145],
						[77.57586392803512, 13.047436667946897],
						[77.57567215313907, 13.047418834731152],
						[77.57548938515183, 13.04740100641935],
						[77.5754670856882, 13.047401047525453],
						[77.57516381254663, 13.047369787842962],
						[77.57411599234091, 13.047320739800751],
						[77.57396881401374, 13.04731188222724],
						[77.57396435406143, 13.047311838653378],
						[77.57366116434329, 13.047294040305802],
						[77.57276042286512, 13.047244953371669],
						[77.57253296264571, 13.047231600311909],
						[77.57136023661512, 13.047178040574181],
						[77.57123089494671, 13.047169183245845],
						[77.57045501673896, 13.047124587456079],
						[77.56984861769355, 13.04703980511576],
						[77.5698084766557, 13.047035357343631],
						[77.56950081567963, 13.046990765124239],
						[77.56919315386769, 13.046950658810777],
						[77.56875168656715, 13.046915022294188],
						[77.56839496084119, 13.046888238396546],
						[77.56834589894103, 13.046883789308714],
						[77.56657563341707, 13.046714362041977],
						[77.56622782135256, 13.046660831591865],
						[77.56603603013454, 13.046634018595043],
						[77.56592461091944, 13.046620678921787],
						[77.56573281918811, 13.046540462925744],
						[77.56556341600721, 13.046469097072828],
						[77.56524682290954, 13.04634417933656],
						[77.56520668024088, 13.046326358670463],
						[77.56502835566498, 13.046223847266095],
						[77.56489900676559, 13.046148039575653],
						[77.56472505456465, 13.046027623187179],
						[77.56456457052249, 13.045911655983128],
						[77.56446198305659, 13.045840338248862],
						[77.56444414174868, 13.045822477539362],
						[77.5643193399722, 13.045666399102728],
						[77.56419891093775, 13.045514846543629],
						[77.56379756735646, 13.044886050968248],
						[77.563124314461, 13.043677711084397],
						[77.56297266146419, 13.04342353959291],
						[77.56290575566578, 13.043307540955961],
						[77.56213436831375, 13.042001034032916],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000033",
			properties: {
				AREA_SQ_KM: 1.9541228553174586,
				ASS_CONST1: "Hebbal",
				ASS_CONST_: "158",
				LAT: 13.0383051456651,
				LON: 77.5705716890458,
				LOST_AREA_SQ_KM: 0.08158627400007058,
				OBJECTID: 52,
				POP_F: 12349.0,
				POP_M: 13862.0,
				POP_SC: 3320.0,
				POP_ST: 372.0,
				POP_TOTAL: 26211.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Radhakrishna Temple Ward",
				WARD_NO: 18.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57586392803512, 13.047436667946897],
						[77.5758594681575, 13.04732964225145],
						[77.57581486937171, 13.047182537980417],
						[77.5757435112779, 13.047026458880735],
						[77.57558304285106, 13.046848117271342],
						[77.57544924611413, 13.046527074857142],
						[77.57513705311277, 13.045693200836709],
						[77.57468222921851, 13.044680997869747],
						[77.57456635858219, 13.04442683019483],
						[77.57448161990591, 13.044342059258588],
						[77.57437458148728, 13.044217212533681],
						[77.57449499970113, 13.044181548920434],
						[77.5751504328305, 13.043909532797922],
						[77.57548938515183, 13.04381595381241],
						[77.57537788778976, 13.043218374903228],
						[77.57497658382599, 13.043289694652564],
						[77.5747402082169, 13.042429151783422],
						[77.57449499970112, 13.042415721121927],
						[77.57420956372688, 13.042433556255281],
						[77.57384393528301, 13.042495991070984],
						[77.57352736533427, 13.04254510036222],
						[77.5732865267195, 13.042553965677468],
						[77.57317957462132, 13.04255844676646],
						[77.57282732284267, 13.042117012087335],
						[77.57278718286089, 13.041934156695008],
						[77.57266230282653, 13.041648761232281],
						[77.57255526268743, 13.041287622491712],
						[77.57233234952388, 13.040743586406121],
						[77.57207366826049, 13.040190660281374],
						[77.5726310827964, 13.04012820010747],
						[77.57259540275146, 13.039842816990882],
						[77.57253296264571, 13.039334512592456],
						[77.57242592238431, 13.038295568705179],
						[77.57264892281468, 13.038286613929683],
						[77.57259094274504, 13.037408184044745],
						[77.57167681262429, 13.03743939499408],
						[77.57164113227857, 13.03698010435786],
						[77.57169911283464, 13.035084961053219],
						[77.57283178283977, 13.035183114743083],
						[77.5737146839026, 13.035236561797419],
						[77.57383055541082, 13.035049280143172],
						[77.57427200290776, 13.03499137443968],
						[77.57436566161448, 13.034683710706854],
						[77.57440134110142, 13.03456777086652],
						[77.57494982434785, 13.034532039779824],
						[77.5754269466426, 13.034683674402462],
						[77.57549830493606, 13.034514250630997],
						[77.5756676932539, 13.034108458004287],
						[77.57580148973254, 13.033818610279106],
						[77.57602448351209, 13.033359340958558],
						[77.576506061128, 13.032489800991739],
						[77.57675126528605, 13.032079572502138],
						[77.57703223501781, 13.031580152263622],
						[77.57721946033716, 13.0312858194768],
						[77.57737555407279, 13.031045017233646],
						[77.57764314283284, 13.030501042873952],
						[77.57774563090925, 13.03036280341725],
						[77.57785712593812, 13.030246824579468],
						[77.57820944950714, 13.029881204294236],
						[77.57896297578046, 13.029800944857687],
						[77.57897189529082, 13.02960029520276],
						[77.57931083616369, 13.029653785644209],
						[77.58053708357703, 13.029725181286095],
						[77.58057276111775, 13.029412991530283],
						[77.58098296458245, 13.02941299221008],
						[77.58096066620169, 13.030772998314829],
						[77.5809918839335, 13.0318699954908],
						[77.58099634360879, 13.032039465281805],
						[77.58121932714809, 13.031994869779616],
						[77.58133081875286, 13.03294013732411],
						[77.58084917423199, 13.035102854329576],
						[77.58058614019261, 13.035053807286443],
						[77.58019368667209, 13.035107281395444],
						[77.5798682150501, 13.035165290261794],
						[77.57969874557574, 13.035200913209257],
						[77.57968090666893, 13.035522002862075],
						[77.579712124754, 13.03581630875365],
						[77.57889161967218, 13.035718194547638],
						[77.57890053918817, 13.035878740247213],
						[77.57891837821806, 13.0362532326086],
						[77.57899865381769, 13.036743760621778],
						[77.57899419406367, 13.03689537835288],
						[77.58008674110273, 13.036828516380877],
						[77.58181237377835, 13.036685828592898],
						[77.58190156660321, 13.036703602763627],
						[77.58205319424397, 13.037229854093056],
						[77.58220027461012, 13.03788080799769],
						[77.58226270938071, 13.038112705850299],
						[77.58232960373952, 13.0386165742322],
						[77.58242325577537, 13.039490551842327],
						[77.58255704426344, 13.040743577100551],
						[77.5826373172803, 13.041537293440445],
						[77.58264623650088, 13.041983242220418],
						[77.58265961533043, 13.042188358915341],
						[77.58285137837997, 13.042170465072429],
						[77.58289597439138, 13.042598559261325],
						[77.5829404829421, 13.04300879201927],
						[77.58302521528199, 13.043940781149665],
						[77.58310548796645, 13.044547215722371],
						[77.58291818494736, 13.04467204782073],
						[77.58230730562431, 13.045077819771901],
						[77.58230284600074, 13.045077862675113],
						[77.58226716900586, 13.04510460633946],
						[77.58224933050421, 13.04511797816952],
						[77.58224487087834, 13.045117934795035],
						[77.58167412476081, 13.045599586868262],
						[77.58166520546355, 13.045603986442995],
						[77.58164290721733, 13.045626287556733],
						[77.5814912790264, 13.045760093173268],
						[77.58146898074587, 13.04578239427605],
						[77.58104985969831, 13.046152498727016],
						[77.58055046265612, 13.04652259736591],
						[77.58036315540484, 13.046665286494434],
						[77.58009557307916, 13.046870434316805],
						[77.57993957054227, 13.046990826944871],
						[77.57954711477818, 13.047276202771483],
						[77.57927515822489, 13.047414445822987],
						[77.57891837821806, 13.047539261776544],
						[77.57891391846084, 13.047539218300408],
						[77.57888270015548, 13.047548145587353],
						[77.57859736269621, 13.047615078765938],
						[77.57857506384514, 13.047619520294512],
						[77.57848140862264, 13.047641815566129],
						[77.57818269079357, 13.047664098816988],
						[77.5778838847287, 13.04764625873233],
						[77.57712589144002, 13.047561567450574],
						[77.57704561451145, 13.04755267422408],
						[77.57686276129485, 13.047534842661301],
						[77.57654619979083, 13.047503630852214],
						[77.57645254288876, 13.047494693145213],
						[77.5761849513129, 13.047463437777338],
						[77.57586392803512, 13.047436667946897],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000034",
			properties: {
				AREA_SQ_KM: 1.5441805914932796,
				ASS_CONST1: "Hebbal",
				ASS_CONST_: "158",
				LAT: 13.0393013347298,
				LON: 77.5777997091635,
				LOST_AREA_SQ_KM: 0.06372348757632385,
				OBJECTID: 53,
				POP_F: 11578.0,
				POP_M: 12600.0,
				POP_SC: 848.0,
				POP_ST: 237.0,
				POP_TOTAL: 24178.0,
				RESERVATIO: "Backward Category - B",
				WARD_NAME: "Sanjaya Nagar",
				WARD_NO: 19.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59032927149775, 13.042826012652817],
						[77.59013306182177, 13.042861642968308],
						[77.58970942613183, 13.042857195133063],
						[77.58937051643606, 13.042794813184104],
						[77.5893170042859, 13.042785839753018],
						[77.58888007488267, 13.042665482696812],
						[77.58867940331774, 13.042616431755516],
						[77.58862143146597, 13.04261198762469],
						[77.5884787313964, 13.04259861253576],
						[77.58836278745719, 13.042607496969907],
						[77.58827805911866, 13.042611938690568],
						[77.58819779010777, 13.042625309993428],
						[77.58814427740218, 13.042634281693225],
						[77.58803725191493, 13.042652052495182],
						[77.58781874123038, 13.04269220957339],
						[77.58762707404755, 13.042732371187576],
						[77.58690910617688, 13.042861638751653],
						[77.58607081542414, 13.043017692821008],
						[77.58580324660664, 13.043071261903807],
						[77.58560702907121, 13.043129232900142],
						[77.58532170858015, 13.043209464032763],
						[77.58517454483355, 13.043267479302441],
						[77.5850095428292, 13.043338780238743],
						[77.58493819053652, 13.043374495750061],
						[77.58484008106052, 13.043427983009687],
						[77.58478210723923, 13.043459169480828],
						[77.58455029909858, 13.043615237410991],
						[77.5844165129693, 13.043704441149726],
						[77.5839304220335, 13.044016609657128],
						[77.58310548796645, 13.044547215722371],
						[77.58302521528199, 13.043940781149665],
						[77.5829404829421, 13.04300879201927],
						[77.58289597439138, 13.042598559261325],
						[77.58285137837997, 13.042170465072429],
						[77.58265961533043, 13.042188358915341],
						[77.58264623650088, 13.041983242220418],
						[77.5826373172803, 13.041537293440445],
						[77.58255704426344, 13.040743577100551],
						[77.58242325577537, 13.039490551842327],
						[77.58232960373952, 13.0386165742322],
						[77.58226270938071, 13.038112705850299],
						[77.58220027461012, 13.03788080799769],
						[77.58205319424397, 13.037229854093056],
						[77.58190156660321, 13.036703602763627],
						[77.58181237377835, 13.036685828592898],
						[77.58008674110273, 13.036828516380877],
						[77.57899419406367, 13.03689537835288],
						[77.57899865381769, 13.036743760621778],
						[77.57891837821806, 13.0362532326086],
						[77.57890053918817, 13.035878740247213],
						[77.57889161967218, 13.035718194547638],
						[77.579712124754, 13.03581630875365],
						[77.57968090666893, 13.035522002862075],
						[77.57969874557574, 13.035200913209257],
						[77.5798682150501, 13.035165290261794],
						[77.58019368667209, 13.035107281395444],
						[77.58058614019261, 13.035053807286443],
						[77.58084917423199, 13.035102854329576],
						[77.58133081875286, 13.03294013732411],
						[77.58121932714809, 13.031994869779616],
						[77.58099634360879, 13.032039465281805],
						[77.5809918839335, 13.0318699954908],
						[77.58096066620169, 13.030772998314829],
						[77.58098296458245, 13.02941299221008],
						[77.58057276111775, 13.029412991530283],
						[77.58053708357703, 13.029725181286095],
						[77.57931083616369, 13.029653785644209],
						[77.57897189529082, 13.02960029520276],
						[77.57896297578046, 13.029800944857687],
						[77.57820944950714, 13.029881204294236],
						[77.57823620821439, 13.029849977459106],
						[77.57860628223541, 13.029297074857787],
						[77.57900757332526, 13.02865053483558],
						[77.57937773226858, 13.027937016780069],
						[77.57954711477818, 13.027673999931489],
						[77.57962293020236, 13.02759813291711],
						[77.57960955101356, 13.02741978508222],
						[77.57961847047294, 13.027236995260791],
						[77.57972550393067, 13.02701400850761],
						[77.57987267476967, 13.026804436052075],
						[77.57999754684653, 13.026527967427594],
						[77.58007336196879, 13.02647892805397],
						[77.58016692842793, 13.026429884524756],
						[77.58027396136663, 13.02629609302307],
						[77.5804924866353, 13.026073150470857],
						[77.58063073709741, 13.025970628865842],
						[77.5807822789974, 13.02586357422893],
						[77.58085809359362, 13.02583239073366],
						[77.58089377103306, 13.025810049003804],
						[77.58092498878334, 13.025778865455434],
						[77.5809651258782, 13.025734225102765],
						[77.58100080328387, 13.025689627881164],
						[77.5810364806783, 13.025600519735743],
						[77.58109891609148, 13.025395390046038],
						[77.58112121444496, 13.025359720947517],
						[77.58114797246336, 13.025332979887226],
						[77.58118810947906, 13.025297224488613],
						[77.58123716581224, 13.0252526704105],
						[77.58129514145129, 13.025221486790622],
						[77.58142884382987, 13.025163514521497],
						[77.58192386479843, 13.024962825061815],
						[77.5824455538677, 13.024708647773236],
						[77.58268191337615, 13.024561526806654],
						[77.5828558379819, 13.024405435210273],
						[77.58426934804422, 13.023188131888851],
						[77.58451462281292, 13.022938358728004],
						[77.58459034746465, 13.022898284621826],
						[77.58465278091535, 13.02309004016358],
						[77.58470629527422, 13.023286195589424],
						[77.58508535459084, 13.023179179353345],
						[77.58510765215811, 13.023170250764887],
						[77.58520576140187, 13.02313462239862],
						[77.58529495154956, 13.023125648806818],
						[77.58527265401924, 13.023023128537798],
						[77.58537076311977, 13.022974043410606],
						[77.58607527489906, 13.022755605455238],
						[77.58643649178362, 13.022688698124623],
						[77.58652122150295, 13.022697623177079],
						[77.58689572785028, 13.022599526976924],
						[77.58694924114715, 13.022697603607444],
						[77.58705180822868, 13.022956253498515],
						[77.5875557234074, 13.022768918352215],
						[77.58772072169437, 13.023139102141831],
						[77.58789009170445, 13.023611718802755],
						[77.58804171131226, 13.023946150632128],
						[77.58839400314484, 13.023803502491676],
						[77.58875967198648, 13.023616212463153],
						[77.58892912787766, 13.023527010248872],
						[77.58899601829106, 13.023468996122984],
						[77.5889915589314, 13.023321876561324],
						[77.58920552055831, 13.02329063592507],
						[77.58956672743648, 13.023241579281555],
						[77.58951767471832, 13.022983011596665],
						[77.58942402856088, 13.022581683869362],
						[77.58942848790348, 13.022501417379035],
						[77.58986104329814, 13.022523694444486],
						[77.59036940525314, 13.022590607144728],
						[77.59030697496082, 13.022804625932624],
						[77.5902713004926, 13.022987416797584],
						[77.5903426494178, 13.023433388556453],
						[77.59047196922987, 13.023781149273193],
						[77.59107834514342, 13.023709861025022],
						[77.59116307138174, 13.023696442685925],
						[77.591448464559, 13.023638458736132],
						[77.59137265706661, 13.023749957524352],
						[77.59185425674127, 13.02382575618142],
						[77.59190776768946, 13.023834638274911],
						[77.59189438995479, 13.024186980905192],
						[77.59193898239752, 13.024561534290493],
						[77.59198803406422, 13.024855820221484],
						[77.59204154494903, 13.025051981433018],
						[77.5921797812841, 13.02505201718773],
						[77.59242057965727, 13.025025262272962],
						[77.59271488808278, 13.024985133118477],
						[77.59284866438627, 13.024967268868135],
						[77.59290663406857, 13.024940566812466],
						[77.59317864525731, 13.024944993644455],
						[77.59361564546734, 13.024909340451215],
						[77.59415520461796, 13.024806781819697],
						[77.59477493815001, 13.024722068781129],
						[77.5948596616149, 13.025359688564903],
						[77.59496222151364, 13.025966169130466],
						[77.59504248571724, 13.026242558698062],
						[77.59509599515461, 13.026550265191052],
						[77.59522976863705, 13.027241421058788],
						[77.59544826498416, 13.028414162578626],
						[77.5953813783922, 13.028431980673908],
						[77.5950692404391, 13.028498855695766],
						[77.59480615206553, 13.028543513673684],
						[77.59439145219801, 13.028592579005204],
						[77.59415966377375, 13.028588109524396],
						[77.59367807393072, 13.028588047779076],
						[77.5936557780549, 13.02850777751618],
						[77.59263462222461, 13.028712925212949],
						[77.59223329207808, 13.028793170788132],
						[77.59187209372682, 13.028895800062896],
						[77.5918364197529, 13.028775373256758],
						[77.59164467195006, 13.027780947376352],
						[77.59107834514342, 13.027705186565484],
						[77.59062804466797, 13.027691794302921],
						[77.59052994013204, 13.027901423363325],
						[77.59030697496082, 13.028356224577019],
						[77.5899948229818, 13.028824400834928],
						[77.59013306182177, 13.028913552495043],
						[77.59032927149775, 13.029145430108159],
						[77.59045413202321, 13.02928819715848],
						[77.59083763134558, 13.029216841656943],
						[77.59087330563497, 13.029636010948549],
						[77.59017319564687, 13.0296894757381],
						[77.58849210953558, 13.029814308921358],
						[77.58823346523077, 13.028721841594328],
						[77.5876805869981, 13.028659402413826],
						[77.58741302199194, 13.028650522814415],
						[77.5874085625698, 13.02856136769285],
						[77.58656126917067, 13.02853455547879],
						[77.586186761715, 13.028543451146804],
						[77.586209059065, 13.028592578707467],
						[77.58645432962454, 13.029207861948953],
						[77.586886808965, 13.030313738273982],
						[77.58729261753248, 13.031236744882785],
						[77.58786779468613, 13.03256115978315],
						[77.58816657436591, 13.033176467247955],
						[77.58821562767068, 13.033310226670475],
						[77.58826468095415, 13.033475215679507],
						[77.58839400314484, 13.034028157583997],
						[77.58849656891496, 13.034407182528849],
						[77.58863926896198, 13.034821881271977],
						[77.5887641313553, 13.035223209929008],
						[77.58910750222537, 13.03608828865948],
						[77.58915646768332, 13.036222047740187],
						[77.58919660185535, 13.03638704154045],
						[77.58920106120692, 13.036766075750155],
						[77.58923673601313, 13.03761326708],
						[77.58922781731263, 13.037845167403313],
						[77.58926349211039, 13.03826436631547],
						[77.5893348416721, 13.038589874214816],
						[77.58937051643606, 13.038839548840455],
						[77.58946416263787, 13.039280967109827],
						[77.58963807680668, 13.040230801172909],
						[77.58984766532106, 13.040172782416203],
						[77.59032035288351, 13.040137151973553],
						[77.59073952699171, 13.040025685177287],
						[77.59092672961098, 13.040021194307217],
						[77.59099361884155, 13.040074727515865],
						[77.59106942658833, 13.04018175102787],
						[77.59118090847645, 13.040333378647924],
						[77.59138157559778, 13.040427029464155],
						[77.59150197569944, 13.040511707974563],
						[77.59161345716063, 13.040511750688513],
						[77.5916892644914, 13.04039135352321],
						[77.59176507177126, 13.04029321533655],
						[77.59187655297276, 13.04021742214684],
						[77.59198357482268, 13.040181703630607],
						[77.59218424051791, 13.040159486372694],
						[77.59270151044372, 13.040079159645485],
						[77.59311621651783, 13.039989989600592],
						[77.5935799720442, 13.039909747357859],
						[77.59426213687401, 13.039825052658975],
						[77.59472588769393, 13.03968234424079],
						[77.59513166809876, 13.03952622014634],
						[77.59553744704512, 13.039392440415265],
						[77.5961171287241, 13.03924532530691],
						[77.59622414655473, 13.039218576855962],
						[77.59634900056221, 13.039187341490468],
						[77.59637129590617, 13.039298851790628],
						[77.59642926377983, 13.039468252062942],
						[77.59650952694037, 13.039642181446743],
						[77.5967235617561, 13.03994094853251],
						[77.59688854664957, 13.040083601176129],
						[77.59693768359826, 13.040119279145923],
						[77.59699119213728, 13.040315556085453],
						[77.59705361873407, 13.0406187297851],
						[77.59725873445184, 13.040578605161171],
						[77.59742817758973, 13.041207299706661],
						[77.59679044755278, 13.04122512902055],
						[77.59545718319343, 13.04126083456786],
						[77.59522085040983, 13.041269768661907],
						[77.59502464923249, 13.041260885688999],
						[77.59478831554344, 13.041247473698595],
						[77.59471696942688, 13.041238588385987],
						[77.59458765447572, 13.041220688081626],
						[77.59418641870481, 13.041167206912737],
						[77.59377171656106, 13.04111367795498],
						[77.59358889040104, 13.041086977668337],
						[77.59349970680093, 13.041082492314077],
						[77.59343281905463, 13.04107804982644],
						[77.59314743089189, 13.041060193583172],
						[77.59300027733865, 13.041055751716673],
						[77.59288879724642, 13.04105570952871],
						[77.59268813280306, 13.04105575423252],
						[77.59228234361693, 13.041104804552399],
						[77.592139648172, 13.04112706438594],
						[77.59197465633905, 13.041158253725184],
						[77.59192114542255, 13.041167226585435],
						[77.5918364197529, 13.041189529112646],
						[77.59137265706661, 13.041296511701127],
						[77.59100699668292, 13.041367861453569],
						[77.59075290486315, 13.041425799192652],
						[77.59022224808038, 13.041541794429268],
						[77.58995468909333, 13.041599769879866],
						[77.58993685180499, 13.041604169803554],
						[77.59000374162173, 13.041849408489718],
						[77.5901419804508, 13.04230869887667],
						[77.59016427702029, 13.042371119765495],
						[77.59022670739145, 13.042527193783751],
						[77.59026238187379, 13.04263426288444],
						[77.59032927149775, 13.042826012652817],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000035",
			properties: {
				AREA_SQ_KM: 2.267801975945417,
				ASS_CONST1: "Hebbal",
				ASS_CONST_: "158",
				LAT: 13.0325549216468,
				LON: 77.5863604495174,
				LOST_AREA_SQ_KM: 0.08890071088927026,
				OBJECTID: 54,
				POP_F: 12568.0,
				POP_M: 13688.0,
				POP_SC: 2842.0,
				POP_ST: 286.0,
				POP_TOTAL: 26256.0,
				RESERVATIO: "General",
				WARD_NAME: "Ganga Nagar",
				WARD_NO: 20.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59544826498416, 13.028414162578626],
						[77.59579607462352, 13.028356170292598],
						[77.59584512461464, 13.028490011214204],
						[77.59588525640973, 13.02889128259868],
						[77.59600119262645, 13.029363937879761],
						[77.59600565170933, 13.029925846439259],
						[77.59600119262645, 13.030090835390276],
						[77.5961171287241, 13.030077457230346],
						[77.59648723162371, 13.030059621507437],
						[77.59647385443161, 13.030411815030963],
						[77.59556420164968, 13.030447494092803],
						[77.59556866074982, 13.030795373330806],
						[77.59555974254936, 13.031022779245822],
						[77.59553744704512, 13.031415176565043],
						[77.59556420164968, 13.031700555111946],
						[77.59561771083976, 13.031910101843797],
						[77.59566676090839, 13.032115206308337],
						[77.5957648609817, 13.03225349255963],
						[77.59609483332996, 13.032333707754072],
						[77.59621522840605, 13.03244072017281],
						[77.59636237776913, 13.032534359427656],
						[77.5964961497509, 13.03253435267848],
						[77.59661654439896, 13.032529903598153],
						[77.59700456926807, 13.032525444624143],
						[77.5973434560526, 13.032525426357324],
						[77.59747722687165, 13.032521019189815],
						[77.5976823418196, 13.032592353778384],
						[77.59782948927001, 13.032636904442079],
						[77.5979766365286, 13.032779631572842],
						[77.59809702959745, 13.032908988382417],
						[77.59823079952334, 13.033015957173061],
						[77.59846266701925, 13.033118520549575],
						[77.59869453403876, 13.033189853581533],
						[77.59889964677427, 13.03327016036584],
						[77.59919393830882, 13.033265656502218],
						[77.59949268802097, 13.033261195090848],
						[77.59980927267362, 13.03324784611272],
						[77.60015260995489, 13.033274611404144],
						[77.6002151222326, 13.033279097689592],
						[77.60024633464361, 13.033279052504172],
						[77.60037564311156, 13.03331920364781],
						[77.60043806783612, 13.033354829918494],
						[77.60047373909183, 13.033381658207107],
						[77.60049603362089, 13.033394985784849],
						[77.60054062266582, 13.033408355014057],
						[77.60060750620018, 13.033439624249331],
						[77.60067438969489, 13.03349755164233],
						[77.60072789646212, 13.033542150876547],
						[77.60081261545831, 13.033635793933717],
						[77.60085274548693, 13.03366697874805],
						[77.6008973343909, 13.033702692579977],
						[77.60098651214594, 13.0337205449764],
						[77.6010132654587, 13.033720543161133],
						[77.60104447764891, 13.033720497902854],
						[77.60111581976554, 13.033733865295972],
						[77.60124066836107, 13.033791831985104],
						[77.6012897159858, 13.03382750239078],
						[77.60136105794734, 13.033898845079861],
						[77.60139227004133, 13.033925630645829],
						[77.60143239986378, 13.033961301710404],
						[77.60149036513765, 13.034010343858872],
						[77.60154387151786, 13.034050457083435],
						[77.60163750762219, 13.034117355313931],
						[77.60180248533116, 13.03422885181962],
						[77.60188274466992, 13.03429130800884],
						[77.60193625086401, 13.034349279899955],
						[77.60195408625641, 13.034389395746095],
						[77.60195854510407, 13.034433955625863],
						[77.60196300395155, 13.034460743201437],
						[77.60196300395155, 13.03450086033991],
						[77.60196746279885, 13.034612282217646],
						[77.60197646792132, 13.03471935130049],
						[77.60204780944855, 13.034920018934836],
						[77.60207902135252, 13.035000294544378],
						[77.60214144513456, 13.035067152478154],
						[77.60220832771985, 13.035120680823619],
						[77.60181140303828, 13.035129546749268],
						[77.60140564665039, 13.03505826845234],
						[77.60098651214594, 13.035031464485128],
						[77.6005896705949, 13.035035932527308],
						[77.60015260995489, 13.035102816804384],
						[77.59988061557131, 13.035156365496242],
						[77.59922960995783, 13.035250008952673],
						[77.59906462848666, 13.035276719538183],
						[77.59890410574265, 13.035312445290687],
						[77.59882384428502, 13.035343594188594],
						[77.59880154942554, 13.035379269346576],
						[77.59855630568062, 13.03544618700082],
						[77.59822634052836, 13.035530923954264],
						[77.59774030901995, 13.035624510702524],
						[77.59734791508245, 13.035682504318723],
						[77.59711158625731, 13.0357404471946],
						[77.59699565118105, 13.035851917051751],
						[77.5970402416091, 13.03584751520207],
						[77.59707591393884, 13.035847513658544],
						[77.59710266817875, 13.035865371037257],
						[77.59713834048877, 13.035923345061],
						[77.59719184893265, 13.036021435415472],
						[77.59725427541846, 13.036155156391338],
						[77.59734791508245, 13.036395984825493],
						[77.5974593907716, 13.036641213047801],
						[77.59749060394483, 13.03666799973469],
						[77.59745047272052, 13.036806169053376],
						[77.59740588245455, 13.036948868062629],
						[77.59732561993141, 13.037006847517935],
						[77.5971606356768, 13.037104990922119],
						[77.59697335596042, 13.037154001967526],
						[77.5968707104565, 13.037265510934969],
						[77.59679936565601, 13.037394838565557],
						[77.596710184592, 13.037644519550014],
						[77.59666559403357, 13.037818450327615],
						[77.59656749474303, 13.038085991082315],
						[77.59648277255985, 13.038295512010878],
						[77.5964426409772, 13.038509604299108],
						[77.59627765543199, 13.0389287766826],
						[77.59622414655473, 13.039218576855962],
						[77.5961171287241, 13.03924532530691],
						[77.59553744704512, 13.039392440415265],
						[77.59513166809876, 13.03952622014634],
						[77.59472588769393, 13.03968234424079],
						[77.59426213687401, 13.039825052658975],
						[77.5935799720442, 13.039909747357859],
						[77.59311621651783, 13.039989989600592],
						[77.59270151044372, 13.040079159645485],
						[77.59218424051791, 13.040159486372694],
						[77.59198357482268, 13.040181703630607],
						[77.59187655297276, 13.04021742214684],
						[77.59176507177126, 13.04029321533655],
						[77.5916892644914, 13.04039135352321],
						[77.59161345716063, 13.040511750688513],
						[77.59150197569944, 13.040511707974563],
						[77.59138157559778, 13.040427029464155],
						[77.59118090847645, 13.040333378647924],
						[77.59106942658833, 13.04018175102787],
						[77.59099361884155, 13.040074727515865],
						[77.59092672961098, 13.040021194307217],
						[77.59073952699171, 13.040025685177287],
						[77.59032035288351, 13.040137151973553],
						[77.58984766532106, 13.040172782416203],
						[77.58963807680668, 13.040230801172909],
						[77.58946416263787, 13.039280967109827],
						[77.58937051643606, 13.038839548840455],
						[77.5893348416721, 13.038589874214816],
						[77.58926349211039, 13.03826436631547],
						[77.58922781731263, 13.037845167403313],
						[77.58923673601313, 13.03761326708],
						[77.58920106120692, 13.036766075750155],
						[77.58919660185535, 13.03638704154045],
						[77.58915646768332, 13.036222047740187],
						[77.58910750222537, 13.03608828865948],
						[77.5887641313553, 13.035223209929008],
						[77.58863926896198, 13.034821881271977],
						[77.58849656891496, 13.034407182528849],
						[77.58839400314484, 13.034028157583997],
						[77.58826468095415, 13.033475215679507],
						[77.58821562767068, 13.033310226670475],
						[77.58816657436591, 13.033176467247955],
						[77.58786779468613, 13.03256115978315],
						[77.58729261753248, 13.031236744882785],
						[77.586886808965, 13.030313738273982],
						[77.58645432962454, 13.029207861948953],
						[77.586209059065, 13.028592578707467],
						[77.586186761715, 13.028543451146804],
						[77.58656126917067, 13.02853455547879],
						[77.5874085625698, 13.02856136769285],
						[77.58741302199194, 13.028650522814415],
						[77.5876805869981, 13.028659402413826],
						[77.58823346523077, 13.028721841594328],
						[77.58849210953558, 13.029814308921358],
						[77.59017319564687, 13.0296894757381],
						[77.59087330563497, 13.029636010948549],
						[77.59083763134558, 13.029216841656943],
						[77.59045413202321, 13.02928819715848],
						[77.59032927149775, 13.029145430108159],
						[77.59013306182177, 13.028913552495043],
						[77.5899948229818, 13.028824400834928],
						[77.59030697496082, 13.028356224577019],
						[77.59052994013204, 13.027901423363325],
						[77.59062804466797, 13.027691794302921],
						[77.59107834514342, 13.027705186565484],
						[77.59164467195006, 13.027780947376352],
						[77.5918364197529, 13.028775373256758],
						[77.59187209372682, 13.028895800062896],
						[77.59223329207808, 13.028793170788132],
						[77.59263462222461, 13.028712925212949],
						[77.5936557780549, 13.02850777751618],
						[77.59367807393072, 13.028588047779076],
						[77.59415966377375, 13.028588109524396],
						[77.59439145219801, 13.028592579005204],
						[77.59480615206553, 13.028543513673684],
						[77.5950692404391, 13.028498855695766],
						[77.5953813783922, 13.028431980673908],
						[77.59544826498416, 13.028414162578626],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000036",
			properties: {
				AREA_SQ_KM: 1.2324308117408302,
				ASS_CONST1: "Hebbal",
				ASS_CONST_: "158",
				LAT: 13.0340544601812,
				LON: 77.5930187449416,
				LOST_AREA_SQ_KM: 0.02703175729905671,
				OBJECTID: 55,
				POP_F: 11601.0,
				POP_M: 12560.0,
				POP_SC: 3131.0,
				POP_ST: 576.0,
				POP_TOTAL: 24161.0,
				RESERVATIO: "General",
				WARD_NAME: "Hebbala",
				WARD_NO: 21.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59742817758973, 13.041207299706661],
						[77.59725873445184, 13.040578605161171],
						[77.59705361873407, 13.0406187297851],
						[77.59699119213728, 13.040315556085453],
						[77.59693768359826, 13.040119279145923],
						[77.59688854664957, 13.040083601176129],
						[77.5967235617561, 13.03994094853251],
						[77.59650952694037, 13.039642181446743],
						[77.59642926377983, 13.039468252062942],
						[77.59637129590617, 13.039298851790628],
						[77.59634900056221, 13.039187341490468],
						[77.59622414655473, 13.039218576855962],
						[77.59627765543199, 13.0389287766826],
						[77.5964426409772, 13.038509604299108],
						[77.59648277255985, 13.038295512010878],
						[77.59656749474303, 13.038085991082315],
						[77.59666559403357, 13.037818450327615],
						[77.596710184592, 13.037644519550014],
						[77.59679936565601, 13.037394838565557],
						[77.5968707104565, 13.037265510934969],
						[77.59697335596042, 13.037154001967526],
						[77.5971606356768, 13.037104990922119],
						[77.59732561993141, 13.037006847517935],
						[77.59740588245455, 13.036948868062629],
						[77.59745047272052, 13.036806169053376],
						[77.59749060394483, 13.03666799973469],
						[77.5974593907716, 13.036641213047801],
						[77.59734791508245, 13.036395984825493],
						[77.59725427541846, 13.036155156391338],
						[77.59719184893265, 13.036021435415472],
						[77.59713834048877, 13.035923345061],
						[77.59710266817875, 13.035865371037257],
						[77.59707591393884, 13.035847513658544],
						[77.5970402416091, 13.03584751520207],
						[77.59699565118105, 13.035851917051751],
						[77.59711158625731, 13.0357404471946],
						[77.59734791508245, 13.035682504318723],
						[77.59774030901995, 13.035624510702524],
						[77.59822634052836, 13.035530923954264],
						[77.59855630568062, 13.03544618700082],
						[77.59880154942554, 13.035379269346576],
						[77.59882384428502, 13.035343594188594],
						[77.59890410574265, 13.035312445290687],
						[77.59906462848666, 13.035276719538183],
						[77.59922960995783, 13.035250008952673],
						[77.59988061557131, 13.035156365496242],
						[77.60015260995489, 13.035102816804384],
						[77.6005896705949, 13.035035932527308],
						[77.60098651214594, 13.035031464485128],
						[77.60140564665039, 13.03505826845234],
						[77.60181140303828, 13.035129546749268],
						[77.60220832771985, 13.035120680823619],
						[77.60240897523776, 13.03510285127684],
						[77.60296186877366, 13.035018090635766],
						[77.60351484702653, 13.034969050092338],
						[77.60415245162922, 13.034884322704897],
						[77.60463854294001, 13.03482186856473],
						[77.60465637790509, 13.03468814191688],
						[77.60464300168154, 13.034532029821607],
						[77.60462070797212, 13.034358146229168],
						[77.60462962545641, 13.034068351392806],
						[77.60465191916408, 13.033988071767043],
						[77.60496848933738, 13.033974715692217],
						[77.60549907678968, 13.034001457848328],
						[77.60598962098386, 13.03399693226091],
						[77.60629281137372, 13.03401924979905],
						[77.60632848077773, 13.033961270275404],
						[77.60636860884378, 13.033595680263385],
						[77.60647561695008, 13.03289555920835],
						[77.60648453428769, 13.032815324075612],
						[77.60629281137372, 13.032797440643009],
						[77.60565967757442, 13.032788527449634],
						[77.605071040048, 13.0328286935897],
						[77.60478568131755, 13.032833118797964],
						[77.60479014005327, 13.0321776614042],
						[77.6046340841983, 13.032213349047158],
						[77.60405435884805, 13.03226239840461],
						[77.60356835244643, 13.032356002078517],
						[77.60311792694256, 13.032418453064746],
						[77.60250706944997, 13.03252103640563],
						[77.60239113991729, 13.032534417643246],
						[77.60194962740859, 13.032605751165889],
						[77.60161075445745, 13.032610176392483],
						[77.60127188048837, 13.032659203651995],
						[77.60093300550137, 13.03270383043847],
						[77.60062534180264, 13.032717180942472],
						[77.600277547046, 13.03275732106868],
						[77.60014815103585, 13.032739510428128],
						[77.60015260995489, 13.032668120339574],
						[77.60015706887374, 13.032628046877072],
						[77.60015706887374, 13.032578958349223],
						[77.60015706887374, 13.032525469994019],
						[77.60014815103585, 13.032463010033544],
						[77.60015260995489, 13.032413964408164],
						[77.60015260995489, 13.032373848195814],
						[77.60015706887374, 13.032324802605318],
						[77.60016152779244, 13.032266871085895],
						[77.60018382238319, 13.032199966153918],
						[77.60018382238319, 13.032141991860117],
						[77.60018836873057, 13.032021604306593],
						[77.60022849898124, 13.031691657877458],
						[77.60025079355877, 13.031312625537211],
						[77.60022849898124, 13.031161006550262],
						[77.60022849898124, 13.031111918618612],
						[77.60022849898124, 13.031076288902385],
						[77.6002151222326, 13.031000415004268],
						[77.6002062043993, 13.030924670241442],
						[77.60018382238319, 13.03085327893348],
						[77.60011247967724, 13.030764080666431],
						[77.59992520485946, 13.030545658317596],
						[77.59976468333967, 13.030371749756268],
						[77.5996532099277, 13.030090820446508],
						[77.59959524370994, 13.029841117547384],
						[77.59956849006093, 13.029524511172603],
						[77.59951052379967, 13.029243580237408],
						[77.5994079680337, 13.028895795149595],
						[77.59937229644108, 13.028686165131145],
						[77.59937229644108, 13.028512247914652],
						[77.59949268802099, 13.028275906581586],
						[77.59962645629243, 13.02803960756011],
						[77.60109798424061, 13.027838916739512],
						[77.60138335230108, 13.027794377455779],
						[77.60182032074471, 13.027731966606364],
						[77.60198092676809, 13.027714098768977],
						[77.60318480894882, 13.027566984378582],
						[77.60421487426372, 13.027370754311006],
						[77.60425946183865, 13.027406378801913],
						[77.60426837935151, 13.02741534985553],
						[77.6043575544414, 13.027410854755598],
						[77.60436201319406, 13.027406411450224],
						[77.60509779239196, 13.027250321472406],
						[77.60592274064105, 13.026982845336027],
						[77.60639090220764, 13.026804431610055],
						[77.60677443480058, 13.027714103023884],
						[77.60706870578923, 13.028333920062401],
						[77.60725596874572, 13.028748601212685],
						[77.60747007059635, 13.029216773391694],
						[77.6074745292259, 13.029225701947919],
						[77.60761720527877, 13.029493299212596],
						[77.60779109147406, 13.029689504099103],
						[77.60783121901949, 13.029738544970533],
						[77.60794268434853, 13.029876782748529],
						[77.60800510488465, 13.030001610931507],
						[77.60807198399213, 13.030162112195452],
						[77.60808090120346, 13.030220085921856],
						[77.60817007327799, 13.03065264432882],
						[77.60843321791023, 13.03169604469693],
						[77.60882111470802, 13.03301154642003],
						[77.60909763351695, 13.033965717868275],
						[77.60914667772427, 13.034139686259863],
						[77.60920463903268, 13.034353685718447],
						[77.60927597598675, 13.034688167893325],
						[77.60930718588995, 13.034830821289864],
						[77.60964157716758, 13.036280011340306],
						[77.6098422988802, 13.03698009051429],
						[77.60990917689757, 13.037207554269822],
						[77.60998943046602, 13.037555331491323],
						[77.61014102038381, 13.03792544896779],
						[77.61022573230788, 13.038130567050414],
						[77.61025694194808, 13.038197472820821],
						[77.61023019082845, 13.038264341097538],
						[77.61013210333546, 13.038505153536684],
						[77.6100295572288, 13.03871917718444],
						[77.60999388899592, 13.038794933176238],
						[77.60994484515726, 13.038888593273958],
						[77.60985121595148, 13.039035750377614],
						[77.60972637688944, 13.039236446442924],
						[77.60947661092796, 13.039539649085404],
						[77.60947215237758, 13.039544092854472],
						[77.60903075501803, 13.039989979036449],
						[77.6085892685066, 13.040315500765466],
						[77.60856251699114, 13.040333362810355],
						[77.60850901394116, 13.040360113831294],
						[77.60821474671233, 13.040516217224909],
						[77.60806752538619, 13.04059202125638],
						[77.60779109147406, 13.040694542999773],
						[77.60767516737366, 13.040734671938115],
						[77.6070999163036, 13.040877374291892],
						[77.60706870578923, 13.040886306522303],
						[77.60696169824587, 13.040895201188734],
						[77.60673876553766, 13.04091747751977],
						[77.60651583238861, 13.040939753596327],
						[77.60520480170432, 13.04097995435086],
						[77.60520480170432, 13.040984354579308],
						[77.60424608556802, 13.041011113932058],
						[77.60241343406746, 13.041064662483498],
						[77.6010756898305, 13.041104756728071],
						[77.59940350908523, 13.041149384953771],
						[77.59798555453811, 13.041193953173297],
						[77.59742817758973, 13.041207299706661],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000037",
			properties: {
				AREA_SQ_KM: 1.48809516590334,
				ASS_CONST1: "Hebbal",
				ASS_CONST_: "158",
				LAT: 13.0351744765503,
				LON: 77.603740582364,
				LOST_AREA_SQ_KM: 0.016054833663282208,
				OBJECTID: 56,
				POP_F: 12103.0,
				POP_M: 13594.0,
				POP_SC: 2422.0,
				POP_ST: 402.0,
				POP_TOTAL: 25697.0,
				RESERVATIO: "Backward Category - B (Woman)",
				WARD_NAME: "Vishwanath Nagenahalli",
				WARD_NO: 22.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59290663406857, 13.024940566812466],
						[77.5929333892965, 13.02491382340505],
						[77.59295568531496, 13.02488703712155],
						[77.59295568531496, 13.02483355340603],
						[77.59280853151186, 13.023906011855228],
						[77.59276839862319, 13.023522531320754],
						[77.59268813280306, 13.022991933596002],
						[77.59260786692589, 13.022545961744282],
						[77.59256327474725, 13.021805750332293],
						[77.5925543563094, 13.021524883057568],
						[77.59247409033713, 13.020584037866222],
						[77.5923849058566, 13.019736793465784],
						[77.59231355822148, 13.019264093772362],
						[77.59228680284669, 13.018978748591238],
						[77.59222883284622, 13.018804810468252],
						[77.59214410740738, 13.018519427626513],
						[77.59185425674126, 13.01755184061025],
						[77.59179628651877, 13.017302083920791],
						[77.59174277551776, 13.017119306890619],
						[77.59155548681456, 13.016512842176654],
						[77.59143508676992, 13.016102567912865],
						[77.59139941265798, 13.01589305194236],
						[77.59140833118701, 13.015736925956755],
						[77.59140833118701, 13.015661106069674],
						[77.59143508676993, 13.015603139298314],
						[77.59146630160863, 13.015571954954734],
						[77.59150643496001, 13.015549654360145],
						[77.59158224236259, 13.015531836267977],
						[77.59165359045973, 13.015527345195512],
						[77.59177844952126, 13.015527335140757],
						[77.59187655297276, 13.015505072891719],
						[77.59195236012692, 13.015482769284759],
						[77.59218869975153, 13.015322268741901],
						[77.59249192722478, 13.015152834654387],
						[77.59257665240268, 13.015103790384606],
						[77.5926167853595, 13.015077004185086],
						[77.59267029594639, 13.01502352033367],
						[77.59269705123033, 13.01497892324753],
						[77.5927059696569, 13.015023517247048],
						[77.59269705123033, 13.01511270761307],
						[77.59268813280306, 13.01519741267946],
						[77.59270151044372, 13.015268789181176],
						[77.59271934729544, 13.015299926434986],
						[77.59275502099047, 13.015389113188059],
						[77.59280407230271, 13.015554162096784],
						[77.59284866438627, 13.015701313249611],
						[77.59285758280086, 13.015759277452329],
						[77.59285758280086, 13.015817242443438],
						[77.59285758280086, 13.015852866774969],
						[77.59294230770442, 13.015852902704351],
						[77.59322323719296, 13.015808326570381],
						[77.59351754352659, 13.015803815490797],
						[77.5938163082625, 13.015799346736273],
						[77.59411507220791, 13.015866299012659],
						[77.5943468607237, 13.01592872439955],
						[77.59441820707413, 13.015928717867885],
						[77.59447617595066, 13.01591081401954],
						[77.59456089963973, 13.015897479351079],
						[77.59460995016752, 13.01589303252502],
						[77.59464562326524, 13.01590191378669],
						[77.59467237808114, 13.015924252121735],
						[77.5946812963517, 13.015942106701461],
						[77.5946991328907, 13.015973244194319],
						[77.59471696942688, 13.01604017880282],
						[77.59473926509314, 13.016115954502933],
						[77.59476156075502, 13.016222955739554],
						[77.59477047901852, 13.016267550436869],
						[77.59479277467422, 13.016316672480878],
						[77.59480615206553, 13.016419189308163],
						[77.59480615206553, 13.016526236028598],
						[77.59481507032551, 13.016633195772362],
						[77.59505140395856, 13.016664442798449],
						[77.59500235362259, 13.01739570710238],
						[77.59475264249079, 13.017551772222548],
						[77.59468575548672, 13.017574076122244],
						[77.59465008240167, 13.017591934911678],
						[77.59461886844299, 13.017605350954323],
						[77.59459657275296, 13.017623165432399],
						[77.59457427705851, 13.017640979908458],
						[77.59456089963973, 13.017654394384866],
						[77.59455198135967, 13.017672164564553],
						[77.59454752221939, 13.017685578265565],
						[77.5945430630789, 13.017707876671873],
						[77.59453860393825, 13.017730175081676],
						[77.5945430630789, 13.017801554273506],
						[77.59459211361442, 13.017962035647605],
						[77.59468575548672, 13.01835886499252],
						[77.59473926509314, 13.01850601970662],
						[77.59395454242505, 13.018733470268582],
						[77.59408385810133, 13.019215092845151],
						[77.59422646365512, 13.01971003966731],
						[77.59436915646306, 13.020196021966884],
						[77.59437807475757, 13.020227247765646],
						[77.59435577901998, 13.020231691958024],
						[77.59358889040104, 13.020423426688053],
						[77.593776175732, 13.021029915653422],
						[77.5938163082625, 13.021186002988978],
						[77.59398575656738, 13.021787925907725],
						[77.59403926650556, 13.021970797858769],
						[77.59419524957936, 13.022586135976972],
						[77.59427997347922, 13.022875930054994],
						[77.59436915646306, 13.023219337236059],
						[77.59449847166448, 13.023696408198273],
						[77.59474372422588, 13.023633979515024],
						[77.59516288191564, 13.023526982870887],
						[77.59568905638709, 13.023384350354988],
						[77.59611266964562, 13.023268336586973],
						[77.5962330647027, 13.023625071573905],
						[77.59646939536722, 13.023558241821506],
						[77.59681720186036, 13.023460132171879],
						[77.59701794639727, 13.024155712929375],
						[77.5970402416091, 13.02423158092945],
						[77.59709375009949, 13.024677479720534],
						[77.59711158625731, 13.024971728669705],
						[77.59715617663954, 13.02497621104292],
						[77.59800339055501, 13.024909288634035],
						[77.59803014457506, 13.02490488693229],
						[77.59803460357779, 13.024922700382094],
						[77.59807027559326, 13.02524377791043],
						[77.59809702959745, 13.025457801122505],
						[77.59815945558259, 13.02547116735119],
						[77.59848050296073, 13.02548899852356],
						[77.5987435827703, 13.025497905511768],
						[77.59915380769021, 13.025560327997658],
						[77.59956403111882, 13.025587121903843],
						[77.60003221907911, 13.025569267128432],
						[77.60039793766, 13.025564839207902],
						[77.60040685547816, 13.025845722543314],
						[77.60046036237226, 13.025934917617224],
						[77.60046036237226, 13.025939403491321],
						[77.60046482127895, 13.025939359960926],
						[77.60063871850265, 13.026215873142748],
						[77.60064763630177, 13.026340700852515],
						[77.60060750620018, 13.026478947582119],
						[77.60060304729912, 13.02648787663451],
						[77.60060750620018, 13.026505690430252],
						[77.60054062266582, 13.026527996388936],
						[77.60051386924097, 13.02653688425839],
						[77.60040239656917, 13.026572565483633],
						[77.60028200595991, 13.026612733304132],
						[77.60011247967724, 13.026719715003559],
						[77.60000546553371, 13.0267955530245],
						[77.59994749949692, 13.026942686875717],
						[77.59987615664153, 13.027147750587714],
						[77.59976914227387, 13.027598162927358],
						[77.5996532099277, 13.027963775790463],
						[77.59962645629243, 13.02803960756011],
						[77.59949268802099, 13.028275906581586],
						[77.59937229644108, 13.028512247914652],
						[77.59937229644108, 13.028686165131145],
						[77.5994079680337, 13.028895795149595],
						[77.59951052379967, 13.029243580237408],
						[77.59956849006093, 13.029524511172603],
						[77.59959524370994, 13.029841117547384],
						[77.5996532099277, 13.030090820446508],
						[77.59976468333967, 13.030371749756268],
						[77.59992520485946, 13.030545658317596],
						[77.60011247967724, 13.030764080666431],
						[77.60018382238319, 13.03085327893348],
						[77.6002062043993, 13.030924670241442],
						[77.6002151222326, 13.031000415004268],
						[77.60022849898124, 13.031076288902385],
						[77.60022849898124, 13.031111918618612],
						[77.60022849898124, 13.031161006550262],
						[77.60025079355877, 13.031312625537211],
						[77.60022849898124, 13.031691657877458],
						[77.60018836873057, 13.032021604306593],
						[77.60018382238319, 13.032141991860117],
						[77.60018382238319, 13.032199966153918],
						[77.60016152779244, 13.032266871085895],
						[77.60015706887374, 13.032324802605318],
						[77.60015260995489, 13.032373848195814],
						[77.60015260995489, 13.032413964408164],
						[77.60014815103585, 13.032463010033544],
						[77.60015706887374, 13.032525469994019],
						[77.60015706887374, 13.032578958349223],
						[77.60015706887374, 13.032628046877072],
						[77.60015260995489, 13.032668120339574],
						[77.60014815103585, 13.032739510428128],
						[77.600277547046, 13.03275732106868],
						[77.60062534180264, 13.032717180942472],
						[77.60093300550137, 13.03270383043847],
						[77.60127188048837, 13.032659203651995],
						[77.60161075445745, 13.032610176392483],
						[77.60194962740859, 13.032605751165889],
						[77.60239113991729, 13.032534417643246],
						[77.60250706944997, 13.03252103640563],
						[77.60311792694256, 13.032418453064746],
						[77.60356835244643, 13.032356002078517],
						[77.60405435884805, 13.03226239840461],
						[77.6046340841983, 13.032213349047158],
						[77.60479014005327, 13.0321776614042],
						[77.60478568131755, 13.032833118797964],
						[77.605071040048, 13.0328286935897],
						[77.60565967757442, 13.032788527449634],
						[77.60629281137372, 13.032797440643009],
						[77.60648453428769, 13.032815324075612],
						[77.60647561695008, 13.03289555920835],
						[77.60636860884378, 13.033595680263385],
						[77.60632848077773, 13.033961270275404],
						[77.60629281137372, 13.03401924979905],
						[77.60598962098386, 13.03399693226091],
						[77.60549907678968, 13.034001457848328],
						[77.60496848933738, 13.033974715692217],
						[77.60465191916408, 13.033988071767043],
						[77.60462962545641, 13.034068351392806],
						[77.60462070797212, 13.034358146229168],
						[77.60464300168154, 13.034532029821607],
						[77.60465637790509, 13.03468814191688],
						[77.60463854294001, 13.03482186856473],
						[77.60415245162922, 13.034884322704897],
						[77.60351484702653, 13.034969050092338],
						[77.60296186877366, 13.035018090635766],
						[77.60240897523776, 13.03510285127684],
						[77.60220832771985, 13.035120680823619],
						[77.60214144513456, 13.035067152478154],
						[77.60207902135252, 13.035000294544378],
						[77.60204780944855, 13.034920018934836],
						[77.60197646792132, 13.03471935130049],
						[77.60196746279885, 13.034612282217646],
						[77.60196300395155, 13.03450086033991],
						[77.60196300395155, 13.034460743201437],
						[77.60195854510407, 13.034433955625863],
						[77.60195408625641, 13.034389395746095],
						[77.60193625086401, 13.034349279899955],
						[77.60188274466992, 13.03429130800884],
						[77.60180248533116, 13.03422885181962],
						[77.60163750762219, 13.034117355313931],
						[77.60154387151786, 13.034050457083435],
						[77.60149036513765, 13.034010343858872],
						[77.60143239986378, 13.033961301710404],
						[77.60139227004133, 13.033925630645829],
						[77.60136105794734, 13.033898845079861],
						[77.6012897159858, 13.03382750239078],
						[77.60124066836107, 13.033791831985104],
						[77.60111581976554, 13.033733865295972],
						[77.60104447764891, 13.033720497902854],
						[77.6010132654587, 13.033720543161133],
						[77.60098651214594, 13.0337205449764],
						[77.6008973343909, 13.033702692579977],
						[77.60085274548693, 13.03366697874805],
						[77.60081261545831, 13.033635793933717],
						[77.60072789646212, 13.033542150876547],
						[77.60067438969489, 13.03349755164233],
						[77.60060750620018, 13.033439624249331],
						[77.60054062266582, 13.033408355014057],
						[77.60049603362089, 13.033394985784849],
						[77.60047373909183, 13.033381658207107],
						[77.60043806783612, 13.033354829918494],
						[77.60037564311156, 13.03331920364781],
						[77.60024633464361, 13.033279052504172],
						[77.6002151222326, 13.033279097689592],
						[77.60015260995489, 13.033274611404144],
						[77.59980927267362, 13.03324784611272],
						[77.59949268802097, 13.033261195090848],
						[77.59919393830882, 13.033265656502218],
						[77.59889964677427, 13.03327016036584],
						[77.59869453403876, 13.033189853581533],
						[77.59846266701925, 13.033118520549575],
						[77.59823079952334, 13.033015957173061],
						[77.59809702959745, 13.032908988382417],
						[77.5979766365286, 13.032779631572842],
						[77.59782948927001, 13.032636904442079],
						[77.5976823418196, 13.032592353778384],
						[77.59747722687165, 13.032521019189815],
						[77.5973434560526, 13.032525426357324],
						[77.59700456926807, 13.032525444624143],
						[77.59661654439896, 13.032529903598153],
						[77.5964961497509, 13.03253435267848],
						[77.59636237776913, 13.032534359427656],
						[77.59621522840605, 13.03244072017281],
						[77.59609483332996, 13.032333707754072],
						[77.5957648609817, 13.03225349255963],
						[77.59566676090839, 13.032115206308337],
						[77.59561771083976, 13.031910101843797],
						[77.59556420164968, 13.031700555111946],
						[77.59553744704512, 13.031415176565043],
						[77.59555974254936, 13.031022779245822],
						[77.59556866074982, 13.030795373330806],
						[77.59556420164968, 13.030447494092803],
						[77.59647385443161, 13.030411815030963],
						[77.59648723162371, 13.030059621507437],
						[77.5961171287241, 13.030077457230346],
						[77.59600119262645, 13.030090835390276],
						[77.59600565170933, 13.029925846439259],
						[77.59600119262645, 13.029363937879761],
						[77.59588525640973, 13.02889128259868],
						[77.59584512461464, 13.028490011214204],
						[77.59579607462352, 13.028356170292598],
						[77.59544826498416, 13.028414162578626],
						[77.59522976863705, 13.027241421058788],
						[77.59509599515461, 13.026550265191052],
						[77.59504248571724, 13.026242558698062],
						[77.59496222151364, 13.025966169130466],
						[77.5948596616149, 13.025359688564903],
						[77.59477493815001, 13.024722068781129],
						[77.59415520461796, 13.024806781819697],
						[77.59361564546734, 13.024909340451215],
						[77.59317864525731, 13.024944993644455],
						[77.59290663406857, 13.024940566812466],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000038",
			properties: {
				AREA_SQ_KM: 0.8127598553163308,
				ASS_CONST1: "Hebbal",
				ASS_CONST_: "158",
				LAT: 13.0267426995105,
				LON: 77.5973052921654,
				LOST_AREA_SQ_KM: 0.010034786051372448,
				OBJECTID: 57,
				POP_F: 16577.0,
				POP_M: 18200.0,
				POP_SC: 838.0,
				POP_ST: 169.0,
				POP_TOTAL: 34777.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Manorayanapalya",
				WARD_NO: 33.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59064588184715, 13.009944597475105],
						[77.59162237567278, 13.010617921639156],
						[77.59296014451813, 13.01156770606603],
						[77.59342835987013, 13.011781700285344],
						[77.5935398394297, 13.01180847121661],
						[77.59358443122271, 13.011830806717622],
						[77.59363794135108, 13.011848656075728],
						[77.59369591062818, 13.011857577609533],
						[77.59399467489216, 13.01185310517016],
						[77.59417750039515, 13.01185304328782],
						[77.59393224660383, 13.012209775849696],
						[77.5938296857695, 13.012352495271148],
						[77.59380293075391, 13.0123970918745],
						[77.59365131887921, 13.012651214915731],
						[77.5935933495792, 13.012776075261572],
						[77.59355767614902, 13.012932201144352],
						[77.59355767614902, 13.01302578843179],
						[77.5935799720442, 13.013284423989377],
						[77.59363794135108, 13.013547542056848],
						[77.5936424005273, 13.013632288182755],
						[77.5936424005273, 13.013721476985154],
						[77.59357551286551, 13.013971151894065],
						[77.59351308434545, 13.014087086231967],
						[77.593406063945, 13.014243133982967],
						[77.59334809451909, 13.014327886292879],
						[77.59323661477022, 13.014430412334198],
						[77.59318310445168, 13.014466127394948],
						[77.5931028389263, 13.014515128361843],
						[77.59302703254441, 13.014533033403549],
						[77.59286650121476, 13.014590925859302],
						[77.59280853151186, 13.014635568760104],
						[77.5927906946742, 13.01464445476064],
						[77.59275502099047, 13.014689052555894],
						[77.59273718414437, 13.014742533223059],
						[77.5927059696569, 13.014907588123712],
						[77.59269705123033, 13.01497892324753],
						[77.59267029594639, 13.01502352033367],
						[77.5926167853595, 13.015077004185086],
						[77.59257665240268, 13.015103790384606],
						[77.59249192722478, 13.015152834654387],
						[77.59218869975153, 13.015322268741901],
						[77.59195236012692, 13.015482769284759],
						[77.59187655297276, 13.015505072891719],
						[77.59177844952126, 13.015527335140757],
						[77.59165359045973, 13.015527345195512],
						[77.59158224236259, 13.015531836267977],
						[77.59150643496001, 13.015549654360145],
						[77.59146630160863, 13.015571954954734],
						[77.59143508676993, 13.015603139298314],
						[77.59140833118701, 13.015661106069674],
						[77.59140833118701, 13.015736925956755],
						[77.59139941265798, 13.01589305194236],
						[77.59143508676992, 13.016102567912865],
						[77.59155548681456, 13.016512842176654],
						[77.59174277551776, 13.017119306890619],
						[77.59179628651877, 13.017302083920791],
						[77.59185425674126, 13.01755184061025],
						[77.59214410740738, 13.018519427626513],
						[77.59222883284622, 13.018804810468252],
						[77.59228680284669, 13.018978748591238],
						[77.59231355822148, 13.019264093772362],
						[77.5923849058566, 13.019736793465784],
						[77.59247409033713, 13.020584037866222],
						[77.5925543563094, 13.021524883057568],
						[77.59256327474725, 13.021805750332293],
						[77.59260786692589, 13.022545961744282],
						[77.59268813280306, 13.022991933596002],
						[77.59276839862319, 13.023522531320754],
						[77.59280853151186, 13.023906011855228],
						[77.59295568531496, 13.02483355340603],
						[77.59295568531496, 13.02488703712155],
						[77.5929333892965, 13.02491382340505],
						[77.59290663406857, 13.024940566812466],
						[77.59284866438627, 13.024967268868135],
						[77.59271488808278, 13.024985133118477],
						[77.59242057965727, 13.025025262272962],
						[77.5921797812841, 13.02505201718773],
						[77.59204154494903, 13.025051981433018],
						[77.59198803406422, 13.024855820221484],
						[77.59193898239752, 13.024561534290493],
						[77.59189438995479, 13.024186980905192],
						[77.59190776768946, 13.023834638274911],
						[77.59185425674127, 13.02382575618142],
						[77.59137265706661, 13.023749957524352],
						[77.591448464559, 13.023638458736132],
						[77.59116307138174, 13.023696442685925],
						[77.59107834514342, 13.023709861025022],
						[77.59047196922987, 13.023781149273193],
						[77.5903426494178, 13.023433388556453],
						[77.5902713004926, 13.022987416797584],
						[77.59030697496082, 13.022804625932624],
						[77.59036940525314, 13.022590607144728],
						[77.58986104329814, 13.022523694444486],
						[77.58942848790348, 13.022501417379035],
						[77.58942402856088, 13.022581683869362],
						[77.58951767471832, 13.022983011596665],
						[77.58956672743648, 13.023241579281555],
						[77.58920552055831, 13.02329063592507],
						[77.5889915589314, 13.023321876561324],
						[77.58899601829106, 13.023468996122984],
						[77.58892912787766, 13.023527010248872],
						[77.58875967198648, 13.023616212463153],
						[77.58839400314484, 13.023803502491676],
						[77.58804171131226, 13.023946150632128],
						[77.58789009170445, 13.023611718802755],
						[77.58772072169437, 13.023139102141831],
						[77.5875557234074, 13.022768918352215],
						[77.58705180822868, 13.022956253498515],
						[77.58694924114715, 13.022697603607444],
						[77.58689572785028, 13.022599526976924],
						[77.58652122150295, 13.022697623177079],
						[77.58643649178362, 13.022688698124623],
						[77.58607527489906, 13.022755605455238],
						[77.58537076311977, 13.022974043410606],
						[77.58527265401924, 13.023023128537798],
						[77.58529495154956, 13.023125648806818],
						[77.58520576140187, 13.02313462239862],
						[77.58510765215811, 13.023170250764887],
						[77.58508535459084, 13.023179179353345],
						[77.58470629527422, 13.023286195589424],
						[77.58465278091535, 13.02309004016358],
						[77.58459034746465, 13.022898284621826],
						[77.58453246095716, 13.022715461496121],
						[77.58409096605997, 13.0211681044359],
						[77.5839705580615, 13.020704423407754],
						[77.58394826026992, 13.020525998137405],
						[77.58392150291424, 13.020383327404558],
						[77.58390812423401, 13.02018264775203],
						[77.58389920511298, 13.019825970592262],
						[77.58389920511298, 13.019598508423227],
						[77.58389920511298, 13.019121417214988],
						[77.58392596247396, 13.018033369080042],
						[77.58394826026992, 13.017083556149618],
						[77.5839705580615, 13.016642140804052],
						[77.58399731540557, 13.015442673678862],
						[77.58399285584868, 13.014524106437221],
						[77.58465724044622, 13.014510656667527],
						[77.58598162588869, 13.014417037401197],
						[77.58657018808165, 13.014332296323952],
						[77.58684221452806, 13.014256504877714],
						[77.58726586096851, 13.0140826316799],
						[77.58766720876284, 13.013868607403422],
						[77.58788117289765, 13.0137303224969],
						[77.58814427740218, 13.013498495088326],
						[77.5883494093027, 13.013235404405366],
						[77.5890673680217, 13.012316786986222],
						[77.58977631608323, 13.011259988948066],
						[77.59018211427271, 13.01065355107892],
						[77.59061466678172, 13.009989149829785],
						[77.59064588184715, 13.009944597475105],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000039",
			properties: {
				AREA_SQ_KM: 1.096631170928184,
				ASS_CONST1: "Hebbal",
				ASS_CONST_: "158",
				LAT: 13.017774892654,
				LON: 77.5888153931365,
				LOST_AREA_SQ_KM: 0.01285748367459166,
				OBJECTID: 58,
				POP_F: 11826.0,
				POP_M: 13128.0,
				POP_SC: 2373.0,
				POP_ST: 185.0,
				POP_TOTAL: 24954.0,
				RESERVATIO: "General",
				WARD_NAME: "Gangenahalli",
				WARD_NO: 34.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59321431880724, 13.005690578315614],
						[77.59326336991998, 13.005668276887025],
						[77.59344619660705, 13.006011661001079],
						[77.593557676149, 13.00617660205265],
						[77.59376279821866, 13.006595756820365],
						[77.5942353819609, 13.007625884245698],
						[77.59474372422588, 13.009213302855185],
						[77.59495776238956, 13.009101839363082],
						[77.59530111442955, 13.009975763363467],
						[77.59531895087063, 13.010047179531197],
						[77.59532786909011, 13.010145249233084],
						[77.59545718319343, 13.010466315463246],
						[77.59575148370399, 13.011300105324151],
						[77.59564892452227, 13.011349152692286],
						[77.59549731512641, 13.011442842354997],
						[77.59548839692032, 13.011465097118199],
						[77.59544826498416, 13.011523108010104],
						[77.59572472914384, 13.012392618045146],
						[77.59577823825782, 13.012553134238333],
						[77.59580945189593, 13.012655732230268],
						[77.59589417458447, 13.01275383923404],
						[77.59641142751418, 13.013574254017025],
						[77.59701794639727, 13.014546397463155],
						[77.59753519417734, 13.015447134172991],
						[77.59757532537154, 13.01553627796293],
						[77.59760207949306, 13.015594240806456],
						[77.59762883360824, 13.015643319051081],
						[77.5976555877171, 13.015728022134669],
						[77.59766450575198, 13.015781501550366],
						[77.59766896476916, 13.015879620367494],
						[77.59766004673463, 13.015950957330572],
						[77.5976555877171, 13.016093673083871],
						[77.59763329262682, 13.016147199108898],
						[77.59763329262682, 13.016182824057205],
						[77.59764221066347, 13.016223019801256],
						[77.59766450575198, 13.01627654117016],
						[77.59774476803413, 13.016379094756019],
						[77.59784732531158, 13.016602020926783],
						[77.59817729157201, 13.01729767012668],
						[77.59844929006128, 13.017908493169678],
						[77.59859197753119, 13.018193914513752],
						[77.59862764937047, 13.018251878001404],
						[77.5986677801762, 13.01830539863004],
						[77.59871682891936, 13.018358918360729],
						[77.59876141866738, 13.018390140147464],
						[77.59900666196656, 13.018626426531155],
						[77.59922960995783, 13.018885100621223],
						[77.59948377013058, 13.0192908913835],
						[77.59972901185982, 13.019763537927508],
						[77.59976914227387, 13.019857171317703],
						[77.59980481374103, 13.019946362754682],
						[77.59983156733401, 13.020075667200095],
						[77.59983602626555, 13.020120221264234],
						[77.59983602626555, 13.020178189619664],
						[77.59991182807487, 13.02034315884598],
						[77.60032213617728, 13.021248364471687],
						[77.60038456093146, 13.02141786571344],
						[77.60080369767223, 13.02258609622424],
						[77.60095529993968, 13.023000841337739],
						[77.60090625216957, 13.023032030973827],
						[77.59990736914631, 13.023527025010276],
						[77.59999208875864, 13.023718741014793],
						[77.60030875943974, 13.024427773933828],
						[77.60037118420132, 13.024561480016168],
						[77.60046036237226, 13.024909295972964],
						[77.60046482127895, 13.025212564294154],
						[77.60043360892838, 13.025368579801837],
						[77.60043360892838, 13.025373065654149],
						[77.60042023220407, 13.025386481290143],
						[77.60039793766, 13.025515753566843],
						[77.60039793766, 13.025564839207902],
						[77.60003221907911, 13.025569267128432],
						[77.59956403111882, 13.025587121903843],
						[77.59915380769021, 13.025560327997658],
						[77.5987435827703, 13.025497905511768],
						[77.59848050296073, 13.02548899852356],
						[77.59815945558259, 13.02547116735119],
						[77.59809702959745, 13.025457801122505],
						[77.59807027559326, 13.02524377791043],
						[77.59803460357779, 13.024922700382094],
						[77.59803014457506, 13.02490488693229],
						[77.59800339055501, 13.024909288634035],
						[77.59715617663954, 13.02497621104292],
						[77.59711158625731, 13.024971728669705],
						[77.59709375009949, 13.024677479720534],
						[77.5970402416091, 13.02423158092945],
						[77.59701794639727, 13.024155712929375],
						[77.59681720186036, 13.023460132171879],
						[77.59646939536722, 13.023558241821506],
						[77.5962330647027, 13.023625071573905],
						[77.59611266964562, 13.023268336586973],
						[77.59568905638709, 13.023384350354988],
						[77.59516288191564, 13.023526982870887],
						[77.59474372422588, 13.023633979515024],
						[77.59449847166448, 13.023696408198273],
						[77.59436915646306, 13.023219337236059],
						[77.59427997347922, 13.022875930054994],
						[77.59419524957936, 13.022586135976972],
						[77.59403926650556, 13.021970797858769],
						[77.59398575656738, 13.021787925907725],
						[77.5938163082625, 13.021186002988978],
						[77.593776175732, 13.021029915653422],
						[77.59358889040104, 13.020423426688053],
						[77.59435577901998, 13.020231691958024],
						[77.59437807475757, 13.020227247765646],
						[77.59436915646306, 13.020196021966884],
						[77.59422646365512, 13.01971003966731],
						[77.59408385810133, 13.019215092845151],
						[77.59395454242505, 13.018733470268582],
						[77.59473926509314, 13.01850601970662],
						[77.59468575548672, 13.01835886499252],
						[77.59459211361442, 13.017962035647605],
						[77.5945430630789, 13.017801554273506],
						[77.59453860393825, 13.017730175081676],
						[77.5945430630789, 13.017707876671873],
						[77.59454752221939, 13.017685578265565],
						[77.59455198135967, 13.017672164564553],
						[77.59456089963973, 13.017654394384866],
						[77.59457427705851, 13.017640979908458],
						[77.59459657275296, 13.017623165432399],
						[77.59461886844299, 13.017605350954323],
						[77.59465008240167, 13.017591934911678],
						[77.59468575548672, 13.017574076122244],
						[77.59475264249079, 13.017551772222548],
						[77.59500235362259, 13.01739570710238],
						[77.59505140395856, 13.016664442798449],
						[77.59481507032551, 13.016633195772362],
						[77.59480615206553, 13.016526236028598],
						[77.59480615206553, 13.016419189308163],
						[77.59479277467422, 13.016316672480878],
						[77.59477047901852, 13.016267550436869],
						[77.59476156075502, 13.016222955739554],
						[77.59473926509314, 13.016115954502933],
						[77.59471696942688, 13.01604017880282],
						[77.5946991328907, 13.015973244194319],
						[77.5946812963517, 13.015942106701461],
						[77.59467237808114, 13.015924252121735],
						[77.59464562326524, 13.01590191378669],
						[77.59460995016752, 13.01589303252502],
						[77.59456089963973, 13.015897479351079],
						[77.59447617595066, 13.01591081401954],
						[77.59441820707413, 13.015928717867885],
						[77.5943468607237, 13.01592872439955],
						[77.59411507220791, 13.015866299012659],
						[77.5938163082625, 13.015799346736273],
						[77.59351754352659, 13.015803815490797],
						[77.59322323719296, 13.015808326570381],
						[77.59294230770442, 13.015852902704351],
						[77.59285758280086, 13.015852866774969],
						[77.59285758280086, 13.015817242443438],
						[77.59285758280086, 13.015759277452329],
						[77.59284866438627, 13.015701313249611],
						[77.59280407230271, 13.015554162096784],
						[77.59275502099047, 13.015389113188059],
						[77.59271934729544, 13.015299926434986],
						[77.59270151044372, 13.015268789181176],
						[77.59268813280306, 13.01519741267946],
						[77.59269705123033, 13.01511270761307],
						[77.5927059696569, 13.015023517247048],
						[77.59269705123033, 13.01497892324753],
						[77.5927059696569, 13.014907588123712],
						[77.59273718414437, 13.014742533223059],
						[77.59275502099047, 13.014689052555894],
						[77.5927906946742, 13.01464445476064],
						[77.59280853151186, 13.014635568760104],
						[77.59286650121476, 13.014590925859302],
						[77.59302703254441, 13.014533033403549],
						[77.5931028389263, 13.014515128361843],
						[77.59318310445168, 13.014466127394948],
						[77.59323661477022, 13.014430412334198],
						[77.59334809451909, 13.014327886292879],
						[77.593406063945, 13.014243133982967],
						[77.59351308434545, 13.014087086231967],
						[77.59357551286551, 13.013971151894065],
						[77.5936424005273, 13.013721476985154],
						[77.5936424005273, 13.013632288182755],
						[77.59363794135108, 13.013547542056848],
						[77.5935799720442, 13.013284423989377],
						[77.59355767614902, 13.01302578843179],
						[77.59355767614902, 13.012932201144352],
						[77.5935933495792, 13.012776075261572],
						[77.59365131887921, 13.012651214915731],
						[77.59380293075391, 13.0123970918745],
						[77.5938296857695, 13.012352495271148],
						[77.59393224660383, 13.012209775849696],
						[77.59417750039515, 13.01185304328782],
						[77.59399467489216, 13.01185310517016],
						[77.59369591062818, 13.011857577609533],
						[77.59363794135108, 13.011848656075728],
						[77.59358443122271, 13.011830806717622],
						[77.5935398394297, 13.01180847121661],
						[77.59342835987013, 13.011781700285344],
						[77.59296014451813, 13.01156770606603],
						[77.59162237567278, 13.010617921639156],
						[77.59064588184715, 13.009944597475105],
						[77.59113185645923, 13.009217741419638],
						[77.59184979749443, 13.008120847321463],
						[77.59231355822148, 13.007367220069236],
						[77.59248300878133, 13.007108620894758],
						[77.59273718414437, 13.006711709239056],
						[77.59303149174475, 13.006181061978262],
						[77.59308500213514, 13.006042882621218],
						[77.59320094122734, 13.00573961310986],
						[77.59321431880724, 13.005690578315614],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000003a",
			properties: {
				AREA_SQ_KM: 0.8942427494610294,
				ASS_CONST1: "Hebbal",
				ASS_CONST_: "158",
				LAT: 13.0171716167441,
				LON: 77.5959757654137,
				LOST_AREA_SQ_KM: 0.02707687543029785,
				OBJECTID: 59,
				POP_F: 14390.0,
				POP_M: 16353.0,
				POP_SC: 2465.0,
				POP_ST: 272.0,
				POP_TOTAL: 30743.0,
				RESERVATIO: "General",
				WARD_NAME: "Jayachamarajendra Nagar",
				WARD_NO: 46.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61017223004745, 13.018635366621796],
						[77.61088122035815, 13.018314313601975],
						[77.61096147323434, 13.018283073173905],
						[77.61087230336837, 13.018131521772661],
						[77.61133161484518, 13.017953101128796],
						[77.6121119330199, 13.017641003066613],
						[77.6126247405648, 13.017480426683651],
						[77.61288787479504, 13.017462658634448],
						[77.61297704306587, 13.017150454568823],
						[77.61303500240406, 13.016914125323616],
						[77.61311525374617, 13.016664464852708],
						[77.613146462586, 13.016392428104929],
						[77.61324008905362, 13.016017900437197],
						[77.61323563065216, 13.015803839942585],
						[77.6132445474549, 13.015509510095825],
						[77.61326683945863, 13.01519296599561],
						[77.6132757562589, 13.01493435014992],
						[77.6133337154434, 13.014546376139476],
						[77.61334263223837, 13.014274391069408],
						[77.61339622041288, 13.013922093151463],
						[77.61339176201761, 13.013507392769707],
						[77.61340959559766, 13.012981197573039],
						[77.61340959559766, 13.01269584728451],
						[77.61338284522652, 13.012321309586373],
						[77.61338730362216, 13.011991320480336],
						[77.61343188756874, 13.011590030870321],
						[77.61344080435595, 13.011322451664597],
						[77.61347647149768, 13.011001387054051],
						[77.61347201310558, 13.010720484694984],
						[77.61346755471331, 13.010377131557835],
						[77.61337838683072, 13.00995793167245],
						[77.6132935898573, 13.0095699969832],
						[77.61322671384872, 13.009226659774665],
						[77.61319996343417, 13.008936838620668],
						[77.61319550503113, 13.00852655514573],
						[77.61325792265767, 13.008562253233471],
						[77.61331588185135, 13.00861576440856],
						[77.61392239723567, 13.008214466316343],
						[77.61476957259781, 13.009561130178378],
						[77.6152823680444, 13.009271242969856],
						[77.6156524954584, 13.009110685887658],
						[77.61581299435571, 13.009070628167084],
						[77.61596020556149, 13.009574481947434],
						[77.61616082859922, 13.010314673752918],
						[77.615318034603, 13.010769470109432],
						[77.61544732578326, 13.010979098155206],
						[77.6154607881424, 13.010996912307032],
						[77.61563466223346, 13.011268897826309],
						[77.61576841135162, 13.011474083655335],
						[77.61583974414968, 13.011634599691394],
						[77.61613853716824, 13.012160776416097],
						[77.61650420349632, 13.013007964579343],
						[77.61686978122016, 13.013939951747531],
						[77.61701699104447, 13.014314521493985],
						[77.61760556616838, 13.015647779258256],
						[77.61767689780268, 13.015848414893794],
						[77.61784193944997, 13.016227474109256],
						[77.6182119710352, 13.017275301768013],
						[77.6184929249867, 13.018167193220807],
						[77.61855088148621, 13.018327679693515],
						[77.61855533967726, 13.018354464423407],
						[77.61855533967726, 13.018358863991184],
						[77.61869808911256, 13.018911807139133],
						[77.61896112173764, 13.020209427531308],
						[77.61899678713102, 13.021078914017266],
						[77.6190324525131, 13.021293024997158],
						[77.61872038003683, 13.021261768220846],
						[77.6185597978681, 13.021230564910745],
						[77.61819859642077, 13.021177012159097],
						[77.6179177291508, 13.021136906216118],
						[77.61781519013161, 13.021114624796597],
						[77.6177705205041, 13.021061142708094],
						[77.61771256360288, 13.021003138256567],
						[77.61768581425379, 13.020905056784178],
						[77.61763231553654, 13.020793608841554],
						[77.61759219148192, 13.020704372447662],
						[77.61756544210424, 13.020650891528941],
						[77.61751640156197, 13.0205527713652],
						[77.61747627746603, 13.020476992889435],
						[77.61740940394105, 13.020418990437696],
						[77.61728002759698, 13.0203833384456],
						[77.61702144929625, 13.020383299383031],
						[77.61666470117976, 13.020396731164203],
						[77.61617866174083, 13.020445770491401],
						[77.61576841135162, 13.020463609827441],
						[77.6156524954584, 13.020490372907071],
						[77.61534924283252, 13.020561677997852],
						[77.61494344775599, 13.020726642794267],
						[77.61468932243427, 13.020838186020589],
						[77.61448423842324, 13.020851505845766],
						[77.61425231645535, 13.020789083570609],
						[77.61412748226965, 13.020722247876861],
						[77.61395360585188, 13.020450235698128],
						[77.61394914747865, 13.020450193312438],
						[77.61391793886122, 13.02036992773618],
						[77.61388664281598, 13.020271887171159],
						[77.61382868391597, 13.020209397055998],
						[77.61373951631924, 13.020213897840872],
						[77.61350322184657, 13.020236150782633],
						[77.61348984667292, 13.02025405321803],
						[77.61348984667292, 13.020240681974943],
						[77.61337838683072, 13.020271885649638],
						[77.61337838683072, 13.020276285219447],
						[77.61275403483646, 13.020392234409355],
						[77.61273620115264, 13.020472550969831],
						[77.61270053377652, 13.021368778039655],
						[77.61270053377652, 13.02142226340194],
						[77.61255340573071, 13.021413358137742],
						[77.61236169314027, 13.021431202466346],
						[77.61204951475845, 13.021529332273891],
						[77.61203168096311, 13.021484735206805],
						[77.61200047181447, 13.021364355091906],
						[77.61192021967828, 13.021114626375892],
						[77.61181321674111, 13.020722218433965],
						[77.61175971523441, 13.020588428373483],
						[77.6116527121448, 13.020405647967397],
						[77.6115857477413, 13.020169329606292],
						[77.61145199365689, 13.02000432487689],
						[77.61138511655516, 13.019874980571409],
						[77.61121123590488, 13.019772395478865],
						[77.61108639834946, 13.019647589512495],
						[77.61096147323434, 13.01950932216297],
						[77.61083663540222, 13.019348802919348],
						[77.61072071443428, 13.01923288134697],
						[77.61058695932309, 13.019094620191794],
						[77.61039078487282, 13.018889514126036],
						[77.61017223004745, 13.018635366621796],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000003b",
			properties: {
				AREA_SQ_KM: 0.6461650621953142,
				ASS_CONST1: "Pulakeshi Nagar (SC)",
				ASS_CONST_: "159",
				LAT: 13.016273310793,
				LON: 77.6150174090356,
				LOST_AREA_SQ_KM: 0.0019509427666757624,
				OBJECTID: 60,
				POP_F: 16540.0,
				POP_M: 17506.0,
				POP_SC: 5167.0,
				POP_ST: 129.0,
				POP_TOTAL: 34046.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Kushal Nagar",
				WARD_NO: 31.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59962645629243, 13.02803960756011],
						[77.5996532099277, 13.027963775790463],
						[77.59976914227387, 13.027598162927358],
						[77.59987615664153, 13.027147750587714],
						[77.59994749949692, 13.026942686875717],
						[77.60000546553371, 13.0267955530245],
						[77.60011247967724, 13.026719715003559],
						[77.60028200595991, 13.026612733304132],
						[77.60040239656917, 13.026572565483633],
						[77.60051386924097, 13.02653688425839],
						[77.60054062266582, 13.026527996388936],
						[77.60060750620018, 13.026505690430252],
						[77.60060304729912, 13.02648787663451],
						[77.60060750620018, 13.026478947582119],
						[77.60064763630177, 13.026340700852515],
						[77.60063871850265, 13.026215873142748],
						[77.60046482127895, 13.025939359960926],
						[77.60046036237226, 13.025939403491321],
						[77.60046036237226, 13.025934917617224],
						[77.60040685547816, 13.025845722543314],
						[77.60039793766, 13.025564839207902],
						[77.60039793766, 13.025515753566843],
						[77.60042023220407, 13.025386481290143],
						[77.60043360892838, 13.025373065654149],
						[77.60043360892838, 13.025368579801837],
						[77.60046482127895, 13.025212564294154],
						[77.60046036237226, 13.024909295972964],
						[77.60037118420132, 13.024561480016168],
						[77.60030875943974, 13.024427773933828],
						[77.59999208875864, 13.023718741014793],
						[77.59990736914631, 13.023527025010276],
						[77.60090625216957, 13.023032030973827],
						[77.60095529993968, 13.023000841337739],
						[77.60142348212668, 13.022661902633951],
						[77.60164196648236, 13.022554954839032],
						[77.60208348019525, 13.022282876724407],
						[77.60244018704182, 13.022086672357302],
						[77.60320710294211, 13.021792424422683],
						[77.60387600811823, 13.02162741323627],
						[77.60523601273462, 13.021395588757812],
						[77.60583356678892, 13.021319777042992],
						[77.60650236896079, 13.021257320980673],
						[77.60639090220764, 13.020971928280664],
						[77.60623484856809, 13.020485891208715],
						[77.60613229891594, 13.020187128603315],
						[77.60609662944985, 13.020182647595172],
						[77.60603866654338, 13.019901736603662],
						[77.60598516229557, 13.019741206766735],
						[77.60598070360712, 13.019723393898117],
						[77.6063195634278, 13.019652008315719],
						[77.60649791029277, 13.019616357207497],
						[77.60670309626343, 13.019625304881796],
						[77.60687698386869, 13.019660951138102],
						[77.60707762307993, 13.01973230690628],
						[77.60722475827457, 13.019767956708856],
						[77.60730947239044, 13.019776873266842],
						[77.60742548429103, 13.019767976107916],
						[77.60749236374238, 13.019750153201896],
						[77.60763503977269, 13.019687677907013],
						[77.60773312943877, 13.019625295079988],
						[77.60780446732412, 13.019567315477206],
						[77.60782676040404, 13.019540527306273],
						[77.60787134655064, 13.019527150022894],
						[77.60822366391237, 13.019375580811133],
						[77.60864277151849, 13.019210582799934],
						[77.60908425782034, 13.01905451300434],
						[77.60917342910109, 13.019018872589085],
						[77.60969516710232, 13.018822676411796],
						[77.61011872776163, 13.018657631072887],
						[77.61017223004745, 13.018635366621796],
						[77.61039078487282, 13.018889514126036],
						[77.61058695932309, 13.019094620191794],
						[77.61072071443428, 13.01923288134697],
						[77.61083663540222, 13.019348802919348],
						[77.61096147323434, 13.01950932216297],
						[77.61108639834946, 13.019647589512495],
						[77.61121123590488, 13.019772395478865],
						[77.61138511655516, 13.019874980571409],
						[77.61145199365689, 13.02000432487689],
						[77.6115857477413, 13.020169329606292],
						[77.6116527121448, 13.020405647967397],
						[77.61175971523441, 13.020588428373483],
						[77.61181321674111, 13.020722218433965],
						[77.61192021967828, 13.021114626375892],
						[77.61200047181447, 13.021364355091906],
						[77.61203168096311, 13.021484735206805],
						[77.61204951475845, 13.021529332273891],
						[77.61236169314027, 13.021431202466346],
						[77.61255340573071, 13.021413358137742],
						[77.61270053377652, 13.02142226340194],
						[77.61270945062161, 13.022077804717727],
						[77.61267378323703, 13.022389925466339],
						[77.61266932481315, 13.022434483296802],
						[77.61257123944345, 13.023442279018862],
						[77.61255340573071, 13.023651827289665],
						[77.61255340573071, 13.02365631324223],
						[77.61290570845485, 13.02448120837425],
						[77.61328913145798, 13.02535523216164],
						[77.61353888896855, 13.025774384621307],
						[77.61365926542184, 13.025912614576978],
						[77.61405169001797, 13.026242630527253],
						[77.61464473898539, 13.026603803987541],
						[77.6149033227433, 13.026813317125702],
						[77.61550537126827, 13.027326160468577],
						[77.61584866074627, 13.027602652104882],
						[77.61603599652886, 13.027767579655814],
						[77.61612070402028, 13.027887959487572],
						[77.6161429954548, 13.027937088137476],
						[77.6161429954548, 13.028017321473202],
						[77.61613407888152, 13.028070811772116],
						[77.61610287086948, 13.028128834837823],
						[77.61588878542216, 13.028463256001668],
						[77.61561682900572, 13.02876641647009],
						[77.61571937002674, 13.02886453648472],
						[77.61488548939975, 13.029907995438867],
						[77.6139134804866, 13.031107504695756],
						[77.6126559495405, 13.03265481585755],
						[77.61207626544616, 13.033381577476773],
						[77.6118042998251, 13.033774033266331],
						[77.6113628241791, 13.034514195932681],
						[77.61113098320656, 13.03501367393252],
						[77.61097484870815, 13.035423887636465],
						[77.61081434291764, 13.035967879081053],
						[77.61063154437778, 13.036716998816285],
						[77.61060033484135, 13.036833001922792],
						[77.61053791574254, 13.037109441338682],
						[77.61041753595433, 13.037617758740955],
						[77.61038186784424, 13.037778325564863],
						[77.61025694194808, 13.038197472820821],
						[77.61022573230788, 13.038130567050414],
						[77.61014102038381, 13.03792544896779],
						[77.60998943046602, 13.037555331491323],
						[77.60990917689757, 13.037207554269822],
						[77.6098422988802, 13.03698009051429],
						[77.60964157716758, 13.036280011340306],
						[77.60930718588995, 13.034830821289864],
						[77.60927597598675, 13.034688167893325],
						[77.60920463903268, 13.034353685718447],
						[77.60914667772427, 13.034139686259863],
						[77.60909763351695, 13.033965717868275],
						[77.60882111470802, 13.03301154642003],
						[77.60843321791023, 13.03169604469693],
						[77.60817007327799, 13.03065264432882],
						[77.60808090120346, 13.030220085921856],
						[77.60807198399213, 13.030162112195452],
						[77.60800510488465, 13.030001610931507],
						[77.60794268434853, 13.029876782748529],
						[77.60783121901949, 13.029738544970533],
						[77.60779109147406, 13.029689504099103],
						[77.60761720527877, 13.029493299212596],
						[77.6074745292259, 13.029225701947919],
						[77.60747007059635, 13.029216773391694],
						[77.60725596874572, 13.028748601212685],
						[77.60706870578923, 13.028333920062401],
						[77.60677443480058, 13.027714103023884],
						[77.60639090220764, 13.026804431610055],
						[77.60592274064105, 13.026982845336027],
						[77.60509779239196, 13.027250321472406],
						[77.60436201319406, 13.027406411450224],
						[77.6043575544414, 13.027410854755598],
						[77.60426837935151, 13.02741534985553],
						[77.60425946183865, 13.027406378801913],
						[77.60421487426372, 13.027370754311006],
						[77.60318480894882, 13.027566984378582],
						[77.60198092676809, 13.027714098768977],
						[77.60182032074471, 13.027731966606364],
						[77.60138335230108, 13.027794377455779],
						[77.60109798424061, 13.027838916739512],
						[77.59962645629243, 13.02803960756011],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000003c",
			properties: {
				AREA_SQ_KM: 1.5916751622335696,
				ASS_CONST1: "Pulakeshi Nagar (SC)",
				ASS_CONST_: "159",
				LAT: 13.0263505691114,
				LON: 77.6085689953968,
				LOST_AREA_SQ_KM: 0.05429220064036051,
				OBJECTID: 61,
				POP_F: 12636.0,
				POP_M: 13914.0,
				POP_SC: 3669.0,
				POP_ST: 178.0,
				POP_TOTAL: 26550.0,
				RESERVATIO: "General (Woman)",
				WARD_NAME: "Kaval Bairasandra",
				WARD_NO: 32.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59575148370399, 13.011300105324151],
						[77.5959030927585, 13.011233240732654],
						[77.59600565170933, 13.011184193218877],
						[77.59620185118172, 13.011099468545144],
						[77.59660316722217, 13.010930017503707],
						[77.59691538836618, 13.010836398409968],
						[77.59732116090068, 13.010622394572396],
						[77.59810594759745, 13.010185330835899],
						[77.60025525247376, 13.00918208121912],
						[77.60096421771378, 13.008811992377783],
						[77.60124958611262, 13.008678170546316],
						[77.60133876358921, 13.0086335640342],
						[77.60135214020463, 13.008660344092819],
						[77.60144131760016, 13.00882533603268],
						[77.60193625086401, 13.009904444478916],
						[77.60195854510407, 13.009962448112162],
						[77.6024936929715, 13.01126889683225],
						[77.60252044592683, 13.01136705313707],
						[77.60254274005143, 13.011567726444886],
						[77.60266758706773, 13.012084906305743],
						[77.60270325761843, 13.012191946636637],
						[77.60273001052406, 13.01228113304236],
						[77.60282364564377, 13.012722629592945],
						[77.60296186877368, 13.013248741084421],
						[77.60328736128142, 13.013123922988099],
						[77.60348363551988, 13.013016939251756],
						[77.60355497609383, 13.01296784818316],
						[77.60362185784093, 13.012896546254145],
						[77.60367536321009, 13.012704786855894],
						[77.60374670366278, 13.012548735883923],
						[77.60378237387219, 13.012495164551375],
						[77.60391167828678, 13.012437223562138],
						[77.60421933302202, 13.012352516293385],
						[77.60428621437515, 13.012325723331445],
						[77.60436647194652, 13.012303456899437],
						[77.6044333532124, 13.012281149292669],
						[77.60449131694406, 13.012276698378592],
						[77.6045493680723, 13.012272251662973],
						[77.60464300168154, 13.012285650773544],
						[77.60483472740074, 13.012285578854941],
						[77.60489714965759, 13.012285569445178],
						[77.60496403060871, 13.012272232475294],
						[77.60500415916034, 13.012254414116388],
						[77.6050532051485, 13.012227623559665],
						[77.6051156272844, 13.01219198950759],
						[77.6051958842655, 13.012156266455051],
						[77.60536085676947, 13.012076064368857],
						[77.60550799420452, 13.012009148619263],
						[77.60563292536378, 13.011946682712411],
						[77.60576222765644, 13.011879855781636],
						[77.60595841016215, 13.011795119729552],
						[77.60607879471257, 13.011746063111175],
						[77.6060743360278, 13.011728251550215],
						[77.60621701385269, 13.011679148186047],
						[77.60648453428769, 13.011567660249295],
						[77.6068234800187, 13.011438395426143],
						[77.60715342002237, 13.011309040943399],
						[77.60732730693304, 13.01124220554972],
						[77.60742548429103, 13.011224338144599],
						[77.60756370178011, 13.011210988342084],
						[77.6077420466769, 13.0111931031904],
						[77.60788026377784, 13.01117526774829],
						[77.60799172905838, 13.011166407550709],
						[77.60810765283323, 13.011175272568398],
						[77.60821920531245, 13.011175301045437],
						[77.6083395874487, 13.011152982882876],
						[77.60870073308604, 13.011037033300767],
						[77.60891920334198, 13.010992443243884],
						[77.60917342910109, 13.010961220933389],
						[77.60947215237758, 13.010925587060445],
						[77.609489986578, 13.011028145020225],
						[77.60957469899144, 13.011237694943567],
						[77.60964603571125, 13.011317989114199],
						[77.60975312812866, 13.011465131774099],
						[77.60982446473555, 13.011554310788727],
						[77.60990471836432, 13.01161674806894],
						[77.60997159634464, 13.011701485671185],
						[77.61007860103058, 13.011915475506008],
						[77.61009643513503, 13.011995779644522],
						[77.61009643513503, 13.012040375651765],
						[77.6100919766092, 13.012138496333838],
						[77.61009197660918, 13.012160751232285],
						[77.6100696839773, 13.012316841438471],
						[77.61003401575712, 13.012521972698798],
						[77.61002509870032, 13.012553113949597],
						[77.6100295572288, 13.012740425988529],
						[77.61000726458458, 13.012878661102711],
						[77.60982446473554, 13.013404835125808],
						[77.60970408418524, 13.013761584788734],
						[77.60969962564387, 13.013774912767403],
						[77.6096594987636, 13.013904267012505],
						[77.60949890367714, 13.014243121089205],
						[77.60943648396828, 13.014336810548581],
						[77.60942756686418, 13.014354667993604],
						[77.60925814175246, 13.014631075505152],
						[77.60921355615442, 13.01477824394523],
						[77.60919572191025, 13.014880811199232],
						[77.60921355615442, 13.015121563147948],
						[77.60911992634111, 13.015121621434435],
						[77.60852684829398, 13.0151170973065],
						[77.60855805840463, 13.015768149454708],
						[77.60854468264398, 13.015959867954496],
						[77.60852684829398, 13.016165001153837],
						[77.60850901394116, 13.016240828083319],
						[77.60847334522707, 13.01627654603746],
						[77.6084153835426, 13.01629436810031],
						[77.60832175306625, 13.016307710036546],
						[77.60807644259788, 13.016316671639366],
						[77.60780892594045, 13.016329996775758],
						[77.60769746047916, 13.016414765850636],
						[77.60763503977269, 13.016517254294063],
						[77.60756370178011, 13.016758108259209],
						[77.60748790511353, 13.01703006075613],
						[77.60748790511353, 13.017279789998488],
						[77.60751019825605, 13.017462619912735],
						[77.60754140864817, 13.01766770458421],
						[77.60759045353261, 13.018028922825232],
						[77.60764395701858, 13.018381213377156],
						[77.60766625013024, 13.018608643288024],
						[77.60770191909972, 13.018813772617104],
						[77.60773312943877, 13.01901885984776],
						[77.60779555009091, 13.019371152349912],
						[77.60782676040404, 13.019540527306273],
						[77.60780446732412, 13.019567315477206],
						[77.60773312943877, 13.019625295079988],
						[77.60763503977269, 13.019687677907013],
						[77.60749236374238, 13.019750153201896],
						[77.60742548429103, 13.019767976107916],
						[77.60730947239044, 13.019776873266842],
						[77.60722475827457, 13.019767956708856],
						[77.60707762307993, 13.01973230690628],
						[77.60687698386869, 13.019660951138102],
						[77.60670309626343, 13.019625304881796],
						[77.60649791029277, 13.019616357207497],
						[77.6063195634278, 13.019652008315719],
						[77.60598070360712, 13.019723393898117],
						[77.60598516229557, 13.019741206766735],
						[77.60603866654338, 13.019901736603662],
						[77.60609662944985, 13.020182647595172],
						[77.60613229891594, 13.020187128603315],
						[77.60623484856809, 13.020485891208715],
						[77.60639090220764, 13.020971928280664],
						[77.60650236896079, 13.021257320980673],
						[77.60583356678892, 13.021319777042992],
						[77.60523601273462, 13.021395588757812],
						[77.60387600811823, 13.02162741323627],
						[77.60320710294211, 13.021792424422683],
						[77.60244018704182, 13.022086672357302],
						[77.60208348019525, 13.022282876724407],
						[77.60164196648236, 13.022554954839032],
						[77.60142348212668, 13.022661902633951],
						[77.60095529993968, 13.023000841337739],
						[77.60080369767223, 13.02258609622424],
						[77.60038456093146, 13.02141786571344],
						[77.60032213617728, 13.021248364471687],
						[77.59991182807487, 13.02034315884598],
						[77.59983602626555, 13.020178189619664],
						[77.59983602626555, 13.020120221264234],
						[77.59983156733401, 13.020075667200095],
						[77.59980481374103, 13.019946362754682],
						[77.59976914227387, 13.019857171317703],
						[77.59972901185982, 13.019763537927508],
						[77.59948377013058, 13.0192908913835],
						[77.59922960995783, 13.018885100621223],
						[77.59900666196656, 13.018626426531155],
						[77.59876141866738, 13.018390140147464],
						[77.59871682891936, 13.018358918360729],
						[77.5986677801762, 13.01830539863004],
						[77.59862764937047, 13.018251878001404],
						[77.59859197753119, 13.018193914513752],
						[77.59844929006128, 13.017908493169678],
						[77.59817729157201, 13.01729767012668],
						[77.59784732531158, 13.016602020926783],
						[77.59774476803413, 13.016379094756019],
						[77.59766450575198, 13.01627654117016],
						[77.59764221066347, 13.016223019801256],
						[77.59763329262682, 13.016182824057205],
						[77.59763329262682, 13.016147199108898],
						[77.5976555877171, 13.016093673083871],
						[77.59766004673463, 13.015950957330572],
						[77.59766896476916, 13.015879620367494],
						[77.59766450575198, 13.015781501550366],
						[77.5976555877171, 13.015728022134669],
						[77.59762883360824, 13.015643319051081],
						[77.59760207949306, 13.015594240806456],
						[77.59757532537154, 13.01553627796293],
						[77.59753519417734, 13.015447134172991],
						[77.59701794639727, 13.014546397463155],
						[77.59641142751418, 13.013574254017025],
						[77.59589417458447, 13.01275383923404],
						[77.59580945189593, 13.012655732230268],
						[77.59577823825782, 13.012553134238333],
						[77.59572472914384, 13.012392618045146],
						[77.59544826498416, 13.011523108010104],
						[77.59548839692032, 13.011465097118199],
						[77.59549731512641, 13.011442842354997],
						[77.59564892452227, 13.011349152692286],
						[77.59575148370399, 13.011300105324151],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000003d",
			properties: {
				AREA_SQ_KM: 1.3703011403424767,
				ASS_CONST1: "Pulakeshi Nagar (SC)",
				ASS_CONST_: "159",
				LAT: 13.0154531442964,
				LON: 77.6027525099095,
				LOST_AREA_SQ_KM: 0.03626318898031197,
				OBJECTID: 62,
				POP_F: 16187.0,
				POP_M: 17296.0,
				POP_SC: 7021.0,
				POP_ST: 167.0,
				POP_TOTAL: 33483.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Devara Jeevanahalli",
				WARD_NO: 47.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61017223004745, 13.018635366621796],
						[77.61011872776163, 13.018657631072887],
						[77.60969516710232, 13.018822676411796],
						[77.60917342910109, 13.019018872589085],
						[77.60908425782034, 13.01905451300434],
						[77.60864277151849, 13.019210582799934],
						[77.60822366391237, 13.019375580811133],
						[77.60787134655064, 13.019527150022894],
						[77.60782676040404, 13.019540527306273],
						[77.60779555009091, 13.019371152349912],
						[77.60773312943877, 13.01901885984776],
						[77.60770191909972, 13.018813772617104],
						[77.60766625013024, 13.018608643288024],
						[77.60764395701858, 13.018381213377156],
						[77.60759045353261, 13.018028922825232],
						[77.60754140864817, 13.01766770458421],
						[77.60751019825605, 13.017462619912735],
						[77.60748790511353, 13.017279789998488],
						[77.60748790511353, 13.01703006075613],
						[77.60756370178011, 13.016758108259209],
						[77.60763503977269, 13.016517254294063],
						[77.60769746047916, 13.016414765850636],
						[77.60780892594045, 13.016329996775758],
						[77.60807644259788, 13.016316671639366],
						[77.60832175306625, 13.016307710036546],
						[77.6084153835426, 13.01629436810031],
						[77.60847334522707, 13.01627654603746],
						[77.60850901394116, 13.016240828083319],
						[77.60852684829398, 13.016165001153837],
						[77.60854468264398, 13.015959867954496],
						[77.60855805840463, 13.015768149454708],
						[77.60852684829398, 13.0151170973065],
						[77.60911992634111, 13.015121621434435],
						[77.60921355615442, 13.015121563147948],
						[77.60919572191025, 13.014880811199232],
						[77.60921355615442, 13.01477824394523],
						[77.60925814175246, 13.014631075505152],
						[77.60942756686418, 13.014354667993604],
						[77.60943648396828, 13.014336810548581],
						[77.60949890367714, 13.014243121089205],
						[77.6096594987636, 13.013904267012505],
						[77.60969962564387, 13.013774912767403],
						[77.60970408418524, 13.013761584788734],
						[77.60982446473554, 13.013404835125808],
						[77.61000726458458, 13.012878661102711],
						[77.6100295572288, 13.012740425988529],
						[77.61002509870032, 13.012553113949597],
						[77.61003401575712, 13.012521972698798],
						[77.6100696839773, 13.012316841438471],
						[77.61009197660918, 13.012160751232285],
						[77.6100919766092, 13.012138496333838],
						[77.61009643513503, 13.012040375651765],
						[77.61009643513503, 13.011995779644522],
						[77.61007860103058, 13.011915475506008],
						[77.60997159634464, 13.011701485671185],
						[77.60990471836432, 13.01161674806894],
						[77.60982446473555, 13.011554310788727],
						[77.60975312812866, 13.011465131774099],
						[77.60964603571125, 13.011317989114199],
						[77.60957469899144, 13.011237694943567],
						[77.609489986578, 13.011028145020225],
						[77.60947215237758, 13.010925587060445],
						[77.6095301135186, 13.010756122625958],
						[77.60960590881191, 13.010564400621703],
						[77.60977096228461, 13.010176430477804],
						[77.60987796716107, 13.0099490358785],
						[77.61001618164279, 13.009668113061542],
						[77.61016777152462, 13.00945408068614],
						[77.61033273675254, 13.009248973544661],
						[77.61040861892786, 13.009128591764691],
						[77.61056466678912, 13.008994820978097],
						[77.61069396342474, 13.008923419001041],
						[77.61088567885277, 13.008910056201811],
						[77.61089459584149, 13.00891005451968],
						[77.6109213468034, 13.008910049471098],
						[77.61141632587425, 13.008838754326602],
						[77.61239736062365, 13.008673734984228],
						[77.61287895796407, 13.00856668491027],
						[77.61298150147755, 13.008531083309668],
						[77.61319550503113, 13.00852655514573],
						[77.61319996343417, 13.008936838620668],
						[77.61322671384872, 13.009226659774665],
						[77.6132935898573, 13.0095699969832],
						[77.61337838683072, 13.00995793167245],
						[77.61346755471331, 13.010377131557835],
						[77.61347201310558, 13.010720484694984],
						[77.61347647149768, 13.011001387054051],
						[77.61344080435595, 13.011322451664597],
						[77.61343188756874, 13.011590030870321],
						[77.61338730362216, 13.011991320480336],
						[77.61338284522652, 13.012321309586373],
						[77.61340959559766, 13.01269584728451],
						[77.61340959559766, 13.012981197573039],
						[77.61339176201761, 13.013507392769707],
						[77.61339622041288, 13.013922093151463],
						[77.61334263223837, 13.014274391069408],
						[77.6133337154434, 13.014546376139476],
						[77.6132757562589, 13.01493435014992],
						[77.61326683945863, 13.01519296599561],
						[77.6132445474549, 13.015509510095825],
						[77.61323563065216, 13.015803839942585],
						[77.61324008905362, 13.016017900437197],
						[77.613146462586, 13.016392428104929],
						[77.61311525374617, 13.016664464852708],
						[77.61303500240406, 13.016914125323616],
						[77.61297704306587, 13.017150454568823],
						[77.61288787479504, 13.017462658634448],
						[77.6126247405648, 13.017480426683651],
						[77.6121119330199, 13.017641003066613],
						[77.61133161484518, 13.017953101128796],
						[77.61087230336837, 13.018131521772661],
						[77.61096147323434, 13.018283073173905],
						[77.61088122035815, 13.018314313601975],
						[77.61017223004745, 13.018635366621796],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000003e",
			properties: {
				AREA_SQ_KM: 0.48188781347786,
				ASS_CONST1: "Pulakeshi Nagar (SC)",
				ASS_CONST_: "159",
				LAT: 13.0141600375146,
				LON: 77.6109785409947,
				LOST_AREA_SQ_KM: 0.004375647551532821,
				OBJECTID: 63,
				POP_F: 14951.0,
				POP_M: 15557.0,
				POP_SC: 6605.0,
				POP_ST: 33.0,
				POP_TOTAL: 30508.0,
				RESERVATIO: "Scheduled Caste (Woman)",
				WARD_NAME: "Muneshwara Nagar",
				WARD_NO: 48.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61319550503113, 13.00852655514573],
						[77.61300825194398, 13.008174317809333],
						[77.61291908369786, 13.007942476713167],
						[77.61281654012757, 13.007723965523404],
						[77.61270945062161, 13.007393964912279],
						[77.6125979900073, 13.007104122784757],
						[77.61254448887327, 13.00682319727336],
						[77.61244194496201, 13.00645753614848],
						[77.61238844375387, 13.006163242763872],
						[77.61233048408305, 13.005672763577628],
						[77.61229035813963, 13.005088610233857],
						[77.61228144126139, 13.004450932629176],
						[77.6122368568595, 13.003733050011409],
						[77.61216097591537, 13.003104317051774],
						[77.61213422524777, 13.002676248683155],
						[77.61209409923445, 13.001962818409023],
						[77.61199601336396, 13.001922645904378],
						[77.61208518234066, 13.00165507219578],
						[77.61213422524777, 13.00167744403216],
						[77.61228589970058, 13.001717522694857],
						[77.61257123944345, 13.001824586076438],
						[77.61339176201761, 13.002016326484815],
						[77.61389110119168, 13.002109972476553],
						[77.6139402307317, 13.002109922613876],
						[77.61396698097047, 13.002118886950855],
						[77.61458232212729, 13.002176801246833],
						[77.61519765992244, 13.002181323205985],
						[77.61554995437648, 13.002158950015328],
						[77.61608503771586, 13.002083185872506],
						[77.61642841284404, 13.002020791532138],
						[77.61672711688377, 13.00196280183009],
						[77.61703928230156, 13.001873631975819],
						[77.61771256360288, 13.001619414481011],
						[77.61806485018911, 13.001467828604284],
						[77.61832351350932, 13.001329591235791],
						[77.61864450346089, 13.00113785666348],
						[77.61901461982347, 13.000901563597079],
						[77.61918411767587, 13.000785600730262],
						[77.61921086666898, 13.00076773955279],
						[77.61936244417652, 13.000647377905333],
						[77.6197191837449, 13.000361965724215],
						[77.62006254778976, 13.000081084763497],
						[77.62006700592093, 13.000081040488832],
						[77.62018737539574, 13.000139014706972],
						[77.62024978914684, 13.000165737095315],
						[77.62026316351756, 13.000892634912251],
						[77.62027207976384, 13.000941624772944],
						[77.62031674839874, 13.001235915908818],
						[77.62043711760649, 13.00161053021519],
						[77.62045049195494, 13.001623939358122],
						[77.62064664888328, 13.002074224336777],
						[77.62065110699132, 13.002502349265336],
						[77.62065110699132, 13.002618276081215],
						[77.62064219077506, 13.002636133141971],
						[77.62057977724157, 13.002680742787232],
						[77.62021858227563, 13.002859075470278],
						[77.61985292801114, 13.00308644463415],
						[77.61971026745483, 13.00320240694505],
						[77.61958098116952, 13.003371887346105],
						[77.61949173071282, 13.003483346972555],
						[77.61947389809595, 13.003608163015583],
						[77.61954977411288, 13.003755343890687],
						[77.61955423226435, 13.003759784969109],
						[77.61974593261085, 13.00431276787682],
						[77.62006254778976, 13.00496380154772],
						[77.62042820137329, 13.00557018959832],
						[77.62051736367347, 13.005623733707075],
						[77.62078047946127, 13.006083038247185],
						[77.62127987328317, 13.006751851917072],
						[77.6216098583578, 13.007144252028924],
						[77.62192201023586, 13.007523287921352],
						[77.6220914162973, 13.0077283708503],
						[77.62259534974538, 13.008259054046622],
						[77.62259980777621, 13.008263495333887],
						[77.62252839184914, 13.00833035984675],
						[77.62221178360704, 13.008473063059698],
						[77.6215831099348, 13.008852106033787],
						[77.62121300201768, 13.009213316766232],
						[77.62100338438164, 13.009498667059903],
						[77.62094097104844, 13.009672582313943],
						[77.62094097104844, 13.009677067851362],
						[77.62089193197664, 13.009748373423415],
						[77.62058869346276, 13.009525456678697],
						[77.62039253643343, 13.00937825608892],
						[77.62026762164078, 13.009271232118477],
						[77.62018291726935, 13.009199871635246],
						[77.62001350833523, 13.009159799827499],
						[77.6198484698715, 13.009141934573602],
						[77.61971472559996, 13.009083998712466],
						[77.61960773006832, 13.009052797191696],
						[77.61946498178645, 13.008999258359486],
						[77.61930894625597, 13.00896802480247],
						[77.61914845234185, 13.008994801593227],
						[77.61899232895746, 13.009039385588707],
						[77.61881846005116, 13.009137458467906],
						[77.61873821277305, 13.009240038886484],
						[77.61869808911256, 13.009387163407032],
						[77.61833688810891, 13.009520984778664],
						[77.61800243522664, 13.009627972835464],
						[77.61750302686491, 13.009810775007379],
						[77.61679844907457, 13.010087293057664],
						[77.61616082859922, 13.010314673752918],
						[77.61596020556149, 13.009574481947434],
						[77.61581299435571, 13.009070628167084],
						[77.6156524954584, 13.009110685887658],
						[77.6152823680444, 13.009271242969856],
						[77.61476957259781, 13.009561130178378],
						[77.61392239723567, 13.008214466316343],
						[77.61331588185135, 13.00861576440856],
						[77.61325792265767, 13.008562253233471],
						[77.61319550503113, 13.00852655514573],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000003f",
			properties: {
				AREA_SQ_KM: 0.7721957147313296,
				ASS_CONST1: "Pulakeshi Nagar (SC)",
				ASS_CONST_: "159",
				LAT: 13.0055771246835,
				LON: 77.6168659479228,
				LOST_AREA_SQ_KM: 0.02238549419313319,
				OBJECTID: 64,
				POP_F: 17349.0,
				POP_M: 17525.0,
				POP_SC: 10444.0,
				POP_ST: 132.0,
				POP_TOTAL: 34874.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Sagayarapuram",
				WARD_NO: 60.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61319550503113, 13.00852655514573],
						[77.61298150147755, 13.008531083309668],
						[77.61287895796407, 13.00856668491027],
						[77.61239736062365, 13.008673734984228],
						[77.61141632587425, 13.008838754326602],
						[77.6109213468034, 13.008910049471098],
						[77.61089459584149, 13.00891005451968],
						[77.61088567885277, 13.008910056201811],
						[77.61069396342474, 13.008923419001041],
						[77.61056466678912, 13.008994820978097],
						[77.61040861892786, 13.009128591764691],
						[77.61033273675254, 13.009248973544661],
						[77.61016777152462, 13.00945408068614],
						[77.61001618164279, 13.009668113061542],
						[77.60987796716107, 13.0099490358785],
						[77.60977096228461, 13.010176430477804],
						[77.60960590881191, 13.010564400621703],
						[77.6095301135186, 13.010756122625958],
						[77.60947215237758, 13.010925587060445],
						[77.60917342910109, 13.010961220933389],
						[77.60891920334198, 13.010992443243884],
						[77.60870073308604, 13.011037033300767],
						[77.6083395874487, 13.011152982882876],
						[77.60821920531245, 13.011175301045437],
						[77.60810765283323, 13.011175272568398],
						[77.60799172905838, 13.011166407550709],
						[77.60788026377784, 13.01117526774829],
						[77.6077420466769, 13.0111931031904],
						[77.60756370178011, 13.011210988342084],
						[77.60742548429103, 13.011224338144599],
						[77.60732730693304, 13.01124220554972],
						[77.60715342002237, 13.011309040943399],
						[77.6068234800187, 13.011438395426143],
						[77.60648453428769, 13.011567660249295],
						[77.60621701385269, 13.011679148186047],
						[77.6060743360278, 13.011728251550215],
						[77.60607879471257, 13.011746063111175],
						[77.60595841016215, 13.011795119729552],
						[77.60576222765644, 13.011879855781636],
						[77.60563292536378, 13.011946682712411],
						[77.60550799420452, 13.012009148619263],
						[77.60536085676947, 13.012076064368857],
						[77.6051958842655, 13.012156266455051],
						[77.6051156272844, 13.01219198950759],
						[77.6050532051485, 13.012227623559665],
						[77.60500415916034, 13.012254414116388],
						[77.60496403060871, 13.012272232475294],
						[77.60489714965759, 13.012285569445178],
						[77.60483472740074, 13.012285578854941],
						[77.60464300168154, 13.012285650773544],
						[77.6045493680723, 13.012272251662973],
						[77.60449131694406, 13.012276698378592],
						[77.6044333532124, 13.012281149292669],
						[77.60436647194652, 13.012303456899437],
						[77.60428621437515, 13.012325723331445],
						[77.60421933302202, 13.012352516293385],
						[77.60391167828678, 13.012437223562138],
						[77.60378237387219, 13.012495164551375],
						[77.60374670366278, 13.012548735883923],
						[77.60367536321009, 13.012704786855894],
						[77.60362185784093, 13.012896546254145],
						[77.60355497609383, 13.01296784818316],
						[77.60348363551988, 13.013016939251756],
						[77.60328736128142, 13.013123922988099],
						[77.60296186877368, 13.013248741084421],
						[77.60282364564377, 13.012722629592945],
						[77.60273001052406, 13.01228113304236],
						[77.60270325761843, 13.012191946636637],
						[77.60266758706773, 13.012084906305743],
						[77.60254274005143, 13.011567726444886],
						[77.60252044592683, 13.01136705313707],
						[77.6024936929715, 13.01126889683225],
						[77.60195854510407, 13.009962448112162],
						[77.60193625086401, 13.009904444478916],
						[77.60144131760016, 13.00882533603268],
						[77.60135214020463, 13.008660344092819],
						[77.60133876358921, 13.0086335640342],
						[77.60147252967194, 13.008566697311014],
						[77.60244464587025, 13.008102938986003],
						[77.60526722375629, 13.006716172483708],
						[77.60514237961779, 13.0064397497396],
						[77.60494173696274, 13.00598044007458],
						[77.60460733174433, 13.006083008914928],
						[77.6045894967715, 13.006056273356819],
						[77.60456274430695, 13.005944752259868],
						[77.6045315330903, 13.005904606608727],
						[77.60433971942908, 13.005449779068668],
						[77.6041034052493, 13.005035072834486],
						[77.60402314749071, 13.00482549296956],
						[77.60399639489181, 13.004767535932428],
						[77.6038225028443, 13.004401899415242],
						[77.6037422448858, 13.00424139820438],
						[77.6037422448858, 13.004161097743804],
						[77.6037422448858, 13.004125475752433],
						[77.60374670366278, 13.004085324794396],
						[77.60378683264757, 13.003826692314677],
						[77.60378683264757, 13.003706285176067],
						[77.60378683264757, 13.00345658738181],
						[77.60259178711001, 13.001739800121129],
						[77.60256949299513, 13.001677402296172],
						[77.60250706944996, 13.001579347214808],
						[77.6024357282132, 13.001463353875078],
						[77.60233763393896, 13.001396441201042],
						[77.60226629259502, 13.001383084819784],
						[77.60224399841579, 13.001378646808607],
						[77.6022172453949, 13.001369767719027],
						[77.60207010366655, 13.001383118465466],
						[77.60202105638112, 13.001392053655325],
						[77.60185599156334, 13.001423256680772],
						[77.60165534306182, 13.001472323403682],
						[77.60165088420217, 13.00147676599556],
						[77.60149036513765, 13.001494560387119],
						[77.60149482400364, 13.001409819756853],
						[77.60153941265382, 13.000990728013],
						[77.60155724810896, 13.000816761035349],
						[77.60157062469847, 13.000740989209786],
						[77.6015795424239, 13.000691912161008],
						[77.60159737787271, 13.000638434921134],
						[77.60160183673446, 13.000522472868646],
						[77.60167317849863, 13.00054031408467],
						[77.60177127335068, 13.000081024043018],
						[77.60182923845042, 12.999840252002908],
						[77.60219495120597, 12.999666315714995],
						[77.60327398489551, 12.9992427295652],
						[77.60383587916516, 12.999019764948399],
						[77.60438430695463, 12.99880572354231],
						[77.60460287300138, 12.998703180390054],
						[77.60470096530545, 12.998613981172904],
						[77.60475447016259, 12.998547128717139],
						[77.60482135119837, 12.998440125277783],
						[77.60487039726603, 12.998359862154077],
						[77.60494619569229, 12.998061042064627],
						[77.60499078297805, 12.997913894936621],
						[77.60500861788742, 12.99787378633951],
						[77.60506658132338, 12.997846995151265],
						[77.60515575578209, 12.997838094196828],
						[77.6058781537238, 12.997833643272077],
						[77.6062571419584, 12.997824727007487],
						[77.60635523282336, 12.997838075683799],
						[77.60639536087989, 12.997851479143206],
						[77.60642211290964, 12.99787821060224],
						[77.60646669961177, 12.997913908258427],
						[77.60649791029277, 12.997949565501457],
						[77.60652474972302, 12.997994154539176],
						[77.60654704305601, 12.998127963955938],
						[77.60654704305601, 12.998212659661453],
						[77.60659162970873, 12.998578343683851],
						[77.60673430687899, 12.999514718943978],
						[77.60678335211456, 12.999545974566692],
						[77.60686360790856, 12.999545958641615],
						[77.60712666816615, 12.999479106149693],
						[77.60844213509299, 12.999042037098544],
						[77.60867844017896, 12.9989305969592],
						[77.60874531888695, 12.999033176575665],
						[77.60891474476955, 12.999269464788972],
						[77.60937852277908, 12.999786784843748],
						[77.61054683275876, 13.000861402886187],
						[77.61119340197686, 13.001262727009376],
						[77.61208518234066, 13.00165507219578],
						[77.61199601336396, 13.001922645904378],
						[77.61209409923445, 13.001962818409023],
						[77.61213422524777, 13.002676248683155],
						[77.61216097591537, 13.003104317051774],
						[77.6122368568595, 13.003733050011409],
						[77.61228144126139, 13.004450932629176],
						[77.61229035813963, 13.005088610233857],
						[77.61233048408305, 13.005672763577628],
						[77.61238844375387, 13.006163242763872],
						[77.61244194496201, 13.00645753614848],
						[77.61254448887327, 13.00682319727336],
						[77.6125979900073, 13.007104122784757],
						[77.61270945062161, 13.007393964912279],
						[77.61281654012757, 13.007723965523404],
						[77.61291908369786, 13.007942476713167],
						[77.61300825194398, 13.008174317809333],
						[77.61319550503113, 13.00852655514573],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000040",
			properties: {
				AREA_SQ_KM: 1.3098445126995204,
				ASS_CONST1: "Pulakeshi Nagar (SC)",
				ASS_CONST_: "159",
				LAT: 13.0053276326838,
				LON: 77.6070579867665,
				LOST_AREA_SQ_KM: 0.05320148984683167,
				OBJECTID: 65,
				POP_F: 16552.0,
				POP_M: 17387.0,
				POP_SC: 7226.0,
				POP_ST: 88.0,
				POP_TOTAL: 33939.0,
				RESERVATIO: "Scheduled Caste (Woman)",
				WARD_NAME: "S K Garden",
				WARD_NO: 61.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61208518234066, 13.00165507219578],
						[77.61119340197686, 13.001262727009376],
						[77.61054683275876, 13.000861402886187],
						[77.60937852277908, 12.999786784843748],
						[77.60891474476955, 12.999269464788972],
						[77.60874531888695, 12.999033176575665],
						[77.60867844017896, 12.9989305969592],
						[77.6085000967637, 12.99868534154267],
						[77.60812548724955, 12.99807891403908],
						[77.60767070875204, 12.997441289595196],
						[77.60692602904219, 12.996321992277966],
						[77.60568197107845, 12.99447153602598],
						[77.60550353549718, 12.994244054333317],
						[77.60478122258166, 12.994587458704244],
						[77.6035594348782, 12.995019960055306],
						[77.60240897523776, 12.995358863447937],
						[77.6015349537896, 12.995546175705469],
						[77.60075464983622, 12.99571557512594],
						[77.60060304729912, 12.995737853867556],
						[77.60050049252618, 12.99574231406272],
						[77.60042915002046, 12.995737842234306],
						[77.60038901984116, 12.995733407875147],
						[77.60030875943974, 12.99571117120273],
						[77.60025971138855, 12.995702253707046],
						[77.6002151222326, 12.995684409075317],
						[77.60016152779244, 12.995653193909325],
						[77.60004559584942, 12.995613024721827],
						[77.59986723878143, 12.995559498772518],
						[77.59866332119849, 12.99518937851293],
						[77.59717401278752, 12.994707791275566],
						[77.59683949711183, 12.99460974180283],
						[77.59657195380353, 12.994547260365705],
						[77.5965318222526, 12.994475987312388],
						[77.5966343806322, 12.99443133951717],
						[77.59740588245455, 12.994203920014654],
						[77.59852509280212, 12.993816019668524],
						[77.59916272560669, 12.993593096641241],
						[77.60068776638907, 12.99294202879729],
						[77.60123175060883, 12.99273244059539],
						[77.60150374173509, 12.992656623450992],
						[77.60186936811742, 12.992562978325719],
						[77.60209685672235, 12.992540730086906],
						[77.60228858676984, 12.992531765887154],
						[77.60253382240212, 12.992527361688113],
						[77.60283702208311, 12.992549637770775],
						[77.60383587916516, 12.992772550310015],
						[77.60403652378777, 12.992500540227201],
						[77.6043085081507, 12.992184013839534],
						[77.60456274430695, 12.991845072226917],
						[77.60479905752418, 12.991573054837488],
						[77.6048926909261, 12.991430432253335],
						[77.60495957187986, 12.99126539236479],
						[77.60506658132338, 12.99106028426264],
						[77.605137920896, 12.990904256048955],
						[77.605200342985, 12.990734776078456],
						[77.60526276503944, 12.990529678211596],
						[77.6052805999058, 12.990333473328892],
						[77.6052939760537, 12.990181813720778],
						[77.60560171444332, 12.99018187943302],
						[77.60606095997247, 12.990172937502821],
						[77.60646224094235, 12.990186301285261],
						[77.60660054703717, 12.990213052576241],
						[77.60672984822016, 12.990248770831332],
						[77.60692157039092, 12.990288873255386],
						[77.60710437494778, 12.990360197425078],
						[77.60711775087933, 12.990520735125276],
						[77.60716233730635, 12.991550812871488],
						[77.60722029963513, 12.992942045339108],
						[77.60724259283056, 12.993352263283848],
						[77.60728717920821, 12.993481534607197],
						[77.60740319113175, 12.99359302820404],
						[77.60767516737366, 12.993584128299949],
						[77.60817453187987, 12.993535116598615],
						[77.60829946008423, 12.993535092946146],
						[77.60843321791023, 12.993521694998018],
						[77.6085000967637, 12.99350835510286],
						[77.60857143416366, 12.993490486303298],
						[77.6086293957679, 12.993463780345653],
						[77.60870519166693, 12.993436984101026],
						[77.60878990467022, 12.993396817789478],
						[77.60897725219031, 12.993258614643429],
						[77.60970854272642, 12.992732441730197],
						[77.60978433789973, 12.992678909063645],
						[77.60986459155707, 12.99262093371272],
						[77.61031490268549, 12.992282100963209],
						[77.61104627196299, 12.991804914798982],
						[77.61127811310982, 12.9916979152715],
						[77.61169283831532, 12.99151510899685],
						[77.61178200753196, 12.991492835992334],
						[77.61189792740808, 12.991448218987651],
						[77.61197817956015, 12.99144380072862],
						[77.6121119330199, 12.991412547110041],
						[77.61235723470406, 12.991363546625426],
						[77.61250436300607, 12.991318878335035],
						[77.61259353158043, 12.99130997286322],
						[77.61279870645363, 12.991211865422308],
						[77.61287449954833, 12.991176184175847],
						[77.61305283604058, 12.991082563695418],
						[77.61324900585599, 12.990971085866674],
						[77.61369493249441, 12.990730353686502],
						[77.6139134804866, 12.99059209469787],
						[77.61500595183223, 12.989994531181349],
						[77.61624553599674, 12.989298904608217],
						[77.61671374209297, 12.989013527761994],
						[77.61711953079056, 12.98873265187096],
						[77.61725327814513, 12.988625585723051],
						[77.61767689780268, 12.988291146323885],
						[77.61826555689275, 12.987756103515261],
						[77.61837701189812, 12.98768030649725],
						[77.61899678713102, 12.987350292853742],
						[77.61905920154223, 12.987301288339793],
						[77.6191439941743, 12.98727897386509],
						[77.61923761565572, 12.98727898961475],
						[77.61932677889898, 12.98727896350875],
						[77.61933123705928, 12.987448387814606],
						[77.61940702575752, 12.988251081714626],
						[77.61950073443498, 12.988888707834873],
						[77.6195230252003, 12.989544206778545],
						[77.61952748335283, 12.990110536456402],
						[77.61947389809596, 12.990605426230411],
						[77.61944714916535, 12.990774907383333],
						[77.6193669023354, 12.991292149260563],
						[77.61934015337938, 12.991501735963467],
						[77.61930448809478, 12.991675619323127],
						[77.61917965950974, 12.991974413271965],
						[77.61894328903672, 12.992491655296266],
						[77.61850629956615, 12.99331655546312],
						[77.61811834870085, 12.9941192363312],
						[77.61780627369075, 12.994667686448697],
						[77.6175342344889, 12.995122499826524],
						[77.61722207044328, 12.995425741570862],
						[77.61694120073734, 12.995599648801313],
						[77.61690999294899, 12.995621952185141],
						[77.61670928382901, 12.99572450631811],
						[77.61682965689383, 12.995782478067703],
						[77.61686086470444, 12.995920684400012],
						[77.6168564064463, 12.99611694582982],
						[77.6169367424824, 12.99646028891592],
						[77.61726219462977, 12.997093410058222],
						[77.61757881679387, 12.997682036450119],
						[77.61766352312469, 12.997900531392938],
						[77.61789097985044, 12.99833307034797],
						[77.6178998962846, 12.998355407173227],
						[77.61797568594642, 12.998373198892724],
						[77.61829676430537, 12.998457906764854],
						[77.61873375458927, 12.998654108156646],
						[77.61892991450917, 12.998787920722597],
						[77.61909941248912, 12.998921658242736],
						[77.61921532483387, 12.999077744762577],
						[77.61945160732088, 12.999421097683305],
						[77.62016508476196, 12.999960692144885],
						[77.62015616850722, 12.999978462576912],
						[77.62013387786727, 13.000000764748714],
						[77.62005808965841, 13.000081042784965],
						[77.6197191837449, 13.000361965724215],
						[77.61936244417652, 13.000647377905333],
						[77.61921086666898, 13.00076773955279],
						[77.61918411767587, 13.000785600730262],
						[77.61901461982347, 13.000901563597079],
						[77.61864450346089, 13.00113785666348],
						[77.61832351350932, 13.001329591235791],
						[77.61806485018911, 13.001467828604284],
						[77.61771256360288, 13.001619414481011],
						[77.61703928230156, 13.001873631975819],
						[77.61672711688377, 13.00196280183009],
						[77.61642841284404, 13.002020791532138],
						[77.61608503771586, 13.002083185872506],
						[77.61554995437648, 13.002158950015328],
						[77.61519765992244, 13.002181323205985],
						[77.61458232212729, 13.002176801246833],
						[77.61396698097047, 13.002118886950855],
						[77.6139402307317, 13.002109922613876],
						[77.61389110119168, 13.002109972476553],
						[77.61339176201761, 13.002016326484815],
						[77.61257123944345, 13.001824586076438],
						[77.61228589970058, 13.001717522694857],
						[77.61213422524777, 13.00167744403216],
						[77.61208518234066, 13.00165507219578],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000041",
			properties: {
				AREA_SQ_KM: 1.7227395881808232,
				ASS_CONST1: "Pulakeshi Nagar (SC)",
				ASS_CONST_: "159",
				LAT: 12.9953435378928,
				LON: 77.6118558337293,
				LOST_AREA_SQ_KM: 0.07495648934891644,
				OBJECTID: 66,
				POP_F: 14291.0,
				POP_M: 13858.0,
				POP_SC: 2474.0,
				POP_ST: 77.0,
				POP_TOTAL: 28149.0,
				RESERVATIO: "General",
				WARD_NAME: "Pulikeshinagar",
				WARD_NO: 78.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61367264057537, 13.042134815461695],
						[77.61356563930262, 13.042161573623204],
						[77.61332925704565, 13.042215130876437],
						[77.61291016686937, 13.042322075177097],
						[77.61249098771381, 13.042424700175687],
						[77.61226806594267, 13.042478215852222],
						[77.61199601336396, 13.042536175047896],
						[77.61197372110877, 13.042411284116643],
						[77.61173742293246, 13.041323254814543],
						[77.61171958908771, 13.041229641704513],
						[77.61171958908771, 13.041225155099024],
						[77.61167500446354, 13.040993322851717],
						[77.6116527121448, 13.040926414310483],
						[77.61156791387255, 13.040672278830755],
						[77.61150995377963, 13.040498300014612],
						[77.6114475351847, 13.040324451346528],
						[77.61138957502983, 13.040177306217485],
						[77.61137174113004, 13.040137187725781],
						[77.61131823941373, 13.039994485859733],
						[77.61128257158875, 13.039940909504962],
						[77.61125136223261, 13.039891862439536],
						[77.61099722858235, 13.039570883498154],
						[77.61095701474272, 13.039499486885479],
						[77.61085446938671, 13.039330087244903],
						[77.6107117974318, 13.039111555814882],
						[77.61065383689852, 13.039004532253967],
						[77.61050224767055, 13.038701362967458],
						[77.61046657958727, 13.038634414141463],
						[77.6104130774412, 13.038531920303651],
						[77.61033273675254, 13.03837135953295],
						[77.61031044416828, 13.038322312407802],
						[77.6102926100977, 13.038286594882637],
						[77.61026140046741, 13.03821528885781],
						[77.6102614004674, 13.038210802386038],
						[77.6102614004674, 13.038206402192735],
						[77.61025694194808, 13.038201873013733],
						[77.61038186784424, 13.037778325564863],
						[77.61041753595433, 13.037617758740955],
						[77.61053791574254, 13.037109441338682],
						[77.61060033484135, 13.036833001922792],
						[77.61063154437778, 13.036716998816285],
						[77.61081434291764, 13.035967879081053],
						[77.61097484870815, 13.035423887636465],
						[77.61113098320656, 13.03501367393252],
						[77.6113628241791, 13.034514195932681],
						[77.6118042998251, 13.033774033266331],
						[77.61207626544616, 13.033381577476773],
						[77.6126559495405, 13.03265481585755],
						[77.6139134804866, 13.031107504695756],
						[77.61488548939975, 13.029907995438867],
						[77.61571937002674, 13.02886453648472],
						[77.61561682900572, 13.02876641647009],
						[77.61588878542216, 13.028463256001668],
						[77.61610287086948, 13.028128834837823],
						[77.61613407888152, 13.028070811772116],
						[77.6161429954548, 13.028017321473202],
						[77.6161429954548, 13.027937088137476],
						[77.61612070402028, 13.027887959487572],
						[77.61603599652886, 13.027767579655814],
						[77.61584866074627, 13.027602652104882],
						[77.61550537126827, 13.027326160468577],
						[77.6149033227433, 13.026813317125702],
						[77.61464473898539, 13.026603803987541],
						[77.61405169001797, 13.026242630527253],
						[77.61365926542184, 13.025912614576978],
						[77.61353888896855, 13.025774384621307],
						[77.61328913145798, 13.02535523216164],
						[77.61290570845485, 13.02448120837425],
						[77.61255340573071, 13.02365631324223],
						[77.61255340573071, 13.023651827289665],
						[77.61257123944345, 13.023442279018862],
						[77.61266932481315, 13.022434483296802],
						[77.61267378323703, 13.022389925466339],
						[77.61270945062161, 13.022077804717727],
						[77.61270053377652, 13.02142226340194],
						[77.61270053377652, 13.021368778039655],
						[77.61273620115264, 13.020472550969831],
						[77.61275403483646, 13.020392234409355],
						[77.61337838683072, 13.020276285219447],
						[77.61337838683072, 13.020271885649638],
						[77.61348984667292, 13.020240681974943],
						[77.61348984667292, 13.02025405321803],
						[77.61350322184657, 13.020236150782633],
						[77.61373951631924, 13.020213897840872],
						[77.61382868391597, 13.020209397055998],
						[77.61388664281598, 13.020271887171159],
						[77.61391793886122, 13.02036992773618],
						[77.61394914747865, 13.020450193312438],
						[77.61395360585188, 13.020450235698128],
						[77.61412748226965, 13.020722247876861],
						[77.61425231645535, 13.020789083570609],
						[77.61448423842324, 13.020851505845766],
						[77.61468932243427, 13.020838186020589],
						[77.61494344775599, 13.020726642794267],
						[77.61534924283252, 13.020561677997852],
						[77.6156524954584, 13.020490372907071],
						[77.61576841135162, 13.020463609827441],
						[77.61617866174083, 13.020445770491401],
						[77.61666470117976, 13.020396731164203],
						[77.61702144929625, 13.020383299383031],
						[77.61728002759698, 13.0203833384456],
						[77.61740940394105, 13.020418990437696],
						[77.61747627746603, 13.020476992889435],
						[77.61751640156197, 13.0205527713652],
						[77.61756544210424, 13.020650891528941],
						[77.61759219148192, 13.020704372447662],
						[77.61763231553654, 13.020793608841554],
						[77.61768581425379, 13.020905056784178],
						[77.61771256360288, 13.021003138256567],
						[77.6177705205041, 13.021061142708094],
						[77.61781519013161, 13.021114624796597],
						[77.6179177291508, 13.021136906216118],
						[77.61819859642077, 13.021177012159097],
						[77.6185597978681, 13.021230564910745],
						[77.61872038003683, 13.021261768220846],
						[77.6190324525131, 13.021293024997158],
						[77.61916628501027, 13.021582865917546],
						[77.61921532483387, 13.021703244515566],
						[77.61927328096151, 13.021930681148152],
						[77.61930448809478, 13.022135779618605],
						[77.61937581865267, 13.022100050898068],
						[77.61956314856678, 13.022028761717822],
						[77.61984401173167, 13.021966292916561],
						[77.62015171037959, 13.02195290985614],
						[77.62031674839874, 13.021912810408827],
						[77.62063327455812, 13.021872677240577],
						[77.62096771962405, 13.02183703027017],
						[77.6212932475315, 13.021729953558506],
						[77.6215385292157, 13.021667579675459],
						[77.621748058442, 13.021622979843098],
						[77.62206020993673, 13.02159177822302],
						[77.62240802487807, 13.021529339353313],
						[77.62267559427349, 13.021484774027924],
						[77.62302340731273, 13.021404476230519],
						[77.62328197182592, 13.02134204987677],
						[77.6232775138221, 13.021373237211447],
						[77.62309027750241, 13.022233902859455],
						[77.62277367084124, 13.02300526216047],
						[77.62228319973504, 13.023977368204836],
						[77.6218506812916, 13.024775586724672],
						[77.62151178077575, 13.02525264287718],
						[77.62106133958862, 13.025658411962068],
						[77.6206154421221, 13.025948277177292],
						[77.61999567580104, 13.026220270926943],
						[77.62004025713127, 13.026358557322702],
						[77.62034349712269, 13.02730388337962],
						[77.62062435834045, 13.028217943729468],
						[77.62075373084117, 13.029537878454882],
						[77.62094542914481, 13.030175547400267],
						[77.62112829835772, 13.030523339829363],
						[77.62131999602337, 13.030920218758252],
						[77.62167672938747, 13.031762995314944],
						[77.62184613581813, 13.032119721134997],
						[77.62211816459948, 13.032708305514303],
						[77.62235452840515, 13.033319159113043],
						[77.62280041898124, 13.03458557030361],
						[77.62244814721608, 13.034697053262962],
						[77.62220732556082, 13.034777327650048],
						[77.62205129383211, 13.034839775686466],
						[77.6220156294066, 13.034755055552885],
						[77.62163660677443, 13.034870941436635],
						[77.6210836300449, 13.035067131983654],
						[77.62075818894496, 13.035165238392045],
						[77.62088301577947, 13.035294551196232],
						[77.62096771962405, 13.035361447469775],
						[77.62083397668242, 13.035472940633053],
						[77.62060652590303, 13.035178630722827],
						[77.62057086101969, 13.035129543569404],
						[77.62040145266946, 13.034915512203021],
						[77.62026316351756, 13.034737148421632],
						[77.62006700592093, 13.03462570683348],
						[77.61991097122495, 13.034518702520838],
						[77.61981726288903, 13.034483036549291],
						[77.61962110451533, 13.034482980003542],
						[77.61952748335283, 13.034483037270654],
						[77.61947389809596, 13.034523160389332],
						[77.61962556266398, 13.034799577041777],
						[77.61978159775559, 13.035183104429631],
						[77.6198217210299, 13.035664663435023],
						[77.61982617917062, 13.037622219031157],
						[77.6212174601033, 13.037590972120348],
						[77.62136903490867, 13.038732535711228],
						[77.62092313866115, 13.03879049112711],
						[77.62057086101969, 13.038843988708365],
						[77.62057086101969, 13.038933203773714],
						[77.62054857046185, 13.039133854784351],
						[77.61991988749912, 13.039165042950195],
						[77.62014725225178, 13.040137193118992],
						[77.62013387786727, 13.040146038877435],
						[77.6200045920685, 13.040235229209376],
						[77.61994217818145, 13.040279845615045],
						[77.61937136049411, 13.040574136109896],
						[77.61934015337938, 13.04059195752745],
						[77.6192331574917, 13.040632092863543],
						[77.61877387823705, 13.04081493734198],
						[77.61850184137317, 13.040935288568253],
						[77.61839038649136, 13.040975467096375],
						[77.61827447329723, 13.04099774250707],
						[77.61808268302919, 13.040988831973117],
						[77.61751640156197, 13.040939759687152],
						[77.61745844452993, 13.04093532321021],
						[77.61728894407952, 13.040930852856173],
						[77.61713736378034, 13.040935357788065],
						[77.61699915803561, 13.040957591975197],
						[77.6167583247228, 13.041011111224844],
						[77.61637482611219, 13.041100281243644],
						[77.61616082859922, 13.041153800806248],
						[77.61603153823808, 13.041185006562129],
						[77.61576841135162, 13.04126083092214],
						[77.6157594947487, 13.041265318590014],
						[77.61532249292205, 13.041407987188089],
						[77.61485873937913, 13.041564031286027],
						[77.61462244725432, 13.041635368611022],
						[77.61448423842324, 13.041697808245452],
						[77.6143058167783, 13.041787038298224],
						[77.61417652428766, 13.041867250849892],
						[77.61397143934298, 13.041996608699703],
						[77.61373951631924, 13.042103617765111],
						[77.61367264057537, 13.042134815461695],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000042",
			properties: {
				AREA_SQ_KM: 2.0763235408531626,
				ASS_CONST1: "Sarvagna Nagar",
				ASS_CONST_: "160",
				LAT: 13.0315090727742,
				LON: 77.6166958347712,
				LOST_AREA_SQ_KM: 0.09341971405567844,
				OBJECTID: 67,
				POP_F: 17048.0,
				POP_M: 18216.0,
				POP_SC: 4331.0,
				POP_ST: 157.0,
				POP_TOTAL: 35264.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Nagavara",
				WARD_NO: 23.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.62136903490867, 13.038732535711228],
						[77.6212174601033, 13.037590972120348],
						[77.61982617917062, 13.037622219031157],
						[77.6198217210299, 13.035664663435023],
						[77.61978159775559, 13.035183104429631],
						[77.61962556266398, 13.034799577041777],
						[77.61947389809596, 13.034523160389332],
						[77.61952748335283, 13.034483037270654],
						[77.61962110451533, 13.034482980003542],
						[77.61981726288903, 13.034483036549291],
						[77.61991097122495, 13.034518702520838],
						[77.62006700592093, 13.03462570683348],
						[77.62026316351756, 13.034737148421632],
						[77.62040145266946, 13.034915512203021],
						[77.62057086101969, 13.035129543569404],
						[77.62060652590303, 13.035178630722827],
						[77.62083397668242, 13.035472940633053],
						[77.62096771962405, 13.035361447469775],
						[77.62088301577947, 13.035294551196232],
						[77.62075818894496, 13.035165238392045],
						[77.6210836300449, 13.035067131983654],
						[77.62163660677443, 13.034870941436635],
						[77.6220156294066, 13.034755055552885],
						[77.62205129383211, 13.034839775686466],
						[77.62220732556082, 13.034777327650048],
						[77.62244814721608, 13.034697053262962],
						[77.62280041898124, 13.03458557030361],
						[77.62235452840515, 13.033319159113043],
						[77.62211816459948, 13.032708305514303],
						[77.62184613581813, 13.032119721134997],
						[77.62167672938747, 13.031762995314944],
						[77.62131999602337, 13.030920218758252],
						[77.62112829835772, 13.030523339829363],
						[77.62094542914481, 13.030175547400267],
						[77.62075373084117, 13.029537878454882],
						[77.62062435834045, 13.028217943729468],
						[77.62034349712269, 13.02730388337962],
						[77.62004025713127, 13.026358557322702],
						[77.61999567580104, 13.026220270926943],
						[77.6206154421221, 13.025948277177292],
						[77.62106133958862, 13.025658411962068],
						[77.62151178077575, 13.02525264287718],
						[77.6218506812916, 13.024775586724672],
						[77.62228319973504, 13.023977368204836],
						[77.62277367084124, 13.02300526216047],
						[77.62309027750241, 13.022233902859455],
						[77.6232775138221, 13.021373237211447],
						[77.62328197182592, 13.02134204987677],
						[77.62334001327123, 13.020967503763675],
						[77.62341134127144, 13.020530449832815],
						[77.6234559212486, 13.019710026023692],
						[77.62344254725731, 13.01917935062127],
						[77.62332663926615, 13.018095855864116],
						[77.62311702556715, 13.017132686486853],
						[77.62277812886502, 13.01624973845119],
						[77.62245260525275, 13.015527377495461],
						[77.62214045484644, 13.014822832510026],
						[77.6219799649698, 13.014475050752717],
						[77.62263101398716, 13.01438140284175],
						[77.62315268964359, 13.0142698782386],
						[77.6234559212486, 13.014180701888277],
						[77.62380373187577, 13.01410488564045],
						[77.624053466061, 13.01398449300477],
						[77.62424961670895, 13.013895336052807],
						[77.62437898504184, 13.013774928079485],
						[77.62463754643976, 13.01360104454259],
						[77.62487390495298, 13.013462840430426],
						[77.62519496363207, 13.013315687302216],
						[77.62501210102819, 13.012869785866712],
						[77.6256453008614, 13.012624478788924],
						[77.62583262033871, 13.012566554054567],
						[77.62614921839148, 13.01307487696011],
						[77.62627849713029, 13.013280024715197],
						[77.62682699046488, 13.014140604752406],
						[77.6269250634135, 13.014269892151125],
						[77.62705442866944, 13.014466075070585],
						[77.62732198701384, 13.014840664965925],
						[77.62748246926981, 13.015063622749512],
						[77.62768761700536, 13.015603171619613],
						[77.62776785790108, 13.015844008060308],
						[77.62786593002923, 13.016726840113003],
						[77.62811565519573, 13.01810473996199],
						[77.62832525958015, 13.019072327384665],
						[77.62846345141472, 13.020240600191768],
						[77.62859281490103, 13.020713291584292],
						[77.62859727269418, 13.020980858424274],
						[77.62894060964418, 13.02104777146826],
						[77.62939101928235, 13.021025459019897],
						[77.62988145939609, 13.02089171293603],
						[77.62984579745317, 13.021355428123613],
						[77.62988591713817, 13.021841428286324],
						[77.62991712132768, 13.021961812903953],
						[77.62993941002921, 13.022037683564866],
						[77.62996178613295, 13.022059983742565],
						[77.63007322955204, 13.022166977528295],
						[77.63019358832057, 13.022251711158997],
						[77.63042993515704, 13.022358679733207],
						[77.63055929638081, 13.022439015673397],
						[77.63068411233938, 13.022568308014316],
						[77.63073760485065, 13.022688731449502],
						[77.63073760485065, 13.022697617504685],
						[77.6309338312467, 13.023049912103936],
						[77.63104973139814, 13.023406622756385],
						[77.63117909190868, 13.024021940137022],
						[77.63120138035998, 13.024111184813856],
						[77.63135294171171, 13.024699708803068],
						[77.63135739939544, 13.024717609613726],
						[77.63130836486478, 13.024739836672518],
						[77.6314689288365, 13.025283848688002],
						[77.63154916703364, 13.025488990826679],
						[77.6315536247096, 13.025511291780578],
						[77.6318434606717, 13.026255997100327],
						[77.63196390495003, 13.026585933644215],
						[77.63197727792797, 13.026612719101417],
						[77.63230723161851, 13.027219165928045],
						[77.63247225180292, 13.027446512852677],
						[77.63260598090599, 13.027598155732143],
						[77.63265501487058, 13.027633819684679],
						[77.63273533962551, 13.027758647438612],
						[77.63272642436719, 13.02776313588734],
						[77.63272642436719, 13.027772108652963],
						[77.63276654302409, 13.027830034109257],
						[77.6329537632336, 13.028088648469854],
						[77.63301179968715, 13.028168919922912],
						[77.63308757916795, 13.02825366950578],
						[77.63319456187742, 13.028382974066881],
						[77.63345764800917, 13.028686138819474],
						[77.63347993600865, 13.028717409223692],
						[77.6336137513165, 13.028646030843738],
						[77.6336672424335, 13.02862824520808],
						[77.63380551510494, 13.028583654796599],
						[77.63427373552342, 13.028472125640986],
						[77.63438517466732, 13.028445396341416],
						[77.63455464935745, 13.028405241232061],
						[77.63486230776009, 13.028329463383887],
						[77.63513876258912, 13.028213530334876],
						[77.6352100831049, 13.028186767060873],
						[77.63561143496706, 13.028017315172495],
						[77.63583439789298, 13.027923653693339],
						[77.63613759529747, 13.027843431410034],
						[77.63618662772102, 13.027834489559682],
						[77.63635164220848, 13.027812236423959],
						[77.6365968910466, 13.027816622935381],
						[77.63703389762784, 13.027883559824836],
						[77.637430698131, 13.02798609165146],
						[77.63750656203233, 13.028235808638271],
						[77.63756450873353, 13.028703983580767],
						[77.63763137027468, 13.029225737498377],
						[77.63776518063816, 13.029667198571712],
						[77.63789890344175, 13.029992697565739],
						[77.63789890344175, 13.03000158436111],
						[77.63791236311381, 13.030046234930202],
						[77.6379613947627, 13.030135392753133],
						[77.63805500057842, 13.030255772857101],
						[77.63832699014371, 13.030523306592732],
						[77.63851874588737, 13.03072848039429],
						[77.6388263934339, 13.031009379955313],
						[77.63930805243139, 13.031455257361397],
						[77.63945068812347, 13.03166039801687],
						[77.6394596028482, 13.031673769365003],
						[77.63955775217282, 13.031852178650645],
						[77.63961569781574, 13.031950222925309],
						[77.63961569781574, 13.031954709560043],
						[77.63962015517164, 13.031963638571982],
						[77.63969593019503, 13.03219550123075],
						[77.63969593019503, 13.032199987875847],
						[77.63981190870736, 13.032503157213773],
						[77.63986093952695, 13.032601290657857],
						[77.63987431156495, 13.03263247825497],
						[77.64006606466667, 13.032864317729896],
						[77.64011063803903, 13.032917887858558],
						[77.64014183938916, 13.032953557781356],
						[77.64026227477136, 13.033149781190016],
						[77.64040945397453, 13.033328137803299],
						[77.64057437485957, 13.03347525169711],
						[77.64067698052379, 13.033591151228455],
						[77.64080624255166, 13.033836464588157],
						[77.640873189561, 13.033992495192788],
						[77.6408999333943, 13.034054957590914],
						[77.64100245136297, 13.034264557444173],
						[77.64101582326504, 13.034291258841154],
						[77.64103810976492, 13.034326931503717],
						[77.64104702436364, 13.03434030322356],
						[77.64107831285158, 13.034362603928642],
						[77.64110505663602, 13.034362597237957],
						[77.64114517230075, 13.03434476963602],
						[77.64127897847753, 13.034282357274865],
						[77.64169368047484, 13.034148564654712],
						[77.64171150956747, 13.034144159670097],
						[77.64171596684017, 13.03414411539751],
						[77.64201469110611, 13.034050469043756],
						[77.64205480644584, 13.034037127980604],
						[77.64217523977615, 13.033996936376328],
						[77.64218415428456, 13.03399693409167],
						[77.64230450007905, 13.034001433103507],
						[77.64237590344153, 13.034028166915656],
						[77.64254982331434, 13.034050516987808],
						[77.64309823722924, 13.034059400026164],
						[77.64319638337312, 13.0340638654475],
						[77.64324095550272, 13.034063853820484],
						[77.64325878434961, 13.034063849167119],
						[77.64326769877201, 13.034068333604154],
						[77.64326769877201, 13.034077220849248],
						[77.64326769877201, 13.034081707614018],
						[77.64324986992652, 13.034153069102683],
						[77.64324986992652, 13.034157469586082],
						[77.64321421222708, 13.03468363975913],
						[77.64317409730168, 13.035120638050874],
						[77.64315172382942, 13.035401625921596],
						[77.64320529780045, 13.035753831814997],
						[77.64323649829056, 13.035927731675367],
						[77.64437797580496, 13.035851958560132],
						[77.64497103962542, 13.035847458357788],
						[77.6449576681945, 13.035914376432476],
						[77.64489972530879, 13.035958958249585],
						[77.64467678038207, 13.036066006788163],
						[77.64448949231813, 13.036173045771074],
						[77.64444492068431, 13.036213180257612],
						[77.64440926336451, 13.036271087302236],
						[77.64437797580496, 13.036346893311736],
						[77.64436460430339, 13.036445046998445],
						[77.64436014713584, 13.0364941017208],
						[77.64434677563214, 13.03651192323534],
						[77.64432003261997, 13.036560940597377],
						[77.64429774677163, 13.0366188874241],
						[77.64427100374778, 13.036672391684613],
						[77.64425763223345, 13.036708074431132],
						[77.644208603334, 13.03688199680648],
						[77.64417731571851, 13.037002413081591],
						[77.64417731571851, 13.037073771872732],
						[77.64417731571851, 13.03712278253042],
						[77.64417731571851, 13.037162905707138],
						[77.64418177289332, 13.037207557795904],
						[77.6441817728933, 13.037265456017781],
						[77.64418177289332, 13.037336814967924],
						[77.64415057266596, 13.037537482441346],
						[77.6441461154899, 13.037550901155736],
						[77.6441416583137, 13.037590982463996],
						[77.64405697193185, 13.038059239670906],
						[77.6440213144888, 13.038179619138713],
						[77.64399902858115, 13.038228678986744],
						[77.64390088313908, 13.038407055058846],
						[77.64386522564645, 13.038518460877533],
						[77.6438563112715, 13.038652294813053],
						[77.64386522564645, 13.038790525106837],
						[77.64388305439418, 13.039031263086544],
						[77.64379836781721, 13.039000091156453],
						[77.64375370851303, 13.038986723440145],
						[77.64362444987884, 13.038977824745922],
						[77.64341041683004, 13.038968899555693],
						[77.64308932279339, 13.03897328450718],
						[77.64303137894318, 13.038973341862786],
						[77.64295560617083, 13.038982204884324],
						[77.64264788254091, 13.039017951345548],
						[77.64233578821656, 13.039040198638755],
						[77.642166325267, 13.039089246478763],
						[77.64211729545411, 13.03911605048257],
						[77.6420191483668, 13.039173968608052],
						[77.64188980033796, 13.039338933615754],
						[77.64187642854, 13.039361242102087],
						[77.64186305674043, 13.03937017603996],
						[77.64169813774826, 13.0394861424613],
						[77.64166684943305, 13.039508450966103],
						[77.64152421655605, 13.039557496026285],
						[77.64147509906505, 13.039610958491073],
						[77.64147064178279, 13.039887468116879],
						[77.64147509906505, 13.040190639250413],
						[77.64147064178277, 13.040253069612497],
						[77.6414394408019, 13.04065444596876],
						[77.64142606895028, 13.040779265075143],
						[77.6414037825274, 13.040988821615825],
						[77.64139486795702, 13.04107804598903],
						[77.64140823981234, 13.041323232024782],
						[77.64142606895028, 13.041448087755073],
						[77.64143052623433, 13.041457017620917],
						[77.64143498351821, 13.04148380927565],
						[77.64149747287145, 13.041630921698953],
						[77.64158216118419, 13.041742430763799],
						[77.64172488138507, 13.042005497869367],
						[77.64177836863958, 13.042188332744091],
						[77.64171596684018, 13.042232958705185],
						[77.64166684943305, 13.042264159365963],
						[77.6415955330172, 13.042308701041252],
						[77.64152421655605, 13.042362216798155],
						[77.64148410102675, 13.04241128152296],
						[77.64144389808541, 13.042478203976692],
						[77.64138149610011, 13.042553980392496],
						[77.641310179503, 13.042638732948914],
						[77.64119420253819, 13.04267887959048],
						[77.64106494095697, 13.042736852183182],
						[77.64086427494848, 13.042817099159295],
						[77.64077504138615, 13.042861640317064],
						[77.64060111876405, 13.042924105357198],
						[77.64033804936145, 13.043026628310137],
						[77.6399590885008, 13.043115801320114],
						[77.6397851646149, 13.043178265553182],
						[77.63953992273821, 13.043249632873032],
						[77.63933033927022, 13.043316505130859],
						[77.63905835213475, 13.043347666585214],
						[77.63880856388319, 13.04334771134243],
						[77.6384786292844, 13.043316453909982],
						[77.63810403216505, 13.043254050751598],
						[77.63783204205986, 13.043160436468956],
						[77.63762245540481, 13.043053434318592],
						[77.63743961301607, 13.042910703072875],
						[77.63739949602767, 13.0428750309723],
						[77.63715870639535, 13.0427813670311],
						[77.6369848655741, 13.042701111346807],
						[77.63680647961512, 13.042603033802132],
						[77.63668158305485, 13.0425094323199],
						[77.63658797609465, 13.04236228688025],
						[77.63657014618863, 13.042219569935789],
						[77.63655231627978, 13.042063478538708],
						[77.63655677375725, 13.041943062800872],
						[77.63658351861841, 13.041849434942176],
						[77.63665483821704, 13.041671063323871],
						[77.63670387041483, 13.041541751033778],
						[77.63672170029963, 13.041434750684955],
						[77.63673961758299, 13.041305406171386],
						[77.63673961758299, 13.041198409743615],
						[77.63671278535759, 13.041095815463255],
						[77.63666821063674, 13.041033439095452],
						[77.6366013485223, 13.040984398812327],
						[77.63653002888971, 13.040953177781041],
						[77.63643187691937, 13.040908583621869],
						[77.63631152483156, 13.04087732967124],
						[77.63624011761321, 13.040805980956224],
						[77.63614205279141, 13.040730241438661],
						[77.63602615789165, 13.0407034303144],
						[77.63592800548108, 13.040716820907011],
						[77.63585222791578, 13.04074362865548],
						[77.6357853653157, 13.040797097168976],
						[77.63574961784393, 13.040872860191554],
						[77.6357585328624, 13.040935330094971],
						[77.63572287278426, 13.041015584316082],
						[77.63562034999651, 13.041113670534928],
						[77.63549099459802, 13.0411672338347],
						[77.6353706415023, 13.04118947724214],
						[77.63530377861564, 13.041229657433417],
						[77.63521899816618, 13.04126086314281],
						[77.63514322012269, 13.041323307141626],
						[77.63500940663619, 13.04148382387228],
						[77.63496928877998, 13.041604159405795],
						[77.63492917090946, 13.041787053341789],
						[77.63479544457093, 13.041902963890129],
						[77.63473295148887, 13.041983219349133],
						[77.63460814002659, 13.042130364143153],
						[77.63453236157113, 13.042174946549752],
						[77.63433614145761, 13.042273089670312],
						[77.63418895429679, 13.04232208229127],
						[77.63402848161437, 13.042331001031267],
						[77.6338367182064, 13.042353339148553],
						[77.63373410629397, 13.042353311515928],
						[77.63355580257767, 13.042353345703534],
						[77.63342644480248, 13.042339948498972],
						[77.63327042863907, 13.04233993506311],
						[77.63314998576085, 13.041786982860224],
						[77.63281557691863, 13.041956466565352],
						[77.63249899763626, 13.041992115307195],
						[77.63229831632623, 13.042224044673896],
						[77.63209317700353, 13.042513830362637],
						[77.63205305810628, 13.042545030547865],
						[77.63200402387909, 13.042598580740364],
						[77.6319549896305, 13.042656531577668],
						[77.63186574899127, 13.04275465223689],
						[77.63183454534263, 13.042785850769286],
						[77.63183454534263, 13.042790337707547],
						[77.63182563001286, 13.04284832439273],
						[77.6318211723477, 13.04284828205975],
						[77.6318211723477, 13.04285276900019],
						[77.63183008767783, 13.042892890989476],
						[77.6318211723477, 13.042901780206776],
						[77.63180334168533, 13.042919644928189],
						[77.63177659568645, 13.04292405043752],
						[77.631758765017, 13.042928540614009],
						[77.63173201900752, 13.042933032404228],
						[77.63169189998136, 13.042932996525787],
						[77.63167406929847, 13.042932999752402],
						[77.63166069628446, 13.042933045315072],
						[77.63144218275805, 13.042977561594554],
						[77.63137085985107, 13.042995435871228],
						[77.63135294171171, 13.042999921793673],
						[77.63132619560567, 13.043008814177675],
						[77.63130836486478, 13.042995442839953],
						[77.63130836486478, 13.042990955901105],
						[77.63118354959929, 13.042839371610917],
						[77.63107201987509, 13.042652101281169],
						[77.6310408160061, 13.042558442397672],
						[77.63100961212847, 13.042540629743499],
						[77.63096949284447, 13.042545080696993],
						[77.63090262733935, 13.042554023335532],
						[77.63079109733644, 13.042589632310595],
						[77.63057266952585, 13.042656586490942],
						[77.63045222375688, 13.042643185910087],
						[77.630363069331, 13.04263871468605],
						[77.63032294981628, 13.042629791054171],
						[77.63031403436662, 13.042625305708226],
						[77.63019804605025, 13.042589685401248],
						[77.6300776872865, 13.042513817348587],
						[77.63001527898788, 13.042464817473254],
						[77.62997961708741, 13.0424068391504],
						[77.62993941002921, 13.042366675582171],
						[77.62987700165384, 13.042322076383758],
						[77.62974326930427, 13.042219591493815],
						[77.62967640306984, 13.042219559988489],
						[77.62960053388248, 13.04223738705618],
						[77.6295024632672, 13.042286414656552],
						[77.62944451240887, 13.042406837239344],
						[77.6293999348052, 13.042487091256037],
						[77.62936864306504, 13.04252277183645],
						[77.62932852319547, 13.042562945053746],
						[77.62929731884257, 13.042576281652131],
						[77.62924382564603, 13.042576290796537],
						[77.62919033242406, 13.042571813033577],
						[77.62917695911457, 13.042571858457727],
						[77.6290030185393, 13.042473733107439],
						[77.62891832074467, 13.042384570651622],
						[77.62885591180255, 13.042362233122116],
						[77.62876221091683, 13.042322078570752],
						[77.62862847724135, 13.042286465028367],
						[77.62858389931418, 13.042290873140828],
						[77.62855269475465, 13.042317670224296],
						[77.62854823695972, 13.042388986435737],
						[77.62851703239028, 13.042522778380842],
						[77.62854377916462, 13.042710015141697],
						[77.62855269475465, 13.042794746885555],
						[77.62855269475465, 13.042843843868948],
						[77.62855715254939, 13.042870548786732],
						[77.62853932136933, 13.042928536283087],
						[77.62849920120387, 13.042999858776623],
						[77.6284500780188, 13.043044515995541],
						[77.6284055000209, 13.04306678531531],
						[77.6282494768891, 13.0431470144428],
						[77.62816023330853, 13.04317826062077],
						[77.62811565519573, 13.043209503675364],
						[77.62810673957105, 13.043263002841883],
						[77.62811119738349, 13.04333880495467],
						[77.62822273004477, 13.043521589518528],
						[77.62824056127502, 13.043539447989362],
						[77.62828959714366, 13.043597381639124],
						[77.6283564642028, 13.043673260105239],
						[77.6284901982018, 13.04378023418994],
						[77.62864630840727, 13.043878364492828],
						[77.62869980188808, 13.043927366872136],
						[77.628704259677, 13.043985394351601],
						[77.628704259677, 13.04404778010362],
						[77.628704259677, 13.044056667703044],
						[77.62869534409897, 13.044065643050939],
						[77.62867751294083, 13.044101282662242],
						[77.62865522398917, 13.044128078546898],
						[77.62853486357388, 13.044244025338564],
						[77.62851257459393, 13.044261847321795],
						[77.6284901982018, 13.04427077747708],
						[77.62844116242063, 13.0442841168399],
						[77.62836537980769, 13.044293059851698],
						[77.62826285030887, 13.044315295410005],
						[77.62818698016773, 13.044346582582097],
						[77.62814685987655, 13.044364407379424],
						[77.62813794425433, 13.044368809469033],
						[77.62804878799341, 13.044453558030565],
						[77.62798192075132, 13.044609619696368],
						[77.62796854729812, 13.044676537861529],
						[77.62797300511603, 13.044689868594855],
						[77.62797746293376, 13.044823656957957],
						[77.62792396910935, 13.044984160697398],
						[77.62789267695842, 13.045059965281542],
						[77.6277990626784, 13.04526512954881],
						[77.62760737605237, 13.045434585915558],
						[77.62754933680887, 13.04551928242779],
						[77.62748246926981, 13.045492500264634],
						[77.62741560169098, 13.04542119346623],
						[77.62680915901049, 13.044770150934982],
						[77.6267824118236, 13.04473891899343],
						[77.62608680791267, 13.043909539979852],
						[77.62581033082324, 13.043530521627488],
						[77.62541348929848, 13.042995475160305],
						[77.62479366199271, 13.041755772066098],
						[77.6247847461047, 13.041733511983312],
						[77.62425407467425, 13.040324436636949],
						[77.62396867712782, 13.039597627006668],
						[77.62397759308051, 13.039535156815],
						[77.62384831169747, 13.039321152574233],
						[77.62369674023162, 13.039049127336678],
						[77.62364315695983, 13.03890202003269],
						[77.62363869897034, 13.038781613829997],
						[77.62359411906559, 13.03863442344833],
						[77.62354062315657, 13.038411479124983],
						[77.62348266922642, 13.038349063283341],
						[77.62311256755682, 13.038357960780914],
						[77.62248826953977, 13.03834458852285],
						[77.6225105597134, 13.038522972477326],
						[77.62232778015915, 13.038554191045153],
						[77.62203791967387, 13.038563074321846],
						[77.62183721969656, 13.038607664525214],
						[77.62182384551288, 13.03924533252818],
						[77.62171239392038, 13.03924530571993],
						[77.62159648414709, 13.039263183025394],
						[77.62144482223479, 13.039303321975213],
						[77.62136903490867, 13.038732535711228],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000043",
			properties: {
				AREA_SQ_KM: 4.652138852147196,
				ASS_CONST1: "Sarvagna Nagar",
				ASS_CONST_: "160",
				LAT: 13.0320201234578,
				LON: 77.6301273655594,
				LOST_AREA_SQ_KM: 0.18713874830471863,
				OBJECTID: 68,
				POP_F: 15650.0,
				POP_M: 16506.0,
				POP_SC: 4584.0,
				POP_ST: 188.0,
				POP_TOTAL: 32156.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "HBR Layout",
				WARD_NO: 24.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.66224114042247, 13.013279961178231],
						[77.66223222750844, 13.013538613846258],
						[77.66222777105114, 13.013743732153262],
						[77.66218766292764, 13.014595406248047],
						[77.66216092417064, 13.014871927849402],
						[77.66209844633121, 13.015442658686034],
						[77.66207170755291, 13.015674491415133],
						[77.66204496876824, 13.01586629267598],
						[77.6620093170454, 13.015982175182195],
						[77.66200040411293, 13.016017896894212],
						[77.66199149117973, 13.016035673267574],
						[77.66196020852625, 13.016098102080159],
						[77.66191118736734, 13.016191774083428],
						[77.66187553560192, 13.016249765710448],
						[77.66186662265879, 13.016263142032924],
						[77.6618041446552, 13.016343399346358],
						[77.66173284104488, 13.016401318958962],
						[77.66170164570109, 13.016423633734137],
						[77.66169718922269, 13.016428078722047],
						[77.6616569935272, 13.016450392852878],
						[77.66161242871753, 13.016472669807747],
						[77.66152329904497, 13.016521796309032],
						[77.66120662598765, 13.016655507658687],
						[77.66060464761078, 13.016891822318955],
						[77.66020338515105, 13.017070217111167],
						[77.66005623192018, 13.01713269093026],
						[77.66004286228807, 13.017137139349943],
						[77.65946761706375, 13.01739569894259],
						[77.65821907222677, 13.017935281495124],
						[77.6575011176917, 13.018238489209036],
						[77.65677878926853, 13.01855511580726],
						[77.65600288835643, 13.018902900545973],
						[77.65576659535718, 13.018992062716693],
						[77.65551684430096, 13.01909015481284],
						[77.65477667607585, 13.019317570103915],
						[77.653902624613, 13.019620858012543],
						[77.65354144938827, 13.019754575606262],
						[77.65186934936102, 13.020485920130826],
						[77.64978246643537, 13.021404463739064],
						[77.64732106571054, 13.022456825582541],
						[77.64541701526146, 13.023299561054035],
						[77.64355304731055, 13.02410667525825],
						[77.6418452276719, 13.024869172315032],
						[77.64036479332222, 13.02552025473422],
						[77.6399233423573, 13.025694085157493],
						[77.63985202483407, 13.02487809991231],
						[77.64032467737869, 13.024699731661258],
						[77.64008835135506, 13.021810219205157],
						[77.63954883745588, 13.021979675999132],
						[77.63954438009713, 13.0219573753803],
						[77.63945514548593, 13.019674345992398],
						[77.63935262610462, 13.01760527849131],
						[77.6393481687381, 13.015647765460885],
						[77.63932142453521, 13.01459546281135],
						[77.63936154083714, 13.014024654952607],
						[77.63935708347097, 13.013351290824167],
						[77.63954883745588, 13.011487390252624],
						[77.639927799701, 13.01144729120775],
						[77.640213244127, 13.011447296053849],
						[77.64046294185567, 13.011505278880005],
						[77.64080178524283, 13.01155434772602],
						[77.64111397122942, 13.01159445713577],
						[77.64137703881411, 13.011670249152521],
						[77.64164456305798, 13.011772825577271],
						[77.64190317213433, 13.011795089855923],
						[77.64194783217474, 13.011710365156256],
						[77.64198794753834, 13.011558737481222],
						[77.64201469110613, 13.011322443148762],
						[77.64201023384527, 13.01109500173984],
						[77.64202806288763, 13.01093893925178],
						[77.64203697740774, 13.010805222924175],
						[77.64205034918658, 13.010666976290056],
						[77.64203252014777, 13.010506440117748],
						[77.64197011848962, 13.01034143290661],
						[77.64190317213433, 13.010047153420501],
						[77.64186305674043, 13.009792983822928],
						[77.64187197127366, 13.009596726144109],
						[77.64186751400713, 13.009396116770477],
						[77.64187642854, 13.009084005271784],
						[77.64183631313655, 13.008829837875304],
						[77.64183631313655, 13.008669298473045],
						[77.6418407704043, 13.008504315164274],
						[77.6418407704043, 13.00838388935551],
						[77.6418407704043, 13.008259064165884],
						[77.64181848406373, 13.008196572772325],
						[77.64179619771876, 13.008174280760205],
						[77.64175162501552, 13.00813418251283],
						[77.64102028056537, 13.007665992507015],
						[77.64089101878392, 13.007572309315645],
						[77.64056991754154, 13.007344928917272],
						[77.64020878679463, 13.007068439251956],
						[77.63995008641683, 13.006841039302207],
						[77.63966027136686, 13.00662698189831],
						[77.63937045556897, 13.0063327005151],
						[77.63906726689063, 13.00609195021226],
						[77.63876844738374, 13.005891263572432],
						[77.63830916051371, 13.005632661279838],
						[77.63802379864852, 13.005485500534306],
						[77.63811294699669, 13.005498842249409],
						[77.63830470310577, 13.005467600634548],
						[77.6384786292844, 13.005467677682006],
						[77.63873278826107, 13.005503354562023],
						[77.63907618164582, 13.005570233043281],
						[77.63937491293461, 13.005650492286758],
						[77.63993225704452, 13.005842207333368],
						[77.64026227477136, 13.005949244428793],
						[77.64047631382199, 13.005989375844967],
						[77.64052980167129, 13.006002728743715],
						[77.64091330530857, 13.006065149585469],
						[77.6411273431182, 13.006087424113884],
						[77.64182294133221, 13.006167685580092],
						[77.64253190695798, 13.00623909864248],
						[77.64267462595147, 13.0062524248694],
						[77.64318301173077, 13.006319289696366],
						[77.64366456464332, 13.006359503120443],
						[77.64427991808977, 13.006386215930554],
						[77.64486852528101, 13.00643527896324],
						[77.64512712692988, 13.006439764002161],
						[77.64546604363302, 13.006408511036485],
						[77.64552398622634, 13.006421904578314],
						[77.64553735758976, 13.006528910863654],
						[77.64566224429946, 13.006716151136953],
						[77.64570235833557, 13.006800891953072],
						[77.64570235833557, 13.006805377718463],
						[77.64570235833557, 13.006809863484024],
						[77.64581387353276, 13.007028293812297],
						[77.64595650095885, 13.007228981896928],
						[77.64610367269623, 13.007389516537447],
						[77.64618844492765, 13.007465361001204],
						[77.64627312970109, 13.007518858583797],
						[77.64670118447155, 13.008058392772648],
						[77.64724520844126, 13.007790873198342],
						[77.64746369122084, 13.007710528519025],
						[77.64765543135766, 13.007639251143704],
						[77.64776694461378, 13.007608029957112],
						[77.64788737181448, 13.007603547887614],
						[77.64809248228615, 13.007634792227305],
						[77.64826193628727, 13.007621363611372],
						[77.64887280753449, 13.007567883723972],
						[77.64954161597358, 13.007518823219204],
						[77.64968432605201, 13.007487632442372],
						[77.64989389018594, 13.00742074558585],
						[77.65026844795872, 13.007184417268322],
						[77.65049146916492, 13.007059514877772],
						[77.65066983337624, 13.006965939444566],
						[77.65079017007, 13.006916851729082],
						[77.65099981952793, 13.006876710247775],
						[77.65135654604994, 13.006805413627937],
						[77.65152599511988, 13.006751868220752],
						[77.65153936576793, 13.006729563305012],
						[77.65141448560087, 13.006493237681113],
						[77.6512539501503, 13.006185584484829],
						[77.65129851907905, 13.006118711526991],
						[77.65168198591459, 13.006047317446008],
						[77.65246683042454, 13.005909096251788],
						[77.65287257741414, 13.005846705830347],
						[77.65293951722437, 13.005864497816246],
						[77.6529929991328, 13.005909076406706],
						[77.65313124800015, 13.006025011249807],
						[77.65363512959703, 13.006466466983497],
						[77.65400967487344, 13.006783099763792],
						[77.65452692281401, 13.00727799598399],
						[77.65472756438673, 13.007545566083314],
						[77.65547227704907, 13.008352718922271],
						[77.6558200759169, 13.008736140084098],
						[77.65594495117675, 13.008874383879851],
						[77.65604745539734, 13.008990331948848],
						[77.6561322201125, 13.009070575992455],
						[77.65644882012612, 13.009315851850136],
						[77.65749666104621, 13.010091759064556],
						[77.65789356395511, 13.010359266952916],
						[77.65791139047285, 13.010359259822115],
						[77.65835731464828, 13.010644649745238],
						[77.658562405967, 13.010769491885235],
						[77.6588968254758, 13.010943417701116],
						[77.65931154972003, 13.011166403844154],
						[77.66000266599895, 13.011442857016172],
						[77.66099708300331, 13.011808490171958],
						[77.66151429869173, 13.011986874474617],
						[77.66186662265879, 13.012174169465217],
						[77.66209844633121, 13.012312373456895],
						[77.66216983709035, 13.012343579006407],
						[77.66227687945299, 13.012388142129995],
						[77.66229470527324, 13.012388134750111],
						[77.66227687945299, 13.012557582655575],
						[77.66224559687922, 13.013074843659668],
						[77.66224114042247, 13.013279961178231],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000044",
			properties: {
				AREA_SQ_KM: 3.4401136609650282,
				ASS_CONST1: "Sarvagna Nagar",
				ASS_CONST_: "160",
				LAT: 13.0146989651032,
				LON: 77.6489273734027,
				LOST_AREA_SQ_KM: 0.08904114491583132,
				OBJECTID: 69,
				POP_F: 15364.0,
				POP_M: 16634.0,
				POP_SC: 3419.0,
				POP_ST: 263.0,
				POP_TOTAL: 31998.0,
				RESERVATIO: "General",
				WARD_NAME: "Banasavadi",
				WARD_NO: 27.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.63802379864852, 13.005485500534306],
						[77.63830916051371, 13.005632661279838],
						[77.63876844738374, 13.005891263572432],
						[77.63906726689063, 13.00609195021226],
						[77.63937045556897, 13.0063327005151],
						[77.63966027136686, 13.00662698189831],
						[77.63995008641683, 13.006841039302207],
						[77.64020878679463, 13.007068439251956],
						[77.64056991754154, 13.007344928917272],
						[77.64089101878392, 13.007572309315645],
						[77.64102028056537, 13.007665992507015],
						[77.64175162501552, 13.00813418251283],
						[77.64179619771876, 13.008174280760205],
						[77.64181848406373, 13.008196572772325],
						[77.6418407704043, 13.008259064165884],
						[77.6418407704043, 13.00838388935551],
						[77.6418407704043, 13.008504315164274],
						[77.64183631313655, 13.008669298473045],
						[77.64183631313655, 13.008829837875304],
						[77.64187642854, 13.009084005271784],
						[77.64186751400713, 13.009396116770477],
						[77.64187197127366, 13.009596726144109],
						[77.64186305674043, 13.009792983822928],
						[77.64190317213433, 13.010047153420501],
						[77.64197011848962, 13.01034143290661],
						[77.64203252014777, 13.010506440117748],
						[77.64205034918658, 13.010666976290056],
						[77.64203697740774, 13.010805222924175],
						[77.64202806288763, 13.01093893925178],
						[77.64201023384527, 13.01109500173984],
						[77.64201469110613, 13.011322443148762],
						[77.64198794753834, 13.011558737481222],
						[77.64194783217474, 13.011710365156256],
						[77.64190317213433, 13.011795089855923],
						[77.64164456305798, 13.011772825577271],
						[77.64137703881411, 13.011670249152521],
						[77.64111397122942, 13.01159445713577],
						[77.64080178524283, 13.01155434772602],
						[77.64046294185567, 13.011505278880005],
						[77.640213244127, 13.011447296053849],
						[77.639927799701, 13.01144729120775],
						[77.63954883745588, 13.011487390252624],
						[77.63935708347097, 13.013351290824167],
						[77.63936154083714, 13.014024654952607],
						[77.63911184065944, 13.014029126123656],
						[77.6385276606861, 13.014082646294488],
						[77.63783649948654, 13.014149481750678],
						[77.63739058113941, 13.014185147401223],
						[77.63694466102208, 13.014238669497198],
						[77.63652557141118, 13.014301158859315],
						[77.63611976531996, 13.014385853177624],
						[77.63582994038684, 13.014461632077142],
						[77.63575853286241, 13.01447950569417],
						[77.63572287278426, 13.01434571475256],
						[77.63562034999651, 13.013984499123632],
						[77.6354731645179, 13.013387008255885],
						[77.63540184416912, 13.013079315414009],
						[77.63535281140307, 13.012771660170081],
						[77.63452344645535, 13.012851940771741],
						[77.63337741117446, 13.012923283403925],
						[77.63267284539785, 13.012958921867106],
						[77.63253020110045, 13.012985703627045],
						[77.63241867271242, 13.012985686974021],
						[77.63242313035387, 13.012722618626746],
						[77.63241421507077, 13.01239265578362],
						[77.63239638450247, 13.012084952745251],
						[77.63230277397247, 13.01206707872247],
						[77.63233397749114, 13.011821861886645],
						[77.63235180806933, 13.011425039113643],
						[77.63242758799514, 13.01088099136328],
						[77.63248116708141, 13.010310257703603],
						[77.63252574346323, 13.009940087546205],
						[77.63255248928385, 13.009677062602572],
						[77.63250791291263, 13.009329261962163],
						[77.63245442124382, 13.009021577219864],
						[77.63243650327718, 13.008758476684223],
						[77.63237409628812, 13.008357115173492],
						[77.63223590926044, 13.008218919748849],
						[77.63198173558693, 13.008045041225431],
						[77.63172756133864, 13.007884447253987],
						[77.63150904794223, 13.00771953014115],
						[77.63135294171171, 13.007585735788211],
						[77.63133065329045, 13.007456393078215],
						[77.63128161874812, 13.007291432279084],
						[77.6313039071791, 13.007072883051398],
						[77.63137977521694, 13.006965858491458],
						[77.63150904794223, 13.006729595291452],
						[77.63178551102011, 13.006248011136803],
						[77.63190141029341, 13.006029479028587],
						[77.63194161664991, 13.005949206020173],
						[77.63183454534263, 13.005378449113769],
						[77.63178996868669, 13.004553560371246],
						[77.63142880971644, 13.003889080113591],
						[77.63113005730023, 13.003327233231563],
						[77.63090708504093, 13.003224734341392],
						[77.63062170437742, 13.003122167720191],
						[77.63023370788143, 13.002966104062622],
						[77.6302872880134, 13.002377470080477],
						[77.630363069331, 13.001927141207549],
						[77.63041656199502, 13.001548111818911],
						[77.6308402194987, 13.00161048574069],
						[77.63162048982748, 13.001753228388539],
						[77.63230277397247, 13.001895917280514],
						[77.63280666166668, 13.001993974385663],
						[77.63313661292243, 13.002074268435724],
						[77.6338099726914, 13.002203587064518],
						[77.63422461486176, 13.002395342526922],
						[77.63456356447074, 13.002551371948462],
						[77.63492025582518, 13.002827895268405],
						[77.63546424947678, 13.003305003215212],
						[77.63595029299056, 13.003670640853578],
						[77.63624903259277, 13.00394265679186],
						[77.63629815236939, 13.003991684212693],
						[77.63643187691937, 13.004103175910704],
						[77.63645862181026, 13.004134393584442],
						[77.63646753677247, 13.004138790005884],
						[77.63690008639325, 13.004495562666918],
						[77.63750210459254, 13.005159979960139],
						[77.63757342360798, 13.005231295170654],
						[77.63772952119697, 13.005338342729505],
						[77.63802379864852, 13.005485500534306],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000045",
			properties: {
				AREA_SQ_KM: 1.0372225658790237,
				ASS_CONST1: "Sarvagna Nagar",
				ASS_CONST_: "160",
				LAT: 13.0083736988122,
				LON: 77.6359927313585,
				LOST_AREA_SQ_KM: 0.0115577393076878,
				OBJECTID: 70,
				POP_F: 16894.0,
				POP_M: 17925.0,
				POP_SC: 4031.0,
				POP_ST: 213.0,
				POP_TOTAL: 34819.0,
				RESERVATIO: "General",
				WARD_NAME: "Kammanahalli",
				WARD_NO: 28.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.62614921839148, 13.01307487696011],
						[77.62634991067685, 13.01301241956544],
						[77.62672445956353, 13.012834109032276],
						[77.62698310297915, 13.0127181095461],
						[77.62723274269726, 13.012624536401065],
						[77.62746463791935, 13.012570998194137],
						[77.62771436397696, 13.012566539473067],
						[77.62810228175844, 13.012575459150383],
						[77.62857498372662, 13.012651258576248],
						[77.62892277852494, 13.012762712808755],
						[77.62927948777988, 13.012807309260452],
						[77.62961390714158, 13.012865325292227],
						[77.62990820584585, 13.012896479982635],
						[77.63044330831748, 13.012981231562113],
						[77.63089371193567, 13.012999106058265],
						[77.63136185707897, 13.012994627892347],
						[77.63188803730645, 13.012999065613524],
						[77.63219133276367, 13.012985706260826],
						[77.63241867271242, 13.012985686974021],
						[77.63253020110045, 13.012985703627045],
						[77.63267284539785, 13.012958921867106],
						[77.63337741117446, 13.012923283403925],
						[77.63452344645535, 13.012851940771741],
						[77.63535281140307, 13.012771660170081],
						[77.63540184416912, 13.013079315414009],
						[77.6354731645179, 13.013387008255885],
						[77.63562034999651, 13.013984499123632],
						[77.63572287278426, 13.01434571475256],
						[77.63575853286241, 13.01447950569417],
						[77.63582994038684, 13.014461632077142],
						[77.63611976531996, 13.014385853177624],
						[77.63652557141118, 13.014301158859315],
						[77.63694466102208, 13.014238669497198],
						[77.63739058113941, 13.014185147401223],
						[77.63783649948654, 13.014149481750678],
						[77.6385276606861, 13.014082646294488],
						[77.63911184065944, 13.014029126123656],
						[77.63936154083714, 13.014024654952607],
						[77.63932142453521, 13.01459546281135],
						[77.6393481687381, 13.015647765460885],
						[77.63935262610462, 13.01760527849131],
						[77.63945514548593, 13.019674345992398],
						[77.63954438009713, 13.0219573753803],
						[77.63954883745588, 13.021979675999132],
						[77.64008835135506, 13.021810219205157],
						[77.64032467737869, 13.024699731661258],
						[77.63985202483407, 13.02487809991231],
						[77.6399233423573, 13.025694085157493],
						[77.6397940793131, 13.02574316860277],
						[77.63941502921743, 13.025841315978408],
						[77.63933033927022, 13.025854668466648],
						[77.63820664008632, 13.026055279910004],
						[77.63726577262953, 13.0262425983485],
						[77.63627140744029, 13.02643432554275],
						[77.63535726892815, 13.026670632460855],
						[77.63472849393905, 13.026880210195095],
						[77.63430493849485, 13.027018480401],
						[77.63350231140761, 13.02734849441664],
						[77.63335958075898, 13.027415301896232],
						[77.63284232267027, 13.027656126040265],
						[77.63277100065176, 13.02766502909877],
						[77.63269959118345, 13.027665041440805],
						[77.63265501487058, 13.027633819684679],
						[77.63260598090599, 13.027598155732143],
						[77.63247225180292, 13.027446512852677],
						[77.63230723161851, 13.027219165928045],
						[77.63197727792797, 13.026612719101417],
						[77.63196390495003, 13.026585933644215],
						[77.6318434606717, 13.026255997100327],
						[77.6315536247096, 13.025511291780578],
						[77.63154916703364, 13.025488990826679],
						[77.6314689288365, 13.025283848688002],
						[77.63130836486478, 13.024739836672518],
						[77.63135739939544, 13.024717609613726],
						[77.63135294171171, 13.024699708803068],
						[77.63120138035998, 13.024111184813856],
						[77.63117909190868, 13.024021940137022],
						[77.63104973139814, 13.023406622756385],
						[77.6309338312467, 13.023049912103936],
						[77.63073760485065, 13.022697617504685],
						[77.63073760485065, 13.022688731449502],
						[77.63068411233938, 13.022568308014316],
						[77.63055929638081, 13.022439015673397],
						[77.63042993515704, 13.022358679733207],
						[77.63019358832057, 13.022251711158997],
						[77.63007322955204, 13.022166977528295],
						[77.62996178613295, 13.022059983742565],
						[77.62993941002921, 13.022037683564866],
						[77.62991712132768, 13.021961812903953],
						[77.62988591713817, 13.021841428286324],
						[77.62984579745317, 13.021355428123613],
						[77.62988145939609, 13.02089171293603],
						[77.62939101928235, 13.021025459019897],
						[77.62894060964418, 13.02104777146826],
						[77.62859727269418, 13.020980858424274],
						[77.62859281490103, 13.020713291584292],
						[77.62846345141472, 13.020240600191768],
						[77.62832525958015, 13.019072327384665],
						[77.62811565519573, 13.01810473996199],
						[77.62786593002923, 13.016726840113003],
						[77.62776785790108, 13.015844008060308],
						[77.62768761700536, 13.015603171619613],
						[77.62748246926981, 13.015063622749512],
						[77.62732198701384, 13.014840664965925],
						[77.62705442866944, 13.014466075070585],
						[77.6269250634135, 13.014269892151125],
						[77.62682699046488, 13.014140604752406],
						[77.62627849713029, 13.013280024715197],
						[77.62614921839148, 13.01307487696011],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000046",
			properties: {
				AREA_SQ_KM: 1.6908524747682911,
				ASS_CONST1: "Sarvagna Nagar",
				ASS_CONST_: "160",
				LAT: 13.0194167842318,
				LON: 77.6340107399307,
				LOST_AREA_SQ_KM: 0.06779290330598121,
				OBJECTID: 71,
				POP_F: 13981.0,
				POP_M: 15037.0,
				POP_SC: 2593.0,
				POP_ST: 321.0,
				POP_TOTAL: 29018.0,
				RESERVATIO: "General",
				WARD_NAME: "Kacharkanahalli",
				WARD_NO: 29.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61616082859922, 13.010314673752918],
						[77.61679844907457, 13.010087293057664],
						[77.61750302686491, 13.009810775007379],
						[77.61800243522664, 13.009627972835464],
						[77.61833688810891, 13.009520984778664],
						[77.61869808911256, 13.009387163407032],
						[77.61873821277305, 13.009240038886484],
						[77.61881846005116, 13.009137458467906],
						[77.61899232895746, 13.009039385588707],
						[77.61914845234185, 13.008994801593227],
						[77.61930894625597, 13.00896802480247],
						[77.61946498178645, 13.008999258359486],
						[77.61960773006832, 13.009052797191696],
						[77.61971472559996, 13.009083998712466],
						[77.6198484698715, 13.009141934573602],
						[77.62001350833523, 13.009159799827499],
						[77.62018291726935, 13.009199871635246],
						[77.62026762164078, 13.009271232118477],
						[77.62039253643343, 13.00937825608892],
						[77.62058869346276, 13.009525456678697],
						[77.62089193197664, 13.009748373423415],
						[77.62078493756401, 13.009949039958256],
						[77.62066002320685, 13.010452912962645],
						[77.62063327455812, 13.01082746416007],
						[77.62062881644937, 13.01118419846284],
						[77.6207269822147, 13.011683634878345],
						[77.62094988724101, 13.01224549523452],
						[77.6210212167562, 13.012379271989117],
						[77.62104350722042, 13.012410450818654],
						[77.6214002414609, 13.01323090588126],
						[77.62166781325247, 13.013774912797784],
						[77.62169901972186, 13.01385965976552],
						[77.62170347778822, 13.013868587051576],
						[77.62170793585439, 13.013895370904606],
						[77.6219799649698, 13.014475050752717],
						[77.62214045484644, 13.014822832510026],
						[77.62245260525275, 13.015527377495461],
						[77.62277812886502, 13.01624973845119],
						[77.62311702556715, 13.017132686486853],
						[77.62332663926615, 13.018095855864116],
						[77.62344254725731, 13.01917935062127],
						[77.6234559212486, 13.019710026023692],
						[77.62341134127144, 13.020530449832815],
						[77.62334001327123, 13.020967503763675],
						[77.62328197182592, 13.02134204987677],
						[77.62302340731273, 13.021404476230519],
						[77.62267559427349, 13.021484774027924],
						[77.62240802487807, 13.021529339353313],
						[77.62206020993673, 13.02159177822302],
						[77.621748058442, 13.021622979843098],
						[77.6215385292157, 13.021667579675459],
						[77.6212932475315, 13.021729953558506],
						[77.62096771962405, 13.02183703027017],
						[77.62063327455812, 13.021872677240577],
						[77.62031674839874, 13.021912810408827],
						[77.62015171037959, 13.02195290985614],
						[77.61984401173167, 13.021966292916561],
						[77.61956314856678, 13.022028761717822],
						[77.61937581865267, 13.022100050898068],
						[77.61930448809478, 13.022135779618605],
						[77.61927328096151, 13.021930681148152],
						[77.61921532483387, 13.021703244515566],
						[77.61916628501027, 13.021582865917546],
						[77.6190324525131, 13.021293024997158],
						[77.61899678713102, 13.021078914017266],
						[77.61896112173764, 13.020209427531308],
						[77.61869808911256, 13.018911807139133],
						[77.61855533967726, 13.018358863991184],
						[77.61855533967726, 13.018354464423407],
						[77.61855088148621, 13.018327679693515],
						[77.6184929249867, 13.018167193220807],
						[77.6182119710352, 13.017275301768013],
						[77.61784193944997, 13.016227474109256],
						[77.61767689780268, 13.015848414893794],
						[77.61760556616838, 13.015647779258256],
						[77.61701699104447, 13.014314521493985],
						[77.61686978122016, 13.013939951747531],
						[77.61650420349632, 13.013007964579343],
						[77.61613853716824, 13.012160776416097],
						[77.61583974414968, 13.011634599691394],
						[77.61576841135162, 13.011474083655335],
						[77.61563466223346, 13.011268897826309],
						[77.6154607881424, 13.010996912307032],
						[77.61544732578326, 13.010979098155206],
						[77.615318034603, 13.010769470109432],
						[77.61616082859922, 13.010314673752918],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000047",
			properties: {
				AREA_SQ_KM: 0.7003074422694229,
				ASS_CONST1: "Sarvagna Nagar",
				ASS_CONST_: "160",
				LAT: 13.015543513984,
				LON: 77.6198640587447,
				LOST_AREA_SQ_KM: 0.00987588081503475,
				OBJECTID: 72,
				POP_F: 16940.0,
				POP_M: 17902.0,
				POP_SC: 3897.0,
				POP_ST: 59.0,
				POP_TOTAL: 34842.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Kadugondanahalli",
				WARD_NO: 30.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.62259534974538, 13.008259054046622],
						[77.62259980777621, 13.008259009827464],
						[77.6227068004634, 13.008339291525877],
						[77.62271125848983, 13.008339333565308],
						[77.6227023424368, 13.008330364724966],
						[77.62279596095836, 13.008245677801888],
						[77.62421841094694, 13.007648120506481],
						[77.62424070077783, 13.007639186971067],
						[77.62665759153403, 13.00664487905178],
						[77.62816023330853, 13.006033984589664],
						[77.62960053388248, 13.005436469586789],
						[77.63024262333748, 13.005164403882535],
						[77.63045222375688, 13.005075198279519],
						[77.63142435203555, 13.004669424798283],
						[77.63178996868669, 13.004553560371246],
						[77.63183454534263, 13.005378449113769],
						[77.63194161664991, 13.005949206020173],
						[77.63190141029341, 13.006029479028587],
						[77.63178551102011, 13.006248011136803],
						[77.63150904794223, 13.006729595291452],
						[77.63137977521694, 13.006965858491458],
						[77.6313039071791, 13.007072883051398],
						[77.63128161874812, 13.007291432279084],
						[77.63133065329045, 13.007456393078215],
						[77.63135294171171, 13.007585735788211],
						[77.63150904794223, 13.00771953014115],
						[77.63172756133864, 13.007884447253987],
						[77.63198173558693, 13.008045041225431],
						[77.63223590926044, 13.008218919748849],
						[77.63237409628812, 13.008357115173492],
						[77.63243650327718, 13.008758476684223],
						[77.63245442124382, 13.009021577219864],
						[77.63250791291263, 13.009329261962163],
						[77.63255248928385, 13.009677062602572],
						[77.63252574346323, 13.009940087546205],
						[77.63248116708141, 13.010310257703603],
						[77.63242758799514, 13.01088099136328],
						[77.63235180806933, 13.011425039113643],
						[77.63233397749114, 13.011821861886645],
						[77.63230277397247, 13.01206707872247],
						[77.63239638450247, 13.012084952745251],
						[77.63241421507077, 13.01239265578362],
						[77.63242313035387, 13.012722618626746],
						[77.63241867271242, 13.012985686974021],
						[77.63219133276367, 13.012985706260826],
						[77.63188803730645, 13.012999065613524],
						[77.63136185707897, 13.012994627892347],
						[77.63089371193567, 13.012999106058265],
						[77.63044330831748, 13.012981231562113],
						[77.62990820584585, 13.012896479982635],
						[77.62961390714158, 13.012865325292227],
						[77.62927948777988, 13.012807309260452],
						[77.62892277852494, 13.012762712808755],
						[77.62857498372662, 13.012651258576248],
						[77.62810228175844, 13.012575459150383],
						[77.62771436397696, 13.012566539473067],
						[77.62746463791935, 13.012570998194137],
						[77.62723274269726, 13.012624536401065],
						[77.62698310297915, 13.0127181095461],
						[77.62672445956353, 13.012834109032276],
						[77.62634991067685, 13.01301241956544],
						[77.62614921839148, 13.01307487696011],
						[77.62583262033871, 13.012566554054567],
						[77.6256453008614, 13.012624478788924],
						[77.62501210102819, 13.012869785866712],
						[77.62519496363207, 13.013315687302216],
						[77.62487390495298, 13.013462840430426],
						[77.62463754643976, 13.01360104454259],
						[77.62437898504184, 13.013774928079485],
						[77.62424961670895, 13.013895336052807],
						[77.624053466061, 13.01398449300477],
						[77.62380373187577, 13.01410488564045],
						[77.6234559212486, 13.014180701888277],
						[77.62315268964359, 13.0142698782386],
						[77.62263101398716, 13.01438140284175],
						[77.6219799649698, 13.014475050752717],
						[77.62170793585439, 13.013895370904606],
						[77.62170347778822, 13.013868587051576],
						[77.62169901972186, 13.01385965976552],
						[77.62166781325247, 13.013774912797784],
						[77.6214002414609, 13.01323090588126],
						[77.62104350722042, 13.012410450818654],
						[77.6210212167562, 13.012379271989117],
						[77.62094988724101, 13.01224549523452],
						[77.6207269822147, 13.011683634878345],
						[77.62062881644937, 13.01118419846284],
						[77.62063327455812, 13.01082746416007],
						[77.62066002320685, 13.010452912962645],
						[77.62078493756401, 13.009949039958256],
						[77.62089193197664, 13.009752858963035],
						[77.62089193197664, 13.009748373423415],
						[77.62094097104844, 13.009677067851362],
						[77.62094097104844, 13.009672582313943],
						[77.62100338438164, 13.009498667059903],
						[77.62121300201768, 13.009213316766232],
						[77.6215831099348, 13.008852106033787],
						[77.62221178360704, 13.008473063059698],
						[77.62252839184914, 13.00833035984675],
						[77.62259980777621, 13.008263495333887],
						[77.62259534974538, 13.008259054046622],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000048",
			properties: {
				AREA_SQ_KM: 0.8797263682285043,
				ASS_CONST1: "Sarvagna Nagar",
				ASS_CONST_: "160",
				LAT: 13.0099459400038,
				LON: 77.6269718546523,
				LOST_AREA_SQ_KM: 0.018135665984479115,
				OBJECTID: 73,
				POP_F: 16108.0,
				POP_M: 16267.0,
				POP_SC: 5013.0,
				POP_ST: 289.0,
				POP_TOTAL: 32375.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Lingarajapura",
				WARD_NO: 49.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.62006254778976, 13.000081084763497],
						[77.62005808965841, 13.000081042784965],
						[77.62013387786727, 13.000000764748714],
						[77.62015616850722, 12.999978462576912],
						[77.62016508476196, 12.999960692144885],
						[77.62075373084117, 12.999452344954591],
						[77.6220423777268, 12.998315247273288],
						[77.62370119821882, 12.99686603203693],
						[77.6243745270815, 12.99622840537293],
						[77.62462417258892, 12.99604556631871],
						[77.62497643754115, 12.995818152388027],
						[77.62539565762, 12.995577396472438],
						[77.62605560266027, 12.995260752896648],
						[77.62683590619102, 12.994975336368789],
						[77.6274200595308, 12.994832722470377],
						[77.62844562021981, 12.994645424667905],
						[77.6291190080885, 12.994507180448666],
						[77.62906096962517, 12.994266337773443],
						[77.63021141923828, 12.994092449693282],
						[77.63114343037739, 12.993914097927636],
						[77.63192824366772, 12.99377145104568],
						[77.63339078398424, 12.99346375389117],
						[77.63409088768476, 12.993312113122911],
						[77.63437180197589, 12.993294296225821],
						[77.6347596967842, 12.993236334831103],
						[77.63534835387783, 12.993098053688641],
						[77.6356961277182, 12.993031177231893],
						[77.6369848655741, 12.992750297666374],
						[77.63716316384878, 12.992723495690829],
						[77.63726131518018, 12.99294202810977],
						[77.63747535995009, 12.993610931527233],
						[77.63793910765223, 12.993597490968416],
						[77.6379792244479, 12.993802629649013],
						[77.63806391541395, 12.99423960208391],
						[77.6379792244479, 12.99427081302457],
						[77.63794356507468, 12.994310933668736],
						[77.63781421235129, 12.99436001073164],
						[77.63790336086579, 12.99527409815803],
						[77.63795693734096, 12.995474797305224],
						[77.63811294699669, 12.995474785219892],
						[77.63830024569766, 12.995439099903662],
						[77.6384697144818, 12.995376681851562],
						[77.63858114946353, 12.995367801042374],
						[77.63886213992656, 12.995363353222366],
						[77.63903597784235, 12.99533659510793],
						[77.6391920733987, 12.995296391721979],
						[77.63919653077143, 12.995296433245702],
						[77.63929913769462, 12.995269704723492],
						[77.63953992273821, 12.995233998875317],
						[77.63969147284213, 12.99520729133049],
						[77.63988322625606, 12.995189414398023],
						[77.64040499664995, 12.995077925082446],
						[77.64055654558639, 12.995501553999533],
						[77.64060111876405, 12.995581841271546],
						[77.64066360858138, 12.995836017053337],
						[77.64098016485602, 12.995613064719933],
						[77.64119865983143, 12.995425771503385],
						[77.641296807636, 12.995840455587436],
						[77.64133246594446, 12.995853812086525],
						[77.6414037825274, 12.995876126043521],
						[77.64145726993489, 12.99588499067729],
						[77.64148410102675, 12.995889470314427],
						[77.6414885583085, 12.995889511799858],
						[77.64161781940203, 12.995902876891211],
						[77.64175162501552, 12.995907316997355],
						[77.6418407704043, 12.995925138971465],
						[77.64189871486906, 12.995951899950233],
						[77.64193446038368, 12.99598760085731],
						[77.6426166818872, 12.995983176476837],
						[77.6422108978056, 12.996544975864362],
						[77.64153758839598, 12.99757946999633],
						[77.64095787834462, 12.998444566922299],
						[77.64039153727663, 12.999425548377452],
						[77.63990105563616, 13.000312877408119],
						[77.63961569781574, 13.000807923945956],
						[77.63948634701818, 13.001021929258144],
						[77.63913858491223, 13.001525796687927],
						[77.63883093822088, 13.001980612622193],
						[77.63873278826107, 13.002141170347107],
						[77.63867038476918, 13.002252638664668],
						[77.63861243863852, 13.002466711403503],
						[77.63854103288284, 13.002627174233321],
						[77.63849645888749, 13.002698526009373],
						[77.63844742747219, 13.00274309559749],
						[77.63833144755077, 13.002809981438473],
						[77.63794356507468, 13.003001732302122],
						[77.6370160677926, 13.0035012059265],
						[77.63666375316369, 13.003697337553701],
						[77.63648536669479, 13.00379546853063],
						[77.63632489729213, 13.003893597822543],
						[77.63624903259277, 13.00394265679186],
						[77.63595029299056, 13.003670640853578],
						[77.63546424947678, 13.003305003215212],
						[77.63492025582518, 13.002827895268405],
						[77.63456356447074, 13.002551371948462],
						[77.63422461486176, 13.002395342526922],
						[77.6338099726914, 13.002203587064518],
						[77.63313661292243, 13.002074268435724],
						[77.63280666166668, 13.001993974385663],
						[77.63230277397247, 13.001895917280514],
						[77.63162048982748, 13.001753228388539],
						[77.6308402194987, 13.00161048574069],
						[77.63041656199502, 13.001548111818911],
						[77.630363069331, 13.001927141207549],
						[77.6302872880134, 13.002377470080477],
						[77.63023370788143, 13.002966104062622],
						[77.63062170437742, 13.003122167720191],
						[77.63090708504093, 13.003224734341392],
						[77.63113005730023, 13.003327233231563],
						[77.63142880971644, 13.003889080113591],
						[77.63178996868669, 13.004553560371246],
						[77.63142435203555, 13.004669424798283],
						[77.63045222375688, 13.005075198279519],
						[77.63024262333748, 13.005164403882535],
						[77.62960053388248, 13.005436469586789],
						[77.62816023330853, 13.006033984589664],
						[77.62665759153403, 13.00664487905178],
						[77.62424070077783, 13.007639186971067],
						[77.62421841094694, 13.007648120506481],
						[77.62279596095836, 13.008245677801888],
						[77.6227023424368, 13.008330364724966],
						[77.62271125848983, 13.008339333565308],
						[77.6227068004634, 13.008339291525877],
						[77.62259980777621, 13.008259009827464],
						[77.62259534974538, 13.008259054046622],
						[77.6220914162973, 13.0077283708503],
						[77.62192201023586, 13.007523287921352],
						[77.6216098583578, 13.007144252028924],
						[77.62127987328317, 13.006751851917072],
						[77.62078047946127, 13.006083038247185],
						[77.62051736367347, 13.005623733707075],
						[77.62042820137329, 13.00557018959832],
						[77.62006254778976, 13.00496380154772],
						[77.61974593261085, 13.00431276787682],
						[77.61955423226435, 13.003759784969109],
						[77.61954977411288, 13.003755343890687],
						[77.61947389809595, 13.003608163015583],
						[77.61949173071282, 13.003483346972555],
						[77.61958098116952, 13.003371887346105],
						[77.61971026745483, 13.00320240694505],
						[77.61985292801114, 13.00308644463415],
						[77.62021858227563, 13.002859075470278],
						[77.62057977724157, 13.002680742787232],
						[77.62064219077506, 13.002636133141971],
						[77.62065110699132, 13.002618276081215],
						[77.62065110699132, 13.002502349265336],
						[77.62064664888328, 13.002074224336777],
						[77.62045049195494, 13.001623939358122],
						[77.62043711760649, 13.00161053021519],
						[77.62031674839874, 13.001235915908818],
						[77.62027207976384, 13.000941624772944],
						[77.62026316351756, 13.000892634912251],
						[77.62024978914684, 13.000165737095315],
						[77.62018737539574, 13.000139014706972],
						[77.62006700592093, 13.000081040488832],
						[77.62006254778976, 13.000081084763497],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000049",
			properties: {
				AREA_SQ_KM: 2.381209962725266,
				ASS_CONST1: "Sarvagna Nagar",
				ASS_CONST_: "160",
				LAT: 12.999921081914,
				LON: 77.6300806168447,
				LOST_AREA_SQ_KM: 0.09635194733261407,
				OBJECTID: 74,
				POP_F: 17949.0,
				POP_M: 17862.0,
				POP_SC: 8453.0,
				POP_ST: 89.0,
				POP_TOTAL: 35811.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Maruthi Seva Nagar",
				WARD_NO: 59.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.64909574412391, 12.947954136987558],
						[77.64910020110335, 12.947994271149332],
						[77.64911357204059, 12.948275220455557],
						[77.64916259879685, 12.94861409283741],
						[77.64916705577362, 12.948623014487325],
						[77.64917596972664, 12.948658705968278],
						[77.64918497107064, 12.948694401640495],
						[77.64918497107064, 12.948698799019715],
						[77.64924736871697, 12.948935188993305],
						[77.64924736871697, 12.948939586382364],
						[77.64925182569038, 12.94897083990508],
						[77.64927411055479, 12.949006481094377],
						[77.64932313724086, 12.949104619683299],
						[77.64936770693689, 12.949175988398922],
						[77.64940345007247, 12.949233915335228],
						[77.64944801973657, 12.94930968155349],
						[77.64963975648179, 12.949554930789997],
						[77.64975572471874, 12.94968877745479],
						[77.64998311649775, 12.949947372937785],
						[77.65010354131927, 12.950094539376192],
						[77.65012582601445, 12.95012129987271],
						[77.6501926800734, 12.950205978855985],
						[77.65038887247388, 12.950428938859863],
						[77.65043344174643, 12.950495824884433],
						[77.65048701224077, 12.95054046425584],
						[77.65060289221113, 12.950647492151385],
						[77.65061626296924, 12.950660806587141],
						[77.65109787137, 12.951106770759695],
						[77.65110678516956, 12.95111564709027],
						[77.65130743286268, 12.951280620004122],
						[77.65139665804992, 12.951338605116375],
						[77.65161067586394, 12.951481281644432],
						[77.65170435768586, 12.95153930750362],
						[77.65180686578377, 12.951601721827915],
						[77.65189609057364, 12.951646255813701],
						[77.6521234780129, 12.951717659381538],
						[77.6522126151615, 12.951757791643507],
						[77.65225281424455, 12.951784546939297],
						[77.65228401221864, 12.95180681921303],
						[77.65229292592393, 12.951824663126553],
						[77.65230629648053, 12.951851429003039],
						[77.65232412388686, 12.951878149316798],
						[77.65232412388686, 12.951882633083283],
						[77.65237760608882, 12.95204764083545],
						[77.65238651978667, 12.952069882341343],
						[77.65240889141737, 12.952150194196092],
						[77.65248911465011, 12.952386497116805],
						[77.65249357149469, 12.95240878426574],
						[77.65249802833908, 12.952417706277316],
						[77.65250694202736, 12.95247124817613],
						[77.65252476940178, 12.952506936250096],
						[77.65253813993074, 12.952520250932066],
						[77.6525515104581, 12.95253365184262],
						[77.6525694252145, 12.95254701136725],
						[77.65260507994063, 12.952560443127812],
						[77.65264964833239, 12.95256929995436],
						[77.65277898395712, 12.952578243628983],
						[77.65294843087754, 12.952573756804863],
						[77.65296180135599, 12.952573792536674],
						[77.6529707150074, 12.952573787615105],
						[77.65310450708208, 12.952578287957166],
						[77.65314461845678, 12.952578222668151],
						[77.65340320102675, 12.952596105220806],
						[77.6535236221755, 12.952600569616587],
						[77.65366187038852, 12.952605024052202],
						[77.6540275020089, 12.952631732099025],
						[77.65444224427246, 12.95265854573861],
						[77.654718650875, 12.952680814526843],
						[77.65473202114234, 12.952685247757454],
						[77.65499514418316, 12.952707571019772],
						[77.65507090882335, 12.95270757161558],
						[77.65517795796814, 12.952716483397243],
						[77.65535185799358, 12.952725400647452],
						[77.65537414164567, 12.95272543122362],
						[77.6554856472265, 12.952734297248648],
						[77.65556141153513, 12.952738781524015],
						[77.6555926959752, 12.952743208849464],
						[77.65588692658066, 12.952765552508064],
						[77.65609656650815, 12.952778937101773],
						[77.6563284021198, 12.952792261591666],
						[77.65646664687368, 12.952801198239808],
						[77.6567966159636, 12.952819040879762],
						[77.65697942681025, 12.952836833376049],
						[77.65707756080808, 12.952845749409812],
						[77.65722917460728, 12.952859204985312],
						[77.65783554036791, 12.952899311872969],
						[77.65801398027963, 12.952908185698183],
						[77.65816559281188, 12.952917156813875],
						[77.65842416379853, 12.952930550137301],
						[77.65900387096605, 12.952952824299677],
						[77.65901278413696, 12.952952819142308],
						[77.65911537293532, 12.952943925527057],
						[77.65936948515912, 12.952930546304435],
						[77.65954337868024, 12.952921611078885],
						[77.65960140138301, 12.952921624691804],
						[77.65992690469307, 12.952921572989332],
						[77.66003394919912, 12.952917117109973],
						[77.66005623192018, 12.952917147223136],
						[77.66099708300331, 12.952868117577198],
						[77.66102382203815, 12.952863617931449],
						[77.66102382203813, 12.95289034900883],
						[77.66102827854333, 12.95309552916965],
						[77.66102827854333, 12.953122260300134],
						[77.66103273504834, 12.95314903193423],
						[77.66103719155316, 12.953376459803511],
						[77.66104164805782, 12.954009600121154],
						[77.66104610456229, 12.95411216828605],
						[77.66104610456229, 12.954134501908369],
						[77.6610505610666, 12.954232586210402],
						[77.6610505610666, 12.9542504358804],
						[77.6610505610666, 12.954344082019588],
						[77.6614385384082, 12.95432174975873],
						[77.6614474513854, 12.954317260555673],
						[77.66218320646857, 12.95427274258941],
						[77.66221440167824, 12.954268283331729],
						[77.66229024881844, 12.954263801970528],
						[77.66231253109063, 12.954263745714872],
						[77.66241511686708, 12.954254850772918],
						[77.66241511686707, 12.954263818761467],
						[77.6624195733169, 12.954290504524455],
						[77.66242402976654, 12.954361857807243],
						[77.66245522490914, 12.954660672426254],
						[77.66247305070101, 12.954736458967982],
						[77.66250424582994, 12.95485237826957],
						[77.66251315872232, 12.954870222863986],
						[77.66251315872232, 12.954896954518201],
						[77.66250870227621, 12.954914850107258],
						[77.66250424582992, 12.954946025300377],
						[77.66249087649003, 12.954972807954244],
						[77.66192010030692, 12.955012878693994],
						[77.6619290132458, 12.955186801700405],
						[77.66192455677646, 12.955458820979347],
						[77.66087221337379, 12.955445434388425],
						[77.660876669885, 12.955485572402024],
						[77.6608900394176, 12.955570286799434],
						[77.66090786545855, 12.95561934205028],
						[77.66092577887922, 12.955641669800702],
						[77.6609302353883, 12.955650592157532],
						[77.66093914840594, 12.955659468818054],
						[77.66097034396209, 12.955686225569563],
						[77.66097925697653, 12.955690704435055],
						[77.6609837134835, 12.955690658737412],
						[77.66099708300331, 12.955699575934844],
						[77.66100153950956, 12.955704100498405],
						[77.66101936553278, 12.955708574199383],
						[77.6610416480578, 12.95570851817004],
						[77.66112195249363, 12.955708562069798],
						[77.66113532199694, 12.95570851119991],
						[77.66122453936052, 12.955717431727836],
						[77.66122899585773, 12.955717472256934],
						[77.66134932121507, 12.955721929535429],
						[77.66193346971497, 12.955753123952153],
						[77.66193792618397, 12.955721948621402],
						[77.66261128787295, 12.95574869560897],
						[77.66276735061929, 12.955739769462225],
						[77.66300817266116, 12.955739722558507],
						[77.66340942507266, 12.955762001064855],
						[77.66339159942984, 12.955828927649533],
						[77.66329355834364, 12.95607862748965],
						[77.66317760411394, 12.956386282136037],
						[77.66307056261222, 12.956698416184265],
						[77.66304382406602, 12.956774230338763],
						[77.66304382406602, 12.956832264938454],
						[77.66303936764103, 12.956908023006356],
						[77.66307056261222, 12.957068527167875],
						[77.66313749633059, 12.957291533872228],
						[77.6631998862096, 12.9575100122503],
						[77.66327564530167, 12.957795357338052],
						[77.66333812248436, 12.958027293426108],
						[77.66336040454809, 12.95811200504297],
						[77.6634719021807, 12.958495469520006],
						[77.66348972781076, 12.958549010494496],
						[77.66355211749352, 12.958767491578499],
						[77.66355657389809, 12.958794264727297],
						[77.66357003049126, 12.958807584323354],
						[77.66357894329882, 12.958812063321353],
						[77.66358785610566, 12.95889234229984],
						[77.66358339970233, 12.958914636476303],
						[77.66356548670673, 12.958932493181976],
						[77.66332920965763, 12.9589770800002],
						[77.66331584041622, 12.958981528825658],
						[77.66309730115202, 12.95902610082784],
						[77.66275843774714, 12.959093033626699],
						[77.66274506843756, 12.959097482409305],
						[77.662727242689, 12.959101890645378],
						[77.66228579236345, 12.959173232356163],
						[77.66223668396555, 12.95918218159331],
						[77.66212972894607, 12.959195523086048],
						[77.6621073592559, 12.959199972759953],
						[77.66159905927117, 12.95926242598894],
						[77.66129138679989, 12.959298078433084],
						[77.66127356081996, 12.959298088591131],
						[77.6611397784977, 12.959302558487924],
						[77.66113532199694, 12.959302604141433],
						[77.66113086549602, 12.959302563560831],
						[77.66101936553278, 12.959293611312154],
						[77.66077417008216, 12.959262400352337],
						[77.66076516966875, 12.95926240123388],
						[77.6604709518725, 12.95921341053518],
						[77.65993136124189, 12.95910638085732],
						[77.65981986008474, 12.959084061915005],
						[77.65980649042443, 12.959084112569174],
						[77.65977975109902, 12.959079643499145],
						[77.65974855521132, 12.959079617963113],
						[77.65971735931494, 12.95909744275634],
						[77.65969053258476, 12.95915988670627],
						[77.65969053258476, 12.95919998530989],
						[77.65968607602636, 12.959253495760427],
						[77.65969053258476, 12.95929363500513],
						[77.65969498914299, 12.959293675614756],
						[77.6597039896424, 12.959364990022504],
						[77.65970844620009, 12.959431861766646],
						[77.65971290275759, 12.959436300298988],
						[77.65973072898588, 12.959561156818454],
						[77.65977529454418, 12.959877782922112],
						[77.65977975109902, 12.959922406571634],
						[77.65981094697804, 12.960158713941732],
						[77.65981540353147, 12.960194369334786],
						[77.659837686296, 12.960292448446484],
						[77.6599179915949, 12.960292493903975],
						[77.66020784168889, 12.960894473660998],
						[77.65979757731667, 12.961077249684617],
						[77.65985559988779, 12.961251200767487],
						[77.65995364398336, 12.961585652690006],
						[77.65996255707869, 12.96163023143194],
						[77.65999375290681, 12.961732791151585],
						[77.66014090621974, 12.962348135001054],
						[77.66028360280477, 12.962825291102789],
						[77.65938731144203, 12.963012505524532],
						[77.65936948515912, 12.963288989170188],
						[77.65902615389197, 12.9632488534368],
						[77.65804963327396, 12.963186451386202],
						[77.65773749441888, 12.96317307324456],
						[77.65752794494607, 12.963097251772217],
						[77.65710430077834, 12.963079401558861],
						[77.65670739507405, 12.963079386976965],
						[77.65662271817978, 12.962976855255464],
						[77.656163417007, 12.962312431311263],
						[77.65604299869403, 12.962044948280415],
						[77.65590921012638, 12.96171051212781],
						[77.65578887892684, 12.961358180512535],
						[77.65572194081801, 12.961104037536106],
						[77.65569520051794, 12.960840952540288],
						[77.65567737364766, 12.960395048408339],
						[77.65564171989864, 12.96024346948867],
						[77.65553912792025, 12.95991346345002],
						[77.65540088202235, 12.95955226157924],
						[77.65540088202235, 12.959547777478463],
						[77.65539642529335, 12.959547823011125],
						[77.65539196856416, 12.959543384443203],
						[77.65537859837556, 12.959480916119396],
						[77.6553251176052, 12.95940514665109],
						[77.65528055027708, 12.95936947053545],
						[77.65517795796814, 12.959373876648867],
						[77.65456703367978, 12.959422954226072],
						[77.65244454619456, 12.959503197526454],
						[77.65042007096652, 12.959654854249514],
						[77.64840010359437, 12.959877762491557],
						[77.64650052853911, 12.960105207202014],
						[77.6457782166479, 12.960185490112913],
						[77.64252299247715, 12.960546674414799],
						[77.64240264691689, 12.960560054718359],
						[77.64250070627203, 12.960265704779276],
						[77.6425051635134, 12.96025677784498],
						[77.64258102398664, 12.9601141645549],
						[77.64264788254091, 12.959971465233236],
						[77.6427058265891, 12.959824286375676],
						[77.64275940077293, 12.959672673157982],
						[77.6428084303054, 12.9595211011637],
						[77.64284408813373, 12.959369492683349],
						[77.64287083149756, 12.959253501351768],
						[77.64287528872424, 12.959204477887136],
						[77.64291094653136, 12.95901717090576],
						[77.64295560617083, 12.958829864015811],
						[77.6430046356176, 12.958647078016307],
						[77.64301355006018, 12.958611374942926],
						[77.64302246450202, 12.958571274211502],
						[77.64304475060356, 12.958508876831544],
						[77.64307149391959, 12.958455315733643],
						[77.64310715166437, 12.958401836514234],
						[77.64314726661372, 12.958348312004583],
						[77.64319192615918, 12.958303714158555],
						[77.64324541271472, 12.958268075525266],
						[77.64329889924477, 12.958232350649574],
						[77.6433568429569, 12.958205634456558],
						[77.64341933124038, 12.958187758666986],
						[77.64342378844529, 12.958183315704579],
						[77.64376708008737, 12.957973744801725],
						[77.64338367359477, 12.957844414531364],
						[77.6436021638924, 12.957264728488596],
						[77.64374925132121, 12.956546852927136],
						[77.64382065376472, 12.956257011386068],
						[77.6437002221997, 12.955753113244294],
						[77.64340150241898, 12.954700735641469],
						[77.64204589192715, 12.954700778730388],
						[77.64139041067156, 12.954700788238108],
						[77.64132800865652, 12.954700818978418],
						[77.64131909408012, 12.954535787932125],
						[77.641310179503, 12.954424303004187],
						[77.64129235034665, 12.954286092784365],
						[77.64110059933905, 12.953866914265598],
						[77.64077058407607, 12.953269446587779],
						[77.64092667722124, 12.953269416720286],
						[77.64102028056537, 12.9532694134812],
						[77.64153313111619, 12.95343442081901],
						[77.64173825320108, 12.953510200671154],
						[77.64179174044922, 12.953536989921755],
						[77.6418318558686, 12.953590472406388],
						[77.64187197127366, 12.953635073762767],
						[77.64191663132647, 12.953706363704844],
						[77.64198794753834, 12.953777722417785],
						[77.64207263548111, 12.953857998809154],
						[77.64220644055253, 12.953920449505464],
						[77.64236253170148, 12.953956116148687],
						[77.64241601865218, 12.953956089427498],
						[77.64246950557742, 12.953956062693232],
						[77.64257656674826, 12.953947218392914],
						[77.6426389680693, 12.953947187154219],
						[77.64267908318595, 12.953933845258707],
						[77.64271919828826, 12.953915933415155],
						[77.64282625922098, 12.953853543087364],
						[77.64302246450202, 12.953768861768005],
						[77.64367347903347, 12.9535414284962],
						[77.64399011421685, 12.953443281243157],
						[77.64408817218523, 12.953420985244598],
						[77.6441416583137, 12.95341207681253],
						[77.644208603334, 12.953389843929735],
						[77.64427100374778, 12.953336266232867],
						[77.64427991808977, 12.953291683242838],
						[77.6442843752605, 12.953238178236104],
						[77.64432894695807, 12.952877044959681],
						[77.64445829217631, 12.952083241824196],
						[77.64457863553267, 12.951525869739662],
						[77.64463666597938, 12.951204875522716],
						[77.64477483775053, 12.950709837140863],
						[77.64498441105472, 12.950210456096965],
						[77.64516278402161, 12.949849264869089],
						[77.64535007096197, 12.949546011190249],
						[77.64555964319194, 12.949242875350052],
						[77.64605464460325, 12.948560563210956],
						[77.64632661478822, 12.948163757489544],
						[77.64646487187244, 12.947976456720914],
						[77.64656301507222, 12.947865009636699],
						[77.6466878132468, 12.947766908358343],
						[77.64699552579985, 12.94758848747787],
						[77.6476688024671, 12.947267433639652],
						[77.64782042898882, 12.947173804808203],
						[77.6482529348692, 12.946759152979963],
						[77.64889954946158, 12.946130389836934],
						[77.64908237318454, 12.946001090464382],
						[77.6491804267029, 12.946553973579244],
						[77.64922954082157, 12.94673678359983],
						[77.64927411055479, 12.946852727213546],
						[77.6492830244993, 12.946883934581399],
						[77.64941236400671, 12.947271909387975],
						[77.6494346488392, 12.947347729484983],
						[77.64942573490673, 12.947387827730907],
						[77.64939453613752, 12.947427981387571],
						[77.6493587929991, 12.947468090212618],
						[77.64925628266361, 12.94757510541707],
						[77.64919834199779, 12.947664334299574],
						[77.64918942804654, 12.947673220103928],
						[77.64916705577362, 12.947704483785998],
						[77.64912694297624, 12.947806981165845],
						[77.64910911506169, 12.947887264080286],
						[77.64910020110335, 12.947936329587012],
						[77.64909574412391, 12.947954136987558],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000004a",
			properties: {
				AREA_SQ_KM: 2.132754858944253,
				ASS_CONST1: "C.V. Ramannagar (SC)",
				ASS_CONST_: "161",
				LAT: 12.9557797461543,
				LON: 77.6522047334089,
				LOST_AREA_SQ_KM: 0.04779062909854067,
				OBJECTID: 75,
				POP_F: 13865.0,
				POP_M: 15479.0,
				POP_SC: 4133.0,
				POP_ST: 110.0,
				POP_TOTAL: 29344.0,
				RESERVATIO: "General",
				WARD_NAME: "Konena Agrahara",
				WARD_NO: 113.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.66229470527324, 13.012388134750111],
						[77.66227687945299, 13.012388142129995],
						[77.66216983709035, 13.012343579006407],
						[77.66209844633121, 13.012312373456895],
						[77.66186662265879, 13.012174169465217],
						[77.66151429869173, 13.011986874474617],
						[77.66099708300331, 13.011808490171958],
						[77.66000266599895, 13.011442857016172],
						[77.65931154972003, 13.011166403844154],
						[77.6588968254758, 13.010943417701116],
						[77.658562405967, 13.010769491885235],
						[77.65835731464828, 13.010644649745238],
						[77.65791139047285, 13.010359259822115],
						[77.65789356395511, 13.010359266952916],
						[77.65749666104621, 13.010091759064556],
						[77.65644882012612, 13.009315851850136],
						[77.6561322201125, 13.009070575992455],
						[77.65604745539734, 13.008990331948848],
						[77.65594495117675, 13.008874383879851],
						[77.6558200759169, 13.008736140084098],
						[77.65547227704907, 13.008352718922271],
						[77.65472756438673, 13.007545566083314],
						[77.65452692281401, 13.00727799598399],
						[77.65400967487344, 13.006783099763792],
						[77.65363512959703, 13.006466466983497],
						[77.65313124800015, 13.006025011249807],
						[77.6529929991328, 13.005909076406706],
						[77.65293951722437, 13.005864497816246],
						[77.65287257741414, 13.005846705830347],
						[77.65246683042454, 13.005909096251788],
						[77.65168198591459, 13.006047317446008],
						[77.65129851907905, 13.006118711526991],
						[77.6512539501503, 13.006185584484829],
						[77.65141448560087, 13.006493237681113],
						[77.65153936576793, 13.006729563305012],
						[77.65152599511988, 13.006751868220752],
						[77.65135654604994, 13.006805413627937],
						[77.65099981952793, 13.006876710247775],
						[77.65079017007, 13.006916851729082],
						[77.65066983337624, 13.006965939444566],
						[77.65049146916492, 13.007059514877772],
						[77.65026844795872, 13.007184417268322],
						[77.64989389018594, 13.00742074558585],
						[77.64968432605201, 13.007487632442372],
						[77.64954161597358, 13.007518823219204],
						[77.64887280753449, 13.007567883723972],
						[77.64826193628727, 13.007621363611372],
						[77.64809248228615, 13.007634792227305],
						[77.64788737181448, 13.007603547887614],
						[77.64776694461378, 13.007608029957112],
						[77.64765543135766, 13.007639251143704],
						[77.64746369122084, 13.007710528519025],
						[77.64724520844126, 13.007790873198342],
						[77.64670118447155, 13.008058392772648],
						[77.64627312970109, 13.007518858583797],
						[77.64618844492765, 13.007465361001204],
						[77.64610367269623, 13.007389516537447],
						[77.64595650095885, 13.007228981896928],
						[77.64581387353276, 13.007028293812297],
						[77.64570235833557, 13.006809863484024],
						[77.64570235833557, 13.006805377718463],
						[77.64570235833557, 13.006800891953072],
						[77.64566224429946, 13.006716151136953],
						[77.64553735758976, 13.006528910863654],
						[77.64552398622634, 13.006421904578314],
						[77.64546604363302, 13.006408511036485],
						[77.64512712692988, 13.006439764002161],
						[77.64486852528101, 13.00643527896324],
						[77.64427991808977, 13.006386215930554],
						[77.64366456464332, 13.006359503120443],
						[77.64318301173077, 13.006319289696366],
						[77.64267462595147, 13.0062524248694],
						[77.64253190695798, 13.00623909864248],
						[77.64182294133221, 13.006167685580092],
						[77.6411273431182, 13.006087424113884],
						[77.64091330530857, 13.006065149585469],
						[77.64052980167129, 13.006002728743715],
						[77.64047631382199, 13.005989375844967],
						[77.64026227477136, 13.005949244428793],
						[77.63993225704452, 13.005842207333368],
						[77.63937491293461, 13.005650492286758],
						[77.63907618164582, 13.005570233043281],
						[77.63873278826107, 13.005503354562023],
						[77.6384786292844, 13.005467677682006],
						[77.63830470310577, 13.005467600634548],
						[77.63811294699669, 13.005498842249409],
						[77.63802379864852, 13.005485500534306],
						[77.63772952119697, 13.005338342729505],
						[77.63757342360798, 13.005231295170654],
						[77.63750210459254, 13.005159979960139],
						[77.63690008639325, 13.004495562666918],
						[77.63646753677247, 13.004138790005884],
						[77.63645862181026, 13.004134393584442],
						[77.63643187691937, 13.004103175910704],
						[77.63629815236939, 13.003991684212693],
						[77.63624903259277, 13.00394265679186],
						[77.63632489729213, 13.003893597822543],
						[77.63648536669479, 13.00379546853063],
						[77.63666375316369, 13.003697337553701],
						[77.6370160677926, 13.0035012059265],
						[77.63794356507468, 13.003001732302122],
						[77.63833144755077, 13.002809981438473],
						[77.63844742747219, 13.00274309559749],
						[77.63849645888749, 13.002698526009373],
						[77.63854103288284, 13.002627174233321],
						[77.63861243863852, 13.002466711403503],
						[77.63867038476918, 13.002252638664668],
						[77.63873278826107, 13.002141170347107],
						[77.63883093822088, 13.001980612622193],
						[77.63913858491223, 13.001525796687927],
						[77.63948634701818, 13.001021929258144],
						[77.63961569781574, 13.000807923945956],
						[77.63990105563616, 13.000312877408119],
						[77.64039153727663, 12.999425548377452],
						[77.64095787834462, 12.998444566922299],
						[77.64153758839598, 12.99757946999633],
						[77.6422108978056, 12.996544975864362],
						[77.6426166818872, 12.995983176476837],
						[77.64193446038368, 12.99598760085731],
						[77.64189871486906, 12.995951899950233],
						[77.6418407704043, 12.995925138971465],
						[77.64175162501552, 12.995907316997355],
						[77.64161781940203, 12.995902876891211],
						[77.6414885583085, 12.995889511799858],
						[77.64148410102675, 12.995889470314427],
						[77.64145726993489, 12.99588499067729],
						[77.6414037825274, 12.995876126043521],
						[77.64133246594446, 12.995853812086525],
						[77.641296807636, 12.995840455587436],
						[77.64119865983143, 12.995425771503385],
						[77.64098016485602, 12.995613064719933],
						[77.64066360858138, 12.995836017053337],
						[77.64060111876405, 12.995581841271546],
						[77.64055654558639, 12.995501553999533],
						[77.64040499664995, 12.995077925082446],
						[77.63988322625606, 12.995189414398023],
						[77.63969147284213, 12.99520729133049],
						[77.63953992273821, 12.995233998875317],
						[77.63929913769462, 12.995269704723492],
						[77.63919653077143, 12.995296433245702],
						[77.6391920733987, 12.995296391721979],
						[77.63903597784235, 12.99533659510793],
						[77.63886213992656, 12.995363353222366],
						[77.63858114946353, 12.995367801042374],
						[77.6384697144818, 12.995376681851562],
						[77.63830024569766, 12.995439099903662],
						[77.63811294699669, 12.995474785219892],
						[77.63795693734096, 12.995474797305224],
						[77.63790336086579, 12.99527409815803],
						[77.63781421235129, 12.99436001073164],
						[77.63794356507468, 12.994310933668736],
						[77.6379792244479, 12.99427081302457],
						[77.63806391541395, 12.99423960208391],
						[77.6379792244479, 12.993802629649013],
						[77.63793910765223, 12.993597490968416],
						[77.63747535995009, 12.993610931527233],
						[77.63726131518018, 12.99294202810977],
						[77.63716316384878, 12.992723495690829],
						[77.63722565557904, 12.992723563775025],
						[77.63752439178973, 12.992647688065492],
						[77.63772952119697, 12.992638820589555],
						[77.6378810737438, 12.992629881821546],
						[77.63798368186876, 12.992607552667437],
						[77.63822892714371, 12.99254963566721],
						[77.63867038476918, 12.992438174365587],
						[77.63953100801986, 12.992250835912039],
						[77.6402221587912, 12.992112630348895],
						[77.64094896373884, 12.992001147035722],
						[77.64164456305798, 12.99189411483482],
						[77.64248733454683, 12.99176930263294],
						[77.6433568429569, 12.99163102077274],
						[77.64411491525264, 12.991497307086343],
						[77.64488635386938, 12.991332318191924],
						[77.6449799539118, 12.991309984471515],
						[77.64544821513675, 12.991180645802155],
						[77.64640247267853, 12.991033565183212],
						[77.64669227032188, 12.990926540516115],
						[77.64715161028833, 12.990783831754698],
						[77.64735226505643, 12.990605424747354],
						[77.64757074762753, 12.99035576507929],
						[77.64778477274162, 12.990150656962266],
						[77.64797205530563, 12.989945473604566],
						[77.64813259545342, 12.989704678935023],
						[77.64822619278796, 12.989504058873672],
						[77.64831987743676, 12.989298958053968],
						[77.64835553351391, 12.989129456773282],
						[77.64841793162161, 12.988911039326492],
						[77.64844021665165, 12.988710363952228],
						[77.64849378809795, 12.988389315147776],
						[77.64826193628727, 12.987078300351675],
						[77.64840010359437, 12.98713624524962],
						[77.64921171292333, 12.987475145369089],
						[77.65009462743997, 12.987903285151562],
						[77.6510399316555, 12.988362535269506],
						[77.65207445255095, 12.98885306078015],
						[77.65316690254764, 12.989356887143549],
						[77.65426388606187, 12.989869729336165],
						[77.65561052285894, 12.990529615376646],
						[77.65664054489967, 12.99102018696466],
						[77.65729602443052, 12.99135462393883],
						[77.65783554036791, 12.991581978945893],
						[77.65892802160066, 12.992143861405783],
						[77.66006068846386, 12.992674461812268],
						[77.66106393057841, 12.99316944628807],
						[77.66218320646857, 12.993682240874218],
						[77.66328018909796, 12.99416386294909],
						[77.66346744577277, 12.994257456952742],
						[77.66367252773537, 12.994360013586387],
						[77.66385987113435, 12.994453698078551],
						[77.6640293012985, 12.994525045403734],
						[77.66412734174054, 12.99456510989031],
						[77.66412734174054, 12.994569595441702],
						[77.66406495237827, 12.994676588327001],
						[77.66380630703222, 12.995104728620172],
						[77.66379739424276, 12.995113617711572],
						[77.66360568171724, 12.995434638093563],
						[77.6633648609603, 12.995853799863783],
						[77.66333366607108, 12.995907339660164],
						[77.66321771188294, 12.996094577003808],
						[77.66278517636148, 12.996825876593922],
						[77.66291004385828, 12.996892762213998],
						[77.66338268660738, 12.997178169410262],
						[77.66369035333346, 12.997334212758174],
						[77.66404267045473, 12.99751706725415],
						[77.6640382140695, 12.997521511866083],
						[77.66396682450143, 12.997632992446556],
						[77.66396236811318, 12.997646408390438],
						[77.66385532736159, 12.997811388592956],
						[77.66374391749106, 12.998029901322024],
						[77.66360122531461, 12.998346462615217],
						[77.6635610303025, 12.9987076184708],
						[77.66344516373033, 13.000250503708466],
						[77.66338268660738, 13.00154364074549],
						[77.66327564530167, 13.003064209851136],
						[77.66326227605386, 13.003099887058555],
						[77.66323108113608, 13.003683970419143],
						[77.66318206053343, 13.004473294847704],
						[77.66317760411394, 13.00452673959792],
						[77.66312849610708, 13.00498160391114],
						[77.66312849610708, 13.005003861042246],
						[77.66299925980817, 13.005748541824438],
						[77.66285211025041, 13.006627033439656],
						[77.66275398131079, 13.00727357375888],
						[77.66264693940415, 13.007844376008284],
						[77.66258900566018, 13.008303659518429],
						[77.66255335411053, 13.008691546281941],
						[77.66253098450495, 13.009003721772649],
						[77.66250870227621, 13.009329187959562],
						[77.66249533293683, 13.009592277174129],
						[77.66247750714852, 13.010011476899981],
						[77.66243294266532, 13.010515321911349],
						[77.66242848621603, 13.010559969445909],
						[77.66237046497764, 13.011260044265503],
						[77.66234372626428, 13.011844120440168],
						[77.66229470527324, 13.012388134750111],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000004b",
			properties: {
				AREA_SQ_KM: 4.940323565270701,
				ASS_CONST1: "C.V. Ramannagar (SC)",
				ASS_CONST_: "161",
				LAT: 12.9995016788585,
				LON: 77.6517042882124,
				LOST_AREA_SQ_KM: 0.18872497765852983,
				OBJECTID: 76,
				POP_F: 14979.0,
				POP_M: 17006.0,
				POP_SC: 7346.0,
				POP_ST: 947.0,
				POP_TOTAL: 31985.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Benniganahalli",
				WARD_NO: 50.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.66412734174054, 12.99456510989031],
						[77.6640293012985, 12.994525045403734],
						[77.66385987113435, 12.994453698078551],
						[77.66367252773537, 12.994360013586387],
						[77.66346744577277, 12.994257456952742],
						[77.66328018909796, 12.99416386294909],
						[77.66218320646857, 12.993682240874218],
						[77.66106393057841, 12.99316944628807],
						[77.66006068846386, 12.992674461812268],
						[77.65892802160066, 12.992143861405783],
						[77.65783554036791, 12.991581978945893],
						[77.65729602443052, 12.99135462393883],
						[77.65664054489967, 12.99102018696466],
						[77.65561052285894, 12.990529615376646],
						[77.65426388606187, 12.989869729336165],
						[77.65316690254764, 12.989356887143549],
						[77.65207445255095, 12.98885306078015],
						[77.6510399316555, 12.988362535269506],
						[77.65009462743997, 12.987903285151562],
						[77.64921171292333, 12.987475145369089],
						[77.64840010359437, 12.98713624524962],
						[77.64826193628727, 12.987078300351675],
						[77.64803891064822, 12.986989117155678],
						[77.64811922439927, 12.986195409195945],
						[77.64842238862796, 12.985374958809695],
						[77.64890846343586, 12.984451933569131],
						[77.64968878300805, 12.983065113210085],
						[77.6504378986727, 12.981959265860473],
						[77.6510087333353, 12.981196767135662],
						[77.65174446956142, 12.980460972754715],
						[77.65280572495281, 12.979395268960271],
						[77.65308222297884, 12.979087592119672],
						[77.65366632718649, 12.979390786963664],
						[77.65351470856805, 12.979653907443684],
						[77.65540533875118, 12.980340618492626],
						[77.65731385104333, 12.98099609846153],
						[77.65828592145948, 12.981281479227807],
						[77.65843307701554, 12.981441964733566],
						[77.6584732738678, 12.982093005698795],
						[77.65852675313583, 12.982481019102881],
						[77.658593602185, 12.982917974627393],
						[77.65868727817107, 12.98319889519699],
						[77.65911091635404, 12.983528827822681],
						[77.65978866420821, 12.983948041437401],
						[77.66039956003056, 12.984095206741241],
						[77.66051106061074, 12.984095199336464],
						[77.66100599601563, 12.983881106282338],
						[77.66107738747107, 12.983778607878962],
						[77.66138060403917, 12.983524367924787],
						[77.66167936330734, 12.983341589420336],
						[77.66225005333578, 12.983225652829052],
						[77.66301262908739, 12.983292527555939],
						[77.66361459452196, 12.983836535462757],
						[77.66380185063758, 12.983885608827665],
						[77.66415416740803, 12.983854348443012],
						[77.66436370448538, 12.98372948129547],
						[77.66440826820022, 12.983524435927054],
						[77.66415862378865, 12.982837732098911],
						[77.6646223485439, 12.982552357613137],
						[77.66496120630829, 12.982619209196708],
						[77.66526886869703, 12.982641527308887],
						[77.66548294739516, 12.982552326327118],
						[77.66560335556062, 12.982400679890432],
						[77.66573713255048, 12.98215100112416],
						[77.66620530706686, 12.98215547003862],
						[77.66626778261481, 12.982351665240015],
						[77.66649959717539, 12.982311527044185],
						[77.667351269089, 12.982320462704909],
						[77.66827877760564, 12.982257984838517],
						[77.66827877760564, 12.982253585951229],
						[77.66834125199625, 12.982151002477858],
						[77.66839918276366, 12.982124276395158],
						[77.66845720087774, 12.982093069338921],
						[77.6684928505474, 12.98209305030539],
						[77.66853295641215, 12.982093072012038],
						[77.6690947865144, 12.982079677665398],
						[77.66916171663095, 12.981227945821221],
						[77.66891199544388, 12.9805546174689],
						[77.6692285593314, 12.980536816105253],
						[77.66957194669256, 12.980541256853446],
						[77.66969680661937, 12.98045658017631],
						[77.66990633332138, 12.980389668227165],
						[77.66988405256063, 12.9803316760975],
						[77.66990187716958, 12.980193491214271],
						[77.66997771910148, 12.980126565887979],
						[77.67011149086296, 12.980059651956989],
						[77.67022743791026, 12.98005967891977],
						[77.67017833299607, 12.979881290337659],
						[77.67025863087835, 12.97973859066793],
						[77.67044141974911, 12.979720769769594],
						[77.67065103243173, 12.97975650030743],
						[77.67079371562131, 12.979582542943358],
						[77.67088738140066, 12.979417539716682],
						[77.67098995931906, 12.979265986838477],
						[77.67098995931906, 12.979163434190951],
						[77.67090966196163, 12.979042981706082],
						[77.67017833299607, 12.979346187548499],
						[77.6699509822051, 12.979297188311289],
						[77.66977701747159, 12.978418769844959],
						[77.66975473668508, 12.978164559720057],
						[77.67028982383776, 12.97789259291084],
						[77.67007138556393, 12.977482290023705],
						[77.66954075353304, 12.976327389618183],
						[77.66921073461518, 12.975257219984119],
						[77.66944263045632, 12.975176974687209],
						[77.66988405256063, 12.975020933282556],
						[77.67045933164438, 12.97480238023662],
						[77.67065103243173, 12.97473107848097],
						[77.6710345203957, 12.974619613985862],
						[77.67124413196716, 12.974557189163214],
						[77.67140463876991, 12.974512598052353],
						[77.67235885001648, 12.97420038126216],
						[77.67281817213262, 12.974044326457692],
						[77.67329086041416, 12.97387933604358],
						[77.6734825563494, 12.973821399639139],
						[77.67387049060056, 12.973705484660968],
						[77.67406672890071, 12.973643021186625],
						[77.67443683724208, 12.973518145615808],
						[77.67458842753354, 12.973473556859823],
						[77.67495407819786, 12.973375462809486],
						[77.67554715491707, 12.973246149334225],
						[77.67584587636925, 12.973197120916211],
						[77.67597518520313, 12.973179324250582],
						[77.67630072826738, 12.973094576661728],
						[77.67667082921484, 12.973018771606561],
						[77.67768300885879, 12.972809174165436],
						[77.67810220668719, 12.97274226748208],
						[77.67812002998643, 12.972809185375343],
						[77.6781869547024, 12.973032144388903],
						[77.67819141052411, 12.973032184814029],
						[77.67821814545059, 12.97319267725358],
						[77.67823596873136, 12.973281847609625],
						[77.67826270364719, 12.973357644086631],
						[77.67840537712584, 12.973767848303538],
						[77.67873536891369, 12.974748922595447],
						[77.6793016909134, 12.976269415730124],
						[77.67930614669066, 12.97629619404912],
						[77.67932396979796, 12.976407706208203],
						[77.67932396979796, 12.97650128890061],
						[77.67931505824467, 12.976617216138287],
						[77.67926604468889, 12.976813424594008],
						[77.67926158891001, 12.97700969288817],
						[77.67926158891001, 12.97704979994448],
						[77.67926158891001, 12.977054285035491],
						[77.67927941202441, 12.977317342518134],
						[77.67929277935833, 12.977464523575303],
						[77.6793016909134, 12.97759829527587],
						[77.67933288135053, 12.97809323575834],
						[77.67937752647089, 12.978485663105628],
						[77.6794265399778, 12.978739778222408],
						[77.67957812350396, 12.979413071945768],
						[77.67964059158315, 12.979685083222567],
						[77.67968514921272, 12.979908109328365],
						[77.6798055420916, 12.980550161777606],
						[77.67980999784878, 12.980581426389481],
						[77.67981890936261, 12.980626014700976],
						[77.67985464277857, 12.980791002842611],
						[77.67993938946509, 12.981085301456083],
						[77.68010433953253, 12.981615892975872],
						[77.68025592213941, 12.982128683335278],
						[77.6803451242575, 12.982431910705285],
						[77.68037631440345, 12.98254786360184],
						[77.68038968160619, 12.982628117048518],
						[77.68039859307379, 12.982663821859342],
						[77.68040313617465, 12.98274848386505],
						[77.68037185866885, 12.98284661445614],
						[77.6803629471991, 12.982873359127659],
						[77.68030056689106, 12.982966983774123],
						[77.68025146640001, 12.98304726983251],
						[77.68014444123355, 12.983207765133946],
						[77.6800865165497, 12.98330143038709],
						[77.68006423781715, 12.983341595806193],
						[77.67997057972427, 12.983466416326406],
						[77.67988137730723, 12.983560052485501],
						[77.67980999784878, 12.983635909392],
						[77.67980999784878, 12.983640308484189],
						[77.6798055420916, 12.983662608435358],
						[77.67907427130055, 12.984032702863827],
						[77.6786283422955, 12.984139728456608],
						[77.67848566910142, 12.9843003299978],
						[77.67820477798814, 12.984451906209157],
						[77.67792825463198, 12.984643627885381],
						[77.67758943614018, 12.984835388785275],
						[77.67701864959767, 12.985120770014426],
						[77.6765058740808, 12.985450759244118],
						[77.6757031996243, 12.985936805831066],
						[77.67488715154678, 12.986431697858126],
						[77.67448139694562, 12.98662344266622],
						[77.67373226733474, 12.986699301514435],
						[77.67350483639348, 12.98661458265467],
						[77.67312135689782, 12.986467450872432],
						[77.67302332433454, 12.986431707329983],
						[77.67271113985068, 12.986306877332671],
						[77.67261747559827, 12.986275655638146],
						[77.67219834458277, 12.986110687614811],
						[77.67214041578231, 12.986088421513628],
						[77.6719040694605, 12.985999221724851],
						[77.671761387687, 12.985945729014459],
						[77.67149830403781, 12.986066059878748],
						[77.67129760513552, 12.986173073545233],
						[77.67117274699505, 12.986222129614697],
						[77.6709988715358, 12.986048282429024],
						[77.67084282026542, 12.986079456742493],
						[77.67055291032865, 12.986137433021062],
						[77.67020961335314, 12.986177586336442],
						[77.67016496457265, 12.986182004804668],
						[77.6699999998436, 12.986195414384426],
						[77.6699376137545, 12.98619984640311],
						[77.66982166640689, 12.986204302442932],
						[77.6697949294732, 12.9862043165493],
						[77.66948273598071, 12.986190926160562],
						[77.66943371811561, 12.986186509768443],
						[77.66935341964137, 12.986173091846114],
						[77.66925983995397, 12.986155242932778],
						[77.66923301551002, 12.986150853746715],
						[77.66916617281223, 12.986128505466773],
						[77.66884060898393, 12.986017005511224],
						[77.66880495942506, 12.986003654565096],
						[77.66860434306835, 12.985910038707033],
						[77.66857306226255, 12.985896638115339],
						[77.66842146382007, 12.98582080838085],
						[77.66841700760914, 12.9858208538364],
						[77.66837244549012, 12.985825276131743],
						[77.66836798927724, 12.985820836309442],
						[77.6683635330642, 12.985820881763889],
						[77.66828323382207, 12.985749500081848],
						[77.66823867164977, 12.985727183234125],
						[77.66805587918093, 12.985624673837009],
						[77.66799340463102, 12.985593391579012],
						[77.66795329857291, 12.985611224144145],
						[77.66793547365357, 12.985633573489617],
						[77.66782398046628, 12.985825329279386],
						[77.66773031212276, 12.985959112404261],
						[77.6674672189961, 12.986034842981933],
						[77.66744493774972, 12.986092861169702],
						[77.66744939399935, 12.986311300564996],
						[77.6674092877463, 12.987368170656785],
						[77.6674226564989, 12.987947850048284],
						[77.66741374399736, 12.988549841137644],
						[77.66744939399935, 12.988977917612448],
						[77.66755197506922, 12.989419379068504],
						[77.66769911844993, 12.989691322775194],
						[77.66778387434745, 12.98975377791719],
						[77.66786854280367, 12.989776032634863],
						[77.66798449217491, 12.989793919627756],
						[77.6681717409812, 12.989985666911258],
						[77.66812272254188, 12.99001687422001],
						[77.6683144273321, 12.990217547443294],
						[77.66794438611359, 12.99040477842622],
						[77.66781952423156, 12.990511798010761],
						[77.66721303776926, 12.99087298848441],
						[77.66707926254317, 12.990966642017815],
						[77.66687418682085, 12.991113811535762],
						[77.66656652884386, 12.991350089960028],
						[77.66636582109668, 12.991519550553308],
						[77.66606707433199, 12.99179153518739],
						[77.66570139462435, 12.99218840843626],
						[77.66555870494253, 12.992353401961273],
						[77.66520647997083, 12.992803796762303],
						[77.66512172212184, 12.992933093802813],
						[77.66486762294878, 12.993338849348568],
						[77.6646891939551, 12.99361090470235],
						[77.66418981844804, 12.994453635685675],
						[77.66413179812223, 12.994560665346699],
						[77.66412734174054, 12.99456510989031],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000004c",
			properties: {
				AREA_SQ_KM: 3.5999324569817808,
				ASS_CONST1: "C.V. Ramannagar (SC)",
				ASS_CONST_: "161",
				LAT: 12.9839496974537,
				LON: 77.6652028542387,
				LOST_AREA_SQ_KM: 0.1647054419296863,
				OBJECTID: 77,
				POP_F: 13938.0,
				POP_M: 15822.0,
				POP_SC: 2710.0,
				POP_ST: 640.0,
				POP_TOTAL: 29760.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "C V Raman Nagar",
				WARD_NO: 57.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.66921073461518, 12.975257219984119],
						[77.66954075353304, 12.976327389618183],
						[77.67007138556393, 12.977482290023705],
						[77.67028982383776, 12.97789259291084],
						[77.66975473668508, 12.978164559720057],
						[77.66977701747159, 12.978418769844959],
						[77.6699509822051, 12.979297188311289],
						[77.67017833299607, 12.979346187548499],
						[77.67090966196163, 12.979042981706082],
						[77.67098995931906, 12.979163434190951],
						[77.67098995931906, 12.979265986838477],
						[77.67088738140066, 12.979417539716682],
						[77.67079371562131, 12.979582542943358],
						[77.67065103243173, 12.97975650030743],
						[77.67044141974911, 12.979720769769594],
						[77.67025863087835, 12.97973859066793],
						[77.67017833299607, 12.979881290337659],
						[77.67022743791026, 12.98005967891977],
						[77.67011149086296, 12.980059651956989],
						[77.66997771910148, 12.980126565887979],
						[77.66990187716958, 12.980193491214271],
						[77.66988405256063, 12.9803316760975],
						[77.66990633332138, 12.980389668227165],
						[77.66969680661937, 12.98045658017631],
						[77.66957194669256, 12.980541256853446],
						[77.6692285593314, 12.980536816105253],
						[77.66891199544388, 12.9805546174689],
						[77.66916171663095, 12.981227945821221],
						[77.6690947865144, 12.982079677665398],
						[77.66853295641215, 12.982093072012038],
						[77.6684928505474, 12.98209305030539],
						[77.66845720087774, 12.982093069338921],
						[77.66839918276366, 12.982124276395158],
						[77.66834125199625, 12.982151002477858],
						[77.66827877760564, 12.982253585951229],
						[77.66827877760564, 12.982257984838517],
						[77.667351269089, 12.982320462704909],
						[77.66649959717539, 12.982311527044185],
						[77.66626778261481, 12.982351665240015],
						[77.66620530706686, 12.98215547003862],
						[77.66573713255048, 12.98215100112416],
						[77.66560335556062, 12.982400679890432],
						[77.66548294739516, 12.982552326327118],
						[77.66526886869703, 12.982641527308887],
						[77.66496120630829, 12.982619209196708],
						[77.6646223485439, 12.982552357613137],
						[77.66415862378865, 12.982837732098911],
						[77.66440826820022, 12.983524435927054],
						[77.66436370448538, 12.98372948129547],
						[77.66415416740803, 12.983854348443012],
						[77.66380185063758, 12.983885608827665],
						[77.66361459452196, 12.983836535462757],
						[77.66301262908739, 12.983292527555939],
						[77.66225005333578, 12.983225652829052],
						[77.66167936330734, 12.983341589420336],
						[77.66138060403917, 12.983524367924787],
						[77.66107738747107, 12.983778607878962],
						[77.66100599601563, 12.983881106282338],
						[77.66051106061074, 12.984095199336464],
						[77.66039956003056, 12.984095206741241],
						[77.65978866420821, 12.983948041437401],
						[77.65911091635404, 12.983528827822681],
						[77.65868727817107, 12.98319889519699],
						[77.658593602185, 12.982917974627393],
						[77.65852675313583, 12.982481019102881],
						[77.6584732738678, 12.982093005698795],
						[77.65843307701554, 12.981441964733566],
						[77.65828592145948, 12.981281479227807],
						[77.65731385104333, 12.98099609846153],
						[77.65540533875118, 12.980340618492626],
						[77.65351470856805, 12.979653907443684],
						[77.65366632718649, 12.979390786963664],
						[77.65308222297884, 12.979087592119672],
						[77.65280572495281, 12.979395268960271],
						[77.65174446956142, 12.980460972754715],
						[77.6510087333353, 12.981196767135662],
						[77.6504378986727, 12.981959265860473],
						[77.64968878300805, 12.983065113210085],
						[77.64890846343586, 12.984451933569131],
						[77.64842238862796, 12.985374958809695],
						[77.64811922439927, 12.986195409195945],
						[77.64803891064822, 12.986989117155678],
						[77.64740129258239, 12.98673497865035],
						[77.64648270020719, 12.986342561319951],
						[77.64559093042207, 12.985999212604755],
						[77.64502015558674, 12.985767340534236],
						[77.64526092836017, 12.985018172289594],
						[77.6455507289516, 12.983354974680699],
						[77.64604573040224, 12.981326032563384],
						[77.6463578144106, 12.979707414557767],
						[77.64662541417687, 12.978726442875015],
						[77.6466610707925, 12.9773307411075],
						[77.6466878132468, 12.977295024331543],
						[77.64670564154612, 12.977103297342614],
						[77.64677258503691, 12.975926099332083],
						[77.64684835523222, 12.974789006672204],
						[77.64690629711176, 12.973638565322197],
						[77.64691966831047, 12.973411185483242],
						[77.64692412537636, 12.973379921196683],
						[77.64692858244207, 12.973304156775692],
						[77.64694204103156, 12.973264009808325],
						[77.64696432635479, 12.972813609108194],
						[77.64702672523624, 12.972376687960336],
						[77.64711586643527, 12.9718548960145],
						[77.64732997981025, 12.97092747346328],
						[77.64746369122084, 12.97042360349185],
						[77.64755291946565, 12.970075765647112],
						[77.64773128834958, 12.968845069429051],
						[77.64858738513114, 12.96879155219527],
						[77.64949704634665, 12.968653323898574],
						[77.6500990843797, 12.968501655716354],
						[77.65114244036077, 12.968220802345213],
						[77.65246683042454, 12.967944341785142],
						[77.65398293416489, 12.967756991489292],
						[77.65480341659507, 12.967667852480833],
						[77.6563997965557, 12.967467165324292],
						[77.65791139047285, 12.96726654878108],
						[77.65872293095117, 12.967172913457105],
						[77.65866053857854, 12.967427093818445],
						[77.65866499517774, 12.96742704832215],
						[77.65866945177677, 12.967427089065096],
						[77.65810756936555, 12.968220774278173],
						[77.65728711112305, 12.968301042936055],
						[77.65733167765329, 12.968470480545234],
						[77.65738078820137, 12.968889628132464],
						[77.65756359809505, 12.969429185430707],
						[77.65762599107846, 12.96990183811382],
						[77.65767955813595, 12.970793634806276],
						[77.65868727817107, 12.971324299695292],
						[77.65857131917303, 12.971542807518576],
						[77.6595612923191, 12.971538352504645],
						[77.65974409865522, 12.971547273042647],
						[77.66005623192018, 12.971551688025531],
						[77.66051106061074, 12.971560602192136],
						[77.66057345195279, 12.971565053791227],
						[77.66090786545855, 12.971582952809706],
						[77.66101936553278, 12.9715874255594],
						[77.661086300477, 12.97158735107956],
						[77.66132258225795, 12.97160072656542],
						[77.66140734298244, 12.97160521337531],
						[77.6614251689411, 12.971609688511323],
						[77.6614429948969, 12.971618648289004],
						[77.66145190787374, 12.97162752657771],
						[77.66148310328708, 12.971640920746722],
						[77.66150092923367, 12.971658763571533],
						[77.66152329904496, 12.971694417422812],
						[77.66154112498515, 12.9717478787128],
						[77.66154558146975, 12.97182372729612],
						[77.66154558146975, 12.971881682809908],
						[77.6615589509225, 12.971921821969055],
						[77.6615589509225, 12.971930705037343],
						[77.6615812333402, 12.971953073323208],
						[77.66161242871753, 12.971975350619855],
						[77.66165253704703, 12.971984255433416],
						[77.6616569935272, 12.971984209935467],
						[77.66171947161288, 12.9719575316119],
						[77.66189781795664, 12.971868351109762],
						[77.6621921193865, 12.971756861701342],
						[77.66225450979219, 12.971734577423932],
						[77.66252652805956, 12.971654276415604],
						[77.66280300210082, 12.971582898783575],
						[77.66281637140347, 12.971578450030051],
						[77.66292341314816, 12.971560630299347],
						[77.66303936764103, 12.971547290348214],
						[77.66314640917258, 12.971560604400494],
						[77.6634227943029, 12.971578483755133],
						[77.66383304539636, 12.971609666198201],
						[77.66419873120627, 12.971676577253069],
						[77.6645866976416, 12.97174791725561],
						[77.66474721399207, 12.971765772336989],
						[77.66501031351137, 12.971801470904127],
						[77.66504596427879, 12.971805849778844],
						[77.6650727023469, 12.971810319789725],
						[77.66514409120987, 12.97181037103017],
						[77.66520202363192, 12.971810382298633],
						[77.66525104335024, 12.971810312211419],
						[77.66531351943071, 12.97180139889573],
						[77.66539373342418, 12.971779103697285],
						[77.66550522903206, 12.971725618134363],
						[77.66563454981747, 12.971663239498971],
						[77.66571930727824, 12.971623050187182],
						[77.665844171503, 12.971569600144715],
						[77.66589319094015, 12.971551677319157],
						[77.6659199288059, 12.971538380893824],
						[77.66599131712864, 12.971520492995458],
						[77.66604033650137, 12.971516024231475],
						[77.6660804432449, 12.971511560401128],
						[77.6661250936549, 12.971511626092019],
						[77.66617411296905, 12.97152940832139],
						[77.66623204485809, 12.971587375458743],
						[77.66635245221784, 12.971738973630805],
						[77.66635245221784, 12.971743458344651],
						[77.66643275284514, 12.971828196348323],
						[77.666553159989, 12.972465871167014],
						[77.66682516781327, 12.972563918856473],
						[77.66699013722216, 12.972635285666556],
						[77.66699013722216, 12.972728948119011],
						[77.66697676841788, 12.972862679085626],
						[77.66682962408757, 12.973041069049223],
						[77.6666734796253, 12.973125797016946],
						[77.66668247956385, 12.973143649109732],
						[77.66668247956385, 12.973152532407866],
						[77.66674041118979, 12.973295193864818],
						[77.6667849739586, 12.973433421251961],
						[77.66681171161135, 12.973576143459884],
						[77.66682962408757, 12.973678684295573],
						[77.66686081800275, 12.973874920126274],
						[77.66691429326563, 12.97423609029865],
						[77.66691874953639, 12.974262867377732],
						[77.66692320580698, 12.97429852785452],
						[77.66692320580698, 12.974338632492085],
						[77.66691874953639, 12.974365414499509],
						[77.66691874953639, 12.974383267539919],
						[77.6669098369947, 12.97439664066228],
						[77.66689646818084, 12.974409973112229],
						[77.66687864309321, 12.974427835958162],
						[77.66683853663565, 12.974441183101737],
						[77.66674932374498, 12.974450111277799],
						[77.66674041118979, 12.974450116171367],
						[77.66663782937617, 12.974454609939071],
						[77.66662000424735, 12.974459018290787],
						[77.6666155479647, 12.974463548679084],
						[77.66661109168189, 12.974476876216816],
						[77.6665843539812, 12.974753311425795],
						[77.6665843539812, 12.9747756493393],
						[77.666553159989, 12.975074382478583],
						[77.66651296603665, 12.975328613448204],
						[77.66650850974973, 12.975341941065874],
						[77.66650850974973, 12.97535979423205],
						[77.66653524746853, 12.975377632822415],
						[77.66662446052982, 12.975417779599603],
						[77.6667849739586, 12.975462372304047],
						[77.6667849739586, 12.975466770918128],
						[77.66693657461764, 12.975511368441717],
						[77.66721303776926, 12.975555988020671],
						[77.66730670654566, 12.975564867530755],
						[77.66735572534236, 12.975569368646532],
						[77.6674761314934, 12.975578319674309],
						[77.66752969383971, 12.975578294489527],
						[77.66785071787085, 12.97556044560989],
						[77.66791319250041, 12.975560415419285],
						[77.66795775480229, 12.975551507344257],
						[77.66826095273814, 12.97550244595542],
						[77.66853741261845, 12.975462368667042],
						[77.66880050322942, 12.975422255279028],
						[77.66893427639819, 12.975377595011537],
						[77.66914389190408, 12.975288435850125],
						[77.66921073461518, 12.975257219984119],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000004d",
			properties: {
				AREA_SQ_KM: 3.3707894605038566,
				ASS_CONST1: "C.V. Ramannagar (SC)",
				ASS_CONST_: "161",
				LAT: 12.9765036486498,
				LON: 77.6567910593107,
				LOST_AREA_SQ_KM: 0.20353510201754105,
				OBJECTID: 78,
				POP_F: 16365.0,
				POP_M: 18212.0,
				POP_SC: 2498.0,
				POP_ST: 368.0,
				POP_TOTAL: 34577.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "New Tippasandara",
				WARD_NO: 58.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61932677889898, 12.98727896350875],
						[77.61933123705928, 12.987279005324279],
						[77.61941594207161, 12.987274538943288],
						[77.61942931654141, 12.987274491896807],
						[77.61963002081247, 12.987287848017447],
						[77.619977843264, 12.987336908528778],
						[77.62084289288347, 12.987484050486007],
						[77.62149840655339, 12.9875643708173],
						[77.6218506812916, 12.987600016670198],
						[77.6222341612481, 12.987689167298713],
						[77.62232332211754, 12.987689226324624],
						[77.62239465076223, 12.987689204523427],
						[77.62246152132555, 12.987640153968627],
						[77.62252839184914, 12.987542030207306],
						[77.62253284988263, 12.987461778266908],
						[77.62253284988263, 12.987381484626903],
						[77.62250164364448, 12.98720318390184],
						[77.62251501774762, 12.987105076881335],
						[77.62249272757485, 12.986534277431518],
						[77.62244368917922, 12.985869915068946],
						[77.62244814721608, 12.985187687591774],
						[77.62242139899233, 12.98444299132469],
						[77.62245260525275, 12.983876672677376],
						[77.62247489543346, 12.983684906875853],
						[77.62253284988263, 12.983439660173161],
						[77.62267559427349, 12.982868959231805],
						[77.6227825868886, 12.982378385580299],
						[77.62288066336312, 12.981950340922172],
						[77.62290741147768, 12.98186115945854],
						[77.6229207855326, 12.981834377413175],
						[77.62295207906661, 12.981807679994235],
						[77.62298328517986, 12.981780892132335],
						[77.62302340731273, 12.981754101354365],
						[77.6230546134062, 12.98172739972405],
						[77.62310365153559, 12.981713973290436],
						[77.62315268964359, 12.981714000360794],
						[77.6231838957012, 12.981714033261754],
						[77.62324184978523, 12.98171397115097],
						[77.6233088072569, 12.98172736382359],
						[77.62362086701054, 12.981821004825605],
						[77.62374577808114, 12.9818522732842],
						[77.62413816751912, 12.981878968697169],
						[77.62421395298023, 12.981887955741517],
						[77.62437898504184, 12.981896831097387],
						[77.624428022594, 12.981874521500004],
						[77.62488282083392, 12.981959235159003],
						[77.62585936775145, 12.982079613800874],
						[77.62671108596082, 12.982093005656905],
						[77.6275895447217, 12.981941404261324],
						[77.62842778902206, 12.981780882582726],
						[77.62931514990242, 12.981607019443683],
						[77.63004648314151, 12.981526737814033],
						[77.63070640088884, 12.98156686941685],
						[77.63149121723033, 12.981656029811319],
						[77.63182563001286, 12.981874580102449],
						[77.63230723161851, 12.982191096221433],
						[77.63247225180292, 12.982249124545614],
						[77.63269513355297, 12.982289231731134],
						[77.63288689890886, 12.982298177483326],
						[77.6335647177702, 12.982373918847493],
						[77.63479990211812, 12.982547904318167],
						[77.63573624531489, 12.982623628420422],
						[77.63663700832163, 12.982726189077558],
						[77.63698040811362, 12.982784236843369],
						[77.63716762130201, 12.982828752863954],
						[77.63730143221794, 12.982868895286208],
						[77.63822892714371, 12.983234575265357],
						[77.63955775217282, 12.983716105210966],
						[77.64048077114373, 12.984077343194752],
						[77.64147509906505, 12.984416213625531],
						[77.64223764132028, 12.984674880087821],
						[77.64334792854159, 12.98513859201636],
						[77.64443600635542, 12.98554440051521],
						[77.64484178239317, 12.985695996049861],
						[77.64502015558674, 12.985767340534236],
						[77.64559093042207, 12.985999212604755],
						[77.64648270020719, 12.986342561319951],
						[77.64740129258239, 12.98673497865035],
						[77.64803891064822, 12.986989117155678],
						[77.64826193628727, 12.987078300351675],
						[77.64849378809795, 12.988389315147776],
						[77.64844021665165, 12.988710363952228],
						[77.64841793162161, 12.988911039326492],
						[77.64835553351391, 12.989129456773282],
						[77.64831987743676, 12.989298958053968],
						[77.64822619278796, 12.989504058873672],
						[77.64813259545342, 12.989704678935023],
						[77.64797205530563, 12.989945473604566],
						[77.64778477274162, 12.990150656962266],
						[77.64757074762753, 12.99035576507929],
						[77.64735226505643, 12.990605424747354],
						[77.64715161028833, 12.990783831754698],
						[77.64669227032188, 12.990926540516115],
						[77.64640247267853, 12.991033565183212],
						[77.64544821513675, 12.991180645802155],
						[77.6449799539118, 12.991309984471515],
						[77.64488635386938, 12.991332318191924],
						[77.64411491525264, 12.991497307086343],
						[77.6433568429569, 12.99163102077274],
						[77.64248733454683, 12.99176930263294],
						[77.64164456305798, 12.99189411483482],
						[77.64094896373884, 12.992001147035722],
						[77.6402221587912, 12.992112630348895],
						[77.63953100801986, 12.992250835912039],
						[77.63867038476918, 12.992438174365587],
						[77.63822892714371, 12.99254963566721],
						[77.63798368186876, 12.992607552667437],
						[77.6378810737438, 12.992629881821546],
						[77.63772952119697, 12.992638820589555],
						[77.63752439178973, 12.992647688065492],
						[77.63722565557904, 12.992723563775025],
						[77.63716316384878, 12.992723495690829],
						[77.6369848655741, 12.992750297666374],
						[77.6356961277182, 12.993031177231893],
						[77.63534835387783, 12.993098053688641],
						[77.6347596967842, 12.993236334831103],
						[77.63437180197589, 12.993294296225821],
						[77.63409088768476, 12.993312113122911],
						[77.63339078398424, 12.99346375389117],
						[77.63192824366772, 12.99377145104568],
						[77.63114343037739, 12.993914097927636],
						[77.63021141923828, 12.994092449693282],
						[77.62906096962517, 12.994266337773443],
						[77.6291190080885, 12.994507180448666],
						[77.62844562021981, 12.994645424667905],
						[77.6274200595308, 12.994832722470377],
						[77.62683590619102, 12.994975336368789],
						[77.62605560266027, 12.995260752896648],
						[77.62539565762, 12.995577396472438],
						[77.62497643754115, 12.995818152388027],
						[77.62462417258892, 12.99604556631871],
						[77.6243745270815, 12.99622840537293],
						[77.62370119821882, 12.99686603203693],
						[77.6220423777268, 12.998315247273288],
						[77.62075373084117, 12.999452344954591],
						[77.62016508476196, 12.999960692144885],
						[77.61945160732088, 12.999421097683305],
						[77.61921532483387, 12.999077744762577],
						[77.61909941248912, 12.998921658242736],
						[77.61892991450917, 12.998787920722597],
						[77.61873375458927, 12.998654108156646],
						[77.61829676430537, 12.998457906764854],
						[77.61797568594642, 12.998373198892724],
						[77.6178998962846, 12.998355407173227],
						[77.61789097985044, 12.99833307034797],
						[77.61766352312469, 12.997900531392938],
						[77.61757881679387, 12.997682036450119],
						[77.61726219462977, 12.997093410058222],
						[77.6169367424824, 12.99646028891592],
						[77.6168564064463, 12.99611694582982],
						[77.61686086470444, 12.995920684400012],
						[77.61682965689383, 12.995782478067703],
						[77.61670928382901, 12.99572450631811],
						[77.61690999294899, 12.995621952185141],
						[77.61694120073734, 12.995599648801313],
						[77.61722207044328, 12.995425741570862],
						[77.6175342344889, 12.995122499826524],
						[77.61780627369075, 12.994667686448697],
						[77.61811834870085, 12.9941192363312],
						[77.61850629956615, 12.99331655546312],
						[77.61894328903672, 12.992491655296266],
						[77.61917965950974, 12.991974413271965],
						[77.61930448809478, 12.991675619323127],
						[77.61934015337938, 12.991501735963467],
						[77.6193669023354, 12.991292149260563],
						[77.61944714916535, 12.990774907383333],
						[77.61947389809596, 12.990605426230411],
						[77.61952748335283, 12.990110536456402],
						[77.6195230252003, 12.989544206778545],
						[77.61950073443498, 12.988888707834873],
						[77.61940702575752, 12.988251081714626],
						[77.61933123705928, 12.987448387814606],
						[77.61932677889898, 12.98727896350875],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000004e",
			properties: {
				AREA_SQ_KM: 3.660012481537771,
				ASS_CONST1: "C.V. Ramannagar (SC)",
				ASS_CONST_: "161",
				LAT: 12.9891700284278,
				LON: 77.6310394811241,
				LOST_AREA_SQ_KM: 0.27084562612340596,
				OBJECTID: 79,
				POP_F: 17064.0,
				POP_M: 17879.0,
				POP_SC: 12221.0,
				POP_ST: 261.0,
				POP_TOTAL: 34943.0,
				RESERVATIO: "Scheduled Caste (Woman)",
				WARD_NAME: "Sarvagna Nagar",
				WARD_NO: 79.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.64502015558674, 12.985767340534236],
						[77.64484178239317, 12.985695996049861],
						[77.64443600635542, 12.98554440051521],
						[77.64334792854159, 12.98513859201636],
						[77.64223764132028, 12.984674880087821],
						[77.64147509906505, 12.984416213625531],
						[77.64048077114373, 12.984077343194752],
						[77.63955775217282, 12.983716105210966],
						[77.63822892714371, 12.983234575265357],
						[77.63730143221794, 12.982868895286208],
						[77.63716762130201, 12.982828752863954],
						[77.63698040811362, 12.982784236843369],
						[77.63663700832163, 12.982726189077558],
						[77.63573624531489, 12.982623628420422],
						[77.63479990211812, 12.982547904318167],
						[77.6335647177702, 12.982373918847493],
						[77.63288689890886, 12.982298177483326],
						[77.63269513355297, 12.982289231731134],
						[77.63247225180292, 12.982249124545614],
						[77.63230723161851, 12.982191096221433],
						[77.63182563001286, 12.981874580102449],
						[77.63149121723033, 12.981656029811319],
						[77.63070640088884, 12.98156686941685],
						[77.63004648314151, 12.981526737814033],
						[77.62931514990242, 12.981607019443683],
						[77.62842778902206, 12.981780882582726],
						[77.6275895447217, 12.981941404261324],
						[77.62671108596082, 12.982093005656905],
						[77.62585936775145, 12.982079613800874],
						[77.62488282083392, 12.981959235159003],
						[77.624428022594, 12.981874521500004],
						[77.62445477034073, 12.981861145249736],
						[77.62446368625488, 12.981852259463476],
						[77.6244770601248, 12.981843329071753],
						[77.62448151808108, 12.981829917120836],
						[77.62449489194888, 12.981812103912763],
						[77.62449489194888, 12.981798736570214],
						[77.62449489194888, 12.981785369229186],
						[77.62449489194888, 12.981745267215183],
						[77.62448151808108, 12.98161595355751],
						[77.62437006912097, 12.9811165284927],
						[77.62434323394311, 12.981018391740605],
						[77.62433877598133, 12.980982732873839],
						[77.62433877598133, 12.980960396644162],
						[77.62433877598133, 12.980938146658959],
						[77.62434323394311, 12.980902484828412],
						[77.62438344300202, 12.9808801824718],
						[77.624428022594, 12.980862315782169],
						[77.62456621921693, 12.980835577973615],
						[77.62481149376661, 12.980782073667438],
						[77.62511917883182, 12.980768649601165],
						[77.62533761723522, 12.980777587499837],
						[77.62549373181662, 12.980777581672788],
						[77.62556951636455, 12.980786481706142],
						[77.62554722679693, 12.980550147595714],
						[77.62552939513965, 12.980304972851783],
						[77.62566759040958, 12.980300484100225],
						[77.62577466758927, 12.980282599840443],
						[77.62577912549413, 12.980282641428026],
						[77.6259084046583, 12.980264788351214],
						[77.62573900434401, 12.979488946835566],
						[77.62610463948157, 12.979408621401722],
						[77.62622054461058, 12.979439886030825],
						[77.62627849713029, 12.97945327610233],
						[77.62640340524997, 12.979435385136961],
						[77.62643015252698, 12.979395274344517],
						[77.6263365370296, 12.978909218314177],
						[77.6262874128999, 12.97868625987526],
						[77.62646135767547, 12.978690730543311],
						[77.62672891743075, 12.978668477018337],
						[77.62702322368551, 12.978614908803968],
						[77.62723720054434, 12.978570333364235],
						[77.62750475845391, 12.978512375686623],
						[77.62767870134674, 12.978467775125026],
						[77.62803987236343, 12.978351873413915],
						[77.62828068153279, 12.978253736680033],
						[77.62851257459393, 12.978137837558483],
						[77.62882916510246, 12.977910356811833],
						[77.62916804357404, 12.977696363849656],
						[77.62969869181907, 12.977375324044342],
						[77.63020696150912, 12.977089946736974],
						[77.63071531630737, 12.97684467334336],
						[77.63124595724935, 12.976639534257814],
						[77.63163840792421, 12.976456739198625],
						[77.63174539201304, 12.976394261330682],
						[77.63184791833598, 12.976287242760014],
						[77.63199065090433, 12.976122303619103],
						[77.63229385867983, 12.975694188542368],
						[77.63252128582585, 12.975364285643366],
						[77.63263718434047, 12.975194782247607],
						[77.63269959118345, 12.975065486823754],
						[77.63274425488314, 12.974851430679916],
						[77.63283786504545, 12.974352033775814],
						[77.63299396921364, 12.973776815547968],
						[77.63306529109066, 12.973892776465217],
						[77.63322130753888, 12.974195968805864],
						[77.63341752959897, 12.97455714540883],
						[77.63349330880622, 12.97469539804792],
						[77.63351568420251, 12.974744419770769],
						[77.63352459939823, 12.974775634442885],
						[77.63352459939823, 12.97482021956997],
						[77.63352459939823, 12.974869289100706],
						[77.63352014180046, 12.974927286073449],
						[77.63343981760642, 12.975591694788537],
						[77.63338186877789, 12.975921624169752],
						[77.63353351459324, 12.975850335549245],
						[77.63368507280019, 12.975801206011752],
						[77.63398381842505, 12.975725417747958],
						[77.63440300492009, 12.975631777039489],
						[77.63474632413732, 12.97555157160531],
						[77.63516105025516, 12.975457887804719],
						[77.63541967426055, 12.975417773121706],
						[77.63559360490606, 12.9753910162269],
						[77.63562034999651, 12.9755871996368],
						[77.63562480751096, 12.975627342233448],
						[77.6356649251331, 12.975966203972542],
						[77.63572733029464, 12.975966265107457],
						[77.63585668542103, 12.975961775900021],
						[77.63669049799938, 12.975970721718035],
						[77.63774735091897, 12.975992948376005],
						[77.63877290477326, 12.976042042763098],
						[77.63930359506308, 12.976055416847712],
						[77.63998583255183, 12.976055444765834],
						[77.64094896373884, 12.976059882934027],
						[77.64098016485602, 12.975252785720057],
						[77.64105156906075, 12.974008661043694],
						[77.64111397122942, 12.972773517465956],
						[77.64112288582213, 12.972662007034316],
						[77.6411318004141, 12.972470294922637],
						[77.64118083065738, 12.971730047994374],
						[77.64120757441741, 12.971284187012355],
						[77.64127006389722, 12.97028087953817],
						[77.64197457575207, 12.970280836267097],
						[77.64197903301434, 12.970280877387598],
						[77.64201023384527, 12.970285304608023],
						[77.64266125424699, 12.970289767448183],
						[77.64328998482486, 12.97032989766847],
						[77.64560430177754, 12.970356696910779],
						[77.64746369122084, 12.97042360349185],
						[77.64732997981025, 12.97092747346328],
						[77.64711586643527, 12.9718548960145],
						[77.64702672523624, 12.972376687960336],
						[77.64696432635479, 12.972813609108194],
						[77.64694204103156, 12.973264009808325],
						[77.64692858244207, 12.973304156775692],
						[77.64692412537636, 12.973379921196683],
						[77.64691966831047, 12.973411185483242],
						[77.64690629711176, 12.973638565322197],
						[77.64684835523222, 12.974789006672204],
						[77.64677258503691, 12.975926099332083],
						[77.64670564154612, 12.977103297342614],
						[77.6466878132468, 12.977295024331543],
						[77.6466610707925, 12.9773307411075],
						[77.64662541417687, 12.978726442875015],
						[77.6463578144106, 12.979707414557767],
						[77.64604573040224, 12.981326032563384],
						[77.6455507289516, 12.983354974680699],
						[77.64526092836017, 12.985018172289594],
						[77.64502015558674, 12.985767340534236],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000004f",
			properties: {
				AREA_SQ_KM: 2.061661158256707,
				ASS_CONST1: "C.V. Ramannagar (SC)",
				ASS_CONST_: "161",
				LAT: 12.9784561074979,
				LON: 77.6387829786122,
				LOST_AREA_SQ_KM: 0.1461923411905625,
				OBJECTID: 80,
				POP_F: 17849.0,
				POP_M: 18041.0,
				POP_SC: 10775.0,
				POP_ST: 220.0,
				POP_TOTAL: 35890.0,
				RESERVATIO: "Scheduled Caste (Woman)",
				WARD_NAME: "Hoysala Nagar",
				WARD_NO: 80.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.65872293095117, 12.967172913457105],
						[77.65791139047285, 12.96726654878108],
						[77.6563997965557, 12.967467165324292],
						[77.65480341659507, 12.967667852480833],
						[77.65398293416489, 12.967756991489292],
						[77.65246683042454, 12.967944341785142],
						[77.65114244036077, 12.968220802345213],
						[77.6500990843797, 12.968501655716354],
						[77.64949704634665, 12.968653323898574],
						[77.64858738513114, 12.96879155219527],
						[77.64773128834958, 12.968845069429051],
						[77.64755291946565, 12.970075765647112],
						[77.64746369122084, 12.97042360349185],
						[77.64560430177754, 12.970356696910779],
						[77.64328998482486, 12.97032989766847],
						[77.64266125424699, 12.970289767448183],
						[77.64201023384527, 12.970285304608023],
						[77.64197903301434, 12.970280877387598],
						[77.64197457575207, 12.970280836267097],
						[77.64127006389722, 12.97028087953817],
						[77.6413681242416, 12.968662180352707],
						[77.64140823981234, 12.966816171661241],
						[77.6415286738362, 12.965036989205359],
						[77.64161781940203, 12.963177517775874],
						[77.64167576398185, 12.961590103642328],
						[77.64169813774826, 12.961184339789988],
						[77.64168467852994, 12.960640272998306],
						[77.64222872681609, 12.96057785410999],
						[77.64240264691689, 12.960560054718359],
						[77.64252299247715, 12.960546674414799],
						[77.6457782166479, 12.960185490112913],
						[77.64650052853911, 12.960105207202014],
						[77.64840010359437, 12.959877762491557],
						[77.65042007096652, 12.959654854249514],
						[77.65244454619456, 12.959503197526454],
						[77.65456703367978, 12.959422954226072],
						[77.65517795796814, 12.959373876648867],
						[77.65528055027708, 12.95936947053545],
						[77.6553251176052, 12.95940514665109],
						[77.65537859837556, 12.959480916119396],
						[77.65539196856416, 12.959543384443203],
						[77.65539642529335, 12.959547823011125],
						[77.65540088202235, 12.959547777478463],
						[77.65540088202235, 12.95955226157924],
						[77.65553912792025, 12.95991346345002],
						[77.65564171989864, 12.96024346948867],
						[77.65567737364766, 12.960395048408339],
						[77.65569520051794, 12.960840952540288],
						[77.65572194081801, 12.961104037536106],
						[77.65578887892684, 12.961358180512535],
						[77.65590921012638, 12.96171051212781],
						[77.65604299869403, 12.962044948280415],
						[77.656163417007, 12.962312431311263],
						[77.65662271817978, 12.962976855255464],
						[77.65670739507405, 12.963079386976965],
						[77.65710430077834, 12.963079401558861],
						[77.65752794494607, 12.963097251772217],
						[77.65773749441888, 12.96317307324456],
						[77.65804963327396, 12.963186451386202],
						[77.65902615389197, 12.9632488534368],
						[77.65936948515912, 12.963288989170188],
						[77.65938731144203, 12.963012505524532],
						[77.66028360280477, 12.962825291102789],
						[77.66014090621974, 12.962348135001054],
						[77.65999375290681, 12.961732791151585],
						[77.66084547430277, 12.961616828094082],
						[77.66091232196837, 12.961937889516433],
						[77.6609302353883, 12.962004802786831],
						[77.6609302353883, 12.962009287056336],
						[77.6609748004694, 12.962200964800669],
						[77.66097925697655, 12.962214372021874],
						[77.66116651749846, 12.962169773516791],
						[77.66130921277698, 12.96214744876771],
						[77.66145636436188, 12.962143015413478],
						[77.66154558146975, 12.962192037893798],
						[77.66162134168088, 12.962468512198813],
						[77.66171501513521, 12.962691514684922],
						[77.66176849285571, 12.962834206426487],
						[77.66179968818088, 12.963137354720583],
						[77.66184434029788, 12.963757207235831],
						[77.66184434029788, 12.96430567884319],
						[77.66160351575346, 12.964261047046648],
						[77.6615946027887, 12.964265536369368],
						[77.66159014630603, 12.96426558196717],
						[77.66157232037365, 12.964265505644148],
						[77.6614251689411, 12.964238806392563],
						[77.66120662598765, 12.964194205025295],
						[77.66118434349542, 12.964189776146844],
						[77.66114869149865, 12.96418970967931],
						[77.66113086549602, 12.96418971956217],
						[77.66111749599216, 12.964198652565335],
						[77.66109966998457, 12.964220997986272],
						[77.66106393057841, 12.964332432604175],
						[77.66089895243843, 12.964631204123629],
						[77.66086330035083, 12.964809563823918],
						[77.66085884383908, 12.964912146410764],
						[77.66052888671202, 12.964987950221968],
						[77.66071169146922, 12.965228674059073],
						[77.6611264089949, 12.965139503701495],
						[77.66120662598765, 12.965460611388671],
						[77.66124682184476, 12.96557205686784],
						[77.66163916760547, 12.96554977178031],
						[77.66167936330734, 12.965634483388461],
						[77.66168381978645, 12.96564340664299],
						[77.66170164570109, 12.965679099669376],
						[77.66172838456772, 12.965737037417222],
						[77.66176403637998, 12.965812821739247],
						[77.66181760145892, 12.965924303416784],
						[77.66189336148605, 12.966071514874177],
						[77.66191564383722, 12.966129412197926],
						[77.66192455677646, 12.966160625883084],
						[77.66197812177862, 12.966258740862655],
						[77.66162134168088, 12.966829571313502],
						[77.66154558146975, 12.96682508528354],
						[77.65992690469307, 12.967012326525374],
						[77.65872293095117, 12.967172913457105],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000050",
			properties: {
				AREA_SQ_KM: 1.8898979255022332,
				ASS_CONST1: "C.V. Ramannagar (SC)",
				ASS_CONST_: "161",
				LAT: 12.9646516380309,
				LON: 77.6500328479455,
				LOST_AREA_SQ_KM: 0.056637697973632814,
				OBJECTID: 81,
				POP_F: 17131.0,
				POP_M: 19021.0,
				POP_SC: 8116.0,
				POP_ST: 281.0,
				POP_TOTAL: 36152.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Jeevanbhima Nagar",
				WARD_NO: 88.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58100972263354, 12.973045496534146],
						[77.58142001195753, 12.972853756142818],
						[77.58185697019958, 12.97261294526278],
						[77.58195508226433, 12.9725639542833],
						[77.58240095767863, 12.972274079793204],
						[77.58276664590981, 12.972042250307657],
						[77.58317238182664, 12.971631971054295],
						[77.58430502440729, 12.970472590262801],
						[77.58432732212847, 12.970459264853295],
						[77.58439867480676, 12.97039234339268],
						[77.58437637709964, 12.969919722294382],
						[77.5843540793881, 12.968702325489387],
						[77.58449232512866, 12.968546282768502],
						[77.58477318818716, 12.968296572822199],
						[77.58495602861392, 12.968082581171682],
						[77.58510319264501, 12.967895238591623],
						[77.58520130189262, 12.967815034442598],
						[77.58527265401924, 12.967770444518779],
						[77.58564270501208, 12.967623232201355],
						[77.58649446475633, 12.96722640439435],
						[77.58700721385685, 12.96701682285493],
						[77.58724356382703, 12.966851791421393],
						[77.58725694211245, 12.966847348295472],
						[77.58729707695919, 12.966820654779996],
						[77.58732383351577, 12.966798318168562],
						[77.58661478262593, 12.9659288101274],
						[77.58662370153267, 12.965732573150866],
						[77.58665937715261, 12.965656779018794],
						[77.58677532283966, 12.965589934873579],
						[77.58696707890715, 12.96539818629014],
						[77.58698937610319, 12.965375893948242],
						[77.5870027544187, 12.965362484054967],
						[77.58828697789413, 12.964256618281759],
						[77.58912971156072, 12.963445067096833],
						[77.58913863026895, 12.963440581723946],
						[77.58929024820131, 12.963297942330346],
						[77.58955780876204, 12.963034829603508],
						[77.58975401943717, 12.962896576830927],
						[77.58996360773644, 12.962794058096673],
						[77.59032035288351, 12.96270042816369],
						[77.59050318433474, 12.962655853546702],
						[77.59074844557286, 12.962615661070437],
						[77.59113185645923, 12.962584440869541],
						[77.59206830038192, 12.96245514632319],
						[77.59299135893532, 12.96226786863299],
						[77.59328120668297, 12.962227747986658],
						[77.59359780875718, 12.962200984035878],
						[77.59395900158877, 12.962205419416156],
						[77.5942353819609, 12.962218795194554],
						[77.59443158450982, 12.962245559242694],
						[77.59465454153792, 12.962316891432511],
						[77.59459657275296, 12.962192104587281],
						[77.594618868443, 12.962218783578988],
						[77.59474372422588, 12.962316954814893],
						[77.59479277467422, 12.962348110363944],
						[77.59499343537739, 12.962481913807512],
						[77.59500681274491, 12.96249083404176],
						[77.59506032219919, 12.9625265149846],
						[77.59529219620795, 12.962678114837422],
						[77.59553298794376, 12.962905499459929],
						[77.59588079732208, 12.963199849665187],
						[77.5960725379314, 12.963395993393842],
						[77.59626427821506, 12.963636799576097],
						[77.59642926377983, 12.96385976567161],
						[77.59643372284579, 12.963882052498244],
						[77.5964916906874, 12.9639979609285],
						[77.5967949066045, 12.964573233772251],
						[77.5967949066045, 12.964577717266724],
						[77.5967859885009, 12.964680236443796],
						[77.59677261134422, 12.964863071924247],
						[77.5967726113442, 12.964880919724868],
						[77.59668343025906, 12.964916552071728],
						[77.59650506787737, 12.964916597880167],
						[77.59633562335374, 12.96494776711243],
						[77.5961617194992, 12.965005795224823],
						[77.59604578344732, 12.96507264613166],
						[77.59598335629316, 12.965112754833811],
						[77.59574702461107, 12.965349103944513],
						[77.59556866074982, 12.965603243178268],
						[77.59510045427324, 12.96642819924309],
						[77.59502464923249, 12.966570872119002],
						[77.59498451713148, 12.966695774615301],
						[77.59497559888487, 12.96695884078458],
						[77.59498005800828, 12.966967763710226],
						[77.59497559888489, 12.966985569705656],
						[77.59497559888487, 12.966990053267205],
						[77.59498005800828, 12.967386891173236],
						[77.59498451713148, 12.96774812036602],
						[77.59473480596026, 12.968764751935504],
						[77.59407939894255, 12.971529394372912],
						[77.59399467489216, 12.971881687859407],
						[77.59399467489216, 12.971899536573169],
						[77.59400359321626, 12.97191289955908],
						[77.59404818482612, 12.97206895820145],
						[77.59410169473472, 12.97221613382174],
						[77.5946277867178, 12.972911771780137],
						[77.5948596616149, 12.97319264657458],
						[77.59512274986378, 12.97350037494992],
						[77.5953813783922, 12.97370545158504],
						[77.59628657357574, 12.97446797515697],
						[77.59720076700418, 12.97519032360046],
						[77.59847158499034, 12.97608660184068],
						[77.59919839726555, 12.97662170701052],
						[77.59920731517852, 12.976684135830157],
						[77.59919393830882, 12.976724279271217],
						[77.59918056143754, 12.976746572989397],
						[77.59918502039481, 12.976751012827052],
						[77.59917610248009, 12.976755498892699],
						[77.59910475913698, 12.976929356439706],
						[77.59894869541667, 12.977397582845894],
						[77.59762437458949, 12.980242537195844],
						[77.59736129217096, 12.98079987349956],
						[77.59749060394483, 12.980947089163914],
						[77.59752627613224, 12.980942597464441],
						[77.59754411222173, 12.9809337116542],
						[77.59754411222173, 12.980938109548429],
						[77.59755748928698, 12.98093814982261],
						[77.59880154942553, 12.98050563700988],
						[77.59944809856192, 12.980287108219985],
						[77.60135659907607, 12.979645014657592],
						[77.6035594348782, 12.978936001654851],
						[77.6057800624438, 12.978222558695586],
						[77.60752357413944, 12.977629468947805],
						[77.60946323527631, 12.977009661212701],
						[77.61130486398068, 12.976380896527608],
						[77.61373505793756, 12.975591631398695],
						[77.61425677481655, 12.975404393151713],
						[77.61431919185505, 12.975382125795557],
						[77.61432810857202, 12.975408855471468],
						[77.61501486849481, 12.977138945659684],
						[77.61524224315247, 12.977923747637826],
						[77.61544732578326, 12.978619351001496],
						[77.61544732578326, 12.978735338774307],
						[77.61543395084047, 12.9789181207839],
						[77.61541611758093, 12.978949343734554],
						[77.61532695124089, 12.979060874254497],
						[77.61524224315247, 12.979100956473479],
						[77.61479186429976, 12.979288262746087],
						[77.61506391012648, 12.979992784467118],
						[77.6152957430052, 12.980608192213234],
						[77.6154116592656, 12.980826684459856],
						[77.61549199633235, 12.980951537469242],
						[77.61555887099601, 12.981067464743232],
						[77.61559899577514, 12.981183400034958],
						[77.61560791239077, 12.981419690142475],
						[77.61561237069833, 12.981638173796766],
						[77.61559899577514, 12.982039489107777],
						[77.61555441268634, 12.98236953826848],
						[77.61552766282458, 12.98249881834783],
						[77.61542503421106, 12.982601339025125],
						[77.61532695124089, 12.982605851812973],
						[77.6152110348933, 12.982588033777654],
						[77.61494790608985, 12.982538905936215],
						[77.61458232212729, 12.982534526247399],
						[77.61429690005957, 12.982543399721438],
						[77.61379747526514, 12.982641504074468],
						[77.6131197121524, 12.982851077132706],
						[77.61296812624197, 12.982935805619286],
						[77.61290570845485, 12.982873386092665],
						[77.61275849325696, 12.982721772058971],
						[77.61258015629878, 12.98293138100279],
						[77.61236169314027, 12.9831231063974],
						[77.61185334285446, 12.983310378559658],
						[77.61112652472164, 12.983568983944787],
						[77.61097484870815, 12.983613605513343],
						[77.61078759193033, 12.983319323066352],
						[77.61064491989076, 12.98305176350713],
						[77.61016777152462, 12.983181027293629],
						[77.60982446473554, 12.983288095863282],
						[77.60944094252005, 12.983435227600602],
						[77.60866952301491, 12.983689350051952],
						[77.60815223886873, 12.983890066944516],
						[77.6076573328861, 12.984050550869638],
						[77.60723813419185, 12.98414421906667],
						[77.6068457732926, 12.984206665768404],
						[77.60663621634384, 12.983756253722573],
						[77.60656933638458, 12.983738462060728],
						[77.60564184276802, 12.983983684054659],
						[77.60503982897204, 12.98410408179321],
						[77.60450469318559, 12.984113000038832],
						[77.60354605852456, 12.98413085840759],
						[77.60341229490078, 12.984144255915467],
						[77.60268542234448, 12.984331508495979],
						[77.60199876215343, 12.98450542981335],
						[77.60174006136154, 12.984665969906175],
						[77.60164642534235, 12.984652580661745],
						[77.60139672891121, 12.984630300073375],
						[77.60046928018548, 12.984456389354934],
						[77.59816837357765, 12.984014909598095],
						[77.59786962035957, 12.984010443557835],
						[77.59759316145326, 12.984126400490958],
						[77.59733453799235, 12.984295819474207],
						[77.59714725856452, 12.984456341600518],
						[77.59701794639727, 12.984603528655846],
						[77.59683949711183, 12.984826479388039],
						[77.5955062333318, 12.986373770944532],
						[77.59409277641839, 12.988148488698943],
						[77.59335255370675, 12.989120602428459],
						[77.59281744992963, 12.98974928204899],
						[77.59049426573422, 12.986980203172994],
						[77.5900215788995, 12.98653426911607],
						[77.58953997141103, 12.98596795459262],
						[77.58901385572793, 12.986494161456132],
						[77.58882210313385, 12.986333665042842],
						[77.58873737513977, 12.986021465371294],
						[77.58863926896198, 12.985803048674638],
						[77.58853224394362, 12.985548846659594],
						[77.5883850843778, 12.985250111765827],
						[77.58830035605601, 12.984973616252473],
						[77.58821562767068, 12.984692723587422],
						[77.58801049552729, 12.984465272302385],
						[77.58817549315019, 12.984429588680433],
						[77.58766720876284, 12.98329252159574],
						[77.58698937610319, 12.982070725466015],
						[77.58591473369091, 12.980420837992384],
						[77.58488913580916, 12.97888690524858],
						[77.58440313434767, 12.978012937413355],
						[77.58392150291424, 12.977263786087093],
						[77.58289151479103, 12.975667438772762],
						[77.58170088264836, 12.974164779253048],
						[77.58126838346773, 12.973371033953827],
						[77.5810364806783, 12.973081188605137],
						[77.58100972263354, 12.973049980135746],
						[77.58100972263354, 12.973045496534146],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000051",
			properties: {
				AREA_SQ_KM: 4.464487696001258,
				ASS_CONST1: "Shivaji Nagar",
				ASS_CONST_: "162",
				LAT: 12.9767945721309,
				LON: 77.5953720295075,
				LOST_AREA_SQ_KM: 0.21234623593193505,
				OBJECTID: 82,
				POP_F: 16147.0,
				POP_M: 16973.0,
				POP_SC: 1503.0,
				POP_ST: 375.0,
				POP_TOTAL: 33120.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Sampangiram Nagar",
				WARD_NO: 110.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59326336991998, 13.005668276887025],
						[77.5932901250634, 13.005654904963565],
						[77.59334809451909, 13.005614834930453],
						[77.59337930882904, 13.005583565564415],
						[77.59341944150061, 13.005543411376424],
						[77.5934595741579, 13.005498858430393],
						[77.59363794135108, 13.005320471984673],
						[77.59387873661494, 13.005173300339415],
						[77.59494884414083, 13.004343956090024],
						[77.59583620643603, 13.003692956642299],
						[77.59659424910345, 13.003184584910839],
						[77.5974192595362, 13.002805535406354],
						[77.59792312845677, 13.002560298205106],
						[77.59856522364433, 13.00218130945111],
						[77.5990690874485, 13.001855771331844],
						[77.5992786584568, 13.001597121359394],
						[77.5993990501366, 13.001351854148629],
						[77.59942134487802, 13.001155678268914],
						[77.6000946439937, 13.001338546449588],
						[77.60086612215997, 13.001534683989815],
						[77.60108906647709, 13.001552586910039],
						[77.60149036513765, 13.001494560387119],
						[77.60165088420217, 13.00147676599556],
						[77.60165534306182, 13.001472323403682],
						[77.60185599156334, 13.001423256680772],
						[77.60202105638112, 13.001392053655325],
						[77.60207010366655, 13.001383118465466],
						[77.6022172453949, 13.001369767719027],
						[77.60224399841579, 13.001378646808607],
						[77.60226629259502, 13.001383084819784],
						[77.60233763393896, 13.001396441201042],
						[77.6024357282132, 13.001463353875078],
						[77.60250706944996, 13.001579347214808],
						[77.60256949299513, 13.001677402296172],
						[77.60259178711001, 13.001739800121129],
						[77.60378683264757, 13.00345658738181],
						[77.60378683264757, 13.003706285176067],
						[77.60378683264757, 13.003826692314677],
						[77.60374670366278, 13.004085324794396],
						[77.6037422448858, 13.004125475752433],
						[77.6037422448858, 13.004161097743804],
						[77.6037422448858, 13.00424139820438],
						[77.6038225028443, 13.004401899415242],
						[77.60399639489181, 13.004767535932428],
						[77.60402314749071, 13.00482549296956],
						[77.6041034052493, 13.005035072834486],
						[77.60433971942908, 13.005449779068668],
						[77.6045315330903, 13.005904606608727],
						[77.60456274430695, 13.005944752259868],
						[77.6045894967715, 13.006056273356819],
						[77.60460733174433, 13.006083008914928],
						[77.60494173696274, 13.00598044007458],
						[77.60514237961779, 13.0064397497396],
						[77.60526722375629, 13.006716172483708],
						[77.60244464587025, 13.008102938986003],
						[77.60147252967194, 13.008566697311014],
						[77.60133876358921, 13.0086335640342],
						[77.60124958611262, 13.008678170546316],
						[77.60096421771378, 13.008811992377783],
						[77.60025525247376, 13.00918208121912],
						[77.59810594759745, 13.010185330835899],
						[77.59732116090068, 13.010622394572396],
						[77.59691538836618, 13.010836398409968],
						[77.59660316722217, 13.010930017503707],
						[77.59620185118172, 13.011099468545144],
						[77.59600565170933, 13.011184193218877],
						[77.5959030927585, 13.011233240732654],
						[77.59575148370399, 13.011300105324151],
						[77.59545718319343, 13.010466315463246],
						[77.59532786909011, 13.010145249233084],
						[77.59531895087063, 13.010047179531197],
						[77.59530111442955, 13.009975763363467],
						[77.59495776238956, 13.009101839363082],
						[77.59474372422588, 13.009213302855185],
						[77.5942353819609, 13.007625884245698],
						[77.59376279821866, 13.006595756820365],
						[77.593557676149, 13.00617660205265],
						[77.59344619660705, 13.006011661001079],
						[77.59326336991998, 13.005668276887025],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000052",
			properties: {
				AREA_SQ_KM: 0.8344378945291673,
				ASS_CONST1: "Shivaji Nagar",
				ASS_CONST_: "162",
				LAT: 13.0059760284195,
				LON: 77.5990766390158,
				LOST_AREA_SQ_KM: 0.016390117419194244,
				OBJECTID: 83,
				POP_F: 15545.0,
				POP_M: 16855.0,
				POP_SC: 7046.0,
				POP_ST: 116.0,
				POP_TOTAL: 32400.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Ramaswamy Palya",
				WARD_NO: 62.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59173831626653, 12.99542578710553],
						[77.59174277551776, 12.995251879032175],
						[77.59253206021172, 12.995077943901812],
						[77.59329904344314, 12.994828244846488],
						[77.59467237808114, 12.994128172482469],
						[77.5956845972917, 12.993570755230223],
						[77.59626427821506, 12.992982143767405],
						[77.59686625140777, 12.992210739193121],
						[77.59732116090068, 12.991479424723433],
						[77.59746830882199, 12.991381385529165],
						[77.59810594759745, 12.99111379343336],
						[77.59894423645007, 12.990757031398172],
						[77.59948822907586, 12.99056978527395],
						[77.59923852786831, 12.989521926921793],
						[77.59899328507306, 12.988750431857067],
						[77.59897544921262, 12.988692482539603],
						[77.59871682891936, 12.98795225797639],
						[77.59863656732854, 12.987782814935054],
						[77.59828876644197, 12.98683305690627],
						[77.59807027559326, 12.98631580451849],
						[77.59714725856452, 12.984456341600518],
						[77.59733453799235, 12.984295819474207],
						[77.59759316145326, 12.984126400490958],
						[77.59786962035957, 12.984010443557835],
						[77.59816837357765, 12.984014909598095],
						[77.60046928018548, 12.984456389354934],
						[77.60139672891121, 12.984630300073375],
						[77.60164642534235, 12.984652580661745],
						[77.60174006136154, 12.984665969906175],
						[77.60176235564036, 12.984710506439471],
						[77.60178910876915, 12.98479966973237],
						[77.6018069441848, 12.984946872734891],
						[77.6018158618916, 12.98500033789628],
						[77.60192733316676, 12.986721580158484],
						[77.60194962740859, 12.987207660267025],
						[77.60202105638112, 12.988063746682819],
						[77.60202997407097, 12.988148518362957],
						[77.6020388917601, 12.988197586863834],
						[77.60208793903779, 12.988402697863936],
						[77.60217265701263, 12.989120586176977],
						[77.60250261062399, 12.988826264806084],
						[77.60300645684396, 12.98827779937432],
						[77.60306888011274, 12.988335739252546],
						[77.60316697375104, 12.988411609429345],
						[77.60320710294211, 12.988745997512442],
						[77.60321156174024, 12.98919638798673],
						[77.60319818534533, 12.98945498398635],
						[77.60298416281101, 12.98948176402877],
						[77.60282364564377, 12.989504048046122],
						[77.60283256327017, 12.989557601760504],
						[77.60289052782454, 12.989954427834336],
						[77.60292619830474, 12.989994522532916],
						[77.60299753923131, 12.989976741868675],
						[77.60340337732022, 12.990025774974868],
						[77.60386709057435, 12.990083716884332],
						[77.60421487426372, 12.990137284634287],
						[77.60445118820992, 12.990164011754269],
						[77.60495065442166, 12.990163993738323],
						[77.6052939760537, 12.990181813720778],
						[77.6052805999058, 12.990333473328892],
						[77.60526276503944, 12.990529678211596],
						[77.605200342985, 12.990734776078456],
						[77.605137920896, 12.990904256048955],
						[77.60506658132338, 12.99106028426264],
						[77.60495957187986, 12.99126539236479],
						[77.6048926909261, 12.991430432253335],
						[77.60479905752418, 12.991573054837488],
						[77.60456274430695, 12.991845072226917],
						[77.6043085081507, 12.992184013839534],
						[77.60403652378777, 12.992500540227201],
						[77.60383587916516, 12.992772550310015],
						[77.60283702208311, 12.992549637770775],
						[77.60253382240212, 12.992527361688113],
						[77.60228858676984, 12.992531765887154],
						[77.60209685672235, 12.992540730086906],
						[77.60186936811742, 12.992562978325719],
						[77.60150374173509, 12.992656623450992],
						[77.60123175060883, 12.99273244059539],
						[77.60068776638907, 12.99294202879729],
						[77.59916272560669, 12.993593096641241],
						[77.59852509280212, 12.993816019668524],
						[77.59740588245455, 12.994203920014654],
						[77.5966343806322, 12.99443133951717],
						[77.5965318222526, 12.994475987312388],
						[77.59657195380353, 12.994547260365705],
						[77.59683949711183, 12.99460974180283],
						[77.59717401278752, 12.994707791275566],
						[77.59866332119849, 12.99518937851293],
						[77.59986723878143, 12.995559498772518],
						[77.60004559584942, 12.995613024721827],
						[77.60016152779244, 12.995653193909325],
						[77.6002151222326, 12.995684409075317],
						[77.60025971138855, 12.995702253707046],
						[77.60030875943974, 12.99571117120273],
						[77.60038901984116, 12.995733407875147],
						[77.60042915002046, 12.995737842234306],
						[77.60050049252618, 12.99574231406272],
						[77.60060304729912, 12.995737853867556],
						[77.60075464983622, 12.99571557512594],
						[77.6015349537896, 12.995546175705469],
						[77.60240897523776, 12.995358863447937],
						[77.6035594348782, 12.995019960055306],
						[77.60478122258166, 12.994587458704244],
						[77.60550353549718, 12.994244054333317],
						[77.60568197107845, 12.99447153602598],
						[77.60692602904219, 12.996321992277966],
						[77.60767070875204, 12.997441289595196],
						[77.60812548724955, 12.99807891403908],
						[77.6085000967637, 12.99868534154267],
						[77.60867844017896, 12.9989305969592],
						[77.60844213509299, 12.999042037098544],
						[77.60712666816615, 12.999479106149693],
						[77.60686360790856, 12.999545958641615],
						[77.60678335211456, 12.999545974566692],
						[77.60673430687899, 12.999514718943978],
						[77.60659162970873, 12.998578343683851],
						[77.60654704305601, 12.998212659661453],
						[77.60654704305601, 12.998127963955938],
						[77.60652474972302, 12.997994154539176],
						[77.60649791029277, 12.997949565501457],
						[77.60646669961177, 12.997913908258427],
						[77.60642211290964, 12.99787821060224],
						[77.60639536087989, 12.997851479143206],
						[77.60635523282336, 12.997838075683799],
						[77.6062571419584, 12.997824727007487],
						[77.6058781537238, 12.997833643272077],
						[77.60515575578209, 12.997838094196828],
						[77.60506658132338, 12.997846995151265],
						[77.60500861788742, 12.99787378633951],
						[77.60499078297805, 12.997913894936621],
						[77.60494619569229, 12.998061042064627],
						[77.60487039726603, 12.998359862154077],
						[77.60482135119837, 12.998440125277783],
						[77.60475447016259, 12.998547128717139],
						[77.60470096530545, 12.998613981172904],
						[77.60460287300138, 12.998703180390054],
						[77.60438430695463, 12.99880572354231],
						[77.60383587916516, 12.999019764948399],
						[77.60327398489551, 12.9992427295652],
						[77.60219495120597, 12.999666315714995],
						[77.60182923845042, 12.999840252002908],
						[77.60177127335068, 13.000081024043018],
						[77.60167317849863, 13.00054031408467],
						[77.60160183673446, 13.000522472868646],
						[77.60159737787271, 13.000638434921134],
						[77.6015795424239, 13.000691912161008],
						[77.60157062469847, 13.000740989209786],
						[77.60155724810896, 13.000816761035349],
						[77.60153941265382, 13.000990728013],
						[77.60149482400364, 13.001409819756853],
						[77.60149036513765, 13.001494560387119],
						[77.60108906647709, 13.001552586910039],
						[77.60086612215997, 13.001534683989815],
						[77.6000946439937, 13.001338546449588],
						[77.59942134487802, 13.001155678268914],
						[77.5993990501366, 13.001351854148629],
						[77.5992786584568, 13.001597121359394],
						[77.5990690874485, 13.001855771331844],
						[77.59856522364433, 13.00218130945111],
						[77.59792312845677, 13.002560298205106],
						[77.5974192595362, 13.002805535406354],
						[77.59659424910345, 13.003184584910839],
						[77.59583620643603, 13.003692956642299],
						[77.59494884414083, 13.004343956090024],
						[77.59387873661494, 13.005173300339415],
						[77.59363794135108, 13.005320471984673],
						[77.5934595741579, 13.005498858430393],
						[77.59341944150061, 13.005543411376424],
						[77.59337930882904, 13.005583565564415],
						[77.59334809451909, 13.005614834930453],
						[77.5932901250634, 13.005654904963565],
						[77.59326336991998, 13.005668276887025],
						[77.59321431880724, 13.005690578315614],
						[77.59325445153743, 13.005561241540684],
						[77.59338376801549, 13.005124327120633],
						[77.5942353819609, 13.002270456190885],
						[77.59481061119561, 13.000433352428344],
						[77.59522085040983, 12.999046539326715],
						[77.59551961063858, 12.998110083918483],
						[77.59575594279676, 12.997240569338263],
						[77.59588079732208, 12.996348783193561],
						[77.59596106087258, 12.99577352397635],
						[77.59613496503626, 12.994912916519464],
						[77.5961617194992, 12.994649871070072],
						[77.59568013819613, 12.99488177046804],
						[77.59515842279947, 12.995149250164033],
						[77.59511829074602, 12.995167151931016],
						[77.594881957253, 12.99526968891523],
						[77.59467683721651, 12.995341043889615],
						[77.59456981791911, 12.995363311193623],
						[77.59444050279939, 12.995416844954821],
						[77.59436023816784, 12.995434623369214],
						[77.59430226923179, 12.99545252769221],
						[77.59415966377375, 12.995483687462364],
						[77.59400805237803, 12.995488195194163],
						[77.59372266566913, 12.995506004414938],
						[77.59279961309339, 12.995474792319321],
						[77.59173831626653, 12.99542578710553],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000053",
			properties: {
				AREA_SQ_KM: 1.4550197256970634,
				ASS_CONST1: "Shivaji Nagar",
				ASS_CONST_: "162",
				LAT: 12.9951067097216,
				LON: 77.5999310114007,
				LOST_AREA_SQ_KM: 0.0929612669424618,
				OBJECTID: 84,
				POP_F: 12051.0,
				POP_M: 12014.0,
				POP_SC: 3291.0,
				POP_ST: 380.0,
				POP_TOTAL: 24065.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Jayamahal",
				WARD_NO: 63.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61725327814513, 12.988625585723051],
						[77.61724436165976, 12.98861670508189],
						[77.61660228544115, 12.987756082756778],
						[77.61581745265515, 12.98660564344873],
						[77.61509957675426, 12.985508716197394],
						[77.6143058167783, 12.984465244290227],
						[77.61430135841901, 12.984438554194792],
						[77.61426569153846, 12.984367157292686],
						[77.6140873569663, 12.984099604287465],
						[77.61338284522652, 12.983372796906483],
						[77.61337392843475, 12.983368314945864],
						[77.61296812624197, 12.982935805619286],
						[77.6131197121524, 12.982851077132706],
						[77.61379747526514, 12.982641504074468],
						[77.61429690005957, 12.982543399721438],
						[77.61458232212729, 12.982534526247399],
						[77.61494790608985, 12.982538905936215],
						[77.6152110348933, 12.982588033777654],
						[77.61532695124089, 12.982605851812973],
						[77.61542503421106, 12.982601339025125],
						[77.61552766282458, 12.98249881834783],
						[77.61555441268634, 12.98236953826848],
						[77.61559899577514, 12.982039489107777],
						[77.61561237069833, 12.981638173796766],
						[77.61560791239077, 12.981419690142475],
						[77.61559899577514, 12.981183400034958],
						[77.61555887099601, 12.981067464743232],
						[77.61549199633235, 12.980951537469242],
						[77.6154116592656, 12.980826684459856],
						[77.6152957430052, 12.980608192213234],
						[77.61506391012648, 12.979992784467118],
						[77.61479186429976, 12.979288262746087],
						[77.61524224315247, 12.979100956473479],
						[77.61532695124089, 12.979060874254497],
						[77.61541611758093, 12.978949343734554],
						[77.61543395084047, 12.9789181207839],
						[77.61544732578326, 12.978735338774307],
						[77.61544732578326, 12.978619351001496],
						[77.61524224315247, 12.977923747637826],
						[77.61501486849481, 12.977138945659684],
						[77.61432810857202, 12.975408855471468],
						[77.61431919185505, 12.975382125795557],
						[77.61406952349355, 12.974490292475549],
						[77.61394468910528, 12.974035453686094],
						[77.61393577235796, 12.97398639017194],
						[77.61392239723567, 12.973906155050512],
						[77.61391793886122, 12.973892747294478],
						[77.6139134804866, 12.973879339540245],
						[77.61390001794253, 12.973830316392283],
						[77.61393131398404, 12.973776803806556],
						[77.6141051904362, 12.973714360893629],
						[77.61507728511322, 12.9735047794216],
						[77.61686086470444, 12.97307228285517],
						[77.61880062732764, 12.972639767931627],
						[77.61917520134342, 12.972577300039747],
						[77.6193669023354, 12.972581762592828],
						[77.61947835625044, 12.97259961823737],
						[77.61976822332764, 12.972697700480769],
						[77.61996892699442, 12.972746746269008],
						[77.62018737539574, 12.972733348857398],
						[77.62057531913071, 12.972679885166473],
						[77.6209186805639, 12.972679900697138],
						[77.62130216369617, 12.972711075177068],
						[77.62155636150545, 12.97278691986023],
						[77.62201117135263, 12.97308116783565],
						[77.62341579926994, 12.973883836364816],
						[77.6252752063061, 12.975029828020066],
						[77.6253465330794, 12.975074386632814],
						[77.62574792015639, 12.975346413337384],
						[77.62622946038478, 12.975765527683208],
						[77.62654605732055, 12.976077725700394],
						[77.62667988088162, 12.97620703772845],
						[77.62692060555403, 12.976541466451922],
						[77.62699201869292, 12.976737635916962],
						[77.62700093440597, 12.976750999576812],
						[77.62703659725112, 12.976889226147867],
						[77.62713467001694, 12.977237075501648],
						[77.62720599561112, 12.977393141272815],
						[77.62723720054434, 12.977424391493514],
						[77.62724611623794, 12.977433270850684],
						[77.6272505740845, 12.97743771052956],
						[77.62729078210415, 12.97746896175749],
						[77.62732198701384, 12.977495727598924],
						[77.62742451737046, 12.97754920175874],
						[77.62777677355264, 12.977700816058892],
						[77.62809782394567, 12.977821179978495],
						[77.62819589578605, 12.977865816265037],
						[77.62830297055868, 12.977932703196334],
						[77.62842778902206, 12.978035282511843],
						[77.62851257459393, 12.978137837558483],
						[77.62828068153279, 12.978253736680033],
						[77.62803987236343, 12.978351873413915],
						[77.62767870134674, 12.978467775125026],
						[77.62750475845391, 12.978512375686623],
						[77.62723720054434, 12.978570333364235],
						[77.62702322368551, 12.978614908803968],
						[77.62672891743075, 12.978668477018337],
						[77.62646135767547, 12.978690730543311],
						[77.6262874128999, 12.97868625987526],
						[77.6263365370296, 12.978909218314177],
						[77.62643015252698, 12.979395274344517],
						[77.62640340524997, 12.979435385136961],
						[77.62627849713029, 12.97945327610233],
						[77.62622054461058, 12.979439886030825],
						[77.62610463948157, 12.979408621401722],
						[77.62573900434401, 12.979488946835566],
						[77.6259084046583, 12.980264788351214],
						[77.62577912549413, 12.980282641428026],
						[77.62577466758927, 12.980282599840443],
						[77.62566759040958, 12.980300484100225],
						[77.62552939513965, 12.980304972851783],
						[77.62554722679693, 12.980550147595714],
						[77.62556951636455, 12.980786481706142],
						[77.62549373181662, 12.980777581672788],
						[77.62533761723522, 12.980777587499837],
						[77.62511917883182, 12.980768649601165],
						[77.62481149376661, 12.980782073667438],
						[77.62456621921693, 12.980835577973615],
						[77.624428022594, 12.980862315782169],
						[77.62438344300202, 12.9808801824718],
						[77.62434323394311, 12.980902484828412],
						[77.62433877598133, 12.980938146658959],
						[77.62433877598133, 12.980960396644162],
						[77.62433877598133, 12.980982732873839],
						[77.62434323394311, 12.981018391740605],
						[77.62437006912097, 12.9811165284927],
						[77.62448151808108, 12.98161595355751],
						[77.62449489194888, 12.981745267215183],
						[77.62449489194888, 12.981785369229186],
						[77.62449489194888, 12.981798736570214],
						[77.62449489194888, 12.981812103912763],
						[77.62448151808108, 12.981829917120836],
						[77.6244770601248, 12.981843329071753],
						[77.62446368625488, 12.981852259463476],
						[77.62445477034073, 12.981861145249736],
						[77.624428022594, 12.981874521500004],
						[77.62437898504184, 12.981896831097387],
						[77.62421395298023, 12.981887955741517],
						[77.62413816751912, 12.981878968697169],
						[77.62374577808114, 12.9818522732842],
						[77.62362086701054, 12.981821004825605],
						[77.6233088072569, 12.98172736382359],
						[77.62324184978523, 12.98171397115097],
						[77.6231838957012, 12.981714033261754],
						[77.62315268964359, 12.981714000360794],
						[77.62310365153559, 12.981713973290436],
						[77.6230546134062, 12.98172739972405],
						[77.62302340731273, 12.981754101354365],
						[77.62298328517986, 12.981780892132335],
						[77.62295207906661, 12.981807679994235],
						[77.6229207855326, 12.981834377413175],
						[77.62290741147768, 12.98186115945854],
						[77.62288066336312, 12.981950340922172],
						[77.6227825868886, 12.982378385580299],
						[77.62267559427349, 12.982868959231805],
						[77.62253284988263, 12.983439660173161],
						[77.62247489543346, 12.983684906875853],
						[77.62245260525275, 12.983876672677376],
						[77.62242139899233, 12.98444299132469],
						[77.62244814721608, 12.985187687591774],
						[77.62244368917922, 12.985869915068946],
						[77.62249272757485, 12.986534277431518],
						[77.62251501774762, 12.987105076881335],
						[77.62250164364448, 12.98720318390184],
						[77.62253284988263, 12.987381484626903],
						[77.62253284988263, 12.987461778266908],
						[77.62252839184914, 12.987542030207306],
						[77.62246152132555, 12.987640153968627],
						[77.62239465076223, 12.987689204523427],
						[77.62232332211754, 12.987689226324624],
						[77.6222341612481, 12.987689167298713],
						[77.6218506812916, 12.987600016670198],
						[77.62149840655339, 12.9875643708173],
						[77.62084289288347, 12.987484050486007],
						[77.619977843264, 12.987336908528778],
						[77.61963002081247, 12.987287848017447],
						[77.61942931654141, 12.987274491896807],
						[77.61941594207161, 12.987274538943288],
						[77.61933123705928, 12.987279005324279],
						[77.61932677889898, 12.98727896350875],
						[77.61923761565572, 12.98727898961475],
						[77.6191439941743, 12.98727897386509],
						[77.61905920154223, 12.987301288339793],
						[77.61899678713102, 12.987350292853742],
						[77.61837701189812, 12.98768030649725],
						[77.61826555689275, 12.987756103515261],
						[77.61767689780268, 12.988291146323885],
						[77.61725327814513, 12.988625585723051],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000054",
			properties: {
				AREA_SQ_KM: 1.6926700494232063,
				ASS_CONST1: "Shivaji Nagar",
				ASS_CONST_: "162",
				LAT: 12.9797043313289,
				LON: 77.6198221307318,
				LOST_AREA_SQ_KM: 0.0635920135449877,
				OBJECTID: 85,
				POP_F: 15365.0,
				POP_M: 20526.0,
				POP_SC: 6474.0,
				POP_ST: 866.0,
				POP_TOTAL: 35891.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Halsoor",
				WARD_NO: 90.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.60978433789973, 12.992678909063645],
						[77.60961928444661, 12.992094764356931],
						[77.60937406422485, 12.99107364824585],
						[77.60920463903268, 12.990150625559243],
						[77.60899062789962, 12.989258841311019],
						[77.60882111470802, 12.98838033098764],
						[77.6087274845687, 12.987907707464712],
						[77.60870965024763, 12.98777395169388],
						[77.60858480992114, 12.987198668901225],
						[77.60845105227504, 12.986601227725096],
						[77.6083395874487, 12.986445117262686],
						[77.60777325700482, 12.986668052222418],
						[77.6072782619341, 12.986792913348415],
						[77.60713112680929, 12.986770655459647],
						[77.60698845014124, 12.986819673771803],
						[77.60650236896079, 12.986824139729446],
						[77.60615459232652, 12.986810767985121],
						[77.6060743360278, 12.986783966194613],
						[77.60564184276802, 12.98555778897016],
						[77.60561063185004, 12.985490831975124],
						[77.60557050351424, 12.985352644059724],
						[77.60540098517988, 12.985455217373321],
						[77.60532964577371, 12.98551758460639],
						[77.60301091565002, 12.985022670588812],
						[77.60207456250963, 12.98483091504696],
						[77.60183369730302, 12.98471945936981],
						[77.60184261500768, 12.98468375524241],
						[77.60174006136154, 12.984665969906175],
						[77.60199876215343, 12.98450542981335],
						[77.60268542234448, 12.984331508495979],
						[77.60341229490078, 12.984144255915467],
						[77.60354605852456, 12.98413085840759],
						[77.60450469318559, 12.984113000038832],
						[77.60503982897204, 12.98410408179321],
						[77.60564184276802, 12.983983684054659],
						[77.60656933638458, 12.983738462060728],
						[77.60663621634384, 12.983756253722573],
						[77.6068457732926, 12.984206665768404],
						[77.60723813419185, 12.98414421906667],
						[77.6076573328861, 12.984050550869638],
						[77.60815223886873, 12.983890066944516],
						[77.60866952301491, 12.983689350051952],
						[77.60944094252005, 12.983435227600602],
						[77.60982446473554, 12.983288095863282],
						[77.61016777152462, 12.983181027293629],
						[77.61064491989076, 12.98305176350713],
						[77.61078759193033, 12.983319323066352],
						[77.61097484870815, 12.983613605513343],
						[77.61112652472164, 12.983568983944787],
						[77.61185334285446, 12.983310378559658],
						[77.61236169314027, 12.9831231063974],
						[77.61258015629878, 12.98293138100279],
						[77.61275849325696, 12.982721772058971],
						[77.61290570845485, 12.982873386092665],
						[77.61296812624197, 12.982935805619286],
						[77.61337392843475, 12.983368314945864],
						[77.61338284522652, 12.983372796906483],
						[77.6140873569663, 12.984099604287465],
						[77.61426569153846, 12.984367157292686],
						[77.61430135841901, 12.984438554194792],
						[77.6143058167783, 12.984465244290227],
						[77.61509957675426, 12.985508716197394],
						[77.61581745265515, 12.98660564344873],
						[77.61660228544115, 12.987756082756778],
						[77.61724436165976, 12.98861670508189],
						[77.61725327814513, 12.988625585723051],
						[77.61711953079056, 12.98873265187096],
						[77.61671374209297, 12.989013527761994],
						[77.61624553599674, 12.989298904608217],
						[77.61500595183223, 12.989994531181349],
						[77.6139134804866, 12.99059209469787],
						[77.61369493249441, 12.990730353686502],
						[77.61324900585599, 12.990971085866674],
						[77.61305283604058, 12.991082563695418],
						[77.61287449954833, 12.991176184175847],
						[77.61279870645363, 12.991211865422308],
						[77.61259353158043, 12.99130997286322],
						[77.61250436300607, 12.991318878335035],
						[77.61235723470406, 12.991363546625426],
						[77.6121119330199, 12.991412547110041],
						[77.61197817956015, 12.99144380072862],
						[77.61189792740808, 12.991448218987651],
						[77.61178200753196, 12.991492835992334],
						[77.61169283831532, 12.99151510899685],
						[77.61127811310982, 12.9916979152715],
						[77.61104627196299, 12.991804914798982],
						[77.61031490268549, 12.992282100963209],
						[77.60986459155707, 12.99262093371272],
						[77.60978433789973, 12.992678909063645],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000055",
			properties: {
				AREA_SQ_KM: 0.7834992411141506,
				ASS_CONST1: "Shivaji Nagar",
				ASS_CONST_: "162",
				LAT: 12.987014409491,
				LON: 77.6110403571514,
				LOST_AREA_SQ_KM: 0.012881018038312129,
				OBJECTID: 86,
				POP_F: 16160.0,
				POP_M: 16472.0,
				POP_SC: 1613.0,
				POP_ST: 185.0,
				POP_TOTAL: 32632.0,
				RESERVATIO: "General",
				WARD_NAME: "Bharathi Nagar",
				WARD_NO: 91.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.6052939760537, 12.990181813720778],
						[77.60495065442166, 12.990163993738323],
						[77.60445118820992, 12.990164011754269],
						[77.60421487426372, 12.990137284634287],
						[77.60386709057435, 12.990083716884332],
						[77.60340337732022, 12.990025774974868],
						[77.60299753923131, 12.989976741868675],
						[77.60292619830474, 12.989994522532916],
						[77.60289052782454, 12.989954427834336],
						[77.60283256327017, 12.989557601760504],
						[77.60282364564377, 12.989504048046122],
						[77.60298416281101, 12.98948176402877],
						[77.60319818534533, 12.98945498398635],
						[77.60321156174024, 12.98919638798673],
						[77.60320710294211, 12.988745997512442],
						[77.60316697375104, 12.988411609429345],
						[77.60306888011274, 12.988335739252546],
						[77.60300645684396, 12.98827779937432],
						[77.60250261062399, 12.988826264806084],
						[77.60217265701263, 12.989120586176977],
						[77.60208793903779, 12.988402697863936],
						[77.6020388917601, 12.988197586863834],
						[77.60202997407097, 12.988148518362957],
						[77.60202105638112, 12.988063746682819],
						[77.60194962740859, 12.987207660267025],
						[77.60192733316676, 12.986721580158484],
						[77.6018158618916, 12.98500033789628],
						[77.6018069441848, 12.984946872734891],
						[77.60178910876915, 12.98479966973237],
						[77.60176235564036, 12.984710506439471],
						[77.60174006136154, 12.984665969906175],
						[77.60184261500768, 12.98468375524241],
						[77.60183369730302, 12.98471945936981],
						[77.60207456250963, 12.98483091504696],
						[77.60301091565002, 12.985022670588812],
						[77.60532964577371, 12.98551758460639],
						[77.60540098517988, 12.985455217373321],
						[77.60557050351424, 12.985352644059724],
						[77.60561063185004, 12.985490831975124],
						[77.60564184276802, 12.98555778897016],
						[77.6060743360278, 12.986783966194613],
						[77.60615459232652, 12.986810767985121],
						[77.60650236896079, 12.986824139729446],
						[77.60698845014124, 12.986819673771803],
						[77.60713112680929, 12.986770655459647],
						[77.6072782619341, 12.986792913348415],
						[77.60777325700482, 12.986668052222418],
						[77.6083395874487, 12.986445117262686],
						[77.60845105227504, 12.986601227725096],
						[77.60858480992114, 12.987198668901225],
						[77.60870965024763, 12.98777395169388],
						[77.6087274845687, 12.987907707464712],
						[77.60882111470802, 12.98838033098764],
						[77.60899062789962, 12.989258841311019],
						[77.60920463903268, 12.990150625559243],
						[77.60937406422485, 12.99107364824585],
						[77.60961928444661, 12.992094764356931],
						[77.60978433789973, 12.992678909063645],
						[77.60970854272642, 12.992732441730197],
						[77.60897725219031, 12.993258614643429],
						[77.60878990467022, 12.993396817789478],
						[77.60870519166693, 12.993436984101026],
						[77.6086293957679, 12.993463780345653],
						[77.60857143416366, 12.993490486303298],
						[77.6085000967637, 12.99350835510286],
						[77.60843321791023, 12.993521694998018],
						[77.60829946008423, 12.993535092946146],
						[77.60817453187987, 12.993535116598615],
						[77.60767516737366, 12.993584128299949],
						[77.60740319113175, 12.99359302820404],
						[77.60728717920821, 12.993481534607197],
						[77.60724259283056, 12.993352263283848],
						[77.60722029963513, 12.992942045339108],
						[77.60716233730635, 12.991550812871488],
						[77.60711775087933, 12.990520735125276],
						[77.60710437494778, 12.990360197425078],
						[77.60692157039092, 12.990288873255386],
						[77.60672984822016, 12.990248770831332],
						[77.60660054703717, 12.990213052576241],
						[77.60646224094235, 12.990186301285261],
						[77.60606095997247, 12.990172937502821],
						[77.60560171444332, 12.99018187943302],
						[77.6052939760537, 12.990181813720778],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000056",
			properties: {
				AREA_SQ_KM: 0.4277160103929199,
				ASS_CONST1: "Shivaji Nagar",
				ASS_CONST_: "162",
				LAT: 12.9887016750541,
				LON: 77.6058728426691,
				LOST_AREA_SQ_KM: 0.004646984622790767,
				OBJECTID: 87,
				POP_F: 17284.0,
				POP_M: 18456.0,
				POP_SC: 1438.0,
				POP_ST: 402.0,
				POP_TOTAL: 35740.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Shivaji Nagar",
				WARD_NO: 92.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58417123798772, 12.99933639335172],
						[77.58405528962935, 12.999380992059987],
						[77.58387690730733, 12.999389891887972],
						[77.58360942073917, 12.999376553337036],
						[77.58328387150566, 12.999345320138746],
						[77.58315008387764, 12.99931411236221],
						[77.58286475718523, 12.999211513366777],
						[77.5825035288872, 12.998970713145377],
						[77.58204873461038, 12.998556018590401],
						[77.58165182651634, 12.998132390412385],
						[77.58104540002515, 12.99747244274526],
						[77.58085809359362, 12.997267378138842],
						[77.58016246871993, 12.996509302699144],
						[77.57940003096142, 12.995666512919511],
						[77.57876683382072, 12.995033318049366],
						[77.57781698774039, 12.994203918952696],
						[77.57725959874689, 12.993735741931815],
						[77.57712143161214, 12.993610928947597],
						[77.57712589144002, 12.993606401140621],
						[77.57780360833797, 12.992299935611985],
						[77.57789280432415, 12.99211710684223],
						[77.57766990167403, 12.99187187523461],
						[77.57754948683882, 12.991742488820256],
						[77.57979685951288, 12.99094880367542],
						[77.57968090666893, 12.99069019429127],
						[77.5792350205303, 12.989998980980832],
						[77.57794186208659, 12.988059282613506],
						[77.5774112326107, 12.987283459638746],
						[77.57661309753053, 12.986106265687761],
						[77.57632320703944, 12.985704931228282],
						[77.57590852680157, 12.985134171939544],
						[77.57580148973254, 12.985022686590508],
						[77.5757435112779, 12.984960218436235],
						[77.57562309439253, 12.984835412039258],
						[77.57534666850869, 12.984630302213544],
						[77.57502564285281, 12.984438562774773],
						[77.57462879756623, 12.98426015342729],
						[77.5747089887599, 12.984010420563193],
						[77.57613589278867, 12.983805294501826],
						[77.57729527732137, 12.98357346068089],
						[77.57696533752592, 12.983319296490894],
						[77.57691181950392, 12.983256872247459],
						[77.57684492194083, 12.98316319136022],
						[77.57678248417959, 12.983069553185375],
						[77.5767289660711, 12.98296703250158],
						[77.57667990778275, 12.982864468317572],
						[77.57663976916737, 12.982757419339825],
						[77.57660417783418, 12.982645933406648],
						[77.57657741874094, 12.982534528614112],
						[77.57655511949173, 12.982422994099984],
						[77.57654619979083, 12.982307103655005],
						[77.57654173994008, 12.982195567373989],
						[77.57654173994008, 12.98207967615711],
						[77.57655065964136, 12.981963697816385],
						[77.57656849904178, 12.981852202445696],
						[77.57659525813715, 12.981740792377988],
						[77.57662647707373, 12.981629252540962],
						[77.57666652824587, 12.98152227763356],
						[77.57671112669598, 12.981415263377047],
						[77.5767646448129, 12.98131712963167],
						[77.57682262274433, 12.981214554661284],
						[77.57688952032062, 12.981120946386797],
						[77.57696087769172, 12.98103177833819],
						[77.57704115468042, 12.980947006857845],
						[77.57712143161214, 12.980866805441142],
						[77.57721054068863, 12.980790996342078],
						[77.57730419696323, 12.98071963162241],
						[77.57740231297728, 12.980657190894544],
						[77.57750042890625, 12.980599233959207],
						[77.57757178589203, 12.980568010830835],
						[77.57841897176455, 12.980501156891956],
						[77.57911460736106, 12.980411990221837],
						[77.57983253728712, 12.980300441861019],
						[77.57996632884024, 12.980287058628454],
						[77.58016246871993, 12.980260383540214],
						[77.58035869570459, 12.980224658504902],
						[77.5805504626561, 12.98018457934733],
						[77.58074214183766, 12.98013552803778],
						[77.58093390813899, 12.980077513001435],
						[77.58112121444496, 12.980015057627826],
						[77.5813085204407, 12.979943720547158],
						[77.5814912790264, 12.979867938933605],
						[77.58166966511227, 12.979787635003364],
						[77.58184805091673, 12.979698535618686],
						[77.58201751717031, 12.979600383397813],
						[77.58218689572622, 12.979497829176719],
						[77.58235190185033, 12.979390838932439],
						[77.58251244811834, 12.979274924611518],
						[77.58266853454923, 12.979154483870282],
						[77.5827042114175, 12.97912774784115],
						[77.58272650945442, 12.979109938413172],
						[77.58294940213877, 12.978935978749918],
						[77.5831768414159, 12.978771033221236],
						[77.58340873981562, 12.9786104412719],
						[77.5836450973105, 12.978454375328127],
						[77.58388136686882, 12.978307272579078],
						[77.58412664247933, 12.97816456994836],
						[77.58437191755769, 12.978030834675742],
						[77.58440313434767, 12.978012937413355],
						[77.58488913580916, 12.97888690524858],
						[77.58591473369091, 12.980420837992384],
						[77.58698937610319, 12.982070725466015],
						[77.58766720876284, 12.98329252159574],
						[77.58817549315019, 12.984429588680433],
						[77.58801049552729, 12.984465272302385],
						[77.58821562767068, 12.984692723587422],
						[77.58830035605601, 12.984973616252473],
						[77.5883850843778, 12.985250111765827],
						[77.58853224394362, 12.985548846659594],
						[77.58863926896198, 12.985803048674638],
						[77.58873737513977, 12.986021465371294],
						[77.58882210313385, 12.986333665042842],
						[77.58901385572793, 12.986494161456132],
						[77.58953997141103, 12.98596795459262],
						[77.5900215788995, 12.98653426911607],
						[77.59049426573422, 12.986980203172994],
						[77.59281744992963, 12.98974928204899],
						[77.59335255370675, 12.989120602428459],
						[77.59409277641839, 12.988148488698943],
						[77.5955062333318, 12.986373770944532],
						[77.59683949711183, 12.984826479388039],
						[77.59701794639727, 12.984603528655846],
						[77.59714725856452, 12.984456341600518],
						[77.59807027559326, 12.98631580451849],
						[77.59828876644197, 12.98683305690627],
						[77.59863656732854, 12.987782814935054],
						[77.59871682891936, 12.98795225797639],
						[77.59897544921262, 12.988692482539603],
						[77.59899328507306, 12.988750431857067],
						[77.59923852786831, 12.989521926921793],
						[77.59948822907586, 12.99056978527395],
						[77.59894423645007, 12.990757031398172],
						[77.59810594759745, 12.99111379343336],
						[77.59746830882199, 12.991381385529165],
						[77.59732116090068, 12.991479424723433],
						[77.59686625140777, 12.992210739193121],
						[77.59626427821506, 12.992982143767405],
						[77.5956845972917, 12.993570755230223],
						[77.59467237808114, 12.994128172482469],
						[77.59329904344314, 12.994828244846488],
						[77.59253206021172, 12.995077943901812],
						[77.59174277551776, 12.995251879032175],
						[77.59173831626653, 12.99542578710553],
						[77.59143508676993, 12.995416860698914],
						[77.59062358537273, 12.995381186950187],
						[77.5897451007775, 12.99534995650157],
						[77.58956226809934, 12.995599607461854],
						[77.58932592297936, 12.995809249188758],
						[77.5890852054473, 12.995920709016257],
						[77.58825576217691, 12.996330980090859],
						[77.58771626228439, 12.996656484262136],
						[77.58730153638574, 12.996981968172728],
						[77.58695370058742, 12.997267384157196],
						[77.58673072835872, 12.997427906572218],
						[77.5866192420794, 12.997490315506301],
						[77.58644095124411, 12.997566146729882],
						[77.58636514039175, 12.997583964194868],
						[77.5862491942839, 12.997606227531683],
						[77.58611095069213, 12.99761516878077],
						[77.5853886011289, 12.997686486871462],
						[77.5843540793881, 12.997780170691735],
						[77.58429610531756, 12.998431176427513],
						[77.58424259076452, 12.999082228196757],
						[77.58423367166988, 12.999229359676166],
						[77.5842202930266, 12.99928735944764],
						[77.58417123798772, 12.99933639335172],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000057",
			properties: {
				AREA_SQ_KM: 3.1355413969040935,
				ASS_CONST1: "Shivaji Nagar",
				ASS_CONST_: "162",
				LAT: 12.9891311126017,
				LON: 77.5858046982688,
				LOST_AREA_SQ_KM: 0.11785188579864503,
				OBJECTID: 88,
				POP_F: 12382.0,
				POP_M: 13250.0,
				POP_SC: 2198.0,
				POP_ST: 423.0,
				POP_TOTAL: 25632.0,
				RESERVATIO: "General",
				WARD_NAME: "Vasanth Nagar",
				WARD_NO: 93.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59500681274491, 12.96249083404176],
						[77.59509599515461, 12.962441752465844],
						[77.59522976863705, 12.962374898215433],
						[77.59542151035214, 12.962348163800959],
						[77.59575148370399, 12.96230798610442],
						[77.59613050595848, 12.962254474097263],
						[77.59695551978075, 12.962125146486976],
						[77.59732561993141, 12.96208060128012],
						[77.59775368606196, 12.96196909029358],
						[77.59811040659717, 12.961848717179866],
						[77.59848050296073, 12.961746143974088],
						[77.59875695969338, 12.961657003047714],
						[77.59907800537168, 12.961541035037822],
						[77.59996087627728, 12.961179872493664],
						[77.60041577329561, 12.96089444723118],
						[77.6010756898305, 12.960488681439271],
						[77.60158846014866, 12.960172064174984],
						[77.60179356762333, 12.96002051683897],
						[77.60199876215343, 12.959913462510825],
						[77.60216373933407, 12.959846550642908],
						[77.6024357282132, 12.959766328905507],
						[77.60297078638914, 12.961255613281518],
						[77.6025873282874, 12.961371606801029],
						[77.60305550370092, 12.962852010094867],
						[77.60326952609985, 12.962802973560082],
						[77.60362185784093, 12.963926647792123],
						[77.60362185784093, 12.963957860291716],
						[77.60447794070093, 12.963614529458313],
						[77.60468313034741, 12.96475157934908],
						[77.60468758908719, 12.965893136252834],
						[77.60667634430035, 12.966205294192333],
						[77.60675660017053, 12.966209753869817],
						[77.60712666816615, 12.96633014219374],
						[77.60819245370915, 12.966553108218992],
						[77.60883449043585, 12.96663336006269],
						[77.60967287439195, 12.965424917434609],
						[77.61028369306136, 12.964608907120192],
						[77.61097484870815, 12.96358326494809],
						[77.61155008000098, 12.962896608613427],
						[77.61247761241998, 12.961741688735367],
						[77.61233048408305, 12.960221161900161],
						[77.61233048408305, 12.960198830422273],
						[77.61249544614473, 12.96018096891833],
						[77.61274065957384, 12.960194378179393],
						[77.61293691735273, 12.960212248790446],
						[77.6130840448977, 12.960252334254436],
						[77.61325346425691, 12.960296938599445],
						[77.61337838683072, 12.960363807924612],
						[77.61344526274928, 12.960439617239215],
						[77.61347647149768, 12.960497590963211],
						[77.613543347358, 12.960528823356379],
						[77.61367264057537, 12.960568914945895],
						[77.61375734984424, 12.961536608311269],
						[77.61425231645535, 12.961527689228232],
						[77.61478740595972, 12.961536560102607],
						[77.61569262020417, 12.961545518603433],
						[77.61623216114873, 12.961630218404157],
						[77.61724436165976, 12.961955748501767],
						[77.61895220538753, 12.962499784453835],
						[77.618889790917, 12.96265139152715],
						[77.61842605206554, 12.963690317293649],
						[77.61785085588801, 12.964925496856125],
						[77.61772148005117, 12.965251001871007],
						[77.61760556616838, 12.965380297041168],
						[77.61837255370004, 12.965340197496703],
						[77.61904136885684, 12.965402650502297],
						[77.61974593261085, 12.965536403411994],
						[77.62003134086663, 12.966151714177816],
						[77.62049507310505, 12.96669573960427],
						[77.62020520789957, 12.967525147579535],
						[77.61989750939792, 12.968439243614794],
						[77.6196701441408, 12.970040062871472],
						[77.61954531596123, 12.971676609700783],
						[77.61951856704759, 12.972260757239914],
						[77.61947835625044, 12.97259961823737],
						[77.6193669023354, 12.972581762592828],
						[77.61917520134342, 12.972577300039747],
						[77.61880062732764, 12.972639767931627],
						[77.61686086470444, 12.97307228285517],
						[77.61507728511322, 12.9735047794216],
						[77.6141051904362, 12.973714360893629],
						[77.61393131398404, 12.973776803806556],
						[77.61390001794253, 12.973830316392283],
						[77.6139134804866, 12.973879339540245],
						[77.61391793886122, 12.973892747294478],
						[77.61392239723567, 12.973906155050512],
						[77.61393577235796, 12.97398639017194],
						[77.61394468910528, 12.974035453686094],
						[77.61406952349355, 12.974490292475549],
						[77.61431919185505, 12.975382125795557],
						[77.61425677481655, 12.975404393151713],
						[77.61373505793756, 12.975591631398695],
						[77.61130486398068, 12.976380896527608],
						[77.60946323527631, 12.977009661212701],
						[77.60752357413944, 12.977629468947805],
						[77.6057800624438, 12.978222558695586],
						[77.6035594348782, 12.978936001654851],
						[77.60135659907607, 12.979645014657592],
						[77.59944809856192, 12.980287108219985],
						[77.59880154942553, 12.98050563700988],
						[77.59755748928698, 12.98093814982261],
						[77.59754411222173, 12.980938109548429],
						[77.59754411222173, 12.9809337116542],
						[77.59752627613224, 12.980942597464441],
						[77.59749060394483, 12.980947089163914],
						[77.59736129217096, 12.98079987349956],
						[77.59762437458949, 12.980242537195844],
						[77.59894869541667, 12.977397582845894],
						[77.59910475913698, 12.976929356439706],
						[77.59917610248009, 12.976755498892699],
						[77.59918502039481, 12.976751012827052],
						[77.59918056143754, 12.976746572989397],
						[77.59919393830882, 12.976724279271217],
						[77.59920731517852, 12.976684135830157],
						[77.59919839726555, 12.97662170701052],
						[77.59847158499034, 12.97608660184068],
						[77.59720076700418, 12.97519032360046],
						[77.59628657357574, 12.97446797515697],
						[77.5953813783922, 12.97370545158504],
						[77.59512274986378, 12.97350037494992],
						[77.5948596616149, 12.97319264657458],
						[77.5946277867178, 12.972911771780137],
						[77.59410169473472, 12.97221613382174],
						[77.59404818482612, 12.97206895820145],
						[77.59400359321626, 12.97191289955908],
						[77.59399467489216, 12.971899536573169],
						[77.59399467489216, 12.971881687859407],
						[77.59407939894255, 12.971529394372912],
						[77.59473480596026, 12.968764751935504],
						[77.59498451713148, 12.96774812036602],
						[77.59498005800828, 12.967386891173236],
						[77.59497559888487, 12.966990053267205],
						[77.59497559888489, 12.966985569705656],
						[77.59498005800828, 12.966967763710226],
						[77.59497559888487, 12.96695884078458],
						[77.59498451713148, 12.966695774615301],
						[77.59502464923249, 12.966570872119002],
						[77.59510045427324, 12.96642819924309],
						[77.59556866074982, 12.965603243178268],
						[77.59574702461107, 12.965349103944513],
						[77.59598335629316, 12.965112754833811],
						[77.59604578344732, 12.96507264613166],
						[77.5961617194992, 12.965005795224823],
						[77.59633562335374, 12.96494776711243],
						[77.59650506787737, 12.964916597880167],
						[77.59668343025906, 12.964916552071728],
						[77.5967726113442, 12.964880919724868],
						[77.59677261134422, 12.964863071924247],
						[77.5967859885009, 12.964680236443796],
						[77.5967949066045, 12.964577717266724],
						[77.5967949066045, 12.964573233772251],
						[77.5964916906874, 12.9639979609285],
						[77.59643372284579, 12.963882052498244],
						[77.59642926377983, 12.96385976567161],
						[77.59626427821506, 12.963636799576097],
						[77.5960725379314, 12.963395993393842],
						[77.59588079732208, 12.963199849665187],
						[77.59553298794376, 12.962905499459929],
						[77.59529219620795, 12.962678114837422],
						[77.59506032219919, 12.9625265149846],
						[77.59500681274491, 12.96249083404176],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000058",
			properties: {
				AREA_SQ_KM: 4.0165003612302845,
				ASS_CONST1: "Shanthi Nagar",
				ASS_CONST_: "163",
				LAT: 12.9698666568526,
				LON: 77.6064642945921,
				LOST_AREA_SQ_KM: 0.17518912740275064,
				OBJECTID: 89,
				POP_F: 14655.0,
				POP_M: 15570.0,
				POP_SC: 4033.0,
				POP_ST: 104.0,
				POP_TOTAL: 30225.0,
				RESERVATIO: "General",
				WARD_NAME: "Shantala Nagar",
				WARD_NO: 111.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.64077058407607, 12.953269446587779],
						[77.64110059933905, 12.953866914265598],
						[77.64129235034665, 12.954286092784365],
						[77.641310179503, 12.954424303004187],
						[77.64131909408012, 12.954535787932125],
						[77.64132800865652, 12.954700818978418],
						[77.64139041067156, 12.954700788238108],
						[77.64204589192715, 12.954700778730388],
						[77.64340150241898, 12.954700735641469],
						[77.6437002221997, 12.955753113244294],
						[77.64382065376472, 12.956257011386068],
						[77.64374925132121, 12.956546852927136],
						[77.6436021638924, 12.957264728488596],
						[77.64338367359477, 12.957844414531364],
						[77.64376708008737, 12.957973744801725],
						[77.64342378844529, 12.958183315704579],
						[77.64341933124038, 12.958187758666986],
						[77.6433568429569, 12.958205634456558],
						[77.64329889924477, 12.958232350649574],
						[77.64324541271472, 12.958268075525266],
						[77.64319192615918, 12.958303714158555],
						[77.64314726661372, 12.958348312004583],
						[77.64310715166437, 12.958401836514234],
						[77.64307149391959, 12.958455315733643],
						[77.64304475060356, 12.958508876831544],
						[77.64302246450202, 12.958571274211502],
						[77.64301355006018, 12.958611374942926],
						[77.6430046356176, 12.958647078016307],
						[77.64295560617083, 12.958829864015811],
						[77.64291094653136, 12.95901717090576],
						[77.64287528872424, 12.959204477887136],
						[77.64287083149756, 12.959253501351768],
						[77.64284408813373, 12.959369492683349],
						[77.6428084303054, 12.9595211011637],
						[77.64275940077293, 12.959672673157982],
						[77.6427058265891, 12.959824286375676],
						[77.64264788254091, 12.959971465233236],
						[77.64258102398664, 12.9601141645549],
						[77.6425051635134, 12.96025677784498],
						[77.64250070627203, 12.960265704779276],
						[77.64240264691689, 12.960560054718359],
						[77.64222872681609, 12.96057785410999],
						[77.64168467852994, 12.960640272998306],
						[77.64169813774826, 12.961184339789988],
						[77.64167576398185, 12.961590103642328],
						[77.64161781940203, 12.963177517775874],
						[77.6415286738362, 12.965036989205359],
						[77.64140823981234, 12.966816171661241],
						[77.6413681242416, 12.968662180352707],
						[77.64127006389722, 12.97028087953817],
						[77.64120757441741, 12.971284187012355],
						[77.64118083065738, 12.971730047994374],
						[77.6411318004141, 12.972470294922637],
						[77.64112288582213, 12.972662007034316],
						[77.64029347607936, 12.972639756671546],
						[77.63960232574695, 12.972590671109074],
						[77.63865701258783, 12.972532762218545],
						[77.63813077665782, 12.972510465169048],
						[77.63758679591832, 12.97234993995668],
						[77.63632043980544, 12.971846049721407],
						[77.63492917090946, 12.971279684462953],
						[77.6339793608455, 12.970842714189047],
						[77.63253911637435, 12.970383431467084],
						[77.63032294981628, 12.969634264796529],
						[77.62896735631776, 12.969201801426905],
						[77.62902530742201, 12.968920875422812],
						[77.6290030185393, 12.968697929822707],
						[77.62893169408491, 12.968144950233386],
						[77.63002419446124, 12.968064724819444],
						[77.63102298521994, 12.968122628671585],
						[77.63216449945219, 12.968354578604597],
						[77.63275762776823, 12.968461578865064],
						[77.6331054096266, 12.968492742409444],
						[77.63313661292243, 12.968359024646102],
						[77.63316335859767, 12.968153864624524],
						[77.6332392253804, 12.967779284014979],
						[77.63347547840912, 12.966575335923526],
						[77.6335691753662, 12.966156170113393],
						[77.633649412064, 12.965893129330865],
						[77.63369398798247, 12.965812828783363],
						[77.63389912438328, 12.96537141306938],
						[77.63355134498113, 12.965358018468375],
						[77.63357809055768, 12.96467134959875],
						[77.63429156579387, 12.964689165092832],
						[77.63429156579387, 12.964591034306348],
						[77.634282650659, 12.96448850966933],
						[77.63428710822653, 12.964403786012166],
						[77.63427373552342, 12.96429233859266],
						[77.6342290724316, 12.964220954662151],
						[77.63419341186804, 12.96410059219694],
						[77.63416666643793, 12.96398013979705],
						[77.6341532937205, 12.96375719708066],
						[77.63417112401007, 12.963489703469296],
						[77.63420678458068, 12.963387074301393],
						[77.63425136027807, 12.963297893192738],
						[77.63427373552342, 12.963074940429852],
						[77.6344163776078, 12.963048233350683],
						[77.63438963223076, 12.962851986185523],
						[77.63429156579387, 12.962847544984891],
						[77.63430493849485, 12.962624592714842],
						[77.63430493849485, 12.962437302506707],
						[77.63430493849485, 12.962276829872147],
						[77.63431831119424, 12.96204491083156],
						[77.63431385362794, 12.961991407687584],
						[77.63429602336105, 12.961777395368548],
						[77.63476415433279, 12.961434046058306],
						[77.63473740903854, 12.961170888221583],
						[77.63479098702358, 12.960934602846978],
						[77.6350317817306, 12.960256833077985],
						[77.63524128581632, 12.95961024704085],
						[77.63562480751096, 12.958433082060042],
						[77.63572287278426, 12.958156594791676],
						[77.63617771273653, 12.956925860958584],
						[77.63635164220848, 12.956435376492273],
						[77.63635609969393, 12.956430933811545],
						[77.63639621705495, 12.956288255248593],
						[77.63622228765198, 12.956248107752002],
						[77.63669941294319, 12.955333951538861],
						[77.63686442667743, 12.95511551421907],
						[77.63705172746027, 12.95489253965031],
						[77.63781866977868, 12.953982866600596],
						[77.63784987176558, 12.95394719803105],
						[77.63867929955586, 12.95291713097307],
						[77.63948188965696, 12.9519316508707],
						[77.6397940793131, 12.951597219118758],
						[77.63994117173102, 12.95182912904327],
						[77.63994117173102, 12.951833612639577],
						[77.63994562907402, 12.951833567322463],
						[77.64043619791829, 12.952685224380168],
						[77.64044065524163, 12.95269414633579],
						[77.64077058407607, 12.953269446587779],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000059",
			properties: {
				AREA_SQ_KM: 1.7504435627627797,
				ASS_CONST1: "Shanthi Nagar",
				ASS_CONST_: "163",
				LAT: 12.9633614843384,
				LON: 77.6380404127316,
				LOST_AREA_SQ_KM: 0.08328860795635148,
				OBJECTID: 90,
				POP_F: 16876.0,
				POP_M: 17827.0,
				POP_SC: 5338.0,
				POP_ST: 295.0,
				POP_TOTAL: 34703.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Domlur",
				WARD_NO: 112.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61947835625044, 12.97259961823737],
						[77.61951856704759, 12.972260757239914],
						[77.61954531596123, 12.971676609700783],
						[77.6196701441408, 12.970040062871472],
						[77.61989750939792, 12.968439243614794],
						[77.62020520789957, 12.967525147579535],
						[77.62049507310505, 12.96669573960427],
						[77.62003134086663, 12.966151714177816],
						[77.61974593261085, 12.965536403411994],
						[77.61904136885684, 12.965402650502297],
						[77.61837255370004, 12.965340197496703],
						[77.61760556616838, 12.965380297041168],
						[77.61772148005117, 12.965251001871007],
						[77.61785085588801, 12.964925496856125],
						[77.61842605206554, 12.963690317293649],
						[77.618889790917, 12.96265139152715],
						[77.61895220538753, 12.962499784453835],
						[77.61724436165976, 12.961955748501767],
						[77.61623216114873, 12.961630218404157],
						[77.61569262020417, 12.961545518603433],
						[77.61478740595972, 12.961536560102607],
						[77.61425231645535, 12.961527689228232],
						[77.61375734984424, 12.961536608311269],
						[77.61367264057537, 12.960568914945895],
						[77.613543347358, 12.960528823356379],
						[77.61347647149768, 12.960497590963211],
						[77.61344526274928, 12.960439617239215],
						[77.61337838683072, 12.960363807924612],
						[77.61325346425691, 12.960296938599445],
						[77.6130840448977, 12.960252334254436],
						[77.61293691735273, 12.960212248790446],
						[77.61274065957384, 12.960194378179393],
						[77.61249544614473, 12.96018096891833],
						[77.61233048408305, 12.960198830422273],
						[77.61233048408305, 12.960087345540087],
						[77.61231710877017, 12.959962544102941],
						[77.61231265033219, 12.959909045060598],
						[77.61212530835715, 12.958441966780894],
						[77.61268715850757, 12.958392887871167],
						[77.61376180822505, 12.95828142159472],
						[77.61453773863605, 12.958183378130283],
						[77.61576395305025, 12.958053998530119],
						[77.61659336890423, 12.95796929157895],
						[77.61661566024519, 12.957969326453373],
						[77.61666915944546, 12.95755458239509],
						[77.61670036730057, 12.957331646317698],
						[77.61676724124665, 12.957086366133481],
						[77.61684303167085, 12.957086380967922],
						[77.61706157355425, 12.957077465874484],
						[77.61745398629546, 12.95699274013697],
						[77.61766798135086, 12.956966017224607],
						[77.61777943694781, 12.95693920352501],
						[77.61770810537847, 12.957211257834917],
						[77.6178954380676, 12.957238006178839],
						[77.61858208881976, 12.95730491651744],
						[77.61858208881976, 12.95727370436376],
						[77.61901016165062, 12.957322691505933],
						[77.61900124530439, 12.957095328899614],
						[77.61899678713102, 12.956809895057615],
						[77.61917520134342, 12.95667170439248],
						[77.61940702575752, 12.956600310079477],
						[77.61985292801114, 12.95656910188462],
						[77.62057086101969, 12.956569131951378],
						[77.62107025577166, 12.956569112350463],
						[77.62100784247555, 12.956364016110252],
						[77.62096326152854, 12.956065277045427],
						[77.62095880343288, 12.956007295102241],
						[77.62095434533703, 12.955953796684213],
						[77.62096771962405, 12.955918138989583],
						[77.62100784247555, 12.955900232244424],
						[77.62144928031121, 12.955744216631293],
						[77.62177480682577, 12.95561040515355],
						[77.62235452840515, 12.955320564208927],
						[77.62324630779047, 12.954816680014906],
						[77.62424070077783, 12.954170101138393],
						[77.6246197146383, 12.953804501469225],
						[77.62490956847252, 12.95354136871656],
						[77.62516375813227, 12.95324264185219],
						[77.62552047930994, 12.952743194394422],
						[77.6256765062276, 12.95253814011888],
						[77.62587274145544, 12.952208097499128],
						[77.62620717094794, 12.951592825123878],
						[77.62623391827161, 12.951503576663526],
						[77.62630970232084, 12.951253896401061],
						[77.62636774220405, 12.951155801310028],
						[77.62636774220405, 12.951146834538674],
						[77.62639448948956, 12.951071036465901],
						[77.62663075690186, 12.950620655835339],
						[77.6266843387506, 12.950500275434061],
						[77.62697864512201, 12.95001870660048],
						[77.62726394762306, 12.949488080219439],
						[77.62704551296063, 12.949323113009795],
						[77.62706780223134, 12.949305299150499],
						[77.6271391278679, 12.94924732914855],
						[77.62723720054434, 12.949162533517857],
						[77.62726394762306, 12.949135794125066],
						[77.62736210759931, 12.949024361382724],
						[77.62745572224304, 12.94890391670061],
						[77.6275627103119, 12.94875229841669],
						[77.6276653278575, 12.94859624616696],
						[77.62781243615176, 12.948328690779759],
						[77.62802204110133, 12.947958599554939],
						[77.6283341751875, 12.947445816537263],
						[77.62861510386504, 12.94695533558592],
						[77.62871763304268, 12.94679031348331],
						[77.62882916510246, 12.946634258042009],
						[77.62901193409292, 12.946357807807326],
						[77.6293017766078, 12.9458539609998],
						[77.6295024632672, 12.945506120689286],
						[77.62956932960508, 12.945358962587756],
						[77.63001082125095, 12.944400265756725],
						[77.63062170437742, 12.94303128501118],
						[77.63098732363912, 12.942242084107876],
						[77.6312370418729, 12.941622265231793],
						[77.6314198943545, 12.94116296637649],
						[77.63172756133864, 12.940498507605387],
						[77.63217787240626, 12.93951309902013],
						[77.63245887888387, 12.939000309167671],
						[77.63284678029493, 12.938197620165997],
						[77.63307866393757, 12.937747313450572],
						[77.63319010426655, 12.937537721620188],
						[77.63327488624674, 12.937435135161383],
						[77.63338186877789, 12.937305850995903],
						[77.63362266650444, 12.937056152888388],
						[77.63419786943909, 12.936570027694772],
						[77.6344163776078, 12.93637831696865],
						[77.63458585225088, 12.936249004511055],
						[77.63525465840426, 12.935557864408377],
						[77.63560697745208, 12.935241255332203],
						[77.6356738401583, 12.935178847495672],
						[77.63574961784393, 12.935120831646179],
						[77.63578536531571, 12.935067367087466],
						[77.63578536531571, 12.935036159214082],
						[77.63578090780761, 12.935004910592413],
						[77.63574070282475, 12.934982642375882],
						[77.63539292912233, 12.934911267220475],
						[77.63514322012269, 12.93487113427607],
						[77.63500940663619, 12.93483102665672],
						[77.63457247958333, 12.934666074302728],
						[77.63447432590286, 12.934608102698263],
						[77.6344163776078, 12.934572399352705],
						[77.63425581784684, 12.93437618148816],
						[77.63413546342811, 12.934197835546943],
						[77.63407305738247, 12.934064072550767],
						[77.63391695471317, 12.933760825936302],
						[77.63381443027767, 12.933537902317514],
						[77.63369398798247, 12.9331633449772],
						[77.63357809055768, 12.932654954048076],
						[77.63351122660438, 12.93232503132635],
						[77.63342644480248, 12.931896925406601],
						[77.63339524158712, 12.931718537273985],
						[77.63339524158712, 12.931691813256249],
						[77.63339969918985, 12.931660561059672],
						[77.63343090240397, 12.931620501737568],
						[77.6336137513165, 12.93142424691709],
						[77.63387237888314, 12.931192391855753],
						[77.63397044568586, 12.93108984067075],
						[77.63399719116265, 12.931045257879783],
						[77.63402402403659, 12.930982834601894],
						[77.6340373967694, 12.9309159746544],
						[77.6340373967694, 12.930866923395808],
						[77.63403293919197, 12.930817831423935],
						[77.63401510888048, 12.930777668902161],
						[77.63391695471317, 12.930670649068965],
						[77.63388129405055, 12.930608254979411],
						[77.63397490326577, 12.930536869890117],
						[77.63441192004541, 12.930148978018444],
						[77.63477306942947, 12.929832370956738],
						[77.63505406941785, 12.929573740987788],
						[77.63521899816618, 12.929417663276299],
						[77.63537509902666, 12.929194699266914],
						[77.6354285893052, 12.929096654975005],
						[77.63558468987449, 12.928944982689137],
						[77.63581656786735, 12.928806760084026],
						[77.63605290287913, 12.928690818750045],
						[77.63634272723708, 12.928570451305497],
						[77.63650328401583, 12.928516920253275],
						[77.63664146579575, 12.928494646456425],
						[77.63682876695033, 12.92852583993933],
						[77.63694020356, 12.928579354967223],
						[77.6370829296602, 12.928797896951918],
						[77.63736829391567, 12.929301701510868],
						[77.63754222154431, 12.929546994918786],
						[77.63784541433941, 12.929943811460705],
						[77.6379792244479, 12.93011330997897],
						[77.6381486937164, 12.930215892735713],
						[77.63833144755077, 12.930273851989428],
						[77.63848308668544, 12.930282737847662],
						[77.63861689603424, 12.9302024956548],
						[77.63880410649507, 12.930082047149385],
						[77.63904043522128, 12.929948342617584],
						[77.63925447660073, 12.929814520927476],
						[77.63966918607497, 12.929551410205423],
						[77.63999474723408, 12.929368607106206],
						[77.64028901875018, 12.929208101274215],
						[77.64051642971134, 12.92914120996719],
						[77.64076612676583, 12.929096590106202],
						[77.64102919516549, 12.929087694927137],
						[77.64130572221417, 12.929101080394059],
						[77.64155987479229, 12.929127793029506],
						[77.64178728317953, 12.929118917300555],
						[77.64198794753834, 12.929074280813355],
						[77.64212175271071, 12.929052053154821],
						[77.64205480644584, 12.928860320411198],
						[77.64186305674043, 12.928423316266558],
						[77.64180956952627, 12.928169124897828],
						[77.64167130670754, 12.927749937648645],
						[77.64156878934959, 12.927366511044772],
						[77.64151084471456, 12.927081075466676],
						[77.64144389808541, 12.926537113124967],
						[77.6413681242416, 12.926260655386105],
						[77.6413547523815, 12.92621156962402],
						[77.6413413805198, 12.926166966520258],
						[77.64127897847752, 12.926113469658821],
						[77.64118528795116, 12.926082270541022],
						[77.64112288582213, 12.926064462323321],
						[77.6408999333943, 12.926059931876175],
						[77.64023553078619, 12.926073361652842],
						[77.64009280869222, 12.926086714764557],
						[77.63997691786886, 12.926095660218945],
						[77.63986985421913, 12.926095631103827],
						[77.63981190870736, 12.926091224757986],
						[77.63974504846432, 12.926082254683259],
						[77.63970038754775, 12.926028743310315],
						[77.63967364342875, 12.926015396987665],
						[77.63961124045964, 12.926015346456625],
						[77.6395265506604, 12.926001990131601],
						[77.63941057185339, 12.92595739851349],
						[77.63931696716745, 12.925899479859387],
						[77.63928576558814, 12.925828077849447],
						[77.63926784871093, 12.925743345618818],
						[77.63925893397098, 12.92562740684601],
						[77.63925893397098, 12.925560512893734],
						[77.63926339134105, 12.925529347777749],
						[77.63929022295713, 12.925471407927658],
						[77.63931696716746, 12.925408981339622],
						[77.6393125097995, 12.9253286854267],
						[77.63927230608066, 12.924771271534306],
						[77.6391920733987, 12.924147037641504],
						[77.63923218974685, 12.924133610076527],
						[77.63949971910078, 12.924026655288626],
						[77.6399724605271, 12.923834852695508],
						[77.64045848453321, 12.92366988894175],
						[77.64088656147845, 12.923504914657915],
						[77.64110951393282, 12.92342022374168],
						[77.64120311712452, 12.923393402991108],
						[77.64127897847753, 12.923384526942852],
						[77.64134583780722, 12.92338004825305],
						[77.6414394408019, 12.923389001409793],
						[77.64154204567559, 12.923415711184745],
						[77.64213066722338, 12.923585196274288],
						[77.64287083149756, 12.92377243483575],
						[77.64404360039204, 12.924097998817366],
						[77.64474809484037, 12.924329822874125],
						[77.6449175538922, 12.92438334430758],
						[77.64507364127104, 12.924432434212292],
						[77.64521189989901, 12.924454725272136],
						[77.64543038663764, 12.92447257150491],
						[77.64565778718345, 12.92447696427802],
						[77.64586290173092, 12.924472534342513],
						[77.64630878642866, 12.924481410387491],
						[77.64680824160578, 12.924508187739349],
						[77.64751726313344, 12.924584020014398],
						[77.64805673873285, 12.924619688312282],
						[77.64855618612876, 12.92465982388741],
						[77.64906900224359, 12.924691027925062],
						[77.64932313724086, 12.924691004079026],
						[77.64968878300805, 12.924641993212616],
						[77.65004997064177, 12.92455725219884],
						[77.6501793092648, 12.924508167838066],
						[77.65028636307983, 12.924472502281919],
						[77.65056277992724, 12.924410093114133],
						[77.65087048185113, 12.9243610730903],
						[77.65129851907905, 12.924316502584716],
						[77.65149470954502, 12.924280780128882],
						[77.65156610705925, 12.924271860093528],
						[77.65162404650188, 12.924262987410142],
						[77.65165970152863, 12.924258482183546],
						[77.6516999008099, 12.924254021406973],
						[77.65172664206294, 12.92423615990072],
						[77.65174446956142, 12.924204942129778],
						[77.6517533833096, 12.92417821270062],
						[77.6517489264356, 12.924048950038879],
						[77.65165970152863, 12.923558406927873],
						[77.6515973052244, 12.9232685384972],
						[77.65157056394051, 12.923134765297123],
						[77.65155273641439, 12.923032278972393],
						[77.65153045200275, 12.922929666271134],
						[77.65152153823685, 12.922836053567469],
						[77.65151253708036, 12.922675541982931],
						[77.65152153823685, 12.922354428773321],
						[77.65154382265027, 12.92177921730691],
						[77.65155719329618, 12.921324440690558],
						[77.6515616501778, 12.921239656607405],
						[77.65156610705925, 12.921204008586512],
						[77.65158393458324, 12.921181756654148],
						[77.65161513274343, 12.92115945305016],
						[77.65166415840618, 12.921150499554408],
						[77.65193620238063, 12.921128222168111],
						[77.65269867354283, 12.921021156889884],
						[77.6529707150074, 12.920994395474574],
						[77.6531223343615, 12.920998870245807],
						[77.65326058306935, 12.920998914097213],
						[77.65345677010235, 12.9209810329124],
						[77.6535771038053, 12.920967636167536],
						[77.65363067279783, 12.920954330138343],
						[77.65366187038852, 12.92093202612688],
						[77.65367078398427, 12.92089184932143],
						[77.6536752407819, 12.920856201131029],
						[77.65367078398427, 12.920802714571778],
						[77.65367078398427, 12.920771508805057],
						[77.65367078398427, 12.920735820452206],
						[77.65367078398427, 12.920717976279862],
						[77.6536752407819, 12.920704568696634],
						[77.6536752407819, 12.920686724528814],
						[77.65369306797054, 12.920677833926122],
						[77.65370643836017, 12.92066890312588],
						[77.65374209272476, 12.92066448348613],
						[77.65379566163844, 12.920664452767037],
						[77.65486581111506, 12.920575245810888],
						[77.65687237938604, 12.919112725196257],
						[77.66051106061074, 12.916530890485488],
						[77.66220994522025, 12.918314549567198],
						[77.66280745853521, 12.920312209870502],
						[77.66283865357099, 12.920954294010379],
						[77.662843197385, 12.921221836627312],
						[77.66212081602318, 12.921413606182503],
						[77.6614207124517, 12.921569625037774],
						[77.6613359517373, 12.921587480429286],
						[77.66128693030517, 12.921600832840747],
						[77.66126910432455, 12.921618689747389],
						[77.66124236534826, 12.921658880199768],
						[77.66122008286315, 12.921698938301866],
						[77.66121108248555, 12.921725750423569],
						[77.66128247381027, 12.921908549903979],
						[77.66155449443843, 12.922604191558237],
						[77.66156340740639, 12.922626426932583],
						[77.66175957990407, 12.9231348349117],
						[77.66197366531124, 12.923861602744523],
						[77.6622590536299, 12.924735658362577],
						[77.66249533293683, 12.925689882558004],
						[77.66283419713784, 12.926492535756973],
						[77.66327564530167, 12.927531478412948],
						[77.66339605584082, 12.927758870014666],
						[77.6634852714035, 12.92785700892292],
						[77.66356548670673, 12.927977392383543],
						[77.66358785610566, 12.928106655962532],
						[77.66354320468383, 12.928200307844568],
						[77.6634719021807, 12.928325190643417],
						[77.66337377378419, 12.92843663825456],
						[77.66323999397062, 12.928592767630292],
						[77.66313749633059, 12.928695299441834],
						[77.66305719333992, 12.928775526301328],
						[77.6626647651655, 12.929163472304495],
						[77.66248196359587, 12.929306228216284],
						[77.66235263916946, 12.929462225151038],
						[77.66225450979219, 12.929587121775532],
						[77.66200486057926, 12.929761001369794],
						[77.66184879677041, 12.929845718083675],
						[77.66162579816229, 12.929966123549049],
						[77.6614385384082, 12.930050903612477],
						[77.66113977849768, 12.930086568295373],
						[77.6609436049145, 12.930064277710837],
						[77.66064038716345, 12.929997394913869],
						[77.66040401656059, 12.929917062103932],
						[77.66011416697417, 12.929872542202162],
						[77.65972181587209, 12.929787784738433],
						[77.65957911857149, 12.929783304259976],
						[77.65914211241929, 12.929765471277111],
						[77.65522252533704, 12.93511638581645],
						[77.65809419950092, 12.936686049960013],
						[77.66050660408497, 12.938112926714224],
						[77.6605422562861, 12.938144115272824],
						[77.6605422562861, 12.938233351286993],
						[77.6605422562861, 12.938273442851692],
						[77.66051106061074, 12.938309113827426],
						[77.6604530383791, 12.938353677964406],
						[77.65907517631342, 12.938326956080202],
						[77.65902615389197, 12.938126314220558],
						[77.65901278413696, 12.937907803426796],
						[77.65821015899273, 12.937947941847854],
						[77.658178962668, 12.938509799384647],
						[77.65816559281188, 12.938772902134358],
						[77.65806745976687, 12.938781752749284],
						[77.65785345427982, 12.938795158084591],
						[77.65769738468774, 12.938853145415822],
						[77.65754131487827, 12.939142973359196],
						[77.65733167765329, 12.939481888494523],
						[77.65708201747022, 12.939709308809965],
						[77.65672530916629, 12.939932220184785],
						[77.65673422251844, 12.93994118151],
						[77.65649784367508, 12.94010164014778],
						[77.65626600841395, 12.940186443046715],
						[77.65586909974088, 12.94021318224214],
						[77.655704113952, 12.940217631445218],
						[77.6547141941189, 12.940222079631189],
						[77.65395173666354, 12.94052975571756],
						[77.65253368308792, 12.9412432342507],
						[77.64999648732974, 12.942518475911735],
						[77.64802108256076, 12.943740272369874],
						[77.6474681482651, 12.944244173026249],
						[77.6474726053092, 12.94479714002421],
						[77.64656301507222, 12.94555514962621],
						[77.64242047589693, 12.948997595369477],
						[77.6397940793131, 12.951597219118758],
						[77.63948188965696, 12.9519316508707],
						[77.63867929955586, 12.95291713097307],
						[77.63784987176558, 12.95394719803105],
						[77.63781866977868, 12.953982866600596],
						[77.63705172746027, 12.95489253965031],
						[77.63686442667743, 12.95511551421907],
						[77.63669941294319, 12.955333951538861],
						[77.63622228765198, 12.956248107752002],
						[77.63639621705495, 12.956288255248593],
						[77.63635609969393, 12.956430933811545],
						[77.63635164220848, 12.956435376492273],
						[77.63617771273653, 12.956925860958584],
						[77.63572287278426, 12.958156594791676],
						[77.63562480751096, 12.958433082060042],
						[77.63524128581632, 12.95961024704085],
						[77.6350317817306, 12.960256833077985],
						[77.63479098702358, 12.960934602846978],
						[77.63473740903854, 12.961170888221583],
						[77.63476415433279, 12.961434046058306],
						[77.63429602336105, 12.961777395368548],
						[77.63431385362794, 12.961991407687584],
						[77.63431831119424, 12.96204491083156],
						[77.63430493849485, 12.962276829872147],
						[77.63430493849485, 12.962437302506707],
						[77.63430493849485, 12.962624592714842],
						[77.63429156579387, 12.962847544984891],
						[77.63438963223076, 12.962851986185523],
						[77.6344163776078, 12.963048233350683],
						[77.63427373552342, 12.963074940429852],
						[77.63425136027807, 12.963297893192738],
						[77.63420678458068, 12.963387074301393],
						[77.63417112401007, 12.963489703469296],
						[77.6341532937205, 12.96375719708066],
						[77.63416666643793, 12.96398013979705],
						[77.63419341186804, 12.96410059219694],
						[77.6342290724316, 12.964220954662151],
						[77.63427373552342, 12.96429233859266],
						[77.63428710822653, 12.964403786012166],
						[77.634282650659, 12.96448850966933],
						[77.63429156579387, 12.964591034306348],
						[77.63429156579387, 12.964689165092832],
						[77.63357809055768, 12.96467134959875],
						[77.63355134498113, 12.965358018468375],
						[77.63389912438328, 12.96537141306938],
						[77.63369398798247, 12.965812828783363],
						[77.633649412064, 12.965893129330865],
						[77.6335691753662, 12.966156170113393],
						[77.63347547840912, 12.966575335923526],
						[77.6332392253804, 12.967779284014979],
						[77.63316335859767, 12.968153864624524],
						[77.63313661292243, 12.968359024646102],
						[77.6331054096266, 12.968492742409444],
						[77.63275762776823, 12.968461578865064],
						[77.63216449945219, 12.968354578604597],
						[77.63102298521994, 12.968122628671585],
						[77.63002419446124, 12.968064724819444],
						[77.62893169408491, 12.968144950233386],
						[77.6290030185393, 12.968697929822707],
						[77.62902530742201, 12.968920875422812],
						[77.62896735631776, 12.969201801426905],
						[77.62822718785259, 12.970414691955629],
						[77.62736656544125, 12.971850500835515],
						[77.626162592061, 12.973758964601044],
						[77.6253465330794, 12.975074386632814],
						[77.6252752063061, 12.975029828020066],
						[77.62341579926994, 12.973883836364816],
						[77.62201117135263, 12.97308116783565],
						[77.62155636150545, 12.97278691986023],
						[77.62130216369617, 12.972711075177068],
						[77.6209186805639, 12.972679900697138],
						[77.62057531913071, 12.972679885166473],
						[77.62018737539574, 12.972733348857398],
						[77.61996892699442, 12.972746746269008],
						[77.61976822332764, 12.972697700480769],
						[77.61947835625044, 12.97259961823737],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000005a",
			properties: {
				AREA_SQ_KM: 11.33057557940508,
				ASS_CONST1: "Shanthi Nagar",
				ASS_CONST_: "163",
				LAT: 12.9442634034865,
				LON: 77.6390466853178,
				LOST_AREA_SQ_KM: 0.6083880196145152,
				OBJECTID: 91,
				POP_F: 15524.0,
				POP_M: 20108.0,
				POP_SC: 12434.0,
				POP_ST: 167.0,
				POP_TOTAL: 35632.0,
				RESERVATIO: "Scheduled Caste (Woman)",
				WARD_NAME: "Agaram",
				WARD_NO: 114.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61212530835715, 12.958441966780894],
						[77.61183550902807, 12.956127716410869],
						[77.61183996748493, 12.956123188348878],
						[77.61178646599093, 12.956007329256693],
						[77.61178646599093, 12.956002845884475],
						[77.61178200753196, 12.955993923825657],
						[77.61134053179862, 12.95485241750935],
						[77.61132715636822, 12.954816685011544],
						[77.61132269789107, 12.954807763061934],
						[77.61131378093623, 12.954749827950202],
						[77.61131378093621, 12.954745344632382],
						[77.61131378093623, 12.954682923071307],
						[77.61131378093623, 12.954638348579062],
						[77.61134053179862, 12.954593764591227],
						[77.61138957502983, 12.954535765850146],
						[77.61144307671229, 12.954473325319636],
						[77.61151887071895, 12.954402039542037],
						[77.61164379521608, 12.95430836697821],
						[77.61178200753196, 12.954241455852785],
						[77.61205843165506, 12.954147810589813],
						[77.61223239841836, 12.954103220762672],
						[77.61231710877017, 12.954058658847117],
						[77.61235277626767, 12.954045282242646],
						[77.61238398531871, 12.954031950354292],
						[77.61239290218884, 12.954022980514887],
						[77.61243302809575, 12.954134488252995],
						[77.61244640339487, 12.954161253946102],
						[77.612615823713, 12.954103254358621],
						[77.61280762329095, 12.95405865751569],
						[77.61309742011953, 12.954005140041472],
						[77.61337838683072, 12.953951629579919],
						[77.61331142345288, 12.953684073927858],
						[77.61341405399223, 12.95367071982962],
						[77.61356118091405, 12.95364398134838],
						[77.61372614117364, 12.953586025447112],
						[77.61378855850617, 12.953844654992219],
						[77.61406060675611, 12.954896979791197],
						[77.61417652428766, 12.9550485956556],
						[77.61419881610699, 12.955039663840319],
						[77.61420773283348, 12.955039660564363],
						[77.61421219119646, 12.955035218667184],
						[77.6143994422823, 12.95495048307839],
						[77.61450207183034, 12.954914798055254],
						[77.61497474350759, 12.954749863406603],
						[77.61525115979636, 12.954651729856966],
						[77.61583528585113, 12.95442876924324],
						[77.61614745374116, 12.954308380647758],
						[77.61621432801559, 12.954281584449133],
						[77.61671820035674, 12.954072015194965],
						[77.61705265705369, 12.95393381235824],
						[77.61737819628247, 12.953773281731232],
						[77.61752531802578, 12.953697481944861],
						[77.61761448262514, 12.953652872478088],
						[77.61762785730896, 12.953648427046183],
						[77.61775714583725, 12.953559270117745],
						[77.61772593827504, 12.953247128712569],
						[77.61779735724917, 12.952926028830094],
						[77.61797568594642, 12.952667391488646],
						[77.61842159386939, 12.952355284270823],
						[77.61851967414403, 12.952306273832656],
						[77.61876496187212, 12.952266132760442],
						[77.618889790917, 12.952266083208373],
						[77.61887195820478, 12.951882594097041],
						[77.61889870727205, 12.95161505073106],
						[77.61904136885684, 12.951690865041463],
						[77.61925990647316, 12.951762213024702],
						[77.61944714916535, 12.951766707492537],
						[77.61981280474795, 12.951842522481957],
						[77.62001350833523, 12.951797914298186],
						[77.61975039075458, 12.951227127067709],
						[77.61942485838499, 12.950638521595867],
						[77.61919749217321, 12.95031749981163],
						[77.61897895443575, 12.949925084344843],
						[77.61891653998003, 12.949800182530172],
						[77.61902799434095, 12.949719999596017],
						[77.61889870727205, 12.949496968441018],
						[77.61917520134342, 12.949345379157583],
						[77.61910387065844, 12.949238414537664],
						[77.62001350833523, 12.94870773777556],
						[77.62020074977389, 12.949060023799644],
						[77.62067339752883, 12.94994294540952],
						[77.62074481463306, 12.950076728401543],
						[77.6209008481731, 12.95033087432082],
						[77.62111046600012, 12.950750022814995],
						[77.62120408584596, 12.951013076635682],
						[77.62135566066934, 12.951392113331718],
						[77.62145373838746, 12.951606152087702],
						[77.62150286462767, 12.9516864482277],
						[77.62165889711677, 12.951624005260562],
						[77.6218506812916, 12.951543704482914],
						[77.62221178360704, 12.9513876296286],
						[77.62234115428295, 12.951334168029998],
						[77.62244368917922, 12.951285110408415],
						[77.62272017454215, 12.951151361335292],
						[77.62285837326279, 12.95107995810153],
						[77.62314377362556, 12.95094172131289],
						[77.62327305581812, 12.950879288183758],
						[77.6232998038394, 12.950865913126112],
						[77.62331326525948, 12.950857074336811],
						[77.62336676127661, 12.950825840873472],
						[77.62339350927562, 12.950816862916726],
						[77.62346483724193, 12.950790105226542],
						[77.62346929523831, 12.950785663118983],
						[77.6236119510296, 12.950754478093891],
						[77.6236297829908, 12.950749987209537],
						[77.62404455011433, 12.950660799502192],
						[77.62441910667702, 12.950580547391002],
						[77.62475799843644, 12.95050919028949],
						[77.6249630637306, 12.950464613597353],
						[77.6254357788926, 12.950362077077537],
						[77.6254625263997, 12.950357582152373],
						[77.62547590015087, 12.950353136125866],
						[77.62550264764845, 12.95034424406982],
						[77.62564975877139, 12.950308529065413],
						[77.62576575177901, 12.950263994337291],
						[77.6258014150158, 12.950250615016106],
						[77.62581033082324, 12.95024612779426],
						[77.62583707824128, 12.95023275234426],
						[77.6260288552942, 12.950130202752204],
						[77.62617596572893, 12.950023184935162],
						[77.62629187078444, 12.949933984722415],
						[77.62636774220405, 12.949871576940238],
						[77.62648364706193, 12.949777893300876],
						[77.62658617818282, 12.949697622387088],
						[77.6266620494039, 12.949635214518283],
						[77.62672445956353, 12.949586215223833],
						[77.62686711122691, 12.949474672499699],
						[77.62693843699077, 12.949412305442202],
						[77.62699201869292, 12.949372194651339],
						[77.62704551296063, 12.949323113009795],
						[77.62726394762306, 12.949488080219439],
						[77.62697864512201, 12.95001870660048],
						[77.6266843387506, 12.950500275434061],
						[77.62663075690186, 12.950620655835339],
						[77.62639448948956, 12.951071036465901],
						[77.62636774220405, 12.951146834538674],
						[77.62636774220405, 12.951155801310028],
						[77.62630970232084, 12.951253896401061],
						[77.62623391827161, 12.951503576663526],
						[77.62620717094794, 12.951592825123878],
						[77.62587274145544, 12.952208097499128],
						[77.6256765062276, 12.95253814011888],
						[77.62552047930994, 12.952743194394422],
						[77.62516375813227, 12.95324264185219],
						[77.62490956847252, 12.95354136871656],
						[77.6246197146383, 12.953804501469225],
						[77.62424070077783, 12.954170101138393],
						[77.62324630779047, 12.954816680014906],
						[77.62235452840515, 12.955320564208927],
						[77.62177480682577, 12.95561040515355],
						[77.62144928031121, 12.955744216631293],
						[77.62100784247555, 12.955900232244424],
						[77.62096771962405, 12.955918138989583],
						[77.62095434533703, 12.955953796684213],
						[77.62095880343288, 12.956007295102241],
						[77.62096326152854, 12.956065277045427],
						[77.62100784247555, 12.956364016110252],
						[77.62107025577166, 12.956569112350463],
						[77.62057086101969, 12.956569131951378],
						[77.61985292801114, 12.95656910188462],
						[77.61940702575752, 12.956600310079477],
						[77.61917520134342, 12.95667170439248],
						[77.61899678713102, 12.956809895057615],
						[77.61900124530439, 12.957095328899614],
						[77.61901016165062, 12.957322691505933],
						[77.61858208881976, 12.95727370436376],
						[77.61858208881976, 12.95730491651744],
						[77.6178954380676, 12.957238006178839],
						[77.61770810537847, 12.957211257834917],
						[77.61777943694781, 12.95693920352501],
						[77.61766798135086, 12.956966017224607],
						[77.61745398629546, 12.95699274013697],
						[77.61706157355425, 12.957077465874484],
						[77.61684303167085, 12.957086380967922],
						[77.61676724124665, 12.957086366133481],
						[77.61670036730057, 12.957331646317698],
						[77.61666915944546, 12.95755458239509],
						[77.61661566024519, 12.957969326453373],
						[77.61659336890423, 12.95796929157895],
						[77.61576395305025, 12.958053998530119],
						[77.61453773863605, 12.958183378130283],
						[77.61376180822505, 12.95828142159472],
						[77.61268715850757, 12.958392887871167],
						[77.61212530835715, 12.958441966780894],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000005b",
			properties: {
				AREA_SQ_KM: 0.7392789730703773,
				ASS_CONST1: "Shanthi Nagar",
				ASS_CONST_: "163",
				LAT: 12.9542092834691,
				LON: 77.61894093987,
				LOST_AREA_SQ_KM: 0.0359664017377068,
				OBJECTID: 92,
				POP_F: 17541.0,
				POP_M: 17991.0,
				POP_SC: 9468.0,
				POP_ST: 1099.0,
				POP_TOTAL: 35532.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Vannarpet",
				WARD_NO: 115.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61239290218884, 12.954022980514887],
						[77.61244640339487, 12.953982870038622],
						[77.61248652928272, 12.95391151061238],
						[77.61250882143645, 12.953858004666232],
						[77.61251773829672, 12.953826790763866],
						[77.61253111358579, 12.953791135057653],
						[77.61254894730207, 12.953746554252277],
						[77.61255340573071, 12.953706418511665],
						[77.61255340573071, 12.953701935216749],
						[77.61255340573071, 12.953661844221786],
						[77.61253557201512, 12.953599343278528],
						[77.61253557201512, 12.953590462914656],
						[77.61245086182757, 12.953380857056473],
						[77.61227698282198, 12.952921600713616],
						[77.6121386836928, 12.952573806861691],
						[77.61208518234066, 12.952426655346821],
						[77.61199601336396, 12.952212613485642],
						[77.61197817956015, 12.952154596680106],
						[77.61195142884915, 12.952123396311563],
						[77.6119157612246, 12.952096682440658],
						[77.6118578013106, 12.952060967175205],
						[77.61175971523441, 12.952020826470516],
						[77.6117418813932, 12.952016435982271],
						[77.61167054600014, 12.951985165721336],
						[77.61167054600013, 12.951980768719839],
						[77.61160804007325, 12.951936127582787],
						[77.61157237233999, 12.951904930533725],
						[77.61155008000098, 12.951878168717624],
						[77.61153670459545, 12.951860370076595],
						[77.61152332918833, 12.951846968433255],
						[77.61149211989888, 12.951735460031625],
						[77.61147428601532, 12.951668563345402],
						[77.6114609106008, 12.951646281542528],
						[77.61143861823972, 12.951624003000859],
						[77.61137174113004, 12.951623984301438],
						[77.61130486398068, 12.95162396558159],
						[77.61080542592258, 12.951650783344538],
						[77.61026585898654, 12.951677485003295],
						[77.610154395955, 12.951686448319593],
						[77.60988688422954, 12.951739911325664],
						[77.60984675741594, 12.95137872823912],
						[77.60982446473554, 12.951262820506237],
						[77.60980663058805, 12.951164714619118],
						[77.60980217205075, 12.951124583318665],
						[77.60978433789973, 12.950968541416625],
						[77.60972637688944, 12.950758931972574],
						[77.60966395730657, 12.950589457255294],
						[77.60963711862375, 12.950513680471305],
						[77.60961482590189, 12.950384324317715],
						[77.60956132335144, 12.95010794187613],
						[77.60953457206669, 12.94998302783521],
						[77.60857589274967, 12.950219377498899],
						[77.60753249139417, 12.950393290125987],
						[77.6074344015535, 12.94979129763513],
						[77.60715342002237, 12.949826955078358],
						[77.60659608837304, 12.949916164740273],
						[77.60602974917052, 12.949987525587037],
						[77.60579789722833, 12.949978553203822],
						[77.6058424841773, 12.949840338942833],
						[77.60586031895197, 12.949773431815805],
						[77.6058647776452, 12.949768990329053],
						[77.60588261241632, 12.949706566283792],
						[77.60731393102635, 12.949523774195171],
						[77.6084555108658, 12.94936319332232],
						[77.60931610300356, 12.94922947264526],
						[77.60957915753774, 12.949175968046543],
						[77.60996267928287, 12.949131346304945],
						[77.61074746543746, 12.94897527445645],
						[77.61109977380845, 12.948926221970492],
						[77.61128257158875, 12.94894852630729],
						[77.61146982754398, 12.948979751948764],
						[77.61157237233999, 12.949068901529865],
						[77.61170175524015, 12.949238396531294],
						[77.61175525677437, 12.949300795290503],
						[77.61249098771381, 12.949296342012207],
						[77.6131286289643, 12.94930528671956],
						[77.61365034865193, 12.949296341377787],
						[77.61445302995406, 12.949309749589865],
						[77.61514861834904, 12.949300776577758],
						[77.6161920365952, 12.94927408095268],
						[77.61665132638154, 12.94925618655206],
						[77.61698578327713, 12.949247307195595],
						[77.61710169779793, 12.949242864093797],
						[77.61702144929625, 12.949140300105228],
						[77.61731123528277, 12.948917361789356],
						[77.61758327502339, 12.948725558048649],
						[77.61819859642077, 12.948284152531466],
						[77.61924653198322, 12.947499312630445],
						[77.61933569521942, 12.947432459590514],
						[77.6193669023354, 12.947485942712689],
						[77.6193669023354, 12.947490425863224],
						[77.61944269100962, 12.947628639439323],
						[77.61950073443498, 12.947740138237915],
						[77.62001350833523, 12.94870773777556],
						[77.61910387065844, 12.949238414537664],
						[77.61917520134342, 12.949345379157583],
						[77.61889870727205, 12.949496968441018],
						[77.61902799434095, 12.949719999596017],
						[77.61891653998003, 12.949800182530172],
						[77.61897895443575, 12.949925084344843],
						[77.61919749217321, 12.95031749981163],
						[77.61942485838499, 12.950638521595867],
						[77.61975039075458, 12.951227127067709],
						[77.62001350833523, 12.951797914298186],
						[77.61981280474795, 12.951842522481957],
						[77.61944714916535, 12.951766707492537],
						[77.61925990647316, 12.951762213024702],
						[77.61904136885684, 12.951690865041463],
						[77.61889870727205, 12.95161505073106],
						[77.61887195820478, 12.951882594097041],
						[77.618889790917, 12.952266083208373],
						[77.61876496187212, 12.952266132760442],
						[77.61851967414403, 12.952306273832656],
						[77.61842159386939, 12.952355284270823],
						[77.61797568594642, 12.952667391488646],
						[77.61779735724917, 12.952926028830094],
						[77.61772593827504, 12.953247128712569],
						[77.61775714583725, 12.953559270117745],
						[77.61762785730896, 12.953648427046183],
						[77.61761448262514, 12.953652872478088],
						[77.61752531802578, 12.953697481944861],
						[77.61737819628247, 12.953773281731232],
						[77.61705265705369, 12.95393381235824],
						[77.61671820035674, 12.954072015194965],
						[77.61621432801559, 12.954281584449133],
						[77.61614745374116, 12.954308380647758],
						[77.61583528585113, 12.95442876924324],
						[77.61525115979636, 12.954651729856966],
						[77.61497474350759, 12.954749863406603],
						[77.61450207183034, 12.954914798055254],
						[77.6143994422823, 12.95495048307839],
						[77.61421219119646, 12.955035218667184],
						[77.61420773283348, 12.955039660564363],
						[77.61419881610699, 12.955039663840319],
						[77.61417652428766, 12.9550485956556],
						[77.61406060675611, 12.954896979791197],
						[77.61378855850617, 12.953844654992219],
						[77.61372614117364, 12.953586025447112],
						[77.61356118091405, 12.95364398134838],
						[77.61341405399223, 12.95367071982962],
						[77.61331142345288, 12.953684073927858],
						[77.61337838683072, 12.953951629579919],
						[77.61309742011953, 12.954005140041472],
						[77.61280762329095, 12.95405865751569],
						[77.612615823713, 12.954103254358621],
						[77.61244640339487, 12.954161253946102],
						[77.61243302809575, 12.954134488252995],
						[77.61239290218884, 12.954022980514887],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000005c",
			properties: {
				AREA_SQ_KM: 0.5310222710761389,
				ASS_CONST1: "Shanthi Nagar",
				ASS_CONST_: "163",
				LAT: 12.9511599438781,
				LON: 77.614644130188,
				LOST_AREA_SQ_KM: 0.014933189677758306,
				OBJECTID: 93,
				POP_F: 17326.0,
				POP_M: 18953.0,
				POP_SC: 4888.0,
				POP_ST: 1365.0,
				POP_TOTAL: 36279.0,
				RESERVATIO: "Scheduled Tribe",
				WARD_NAME: "Nilasandra",
				WARD_NO: 116.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59459657275296, 12.962192104587281],
						[77.59436469731553, 12.961683733459372],
						[77.59395900158877, 12.96062694048986],
						[77.59341052313037, 12.959222308579987],
						[77.5929646037211, 12.958067392205546],
						[77.59283082755496, 12.957710668978507],
						[77.5927505617792, 12.957483255126228],
						[77.59242503888156, 12.956578046129442],
						[77.59242057965727, 12.956546880932441],
						[77.59227788438702, 12.956105417496504],
						[77.59239828353319, 12.956016197668921],
						[77.59240274275835, 12.956016239595296],
						[77.59335255370675, 12.955325027064596],
						[77.59518517749385, 12.953938250878988],
						[77.59554190614632, 12.953670717184501],
						[77.59714725856452, 12.952377583254531],
						[77.598890728837, 12.951022018070834],
						[77.59881938531348, 12.950950656929537],
						[77.59884168016943, 12.950928364333793],
						[77.59893531851631, 12.950839235662164],
						[77.59911367705733, 12.950692017156163],
						[77.59971117611566, 12.950206028612795],
						[77.59975576547076, 12.950170323058716],
						[77.60011247967724, 12.949907265663132],
						[77.60018836873057, 12.949862631422903],
						[77.6002730881319, 12.949813593337002],
						[77.60030875943974, 12.949786856482628],
						[77.60033105400142, 12.949773443521151],
						[77.6003578074696, 12.949760072235296],
						[77.60038010202159, 12.949760108202593],
						[77.60043360892838, 12.949751211314643],
						[77.60046928018548, 12.94974671689979],
						[77.60057629388909, 12.949769011250492],
						[77.6009775943736, 12.949880525694097],
						[77.60227521026547, 12.95021939413519],
						[77.6029797040039, 12.950406673839788],
						[77.60335878940684, 12.950504791629962],
						[77.60343458884908, 12.950504723376676],
						[77.6034167536908, 12.950437914653264],
						[77.60434417818243, 12.95029518150377],
						[77.60573101677178, 12.950098972302673],
						[77.60575776895915, 12.950094566255457],
						[77.60579789722833, 12.949978553203822],
						[77.60602974917052, 12.949987525587037],
						[77.60659608837304, 12.949916164740273],
						[77.60715342002237, 12.949826955078358],
						[77.6074344015535, 12.94979129763513],
						[77.60753249139417, 12.950393290125987],
						[77.60857589274967, 12.950219377498899],
						[77.60953457206669, 12.94998302783521],
						[77.60956132335144, 12.95010794187613],
						[77.60961482590189, 12.950384324317715],
						[77.60963711862375, 12.950513680471305],
						[77.60966395730657, 12.950589457255294],
						[77.60972637688944, 12.950758931972574],
						[77.60978433789973, 12.950968541416625],
						[77.60980217205075, 12.951124583318665],
						[77.60980663058805, 12.951164714619118],
						[77.60982446473554, 12.951262820506237],
						[77.60984675741594, 12.95137872823912],
						[77.60988688422954, 12.951739911325664],
						[77.610154395955, 12.951686448319593],
						[77.61026585898654, 12.951677485003295],
						[77.61080542592258, 12.951650783344538],
						[77.61130486398068, 12.95162396558159],
						[77.61137174113004, 12.951623984301438],
						[77.61143861823972, 12.951624003000859],
						[77.6114609106008, 12.951646281542528],
						[77.61147428601532, 12.951668563345402],
						[77.61149211989888, 12.951735460031625],
						[77.61152332918833, 12.951846968433255],
						[77.61153670459545, 12.951860370076595],
						[77.61155008000098, 12.951878168717624],
						[77.61157237233999, 12.951904930533725],
						[77.61160804007325, 12.951936127582787],
						[77.61167054600013, 12.951980768719839],
						[77.61167054600014, 12.951985165721336],
						[77.6117418813932, 12.952016435982271],
						[77.61175971523441, 12.952020826470516],
						[77.6118578013106, 12.952060967175205],
						[77.6119157612246, 12.952096682440658],
						[77.61195142884915, 12.952123396311563],
						[77.61197817956015, 12.952154596680106],
						[77.61199601336396, 12.952212613485642],
						[77.61208518234066, 12.952426655346821],
						[77.6121386836928, 12.952573806861691],
						[77.61227698282198, 12.952921600713616],
						[77.61245086182757, 12.953380857056473],
						[77.61253557201512, 12.953590462914656],
						[77.61253557201512, 12.953599343278528],
						[77.61255340573071, 12.953661844221786],
						[77.61255340573071, 12.953701935216749],
						[77.61255340573071, 12.953706418511665],
						[77.61254894730207, 12.953746554252277],
						[77.61253111358579, 12.953791135057653],
						[77.61251773829672, 12.953826790763866],
						[77.61250882143645, 12.953858004666232],
						[77.61248652928272, 12.95391151061238],
						[77.61244640339487, 12.953982870038622],
						[77.61239290218884, 12.954022980514887],
						[77.61238398531871, 12.954031950354292],
						[77.61235277626767, 12.954045282242646],
						[77.61231710877017, 12.954058658847117],
						[77.61223239841836, 12.954103220762672],
						[77.61205843165506, 12.954147810589813],
						[77.61178200753196, 12.954241455852785],
						[77.61164379521608, 12.95430836697821],
						[77.61151887071895, 12.954402039542037],
						[77.61144307671229, 12.954473325319636],
						[77.61138957502983, 12.954535765850146],
						[77.61134053179862, 12.954593764591227],
						[77.61131378093623, 12.954638348579062],
						[77.61131378093623, 12.954682923071307],
						[77.61131378093621, 12.954745344632382],
						[77.61131378093623, 12.954749827950202],
						[77.61132269789107, 12.954807763061934],
						[77.61132715636822, 12.954816685011544],
						[77.61134053179862, 12.95485241750935],
						[77.61178200753196, 12.955993923825657],
						[77.61178646599093, 12.956002845884475],
						[77.61178646599093, 12.956007329256693],
						[77.61183996748493, 12.956123188348878],
						[77.61183550902807, 12.956127716410869],
						[77.61212530835715, 12.958441966780894],
						[77.61231265033219, 12.959909045060598],
						[77.61231710877017, 12.959962544102941],
						[77.61233048408305, 12.960087345540087],
						[77.61233048408305, 12.960198830422273],
						[77.61233048408305, 12.960221161900161],
						[77.61247761241998, 12.961741688735367],
						[77.61155008000098, 12.962896608613427],
						[77.61097484870815, 12.96358326494809],
						[77.61028369306136, 12.964608907120192],
						[77.60967287439195, 12.965424917434609],
						[77.60883449043585, 12.96663336006269],
						[77.60819245370915, 12.966553108218992],
						[77.60712666816615, 12.96633014219374],
						[77.60675660017053, 12.966209753869817],
						[77.60667634430035, 12.966205294192333],
						[77.60468758908719, 12.965893136252834],
						[77.60468313034741, 12.96475157934908],
						[77.60447794070093, 12.963614529458313],
						[77.60362185784093, 12.963957860291716],
						[77.60362185784093, 12.963926647792123],
						[77.60326952609985, 12.962802973560082],
						[77.60305550370092, 12.962852010094867],
						[77.6025873282874, 12.961371606801029],
						[77.60297078638914, 12.961255613281518],
						[77.6024357282132, 12.959766328905507],
						[77.60216373933407, 12.959846550642908],
						[77.60199876215343, 12.959913462510825],
						[77.60179356762333, 12.96002051683897],
						[77.60158846014866, 12.960172064174984],
						[77.6010756898305, 12.960488681439271],
						[77.60041577329561, 12.96089444723118],
						[77.59996087627728, 12.961179872493664],
						[77.59907800537168, 12.961541035037822],
						[77.59875695969338, 12.961657003047714],
						[77.59848050296073, 12.961746143974088],
						[77.59811040659717, 12.961848717179866],
						[77.59775368606196, 12.96196909029358],
						[77.59732561993141, 12.96208060128012],
						[77.59695551978075, 12.962125146486976],
						[77.59613050595848, 12.962254474097263],
						[77.59575148370399, 12.96230798610442],
						[77.59542151035214, 12.962348163800959],
						[77.59522976863705, 12.962374898215433],
						[77.59509599515461, 12.962441752465844],
						[77.59500681274491, 12.96249083404176],
						[77.59499343537739, 12.962481913807512],
						[77.59479277467422, 12.962348110363944],
						[77.59474372422588, 12.962316954814893],
						[77.594618868443, 12.962218783578988],
						[77.59459657275296, 12.962192104587281],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000005d",
			properties: {
				AREA_SQ_KM: 2.713320763365272,
				ASS_CONST1: "Shanthi Nagar",
				ASS_CONST_: "163",
				LAT: 12.9573236548961,
				LON: 77.603705049467,
				LOST_AREA_SQ_KM: 0.1508125065857831,
				OBJECTID: 94,
				POP_F: 17754.0,
				POP_M: 18672.0,
				POP_SC: 4113.0,
				POP_ST: 650.0,
				POP_TOTAL: 36426.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Shanthi Nagar",
				WARD_NO: 117.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.62851257459393, 12.978137837558483],
						[77.62842778902206, 12.978035282511843],
						[77.62830297055868, 12.977932703196334],
						[77.62819589578605, 12.977865816265037],
						[77.62809782394567, 12.977821179978495],
						[77.62777677355264, 12.977700816058892],
						[77.62742451737046, 12.97754920175874],
						[77.62732198701384, 12.977495727598924],
						[77.62729078210415, 12.97746896175749],
						[77.6272505740845, 12.97743771052956],
						[77.62724611623794, 12.977433270850684],
						[77.62723720054434, 12.977424391493514],
						[77.62720599561112, 12.977393141272815],
						[77.62713467001694, 12.977237075501648],
						[77.62703659725112, 12.976889226147867],
						[77.62700093440597, 12.976750999576812],
						[77.62699201869292, 12.976737635916962],
						[77.62692060555403, 12.976541466451922],
						[77.62667988088162, 12.97620703772845],
						[77.62654605732055, 12.976077725700394],
						[77.62622946038478, 12.975765527683208],
						[77.62574792015639, 12.975346413337384],
						[77.6253465330794, 12.975074386632814],
						[77.626162592061, 12.973758964601044],
						[77.62736656544125, 12.971850500835515],
						[77.62822718785259, 12.970414691955629],
						[77.62896735631776, 12.969201801426905],
						[77.63032294981628, 12.969634264796529],
						[77.63253911637435, 12.970383431467084],
						[77.6339793608455, 12.970842714189047],
						[77.63492917090946, 12.971279684462953],
						[77.63632043980544, 12.971846049721407],
						[77.63758679591832, 12.97234993995668],
						[77.63813077665782, 12.972510465169048],
						[77.63865701258783, 12.972532762218545],
						[77.63960232574695, 12.972590671109074],
						[77.64029347607936, 12.972639756671546],
						[77.64112288582213, 12.972662007034316],
						[77.64111397122942, 12.972773517465956],
						[77.64105156906075, 12.974008661043694],
						[77.64098016485602, 12.975252785720057],
						[77.64094896373884, 12.976059882934027],
						[77.63998583255183, 12.976055444765834],
						[77.63930359506308, 12.976055416847712],
						[77.63877290477326, 12.976042042763098],
						[77.63774735091897, 12.975992948376005],
						[77.63669049799938, 12.975970721718035],
						[77.63585668542103, 12.975961775900021],
						[77.63572733029464, 12.975966265107457],
						[77.6356649251331, 12.975966203972542],
						[77.63562480751096, 12.975627342233448],
						[77.63562034999651, 12.9755871996368],
						[77.63559360490606, 12.9753910162269],
						[77.63541967426055, 12.975417773121706],
						[77.63516105025516, 12.975457887804719],
						[77.63474632413732, 12.97555157160531],
						[77.63440300492009, 12.975631777039489],
						[77.63398381842505, 12.975725417747958],
						[77.63368507280019, 12.975801206011752],
						[77.63353351459324, 12.975850335549245],
						[77.63338186877789, 12.975921624169752],
						[77.63343981760642, 12.975591694788537],
						[77.63352014180046, 12.974927286073449],
						[77.63352459939823, 12.974869289100706],
						[77.63352459939823, 12.97482021956997],
						[77.63352459939823, 12.974775634442885],
						[77.63351568420251, 12.974744419770769],
						[77.63349330880622, 12.97469539804792],
						[77.63341752959897, 12.97455714540883],
						[77.63322130753888, 12.974195968805864],
						[77.63306529109066, 12.973892776465217],
						[77.63299396921364, 12.973776815547968],
						[77.63283786504545, 12.974352033775814],
						[77.63274425488314, 12.974851430679916],
						[77.63269959118345, 12.975065486823754],
						[77.63263718434047, 12.975194782247607],
						[77.63252128582585, 12.975364285643366],
						[77.63229385867983, 12.975694188542368],
						[77.63199065090433, 12.976122303619103],
						[77.63184791833598, 12.976287242760014],
						[77.63174539201304, 12.976394261330682],
						[77.63163840792421, 12.976456739198625],
						[77.63124595724935, 12.976639534257814],
						[77.63071531630737, 12.97684467334336],
						[77.63020696150912, 12.977089946736974],
						[77.62969869181907, 12.977375324044342],
						[77.62916804357404, 12.977696363849656],
						[77.62882916510246, 12.977910356811833],
						[77.62851257459393, 12.978137837558483],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000005e",
			properties: {
				AREA_SQ_KM: 0.9053600809978807,
				ASS_CONST1: "Shanthi Nagar",
				ASS_CONST_: "163",
				LAT: 12.973724534752,
				LON: 77.6325939694915,
				LOST_AREA_SQ_KM: 0.02394459948808258,
				OBJECTID: 95,
				POP_F: 16901.0,
				POP_M: 17710.0,
				POP_SC: 3570.0,
				POP_ST: 661.0,
				POP_TOTAL: 34611.0,
				RESERVATIO: "General",
				WARD_NAME: "Jogupalya",
				WARD_NO: 89.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58100972263354, 12.973045496534146],
						[77.57949359797757, 12.973709918847053],
						[77.5782896381632, 12.974253915681619],
						[77.57754056721629, 12.973281869979527],
						[77.57544032632605, 12.973964066756306],
						[77.57541802685276, 12.973906085220497],
						[77.575373427893, 12.973803572910601],
						[77.57528868982101, 12.973669810532877],
						[77.5752173313954, 12.973620759657008],
						[77.57513259320652, 12.973611846892549],
						[77.57486954587549, 12.973152539402394],
						[77.57465992959582, 12.973250644053953],
						[77.57447270004155, 12.973379918896354],
						[77.57438350135936, 12.973473567162504],
						[77.57368346416274, 12.973696590766526],
						[77.57292535528737, 12.973843696297251],
						[77.57257756272475, 12.972969718597932],
						[77.57231896947302, 12.972528304822788],
						[77.57224314915499, 12.972604058767756],
						[77.57199784777818, 12.972777952143922],
						[77.57185075414864, 12.972876049882087],
						[77.57166789253891, 12.972969750269046],
						[77.57145835778223, 12.972983100241272],
						[77.57118629433715, 12.973000936494346],
						[77.57086525688202, 12.972987527318141],
						[77.57055759877724, 12.972974159294933],
						[77.57029454081491, 12.972991997602545],
						[77.57027224032791, 12.971279721594119],
						[77.57022317924103, 12.970106939517098],
						[77.57047731718997, 12.970102550542004],
						[77.5714092972093, 12.969999956294565],
						[77.57170803291757, 12.96999547394618],
						[77.57189981453025, 12.969915223147654],
						[77.57221192900928, 12.969750242682615],
						[77.57360318479213, 12.969018911272512],
						[77.57485170620723, 12.968381309388946],
						[77.57630090774013, 12.967574206556142],
						[77.57698317686095, 12.967204090772636],
						[77.57801321879396, 12.96674036457231],
						[77.57802659817158, 12.966776013374046],
						[77.57807119608555, 12.966860758029037],
						[77.57807119608555, 12.966874121789576],
						[77.57832977619323, 12.967364655233187],
						[77.57850370749217, 12.967703507794662],
						[77.57865087992084, 12.967993393693467],
						[77.5798592956104, 12.967333392238928],
						[77.5804924866353, 12.966999007857485],
						[77.58106323871675, 12.966677910217996],
						[77.58115243213246, 12.96660210808936],
						[77.5812148674816, 12.966584249561294],
						[77.58122378681439, 12.966575367445305],
						[77.5812817624603, 12.96654418895646],
						[77.58157155279987, 12.967105976002188],
						[77.58197738044899, 12.967819409587841],
						[77.58224041125231, 12.968318871894821],
						[77.58270867102522, 12.969536177613413],
						[77.58290926574826, 12.970071269417625],
						[77.58294494254052, 12.970173866600614],
						[77.58279340353883, 12.970379018864703],
						[77.58277110551508, 12.971752411840155],
						[77.58276664590981, 12.971788065669726],
						[77.58276664590981, 12.972042250307657],
						[77.58240095767863, 12.972274079793204],
						[77.58195508226433, 12.9725639542833],
						[77.58185697019958, 12.97261294526278],
						[77.58142001195753, 12.972853756142818],
						[77.58100972263354, 12.973045496534146],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000005f",
			properties: {
				AREA_SQ_KM: 0.7706408629593264,
				ASS_CONST1: "Gandhi Nagar",
				ASS_CONST_: "164",
				LAT: 12.9706877407971,
				LON: 77.5770978874836,
				LOST_AREA_SQ_KM: 0.0018418764958400351,
				OBJECTID: 96,
				POP_F: 16028.0,
				POP_M: 17670.0,
				POP_SC: 228.0,
				POP_ST: 75.0,
				POP_TOTAL: 33698.0,
				RESERVATIO: "General",
				WARD_NAME: "Chickpete",
				WARD_NO: 109.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57022317924103, 12.970106939517098],
						[77.57027224032791, 12.971279721594119],
						[77.57029454081491, 12.972991997602545],
						[77.57027670042567, 12.972996483050471],
						[77.57027670042565, 12.972978635460256],
						[77.56993781994893, 12.972969710425296],
						[77.56963899211678, 12.973018759298169],
						[77.5691128713131, 12.973206008033017],
						[77.56860896142993, 12.972055594524972],
						[77.56818533257372, 12.970824916839502],
						[77.56803377380577, 12.969603061365461],
						[77.56803823399166, 12.968849482052155],
						[77.56770380701579, 12.968836117534345],
						[77.5674718764307, 12.968898564283304],
						[77.56738721989974, 12.968974319136331],
						[77.56720435108231, 12.96930881669602],
						[77.56711068645173, 12.969536223690595],
						[77.56707500466733, 12.969661069634594],
						[77.5670883853378, 12.969768106613735],
						[77.56713298756128, 12.970057877726548],
						[77.56710176600669, 12.970222944109674],
						[77.56690560349342, 12.970343328438927],
						[77.56673165437338, 12.97045478254485],
						[77.56666037801025, 12.970552909606658],
						[77.56664699728924, 12.97058856192401],
						[77.56664699728924, 12.970668830473349],
						[77.56666929849007, 12.970758020328908],
						[77.56668713944757, 12.970873895107376],
						[77.56675395555756, 12.97109688653784],
						[77.56686546141252, 12.971293064332523],
						[77.56690114326292, 12.971493776654384],
						[77.5668476204831, 12.971694412080682],
						[77.56672719413604, 12.97199312799132],
						[77.56664253704854, 12.972363234292711],
						[77.56656225268604, 12.972813649988971],
						[77.56636600178263, 12.973482471964207],
						[77.5662501226359, 12.97391056768928],
						[77.56613415591455, 12.974276237020964],
						[77.56599151493538, 12.974543795029186],
						[77.56581310413843, 12.974784537622282],
						[77.56547867059346, 12.975065519485742],
						[77.56515315666051, 12.975324082850774],
						[77.56473397519683, 12.975591663220516],
						[77.56447982436167, 12.975783398959297],
						[77.56437286394643, 12.975881530605854],
						[77.5643193399722, 12.975845839261018],
						[77.56397152098124, 12.975783445825808],
						[77.56336954759739, 12.975671972112961],
						[77.56293251798986, 12.975640709409024],
						[77.56270066509644, 12.975631762862639],
						[77.5626739027176, 12.974133526199797],
						[77.5626739027176, 12.973995317092983],
						[77.56261145714238, 12.972265178455157],
						[77.56156360826105, 12.972332050951152],
						[77.56150562251719, 12.971984211418679],
						[77.56109980893956, 12.972144738816914],
						[77.56090363603622, 12.97206448900228],
						[77.56084564995474, 12.97148929726812],
						[77.56086795229727, 12.970642090566828],
						[77.56079212431476, 12.970048974620125],
						[77.56069854184426, 12.96947380028427],
						[77.56033733027563, 12.968506115719995],
						[77.56072530468951, 12.968069189212262],
						[77.56112211123208, 12.967699073416709],
						[77.56161713353676, 12.967369042066812],
						[77.56205854117839, 12.96723980219113],
						[77.56253571778508, 12.967226433616359],
						[77.56307525027835, 12.967373584531432],
						[77.56344091348889, 12.967600978541398],
						[77.56391799681744, 12.968006693154374],
						[77.56414538688136, 12.968314411334532],
						[77.56422121262045, 12.968421440145653],
						[77.56427027630694, 12.968412512046196],
						[77.56428811764222, 12.968408027095922],
						[77.5643550226245, 12.968385733843927],
						[77.56496145108028, 12.968189524634205],
						[77.56506841093992, 12.968140539146642],
						[77.56520668024088, 12.967930892435367],
						[77.56531818761874, 12.96770348834358],
						[77.5655678762904, 12.967489471932145],
						[77.56557679685622, 12.967418171210042],
						[77.5655411145887, 12.967262039920323],
						[77.56550097202428, 12.967092589179869],
						[77.56552327345071, 12.966847349382405],
						[77.56553219402005, 12.966735873118282],
						[77.56576850139528, 12.96676709572394],
						[77.56601827653068, 12.966793880805291],
						[77.56616537773583, 12.966776056853323],
						[77.56631256620592, 12.966726941095846],
						[77.56640614404053, 12.966713606137665],
						[77.5664462862842, 12.967150581307713],
						[77.56699926829359, 12.966976704883677],
						[77.56775724193622, 12.966972206651274],
						[77.56775724193622, 12.96721300588175],
						[77.56793118948131, 12.967221908602001],
						[77.56786874680355, 12.968225208740543],
						[77.56804269417741, 12.968216351458148],
						[77.56809621639253, 12.96821186199534],
						[77.56854651912583, 12.968167269965683],
						[77.56974603501298, 12.968037916292197],
						[77.57012059689978, 12.967957683736214],
						[77.57023655953957, 12.967957669045239],
						[77.57042379610017, 12.96790418668981],
						[77.57085187665778, 12.967721348059829],
						[77.57136023661512, 12.967516253841017],
						[77.57173033312178, 12.967342344232797],
						[77.57220746898776, 12.967114878649001],
						[77.57306361499596, 12.966829545492724],
						[77.57322863442707, 12.966758177638551],
						[77.57333112650164, 12.966691297734128],
						[77.5733668063147, 12.966646718665254],
						[77.57340694609094, 12.966606579092598],
						[77.5735898048915, 12.966526328024669],
						[77.57384839524005, 12.966405927617494],
						[77.5738751549786, 12.96644601426817],
						[77.57392867443674, 12.966548603904915],
						[77.57421402366951, 12.967163972652072],
						[77.57480710702427, 12.96830553027961],
						[77.57482048678101, 12.968327815672346],
						[77.57485170620723, 12.968381309388946],
						[77.57360318479213, 12.969018911272512],
						[77.57221192900928, 12.969750242682615],
						[77.57189981453025, 12.969915223147654],
						[77.57170803291757, 12.96999547394618],
						[77.5714092972093, 12.969999956294565],
						[77.57047731718997, 12.970102550542004],
						[77.57022317924103, 12.970106939517098],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000060",
			properties: {
				AREA_SQ_KM: 0.7560613313254417,
				ASS_CONST1: "Gandhi Nagar",
				ASS_CONST_: "164",
				LAT: 12.9703588123314,
				LON: 77.5662871520615,
				LOST_AREA_SQ_KM: 0.028819742540366976,
				OBJECTID: 97,
				POP_F: 17534.0,
				POP_M: 19048.0,
				POP_SC: 3115.0,
				POP_ST: 305.0,
				POP_TOTAL: 36582.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Cottonpete",
				WARD_NO: 120.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56033733027563, 12.968506115719995],
						[77.56069854184426, 12.96947380028427],
						[77.56079212431476, 12.970048974620125],
						[77.56086795229727, 12.970642090566828],
						[77.56084564995474, 12.97148929726812],
						[77.56090363603622, 12.97206448900228],
						[77.56109980893956, 12.972144738816914],
						[77.56150562251719, 12.971984211418679],
						[77.56156360826105, 12.972332050951152],
						[77.56261145714238, 12.972265178455157],
						[77.5626739027176, 12.973995317092983],
						[77.5626739027176, 12.974133526199797],
						[77.56270066509644, 12.975631762862639],
						[77.56264714033244, 12.975627369323451],
						[77.56264714033244, 12.97561840242633],
						[77.56168395263632, 12.975662968776465],
						[77.56129160851194, 12.975685333045591],
						[77.5612826876088, 12.975894848027378],
						[77.56127822715696, 12.975970678820243],
						[77.56122470172122, 12.97605095299492],
						[77.56121132035832, 12.976340783033885],
						[77.56118455762784, 12.976577114733246],
						[77.56118455762784, 12.976594962373552],
						[77.56026596243002, 12.976661848188469],
						[77.56041761904824, 12.977330739113377],
						[77.56037301418158, 12.977428774599879],
						[77.56030164635844, 12.977674076991667],
						[77.56009655107741, 12.97819581614513],
						[77.55974871912785, 12.979069779425032],
						[77.55946779397827, 12.979591482582958],
						[77.5592626971888, 12.979872407550829],
						[77.55919132866673, 12.979769805851408],
						[77.55915118385333, 12.979613788357746],
						[77.55916902599438, 12.979435394098516],
						[77.55913334170947, 12.979194577686348],
						[77.55905313948895, 12.979016278640335],
						[77.5589416259474, 12.978900358316514],
						[77.55881235756353, 12.978828933381298],
						[77.55864731713258, 12.978820016246264],
						[77.5584779033614, 12.978828994339644],
						[77.55825942307014, 12.978828964076765],
						[77.55807653952428, 12.978797715905447],
						[77.55788482197543, 12.978695167261234],
						[77.55763065578371, 12.978556925469347],
						[77.55737648902235, 12.978418769979164],
						[77.55697520875017, 12.978325070694462],
						[77.55687261441606, 12.978329514584033],
						[77.55665859166587, 12.978222526333482],
						[77.556413344013, 12.978066436654988],
						[77.55608334339216, 12.977807877295783],
						[77.55577564515534, 12.977598247552269],
						[77.55553039559335, 12.977491217573016],
						[77.55529852756334, 12.977277231912051],
						[77.55516925502701, 12.97701414176924],
						[77.5551380301771, 12.976942796342996],
						[77.55501759138993, 12.976617280126815],
						[77.55490170064246, 12.976421053951894],
						[77.55466537061545, 12.975957339275828],
						[77.5544914901776, 12.975560443765549],
						[77.55449595089638, 12.975560486733148],
						[77.55569544056021, 12.975605042250038],
						[77.55854481177315, 12.975765540921739],
						[77.5587053043384, 12.975765528673195],
						[77.55868746212438, 12.975569380992633],
						[77.5586562382431, 12.97532413267437],
						[77.5585760356851, 12.975141270923139],
						[77.55851804841319, 12.974994141748008],
						[77.55844221885901, 12.974744413762206],
						[77.55832624414867, 12.974454553940054],
						[77.55805869721134, 12.97400425579365],
						[77.55792942782092, 12.973785748147405],
						[77.55793388840452, 12.973660861813617],
						[77.55795619131982, 12.973188174161562],
						[77.55793388840452, 12.972876112849177],
						[77.55773324943206, 12.972024351747985],
						[77.55754590443898, 12.971061245306513],
						[77.55738094962769, 12.970245189853356],
						[77.55716692783676, 12.970303143410204],
						[77.55715800660909, 12.969991046689207],
						[77.55711786107584, 12.96946483957593],
						[77.55698412999224, 12.969482698428726],
						[77.55670765862548, 12.969469356338937],
						[77.55637765886095, 12.96942920453483],
						[77.55599421769539, 12.969353367099005],
						[77.5556552945093, 12.969268642484806],
						[77.55556162033523, 12.969237481501716],
						[77.5553832805874, 12.969183957002441],
						[77.5552049405592, 12.969125949340798],
						[77.55471443843729, 12.967770373576489],
						[77.55465198847855, 12.967618815949942],
						[77.55470105630616, 12.967583078206903],
						[77.5546207634863, 12.967400313119445],
						[77.5553832805874, 12.967097090574484],
						[77.55564191248915, 12.96711491616315],
						[77.55585593715777, 12.967106018554041],
						[77.5560387368206, 12.967061387799484],
						[77.55618147778787, 12.966936543323701],
						[77.5562260843033, 12.966887485703124],
						[77.55630182787563, 12.96682510201994],
						[77.55637765886097, 12.966789362054412],
						[77.55656045768296, 12.966726971052262],
						[77.55668089483196, 12.9667046769935],
						[77.55677894124736, 12.966624402576448],
						[77.55687261441606, 12.966472792248485],
						[77.556997511854, 12.966414848993816],
						[77.55720261274047, 12.966352412629472],
						[77.5573452647801, 12.966298860428102],
						[77.55743001627476, 12.966205271172534],
						[77.557407713256, 12.966102723816364],
						[77.55736310720529, 12.965875300591179],
						[77.55787144021836, 12.965549804745876],
						[77.55823265964284, 12.965349114757611],
						[77.55838423151866, 12.965264352462391],
						[77.55858486933931, 12.965045915246291],
						[77.5587231465496, 12.964827401234622],
						[77.55880789701449, 12.96476053730977],
						[77.55888363886243, 12.964751603816259],
						[77.55975317963988, 12.966535200841204],
						[77.55986023187563, 12.966521828915749],
						[77.56000734107391, 12.96645047122946],
						[77.56025258095397, 12.966298927152888],
						[77.5604533029289, 12.966138338576714],
						[77.56056918800317, 12.966031334274032],
						[77.56064501613481, 12.965933260734777],
						[77.56074314658282, 12.965745998405579],
						[77.56087687323306, 12.965456136112582],
						[77.56095716162353, 12.965300084615897],
						[77.56103744995715, 12.965135067165471],
						[77.56115333443425, 12.964983491317252],
						[77.56124254353594, 12.96490321952364],
						[77.5614030322823, 12.964827419661102],
						[77.56165719001739, 12.964747170630082],
						[77.56176424045513, 12.9646757766796],
						[77.56177762175275, 12.964662369412476],
						[77.56211206622038, 12.965081538641517],
						[77.56245543051273, 12.96557209296561],
						[77.56278541258779, 12.966031312649507],
						[77.56314215597669, 12.96666899420982],
						[77.56361924056299, 12.96739136957744],
						[77.56414984722036, 12.968113745168681],
						[77.5643550226245, 12.968385733843927],
						[77.56428811764222, 12.968408027095922],
						[77.56427027630694, 12.968412512046196],
						[77.56422121262045, 12.968421440145653],
						[77.56414538688136, 12.968314411334532],
						[77.56391799681744, 12.968006693154374],
						[77.56344091348889, 12.967600978541398],
						[77.56307525027835, 12.967373584531432],
						[77.56253571778508, 12.967226433616359],
						[77.56205854117839, 12.96723980219113],
						[77.56161713353676, 12.967369042066812],
						[77.56112211123208, 12.967699073416709],
						[77.56072530468951, 12.968069189212262],
						[77.56033733027563, 12.968506115719995],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000061",
			properties: {
				AREA_SQ_KM: 0.7754790341097761,
				ASS_CONST1: "Gandhi Nagar",
				ASS_CONST_: "164",
				LAT: 12.9717984207048,
				LON: 77.5590969095086,
				LOST_AREA_SQ_KM: 0.012843252380311254,
				OBJECTID: 98,
				POP_F: 16499.0,
				POP_M: 17447.0,
				POP_SC: 16499.0,
				POP_ST: 692.0,
				POP_TOTAL: 33946.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Binnipete",
				WARD_NO: 121.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57220746898776, 13.002854600725694],
						[77.57220746898776, 13.002872452940196],
						[77.57218962889993, 13.003278225520479],
						[77.57218516887752, 13.00331837171323],
						[77.57218070885496, 13.00333618100007],
						[77.57216286876292, 13.003354033783873],
						[77.5721093484699, 13.003385255208283],
						[77.57206474820639, 13.00339413945344],
						[77.57197563507823, 13.003389661464803],
						[77.57173033312178, 13.003367374155392],
						[77.57148511808577, 13.003358458458957],
						[77.57135577656004, 13.003349535501203],
						[77.57129333577059, 13.003349537002574],
						[77.57132009611314, 13.002114374815717],
						[77.57136023661512, 12.999416631213117],
						[77.57140037710285, 12.99733425744667],
						[77.57139591704936, 12.996834809381731],
						[77.57013397721045, 12.996767910722038],
						[77.56918423358667, 12.996776883607161],
						[77.56864018256906, 12.996781303896189],
						[77.56854651912583, 12.996781349969316],
						[77.56854205895993, 12.996611851053341],
						[77.5685732801175, 12.996295231099616],
						[77.56863126224447, 12.996067865949883],
						[77.56873384593483, 12.995795829061832],
						[77.56889878422257, 12.995327684760127],
						[77.56912625174282, 12.994828239824344],
						[77.56927334891172, 12.99434216176085],
						[77.56936255162026, 12.994025587469439],
						[77.56952757644581, 12.993494931724655],
						[77.56959439088018, 12.993267565914712],
						[77.57037027498507, 12.99332107173957],
						[77.57148511808577, 12.993414656138572],
						[77.57196671501642, 12.993454810667755],
						[77.57291643530039, 12.99356628783365],
						[77.5734426258801, 12.993691120757575],
						[77.5738974547559, 12.993860628063256],
						[77.57393759434396, 12.993691127807685],
						[77.57395989410895, 12.99348601337089],
						[77.57400003367488, 12.993214065138808],
						[77.57404463317586, 12.99303124191192],
						[77.57405801302274, 12.993013347055918],
						[77.57405801302274, 12.9929553965595],
						[77.57409369260667, 12.99285734400502],
						[77.57415613185147, 12.99276368767363],
						[77.57461987771349, 12.99243811095664],
						[77.57509254149144, 12.99212598529529],
						[77.57531990911806, 12.99200113786653],
						[77.57539572737508, 12.991974442137677],
						[77.57550276482789, 12.99199668259013],
						[77.57563201416622, 12.992152797835562],
						[77.57598434466419, 12.992518453390883],
						[77.57656849904178, 12.993075791420464],
						[77.57712589144002, 12.993606401140621],
						[77.57712143161214, 12.993610928947597],
						[77.57725959874689, 12.993735741931815],
						[77.57781698774039, 12.994203918952696],
						[77.57876683382072, 12.995033318049366],
						[77.57940003096142, 12.995666512919511],
						[77.58016246871993, 12.996509302699144],
						[77.58085809359362, 12.997267378138842],
						[77.58041221209587, 12.997298553268264],
						[77.57998862741704, 12.9973342532109],
						[77.57981023867954, 12.997334267784488],
						[77.5796630677593, 12.997334236577618],
						[77.57960955101356, 12.997338725390849],
						[77.57942232964986, 12.997347627348104],
						[77.57940895043733, 12.997142505679339],
						[77.57906109035584, 12.997142533241473],
						[77.57906109035584, 12.997200486675865],
						[77.5782851783812, 12.997222800179708],
						[77.57829855772664, 12.997811345982795],
						[77.57837437398767, 12.998854812177575],
						[77.57841005221059, 12.99909559559414],
						[77.57841451198765, 12.999126771577561],
						[77.57840559243333, 12.999153593301399],
						[77.57839221310053, 12.99917140309947],
						[77.5783654544302, 12.999180287824746],
						[77.57833869575353, 12.999189258787482],
						[77.57823620821439, 12.99922493091092],
						[77.57815593207367, 12.999265038679809],
						[77.5780979548255, 12.999269484041706],
						[77.57800875900107, 12.99926060717525],
						[77.57760746436807, 12.999149127941834],
						[77.57717932191319, 12.999019793935329],
						[77.57705007434231, 12.998984145738934],
						[77.57688060064606, 12.998948452692199],
						[77.5767289660711, 12.99893949312814],
						[77.57649268157057, 12.998917218684324],
						[77.57602894338318, 12.99892164910024],
						[77.57563201416622, 12.998926113594008],
						[77.57515489273607, 12.998926101508522],
						[77.57486954587549, 12.998917238507751],
						[77.57408031276401, 12.998921643133261],
						[77.57391083462016, 12.99892165157146],
						[77.57388853484551, 12.999653014508892],
						[77.57388853484551, 12.999786773881715],
						[77.5738350153684, 13.000567085391287],
						[77.57380825562035, 13.001017439638245],
						[77.5737236038267, 13.001017490571543],
						[77.57353628527314, 13.001017498387522],
						[77.57256864271035, 13.001008532436668],
						[77.57255972269525, 13.001253803338052],
						[77.57255526268743, 13.001632792374743],
						[77.57255972269525, 13.001864653463457],
						[77.57253296264571, 13.001864654377963],
						[77.57225206919503, 13.001860226550688],
						[77.57225206919505, 13.0022035559016],
						[77.57220746898776, 13.002854600725694],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000062",
			properties: {
				AREA_SQ_KM: 0.712029276486532,
				ASS_CONST1: "Gandhi Nagar",
				ASS_CONST_: "164",
				LAT: 12.9965554677515,
				LON: 77.5741380029964,
				LOST_AREA_SQ_KM: 0.017944735615270282,
				OBJECTID: 99,
				POP_F: 16596.0,
				POP_M: 17640.0,
				POP_SC: 3351.0,
				POP_ST: 256.0,
				POP_TOTAL: 34236.0,
				RESERVATIO: "General",
				WARD_NAME: "Dattatreya Temple",
				WARD_NO: 77.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58100972263354, 12.973045496534146],
						[77.58100972263354, 12.973049980135746],
						[77.5810364806783, 12.973081188605137],
						[77.58126838346773, 12.973371033953827],
						[77.58170088264836, 12.974164779253048],
						[77.58289151479103, 12.975667438772762],
						[77.58392150291424, 12.977263786087093],
						[77.58440313434767, 12.978012937413355],
						[77.58437191755769, 12.978030834675742],
						[77.58412664247933, 12.97816456994836],
						[77.58388136686882, 12.978307272579078],
						[77.5836450973105, 12.978454375328127],
						[77.58340873981562, 12.9786104412719],
						[77.5831768414159, 12.978771033221236],
						[77.58294940213877, 12.978935978749918],
						[77.58272650945442, 12.979109938413172],
						[77.5827042114175, 12.97912774784115],
						[77.58266853454923, 12.979154483870282],
						[77.58251244811834, 12.979274924611518],
						[77.58235190185033, 12.979390838932439],
						[77.58218689572622, 12.979497829176719],
						[77.58201751717031, 12.979600383397813],
						[77.58184805091673, 12.979698535618686],
						[77.58166966511227, 12.979787635003364],
						[77.5814912790264, 12.979867938933605],
						[77.5813085204407, 12.979943720547158],
						[77.58112121444496, 12.980015057627826],
						[77.58093390813899, 12.980077513001435],
						[77.58074214183766, 12.98013552803778],
						[77.5805504626561, 12.98018457934733],
						[77.58035869570459, 12.980224658504902],
						[77.58016246871993, 12.980260383540214],
						[77.57996632884024, 12.980287058628454],
						[77.57983253728712, 12.980300441861019],
						[77.57911460736106, 12.980411990221837],
						[77.57841897176455, 12.980501156891956],
						[77.57757178589203, 12.980568010830835],
						[77.57750042890625, 12.980599233959207],
						[77.57740231297728, 12.980657190894544],
						[77.57730419696323, 12.98071963162241],
						[77.57721054068863, 12.980790996342078],
						[77.57712143161214, 12.980866805441142],
						[77.57704115468042, 12.980947006857845],
						[77.57696087769172, 12.98103177833819],
						[77.57688952032062, 12.981120946386797],
						[77.57682262274433, 12.981214554661284],
						[77.5767646448129, 12.98131712963167],
						[77.57671112669598, 12.981415263377047],
						[77.57666652824587, 12.98152227763356],
						[77.57662647707373, 12.981629252540962],
						[77.57659525813715, 12.981740792377988],
						[77.57656849904178, 12.981852202445696],
						[77.57655065964136, 12.981963697816385],
						[77.57654173994008, 12.98207967615711],
						[77.57654173994008, 12.982195567373989],
						[77.57654619979083, 12.982307103655005],
						[77.57655511949173, 12.982422994099984],
						[77.57657741874094, 12.982534528614112],
						[77.57660417783418, 12.982645933406648],
						[77.57663976916737, 12.982757419339825],
						[77.57667990778275, 12.982864468317572],
						[77.5767289660711, 12.98296703250158],
						[77.57678248417959, 12.983069553185375],
						[77.57684492194083, 12.98316319136022],
						[77.57691181950392, 12.983256872247459],
						[77.57696533752592, 12.983319296490894],
						[77.57729527732137, 12.98357346068089],
						[77.57613589278867, 12.983805294501826],
						[77.5747089887599, 12.984010420563193],
						[77.57462879756623, 12.98426015342729],
						[77.57502564285281, 12.984438562774773],
						[77.57534666850869, 12.984630302213544],
						[77.57562309439253, 12.984835412039258],
						[77.5757435112779, 12.984960218436235],
						[77.57580148973254, 12.985022686590508],
						[77.57590852680157, 12.985134171939544],
						[77.57632320703944, 12.985704931228282],
						[77.57661309753053, 12.986106265687761],
						[77.5774112326107, 12.987283459638746],
						[77.57794186208659, 12.988059282613506],
						[77.5792350205303, 12.989998980980832],
						[77.57968090666893, 12.99069019429127],
						[77.57979685951288, 12.99094880367542],
						[77.57754948683882, 12.991742488820256],
						[77.57766990167403, 12.99187187523461],
						[77.57789280432415, 12.99211710684223],
						[77.57780360833797, 12.992299935611985],
						[77.57712589144002, 12.993606401140621],
						[77.57656849904178, 12.993075791420464],
						[77.57598434466419, 12.992518453390883],
						[77.57563201416622, 12.992152797835562],
						[77.57550276482789, 12.99199668259013],
						[77.57539572737508, 12.991974442137677],
						[77.57531990911806, 12.99200113786653],
						[77.57509254149144, 12.99212598529529],
						[77.57461987771349, 12.99243811095664],
						[77.57415613185147, 12.99276368767363],
						[77.57409369260667, 12.99285734400502],
						[77.57405801302274, 12.9929553965595],
						[77.57405801302274, 12.993013347055918],
						[77.57404463317586, 12.99303124191192],
						[77.57400003367488, 12.993214065138808],
						[77.57395989410895, 12.99348601337089],
						[77.57393759434396, 12.993691127807685],
						[77.5738974547559, 12.993860628063256],
						[77.5734426258801, 12.993691120757575],
						[77.57291643530039, 12.99356628783365],
						[77.57196671501642, 12.993454810667755],
						[77.57148511808577, 12.993414656138572],
						[77.57150295828463, 12.992955365773934],
						[77.57157423160002, 12.992094819743155],
						[77.57164113227856, 12.990957689035655],
						[77.57185075414864, 12.990074860474625],
						[77.5722297690936, 12.98805487703047],
						[77.57230558942058, 12.985865423242881],
						[77.57191319463065, 12.984536660984308],
						[77.57142267736766, 12.983368308846135],
						[77.5707538424176, 12.982396243353461],
						[77.57027670042565, 12.98174971542671],
						[77.57027224032791, 12.981740791347997],
						[77.57021425904114, 12.98166495997706],
						[77.57004477510957, 12.981415262559773],
						[77.57012505700351, 12.981312732609362],
						[77.57032130139353, 12.981161043653831],
						[77.5706111198038, 12.980813306909816],
						[77.57098121875917, 12.980331654056805],
						[77.57097675868917, 12.980041806798223],
						[77.57094107812283, 12.978748692215484],
						[77.57092323783544, 12.977237099682268],
						[77.57099459896813, 12.976773296889514],
						[77.57099905903742, 12.975881536323408],
						[77.57064234039095, 12.974731031325993],
						[77.57049069745847, 12.974704318989753],
						[77.57002247457336, 12.974664150778883],
						[77.56964791236202, 12.974588405707847],
						[77.56933579081507, 12.973709937990709],
						[77.5691128713131, 12.973206008033017],
						[77.56963899211678, 12.973018759298169],
						[77.56993781994893, 12.972969710425296],
						[77.57027670042565, 12.972978635460256],
						[77.57027670042567, 12.972996483050471],
						[77.57029454081491, 12.972991997602545],
						[77.57055759877724, 12.972974159294933],
						[77.57086525688202, 12.972987527318141],
						[77.57118629433715, 12.973000936494346],
						[77.57145835778223, 12.972983100241272],
						[77.57166789253891, 12.972969750269046],
						[77.57185075414864, 12.972876049882087],
						[77.57199784777818, 12.972777952143922],
						[77.57224314915499, 12.972604058767756],
						[77.57231896947302, 12.972528304822788],
						[77.57257756272475, 12.972969718597932],
						[77.57292535528737, 12.973843696297251],
						[77.57368346416274, 12.973696590766526],
						[77.57438350135936, 12.973473567162504],
						[77.57447270004155, 12.973379918896354],
						[77.57465992959582, 12.973250644053953],
						[77.57486954587549, 12.973152539402394],
						[77.57513259320652, 12.973611846892549],
						[77.5752173313954, 12.973620759657008],
						[77.57528868982101, 12.973669810532877],
						[77.575373427893, 12.973803572910601],
						[77.57541802685276, 12.973906085220497],
						[77.57544032632605, 12.973964066756306],
						[77.57754056721629, 12.973281869979527],
						[77.5782896381632, 12.974253915681619],
						[77.57949359797757, 12.973709918847053],
						[77.58100972263354, 12.973045496534146],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000063",
			properties: {
				AREA_SQ_KM: 1.8891251285558124,
				ASS_CONST1: "Gandhi Nagar",
				ASS_CONST_: "164",
				LAT: 12.9815648080642,
				LON: 77.5756650129311,
				LOST_AREA_SQ_KM: 0.07328278209817923,
				OBJECTID: 100,
				POP_F: 15744.0,
				POP_M: 19566.0,
				POP_SC: 6153.0,
				POP_ST: 167.0,
				POP_TOTAL: 35310.0,
				RESERVATIO: "General",
				WARD_NAME: "Gandhinagar",
				WARD_NO: 94.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56616091747618, 12.996683174741332],
						[77.56604941092773, 12.996634151606846],
						[77.5663838427879, 12.996210504253312],
						[77.56671381342291, 12.995724465447982],
						[77.56696804670214, 12.995225110181162],
						[77.5670973057839, 12.994912972615042],
						[77.5673247769221, 12.994614203087947],
						[77.56765474481624, 12.9942173334609],
						[77.56802485343341, 12.993717886001726],
						[77.56838604049729, 12.99337900808768],
						[77.56775724193622, 12.993405812981095],
						[77.56778400311438, 12.992629876336935],
						[77.56778400311438, 12.992612025910772],
						[77.56781968467544, 12.991782629284877],
						[77.5678419856454, 12.991220776292263],
						[77.56786874680355, 12.990444939851878],
						[77.5678776671882, 12.989753788941714],
						[77.56791780891041, 12.98885305524041],
						[77.56792226910089, 12.988719267333346],
						[77.56792672929119, 12.98862557701106],
						[77.56792672929119, 12.988603329399263],
						[77.56792672929119, 12.988576597778234],
						[77.56791780891041, 12.988545382628978],
						[77.56791334871976, 12.988505242344386],
						[77.56790888852892, 12.98847846787085],
						[77.56788212738026, 12.988451737717808],
						[77.56785536622527, 12.988433889354551],
						[77.5677929235057, 12.988416042901324],
						[77.5677973837011, 12.988411601773224],
						[77.56781076428624, 12.98841155794263],
						[77.56790442833793, 12.988393746234184],
						[77.56789996814673, 12.988224259791728],
						[77.56789550795537, 12.9878586852932],
						[77.56794903024007, 12.98619987770888],
						[77.56802931361968, 12.98516538424146],
						[77.568100676576, 12.983386152566158],
						[77.56811842985348, 12.982882322158543],
						[77.56815857149036, 12.982449762700796],
						[77.56769934681671, 12.982302572674392],
						[77.56720881130087, 12.98215990711166],
						[77.56662023584245, 12.981972673139742],
						[77.5664329055379, 12.981932504515505],
						[77.56641506454035, 12.98192362445464],
						[77.5662412021231, 12.981874534701317],
						[77.5658800082202, 12.981856670740322],
						[77.56563478051699, 12.981838885110028],
						[77.56562139967482, 12.981847810299026],
						[77.5650149747512, 12.981919164776162],
						[77.56472059424829, 12.981928063586837],
						[77.56458241181144, 12.981905786701306],
						[77.56447536403566, 12.981829914752197],
						[77.56442630043797, 12.981763050002664],
						[77.56436394328583, 12.980443131286524],
						[77.56436840361621, 12.98015777255095],
						[77.56435948295525, 12.979319507016648],
						[77.56435948295525, 12.979243630519699],
						[77.56438624493605, 12.979190170345621],
						[77.56438624493605, 12.979181203129816],
						[77.56445752272987, 12.978931491940163],
						[77.5647428958283, 12.97824478933759],
						[77.56477411803297, 12.978177921495925],
						[77.56477411803297, 12.978169040569112],
						[77.56477857834723, 12.978164599790027],
						[77.5647830386613, 12.978128860217412],
						[77.56482318148005, 12.977919293593],
						[77.56483656241649, 12.977861308068137],
						[77.56481872116757, 12.97769188250979],
						[77.56466715789554, 12.976915986553598],
						[77.56451996728786, 12.976091075111828],
						[77.56445752272987, 12.975939503455944],
						[77.56437286394643, 12.975881530605854],
						[77.56447982436167, 12.975783398959297],
						[77.56473397519683, 12.975591663220516],
						[77.56515315666051, 12.975324082850774],
						[77.56547867059346, 12.975065519485742],
						[77.56581310413843, 12.974784537622282],
						[77.56599151493538, 12.974543795029186],
						[77.56613415591455, 12.974276237020964],
						[77.5662501226359, 12.97391056768928],
						[77.56636600178263, 12.973482471964207],
						[77.56656225268604, 12.972813649988971],
						[77.56664253704854, 12.972363234292711],
						[77.56672719413604, 12.97199312799132],
						[77.5668476204831, 12.971694412080682],
						[77.56690114326292, 12.971493776654384],
						[77.56686546141252, 12.971293064332523],
						[77.56675395555756, 12.97109688653784],
						[77.56668713944757, 12.970873895107376],
						[77.56666929849007, 12.970758020328908],
						[77.56664699728924, 12.970668830473349],
						[77.56664699728924, 12.97058856192401],
						[77.56666037801025, 12.970552909606658],
						[77.56673165437338, 12.97045478254485],
						[77.56690560349342, 12.970343328438927],
						[77.56710176600669, 12.970222944109674],
						[77.56713298756128, 12.970057877726548],
						[77.5670883853378, 12.969768106613735],
						[77.56707500466733, 12.969661069634594],
						[77.56711068645173, 12.969536223690595],
						[77.56720435108231, 12.96930881669602],
						[77.56738721989974, 12.968974319136331],
						[77.5674718764307, 12.968898564283304],
						[77.56770380701579, 12.968836117534345],
						[77.56803823399166, 12.968849482052155],
						[77.56803377380577, 12.969603061365461],
						[77.56818533257372, 12.970824916839502],
						[77.56860896142993, 12.972055594524972],
						[77.5691128713131, 12.973206008033017],
						[77.56933579081507, 12.973709937990709],
						[77.56964791236202, 12.974588405707847],
						[77.57002247457336, 12.974664150778883],
						[77.57049069745847, 12.974704318989753],
						[77.57064234039095, 12.974731031325993],
						[77.57099905903742, 12.975881536323408],
						[77.57099459896813, 12.976773296889514],
						[77.57092323783544, 12.977237099682268],
						[77.57094107812283, 12.978748692215484],
						[77.57097675868917, 12.980041806798223],
						[77.57098121875917, 12.980331654056805],
						[77.5706111198038, 12.980813306909816],
						[77.57032130139353, 12.981161043653831],
						[77.57012505700351, 12.981312732609362],
						[77.57004477510957, 12.981415262559773],
						[77.57021425904114, 12.98166495997706],
						[77.57027224032791, 12.981740791347997],
						[77.57027670042565, 12.98174971542671],
						[77.5707538424176, 12.982396243353461],
						[77.57142267736766, 12.983368308846135],
						[77.57191319463065, 12.984536660984308],
						[77.57230558942058, 12.985865423242881],
						[77.5722297690936, 12.98805487703047],
						[77.57185075414864, 12.990074860474625],
						[77.57164113227856, 12.990957689035655],
						[77.57157423160002, 12.992094819743155],
						[77.57150295828463, 12.992955365773934],
						[77.57148511808577, 12.993414656138572],
						[77.57037027498507, 12.99332107173957],
						[77.56959439088018, 12.993267565914712],
						[77.56952757644581, 12.993494931724655],
						[77.56936255162026, 12.994025587469439],
						[77.56927334891172, 12.99434216176085],
						[77.56912625174282, 12.994828239824344],
						[77.56889878422257, 12.995327684760127],
						[77.56873384593483, 12.995795829061832],
						[77.56863126224447, 12.996067865949883],
						[77.5685732801175, 12.996295231099616],
						[77.56854205895993, 12.996611851053341],
						[77.56854651912583, 12.996781349969316],
						[77.56808729602511, 12.996785813536365],
						[77.56744957538787, 12.996817010101443],
						[77.56719543064463, 12.996843711145786],
						[77.56714190800386, 12.996848196791003],
						[77.56694574556012, 12.996870454871338],
						[77.56674949532108, 12.996870459437837],
						[77.56656225268604, 12.996839250167325],
						[77.56640168379036, 12.996781302628783],
						[77.56621444058044, 12.996705508881906],
						[77.56616091747618, 12.996683174741332],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000064",
			properties: {
				AREA_SQ_KM: 1.2896127584903563,
				ASS_CONST1: "Gandhi Nagar",
				ASS_CONST_: "164",
				LAT: 12.9826668015183,
				LON: 77.5686274619372,
				LOST_AREA_SQ_KM: 0.056597312942175765,
				OBJECTID: 101,
				POP_F: 17264.0,
				POP_M: 18163.0,
				POP_SC: 11378.0,
				POP_ST: 441.0,
				POP_TOTAL: 35427.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Subhash Nagar",
				WARD_NO: 95.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56437286394643, 12.975881530605854],
						[77.56445752272987, 12.975939503455944],
						[77.56451996728786, 12.976091075111828],
						[77.56466715789554, 12.976915986553598],
						[77.56481872116757, 12.97769188250979],
						[77.56483656241649, 12.977861308068137],
						[77.56482318148005, 12.977919293593],
						[77.5647830386613, 12.978128860217412],
						[77.56477857834723, 12.978164599790027],
						[77.56477411803297, 12.978169040569112],
						[77.56477411803297, 12.978177921495925],
						[77.5647428958283, 12.97824478933759],
						[77.56445752272987, 12.978931491940163],
						[77.56438624493605, 12.979181203129816],
						[77.56438624493605, 12.979190170345621],
						[77.56435948295525, 12.979243630519699],
						[77.56435948295525, 12.979319507016648],
						[77.56436840361621, 12.98015777255095],
						[77.56436394328583, 12.980443131286524],
						[77.56442630043797, 12.981763050002664],
						[77.56447536403566, 12.981829914752197],
						[77.56458241181144, 12.981905786701306],
						[77.56472059424829, 12.981928063586837],
						[77.5650149747512, 12.981919164776162],
						[77.56562139967482, 12.981847810299026],
						[77.56563478051699, 12.981838885110028],
						[77.5658800082202, 12.981856670740322],
						[77.5662412021231, 12.981874534701317],
						[77.56641506454035, 12.98192362445464],
						[77.5664329055379, 12.981932504515505],
						[77.56662023584245, 12.981972673139742],
						[77.56720881130087, 12.98215990711166],
						[77.56769934681671, 12.982302572674392],
						[77.56815857149036, 12.982449762700796],
						[77.56811842985348, 12.982882322158543],
						[77.568100676576, 12.983386152566158],
						[77.56802931361968, 12.98516538424146],
						[77.56794903024007, 12.98619987770888],
						[77.56789550795537, 12.9878586852932],
						[77.56789996814673, 12.988224259791728],
						[77.56790442833793, 12.988393746234184],
						[77.56781076428624, 12.98841155794263],
						[77.5677973837011, 12.988411601773224],
						[77.5677929235057, 12.988416042901324],
						[77.56680301814725, 12.988465085179286],
						[77.56569713696975, 12.98851861174549],
						[77.56506841093992, 12.988536453166454],
						[77.56504164912023, 12.988317947029541],
						[77.56396260028903, 12.988384833700986],
						[77.56395813994266, 12.988375909072891],
						[77.56394029855542, 12.988175253067963],
						[77.56226363290966, 12.988273371589221],
						[77.56147439941205, 12.988282243805381],
						[77.56144763674365, 12.988282244468481],
						[77.56143871585279, 12.988282244688781],
						[77.56143871585279, 12.988219814833966],
						[77.56148332030011, 12.987992427881373],
						[77.56154130605539, 12.987800696533556],
						[77.56158145002243, 12.987720459599922],
						[77.56162605441358, 12.987631211674074],
						[77.56169287350788, 12.987546485935525],
						[77.56175085915595, 12.987484097888638],
						[77.56174193828895, 12.987479614255914],
						[77.56179100304878, 12.987345829238524],
						[77.5618177656361, 12.9872745174928],
						[77.56181330520532, 12.987207647467697],
						[77.56177762175275, 12.98716306844503],
						[77.5612826876088, 12.987051554826254],
						[77.56105529180135, 12.986993576851377],
						[77.56098838487115, 12.986940074148515],
						[77.56080550572713, 12.986757232715728],
						[77.56076990941753, 12.986676959813225],
						[77.56076544894553, 12.986614574168078],
						[77.56078329083253, 12.986511962757685],
						[77.56097500348038, 12.9860437809517],
						[77.5610106871856, 12.985945739437923],
						[77.56101960811016, 12.985825279714222],
						[77.5610106871856, 12.985704906880011],
						[77.56098838487115, 12.985526546935324],
						[77.5609660825523, 12.985379401400825],
						[77.56097500348037, 12.985250060901018],
						[77.56097054301642, 12.985098431374457],
						[77.56098392440774, 12.984906706125576],
						[77.56101960811016, 12.984804181653335],
						[77.56105529180135, 12.984750633839559],
						[77.56105975226195, 12.984732827934184],
						[77.56109980893956, 12.984679318869174],
						[77.56130052941438, 12.98420666267627],
						[77.56141641362255, 12.983783026028222],
						[77.56152346428769, 12.982891191433927],
						[77.56158591046233, 12.982605869984031],
						[77.56161267309807, 12.982485412576215],
						[77.56161267309807, 12.982400739742571],
						[77.56161267309807, 12.982262521388137],
						[77.56161267309807, 12.98222682434726],
						[77.56160821265922, 12.98212426028078],
						[77.56159037090207, 12.981963710894322],
						[77.56151454340278, 12.981910211973437],
						[77.56146547852329, 12.98188344145992],
						[77.5612648458004, 12.98194144039709],
						[77.56121132035832, 12.981936959035014],
						[77.56114441352022, 12.981892340788908],
						[77.56111319031561, 12.981843323592493],
						[77.56102406857217, 12.981272528212761],
						[77.56096162208802, 12.981018429537182],
						[77.56092593836733, 12.980911341391831],
						[77.56085457089229, 12.98073743178256],
						[77.56076544894553, 12.98057249473298],
						[77.56064947661156, 12.980407468730018],
						[77.56048898679836, 12.98026926459008],
						[77.56025258095397, 12.98020680654806],
						[77.56003410408233, 12.98017565070353],
						[77.55979332424013, 12.980122120833197],
						[77.55956146492585, 12.9800463450035],
						[77.55943210978741, 12.979975001152955],
						[77.5592626971888, 12.979872407550829],
						[77.55946779397827, 12.979591482582958],
						[77.55974871912785, 12.979069779425032],
						[77.56009655107741, 12.97819581614513],
						[77.56030164635844, 12.977674076991667],
						[77.56037301418158, 12.977428774599879],
						[77.56041761904824, 12.977330739113377],
						[77.56026596243002, 12.976661848188469],
						[77.56118455762784, 12.976594962373552],
						[77.56118455762784, 12.976577114733246],
						[77.56121132035832, 12.976340783033885],
						[77.56122470172122, 12.97605095299492],
						[77.56127822715696, 12.975970678820243],
						[77.5612826876088, 12.975894848027378],
						[77.56129160851194, 12.975685333045591],
						[77.56168395263632, 12.975662968776465],
						[77.56264714033244, 12.97561840242633],
						[77.56264714033244, 12.975627369323451],
						[77.56270066509644, 12.975631762862639],
						[77.56293251798986, 12.975640709409024],
						[77.56336954759739, 12.975671972112961],
						[77.56397152098124, 12.975783445825808],
						[77.5643193399722, 12.975845839261018],
						[77.56437286394643, 12.975881530605854],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000065",
			properties: {
				AREA_SQ_KM: 0.8257875445615661,
				ASS_CONST1: "Gandhi Nagar",
				ASS_CONST_: "164",
				LAT: 12.9827837272182,
				LON: 77.563816623965,
				LOST_AREA_SQ_KM: 0.023546752675553867,
				OBJECTID: 102,
				POP_F: 17266.0,
				POP_M: 18609.0,
				POP_SC: 6352.0,
				POP_ST: 287.0,
				POP_TOTAL: 35875.0,
				RESERVATIO: "Scheduled Caste (Woman)",
				WARD_NAME: "Okalipuram",
				WARD_NO: 96.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53838514080618, 12.99157305056741],
						[77.53820240019849, 12.9910067584292],
						[77.5380685593564, 12.990556394324377],
						[77.53793480583454, 12.990097155361877],
						[77.53783228177272, 12.989463964674316],
						[77.53772074738545, 12.988955624078676],
						[77.53762268451217, 12.988518640848655],
						[77.53750231462524, 12.988184133241665],
						[77.53745770075396, 12.987970124870099],
						[77.53753791823152, 12.987956762887737],
						[77.53825138765684, 12.987747197291212],
						[77.53890694279119, 12.987457312287674],
						[77.53917444738963, 12.987301261140777],
						[77.5392056766297, 12.987283458508571],
						[77.5410428506034, 12.986311384365214],
						[77.54183205125335, 12.985883274279493],
						[77.54210855885204, 12.98579852941601],
						[77.54242512904749, 12.985678129201476],
						[77.5424295902404, 12.985678172555597],
						[77.54245189620235, 12.98566924977184],
						[77.54287553369917, 12.985539887426096],
						[77.54292005796958, 12.985522123733471],
						[77.54329908214255, 12.985406122500484],
						[77.54408835459276, 12.985223308825539],
						[77.54518534934087, 12.985009330550515],
						[77.54518534934087, 12.98501372789466],
						[77.54520319367786, 12.985098485293769],
						[77.54529232784866, 12.985811891950267],
						[77.54529678892897, 12.985843147995771],
						[77.54548406667179, 12.987430616081411],
						[77.54553313846272, 12.988353609889842],
						[77.54557328809408, 12.988812890075103],
						[77.5455955934387, 12.989084889268394],
						[77.54563119450907, 12.989312349995863],
						[77.54567580517119, 12.989579824707185],
						[77.54574718219413, 12.989949996831344],
						[77.54580508852047, 12.990346852409504],
						[77.54585862123095, 12.990623297771236],
						[77.54591215391616, 12.99082843420931],
						[77.54597906973723, 12.991064742869103],
						[77.5460146706874, 12.991198478856353],
						[77.54602805384269, 12.99127880023387],
						[77.54602805384269, 12.991372443049379],
						[77.5460102096353, 12.991510664502906],
						[77.54598799184373, 12.991689028988073],
						[77.54592107602792, 12.9922062620322],
						[77.54585862123095, 12.992607563558733],
						[77.54583185487887, 12.992785968140385],
						[77.54575164325658, 12.99337456308999],
						[77.54568918836642, 12.993735771643898],
						[77.54577840962756, 12.994489334677839],
						[77.54532355540715, 12.994587441708198],
						[77.54456551930681, 12.994814878450951],
						[77.54410173797504, 12.99498429345836],
						[77.54349082433042, 12.995113646411616],
						[77.54281307722758, 12.995193888506279],
						[77.54278631015782, 12.995492671014206],
						[77.5428264607601, 12.996375538064552],
						[77.54147086710945, 12.997209378677182],
						[77.54121675163866, 12.997334267723486],
						[77.54114537176906, 12.997338658633115],
						[77.54110075932779, 12.997307525189115],
						[77.5410428506034, 12.997245049989285],
						[77.54090901310632, 12.997057745134427],
						[77.54071726656127, 12.996910665557204],
						[77.54056567109386, 12.996866074225279],
						[77.54036500142205, 12.9968749836406],
						[77.5402357118817, 12.996451372023435],
						[77.54007073191637, 12.996009909298287],
						[77.53987452266382, 12.995483725821245],
						[77.53978975806251, 12.995247408239704],
						[77.53970954217414, 12.99503778354264],
						[77.53945979659757, 12.994471504201401],
						[77.53924136717927, 12.993873977918254],
						[77.53911645020673, 12.993530609473908],
						[77.53902731119071, 12.993285375898711],
						[77.53843421566324, 12.991648890336137],
						[77.53841190891266, 12.99160426661701],
						[77.53838514080618, 12.99157305056741],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000066",
			properties: {
				AREA_SQ_KM: 0.840603570016754,
				ASS_CONST1: "Rajaji Nagar",
				ASS_CONST_: "165",
				LAT: 12.9908084449253,
				LON: 77.5421301173104,
				LOST_AREA_SQ_KM: 0.028608510482458983,
				OBJECTID: 103,
				POP_F: 12798.0,
				POP_M: 14204.0,
				POP_SC: 1236.0,
				POP_ST: 234.0,
				POP_TOTAL: 27002.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Basaveshwara Nagar",
				WARD_NO: 100.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52872677919115, 12.983827584915634],
						[77.5287624730294, 12.98383207219167],
						[77.52876693475841, 12.983827632500805],
						[77.52890069906216, 12.983809796158605],
						[77.52894969052716, 12.983800873863498],
						[77.52922176774348, 12.983796373113554],
						[77.52924845052227, 12.983796371799595],
						[77.52947591002192, 12.98375186098673],
						[77.52952052702427, 12.9837428991892],
						[77.52991735512255, 12.983600195924284],
						[77.53020718923247, 12.98343075755061],
						[77.5304123384958, 12.983332659134359],
						[77.53048809928161, 12.983265801125746],
						[77.53055056252434, 12.983212266543566],
						[77.53065755480583, 12.983060617019312],
						[77.53069324803842, 12.982953625118146],
						[77.53071555630311, 12.98289564634969],
						[77.53072894125982, 12.982859996926898],
						[77.53077347028749, 12.982748518515566],
						[77.53095184867348, 12.98239176633701],
						[77.5309652336024, 12.982360600276458],
						[77.5310500047823, 12.982289264113025],
						[77.53116145822918, 12.982222323297695],
						[77.53116591986411, 12.982222366832119],
						[77.53127737319744, 12.982177756071204],
						[77.53156720384276, 12.98207073683965],
						[77.53179019709651, 12.982044026088973],
						[77.53204879523807, 12.982017227873502],
						[77.53235200868369, 12.98200828019394],
						[77.53247238402251, 12.98200384652649],
						[77.53262845185937, 12.981999415937272],
						[77.53287375087419, 12.981981543162528],
						[77.53304766440586, 12.981950386536765],
						[77.53309665408509, 12.98193249649178],
						[77.53318588523231, 12.98190129356878],
						[77.53319034678785, 12.981901250840602],
						[77.5333241058908, 12.98185220049221],
						[77.53410443709014, 12.981660540085613],
						[77.53611551486753, 12.980973791161272],
						[77.536712996431, 12.980715163202015],
						[77.53673530351496, 12.980737451774962],
						[77.53678437908425, 12.98078655562666],
						[77.53691813395966, 12.981067455963814],
						[77.53691813395966, 12.981076336353413],
						[77.53713219392007, 12.981669437295931],
						[77.5371856432384, 12.981977062421139],
						[77.53720348883174, 12.982124238027987],
						[77.53723471861329, 12.982356124286573],
						[77.53723918000996, 12.982396259261712],
						[77.53725702559488, 12.982525588197948],
						[77.53725256419891, 12.982681600418202],
						[77.53721241162732, 12.98282881604508],
						[77.53706973429203, 12.983252403518923],
						[77.53702065884593, 12.983408499595775],
						[77.53700290070245, 12.983564559222229],
						[77.53703850446513, 12.98371613108941],
						[77.53706081148522, 12.983907927811297],
						[77.53705635008156, 12.984215602116286],
						[77.5371678851207, 12.985165367772158],
						[77.53730163954492, 12.986578880641279],
						[77.53741754825485, 12.98774719329244],
						[77.53743539381176, 12.987876528444136],
						[77.53745770075396, 12.987970124870099],
						[77.53750231462524, 12.988184133241665],
						[77.53762268451217, 12.988518640848655],
						[77.53772074738545, 12.988955624078676],
						[77.53783228177272, 12.989463964674316],
						[77.53793480583454, 12.990097155361877],
						[77.5380685593564, 12.990556394324377],
						[77.53820240019849, 12.9910067584292],
						[77.53838514080618, 12.99157305056741],
						[77.53835846017091, 12.99156865486726],
						[77.53834953746525, 12.99156865405398],
						[77.53706081148522, 12.991778218825058],
						[77.5358123114559, 12.991983342531606],
						[77.53442995259192, 12.992201807915343],
						[77.53404643732082, 12.992255319019364],
						[77.53355155724168, 12.992322232917775],
						[77.53277559633902, 12.992447098226169],
						[77.53248130718937, 12.992478284623314],
						[77.53165197457407, 12.992567450396482],
						[77.53165197457407, 12.99256296672839],
						[77.53165197457407, 12.992558569284853],
						[77.53158951193606, 12.99213494192969],
						[77.53156274222356, 12.991952143770437],
						[77.53154489574506, 12.991800473805878],
						[77.53147805890853, 12.991292138585697],
						[77.53142898104937, 12.99088192550056],
						[77.5314022112991, 12.990654465971033],
						[77.53135322089011, 12.990181834358408],
						[77.53133091275046, 12.989985632429294],
						[77.53128183482784, 12.989878581542213],
						[77.53122829525171, 12.98978054046934],
						[77.53120607457058, 12.989749286509538],
						[77.53117038149887, 12.98965564500832],
						[77.53109453368428, 12.98946392131453],
						[77.53099646509708, 12.989312335862449],
						[77.53098754181376, 12.989298970426043],
						[77.53098308017184, 12.989285562406861],
						[77.5308760881965, 12.989053703834358],
						[77.530791316887, 12.988803953255776],
						[77.53062632321812, 12.988416071872365],
						[77.53058625579051, 12.988326875743436],
						[77.53049256094295, 12.988184126024663],
						[77.53045686764737, 12.988148512638093],
						[77.53031864350501, 12.988001364949529],
						[77.53026519095935, 12.987889879617972],
						[77.53025180594808, 12.987867590028761],
						[77.53024288260637, 12.987849741325734],
						[77.53003327135832, 12.987510831695612],
						[77.52992181680625, 12.987274536545584],
						[77.52988612333165, 12.987203142195076],
						[77.5298548915322, 12.987140758401777],
						[77.52971666665852, 12.98696240072063],
						[77.52955167143125, 12.986761709369729],
						[77.52939559937359, 12.986565502890334],
						[77.52912807177232, 12.986213274442946],
						[77.52910130148068, 12.986155245886755],
						[77.52908354210072, 12.986106189419742],
						[77.52907461866738, 12.986021521361344],
						[77.5290746186674, 12.985910040343711],
						[77.5290879163325, 12.985789633105396],
						[77.5291057631964, 12.985597884798391],
						[77.52911468662728, 12.985508735800952],
						[77.52911468662728, 12.985401652396856],
						[77.52903000149018, 12.984986979944761],
						[77.52885608181678, 12.984407278274906],
						[77.52877585821588, 12.984028261188731],
						[77.52875801130024, 12.983983684810442],
						[77.52872677919115, 12.983827584915634],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000067",
			properties: {
				AREA_SQ_KM: 0.9094612206693904,
				ASS_CONST1: "Rajaji Nagar",
				ASS_CONST_: "165",
				LAT: 12.9867975705395,
				LON: 77.5338707039502,
				LOST_AREA_SQ_KM: 0.02958375086062562,
				OBJECTID: 104,
				POP_F: 12104.0,
				POP_M: 13638.0,
				POP_SC: 2004.0,
				POP_ST: 321.0,
				POP_TOTAL: 25742.0,
				RESERVATIO: "General",
				WARD_NAME: "Kamakshipalya",
				WARD_NO: 101.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54518534934087, 12.98501372789466],
						[77.54812385399039, 12.984541043897163],
						[77.54880611781199, 12.984398391202348],
						[77.54881057875426, 12.984393950816143],
						[77.54882396158, 12.984393907989437],
						[77.54904692097416, 12.984300270245614],
						[77.54907368656835, 12.984741733049017],
						[77.54910482561769, 12.984973627190376],
						[77.54950176027917, 12.984929010553744],
						[77.55048718087579, 12.98476850124602],
						[77.55158857463438, 12.984532197125757],
						[77.55259636854812, 12.984483125502807],
						[77.55364421282246, 12.984376058907134],
						[77.55410795517098, 12.98437165210942],
						[77.55428183619667, 12.986993543718153],
						[77.55427746293469, 12.987363674803227],
						[77.55427746293469, 12.987506379826428],
						[77.55437997215087, 12.989999000224064],
						[77.55338557636641, 12.989949981284354],
						[77.55139684612075, 12.990061468266433],
						[77.55071905873781, 12.990097159973669],
						[77.55092417097458, 12.99119406899145],
						[77.55107575261542, 12.992478277712896],
						[77.55107129176217, 12.993494970149927],
						[77.55089740581674, 12.993490526717618],
						[77.5505273287553, 12.99348607938064],
						[77.5492431143825, 12.993468198487697],
						[77.54837358051874, 12.993445882442202],
						[77.54723646753958, 12.993405789233995],
						[77.54676832356382, 12.99339236656495],
						[77.54628224602202, 12.993387905422315],
						[77.54611727483761, 12.993383458843992],
						[77.54580954958064, 12.993374519375472],
						[77.54575164325658, 12.99337456308999],
						[77.54583185487887, 12.992785968140385],
						[77.54585862123095, 12.992607563558733],
						[77.54592107602792, 12.9922062620322],
						[77.54598799184373, 12.991689028988073],
						[77.5460102096353, 12.991510664502906],
						[77.54602805384269, 12.991372443049379],
						[77.54602805384269, 12.99127880023387],
						[77.5460146706874, 12.991198478856353],
						[77.54597906973723, 12.991064742869103],
						[77.54591215391616, 12.99082843420931],
						[77.54585862123095, 12.990623297771236],
						[77.54580508852047, 12.990346852409504],
						[77.54574718219413, 12.989949996831344],
						[77.54567580517119, 12.989579824707185],
						[77.54563119450907, 12.989312349995863],
						[77.5455955934387, 12.989084889268394],
						[77.54557328809408, 12.988812890075103],
						[77.54553313846272, 12.988353609889842],
						[77.54548406667179, 12.987430616081411],
						[77.54529678892897, 12.985843147995771],
						[77.54529232784866, 12.985811891950267],
						[77.54520319367786, 12.985098485293769],
						[77.54518534934087, 12.98501372789466],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000068",
			properties: {
				AREA_SQ_KM: 0.7793117149448799,
				ASS_CONST1: "Rajaji Nagar",
				ASS_CONST_: "165",
				LAT: 12.9885035896526,
				LON: 77.5495200478752,
				LOST_AREA_SQ_KM: 0.017840498319558536,
				OBJECTID: 105,
				POP_F: 17039.0,
				POP_M: 18940.0,
				POP_SC: 858.0,
				POP_ST: 271.0,
				POP_TOTAL: 35979.0,
				RESERVATIO: "General",
				WARD_NAME: "Shivanagara",
				WARD_NO: 107.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5592626971888, 12.979872407550829],
						[77.55943210978741, 12.979975001152955],
						[77.55956146492585, 12.9800463450035],
						[77.55979332424013, 12.980122120833197],
						[77.56003410408233, 12.98017565070353],
						[77.56025258095397, 12.98020680654806],
						[77.56048898679836, 12.98026926459008],
						[77.56064947661156, 12.980407468730018],
						[77.56076544894553, 12.98057249473298],
						[77.56085457089229, 12.98073743178256],
						[77.56092593836733, 12.980911341391831],
						[77.56096162208802, 12.981018429537182],
						[77.56102406857217, 12.981272528212761],
						[77.56111319031561, 12.981843323592493],
						[77.56114441352022, 12.981892340788908],
						[77.56121132035832, 12.981936959035014],
						[77.5612648458004, 12.98194144039709],
						[77.56146547852329, 12.98188344145992],
						[77.56151454340278, 12.981910211973437],
						[77.56159037090207, 12.981963710894322],
						[77.56160821265922, 12.98212426028078],
						[77.56161267309807, 12.98222682434726],
						[77.56161267309807, 12.982262521388137],
						[77.56161267309807, 12.982400739742571],
						[77.56161267309807, 12.982485412576215],
						[77.56158591046233, 12.982605869984031],
						[77.56156360826105, 12.982605827822006],
						[77.56146101807865, 12.982614756448603],
						[77.56135405481432, 12.98262813001533],
						[77.56125146444225, 12.982650423397107],
						[77.56114887397734, 12.982681597888792],
						[77.56105083134057, 12.982717303175292],
						[77.5609527011589, 12.982761885352526],
						[77.56086349182912, 12.982810950827812],
						[77.56077436988937, 12.982868901655536],
						[77.56068516041925, 12.98292684822041],
						[77.56060487183615, 12.982998245518399],
						[77.56053350415897, 12.983069556245209],
						[77.56046222389733, 12.983145354889047],
						[77.5603953166171, 12.983230073445446],
						[77.56033733027563, 12.983314791697456],
						[77.5602882648866, 12.983408477083612],
						[77.56023919947634, 12.98350207627981],
						[77.56020360298895, 12.983595722402322],
						[77.56019468200017, 12.983618055067424],
						[77.56019022150554, 12.983676041788616],
						[77.56018130051571, 12.98383211017173],
						[77.56017684002055, 12.983921224424492],
						[77.56017684002055, 12.984063927853107],
						[77.56015899803809, 12.984500920995686],
						[77.56014115605284, 12.98493791570827],
						[77.56011885356732, 12.985267948025102],
						[77.56005640658454, 12.986195406034769],
						[77.56004302508374, 12.98678403789406],
						[77.55992267896647, 12.987287916954228],
						[77.55981562678969, 12.987662493961423],
						[77.55969073245566, 12.98796567502453],
						[77.55911558702432, 12.988866397830021],
						[77.55783575552512, 12.991006768834147],
						[77.55733188295936, 12.990676817943735],
						[77.55702864872887, 12.990471681258194],
						[77.55610564667137, 12.989963330424143],
						[77.55437997215087, 12.989999000224064],
						[77.55427746293469, 12.987506379826428],
						[77.55427746293469, 12.987363674803227],
						[77.55428183619667, 12.986993543718153],
						[77.55410795517098, 12.98437165210942],
						[77.55364421282246, 12.984376058907134],
						[77.55259636854812, 12.984483125502807],
						[77.55158857463438, 12.984532197125757],
						[77.55048718087579, 12.98476850124602],
						[77.54950176027917, 12.984929010553744],
						[77.54910482561769, 12.984973627190376],
						[77.54907368656835, 12.984741733049017],
						[77.54904692097416, 12.984295872876192],
						[77.54900231163651, 12.981410802266616],
						[77.54899785070177, 12.980920249962434],
						[77.54899785070177, 12.980889038164761],
						[77.54899785070177, 12.980875673999774],
						[77.54901123350544, 12.980835538531526],
						[77.54906922563642, 12.980835582186083],
						[77.55050502437956, 12.980790985031062],
						[77.55272564401608, 12.980679539208246],
						[77.55364421282246, 12.980634936546522],
						[77.55367097733244, 12.980639333510382],
						[77.5536977418361, 12.98063933317632],
						[77.55384039828346, 12.980630446297974],
						[77.55388054497664, 12.980608157502393],
						[77.55381363381345, 12.980211324668316],
						[77.55381809455888, 12.980211281493524],
						[77.55379587829553, 12.979979394371234],
						[77.55467875275079, 12.980046270143328],
						[77.5550443555759, 12.98010428151032],
						[77.55524954745866, 12.980117641572273],
						[77.5551960191772, 12.979908038441934],
						[77.55495968976335, 12.9790920954277],
						[77.5547678794811, 12.978458854054002],
						[77.55476350623789, 12.978449934599118],
						[77.55472782056682, 12.978351816967958],
						[77.55469659559543, 12.978258225875162],
						[77.55467429203918, 12.978160064995057],
						[77.55465644919101, 12.978057549995057],
						[77.55465198847855, 12.977977322570183],
						[77.55465198847855, 12.977954991648462],
						[77.55465644919101, 12.977856916591138],
						[77.55465198847855, 12.977758755622581],
						[77.55463860634006, 12.97766516460616],
						[77.5546207634863, 12.97756704726502],
						[77.55459399920036, 12.977473413658547],
						[77.554558313476, 12.977379780374521],
						[77.55453154917534, 12.977321755496227],
						[77.5548169472586, 12.977196897974606],
						[77.5550934232338, 12.977081007062004],
						[77.55516925502701, 12.97701414176924],
						[77.55529852756334, 12.977277231912051],
						[77.55553039559335, 12.977491217573016],
						[77.55577564515534, 12.977598247552269],
						[77.55608334339216, 12.977807877295783],
						[77.556413344013, 12.978066436654988],
						[77.55665859166587, 12.978222526333482],
						[77.55687261441606, 12.978329514584033],
						[77.55697520875017, 12.978325070694462],
						[77.55737648902235, 12.978418769979164],
						[77.55763065578371, 12.978556925469347],
						[77.55788482197543, 12.978695167261234],
						[77.55807653952428, 12.978797715905447],
						[77.55825942307014, 12.978828964076765],
						[77.5584779033614, 12.978828994339644],
						[77.55864731713258, 12.978820016246264],
						[77.55881235756353, 12.978828933381298],
						[77.5589416259474, 12.978900358316514],
						[77.55905313948895, 12.979016278640335],
						[77.55913334170947, 12.979194577686348],
						[77.55916902599438, 12.979435394098516],
						[77.55915118385333, 12.979613788357746],
						[77.55919132866673, 12.979769805851408],
						[77.5592626971888, 12.979872407550829],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000069",
			properties: {
				AREA_SQ_KM: 1.0634104723789843,
				ASS_CONST1: "Rajaji Nagar",
				ASS_CONST_: "165",
				LAT: 12.9837673095898,
				LON: 77.5558908790906,
				LOST_AREA_SQ_KM: 0.038070020109199076,
				OBJECTID: 106,
				POP_F: 16265.0,
				POP_M: 17348.0,
				POP_SC: 905.0,
				POP_ST: 285.0,
				POP_TOTAL: 33613.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Sriramamandir",
				WARD_NO: 108.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56008763008022, 12.993923040236727],
						[77.56002518308024, 12.993731258598565],
						[77.55999842006972, 12.99326750005908],
						[77.56003410408233, 12.992870659225261],
						[77.56013669555608, 12.992496110175782],
						[77.56036409320616, 12.992112674980419],
						[77.56046668438127, 12.992001133546903],
						[77.5605558065629, 12.991934299048607],
						[77.5606182532706, 12.991898598588456],
						[77.56065393708815, 12.991840650652588],
						[77.56069408136943, 12.991764809651828],
						[77.56073422563651, 12.991675602806405],
						[77.56075652800097, 12.991595364264013],
						[77.56079658478572, 12.991532971205775],
						[77.56085903136079, 12.991443807301234],
						[77.56089917556946, 12.991367966421608],
						[77.5609393197639, 12.991305577628292],
						[77.56098392440772, 12.991229693611016],
						[77.561028529034, 12.991176229699343],
						[77.56105529180135, 12.991140529646707],
						[77.56109534848053, 12.9911093523081],
						[77.56115779489103, 12.991069167711508],
						[77.56122024126711, 12.990980003867262],
						[77.56128714806046, 12.990890883082585],
						[77.56134067346669, 12.990806117191495],
						[77.56139411138793, 12.990734799112111],
						[77.56141195317598, 12.99065451787803],
						[77.56145209718882, 12.990605494956458],
						[77.56149670163089, 12.990502879537422],
						[77.56152792472987, 12.990413759521541],
						[77.56155914782026, 12.99034240306221],
						[77.56160821265922, 12.990262078300605],
						[77.56146547852329, 12.990266565013572],
						[77.56131837121717, 12.990271012820171],
						[77.56120239944887, 12.990262133142595],
						[77.56105083134057, 12.990239806293012],
						[77.56106867318265, 12.99012831008798],
						[77.56107313364272, 12.990074804086367],
						[77.56108196710237, 12.989999003277491],
						[77.56107750664263, 12.989981196808806],
						[77.56105975226195, 12.98995446997858],
						[77.561028529034, 12.989874147112557],
						[77.56102406857217, 12.989807275723864],
						[77.56106421272239, 12.989637876043929],
						[77.56108642756193, 12.989539698581401],
						[77.56111765077392, 12.989339084197848],
						[77.56114887397734, 12.988973426671366],
						[77.56116225534758, 12.988839641336954],
						[77.56115779489102, 12.988763888897266],
						[77.56116671580399, 12.988732673668522],
						[77.56118901808335, 12.988692533411792],
						[77.56126038534785, 12.98864346731626],
						[77.56127822715696, 12.988625617440446],
						[77.56129606896324, 12.988612251487845],
						[77.56131837121717, 12.988594444621537],
						[77.56136297571184, 12.988545379114989],
						[77.5614030322823, 12.988474019228702],
						[77.56142979496121, 12.988389255272454],
						[77.56144763674365, 12.988282244468481],
						[77.56147439941205, 12.988282243805381],
						[77.56226363290966, 12.988273371589221],
						[77.56394029855542, 12.988175253067963],
						[77.56395813994266, 12.988375909072891],
						[77.56396260028903, 12.988384833700986],
						[77.56504164912023, 12.988317947029541],
						[77.56506841093992, 12.988536453166454],
						[77.56569713696975, 12.98851861174549],
						[77.56680301814725, 12.988465085179286],
						[77.5677929235057, 12.988416042901324],
						[77.56785536622527, 12.988433889354551],
						[77.56788212738026, 12.988451737717808],
						[77.56790888852892, 12.98847846787085],
						[77.56791334871976, 12.988505242344386],
						[77.56791780891041, 12.988545382628978],
						[77.56792672929119, 12.988576597778234],
						[77.56792672929119, 12.988603329399263],
						[77.56792672929119, 12.98862557701106],
						[77.56792226910089, 12.988719267333346],
						[77.56791780891041, 12.98885305524041],
						[77.5678776671882, 12.989753788941714],
						[77.56786874680355, 12.990444939851878],
						[77.5678419856454, 12.991220776292263],
						[77.56781968467544, 12.991782629284877],
						[77.56778400311438, 12.992612025910772],
						[77.56778400311438, 12.992629876336935],
						[77.56775724193622, 12.993405812981095],
						[77.56768150601863, 12.993410261149647],
						[77.56662023584245, 12.993450323880948],
						[77.5655411145887, 12.993481585830821],
						[77.56489454645609, 12.99352175302778],
						[77.56472505456465, 12.993539521282665],
						[77.56401612443177, 12.993637640398516],
						[77.56347204859414, 12.993708970077918],
						[77.56329372128842, 12.99370897734169],
						[77.562722967074, 12.99374469517067],
						[77.56212544747693, 12.993838274249441],
						[77.56179992391192, 12.993856175174557],
						[77.56032840929738, 12.993914067346138],
						[77.56008763008022, 12.993923040236727],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000006a",
			properties: {
				AREA_SQ_KM: 0.4485629948984479,
				ASS_CONST1: "Rajaji Nagar",
				ASS_CONST_: "165",
				LAT: 12.9910970569635,
				LON: 77.5641946734242,
				LOST_AREA_SQ_KM: 0.004873456225556019,
				OBJECTID: 107,
				POP_F: 16828.0,
				POP_M: 17767.0,
				POP_SC: 10651.0,
				POP_ST: 170.0,
				POP_TOTAL: 34595.0,
				RESERVATIO: "Scheduled Caste (Woman)",
				WARD_NAME: "Dayananda Nagar",
				WARD_NO: 97.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5530868798271, 12.998199329181881],
						[77.5532339978109, 12.997806923343065],
						[77.55354616369765, 12.997271851352236],
						[77.5543264434591, 12.996406726022702],
						[77.55478572231175, 12.995621968689951],
						[77.55515141225669, 12.994930820522036],
						[77.5556240697932, 12.994159390302519],
						[77.55580240916245, 12.993824896433184],
						[77.55783575552512, 12.991006768834147],
						[77.55911558702432, 12.988866397830021],
						[77.55969073245566, 12.98796567502453],
						[77.55981562678969, 12.987662493961423],
						[77.55992267896647, 12.987287916954228],
						[77.56004302508374, 12.98678403789406],
						[77.56005640658454, 12.986195406034769],
						[77.56011885356732, 12.985267948025102],
						[77.56014115605284, 12.98493791570827],
						[77.56015899803809, 12.984500920995686],
						[77.56017684002055, 12.984063927853107],
						[77.56017684002055, 12.983921224424492],
						[77.56018130051571, 12.98383211017173],
						[77.56019022150554, 12.983676041788616],
						[77.56019468200017, 12.983618055067424],
						[77.56020360298895, 12.983595722402322],
						[77.56023919947634, 12.98350207627981],
						[77.5602882648866, 12.983408477083612],
						[77.56033733027563, 12.983314791697456],
						[77.5603953166171, 12.983230073445446],
						[77.56046222389733, 12.983145354889047],
						[77.56053350415897, 12.983069556245209],
						[77.56060487183615, 12.982998245518399],
						[77.56068516041925, 12.98292684822041],
						[77.56077436988937, 12.982868901655536],
						[77.56086349182912, 12.982810950827812],
						[77.5609527011589, 12.982761885352526],
						[77.56105083134057, 12.982717303175292],
						[77.56114887397734, 12.982681597888792],
						[77.56125146444225, 12.982650423397107],
						[77.56135405481432, 12.98262813001533],
						[77.56146101807865, 12.982614756448603],
						[77.56156360826105, 12.982605827822006],
						[77.56158591046233, 12.982605869984031],
						[77.56152346428769, 12.982891191433927],
						[77.56141641362255, 12.983783026028222],
						[77.56130052941438, 12.98420666267627],
						[77.56109980893956, 12.984679318869174],
						[77.56105975226195, 12.984732827934184],
						[77.56105529180135, 12.984750633839559],
						[77.56101960811016, 12.984804181653335],
						[77.56098392440774, 12.984906706125576],
						[77.56097054301642, 12.985098431374457],
						[77.56097500348037, 12.985250060901018],
						[77.5609660825523, 12.985379401400825],
						[77.56098838487115, 12.985526546935324],
						[77.5610106871856, 12.985704906880011],
						[77.56101960811016, 12.985825279714222],
						[77.5610106871856, 12.985945739437923],
						[77.56097500348038, 12.9860437809517],
						[77.56078329083253, 12.986511962757685],
						[77.56076544894553, 12.986614574168078],
						[77.56076990941753, 12.986676959813225],
						[77.56080550572713, 12.986757232715728],
						[77.56098838487115, 12.986940074148515],
						[77.56105529180135, 12.986993576851377],
						[77.5612826876088, 12.987051554826254],
						[77.56177762175275, 12.98716306844503],
						[77.56181330520532, 12.987207647467697],
						[77.5618177656361, 12.9872745174928],
						[77.56179100304878, 12.987345829238524],
						[77.56174193828895, 12.987479614255914],
						[77.56175085915595, 12.987484097888638],
						[77.56169287350788, 12.987546485935525],
						[77.56162605441358, 12.987631211674074],
						[77.56158145002243, 12.987720459599922],
						[77.56154130605539, 12.987800696533556],
						[77.56148332030011, 12.987992427881373],
						[77.56143871585279, 12.988219814833966],
						[77.56143871585279, 12.988282244688781],
						[77.56144763674365, 12.988282244468481],
						[77.56142979496121, 12.988389255272454],
						[77.5614030322823, 12.988474019228702],
						[77.56136297571184, 12.988545379114989],
						[77.56131837121717, 12.988594444621537],
						[77.56129606896324, 12.988612251487845],
						[77.56127822715696, 12.988625617440446],
						[77.56126038534785, 12.98864346731626],
						[77.56118901808335, 12.988692533411792],
						[77.56116671580399, 12.988732673668522],
						[77.56115779489102, 12.988763888897266],
						[77.56116225534758, 12.988839641336954],
						[77.56114887397734, 12.988973426671366],
						[77.56111765077392, 12.989339084197848],
						[77.56108642756193, 12.989539698581401],
						[77.56106421272239, 12.989637876043929],
						[77.56102406857217, 12.989807275723864],
						[77.561028529034, 12.989874147112557],
						[77.56105975226195, 12.98995446997858],
						[77.56107750664263, 12.989981196808806],
						[77.56108196710237, 12.989999003277491],
						[77.56107313364272, 12.990074804086367],
						[77.56106867318265, 12.99012831008798],
						[77.56105083134057, 12.990239806293012],
						[77.56120239944887, 12.990262133142595],
						[77.56131837121717, 12.990271012820171],
						[77.56146547852329, 12.990266565013572],
						[77.56160821265922, 12.990262078300605],
						[77.56155914782026, 12.99034240306221],
						[77.56152792472987, 12.990413759521541],
						[77.56149670163089, 12.990502879537422],
						[77.56145209718882, 12.990605494956458],
						[77.56141195317598, 12.99065451787803],
						[77.56139411138793, 12.990734799112111],
						[77.56134067346669, 12.990806117191495],
						[77.56128714806046, 12.990890883082585],
						[77.56122024126711, 12.990980003867262],
						[77.56115779489103, 12.991069167711508],
						[77.56109534848053, 12.9911093523081],
						[77.56105529180135, 12.991140529646707],
						[77.561028529034, 12.991176229699343],
						[77.56098392440772, 12.991229693611016],
						[77.5609393197639, 12.991305577628292],
						[77.56089917556946, 12.991367966421608],
						[77.56085903136079, 12.991443807301234],
						[77.56079658478572, 12.991532971205775],
						[77.56075652800097, 12.991595364264013],
						[77.56073422563651, 12.991675602806405],
						[77.56069408136943, 12.991764809651828],
						[77.56065393708815, 12.991840650652588],
						[77.5606182532706, 12.991898598588456],
						[77.5605558065629, 12.991934299048607],
						[77.56046668438127, 12.992001133546903],
						[77.56036409320616, 12.992112674980419],
						[77.56013669555608, 12.992496110175782],
						[77.56003410408233, 12.992870659225261],
						[77.55999842006972, 12.99326750005908],
						[77.56002518308024, 12.993731258598565],
						[77.56008763008022, 12.993923040236727],
						[77.56013669555608, 12.994065712558323],
						[77.56037747466904, 12.994449186937013],
						[77.56038193515631, 12.994507178861703],
						[77.56037301418158, 12.994556245667138],
						[77.56035517223002, 12.994574095993485],
						[77.560323948808, 12.99460078524958],
						[77.56026150193819, 12.994623119713738],
						[77.56008763008022, 12.994689997865034],
						[77.55972641656514, 12.994796973906158],
						[77.55934744725042, 12.994926287797561],
						[77.5591734865292, 12.994984278776046],
						[77.55914226278175, 12.9950021720124],
						[77.5591378022457, 12.995028861226531],
						[77.55914226278175, 12.995091337390255],
						[77.55916456545938, 12.995216160564162],
						[77.5591734865292, 12.995274109600714],
						[77.55922255240067, 12.99540341733671],
						[77.55932960513744, 12.995782502258445],
						[77.55939205252055, 12.995960834878671],
						[77.55942318873792, 12.99604555601699],
						[77.55951239920107, 12.996139207186564],
						[77.55961945166416, 12.996250709115863],
						[77.55969073245566, 12.996344356111],
						[77.5597397981033, 12.996442448331162],
						[77.55978440321908, 12.99654049749766],
						[77.55982900831731, 12.996709949185625],
						[77.55984685035169, 12.996781351804673],
						[77.55986915289071, 12.996812525914274],
						[77.55992713947165, 12.996879401614482],
						[77.56007870908233, 12.997035484119737],
						[77.56014561654942, 12.997151427887887],
						[77.56017237952518, 12.997222744680808],
						[77.56017684002055, 12.997254005019288],
						[77.56017684002055, 12.997289620213696],
						[77.56017684002055, 12.9973119551716],
						[77.56011439306968, 12.997365420551137],
						[77.55957484648349, 12.997588384865226],
						[77.5581702116212, 12.99812800124938],
						[77.557648498164, 12.998301892066706],
						[77.5572204551881, 12.998435641182311],
						[77.55693506315224, 12.998498072302082],
						[77.5563910407943, 12.998627418236092],
						[77.55606104010857, 12.998658629464938],
						[77.55563745181543, 12.99868091589457],
						[77.55539666263805, 12.998658576994933],
						[77.55486155431063, 12.998573883539729],
						[77.55416148396583, 12.998431194044365],
						[77.55389392720454, 12.998377635865852],
						[77.55374234932818, 12.998342019045124],
						[77.5530868798271, 12.998199329181881],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000006b",
			properties: {
				AREA_SQ_KM: 0.5702753929484681,
				ASS_CONST1: "Rajaji Nagar",
				ASS_CONST_: "165",
				LAT: 12.9930942205099,
				LON: 77.5582212219401,
				LOST_AREA_SQ_KM: 0.014629400523675659,
				OBJECTID: 108,
				POP_F: 16824.0,
				POP_M: 18641.0,
				POP_SC: 1994.0,
				POP_ST: 140.0,
				POP_TOTAL: 35465.0,
				RESERVATIO: "General",
				WARD_NAME: "Prakash Nagar",
				WARD_NO: 98.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54110968181746, 12.999345252845542],
						[77.54110968181746, 12.9993408550223],
						[77.54099377687278, 12.99889494358762],
						[77.54060582248086, 12.997641934085392],
						[77.54042299796778, 12.997053355896252],
						[77.54036500142205, 12.9968749836406],
						[77.54056567109386, 12.996866074225279],
						[77.54071726656127, 12.996910665557204],
						[77.54090901310632, 12.997057745134427],
						[77.5410428506034, 12.997245049989285],
						[77.54110075932779, 12.997307525189115],
						[77.54114537176906, 12.997338658633115],
						[77.54121675163866, 12.997334267723486],
						[77.54147086710945, 12.997209378677182],
						[77.5428264607601, 12.996375538064552],
						[77.54278631015782, 12.995492671014206],
						[77.54281307722758, 12.995193888506279],
						[77.54349082433042, 12.995113646411616],
						[77.54410173797504, 12.99498429345836],
						[77.54456551930681, 12.994814878450951],
						[77.54532355540715, 12.994587441708198],
						[77.54577840962756, 12.994489334677839],
						[77.54568918836642, 12.993735771643898],
						[77.54575164325658, 12.99337456308999],
						[77.54580954958064, 12.993374519375472],
						[77.54611727483761, 12.993383458843992],
						[77.54628224602202, 12.993387905422315],
						[77.54676832356382, 12.99339236656495],
						[77.54723646753958, 12.993405789233995],
						[77.54837358051874, 12.993445882442202],
						[77.5492431143825, 12.993468198487697],
						[77.5505273287553, 12.99348607938064],
						[77.55089740581674, 12.993490526717618],
						[77.55107129176217, 12.993494970149927],
						[77.55107575261542, 12.992478277712896],
						[77.55092417097458, 12.99119406899145],
						[77.55071905873781, 12.990097159973669],
						[77.55139684612075, 12.990061468266433],
						[77.55338557636641, 12.989949981284354],
						[77.55437997215087, 12.989999000224064],
						[77.55610564667137, 12.989963330424143],
						[77.55702864872887, 12.990471681258194],
						[77.55733188295936, 12.990676817943735],
						[77.55783575552512, 12.991006768834147],
						[77.55580240916245, 12.993824896433184],
						[77.5556240697932, 12.994159390302519],
						[77.55515141225669, 12.994930820522036],
						[77.55478572231175, 12.995621968689951],
						[77.5543264434591, 12.996406726022702],
						[77.55354616369765, 12.997271851352236],
						[77.5532339978109, 12.997806923343065],
						[77.5530868798271, 12.998199329181881],
						[77.55301104664206, 12.998185916435222],
						[77.55300212508753, 12.998181431866897],
						[77.55255622140055, 12.998087856303817],
						[77.55246254466768, 12.998069958444061],
						[77.55191412750908, 12.997958487957401],
						[77.55178030282408, 12.997931749001673],
						[77.55126310829336, 12.99782472001046],
						[77.55048271999942, 12.997650802324722],
						[77.55047825912285, 12.997650845199695],
						[77.55044257210413, 12.997641961259935],
						[77.55038466814301, 12.997637435101334],
						[77.5501883892393, 12.997619660314673],
						[77.54951068210886, 12.997566088082463],
						[77.54835127571982, 12.99750817654667],
						[77.5468886836343, 12.997427912648876],
						[77.54686637854365, 12.99742786797182],
						[77.54683961242911, 12.997427866095512],
						[77.54622425246754, 12.99742343691353],
						[77.54568472730152, 12.997450184483743],
						[77.54541715055906, 12.997454652380462],
						[77.5453681661901, 12.997454609725947],
						[77.54527894460666, 12.99745460283861],
						[77.54513627739966, 12.997485811787886],
						[77.54511397196482, 12.997494735007095],
						[77.54505597781376, 12.997508139461745],
						[77.54501582799953, 12.9975170612654],
						[77.54499807110548, 12.997521548131681],
						[77.54487762155878, 12.997548313477298],
						[77.54473495384767, 12.997606253876569],
						[77.54467249839841, 12.997628582809448],
						[77.54351313008401, 12.998074413947409],
						[77.54314302895516, 12.998221630017548],
						[77.54311626196314, 12.99823499350857],
						[77.54279077133656, 12.998364276672726],
						[77.54267486810676, 12.99843118593732],
						[77.54261241152486, 12.99846688003301],
						[77.54259902796713, 12.998475803760716],
						[77.54235821113275, 12.99860953067561],
						[77.54227799705751, 12.998658593113285],
						[77.54212640367236, 12.998747746415708],
						[77.5418097451697, 12.998935058328174],
						[77.54110968181746, 12.999345252845542],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000006c",
			properties: {
				AREA_SQ_KM: 0.8616013490133967,
				ASS_CONST1: "Rajaji Nagar",
				ASS_CONST_: "165",
				LAT: 12.9945988368238,
				LON: 77.5501925896872,
				LOST_AREA_SQ_KM: 0.021564910581401756,
				OBJECTID: 109,
				POP_F: 16010.0,
				POP_M: 17221.0,
				POP_SC: 2175.0,
				POP_ST: 241.0,
				POP_TOTAL: 33231.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Rajaji Nagar",
				WARD_NO: 99.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.51826572773005, 12.975301823801493],
						[77.51833257232336, 12.97527057799372],
						[77.51894799570225, 12.97501641378607],
						[77.5195990249759, 12.974806903534734],
						[77.52016080924082, 12.974637380582593],
						[77.52052206104466, 12.974530388533951],
						[77.52051313694028, 12.974334177618783],
						[77.52078960886911, 12.97430299483919],
						[77.52145847568083, 12.974200464230377],
						[77.52217633294464, 12.973999784294913],
						[77.5222254147934, 12.9739551769614],
						[77.5223591867836, 12.974267348324448],
						[77.52321089684908, 12.973999781383986],
						[77.52327773854047, 12.97398189599894],
						[77.52542706295444, 12.973339794217216],
						[77.52553406005265, 12.973767913338586],
						[77.52560090038023, 12.973758992842848],
						[77.525908592848, 12.973705434468348],
						[77.52615836818056, 12.973696573909391],
						[77.52628767372411, 12.973683176056776],
						[77.52631435719078, 12.973656449435524],
						[77.52632328084054, 12.973625156332767],
						[77.52633666631387, 12.973576147671933],
						[77.52635897543259, 12.973544898907454],
						[77.52641251729966, 12.97354490376984],
						[77.52652843710531, 12.973549392900853],
						[77.52707688928712, 12.973495845713261],
						[77.52742919558747, 12.973576171821573],
						[77.52799995222597, 12.973580600020409],
						[77.52857516773865, 12.973513688602347],
						[77.52856178252858, 12.973143633220731],
						[77.52853947384163, 12.97281366556533],
						[77.52863317029743, 12.972835957987463],
						[77.52878031994435, 12.972876095632692],
						[77.52883823491374, 12.974378775768894],
						[77.5288917756145, 12.975328563535856],
						[77.52900769289504, 12.976305086135222],
						[77.52963644366432, 12.976287320421834],
						[77.53073340291171, 12.976220393695616],
						[77.53091169387729, 12.976215916237312],
						[77.53117038149887, 12.976211449066115],
						[77.53129075808808, 12.976189168198234],
						[77.53140667292459, 12.976175809890208],
						[77.53141113454988, 12.976175767122552],
						[77.53191057302985, 12.976100011023012],
						[77.53195518907745, 12.97651021374069],
						[77.53143344267382, 12.976603856998224],
						[77.53148698215337, 12.976991818789335],
						[77.53174558098421, 12.978378575041653],
						[77.5318614953573, 12.979114333362592],
						[77.53186595696477, 12.979127740104648],
						[77.53203987203729, 12.979925885187619],
						[77.53230293120158, 12.979836709079992],
						[77.53273106809505, 12.981441990588504],
						[77.53304766440586, 12.981366181615039],
						[77.53319034678785, 12.981901250840602],
						[77.53318588523231, 12.98190129356878],
						[77.53309665408509, 12.98193249649178],
						[77.53304766440586, 12.981950386536765],
						[77.53287375087419, 12.981981543162528],
						[77.53262845185937, 12.981999415937272],
						[77.53247238402251, 12.98200384652649],
						[77.53235200868369, 12.98200828019394],
						[77.53204879523807, 12.982017227873502],
						[77.53179019709651, 12.982044026088973],
						[77.53156720384276, 12.98207073683965],
						[77.53127737319744, 12.982177756071204],
						[77.53116591986411, 12.982222366832119],
						[77.53116145822918, 12.982222323297695],
						[77.5310500047823, 12.982289264113025],
						[77.5309652336024, 12.982360600276458],
						[77.53095184867348, 12.98239176633701],
						[77.53077347028749, 12.982748518515566],
						[77.53072894125982, 12.982859996926898],
						[77.53071555630311, 12.98289564634969],
						[77.53069324803842, 12.982953625118146],
						[77.53065755480583, 12.983060617019312],
						[77.53055056252434, 12.983212266543566],
						[77.53048809928161, 12.983265801125746],
						[77.5304123384958, 12.983332659134359],
						[77.53020718923247, 12.98343075755061],
						[77.52991735512255, 12.983600195924284],
						[77.52952052702427, 12.9837428991892],
						[77.52947591002192, 12.98375186098673],
						[77.52924845052227, 12.983796371799595],
						[77.52922176774348, 12.983796373113554],
						[77.52894969052716, 12.983800873863498],
						[77.52890069906216, 12.983809796158605],
						[77.52876693475841, 12.983827632500805],
						[77.5287624730294, 12.98383207219167],
						[77.52872677919115, 12.983827584915634],
						[77.52872231746058, 12.983827627526395],
						[77.52870893226782, 12.983827582923682],
						[77.52867323841275, 12.983836545526158],
						[77.52847263523967, 12.983956933788333],
						[77.52830317646162, 12.98411297203537],
						[77.52819172021502, 12.984242332609467],
						[77.5281649497031, 12.984278023524015],
						[77.52795087286663, 12.98456338270205],
						[77.5276253388262, 12.984893350556098],
						[77.5273711924151, 12.985120727668003],
						[77.52710366005495, 12.98534814602276],
						[77.52680489442947, 12.985588967090997],
						[77.52644375004381, 12.98586987546169],
						[77.52619843716712, 12.985954643569272],
						[77.52615836818056, 12.985959082829034],
						[77.52611374986013, 12.985959077030254],
						[77.5260735933568, 12.985959114912605],
						[77.52601567698063, 12.985954585138954],
						[77.52597998228094, 12.985941216679297],
						[77.52593090205062, 12.985927889576734],
						[77.52587298560185, 12.98589663219423],
						[77.52581051979176, 12.985847565987356],
						[77.52573475589206, 12.985780698291274],
						[77.52560536223315, 12.985624670202002],
						[77.52552067448497, 12.985548834845845],
						[77.52545383411028, 12.985499815561367],
						[77.52535567317476, 12.985446261518904],
						[77.52505244108717, 12.985370444635377],
						[77.52470467680723, 12.98529471197922],
						[77.52457536946568, 12.98524559808807],
						[77.52418744655994, 12.985111878591495],
						[77.52393766690334, 12.98502270038498],
						[77.52387528752004, 12.9850137294967],
						[77.52381728253691, 12.985013764626027],
						[77.52377721271229, 12.985013720223002],
						[77.52371474575233, 12.985018194873799],
						[77.52366566454548, 12.985018231194887],
						[77.52362113273699, 12.98503159285093],
						[77.52355420375828, 12.985067234190785],
						[77.5234472047995, 12.985160854931442],
						[77.52327327659633, 12.985325896879628],
						[77.52299234874131, 12.985584473112246],
						[77.52266688801164, 12.98581640577466],
						[77.52243495292942, 12.985932338103366],
						[77.52241710501968, 12.985945699133685],
						[77.52220310486476, 12.986057154739065],
						[77.52195787034161, 12.986132954630815],
						[77.52169478726748, 12.986271172693812],
						[77.52095006726941, 12.986650219612638],
						[77.5204952887294, 12.986864242477921],
						[77.52043728202477, 12.98688660665332],
						[77.52033028013767, 12.986926684877783],
						[77.52029904571668, 12.986850851827413],
						[77.52025897465423, 12.98677062457935],
						[77.52021435402227, 12.98669483262652],
						[77.52016080924082, 12.98661903931203],
						[77.52011181399394, 12.986565538090742],
						[77.5200582691643, 12.986520955396784],
						[77.51999588765622, 12.986458528647601],
						[77.51993788069728, 12.986391572028523],
						[77.5198887978628, 12.98632470309792],
						[77.51987103704583, 12.986297977307498],
						[77.51986657496822, 12.986289053167013],
						[77.5198353404201, 12.986248914317883],
						[77.51981303002333, 12.98620429364688],
						[77.51979964378316, 12.986159760637669],
						[77.5197907196222, 12.986110701991429],
						[77.51979071962218, 12.98606164479012],
						[77.51979964378316, 12.986012589029714],
						[77.51980856794344, 12.985981380090593],
						[77.51981303002333, 12.985936763794722],
						[77.51982195418255, 12.985825287431856],
						[77.5198264162619, 12.985713853570545],
						[77.51982195418255, 12.985602332187188],
						[77.51980856794344, 12.985490895722824],
						[77.51979518170276, 12.985410583354735],
						[77.51978179546052, 12.985325874032831],
						[77.51976394713506, 12.985245604196413],
						[77.51973726213346, 12.985165337262401],
						[77.51970602754987, 12.985085108561437],
						[77.51966586878696, 12.985013758745211],
						[77.5196390962705, 12.984973578039446],
						[77.51957225244372, 12.984911194773217],
						[77.51949639690172, 12.984826476081528],
						[77.51942062880127, 12.984741761703669],
						[77.51935378484633, 12.984648082403988],
						[77.5193225501576, 12.984594580886311],
						[77.51930023966041, 12.984527717434279],
						[77.51922884604004, 12.98430475383475],
						[77.51916200197259, 12.984077355600832],
						[77.51914415355003, 12.984010450082685],
						[77.51908623364974, 12.983872199883018],
						[77.51901483989526, 12.98372510655855],
						[77.51893907147456, 12.983586853986823],
						[77.51885429127664, 12.983448595986548],
						[77.51880975758704, 12.983377294043718],
						[77.51879637122899, 12.983350522429408],
						[77.51867151930408, 12.98309634792619],
						[77.51853774298326, 12.982846655827514],
						[77.51850204593975, 12.982784231498421],
						[77.51846188675246, 12.982726246523963],
						[77.51842181504385, 12.982654902707743],
						[77.51838611796403, 12.98258351229055],
						[77.51835934514683, 12.982507726420016],
						[77.51834149659854, 12.982431941989564],
						[77.5183281101855, 12.982351632101063],
						[77.51832364804746, 12.98227589295902],
						[77.5183281101855, 12.982195585939495],
						[77.51833703446103, 12.982115322765834],
						[77.51835488301002, 12.982039544098422],
						[77.51836380728346, 12.982008336299376],
						[77.51836826941994, 12.981999413921812],
						[77.51845742461967, 12.981798719237117],
						[77.51855112937166, 12.981598072769295],
						[77.5186536708047, 12.981401906654897],
						[77.51866259505474, 12.981384061947631],
						[77.51867151930408, 12.981361734177465],
						[77.51871605304773, 12.9812502635525],
						[77.51875621214543, 12.98113875346146],
						[77.51878744698944, 12.981022845275458],
						[77.51881421970603, 12.980906893410078],
						[77.51883206818026, 12.98078654348733],
						[77.51884090492383, 12.980688430898466],
						[77.51886321551098, 12.980634939443037],
						[77.5188989124413, 12.980559077885816],
						[77.51894799570225, 12.980483261417469],
						[77.51899699144967, 12.980411923757664],
						[77.51905499888767, 12.980345074674],
						[77.5191218430179, 12.980282619458917],
						[77.51916646407778, 12.980246934101405],
						[77.51917092618281, 12.980242494823138],
						[77.51924669444932, 12.980171161025524],
						[77.5193180880585, 12.980095390892187],
						[77.51938046993774, 12.980015132216304],
						[77.5194384771805, 12.979930351024358],
						[77.51949193480944, 12.979841211277414],
						[77.51949639690172, 12.979832288980392],
						[77.51950978317751, 12.979814401912835],
						[77.51953209363367, 12.979752030798377],
						[77.51954994199544, 12.979694012772397],
						[77.51956332826494, 12.979627157435763],
						[77.51956779035442, 12.979564697673895],
						[77.51956332826494, 12.979502322903162],
						[77.51954994199544, 12.979439860704792],
						[77.51953209363367, 12.979377441024724],
						[77.51950532108575, 12.979319503131872],
						[77.51946971183744, 12.979265965046853],
						[77.51942955299123, 12.9792124652347],
						[77.5193849320344, 12.979167887702745],
						[77.5193849320344, 12.979163404712299],
						[77.51933147435525, 12.979118830190075],
						[77.51924223234727, 12.979025192245976],
						[77.51915307776166, 12.978922592672923],
						[77.51907284732418, 12.978820080673414],
						[77.51899699144967, 12.97871303909909],
						[77.51893460936044, 12.978610443621529],
						[77.5188989124413, 12.978494571319898],
						[77.5188721397446, 12.978374131237397],
						[77.51885429127664, 12.978249295803646],
						[77.51884090492383, 12.978128900974538],
						[77.51883644280588, 12.97800402457973],
						[77.51883644280588, 12.97791927982623],
						[77.51883206818026, 12.977874755835154],
						[77.51877852274916, 12.977584909899484],
						[77.5187205151704, 12.97729502104085],
						[77.51868936780069, 12.977143421145096],
						[77.5186581329298, 12.97702750799935],
						[77.518609049544, 12.976795640824305],
						[77.51856451575853, 12.976559253023664],
						[77.51854666724238, 12.976434419704473],
						[77.51854666724238, 12.976380883843746],
						[77.51855112937166, 12.97623825158301],
						[77.51856451575853, 12.976100017344404],
						[77.51858673890708, 12.975961780213504],
						[77.51861351167084, 12.975823504952144],
						[77.51861797379753, 12.975805703450602],
						[77.51862243592403, 12.975747728712216],
						[77.51861797379753, 12.975689752814478],
						[77.51860458741695, 12.975631775761581],
						[77.51858673890708, 12.97557832409612],
						[77.51856005362974, 12.97552478930426],
						[77.51852881872342, 12.975475689446725],
						[77.51848865954555, 12.975431157474615],
						[77.51844412571305, 12.97539098268918],
						[77.51839504223503, 12.975359811856636],
						[77.51834149659854, 12.975332993954611],
						[77.51831481126376, 12.975324115175443],
						[77.5182969627085, 12.975315233347041],
						[77.51826572773005, 12.975301823801493],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000006d",
			properties: {
				AREA_SQ_KM: 1.6590043821025418,
				ASS_CONST1: "Govindaraja Nagar",
				ASS_CONST_: "166",
				LAT: 12.9797422678665,
				LON: 77.5248978783418,
				LOST_AREA_SQ_KM: 0.03281686771880506,
				OBJECTID: 110,
				POP_F: 15982.0,
				POP_M: 19274.0,
				POP_SC: 924.0,
				POP_ST: 524.0,
				POP_TOTAL: 35256.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Kaveripura",
				WARD_NO: 103.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53319034678785, 12.981901250840602],
						[77.53304766440586, 12.981366181615039],
						[77.53273106809505, 12.981441990588504],
						[77.53230293120158, 12.979836709079992],
						[77.53203987203729, 12.979925885187619],
						[77.53186595696477, 12.979127740104648],
						[77.5318614953573, 12.979114333362592],
						[77.53174558098421, 12.978378575041653],
						[77.53148698215337, 12.976991818789335],
						[77.53143344267382, 12.976603856998224],
						[77.53195518907745, 12.97651021374069],
						[77.53191057302985, 12.976100011023012],
						[77.53237877821046, 12.975997482410763],
						[77.53275783753261, 12.975908227422346],
						[77.53282021204733, 12.975899347594732],
						[77.53244570199983, 12.974722109680457],
						[77.53294951002079, 12.974615094032991],
						[77.53380568984757, 12.974423391104498],
						[77.53417135986412, 12.97435650379076],
						[77.53432742536965, 12.974329739753562],
						[77.53451472118968, 12.974289571401815],
						[77.5346172482431, 12.974285132973337],
						[77.53484915849815, 12.974236086931931],
						[77.5351122111648, 12.974182598105942],
						[77.53543326231448, 12.974093377049355],
						[77.53558486462674, 12.974030963102736],
						[77.53660154842444, 12.973678724135686],
						[77.53732840790653, 12.973437905159091],
						[77.53779212940591, 12.973281867070638],
						[77.5381622479624, 12.973148114448653],
						[77.5386438114274, 12.97290732847066],
						[77.53869734751223, 12.97294293572523],
						[77.53959363580142, 12.973255073429693],
						[77.54147086710945, 12.973651955748306],
						[77.5422066178546, 12.97380356011374],
						[77.54206840799604, 12.974624014458511],
						[77.5421575446266, 12.975502450315448],
						[77.54235821113275, 12.976920476306313],
						[77.5413460400628, 12.977054234388676],
						[77.5405835161565, 12.977147897821984],
						[77.54079756921394, 12.978940422028574],
						[77.54050767462093, 12.979060815847182],
						[77.54026247955234, 12.979163368520386],
						[77.54020894420478, 12.9791856962982],
						[77.54011980604562, 12.979221390290872],
						[77.5396693904705, 12.979426530799957],
						[77.53929035418986, 12.979587013408302],
						[77.53924582849719, 12.97960037901459],
						[77.53791249898596, 12.98018898227861],
						[77.536712996431, 12.980715163202015],
						[77.53611551486753, 12.980973791161272],
						[77.53410443709014, 12.981660540085613],
						[77.5333241058908, 12.98185220049221],
						[77.53319034678785, 12.981901250840602],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000006e",
			properties: {
				AREA_SQ_KM: 0.7709192866463445,
				ASS_CONST1: "Govindaraja Nagar",
				ASS_CONST_: "166",
				LAT: 12.9771164127201,
				LON: 77.5366824799528,
				LOST_AREA_SQ_KM: 0.031959717137235756,
				OBJECTID: 111,
				POP_F: 12208.0,
				POP_M: 13565.0,
				POP_SC: 2278.0,
				POP_ST: 274.0,
				POP_TOTAL: 25773.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Govindaraja Nagar",
				WARD_NO: 104.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53745770075396, 12.987970124870099],
						[77.53743539381176, 12.987876528444136],
						[77.53741754825485, 12.98774719329244],
						[77.53730163954492, 12.986578880641279],
						[77.5371678851207, 12.985165367772158],
						[77.53705635008156, 12.984215602116286],
						[77.53706081148522, 12.983907927811297],
						[77.53703850446513, 12.98371613108941],
						[77.53700290070245, 12.983564559222229],
						[77.53702065884593, 12.983408499595775],
						[77.53706973429203, 12.983252403518923],
						[77.53721241162732, 12.98282881604508],
						[77.53725256419891, 12.982681600418202],
						[77.53725702559488, 12.982525588197948],
						[77.53723918000996, 12.982396259261712],
						[77.53723471861329, 12.982356124286573],
						[77.53720348883174, 12.982124238027987],
						[77.5371856432384, 12.981977062421139],
						[77.53713219392007, 12.981669437295931],
						[77.53691813395966, 12.981076336353413],
						[77.53691813395966, 12.981067455963814],
						[77.53678437908425, 12.98078655562666],
						[77.53673530351496, 12.980737451774962],
						[77.536712996431, 12.980715163202015],
						[77.53791249898596, 12.98018898227861],
						[77.53924582849719, 12.97960037901459],
						[77.53929035418986, 12.979587013408302],
						[77.5396693904705, 12.979426530799957],
						[77.54011980604562, 12.979221390290872],
						[77.54020894420478, 12.9791856962982],
						[77.54026247955234, 12.979163368520386],
						[77.54050767462093, 12.979060815847182],
						[77.54079756921394, 12.978940422028574],
						[77.5405835161565, 12.977147897821984],
						[77.5413460400628, 12.977054234388676],
						[77.54235821113275, 12.976920476306313],
						[77.5421575446266, 12.975502450315448],
						[77.54206840799604, 12.974624014458511],
						[77.5422066178546, 12.97380356011374],
						[77.54346851857247, 12.974066632750777],
						[77.5442577898454, 12.974164780528149],
						[77.54505151672399, 12.97416916777167],
						[77.54624655768431, 12.974204840772211],
						[77.54727661656872, 12.974227192005083],
						[77.54731230458269, 12.974218225534138],
						[77.547383680577, 12.974209345157325],
						[77.54745496905595, 12.974209340686185],
						[77.54752188396785, 12.974213780515345],
						[77.5475932598304, 12.974231626767512],
						[77.54766008719048, 12.974249511908347],
						[77.54772254099665, 12.97428072154123],
						[77.54778499476846, 12.974316328156435],
						[77.54784290005563, 12.974356456879699],
						[77.54789197082722, 12.974401073086295],
						[77.54789643180541, 12.974409996356792],
						[77.5479187366937, 12.974441249330463],
						[77.54801241717666, 12.97457501218286],
						[77.54803472204217, 12.974597298791464],
						[77.5483646585997, 12.97506104333407],
						[77.54817738560769, 12.975252792862474],
						[77.54755757190475, 12.975926112127283],
						[77.54747281303632, 12.976019699941396],
						[77.54690206668656, 12.976603913981714],
						[77.54648736627497, 12.976911583887999],
						[77.54589430969057, 12.977352977578768],
						[77.5455955934387, 12.977611676114424],
						[77.54532801648624, 12.97776324576905],
						[77.54509612761382, 12.977865844335744],
						[77.5448018703623, 12.977977325955948],
						[77.54441839008902, 12.97812001574524],
						[77.54403044739117, 12.978235934154533],
						[77.54418203823556, 12.978953866506286],
						[77.54425332872428, 12.97922579523851],
						[77.54434255111339, 12.980804373163267],
						[77.54277292662057, 12.980844444692682],
						[77.54283984429104, 12.981250236568734],
						[77.5428576889965, 12.981660468316896],
						[77.54278631015782, 12.982240205781629],
						[77.54266148455639, 12.982819861446282],
						[77.54256333847214, 12.983292516800509],
						[77.54202825712586, 12.983167656917011],
						[77.54189896947798, 12.983783053245459],
						[77.54184543490143, 12.984380561163082],
						[77.54180082273503, 12.984741739448141],
						[77.5418097451697, 12.984920132036972],
						[77.5418097451697, 12.985036013760222],
						[77.54181866760368, 12.985129650910556],
						[77.54183205125335, 12.98519211924661],
						[77.54187666340749, 12.985232214822355],
						[77.54193912039385, 12.98523221828816],
						[77.54205502437422, 12.98520997523114],
						[77.54238497830337, 12.985120745021229],
						[77.54242512904749, 12.985593459037178],
						[77.5424295902404, 12.985678172555597],
						[77.54242512904749, 12.985678129201476],
						[77.54210855885204, 12.98579852941601],
						[77.54183205125335, 12.985883274279493],
						[77.5410428506034, 12.986311384365214],
						[77.5392056766297, 12.987283458508571],
						[77.53917444738963, 12.987301261140777],
						[77.53890694279119, 12.987457312287674],
						[77.53825138765684, 12.987747197291212],
						[77.53753791823152, 12.987956762887737],
						[77.53745770075396, 12.987970124870099],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000006f",
			properties: {
				AREA_SQ_KM: 0.7987784790779413,
				ASS_CONST1: "Govindaraja Nagar",
				ASS_CONST_: "166",
				LAT: 12.9804974028429,
				LON: 77.5415353943762,
				LOST_AREA_SQ_KM: 0.04265011297116746,
				OBJECTID: 112,
				POP_F: 12716.0,
				POP_M: 14141.0,
				POP_SC: 3172.0,
				POP_ST: 433.0,
				POP_TOTAL: 26857.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Agrahara Dasarahalli",
				WARD_NO: 105.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5544914901776, 12.975560443765549],
						[77.55466537061545, 12.975957339275828],
						[77.55490170064246, 12.976421053951894],
						[77.55501759138993, 12.976617280126815],
						[77.5551380301771, 12.976942796342996],
						[77.55516925502701, 12.97701414176924],
						[77.5550934232338, 12.977081007062004],
						[77.5548169472586, 12.977196897974606],
						[77.55453154917534, 12.977321755496227],
						[77.554558313476, 12.977379780374521],
						[77.55459399920036, 12.977473413658547],
						[77.5546207634863, 12.97756704726502],
						[77.55463860634006, 12.97766516460616],
						[77.55465198847855, 12.977758755622581],
						[77.55465644919101, 12.977856916591138],
						[77.55465198847855, 12.977954991648462],
						[77.55465198847855, 12.977977322570183],
						[77.55465644919101, 12.978057549995057],
						[77.55467429203918, 12.978160064995057],
						[77.55469659559543, 12.978258225875162],
						[77.55472782056682, 12.978351816967958],
						[77.55476350623789, 12.978449934599118],
						[77.5547678794811, 12.978458854054002],
						[77.55495968976335, 12.9790920954277],
						[77.5551960191772, 12.979908038441934],
						[77.55524954745866, 12.980117641572273],
						[77.5550443555759, 12.98010428151032],
						[77.55467875275079, 12.980046270143328],
						[77.55379587829553, 12.979979394371234],
						[77.55381809455888, 12.980211281493524],
						[77.55381363381345, 12.980211324668316],
						[77.55388054497664, 12.980608157502393],
						[77.55384039828346, 12.980630446297974],
						[77.5536977418361, 12.98063933317632],
						[77.55367097733244, 12.980639333510382],
						[77.55364421282246, 12.980634936546522],
						[77.55272564401608, 12.980679539208246],
						[77.55050502437956, 12.980790985031062],
						[77.54906922563642, 12.980835582186083],
						[77.54901123350544, 12.980835538531526],
						[77.54899785070177, 12.980875673999774],
						[77.54899785070177, 12.980889038164761],
						[77.54899785070177, 12.980920249962434],
						[77.54900231163651, 12.981410802266616],
						[77.54904692097416, 12.984295872876192],
						[77.54904692097416, 12.984300270245614],
						[77.54882396158, 12.984393907989437],
						[77.54881057875426, 12.984393950816143],
						[77.54880611781199, 12.984398391202348],
						[77.54812385399039, 12.984541043897163],
						[77.54518534934087, 12.98501372789466],
						[77.54518534934087, 12.985009330550515],
						[77.54408835459276, 12.985223308825539],
						[77.54329908214255, 12.985406122500484],
						[77.54292005796958, 12.985522123733471],
						[77.54287553369917, 12.985539887426096],
						[77.54245189620235, 12.98566924977184],
						[77.5424295902404, 12.985678172555597],
						[77.54242512904749, 12.985593459037178],
						[77.54238497830337, 12.985120745021229],
						[77.54205502437422, 12.98520997523114],
						[77.54193912039385, 12.98523221828816],
						[77.54187666340749, 12.985232214822355],
						[77.54183205125335, 12.98519211924661],
						[77.54181866760368, 12.985129650910556],
						[77.5418097451697, 12.985036013760222],
						[77.5418097451697, 12.984920132036972],
						[77.54180082273503, 12.984741739448141],
						[77.54184543490143, 12.984380561163082],
						[77.54189896947798, 12.983783053245459],
						[77.54202825712586, 12.983167656917011],
						[77.54256333847214, 12.983292516800509],
						[77.54266148455639, 12.982819861446282],
						[77.54278631015782, 12.982240205781629],
						[77.5428576889965, 12.981660468316896],
						[77.54283984429104, 12.981250236568734],
						[77.54277292662057, 12.980844444692682],
						[77.54434255111339, 12.980804373163267],
						[77.54425332872428, 12.97922579523851],
						[77.54418203823556, 12.978953866506286],
						[77.54403044739117, 12.978235934154533],
						[77.54441839008902, 12.97812001574524],
						[77.5448018703623, 12.977977325955948],
						[77.54509612761382, 12.977865844335744],
						[77.54532801648624, 12.97776324576905],
						[77.5455955934387, 12.977611676114424],
						[77.54589430969057, 12.977352977578768],
						[77.54648736627497, 12.976911583887999],
						[77.54690206668656, 12.976603913981714],
						[77.54747281303632, 12.976019699941396],
						[77.54755757190475, 12.975926112127283],
						[77.54817738560769, 12.975252792862474],
						[77.5483646585997, 12.97506104333407],
						[77.5483825024371, 12.975043196530974],
						[77.54857869699369, 12.974833600178256],
						[77.54946161203694, 12.97405323597255],
						[77.54950622119411, 12.97401314514943],
						[77.54957750834026, 12.973955203110215],
						[77.5496533438196, 12.97390170530819],
						[77.54973364015427, 12.97385264750849],
						[77.54981384896347, 12.97381246564295],
						[77.54990306698342, 12.973776771026754],
						[77.54999219746473, 12.973750038548467],
						[77.55008141534451, 12.973727793454424],
						[77.5501750065744, 12.973714381099741],
						[77.55026868519548, 12.97370993933578],
						[77.55035790285795, 12.973709937711211],
						[77.55045149385988, 12.973718855051793],
						[77.55054517225274, 12.973732259793502],
						[77.55063430222982, 12.973754497469285],
						[77.5507235196049, 12.973785705780054],
						[77.55080827604627, 12.973821440495318],
						[77.55089294495649, 12.973861567987335],
						[77.55096877955697, 12.973910580120526],
						[77.55104461410676, 12.973968558723715],
						[77.55111590028673, 12.974026490116907],
						[77.55117835219164, 12.974093392419157],
						[77.55138792443934, 12.974231631259626],
						[77.55251161344216, 12.975119012595943],
						[77.55293521340631, 12.97537760247325],
						[77.5534301839812, 12.975524797600961],
						[77.55399206349429, 12.975547103707262],
						[77.55434874708374, 12.975547084462642],
						[77.55435320780815, 12.97555600787969],
						[77.5544914901776, 12.975560443765549],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000070",
			properties: {
				AREA_SQ_KM: 0.9899607045272927,
				ASS_CONST1: "Govindaraja Nagar",
				ASS_CONST_: "166",
				LAT: 12.979654172134,
				LON: 77.5484753583426,
				LOST_AREA_SQ_KM: 0.05657661247630401,
				OBJECTID: 113,
				POP_F: 11740.0,
				POP_M: 13012.0,
				POP_SC: 2111.0,
				POP_ST: 335.0,
				POP_TOTAL: 24752.0,
				RESERVATIO: "General",
				WARD_NAME: "Dr. Raj Kumar Ward",
				WARD_NO: 106.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52853947384163, 12.97281366556533],
						[77.52852608862736, 12.972621892482692],
						[77.52843247956856, 12.972349857329917],
						[77.5282674824793, 12.971926262919146],
						[77.52823625023558, 12.97190397563694],
						[77.52818279671173, 12.971854923175318],
						[77.52788403391897, 12.970744594405913],
						[77.52780818391382, 12.970427992370889],
						[77.52878031994435, 12.969826052524214],
						[77.52890069906216, 12.970080238210302],
						[77.52900323117548, 12.97003562876424],
						[77.52924398881215, 12.970017791669298],
						[77.52952945042266, 12.969964271620954],
						[77.5298370447858, 12.969879580145127],
						[77.53039003017165, 12.969759170284762],
						[77.53077793193764, 12.969683361435093],
						[77.530572870817, 12.968680080656036],
						[77.53116591986411, 12.968519536576194],
						[77.5317277345344, 12.968461531864278],
						[77.53241000931706, 12.96852400551704],
						[77.5325839235578, 12.968564138936117],
						[77.53324379795905, 12.968416992272763],
						[77.53385476668126, 12.968153857957333],
						[77.53467524772128, 12.967930936276002],
						[77.53533519746819, 12.967752579084499],
						[77.53596391326279, 12.96758314100589],
						[77.53617788754063, 12.967525121095173],
						[77.53665062405146, 12.968764793949234],
						[77.53665954689046, 12.968791518785434],
						[77.53745770075396, 12.971034471318553],
						[77.53784120451898, 12.972086848479934],
						[77.53787680801912, 12.97218047069909],
						[77.53787234664748, 12.972184910419935],
						[77.53795265131026, 12.972327592672258],
						[77.53821132291569, 12.972612956931052],
						[77.5386438114274, 12.97290732847066],
						[77.5381622479624, 12.973148114448653],
						[77.53779212940591, 12.973281867070638],
						[77.53732840790653, 12.973437905159091],
						[77.53660154842444, 12.973678724135686],
						[77.53558486462674, 12.974030963102736],
						[77.53543326231448, 12.974093377049355],
						[77.5351122111648, 12.974182598105942],
						[77.53484915849815, 12.974236086931931],
						[77.5346172482431, 12.974285132973337],
						[77.53451472118968, 12.974289571401815],
						[77.53432742536965, 12.974329739753562],
						[77.53417135986412, 12.97435650379076],
						[77.53380568984757, 12.974423391104498],
						[77.53294951002079, 12.974615094032991],
						[77.53244570199983, 12.974722109680457],
						[77.53282021204733, 12.975899347594732],
						[77.53275783753261, 12.975908227422346],
						[77.53237877821046, 12.975997482410763],
						[77.53191057302985, 12.976100011023012],
						[77.53141113454988, 12.976175767122552],
						[77.53140667292459, 12.976175809890208],
						[77.53129075808808, 12.976189168198234],
						[77.53117038149887, 12.976211449066115],
						[77.53091169387729, 12.976215916237312],
						[77.53073340291171, 12.976220393695616],
						[77.52963644366432, 12.976287320421834],
						[77.52900769289504, 12.976305086135222],
						[77.5288917756145, 12.975328563535856],
						[77.52883823491374, 12.974378775768894],
						[77.52878031994435, 12.972876095632692],
						[77.52863317029743, 12.972835957987463],
						[77.52853947384163, 12.97281366556533],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000071",
			properties: {
				AREA_SQ_KM: 0.6909311476163073,
				ASS_CONST1: "Govindaraja Nagar",
				ASS_CONST_: "166",
				LAT: 12.9718892616902,
				LON: 77.5328436441007,
				LOST_AREA_SQ_KM: 0.01605889308319092,
				OBJECTID: 114,
				POP_F: 9638.0,
				POP_M: 10430.0,
				POP_SC: 698.0,
				POP_ST: 391.0,
				POP_TOTAL: 20068.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Marenahalli",
				WARD_NO: 125.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52780818391382, 12.970427992370889],
						[77.52788403391897, 12.970744594405913],
						[77.52818279671173, 12.971854923175318],
						[77.52812925567743, 12.971846039568009],
						[77.52807125286182, 12.971845991953185],
						[77.5280356462922, 12.971854959064023],
						[77.5279954904669, 12.971854912820444],
						[77.52794641110563, 12.97187719381167],
						[77.52789741920876, 12.971872754457292],
						[77.52787957215536, 12.971868356429487],
						[77.52701897342142, 12.972011029714789],
						[77.5268584360868, 12.971707827055466],
						[77.52678704720476, 12.971400145700754],
						[77.52668897490535, 12.9709051388284],
						[77.52660420047675, 12.970659915750106],
						[77.52655966981766, 12.970588536971764],
						[77.52645267368342, 12.97055284283937],
						[77.5261716661864, 12.970597436074428],
						[77.52571244665103, 12.970700043670373],
						[77.52522190470481, 12.970789237977472],
						[77.52505244108717, 12.970758020446382],
						[77.52494990541493, 12.970664352075799],
						[77.52482059835191, 12.97036111360082],
						[77.52471360058317, 12.970071283078724],
						[77.5246422103561, 12.969879554617554],
						[77.5246422103561, 12.96967886734726],
						[77.52462882468355, 12.969424688670443],
						[77.52459321703712, 12.96918840051128],
						[77.52448166966973, 12.968827233968584],
						[77.52439698108567, 12.968639950519368],
						[77.52425428767808, 12.968345639302749],
						[77.52397336224332, 12.96777482111641],
						[77.5236968980432, 12.967181809232972],
						[77.52356312762439, 12.966820609344389],
						[77.52350512248222, 12.96660660475582],
						[77.52346505254776, 12.966307782518214],
						[77.52342935704841, 12.96605365339068],
						[77.52337581377843, 12.965897535841993],
						[77.52328666242823, 12.965705818228644],
						[77.52325096687298, 12.965585476676674],
						[77.52324650492778, 12.96534018755219],
						[77.52333128185658, 12.965054822039281],
						[77.52346951448439, 12.964733769371852],
						[77.52354974182497, 12.964528611009488],
						[77.52363005659785, 12.964154071068508],
						[77.52367012647424, 12.963917748670859],
						[77.52369243611548, 12.963511958266373],
						[77.52364781682864, 12.963342483950736],
						[77.52352743215579, 12.963066076722821],
						[77.52330897214459, 12.962713811144878],
						[77.52325989076283, 12.96247748964906],
						[77.52323758103685, 12.96230797558243],
						[77.52321982074207, 12.9621786785863],
						[77.52322865714524, 12.962062736134264],
						[77.5232420429824, 12.961964684784853],
						[77.52331343408734, 12.961830907744728],
						[77.52340704735565, 12.961755097327238],
						[77.52367905033127, 12.96172834228075],
						[77.52400905757214, 12.961683704205656],
						[77.52631881901576, 12.962232179279892],
						[77.52630989536566, 12.961995902667237],
						[77.52677812359136, 12.961960185419416],
						[77.52678704720476, 12.962058282345302],
						[77.52678258539817, 12.9622054700293],
						[77.52669343671565, 12.962441746204284],
						[77.52663543316832, 12.962664702138024],
						[77.52666220403998, 12.962847536517332],
						[77.52674689093895, 12.963115063276938],
						[77.52678258539817, 12.963306863932369],
						[77.52678258539817, 12.963538746721124],
						[77.52676027636247, 12.963569907394188],
						[77.5267291311916, 12.963654689367171],
						[77.52672020757366, 12.96374830417805],
						[77.52664435679291, 12.963953417463252],
						[77.52652397528855, 12.964274474563403],
						[77.52652397528855, 12.964283353404356],
						[77.52649720438438, 12.964421620375019],
						[77.52646150983568, 12.964599971103317],
						[77.52742473380603, 12.9647159472296],
						[77.5278483398052, 12.9647694481274],
						[77.52842355608416, 12.964818469932487],
						[77.52938667596472, 12.964929952151858],
						[77.52947591002192, 12.964925474071883],
						[77.5296453670536, 12.964885393618065],
						[77.52988166164654, 12.964831821141274],
						[77.5301848808681, 12.964756054041311],
						[77.53025626761868, 12.9647248516253],
						[77.53034541351025, 12.96469804215182],
						[77.53047025263453, 12.964671320444873],
						[77.53053717754663, 12.964657918771328],
						[77.53055502418323, 12.964653523179193],
						[77.53113915005193, 12.964640126806025],
						[77.53121937198658, 12.964640125919404],
						[77.53170096485441, 12.964644529071256],
						[77.53236093185998, 12.964649019691377],
						[77.53246792243883, 12.964662380965722],
						[77.53254376933769, 12.964666909388574],
						[77.53256161565949, 12.964671306435838],
						[77.53279790419536, 12.964711434320089],
						[77.53294058689065, 12.9647247966361],
						[77.53299858722391, 12.964729324319945],
						[77.53306104908818, 12.964720361151405],
						[77.53328395193202, 12.964698119254976],
						[77.53352032644851, 12.964662390749451],
						[77.53383245903221, 12.96459997966893],
						[77.53388153585435, 12.964591058937405],
						[77.5340376017576, 12.964568775325006],
						[77.53431404083638, 12.964501879773994],
						[77.53453694122446, 12.964412655451552],
						[77.53468863221205, 12.964345850737951],
						[77.53472423669942, 12.964417138573332],
						[77.53546003111599, 12.966084825358429],
						[77.5361556678265, 12.967467150340175],
						[77.53617788754063, 12.967525121095173],
						[77.53596391326279, 12.96758314100589],
						[77.53533519746819, 12.967752579084499],
						[77.53467524772128, 12.967930936276002],
						[77.53385476668126, 12.968153857957333],
						[77.53324379795905, 12.968416992272763],
						[77.5325839235578, 12.968564138936117],
						[77.53241000931706, 12.96852400551704],
						[77.5317277345344, 12.968461531864278],
						[77.53116591986411, 12.968519536576194],
						[77.530572870817, 12.968680080656036],
						[77.53077793193764, 12.969683361435093],
						[77.53039003017165, 12.969759170284762],
						[77.5298370447858, 12.969879580145127],
						[77.52952945042266, 12.969964271620954],
						[77.52924398881215, 12.970017791669298],
						[77.52900323117548, 12.97003562876424],
						[77.52890069906216, 12.970080238210302],
						[77.52878031994435, 12.969826052524214],
						[77.52780818391382, 12.970427992370889],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000072",
			properties: {
				AREA_SQ_KM: 0.7917169212027978,
				ASS_CONST1: "Govindaraja Nagar",
				ASS_CONST_: "166",
				LAT: 12.9667105528406,
				LON: 77.5284643519759,
				LOST_AREA_SQ_KM: 0.016337326608007095,
				OBJECTID: 115,
				POP_F: 10363.0,
				POP_M: 11421.0,
				POP_SC: 1072.0,
				POP_ST: 285.0,
				POP_TOTAL: 21784.0,
				RESERVATIO: "General",
				WARD_NAME: "Maruthi Mandir ward",
				WARD_NO: 126.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.51753892148625, 12.963659111912154],
						[77.51823449274305, 12.963645708565362],
						[77.51899699144967, 12.96352089597835],
						[77.51922000932657, 12.963480743459789],
						[77.51926454285582, 12.963619007983548],
						[77.51967925504285, 12.963552099163097],
						[77.51965248252951, 12.963458483572696],
						[77.52014742304154, 12.963329167906974],
						[77.52022327815006, 12.963329131474698],
						[77.52036597660832, 12.963766088424002],
						[77.52048190256941, 12.963730493670171],
						[77.52045513024473, 12.963587743649367],
						[77.52045513024473, 12.963476286803413],
						[77.52056659406492, 12.963449527107455],
						[77.52075837457406, 12.963409413200896],
						[77.52082521770483, 12.963373684705285],
						[77.52080736954285, 12.963168613166571],
						[77.5206869818675, 12.96221433411499],
						[77.52067359573005, 12.961817515288567],
						[77.52077176070155, 12.961813040872679],
						[77.52080290750192, 12.961817478404361],
						[77.52086983809751, 12.961817526877873],
						[77.52102145981107, 12.96181753475068],
						[77.52160554711097, 12.96178631202705],
						[77.52201578878177, 12.961786244223191],
						[77.52203363675439, 12.961786245557525],
						[77.52207379468253, 12.961795170186262],
						[77.5221273385647, 12.961826464543499],
						[77.52223433876362, 12.961884394271936],
						[77.52261334438313, 12.962098399935847],
						[77.52290319709093, 12.96220094714901],
						[77.5230325063314, 12.962250043047701],
						[77.52305481609756, 12.96225448394015],
						[77.52310827202925, 12.962272326921232],
						[77.52323758103685, 12.96230797558243],
						[77.52325989076283, 12.96247748964906],
						[77.52330897214459, 12.962713811144878],
						[77.52352743215579, 12.963066076722821],
						[77.52364781682864, 12.963342483950736],
						[77.52369243611548, 12.963511958266373],
						[77.52367012647424, 12.963917748670859],
						[77.52363005659785, 12.964154071068508],
						[77.52354974182497, 12.964528611009488],
						[77.52346951448439, 12.964733769371852],
						[77.52333128185658, 12.965054822039281],
						[77.52324650492778, 12.96534018755219],
						[77.52325096687298, 12.965585476676674],
						[77.52328666242823, 12.965705818228644],
						[77.52337581377843, 12.965897535841993],
						[77.52342935704841, 12.96605365339068],
						[77.52346505254776, 12.966307782518214],
						[77.52350512248222, 12.96660660475582],
						[77.52356312762439, 12.966820609344389],
						[77.5236968980432, 12.967181809232972],
						[77.52397336224332, 12.96777482111641],
						[77.52425428767808, 12.968345639302749],
						[77.52439698108567, 12.968639950519368],
						[77.52448166966973, 12.968827233968584],
						[77.52459321703712, 12.96918840051128],
						[77.52462882468355, 12.969424688670443],
						[77.5246422103561, 12.96967886734726],
						[77.5246422103561, 12.969879554617554],
						[77.52471360058317, 12.970071283078724],
						[77.52482059835191, 12.97036111360082],
						[77.52494990541493, 12.970664352075799],
						[77.52505244108717, 12.970758020446382],
						[77.52522190470481, 12.970789237977472],
						[77.52571244665103, 12.970700043670373],
						[77.5261716661864, 12.970597436074428],
						[77.52645267368342, 12.97055284283937],
						[77.52655966981766, 12.970588536971764],
						[77.52660420047675, 12.970659915750106],
						[77.52668897490535, 12.9709051388284],
						[77.52678704720476, 12.971400145700754],
						[77.5268584360868, 12.971707827055466],
						[77.52701897342142, 12.972011029714789],
						[77.52787957215536, 12.971868356429487],
						[77.52789741920876, 12.971872754457292],
						[77.52794641110563, 12.97187719381167],
						[77.5279954904669, 12.971854912820444],
						[77.5280356462922, 12.971854959064023],
						[77.52807125286182, 12.971845991953185],
						[77.52812925567743, 12.971846039568009],
						[77.52818279671173, 12.971854923175318],
						[77.52823625023558, 12.97190397563694],
						[77.5282674824793, 12.971926262919146],
						[77.52843247956856, 12.972349857329917],
						[77.52852608862736, 12.972621892482692],
						[77.52853947384163, 12.97281366556533],
						[77.52856178252858, 12.973143633220731],
						[77.52857516773865, 12.973513688602347],
						[77.52799995222597, 12.973580600020409],
						[77.52742919558747, 12.973576171821573],
						[77.52707688928712, 12.973495845713261],
						[77.52652843710531, 12.973549392900853],
						[77.52641251729966, 12.97354490376984],
						[77.52635897543259, 12.973544898907454],
						[77.52633666631387, 12.973576147671933],
						[77.52632328084054, 12.973625156332767],
						[77.52631435719078, 12.973656449435524],
						[77.52628767372411, 12.973683176056776],
						[77.52615836818056, 12.973696573909391],
						[77.525908592848, 12.973705434468348],
						[77.52560090038023, 12.973758992842848],
						[77.52553406005265, 12.973767913338586],
						[77.52542706295444, 12.973339794217216],
						[77.52327773854047, 12.97398189599894],
						[77.52321089684908, 12.973999781383986],
						[77.5223591867836, 12.974267348324448],
						[77.5222254147934, 12.9739551769614],
						[77.52217633294464, 12.973999784294913],
						[77.52145847568083, 12.974200464230377],
						[77.52078960886911, 12.97430299483919],
						[77.52051313694028, 12.974334177618783],
						[77.52052206104466, 12.974530388533951],
						[77.52016080924082, 12.974637380582593],
						[77.5195990249759, 12.974806903534734],
						[77.51894799570225, 12.97501641378607],
						[77.51833257232336, 12.97527057799372],
						[77.51826572773005, 12.975301823801493],
						[77.51822110631743, 12.975283972745759],
						[77.51815426165874, 12.975243838131293],
						[77.51808732946776, 12.975194819853554],
						[77.51802940903023, 12.975145720821109],
						[77.5179714010701, 12.97508782435748],
						[77.51792240488166, 12.975025364341189],
						[77.51787778333458, 12.974958461020783],
						[77.51786885902307, 12.974940614794978],
						[77.51784208608434, 12.974887162345272],
						[77.5177930898403, 12.974789012454805],
						[77.5176548503239, 12.974303003238479],
						[77.51752999714819, 12.973870489546867],
						[77.51749429978899, 12.973763415798127],
						[77.51746306459053, 12.973362076155565],
						[77.5174764511052, 12.973299707448707],
						[77.51750768630002, 12.973255099643708],
						[77.51761915300382, 12.973179291295018],
						[77.51769046013942, 12.973170330836329],
						[77.5177083087897, 12.97315257460367],
						[77.51796247676592, 12.97309902149952],
						[77.5180249468805, 12.973094546746166],
						[77.51810964020245, 12.973054424165717],
						[77.51816764809222, 12.972987578367064],
						[77.51823449274305, 12.972835902310226],
						[77.5182523413081, 12.9727646118669],
						[77.5182523413081, 12.972728922434582],
						[77.51823895488458, 12.972666464275626],
						[77.51822110631743, 12.972626376117859],
						[77.51818995881119, 12.972559437299477],
						[77.5181007159091, 12.972430203103753],
						[77.51805171977627, 12.97233205560995],
						[77.51798924967636, 12.97218041154664],
						[77.51799371182749, 12.9720912321604],
						[77.51799371182749, 12.972024422676569],
						[77.51801602258051, 12.971921797533724],
						[77.51808732946776, 12.971734563494401],
						[77.51814533736888, 12.971609701906896],
						[77.51820771989023, 12.971520482647382],
						[77.5183281101855, 12.971373383520962],
						[77.51837719369233, 12.971275244665948],
						[77.51838611796403, 12.971145937837997],
						[77.51839058009962, 12.971065638236206],
						[77.51844412571305, 12.970922975161955],
						[77.51846634888508, 12.970847156239621],
						[77.51847081101754, 12.970784787450523],
						[77.51847081101752, 12.970753581252106],
						[77.51849312167714, 12.970650956931483],
						[77.51849312167714, 12.97060638901317],
						[77.51844850035354, 12.970387854672124],
						[77.51819433346147, 12.969968750124588],
						[77.51770384662738, 12.969424761802829],
						[77.51763253950017, 12.969304331013582],
						[77.51761022867205, 12.969215150721492],
						[77.5176057665059, 12.96911261105681],
						[77.51753892148625, 12.968595344405326],
						[77.51753892148623, 12.96849724528434],
						[77.51755230799202, 12.968416948710143],
						[77.51764146383019, 12.968243083391187],
						[77.51794462815545, 12.967904155619248],
						[77.51806064407347, 12.967774864530655],
						[77.51817657238033, 12.96769013594934],
						[77.5181007159091, 12.966999000119689],
						[77.5180249468805, 12.966909820070308],
						[77.51763253950017, 12.966637822975711],
						[77.51750322412984, 12.96652631114631],
						[77.51744967807427, 12.966486221518641],
						[77.51741406818353, 12.966441569425633],
						[77.51740960600969, 12.966370237199651],
						[77.51740514383569, 12.966294422545417],
						[77.51744967807427, 12.966093746464296],
						[77.51757006917045, 12.965919844308864],
						[77.51792240488166, 12.96570587909495],
						[77.51806501872869, 12.965647872286905],
						[77.5181408752237, 12.96564783711567],
						[77.51823449274305, 12.965621077184334],
						[77.51843073931205, 12.965496273998308],
						[77.5184752731498, 12.965424899841063],
						[77.5184752731498, 12.965380333904282],
						[77.5184752731498, 12.965313441930281],
						[77.51843966357956, 12.965010187928973],
						[77.51834595873588, 12.964662402164386],
						[77.51833703446103, 12.964617835639157],
						[77.51818995881119, 12.964252210911495],
						[77.51796247676592, 12.964015875076383],
						[77.5179000941103, 12.96395337815462],
						[77.51784208608434, 12.963917728714998],
						[77.51777077904363, 12.963895486230951],
						[77.51771277095182, 12.96386871536906],
						[77.51760130433958, 12.963779534784395],
						[77.51758345567255, 12.963761689660531],
						[77.51756114483483, 12.963721561498787],
						[77.51753892148625, 12.963690316140145],
						[77.51753892148625, 12.963659111912154],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000073",
			properties: {
				AREA_SQ_KM: 0.9715359212736522,
				ASS_CONST1: "Govindaraja Nagar",
				ASS_CONST_: "166",
				LAT: 12.9692392151885,
				LON: 77.521640582415,
				LOST_AREA_SQ_KM: 0.013655812504278445,
				OBJECTID: 116,
				POP_F: 11671.0,
				POP_M: 12816.0,
				POP_SC: 854.0,
				POP_ST: 529.0,
				POP_TOTAL: 24487.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Mudalapalya",
				WARD_NO: 127.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.51547431850257, 12.959614704469397],
						[77.51541193448496, 12.959529971943372],
						[77.51531822715171, 12.95941407240083],
						[77.51522014497715, 12.959307011854431],
						[77.51517114760588, 12.959258009014533],
						[77.5151265249816, 12.959195512791247],
						[77.51506405327822, 12.959093020127725],
						[77.5150105935721, 12.958990446490624],
						[77.51496150861527, 12.958883430108196],
						[77.51492143567584, 12.95877641895321],
						[77.51488573749961, 12.958664878871945],
						[77.51485896386008, 12.958553425981604],
						[77.51485896386008, 12.958535583604965],
						[77.51485450158621, 12.958446414425664],
						[77.51484557703797, 12.95835720178571],
						[77.51482326566426, 12.958268031018429],
						[77.51479657950568, 12.958183303161492],
						[77.51476534356787, 12.958098613808612],
						[77.51472518306382, 12.958018319593016],
						[77.51467618547979, 12.957942510933979],
						[77.51468056026494, 12.957920225837645],
						[77.51467618547979, 12.957880106084398],
						[77.51466279863621, 12.9578399812526],
						[77.51464494950898, 12.957804295021777],
						[77.5146181758129, 12.957773090054886],
						[77.5145869398262, 12.95774632367955],
						[77.51455132904101, 12.95772408631867],
						[77.51451563074886, 12.957710636551173],
						[77.51447547015681, 12.957706193658828],
						[77.51443093475609, 12.957706193569214],
						[77.51439077413421, 12.957710628677905],
						[77.51436400037844, 12.957724072388029],
						[77.51415890970081, 12.957799825564567],
						[77.51394043172577, 12.957880102369456],
						[77.51376648900977, 12.958000545741074],
						[77.51358362138131, 12.958134261997708],
						[77.51356585958169, 12.958147624542319],
						[77.51349446236901, 12.958147703421423],
						[77.51341422794799, 12.958152095311702],
						[77.51333399347051, 12.958165537579617],
						[77.51325813377629, 12.95818781096473],
						[77.51320467322329, 12.9582056518662],
						[77.51316451216944, 12.958236806946061],
						[77.51309320220234, 12.958308172669778],
						[77.5130262670393, 12.958379491521402],
						[77.51296388168201, 12.958459822398238],
						[77.51291033349398, 12.958540063803001],
						[77.51287017233643, 12.958606989592072],
						[77.51282117395515, 12.95866935035804],
						[77.51276762570008, 12.95873183570398],
						[77.51270077784311, 12.95878979861562],
						[77.51263384244967, 12.958843275153074],
						[77.51256253215071, 12.958887834268106],
						[77.51248675944058, 12.958928040035131],
						[77.51243321102847, 12.958981474882554],
						[77.51235297586062, 12.959048443577077],
						[77.51226819076369, 12.959110796095041],
						[77.51217903072273, 12.959168799479254],
						[77.51208540822982, 12.959222277094446],
						[77.51203185962923, 12.959244595528148],
						[77.51202302235775, 12.959253563019148],
						[77.51199624804596, 12.959266920196791],
						[77.5119650113409, 12.959275837832148],
						[77.51193377462728, 12.959275791200415],
						[77.51190262540297, 12.959275834994198],
						[77.51187138867226, 12.959266910289156],
						[77.51184015193301, 12.959253503453459],
						[77.51181783997117, 12.959235701698947],
						[77.51179552800494, 12.959213417820857],
						[77.51177776592677, 12.959191095592871],
						[77.51176437874285, 12.959168812739541],
						[77.51175991634786, 12.959150926834349],
						[77.51175545395272, 12.959097442539782],
						[77.51175991634786, 12.959039477162989],
						[77.5117733035323, 12.958981512822222],
						[77.51179106561118, 12.958927983790788],
						[77.51181337757826, 12.95887898472477],
						[77.51184461432486, 12.958829900477792],
						[77.51188477584361, 12.95878978145904],
						[77.51189370062363, 12.958780818235248],
						[77.51190700029453, 12.958767498455906],
						[77.51195162417898, 12.958727336831823],
						[77.51198732327396, 12.958678296186301],
						[77.51201855997289, 12.958629211952886],
						[77.51204078439774, 12.958575726593217],
						[77.51205863393267, 12.95851780598451],
						[77.51207202108203, 12.958459755613962],
						[77.51207202108203, 12.958401833086917],
						[77.51206755869907, 12.958343867001629],
						[77.51205863393267, 12.958285857360277],
						[77.51204078439774, 12.958232415059447],
						[77.51203632201356, 12.958227889381437],
						[77.51202739724471, 12.958129799586004],
						[77.51199178566004, 12.957866736139199],
						[77.51194716179131, 12.957603668134222],
						[77.51189816301338, 12.957340561439374],
						[77.51189370062363, 12.957331639944323],
						[77.51188031345333, 12.957255831452366],
						[77.51187138867226, 12.957171102489845],
						[77.51187138867226, 12.957086374545941],
						[77.51188031345333, 12.957001647613705],
						[77.5118892382337, 12.956943726787916],
						[77.51191592507281, 12.956903559417182],
						[77.51196054895377, 12.956827800444707],
						[77.51200071043169, 12.956747515890248],
						[77.51203185962922, 12.956662744161891],
						[77.51205863393267, 12.956578019339052],
						[77.5120764834648, 12.956488897798238],
						[77.5120809458474, 12.956462135396405],
						[77.51206309631597, 12.956399729977017],
						[77.51203632201356, 12.956319395591919],
						[77.51200517281725, 12.956239194332586],
						[77.51196054895377, 12.956167822254788],
						[77.51191592507281, 12.956096450230756],
						[77.51186246389048, 12.956029563576212],
						[77.51184907671653, 12.956011763456313],
						[77.51182230236387, 12.955962717217508],
						[77.51179999039853, 12.955900268561146],
						[77.51178222832105, 12.955837867735852],
						[77.51177776592675, 12.95577542100075],
						[77.5117733035323, 12.955712974300459],
						[77.51178222832105, 12.955646090117376],
						[77.51179552800494, 12.955583641083589],
						[77.51181783997116, 12.955525679209686],
						[77.51184461432486, 12.95546776090517],
						[77.51188031345333, 12.955409757331468],
						[77.5119203874617, 12.955360757043675],
						[77.51196947372786, 12.955316157679665],
						[77.51201855997289, 12.95527604028732],
						[77.5120764834648, 12.955244797311451],
						[77.51209433299414, 12.955231439354723],
						[77.51211664490188, 12.955213556768362],
						[77.51216118120665, 12.955164513807938],
						[77.51219688023602, 12.95511099221812],
						[77.51222811687755, 12.955053031315874],
						[77.51225489113492, 12.954995113082488],
						[77.51227265313878, 12.954932621592008],
						[77.51228157788844, 12.954865737709047],
						[77.51228604026302, 12.95480329229239],
						[77.51228157788844, 12.954736450264363],
						[77.51226819076369, 12.954674003173631],
						[77.51225042875913, 12.954611603014833],
						[77.51222365450072, 12.954549197791838],
						[77.51219241785796, 12.954495626790917],
						[77.51217010596504, 12.954459984488388],
						[77.51212110728291, 12.954415379928339],
						[77.51190700029453, 12.954201350508363],
						[77.51169744279973, 12.953978405465397],
						[77.51155473348786, 12.953822304153269],
						[77.51151912175558, 12.953773262373351],
						[77.51146557288905, 12.953701977451708],
						[77.51141657390419, 12.953621690344468],
						[77.51137641222054, 12.953536918043916],
						[77.51134071293431, 12.953452189350923],
						[77.51131393846231, 12.953367461606009],
						[77.51129617630912, 12.953278343336114],
						[77.51128278906866, 12.953184696359292],
						[77.51127832665483, 12.95309553221794],
						[77.51127832665483, 12.953028648708996],
						[77.51126940182664, 12.952930477403035],
						[77.51126940182664, 12.95283687511128],
						[77.51127832665483, 12.952743273752745],
						[77.51129171389579, 12.952645147927143],
						[77.51131393846231, 12.95255598252436],
						[77.5113407129343, 12.952462296874863],
						[77.51140318667787, 12.952301817725663],
						[77.51147449770188, 12.952132371613402],
						[77.51155919589087, 12.95189600087089],
						[77.51156365829371, 12.951699791485757],
						[77.51151019694623, 12.951557147845433],
						[77.51141211149559, 12.951392091217706],
						[77.51138979945, 12.951249403551223],
						[77.5113451753457, 12.951093397900166],
						[77.51124262733788, 12.95089718452459],
						[77.51118024100477, 12.950767814254402],
						[77.51115346649506, 12.950571647256401],
						[77.51112669197911, 12.950362035275774],
						[77.51111330471875, 12.950277354334089],
						[77.51111776713905, 12.950156993248731],
						[77.51116239133233, 12.950018751863018],
						[77.51125155216818, 12.949960751090781],
						[77.51149234732546, 12.949764514030116],
						[77.51174206676617, 12.949532682002594],
						[77.51191146268376, 12.949389961066235],
						[77.51213895680527, 12.949617381866133],
						[77.51231281450679, 12.949818076262794],
						[77.51244213576557, 12.949889403271712],
						[77.51278993747609, 12.949880455312458],
						[77.51320913556174, 12.949809112120445],
						[77.51358808370492, 12.94977796751827],
						[77.51390919555337, 12.949831420213682],
						[77.51413213589058, 12.9499295109447],
						[77.51437738725711, 12.950081169587072],
						[77.51465387407293, 12.950281829064965],
						[77.51487681295379, 12.950433443370262],
						[77.51513544950784, 12.950665302678638],
						[77.51534053842852, 12.950910518044543],
						[77.51552786548625, 12.951244943476922],
						[77.51581318648984, 12.951735459255358],
						[77.51609859427377, 12.952217017961877],
						[77.51657123919148, 12.953028610842352],
						[77.51695472584332, 12.953706438186225],
						[77.5172222820449, 12.954129998053322],
						[77.51735159773403, 12.954299414699106],
						[77.51758345567255, 12.954584807347082],
						[77.51799371182749, 12.954986139438006],
						[77.51950085899382, 12.954482305475919],
						[77.5195410178149, 12.954259283053382],
						[77.51998250143754, 12.952868103598494],
						[77.52028119747226, 12.952368667318343],
						[77.52031243189816, 12.952390949624496],
						[77.52035705249169, 12.952413275749594],
						[77.52040167306778, 12.952426637904258],
						[77.5204462061351, 12.952435513838518],
						[77.5204952887294, 12.952440041534262],
						[77.52054428381136, 12.952431119026635],
						[77.5205889043141, 12.95241767541687],
						[77.52063352479935, 12.952399922192104],
						[77.52066913368388, 12.952377596081547],
						[77.52067805777604, 12.952364236776708],
						[77.52071821618213, 12.952324116461886],
						[77.52074945048818, 12.952275031714592],
						[77.52077176070154, 12.952230428500359],
						[77.52077176070154, 12.952225946517183],
						[77.52078068478566, 12.952208105234442],
						[77.52079407091055, 12.952190307276641],
						[77.52080736954285, 12.95217690087938],
						[77.52082521770483, 12.952168024015377],
						[77.520843065864, 12.95215906095766],
						[77.52086091402036, 12.952150184090142],
						[77.52086983809751, 12.95215018454121],
						[77.52087876217395, 12.952150184991917],
						[77.52097237744348, 12.952145746585991],
						[77.52104823200266, 12.952136829497478],
						[77.5210616180961, 12.952132305082356],
						[77.52107045466671, 12.952123423520408],
						[77.52107937872742, 12.952114459995734],
						[77.52108830278746, 12.95210558266287],
						[77.5210927648172, 12.952096662013721],
						[77.52109722684678, 12.952083259382716],
						[77.52109722684678, 12.952069899629096],
						[77.52109722684678, 12.95206102185818],
						[77.5210927648172, 12.952052100772447],
						[77.52090999643599, 12.951454576385613],
						[77.52164570519004, 12.95050480025162],
						[77.52208271866465, 12.94976899464712],
						[77.52208271866465, 12.949760117023345],
						[77.52201132678819, 12.949599541531366],
						[77.52195787034161, 12.949443539283038],
						[77.52193109836384, 12.949238405676507],
						[77.52247064874045, 12.949381095968509],
						[77.52283180572881, 12.949421217192842],
						[77.52339366153788, 12.949447937318062],
						[77.5240179814026, 12.949412255715549],
						[77.52450844104787, 12.949336490095835],
						[77.52452182673458, 12.949372130356355],
						[77.5245485981033, 12.949474698230546],
						[77.52405805114248, 12.949635271466523],
						[77.52404029097568, 12.950544937934916],
						[77.52407589879502, 12.95118258215843],
						[77.52411605600297, 12.951574973665213],
						[77.52411605600297, 12.951610657319101],
						[77.52413836555684, 12.952074331114124],
						[77.52545820848155, 12.952136821560577],
						[77.52554744561877, 12.953010740055314],
						[77.52556083118333, 12.95301078357719],
						[77.52594874940954, 12.952943853297775],
						[77.52642144094172, 12.952854729606171],
						[77.52733103635536, 12.952698625428079],
						[77.52742027202441, 12.953162352520827],
						[77.52761641527852, 12.953849062026057],
						[77.52789295744567, 12.95465171558342],
						[77.52816941145552, 12.955467731833487],
						[77.52846817349912, 12.95639973352605],
						[77.52859747641861, 12.956769820860895],
						[77.52860193815405, 12.956827788745853],
						[77.52859747641861, 12.956872395789533],
						[77.52857516773865, 12.95690355550938],
						[77.52854393557938, 12.956939283429572],
						[77.52839232388328, 12.957072975361376],
						[77.5282050179833, 12.957242438068556],
						[77.52780372214723, 12.957532319913296],
						[77.52746042805265, 12.95773294537935],
						[77.5266398949807, 12.95813875307091],
						[77.5256187477909, 12.958624763050137],
						[77.52503459358772, 12.958865544562217],
						[77.52484736965035, 12.958910193723243],
						[77.52429444482314, 12.959003791461466],
						[77.52345612867397, 12.959115308208116],
						[77.5216010851013, 12.959324875814769],
						[77.51994680484673, 12.959543362703672],
						[77.51941616670602, 12.959605787630455],
						[77.5193581594519, 12.959641425455718],
						[77.5193403985522, 12.959668235536348],
						[77.51935378484632, 12.959695000806603],
						[77.51937600784093, 12.959739519177425],
						[77.51961678583528, 12.959940197229075],
						[77.5198887978628, 12.960123078345381],
						[77.52014742304154, 12.96028798642955],
						[77.52024558846647, 12.960363848189422],
						[77.52029012159483, 12.960417376318302],
						[77.5203168939583, 12.960466425016978],
						[77.52033920425639, 12.960528790974111],
						[77.52034366631547, 12.960577881073013],
						[77.5203615145501, 12.960733900961568],
						[77.52037490072424, 12.960818677900269],
						[77.52040604763312, 12.960921208933772],
						[77.5204952887294, 12.96118877579928],
						[77.52059336636341, 12.961581155659339],
						[77.52062013865562, 12.961657012789571],
						[77.52065137298857, 12.961710501720344],
						[77.52066913368388, 12.961741702939591],
						[77.52067359573005, 12.961817515288567],
						[77.5206869818675, 12.96221433411499],
						[77.52080736954285, 12.963168613166571],
						[77.52082521770483, 12.963373684705285],
						[77.52075837457406, 12.963409413200896],
						[77.52056659406492, 12.963449527107455],
						[77.52045513024473, 12.963476286803413],
						[77.52045513024473, 12.963587743649367],
						[77.52048190256941, 12.963730493670171],
						[77.52036597660832, 12.963766088424002],
						[77.52022327815006, 12.963329131474698],
						[77.52014742304154, 12.963329167906974],
						[77.51965248252951, 12.963458483572696],
						[77.51967925504285, 12.963552099163097],
						[77.51926454285582, 12.963619007983548],
						[77.51922000932657, 12.963480743459789],
						[77.51899699144967, 12.96352089597835],
						[77.51823449274305, 12.963645708565362],
						[77.51753892148625, 12.963659111912154],
						[77.5175478458236, 12.963445079610606],
						[77.51762361516944, 12.963030335392874],
						[77.51763700166525, 12.962999175751014],
						[77.51779755199911, 12.962517593913525],
						[77.51784208608434, 12.962223311786214],
						[77.51784208608434, 12.962183143272911],
						[77.51783762392726, 12.962125174364457],
						[77.51782423745502, 12.962071686957952],
						[77.51780647631621, 12.962018160291043],
						[77.5177797033628, 12.961964628574025],
						[77.51774846824257, 12.961915621874528],
						[77.5177083087897, 12.961875492774475],
						[77.5176993844649, 12.961866527310972],
						[77.51763253950017, 12.96183536453597],
						[77.51755668266676, 12.961813075088598],
						[77.5174764511052, 12.961795228640458],
						[77.51739621948717, 12.961786260578629],
						[77.51731143814128, 12.961781813227978],
						[77.51723120640706, 12.961786292010945],
						[77.51715097461634, 12.961799649161044],
						[77.51708404183606, 12.961813046446549],
						[77.51703950745458, 12.961813046327945],
						[77.51677641302265, 12.961830915008582],
						[77.51650885577428, 12.96185313589423],
						[77.51637507691451, 12.961866573545224],
						[77.51636615248533, 12.961866572645034],
						[77.51631706811227, 12.961866524588553],
						[77.51626360899385, 12.961862127311093],
						[77.51621452457664, 12.961848718566813],
						[77.51616990236082, 12.961826388797352],
						[77.51612536762212, 12.961799667176694],
						[77.51608966982293, 12.961763977669834],
						[77.5160807453714, 12.961755098393986],
						[77.51605843423947, 12.961634634858768],
						[77.51603621059786, 12.9614563371573],
						[77.5160183616857, 12.961282388930409],
						[77.51600943722858, 12.961104045813634],
						[77.51600497499975, 12.960925660322939],
						[77.51600943722858, 12.960845454387865],
						[77.51600497499975, 12.960814207519306],
						[77.51597820162313, 12.960680427165975],
						[77.51594250377785, 12.960546646102145],
						[77.5159024311833, 12.960417308138425],
						[77.51585334661056, 12.960288051426117],
						[77.51579533754276, 12.960163190027915],
						[77.51576865161175, 12.960105181409961],
						[77.51572849146119, 12.960024971710672],
						[77.515657183098, 12.959895588270648],
						[77.51558132494976, 12.95977081182871],
						[77.51550109199755, 12.959645910053279],
						[77.51547431850257, 12.959614704469397],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000074",
			properties: {
				AREA_SQ_KM: 1.5932904106386467,
				ASS_CONST1: "Govindaraja Nagar",
				ASS_CONST_: "166",
				LAT: 12.9558427307937,
				LON: 77.519366146173,
				LOST_AREA_SQ_KM: 0.04093030963601506,
				OBJECTID: 117,
				POP_F: 9861.0,
				POP_M: 10408.0,
				POP_SC: 1343.0,
				POP_ST: 123.0,
				POP_TOTAL: 20269.0,
				RESERVATIO: "General",
				WARD_NAME: "Nagarabhavi",
				WARD_NO: 128.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52812925567743, 12.945381256980367],
						[77.52811587041491, 12.945390177576286],
						[77.52804456980701, 12.945443705333373],
						[77.52803118453454, 12.94545262592399],
						[77.52797318166895, 12.9454837833691],
						[77.52792410229806, 12.945506149506162],
						[77.52784387804017, 12.945528390624146],
						[77.52770118895322, 12.945559595418596],
						[77.52759419389203, 12.945572958989976],
						[77.52727758240637, 12.945635411134752],
						[77.52673805480885, 12.945755789281872],
						[77.52659973866297, 12.945795909547652],
						[77.52647489529276, 12.945876241086877],
						[77.5264348264035, 12.945911884160807],
						[77.52651951347164, 12.946603074060699],
						[77.52655520800214, 12.946950849002764],
						[77.52668897490535, 12.948012104120236],
						[77.52671574576442, 12.948649743162985],
						[77.52676027636247, 12.949287468000561],
						[77.52686735969391, 12.950041043694736],
						[77.52705011851297, 12.951155808201502],
						[77.52727312061899, 12.952426643337573],
						[77.52733103635536, 12.952698625428079],
						[77.52642144094172, 12.952854729606171],
						[77.52594874940954, 12.952943853297775],
						[77.52556083118333, 12.95301078357719],
						[77.52554744561877, 12.953010740055314],
						[77.52545820848155, 12.952136821560577],
						[77.52413836555684, 12.952074331114124],
						[77.52411605600297, 12.951610657319101],
						[77.52411605600297, 12.951574973665213],
						[77.52407589879502, 12.95118258215843],
						[77.52404029097568, 12.950544937934916],
						[77.52405805114248, 12.949635271466523],
						[77.5245485981033, 12.949474698230546],
						[77.52452182673458, 12.949372130356355],
						[77.52450844104787, 12.949336490095835],
						[77.5240179814026, 12.949412255715549],
						[77.52339366153788, 12.949447937318062],
						[77.52283180572881, 12.949421217192842],
						[77.52247064874045, 12.949381095968509],
						[77.52193109836384, 12.949238405676507],
						[77.52195787034161, 12.949443539283038],
						[77.52201132678819, 12.949599541531366],
						[77.52208271866465, 12.949760117023345],
						[77.52208271866465, 12.94976899464712],
						[77.52164570519004, 12.95050480025162],
						[77.52090999643599, 12.951454576385613],
						[77.5210927648172, 12.952052100772447],
						[77.52109722684678, 12.95206102185818],
						[77.52109722684678, 12.952069899629096],
						[77.52109722684678, 12.952083259382716],
						[77.5210927648172, 12.952096662013721],
						[77.52108830278746, 12.95210558266287],
						[77.52107937872742, 12.952114459995734],
						[77.52107045466671, 12.952123423520408],
						[77.5210616180961, 12.952132305082356],
						[77.52104823200266, 12.952136829497478],
						[77.52097237744348, 12.952145746585991],
						[77.52087876217395, 12.952150184991917],
						[77.52086983809751, 12.95215018454121],
						[77.52086091402036, 12.952150184090142],
						[77.520843065864, 12.95215906095766],
						[77.52082521770483, 12.952168024015377],
						[77.52080736954285, 12.95217690087938],
						[77.52079407091055, 12.952190307276641],
						[77.52078068478566, 12.952208105234442],
						[77.52077176070154, 12.952225946517183],
						[77.52077176070154, 12.952230428500359],
						[77.52074945048818, 12.952275031714592],
						[77.52071821618213, 12.952324116461886],
						[77.52067805777604, 12.952364236776708],
						[77.52066913368388, 12.952377596081547],
						[77.52063352479935, 12.952399922192104],
						[77.5205889043141, 12.95241767541687],
						[77.52054428381136, 12.952431119026635],
						[77.5204952887294, 12.952440041534262],
						[77.5204462061351, 12.952435513838518],
						[77.52040167306778, 12.952426637904258],
						[77.52035705249169, 12.952413275749594],
						[77.52031243189816, 12.952390949624496],
						[77.52028119747226, 12.952368667318343],
						[77.51998250143754, 12.952868103598494],
						[77.5195410178149, 12.954259283053382],
						[77.51950085899382, 12.954482305475919],
						[77.51799371182749, 12.954986139438006],
						[77.51758345567255, 12.954584807347082],
						[77.51735159773403, 12.954299414699106],
						[77.5172222820449, 12.954129998053322],
						[77.51695472584332, 12.953706438186225],
						[77.51657123919148, 12.953028610842352],
						[77.51609859427377, 12.952217017961877],
						[77.51581318648984, 12.951735459255358],
						[77.51552786548625, 12.951244943476922],
						[77.51534053842852, 12.950910518044543],
						[77.51513544950784, 12.950665302678638],
						[77.51487681295379, 12.950433443370262],
						[77.51465387407293, 12.950281829064965],
						[77.51437738725711, 12.950081169587072],
						[77.51413213589058, 12.9499295109447],
						[77.51390919555337, 12.949831420213682],
						[77.51358808370492, 12.94977796751827],
						[77.51320913556174, 12.949809112120445],
						[77.51278993747609, 12.949880455312458],
						[77.51244213576557, 12.949889403271712],
						[77.51231281450679, 12.949818076262794],
						[77.51213895680527, 12.949617381866133],
						[77.51191146268376, 12.949389961066235],
						[77.51187138867226, 12.949349841663006],
						[77.5119248498504, 12.949305282925415],
						[77.51206309631597, 12.949193806559657],
						[77.51220580499162, 12.949091250875746],
						[77.51235297586062, 12.948993134104894],
						[77.51250452140832, 12.948899451997184],
						[77.51258930632953, 12.948850461146137],
						[77.51262045536627, 12.948837057224202],
						[77.51267846604968, 12.948801423278649],
						[77.51273201434661, 12.948756782396195],
						[77.51278101276621, 12.948707784469766],
						[77.5128256363086, 12.948654265596561],
						[77.51286570998474, 12.948596303509904],
						[77.51289694644302, 12.948538258797427],
						[77.51291925819372, 12.948471422333476],
						[77.51293710759114, 12.948404542466628],
						[77.51295049463735, 12.948337619200787],
						[77.51295049463735, 12.948270738058937],
						[77.51295049463735, 12.948221697658266],
						[77.51293264524205, 12.94819937390061],
						[77.51291033349398, 12.948163733934143],
						[77.51289694644302, 12.948123612928946],
						[77.51288802174153, 12.948078967462552],
						[77.51288802174153, 12.948038890581069],
						[77.51289694644302, 12.947994246475712],
						[77.51291033349398, 12.947954127520306],
						[77.51292818289278, 12.94791844752155],
						[77.51295495698575, 12.94788285437223],
						[77.5129905682696, 12.947856048693238],
						[77.5130262670393, 12.947833728948948],
						[77.51306642814184, 12.947815934325737],
						[77.51311096407562, 12.947806969924539],
						[77.51311542641773, 12.947807013342153],
						[77.51312881344302, 12.947802575698356],
						[77.51330275713195, 12.947735660026705],
						[77.51347215073089, 12.947664301022082],
						[77.51364163157464, 12.947584068856877],
						[77.51374417742488, 12.947530506707938],
						[77.51445315871068, 12.947218403758399],
						[77.51492143567584, 12.94700437627015],
						[77.5149525840754, 12.94698649025167],
						[77.51502844264208, 12.946928534434857],
						[77.51510421366288, 12.94687057436327],
						[77.51517552237203, 12.94680369369297],
						[77.51523799401431, 12.946732335008468],
						[77.51529591587054, 12.946656533226003],
						[77.51534946293802, 12.946571815125608],
						[77.51538069871577, 12.946513856610332],
						[77.515416396737, 12.946473695846041],
						[77.51564825861259, 12.94620169674863],
						[77.51587565778262, 12.945925258824794],
						[77.5159603527019, 12.945818259202484],
						[77.5159871260827, 12.945778097958602],
						[77.51605843423947, 12.945671054555074],
						[77.51613875429393, 12.945568497537701],
						[77.5162234490177, 12.945470461285153],
						[77.51631260589548, 12.945376777638236],
						[77.51640631241119, 12.945287623181459],
						[77.51650885577428, 12.945207342038056],
						[77.516531166818, 12.945189545786382],
						[77.51659801242879, 12.945153820855111],
						[77.51667378240616, 12.94510034208037],
						[77.51674963982727, 12.945042385854164],
						[77.5168164853099, 12.944979943302094],
						[77.51688341824733, 12.9449130233155],
						[77.51694133926735, 12.944841703215985],
						[77.51699042337154, 12.944765905294336],
						[77.51703950745458, 12.944685625743766],
						[77.51707511746238, 12.944600902837024],
						[77.51709742839526, 12.944547425796536],
						[77.51711081495289, 12.944502825466447],
						[77.51712420150893, 12.944444866425508],
						[77.51712420150893, 12.94439134536216],
						[77.51711973932377, 12.94433338558905],
						[77.51711081495289, 12.944275382559079],
						[77.51709296620903, 12.94422186079967],
						[77.517066193088, 12.944172820306173],
						[77.51703504526608, 12.944123826965162],
						[77.51700380994173, 12.944092583532987],
						[77.51699042337154, 12.944083662801734],
						[77.51694133926735, 12.9440613818406],
						[77.51688788044176, 12.944043543437099],
						[77.51682987190054, 12.944034620743468],
						[77.51677195082387, 12.94403018388214],
						[77.51671394222362, 12.944034619822647],
						[77.51665602108804, 12.94404354158916],
						[77.5166024746344, 12.944065861060686],
						[77.51659801242879, 12.944065817766258],
						[77.51658908801706, 12.94407029897624],
						[77.51655339036311, 12.944088137626876],
						[77.51650885577428, 12.944097016861866],
						[77.51646869588453, 12.944097058112172],
						[77.51642407376823, 12.944092574443735],
						[77.51638400134301, 12.94408365668348],
						[77.51634830362485, 12.944061333156927],
						[77.51631706811227, 12.944034571324227],
						[77.5162859200856, 12.944007813727106],
						[77.51626360899385, 12.943972175295736],
						[77.51625468455593, 12.943945457617156],
						[77.51620560013487, 12.943851815694062],
						[77.51615214096417, 12.943731417586386],
						[77.51610305649893, 12.943606490923617],
						[77.51605843423947, 12.943477212317559],
						[77.516022823914, 12.943347938516975],
						[77.51600497499975, 12.943258737081685],
						[77.51600497499975, 12.943227538427154],
						[77.51599158831222, 12.943067020017104],
						[77.51596927716287, 12.942910896818772],
						[77.51594250377785, 12.942750421370404],
						[77.51590689341614, 12.942594345366292],
						[77.51588012001649, 12.942478341094935],
						[77.51587119554856, 12.942464982258244],
						[77.51584888437561, 12.942398145984985],
						[77.51581318648984, 12.942335661436346],
						[77.51577311384983, 12.94227770558925],
						[77.51572849146119, 12.942224183794966],
						[77.51567940681352, 12.942175186456806],
						[77.51562148515217, 12.94213058832433],
						[77.51556347596637, 12.942094862846814],
						[77.51549662974882, 12.942063708917816],
						[77.51542969599696, 12.942036946098439],
						[77.51536284970096, 12.942041385773635],
						[77.51525138079035, 12.942045823412764],
						[77.5151399117707, 12.942041384141156],
						[77.51502844264208, 12.942027981785937],
						[77.51491697340442, 12.942010184054858],
						[77.51490358658913, 12.94201014033843],
						[77.51486788840728, 12.941983421964967],
						[77.51483665248901, 12.9419521792059],
						[77.51480987883797, 12.941916498249618],
						[77.51479211722939, 12.941880821953625],
						[77.51477873039941, 12.941836307916601],
						[77.51477426812241, 12.941791708132959],
						[77.51477873039941, 12.94175159028317],
						[77.51478319267625, 12.941733707579617],
						[77.5147876549529, 12.941671268438542],
						[77.5147876549529, 12.94149735197527],
						[77.51478319267625, 12.941327960144251],
						[77.51476980584522, 12.941158481981237],
						[77.51474749445671, 12.940993485152417],
						[77.51474749445671, 12.940989003681038],
						[77.51474749445671, 12.940984608391965],
						[77.51473856990008, 12.940935570372147],
						[77.51471625850544, 12.940890970160845],
						[77.51469394710644, 12.94084636997033],
						[77.51465833635466, 12.940806211810703],
						[77.51462710037896, 12.940774969468064],
						[77.51461371352961, 12.94074820938435],
						[77.5146003266787, 12.940703695837062],
						[77.51459140211054, 12.940654571776644],
						[77.51459140211054, 12.940610015766925],
						[77.5145958643947, 12.940560935425307],
						[77.51461371352961, 12.940516380227013],
						[77.51463156266173, 12.940471825039316],
						[77.51465833635466, 12.940436146961858],
						[77.5146894848261, 12.94040490320588],
						[77.51472072078471, 12.94034694735805],
						[77.51474303217847, 12.940284509702714],
						[77.51476088129034, 12.940217633531638],
						[77.51476980584522, 12.940150757018603],
						[77.51477426812241, 12.940083837264247],
						[77.51476980584522, 12.940012521925546],
						[77.51475641901264, 12.939945601516031],
						[77.51473856990008, 12.939878724057415],
						[77.51471179622598, 12.939816327704301],
						[77.51469394710644, 12.939776166511777],
						[77.51457355297214, 12.939597813923859],
						[77.51437738725711, 12.939316864486504],
						[77.51417675890413, 12.93903595879208],
						[77.51408751285955, 12.938924437568327],
						[77.51406082652872, 12.938862046074295],
						[77.51402066577647, 12.938795125377373],
						[77.5139760427018, 12.93873272900986],
						[77.51392258248545, 12.938674731737496],
						[77.51386457243508, 12.938621254547177],
						[77.5138021875365, 12.938572219705055],
						[77.5137352527897, 12.93852761874497],
						[77.51366840550006, 12.938491984749602],
						[77.51359254602836, 12.938465222695799],
						[77.51351677400278, 12.938442860039515],
						[77.51344100192682, 12.938425064906866],
						[77.51336067997147, 12.938416098777825],
						[77.51334283064037, 12.93841609801413],
						[77.51329829479717, 12.938416100326785],
						[77.51320021088466, 12.938411705136971],
						[77.51310650173333, 12.938393818734117],
						[77.51301288000197, 12.938376022712282],
						[77.51296834402987, 12.938362667102789],
						[77.51296388168201, 12.938362623814866],
						[77.51292818289278, 12.938344783024498],
						[77.51289694644302, 12.93831802283584],
						[77.51287017233643, 12.938286824600539],
						[77.51284794807324, 12.93825119255197],
						[77.51283009866188, 12.9382155133975],
						[77.5128256363086, 12.938175310424754],
						[77.51282117395515, 12.938135193646328],
						[77.5128256363086, 12.938108435101505],
						[77.51285687277787, 12.937925606763855],
						[77.51289694644302, 12.937729417040336],
						[77.51294156994004, 12.937533275094529],
						[77.51296834402987, 12.93742624178477],
						[77.51304411641996, 12.937292447759047],
						[77.51312881344302, 12.93716313545712],
						[77.51321806023809, 12.93703378451117],
						[77.51331168180099, 12.936913435224762],
						[77.51333399347051, 12.936886677574025],
						[77.51342315260831, 12.936766285047883],
						[77.513521236329, 12.936619091320855],
						[77.51361485764293, 12.936467545472047],
						[77.51370401656108, 12.936311475169685],
						[77.51371740351725, 12.936271273403287],
						[77.51376648900977, 12.936146445727426],
						[77.51382003679569, 12.936017180105102],
						[77.5138824216845, 12.935896786984825],
						[77.51392704479579, 12.93582095166216],
						[77.51431937743818, 12.935959177007634],
						[77.51497935769294, 12.93607956667446],
						[77.51575972713508, 12.93619551556829],
						[77.51665602108804, 12.936329299716782],
						[77.51759238000642, 12.936360480834649],
						[77.51833257232335, 12.936396145303794],
						[77.51908177154141, 12.936498687412358],
						[77.5198041058634, 12.936743937669211],
						[77.52037936278192, 12.936971398400317],
						[77.52064244889431, 12.937131903187568],
						[77.52107045466671, 12.937622431386375],
						[77.52161000912045, 12.938099561302517],
						[77.52211841458609, 12.938483060487117],
						[77.5226311922621, 12.938786277600698],
						[77.52295227862642, 12.93901361956233],
						[77.52349182416492, 12.939584436888747],
						[77.52419190846827, 12.940355889734205],
						[77.52447274587561, 12.940717032286905],
						[77.52495882917165, 12.941559778104573],
						[77.52528883270321, 12.942090413566048],
						[77.52593982573043, 12.943017927534015],
						[77.52658644070715, 12.943909730136768],
						[77.52692973743775, 12.944244167985111],
						[77.52702789701593, 12.944342249555204],
						[77.52748264946526, 12.944774809685956],
						[77.52797318166894, 12.945238576108826],
						[77.52810248515082, 12.945358934148175],
						[77.52812925567743, 12.945381256980367],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000075",
			properties: {
				AREA_SQ_KM: 2.0546836603477434,
				ASS_CONST1: "Govindaraja Nagar",
				ASS_CONST_: "166",
				LAT: 12.9451408764406,
				LON: 77.5199607392573,
				LOST_AREA_SQ_KM: 0.044427552424711345,
				OBJECTID: 118,
				POP_F: 10891.0,
				POP_M: 11987.0,
				POP_SC: 3499.0,
				POP_ST: 379.0,
				POP_TOTAL: 22878.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Nayandahalli",
				WARD_NO: 131.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.55449595089638, 12.975560486733148],
						[77.55440673648728, 12.975288472224841],
						[77.55427746293469, 12.974922788686932],
						[77.55409457296831, 12.97455270696858],
						[77.55396083832039, 12.974258370764622],
						[77.55388500571945, 12.974013129669101],
						[77.55381809455888, 12.973665291297308],
						[77.5537557315724, 12.973451308769604],
						[77.55366205582982, 12.973188223533336],
						[77.5535817622746, 12.972929578633515],
						[77.55347033081955, 12.972688829479305],
						[77.55337219407853, 12.972523816471876],
						[77.5532875270181, 12.972349884208453],
						[77.55322507627392, 12.972113570576251],
						[77.55314478240949, 12.971926314448089],
						[77.55311801777538, 12.971850446294308],
						[77.55306011517935, 12.97164978767734],
						[77.5530244289725, 12.971551762897507],
						[77.55293967418629, 12.97142688421016],
						[77.55277025189032, 12.9712351537964],
						[77.55256960378465, 12.97107459133244],
						[77.55241793667247, 12.970896219902375],
						[77.55223067036788, 12.970700008055635],
						[77.55200771722768, 12.970450345448532],
						[77.55181598942218, 12.970214002330017],
						[77.55166886959996, 12.970066802624403],
						[77.5515573487991, 12.969946409917718],
						[77.55162872212426, 12.969915198238274],
						[77.55203002130811, 12.96970567717334],
						[77.55242685827291, 12.969500509168602],
						[77.55296643886246, 12.969353355445444],
						[77.55329644855016, 12.96930436752391],
						[77.55369328108593, 12.969228516257985],
						[77.55407673002898, 12.969130464907602],
						[77.55424177711076, 12.969076961410499],
						[77.55423285565301, 12.969072478775297],
						[77.55443350081737, 12.969023452163967],
						[77.554558313476, 12.968938693939952],
						[77.5550086699932, 12.969063541484946],
						[77.5552049405592, 12.969125949340798],
						[77.5553832805874, 12.969183957002441],
						[77.55556162033523, 12.969237481501716],
						[77.5556552945093, 12.969268642484806],
						[77.55599421769539, 12.969353367099005],
						[77.55637765886095, 12.96942920453483],
						[77.55670765862548, 12.969469356338937],
						[77.55698412999224, 12.969482698428726],
						[77.55711786107584, 12.96946483957593],
						[77.55715800660909, 12.969991046689207],
						[77.55716692783676, 12.970303143410204],
						[77.55738094962769, 12.970245189853356],
						[77.55754590443898, 12.971061245306513],
						[77.55773324943206, 12.972024351747985],
						[77.55793388840452, 12.972876112849177],
						[77.55795619131982, 12.973188174161562],
						[77.55793388840452, 12.973660861813617],
						[77.55792942782092, 12.973785748147405],
						[77.55805869721134, 12.97400425579365],
						[77.55832624414867, 12.974454553940054],
						[77.55844221885901, 12.974744413762206],
						[77.55851804841319, 12.974994141748008],
						[77.5585760356851, 12.975141270923139],
						[77.5586562382431, 12.97532413267437],
						[77.55868746212438, 12.975569380992633],
						[77.5587053043384, 12.975765528673195],
						[77.55854481177315, 12.975765540921739],
						[77.55569544056021, 12.975605042250038],
						[77.55449595089638, 12.975560486733148],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000076",
			properties: {
				AREA_SQ_KM: 0.35426353331010946,
				ASS_CONST1: "Vijaya Nagar",
				ASS_CONST_: "167",
				LAT: 12.9723126235624,
				LON: 77.5554793838085,
				LOST_AREA_SQ_KM: 7.436319702148437e-4,
				OBJECTID: 119,
				POP_F: 16381.0,
				POP_M: 17355.0,
				POP_SC: 894.0,
				POP_ST: 914.0,
				POP_TOTAL: 33736.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Kempapura Agrahara",
				WARD_NO: 122.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5544914901776, 12.975560443765549],
						[77.55435320780815, 12.97555600787969],
						[77.55434874708374, 12.975547084462642],
						[77.55399206349429, 12.975547103707262],
						[77.5534301839812, 12.975524797600961],
						[77.55293521340631, 12.97537760247325],
						[77.55251161344216, 12.975119012595943],
						[77.55138792443934, 12.974231631259626],
						[77.55117835219164, 12.974093392419157],
						[77.55111590028673, 12.974026490116907],
						[77.55104461410676, 12.973968558723715],
						[77.55096877955697, 12.973910580120526],
						[77.55089294495649, 12.973861567987335],
						[77.55080827604627, 12.973821440495318],
						[77.5507235196049, 12.973785705780054],
						[77.55063430222982, 12.973754497469285],
						[77.55054517225274, 12.973732259793502],
						[77.55045149385988, 12.973718855051793],
						[77.55035790285795, 12.973709937711211],
						[77.55026868519548, 12.97370993933578],
						[77.5501750065744, 12.973714381099741],
						[77.55008141534451, 12.973727793454424],
						[77.54999219746473, 12.973750038548467],
						[77.54990306698342, 12.973776771026754],
						[77.54981384896347, 12.97381246564295],
						[77.54973364015427, 12.97385264750849],
						[77.5496533438196, 12.97390170530819],
						[77.54957750834026, 12.973955203110215],
						[77.54950622119411, 12.97401314514943],
						[77.54946161203694, 12.97405323597255],
						[77.54857869699369, 12.974833600178256],
						[77.5483825024371, 12.975043196530974],
						[77.5483646585997, 12.97506104333407],
						[77.54803472204217, 12.974597298791464],
						[77.54801241717666, 12.97457501218286],
						[77.5479187366937, 12.974441249330463],
						[77.54789643180541, 12.974409996356792],
						[77.54789197082722, 12.974401073086295],
						[77.54784290005563, 12.974356456879699],
						[77.54778499476846, 12.974316328156435],
						[77.54772254099665, 12.97428072154123],
						[77.54766008719048, 12.974249511908347],
						[77.5475932598304, 12.974231626767512],
						[77.54752188396785, 12.974213780515345],
						[77.54745496905595, 12.974209340686185],
						[77.547383680577, 12.974209345157325],
						[77.54731230458269, 12.974218225534138],
						[77.54727661656872, 12.974227192005083],
						[77.54624655768431, 12.974204840772211],
						[77.54505151672399, 12.97416916777167],
						[77.5442577898454, 12.974164780528149],
						[77.54346851857247, 12.974066632750777],
						[77.5422066178546, 12.97380356011374],
						[77.54147086710945, 12.973651955748306],
						[77.53959363580142, 12.973255073429693],
						[77.53869734751223, 12.97294293572523],
						[77.5386438114274, 12.97290732847066],
						[77.53821132291569, 12.972612956931052],
						[77.53795265131026, 12.972327592672258],
						[77.53787234664748, 12.972184910419935],
						[77.53787680801912, 12.97218047069909],
						[77.53784120451898, 12.972086848479934],
						[77.5393885031011, 12.971578512725555],
						[77.54073511160011, 12.971128141144225],
						[77.54231368664215, 12.9705930159517],
						[77.54313856779025, 12.970316564235437],
						[77.54485085497551, 12.969741324435901],
						[77.54625994081226, 12.969286517777014],
						[77.54726769456347, 12.968992155435366],
						[77.54742820308014, 12.969589680213817],
						[77.54868567230459, 12.969531780832199],
						[77.54840926818792, 12.968702353292333],
						[77.54929664547204, 12.968323370180945],
						[77.54943038561649, 12.968247495944519],
						[77.54963550018196, 12.968394648142336],
						[77.5497647790226, 12.968586417422815],
						[77.54988968428489, 12.968782631361426],
						[77.54996989298382, 12.968898579562975],
						[77.55009033712864, 12.969072422253724],
						[77.5501883892393, 12.969273116846653],
						[77.55029991138532, 12.969420234663367],
						[77.55042026771173, 12.969487170594569],
						[77.55062984135924, 12.969585226416331],
						[77.55090186667682, 12.96968784945419],
						[77.55115158709374, 12.96972792575347],
						[77.55133439433607, 12.969808222514258],
						[77.5515573487991, 12.969946409917718],
						[77.55166886959996, 12.970066802624403],
						[77.55181598942218, 12.970214002330017],
						[77.55200771722768, 12.970450345448532],
						[77.55223067036788, 12.970700008055635],
						[77.55241793667247, 12.970896219902375],
						[77.55256960378465, 12.97107459133244],
						[77.55277025189032, 12.9712351537964],
						[77.55293967418629, 12.97142688421016],
						[77.5530244289725, 12.971551762897507],
						[77.55306011517935, 12.97164978767734],
						[77.55311801777538, 12.971850446294308],
						[77.55314478240949, 12.971926314448089],
						[77.55322507627392, 12.972113570576251],
						[77.5532875270181, 12.972349884208453],
						[77.55337219407853, 12.972523816471876],
						[77.55347033081955, 12.972688829479305],
						[77.5535817622746, 12.972929578633515],
						[77.55366205582982, 12.973188223533336],
						[77.5537557315724, 12.973451308769604],
						[77.55381809455888, 12.973665291297308],
						[77.55388500571945, 12.974013129669101],
						[77.55396083832039, 12.974258370764622],
						[77.55409457296831, 12.97455270696858],
						[77.55427746293469, 12.974922788686932],
						[77.55440673648728, 12.975288472224841],
						[77.55449595089638, 12.975560486733148],
						[77.5544914901776, 12.975560443765549],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000077",
			properties: {
				AREA_SQ_KM: 0.7312532958756857,
				ASS_CONST1: "Vijaya Nagar",
				ASS_CONST_: "167",
				LAT: 12.9720925542614,
				LON: 77.5470438346722,
				LOST_AREA_SQ_KM: 0.007573540161132812,
				OBJECTID: 120,
				POP_F: 17068.0,
				POP_M: 19009.0,
				POP_SC: 1297.0,
				POP_ST: 258.0,
				POP_TOTAL: 36077.0,
				RESERVATIO: "General",
				WARD_NAME: "Vijayanagar",
				WARD_NO: 123.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53665954689046, 12.968791518785434],
						[77.5387909481228, 12.968171761784584],
						[77.53855904590823, 12.96682950520849],
						[77.53828707850609, 12.965451709256124],
						[77.53818901612136, 12.965063698518009],
						[77.538813254799, 12.964863085943135],
						[77.53941527097126, 12.964680276843984],
						[77.54010196091016, 12.964461729104523],
						[77.54050767462093, 12.964363656687546],
						[77.54113198803853, 12.964256660020833],
						[77.54172060826627, 12.9642120748951],
						[77.54241620666114, 12.964212051382155],
						[77.54294682500783, 12.964296730209997],
						[77.54339276643628, 12.964332405010246],
						[77.54353989698251, 12.96435476918327],
						[77.5438164004245, 12.964024760654274],
						[77.54387430773522, 12.96406488461315],
						[77.54395460815651, 12.964118417557641],
						[77.54421326609932, 12.964238753289592],
						[77.5444629137547, 12.964323481444854],
						[77.54473049274529, 12.964381489114123],
						[77.54497130455059, 12.964385962437895],
						[77.54531463324845, 12.964363661473469],
						[77.54563565557608, 12.964345800177963],
						[77.54596568657617, 12.964345787315821],
						[77.54615742426245, 12.964359183120834],
						[77.54637146681722, 12.964403742202295],
						[77.54663003178467, 12.964475106225816],
						[77.54702242661527, 12.964608880537588],
						[77.54751296198188, 12.96476497935124],
						[77.5480258000965, 12.964938879021025],
						[77.54825759551645, 12.965041450663714],
						[77.54846717315671, 12.965161829430569],
						[77.5486812113574, 12.965317855503292],
						[77.54899785070177, 12.96554978514163],
						[77.54941700286223, 12.965857442145245],
						[77.54975585721286, 12.966133895100006],
						[77.55000558015116, 12.966356906235825],
						[77.55005464998794, 12.966414794376748],
						[77.55023745899705, 12.966615523406992],
						[77.55028206784931, 12.9666913005298],
						[77.55030437226888, 12.966731430584344],
						[77.5506744500573, 12.967163969199104],
						[77.55099554469797, 12.967538538914136],
						[77.55117389134243, 12.967716898609766],
						[77.55143699367848, 12.967935386642603],
						[77.55169563457586, 12.96808701805816],
						[77.55190520586832, 12.968194037013301],
						[77.55227081763061, 12.968314450992255],
						[77.55257406457899, 12.968403576729466],
						[77.55285946758623, 12.968488306265694],
						[77.5534747915785, 12.96864887857999],
						[77.55425069856778, 12.968853927721028],
						[77.554558313476, 12.968938693939952],
						[77.55443350081737, 12.969023452163967],
						[77.55423285565301, 12.969072478775297],
						[77.55424177711076, 12.969076961410499],
						[77.55407673002898, 12.969130464907602],
						[77.55369328108593, 12.969228516257985],
						[77.55329644855016, 12.96930436752391],
						[77.55296643886246, 12.969353355445444],
						[77.55242685827291, 12.969500509168602],
						[77.55203002130811, 12.96970567717334],
						[77.55162872212426, 12.969915198238274],
						[77.5515573487991, 12.969946409917718],
						[77.55133439433607, 12.969808222514258],
						[77.55115158709374, 12.96972792575347],
						[77.55090186667682, 12.96968784945419],
						[77.55062984135924, 12.969585226416331],
						[77.55042026771173, 12.969487170594569],
						[77.55029991138532, 12.969420234663367],
						[77.5501883892393, 12.969273116846653],
						[77.55009033712864, 12.969072422253724],
						[77.54996989298382, 12.968898579562975],
						[77.54988968428489, 12.968782631361426],
						[77.5497647790226, 12.968586417422815],
						[77.54963550018196, 12.968394648142336],
						[77.54943038561649, 12.968247495944519],
						[77.54929664547204, 12.968323370180945],
						[77.54840926818792, 12.968702353292333],
						[77.54868567230459, 12.969531780832199],
						[77.54742820308014, 12.969589680213817],
						[77.54726769456347, 12.968992155435366],
						[77.54625994081226, 12.969286517777014],
						[77.54485085497551, 12.969741324435901],
						[77.54313856779025, 12.970316564235437],
						[77.54231368664215, 12.9705930159517],
						[77.54073511160011, 12.971128141144225],
						[77.5393885031011, 12.971578512725555],
						[77.53784120451898, 12.972086848479934],
						[77.53745770075396, 12.971034471318553],
						[77.53665954689046, 12.968791518785434],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000078",
			properties: {
				AREA_SQ_KM: 0.8835538302220373,
				ASS_CONST1: "Vijaya Nagar",
				ASS_CONST_: "167",
				LAT: 12.9676691462426,
				LON: 77.5438119924571,
				LOST_AREA_SQ_KM: 0.02359895186617983,
				OBJECTID: 121,
				POP_F: 15441.0,
				POP_M: 16662.0,
				POP_SC: 3613.0,
				POP_ST: 403.0,
				POP_TOTAL: 32103.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Hosahalli",
				WARD_NO: 124.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.52321982074207, 12.9621786785863],
						[77.52323758103685, 12.96230797558243],
						[77.52310827202925, 12.962272326921232],
						[77.52305481609756, 12.96225448394015],
						[77.5230325063314, 12.962250043047701],
						[77.52290319709093, 12.96220094714901],
						[77.52261334438313, 12.962098399935847],
						[77.52223433876362, 12.961884394271936],
						[77.5221273385647, 12.961826464543499],
						[77.52207379468253, 12.961795170186262],
						[77.52203363675439, 12.961786245557525],
						[77.52201578878177, 12.961786244223191],
						[77.52160554711097, 12.96178631202705],
						[77.52102145981107, 12.96181753475068],
						[77.52086983809751, 12.961817526877873],
						[77.52080290750192, 12.961817478404361],
						[77.52077176070155, 12.961813040872679],
						[77.52067359573005, 12.961817515288567],
						[77.52066913368388, 12.961741702939591],
						[77.52065137298857, 12.961710501720344],
						[77.52062013865562, 12.961657012789571],
						[77.52059336636341, 12.961581155659339],
						[77.5204952887294, 12.96118877579928],
						[77.52040604763312, 12.960921208933772],
						[77.52037490072424, 12.960818677900269],
						[77.5203615145501, 12.960733900961568],
						[77.52034366631547, 12.960577881073013],
						[77.52033920425639, 12.960528790974111],
						[77.5203168939583, 12.960466425016978],
						[77.52029012159483, 12.960417376318302],
						[77.52024558846647, 12.960363848189422],
						[77.52014742304154, 12.96028798642955],
						[77.5198887978628, 12.960123078345381],
						[77.51961678583528, 12.959940197229075],
						[77.51937600784093, 12.959739519177425],
						[77.51935378484632, 12.959695000806603],
						[77.5193403985522, 12.959668235536348],
						[77.5193581594519, 12.959641425455718],
						[77.51941616670602, 12.959605787630455],
						[77.51994680484673, 12.959543362703672],
						[77.5216010851013, 12.959324875814769],
						[77.52345612867397, 12.959115308208116],
						[77.52429444482314, 12.959003791461466],
						[77.52484736965035, 12.958910193723243],
						[77.52503459358772, 12.958865544562217],
						[77.5256187477909, 12.958624763050137],
						[77.5266398949807, 12.95813875307091],
						[77.52746042805265, 12.95773294537935],
						[77.52780372214723, 12.957532319913296],
						[77.5282050179833, 12.957242438068556],
						[77.52839232388328, 12.957072975361376],
						[77.52854393557938, 12.956939283429572],
						[77.52857516773865, 12.95690355550938],
						[77.52859747641861, 12.956872395789533],
						[77.52860193815405, 12.956827788745853],
						[77.52859747641861, 12.956769820860895],
						[77.52846817349912, 12.95639973352605],
						[77.52816941145552, 12.955467731833487],
						[77.52789295744567, 12.95465171558342],
						[77.52761641527852, 12.953849062026057],
						[77.52742027202441, 12.953162352520827],
						[77.52733103635536, 12.952698625428079],
						[77.52727312061899, 12.952426643337573],
						[77.52705011851297, 12.951155808201502],
						[77.52686735969391, 12.950041043694736],
						[77.52676027636247, 12.949287468000561],
						[77.52671574576442, 12.948649743162985],
						[77.52668897490535, 12.948012104120236],
						[77.52655520800214, 12.946950849002764],
						[77.52651951347164, 12.946603074060699],
						[77.5264348264035, 12.945911884160807],
						[77.52660420047675, 12.946290935995897],
						[77.52694758464007, 12.946982040047567],
						[77.52716166336366, 12.947325417943643],
						[77.52770565072383, 12.94813697404575],
						[77.52833887043275, 12.949064465438799],
						[77.5291414569158, 12.950219420936284],
						[77.52963644366432, 12.95090158635719],
						[77.5299441252222, 12.951222693797705],
						[77.53058625579051, 12.951606156944024],
						[77.53135322089011, 12.952020819488792],
						[77.53198633281794, 12.952261627338679],
						[77.53294058689065, 12.95253811454537],
						[77.53378784372099, 12.952738752478282],
						[77.53459047924319, 12.953073165457953],
						[77.53537972469255, 12.95360828744389],
						[77.53587022284512, 12.954049770418791],
						[77.53583908016817, 12.954080935349596],
						[77.53560271047134, 12.95431285623062],
						[77.53561163339258, 12.95432173447493],
						[77.53525042948205, 12.954673992278364],
						[77.53494722376361, 12.954954920839953],
						[77.53480008209358, 12.955164515648468],
						[77.5346974677247, 12.955579257536511],
						[77.53440318354794, 12.956685106286988],
						[77.53432296385874, 12.957006115173755],
						[77.5340376017576, 12.957643824185482],
						[77.53345340329143, 12.958892306149204],
						[77.53314573122474, 12.95976633973443],
						[77.53307889532881, 12.96001154108591],
						[77.53305658752758, 12.960345957668213],
						[77.53322603922574, 12.960894456420217],
						[77.53410443709014, 12.963048177625415],
						[77.53466186322893, 12.964292317071878],
						[77.53466632472656, 12.964296756713383],
						[77.53468863221205, 12.964345850737951],
						[77.53453694122446, 12.964412655451552],
						[77.53431404083638, 12.964501879773994],
						[77.5340376017576, 12.964568775325006],
						[77.53388153585435, 12.964591058937405],
						[77.53383245903221, 12.96459997966893],
						[77.53352032644851, 12.964662390749451],
						[77.53328395193202, 12.964698119254976],
						[77.53306104908818, 12.964720361151405],
						[77.53299858722391, 12.964729324319945],
						[77.53294058689065, 12.9647247966361],
						[77.53279790419536, 12.964711434320089],
						[77.53256161565949, 12.964671306435838],
						[77.53254376933769, 12.964666909388574],
						[77.53246792243883, 12.964662380965722],
						[77.53236093185998, 12.964649019691377],
						[77.53170096485441, 12.964644529071256],
						[77.53121937198658, 12.964640125919404],
						[77.53113915005193, 12.964640126806025],
						[77.53055502418323, 12.964653523179193],
						[77.53053717754663, 12.964657918771328],
						[77.53047025263453, 12.964671320444873],
						[77.53034541351025, 12.96469804215182],
						[77.53025626761868, 12.9647248516253],
						[77.5301848808681, 12.964756054041311],
						[77.52988166164654, 12.964831821141274],
						[77.5296453670536, 12.964885393618065],
						[77.52947591002192, 12.964925474071883],
						[77.52938667596472, 12.964929952151858],
						[77.52842355608416, 12.964818469932487],
						[77.5278483398052, 12.9647694481274],
						[77.52742473380603, 12.9647159472296],
						[77.52646150983568, 12.964599971103317],
						[77.52649720438438, 12.964421620375019],
						[77.52652397528855, 12.964283353404356],
						[77.52652397528855, 12.964274474563403],
						[77.52664435679291, 12.963953417463252],
						[77.52672020757366, 12.96374830417805],
						[77.5267291311916, 12.963654689367171],
						[77.52676027636247, 12.963569907394188],
						[77.52678258539817, 12.963538746721124],
						[77.52678258539817, 12.963306863932369],
						[77.52674689093895, 12.963115063276938],
						[77.52666220403998, 12.962847536517332],
						[77.52663543316832, 12.962664702138024],
						[77.52669343671565, 12.962441746204284],
						[77.52678258539817, 12.9622054700293],
						[77.52678704720476, 12.962058282345302],
						[77.52677812359136, 12.961960185419416],
						[77.52630989536566, 12.961995902667237],
						[77.52631881901576, 12.962232179279892],
						[77.52400905757214, 12.961683704205656],
						[77.52367905033127, 12.96172834228075],
						[77.52340704735565, 12.961755097327238],
						[77.52331343408734, 12.961830907744728],
						[77.5232420429824, 12.961964684784853],
						[77.52322865714524, 12.962062736134264],
						[77.52321982074207, 12.9621786785863],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000079",
			properties: {
				AREA_SQ_KM: 1.388288234971278,
				ASS_CONST1: "Vijaya Nagar",
				ASS_CONST_: "167",
				LAT: 12.9581117077057,
				LON: 77.5293089282806,
				LOST_AREA_SQ_KM: 0.03800949966200286,
				OBJECTID: 122,
				POP_F: 12817.0,
				POP_M: 13989.0,
				POP_SC: 1318.0,
				POP_ST: 237.0,
				POP_TOTAL: 26806.0,
				RESERVATIO: "General",
				WARD_NAME: "Attiguppe",
				WARD_NO: 132.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53617788754063, 12.967525121095173],
						[77.5361556678265, 12.967467150340175],
						[77.53546003111599, 12.966084825358429],
						[77.53472423669942, 12.964417138573332],
						[77.53468863221205, 12.964345850737951],
						[77.53466632472656, 12.964296756713383],
						[77.53466186322893, 12.964292317071878],
						[77.53410443709014, 12.963048177625415],
						[77.53322603922574, 12.960894456420217],
						[77.53305658752758, 12.960345957668213],
						[77.53307889532881, 12.96001154108591],
						[77.53314573122474, 12.95976633973443],
						[77.53345340329143, 12.958892306149204],
						[77.5340376017576, 12.957643824185482],
						[77.53432296385874, 12.957006115173755],
						[77.53440318354794, 12.956685106286988],
						[77.5346974677247, 12.955579257536511],
						[77.53480008209358, 12.955164515648468],
						[77.53494722376361, 12.954954920839953],
						[77.53525042948205, 12.954673992278364],
						[77.53561163339258, 12.95432173447493],
						[77.53560271047134, 12.95431285623062],
						[77.53583908016817, 12.954080935349596],
						[77.53587022284512, 12.954049770418791],
						[77.53600844024096, 12.953915943163533],
						[77.53625819340034, 12.953643983286753],
						[77.53656585704601, 12.953354132234315],
						[77.53662385553025, 12.953273838737989],
						[77.53669515076069, 12.953166862844533],
						[77.53675314917896, 12.953055366128442],
						[77.53681560898094, 12.952859181105573],
						[77.53690921114097, 12.952520293113636],
						[77.53715450092179, 12.951837996858593],
						[77.53739077991422, 12.9510755374319],
						[77.53752462155431, 12.95072325288783],
						[77.53755576376955, 12.950660799243078],
						[77.53757360930477, 12.950616235757131],
						[77.53759591621984, 12.950589471456217],
						[77.53761822313056, 12.950567189306291],
						[77.53765391417858, 12.95055382794318],
						[77.53770290187332, 12.95055826124394],
						[77.53773413151768, 12.950562699319157],
						[77.53779212940591, 12.950589461118101],
						[77.53793034446518, 12.950678707756984],
						[77.53811317298793, 12.950781227111205],
						[77.53817117068277, 12.950794628622528],
						[77.53840744756202, 12.950861457011325],
						[77.53855904590823, 12.950883772323985],
						[77.53871965420679, 12.95093281215298],
						[77.53885786813824, 12.950972927229325],
						[77.53902731119071, 12.951044287166399],
						[77.53945533528805, 12.951302933337493],
						[77.53953563883243, 12.951351976029137],
						[77.54007519320173, 12.951775641927483],
						[77.5406816639509, 12.952154613514915],
						[77.54105168562218, 12.952386499726314],
						[77.54120782915741, 12.952493507501648],
						[77.54129250522573, 12.95258267119762],
						[77.54140849734085, 12.952712049153211],
						[77.54168491849535, 12.953028634167808],
						[77.54094024186973, 12.95322034220126],
						[77.54078418544243, 12.95328719885433],
						[77.54075295663614, 12.953349650499415],
						[77.5407574178947, 12.953568205768683],
						[77.54077526292723, 12.954000662511875],
						[77.53996374849164, 12.954089839505857],
						[77.53883110013678, 12.954205833360774],
						[77.53897377526131, 12.955436570356014],
						[77.5390496178205, 12.956462127641768],
						[77.53922798322449, 12.957572466804873],
						[77.53927697024085, 12.958045058512369],
						[77.53978092294562, 12.958615910893442],
						[77.54023125060267, 12.95911978420111],
						[77.54070834404081, 12.959761842624753],
						[77.54137280747189, 12.960702769721266],
						[77.54189450826424, 12.961447421166481],
						[77.54258118322103, 12.962423920015814],
						[77.5430092814059, 12.96301699787841],
						[77.54334369372064, 12.963489694059911],
						[77.54352205238422, 12.96371709892176],
						[77.54374056104695, 12.963948943249601],
						[77.5438164004245, 12.964024760654274],
						[77.54353989698251, 12.96435476918327],
						[77.54339276643628, 12.964332405010246],
						[77.54294682500783, 12.964296730209997],
						[77.54241620666114, 12.964212051382155],
						[77.54172060826627, 12.9642120748951],
						[77.54113198803853, 12.964256660020833],
						[77.54050767462093, 12.964363656687546],
						[77.54010196091016, 12.964461729104523],
						[77.53941527097126, 12.964680276843984],
						[77.538813254799, 12.964863085943135],
						[77.53818901612136, 12.965063698518009],
						[77.53828707850609, 12.965451709256124],
						[77.53855904590823, 12.96682950520849],
						[77.5387909481228, 12.968171761784584],
						[77.53665954689046, 12.968791518785434],
						[77.53665062405146, 12.968764793949234],
						[77.53617788754063, 12.967525121095173],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000007a",
			properties: {
				AREA_SQ_KM: 1.1120781982112296,
				ASS_CONST1: "Vijaya Nagar",
				ASS_CONST_: "167",
				LAT: 12.959949194647,
				LON: 77.5376145866468,
				LOST_AREA_SQ_KM: 0.028940541640786563,
				OBJECTID: 123,
				POP_F: 14639.0,
				POP_M: 15716.0,
				POP_SC: 3502.0,
				POP_ST: 414.0,
				POP_TOTAL: 30355.0,
				RESERVATIO: "General",
				WARD_NAME: "Hampi Nagar",
				WARD_NO: 133.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5438164004245, 12.964024760654274],
						[77.54374056104695, 12.963948943249601],
						[77.54352205238422, 12.96371709892176],
						[77.54334369372064, 12.963489694059911],
						[77.5430092814059, 12.96301699787841],
						[77.54258118322103, 12.962423920015814],
						[77.54189450826424, 12.961447421166481],
						[77.54137280747189, 12.960702769721266],
						[77.54070834404081, 12.959761842624753],
						[77.54023125060267, 12.95911978420111],
						[77.53978092294562, 12.958615910893442],
						[77.53927697024085, 12.958045058512369],
						[77.53922798322449, 12.957572466804873],
						[77.5390496178205, 12.956462127641768],
						[77.53897377526131, 12.955436570356014],
						[77.53883110013678, 12.954205833360774],
						[77.53996374849164, 12.954089839505857],
						[77.54077526292723, 12.954000662511875],
						[77.5407574178947, 12.953568205768683],
						[77.54075295663614, 12.953349650499415],
						[77.54078418544243, 12.95328719885433],
						[77.54094024186973, 12.95322034220126],
						[77.54168491849535, 12.953028634167808],
						[77.54193019796934, 12.953296135789834],
						[77.54201487349931, 12.9533764217815],
						[77.54213532608148, 12.953461106662544],
						[77.54233153143026, 12.95356367032599],
						[77.54247420215992, 12.953639514752785],
						[77.54259456678086, 12.953684113111875],
						[77.54287107252377, 12.953742108381306],
						[77.54312518429451, 12.953822296490683],
						[77.54327677634694, 12.953875814871868],
						[77.54343291682422, 12.953933776492278],
						[77.54358004731846, 12.954009577544538],
						[77.54378963359116, 12.954143387828054],
						[77.54403490852106, 12.954344087420436],
						[77.54438270116562, 12.954696360733394],
						[77.54476172045827, 12.955017386110775],
						[77.54527448352566, 12.955405293192426],
						[77.54551083310584, 12.955570308389202],
						[77.54587646546216, 12.955788800626866],
						[77.54633577850755, 12.956056379863846],
						[77.54664787589466, 12.956203500605508],
						[77.54691544973727, 12.956292700411856],
						[77.54721862352207, 12.956364050038975],
						[77.54744158606883, 12.956408599450208],
						[77.54778499476846, 12.956471028554425],
						[77.54798565133228, 12.956511139501577],
						[77.54801687815012, 12.9565200595621],
						[77.54840926818792, 12.95660924666973],
						[77.5483646585997, 12.956693986482689],
						[77.54815508077021, 12.95706859693175],
						[77.54778499476848, 12.95774191476617],
						[77.54740598556603, 12.958415234768921],
						[77.54705811470915, 12.95909299019723],
						[77.54680838528749, 12.959570145738937],
						[77.54648290524143, 12.960073989951884],
						[77.5463045512274, 12.96042183721906],
						[77.54618419053779, 12.960653694040653],
						[77.54592107602792, 12.961135292506654],
						[77.54574272113152, 12.961411763264577],
						[77.54558221023245, 12.961652544574802],
						[77.54531909432788, 12.962080610975514],
						[77.54518534934087, 12.962254496213712],
						[77.54525663919986, 12.962299058260333],
						[77.54535924403493, 12.96234366670315],
						[77.54544391700888, 12.962397150677326],
						[77.54550191096185, 12.962464087851625],
						[77.54555544381522, 12.962522016674237],
						[77.54557328809408, 12.962588912060024],
						[77.54556882702462, 12.962642403107175],
						[77.54555544381522, 12.96268701520774],
						[77.54551529417755, 12.962722662674116],
						[77.5454260727097, 12.962785164815937],
						[77.54527002244447, 12.962874267674996],
						[77.54510951087734, 12.962936775893603],
						[77.54497130455059, 12.9629946713414],
						[77.54484639387768, 12.963052691422991],
						[77.54473495384767, 12.963119551450626],
						[77.54463680955485, 12.96321317392806],
						[77.54456998041567, 12.963280118991971],
						[77.54448968042941, 12.963364775548897],
						[77.54441392897422, 12.963311290465814],
						[77.5438164004245, 12.964024760654274],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000007b",
			properties: {
				AREA_SQ_KM: 0.6838712149574153,
				ASS_CONST1: "Vijaya Nagar",
				ASS_CONST_: "167",
				LAT: 12.9579760999735,
				LON: 77.5432214136966,
				LOST_AREA_SQ_KM: 0.007716887110721364,
				OBJECTID: 124,
				POP_F: 17238.0,
				POP_M: 18996.0,
				POP_SC: 1564.0,
				POP_ST: 312.0,
				POP_TOTAL: 36234.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Bapuji Nagar",
				WARD_NO: 134.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53840744756202, 12.950861457011325],
						[77.53835846017091, 12.950495864719285],
						[77.53831830798995, 12.950228272965617],
						[77.53826923308287, 12.95009454301109],
						[77.53822015815457, 12.949996411746755],
						[77.53817563204267, 12.949951854768097],
						[77.53809978890033, 12.949911733720482],
						[77.53805071389888, 12.949858165354094],
						[77.53798388088602, 12.949822530371186],
						[77.53782782039933, 12.949746731745094],
						[77.53765391417858, 12.949661969498507],
						[77.53746662352962, 12.949537170397958],
						[77.53739077991422, 12.949470242985246],
						[77.53734625347744, 12.94938551968515],
						[77.53731948512002, 12.949260711346044],
						[77.53733286929952, 12.949033287785209],
						[77.53737293435029, 12.948801377404015],
						[77.53747554630456, 12.948587311476693],
						[77.53757360930477, 12.948493697302455],
						[77.53773413151768, 12.948400080736114],
						[77.53780551352976, 12.948284147582804],
						[77.5378812693906, 12.94816377110643],
						[77.53793926720374, 12.948083479566058],
						[77.53796603541522, 12.94805227646032],
						[77.53798834225329, 12.94799431047502],
						[77.53798834225329, 12.947958626405104],
						[77.53798834225329, 12.947914064429643],
						[77.53796603541521, 12.947856014126492],
						[77.53788573076189, 12.947633121748154],
						[77.53772520876304, 12.94732990562902],
						[77.53761822313056, 12.947097969557586],
						[77.53756468653751, 12.946950841012624],
						[77.53752462155431, 12.94679927743778],
						[77.53752016016885, 12.946727867228532],
						[77.5375423796163, 12.946545091284323],
						[77.53754684100089, 12.946478162839288],
						[77.53755130238531, 12.946455881968085],
						[77.53846990645506, 12.94642022181913],
						[77.53872857688339, 12.946402363329389],
						[77.5389380846277, 12.946402387674581],
						[77.53906300179628, 12.946442547164695],
						[77.53925028981492, 12.946509418963526],
						[77.53959363580142, 12.946620883198092],
						[77.53982990972365, 12.94669666901674],
						[77.53989682912734, 12.946705586444413],
						[77.54010642219428, 12.94670561408617],
						[77.54052998096017, 12.946625375412772],
						[77.54127019903616, 12.946455859768303],
						[77.54168491849535, 12.946344421244032],
						[77.54197918382081, 12.946268630390733],
						[77.54255887728446, 12.946116965859481],
						[77.54314749011988, 12.945965385018198],
						[77.54356666387471, 12.945827178795621],
						[77.5442043438534, 12.945671065758194],
						[77.54534139972245, 12.94531880746649],
						[77.54553313846272, 12.94580039424213],
						[77.54580954958064, 12.945751318754546],
						[77.5459968264779, 12.945983246865605],
						[77.54679508969518, 12.94594312910421],
						[77.54696452090968, 12.94593862797853],
						[77.54694221583395, 12.945724566662212],
						[77.54773146296615, 12.945581956431894],
						[77.54817738560769, 12.9455195016726],
						[77.54883734440416, 12.945425818446846],
						[77.54879719592694, 12.945100309742905],
						[77.54947499478594, 12.945011190435224],
						[77.54947053386978, 12.945042350117392],
						[77.54947053386978, 12.945216290297276],
						[77.54947945570191, 12.945506075346827],
						[77.54950176027917, 12.945653250518664],
						[77.54956412560335, 12.94595647355831],
						[77.54957304742814, 12.946067922825813],
						[77.54956858651583, 12.946152696136748],
						[77.54957304742814, 12.946250742684397],
						[77.54961765654151, 12.946603018600484],
						[77.54965780472858, 12.946852766315859],
						[77.5497068747157, 12.947365583789072],
						[77.54964442200114, 12.947374467961465],
						[77.54951514302344, 12.947405730562268],
						[77.54955520377789, 12.947477049771408],
						[77.5495908910756, 12.947534970173672],
						[77.54964442200114, 12.947575132503141],
						[77.54966226563737, 12.947601851628614],
						[77.5496533438196, 12.947650898126547],
						[77.54961765654151, 12.947691068957276],
						[77.54957304742813, 12.947771321903963],
						[77.54957304742814, 12.947820367613385],
						[77.54957304742814, 12.947891738207812],
						[77.54963996109164, 12.948007623063875],
						[77.54973364015427, 12.9481280739748],
						[77.54980938806061, 12.94824404026404],
						[77.54984953617985, 12.948337603083829],
						[77.54984953617985, 12.948440177316394],
						[77.54981830986611, 12.94851599039294],
						[77.54980492715761, 12.94861412686536],
						[77.54980046625441, 12.94873001918371],
						[77.54980046625441, 12.948823715437905],
						[77.54984953617985, 12.948917364167976],
						[77.54988968428489, 12.94901540987077],
						[77.54992537147746, 12.949104620848924],
						[77.54994321506953, 12.94920719414572],
						[77.5499654320871, 12.949287394658198],
						[77.55000558015116, 12.949358805671345],
						[77.55000558015116, 12.949434573374928],
						[77.5499877365689, 12.949595075109034],
						[77.54998327567289, 12.949719933026303],
						[77.54999665836039, 12.949809103531264],
						[77.55001004104629, 12.949898360308396],
						[77.55003234551931, 12.949991926265374],
						[77.55006803266701, 12.950156992489768],
						[77.5500769544522, 12.950228277621537],
						[77.5500769544522, 12.950295167478071],
						[77.55008141534451, 12.950348653136437],
						[77.55005464998794, 12.950442353122291],
						[77.5500412673073, 12.950848133931817],
						[77.54994321506953, 12.950915036828622],
						[77.54976923992717, 12.951004138103594],
						[77.54955074286487, 12.951133501993123],
						[77.54943038561649, 12.951173641777391],
						[77.5494125419438, 12.951178125593717],
						[77.54959981289826, 12.952274990809823],
						[77.54950622119411, 12.952283924200884],
						[77.54935901090892, 12.952292857578891],
						[77.54921188790196, 12.952319638374819],
						[77.54914943491507, 12.952350847505103],
						[77.54920742697546, 12.952613881257298],
						[77.54920742697546, 12.952667411324096],
						[77.54922080975442, 12.952810114157346],
						[77.54924757530759, 12.953363085884153],
						[77.54927880177834, 12.95405419673819],
						[77.549332245382, 12.954736470329058],
						[77.549586430164, 12.95472752588847],
						[77.54930547984878, 12.955088691485845],
						[77.54910036468699, 12.955445412641527],
						[77.54909590375611, 12.955449938542168],
						[77.54879273498415, 12.955958214068605],
						[77.54858761889591, 12.956310539960777],
						[77.54840926818792, 12.95660924666973],
						[77.54801687815012, 12.9565200595621],
						[77.54798565133228, 12.956511139501577],
						[77.54778499476846, 12.956471028554425],
						[77.54744158606883, 12.956408599450208],
						[77.54721862352207, 12.956364050038975],
						[77.54691544973727, 12.956292700411856],
						[77.54664787589466, 12.956203500605508],
						[77.54633577850755, 12.956056379863846],
						[77.54587646546216, 12.955788800626866],
						[77.54551083310584, 12.955570308389202],
						[77.54527448352566, 12.955405293192426],
						[77.54476172045827, 12.955017386110775],
						[77.54438270116562, 12.954696360733394],
						[77.54403490852106, 12.954344087420436],
						[77.54378963359116, 12.954143387828054],
						[77.54358004731846, 12.954009577544538],
						[77.54343291682422, 12.953933776492278],
						[77.54327677634694, 12.953875814871868],
						[77.54312518429451, 12.953822296490683],
						[77.54287107252377, 12.953742108381306],
						[77.54259456678086, 12.953684113111875],
						[77.54247420215992, 12.953639514752785],
						[77.54233153143026, 12.95356367032599],
						[77.54213532608148, 12.953461106662544],
						[77.54201487349931, 12.9533764217815],
						[77.54193019796934, 12.953296135789834],
						[77.54168491849535, 12.953028634167808],
						[77.54140849734085, 12.952712049153211],
						[77.54129250522573, 12.95258267119762],
						[77.54120782915741, 12.952493507501648],
						[77.54105168562218, 12.952386499726314],
						[77.5406816639509, 12.952154613514915],
						[77.54007519320173, 12.951775641927483],
						[77.53953563883243, 12.951351976029137],
						[77.53945533528805, 12.951302933337493],
						[77.53902731119071, 12.951044287166399],
						[77.53885786813824, 12.950972927229325],
						[77.53871965420679, 12.95093281215298],
						[77.53855904590823, 12.950883772323985],
						[77.53840744756202, 12.950861457011325],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000007c",
			properties: {
				AREA_SQ_KM: 1.1414199311592441,
				ASS_CONST1: "Vijaya Nagar",
				ASS_CONST_: "167",
				LAT: 12.9501592455071,
				LON: 77.5446560301153,
				LOST_AREA_SQ_KM: 0.030215434158803903,
				OBJECTID: 125,
				POP_F: 12798.0,
				POP_M: 14466.0,
				POP_SC: 1874.0,
				POP_ST: 309.0,
				POP_TOTAL: 27264.0,
				RESERVATIO: "General",
				WARD_NAME: "Gali Anjenaya Temple ward",
				WARD_NO: 157.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53433634839097, 12.936494295500443],
						[77.5343631174507, 12.936463095312275],
						[77.53437641449763, 12.936449688690235],
						[77.53458601774258, 12.936289154281006],
						[77.53460386374392, 12.936244596115317],
						[77.53476885164328, 12.936155338344365],
						[77.53537526322292, 12.935834334146834],
						[77.53602628601905, 12.93551323867149],
						[77.537190104637, 12.934866703348106],
						[77.53895592994591, 12.933858944967977],
						[77.53900054322916, 12.933832224213788],
						[77.53921459926815, 12.933702839851524],
						[77.53932604471284, 12.933635990489554],
						[77.5393840417888, 12.93377866167033],
						[77.53966492916919, 12.934906817109711],
						[77.53988344524976, 12.935638112701312],
						[77.5406325900643, 12.937617975024645],
						[77.53985221619592, 12.937796332750455],
						[77.5397675390544, 12.937823104624016],
						[77.53936173522462, 12.937907823107633],
						[77.539348351284, 12.937952425599057],
						[77.53924136717927, 12.938433962325105],
						[77.53905407914594, 12.939214317360253],
						[77.53896931393272, 12.939660213932335],
						[77.53895146861662, 12.939860859978495],
						[77.53891131664514, 12.940235478157616],
						[77.5388266388026, 12.940926588196497],
						[77.5387998707938, 12.941149559668814],
						[77.53876418010559, 12.941729267918035],
						[77.53866165679183, 12.94352180790896],
						[77.5407038827803, 12.943414754276622],
						[77.54123897036342, 12.943352389163776],
						[77.54147086710945, 12.943343404846372],
						[77.54277738779983, 12.943294365634591],
						[77.54407943233704, 12.94322752007263],
						[77.54481962728704, 12.943191843425428],
						[77.5449222325169, 12.943187395669483],
						[77.54504259454393, 12.943169582367188],
						[77.54610389169284, 12.942861846991791],
						[77.54768239212518, 12.942473882279126],
						[77.54803026106943, 12.942398079678064],
						[77.54849393888756, 12.942322341934265],
						[77.5490335381747, 12.94226431152479],
						[77.54985399708112, 12.94219749128113],
						[77.54999665836039, 12.94217066516783],
						[77.55001896283603, 12.942264310628387],
						[77.55003234551931, 12.942326755515914],
						[77.55005911088115, 12.942393637845779],
						[77.55014832870835, 12.942514038769962],
						[77.5502285372245, 12.942625558789253],
						[77.55027314608026, 12.942750360869018],
						[77.55030437226888, 12.9428351280131],
						[77.55031775491851, 12.942893091232778],
						[77.55029545050158, 12.9429198566413],
						[77.55025530254007, 12.942937659981876],
						[77.5501750065744, 12.942959992923509],
						[77.5500769544522, 12.942991209897142],
						[77.55001896283603, 12.943044699040545],
						[77.54995651029317, 12.94313831150283],
						[77.549880762485, 12.943249814725794],
						[77.54984507527843, 12.943321186497442],
						[77.54981384896347, 12.943419234591703],
						[77.5497603181178, 12.943704626294762],
						[77.54972025743578, 12.943829485965207],
						[77.54968457017873, 12.944012306207004],
						[77.54967118745444, 12.944105956980707],
						[77.54966226563737, 12.944186204229696],
						[77.54963996109164, 12.944293216098407],
						[77.54963103927211, 12.944346743377768],
						[77.549586430164, 12.944538443208083],
						[77.54948391661772, 12.944971023007408],
						[77.54947499478594, 12.945011190435224],
						[77.54879719592694, 12.945100309742905],
						[77.54883734440416, 12.945425818446846],
						[77.54817738560769, 12.9455195016726],
						[77.54773146296615, 12.945581956431894],
						[77.54694221583395, 12.945724566662212],
						[77.54696452090968, 12.94593862797853],
						[77.54679508969518, 12.94594312910421],
						[77.5459968264779, 12.945983246865605],
						[77.54580954958064, 12.945751318754546],
						[77.54553313846272, 12.94580039424213],
						[77.54534139972245, 12.94531880746649],
						[77.5442043438534, 12.945671065758194],
						[77.54356666387471, 12.945827178795621],
						[77.54314749011988, 12.945965385018198],
						[77.54255887728446, 12.946116965859481],
						[77.54197918382081, 12.946268630390733],
						[77.54168491849535, 12.946344421244032],
						[77.54127019903616, 12.946455859768303],
						[77.54052998096017, 12.946625375412772],
						[77.54010642219428, 12.94670561408617],
						[77.53989682912734, 12.946705586444413],
						[77.53982990972365, 12.94669666901674],
						[77.53959363580142, 12.946620883198092],
						[77.53925028981492, 12.946509418963526],
						[77.53906300179628, 12.946442547164695],
						[77.5389380846277, 12.946402387674581],
						[77.53872857688339, 12.946402363329389],
						[77.53846990645506, 12.94642022181913],
						[77.53755130238531, 12.946455881968085],
						[77.53754684100089, 12.946478162839288],
						[77.5375423796163, 12.946545091284323],
						[77.53752016016885, 12.946727867228532],
						[77.53752462155431, 12.94679927743778],
						[77.53756468653751, 12.946950841012624],
						[77.53761822313056, 12.947097969557586],
						[77.53772520876304, 12.94732990562902],
						[77.53788573076189, 12.947633121748154],
						[77.53796603541521, 12.947856014126492],
						[77.53798834225329, 12.947914064429643],
						[77.53798834225329, 12.947958626405104],
						[77.53798834225329, 12.94799431047502],
						[77.53796603541522, 12.94805227646032],
						[77.53793926720374, 12.948083479566058],
						[77.5378812693906, 12.94816377110643],
						[77.53780551352976, 12.948284147582804],
						[77.53773413151768, 12.948400080736114],
						[77.53757360930477, 12.948493697302455],
						[77.53747554630456, 12.948587311476693],
						[77.53737293435029, 12.948801377404015],
						[77.53733286929952, 12.949033287785209],
						[77.53731948512002, 12.949260711346044],
						[77.53734625347744, 12.94938551968515],
						[77.53739077991422, 12.949470242985246],
						[77.53746662352962, 12.949537170397958],
						[77.53765391417858, 12.949661969498507],
						[77.53782782039933, 12.949746731745094],
						[77.53798388088602, 12.949822530371186],
						[77.53805071389888, 12.949858165354094],
						[77.53809978890033, 12.949911733720482],
						[77.53817563204267, 12.949951854768097],
						[77.53822015815457, 12.949996411746755],
						[77.53826923308287, 12.95009454301109],
						[77.53831830798995, 12.950228272965617],
						[77.53835846017091, 12.950495864719285],
						[77.53840744756202, 12.950861457011325],
						[77.53817117068277, 12.950794628622528],
						[77.53811317298793, 12.950781227111205],
						[77.53793034446518, 12.950678707756984],
						[77.53779212940591, 12.950589461118101],
						[77.53773413151768, 12.950562699319157],
						[77.53770290187332, 12.95055826124394],
						[77.53765391417858, 12.95055382794318],
						[77.53761822313056, 12.950567189306291],
						[77.53759591621984, 12.950589471456217],
						[77.53757360930477, 12.950616235757131],
						[77.53755576376955, 12.950660799243078],
						[77.53752462155431, 12.95072325288783],
						[77.53739077991422, 12.9510755374319],
						[77.53715450092179, 12.951837996858593],
						[77.53690921114097, 12.952520293113636],
						[77.53681560898094, 12.952859181105573],
						[77.53675314917896, 12.953055366128442],
						[77.53669515076069, 12.953166862844533],
						[77.53662385553025, 12.953273838737989],
						[77.53656585704601, 12.953354132234315],
						[77.53625819340034, 12.953643983286753],
						[77.53600844024096, 12.953915943163533],
						[77.53587022284512, 12.954049770418791],
						[77.53537972469255, 12.95360828744389],
						[77.53459047924319, 12.953073165457953],
						[77.53378784372099, 12.952738752478282],
						[77.53294058689065, 12.95253811454537],
						[77.53198633281794, 12.952261627338679],
						[77.53135322089011, 12.952020819488792],
						[77.53058625579051, 12.951606156944024],
						[77.5299441252222, 12.951222693797705],
						[77.52963644366432, 12.95090158635719],
						[77.5291414569158, 12.950219420936284],
						[77.52833887043275, 12.949064465438799],
						[77.52770565072383, 12.94813697404575],
						[77.52716166336366, 12.947325417943643],
						[77.52694758464007, 12.946982040047567],
						[77.52660420047675, 12.946290935995897],
						[77.5264348264035, 12.945911884160807],
						[77.52647489529276, 12.945876241086877],
						[77.52659973866297, 12.945795909547652],
						[77.52673805480885, 12.945755789281872],
						[77.52727758240637, 12.945635411134752],
						[77.52759419389203, 12.945572958989976],
						[77.52770118895322, 12.945559595418596],
						[77.52784387804017, 12.945528390624146],
						[77.52792410229806, 12.945506149506162],
						[77.52797318166895, 12.9454837833691],
						[77.52803118453454, 12.94545262592399],
						[77.52804456980701, 12.945443705333373],
						[77.52811587041491, 12.945390177576286],
						[77.52812925567743, 12.945381256980367],
						[77.52817387320776, 12.945350056443235],
						[77.52817833495985, 12.945350013332325],
						[77.52818725846346, 12.945341135843591],
						[77.52821840323531, 12.945323247305002],
						[77.52824963548395, 12.945296571720894],
						[77.52828086772398, 12.945274205576261],
						[77.52828979121955, 12.945260846263752],
						[77.52831209995544, 12.945247443795747],
						[77.52867770014525, 12.945113750145383],
						[77.52894522880533, 12.944997729511334],
						[77.52896307569159, 12.944993333732137],
						[77.52919499747388, 12.944904121968037],
						[77.52922622945447, 12.944886323668715],
						[77.52925291223222, 12.944872873720673],
						[77.52928414419692, 12.944855075411681],
						[77.52940006107777, 12.944779309992503],
						[77.52945806321607, 12.944739102273],
						[77.52959628840388, 12.944614251994054],
						[77.52961859688365, 12.944587576306931],
						[77.52961859688365, 12.944583094494114],
						[77.52968543481254, 12.94452512751235],
						[77.52997535699717, 12.944119300229328],
						[77.5301091198804, 12.94392312901549],
						[77.5301804191947, 12.943816077668236],
						[77.53034987517717, 12.943566383061503],
						[77.53038556850629, 12.943508463947003],
						[77.53043018515197, 12.943437099325042],
						[77.53086270325709, 12.942647811919143],
						[77.5308805498426, 12.94261212981758],
						[77.53093400209916, 12.942523006330175],
						[77.53141113454988, 12.941662357452282],
						[77.53142005779998, 12.941644516471465],
						[77.53143344267382, 12.941622236727257],
						[77.53147359728585, 12.941564274762563],
						[77.53212018081908, 12.94045841829087],
						[77.53238770138465, 12.940012472244534],
						[77.53250807668572, 12.939816260742852],
						[77.53267306762538, 12.939562130611991],
						[77.53290498184488, 12.939196516583744],
						[77.53298520253425, 12.939076107531365],
						[77.5331234234366, 12.938835251692401],
						[77.53337764448048, 12.938402800744166],
						[77.53380568984757, 12.937725007794993],
						[77.53386368973965, 12.937622401784024],
						[77.53392160212124, 12.93751987777569],
						[77.53409105253061, 12.936958027662872],
						[77.53418920593053, 12.93669490170498],
						[77.53419366744669, 12.936677104428947],
						[77.53424711814635, 12.936601255113198],
						[77.53425157966025, 12.936596816374326],
						[77.53426496420087, 12.936579018586725],
						[77.53426942571407, 12.936570098277349],
						[77.53429619478956, 12.936534416520344],
						[77.53433634839097, 12.936494295500443],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000007d",
			properties: {
				AREA_SQ_KM: 2.0952037460827766,
				ASS_CONST1: "Vijaya Nagar",
				ASS_CONST_: "167",
				LAT: 12.9445404293539,
				LON: 77.5362326389086,
				LOST_AREA_SQ_KM: 0.08177040402122944,
				OBJECTID: 126,
				POP_F: 14269.0,
				POP_M: 16655.0,
				POP_SC: 1776.0,
				POP_ST: 367.0,
				POP_TOTAL: 30924.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Deepanjali Nagar",
				WARD_NO: 158.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5438164004245, 12.964024760654274],
						[77.54441392897422, 12.963311290465814],
						[77.54448968042941, 12.963364775548897],
						[77.54456998041567, 12.963280118991971],
						[77.54463680955485, 12.96321317392806],
						[77.54473495384767, 12.963119551450626],
						[77.54484639387768, 12.963052691422991],
						[77.54497130455059, 12.9629946713414],
						[77.54510951087734, 12.962936775893603],
						[77.54527002244447, 12.962874267674996],
						[77.5454260727097, 12.962785164815937],
						[77.54551529417755, 12.962722662674116],
						[77.54555544381522, 12.96268701520774],
						[77.54556882702462, 12.962642403107175],
						[77.54557328809408, 12.962588912060024],
						[77.54555544381522, 12.962522016674237],
						[77.54550191096185, 12.962464087851625],
						[77.54544391700888, 12.962397150677326],
						[77.54535924403493, 12.96234366670315],
						[77.54525663919986, 12.962299058260333],
						[77.54518534934087, 12.962254496213712],
						[77.54531909432788, 12.962080610975514],
						[77.54558221023245, 12.961652544574802],
						[77.54574272113152, 12.961411763264577],
						[77.54634470058603, 12.961224447546343],
						[77.54666125897533, 12.96116197097153],
						[77.54780283869697, 12.960934577269244],
						[77.54870351609152, 12.960863220956789],
						[77.54907368656835, 12.961942369895189],
						[77.5492431143825, 12.962241149901411],
						[77.5495908910756, 12.962053796855843],
						[77.54974247449698, 12.962379388567465],
						[77.54986737978386, 12.962272313089427],
						[77.55054963312666, 12.962125151980239],
						[77.55059424185625, 12.962218856698517],
						[77.55099108384157, 12.963663539787062],
						[77.55109359602665, 12.963940043863474],
						[77.55113820454243, 12.964064871645771],
						[77.55123634321544, 12.964127281688185],
						[77.55132101180631, 12.964189731335072],
						[77.55141022864157, 12.964310160489685],
						[77.55152166211965, 12.964408213171112],
						[77.55162872212426, 12.964443898114553],
						[77.55178922447466, 12.964466213562538],
						[77.55200771722768, 12.964466240708672],
						[77.55222620956003, 12.964461784755459],
						[77.55254283901488, 12.96446171971425],
						[77.5527836342492, 12.964448339256865],
						[77.55319385088895, 12.96439485683494],
						[77.553407880176, 12.964381477222888],
						[77.55364421282246, 12.964363656212075],
						[77.55384485902783, 12.964341354274213],
						[77.554085651499, 12.964296764369065],
						[77.5543264434591, 12.964238811655337],
						[77.55451379376979, 12.964225436802788],
						[77.5546475277659, 12.96421645784644],
						[77.5548169472586, 12.964252133039704],
						[77.55495522906278, 12.964305703045095],
						[77.5550934232338, 12.964363665519118],
						[77.55528960618871, 12.96445727182056],
						[77.55525838137196, 12.964541975378676],
						[77.55524954745866, 12.964546463138054],
						[77.55512910878986, 12.964631172818246],
						[77.55452262774038, 12.964836315368787],
						[77.55326968395184, 12.965277769927518],
						[77.55197649150772, 12.965656754176855],
						[77.55150381877574, 12.965835154437482],
						[77.5516822520887, 12.96632568240875],
						[77.55076366740082, 12.966561956847073],
						[77.55071905873781, 12.966218669446938],
						[77.55035790285795, 12.966316757077331],
						[77.55005464998794, 12.966414794376748],
						[77.55000558015116, 12.966356906235825],
						[77.54975585721286, 12.966133895100006],
						[77.54941700286223, 12.965857442145245],
						[77.54899785070177, 12.96554978514163],
						[77.5486812113574, 12.965317855503292],
						[77.54846717315671, 12.965161829430569],
						[77.54825759551645, 12.965041450663714],
						[77.5480258000965, 12.964938879021025],
						[77.54751296198188, 12.96476497935124],
						[77.54702242661527, 12.964608880537588],
						[77.54663003178467, 12.964475106225816],
						[77.54637146681722, 12.964403742202295],
						[77.54615742426245, 12.964359183120834],
						[77.54596568657617, 12.964345787315821],
						[77.54563565557608, 12.964345800177963],
						[77.54531463324845, 12.964363661473469],
						[77.54497130455059, 12.964385962437895],
						[77.54473049274529, 12.964381489114123],
						[77.5444629137547, 12.964323481444854],
						[77.54421326609932, 12.964238753289592],
						[77.54395460815651, 12.964118417557641],
						[77.54387430773522, 12.96406488461315],
						[77.5438164004245, 12.964024760654274],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000007e",
			properties: {
				AREA_SQ_KM: 0.3194777914462624,
				ASS_CONST1: "Chamarajpet",
				ASS_CONST_: "168",
				LAT: 12.9637025463239,
				LON: 77.5488120504647,
				LOST_AREA_SQ_KM: 2.200529503616632e-4,
				OBJECTID: 127,
				POP_F: 16842.0,
				POP_M: 18371.0,
				POP_SC: 378.0,
				POP_ST: 110.0,
				POP_TOTAL: 35213.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Padarayanapura",
				WARD_NO: 135.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54840926818792, 12.95660924666973],
						[77.54883288346295, 12.956689509401963],
						[77.54892647572224, 12.956702903737389],
						[77.55024638076891, 12.95691691870997],
						[77.55054963312666, 12.95696154659433],
						[77.55079489345447, 12.957001652629495],
						[77.55112036113822, 12.957081919258293],
						[77.55187398012015, 12.957291541298508],
						[77.55220390551816, 12.957394090477832],
						[77.55299320353232, 12.957630431288118],
						[77.55443796153844, 12.958085276120855],
						[77.55449595089638, 12.958111951699435],
						[77.55455831347598, 12.958143234659653],
						[77.55473228127632, 12.958227916487907],
						[77.55490616134512, 12.95834828317093],
						[77.5550889625385, 12.958499941834658],
						[77.55523616539065, 12.958642642625353],
						[77.55532083099688, 12.958731810970324],
						[77.5553832805874, 12.958798701318521],
						[77.55552147423694, 12.958994846871201],
						[77.55567313720034, 12.959213366417831],
						[77.55579348782744, 12.959414083323802],
						[77.55585593715777, 12.95954338771316],
						[77.55593622910337, 12.959641483901327],
						[77.55609226470438, 12.959784181393902],
						[77.55651585129903, 12.960127541488802],
						[77.55701080625107, 12.96047529288043],
						[77.5571490853807, 12.960568945112037],
						[77.55681016564552, 12.961086226199653],
						[77.5561101073267, 12.961960233094551],
						[77.55551701355849, 12.962874315414105],
						[77.55544573014356, 12.963008121322723],
						[77.5563375130515, 12.962807427480247],
						[77.5566362884954, 12.964047051110228],
						[77.55618593844021, 12.964171882641656],
						[77.55586485848785, 12.964261051627416],
						[77.55563745181543, 12.964319048454405],
						[77.55541896605227, 12.96439488984485],
						[77.55528960618871, 12.96445727182056],
						[77.5550934232338, 12.964363665519118],
						[77.55495522906278, 12.964305703045095],
						[77.5548169472586, 12.964252133039704],
						[77.5546475277659, 12.96421645784644],
						[77.55451379376979, 12.964225436802788],
						[77.5543264434591, 12.964238811655337],
						[77.554085651499, 12.964296764369065],
						[77.55384485902783, 12.964341354274213],
						[77.55364421282246, 12.964363656212075],
						[77.553407880176, 12.964381477222888],
						[77.55319385088895, 12.96439485683494],
						[77.5527836342492, 12.964448339256865],
						[77.55254283901488, 12.96446171971425],
						[77.55222620956003, 12.964461784755459],
						[77.55200771722768, 12.964466240708672],
						[77.55178922447466, 12.964466213562538],
						[77.55162872212426, 12.964443898114553],
						[77.55152166211965, 12.964408213171112],
						[77.55141022864157, 12.964310160489685],
						[77.55132101180631, 12.964189731335072],
						[77.55123634321544, 12.964127281688185],
						[77.55113820454243, 12.964064871645771],
						[77.55109359602665, 12.963940043863474],
						[77.55099108384157, 12.963663539787062],
						[77.55059424185625, 12.962218856698517],
						[77.55054963312666, 12.962125151980239],
						[77.54986737978386, 12.962272313089427],
						[77.54974247449698, 12.962379388567465],
						[77.5495908910756, 12.962053796855843],
						[77.5492431143825, 12.962241149901411],
						[77.54907368656835, 12.961942369895189],
						[77.54870351609152, 12.960863220956789],
						[77.54780283869697, 12.960934577269244],
						[77.54666125897533, 12.96116197097153],
						[77.54634470058603, 12.961224447546343],
						[77.54574272113152, 12.961411763264577],
						[77.54592107602792, 12.961135292506654],
						[77.54618419053779, 12.960653694040653],
						[77.5463045512274, 12.96042183721906],
						[77.54648290524143, 12.960073989951884],
						[77.54680838528749, 12.959570145738937],
						[77.54705811470915, 12.95909299019723],
						[77.54740598556603, 12.958415234768921],
						[77.54778499476848, 12.95774191476617],
						[77.54815508077021, 12.95706859693175],
						[77.5483646585997, 12.956693986482689],
						[77.54840926818792, 12.95660924666973],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000007f",
			properties: {
				AREA_SQ_KM: 0.6379731063267013,
				ASS_CONST1: "Chamarajpet",
				ASS_CONST_: "168",
				LAT: 12.9605447475681,
				LON: 77.5519414463642,
				LOST_AREA_SQ_KM: 0.0050239228738523,
				OBJECTID: 128,
				POP_F: 16188.0,
				POP_M: 17570.0,
				POP_SC: 5330.0,
				POP_ST: 342.0,
				POP_TOTAL: 33758.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Jagajivanaramnagar",
				WARD_NO: 136.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5552049405592, 12.969125949340798],
						[77.5550086699932, 12.969063541484946],
						[77.554558313476, 12.968938693939952],
						[77.55425069856778, 12.968853927721028],
						[77.5534747915785, 12.96864887857999],
						[77.55285946758623, 12.968488306265694],
						[77.55257406457899, 12.968403576729466],
						[77.55227081763061, 12.968314450992255],
						[77.55190520586832, 12.968194037013301],
						[77.55169563457586, 12.96808701805816],
						[77.55143699367848, 12.967935386642603],
						[77.55117389134243, 12.967716898609766],
						[77.55099554469797, 12.967538538914136],
						[77.5506744500573, 12.967163969199104],
						[77.55030437226888, 12.966731430584344],
						[77.55028206784931, 12.9666913005298],
						[77.55023745899705, 12.966615523406992],
						[77.55005464998794, 12.966414794376748],
						[77.55035790285795, 12.966316757077331],
						[77.55071905873781, 12.966218669446938],
						[77.55076366740082, 12.966561956847073],
						[77.5516822520887, 12.96632568240875],
						[77.55150381877574, 12.965835154437482],
						[77.55197649150772, 12.965656754176855],
						[77.55326968395184, 12.965277769927518],
						[77.55452262774038, 12.964836315368787],
						[77.55512910878986, 12.964631172818246],
						[77.55524954745866, 12.964546463138054],
						[77.55525838137196, 12.964541975378676],
						[77.55528960618871, 12.96445727182056],
						[77.55541896605227, 12.96439488984485],
						[77.55563745181543, 12.964319048454405],
						[77.55586485848785, 12.964261051627416],
						[77.55618593844021, 12.964171882641656],
						[77.5566362884954, 12.964047051110228],
						[77.5563375130515, 12.962807427480247],
						[77.55544573014356, 12.963008121322723],
						[77.55551701355849, 12.962874315414105],
						[77.5561101073267, 12.961960233094551],
						[77.55681016564552, 12.961086226199653],
						[77.5571490853807, 12.960568945112037],
						[77.55742109506778, 12.960751806251965],
						[77.55769756469535, 12.9609078134144],
						[77.5581746721953, 12.961090651841173],
						[77.55902191570837, 12.961282373186853],
						[77.55909328433707, 12.961295814885478],
						[77.55936528936056, 12.961532125982396],
						[77.56001626207743, 12.96236595339733],
						[77.56065839756454, 12.963204276136304],
						[77.56075206752843, 12.963320177142],
						[77.56177762175275, 12.964662369412476],
						[77.56176424045513, 12.9646757766796],
						[77.56165719001739, 12.964747170630082],
						[77.5614030322823, 12.964827419661102],
						[77.56124254353594, 12.96490321952364],
						[77.56115333443425, 12.964983491317252],
						[77.56103744995715, 12.965135067165471],
						[77.56095716162353, 12.965300084615897],
						[77.56087687323306, 12.965456136112582],
						[77.56074314658282, 12.965745998405579],
						[77.56064501613481, 12.965933260734777],
						[77.56056918800317, 12.966031334274032],
						[77.5604533029289, 12.966138338576714],
						[77.56025258095397, 12.966298927152888],
						[77.56000734107391, 12.96645047122946],
						[77.55986023187563, 12.966521828915749],
						[77.55975317963988, 12.966535200841204],
						[77.55888363886243, 12.964751603816259],
						[77.55880789701449, 12.96476053730977],
						[77.5587231465496, 12.964827401234622],
						[77.55858486933931, 12.965045915246291],
						[77.55838423151866, 12.965264352462391],
						[77.55823265964284, 12.965349114757611],
						[77.55787144021836, 12.965549804745876],
						[77.55736310720529, 12.965875300591179],
						[77.557407713256, 12.966102723816364],
						[77.55743001627476, 12.966205271172534],
						[77.5573452647801, 12.966298860428102],
						[77.55720261274047, 12.966352412629472],
						[77.556997511854, 12.966414848993816],
						[77.55687261441606, 12.966472792248485],
						[77.55677894124736, 12.966624402576448],
						[77.55668089483196, 12.9667046769935],
						[77.55656045768296, 12.966726971052262],
						[77.55637765886097, 12.966789362054412],
						[77.55630182787563, 12.96682510201994],
						[77.5562260843033, 12.966887485703124],
						[77.55618147778787, 12.966936543323701],
						[77.5560387368206, 12.967061387799484],
						[77.55585593715777, 12.967106018554041],
						[77.55564191248915, 12.96711491616315],
						[77.5553832805874, 12.967097090574484],
						[77.5546207634863, 12.967400313119445],
						[77.55470105630616, 12.967583078206903],
						[77.55465198847855, 12.967618815949942],
						[77.55471443843729, 12.967770373576489],
						[77.5552049405592, 12.969125949340798],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000080",
			properties: {
				AREA_SQ_KM: 0.44589694137708674,
				ASS_CONST1: "Chamarajpet",
				ASS_CONST_: "168",
				LAT: 12.9649880815167,
				LON: 77.5563781786261,
				LOST_AREA_SQ_KM: 0.01526465590865191,
				OBJECTID: 129,
				POP_F: 16021.0,
				POP_M: 16928.0,
				POP_SC: 10777.0,
				POP_ST: 233.0,
				POP_TOTAL: 32949.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Rayapuram",
				WARD_NO: 137.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5643550226245, 12.968385733843927],
						[77.56414984722036, 12.968113745168681],
						[77.56361924056299, 12.96739136957744],
						[77.56314215597669, 12.96666899420982],
						[77.56278541258779, 12.966031312649507],
						[77.56245543051273, 12.96557209296561],
						[77.56211206622038, 12.965081538641517],
						[77.56177762175275, 12.964662369412476],
						[77.56075206752843, 12.963320177142],
						[77.56065839756454, 12.963204276136304],
						[77.56001626207743, 12.96236595339733],
						[77.55936528936056, 12.961532125982396],
						[77.55909328433707, 12.961295814885478],
						[77.55922255240066, 12.961326962648512],
						[77.55982008729907, 12.961451812381362],
						[77.56011885356732, 12.96152768548668],
						[77.56087241276526, 12.96163022825194],
						[77.56112211123208, 12.961639163852297],
						[77.5624911137519, 12.96169266523211],
						[77.56393583820818, 12.96222776048643],
						[77.56515761695984, 12.962633481089238],
						[77.56609401356029, 12.962945612763374],
						[77.56692790464335, 12.963168573695171],
						[77.56834143876722, 12.963717074526445],
						[77.56820317329243, 12.96510387852116],
						[77.568100676576, 12.96669131975399],
						[77.56809621639253, 12.966972252397296],
						[77.56809175620891, 12.967137225954852],
						[77.568100676576, 12.967275428232885],
						[77.56804715436296, 12.968095950130548],
						[77.56804269417741, 12.968171691494954],
						[77.56804269417741, 12.968216351458148],
						[77.56786874680355, 12.968225208740543],
						[77.56793118948131, 12.967221908602001],
						[77.56775724193622, 12.96721300588175],
						[77.56775724193622, 12.966972206651274],
						[77.56699926829359, 12.966976704883677],
						[77.5664462862842, 12.967150581307713],
						[77.56640614404053, 12.966713606137665],
						[77.56631256620592, 12.966726941095846],
						[77.56616537773583, 12.966776056853323],
						[77.56601827653068, 12.966793880805291],
						[77.56576850139528, 12.96676709572394],
						[77.56553219402005, 12.966735873118282],
						[77.56552327345071, 12.966847349382405],
						[77.56550097202428, 12.967092589179869],
						[77.5655411145887, 12.967262039920323],
						[77.56557679685622, 12.967418171210042],
						[77.5655678762904, 12.967489471932145],
						[77.56531818761874, 12.96770348834358],
						[77.56520668024088, 12.967930892435367],
						[77.56506841093992, 12.968140539146642],
						[77.56496145108028, 12.968189524634205],
						[77.5643550226245, 12.968385733843927],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000081",
			properties: {
				AREA_SQ_KM: 0.3884706215547287,
				ASS_CONST1: "Chamarajpet",
				ASS_CONST_: "168",
				LAT: 12.9645793210376,
				LON: 77.5644482281296,
				LOST_AREA_SQ_KM: 0.009199386229122385,
				OBJECTID: 130,
				POP_F: 12138.0,
				POP_M: 13181.0,
				POP_SC: 12632.0,
				POP_ST: 86.0,
				POP_TOTAL: 25319.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "Chalavadipalya",
				WARD_NO: 138.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57485170620723, 12.968381309388946],
						[77.57482048678101, 12.968327815672346],
						[77.57480710702427, 12.96830553027961],
						[77.57421402366951, 12.967163972652072],
						[77.57392867443674, 12.966548603904915],
						[77.5738751549786, 12.96644601426817],
						[77.57384839524005, 12.966405927617494],
						[77.5735898048915, 12.966526328024669],
						[77.57340694609094, 12.966606579092598],
						[77.5733668063147, 12.966646718665254],
						[77.57333112650164, 12.966691297734128],
						[77.57322863442707, 12.966758177638551],
						[77.57306361499596, 12.966829545492724],
						[77.57220746898776, 12.967114878649001],
						[77.57173033312178, 12.967342344232797],
						[77.57136023661512, 12.967516253841017],
						[77.57085187665778, 12.967721348059829],
						[77.57042379610017, 12.96790418668981],
						[77.57023655953957, 12.967957669045239],
						[77.57012059689978, 12.967957683736214],
						[77.56974603501298, 12.968037916292197],
						[77.56854651912583, 12.968167269965683],
						[77.56809621639253, 12.96821186199534],
						[77.56804269417741, 12.968216351458148],
						[77.56804269417741, 12.968171691494954],
						[77.56804715436296, 12.968095950130548],
						[77.568100676576, 12.967275428232885],
						[77.56809175620891, 12.967137225954852],
						[77.56809621639253, 12.966972252397296],
						[77.568100676576, 12.96669131975399],
						[77.56820317329243, 12.96510387852116],
						[77.56834143876722, 12.963717074526445],
						[77.56692790464335, 12.963168573695171],
						[77.56609401356029, 12.962945612763374],
						[77.56515761695984, 12.962633481089238],
						[77.56525574350066, 12.960903392887962],
						[77.56561247911252, 12.960903429747644],
						[77.56599151493538, 12.96089893711006],
						[77.56629918544381, 12.960841002730968],
						[77.56658901414653, 12.960769621385968],
						[77.56685208071572, 12.960680397612101],
						[77.56686546141252, 12.960421808222858],
						[77.56793564967127, 12.960461909249302],
						[77.57170803291757, 12.96057338394376],
						[77.57320633451799, 12.960163195140744],
						[77.57449053976956, 12.959810878594007],
						[77.57448607983783, 12.959806439455441],
						[77.57455743872453, 12.9597797015965],
						[77.57456635858219, 12.959802028896249],
						[77.57478926734616, 12.960435213452584],
						[77.57510146130696, 12.961121843034272],
						[77.57545816590152, 12.961759496505644],
						[77.57617157171748, 12.962629091484763],
						[77.57704561451143, 12.964617834225812],
						[77.57729081750017, 12.965170735934366],
						[77.57775009071251, 12.96619633304669],
						[77.57784374654047, 12.966392504949154],
						[77.57801321879396, 12.96674036457231],
						[77.57698317686095, 12.967204090772636],
						[77.57630090774013, 12.967574206556142],
						[77.57485170620723, 12.968381309388946],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000082",
			properties: {
				AREA_SQ_KM: 0.8181490418695209,
				ASS_CONST1: "Chamarajpet",
				ASS_CONST_: "168",
				LAT: 12.9639680609056,
				LON: 77.5718004624152,
				LOST_AREA_SQ_KM: 0.023065434586229516,
				OBJECTID: 131,
				POP_F: 13532.0,
				POP_M: 14982.0,
				POP_SC: 8845.0,
				POP_ST: 360.0,
				POP_TOTAL: 28514.0,
				RESERVATIO: "Scheduled Caste",
				WARD_NAME: "K R Market",
				WARD_NO: 139.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.55449595089638, 12.958111951699435],
						[77.55527622412544, 12.955873522169453],
						[77.5553475951113, 12.956056353697223],
						[77.55540112332125, 12.95620798139448],
						[77.55544126946211, 12.956399652427956],
						[77.55549033694834, 12.956582530131481],
						[77.55553039559335, 12.956734112562298],
						[77.55558392371702, 12.956908068272666],
						[77.55563299114154, 12.957050689283363],
						[77.5556552945093, 12.957211243596557],
						[77.5556597551823, 12.957550157988724],
						[77.55566421585516, 12.957746317417838],
						[77.55570436190293, 12.957947042819601],
						[77.5557578024804, 12.958120910149715],
						[77.55577564515534, 12.958241337724388],
						[77.55576226314939, 12.958375087029594],
						[77.5562260843033, 12.958593572588665],
						[77.55678786250486, 12.958740748021695],
						[77.55725167946471, 12.958843285550532],
						[77.5577154070651, 12.958981507817064],
						[77.55823712021449, 12.959208905008053],
						[77.55858049624325, 12.959347185933003],
						[77.5587989759159, 12.959396168593855],
						[77.55899069191919, 12.959396188073555],
						[77.55922701293339, 12.959405168376499],
						[77.5592359339983, 12.95886559665933],
						[77.5592359339983, 12.957951461270037],
						[77.55924039453048, 12.957875642355523],
						[77.55924039453048, 12.957862280384857],
						[77.5592716182509, 12.956943709752661],
						[77.55915118385333, 12.956774286107464],
						[77.5589416259474, 12.956586946112662],
						[77.55864285657707, 12.956368496202472],
						[77.55839761321522, 12.956248055746396],
						[77.55751030710606, 12.955588143329594],
						[77.55731404052925, 12.95532058697108],
						[77.5572204551881, 12.955124358206325],
						[77.5571490853807, 12.954923769963928],
						[77.55710447922829, 12.954740937159348],
						[77.55705541244033, 12.954446601297212],
						[77.55701080625107, 12.954179031784125],
						[77.55696182688577, 12.953938233606829],
						[77.55688153566621, 12.95374204502981],
						[77.55679232313334, 12.953590424497069],
						[77.5566942767295, 12.953403207835459],
						[77.55658722150491, 12.953247107281971],
						[77.55735864659925, 12.953140074850957],
						[77.55756820743058, 12.953095523284274],
						[77.5580097182986, 12.952988447850448],
						[77.5585091272918, 12.952845801719684],
						[77.55850020616971, 12.953006311731468],
						[77.55849128504691, 12.953122255209347],
						[77.55849128504691, 12.953144581680577],
						[77.5584957456084, 12.953180225933236],
						[77.55851804841319, 12.953198024138084],
						[77.55856711456828, 12.953211422838494],
						[77.55860271156948, 12.953215896928917],
						[77.55864731713258, 12.95322037416938],
						[77.55876329151455, 12.953238204315106],
						[77.55892378377057, 12.953282747718593],
						[77.55931622355088, 12.95347010135221],
						[77.55976210066338, 12.95363054882577],
						[77.55991821846112, 12.953670743468601],
						[77.55998503856208, 12.953675170465514],
						[77.56002964358137, 12.953675164946535],
						[77.5600920905789, 12.953675157204684],
						[77.56016345853396, 12.953666269382154],
						[77.56034179076448, 12.953621675701278],
						[77.56060041135764, 12.953532504495605],
						[77.5608367290165, 12.953434413716735],
						[77.56098838487115, 12.95338086187081],
						[77.56109980893956, 12.953358559638195],
						[77.56124254353594, 12.95333630058426],
						[77.56148778074387, 12.953327342174434],
						[77.56168841307218, 12.95331847559541],
						[77.56168841307218, 12.95338976596846],
						[77.56206300159953, 12.953407642243922],
						[77.56223695788673, 12.953416540965602],
						[77.56231269744366, 12.953434413814609],
						[77.56237960359218, 12.95347005006322],
						[77.56250003455995, 12.95359490015378],
						[77.56263375913748, 12.95370642584001],
						[77.56273188786379, 12.95375994528325],
						[77.56277649180223, 12.953791145132724],
						[77.56279879376484, 12.953800064233544],
						[77.56280771454865, 12.95380006301914],
						[77.56286115177824, 12.953808930547412],
						[77.56295928030765, 12.95382676142745],
						[77.56311539370209, 12.953880316016418],
						[77.56336062685779, 12.953933854039088],
						[77.56340523054875, 12.953942726928046],
						[77.56342307202023, 12.953978413118673],
						[77.56342307202023, 12.95400513651964],
						[77.56342307202023, 12.954054186906907],
						[77.56342753238765, 12.954192415393694],
						[77.56340969091688, 12.95497722772331],
						[77.56336954759739, 12.95643536052751],
						[77.56335170611749, 12.95687234336941],
						[77.56335616648772, 12.956943678787999],
						[77.56336062685779, 12.956988290171447],
						[77.5633918494433, 12.957010570579358],
						[77.56344537385561, 12.957024011918788],
						[77.56349435041989, 12.95702395825478],
						[77.56496145108028, 12.957032927566472],
						[77.56669159968651, 12.95709085420466],
						[77.56836819980738, 12.957131017032209],
						[77.56974603501298, 12.957193417639376],
						[77.57001355435766, 12.957197853955186],
						[77.57185521418421, 12.957251374540805],
						[77.57309929489332, 12.957304902172266],
						[77.57348722560073, 12.957318236267763],
						[77.57357196502151, 12.957309384998283],
						[77.57362548462302, 12.957296013150543],
						[77.57455743872453, 12.9597797015965],
						[77.57448607983783, 12.959806439455441],
						[77.57449053976956, 12.959810878594007],
						[77.57320633451799, 12.960163195140744],
						[77.57170803291757, 12.96057338394376],
						[77.56793564967127, 12.960461909249302],
						[77.56686546141252, 12.960421808222858],
						[77.56685208071572, 12.960680397612101],
						[77.56658901414653, 12.960769621385968],
						[77.56629918544381, 12.960841002730968],
						[77.56599151493538, 12.96089893711006],
						[77.56561247911252, 12.960903429747644],
						[77.56525574350066, 12.960903392887962],
						[77.56515761695984, 12.962633481089238],
						[77.56393583820818, 12.96222776048643],
						[77.5624911137519, 12.96169266523211],
						[77.56112211123208, 12.961639163852297],
						[77.56087241276526, 12.96163022825194],
						[77.56011885356732, 12.96152768548668],
						[77.55982008729907, 12.961451812381362],
						[77.55922255240066, 12.961326962648512],
						[77.55909328433707, 12.961295814885478],
						[77.55902191570837, 12.961282373186853],
						[77.5581746721953, 12.961090651841173],
						[77.55769756469535, 12.9609078134144],
						[77.55742109506778, 12.960751806251965],
						[77.5571490853807, 12.960568945112037],
						[77.55701080625107, 12.96047529288043],
						[77.55651585129903, 12.960127541488802],
						[77.55609226470438, 12.959784181393902],
						[77.55593622910337, 12.959641483901327],
						[77.55585593715777, 12.95954338771316],
						[77.55579348782744, 12.959414083323802],
						[77.55567313720034, 12.959213366417831],
						[77.55552147423694, 12.958994846871201],
						[77.5553832805874, 12.958798701318521],
						[77.55532083099688, 12.958731810970324],
						[77.55523616539065, 12.958642642625353],
						[77.5550889625385, 12.958499941834658],
						[77.55490616134512, 12.95834828317093],
						[77.55473228127632, 12.958227916487907],
						[77.55455831347598, 12.958143234659653],
						[77.55449595089638, 12.958111951699435],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000083",
			properties: {
				AREA_SQ_KM: 1.0510918671555418,
				ASS_CONST1: "Chamarajpet",
				ASS_CONST_: "168",
				LAT: 12.9581840004128,
				LON: 77.5637756014863,
				LOST_AREA_SQ_KM: 0.03448933465342802,
				OBJECTID: 132,
				POP_F: 16205.0,
				POP_M: 17193.0,
				POP_SC: 948.0,
				POP_ST: 469.0,
				POP_TOTAL: 33398.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Chamrajapet",
				WARD_NO: 140.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54840926818792, 12.95660924666973],
						[77.54858761889591, 12.956310539960777],
						[77.54879273498415, 12.955958214068605],
						[77.54909590375611, 12.955449938542168],
						[77.54910036468699, 12.955445412641527],
						[77.54930547984878, 12.955088691485845],
						[77.549586430164, 12.95472752588847],
						[77.549332245382, 12.954736470329058],
						[77.54927880177834, 12.95405419673819],
						[77.54924757530759, 12.953363085884153],
						[77.54922080975442, 12.952810114157346],
						[77.54920742697546, 12.952667411324096],
						[77.54920742697546, 12.952613881257298],
						[77.54914943491507, 12.952350847505103],
						[77.54921188790196, 12.952319638374819],
						[77.54935901090892, 12.952292857578891],
						[77.54950622119411, 12.952283924200884],
						[77.54959981289826, 12.952274990809823],
						[77.5507056761355, 12.95220367684253],
						[77.55145483703289, 12.952248250319318],
						[77.55157073130098, 12.952110057038944],
						[77.55164210461774, 12.952043159398087],
						[77.55197649150772, 12.95167302099493],
						[77.55228866085395, 12.951236041060554],
						[77.55235557291641, 12.951142378823526],
						[77.55236895532417, 12.95112009495848],
						[77.552587446961, 12.950816862954634],
						[77.55267211454382, 12.950705437750479],
						[77.55279255582087, 12.95055824053302],
						[77.5532786054853, 12.950883801227617],
						[77.55346587006044, 12.950857056037833],
						[77.55363529131772, 12.95075893924064],
						[77.55384039828346, 12.950656336181183],
						[77.55392961313791, 12.950616244022816],
						[77.55404550487839, 12.950535975789082],
						[77.55422393419457, 12.950402175148096],
						[77.5543978150425, 12.950268413735914],
						[77.55446026514116, 12.950201516214962],
						[77.554500411615, 12.950054412670312],
						[77.55452262774038, 12.949942907283422],
						[77.55458061705504, 12.949755633236196],
						[77.5547412026948, 12.949679846363349],
						[77.55499091466231, 12.950562755130065],
						[77.55556162033524, 12.950411139375023],
						[77.55561960911876, 12.950397728719095],
						[77.55571328324494, 12.95037991778226],
						[77.55574450793648, 12.950375474923373],
						[77.55599421769539, 12.950339755252278],
						[77.55619485974435, 12.950295205488427],
						[77.5563152098179, 12.950263939722111],
						[77.55640442272603, 12.95022832867403],
						[77.55652031193821, 12.950174780617209],
						[77.55668981609718, 12.950063303992096],
						[77.55676109873026, 12.949992003948607],
						[77.55678340187619, 12.94996963241263],
						[77.55681462627314, 12.949960793171924],
						[77.55683692940853, 12.949969712166396],
						[77.55733188295936, 12.950081143197409],
						[77.55763957697421, 12.950139156872583],
						[77.5576574193531, 12.950139154664775],
						[77.55770202528805, 12.95014802775843],
						[77.5577154070651, 12.95015246540946],
						[77.5580854606797, 12.950192623202515],
						[77.5580854606797, 12.950393297734598],
						[77.5579517307371, 12.950437884265627],
						[77.55788036138992, 12.950469097687096],
						[77.55784021611238, 12.950504746550822],
						[77.55779114964277, 12.950540396508233],
						[77.55773771002337, 12.950576094317684],
						[77.55769310410247, 12.950616269233509],
						[77.55763065578371, 12.95067420363606],
						[77.55757266802837, 12.9507500241083],
						[77.55752368890573, 12.950812400422633],
						[77.55744785868663, 12.95094175368579],
						[77.55732742235209, 12.951039821460883],
						[77.55716692783676, 12.951137941255482],
						[77.55696628750741, 12.951213782725654],
						[77.55675226493399, 12.951267256421842],
						[77.55648917492378, 12.951360948152667],
						[77.55628844593177, 12.951427819756772],
						[77.5561190286368, 12.951481287616396],
						[77.55593622910337, 12.951543764786692],
						[77.55572220458626, 12.95160171968004],
						[77.55576226314939, 12.951766656275176],
						[77.55577118448686, 12.951802342459038],
						[77.55579348782744, 12.951891514872537],
						[77.55585147649245, 12.952061022851483],
						[77.55590054380122, 12.952176914932071],
						[77.55598975703552, 12.952386546724028],
						[77.55606104010857, 12.952502475641307],
						[77.5561547138702, 12.952636207110173],
						[77.55628398528346, 12.95283242692904],
						[77.55658722150491, 12.953247107281971],
						[77.5566942767295, 12.953403207835459],
						[77.55679232313334, 12.953590424497069],
						[77.55688153566621, 12.95374204502981],
						[77.55696182688577, 12.953938233606829],
						[77.55701080625107, 12.954179031784125],
						[77.55705541244033, 12.954446601297212],
						[77.55710447922829, 12.954740937159348],
						[77.5571490853807, 12.954923769963928],
						[77.5572204551881, 12.955124358206325],
						[77.55731404052925, 12.95532058697108],
						[77.55751030710606, 12.955588143329594],
						[77.55839761321522, 12.956248055746396],
						[77.55864285657707, 12.956368496202472],
						[77.5589416259474, 12.956586946112662],
						[77.55915118385333, 12.956774286107464],
						[77.5592716182509, 12.956943709752661],
						[77.55924039453048, 12.957862280384857],
						[77.55924039453048, 12.957875642355523],
						[77.5592359339983, 12.957951461270037],
						[77.5592359339983, 12.95886559665933],
						[77.55922701293339, 12.959405168376499],
						[77.55899069191919, 12.959396188073555],
						[77.5587989759159, 12.959396168593855],
						[77.55858049624325, 12.959347185933003],
						[77.55823712021449, 12.959208905008053],
						[77.5577154070651, 12.958981507817064],
						[77.55725167946471, 12.958843285550532],
						[77.55678786250486, 12.958740748021695],
						[77.5562260843033, 12.958593572588665],
						[77.55576226314939, 12.958375087029594],
						[77.55577564515534, 12.958241337724388],
						[77.5557578024804, 12.958120910149715],
						[77.55570436190293, 12.957947042819601],
						[77.55566421585516, 12.957746317417838],
						[77.5556597551823, 12.957550157988724],
						[77.5556552945093, 12.957211243596557],
						[77.55563299114154, 12.957050689283363],
						[77.55558392371702, 12.956908068272666],
						[77.55553039559335, 12.956734112562298],
						[77.55549033694834, 12.956582530131481],
						[77.55544126946211, 12.956399652427956],
						[77.55540112332125, 12.95620798139448],
						[77.5553475951113, 12.956056353697223],
						[77.55527622412544, 12.955873522169453],
						[77.55449595089638, 12.958111951699435],
						[77.55443796153844, 12.958085276120855],
						[77.55299320353232, 12.957630431288118],
						[77.55220390551816, 12.957394090477832],
						[77.55187398012015, 12.957291541298508],
						[77.55112036113822, 12.957081919258293],
						[77.55079489345447, 12.957001652629495],
						[77.55054963312666, 12.95696154659433],
						[77.55024638076891, 12.95691691870997],
						[77.54892647572224, 12.956702903737389],
						[77.54883288346295, 12.956689509401963],
						[77.54840926818792, 12.95660924666973],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000084",
			properties: {
				AREA_SQ_KM: 0.6741042450904013,
				ASS_CONST1: "Chamarajpet",
				ASS_CONST_: "168",
				LAT: 12.9547171397323,
				LON: 77.554027303718,
				LOST_AREA_SQ_KM: 0.012268680116840437,
				OBJECTID: 133,
				POP_F: 17157.0,
				POP_M: 18584.0,
				POP_SC: 2189.0,
				POP_ST: 780.0,
				POP_TOTAL: 35741.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Azad Nagar",
				WARD_NO: 141.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58677532283966, 12.965589934873579],
						[77.58648108638064, 12.965193048656452],
						[77.58565608348701, 12.964301249212918],
						[77.58519684238323, 12.963783999994348],
						[77.58424705031157, 12.961746172827082],
						[77.58321251812374, 12.959752936061081],
						[77.58227608825564, 12.95795593908708],
						[77.58216459758287, 12.957768666561174],
						[77.58174547911355, 12.958000497849257],
						[77.5808001177305, 12.95856685426207],
						[77.58016692842793, 12.958910198254877],
						[77.58003768427052, 12.95889233919465],
						[77.57982361784461, 12.95892798751621],
						[77.5794446283339, 12.959017167079587],
						[77.57911906711037, 12.959030550559776],
						[77.57881143144287, 12.958878924120384],
						[77.57847248907359, 12.958749671542117],
						[77.57830747728941, 12.958722848266039],
						[77.57800875900107, 12.958794249955226],
						[77.57767436148028, 12.958816517145632],
						[77.5776342232177, 12.958308177972533],
						[77.57752718778119, 12.95771954838088],
						[77.57742907187543, 12.956863409455917],
						[77.5774112326107, 12.956426413691455],
						[77.57743799150674, 12.956252523051669],
						[77.57765652225422, 12.956127691410261],
						[77.57814701249896, 12.955855684138765],
						[77.57867763852367, 12.9555747447248],
						[77.5789986538177, 12.955427601212866],
						[77.57917258408818, 12.955369675640688],
						[77.57936881279021, 12.955325064673069],
						[77.57975226227929, 12.95524480557131],
						[77.58004660369615, 12.955164482484294],
						[77.58018030755079, 12.95511996578954],
						[77.58043451058475, 12.955030727837071],
						[77.58087593231474, 12.954928168757677],
						[77.58116135147017, 12.954888019529797],
						[77.58193278407526, 12.954803317813479],
						[77.58256150387697, 12.954754249777022],
						[77.58289597439138, 12.954714130114821],
						[77.58304751325561, 12.954691849475424],
						[77.5832794119206, 12.954642829534846],
						[77.58352468883713, 12.954580400551169],
						[77.58422475257453, 12.954352941688793],
						[77.58471521433155, 12.954192471732332],
						[77.58510319264501, 12.954116644382934],
						[77.58525035648451, 12.954085444399535],
						[77.5854198176381, 12.954045316204056],
						[77.58545995313726, 12.95454468113892],
						[77.58566054297829, 12.955289363290607],
						[77.5859682474523, 12.95664492936241],
						[77.58622689694181, 12.957661662992056],
						[77.5864855458394, 12.958473217524915],
						[77.58680653896585, 12.959075172747523],
						[77.58721680725144, 12.95895032575525],
						[77.5880238737219, 12.958691704689162],
						[77.58893358723978, 12.958401806656429],
						[77.5898387466688, 12.958125393411978],
						[77.59064142255262, 12.957889052173135],
						[77.59118536774969, 12.957724068412848],
						[77.59135482000221, 12.95763039629664],
						[77.59143508676992, 12.957567956494277],
						[77.59167588673084, 12.957135443342644],
						[77.59186317523438, 12.956778726926386],
						[77.59194790088397, 12.956502259827545],
						[77.59201478950979, 12.956252524124517],
						[77.59227788438702, 12.956105417496504],
						[77.59242057965727, 12.956546880932441],
						[77.59242503888156, 12.956578046129442],
						[77.5927505617792, 12.957483255126228],
						[77.59283082755496, 12.957710668978507],
						[77.5929646037211, 12.958067392205546],
						[77.59341052313037, 12.959222308579987],
						[77.59395900158877, 12.96062694048986],
						[77.59436469731553, 12.961683733459372],
						[77.59459657275296, 12.962192104587281],
						[77.59465454153792, 12.962316891432511],
						[77.59443158450982, 12.962245559242694],
						[77.5942353819609, 12.962218795194554],
						[77.59395900158877, 12.962205419416156],
						[77.59359780875718, 12.962200984035878],
						[77.59328120668297, 12.962227747986658],
						[77.59299135893532, 12.96226786863299],
						[77.59206830038192, 12.96245514632319],
						[77.59113185645923, 12.962584440869541],
						[77.59074844557286, 12.962615661070437],
						[77.59050318433474, 12.962655853546702],
						[77.59032035288351, 12.96270042816369],
						[77.58996360773644, 12.962794058096673],
						[77.58975401943717, 12.962896576830927],
						[77.58955780876204, 12.963034829603508],
						[77.58929024820131, 12.963297942330346],
						[77.58913863026895, 12.963440581723946],
						[77.58912971156072, 12.963445067096833],
						[77.58828697789413, 12.964256618281759],
						[77.5870027544187, 12.965362484054967],
						[77.58698937610319, 12.965375893948242],
						[77.58696707890715, 12.96539818629014],
						[77.58677532283966, 12.965589934873579],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000085",
			properties: {
				AREA_SQ_KM: 1.0090730309898848,
				ASS_CONST1: "Chickpet",
				ASS_CONST_: "169",
				LAT: 12.9593349100093,
				LON: 77.586192712433,
				LOST_AREA_SQ_KM: 0.025855650318639418,
				OBJECTID: 134,
				POP_F: 15650.0,
				POP_M: 16552.0,
				POP_SC: 8344.0,
				POP_ST: 303.0,
				POP_TOTAL: 32202.0,
				RESERVATIO: "Scheduled Caste (Woman)",
				WARD_NAME: "Sudham Nagara",
				WARD_NO: 118.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58276664590981, 12.972042250307657],
						[77.58276664590981, 12.971788065669726],
						[77.58277110551508, 12.971752411840155],
						[77.58279340353883, 12.970379018864703],
						[77.58294494254052, 12.970173866600614],
						[77.58290926574826, 12.970071269417625],
						[77.58270867102522, 12.969536177613413],
						[77.58224041125231, 12.968318871894821],
						[77.58197738044899, 12.967819409587841],
						[77.58157155279987, 12.967105976002188],
						[77.5812817624603, 12.96654418895646],
						[77.58122378681439, 12.966575367445305],
						[77.5812148674816, 12.966584249561294],
						[77.58115243213246, 12.96660210808936],
						[77.58106323871675, 12.966677910217996],
						[77.5804924866353, 12.966999007857485],
						[77.5798592956104, 12.967333392238928],
						[77.57865087992084, 12.967993393693467],
						[77.57850370749217, 12.967703507794662],
						[77.57832977619323, 12.967364655233187],
						[77.57807119608555, 12.966874121789576],
						[77.57807119608555, 12.966860758029037],
						[77.57802659817158, 12.966776013374046],
						[77.57801321879396, 12.96674036457231],
						[77.57784374654047, 12.966392504949154],
						[77.57775009071251, 12.96619633304669],
						[77.57729081750017, 12.965170735934366],
						[77.57704561451143, 12.964617834225812],
						[77.57617157171748, 12.962629091484763],
						[77.57545816590152, 12.961759496505644],
						[77.57510146130696, 12.961121843034272],
						[77.57478926734616, 12.960435213452584],
						[77.57456635858219, 12.959802028896249],
						[77.57455743872453, 12.9597797015965],
						[77.57362548462302, 12.957296013150543],
						[77.5736923840893, 12.957278112936034],
						[77.57382609545307, 12.957242480640607],
						[77.57396435406143, 12.957202325660798],
						[77.57484724628972, 12.956957064908128],
						[77.57531990911806, 12.956818866290856],
						[77.57604678286586, 12.956622642712322],
						[77.57685830145661, 12.95641307626191],
						[77.57719270138944, 12.956310546989977],
						[77.57731311660439, 12.956283755400813],
						[77.57743799150674, 12.956252523051669],
						[77.5774112326107, 12.956426413691455],
						[77.57742907187543, 12.956863409455917],
						[77.57752718778119, 12.95771954838088],
						[77.5776342232177, 12.958308177972533],
						[77.57767436148028, 12.958816517145632],
						[77.57800875900107, 12.958794249955226],
						[77.57830747728941, 12.958722848266039],
						[77.57847248907359, 12.958749671542117],
						[77.57881143144287, 12.958878924120384],
						[77.57911906711037, 12.959030550559776],
						[77.5794446283339, 12.959017167079587],
						[77.57982361784461, 12.95892798751621],
						[77.58003768427052, 12.95889233919465],
						[77.58016692842793, 12.958910198254877],
						[77.5808001177305, 12.95856685426207],
						[77.58174547911355, 12.958000497849257],
						[77.58216459758287, 12.957768666561174],
						[77.58227608825564, 12.95795593908708],
						[77.58321251812374, 12.959752936061081],
						[77.58424705031157, 12.961746172827082],
						[77.58519684238323, 12.963783999994348],
						[77.58565608348701, 12.964301249212918],
						[77.58648108638064, 12.965193048656452],
						[77.58677532283966, 12.965589934873579],
						[77.58665937715261, 12.965656779018794],
						[77.58662370153267, 12.965732573150866],
						[77.58661478262593, 12.9659288101274],
						[77.58732383351577, 12.966798318168562],
						[77.58729707695919, 12.966820654779996],
						[77.58725694211245, 12.966847348295472],
						[77.58724356382703, 12.966851791421393],
						[77.58700721385685, 12.96701682285493],
						[77.58649446475633, 12.96722640439435],
						[77.58564270501208, 12.967623232201355],
						[77.58527265401924, 12.967770444518779],
						[77.58520130189262, 12.967815034442598],
						[77.58510319264501, 12.967895238591623],
						[77.58495602861392, 12.968082581171682],
						[77.58477318818716, 12.968296572822199],
						[77.58449232512866, 12.968546282768502],
						[77.5843540793881, 12.968702325489387],
						[77.58437637709964, 12.969919722294382],
						[77.58439867480676, 12.97039234339268],
						[77.58432732212847, 12.970459264853295],
						[77.58430502440729, 12.970472590262801],
						[77.58317238182664, 12.971631971054295],
						[77.58276664590981, 12.972042250307657],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000086",
			properties: {
				AREA_SQ_KM: 1.1034308465297196,
				ASS_CONST1: "Chickpet",
				ASS_CONST_: "169",
				LAT: 12.963133327422,
				LON: 77.580510578405,
				LOST_AREA_SQ_KM: 0.022275005662237432,
				OBJECTID: 135,
				POP_F: 15006.0,
				POP_M: 18193.0,
				POP_SC: 2037.0,
				POP_ST: 108.0,
				POP_TOTAL: 33199.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Dharmaraya Swamy Temple",
				WARD_NO: 119.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				geometries: [
					{
						coordinates: [
							[77.56396258809488, 12.939223236442897],
							[77.56394921079172, 12.939205400038684],
						],
						type: "LineString",
					},
					{
						coordinates: [
							[
								[77.56168841307218, 12.95331847559541],
								[77.56169287350788, 12.953037494803896],
								[77.56174639872253, 12.952497941954524],
								[77.56175531958918, 12.95237311889251],
								[77.56177316132039, 12.952034253369453],
								[77.56177316132039, 12.951891501869719],
								[77.56178654261696, 12.95173991295063],
								[77.56181330520532, 12.95161508819018],
								[77.56187129079179, 12.951450132051084],
								[77.56196041186911, 12.9512806418763],
								[77.56209422454252, 12.951079944601842],
								[77.56220127456753, 12.950923903834132],
								[77.56221911622852, 12.950861491100909],
								[77.56224141830083, 12.950763433290033],
								[77.56223249747244, 12.95046017689256],
								[77.56224141830081, 12.950286307077773],
								[77.56220127456753, 12.950058870484865],
								[77.56214328914987, 12.949934016361558],
								[77.56206300159953, 12.949849292248334],
								[77.56197379314357, 12.94977344820596],
								[77.56176424045513, 12.949621898299666],
								[77.56163051485173, 12.949514860362795],
								[77.56159037090207, 12.949439052597006],
								[77.5615725291421, 12.949376645879635],
								[77.56157698958235, 12.949323071547534],
								[77.56186683036312, 12.949109032336457],
								[77.56214774956767, 12.94890844134422],
								[77.56225471208302, 12.948979709075298],
								[77.56241082644796, 12.949051103060663],
								[77.56248219294316, 12.949077814630959],
								[77.56258023434187, 12.94909124307676],
								[77.56266498192325, 12.949082308617712],
								[77.56275865022896, 12.949077855219151],
								[77.5628255561142, 12.94906004469576],
								[77.56289237450136, 12.949028782619209],
								[77.56296820107886, 12.949006488255165],
								[77.56303064645752, 12.948970791676818],
								[77.56338738907448, 12.948819192016044],
								[77.56374413056844, 12.948685435184899],
								[77.56390907612102, 12.948649761153437],
								[77.56407848177533, 12.948631972924858],
								[77.56414538688136, 12.948591835907378],
								[77.56416768857459, 12.94857394572431],
								[77.56419891093775, 12.948542779213081],
								[77.56422121262045, 12.948511527915356],
								[77.56424351429878, 12.94847588038429],
								[77.56425689530366, 12.948359895284806],
								[77.56426581597269, 12.948114653389563],
								[77.56423013329231, 12.947628662148235],
								[77.56421229194791, 12.947111379626234],
								[77.56423459362797, 12.946527245291358],
								[77.56423013329231, 12.945528416432063],
								[77.56425689530366, 12.943700155427905],
								[77.56427027630694, 12.942723672330374],
								[77.56427027630694, 12.941894206630518],
								[77.56427027630694, 12.941858607745433],
								[77.56428365730866, 12.94181839499462],
								[77.56430149864185, 12.941800635455994],
								[77.56433272096814, 12.941791708580713],
								[77.56438624493605, 12.941791698952699],
								[77.56458687213323, 12.94178721943448],
								[77.5648900861464, 12.941787246333107],
								[77.56499267322474, 12.941787184507398],
								[77.56502835566498, 12.941787264178112],
								[77.56505949033405, 12.941769368587938],
								[77.56509071245148, 12.941733720833096],
								[77.56509071245148, 12.941706913893004],
								[77.56509071245148, 12.941675710967235],
								[77.56507287124258, 12.941568745164474],
								[77.56501051444626, 12.941158469474765],
								[77.56499267322474, 12.941069346647126],
								[77.56496145108028, 12.941002421682928],
								[77.56490792738408, 12.940882016705691],
								[77.56431487963987, 12.939892144187665],
								[77.56406518820707, 12.939410541622083],
								[77.56396260028903, 12.939223218247804],
								[77.56399828305366, 12.939245535814488],
								[77.5640295054635, 12.939258933149507],
								[77.5640741088912, 12.939276766977196],
								[77.56414092654218, 12.93928567136627],
								[77.56436394328581, 12.939276751675001],
								[77.56449320533865, 12.939285644244181],
								[77.5647428958283, 12.939294557083544],
								[77.565269124386, 12.93931686313348],
								[77.56591569038099, 12.939325823363403],
								[77.565897849302, 12.939740508833006],
								[77.5659290711884, 12.939878716106206],
								[77.56597367386833, 12.939994639599274],
								[77.56602264933979, 12.940074916530197],
								[77.56610739434663, 12.94016855109481],
								[77.56618767903147, 12.94021318466855],
								[77.56666037801025, 12.940222052985238],
								[77.56671381342291, 12.941002452939582],
								[77.56672719413604, 12.941149543669633],
								[77.56674057484759, 12.941220954468843],
								[77.56676733626595, 12.94130119784048],
								[77.56682531931737, 12.941430523993274],
								[77.56695912624585, 12.941617802779799],
								[77.56715528866643, 12.941827390619078],
								[77.56764582441404, 12.942438255190424],
								[77.56770826721468, 12.9425229749784],
								[77.56776170213301, 12.9426076923548],
								[77.56780630409136, 12.942692415736209],
								[77.56783752545176, 12.942803906083107],
								[77.56785536622527, 12.943013448886614],
								[77.56785982641821, 12.943401381092285],
								[77.56786874680355, 12.943526280233268],
								[77.56793118948131, 12.94357979730554],
								[77.56798917193697, 12.943615429148247],
								[77.56807837565697, 12.943651098130754],
								[77.56833251841913, 12.943668931297166],
								[77.57016519792914, 12.943668937949363],
								[77.57110164058281, 12.943664515817792],
								[77.57230558942058, 12.943677872535929],
								[77.57343370593387, 12.943682317432867],
								[77.57361656469119, 12.943686803417137],
								[77.57377257594645, 12.943695687042291],
								[77.57375473610527, 12.94453846819742],
								[77.5737146839026, 12.946250819099014],
								[77.57374135622256, 12.948547241086438],
								[77.57375473610527, 12.951851411986196],
								[77.57372797633826, 12.953189164159781],
								[77.57234126955689, 12.953229307699102],
								[77.57115516135221, 12.953255997659596],
								[77.56986199803633, 12.953256061669306],
								[77.56979509630659, 12.95401403870845],
								[77.56752093870946, 12.953965037233656],
								[77.5674807968466, 12.955106540101175],
								[77.56625904314802, 12.95504855208006],
								[77.56566600247588, 12.954981708720785],
								[77.56503718881635, 12.955097620983024],
								[77.5644307607659, 12.955280470946436],
								[77.56449766566395, 12.955654983098633],
								[77.56456903084499, 12.95606971121697],
								[77.56458687213323, 12.956306086815538],
								[77.56495253046602, 12.956288187550912],
								[77.56497929230673, 12.956743010817409],
								[77.56496145108028, 12.957032927566472],
								[77.56349435041989, 12.95702395825478],
								[77.56344537385561, 12.957024011918788],
								[77.5633918494433, 12.957010570579358],
								[77.56336062685779, 12.956988290171447],
								[77.56335616648772, 12.956943678787999],
								[77.56335170611749, 12.95687234336941],
								[77.56336954759739, 12.95643536052751],
								[77.56340969091688, 12.95497722772331],
								[77.56342753238765, 12.954192415393694],
								[77.56342307202023, 12.954054186906907],
								[77.56342307202023, 12.95400513651964],
								[77.56342307202023, 12.953978413118673],
								[77.56340523054875, 12.953942726928046],
								[77.56336062685779, 12.953933854039088],
								[77.56311539370209, 12.953880316016418],
								[77.56295928030765, 12.95382676142745],
								[77.56286115177824, 12.953808930547412],
								[77.56280771454865, 12.95380006301914],
								[77.56279879376484, 12.953800064233544],
								[77.56277649180223, 12.953791145132724],
								[77.56273188786379, 12.95375994528325],
								[77.56263375913748, 12.95370642584001],
								[77.56250003455995, 12.95359490015378],
								[77.56237960359218, 12.95347005006322],
								[77.56231269744366, 12.953434413814609],
								[77.56223695788673, 12.953416540965602],
								[77.56206300159953, 12.953407642243922],
								[77.56168841307218, 12.95338976596846],
								[77.56168841307218, 12.95331847559541],
							],
						],
						type: "Polygon",
					},
				],
				type: "GeometryCollection",
			},
			id: "00000000000000000087",
			properties: {
				AREA_SQ_KM: 1.4999568929089182,
				ASS_CONST1: "Chickpet",
				ASS_CONST_: "169",
				LAT: 12.9486914803666,
				LON: 77.5678744056857,
				LOST_AREA_SQ_KM: 0.04656412392631979,
				OBJECTID: 136,
				POP_F: 17513.0,
				POP_M: 18645.0,
				POP_SC: 1966.0,
				POP_ST: 243.0,
				POP_TOTAL: 36158.0,
				RESERVATIO: "General",
				WARD_NAME: "Sunkenahalli",
				WARD_NO: 142.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56496145108028, 12.957032927566472],
						[77.56497929230673, 12.956743010817409],
						[77.56495253046602, 12.956288187550912],
						[77.56458687213323, 12.956306086815538],
						[77.56456903084499, 12.95606971121697],
						[77.56449766566395, 12.955654983098633],
						[77.5644307607659, 12.955280470946436],
						[77.56503718881635, 12.955097620983024],
						[77.56566600247588, 12.954981708720785],
						[77.56625904314802, 12.95504855208006],
						[77.5674807968466, 12.955106540101175],
						[77.56752093870946, 12.953965037233656],
						[77.56979509630659, 12.95401403870845],
						[77.56986199803633, 12.953256061669306],
						[77.57115516135221, 12.953255997659596],
						[77.57234126955689, 12.953229307699102],
						[77.57372797633826, 12.953189164159781],
						[77.57375473610527, 12.951851411986196],
						[77.57374135622256, 12.948547241086438],
						[77.5737146839026, 12.946250819099014],
						[77.57375473610527, 12.94453846819742],
						[77.57508808158344, 12.944560797441394],
						[77.57651498083209, 12.944511761962419],
						[77.57654173994008, 12.944507273430562],
						[77.57676910465483, 12.944489416895948],
						[77.57693857851808, 12.94446713824831],
						[77.57697871702746, 12.944467172145417],
						[77.57705453417299, 12.944467111640511],
						[77.57712589144002, 12.944453734061357],
						[77.5771971612145, 12.944440352249265],
						[77.57726851839156, 12.944413613439215],
						[77.57733987552363, 12.94438239214176],
						[77.57740231297728, 12.944346776625416],
						[77.57746029058195, 12.944302154108412],
						[77.57751826815691, 12.944253135322215],
						[77.57756732608175, 12.944199636146427],
						[77.57761192417678, 12.944141612454706],
						[77.57765206244727, 12.944079236658986],
						[77.57768328109225, 12.944012294310834],
						[77.57770558011912, 12.943940957827836],
						[77.57772333189028, 12.943869575117134],
						[77.5777367113022, 12.943798240793658],
						[77.5777367113022, 12.943726952753222],
						[77.5777367113022, 12.943668939664006],
						[77.57774117110581, 12.943624329757377],
						[77.57843681087037, 12.943606489473215],
						[77.57998416770204, 12.9436333027426],
						[77.58005106340869, 12.94363332939957],
						[77.58019814637883, 12.94363772831596],
						[77.58096958555451, 12.943664470458536],
						[77.58256596349035, 12.943673453000057],
						[77.5844388106685, 12.943677868129564],
						[77.58520576140187, 12.943691278668402],
						[77.58522359943703, 12.943998931664005],
						[77.58532170858015, 12.944636550186827],
						[77.58533508709306, 12.944757015945516],
						[77.58537522262232, 12.944872905916911],
						[77.58545549363805, 12.94497546685866],
						[77.5855312176595, 12.94506462020904],
						[77.5857274353265, 12.945247492470209],
						[77.5860128422341, 12.945465943527292],
						[77.58638743770655, 12.945688939890642],
						[77.58672626890966, 12.945831599195529],
						[77.58703842991898, 12.945947546394322],
						[77.58714545635225, 12.945987688847765],
						[77.58775185012003, 12.94616605009189],
						[77.58789455110758, 12.94619730351958],
						[77.58841184067677, 12.946259660078622],
						[77.58921889861143, 12.946286456942497],
						[77.58970496680034, 12.94629981161417],
						[77.59014198045081, 12.946585202232587],
						[77.59091335176011, 12.947378897960094],
						[77.59133252366772, 12.947624210018631],
						[77.59226896592662, 12.948061185665864],
						[77.59299581813708, 12.948524903830286],
						[77.59344173742308, 12.948917281439556],
						[77.59333917614323, 12.94911356544128],
						[77.59322769638555, 12.949367698374218],
						[77.5931652676732, 12.94947021886742],
						[77.59304486934472, 12.949706595697704],
						[77.59287541962794, 12.94997415142921],
						[77.59283528676305, 12.950054380656173],
						[77.59274610256776, 12.950148029418223],
						[77.59250530488869, 12.950384397752666],
						[77.59219315898498, 12.950696478012711],
						[77.59190330844474, 12.950946179088511],
						[77.5917829087709, 12.951039879428587],
						[77.59172939776354, 12.951075498896248],
						[77.59169372374456, 12.951093354195619],
						[77.59165804971431, 12.951120089100451],
						[77.59144400529615, 12.951204806150292],
						[77.59091335176012, 12.951409915167213],
						[77.5902713004926, 12.95163294528424],
						[77.58941065053206, 12.951949521143826],
						[77.58866156582806, 12.952199212417813],
						[77.58743977852107, 12.952627302366363],
						[77.58709640258292, 12.952752177413299],
						[77.58701613273263, 12.952796767978967],
						[77.5869759977861, 12.952827942863829],
						[77.58693140338436, 12.952863644871986],
						[77.58682437674837, 12.952934967089732],
						[77.5866103231723, 12.953117785199229],
						[77.58636959985506, 12.953354150367392],
						[77.58574527327936, 12.953858028226827],
						[77.58562932653558, 12.953947197563515],
						[77.58549562912455, 12.954009650108691],
						[77.5854198176381, 12.954045316204056],
						[77.58525035648451, 12.954085444399535],
						[77.58510319264501, 12.954116644382934],
						[77.58471521433155, 12.954192471732332],
						[77.58422475257453, 12.954352941688793],
						[77.58352468883713, 12.954580400551169],
						[77.5832794119206, 12.954642829534846],
						[77.58304751325561, 12.954691849475424],
						[77.58289597439138, 12.954714130114821],
						[77.58256150387697, 12.954754249777022],
						[77.58193278407526, 12.954803317813479],
						[77.58116135147017, 12.954888019529797],
						[77.58087593231474, 12.954928168757677],
						[77.58043451058475, 12.955030727837071],
						[77.58018030755079, 12.95511996578954],
						[77.58004660369615, 12.955164482484294],
						[77.57975226227929, 12.95524480557131],
						[77.57936881279021, 12.955325064673069],
						[77.57917258408818, 12.955369675640688],
						[77.5789986538177, 12.955427601212866],
						[77.57867763852367, 12.9555747447248],
						[77.57814701249896, 12.955855684138765],
						[77.57765652225422, 12.956127691410261],
						[77.57743799150674, 12.956252523051669],
						[77.57731311660439, 12.956283755400813],
						[77.57719270138944, 12.956310546989977],
						[77.57685830145661, 12.95641307626191],
						[77.57604678286586, 12.956622642712322],
						[77.57531990911806, 12.956818866290856],
						[77.57484724628972, 12.956957064908128],
						[77.57396435406143, 12.957202325660798],
						[77.57382609545307, 12.957242480640607],
						[77.5736923840893, 12.957278112936034],
						[77.57362548462302, 12.957296013150543],
						[77.57357196502151, 12.957309384998283],
						[77.57348722560073, 12.957318236267763],
						[77.57309929489332, 12.957304902172266],
						[77.57185521418421, 12.957251374540805],
						[77.57001355435766, 12.957197853955186],
						[77.56974603501298, 12.957193417639376],
						[77.56836819980738, 12.957131017032209],
						[77.56669159968651, 12.95709085420466],
						[77.56496145108028, 12.957032927566472],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000088",
			properties: {
				AREA_SQ_KM: 2.4701022844956073,
				ASS_CONST1: "Chickpet",
				ASS_CONST_: "169",
				LAT: 12.9504863664593,
				LON: 77.5798830579196,
				LOST_AREA_SQ_KM: 0.0706857024883794,
				OBJECTID: 137,
				POP_F: 17731.0,
				POP_M: 18362.0,
				POP_SC: 1744.0,
				POP_ST: 188.0,
				POP_TOTAL: 36093.0,
				RESERVATIO: "General",
				WARD_NAME: "Vishveshwara Puram",
				WARD_NO: 143.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58703842991898, 12.945947546394322],
						[77.58710086201737, 12.94515832682589],
						[77.58705180822868, 12.94376257247513],
						[77.58900939636898, 12.943758180078826],
						[77.58907182737836, 12.943566445201256],
						[77.58910304287011, 12.943352349742254],
						[77.58913417091492, 12.943138336631579],
						[77.58915200833, 12.943075920293026],
						[77.58920106120692, 12.942995607769681],
						[77.58923227666297, 12.942964436260015],
						[77.58926795145932, 12.942937702918751],
						[77.58933930101821, 12.94289311514722],
						[77.58941510987518, 12.9428663270079],
						[77.58943740658812, 12.942852915921234],
						[77.58952659339595, 12.942844010999632],
						[77.5895890241195, 12.942843992781937],
						[77.58966037347564, 12.942875263717301],
						[77.5897183447943, 12.942924253340884],
						[77.58986550262348, 12.943004594924318],
						[77.59009738729863, 12.943093747543136],
						[77.59066817831717, 12.942737041385943],
						[77.59121658265741, 12.94247839238841],
						[77.59126117536776, 12.942456052309552],
						[77.59123441974366, 12.94228218830627],
						[77.59120766411323, 12.94217073564509],
						[77.591707101503, 12.942023521853143],
						[77.59297352212654, 12.941657889383201],
						[77.59344173742308, 12.941524171236612],
						[77.59324999234589, 12.941305663255475],
						[77.59293784850054, 12.940984569047066],
						[77.59283974597096, 12.940882018553154],
						[77.59266583673178, 12.940708159302277],
						[77.59260786692589, 12.94064124123749],
						[77.59259003005654, 12.940605559028707],
						[77.5925677339659, 12.940565438807027],
						[77.5925543563094, 12.940494110590697],
						[77.5925365194316, 12.940418258201692],
						[77.59252760099166, 12.940364729435514],
						[77.59251422333041, 12.940150736954575],
						[77.59251422333041, 12.94013289314139],
						[77.59252760099166, 12.939816312554012],
						[77.59257219318437, 12.939303485299956],
						[77.59259448927413, 12.938839758724704],
						[77.59262570379241, 12.937979163589937],
						[77.59314743089189, 12.938795188652593],
						[77.59319202283986, 12.938862066493302],
						[77.59345511497446, 12.939125111778152],
						[77.59383860410661, 12.939361439586897],
						[77.59421754534863, 12.939517554021334],
						[77.59461440930535, 12.939571086200184],
						[77.59543934677419, 12.939718179649303],
						[77.59638913217816, 12.939851954257385],
						[77.59731670186974, 12.939936730885563],
						[77.59763775164524, 12.939990241141095],
						[77.59759762047325, 12.94020425436403],
						[77.59756640732961, 12.940556535459335],
						[77.59750398101644, 12.941426009221917],
						[77.59736575120012, 12.942335728613344],
						[77.59724535735117, 12.943200793945737],
						[77.59713834048877, 12.94403909364876],
						[77.59694214264415, 12.944828289467084],
						[77.59654074037627, 12.94534556703272],
						[77.59591647001825, 12.945693349453574],
						[77.5955508243482, 12.945934153131427],
						[77.59526990065088, 12.946166093864635],
						[77.59489087550699, 12.946495995714681],
						[77.59472142856049, 12.94666104812002],
						[77.59457427705851, 12.946812602245494],
						[77.59442712536476, 12.946995449661882],
						[77.5943424015753, 12.947106898442938],
						[77.59424430026597, 12.947254084327424],
						[77.59417304124007, 12.947401266465334],
						[77.59393224660383, 12.9479140166503],
						[77.59369145145409, 12.948453578869767],
						[77.59344173742308, 12.948917281439556],
						[77.59299581813708, 12.948524903830286],
						[77.59226896592662, 12.948061185665864],
						[77.59133252366772, 12.947624210018631],
						[77.59091335176011, 12.947378897960094],
						[77.59014198045081, 12.946585202232587],
						[77.58970496680034, 12.94629981161417],
						[77.58921889861143, 12.946286456942497],
						[77.58841184067677, 12.946259660078622],
						[77.58789455110758, 12.94619730351958],
						[77.58775185012003, 12.94616605009189],
						[77.58714545635225, 12.945987688847765],
						[77.58703842991898, 12.945947546394322],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000089",
			properties: {
				AREA_SQ_KM: 0.6656286996143098,
				ASS_CONST1: "Chickpet",
				ASS_CONST_: "169",
				LAT: 12.9438446355212,
				LON: 77.5930164521246,
				LOST_AREA_SQ_KM: 0.025670317994151405,
				OBJECTID: 138,
				POP_F: 15463.0,
				POP_M: 16731.0,
				POP_SC: 4498.0,
				POP_ST: 186.0,
				POP_TOTAL: 32194.0,
				RESERVATIO: "General",
				WARD_NAME: "Siddapura",
				WARD_NO: 144.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59227788438702, 12.956105417496504],
						[77.59201478950979, 12.956252524124517],
						[77.59194790088397, 12.956502259827545],
						[77.59186317523438, 12.956778726926386],
						[77.59167588673084, 12.957135443342644],
						[77.59143508676992, 12.957567956494277],
						[77.59135482000221, 12.95763039629664],
						[77.59118536774969, 12.957724068412848],
						[77.59064142255262, 12.957889052173135],
						[77.5898387466688, 12.958125393411978],
						[77.58893358723978, 12.958401806656429],
						[77.5880238737219, 12.958691704689162],
						[77.58721680725144, 12.95895032575525],
						[77.58680653896585, 12.959075172747523],
						[77.5864855458394, 12.958473217524915],
						[77.58622689694181, 12.957661662992056],
						[77.5859682474523, 12.95664492936241],
						[77.58566054297829, 12.955289363290607],
						[77.58545995313726, 12.95454468113892],
						[77.5854198176381, 12.954045316204056],
						[77.58549562912455, 12.954009650108691],
						[77.58562932653558, 12.953947197563515],
						[77.58574527327936, 12.953858028226827],
						[77.58636959985506, 12.953354150367392],
						[77.5866103231723, 12.953117785199229],
						[77.58682437674837, 12.952934967089732],
						[77.58693140338436, 12.952863644871986],
						[77.5869759977861, 12.952827942863829],
						[77.58701613273263, 12.952796767978967],
						[77.58709640258292, 12.952752177413299],
						[77.58743977852107, 12.952627302366363],
						[77.58866156582806, 12.952199212417813],
						[77.58941065053206, 12.951949521143826],
						[77.5902713004926, 12.95163294528424],
						[77.59091335176012, 12.951409915167213],
						[77.59144400529615, 12.951204806150292],
						[77.59165804971431, 12.951120089100451],
						[77.59169372374456, 12.951093354195619],
						[77.59172939776354, 12.951075498896248],
						[77.5917829087709, 12.951039879428587],
						[77.59190330844474, 12.950946179088511],
						[77.59219315898498, 12.950696478012711],
						[77.59250530488869, 12.950384397752666],
						[77.59274610256776, 12.950148029418223],
						[77.59283528676305, 12.950054380656173],
						[77.59287541962794, 12.94997415142921],
						[77.59304486934472, 12.949706595697704],
						[77.5931652676732, 12.94947021886742],
						[77.59322769638555, 12.949367698374218],
						[77.59333917614323, 12.94911356544128],
						[77.59344173742308, 12.948917281439556],
						[77.59369145145409, 12.948453578869767],
						[77.59393224660383, 12.9479140166503],
						[77.59417304124007, 12.947401266465334],
						[77.59424430026597, 12.947254084327424],
						[77.5943424015753, 12.947106898442938],
						[77.59442712536476, 12.946995449661882],
						[77.59457427705851, 12.946812602245494],
						[77.59472142856049, 12.94666104812002],
						[77.59489087550699, 12.946495995714681],
						[77.59526990065088, 12.946166093864635],
						[77.5955508243482, 12.945934153131427],
						[77.59591647001825, 12.945693349453574],
						[77.59654074037627, 12.94534556703272],
						[77.59694214264415, 12.944828289467084],
						[77.59713834048877, 12.94403909364876],
						[77.59724535735117, 12.943200793945737],
						[77.59762437458949, 12.943102697509257],
						[77.59806135759044, 12.942973331631439],
						[77.59826647147678, 12.942924299029878],
						[77.598493879915, 12.942879698369717],
						[77.59872128789497, 12.942844062811792],
						[77.59905571056241, 12.942803908426834],
						[77.59944363961503, 12.942737011649271],
						[77.59982264947038, 12.942661237896171],
						[77.60009018507236, 12.94261218206258],
						[77.60039793766, 12.942563073207184],
						[77.60076356762616, 12.942505103614131],
						[77.6010756898305, 12.942464996441332],
						[77.60123175060883, 12.94244714091713],
						[77.60148590627149, 12.942393648108986],
						[77.60190503892055, 12.942304451100773],
						[77.60218603352916, 12.942259917587597],
						[77.60266758706773, 12.942161817673728],
						[77.60282364564377, 12.942830681208893],
						[77.60287269258022, 12.943187344636346],
						[77.60287715139158, 12.943370231261351],
						[77.60289498663519, 12.943686777867097],
						[77.60287269258022, 12.944101486710993],
						[77.60284148089586, 12.944426974897258],
						[77.60275676342334, 12.94463213605128],
						[77.60260962239877, 12.945238532151484],
						[77.6025828694646, 12.945604237883485],
						[77.60257841064161, 12.945635403843413],
						[77.60251598710138, 12.94637121841844],
						[77.60247139883721, 12.94687952403923],
						[77.60245802235453, 12.94709354398076],
						[77.60245802235453, 12.947231824439172],
						[77.60247139883721, 12.947392299608161],
						[77.60248477531832, 12.94758406926302],
						[77.60251152827577, 12.947789154232392],
						[77.60256057534794, 12.947976429663875],
						[77.6025828694646, 12.948052244244835],
						[77.60268096352556, 12.948315325973288],
						[77.60287715139158, 12.94884597451496],
						[77.60320710294211, 12.949693236806791],
						[77.6034167536908, 12.950437914653264],
						[77.60343458884908, 12.950504723376676],
						[77.60335878940684, 12.950504791629962],
						[77.6029797040039, 12.950406673839788],
						[77.60227521026547, 12.95021939413519],
						[77.6009775943736, 12.949880525694097],
						[77.60057629388909, 12.949769011250492],
						[77.60046928018548, 12.94974671689979],
						[77.60043360892838, 12.949751211314643],
						[77.60038010202159, 12.949760108202593],
						[77.6003578074696, 12.949760072235296],
						[77.60033105400142, 12.949773443521151],
						[77.60030875943974, 12.949786856482628],
						[77.6002730881319, 12.949813593337002],
						[77.60018836873057, 12.949862631422903],
						[77.60011247967724, 12.949907265663132],
						[77.59975576547076, 12.950170323058716],
						[77.59971117611566, 12.950206028612795],
						[77.59911367705733, 12.950692017156163],
						[77.59893531851631, 12.950839235662164],
						[77.59884168016943, 12.950928364333793],
						[77.59881938531348, 12.950950656929537],
						[77.598890728837, 12.951022018070834],
						[77.59714725856452, 12.952377583254531],
						[77.59554190614632, 12.953670717184501],
						[77.59518517749385, 12.953938250878988],
						[77.59335255370675, 12.955325027064596],
						[77.59240274275835, 12.956016239595296],
						[77.59239828353319, 12.956016197668921],
						[77.59227788438702, 12.956105417496504],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000008a",
			properties: {
				AREA_SQ_KM: 1.4233492225424345,
				ASS_CONST1: "Chickpet",
				ASS_CONST_: "169",
				LAT: 12.9505957757457,
				LON: 77.5948740249626,
				LOST_AREA_SQ_KM: 0.04872849194467582,
				OBJECTID: 139,
				POP_F: 17762.0,
				POP_M: 18693.0,
				POP_SC: 3979.0,
				POP_ST: 221.0,
				POP_TOTAL: 36455.0,
				RESERVATIO: "General",
				WARD_NAME: "Hombegowda Nagara",
				WARD_NO: 145.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57377257594645, 12.943695687042291],
						[77.57375473610527, 12.94273698200558],
						[77.57377257594645, 12.94145727501715],
						[77.57379487574396, 12.940694721498017],
						[77.57379933570292, 12.94063227042678],
						[77.57402679337758, 12.94063674414813],
						[77.5762518492662, 12.940601079639338],
						[77.57765652225422, 12.940587759725048],
						[77.57775009071251, 12.94058768952149],
						[77.57775901031852, 12.940257720143896],
						[77.5777545505156, 12.940128467103849],
						[77.5777367113022, 12.940030291894143],
						[77.57766990167403, 12.939901058716803],
						[77.57766990167403, 12.939771675710304],
						[77.57768328109225, 12.939669140135127],
						[77.57791510330972, 12.939379281782758],
						[77.5791592048461, 12.937827536668209],
						[77.57973888310576, 12.937114103697587],
						[77.57993957054227, 12.93687774316503],
						[77.58001538570339, 12.936824238181634],
						[77.58010003278979, 12.936761848373367],
						[77.58017584784335, 12.936752906794798],
						[77.58030071958548, 12.93675287335684],
						[77.58060843864722, 12.936748437735226],
						[77.58288259558981, 12.936721682720615],
						[77.58490251437327, 12.936645887966241],
						[77.58519684238323, 12.93663692424481],
						[77.58542873663914, 12.936614703921162],
						[77.58560256957782, 12.936583488459583],
						[77.58578094917658, 12.93652102910312],
						[77.58593257161418, 12.936476420349692],
						[77.58613324805707, 12.93635159013453],
						[77.5866950527613, 12.935887848716797],
						[77.5870830242785, 12.935651508163575],
						[77.58727923925129, 12.935522152830812],
						[77.58739518430234, 12.935441954168196],
						[77.58753788574032, 12.93533493995515],
						[77.58760923639173, 12.935281389716938],
						[77.58768950582073, 12.935236801224205],
						[77.58776522834525, 12.935223456324353],
						[77.58779644419799, 12.935223403646118],
						[77.58784549766344, 12.935223431681939],
						[77.58792130752269, 12.935250172607462],
						[77.58797927973369, 12.935272436757435],
						[77.58809968346148, 12.935330453550717],
						[77.58844305635087, 12.935513301621997],
						[77.58860359396714, 12.935620309380273],
						[77.58880872503376, 12.935727303401743],
						[77.58892912787766, 12.93577635570146],
						[77.58901831508672, 12.93579417095585],
						[77.58908966480327, 12.935798631059754],
						[77.58916984574225, 12.935798601874465],
						[77.58922781731263, 12.935780783936453],
						[77.5893125449389, 12.935749596947389],
						[77.58947754066035, 12.935642616826792],
						[77.5896559141422, 12.935602478824697],
						[77.59004387549275, 12.935584642856753],
						[77.59017319564687, 12.936347155307434],
						[77.59062804466797, 12.93614203147672],
						[77.59106942658833, 12.936614644444337],
						[77.59110956008074, 12.936672600556832],
						[77.59138603486309, 12.936485374346724],
						[77.59157778310502, 12.936396140100587],
						[77.59174277551776, 12.93634268683887],
						[77.59200587102863, 12.93628471941472],
						[77.59243841655338, 12.936208853374906],
						[77.59270151044372, 12.936177692878562],
						[77.59272380650795, 12.93570501358018],
						[77.59271934729544, 12.934960302970236],
						[77.5926524590869, 12.933234692405255],
						[77.59260786692589, 12.931977186885813],
						[77.59254543787087, 12.930692980827699],
						[77.59250530488869, 12.929274965329707],
						[77.59250530488869, 12.928690830021155],
						[77.59252760099166, 12.928579330910683],
						[77.59254989709022, 12.928552646246699],
						[77.59259003005654, 12.928530351221815],
						[77.59264354065611, 12.928521368405736],
						[77.59348632925484, 12.928508013895298],
						[77.59538583749956, 12.928458975261313],
						[77.5955062333318, 12.928458974718534],
						[77.59715171760212, 12.928427733686624],
						[77.59869453403876, 12.928418795951938],
						[77.60051832814555, 12.928347467070292],
						[77.60051386924097, 12.928610580616532],
						[77.6005272459542, 12.928976260954677],
						[77.60061642400177, 12.930305030411885],
						[77.60070560197885, 12.931335054112122],
						[77.60071897866933, 12.931723068535138],
						[77.60074127314995, 12.931928168377592],
						[77.60078140320395, 12.932195669475417],
						[77.6009240877248, 12.932984936911806],
						[77.60124958611262, 12.93455455292565],
						[77.60129417485972, 12.934897872277194],
						[77.60130755148042, 12.934955837149424],
						[77.60136997568937, 12.935771877128765],
						[77.60139672891121, 12.93614201547458],
						[77.60142348212668, 12.93628915154645],
						[77.60152603606062, 12.93675731853455],
						[77.60159291901077, 12.93712748871812],
						[77.60088395772155, 12.937216663131938],
						[77.60050049252618, 12.937270160114513],
						[77.60030875943974, 12.937310269619637],
						[77.60009018507236, 12.937363831774729],
						[77.59984940305912, 12.937435120278348],
						[77.59961307947242, 12.937528776278876],
						[77.599300953222, 12.937707152098222],
						[77.599082464333, 12.93785433257272],
						[77.59891302367886, 12.937992487572698],
						[77.5987435827703, 12.938157537536714],
						[77.5984983388994, 12.938451787816],
						[77.59836902828016, 12.938621263246306],
						[77.59805689858878, 12.93909393282452],
						[77.59787853837754, 12.939397168814983],
						[77.59773139099106, 12.93982077715325],
						[77.59763775164524, 12.939990241141095],
						[77.59731670186974, 12.939936730885563],
						[77.59638913217816, 12.939851954257385],
						[77.59543934677419, 12.939718179649303],
						[77.59461440930535, 12.939571086200184],
						[77.59421754534863, 12.939517554021334],
						[77.59383860410661, 12.939361439586897],
						[77.59345511497446, 12.939125111778152],
						[77.59319202283986, 12.938862066493302],
						[77.59314743089189, 12.938795188652593],
						[77.59262570379241, 12.937979163589937],
						[77.59259448927413, 12.938839758724704],
						[77.59257219318437, 12.939303485299956],
						[77.59252760099166, 12.939816312554012],
						[77.59251422333041, 12.94013289314139],
						[77.59251422333041, 12.940150736954575],
						[77.59252760099166, 12.940364729435514],
						[77.5925365194316, 12.940418258201692],
						[77.5925543563094, 12.940494110590697],
						[77.5925677339659, 12.940565438807027],
						[77.59259003005654, 12.940605559028707],
						[77.59260786692589, 12.94064124123749],
						[77.59266583673178, 12.940708159302277],
						[77.59283974597096, 12.940882018553154],
						[77.59293784850054, 12.940984569047066],
						[77.59324999234589, 12.941305663255475],
						[77.59344173742308, 12.941524171236612],
						[77.59297352212654, 12.941657889383201],
						[77.591707101503, 12.942023521853143],
						[77.59120766411323, 12.94217073564509],
						[77.59123441974366, 12.94228218830627],
						[77.59126117536776, 12.942456052309552],
						[77.59121658265741, 12.94247839238841],
						[77.59066817831717, 12.942737041385943],
						[77.59009738729863, 12.943093747543136],
						[77.58986550262348, 12.943004594924318],
						[77.5897183447943, 12.942924253340884],
						[77.58966037347564, 12.942875263717301],
						[77.5895890241195, 12.942843992781937],
						[77.58952659339595, 12.942844010999632],
						[77.58943740658812, 12.942852915921234],
						[77.58941510987518, 12.9428663270079],
						[77.58933930101821, 12.94289311514722],
						[77.58926795145932, 12.942937702918751],
						[77.58923227666297, 12.942964436260015],
						[77.58920106120692, 12.942995607769681],
						[77.58915200833, 12.943075920293026],
						[77.58913417091492, 12.943138336631579],
						[77.58910304287011, 12.943352349742254],
						[77.58907182737836, 12.943566445201256],
						[77.58900939636898, 12.943758180078826],
						[77.58705180822868, 12.94376257247513],
						[77.58710086201737, 12.94515832682589],
						[77.58703842991898, 12.945947546394322],
						[77.58672626890966, 12.945831599195529],
						[77.58638743770655, 12.945688939890642],
						[77.5860128422341, 12.945465943527292],
						[77.5857274353265, 12.945247492470209],
						[77.5855312176595, 12.94506462020904],
						[77.58545549363805, 12.94497546685866],
						[77.58537522262232, 12.944872905916911],
						[77.58533508709306, 12.944757015945516],
						[77.58532170858015, 12.944636550186827],
						[77.58522359943703, 12.943998931664005],
						[77.58520576140187, 12.943691278668402],
						[77.5844388106685, 12.943677868129564],
						[77.58256596349035, 12.943673453000057],
						[77.58096958555451, 12.943664470458536],
						[77.58019814637883, 12.94363772831596],
						[77.58005106340869, 12.94363332939957],
						[77.57998416770204, 12.9436333027426],
						[77.57843681087037, 12.943606489473215],
						[77.57774117110581, 12.943624329757377],
						[77.5777367113022, 12.943668939664006],
						[77.5777367113022, 12.943726952753222],
						[77.5777367113022, 12.943798240793658],
						[77.57772333189028, 12.943869575117134],
						[77.57770558011912, 12.943940957827836],
						[77.57768328109225, 12.944012294310834],
						[77.57765206244727, 12.944079236658986],
						[77.57761192417678, 12.944141612454706],
						[77.57756732608175, 12.944199636146427],
						[77.57751826815691, 12.944253135322215],
						[77.57746029058195, 12.944302154108412],
						[77.57740231297728, 12.944346776625416],
						[77.57733987552363, 12.94438239214176],
						[77.57726851839156, 12.944413613439215],
						[77.5771971612145, 12.944440352249265],
						[77.57712589144002, 12.944453734061357],
						[77.57705453417299, 12.944467111640511],
						[77.57697871702746, 12.944467172145417],
						[77.57693857851808, 12.94446713824831],
						[77.57676910465483, 12.944489416895948],
						[77.57654173994008, 12.944507273430562],
						[77.57651498083209, 12.944511761962419],
						[77.57508808158344, 12.944560797441394],
						[77.57375473610527, 12.94453846819742],
						[77.57377257594645, 12.943695687042291],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000008b",
			properties: {
				AREA_SQ_KM: 2.5042006870367044,
				ASS_CONST1: "Chickpet",
				ASS_CONST_: "169",
				LAT: 12.9376337000747,
				LON: 77.5894196695984,
				LOST_AREA_SQ_KM: 0.15340517392081468,
				OBJECTID: 140,
				POP_F: 17790.0,
				POP_M: 18173.0,
				POP_SC: 3743.0,
				POP_ST: 220.0,
				POP_TOTAL: 35963.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Jayanagar",
				WARD_NO: 153.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.55658722150491, 12.953247107281971],
						[77.55628398528346, 12.95283242692904],
						[77.5561547138702, 12.952636207110173],
						[77.55606104010857, 12.952502475641307],
						[77.55598975703552, 12.952386546724028],
						[77.55590054380122, 12.952176914932071],
						[77.55585147649245, 12.952061022851483],
						[77.55579348782744, 12.951891514872537],
						[77.55577118448686, 12.951802342459038],
						[77.55576226314939, 12.951766656275176],
						[77.55572220458626, 12.95160171968004],
						[77.55593622910337, 12.951543764786692],
						[77.5561190286368, 12.951481287616396],
						[77.55628844593177, 12.951427819756772],
						[77.55648917492378, 12.951360948152667],
						[77.55675226493399, 12.951267256421842],
						[77.55696628750741, 12.951213782725654],
						[77.55716692783676, 12.951137941255482],
						[77.55732742235209, 12.951039821460883],
						[77.55744785868663, 12.95094175368579],
						[77.55752368890573, 12.950812400422633],
						[77.55757266802837, 12.9507500241083],
						[77.55763065578371, 12.95067420363606],
						[77.55769310410247, 12.950616269233509],
						[77.55773771002337, 12.950576094317684],
						[77.55779114964277, 12.950540396508233],
						[77.55784021611238, 12.950504746550822],
						[77.55788036138992, 12.950469097687096],
						[77.5579517307371, 12.950437884265627],
						[77.5580854606797, 12.950393297734598],
						[77.5580854606797, 12.950192623202515],
						[77.5577154070651, 12.95015246540946],
						[77.55770202528805, 12.95014802775843],
						[77.5576574193531, 12.950139154664775],
						[77.55763957697421, 12.950139156872583],
						[77.55733188295936, 12.950081143197409],
						[77.55683692940853, 12.949969712166396],
						[77.55681462627314, 12.949960793171924],
						[77.55678340187619, 12.94996963241263],
						[77.55676109873026, 12.949992003948607],
						[77.55668981609718, 12.950063303992096],
						[77.55652031193821, 12.950174780617209],
						[77.55640442272603, 12.95022832867403],
						[77.5563152098179, 12.950263939722111],
						[77.55619485974435, 12.950295205488427],
						[77.55599421769539, 12.950339755252278],
						[77.55574450793648, 12.950375474923373],
						[77.55557946304101, 12.949577243181661],
						[77.55544573014356, 12.948921806142408],
						[77.55515587294953, 12.947472552602761],
						[77.55528960618871, 12.947298671804962],
						[77.55563745181543, 12.94685272587451],
						[77.5559050044646, 12.946500486510374],
						[77.55608334339216, 12.946281950166977],
						[77.55647579300202, 12.945791521323088],
						[77.55697966937129, 12.945144928603622],
						[77.557434476878, 12.944538467403358],
						[77.55750138590538, 12.94445377047453],
						[77.5570064330943, 12.944413637520963],
						[77.55652031193821, 12.941720340084744],
						[77.55786697963232, 12.941644528354686],
						[77.55789820373091, 12.941644566744925],
						[77.55871868599706, 12.941568750294946],
						[77.56016345853396, 12.94160435769499],
						[77.56179546348044, 12.941738182533422],
						[77.56335170611749, 12.941760434035821],
						[77.56428365730866, 12.94181839499462],
						[77.56427027630694, 12.941858607745433],
						[77.56427027630694, 12.941894206630518],
						[77.56427027630694, 12.942723672330374],
						[77.56425689530366, 12.943700155427905],
						[77.56423013329231, 12.945528416432063],
						[77.56423459362797, 12.946527245291358],
						[77.56421229194791, 12.947111379626234],
						[77.56423013329231, 12.947628662148235],
						[77.56426581597269, 12.948114653389563],
						[77.56425689530366, 12.948359895284806],
						[77.56424351429878, 12.94847588038429],
						[77.56422121262045, 12.948511527915356],
						[77.56419891093775, 12.948542779213081],
						[77.56416768857459, 12.94857394572431],
						[77.56414538688136, 12.948591835907378],
						[77.56407848177533, 12.948631972924858],
						[77.56390907612102, 12.948649761153437],
						[77.56374413056844, 12.948685435184899],
						[77.56338738907448, 12.948819192016044],
						[77.56303064645752, 12.948970791676818],
						[77.56296820107886, 12.949006488255165],
						[77.56289237450136, 12.949028782619209],
						[77.5628255561142, 12.94906004469576],
						[77.56275865022896, 12.949077855219151],
						[77.56266498192325, 12.949082308617712],
						[77.56258023434187, 12.94909124307676],
						[77.56248219294316, 12.949077814630959],
						[77.56241082644796, 12.949051103060663],
						[77.56225471208302, 12.948979709075298],
						[77.56214774956767, 12.94890844134422],
						[77.56186683036312, 12.949109032336457],
						[77.56157698958235, 12.949323071547534],
						[77.5615725291421, 12.949376645879635],
						[77.56159037090207, 12.949439052597006],
						[77.56163051485173, 12.949514860362795],
						[77.56176424045513, 12.949621898299666],
						[77.56197379314357, 12.94977344820596],
						[77.56206300159953, 12.949849292248334],
						[77.56214328914987, 12.949934016361558],
						[77.56220127456753, 12.950058870484865],
						[77.56224141830081, 12.950286307077773],
						[77.56223249747244, 12.95046017689256],
						[77.56224141830083, 12.950763433290033],
						[77.56221911622852, 12.950861491100909],
						[77.56220127456753, 12.950923903834132],
						[77.56209422454252, 12.951079944601842],
						[77.56196041186911, 12.9512806418763],
						[77.56187129079179, 12.951450132051084],
						[77.56181330520532, 12.95161508819018],
						[77.56178654261696, 12.95173991295063],
						[77.56177316132039, 12.951891501869719],
						[77.56177316132039, 12.952034253369453],
						[77.56175531958918, 12.95237311889251],
						[77.56174639872253, 12.952497941954524],
						[77.56169287350788, 12.953037494803896],
						[77.56168841307218, 12.95331847559541],
						[77.56148778074387, 12.953327342174434],
						[77.56124254353594, 12.95333630058426],
						[77.56109980893956, 12.953358559638195],
						[77.56098838487115, 12.95338086187081],
						[77.5608367290165, 12.953434413716735],
						[77.56060041135764, 12.953532504495605],
						[77.56034179076448, 12.953621675701278],
						[77.56016345853396, 12.953666269382154],
						[77.5600920905789, 12.953675157204684],
						[77.56002964358137, 12.953675164946535],
						[77.55998503856208, 12.953675170465514],
						[77.55991821846112, 12.953670743468601],
						[77.55976210066338, 12.95363054882577],
						[77.55931622355088, 12.95347010135221],
						[77.55892378377057, 12.953282747718593],
						[77.55876329151455, 12.953238204315106],
						[77.55864731713258, 12.95322037416938],
						[77.55860271156948, 12.953215896928917],
						[77.55856711456828, 12.953211422838494],
						[77.55851804841319, 12.953198024138084],
						[77.5584957456084, 12.953180225933236],
						[77.55849128504691, 12.953144581680577],
						[77.55849128504691, 12.953122255209347],
						[77.55850020616971, 12.953006311731468],
						[77.5585091272918, 12.952845801719684],
						[77.5580097182986, 12.952988447850448],
						[77.55756820743058, 12.953095523284274],
						[77.55735864659925, 12.953140074850957],
						[77.55658722150491, 12.953247107281971],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000008c",
			properties: {
				AREA_SQ_KM: 0.9870246631214685,
				ASS_CONST1: "Basavanagudi",
				ASS_CONST_: "170",
				LAT: 12.9471215024395,
				LON: 77.5598838663128,
				LOST_AREA_SQ_KM: 0.028335478232499656,
				OBJECTID: 141,
				POP_F: 16928.0,
				POP_M: 18137.0,
				POP_SC: 857.0,
				POP_ST: 290.0,
				POP_TOTAL: 35065.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Hanumanth Nagar",
				WARD_NO: 155.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54947499478594, 12.945011190435224],
						[77.55072798047182, 12.94482834435342],
						[77.55225743521127, 12.944654418147284],
						[77.55222620956003, 12.944110405061421],
						[77.55219052309094, 12.943517307705937],
						[77.55231096487914, 12.943503976380596],
						[77.5524447014717, 12.943490596060098],
						[77.5526275940975, 12.943437090643572],
						[77.5527657911037, 12.94337462210545],
						[77.55291737028466, 12.943316676996023],
						[77.55283270288483, 12.942875250411573],
						[77.5527657911037, 12.942429337192518],
						[77.55268995770405, 12.941934383216834],
						[77.55261867251284, 12.941938792255895],
						[77.55258298616718, 12.941426036793835],
						[77.55252945662761, 12.940873120484618],
						[77.55245808386894, 12.940873129654504],
						[77.55239125933362, 12.940351380431448],
						[77.55233326889997, 12.939927800159944],
						[77.55231542568366, 12.939767228265827],
						[77.55223959198305, 12.939428378247767],
						[77.55215938488816, 12.93917421645033],
						[77.55213708083315, 12.939058249909621],
						[77.55213262002162, 12.93898244590789],
						[77.55213708083315, 12.938951201254325],
						[77.5521415416445, 12.93892892041867],
						[77.55215938488816, 12.938906680902903],
						[77.55217276731905, 12.93888879459974],
						[77.55219944470927, 12.93887990920446],
						[77.55232880809616, 12.938848647879158],
						[77.55247146626462, 12.938804064015617],
						[77.55247592706283, 12.938804106509965],
						[77.55281039896217, 12.938656972848896],
						[77.55310463545597, 12.938598917759748],
						[77.55354616369765, 12.93856778035044],
						[77.55409011223375, 12.93847856948089],
						[77.55458061705504, 12.938425052013864],
						[77.5550443555759, 12.938398342624462],
						[77.55540558400426, 12.93838048632088],
						[77.55556608101195, 12.939624545377514],
						[77.55756820743058, 12.939441697130357],
						[77.55756820743058, 12.939446179129778],
						[77.55761281340061, 12.939789562831372],
						[77.55761281340061, 12.939820764543066],
						[77.55789820373091, 12.941644566744925],
						[77.55786697963232, 12.941644528354686],
						[77.55652031193821, 12.941720340084744],
						[77.5570064330943, 12.944413637520963],
						[77.55750138590538, 12.94445377047453],
						[77.557434476878, 12.944538467403358],
						[77.55697966937129, 12.945144928603622],
						[77.55647579300202, 12.945791521323088],
						[77.55608334339216, 12.946281950166977],
						[77.5559050044646, 12.946500486510374],
						[77.55563745181543, 12.94685272587451],
						[77.55528960618871, 12.947298671804962],
						[77.55515587294953, 12.947472552602761],
						[77.55544573014356, 12.948921806142408],
						[77.55557946304101, 12.949577243181661],
						[77.55574450793648, 12.950375474923373],
						[77.55571328324494, 12.95037991778226],
						[77.55561960911876, 12.950397728719095],
						[77.55556162033524, 12.950411139375023],
						[77.55499091466231, 12.950562755130065],
						[77.5547412026948, 12.949679846363349],
						[77.55458061705504, 12.949755633236196],
						[77.55452262774038, 12.949942907283422],
						[77.554500411615, 12.950054412670312],
						[77.55446026514116, 12.950201516214962],
						[77.5543978150425, 12.950268413735914],
						[77.55422393419457, 12.950402175148096],
						[77.55404550487839, 12.950535975789082],
						[77.55392961313791, 12.950616244022816],
						[77.55384039828346, 12.950656336181183],
						[77.55363529131772, 12.95075893924064],
						[77.55346587006044, 12.950857056037833],
						[77.5532786054853, 12.950883801227617],
						[77.55279255582087, 12.95055824053302],
						[77.55267211454382, 12.950705437750479],
						[77.552587446961, 12.950816862954634],
						[77.55236895532417, 12.95112009495848],
						[77.55235557291641, 12.951142378823526],
						[77.55228866085395, 12.951236041060554],
						[77.55197649150772, 12.95167302099493],
						[77.55164210461774, 12.952043159398087],
						[77.55157073130098, 12.952110057038944],
						[77.55145483703289, 12.952248250319318],
						[77.5507056761355, 12.95220367684253],
						[77.54959981289826, 12.952274990809823],
						[77.5494125419438, 12.951178125593717],
						[77.54943038561649, 12.951173641777391],
						[77.54955074286487, 12.951133501993123],
						[77.54976923992717, 12.951004138103594],
						[77.54994321506953, 12.950915036828622],
						[77.5500412673073, 12.950848133931817],
						[77.55005464998794, 12.950442353122291],
						[77.55008141534451, 12.950348653136437],
						[77.5500769544522, 12.950295167478071],
						[77.5500769544522, 12.950228277621537],
						[77.55006803266701, 12.950156992489768],
						[77.55003234551931, 12.949991926265374],
						[77.55001004104629, 12.949898360308396],
						[77.54999665836039, 12.949809103531264],
						[77.54998327567289, 12.949719933026303],
						[77.5499877365689, 12.949595075109034],
						[77.55000558015116, 12.949434573374928],
						[77.55000558015116, 12.949358805671345],
						[77.5499654320871, 12.949287394658198],
						[77.54994321506953, 12.94920719414572],
						[77.54992537147746, 12.949104620848924],
						[77.54988968428489, 12.94901540987077],
						[77.54984953617985, 12.948917364167976],
						[77.54980046625441, 12.948823715437905],
						[77.54980046625441, 12.94873001918371],
						[77.54980492715761, 12.94861412686536],
						[77.54981830986611, 12.94851599039294],
						[77.54984953617985, 12.948440177316394],
						[77.54984953617985, 12.948337603083829],
						[77.54980938806061, 12.94824404026404],
						[77.54973364015427, 12.9481280739748],
						[77.54963996109164, 12.948007623063875],
						[77.54957304742814, 12.947891738207812],
						[77.54957304742814, 12.947820367613385],
						[77.54957304742813, 12.947771321903963],
						[77.54961765654151, 12.947691068957276],
						[77.5496533438196, 12.947650898126547],
						[77.54966226563737, 12.947601851628614],
						[77.54964442200114, 12.947575132503141],
						[77.5495908910756, 12.947534970173672],
						[77.54955520377789, 12.947477049771408],
						[77.54951514302344, 12.947405730562268],
						[77.54964442200114, 12.947374467961465],
						[77.5497068747157, 12.947365583789072],
						[77.54965780472858, 12.946852766315859],
						[77.54961765654151, 12.946603018600484],
						[77.54957304742814, 12.946250742684397],
						[77.54956858651583, 12.946152696136748],
						[77.54957304742814, 12.946067922825813],
						[77.54956412560335, 12.94595647355831],
						[77.54950176027917, 12.945653250518664],
						[77.54947945570191, 12.945506075346827],
						[77.54947053386978, 12.945216290297276],
						[77.54947053386978, 12.945042350117392],
						[77.54947499478594, 12.945011190435224],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000008d",
			properties: {
				AREA_SQ_KM: 0.8062945963311748,
				ASS_CONST1: "Basavanagudi",
				ASS_CONST_: "170",
				LAT: 12.9453275336224,
				LON: 77.5534943697637,
				LOST_AREA_SQ_KM: 0.013817740728251138,
				OBJECTID: 142,
				POP_F: 17252.0,
				POP_M: 18793.0,
				POP_SC: 272.0,
				POP_ST: 156.0,
				POP_TOTAL: 36045.0,
				RESERVATIO: "General",
				WARD_NAME: "Srinagar",
				WARD_NO: 156.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56449320533865, 12.939285644244181],
						[77.56449320533865, 12.939156353311391],
						[77.56453780858382, 12.938603412207982],
						[77.56455564987697, 12.937992556940413],
						[77.56449766566395, 12.937448475698814],
						[77.5642479746339, 12.936917841599433],
						[77.56404288649365, 12.936650345455718],
						[77.56386447262837, 12.936699423218663],
						[77.56365046307725, 12.936043895069076],
						[77.56351219187734, 12.935548969024277],
						[77.56320005342495, 12.934826583881302],
						[77.56290129527781, 12.934041757015333],
						[77.56266052152581, 12.933381856096217],
						[77.56267836311453, 12.933158881356988],
						[77.56283893728651, 12.933038443357393],
						[77.56361924056299, 12.932681752507909],
						[77.56399828305366, 12.93243202429475],
						[77.56401612443177, 12.9323294559316],
						[77.56383771052434, 12.931134406178073],
						[77.56369060629724, 12.930514623333861],
						[77.56399828305366, 12.930447715573147],
						[77.56490792738408, 12.930269357736282],
						[77.56537608395517, 12.930189135591405],
						[77.56633486747259, 12.9300240801363],
						[77.5666737587297, 12.929961685726946],
						[77.56681639884985, 12.930309503450367],
						[77.56709284556094, 12.931014026265892],
						[77.56726233391005, 12.931517934142212],
						[77.5673247769221, 12.931727445851678],
						[77.56737829947647, 12.931847926419724],
						[77.56741389371022, 12.931883596595467],
						[77.56743619476008, 12.931910267199846],
						[77.56744065496953, 12.931914791141635],
						[77.56749417746913, 12.931963820905157],
						[77.56757446117113, 12.932061886505327],
						[77.56764136421268, 12.932159998302048],
						[77.56769042641803, 12.932258114200776],
						[77.56778400311438, 12.93248550072719],
						[77.56788658757215, 12.93280657863132],
						[77.56798917193697, 12.933141017080162],
						[77.56804269417741, 12.933297096099546],
						[77.5680605349186, 12.933368457915],
						[77.5681050493047, 12.93360477865341],
						[77.56812735021846, 12.933738498777789],
						[77.56815857149036, 12.933738534950585],
						[77.56841726169787, 12.933720722084523],
						[77.56855989962246, 12.933711808328956],
						[77.56876952719666, 12.933684999020205],
						[77.56888986391834, 12.933662773524821],
						[77.5690548894327, 12.933613650467183],
						[77.56920653428793, 12.933564659582249],
						[77.56928672932249, 12.933836656423784],
						[77.56932241041011, 12.933827684442216],
						[77.56946959477774, 12.933805456747569],
						[77.56959885100464, 12.933778660666999],
						[77.56978617607297, 12.933738538859753],
						[77.57013397721045, 12.933658296648662],
						[77.57033914177575, 12.933600328537283],
						[77.5707404621802, 12.933484389492715],
						[77.57085633673272, 12.933453156793187],
						[77.57103473958549, 12.933421913502242],
						[77.57115070128906, 12.933413008397991],
						[77.57123089494671, 12.933417467151896],
						[77.57136915672473, 12.93342195942045],
						[77.57151633843192, 12.933421967522033],
						[77.57163221219038, 12.934434173844922],
						[77.57166789253891, 12.934866679315682],
						[77.57169019275103, 12.934929120677449],
						[77.57173033312178, 12.93496035612837],
						[77.57183737404087, 12.934960331013452],
						[77.57283178283977, 12.93493806443092],
						[77.57304131505438, 12.934924693099891],
						[77.57322863442707, 12.934897928116834],
						[77.57346938571459, 12.934853303793762],
						[77.57383055541082, 12.934759648950171],
						[77.57382609545307, 12.935031718972832],
						[77.57383055541082, 12.939575530497782],
						[77.57379933570292, 12.94063227042678],
						[77.57379487574396, 12.940694721498017],
						[77.57377257594645, 12.94145727501715],
						[77.57375473610527, 12.94273698200558],
						[77.57377257594645, 12.943695687042291],
						[77.57361656469119, 12.943686803417137],
						[77.57343370593387, 12.943682317432867],
						[77.57230558942058, 12.943677872535929],
						[77.57110164058281, 12.943664515817792],
						[77.57016519792914, 12.943668937949363],
						[77.56833251841913, 12.943668931297166],
						[77.56807837565697, 12.943651098130754],
						[77.56798917193697, 12.943615429148247],
						[77.56793118948131, 12.94357979730554],
						[77.56786874680355, 12.943526280233268],
						[77.56785982641821, 12.943401381092285],
						[77.56785536622527, 12.943013448886614],
						[77.56783752545176, 12.942803906083107],
						[77.56780630409136, 12.942692415736209],
						[77.56776170213301, 12.9426076923548],
						[77.56770826721468, 12.9425229749784],
						[77.56764582441404, 12.942438255190424],
						[77.56715528866643, 12.941827390619078],
						[77.56695912624585, 12.941617802779799],
						[77.56682531931737, 12.941430523993274],
						[77.56676733626595, 12.94130119784048],
						[77.56674057484759, 12.941220954468843],
						[77.56672719413604, 12.941149543669633],
						[77.56671381342291, 12.941002452939582],
						[77.56666037801025, 12.940222052985238],
						[77.56618767903147, 12.94021318466855],
						[77.56610739434663, 12.94016855109481],
						[77.56602264933979, 12.940074916530197],
						[77.56597367386833, 12.939994639599274],
						[77.5659290711884, 12.939878716106206],
						[77.565897849302, 12.939740508833006],
						[77.56591569038099, 12.939325823363403],
						[77.565269124386, 12.93931686313348],
						[77.5647428958283, 12.939294557083544],
						[77.56449320533865, 12.939285644244181],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000008e",
			properties: {
				AREA_SQ_KM: 1.165745394003653,
				ASS_CONST1: "Basavanagudi",
				ASS_CONST_: "170",
				LAT: 12.9373753661626,
				LON: 77.5687334210958,
				LOST_AREA_SQ_KM: 0.02289585440554151,
				OBJECTID: 143,
				POP_F: 17619.0,
				POP_M: 18396.0,
				POP_SC: 956.0,
				POP_ST: 159.0,
				POP_TOTAL: 36015.0,
				RESERVATIO: "General",
				WARD_NAME: "Basavanagudi",
				WARD_NO: 154.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54947499478594, 12.945011190435224],
						[77.54948391661772, 12.944971023007408],
						[77.549586430164, 12.944538443208083],
						[77.54963103927211, 12.944346743377768],
						[77.54963996109164, 12.944293216098407],
						[77.54966226563737, 12.944186204229696],
						[77.54967118745444, 12.944105956980707],
						[77.54968457017873, 12.944012306207004],
						[77.54972025743578, 12.943829485965207],
						[77.5497603181178, 12.943704626294762],
						[77.54981384896347, 12.943419234591703],
						[77.54984507527843, 12.943321186497442],
						[77.549880762485, 12.943249814725794],
						[77.54995651029317, 12.94313831150283],
						[77.55001896283603, 12.943044699040545],
						[77.5500769544522, 12.942991209897142],
						[77.5501750065744, 12.942959992923509],
						[77.55025530254007, 12.942937659981876],
						[77.55029545050158, 12.9429198566413],
						[77.55031775491851, 12.942893091232778],
						[77.55030437226888, 12.9428351280131],
						[77.55027314608026, 12.942750360869018],
						[77.5502285372245, 12.942625558789253],
						[77.55014832870835, 12.942514038769962],
						[77.55005911088115, 12.942393637845779],
						[77.55003234551931, 12.942326755515914],
						[77.55001896283603, 12.942264310628387],
						[77.54999665836039, 12.94217066516783],
						[77.54985399708112, 12.94219749128113],
						[77.5490335381747, 12.94226431152479],
						[77.54849393888756, 12.942322341934265],
						[77.54803026106943, 12.942398079678064],
						[77.54768239212518, 12.942473882279126],
						[77.54610389169284, 12.942861846991791],
						[77.54504259454393, 12.943169582367188],
						[77.5449222325169, 12.943187395669483],
						[77.54481962728704, 12.943191843425428],
						[77.54407943233704, 12.94322752007263],
						[77.54277738779983, 12.943294365634591],
						[77.54147086710945, 12.943343404846372],
						[77.54123897036342, 12.943352389163776],
						[77.5407038827803, 12.943414754276622],
						[77.53866165679183, 12.94352180790896],
						[77.53876418010559, 12.941729267918035],
						[77.5387998707938, 12.941149559668814],
						[77.5388266388026, 12.940926588196497],
						[77.53891131664514, 12.940235478157616],
						[77.53895146861662, 12.939860859978495],
						[77.53896931393272, 12.939660213932335],
						[77.53905407914594, 12.939214317360253],
						[77.53924136717927, 12.938433962325105],
						[77.539348351284, 12.937952425599057],
						[77.53936173522462, 12.937907823107633],
						[77.5397675390544, 12.937823104624016],
						[77.53985221619592, 12.937796332750455],
						[77.5406325900643, 12.937617975024645],
						[77.53988344524976, 12.935638112701312],
						[77.53966492916919, 12.934906817109711],
						[77.5393840417888, 12.93377866167033],
						[77.53932604471284, 12.933635990489554],
						[77.5393840417888, 12.933600348472604],
						[77.54065935764145, 12.932712943093838],
						[77.54151101817659, 12.932075297801875],
						[77.5417027633822, 12.93193259794731],
						[77.54242066785442, 12.931397524989965],
						[77.54306281543406, 12.93086690118116],
						[77.544083893465, 12.929917071489282],
						[77.54494899908335, 12.929141224673403],
						[77.5455599048852, 12.92863287717237],
						[77.54651859350486, 12.929729777014863],
						[77.54721416251726, 12.930572618294127],
						[77.54805693943325, 12.931522358042022],
						[77.54881503969635, 12.932592566422436],
						[77.54922080975442, 12.933185586017565],
						[77.54996097119025, 12.933890122794054],
						[77.5506432239705, 12.934425276539587],
						[77.55075920653529, 12.93455902231575],
						[77.5508216586365, 12.934697257489258],
						[77.55081273690985, 12.934790857885817],
						[77.55075474566961, 12.934875630986792],
						[77.5507725891313, 12.934982672996414],
						[77.55084833634432, 12.935290304043518],
						[77.55091524925601, 12.935522182473505],
						[77.55099108384157, 12.93575854198062],
						[77.55054517225274, 12.935968133751649],
						[77.55069229353161, 12.936271276120221],
						[77.55102230983267, 12.93609299017628],
						[77.551329933493, 12.936663722246443],
						[77.55153504462575, 12.937287956184685],
						[77.55184275436339, 12.937230034150693],
						[77.55216384569863, 12.937149743657788],
						[77.55233772970361, 12.93783203435724],
						[77.55241793667247, 12.938273483884942],
						[77.55247146626462, 12.938804064015617],
						[77.55232880809616, 12.938848647879158],
						[77.55219944470927, 12.93887990920446],
						[77.55217276731905, 12.93888879459974],
						[77.55215938488816, 12.938906680902903],
						[77.5521415416445, 12.93892892041867],
						[77.55213708083315, 12.938951201254325],
						[77.55213262002162, 12.93898244590789],
						[77.55213708083315, 12.939058249909621],
						[77.55215938488816, 12.93917421645033],
						[77.55223959198305, 12.939428378247767],
						[77.55231542568366, 12.939767228265827],
						[77.55233326889997, 12.939927800159944],
						[77.55239125933362, 12.940351380431448],
						[77.55245808386894, 12.940873129654504],
						[77.55252945662761, 12.940873120484618],
						[77.55258298616718, 12.941426036793835],
						[77.55261867251284, 12.941938792255895],
						[77.55268995770405, 12.941934383216834],
						[77.5527657911037, 12.942429337192518],
						[77.55283270288483, 12.942875250411573],
						[77.55291737028466, 12.943316676996023],
						[77.5527657911037, 12.94337462210545],
						[77.5526275940975, 12.943437090643572],
						[77.5524447014717, 12.943490596060098],
						[77.55231096487914, 12.943503976380596],
						[77.55219052309094, 12.943517307705937],
						[77.55222620956003, 12.944110405061421],
						[77.55225743521127, 12.944654418147284],
						[77.55072798047182, 12.94482834435342],
						[77.54947499478594, 12.945011190435224],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000008f",
			properties: {
				AREA_SQ_KM: 1.7650345653305524,
				ASS_CONST1: "Basavanagudi",
				ASS_CONST_: "170",
				LAT: 12.9376240163993,
				LON: 77.5455754936583,
				LOST_AREA_SQ_KM: 0.0848604582215552,
				OBJECTID: 144,
				POP_F: 16704.0,
				POP_M: 18208.0,
				POP_SC: 474.0,
				POP_ST: 125.0,
				POP_TOTAL: 34912.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Girinagar",
				WARD_NO: 162.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.55247146626462, 12.938804064015617],
						[77.55241793667247, 12.938273483884942],
						[77.55233772970361, 12.93783203435724],
						[77.55216384569863, 12.937149743657788],
						[77.55184275436339, 12.937230034150693],
						[77.55153504462575, 12.937287956184685],
						[77.551329933493, 12.936663722246443],
						[77.55102230983267, 12.93609299017628],
						[77.55069229353161, 12.936271276120221],
						[77.55054517225274, 12.935968133751649],
						[77.55099108384157, 12.93575854198062],
						[77.55091524925601, 12.935522182473505],
						[77.55084833634432, 12.935290304043518],
						[77.5507725891313, 12.934982672996414],
						[77.55075474566961, 12.934875630986792],
						[77.55081273690985, 12.934790857885817],
						[77.5508216586365, 12.934697257489258],
						[77.55075920653529, 12.93455902231575],
						[77.5506432239705, 12.934425276539587],
						[77.54996097119025, 12.933890122794054],
						[77.54922080975442, 12.933185586017565],
						[77.54881503969635, 12.932592566422436],
						[77.54805693943325, 12.931522358042022],
						[77.54721416251726, 12.930572618294127],
						[77.54651859350486, 12.929729777014863],
						[77.5455599048852, 12.92863287717237],
						[77.54606374224906, 12.928218135625343],
						[77.54624209664131, 12.928075477188989],
						[77.54724538954729, 12.92727732201033],
						[77.54826205648027, 12.926465759696242],
						[77.54875704743552, 12.926100141139747],
						[77.54911820840874, 12.92585933155201],
						[77.54993429327385, 12.925382223784046],
						[77.55025530254007, 12.925194893365155],
						[77.55093309269246, 12.924833759273854],
						[77.55109359602665, 12.924749012891413],
						[77.55116943049303, 12.9247133645416],
						[77.55170455623309, 12.924472563244061],
						[77.5519364316079, 12.924401161471053],
						[77.55239572013497, 12.92424961056806],
						[77.55243577987265, 12.924231716876184],
						[77.55292629184584, 12.924053400187793],
						[77.55332321314218, 12.923928491974193],
						[77.55339449789076, 12.924347653847189],
						[77.55334542949808, 12.92530637706228],
						[77.55342572322051, 12.926086732418527],
						[77.55363975207018, 12.926398839601605],
						[77.55409457296831, 12.926849222831963],
						[77.55435320780815, 12.92721484385093],
						[77.55466983132742, 12.927509139658007],
						[77.55462968491352, 12.927914939013428],
						[77.554558313476, 12.928565980080434],
						[77.5546207634863, 12.928802284566425],
						[77.5548169472586, 12.929453365929218],
						[77.5549106220476, 12.929760984700197],
						[77.55442011865311, 12.929934911638338],
						[77.55406780855827, 12.930055332265681],
						[77.55416594469759, 12.930487876479292],
						[77.55382255530415, 12.930608208912954],
						[77.55424623783937, 12.930844582571709],
						[77.5547678794811, 12.931196850974203],
						[77.55539666263805, 12.931781032872323],
						[77.55615917452359, 12.932507830154787],
						[77.55685923253955, 12.93294479505522],
						[77.55763957697421, 12.933448715177866],
						[77.55754590443898, 12.933934702906054],
						[77.5574121738601, 12.934897887345521],
						[77.55734972538666, 12.935321517098238],
						[77.5572694344417, 12.935522179141834],
						[77.55725614007495, 12.935852161902595],
						[77.55602089418704, 12.93608849551548],
						[77.55626168203932, 12.936717206812597],
						[77.55648025364279, 12.93727908917642],
						[77.55679232313334, 12.938135250866427],
						[77.55720261274047, 12.937974694080838],
						[77.55730511931314, 12.938139686617685],
						[77.55744339808392, 12.938879920975353],
						[77.557519228306, 12.939205413089118],
						[77.55756820743058, 12.939441697130357],
						[77.55556608101195, 12.939624545377514],
						[77.55540558400426, 12.93838048632088],
						[77.5550443555759, 12.938398342624462],
						[77.55458061705504, 12.938425052013864],
						[77.55409011223375, 12.93847856948089],
						[77.55354616369765, 12.93856778035044],
						[77.55310463545597, 12.938598917759748],
						[77.55281039896217, 12.938656972848896],
						[77.55247592706283, 12.938804106509965],
						[77.55247146626462, 12.938804064015617],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000090",
			properties: {
				AREA_SQ_KM: 1.1118117028566996,
				ASS_CONST1: "Basavanagudi",
				ASS_CONST_: "170",
				LAT: 12.9316414559219,
				LON: 77.5522232871992,
				LOST_AREA_SQ_KM: 0.025271686879894777,
				OBJECTID: 145,
				POP_F: 16967.0,
				POP_M: 18769.0,
				POP_SC: 574.0,
				POP_ST: 167.0,
				POP_TOTAL: 35736.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Katriguppe",
				WARD_NO: 163.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56428365730866, 12.94181839499462],
						[77.56335170611749, 12.941760434035821],
						[77.56179546348044, 12.941738182533422],
						[77.56016345853396, 12.94160435769499],
						[77.55871868599706, 12.941568750294946],
						[77.55789820373091, 12.941644566744925],
						[77.55761281340061, 12.939820764543066],
						[77.55761281340061, 12.939789562831372],
						[77.55756820743058, 12.939446179129778],
						[77.55756820743058, 12.939441697130357],
						[77.557519228306, 12.939205413089118],
						[77.55744339808392, 12.938879920975353],
						[77.55730511931314, 12.938139686617685],
						[77.55720261274047, 12.937974694080838],
						[77.55679232313334, 12.938135250866427],
						[77.55648025364279, 12.93727908917642],
						[77.55626168203932, 12.936717206812597],
						[77.55602089418704, 12.93608849551548],
						[77.55725614007495, 12.935852161902595],
						[77.5572694344417, 12.935522179141834],
						[77.55734972538666, 12.935321517098238],
						[77.5574121738601, 12.934897887345521],
						[77.55754590443898, 12.933934702906054],
						[77.55763957697421, 12.933448715177866],
						[77.55685923253955, 12.93294479505522],
						[77.55615917452359, 12.932507830154787],
						[77.55539666263805, 12.931781032872323],
						[77.5547678794811, 12.931196850974203],
						[77.55424623783937, 12.930844582571709],
						[77.55382255530415, 12.930608208912954],
						[77.55416594469759, 12.930487876479292],
						[77.55406780855827, 12.930055332265681],
						[77.55442011865311, 12.929934911638338],
						[77.5549106220476, 12.929760984700197],
						[77.5548169472586, 12.929453365929218],
						[77.5546207634863, 12.928802284566425],
						[77.554558313476, 12.928565980080434],
						[77.55462968491352, 12.927914939013428],
						[77.55466983132742, 12.927509139658007],
						[77.55435320780815, 12.92721484385093],
						[77.55409457296831, 12.926849222831963],
						[77.55363975207018, 12.926398839601605],
						[77.55342572322051, 12.926086732418527],
						[77.55334542949808, 12.92530637706228],
						[77.55339449789076, 12.924347653847189],
						[77.55332321314218, 12.923928491974193],
						[77.55434874708374, 12.923603036726936],
						[77.55438889359704, 12.923589628148397],
						[77.55463414562688, 12.924178230239075],
						[77.55493738625881, 12.925181570832345],
						[77.55503543418128, 12.925373301389575],
						[77.55521386194049, 12.92556054005153],
						[77.55539666263805, 12.925694298322663],
						[77.55579794849504, 12.926046611604075],
						[77.55619039909236, 12.926381006048807],
						[77.55671211925713, 12.92671550194773],
						[77.55740325265171, 12.927179236531165],
						[77.55805869721134, 12.92762065807745],
						[77.5584779033614, 12.927897136249321],
						[77.5587053043384, 12.928004171265373],
						[77.55890148104558, 12.928071007460623],
						[77.55911558702432, 12.92816464762936],
						[77.559253776126, 12.92825386009357],
						[77.55942318873792, 12.928441101470373],
						[77.56006532758418, 12.929243779633188],
						[77.56023919947634, 12.929475623130443],
						[77.5604845263153, 12.92964505862867],
						[77.56094824069406, 12.929970568381707],
						[77.56143871585279, 12.930327359321721],
						[77.56175085915595, 12.930501216848045],
						[77.56200501611114, 12.930626088991813],
						[77.56225471208302, 12.930643960793796],
						[77.56245097010704, 12.930648402343342],
						[77.56260699674283, 12.930657286422418],
						[77.56282555611419, 12.930585921419771],
						[77.56315544965324, 12.93050569029243],
						[77.56361478020311, 12.930403156088978],
						[77.56367276486789, 12.930398705189718],
						[77.56368168558292, 12.930447743861093],
						[77.56369060629724, 12.930514623333861],
						[77.56383771052434, 12.931134406178073],
						[77.56401612443177, 12.9323294559316],
						[77.56399828305366, 12.93243202429475],
						[77.56361924056299, 12.932681752507909],
						[77.56283893728651, 12.933038443357393],
						[77.56267836311453, 12.933158881356988],
						[77.56266052152581, 12.933381856096217],
						[77.56290129527781, 12.934041757015333],
						[77.56320005342495, 12.934826583881302],
						[77.56351219187734, 12.935548969024277],
						[77.56365046307725, 12.936043895069076],
						[77.56386447262837, 12.936699423218663],
						[77.56404288649365, 12.936650345455718],
						[77.5642479746339, 12.936917841599433],
						[77.56449766566395, 12.937448475698814],
						[77.56455564987697, 12.937992556940413],
						[77.56453780858382, 12.938603412207982],
						[77.56449320533865, 12.939156353311391],
						[77.56449320533865, 12.939285644244181],
						[77.56436394328581, 12.939276751675001],
						[77.56414092654218, 12.93928567136627],
						[77.5640741088912, 12.939276766977196],
						[77.5640295054635, 12.939258933149507],
						[77.56399828305366, 12.939245535814488],
						[77.56396260028903, 12.939223218247804],
						[77.56406518820707, 12.939410541622083],
						[77.56431487963987, 12.939892144187665],
						[77.56490792738408, 12.940882016705691],
						[77.56496145108028, 12.941002421682928],
						[77.56499267322474, 12.941069346647126],
						[77.56501051444626, 12.941158469474765],
						[77.56507287124258, 12.941568745164474],
						[77.56509071245148, 12.941675710967235],
						[77.56509071245148, 12.941706913893004],
						[77.56509071245148, 12.941733720833096],
						[77.56505949033405, 12.941769368587938],
						[77.56502835566498, 12.941787264178112],
						[77.56499267322474, 12.941787184507398],
						[77.5648900861464, 12.941787246333107],
						[77.56458687213323, 12.94178721943448],
						[77.56438624493605, 12.941791698952699],
						[77.56433272096814, 12.941791708580713],
						[77.56430149864185, 12.941800635455994],
						[77.56428365730866, 12.94181839499462],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000091",
			properties: {
				AREA_SQ_KM: 1.2423225052569493,
				ASS_CONST1: "Basavanagudi",
				ASS_CONST_: "170",
				LAT: 12.9342004520233,
				LON: 77.5594762586734,
				LOST_AREA_SQ_KM: 0.029897544930790925,
				OBJECTID: 146,
				POP_F: 16452.0,
				POP_M: 18083.0,
				POP_SC: 1261.0,
				POP_ST: 357.0,
				POP_TOTAL: 34535.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Vidyapeeta ward",
				WARD_NO: 164.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.53897377526131, 12.920196241103088],
						[77.53896039127501, 12.920156129208568],
						[77.53895592994591, 12.920102573155145],
						[77.53884894547178, 12.919884142584095],
						[77.5388177161337, 12.919786038771367],
						[77.5387998707938, 12.919745884539903],
						[77.53877756411498, 12.919723611770076],
						[77.53875079609462, 12.919701296500893],
						[77.53872411554518, 12.919678985449098],
						[77.5386928861728, 12.919638876095886],
						[77.53865719545098, 12.919572010716617],
						[77.53862596606021, 12.91949174495806],
						[77.53861258203297, 12.91942035270809],
						[77.53860365934726, 12.919371321692894],
						[77.53861258203297, 12.919317807394066],
						[77.53865273410997, 12.919237532469246],
						[77.53872857688339, 12.919135020384655],
						[77.53878648678703, 12.919041382222064],
						[77.5388087934641, 12.91895214783503],
						[77.53884002280465, 12.918876355178382],
						[77.53885786813824, 12.918836196543012],
						[77.5388712521366, 12.918791600645301],
						[77.5388623294712, 12.918738088931473],
						[77.53883110013678, 12.91868462331935],
						[77.53879540945839, 12.91864895286207],
						[77.53863042740228, 12.91847504104404],
						[77.53854574935048, 12.918403663207918],
						[77.53851451992908, 12.918376911034844],
						[77.53847436780325, 12.918367997611083],
						[77.53844759971149, 12.91836800117681],
						[77.53842529296352, 12.91836356630092],
						[77.53839406350905, 12.91835017075011],
						[77.53838960215771, 12.918323415026165],
						[77.53838960215771, 12.918274383337026],
						[77.53838514080618, 12.918243146706184],
						[77.53838076693198, 12.918207519544385],
						[77.53838076693198, 12.918167363578215],
						[77.5383851408062, 12.918131726824315],
						[77.53840744756202, 12.918096005734176],
						[77.53847882915126, 12.91804248364565],
						[77.53871965420679, 12.91790431398283],
						[77.53891131664514, 12.91781065408929],
						[77.539031772517, 12.917730368837411],
						[77.53909860491373, 12.917672405107881],
						[77.53914321814096, 12.917632243059685],
						[77.53923244454293, 12.917601036628685],
						[77.53925028981492, 12.917601034146166],
						[77.53929035418986, 12.917601067428992],
						[77.53935281259773, 12.917601058717333],
						[77.53939742572518, 12.91760553339865],
						[77.5395311775259, 12.917636704512104],
						[77.53966492916919, 12.917699155857198],
						[77.53990129041952, 12.917819543171097],
						[77.5400841157719, 12.917895387285366],
						[77.54055674856149, 12.918153995471611],
						[77.54089562934793, 12.918350152512392],
						[77.54110522057272, 12.918443830495049],
						[77.54113644928222, 12.91844826384817],
						[77.54115429425522, 12.918452742228306],
						[77.54120336791652, 12.918470615899212],
						[77.5412256741192, 12.91847953151496],
						[77.54127466027444, 12.9185017957728],
						[77.54141733233119, 12.918599921394934],
						[77.54162246136924, 12.918733627328663],
						[77.54169830216075, 12.918751497077615],
						[77.54174737558712, 12.918773765565899],
						[77.54176075924516, 12.918782682496646],
						[77.54179190029964, 12.918805035572593],
						[77.54180528395246, 12.918827309315933],
						[77.5418097451697, 12.91884070855845],
						[77.54181420638679, 12.918858502625739],
						[77.54182312882041, 12.91887633912692],
						[77.54183205125335, 12.91888969463387],
						[77.54183651246954, 12.918898612886977],
						[77.54185435733261, 12.918903091261567],
						[77.54191681433123, 12.918889725244679],
						[77.54201933470834, 12.918862949149833],
						[77.54231368664215, 12.919522903093315],
						[77.54275954308176, 12.92016059789703],
						[77.54309841729828, 12.920909741006419],
						[77.54346851857247, 12.92188625966034],
						[77.54345968374163, 12.92255068142682],
						[77.54345076143653, 12.923094627835585],
						[77.54358896961341, 12.92372787438027],
						[77.54384754091552, 12.924610717474119],
						[77.54400814173917, 12.924976434358367],
						[77.54426671208712, 12.925774574760798],
						[77.54433808999559, 12.92603766901227],
						[77.5445253693192, 12.925993084595508],
						[77.54483301058315, 12.925881647241972],
						[77.5452298727059, 12.926813570434675],
						[77.54538601049839, 12.927210450528634],
						[77.54560888917145, 12.927205929892741],
						[77.54582739381956, 12.927362010775198],
						[77.54611281378952, 12.927910506095609],
						[77.54624209664131, 12.928075477188989],
						[77.54606374224906, 12.928218135625343],
						[77.5455599048852, 12.92863287717237],
						[77.54494899908335, 12.929141224673403],
						[77.544083893465, 12.929917071489282],
						[77.54306281543406, 12.93086690118116],
						[77.54242066785442, 12.931397524989965],
						[77.5417027633822, 12.93193259794731],
						[77.54151101817659, 12.932075297801875],
						[77.54065935764145, 12.932712943093838],
						[77.5393840417888, 12.933600348472604],
						[77.53932604471284, 12.933635990489554],
						[77.53921459926815, 12.933702839851524],
						[77.53900054322916, 12.933832224213788],
						[77.53895592994591, 12.933858944967977],
						[77.537190104637, 12.934866703348106],
						[77.53602628601905, 12.93551323867149],
						[77.53537526322292, 12.935834334146834],
						[77.53476885164328, 12.936155338344365],
						[77.53460386374392, 12.936244596115317],
						[77.53458601774258, 12.936289154281006],
						[77.53437641449763, 12.936449688690235],
						[77.5343631174507, 12.936463095312275],
						[77.53433634839097, 12.936494295500443],
						[77.53374322839225, 12.936043903294667],
						[77.53362285439839, 12.93541516447079],
						[77.53277113476723, 12.935495427292256],
						[77.53354263415879, 12.934023929428436],
						[77.53359162362482, 12.93398380440705],
						[77.53364962362606, 12.93392136190504],
						[77.53368085438372, 12.933863402572419],
						[77.53369423899153, 12.933751925476553],
						[77.53368977745576, 12.933618128564287],
						[77.53369870052711, 12.93361812798607],
						[77.53377892065663, 12.933377326997789],
						[77.53389483295759, 12.933158844951796],
						[77.53393944822685, 12.933096446599487],
						[77.53396175585492, 12.933083043883748],
						[77.53397514042965, 12.933042925541251],
						[77.53397514042965, 12.933038444109886],
						[77.53398852500281, 12.93301168388758],
						[77.5340019095744, 12.933011726067706],
						[77.53402421719021, 12.933002804777455],
						[77.53404643732082, 12.932953718760405],
						[77.5340508988426, 12.932931440583726],
						[77.5340598218856, 12.93290024234038],
						[77.53409551405063, 12.932797684183457],
						[77.53408659101042, 12.93267728969341],
						[77.53407766796953, 12.9325078583297],
						[77.53417582138098, 12.93241865410635],
						[77.53422034905932, 12.932235857015355],
						[77.53422034905932, 12.932088660224268],
						[77.53422034905932, 12.931896908241573],
						[77.5342158875442, 12.931758718062765],
						[77.53420696451344, 12.931616003907163],
						[77.53420696451344, 12.931379697527843],
						[77.53425157966025, 12.931067549867949],
						[77.53424711814635, 12.930768807638803],
						[77.53424711814635, 12.930585934303002],
						[77.5343318868804, 12.930336222491693],
						[77.53444779861775, 12.930140064828478],
						[77.53459047924319, 12.92997949718824],
						[77.53471977520407, 12.9297832955276],
						[77.53482685104416, 12.929591538239935],
						[77.53485799399833, 12.929453343396249],
						[77.53483577402628, 12.929288359956796],
						[77.5348446970077, 12.929275001494013],
						[77.53514344152079, 12.929466759265967],
						[77.53508990376241, 12.928895915209713],
						[77.53506313487375, 12.928606099191795],
						[77.53499183861999, 12.928285095544066],
						[77.53497399267953, 12.928044229450197],
						[77.53499630010467, 12.927745491954338],
						[77.53497845416491, 12.9275939069875],
						[77.53500076158916, 12.92737092057833],
						[77.53509882672388, 12.927312996253887],
						[77.53527273685293, 12.92712566957257],
						[77.53551348122042, 12.927041012671907],
						[77.53578554273733, 12.926956227437111],
						[77.53628942344159, 12.926800142180703],
						[77.5366104712673, 12.926661959779496],
						[77.53674868776322, 12.92654599012448],
						[77.53681114756766, 12.92641671790406],
						[77.53681114756766, 12.92623384992883],
						[77.53678437908425, 12.925957396819982],
						[77.5367308420985, 12.925551596031662],
						[77.5367308420985, 12.925145877353593],
						[77.5367308420985, 12.924945172945366],
						[77.5367308420985, 12.9247355927541],
						[77.53673530351496, 12.924485897618526],
						[77.53667730508757, 12.924173732410159],
						[77.53671745784814, 12.924026583402302],
						[77.5368066861542, 12.923759085348276],
						[77.53682007039407, 12.923732326461293],
						[77.53683791604477, 12.92367432856208],
						[77.53688244268064, 12.923549537763218],
						[77.53699843929651, 12.923438014317451],
						[77.53710096411034, 12.9233890081346],
						[77.5371678851207, 12.92338447645967],
						[77.537274871177, 12.923388941337869],
						[77.53743093242278, 12.92341128197242],
						[77.53762268451217, 12.923442494512182],
						[77.53773859289475, 12.923518311350083],
						[77.53785012726453, 12.923598570989856],
						[77.53813101843565, 12.92385270519454],
						[77.53819793883963, 12.923915131822946],
						[77.53827815579483, 12.92410685965068],
						[77.53850559723568, 12.924427964473345],
						[77.53863935008587, 12.924575047820078],
						[77.53873295074422, 12.924677625855047],
						[77.5388087934641, 12.924775729087646],
						[77.5388177161337, 12.924784690423037],
						[77.53885786813824, 12.924811443626249],
						[77.53888463613337, 12.92481592172188],
						[77.53890248145997, 12.924815919661452],
						[77.53894254595751, 12.924806991525013],
						[77.53902731119071, 12.924766866478292],
						[77.5390451564949, 12.92472670610479],
						[77.53911198888375, 12.924686578884376],
						[77.53935727391125, 12.924633073264646],
						[77.53941973228235, 12.924633065877181],
						[77.53952671621919, 12.924610729207362],
						[77.5396693904705, 12.924583993196547],
						[77.53985221619592, 12.92449033606303],
						[77.54015987011402, 12.924303071467454],
						[77.54019556036394, 12.924289709711847],
						[77.54025355699616, 12.924276302111135],
						[77.54028924721666, 12.92425845915458],
						[77.54033377250055, 12.924218377319198],
						[77.54036946269585, 12.924147018720024],
						[77.54035607887393, 12.923977554396437],
						[77.54032484994997, 12.9236833094159],
						[77.54028924721666, 12.923322068232627],
						[77.54028478593972, 12.923286435073557],
						[77.54022678932344, 12.923094658219682],
						[77.54014648626742, 12.92282269825525],
						[77.54007519320173, 12.922488265052163],
						[77.54002611905321, 12.922341041085637],
						[77.53999042875009, 12.922193944880792],
						[77.53989682912734, 12.922113689171036],
						[77.53986560007719, 12.922060135497118],
						[77.53973184867004, 12.921917451446017],
						[77.53971846477303, 12.921908534079517],
						[77.53969169697427, 12.921895180431461],
						[77.53961139354016, 12.921895190698395],
						[77.53953563883243, 12.92190412365627],
						[77.53949548706733, 12.921904085676418],
						[77.53945979659757, 12.921899609129898],
						[77.53944641266851, 12.921886210676686],
						[77.53943311621455, 12.921872902613018],
						[77.53939742572518, 12.921690044816197],
						[77.53931266076803, 12.921400208266595],
						[77.5392680476073, 12.921257509573714],
						[77.53921013794901, 12.921114860101271],
						[77.53920121531021, 12.92109702323397],
						[77.53912091152955, 12.920918653598486],
						[77.53908522094214, 12.92087402015965],
						[77.53906300179628, 12.920842789251639],
						[77.53905407914594, 12.920789276520022],
						[77.53902731119071, 12.92061098673331],
						[77.53902284986422, 12.920593106297032],
						[77.53901838853754, 12.920428127743765],
						[77.53899608190162, 12.920236351854644],
						[77.53899608190162, 12.920227476007895],
						[77.53899608190162, 12.92020515713409],
						[77.53897377526131, 12.920196241103088],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000092",
			properties: {
				AREA_SQ_KM: 1.3503835125381318,
				ASS_CONST1: "Padmanaba Nagar",
				ASS_CONST_: "171",
				LAT: 12.9277969317167,
				LON: 77.5395058294504,
				LOST_AREA_SQ_KM: 0.02523855816578584,
				OBJECTID: 147,
				POP_F: 10144.0,
				POP_M: 11041.0,
				POP_SC: 1192.0,
				POP_ST: 285.0,
				POP_TOTAL: 21185.0,
				RESERVATIO: "General",
				WARD_NAME: "Hosakerehalli",
				WARD_NO: 161.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56369060629724, 12.930514623333861],
						[77.56368168558292, 12.930447743861093],
						[77.56367276486789, 12.930398705189718],
						[77.56361478020311, 12.930403156088978],
						[77.56315544965324, 12.93050569029243],
						[77.56282555611419, 12.930585921419771],
						[77.56260699674283, 12.930657286422418],
						[77.56245097010704, 12.930648402343342],
						[77.56225471208302, 12.930643960793796],
						[77.56200501611114, 12.930626088991813],
						[77.56175085915595, 12.930501216848045],
						[77.56143871585279, 12.930327359321721],
						[77.56094824069406, 12.929970568381707],
						[77.5604845263153, 12.92964505862867],
						[77.56023919947634, 12.929475623130443],
						[77.56006532758418, 12.929243779633188],
						[77.55942318873792, 12.928441101470373],
						[77.559253776126, 12.92825386009357],
						[77.55911558702432, 12.92816464762936],
						[77.55890148104558, 12.928071007460623],
						[77.5587053043384, 12.928004171265373],
						[77.5584779033614, 12.927897136249321],
						[77.55805869721134, 12.92762065807745],
						[77.55740325265171, 12.927179236531165],
						[77.55671211925713, 12.92671550194773],
						[77.55619039909236, 12.926381006048807],
						[77.55579794849504, 12.926046611604075],
						[77.55539666263805, 12.925694298322663],
						[77.55521386194049, 12.92556054005153],
						[77.55503543418128, 12.925373301389575],
						[77.55493738625881, 12.925181570832345],
						[77.55463414562688, 12.924178230239075],
						[77.55438889359704, 12.923589628148397],
						[77.55535205579643, 12.923268578320666],
						[77.55627952463497, 12.922978727511037],
						[77.5564490291538, 12.92292526195511],
						[77.55655153640758, 12.92288951551306],
						[77.55691722065968, 12.9227780927031],
						[77.55721599457645, 12.922671034992701],
						[77.55751476770612, 12.9225684581308],
						[77.55874990986115, 12.922184977877695],
						[77.55911558702432, 12.922082427069729],
						[77.55950347815791, 12.9220022301442],
						[77.5603105673388, 12.921846097010233],
						[77.56109534848053, 12.921712329147827],
						[77.56140749272922, 12.9216588223272],
						[77.56153238517189, 12.921627596587582],
						[77.56161267309807, 12.92160982509567],
						[77.56169287350788, 12.921516124588415],
						[77.56185344907604, 12.921137153780476],
						[77.56215667040273, 12.92042368299063],
						[77.56225917249643, 12.920249787561968],
						[77.56229485579739, 12.92019626172952],
						[77.56242866807597, 12.920102639254289],
						[77.562709585888, 12.919910862239252],
						[77.56299942377248, 12.919754760899963],
						[77.56315991003119, 12.919665608609225],
						[77.56332494389237, 12.919554181981518],
						[77.56341861165262, 12.919447167767622],
						[77.56352557296856, 12.919331226541225],
						[77.56365046307725, 12.91917064440396],
						[77.56389569507506, 12.918809447062054],
						[77.56406964854921, 12.9185329856151],
						[77.56422121262045, 12.918256568876732],
						[77.56438624493605, 12.917913235823432],
						[77.56445752272987, 12.917752663561652],
						[77.56472059424829, 12.917079327049771],
						[77.56484548303989, 12.916744925713655],
						[77.56489900676559, 12.916780589370362],
						[77.56500605414114, 12.916883113018482],
						[77.56522898172523, 12.917115041611021],
						[77.56530034644565, 12.917195254759937],
						[77.56539392511634, 12.917297821194623],
						[77.56546528973287, 12.917404835899621],
						[77.56552327345071, 12.91752077364506],
						[77.56574620001713, 12.917980042850326],
						[77.56602710960487, 12.918764841145753],
						[77.56857774028218, 12.91818070232051],
						[77.56869816466177, 12.918149515718708],
						[77.56915301259751, 12.91945605095111],
						[77.56973711477549, 12.921609777368703],
						[77.57021871914117, 12.923585136581057],
						[77.57048177727964, 12.924655344495932],
						[77.57053083825448, 12.925328652652281],
						[77.57004923521627, 12.92609117748969],
						[77.56970143381854, 12.926340863657616],
						[77.5695364966998, 12.926452390683728],
						[77.56977279572122, 12.92647908792492],
						[77.57069586137744, 12.926456824980619],
						[77.57080727589891, 12.9277187809081],
						[77.57103473958549, 12.929203609299961],
						[77.57113732109855, 12.929810049560558],
						[77.57100797917549, 12.929823483870203],
						[77.56972373441795, 12.929908188159985],
						[77.56934025094971, 12.929917075541077],
						[77.5685732801175, 12.929939416204746],
						[77.56782414486979, 12.929957181984468],
						[77.5671062262293, 12.929957226393409],
						[77.5666737587297, 12.929961685726946],
						[77.56633486747259, 12.9300240801363],
						[77.56537608395517, 12.930189135591405],
						[77.56490792738408, 12.930269357736282],
						[77.56399828305366, 12.930447715573147],
						[77.56369060629724, 12.930514623333861],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000093",
			properties: {
				AREA_SQ_KM: 1.6255448196165585,
				ASS_CONST1: "Padmanaba Nagar",
				ASS_CONST_: "171",
				LAT: 12.9248031449056,
				LON: 77.5640458573888,
				LOST_AREA_SQ_KM: 0.0651457967571782,
				OBJECTID: 148,
				POP_F: 11113.0,
				POP_M: 11988.0,
				POP_SC: 652.0,
				POP_ST: 179.0,
				POP_TOTAL: 23101.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Ganesh Mandir ward",
				WARD_NO: 165.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56869816466177, 12.918149515718708],
						[77.56867586386039, 12.918091566044993],
						[77.5686714036996, 12.918024648888972],
						[77.56865802321613, 12.917957820380733],
						[77.56865802321613, 12.917890859001684],
						[77.56865356305462, 12.917837385938977],
						[77.5686669435386, 12.917538642062752],
						[77.5687739873536, 12.917181917892927],
						[77.56907719015945, 12.91655315633773],
						[77.5694651346482, 12.915705954450418],
						[77.56964791236202, 12.915545392040356],
						[77.56999125381532, 12.915665809975797],
						[77.57008045595835, 12.915920010155093],
						[77.57038811535958, 12.916365848829209],
						[77.57079389566782, 12.916477373281488],
						[77.57127549553871, 12.916593313740984],
						[77.57176155340031, 12.916570977696463],
						[77.57255526268743, 12.916597797500373],
						[77.57309929489332, 12.916744913563395],
						[77.5736477844495, 12.916914391315501],
						[77.57361210472502, 12.917730356466622],
						[77.57361656469118, 12.918006865744873],
						[77.57368346416274, 12.918203036693226],
						[77.57377257594645, 12.918408117013707],
						[77.57405355307397, 12.919126054685966],
						[77.57418280406739, 12.91954524424271],
						[77.57428538272775, 12.919897483886475],
						[77.57436120167783, 12.920200694672616],
						[77.57438350135936, 12.920396882943695],
						[77.57439688116618, 12.920499479614133],
						[77.57440580103649, 12.920709073752827],
						[77.57439242123074, 12.920807110768633],
						[77.57437012155097, 12.92082948171965],
						[77.57434336192944, 12.920851724685487],
						[77.57422294355425, 12.920914199092815],
						[77.57416951168516, 12.920936453955004],
						[77.57397327396585, 12.920981067054964],
						[77.57356750505359, 12.92103457779026],
						[77.57356750505359, 12.921114814156317],
						[77.57357196502151, 12.922207317078628],
						[77.57359872482543, 12.923553939703844],
						[77.57481156694337, 12.923536091298192],
						[77.57612697305473, 12.923513854034457],
						[77.57725959874689, 12.923487055909838],
						[77.57902987209107, 12.923469234231797],
						[77.57964968857524, 12.92343805750234],
						[77.57969874557574, 12.923433517719365],
						[77.57974334283045, 12.923424626878877],
						[77.57974334283045, 12.924147043061998],
						[77.579703205302, 12.925199358544983],
						[77.57968982612267, 12.926260638862887],
						[77.57967198721447, 12.927188121371568],
						[77.57967198721447, 12.928249372538339],
						[77.5796630677593, 12.929194709511092],
						[77.57967198721447, 12.930180214109528],
						[77.5796630677593, 12.930831221326223],
						[77.57960955101356, 12.930831236475173],
						[77.57902541233827, 12.930840153650129],
						[77.57783482694114, 12.930862428217337],
						[77.57701439569051, 12.930889207406143],
						[77.57618049144794, 12.930902580079838],
						[77.57632320703944, 12.930585962985],
						[77.5763544260511, 12.930345181049912],
						[77.57552506428448, 12.930305074429977],
						[77.57526193041667, 12.930305014954488],
						[77.5750479424034, 12.929243734703489],
						[77.57457973836739, 12.92931061702926],
						[77.57401341352701, 12.929453370453597],
						[77.57366562430752, 12.92952913951252],
						[77.57360318479213, 12.929364189663769],
						[77.57355858511718, 12.929386438042776],
						[77.57349168557182, 12.92940873529007],
						[77.57340694609094, 12.929435518964848],
						[77.57325985429236, 12.92947120040408],
						[77.57299671515806, 12.929529144106064],
						[77.57280948285252, 12.92956039674619],
						[77.5720201479253, 12.929676310004478],
						[77.57169465279293, 12.92974314922196],
						[77.57145389773103, 12.929778895709953],
						[77.57113732109855, 12.929810049560558],
						[77.57103473958549, 12.929203609299961],
						[77.57080727589891, 12.9277187809081],
						[77.57069586137744, 12.926456824980619],
						[77.56977279572122, 12.92647908792492],
						[77.5695364966998, 12.926452390683728],
						[77.56970143381854, 12.926340863657616],
						[77.57004923521627, 12.92609117748969],
						[77.57053083825448, 12.925328652652281],
						[77.57048177727964, 12.924655344495932],
						[77.57021871914117, 12.923585136581057],
						[77.56973711477549, 12.921609777368703],
						[77.56915301259751, 12.91945605095111],
						[77.56869816466177, 12.918149515718708],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000094",
			properties: {
				AREA_SQ_KM: 1.109601084497731,
				ASS_CONST1: "Padmanaba Nagar",
				ASS_CONST_: "171",
				LAT: 12.9243524235242,
				LON: 77.5740169457256,
				LOST_AREA_SQ_KM: 0.04066846072764677,
				OBJECTID: 149,
				POP_F: 14761.0,
				POP_M: 15445.0,
				POP_SC: 2575.0,
				POP_ST: 365.0,
				POP_TOTAL: 30206.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Karisandra",
				WARD_NO: 166.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57379933570292, 12.94063227042678],
						[77.57383055541082, 12.939575530497782],
						[77.57382609545307, 12.935031718972832],
						[77.57383055541082, 12.934759648950171],
						[77.57346938571459, 12.934853303793762],
						[77.57322863442707, 12.934897928116834],
						[77.57304131505438, 12.934924693099891],
						[77.57283178283977, 12.93493806443092],
						[77.57183737404087, 12.934960331013452],
						[77.57173033312178, 12.93496035612837],
						[77.57169019275103, 12.934929120677449],
						[77.57166789253891, 12.934866679315682],
						[77.57163221219038, 12.934434173844922],
						[77.57151633843192, 12.933421967522033],
						[77.57136915672473, 12.93342195942045],
						[77.57123089494671, 12.933417467151896],
						[77.57115070128906, 12.933413008397991],
						[77.57103473958549, 12.933421913502242],
						[77.57085633673272, 12.933453156793187],
						[77.5707404621802, 12.933484389492715],
						[77.57033914177575, 12.933600328537283],
						[77.57013397721045, 12.933658296648662],
						[77.56978617607297, 12.933738538859753],
						[77.56959885100464, 12.933778660666999],
						[77.56946959477774, 12.933805456747569],
						[77.56932241041011, 12.933827684442216],
						[77.56928672932249, 12.933836656423784],
						[77.56920653428793, 12.933564659582249],
						[77.5690548894327, 12.933613650467183],
						[77.56888986391834, 12.933662773524821],
						[77.56876952719666, 12.933684999020205],
						[77.56855989962246, 12.933711808328956],
						[77.56841726169787, 12.933720722084523],
						[77.56815857149036, 12.933738534950585],
						[77.56812735021846, 12.933738498777789],
						[77.5681050493047, 12.93360477865341],
						[77.5680605349186, 12.933368457915],
						[77.56804269417741, 12.933297096099546],
						[77.56798917193697, 12.933141017080162],
						[77.56788658757215, 12.93280657863132],
						[77.56778400311438, 12.93248550072719],
						[77.56769042641803, 12.932258114200776],
						[77.56764136421268, 12.932159998302048],
						[77.56757446117113, 12.932061886505327],
						[77.56749417746913, 12.931963820905157],
						[77.56744065496953, 12.931914791141635],
						[77.56743619476008, 12.931910267199846],
						[77.56741389371022, 12.931883596595467],
						[77.56737829947647, 12.931847926419724],
						[77.5673247769221, 12.931727445851678],
						[77.56726233391005, 12.931517934142212],
						[77.56709284556094, 12.931014026265892],
						[77.56681639884985, 12.930309503450367],
						[77.5666737587297, 12.929961685726946],
						[77.5671062262293, 12.929957226393409],
						[77.56782414486979, 12.929957181984468],
						[77.5685732801175, 12.929939416204746],
						[77.56934025094971, 12.929917075541077],
						[77.56972373441795, 12.929908188159985],
						[77.57100797917549, 12.929823483870203],
						[77.57113732109855, 12.929810049560558],
						[77.57145389773103, 12.929778895709953],
						[77.57169465279293, 12.92974314922196],
						[77.5720201479253, 12.929676310004478],
						[77.57280948285252, 12.92956039674619],
						[77.57299671515806, 12.929529144106064],
						[77.57325985429236, 12.92947120040408],
						[77.57340694609094, 12.929435518964848],
						[77.57349168557182, 12.92940873529007],
						[77.57355858511718, 12.929386438042776],
						[77.57360318479213, 12.929364189663769],
						[77.57366562430752, 12.92952913951252],
						[77.57401341352701, 12.929453370453597],
						[77.57457973836739, 12.92931061702926],
						[77.5750479424034, 12.929243734703489],
						[77.57526193041667, 12.930305014954488],
						[77.57552506428448, 12.930305074429977],
						[77.5763544260511, 12.930345181049912],
						[77.57632320703944, 12.930585962985],
						[77.57618049144794, 12.930902580079838],
						[77.57701439569051, 12.930889207406143],
						[77.57783482694114, 12.930862428217337],
						[77.57902541233827, 12.930840153650129],
						[77.57960955101356, 12.930831236475173],
						[77.57958279263119, 12.93305630531279],
						[77.57964968857524, 12.9330563299329],
						[77.58125946413847, 12.93302505641008],
						[77.58234744222852, 12.932989396917556],
						[77.58382339255586, 12.932953746698225],
						[77.58392150291424, 12.93295371766278],
						[77.58482670248902, 12.932935902853922],
						[77.58488467628744, 12.934608031356108],
						[77.58490251437327, 12.936489784528106],
						[77.58490251437327, 12.936645887966241],
						[77.58288259558981, 12.936721682720615],
						[77.58060843864722, 12.936748437735226],
						[77.58030071958548, 12.93675287335684],
						[77.58017584784335, 12.936752906794798],
						[77.58010003278979, 12.936761848373367],
						[77.58001538570339, 12.936824238181634],
						[77.57993957054227, 12.93687774316503],
						[77.57973888310576, 12.937114103697587],
						[77.5791592048461, 12.937827536668209],
						[77.57791510330972, 12.939379281782758],
						[77.57768328109225, 12.939669140135127],
						[77.57766990167403, 12.939771675710304],
						[77.57766990167403, 12.939901058716803],
						[77.5777367113022, 12.940030291894143],
						[77.5777545505156, 12.940128467103849],
						[77.57775901031852, 12.940257720143896],
						[77.57775009071251, 12.94058768952149],
						[77.57765652225422, 12.940587759725048],
						[77.5762518492662, 12.940601079639338],
						[77.57402679337758, 12.94063674414813],
						[77.57379933570292, 12.94063227042678],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000095",
			properties: {
				AREA_SQ_KM: 1.2297655106653675,
				ASS_CONST1: "Padmanaba Nagar",
				ASS_CONST_: "171",
				LAT: 12.934251739139,
				LON: 77.5760804828518,
				LOST_AREA_SQ_KM: 0.033915353150371474,
				OBJECTID: 150,
				POP_F: 16734.0,
				POP_M: 17857.0,
				POP_SC: 1111.0,
				POP_ST: 180.0,
				POP_TOTAL: 34591.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Yediyur",
				WARD_NO: 167.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56484548303989, 12.916744925713655],
						[77.5649882129189, 12.916365880533105],
						[77.56561247911252, 12.914836413983252],
						[77.56585324659223, 12.914238885907924],
						[77.56603156986979, 12.91384647477517],
						[77.56609847382258, 12.913743950562012],
						[77.56631256620592, 12.913480884036888],
						[77.5671062262293, 12.91256676182991],
						[77.5677973837011, 12.91168382528616],
						[77.56868032402103, 12.910582425035255],
						[77.56912625174282, 12.910065159307615],
						[77.56935809148649, 12.909784245788497],
						[77.56992889972656, 12.909093130581647],
						[77.57023209944023, 12.908687330534729],
						[77.57044163646624, 12.908406381052703],
						[77.57077605535724, 12.90796045831695],
						[77.57101243924426, 12.907688466854982],
						[77.57156539895847, 12.907108778858202],
						[77.57174817328199, 12.906899188767348],
						[77.57194441485875, 12.906752030164734],
						[77.5720915083666, 12.90666291984247],
						[77.57219408892215, 12.906627168463405],
						[77.57250620258984, 12.906573722744135],
						[77.57309483490678, 12.906488991857525],
						[77.57325985429236, 12.90648898085208],
						[77.5731661946706, 12.90714448176573],
						[77.57298779517667, 12.908468847419527],
						[77.57294319525926, 12.909044071511799],
						[77.57293427527367, 12.909168854317684],
						[77.57291197530661, 12.910230142707968],
						[77.5728941353298, 12.911465298614193],
						[77.5728941353298, 12.911630325263287],
						[77.57292089529396, 12.912120830989542],
						[77.57296995521182, 12.91237947406767],
						[77.5730100951288, 12.912580123676841],
						[77.573059155008, 12.91272277398729],
						[77.57313051479431, 12.912985849814286],
						[77.57327323423202, 12.91347193106041],
						[77.57339802614189, 12.914011446109743],
						[77.57348276562946, 12.914573343652263],
						[77.57355858511718, 12.915037089456012],
						[77.57358088495687, 12.915161912381452],
						[77.57363440455414, 12.915282287790486],
						[77.57366116434329, 12.915353635705735],
						[77.5736745442355, 12.91553646013189],
						[77.5736700842716, 12.915656896611196],
						[77.5736923840893, 12.915866434383364],
						[77.5736923840893, 12.915946753414875],
						[77.57370130401515, 12.916174177989737],
						[77.57370130401515, 12.91624992972258],
						[77.57366116434329, 12.916646841968653],
						[77.5736477844495, 12.916914391315501],
						[77.57309929489332, 12.916744913563395],
						[77.57255526268743, 12.916597797500373],
						[77.57176155340031, 12.916570977696463],
						[77.57127549553871, 12.916593313740984],
						[77.57079389566782, 12.916477373281488],
						[77.57038811535958, 12.916365848829209],
						[77.57008045595835, 12.915920010155093],
						[77.56999125381532, 12.915665809975797],
						[77.56964791236202, 12.915545392040356],
						[77.5694651346482, 12.915705954450418],
						[77.56907719015945, 12.91655315633773],
						[77.5687739873536, 12.917181917892927],
						[77.5686669435386, 12.917538642062752],
						[77.56865356305462, 12.917837385938977],
						[77.56865802321613, 12.917890859001684],
						[77.56865802321613, 12.917957820380733],
						[77.5686714036996, 12.918024648888972],
						[77.56867586386039, 12.918091566044993],
						[77.56869816466177, 12.918149515718708],
						[77.56857774028218, 12.91818070232051],
						[77.56602710960487, 12.918764841145753],
						[77.56574620001713, 12.917980042850326],
						[77.56552327345071, 12.91752077364506],
						[77.56546528973287, 12.917404835899621],
						[77.56539392511634, 12.917297821194623],
						[77.56530034644565, 12.917195254759937],
						[77.56522898172523, 12.917115041611021],
						[77.56500605414114, 12.916883113018482],
						[77.56489900676559, 12.916780589370362],
						[77.56484548303989, 12.916744925713655],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000096",
			properties: {
				AREA_SQ_KM: 0.685762505678536,
				ASS_CONST1: "Padmanaba Nagar",
				ASS_CONST_: "171",
				LAT: 12.9133515290769,
				LON: 77.5698323279022,
				LOST_AREA_SQ_KM: 0.01375892001309862,
				OBJECTID: 151,
				POP_F: 16571.0,
				POP_M: 17451.0,
				POP_SC: 2548.0,
				POP_ST: 494.0,
				POP_TOTAL: 34022.0,
				RESERVATIO: "General",
				WARD_NAME: "Banashankari Temple ward",
				WARD_NO: 180.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.56341861165262, 12.919447167767622],
						[77.563124314461, 12.919166213998654],
						[77.56282109572308, 12.91858648251775],
						[77.56270512549231, 12.918385887828308],
						[77.56245989091823, 12.917730353705444],
						[77.56217005165401, 12.917128350869229],
						[77.56199163484038, 12.916548683018474],
						[77.56171517568373, 12.91609391620664],
						[77.56141641362255, 12.915692542991067],
						[77.56109088802133, 12.915251106482021],
						[77.56060487183613, 12.914617890330613],
						[77.56010993257189, 12.914105152234423],
						[77.55974871912785, 12.913739478733437],
						[77.55958376752102, 12.913726123174694],
						[77.55953470180587, 12.913699378139269],
						[77.55946779397827, 12.913685994394267],
						[77.55941426768776, 12.913672564296013],
						[77.55935190777821, 12.913663707771436],
						[77.55930284196272, 12.913659195558951],
						[77.55920917080147, 12.913650299285717],
						[77.55910666594995, 12.913645804198115],
						[77.55906206056754, 12.91364133394672],
						[77.55900853408549, 12.913619114063955],
						[77.55897284975008, 12.913601198556577],
						[77.55895500757816, 12.913578970064094],
						[77.55894608649115, 12.913543296342041],
						[77.55891932322592, 12.913454113168866],
						[77.55889255995436, 12.913222226926367],
						[77.55887025722323, 12.91295022649227],
						[77.55886579667649, 12.912847638498695],
						[77.55886579667649, 12.912762930408253],
						[77.55886133612955, 12.912718336975114],
						[77.55882573920955, 12.912615804090441],
						[77.55860271156948, 12.911978094996885],
						[77.55852696953389, 12.911732914617543],
						[77.5584957456084, 12.911661528665554],
						[77.55845560054873, 12.911612463510593],
						[77.55841545547484, 12.911295920805388],
						[77.55829510763006, 12.910734072911046],
						[77.55833962585207, 12.910595838896752],
						[77.55845113998566, 12.910212306541432],
						[77.55851804841319, 12.90977536046678],
						[77.55851358785257, 12.908656138738618],
						[77.5582505019284, 12.908210152540814],
						[77.55803648177854, 12.90775980440731],
						[77.55850020616971, 12.907349598693767],
						[77.55906206056754, 12.906992877394242],
						[77.55945441240802, 12.906640594068415],
						[77.55893716540344, 12.90621696291925],
						[77.55805869721134, 12.905490165578367],
						[77.55732742235209, 12.904928301061508],
						[77.55683692940853, 12.904428843244236],
						[77.55655153640758, 12.903501345422605],
						[77.55632413111186, 12.903046585198718],
						[77.55635981628073, 12.90298858671678],
						[77.55618593844021, 12.902248351404824],
						[77.55670319799367, 12.902128006499938],
						[77.55633305240514, 12.900852665980258],
						[77.55572220458626, 12.898886221965851],
						[77.55569990123166, 12.8985384359064],
						[77.55569097988858, 12.898043440586935],
						[77.55529406687612, 12.897749097621523],
						[77.55566421585516, 12.89749494637894],
						[77.55578902715969, 12.897410211584209],
						[77.55564637316272, 12.897249737661078],
						[77.55554823830406, 12.897133792513287],
						[77.555311909624, 12.896848369255972],
						[77.55516033364219, 12.896674457748652],
						[77.55491954345203, 12.89636685092935],
						[77.55529406687612, 12.896076983395623],
						[77.5561101073267, 12.89572025598956],
						[77.55644456851181, 12.895577519458685],
						[77.55651139065968, 12.895573058967313],
						[77.55670319799367, 12.895519628237924],
						[77.55695736626394, 12.89544825393518],
						[77.55705095182219, 12.895408115424543],
						[77.55739433144262, 12.89525650007688],
						[77.55785359787315, 12.895051340794364],
						[77.55806315778983, 12.894953268458778],
						[77.5581702116212, 12.894908692402797],
						[77.558228199071, 12.89489088333604],
						[77.55828618649113, 12.894904264046893],
						[77.55833070471664, 12.894957751848466],
						[77.55834408641951, 12.89500233556249],
						[77.55837531038675, 12.895087064502558],
						[77.55842883716772, 12.895234209803503],
						[77.55846006111163, 12.895252078785745],
						[77.55851804841319, 12.895252018591366],
						[77.55857157512679, 12.89525200277988],
						[77.55866515935291, 12.895243139478309],
						[77.55882573920955, 12.89521638227643],
						[77.55890148104558, 12.895189602864496],
						[77.55895500757816, 12.895167357656732],
						[77.55897731029262, 12.895136118007327],
						[77.55901745516758, 12.895064722485339],
						[77.55904867894941, 12.894864089811353],
						[77.55914226278175, 12.894284418539366],
						[77.55962837269819, 12.894195221547408],
						[77.5596506752802, 12.894293307414852],
						[77.55972195605209, 12.894587603111045],
						[77.55974871912785, 12.894930942400014],
						[77.55985577136782, 12.8954750123805],
						[77.5599137579556, 12.8957782372829],
						[77.56016791902965, 12.896179537487512],
						[77.56008763008022, 12.896250902957492],
						[77.56123362262893, 12.899287554895398],
						[77.56098392440772, 12.89950157717742],
						[77.5601054720739, 12.90025065263031],
						[77.56013669555608, 12.900500390586101],
						[77.56033733027563, 12.900669857230936],
						[77.5674674162225, 12.906569236596496],
						[77.57021425904114, 12.908669498216863],
						[77.57023209944023, 12.908687330534729],
						[77.56992889972656, 12.909093130581647],
						[77.56935809148649, 12.909784245788497],
						[77.56912625174282, 12.910065159307615],
						[77.56868032402103, 12.910582425035255],
						[77.5677973837011, 12.91168382528616],
						[77.5671062262293, 12.91256676182991],
						[77.56631256620592, 12.913480884036888],
						[77.56609847382258, 12.913743950562012],
						[77.56603156986979, 12.91384647477517],
						[77.56585324659223, 12.914238885907924],
						[77.56561247911252, 12.914836413983252],
						[77.5649882129189, 12.916365880533105],
						[77.56484548303989, 12.916744925713655],
						[77.56472059424829, 12.917079327049771],
						[77.56445752272987, 12.917752663561652],
						[77.56438624493605, 12.917913235823432],
						[77.56422121262045, 12.918256568876732],
						[77.56406964854921, 12.9185329856151],
						[77.56389569507506, 12.918809447062054],
						[77.56365046307725, 12.91917064440396],
						[77.56352557296856, 12.919331226541225],
						[77.56341861165262, 12.919447167767622],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000097",
			properties: {
				AREA_SQ_KM: 1.8646117120631278,
				ASS_CONST1: "Padmanaba Nagar",
				ASS_CONST_: "171",
				LAT: 12.9069901964838,
				LON: 77.5618576805592,
				LOST_AREA_SQ_KM: 0.06493256370658126,
				OBJECTID: 152,
				POP_F: 16735.0,
				POP_M: 18649.0,
				POP_SC: 1428.0,
				POP_ST: 426.0,
				POP_TOTAL: 35384.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Kumaraswamy Layout",
				WARD_NO: 181.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.55627952463497, 12.922978727511037],
						[77.55616809582982, 12.922581853566388],
						[77.55597191439428, 12.92201113190171],
						[77.55578456649174, 12.921382409340703],
						[77.55564637316272, 12.920998904775885],
						[77.55552593491524, 12.921003367436874],
						[77.55524954745866, 12.921021184513204],
						[77.55521832263086, 12.92102562898219],
						[77.55519155848594, 12.921025634374393],
						[77.5551201874019, 12.921021167476383],
						[77.55504881627296, 12.92101230547542],
						[77.55498199326348, 12.920994441162371],
						[77.55491508274991, 12.920967696297152],
						[77.55489723993962, 12.92096321861483],
						[77.5548169472586, 12.920967715934236],
						[77.55473674198565, 12.920976612505328],
						[77.55465644919101, 12.92099886241954],
						[77.55461630277242, 12.921012271079029],
						[77.55454493132645, 12.921021161586205],
						[77.55446472586118, 12.921021181714604],
						[77.55438443287404, 12.921016716355549],
						[77.55430860055624, 12.92100333067872],
						[77.55422839492388, 12.920985511911192],
						[77.55415702323388, 12.920958724670928],
						[77.55413025883857, 12.920949853626764],
						[77.55404113160732, 12.920900754058898],
						[77.55371558483505, 12.920726872586947],
						[77.55355508520938, 12.92064659076967],
						[77.55350601688615, 12.92061984225838],
						[77.55339895865268, 12.920552989499447],
						[77.55329644855016, 12.920481615806166],
						[77.55319831165876, 12.92040140392278],
						[77.55316262549539, 12.920374609767427],
						[77.55309580137497, 12.920321067905824],
						[77.5530244289725, 12.920258689586166],
						[77.55296197808353, 12.920191828375259],
						[77.55289952716019, 12.920120486013957],
						[77.55288168403293, 12.920093688473608],
						[77.55285946758623, 12.920049100504311],
						[77.55268103612428, 12.919732522085377],
						[77.55267657533415, 12.919710246325472],
						[77.55266765375333, 12.91969240952404],
						[77.5526542713808, 12.919674530502975],
						[77.55264088900668, 12.919661132660316],
						[77.55262313330526, 12.91964778298006],
						[77.5526008293414, 12.919638868044105],
						[77.55258298616718, 12.91963439032846],
						[77.55256068219542, 12.919634437737107],
						[77.5525383782193, 12.919634398967078],
						[77.55251607423878, 12.91963884136726],
						[77.55249823105123, 12.919647807163075],
						[77.55248484865871, 12.919661124003479],
						[77.55247592706283, 12.919665606900152],
						[77.55242685827291, 12.919701250316827],
						[77.55237341612641, 12.919728065705145],
						[77.55231988648801, 12.919750309519651],
						[77.55226189601791, 12.91976372109057],
						[77.55220390551816, 12.919768170296127],
						[77.55219498390018, 12.919768172005504],
						[77.55214600245567, 12.91977262369254],
						[77.5521415416445, 12.919768186461766],
						[77.55203894293906, 12.91922861400381],
						[77.5519809523254, 12.91889421985559],
						[77.55194535324621, 12.918506268614873],
						[77.55199879559429, 12.918207484184205],
						[77.55195418741677, 12.917877528549933],
						[77.55173569456069, 12.917431578240727],
						[77.55161980046107, 12.917168514667425],
						[77.55153058379057, 12.917052541643988],
						[77.55100892726611, 12.916575468313521],
						[77.55073690220512, 12.916218724128454],
						[77.55061645874639, 12.915830752695804],
						[77.550491641752, 12.915349247350521],
						[77.55154842713027, 12.915144052915842],
						[77.55185167600906, 12.915086164988983],
						[77.55228866085395, 12.914970164220042],
						[77.55256068219542, 12.914858725103116],
						[77.55325184088277, 12.914564416016646],
						[77.55353278142871, 12.914430654754504],
						[77.5535817622746, 12.914408364367034],
						[77.55359068378355, 12.914363724940555],
						[77.55357730151988, 12.91431473859019],
						[77.55348817385425, 12.914207649265228],
						[77.55334989026191, 12.914038220557037],
						[77.55325184088277, 12.91391786290781],
						[77.55317600780795, 12.913788491425649],
						[77.55288168403293, 12.913141924272928],
						[77.55271672243911, 12.912780775934525],
						[77.55257852537316, 12.912553360789998],
						[77.55232880809616, 12.912089646577904],
						[77.55227973924262, 12.912009302938253],
						[77.55225743521127, 12.911955838837054],
						[77.55225297440448, 12.911942440261768],
						[77.55225297440448, 12.911933564684675],
						[77.55225297440448, 12.911924602937614],
						[77.55225297440448, 12.911915727361873],
						[77.55224851359752, 12.911906809664353],
						[77.55224851359752, 12.911897847919464],
						[77.55225297440448, 12.911888928297472],
						[77.55225297440448, 12.911875571852878],
						[77.55225297440448, 12.911857734538932],
						[77.55226189601791, 12.911844376170219],
						[77.55226635682435, 12.911835456550941],
						[77.55228866085395, 12.91180421489613],
						[77.55233772970361, 12.911786410052978],
						[77.55243131907287, 12.911759629572286],
						[77.55294413496608, 12.911648133602515],
						[77.55370220258611, 12.911465357173688],
						[77.55403667087064, 12.911402933298339],
						[77.55415256250177, 12.911376189974927],
						[77.55420609127559, 12.911371696984387],
						[77.5542862969237, 12.911371674587258],
						[77.55435766853236, 12.911389495745654],
						[77.55445580442097, 12.911420753457863],
						[77.55457169562395, 12.911465359375763],
						[77.55468321346221, 12.911514408308294],
						[77.55477234018903, 12.911554539427046],
						[77.5548749364228, 12.911594628602783],
						[77.55494630766115, 12.91159461226379],
						[77.5551201874019, 12.911581254693818],
						[77.55551701355849, 12.911505453229154],
						[77.55559730574399, 12.911501039857527],
						[77.55570882257402, 12.91149657609988],
						[77.55581133049677, 12.911509947613125],
						[77.5558603978229, 12.911527730532333],
						[77.55589608313767, 12.911541164923042],
						[77.55579794849504, 12.911385045546448],
						[77.55550809220104, 12.910760766249073],
						[77.55520940124994, 12.910136497307285],
						[77.55509788392892, 12.909949232954766],
						[77.55496415046372, 12.909695067613843],
						[77.55487047571893, 12.909543474139099],
						[77.55467429203918, 12.909543438211099],
						[77.55465198847855, 12.909360634942884],
						[77.55463860634006, 12.909195666921292],
						[77.55463860634006, 12.909133194226182],
						[77.5546252242, 12.909088604816668],
						[77.55457169562395, 12.909008393942987],
						[77.55452708845796, 12.908950412650427],
						[77.55432198273365, 12.908352884589384],
						[77.55412133737205, 12.907835624658992],
						[77.55399652423272, 12.907532433304956],
						[77.55357284076497, 12.906328478748694],
						[77.55255622140055, 12.906720885227964],
						[77.55201217804412, 12.906997270705245],
						[77.55164656544856, 12.907117737999695],
						[77.55141022864157, 12.907189012859392],
						[77.55105790920139, 12.907291592070122],
						[77.55037128550127, 12.907469954976397],
						[77.54995204939594, 12.90760378225458],
						[77.5494125419438, 12.90774647505652],
						[77.54926095808183, 12.907786579468338],
						[77.54924757530759, 12.907737510361184],
						[77.54917620048508, 12.907559160664682],
						[77.54879273498415, 12.90668966207347],
						[77.54856977509077, 12.906020811073908],
						[77.54840480722989, 12.905499078390838],
						[77.5482665174439, 12.904705317472319],
						[77.5482219952695, 12.904379803369874],
						[77.54825313455247, 12.904241628348792],
						[77.54825313455247, 12.904152362560422],
						[77.54827990033378, 12.903960642198395],
						[77.54832004899394, 12.903733290496202],
						[77.54827543937067, 12.903617324351716],
						[77.54825313455247, 12.903394381584157],
						[77.54833343187752, 12.903430035231136],
						[77.5484449558459, 12.903514751941751],
						[77.54861438459838, 12.903706509741221],
						[77.54873474271191, 12.903768904068633],
						[77.54883288346295, 12.90387598355567],
						[77.54897554602549, 12.90396064630177],
						[77.54906476470433, 12.9042460876338],
						[77.54934562814624, 12.904179195560733],
						[77.54952843829723, 12.90413455993589],
						[77.54975585721286, 12.904081039030974],
						[77.54992983237574, 12.904036495405169],
						[77.55013494603874, 12.90399189713362],
						[77.55023299811087, 12.903991870298416],
						[77.55029545050158, 12.90400081695858],
						[77.55039350243506, 12.904049817555613],
						[77.55053625050438, 12.904134570261402],
						[77.55048271999942, 12.904219282327107],
						[77.55030883315227, 12.904348616520823],
						[77.55031329403548, 12.904402080605571],
						[77.55037574638202, 12.904455660629036],
						[77.55051840700553, 12.90445562353786],
						[77.55062538048847, 12.904455594607738],
						[77.55074582393772, 12.904388745994398],
						[77.55090632753672, 12.904237140822703],
						[77.55098216212825, 12.904130149781022],
						[77.55110697858322, 12.904014138855205],
						[77.55123634321544, 12.903938412926044],
						[77.5513790027572, 12.90386255029624],
						[77.55156180963323, 12.903826873751203],
						[77.55165994793992, 12.90382685043218],
						[77.5517669203469, 12.90382690690483],
						[77.5518873625847, 12.903826921249669],
						[77.55200771722768, 12.9039071505999],
						[77.55215046326667, 12.903982941373526],
						[77.55222620956003, 12.903982962070545],
						[77.55246254466768, 12.903902724657462],
						[77.55264534979823, 12.903880402647333],
						[77.55275240874273, 12.903795676688292],
						[77.5529129095038, 12.903688616761235],
						[77.5530556544041, 12.903581651448341],
						[77.55311801777538, 12.903501412617073],
						[77.55322061550515, 12.903474633276524],
						[77.55370220258611, 12.903340864303619],
						[77.55392069165562, 12.903345329297693],
						[77.55423731638197, 12.903318577798515],
						[77.5544513437006, 12.903345316998879],
						[77.55466090990333, 12.903376494655811],
						[77.55485709360622, 12.903403238056526],
						[77.55499537536147, 12.903403246013111],
						[77.55508004114739, 12.903398782737137],
						[77.55521832263086, 12.90334528201545],
						[77.55584701582696, 12.903166961257096],
						[77.55588270114593, 12.903149115831827],
						[77.55614133190898, 12.903104497034688],
						[77.55632413111186, 12.903046585198718],
						[77.55655153640758, 12.903501345422605],
						[77.55683692940853, 12.904428843244236],
						[77.55732742235209, 12.904928301061508],
						[77.55805869721134, 12.905490165578367],
						[77.55893716540344, 12.90621696291925],
						[77.55945441240802, 12.906640594068415],
						[77.55906206056754, 12.906992877394242],
						[77.55850020616971, 12.907349598693767],
						[77.55803648177854, 12.90775980440731],
						[77.5582505019284, 12.908210152540814],
						[77.55851358785257, 12.908656138738618],
						[77.55851804841319, 12.90977536046678],
						[77.55845113998566, 12.910212306541432],
						[77.55833962585207, 12.910595838896752],
						[77.55829510763006, 12.910734072911046],
						[77.55841545547484, 12.911295920805388],
						[77.55845560054873, 12.911612463510593],
						[77.5584957456084, 12.911661528665554],
						[77.55852696953389, 12.911732914617543],
						[77.55860271156948, 12.911978094996885],
						[77.55882573920955, 12.912615804090441],
						[77.55886133612955, 12.912718336975114],
						[77.55886579667649, 12.912762930408253],
						[77.55886579667649, 12.912847638498695],
						[77.55887025722323, 12.91295022649227],
						[77.55889255995436, 12.913222226926367],
						[77.55891932322592, 12.913454113168866],
						[77.55894608649115, 12.913543296342041],
						[77.55895500757816, 12.913578970064094],
						[77.55897284975008, 12.913601198556577],
						[77.55900853408549, 12.913619114063955],
						[77.55906206056754, 12.91364133394672],
						[77.55910666594995, 12.913645804198115],
						[77.55920917080147, 12.913650299285717],
						[77.55930284196272, 12.913659195558951],
						[77.55935190777821, 12.913663707771436],
						[77.55941426768776, 12.913672564296013],
						[77.55946779397827, 12.913685994394267],
						[77.55953470180587, 12.913699378139269],
						[77.55958376752102, 12.913726123174694],
						[77.55974871912785, 12.913739478733437],
						[77.56010993257189, 12.914105152234423],
						[77.56060487183613, 12.914617890330613],
						[77.56109088802133, 12.915251106482021],
						[77.56141641362255, 12.915692542991067],
						[77.56171517568373, 12.91609391620664],
						[77.56199163484038, 12.916548683018474],
						[77.56217005165401, 12.917128350869229],
						[77.56245989091823, 12.917730353705444],
						[77.56270512549231, 12.918385887828308],
						[77.56282109572308, 12.91858648251775],
						[77.563124314461, 12.919166213998654],
						[77.56341861165262, 12.919447167767622],
						[77.56332494389237, 12.919554181981518],
						[77.56315991003119, 12.919665608609225],
						[77.56299942377248, 12.919754760899963],
						[77.562709585888, 12.919910862239252],
						[77.56242866807597, 12.920102639254289],
						[77.56229485579739, 12.92019626172952],
						[77.56225917249643, 12.920249787561968],
						[77.56215667040273, 12.92042368299063],
						[77.56185344907604, 12.921137153780476],
						[77.56169287350788, 12.921516124588415],
						[77.56161267309807, 12.92160982509567],
						[77.56153238517189, 12.921627596587582],
						[77.56140749272922, 12.9216588223272],
						[77.56109534848053, 12.921712329147827],
						[77.5603105673388, 12.921846097010233],
						[77.55950347815791, 12.9220022301442],
						[77.55911558702432, 12.922082427069729],
						[77.55874990986115, 12.922184977877695],
						[77.55751476770612, 12.9225684581308],
						[77.55721599457645, 12.922671034992701],
						[77.55691722065968, 12.9227780927031],
						[77.55655153640758, 12.92288951551306],
						[77.5564490291538, 12.92292526195511],
						[77.55627952463497, 12.922978727511037],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000098",
			properties: {
				AREA_SQ_KM: 1.6936026687585521,
				ASS_CONST1: "Padmanaba Nagar",
				ASS_CONST_: "171",
				LAT: 12.9135013256266,
				LON: 77.5560571929402,
				LOST_AREA_SQ_KM: 0.06585043968248555,
				OBJECTID: 153,
				POP_F: 12323.0,
				POP_M: 13131.0,
				POP_SC: 1150.0,
				POP_ST: 111.0,
				POP_TOTAL: 25454.0,
				RESERVATIO: "General",
				WARD_NAME: "Padmanabha Nagar",
				WARD_NO: 182.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.54624209664131, 12.928075477188989],
						[77.54611281378952, 12.927910506095609],
						[77.54582739381956, 12.927362010775198],
						[77.54560888917145, 12.927205929892741],
						[77.54538601049839, 12.927210450528634],
						[77.5452298727059, 12.926813570434675],
						[77.54483301058315, 12.925881647241972],
						[77.5445253693192, 12.925993084595508],
						[77.54433808999559, 12.92603766901227],
						[77.54426671208712, 12.925774574760798],
						[77.54400814173917, 12.924976434358367],
						[77.54384754091552, 12.924610717474119],
						[77.54358896961341, 12.92372787438027],
						[77.54345076143653, 12.923094627835585],
						[77.54345968374163, 12.92255068142682],
						[77.54346851857247, 12.92188625966034],
						[77.54309841729828, 12.920909741006419],
						[77.54275954308176, 12.92016059789703],
						[77.54231368664215, 12.919522903093315],
						[77.54201933470834, 12.918862949149833],
						[77.54199702866147, 12.918791644269376],
						[77.54197918382081, 12.91870693888705],
						[77.54196580018849, 12.91851068218908],
						[77.54196580018848, 12.918483968628943],
						[77.54287107252377, 12.917855227837926],
						[77.54311180079719, 12.917694728692531],
						[77.54317871826804, 12.917654604917695],
						[77.54331246561783, 12.917618904253393],
						[77.54344630028372, 12.917623364087415],
						[77.5439456858903, 12.917587682530694],
						[77.54439608451322, 12.917551968855607],
						[77.54441839008902, 12.917552008310025],
						[77.54472157054, 12.91753419867447],
						[77.5448018703623, 12.91829664499606],
						[77.54567580517119, 12.91820306809946],
						[77.54592553708355, 12.918158470378536],
						[77.54620640829097, 12.918127262824779],
						[77.54640260660769, 12.918096030436496],
						[77.54672817435498, 12.91804698078011],
						[77.54677724560831, 12.91824762799719],
						[77.54679062867372, 12.91832789668166],
						[77.54703134863979, 12.918283294917698],
						[77.54741490756041, 12.918207477202609],
						[77.54752634496057, 12.918176301593101],
						[77.5476868531116, 12.918171788147491],
						[77.54785182201567, 12.918167316879178],
						[77.54809262720197, 12.918131680065615],
						[77.54844941680234, 12.918087060030464],
						[77.54860100174794, 12.918069276636587],
						[77.54858315794489, 12.91799344658025],
						[77.54857423604231, 12.917948896166251],
						[77.54852962651891, 12.917815075917812],
						[77.54848055602292, 12.917583233898334],
						[77.54845387775862, 12.917467253065857],
						[77.54839588531331, 12.917217575527284],
						[77.54818630754146, 12.917239893647633],
						[77.54813723689709, 12.917128351015082],
						[77.54811047108211, 12.917048128467378],
						[77.54805247846139, 12.916798452069173],
						[77.54799903425526, 12.91657993119031],
						[77.54799903425526, 12.916526417799385],
						[77.54799903425526, 12.916504099017171],
						[77.54800795620305, 12.916486259600902],
						[77.54803472204217, 12.916468416926957],
						[77.54807924429002, 12.916464009747422],
						[77.54818184657466, 12.916450591069944],
						[77.54838696339601, 12.916428316508203],
						[77.54845825124501, 12.91642381813214],
						[77.5484983998421, 12.91641937280314],
						[77.54853408747204, 12.916414971378234],
						[77.54867675041007, 12.916365908265384],
						[77.54888186633724, 12.916312352371579],
						[77.54916727862911, 12.916218710674015],
						[77.54947053386978, 12.91612954611838],
						[77.55047379824613, 12.91587094154223],
						[77.55061645874639, 12.915830752695804],
						[77.55073690220512, 12.916218724128454],
						[77.55100892726611, 12.916575468313521],
						[77.55153058379057, 12.917052541643988],
						[77.55161980046107, 12.917168514667425],
						[77.55173569456069, 12.917431578240727],
						[77.55195418741677, 12.917877528549933],
						[77.55199879559429, 12.918207484184205],
						[77.55194535324621, 12.918506268614873],
						[77.5519809523254, 12.91889421985559],
						[77.55203894293906, 12.91922861400381],
						[77.5521415416445, 12.919768186461766],
						[77.55214600245567, 12.91977262369254],
						[77.55219498390018, 12.919768172005504],
						[77.55220390551816, 12.919768170296127],
						[77.55226189601791, 12.91976372109057],
						[77.55231988648801, 12.919750309519651],
						[77.55237341612641, 12.919728065705145],
						[77.55242685827291, 12.919701250316827],
						[77.55247592706283, 12.919665606900152],
						[77.55248484865871, 12.919661124003479],
						[77.55249823105123, 12.919647807163075],
						[77.55251607423878, 12.91963884136726],
						[77.5525383782193, 12.919634398967078],
						[77.55256068219542, 12.919634437737107],
						[77.55258298616718, 12.91963439032846],
						[77.5526008293414, 12.919638868044105],
						[77.55262313330526, 12.91964778298006],
						[77.55264088900668, 12.919661132660316],
						[77.5526542713808, 12.919674530502975],
						[77.55266765375333, 12.91969240952404],
						[77.55267657533415, 12.919710246325472],
						[77.55268103612428, 12.919732522085377],
						[77.55285946758623, 12.920049100504311],
						[77.55288168403293, 12.920093688473608],
						[77.55289952716019, 12.920120486013957],
						[77.55296197808353, 12.920191828375259],
						[77.5530244289725, 12.920258689586166],
						[77.55309580137497, 12.920321067905824],
						[77.55316262549539, 12.920374609767427],
						[77.55319831165876, 12.92040140392278],
						[77.55329644855016, 12.920481615806166],
						[77.55339895865268, 12.920552989499447],
						[77.55350601688615, 12.92061984225838],
						[77.55355508520938, 12.92064659076967],
						[77.55371558483505, 12.920726872586947],
						[77.55404113160732, 12.920900754058898],
						[77.55413025883857, 12.920949853626764],
						[77.55415702323388, 12.920958724670928],
						[77.55422839492388, 12.920985511911192],
						[77.55430860055624, 12.92100333067872],
						[77.55438443287404, 12.921016716355549],
						[77.55446472586118, 12.921021181714604],
						[77.55454493132645, 12.921021161586205],
						[77.55461630277242, 12.921012271079029],
						[77.55465644919101, 12.92099886241954],
						[77.55473674198565, 12.920976612505328],
						[77.5548169472586, 12.920967715934236],
						[77.55489723993962, 12.92096321861483],
						[77.55491508274991, 12.920967696297152],
						[77.55498199326348, 12.920994441162371],
						[77.55504881627296, 12.92101230547542],
						[77.5551201874019, 12.921021167476383],
						[77.55519155848594, 12.921025634374393],
						[77.55521832263086, 12.92102562898219],
						[77.55524954745866, 12.921021184513204],
						[77.55552593491524, 12.921003367436874],
						[77.55564637316272, 12.920998904775885],
						[77.55578456649174, 12.921382409340703],
						[77.55597191439428, 12.92201113190171],
						[77.55616809582982, 12.922581853566388],
						[77.55627952463497, 12.922978727511037],
						[77.55535205579643, 12.923268578320666],
						[77.55438889359704, 12.923589628148397],
						[77.55434874708374, 12.923603036726936],
						[77.55332321314218, 12.923928491974193],
						[77.55292629184584, 12.924053400187793],
						[77.55243577987265, 12.924231716876184],
						[77.55239572013497, 12.92424961056806],
						[77.5519364316079, 12.924401161471053],
						[77.55170455623309, 12.924472563244061],
						[77.55116943049303, 12.9247133645416],
						[77.55109359602665, 12.924749012891413],
						[77.55093309269246, 12.924833759273854],
						[77.55025530254007, 12.925194893365155],
						[77.54993429327385, 12.925382223784046],
						[77.54911820840874, 12.92585933155201],
						[77.54875704743552, 12.926100141139747],
						[77.54826205648027, 12.926465759696242],
						[77.54724538954729, 12.92727732201033],
						[77.54624209664131, 12.928075477188989],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000be",
			properties: {
				AREA_SQ_KM: 1.086714435843098,
				ASS_CONST1: "Padmanaba Nagar",
				ASS_CONST_: "171",
				LAT: 12.9216847932897,
				LON: 77.548407399626,
				LOST_AREA_SQ_KM: 0.01350297685950784,
				OBJECTID: 191,
				POP_F: 11682.0,
				POP_M: 12995.0,
				POP_SC: 519.0,
				POP_ST: 145.0,
				POP_TOTAL: 24677.0,
				RESERVATIO: "General",
				WARD_NAME: "Chikkalsandra",
				WARD_NO: 183.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.60579789722833, 12.949978553203822],
						[77.60575776895915, 12.950094566255457],
						[77.60573101677178, 12.950098972302673],
						[77.60434417818243, 12.95029518150377],
						[77.6034167536908, 12.950437914653264],
						[77.60320710294211, 12.949693236806791],
						[77.60287715139158, 12.94884597451496],
						[77.60268096352556, 12.948315325973288],
						[77.6025828694646, 12.948052244244835],
						[77.60256057534794, 12.947976429663875],
						[77.60251152827577, 12.947789154232392],
						[77.60248477531832, 12.94758406926302],
						[77.60247139883721, 12.947392299608161],
						[77.60245802235453, 12.947231824439172],
						[77.60245802235453, 12.94709354398076],
						[77.60247139883721, 12.94687952403923],
						[77.60251598710138, 12.94637121841844],
						[77.60257841064161, 12.945635403843413],
						[77.6025828694646, 12.945604237883485],
						[77.60260962239877, 12.945238532151484],
						[77.60275676342334, 12.94463213605128],
						[77.60284148089586, 12.944426974897258],
						[77.60287269258022, 12.944101486710993],
						[77.60289498663519, 12.943686777867097],
						[77.60287715139158, 12.943370231261351],
						[77.60287269258022, 12.943187344636346],
						[77.60282364564377, 12.942830681208893],
						[77.60266758706773, 12.942161817673728],
						[77.60218603352916, 12.942259917587597],
						[77.60190503892055, 12.942304451100773],
						[77.60148590627149, 12.942393648108986],
						[77.60123175060883, 12.94244714091713],
						[77.6010756898305, 12.942464996441332],
						[77.60076356762616, 12.942505103614131],
						[77.60039793766, 12.942563073207184],
						[77.60009018507236, 12.94261218206258],
						[77.59982264947038, 12.942661237896171],
						[77.59944363961503, 12.942737011649271],
						[77.59905571056241, 12.942803908426834],
						[77.59872128789497, 12.942844062811792],
						[77.598493879915, 12.942879698369717],
						[77.59826647147678, 12.942924299029878],
						[77.59806135759044, 12.942973331631439],
						[77.59762437458949, 12.943102697509257],
						[77.59724535735117, 12.943200793945737],
						[77.59736575120012, 12.942335728613344],
						[77.59750398101644, 12.941426009221917],
						[77.59756640732961, 12.940556535459335],
						[77.59759762047325, 12.94020425436403],
						[77.59763775164524, 12.939990241141095],
						[77.59773139099106, 12.93982077715325],
						[77.59787853837754, 12.939397168814983],
						[77.59805689858878, 12.93909393282452],
						[77.59836902828016, 12.938621263246306],
						[77.5984983388994, 12.938451787816],
						[77.5987435827703, 12.938157537536714],
						[77.59891302367886, 12.937992487572698],
						[77.599082464333, 12.93785433257272],
						[77.599300953222, 12.937707152098222],
						[77.59961307947242, 12.937528776278876],
						[77.59984940305912, 12.937435120278348],
						[77.60009018507236, 12.937363831774729],
						[77.60030875943974, 12.937310269619637],
						[77.60050049252618, 12.937270160114513],
						[77.60088395772155, 12.937216663131938],
						[77.60159291901077, 12.93712748871812],
						[77.60288606901373, 12.936953578879812],
						[77.60463854294001, 12.93669496912671],
						[77.60588261241632, 12.936516531913384],
						[77.60640873689556, 12.93647193386677],
						[77.60725596874572, 12.936204464275121],
						[77.60804523235389, 12.935865508323323],
						[77.60896387647941, 12.935245695366078],
						[77.61005184986861, 12.934527792462575],
						[77.61085892788239, 12.933872282068656],
						[77.61153224612659, 12.933386298610596],
						[77.61248652928272, 12.932614826521602],
						[77.6134051372029, 12.931883536215839],
						[77.61379747526514, 12.931669499820378],
						[77.61313308736999, 12.932864582089561],
						[77.61245532026007, 12.934086355116722],
						[77.61230373345569, 12.9343627854886],
						[77.61223239841836, 12.934487637440501],
						[77.61222793997702, 12.934496561328565],
						[77.61174633985377, 12.935433012552219],
						[77.61080988442019, 12.937176521979218],
						[77.60982000619893, 12.939044894625559],
						[77.60932947867265, 12.939932229266542],
						[77.60857589274967, 12.941394797731943],
						[77.60814332166304, 12.942237592315912],
						[77.60787134655064, 12.94295102177355],
						[77.6077063777201, 12.943642172434677],
						[77.60760382940649, 12.944047951721389],
						[77.60757707765715, 12.944114859695585],
						[77.60714450273767, 12.945733552613165],
						[77.6068457732926, 12.946603047552193],
						[77.60648453428769, 12.947708869635214],
						[77.60619917913445, 12.948596259019753],
						[77.60588261241632, 12.949706566283792],
						[77.6058647776452, 12.949768990329053],
						[77.60586031895197, 12.949773431815805],
						[77.6058424841773, 12.949840338942833],
						[77.60579789722833, 12.949978553203822],
					],
				],
				type: "Polygon",
			},
			id: "00000000000000000099",
			properties: {
				AREA_SQ_KM: 1.297608944123309,
				ASS_CONST1: "B.T.M. Layout",
				ASS_CONST_: "172",
				LAT: 12.9411090124204,
				LON: 77.6048043772362,
				LOST_AREA_SQ_KM: 0.04932023123288622,
				OBJECTID: 154,
				POP_F: 13394.0,
				POP_M: 14909.0,
				POP_SC: 4562.0,
				POP_ST: 422.0,
				POP_TOTAL: 28303.0,
				RESERVATIO: "General",
				WARD_NAME: "Lakkasandra",
				WARD_NO: 146.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.60588261241632, 12.949706566283792],
						[77.60619917913445, 12.948596259019753],
						[77.60648453428769, 12.947708869635214],
						[77.6068457732926, 12.946603047552193],
						[77.60714450273767, 12.945733552613165],
						[77.60757707765715, 12.944114859695585],
						[77.60760382940649, 12.944047951721389],
						[77.6077063777201, 12.943642172434677],
						[77.60787134655064, 12.94295102177355],
						[77.60814332166304, 12.942237592315912],
						[77.60857589274967, 12.941394797731943],
						[77.60932947867265, 12.939932229266542],
						[77.60982000619893, 12.939044894625559],
						[77.61080988442019, 12.937176521979218],
						[77.61174633985377, 12.935433012552219],
						[77.61222793997702, 12.934496561328565],
						[77.61223239841836, 12.934487637440501],
						[77.61230373345569, 12.9343627854886],
						[77.61331142345288, 12.934839929370611],
						[77.61458678047545, 12.935499892797473],
						[77.61533586787807, 12.93586999639719],
						[77.61567478698558, 12.93605726403255],
						[77.61596912214854, 12.936173173163702],
						[77.61627674396924, 12.936249029832146],
						[77.61666470117976, 12.936311407455737],
						[77.61702590754783, 12.936356037899435],
						[77.6181941382156, 12.940329090761946],
						[77.61846171762843, 12.940993480063652],
						[77.6184929249867, 12.941038080048722],
						[77.61731123528277, 12.94112284120761],
						[77.61754760918224, 12.943561932785341],
						[77.61776606228199, 12.943530762357435],
						[77.61796676951826, 12.943472791691134],
						[77.61825664048754, 12.943445991700731],
						[77.61873821277305, 12.943463810760091],
						[77.61875158732339, 12.94357531949023],
						[77.61898341260982, 12.944596486700645],
						[77.61912616150235, 12.945028958429967],
						[77.61929111361016, 12.945332228492454],
						[77.61911278699652, 12.945452570233984],
						[77.61874267095668, 12.945697867034777],
						[77.61867133999762, 12.945773677928768],
						[77.6186266707098, 12.945858353455803],
						[77.61860437976699, 12.945894011812513],
						[77.61859546338862, 12.94595203691938],
						[77.6185999215779, 12.946010013396053],
						[77.6186266707098, 12.946081300596145],
						[77.61884966731053, 12.946522797379705],
						[77.61933569521942, 12.947432459590514],
						[77.61924653198322, 12.947499312630445],
						[77.61819859642077, 12.948284152531466],
						[77.61758327502339, 12.948725558048649],
						[77.61731123528277, 12.948917361789356],
						[77.61702144929625, 12.949140300105228],
						[77.61710169779793, 12.949242864093797],
						[77.61698578327713, 12.949247307195595],
						[77.61665132638154, 12.94925618655206],
						[77.6161920365952, 12.94927408095268],
						[77.61514861834904, 12.949300776577758],
						[77.61445302995406, 12.949309749589865],
						[77.61365034865193, 12.949296341377787],
						[77.6131286289643, 12.94930528671956],
						[77.61249098771381, 12.949296342012207],
						[77.61175525677437, 12.949300795290503],
						[77.61170175524015, 12.949238396531294],
						[77.61157237233999, 12.949068901529865],
						[77.61146982754398, 12.948979751948764],
						[77.61128257158875, 12.94894852630729],
						[77.61109977380845, 12.948926221970492],
						[77.61074746543746, 12.94897527445645],
						[77.60996267928287, 12.949131346304945],
						[77.60957915753774, 12.949175968046543],
						[77.60931610300356, 12.94922947264526],
						[77.6084555108658, 12.94936319332232],
						[77.60731393102635, 12.949523774195171],
						[77.60588261241632, 12.949706566283792],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000009a",
			properties: {
				AREA_SQ_KM: 1.594532050607082,
				ASS_CONST1: "B.T.M. Layout",
				ASS_CONST_: "172",
				LAT: 12.9432388832612,
				LON: 77.6130791124901,
				LOST_AREA_SQ_KM: 0.045480010580564015,
				OBJECTID: 155,
				POP_F: 14269.0,
				POP_M: 15676.0,
				POP_SC: 5381.0,
				POP_ST: 411.0,
				POP_TOTAL: 29945.0,
				RESERVATIO: "General",
				WARD_NAME: "Adugodi",
				WARD_NO: 147.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.62704551296063, 12.949323113009795],
						[77.62699201869292, 12.949372194651339],
						[77.62693843699077, 12.949412305442202],
						[77.62686711122691, 12.949474672499699],
						[77.62672445956353, 12.949586215223833],
						[77.6266620494039, 12.949635214518283],
						[77.62658617818282, 12.949697622387088],
						[77.62648364706193, 12.949777893300876],
						[77.62636774220405, 12.949871576940238],
						[77.62629187078444, 12.949933984722415],
						[77.62617596572893, 12.950023184935162],
						[77.6260288552942, 12.950130202752204],
						[77.62583707824128, 12.95023275234426],
						[77.62581033082324, 12.95024612779426],
						[77.6258014150158, 12.950250615016106],
						[77.62576575177901, 12.950263994337291],
						[77.62564975877139, 12.950308529065413],
						[77.62550264764845, 12.95034424406982],
						[77.62547590015087, 12.950353136125866],
						[77.6254625263997, 12.950357582152373],
						[77.6254357788926, 12.950362077077537],
						[77.6249630637306, 12.950464613597353],
						[77.62475799843644, 12.95050919028949],
						[77.62441910667702, 12.950580547391002],
						[77.62404455011433, 12.950660799502192],
						[77.6236297829908, 12.950749987209537],
						[77.6236119510296, 12.950754478093891],
						[77.62346929523831, 12.950785663118983],
						[77.62346483724193, 12.950790105226542],
						[77.62339350927562, 12.950816862916726],
						[77.62336676127661, 12.950825840873472],
						[77.62331326525948, 12.950857074336811],
						[77.6232998038394, 12.950865913126112],
						[77.62327305581812, 12.950879288183758],
						[77.62314377362556, 12.95094172131289],
						[77.62285837326279, 12.95107995810153],
						[77.62272017454215, 12.951151361335292],
						[77.62244368917922, 12.951285110408415],
						[77.62234115428295, 12.951334168029998],
						[77.62221178360704, 12.9513876296286],
						[77.6218506812916, 12.951543704482914],
						[77.62165889711677, 12.951624005260562],
						[77.62150286462767, 12.9516864482277],
						[77.62145373838746, 12.951606152087702],
						[77.62135566066934, 12.951392113331718],
						[77.62120408584596, 12.951013076635682],
						[77.62111046600012, 12.950750022814995],
						[77.6209008481731, 12.95033087432082],
						[77.62074481463306, 12.950076728401543],
						[77.62067339752883, 12.94994294540952],
						[77.62020074977389, 12.949060023799644],
						[77.62001350833523, 12.94870773777556],
						[77.61950073443498, 12.947740138237915],
						[77.61944269100962, 12.947628639439323],
						[77.6193669023354, 12.947490425863224],
						[77.6193669023354, 12.947485942712689],
						[77.61933569521942, 12.947432459590514],
						[77.61884966731053, 12.946522797379705],
						[77.6186266707098, 12.946081300596145],
						[77.6185999215779, 12.946010013396053],
						[77.61859546338862, 12.94595203691938],
						[77.61860437976699, 12.945894011812513],
						[77.6186266707098, 12.945858353455803],
						[77.61867133999762, 12.945773677928768],
						[77.61874267095668, 12.945697867034777],
						[77.61911278699652, 12.945452570233984],
						[77.61929111361016, 12.945332228492454],
						[77.6197548488981, 12.945015636294121],
						[77.62018291726935, 12.9446989720635],
						[77.62048169876186, 12.944480515366392],
						[77.62068677184924, 12.944328865867137],
						[77.62086518338302, 12.944186197986166],
						[77.62096771962405, 12.944079207321753],
						[77.62111938217929, 12.94395431056634],
						[77.62177926488913, 12.943392527817078],
						[77.62252839184914, 12.942705787376195],
						[77.62346483724193, 12.941863008935298],
						[77.62391072341816, 12.941426065574149],
						[77.62407129795223, 12.941256592074936],
						[77.6247178769221, 12.940627870525374],
						[77.62510134710669, 12.940244405306762],
						[77.62523954290249, 12.94009274006908],
						[77.62533315931285, 12.939967906960224],
						[77.62541794721766, 12.939829633380507],
						[77.62562746921967, 12.939388182332705],
						[77.62578358339883, 12.939035988691685],
						[77.625948525748, 12.93868370107165],
						[77.62612247104765, 12.938389346786508],
						[77.62630970232084, 12.938153044278588],
						[77.62642569464793, 12.937930056135167],
						[77.62659509392805, 12.937689279440395],
						[77.6269250634135, 12.93729247819918],
						[77.62785255656225, 12.936262366518351],
						[77.62873100640678, 12.935330426096625],
						[77.62964065365499, 12.934514461229188],
						[77.63053255008593, 12.93361816187016],
						[77.6312548726251, 12.932819956543002],
						[77.63217341475507, 12.931990532660857],
						[77.63274871251168, 12.931344000131743],
						[77.63297159371348, 12.930902594861267],
						[77.63310986724082, 12.930091030356662],
						[77.6332525982066, 12.929792258033808],
						[77.63348885120722, 12.92984131248237],
						[77.63364049687819, 12.930166833485593],
						[77.63388129405055, 12.930608254979411],
						[77.63391695471317, 12.930670649068965],
						[77.63401510888048, 12.930777668902161],
						[77.63403293919197, 12.930817831423935],
						[77.6340373967694, 12.930866923395808],
						[77.6340373967694, 12.9309159746544],
						[77.63402402403659, 12.930982834601894],
						[77.63399719116265, 12.931045257879783],
						[77.63397044568586, 12.93108984067075],
						[77.63387237888314, 12.931192391855753],
						[77.6336137513165, 12.93142424691709],
						[77.63343090240397, 12.931620501737568],
						[77.63339969918985, 12.931660561059672],
						[77.63339524158712, 12.931691813256249],
						[77.63339524158712, 12.931718537273985],
						[77.63342644480248, 12.931896925406601],
						[77.63351122660438, 12.93232503132635],
						[77.63357809055768, 12.932654954048076],
						[77.63369398798247, 12.9331633449772],
						[77.63381443027767, 12.933537902317514],
						[77.63391695471317, 12.933760825936302],
						[77.63407305738247, 12.934064072550767],
						[77.63413546342811, 12.934197835546943],
						[77.63425581784684, 12.93437618148816],
						[77.6344163776078, 12.934572399352705],
						[77.63447432590286, 12.934608102698263],
						[77.63457247958333, 12.934666074302728],
						[77.63500940663619, 12.93483102665672],
						[77.63514322012269, 12.93487113427607],
						[77.63539292912233, 12.934911267220475],
						[77.63574070282475, 12.934982642375882],
						[77.63578090780761, 12.935004910592413],
						[77.63578536531571, 12.935036159214082],
						[77.63578536531571, 12.935067367087466],
						[77.63574961784393, 12.935120831646179],
						[77.6356738401583, 12.935178847495672],
						[77.63560697745208, 12.935241255332203],
						[77.63525465840426, 12.935557864408377],
						[77.63458585225088, 12.936249004511055],
						[77.6344163776078, 12.93637831696865],
						[77.63419786943909, 12.936570027694772],
						[77.63362266650444, 12.937056152888388],
						[77.63338186877789, 12.937305850995903],
						[77.63327488624674, 12.937435135161383],
						[77.63319010426655, 12.937537721620188],
						[77.63307866393757, 12.937747313450572],
						[77.63284678029493, 12.938197620165997],
						[77.63245887888387, 12.939000309167671],
						[77.63217787240626, 12.93951309902013],
						[77.63172756133864, 12.940498507605387],
						[77.6314198943545, 12.94116296637649],
						[77.6312370418729, 12.941622265231793],
						[77.63098732363912, 12.942242084107876],
						[77.63062170437742, 12.94303128501118],
						[77.63001082125095, 12.944400265756725],
						[77.62956932960508, 12.945358962587756],
						[77.6295024632672, 12.945506120689286],
						[77.6293017766078, 12.9458539609998],
						[77.62901193409292, 12.946357807807326],
						[77.62882916510246, 12.946634258042009],
						[77.62871763304268, 12.94679031348331],
						[77.62861510386504, 12.94695533558592],
						[77.6283341751875, 12.947445816537263],
						[77.62802204110133, 12.947958599554939],
						[77.62781243615176, 12.948328690779759],
						[77.6276653278575, 12.94859624616696],
						[77.6275627103119, 12.94875229841669],
						[77.62745572224304, 12.94890391670061],
						[77.62736210759931, 12.949024361382724],
						[77.62726394762306, 12.949135794125066],
						[77.62723720054434, 12.949162533517857],
						[77.6271391278679, 12.94924732914855],
						[77.62706780223134, 12.949305299150499],
						[77.62704551296063, 12.949323113009795],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000009b",
			properties: {
				AREA_SQ_KM: 1.6117957765832276,
				ASS_CONST1: "B.T.M. Layout",
				ASS_CONST_: "172",
				LAT: 12.9423159169765,
				LON: 77.6270336149298,
				LOST_AREA_SQ_KM: 0.02765421403033687,
				OBJECTID: 156,
				POP_F: 13789.0,
				POP_M: 15316.0,
				POP_SC: 4906.0,
				POP_ST: 499.0,
				POP_TOTAL: 29105.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Ejipura",
				WARD_NO: 148.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.63388129405055, 12.930608254979411],
						[77.63364049687819, 12.930166833485593],
						[77.63348885120722, 12.92984131248237],
						[77.6332525982066, 12.929792258033808],
						[77.63310986724082, 12.930091030356662],
						[77.63297159371348, 12.930902594861267],
						[77.63274871251168, 12.931344000131743],
						[77.63217341475507, 12.931990532660857],
						[77.6312548726251, 12.932819956543002],
						[77.63053255008593, 12.93361816187016],
						[77.62964065365499, 12.934514461229188],
						[77.62873100640678, 12.935330426096625],
						[77.62785255656225, 12.936262366518351],
						[77.6269250634135, 12.93729247819918],
						[77.62659509392805, 12.937689279440395],
						[77.62642569464793, 12.937930056135167],
						[77.62630970232084, 12.938153044278588],
						[77.62612247104765, 12.938389346786508],
						[77.625948525748, 12.93868370107165],
						[77.62578358339883, 12.939035988691685],
						[77.62562746921967, 12.939388182332705],
						[77.62541794721766, 12.939829633380507],
						[77.62533315931285, 12.939967906960224],
						[77.62523954290249, 12.94009274006908],
						[77.62510134710669, 12.940244405306762],
						[77.6247178769221, 12.940627870525374],
						[77.62407129795223, 12.941256592074936],
						[77.62391072341816, 12.941426065574149],
						[77.62346483724193, 12.941863008935298],
						[77.62252839184914, 12.942705787376195],
						[77.62177926488913, 12.943392527817078],
						[77.62111938217929, 12.94395431056634],
						[77.62096771962405, 12.944079207321753],
						[77.62086518338302, 12.944186197986166],
						[77.62068677184924, 12.944328865867137],
						[77.62048169876186, 12.944480515366392],
						[77.62018291726935, 12.9446989720635],
						[77.6197548488981, 12.945015636294121],
						[77.61929111361016, 12.945332228492454],
						[77.61912616150235, 12.945028958429967],
						[77.61898341260982, 12.944596486700645],
						[77.61875158732339, 12.94357531949023],
						[77.61873821277305, 12.943463810760091],
						[77.61825664048754, 12.943445991700731],
						[77.61796676951826, 12.943472791691134],
						[77.61776606228199, 12.943530762357435],
						[77.61754760918224, 12.943561932785341],
						[77.61731123528277, 12.94112284120761],
						[77.6184929249867, 12.941038080048722],
						[77.61846171762843, 12.940993480063652],
						[77.6181941382156, 12.940329090761946],
						[77.61702590754783, 12.936356037899435],
						[77.61666470117976, 12.936311407455737],
						[77.61627674396924, 12.936249029832146],
						[77.61596912214854, 12.936173173163702],
						[77.61567478698558, 12.93605726403255],
						[77.61533586787807, 12.93586999639719],
						[77.61458678047545, 12.935499892797473],
						[77.61331142345288, 12.934839929370611],
						[77.61230373345569, 12.9343627854886],
						[77.61245532026007, 12.934086355116722],
						[77.61313308736999, 12.932864582089561],
						[77.61379747526514, 12.931669499820378],
						[77.61388218444013, 12.931517877812633],
						[77.61464028063953, 12.93021583238748],
						[77.61475173923309, 12.930024116061876],
						[77.61501486849481, 12.929703035841765],
						[77.61494344775599, 12.929671859133595],
						[77.61505945179721, 12.929502428088147],
						[77.6154607881424, 12.928891525038596],
						[77.61592891008374, 12.92814236756193],
						[77.6160092467815, 12.928004157610257],
						[77.6160449131099, 12.92793276833035],
						[77.61606274626988, 12.927883712828308],
						[77.61734253037635, 12.925774585244197],
						[77.61822989126772, 12.92441901755832],
						[77.61839038649136, 12.924173796027947],
						[77.61846171762843, 12.924004298469196],
						[77.62022304040062, 12.921159455620844],
						[77.6218640554721, 12.922069056627928],
						[77.6228717473235, 12.922635387052233],
						[77.6240088863206, 12.92307240815283],
						[77.624856073189, 12.923331008605178],
						[77.62600202051202, 12.923531661583608],
						[77.62722382700258, 12.923785847667373],
						[77.62824501908214, 12.92401328376783],
						[77.62902084964581, 12.924205022292483],
						[77.62912346586077, 12.924196050558937],
						[77.62928394554582, 12.924187173192003],
						[77.62945342792749, 12.924187173741494],
						[77.62974772705182, 12.924213915999724],
						[77.63014009555046, 12.92427189626729],
						[77.63041210427399, 12.924307571403698],
						[77.63132173792071, 12.924472514735562],
						[77.63210209231208, 12.924601881370428],
						[77.6327085064439, 12.92469991609821],
						[77.63344427520738, 12.924829217318974],
						[77.63498711894006, 12.92507001745244],
						[77.63551773971288, 12.925163649001147],
						[77.6359770379961, 12.925239475491457],
						[77.63637392963392, 12.925293009696805],
						[77.63654785880212, 12.925319768758541],
						[77.63669495547137, 12.925319729490093],
						[77.63683322441682, 12.925319785503188],
						[77.6369179162469, 12.92531529853937],
						[77.63706064237542, 12.92527513832137],
						[77.63725685773066, 12.925199346140657],
						[77.63740841091521, 12.92511909217729],
						[77.63767148718026, 12.924989773027438],
						[77.63813523407266, 12.92468211980672],
						[77.63850983108796, 12.92443235837065],
						[77.63875507521405, 12.924312012947954],
						[77.6391920733987, 12.924147037641504],
						[77.63927230608066, 12.924771271534306],
						[77.6393125097995, 12.9253286854267],
						[77.63931696716746, 12.925408981339622],
						[77.63929022295713, 12.925471407927658],
						[77.63926339134105, 12.925529347777749],
						[77.63925893397098, 12.925560512893734],
						[77.63925893397098, 12.92562740684601],
						[77.63926784871093, 12.925743345618818],
						[77.63928576558814, 12.925828077849447],
						[77.63931696716745, 12.925899479859387],
						[77.63941057185339, 12.92595739851349],
						[77.6395265506604, 12.926001990131601],
						[77.63961124045964, 12.926015346456625],
						[77.63967364342875, 12.926015396987665],
						[77.63970038754775, 12.926028743310315],
						[77.63974504846432, 12.926082254683259],
						[77.63981190870736, 12.926091224757986],
						[77.63986985421913, 12.926095631103827],
						[77.63997691786886, 12.926095660218945],
						[77.64009280869222, 12.926086714764557],
						[77.64023553078619, 12.926073361652842],
						[77.6408999333943, 12.926059931876175],
						[77.64112288582213, 12.926064462323321],
						[77.64118528795116, 12.926082270541022],
						[77.64127897847752, 12.926113469658821],
						[77.6413413805198, 12.926166966520258],
						[77.6413547523815, 12.92621156962402],
						[77.6413681242416, 12.926260655386105],
						[77.64144389808541, 12.926537113124967],
						[77.64151084471456, 12.927081075466676],
						[77.64156878934959, 12.927366511044772],
						[77.64167130670754, 12.927749937648645],
						[77.64180956952627, 12.928169124897828],
						[77.64186305674043, 12.928423316266558],
						[77.64205480644584, 12.928860320411198],
						[77.64212175271071, 12.929052053154821],
						[77.64198794753834, 12.929074280813355],
						[77.64178728317953, 12.929118917300555],
						[77.64155987479229, 12.929127793029506],
						[77.64130572221417, 12.929101080394059],
						[77.64102919516549, 12.929087694927137],
						[77.64076612676583, 12.929096590106202],
						[77.64051642971134, 12.92914120996719],
						[77.64028901875018, 12.929208101274215],
						[77.63999474723408, 12.929368607106206],
						[77.63966918607497, 12.929551410205423],
						[77.63925447660073, 12.929814520927476],
						[77.63904043522128, 12.929948342617584],
						[77.63880410649507, 12.930082047149385],
						[77.63861689603424, 12.9302024956548],
						[77.63848308668544, 12.930282737847662],
						[77.63833144755077, 12.930273851989428],
						[77.6381486937164, 12.930215892735713],
						[77.6379792244479, 12.93011330997897],
						[77.63784541433941, 12.929943811460705],
						[77.63754222154431, 12.929546994918786],
						[77.63736829391567, 12.929301701510868],
						[77.6370829296602, 12.928797896951918],
						[77.63694020356, 12.928579354967223],
						[77.63682876695033, 12.92852583993933],
						[77.63664146579575, 12.928494646456425],
						[77.63650328401583, 12.928516920253275],
						[77.63634272723708, 12.928570451305497],
						[77.63605290287913, 12.928690818750045],
						[77.63581656786735, 12.928806760084026],
						[77.63558468987449, 12.928944982689137],
						[77.6354285893052, 12.929096654975005],
						[77.63537509902666, 12.929194699266914],
						[77.63521899816618, 12.929417663276299],
						[77.63505406941785, 12.929573740987788],
						[77.63477306942947, 12.929832370956738],
						[77.63441192004541, 12.930148978018444],
						[77.63397490326577, 12.930536869890117],
						[77.63388129405055, 12.930608254979411],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000009c",
			properties: {
				AREA_SQ_KM: 3.7187872786361402,
				ASS_CONST1: "B.T.M. Layout",
				ASS_CONST_: "172",
				LAT: 12.9313404051701,
				LON: 77.6246494460865,
				LOST_AREA_SQ_KM: 0.17715709771456253,
				OBJECTID: 157,
				POP_F: 16925.0,
				POP_M: 18434.0,
				POP_SC: 3246.0,
				POP_ST: 232.0,
				POP_TOTAL: 35359.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Koramangala",
				WARD_NO: 151.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.60051832814555, 12.928347467070292],
						[77.60053616376211, 12.92745568566937],
						[77.60053170485826, 12.927165857840928],
						[77.60050941033622, 12.926880433612524],
						[77.60049157471543, 12.926626253747536],
						[77.60082599213611, 12.926630734101227],
						[77.60095975882682, 12.926635163647465],
						[77.60102664211271, 12.926661986866495],
						[77.60110690200342, 12.926733324210876],
						[77.60118716183705, 12.926822503859746],
						[77.6012629627386, 12.926889404053837],
						[77.60141010551973, 12.926969636343866],
						[77.60171330822114, 12.92715242195107],
						[77.60214144513456, 12.927415496357524],
						[77.60297078638914, 12.927843644222126],
						[77.6032829024863, 12.927986259858102],
						[77.60339000094807, 12.928013028183331],
						[77.60354159973966, 12.928044256619227],
						[77.60384925548445, 12.928075465406277],
						[77.6041836629508, 12.92808442441747],
						[77.6045493680723, 12.92807548875494],
						[77.60466975412704, 12.928066604820232],
						[77.60475892889954, 12.928053208187062],
						[77.60493727823301, 12.928035379215189],
						[77.6049239020428, 12.92796845321297],
						[77.60485702106787, 12.92776777256383],
						[77.60477230510935, 12.92742444535557],
						[77.60441997696239, 12.92753590655272],
						[77.60437538945094, 12.927424473494545],
						[77.60454045058165, 12.927379890457715],
						[77.60453599183607, 12.927281758434523],
						[77.60476338763632, 12.92721930303082],
						[77.60475447016259, 12.927130180350156],
						[77.60462962545641, 12.92670658073267],
						[77.60459841425826, 12.926644145049286],
						[77.60452252817184, 12.926447948649146],
						[77.60449131694406, 12.926323021758273],
						[77.60459395551497, 12.92625174370226],
						[77.60486593853348, 12.926100144109746],
						[77.60503982897204, 12.926006421502757],
						[77.60500861788742, 12.925912783713896],
						[77.60487931473058, 12.925181519888339],
						[77.60497294806586, 12.9251815240611],
						[77.6051468383394, 12.925190458972512],
						[77.60528951733791, 12.925203845792826],
						[77.6054321961559, 12.925248434914034],
						[77.60561063185004, 12.92529301326207],
						[77.6057800624438, 12.92532423091884],
						[77.60583802548321, 12.92536433020544],
						[77.6059271993318, 12.925422301807632],
						[77.60598962098386, 12.925498126976628],
						[77.60604758391554, 12.925573910979407],
						[77.60611892286748, 12.925627408046998],
						[77.60619026177427, 12.925654184707588],
						[77.60627497666748, 12.92565419220468],
						[77.60637752618986, 12.925645314080628],
						[77.60650682762862, 12.92562297820899],
						[77.60667634430035, 12.925573952133572],
						[77.60673876553766, 12.925556083384507],
						[77.6068457732926, 12.925524835513851],
						[77.6069483222958, 12.925489236621488],
						[77.60698845014124, 12.925457973913424],
						[77.60700628473461, 12.925413403539284],
						[77.60699736743828, 12.925359880263592],
						[77.60699290878985, 12.925297433950828],
						[77.60698399149246, 12.92519046994646],
						[77.60697953284348, 12.925128023728599],
						[77.60697953284348, 12.9250521723731],
						[77.60712666816615, 12.925025432454342],
						[77.60738981523407, 12.924967530773454],
						[77.60763503977267, 12.924922906168987],
						[77.6078223017884, 12.924891709453659],
						[77.60796497740111, 12.924864927688061],
						[77.60813886305993, 12.924842657579322],
						[77.60821028811205, 12.92484263097983],
						[77.60835742182832, 12.924824768162527],
						[77.60856697557749, 12.924869369525913],
						[77.60863831293513, 12.92488278502537],
						[77.60881665613172, 12.924918478581601],
						[77.60899508646904, 12.92494072978693],
						[77.60892812048628, 12.925016563317996],
						[77.60899508646904, 12.925043302049305],
						[77.60909763351695, 12.92505217865578],
						[77.60934285434017, 12.925020998405577],
						[77.60964157716758, 12.924976434147325],
						[77.60983338180823, 12.924958554959584],
						[77.61015885447837, 12.924918460316263],
						[77.61024356638906, 12.924913983927567],
						[77.61039524338685, 12.924842637594997],
						[77.6106270858731, 12.924744530828352],
						[77.61098822418037, 12.924570643585644],
						[77.61130040550263, 12.924419063246567],
						[77.61153224612659, 12.924338797748065],
						[77.61181321674111, 12.92423623256795],
						[77.61196034575353, 12.924196042356309],
						[77.61212976680255, 12.924164849373453],
						[77.61231265033217, 12.924155935885482],
						[77.61247761241998, 12.924115823379521],
						[77.61286558271631, 12.924026654479807],
						[77.61321333864224, 12.923915131187671],
						[77.61338284522652, 12.923857134432044],
						[77.61348984667292, 12.923781405720352],
						[77.61357455607923, 12.923701075748665],
						[77.61364589026672, 12.923620881207427],
						[77.61370384926079, 12.923571766252063],
						[77.61381976715947, 12.923518271687715],
						[77.61398927283125, 12.923464756507387],
						[77.61419435774347, 12.923442509382078],
						[77.61434594200382, 12.923478124019018],
						[77.61448869677528, 12.92351383311638],
						[77.6145466553357, 12.92350936712092],
						[77.61458232212729, 12.923495990233882],
						[77.61460907221357, 12.92350933810685],
						[77.61466703071203, 12.923768027449924],
						[77.61580407775631, 12.923268559989685],
						[77.61582191095442, 12.923308718789196],
						[77.61603599652886, 12.923794767283221],
						[77.61615191202736, 12.924298616610834],
						[77.61627228568797, 12.92464640684347],
						[77.6165532444794, 12.924775790005288],
						[77.61657107755887, 12.924784659914616],
						[77.61713290553315, 12.923834855272172],
						[77.61763677376393, 12.924102389337737],
						[77.61822989126772, 12.92441901755832],
						[77.61734253037635, 12.925774585244197],
						[77.61606274626988, 12.927883712828308],
						[77.6160449131099, 12.92793276833035],
						[77.6160092467815, 12.928004157610257],
						[77.61592891008374, 12.92814236756193],
						[77.6154607881424, 12.928891525038596],
						[77.61505945179721, 12.929502428088147],
						[77.61494344775599, 12.929671859133595],
						[77.61501486849481, 12.929703035841765],
						[77.61475173923309, 12.930024116061876],
						[77.61464028063953, 12.93021583238748],
						[77.61388218444013, 12.931517877812633],
						[77.61379747526514, 12.931669499820378],
						[77.6134051372029, 12.931883536215839],
						[77.61248652928272, 12.932614826521602],
						[77.61153224612659, 12.933386298610596],
						[77.61085892788239, 12.933872282068656],
						[77.61005184986861, 12.934527792462575],
						[77.60896387647941, 12.935245695366078],
						[77.60804523235389, 12.935865508323323],
						[77.60725596874572, 12.936204464275121],
						[77.60640873689556, 12.93647193386677],
						[77.60588261241632, 12.936516531913384],
						[77.60463854294001, 12.93669496912671],
						[77.60288606901373, 12.936953578879812],
						[77.60159291901077, 12.93712748871812],
						[77.60152603606062, 12.93675731853455],
						[77.60142348212668, 12.93628915154645],
						[77.60139672891121, 12.93614201547458],
						[77.60136997568937, 12.935771877128765],
						[77.60130755148042, 12.934955837149424],
						[77.60129417485972, 12.934897872277194],
						[77.60124958611262, 12.93455455292565],
						[77.6009240877248, 12.932984936911806],
						[77.60078140320395, 12.932195669475417],
						[77.60074127314995, 12.931928168377592],
						[77.60071897866933, 12.931723068535138],
						[77.60070560197885, 12.931335054112122],
						[77.60061642400177, 12.930305030411885],
						[77.6005272459542, 12.928976260954677],
						[77.60051386924097, 12.928610580616532],
						[77.60051832814555, 12.928347467070292],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000009d",
			properties: {
				AREA_SQ_KM: 1.738812295751189,
				ASS_CONST1: "B.T.M. Layout",
				ASS_CONST_: "172",
				LAT: 12.92997135113,
				LON: 77.6082147179137,
				LOST_AREA_SQ_KM: 0.06138893254044475,
				OBJECTID: 158,
				POP_F: 16569.0,
				POP_M: 19341.0,
				POP_SC: 3193.0,
				POP_ST: 299.0,
				POP_TOTAL: 35910.0,
				RESERVATIO: "General",
				WARD_NAME: "Suddagunte Palya",
				WARD_NO: 152.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.6391920733987, 12.924147037641504],
						[77.63875507521405, 12.924312012947954],
						[77.63850983108796, 12.92443235837065],
						[77.63813523407266, 12.92468211980672],
						[77.63767148718026, 12.924989773027438],
						[77.63740841091521, 12.92511909217729],
						[77.63725685773066, 12.925199346140657],
						[77.63706064237542, 12.92527513832137],
						[77.6369179162469, 12.92531529853937],
						[77.63683322441682, 12.925319785503188],
						[77.63669495547137, 12.925319729490093],
						[77.63654785880212, 12.925319768758541],
						[77.63637392963392, 12.925293009696805],
						[77.6359770379961, 12.925239475491457],
						[77.63551773971288, 12.925163649001147],
						[77.63498711894006, 12.92507001745244],
						[77.63344427520738, 12.924829217318974],
						[77.6327085064439, 12.92469991609821],
						[77.63210209231208, 12.924601881370428],
						[77.63132173792071, 12.924472514735562],
						[77.63041210427399, 12.924307571403698],
						[77.63014009555046, 12.92427189626729],
						[77.62974772705182, 12.924213915999724],
						[77.62945342792749, 12.924187173741494],
						[77.62928394554582, 12.924187173192003],
						[77.62912346586077, 12.924196050558937],
						[77.62902084964581, 12.924205022292483],
						[77.62824501908214, 12.92401328376783],
						[77.62722382700258, 12.923785847667373],
						[77.62600202051202, 12.923531661583608],
						[77.624856073189, 12.923331008605178],
						[77.6240088863206, 12.92307240815283],
						[77.6228717473235, 12.922635387052233],
						[77.6218640554721, 12.922069056627928],
						[77.62022304040062, 12.921159455620844],
						[77.62145373838744, 12.919175076856735],
						[77.62149840655339, 12.919166180324998],
						[77.62167672938747, 12.918938795460594],
						[77.62210479044919, 12.918323414400199],
						[77.62272463256804, 12.917337954154792],
						[77.62277812886502, 12.917369214409094],
						[77.62281379304886, 12.917306791978358],
						[77.62296991113239, 12.917355801797953],
						[77.62338905127622, 12.917431609447199],
						[77.62365653092729, 12.917476203669354],
						[77.62398205105659, 12.917538656536314],
						[77.62439235892182, 12.917609989643239],
						[77.62457959307467, 12.91763669707367],
						[77.62477137227135, 12.917654571325091],
						[77.62507014158093, 12.917663510725076],
						[77.62536445217587, 12.917663444825163],
						[77.62557843219035, 12.91765453912372],
						[77.62577466758927, 12.91762332261264],
						[77.62608235002, 12.917538648968996],
						[77.62687156908848, 12.917288916441205],
						[77.62766978568742, 12.917052626035328],
						[77.62816469111884, 12.916905442200093],
						[77.6283341751875, 12.916860877426462],
						[77.62844562021981, 12.91682517501739],
						[77.62853040577824, 12.916802851509187],
						[77.62864185061606, 12.916793955397988],
						[77.6289584407606, 12.91677167660621],
						[77.62995724098724, 12.916718127079122],
						[77.63115234576128, 12.916655765385908],
						[77.6313976059465, 12.916628954495408],
						[77.63192824366772, 12.91657104247339],
						[77.63286906841556, 12.916477387589905],
						[77.63360037853326, 12.916406043704733],
						[77.63480435966513, 12.916294523896246],
						[77.63599486799625, 12.9161830435199],
						[77.6367574474621, 12.916093874430377],
						[77.63747090250905, 12.915986841537052],
						[77.63799705413027, 12.915946715197212],
						[77.6381263192428, 12.915946767586101],
						[77.63820218267432, 12.915942287539048],
						[77.63821555490982, 12.916459500814788],
						[77.63828687347224, 12.91770810855466],
						[77.63834481977086, 12.919313384496968],
						[77.63840285343969, 12.919995576295074],
						[77.6384697144818, 12.920338912313891],
						[77.63865701258783, 12.920976599161055],
						[77.63868821434183, 12.921101441750443],
						[77.63883539560794, 12.92153395638498],
						[77.63905380735697, 12.921957634805924],
						[77.63939719976017, 12.92261752868613],
						[77.63978070726556, 12.923460281475634],
						[77.6399724605271, 12.923834852695508],
						[77.63949971910078, 12.924026655288626],
						[77.63923218974685, 12.924133610076527],
						[77.6391920733987, 12.924147037641504],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000009f",
			properties: {
				AREA_SQ_KM: 1.5262838929794615,
				ASS_CONST1: "B.T.M. Layout",
				ASS_CONST_: "172",
				LAT: 12.9205909717835,
				LON: 77.6309886977238,
				LOST_AREA_SQ_KM: 0.05793700609424067,
				OBJECTID: 160,
				POP_F: 11043.0,
				POP_M: 13045.0,
				POP_SC: 2829.0,
				POP_ST: 536.0,
				POP_TOTAL: 24088.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Jakkasandra",
				WARD_NO: 173.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59992520485946, 12.916392629594723],
						[77.59992966378731, 12.915367056344078],
						[77.59996087627728, 12.914287959811679],
						[77.59995641735067, 12.912285773788438],
						[77.59998762983325, 12.911554539044337],
						[77.60026862921762, 12.908754239846553],
						[77.600277547046, 12.908678396690442],
						[77.60029092378723, 12.908513397570204],
						[77.60165088420217, 12.90858031076884],
						[77.60312684454568, 12.908633809137749],
						[77.60386709057435, 12.908696213581301],
						[77.60403652378777, 12.908705184506372],
						[77.60399193612471, 12.907710756754906],
						[77.60395180721288, 12.90693932761448],
						[77.6039027607457, 12.906435497050298],
						[77.60387600811823, 12.906047527120654],
						[77.60386709057435, 12.905985137091179],
						[77.60385817302975, 12.905940500134047],
						[77.60386263180214, 12.90588702349136],
						[77.60386709057435, 12.90584690472842],
						[77.60388938443278, 12.90581565410831],
						[77.60398301858999, 12.905793332635836],
						[77.60446456445622, 12.905762167057695],
						[77.60544111357602, 12.905690824471188],
						[77.605913823259, 12.905650701161369],
						[77.6061768857327, 12.905628383449391],
						[77.6065202910559, 12.905637314299337],
						[77.60704641255941, 12.905668515729905],
						[77.60744777744588, 12.90569969477061],
						[77.60774650529571, 12.905730962106047],
						[77.60800510488465, 12.905788921662035],
						[77.60820137091096, 12.905833471559244],
						[77.60832175306625, 12.905842418508232],
						[77.6084376765017, 12.905851324494865],
						[77.60852684829398, 12.905851366921535],
						[77.60864723010167, 12.905829030013788],
						[77.6089683350499, 12.905699690858377],
						[77.60938743988706, 12.905481187257816],
						[77.60945431817434, 12.905441123049693],
						[77.60934285434017, 12.905021952290548],
						[77.61021681526624, 12.904821236345587],
						[77.61125136223261, 12.904718685163095],
						[77.61272282588791, 12.904486805236534],
						[77.61300379353335, 12.905253808567808],
						[77.61322225544673, 12.905900362854844],
						[77.61356118091405, 12.906341792220486],
						[77.61373951631924, 12.906538025089661],
						[77.61389555956718, 12.906752066579012],
						[77.6141051904362, 12.907322798257157],
						[77.61417652428766, 12.907554681811376],
						[77.61431919185505, 12.907728613869395],
						[77.6144083589929, 12.90782225014795],
						[77.61461353056066, 12.90815671605982],
						[77.61470715580889, 12.908294950885317],
						[77.61490778107876, 12.90862489187507],
						[77.61493898942196, 12.908700675357442],
						[77.6151753683008, 12.909338370879984],
						[77.61526453476087, 12.90958360892522],
						[77.6155321211353, 12.910609175065796],
						[77.61572382832989, 12.91145193994275],
						[77.6159244517887, 12.91233038889621],
						[77.6161296205946, 12.91349865602766],
						[77.61629903537286, 12.914591189198608],
						[77.61633024333052, 12.915014752374436],
						[77.61637482611219, 12.91518417933996],
						[77.61640157577273, 12.915273372585542],
						[77.61647299558685, 12.91539383487528],
						[77.61649974522406, 12.91543389994145],
						[77.61654432793861, 12.91537147609997],
						[77.61658891063553, 12.91529121092936],
						[77.61661566024519, 12.915179753922558],
						[77.61666470117976, 12.91498360432946],
						[77.61678953255317, 12.914836415864508],
						[77.61720869571124, 12.914452923932762],
						[77.61759664971092, 12.9141318915424],
						[77.6177705205041, 12.913984721050632],
						[77.61802026807659, 12.913766194350542],
						[77.61814063973989, 12.913623532790075],
						[77.61827447329723, 12.913471947989104],
						[77.61845725943368, 12.913262371694403],
						[77.6185597978681, 12.913159797176068],
						[77.61911724516531, 12.912762919602661],
						[77.61934461153916, 12.912629165880883],
						[77.61943377469765, 12.91258904174264],
						[77.61949173071282, 12.912566731788036],
						[77.61954531596123, 12.912557830671037],
						[77.61959435561971, 12.912553366404937],
						[77.61970135116407, 12.912544433186671],
						[77.61985738615061, 12.912544394852258],
						[77.62026762164078, 12.912553320491243],
						[77.62071360789909, 12.912589035617676],
						[77.62090976436885, 12.912580141059372],
						[77.62107471386292, 12.912584579016956],
						[77.62106133958862, 12.912647030166776],
						[77.62130662177823, 12.91273175830142],
						[77.62152515499652, 12.912829859873643],
						[77.62162769063627, 12.912874408703209],
						[77.62165889711677, 12.91290563590762],
						[77.62167227132007, 12.912950231953387],
						[77.62167227132007, 12.91299031013878],
						[77.62167672938747, 12.91312859917414],
						[77.62167227132007, 12.913191046031024],
						[77.62164998098038, 12.91324453859602],
						[77.6216009422175, 12.913306922854503],
						[77.62156973572093, 12.913342578840401],
						[77.62145373838746, 12.913431756138172],
						[77.62114167262406, 12.913766251209825],
						[77.62182384551288, 12.914171982835379],
						[77.62207804214383, 12.91429687272939],
						[77.6221538289925, 12.914328076925383],
						[77.62222970320276, 12.914328084225708],
						[77.62229657386412, 12.91432809202819],
						[77.62232778015915, 12.914336996301232],
						[77.62235452840515, 12.914368183260613],
						[77.62236790252577, 12.914408298049176],
						[77.62238127664479, 12.914689230535407],
						[77.62240356683964, 12.915246661078385],
						[77.6223857346841, 12.9153714758246],
						[77.6223768186053, 12.915848638178417],
						[77.62232332211754, 12.916174127396712],
						[77.62274246466987, 12.917306742953743],
						[77.62273800664467, 12.917311184221802],
						[77.62198442302503, 12.917039240777411],
						[77.62172576811732, 12.916945592880621],
						[77.6202096660251, 12.916432736993716],
						[77.61960327191895, 12.916294532509319],
						[77.61935798601743, 12.916263322561386],
						[77.61891208180332, 12.91624992646073],
						[77.61842605206554, 12.916245470527276],
						[77.61770810537847, 12.916227679864821],
						[77.61696349200943, 12.916218695897717],
						[77.6164640790397, 12.916241004558755],
						[77.6156614120698, 12.916334650513605],
						[77.6152110348933, 12.9163792983953],
						[77.61502824348739, 12.916410459423604],
						[77.61470715580889, 12.916437244623031],
						[77.61304391922268, 12.916477381193292],
						[77.61103735498628, 12.916490732153997],
						[77.60961928444661, 12.916539819663326],
						[77.60783567763477, 12.91657993248335],
						[77.6072782619341, 12.916593324996343],
						[77.60723813419185, 12.916593300057933],
						[77.60697953284348, 12.9165977263096],
						[77.60532072834476, 12.916637847917206],
						[77.60429959064099, 12.916660142772972],
						[77.603278443691, 12.916678037177517],
						[77.60285039852084, 12.916713731780167],
						[77.60098205325986, 12.916740425864393],
						[77.60019282764803, 12.916744938639548],
						[77.60010356183581, 12.916744885679867],
						[77.6000723493853, 12.916713656068517],
						[77.59999654768383, 12.91666020899057],
						[77.59993858164246, 12.916606668276124],
						[77.59992520485946, 12.916557590608491],
						[77.59992520485946, 12.916392629594723],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000a0",
			properties: {
				AREA_SQ_KM: 2.1328902425908685,
				ASS_CONST1: "B.T.M. Layout",
				ASS_CONST_: "172",
				LAT: 12.9118377209653,
				LON: 77.6093452875564,
				LOST_AREA_SQ_KM: 0.05034429646319222,
				OBJECTID: 161,
				POP_F: 15934.0,
				POP_M: 18502.0,
				POP_SC: 1512.0,
				POP_ST: 247.0,
				POP_TOTAL: 34436.0,
				RESERVATIO: "General",
				WARD_NAME: "BTM Layout",
				WARD_NO: 176.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61822989126772, 12.92441901755832],
						[77.61763677376393, 12.924102389337737],
						[77.61713290553315, 12.923834855272172],
						[77.61657107755887, 12.924784659914616],
						[77.6165532444794, 12.924775790005288],
						[77.61627228568797, 12.92464640684347],
						[77.61615191202736, 12.924298616610834],
						[77.61603599652886, 12.923794767283221],
						[77.61582191095442, 12.923308718789196],
						[77.61580407775631, 12.923268559989685],
						[77.61466703071203, 12.923768027449924],
						[77.61460907221357, 12.92350933810685],
						[77.61458232212729, 12.923495990233882],
						[77.6145466553357, 12.92350936712092],
						[77.61448869677528, 12.92351383311638],
						[77.61434594200382, 12.923478124019018],
						[77.61419435774347, 12.923442509382078],
						[77.61398927283125, 12.923464756507387],
						[77.61381976715947, 12.923518271687715],
						[77.61370384926079, 12.923571766252063],
						[77.61364589026672, 12.923620881207427],
						[77.61357455607923, 12.923701075748665],
						[77.61348984667292, 12.923781405720352],
						[77.61338284522652, 12.923857134432044],
						[77.61321333864224, 12.923915131187671],
						[77.61286558271631, 12.924026654479807],
						[77.61247761241998, 12.924115823379521],
						[77.61231265033217, 12.924155935885482],
						[77.61212976680255, 12.924164849373453],
						[77.61196034575353, 12.924196042356309],
						[77.61181321674111, 12.92423623256795],
						[77.61153224612659, 12.924338797748065],
						[77.61130040550263, 12.924419063246567],
						[77.61098822418037, 12.924570643585644],
						[77.6106270858731, 12.924744530828352],
						[77.61039524338685, 12.924842637594997],
						[77.61024356638906, 12.924913983927567],
						[77.61015885447837, 12.924918460316263],
						[77.60983338180823, 12.924958554959584],
						[77.60964157716758, 12.924976434147325],
						[77.60934285434017, 12.925020998405577],
						[77.60909763351695, 12.92505217865578],
						[77.60899508646904, 12.925043302049305],
						[77.60892812048628, 12.925016563317996],
						[77.60899508646904, 12.92494072978693],
						[77.60881665613172, 12.924918478581601],
						[77.60863831293513, 12.92488278502537],
						[77.60856697557749, 12.924869369525913],
						[77.60876315320237, 12.92383486248487],
						[77.6087765289371, 12.923741206598818],
						[77.60870519166693, 12.923683229138819],
						[77.60860264426196, 12.923665474897716],
						[77.60853130688174, 12.923643095608758],
						[77.60856697557749, 12.923549473298195],
						[77.60866060585015, 12.923339937935104],
						[77.60875869462379, 12.923210604136685],
						[77.60881665613172, 12.923116929124461],
						[77.60885678331206, 12.922934137745811],
						[77.60890136905124, 12.922791467716529],
						[77.60890582762418, 12.922599728387823],
						[77.60890136905124, 12.922421353266703],
						[77.60887907618385, 12.922341074134296],
						[77.60883449043585, 12.922278690004548],
						[77.60877207035904, 12.922158219685064],
						[77.60870965024763, 12.922055677648105],
						[77.60870073308604, 12.92186838448558],
						[77.60867844017896, 12.921627615032655],
						[77.60866506443261, 12.921516130871673],
						[77.60862493718402, 12.921311053753419],
						[77.60850901394116, 12.921128205228642],
						[77.6084153835426, 12.92088738301155],
						[77.60824595690939, 12.920499508917553],
						[77.60808535980887, 12.920133865588681],
						[77.60802739792489, 12.919964396238214],
						[77.6081031942287, 12.919933204311171],
						[77.60814332166304, 12.91989746014472],
						[77.60812994585318, 12.919830624858905],
						[77.60808535980887, 12.919719113484424],
						[77.6079114740675, 12.919179593507787],
						[77.60772867081943, 12.918524068539655],
						[77.60759491215742, 12.917944415687945],
						[77.6074344015535, 12.917355814490955],
						[77.6072648860216, 12.916731534454536],
						[77.6072782619341, 12.916593324996343],
						[77.60783567763477, 12.91657993248335],
						[77.60961928444661, 12.916539819663326],
						[77.61103735498628, 12.916490732153997],
						[77.61304391922268, 12.916477381193292],
						[77.61470715580889, 12.916437244623031],
						[77.61502824348739, 12.916410459423604],
						[77.6152110348933, 12.9163792983953],
						[77.6156614120698, 12.916334650513605],
						[77.6164640790397, 12.916241004558755],
						[77.61696349200943, 12.916218695897717],
						[77.61770810537847, 12.916227679864821],
						[77.61842605206554, 12.916245470527276],
						[77.61891208180332, 12.91624992646073],
						[77.61935798601743, 12.916263322561386],
						[77.61960327191895, 12.916294532509319],
						[77.6202096660251, 12.916432736993716],
						[77.62172576811732, 12.916945592880621],
						[77.62198442302503, 12.917039240777411],
						[77.62273800664467, 12.917311184221802],
						[77.62272463256804, 12.917337954154792],
						[77.62210479044919, 12.918323414400199],
						[77.62167672938747, 12.918938795460594],
						[77.62149840655339, 12.919166180324998],
						[77.62145373838744, 12.919175076856735],
						[77.62022304040062, 12.921159455620844],
						[77.61846171762843, 12.924004298469196],
						[77.61839038649136, 12.924173796027947],
						[77.61822989126772, 12.92441901755832],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000c1",
			properties: {
				AREA_SQ_KM: 1.1671972413673526,
				ASS_CONST1: "B.T.M. Layout",
				ASS_CONST_: "172",
				LAT: 12.9200182348871,
				LON: 77.6144183930996,
				LOST_AREA_SQ_KM: 0.02779022147306555,
				OBJECTID: 194,
				POP_F: 16034.0,
				POP_M: 19121.0,
				POP_SC: 2658.0,
				POP_ST: 555.0,
				POP_TOTAL: 35155.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Madivala",
				WARD_NO: 172.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57325985429236, 12.90648898085208],
						[77.57511484102889, 12.90647111284071],
						[77.57540910706221, 12.906466700711409],
						[77.57548492525945, 12.906466718155816],
						[77.57548492525945, 12.905882554679259],
						[77.57548046536691, 12.90518245145945],
						[77.57548046536691, 12.905053107687053],
						[77.57562309439253, 12.904910440427342],
						[77.57589068729709, 12.904651833499587],
						[77.57606016247601, 12.904477880793815],
						[77.57634550633435, 12.90431741232179],
						[77.57659971798574, 12.904170272027448],
						[77.57682262274433, 12.903996295841171],
						[77.57690735966762, 12.903924959283561],
						[77.57700993585824, 12.90385809730083],
						[77.5772551389243, 12.903902686127383],
						[77.57718378173878, 12.904076605177451],
						[77.57705007434231, 12.904299582181855],
						[77.57703223501781, 12.904477963682629],
						[77.5770366948492, 12.904687488720588],
						[77.57700101619318, 12.904892590338617],
						[77.57693857851808, 12.905124501138458],
						[77.57690735966762, 12.9053162020591],
						[77.57689398015764, 12.905548139565564],
						[77.57724175945543, 12.905548100350599],
						[77.5774692102108, 12.905552544870709],
						[77.57817377122097, 12.90554367549659],
						[77.57873561547476, 12.905552606299366],
						[77.57869993735453, 12.904990686862133],
						[77.5796363093896, 12.904772238653054],
						[77.57961847047294, 12.904553711693874],
						[77.580327477798, 12.904415529769153],
						[77.580327477798, 12.904344178969453],
						[77.58030963899036, 12.904250516117644],
						[77.58030963899036, 12.904205878841992],
						[77.58034085690188, 12.904170235118196],
						[77.58044788967598, 12.904121163705245],
						[77.5808536339129, 12.90400523715635],
						[77.5812817624603, 12.903893743445384],
						[77.58172764052958, 12.903809037451294],
						[77.58170534229568, 12.904816793340638],
						[77.58171426158977, 12.905597176679954],
						[77.58251690773365, 12.905521386128],
						[77.58303413447196, 12.9055123984579],
						[77.58306089203768, 12.90536977049207],
						[77.58424705031157, 12.905236005317434],
						[77.5845636777028, 12.905164661154684],
						[77.58496048813282, 12.905169114627315],
						[77.5849783262067, 12.90464292885585],
						[77.58575865174213, 12.904620603619824],
						[77.58647662692175, 12.904625104508709],
						[77.58648108638064, 12.905739815225639],
						[77.5866861338602, 12.905739816965188],
						[77.58808630527584, 12.905726505918269],
						[77.58828697789413, 12.90573095016015],
						[77.58829143728161, 12.905293909883909],
						[77.58828697789413, 12.905249315996617],
						[77.58857683771359, 12.90518693811553],
						[77.58854116269902, 12.904968412307008],
						[77.58993239248247, 12.904834616637478],
						[77.59009292798243, 12.904812317644959],
						[77.59015089907913, 12.90510223123793],
						[77.59028021911071, 12.905717516828629],
						[77.5917472347688, 12.905628362735714],
						[77.59175169401969, 12.905405379700122],
						[77.59341498231558, 12.905316221557717],
						[77.59345511497446, 12.906301681247403],
						[77.5935621353284, 12.906297241142138],
						[77.5935621353284, 12.906332918572243],
						[77.59358443122271, 12.90635966726182],
						[77.59361564546734, 12.90740305181503],
						[77.59317418606278, 12.907465499650613],
						[77.59332133938939, 12.910230195500652],
						[77.59022670739145, 12.910404058646915],
						[77.58791238871835, 12.910475412831781],
						[77.5857051378815, 12.910569026214343],
						[77.58313224551527, 12.910515560881015],
						[77.5805995192659, 12.910716235822184],
						[77.57767436148028, 12.910916842371396],
						[77.57418280406739, 12.911318166284488],
						[77.5728941353298, 12.911465298614193],
						[77.57291197530661, 12.910230142707968],
						[77.57293427527367, 12.909168854317684],
						[77.57294319525926, 12.909044071511799],
						[77.57298779517667, 12.908468847419527],
						[77.5731661946706, 12.90714448176573],
						[77.57325985429236, 12.90648898085208],
					],
				],
				type: "Polygon",
			},
			id: "0000000000000000009e",
			properties: {
				AREA_SQ_KM: 1.3327022427793325,
				ASS_CONST1: "Jaya Nagar",
				ASS_CONST_: "173",
				LAT: 12.9079402946364,
				LON: 77.5829497522443,
				LOST_AREA_SQ_KM: 0.05466208558029474,
				OBJECTID: 159,
				POP_F: 12837.0,
				POP_M: 13870.0,
				POP_SC: 1379.0,
				POP_ST: 173.0,
				POP_TOTAL: 26707.0,
				RESERVATIO: "General",
				WARD_NAME: "Sarakki",
				WARD_NO: 178.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57974334283045, 12.923424626878877],
						[77.57992619139091, 12.923406773339414],
						[77.58090269039116, 12.923415727980943],
						[77.58238311919807, 12.92340680713475],
						[77.58425596940516, 12.923371116379544],
						[77.58595932849383, 12.92335330692818],
						[77.58590581472822, 12.920820548039199],
						[77.58583892248556, 12.917315653018866],
						[77.58583892248556, 12.917141734948606],
						[77.58961578013333, 12.916990180164987],
						[77.5932901250634, 12.916900960182197],
						[77.59331242101142, 12.918938773016023],
						[77.59331688020049, 12.918978891186878],
						[77.59312067571464, 12.918978879782994],
						[77.5926301630086, 12.91897889359892],
						[77.5926524590869, 12.919050290666158],
						[77.59283528676306, 12.919554112247887],
						[77.59297352212654, 12.920147243332648],
						[77.59312513491129, 12.920356794262894],
						[77.59317418606278, 12.92040585964538],
						[77.59327228830183, 12.920481667858233],
						[77.59333025776667, 12.920521766410163],
						[77.59335701289424, 12.920530719852824],
						[77.59345511497446, 12.923237356337467],
						[77.59704470065093, 12.92313477041507],
						[77.5970982091392, 12.924887191991747],
						[77.5971606356768, 12.926938383314123],
						[77.59621522840605, 12.926969588693503],
						[77.59535016463573, 12.926991886732457],
						[77.5943736156104, 12.927018660556518],
						[77.59230463976391, 12.92705879377114],
						[77.59130130879203, 12.927063239158974],
						[77.5897183447943, 12.927058832192458],
						[77.58963361747237, 12.927121220685997],
						[77.58920997990953, 12.927531507544266],
						[77.58917430509487, 12.927571598773005],
						[77.5890361525201, 12.927683052454226],
						[77.58900493700985, 12.927705343538754],
						[77.5889335872398, 12.92774105091162],
						[77.58883548123238, 12.927754443494862],
						[77.588706159547, 12.927758925588806],
						[77.58847427201633, 12.927763312469116],
						[77.58827805911866, 12.927758895700485],
						[77.58813535861545, 12.927776698513142],
						[77.58798373913329, 12.927821309797627],
						[77.58786333528195, 12.9278658675688],
						[77.58774739071126, 12.927937272524439],
						[77.58764491170055, 12.928013029903319],
						[77.58753788574032, 12.92810666930545],
						[77.5874932915603, 12.928173568780718],
						[77.58743085967873, 12.928267193587725],
						[77.58736396833876, 12.92840102843561],
						[77.58735950891469, 12.928445548069726],
						[77.58734613064141, 12.928481279030827],
						[77.58731045523827, 12.928543693702691],
						[77.58725248268415, 12.928592712751756],
						[77.58720342896127, 12.928615009334438],
						[77.58715883464929, 12.928628383573285],
						[77.5870830242785, 12.928628451228],
						[77.58701167329482, 12.928628388125977],
						[77.58689572785028, 12.928623943611033],
						[77.58665937715261, 12.928619494729793],
						[77.58606189647375, 12.928615036091415],
						[77.58383231168287, 12.928708658969517],
						[77.58392150291424, 12.93295371766278],
						[77.58382339255586, 12.932953746698225],
						[77.58234744222852, 12.932989396917556],
						[77.58125946413847, 12.93302505641008],
						[77.57964968857524, 12.9330563299329],
						[77.57958279263119, 12.93305630531279],
						[77.57960955101356, 12.930831236475173],
						[77.5796630677593, 12.930831221326223],
						[77.57967198721447, 12.930180214109528],
						[77.5796630677593, 12.929194709511092],
						[77.57967198721447, 12.928249372538339],
						[77.57967198721447, 12.927188121371568],
						[77.57968982612267, 12.926260638862887],
						[77.579703205302, 12.925199358544983],
						[77.57974334283045, 12.924147043061998],
						[77.57974334283045, 12.923424626878877],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000a1",
			properties: {
				AREA_SQ_KM: 1.7295659539623203,
				ASS_CONST1: "Jaya Nagar",
				ASS_CONST_: "173",
				LAT: 12.9245450128768,
				LON: 77.5875454398899,
				LOST_AREA_SQ_KM: 0.061734922939165895,
				OBJECTID: 162,
				POP_F: 15238.0,
				POP_M: 15751.0,
				POP_SC: 1473.0,
				POP_ST: 179.0,
				POP_TOTAL: 30989.0,
				RESERVATIO: "General",
				WARD_NAME: "Pattabhiram Nagar",
				WARD_NO: 168.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58490251437327, 12.936645887966241],
						[77.58490251437327, 12.936489784528106],
						[77.58488467628744, 12.934608031356108],
						[77.58482670248902, 12.932935902853922],
						[77.58392150291424, 12.93295371766278],
						[77.58383231168287, 12.928708658969517],
						[77.58606189647375, 12.928615036091415],
						[77.58665937715261, 12.928619494729793],
						[77.58689572785028, 12.928623943611033],
						[77.58701167329482, 12.928628388125977],
						[77.5870830242785, 12.928628451228],
						[77.58715883464929, 12.928628383573285],
						[77.58720342896127, 12.928615009334438],
						[77.58725248268415, 12.928592712751756],
						[77.58731045523827, 12.928543693702691],
						[77.58734613064141, 12.928481279030827],
						[77.58735950891469, 12.928445548069726],
						[77.58736396833876, 12.92840102843561],
						[77.58743085967873, 12.928267193587725],
						[77.5874932915603, 12.928173568780718],
						[77.58753788574032, 12.92810666930545],
						[77.58764491170055, 12.928013029903319],
						[77.58774739071126, 12.927937272524439],
						[77.58786333528195, 12.9278658675688],
						[77.58798373913329, 12.927821309797627],
						[77.58813535861545, 12.927776698513142],
						[77.58827805911866, 12.927758895700485],
						[77.58847427201633, 12.927763312469116],
						[77.588706159547, 12.927758925588806],
						[77.58883548123238, 12.927754443494862],
						[77.5889335872398, 12.92774105091162],
						[77.58900493700985, 12.927705343538754],
						[77.5890361525201, 12.927683052454226],
						[77.58917430509487, 12.927571598773005],
						[77.58920997990953, 12.927531507544266],
						[77.58963361747237, 12.927121220685997],
						[77.5897183447943, 12.927058832192458],
						[77.59130130879203, 12.927063239158974],
						[77.59230463976391, 12.92705879377114],
						[77.5943736156104, 12.927018660556518],
						[77.59535016463573, 12.926991886732457],
						[77.59538583749956, 12.928458975261313],
						[77.59348632925484, 12.928508013895298],
						[77.59264354065611, 12.928521368405736],
						[77.59259003005654, 12.928530351221815],
						[77.59254989709022, 12.928552646246699],
						[77.59252760099166, 12.928579330910683],
						[77.59250530488869, 12.928690830021155],
						[77.59250530488869, 12.929274965329707],
						[77.59254543787087, 12.930692980827699],
						[77.59260786692589, 12.931977186885813],
						[77.5926524590869, 12.933234692405255],
						[77.59271934729544, 12.934960302970236],
						[77.59272380650795, 12.93570501358018],
						[77.59270151044372, 12.936177692878562],
						[77.59243841655338, 12.936208853374906],
						[77.59200587102863, 12.93628471941472],
						[77.59174277551776, 12.93634268683887],
						[77.59157778310502, 12.936396140100587],
						[77.59138603486309, 12.936485374346724],
						[77.59110956008074, 12.936672600556832],
						[77.59106942658833, 12.936614644444337],
						[77.59062804466797, 12.93614203147672],
						[77.59017319564687, 12.936347155307434],
						[77.59004387549275, 12.935584642856753],
						[77.5896559141422, 12.935602478824697],
						[77.58947754066035, 12.935642616826792],
						[77.5893125449389, 12.935749596947389],
						[77.58922781731263, 12.935780783936453],
						[77.58916984574225, 12.935798601874465],
						[77.58908966480327, 12.935798631059754],
						[77.58901831508672, 12.93579417095585],
						[77.58892912787766, 12.93577635570146],
						[77.58880872503376, 12.935727303401743],
						[77.58860359396714, 12.935620309380273],
						[77.58844305635087, 12.935513301621997],
						[77.58809968346148, 12.935330453550717],
						[77.58797927973369, 12.935272436757435],
						[77.58792130752269, 12.935250172607462],
						[77.58784549766344, 12.935223431681939],
						[77.58779644419799, 12.935223403646118],
						[77.58776522834525, 12.935223456324353],
						[77.58768950582073, 12.935236801224205],
						[77.58760923639173, 12.935281389716938],
						[77.58753788574032, 12.93533493995515],
						[77.58739518430234, 12.935441954168196],
						[77.58727923925129, 12.935522152830812],
						[77.5870830242785, 12.935651508163575],
						[77.5866950527613, 12.935887848716797],
						[77.58613324805707, 12.93635159013453],
						[77.58593257161418, 12.936476420349692],
						[77.58578094917658, 12.93652102910312],
						[77.58560256957782, 12.936583488459583],
						[77.58542873663914, 12.936614703921162],
						[77.58519684238323, 12.93663692424481],
						[77.58490251437327, 12.936645887966241],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000a2",
			properties: {
				AREA_SQ_KM: 0.8701441044285644,
				ASS_CONST1: "Jaya Nagar",
				ASS_CONST_: "173",
				LAT: 12.9315978380753,
				LON: 77.5889403697453,
				LOST_AREA_SQ_KM: 0.02799417278328689,
				OBJECTID: 163,
				POP_F: 14878.0,
				POP_M: 15760.0,
				POP_SC: 3767.0,
				POP_ST: 73.0,
				POP_TOTAL: 30638.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Byrasandra",
				WARD_NO: 169.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5736477844495, 12.916914391315501],
						[77.57366116434329, 12.916646841968653],
						[77.57370130401515, 12.91624992972258],
						[77.57370130401515, 12.916174177989737],
						[77.5736923840893, 12.915946753414875],
						[77.5736923840893, 12.915866434383364],
						[77.5736700842716, 12.915656896611196],
						[77.5736745442355, 12.91553646013189],
						[77.57366116434329, 12.915353635705735],
						[77.57363440455414, 12.915282287790486],
						[77.57358088495687, 12.915161912381452],
						[77.57355858511718, 12.915037089456012],
						[77.57348276562946, 12.914573343652263],
						[77.57339802614189, 12.914011446109743],
						[77.57327323423202, 12.91347193106041],
						[77.57313051479431, 12.912985849814286],
						[77.573059155008, 12.91272277398729],
						[77.5730100951288, 12.912580123676841],
						[77.57296995521182, 12.91237947406767],
						[77.57292089529396, 12.912120830989542],
						[77.5728941353298, 12.911630325263287],
						[77.5728941353298, 12.911465298614193],
						[77.57418280406739, 12.911318166284488],
						[77.57767436148028, 12.910916842371396],
						[77.5805995192659, 12.910716235822184],
						[77.58313224551527, 12.910515560881015],
						[77.5857051378815, 12.910569026214343],
						[77.5857363543033, 12.912209993048368],
						[77.58583892248556, 12.915750485877748],
						[77.58583892248556, 12.916080478352866],
						[77.58583892248556, 12.917141734948606],
						[77.58583892248556, 12.917315653018866],
						[77.58590581472822, 12.920820548039199],
						[77.58595932849383, 12.92335330692818],
						[77.58425596940516, 12.923371116379544],
						[77.58238311919807, 12.92340680713475],
						[77.58090269039116, 12.923415727980943],
						[77.57992619139091, 12.923406773339414],
						[77.57974334283045, 12.923424626878877],
						[77.57969874557574, 12.923433517719365],
						[77.57964968857524, 12.92343805750234],
						[77.57902987209107, 12.923469234231797],
						[77.57725959874689, 12.923487055909838],
						[77.57612697305473, 12.923513854034457],
						[77.57481156694337, 12.923536091298192],
						[77.57359872482543, 12.923553939703844],
						[77.57357196502151, 12.922207317078628],
						[77.57356750505359, 12.921114814156317],
						[77.57356750505359, 12.92103457779026],
						[77.57397327396585, 12.920981067054964],
						[77.57416951168516, 12.920936453955004],
						[77.57422294355425, 12.920914199092815],
						[77.57434336192944, 12.920851724685487],
						[77.57437012155097, 12.92082948171965],
						[77.57439242123074, 12.920807110768633],
						[77.57440580103649, 12.920709073752827],
						[77.57439688116618, 12.920499479614133],
						[77.57438350135936, 12.920396882943695],
						[77.57436120167783, 12.920200694672616],
						[77.57428538272775, 12.919897483886475],
						[77.57418280406739, 12.91954524424271],
						[77.57405355307397, 12.919126054685966],
						[77.57377257594645, 12.918408117013707],
						[77.57368346416274, 12.918203036693226],
						[77.57361656469118, 12.918006865744873],
						[77.57361210472502, 12.917730356466622],
						[77.5736477844495, 12.916914391315501],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000a3",
			properties: {
				AREA_SQ_KM: 1.859390659900876,
				ASS_CONST1: "Jaya Nagar",
				ASS_CONST_: "173",
				LAT: 12.9170881910162,
				LON: 77.5797747306037,
				LOST_AREA_SQ_KM: 0.07178158831745222,
				OBJECTID: 164,
				POP_F: 11506.0,
				POP_M: 11733.0,
				POP_SC: 607.0,
				POP_ST: 162.0,
				POP_TOTAL: 23239.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Shakambari Nagar",
				WARD_NO: 179.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59538583749956, 12.928458975261313],
						[77.59535016463573, 12.926991886732457],
						[77.59621522840605, 12.926969588693503],
						[77.5971606356768, 12.926938383314123],
						[77.5970982091392, 12.924887191991747],
						[77.59704470065093, 12.92313477041507],
						[77.59345511497446, 12.923237356337467],
						[77.59335701289424, 12.920530719852824],
						[77.59333025776667, 12.920521766410163],
						[77.59327228830183, 12.920481667858233],
						[77.59317418606278, 12.92040585964538],
						[77.59312513491129, 12.920356794262894],
						[77.59297352212654, 12.920147243332648],
						[77.59283528676306, 12.919554112247887],
						[77.5926524590869, 12.919050290666158],
						[77.5926301630086, 12.91897889359892],
						[77.59312067571464, 12.918978879782994],
						[77.59331688020049, 12.918978891186878],
						[77.59331242101142, 12.918938773016023],
						[77.5932901250634, 12.916900960182197],
						[77.60010356183581, 12.916744885679867],
						[77.60019282764803, 12.916744938639548],
						[77.60098205325986, 12.916740425864393],
						[77.60285039852084, 12.916713731780167],
						[77.603278443691, 12.916678037177517],
						[77.60429959064099, 12.916660142772972],
						[77.60532072834476, 12.916637847917206],
						[77.6053474806295, 12.917057055574588],
						[77.60536085676947, 12.917409345200433],
						[77.60540098517988, 12.917846261676157],
						[77.60540544389127, 12.918087115053025],
						[77.60539206775658, 12.91824764833151],
						[77.60532518705934, 12.918559726271647],
						[77.60522709529828, 12.919063633814071],
						[77.6051468383394, 12.919442645948605],
						[77.60512454472956, 12.919585343386855],
						[77.60479905752418, 12.919513989620288],
						[77.60472325899902, 12.920124895774808],
						[77.60466975412704, 12.920405813832298],
						[77.60469204782679, 12.92050393238882],
						[77.60475001142548, 12.920570834667938],
						[77.60456720305149, 12.92079375942998],
						[77.60429959064099, 12.921047960341493],
						[77.60417028667119, 12.9211192522022],
						[77.60400977119205, 12.921293167715891],
						[77.60391613705704, 12.921404695606855],
						[77.60377791509663, 12.921444789778146],
						[77.60336770699094, 12.921458149669004],
						[77.60278351631626, 12.921440332973441],
						[77.60251152827577, 12.921431439361303],
						[77.60241789289695, 12.921364551139877],
						[77.602373304594, 12.921284239906798],
						[77.60235992809969, 12.921168362119372],
						[77.60229750443855, 12.921065735123191],
						[77.60201659753594, 12.921025644021825],
						[77.6015617069723, 12.920981095371754],
						[77.60118716183705, 12.920949876564766],
						[77.60084828659558, 12.92094536148981],
						[77.60015260995489, 12.920914182070057],
						[77.60018836873057, 12.921395715516118],
						[77.60024633464361, 12.922862788296749],
						[77.60033997182485, 12.924686530574489],
						[77.6003578074696, 12.924820380827132],
						[77.60049157471543, 12.926626253747536],
						[77.60050941033622, 12.926880433612524],
						[77.60053170485826, 12.927165857840928],
						[77.60053616376211, 12.92745568566937],
						[77.60051832814555, 12.928347467070292],
						[77.59869453403876, 12.928418795951938],
						[77.59715171760212, 12.928427733686624],
						[77.5955062333318, 12.928458974718534],
						[77.59538583749956, 12.928458975261313],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000bc",
			properties: {
				AREA_SQ_KM: 1.0357720556204355,
				ASS_CONST1: "Jaya Nagar",
				ASS_CONST_: "173",
				LAT: 12.9211063701817,
				LON: 77.5986397560155,
				LOST_AREA_SQ_KM: 0.043036607741441905,
				OBJECTID: 189,
				POP_F: 14882.0,
				POP_M: 15964.0,
				POP_SC: 5274.0,
				POP_ST: 188.0,
				POP_TOTAL: 30846.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Jayanagar East",
				WARD_NO: 170.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.60049157471543, 12.926626253747536],
						[77.6003578074696, 12.924820380827132],
						[77.60033997182485, 12.924686530574489],
						[77.60024633464361, 12.922862788296749],
						[77.60018836873057, 12.921395715516118],
						[77.60015260995489, 12.920914182070057],
						[77.60084828659558, 12.92094536148981],
						[77.60118716183705, 12.920949876564766],
						[77.6015617069723, 12.920981095371754],
						[77.60201659753594, 12.921025644021825],
						[77.60229750443855, 12.921065735123191],
						[77.60235992809969, 12.921168362119372],
						[77.602373304594, 12.921284239906798],
						[77.60241789289695, 12.921364551139877],
						[77.60251152827577, 12.921431439361303],
						[77.60278351631626, 12.921440332973441],
						[77.60336770699094, 12.921458149669004],
						[77.60377791509663, 12.921444789778146],
						[77.60391613705704, 12.921404695606855],
						[77.60400977119205, 12.921293167715891],
						[77.60417028667119, 12.9211192522022],
						[77.60429959064099, 12.921047960341493],
						[77.60456720305149, 12.92079375942998],
						[77.60475001142548, 12.920570834667938],
						[77.60469204782679, 12.92050393238882],
						[77.60466975412704, 12.920405813832298],
						[77.60472325899902, 12.920124895774808],
						[77.60479905752418, 12.919513989620288],
						[77.60512454472956, 12.919585343386855],
						[77.6051468383394, 12.919442645948605],
						[77.60522709529828, 12.919063633814071],
						[77.60532518705934, 12.918559726271647],
						[77.60539206775658, 12.91824764833151],
						[77.60540544389127, 12.918087115053025],
						[77.60540098517988, 12.917846261676157],
						[77.60536085676947, 12.917409345200433],
						[77.6053474806295, 12.917057055574588],
						[77.60532072834476, 12.916637847917206],
						[77.60697953284348, 12.9165977263096],
						[77.60723813419185, 12.916593300057933],
						[77.6072782619341, 12.916593324996343],
						[77.6072648860216, 12.916731534454536],
						[77.6074344015535, 12.917355814490955],
						[77.60759491215742, 12.917944415687945],
						[77.60772867081943, 12.918524068539655],
						[77.6079114740675, 12.919179593507787],
						[77.60808535980887, 12.919719113484424],
						[77.60812994585318, 12.919830624858905],
						[77.60814332166304, 12.91989746014472],
						[77.6081031942287, 12.919933204311171],
						[77.60802739792489, 12.919964396238214],
						[77.60808535980887, 12.920133865588681],
						[77.60824595690939, 12.920499508917553],
						[77.6084153835426, 12.92088738301155],
						[77.60850901394116, 12.921128205228642],
						[77.60862493718402, 12.921311053753419],
						[77.60866506443261, 12.921516130871673],
						[77.60867844017896, 12.921627615032655],
						[77.60870073308604, 12.92186838448558],
						[77.60870965024763, 12.922055677648105],
						[77.60877207035904, 12.922158219685064],
						[77.60883449043585, 12.922278690004548],
						[77.60887907618385, 12.922341074134296],
						[77.60890136905124, 12.922421353266703],
						[77.60890582762418, 12.922599728387823],
						[77.60890136905124, 12.922791467716529],
						[77.60885678331206, 12.922934137745811],
						[77.60881665613172, 12.923116929124461],
						[77.60875869462379, 12.923210604136685],
						[77.60866060585015, 12.923339937935104],
						[77.60856697557749, 12.923549473298195],
						[77.60853130688174, 12.923643095608758],
						[77.60860264426196, 12.923665474897716],
						[77.60870519166693, 12.923683229138819],
						[77.6087765289371, 12.923741206598818],
						[77.60876315320237, 12.92383486248487],
						[77.60856697557749, 12.924869369525913],
						[77.60835742182832, 12.924824768162527],
						[77.60821028811205, 12.92484263097983],
						[77.60813886305993, 12.924842657579322],
						[77.60796497740111, 12.924864927688061],
						[77.6078223017884, 12.924891709453659],
						[77.60763503977267, 12.924922906168987],
						[77.60738981523407, 12.924967530773454],
						[77.60712666816615, 12.925025432454342],
						[77.60697953284348, 12.9250521723731],
						[77.60697953284348, 12.925128023728599],
						[77.60698399149246, 12.92519046994646],
						[77.60699290878985, 12.925297433950828],
						[77.60699736743828, 12.925359880263592],
						[77.60700628473461, 12.925413403539284],
						[77.60698845014124, 12.925457973913424],
						[77.6069483222958, 12.925489236621488],
						[77.6068457732926, 12.925524835513851],
						[77.60673876553766, 12.925556083384507],
						[77.60667634430035, 12.925573952133572],
						[77.60650682762862, 12.92562297820899],
						[77.60637752618986, 12.925645314080628],
						[77.60627497666748, 12.92565419220468],
						[77.60619026177427, 12.925654184707588],
						[77.60611892286748, 12.925627408046998],
						[77.60604758391554, 12.925573910979407],
						[77.60598962098386, 12.925498126976628],
						[77.6059271993318, 12.925422301807632],
						[77.60583802548321, 12.92536433020544],
						[77.6057800624438, 12.92532423091884],
						[77.60561063185004, 12.92529301326207],
						[77.6054321961559, 12.925248434914034],
						[77.60528951733791, 12.925203845792826],
						[77.6051468383394, 12.925190458972512],
						[77.60497294806586, 12.9251815240611],
						[77.60487931473058, 12.925181519888339],
						[77.60500861788742, 12.925912783713896],
						[77.60503982897204, 12.926006421502757],
						[77.60486593853348, 12.926100144109746],
						[77.60459395551497, 12.92625174370226],
						[77.60449131694406, 12.926323021758273],
						[77.60452252817184, 12.926447948649146],
						[77.60459841425826, 12.926644145049286],
						[77.60462962545641, 12.92670658073267],
						[77.60475447016259, 12.927130180350156],
						[77.60476338763632, 12.92721930303082],
						[77.60453599183607, 12.927281758434523],
						[77.60454045058165, 12.927379890457715],
						[77.60437538945094, 12.927424473494545],
						[77.60441997696239, 12.92753590655272],
						[77.60477230510935, 12.92742444535557],
						[77.60485702106787, 12.92776777256383],
						[77.6049239020428, 12.92796845321297],
						[77.60493727823301, 12.928035379215189],
						[77.60475892889954, 12.928053208187062],
						[77.60466975412704, 12.928066604820232],
						[77.6045493680723, 12.92807548875494],
						[77.6041836629508, 12.92808442441747],
						[77.60384925548445, 12.928075465406277],
						[77.60354159973966, 12.928044256619227],
						[77.60339000094807, 12.928013028183331],
						[77.6032829024863, 12.927986259858102],
						[77.60297078638914, 12.927843644222126],
						[77.60214144513456, 12.927415496357524],
						[77.60171330822114, 12.92715242195107],
						[77.60141010551973, 12.926969636343866],
						[77.6012629627386, 12.926889404053837],
						[77.60118716183705, 12.926822503859746],
						[77.60110690200342, 12.926733324210876],
						[77.60102664211271, 12.926661986866495],
						[77.60095975882682, 12.926635163647465],
						[77.60082599213611, 12.926630734101227],
						[77.60049157471543, 12.926626253747536],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000bd",
			properties: {
				AREA_SQ_KM: 0.6828445072155571,
				ASS_CONST1: "Jaya Nagar",
				ASS_CONST_: "173",
				LAT: 12.922866401542,
				LON: 77.6046151492465,
				LOST_AREA_SQ_KM: 0.00433108468433455,
				OBJECTID: 190,
				POP_F: 16989.0,
				POP_M: 18839.0,
				POP_SC: 950.0,
				POP_ST: 87.0,
				POP_TOTAL: 35828.0,
				RESERVATIO: "General",
				WARD_NAME: "Gurappanapalya",
				WARD_NO: 171.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.59341498231558, 12.905316221557717],
						[77.59351754352659, 12.905227028409207],
						[77.59366023723041, 12.90509330799493],
						[77.59376279821866, 12.904861406646832],
						[77.59402588902338, 12.902712117288635],
						[77.59419970873356, 12.902694242391089],
						[77.59528327798566, 12.902658565636548],
						[77.59592092910447, 12.902618514116474],
						[77.59615726042249, 12.90257838080682],
						[77.59625536006955, 12.902573856068724],
						[77.59637575497442, 12.902564969498876],
						[77.59667451214668, 12.902493612244248],
						[77.5974593907716, 12.90217700026145],
						[77.59817283257492, 12.901838093767688],
						[77.59868115710826, 12.901575026745645],
						[77.59926974054947, 12.90126288755743],
						[77.59932770693445, 12.901213870168649],
						[77.59937675539076, 12.90120044721973],
						[77.5994079680337, 12.901195994737458],
						[77.59943918066799, 12.901191542250073],
						[77.59946593434766, 12.901213849207409],
						[77.59947485223947, 12.901249521591993],
						[77.59947485223947, 12.901276322076557],
						[77.59947039329366, 12.901329795867344],
						[77.59943472172077, 12.901410041559583],
						[77.5993901322388, 12.901539411360833],
						[77.59931433007901, 12.901704343049294],
						[77.59926528159555, 12.90180247648433],
						[77.59920731517852, 12.901945166857166],
						[77.59908692329414, 12.902337537284513],
						[77.598979908178, 12.90293504505749],
						[77.5989174826467, 12.903291757693422],
						[77.59890856471083, 12.903398793061552],
						[77.59893085954917, 12.903505857433336],
						[77.59893531851631, 12.90353261327777],
						[77.59893531851631, 12.903559328065551],
						[77.5989353185163, 12.903586129036212],
						[77.59894423645007, 12.903621802173811],
						[77.59901557989465, 12.903697605393234],
						[77.59911813601724, 12.903764475179726],
						[77.59917610248009, 12.903777849354011],
						[77.59922960995783, 12.903795663662269],
						[77.59928311741018, 12.903822440376791],
						[77.59932770693445, 12.90385361618145],
						[77.59937675539076, 12.903871475603138],
						[77.59943026277337, 12.90387145124087],
						[77.5994793111851, 12.903866990775859],
						[77.59951052379967, 12.903862538429387],
						[77.59963983311086, 12.903795649086113],
						[77.59969779930572, 12.90378678945588],
						[77.59980035480825, 12.90375550336486],
						[77.59986723878143, 12.903724319727623],
						[77.59995641735067, 12.903688687726447],
						[77.60000100660886, 12.90368866728297],
						[77.60005897261816, 12.903710917484407],
						[77.6000723493853, 12.90371095443555],
						[77.60009018507236, 12.903693107580368],
						[77.60010802075661, 12.903688618181672],
						[77.60011693859768, 12.90368421905297],
						[77.60013031535794, 12.903688651034244],
						[77.60014815103585, 12.903693124056133],
						[77.60017044562926, 12.903715390616275],
						[77.60019282764803, 12.903702070184897],
						[77.60019728656529, 12.903693148798434],
						[77.60019728656529, 12.903684186372624],
						[77.60018836873057, 12.903679709257615],
						[77.6001793634654, 12.903675314135612],
						[77.60016152779244, 12.903661878694363],
						[77.60016152779242, 12.903648521238102],
						[77.60017044562926, 12.903635159685622],
						[77.60029538270062, 12.903612872758695],
						[77.60032659508944, 12.903595062813874],
						[77.60037118420134, 12.903572722403398],
						[77.60040685547816, 12.90356382972423],
						[77.60044698565112, 12.903554891893826],
						[77.6006431774023, 12.903523691395412],
						[77.60087058105064, 12.903496914365261],
						[77.60096421771378, 12.903479075382243],
						[77.60105339541596, 12.903447837862968],
						[77.6011693263234, 12.903421155260274],
						[77.60125404498811, 12.903389876623718],
						[77.60129863373346, 12.903380979620882],
						[77.60146361193803, 12.90334526847364],
						[77.60157062469847, 12.903742151765433],
						[77.60162413104061, 12.903974030959114],
						[77.60174006136154, 12.904451144386034],
						[77.60184707385974, 12.904883709882924],
						[77.60188274466992, 12.905075440660424],
						[77.60189612122082, 12.905240423590406],
						[77.60190058007078, 12.905351893811247],
						[77.60188720352039, 12.90544105203911],
						[77.60188274466992, 12.905503490732738],
						[77.60186936811742, 12.905543613215153],
						[77.60184707385974, 12.905623899242583],
						[77.60178019106027, 12.90575324175575],
						[77.60169101393261, 12.905891427953554],
						[77.60161967217974, 12.905985137524931],
						[77.60151265946583, 12.906118850647882],
						[77.60132092809953, 12.906364161732638],
						[77.60105339541596, 12.906685216103288],
						[77.60065655410018, 12.907157876095866],
						[77.60057629388909, 12.907255984968245],
						[77.6005272459542, 12.907336197466707],
						[77.60048265690396, 12.907416451071327],
						[77.60041131438697, 12.90767510952278],
						[77.60033551291323, 12.908013961238327],
						[77.60032213617728, 12.908143280970522],
						[77.60029984161385, 12.908401961933626],
						[77.60029092378723, 12.908513397570204],
						[77.600277547046, 12.908678396690442],
						[77.60026862921762, 12.908754239846553],
						[77.59998762983325, 12.911554539044337],
						[77.59995641735067, 12.912285773788438],
						[77.59996087627728, 12.914287959811679],
						[77.59992966378731, 12.915367056344078],
						[77.59992520485946, 12.916392629594723],
						[77.59992520485946, 12.916557590608491],
						[77.59993858164246, 12.916606668276124],
						[77.59999654768383, 12.91666020899057],
						[77.6000723493853, 12.916713656068517],
						[77.60010356183581, 12.916744885679867],
						[77.5932901250634, 12.916900960182197],
						[77.58961578013333, 12.916990180164987],
						[77.58583892248556, 12.917141734948606],
						[77.58583892248556, 12.916080478352866],
						[77.58583892248556, 12.915750485877748],
						[77.5857363543033, 12.912209993048368],
						[77.5857051378815, 12.910569026214343],
						[77.58791238871835, 12.910475412831781],
						[77.59022670739145, 12.910404058646915],
						[77.59332133938939, 12.910230195500652],
						[77.59317418606278, 12.907465499650613],
						[77.59361564546734, 12.90740305181503],
						[77.59358443122271, 12.90635966726182],
						[77.5935621353284, 12.906332918572243],
						[77.5935621353284, 12.906297241142138],
						[77.59345511497446, 12.906301681247403],
						[77.59341498231558, 12.905316221557717],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000c0",
			properties: {
				AREA_SQ_KM: 1.806000874118347,
				ASS_CONST1: "Jaya Nagar",
				ASS_CONST_: "173",
				LAT: 12.9108817569626,
				LON: 77.5945067654985,
				LOST_AREA_SQ_KM: 0.06276582592668721,
				OBJECTID: 193,
				POP_F: 13761.0,
				POP_M: 14747.0,
				POP_SC: 2497.0,
				POP_ST: 457.0,
				POP_TOTAL: 28508.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "J P Nagar",
				WARD_NO: 177.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.69746362268774, 12.933671670655839],
						[77.69914919220699, 12.933502231726052],
						[77.70129394125345, 12.933243558841792],
						[77.70313109175909, 12.93273079021804],
						[77.70461156915083, 12.932026269424316],
						[77.705788756943, 12.931308356426014],
						[77.70656911632204, 12.930545796278441],
						[77.70719783708427, 12.929979518617987],
						[77.70752337643478, 12.92976106156601],
						[77.70771955557863, 12.929502398519682],
						[77.70780428110383, 12.92932845767546],
						[77.70810745820275, 12.928766628293499],
						[77.71033702047275, 12.926314129020748],
						[77.71083199715667, 12.925578363186],
						[77.71030138416285, 12.924896152469358],
						[77.70779091718506, 12.921935281751573],
						[77.70557921021515, 12.920151666404418],
						[77.70537403048202, 12.91989749706425],
						[77.70367064851482, 12.91780613413886],
						[77.70412992918646, 12.91750291775493],
						[77.70532502836981, 12.9167805507724],
						[77.70656466163324, 12.915968990801035],
						[77.70750101583367, 12.915402703412788],
						[77.70882989157667, 12.914715979968108],
						[77.70907061431517, 12.914591187655086],
						[77.7091597933979, 12.914484118425175],
						[77.70941842106849, 12.914394919575097],
						[77.70923115408164, 12.908731939461054],
						[77.71536686638169, 12.910083009635418],
						[77.71652184371128, 12.909298245079528],
						[77.71947823060927, 12.910934717140396],
						[77.7270319007478, 12.908174496065412],
						[77.72827155420808, 12.915558784099803],
						[77.73461684377884, 12.915937776476232],
						[77.74485494408954, 12.916316874964687],
						[77.7449887132827, 12.917453869735843],
						[77.74524734490748, 12.91838587771985],
						[77.74621943467857, 12.919799353714334],
						[77.74671887926655, 12.920160573037917],
						[77.74730301702316, 12.920602027585932],
						[77.7478871517023, 12.921043483795613],
						[77.74855597776488, 12.921400220506875],
						[77.74917581701064, 12.92147603284659],
						[77.74967524828074, 12.921471557464605],
						[77.75066965114901, 12.921458167297972],
						[77.75116898837628, 12.9215785449261],
						[77.75154355530394, 12.92197990557747],
						[77.7517977187616, 12.922341078502342],
						[77.75235956549065, 12.924410139657514],
						[77.75248896009134, 12.925016526562331],
						[77.75255138725694, 12.925359927769065],
						[77.75469170099888, 12.925868232848815],
						[77.75707730669535, 12.926523694023338],
						[77.76018530958028, 12.927669678964204],
						[77.76212057548092, 12.928298473702467],
						[77.76362776684451, 12.928637351960711],
						[77.76468009971072, 12.928802331767946],
						[77.76567890747006, 12.932008447239612],
						[77.76560313074435, 12.933216816346329],
						[77.76594203039801, 12.933921405307046],
						[77.76606686952451, 12.934509967267482],
						[77.76634334840317, 12.93495141597646],
						[77.76631663465471, 12.935366105432529],
						[77.76581719113065, 12.93622673849565],
						[77.7654961003959, 12.93702491960024],
						[77.76530884068725, 12.942103833891965],
						[77.76542922820066, 12.944725737555194],
						[77.76547829109458, 12.945256431679262],
						[77.76556742487288, 12.946585213505564],
						[77.76541141888859, 12.948565085488836],
						[77.76446158475798, 12.955387510145213],
						[77.76429658516695, 12.957947016309827],
						[77.76453744956673, 12.958134314747017],
						[77.76516610405314, 12.958696159722948],
						[77.76546048179041, 12.958981486202287],
						[77.76550945737, 12.959507670829474],
						[77.76539352227309, 12.960279134508493],
						[77.76535345130395, 12.96064919702814],
						[77.76537126062536, 12.960658149729202],
						[77.7655629725507, 12.96076514333907],
						[77.76731097538749, 12.960292451505925],
						[77.76905449842018, 12.960323736862556],
						[77.77027631001756, 12.961054994012033],
						[77.77048582193927, 12.961942397811724],
						[77.77030747494105, 12.962687037959164],
						[77.76794415363858, 12.963048209321093],
						[77.7676365130935, 12.963485224580413],
						[77.76566555051642, 12.962517605281468],
						[77.75905715277175, 12.959962540915939],
						[77.75901707950854, 12.959949168174346],
						[77.75762576872447, 12.959409628221792],
						[77.75760350551447, 12.959400636629917],
						[77.75758569494326, 12.959324832830221],
						[77.75755443912999, 12.959271336413638],
						[77.75750092007728, 12.959226790331472],
						[77.7574117798032, 12.959182193991468],
						[77.75723786439002, 12.95917325658838],
						[77.75715317656484, 12.95915095363738],
						[77.75708621202219, 12.959106373782205],
						[77.75702832738489, 12.9590394522163],
						[77.75696581543491, 12.959026136703573],
						[77.75692128874512, 12.95905283555532],
						[77.75687222205836, 12.95911977446188],
						[77.75686776938643, 12.95911525036303],
						[77.75677854129536, 12.959084106144108],
						[77.7565243892643, 12.958945856918753],
						[77.75606515051861, 12.958758547794714],
						[77.75577083510456, 12.958611407060038],
						[77.7532603478446, 12.957844455665473],
						[77.75043774975877, 12.956974879675816],
						[77.74977330069416, 12.956765325451576],
						[77.74950132072316, 12.955811123048491],
						[77.74948350884945, 12.955806655343077],
						[77.74921144085408, 12.955793230405364],
						[77.74905095884152, 12.955811061733554],
						[77.74870310149443, 12.955797749484336],
						[77.74839104179087, 12.955811062658826],
						[77.74832861228194, 12.955820003711269],
						[77.74806553481827, 12.9558735059936],
						[77.748052088426, 12.955886927421762],
						[77.74820820617873, 12.958361661356625],
						[77.74773548653656, 12.958428589043002],
						[77.74666535498041, 12.958749649811232],
						[77.74551042905759, 12.95702838638313],
						[77.74550152280051, 12.957113182965585],
						[77.74550152280051, 12.95715777648284],
						[77.74550597592913, 12.95721569204631],
						[77.74550152280051, 12.957237993103815],
						[77.74548371028422, 12.957246894437288],
						[77.74545245105755, 12.957237992920339],
						[77.74539892616012, 12.957184476140947],
						[77.74511802917047, 12.956908068601635],
						[77.74503778523085, 12.956845607238813],
						[77.74501543217531, 12.95680995843559],
						[77.74484603777982, 12.956702898965071],
						[77.74473008105478, 12.956658322301411],
						[77.74467655580872, 12.956653884231166],
						[77.74467210264679, 12.956644961233621],
						[77.7446721026468, 12.95664047603555],
						[77.74457404572215, 12.956635994410183],
						[77.7445249735688, 12.956635994107783],
						[77.74446254188325, 12.956640448667388],
						[77.74442237602503, 12.956640526058774],
						[77.7443689379476, 12.956569159193487],
						[77.74433322523119, 12.95652899498101],
						[77.74429751250328, 12.956520054592048],
						[77.74423516800695, 12.956479920138232],
						[77.74404787235551, 12.956145509820548],
						[77.74403896598098, 12.95606970174395],
						[77.74402551910045, 12.956020675454312],
						[77.74395418075245, 12.955976060445696],
						[77.74389620193887, 12.955931475778806],
						[77.7438605763878, 12.955846723159866],
						[77.7438605763878, 12.955806615514781],
						[77.74385166999824, 12.955757546111338],
						[77.74384276360796, 12.955735301388355],
						[77.74383376989942, 12.955699597072824],
						[77.7437758783405, 12.955659501836019],
						[77.74370899303273, 12.95560155666998],
						[77.7436331139598, 12.955356275134587],
						[77.74358858191353, 12.955276016684596],
						[77.74349934313172, 12.955003967416642],
						[77.74343245765719, 12.954914799361223],
						[77.74339683195717, 12.954883610223325],
						[77.74335221249811, 12.954843455265506],
						[77.74324524796837, 12.954459991012163],
						[77.74321398811081, 12.954393128497362],
						[77.74316046213316, 12.95435746739258],
						[77.7431337427937, 12.95431281463356],
						[77.74317827502256, 12.954232557842017],
						[77.74320062844834, 12.954152283472375],
						[77.74310248290472, 12.954085438022258],
						[77.7430980296796, 12.95406314627953],
						[77.74307131032509, 12.954036347712204],
						[77.74300887782137, 12.953951618241977],
						[77.74297761789721, 12.95392934803267],
						[77.74293308558781, 12.953911536737039],
						[77.74286619977184, 12.95387584550964],
						[77.74276360069379, 12.95374203042142],
						[77.74273688125875, 12.953701949261328],
						[77.74270562125794, 12.953670708985424],
						[77.74267890180893, 12.953635026660612],
						[77.74266554208201, 12.953603859713663],
						[77.74263873530484, 12.953581542179622],
						[77.7425629428184, 12.953532490699391],
						[77.74250496327767, 12.953492392251876],
						[77.74243362395178, 12.953443293407716],
						[77.74241581094282, 12.953425456554728],
						[77.74237119108918, 12.953407641174536],
						[77.74234447155962, 12.953403181741955],
						[77.74229985167729, 12.953367512442156],
						[77.74226867887212, 12.95334076144921],
						[77.74222405895931, 12.953305092169424],
						[77.742170532504, 12.953273830219166],
						[77.7421170933418, 12.953251542486774],
						[77.74207247336801, 12.953233727111444],
						[77.74201449357096, 12.95320251255842],
						[77.7419431539297, 12.953166868895606],
						[77.74188962733888, 12.95314457701608],
						[77.74183610072224, 12.953108916309438],
						[77.74177366752336, 12.953059809066458],
						[77.74170232772725, 12.953042019255987],
						[77.74165334162282, 12.953046421754989],
						[77.74159090832113, 12.95305087599586],
						[77.74152847498429, 12.953050931449027],
						[77.74145713503052, 12.953050909144006],
						[77.74145268173935, 12.953055355291438],
						[77.74143032796137, 12.953046445509889],
						[77.74137680112314, 12.953046406161349],
						[77.741341174763, 12.953028586372653],
						[77.74126092809124, 12.953010804959003],
						[77.7412074011713, 12.953001881799768],
						[77.74114051431495, 12.953010782050127],
						[77.74110925386435, 12.953015249532653],
						[77.74108698733691, 12.95300634387567],
						[77.741064720805, 12.952988468197837],
						[77.74104236694886, 12.952966189619374],
						[77.74098002062317, 12.952952794053292],
						[77.74092649356771, 12.952930502078136],
						[77.74087296648642, 12.952921578865213],
						[77.74081053274561, 12.952917149133947],
						[77.74077490620364, 12.952921581832719],
						[77.74075700560837, 12.952930478415201],
						[77.74073473901025, 12.952948310265205],
						[77.7407080190866, 12.952957305720775],
						[77.74067675851416, 12.952952803131526],
						[77.74064558525298, 12.952952789686599],
						[77.74061432466294, 12.95295277209242],
						[77.74057869805799, 12.952952806002026],
						[77.74049391018497, 12.952939466347132],
						[77.74046273687242, 12.952939452877455],
						[77.74042256956604, 12.952939443729322],
						[77.7403869428995, 12.952934992610201],
						[77.74036904224165, 12.952935005403267],
						[77.74034232222995, 12.952934944557548],
						[77.7403200555486, 12.952930523852359],
						[77.74030215487997, 12.952921566645426],
						[77.74027543485215, 12.952903738306446],
						[77.74027988819056, 12.952890408460508],
						[77.7402487148179, 12.952885909969778],
						[77.74020409409276, 12.952881463151053],
						[77.74017737404132, 12.952877003545549],
						[77.74015056666299, 12.952863656056289],
						[77.7401060332189, 12.952819020944533],
						[77.74009703920743, 12.952810141529467],
						[77.74007922582334, 12.952814557190777],
						[77.74004805239429, 12.952814543673862],
						[77.74002569833334, 12.952810118810266],
						[77.74000343158848, 12.952796728112979],
						[77.73996780478737, 12.952774423252032],
						[77.73990091718385, 12.952725363307731],
						[77.73979831536377, 12.952689701744712],
						[77.73970470749283, 12.952676374509936],
						[77.73964672645855, 12.952640674820161],
						[77.7395709319283, 12.952578254679448],
						[77.7395307642991, 12.952551508071284],
						[77.73949513734627, 12.95252471826673],
						[77.73944160957456, 12.952448951552903],
						[77.73941480201849, 12.952431119119666],
						[77.73939253515104, 12.952413243487817],
						[77.7393613615291, 12.952377608943218],
						[77.73933010057732, 12.952337485304618],
						[77.739276572726, 12.952185819470575],
						[77.7392587592102, 12.95215909912913],
						[77.7392275855507, 12.952127863383982],
						[77.73921867878921, 12.95211450322044],
						[77.73919632456122, 12.952069886299597],
						[77.73917405764993, 12.952065465583674],
						[77.73915179073417, 12.95206544356619],
						[77.7391294364927, 12.95207878599927],
						[77.73912498310811, 12.952105570544417],
						[77.73910271618251, 12.952118917120705],
						[77.73907590854459, 12.952132306892596],
						[77.73901801450315, 12.95216354075866],
						[77.7389600331101, 12.952163548304174],
						[77.73893767883, 12.952181375665027],
						[77.73889759829308, 12.952194739076145],
						[77.73881734985427, 12.95222154797503],
						[77.73878608874924, 12.952221530189023],
						[77.73874600815756, 12.952230408616105],
						[77.73873256063554, 12.952248227529047],
						[77.73870584022981, 12.952279474944746],
						[77.73868802662241, 12.952306228991654],
						[77.738674666415, 12.952315168396222],
						[77.73865676548097, 12.952306211179232],
						[77.73862113824755, 12.95229736118676],
						[77.7385987838992, 12.952288365051633],
						[77.73857651686802, 12.952288429209796],
						[77.7385542498324, 12.952306260666925],
						[77.73854088960887, 12.952310715109505],
						[77.73851408183525, 12.952310736195304],
						[77.73847400114535, 12.952288392416406],
						[77.73845164676736, 12.95228396746839],
						[77.73842937970666, 12.952283945362874],
						[77.7384160194681, 12.952297283442794],
						[77.73839366507843, 12.952315196979274],
						[77.73838030483557, 12.952310681513572],
						[77.73836694459112, 12.952288398756371],
						[77.73832232310939, 12.952266098148042],
						[77.7382956026049, 12.95224378488154],
						[77.73829114918685, 12.952221493737218],
						[77.73827778893165, 12.952181357481887],
						[77.73824652767466, 12.952154602476021],
						[77.73822426057282, 12.952141211788183],
						[77.73816627876512, 12.952123451872012],
						[77.73813510479876, 12.952123438147941],
						[77.7381127503525, 12.952110043313375],
						[77.73804586163098, 12.952078836975982],
						[77.73799687391248, 12.952078840029769],
						[77.73792998512111, 12.951896008583287],
						[77.73794334541817, 12.951748812873738],
						[77.73792107825551, 12.95173102356941],
						[77.73781402116798, 12.951557153117305],
						[77.73773822538615, 12.951454541838782],
						[77.73761335434239, 12.95126283534925],
						[77.73760890089697, 12.951258311528294],
						[77.73759554055968, 12.951227145473087],
						[77.73752419807765, 12.951124573473104],
						[77.73747512280637, 12.951071098722846],
						[77.73744394864589, 12.951066600111336],
						[77.73742604751338, 12.951075496292114],
						[77.73740378024694, 12.951097812356368],
						[77.7373859664306, 12.95109334426169],
						[77.73735915837804, 12.951075512002229],
						[77.7373190772711, 12.951057653359241],
						[77.73726109499057, 12.951044292241372],
						[77.73718975229362, 12.95104435522627],
						[77.73713176994552, 12.951066614403974],
						[77.73712286301588, 12.951062137907222],
						[77.73710059568864, 12.951075570398903],
						[77.73707378756717, 12.951075505160071],
						[77.73706042716546, 12.951071075977028],
						[77.73704706676212, 12.951057677028956],
						[77.73702025862772, 12.951053213151022],
						[77.73699799127989, 12.951048792349287],
						[77.7369757239276, 12.951044285298337],
						[77.73694891577598, 12.951017569510768],
						[77.73692664841386, 12.950981927053837],
						[77.73690438104724, 12.950977420003651],
						[77.73688202635314, 12.950981878560118],
						[77.73683749159757, 12.950959582318005],
						[77.73681522421309, 12.95090608663624],
						[77.73680622993494, 12.95089272249753],
						[77.7367795090655, 12.950861526024013],
						[77.73675724166938, 12.950843650609938],
						[77.73672152650316, 12.950830311611208],
						[77.736681445166, 12.950803483217221],
						[77.73666354391052, 12.950772274249815],
						[77.736659090427, 12.950767836695357],
						[77.7366546369433, 12.950754429410095],
						[77.73663682300673, 12.950745562678614],
						[77.7366190090673, 12.9507455794286],
						[77.73651195072404, 12.950683142282326],
						[77.73640052610705, 12.950616271754395],
						[77.73636926432138, 12.950620738631297],
						[77.73633363635379, 12.950616200996615],
						[77.73629346755308, 12.950589454739097],
						[77.73626229306079, 12.950571673973526],
						[77.73622212423429, 12.950553811180384],
						[77.73617750189041, 12.950553848848124],
						[77.73614632736553, 12.950553834988655],
						[77.73611951901363, 12.950540401372185],
						[77.73609725148515, 12.950527097093051],
						[77.7360838909659, 12.950509213360611],
						[77.73604372207484, 12.950486951961494],
						[77.73599473347282, 12.95045124853207],
						[77.73596792508431, 12.95041107835632],
						[77.73594565752542, 12.950406657528841],
						[77.73590994209809, 12.950415570242704],
						[77.7358743139831, 12.950415603658277],
						[77.73582078446532, 12.9503843419879],
						[77.73577624928403, 12.950357647203228],
						[77.73560229993421, 12.950223813136589],
						[77.73556213086893, 12.950192582111377],
						[77.7355220491132, 12.950165840068113],
						[77.7354685194256, 12.950161401161957],
						[77.73544179823418, 12.950165911017141],
						[77.73541053617855, 12.95016589294349],
						[77.73539717557668, 12.95015249410093],
						[77.73530356399425, 12.950018679715182],
						[77.7352857498409, 12.949983076590259],
						[77.73526339482089, 12.949974166784449],
						[77.73525448774134, 12.949938469089442],
						[77.73520541146674, 12.949902761669863],
						[77.73513406744877, 12.949889369793484],
						[77.73507617135154, 12.949876012668087],
						[77.73502709499812, 12.949840391502255],
						[77.73491112801275, 12.949751140403183],
						[77.73487995313232, 12.949715506758281],
						[77.73480415534732, 12.949666456234183],
						[77.73471936306339, 12.949617409908342],
						[77.73456331365482, 12.949541607684788],
						[77.73448306208287, 12.949514855943466],
						[77.73442062474794, 12.949528191905701],
						[77.73435373380191, 12.949523722222281],
						[77.7343314659193, 12.949523786132053],
						[77.73428684281559, 12.949519338766308],
						[77.73424676059952, 12.9495193330463],
						[77.73421104462574, 12.949505907786007],
						[77.73418432313171, 12.949474711719313],
						[77.73418432313171, 12.949447975515032],
						[77.73421549820745, 12.949398915689112],
						[77.7341887767145, 12.949367719659545],
						[77.73417541596562, 12.94935432091486],
						[77.73417986954877, 12.949327537459196],
						[77.73418432313171, 12.94930972356222],
						[77.73418432313171, 12.949278502619716],
						[77.73418432313171, 12.949247281685508],
						[77.73398364974572, 12.9495817015993],
						[77.7339123049428, 12.949572794263142],
						[77.73363137887783, 12.949572823204338],
						[77.73360465724434, 12.949572848056192],
						[77.7335244049796, 12.949554979334986],
						[77.7335244049796, 12.94953264169623],
						[77.7335244049796, 12.949519273613292],
						[77.73312750908543, 12.94940340103116],
						[77.73302944196061, 12.949278518185222],
						[77.73301599374618, 12.948841478775167],
						[77.73274406002187, 12.948810282381736],
						[77.73274851366241, 12.948574010390159],
						[77.73253893038468, 12.948573976707925],
						[77.73260136877754, 12.947922949347246],
						[77.73263699794262, 12.947922916197435],
						[77.73265035887658, 12.947775814215134],
						[77.7323783369305, 12.947753497552892],
						[77.73201715363165, 12.947784730431144],
						[77.73138403210437, 12.947940781064908],
						[77.73031828393866, 12.947949678102715],
						[77.7285925780983, 12.947807001626884],
						[77.72765168554129, 12.947227346586573],
						[77.72634076283282, 12.947240709829684],
						[77.72547120148165, 12.947124801085103],
						[77.72546229361701, 12.946946374870132],
						[77.72511453700986, 12.94653616148682],
						[77.72499855967747, 12.946402411007668],
						[77.72300535503499, 12.946348849012292],
						[77.72125289839008, 12.945390155401846],
						[77.71946478075519, 12.945153831383568],
						[77.7184347267096, 12.944614266240645],
						[77.71719959252212, 12.943575299928655],
						[77.71579500592784, 12.947494896206871],
						[77.71534904903316, 12.950968511919497],
						[77.71501907821036, 12.954076542373201],
						[77.71488090591299, 12.954410979068493],
						[77.71462665795677, 12.954553605647481],
						[77.71451966577133, 12.955962673996614],
						[77.70997139837901, 12.956279297186475],
						[77.70661366320024, 12.956582487329943],
						[77.7044777513645, 12.956814366068471],
						[77.70664938803678, 12.941377010693417],
						[77.7065423008397, 12.94145720144832],
						[77.70262725851735, 12.94309821998964],
						[77.69939439083267, 12.94420853185969],
						[77.69943902793095, 12.944034569135964],
						[77.69941221073316, 12.943691293833862],
						[77.6993676609766, 12.943120471088072],
						[77.69836432586311, 12.938184246056018],
						[77.69746362268774, 12.933671670655839],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000a4",
			properties: {
				AREA_SQ_KM: 26.907471378238387,
				ASS_CONST1: "Mahadevapura",
				ASS_CONST_: "174",
				LAT: 12.935373342926,
				LON: 77.733613606496,
				LOST_AREA_SQ_KM: 1.8019452059674732,
				OBJECTID: 165,
				POP_F: 12034.0,
				POP_M: 13033.0,
				POP_SC: 5581.0,
				POP_ST: 183.0,
				POP_TOTAL: 25067.0,
				RESERVATIO: "General",
				WARD_NAME: "Varthuru",
				WARD_NO: 149.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				geometries: [
					{
						coordinates: [
							[77.64939450517785, 12.947427982381239],
							[77.64935883236942, 12.94746811429072],
						],
						type: "LineString",
					},
					{
						coordinates: [
							[
								[77.64939453613752, 12.947427981387571],
								[77.64942573490673, 12.947387827730907],
								[77.6494346488392, 12.947347729484983],
								[77.64941236400671, 12.947271909387975],
								[77.6492830244993, 12.946883934581399],
								[77.64927411055479, 12.946852727213546],
								[77.64922954082157, 12.94673678359983],
								[77.6491804267029, 12.946553973579244],
								[77.64908237318454, 12.946001090464382],
								[77.64889954946158, 12.946130389836934],
								[77.6482529348692, 12.946759152979963],
								[77.64782042898882, 12.947173804808203],
								[77.6476688024671, 12.947267433639652],
								[77.64699552579985, 12.94758848747787],
								[77.6466878132468, 12.947766908358343],
								[77.64656301507222, 12.947865009636699],
								[77.64646487187244, 12.947976456720914],
								[77.64632661478822, 12.948163757489544],
								[77.64605464460325, 12.948560563210956],
								[77.64555964319194, 12.949242875350052],
								[77.64535007096197, 12.949546011190249],
								[77.64516278402161, 12.949849264869089],
								[77.64498441105472, 12.950210456096965],
								[77.64477483775053, 12.950709837140863],
								[77.64463666597938, 12.951204875522716],
								[77.64457863553267, 12.951525869739662],
								[77.64445829217631, 12.952083241824196],
								[77.64432894695807, 12.952877044959681],
								[77.6442843752605, 12.953238178236104],
								[77.64427991808977, 12.953291683242838],
								[77.64427100374778, 12.953336266232867],
								[77.644208603334, 12.953389843929735],
								[77.6441416583137, 12.95341207681253],
								[77.64408817218523, 12.953420985244598],
								[77.64399011421685, 12.953443281243157],
								[77.64367347903347, 12.9535414284962],
								[77.64302246450202, 12.953768861768005],
								[77.64282625922098, 12.953853543087364],
								[77.64271919828826, 12.953915933415155],
								[77.64267908318595, 12.953933845258707],
								[77.6426389680693, 12.953947187154219],
								[77.64257656674826, 12.953947218392914],
								[77.64246950557742, 12.953956062693232],
								[77.64241601865218, 12.953956089427498],
								[77.64236253170148, 12.953956116148687],
								[77.64220644055253, 12.953920449505464],
								[77.64207263548111, 12.953857998809154],
								[77.64198794753834, 12.953777722417785],
								[77.64191663132647, 12.953706363704844],
								[77.64187197127366, 12.953635073762767],
								[77.6418318558686, 12.953590472406388],
								[77.64179174044922, 12.953536989921755],
								[77.64173825320108, 12.953510200671154],
								[77.64153313111619, 12.95343442081901],
								[77.64102028056537, 12.9532694134812],
								[77.64092667722124, 12.953269416720286],
								[77.64077058407607, 12.953269446587779],
								[77.64044065524163, 12.95269414633579],
								[77.64043619791829, 12.952685224380168],
								[77.63994562907402, 12.951833567322463],
								[77.63994117173102, 12.951833612639577],
								[77.63994117173102, 12.95182912904327],
								[77.6397940793131, 12.951597219118758],
								[77.64242047589693, 12.948997595369477],
								[77.64656301507222, 12.94555514962621],
								[77.6474726053092, 12.94479714002421],
								[77.6474681482651, 12.944244173026249],
								[77.64802108256076, 12.943740272369874],
								[77.64999648732974, 12.942518475911735],
								[77.65253368308792, 12.9412432342507],
								[77.65395173666354, 12.94052975571756],
								[77.6547141941189, 12.940222079631189],
								[77.655704113952, 12.940217631445218],
								[77.65586909974088, 12.94021318224214],
								[77.65626600841395, 12.940186443046715],
								[77.65649784367508, 12.94010164014778],
								[77.65673422251844, 12.93994118151],
								[77.65672530916629, 12.939932220184785],
								[77.65708201747022, 12.939709308809965],
								[77.65733167765329, 12.939481888494523],
								[77.65754131487827, 12.939142973359196],
								[77.65769738468774, 12.938853145415822],
								[77.65785345427982, 12.938795158084591],
								[77.65806745976687, 12.938781752749284],
								[77.65816559281188, 12.938772902134358],
								[77.658178962668, 12.938509799384647],
								[77.65821015899273, 12.937947941847854],
								[77.65901278413696, 12.937907803426796],
								[77.65902615389197, 12.938126314220558],
								[77.65907517631342, 12.938326956080202],
								[77.6604530383791, 12.938353677964406],
								[77.66051106061074, 12.938309113827426],
								[77.6605422562861, 12.938273442851692],
								[77.6605422562861, 12.938233351286993],
								[77.6605422562861, 12.938144115272824],
								[77.66050660408497, 12.938112926714224],
								[77.65809419950092, 12.936686049960013],
								[77.65522252533704, 12.93511638581645],
								[77.65914211241929, 12.929765471277111],
								[77.65957911857149, 12.929783304259976],
								[77.65972181587209, 12.929787784738433],
								[77.66011416697417, 12.929872542202162],
								[77.66040401656059, 12.929917062103932],
								[77.66064038716345, 12.929997394913869],
								[77.6609436049145, 12.930064277710837],
								[77.66113977849768, 12.930086568295373],
								[77.6614385384082, 12.930050903612477],
								[77.66162579816229, 12.929966123549049],
								[77.66184879677041, 12.929845718083675],
								[77.66200486057926, 12.929761001369794],
								[77.66225450979219, 12.929587121775532],
								[77.66235263916946, 12.929462225151038],
								[77.66248196359587, 12.929306228216284],
								[77.6626647651655, 12.929163472304495],
								[77.66305719333992, 12.928775526301328],
								[77.66313749633059, 12.928695299441834],
								[77.66323999397062, 12.928592767630292],
								[77.66337377378419, 12.92843663825456],
								[77.6634719021807, 12.928325190643417],
								[77.66354320468383, 12.928200307844568],
								[77.66358785610566, 12.928106655962532],
								[77.66369035333346, 12.928169133451132],
								[77.66395791172474, 12.927981881492187],
								[77.66422546947669, 12.927981881142527],
								[77.66453313388716, 12.927977413310277],
								[77.66471147574995, 12.927977382517145],
								[77.66484971015862, 12.92798181880533],
								[77.66490327376161, 12.927999718657398],
								[77.66498794439661, 12.928044275720694],
								[77.66513063480589, 12.92821815886084],
								[77.6653001504262, 12.928548159037842],
								[77.66558553026707, 12.929315095137461],
								[77.66577278308644, 12.929818975450065],
								[77.66578615203449, 12.9300241117743],
								[77.66579506466564, 12.930073161351432],
								[77.665844171503, 12.93021585925251],
								[77.66589764725155, 12.930300571259778],
								[77.6659199288059, 12.930336205623295],
								[77.6659377540462, 12.93038973243715],
								[77.66603588019565, 12.930563650189168],
								[77.66609381215622, 12.930697458524206],
								[77.66616520036807, 12.930746470283195],
								[77.66627669520761, 12.930813344335716],
								[77.66644612171437, 12.930889188487663],
								[77.6666601107832, 12.930965002335343],
								[77.66703469989153, 12.931054164690291],
								[77.66748950023803, 12.931063091954181],
								[77.66804242312698, 12.931014018700896],
								[77.66835462063754, 12.931022955727995],
								[77.6687559412631, 12.931040797289604],
								[77.66886743352195, 12.931045250928591],
								[77.66911706743232, 12.930996198696182],
								[77.66927320848554, 12.930951568946275],
								[77.66925538377643, 12.93022927151519],
								[77.66930885789523, 12.92981454190099],
								[77.66929994554387, 12.929792218547018],
								[77.66932222642093, 12.928882600605137],
								[77.66934896346756, 12.928833526270356],
								[77.66933113877052, 12.928566017650583],
								[77.66927320848554, 12.927415500552495],
								[77.66927766466237, 12.927321913540789],
								[77.66927766466237, 12.927277341603814],
								[77.66930885789523, 12.92722382474009],
								[77.66810935387288, 12.92433426671831],
								[77.66769466221024, 12.923794743731428],
								[77.66706589374954, 12.922804838577882],
								[77.66621421966465, 12.9220333753121],
								[77.66485416651156, 12.92067337907039],
								[77.66417199292945, 12.919772598903812],
								[77.66314195275167, 12.91806923564805],
								[77.66272278625142, 12.918038052718881],
								[77.66200040411293, 12.916022503020248],
								[77.66122899585773, 12.915893246152352],
								[77.66119325649284, 12.915585525750853],
								[77.66118879999422, 12.915139650359587],
								[77.6609302353883, 12.913980250736994],
								[77.66017218938131, 12.9115188174716],
								[77.65702399346283, 12.911447498324032],
								[77.65557478170193, 12.906386415019167],
								[77.65477667607587, 12.903483524891936],
								[77.65480787334765, 12.903140160162717],
								[77.65504862511096, 12.90276118391017],
								[77.65594495117675, 12.901686503723274],
								[77.65604299869403, 12.901601794281623],
								[77.65638633910123, 12.901200469064735],
								[77.6567565058957, 12.900910658318365],
								[77.65706419082056, 12.900736727428765],
								[77.65717560733451, 12.900424596178622],
								[77.65707310414574, 12.900246215349526],
								[77.65701508013373, 12.899711147105632],
								[77.65681889932847, 12.89949710648967],
								[77.65660934813799, 12.89974681526648],
								[77.65643545006358, 12.899746808384284],
								[77.65624818163448, 12.89973340870494],
								[77.6560251718791, 12.89969331206483],
								[77.65586018631994, 12.899555051537149],
								[77.65578442221326, 12.899193847239408],
								[77.6559047534176, 12.898716750848994],
								[77.65586909974088, 12.898382367557293],
								[77.65583790276447, 12.898190579466362],
								[77.65549901740233, 12.89835560884971],
								[77.65536522818536, 12.898373370418998],
								[77.65521806860096, 12.898346625685159],
								[77.6546517995145, 12.898261913536299],
								[77.65466071303153, 12.89798550433403],
								[77.65519132818068, 12.896130523690983],
								[77.65720243466681, 12.894088259441821],
								[77.65716669401748, 12.89404363814094],
								[77.65723808791935, 12.894052552165224],
								[77.65746992116956, 12.89407927244523],
								[77.65780880057214, 12.894137287783613],
								[77.65827255161614, 12.89422641209465],
								[77.6586917347692, 12.894329014323626],
								[77.65909309002713, 12.894418184604008],
								[77.65993581779054, 12.894623336185443],
								[77.66011416697417, 12.894663453878028],
								[77.66023903744869, 12.894690167096975],
								[77.66032379899309, 12.894694631632518],
								[77.66041738614958, 12.894699085195303],
								[77.66053334323689, 12.894694691809121],
								[77.66064930020416, 12.894694693862945],
								[77.66075180012125, 12.894694658801825],
								[77.66125573483718, 12.894645638486605],
								[77.66202714290824, 12.89456530225991],
								[77.66227687945299, 12.89453857304464],
								[77.66267367804511, 12.89450289588324],
								[77.66300817266116, 12.894422625611286],
								[77.66312849610708, 12.894395857657509],
								[77.6633024711732, 12.894324584098847],
								[77.66338268660738, 12.894284445027262],
								[77.66343625091213, 12.894271048732502],
								[77.6634719021807, 12.894271021056122],
								[77.6634763585885, 12.894275456215487],
								[77.66471601948797, 12.894351321005894],
								[77.66496120630828, 12.894320062911262],
								[77.6659689482099, 12.894177436595736],
								[77.66695894334303, 12.894052577887404],
								[77.66825204030333, 12.893887571361084],
								[77.66838135791534, 12.893869760593532],
								[77.66959422751546, 12.893695845653214],
								[77.66983949102578, 12.893642262813492],
								[77.66982612256187, 12.89355759395155],
								[77.67057073483106, 12.893414931534545],
								[77.67149830403781, 12.893249936142867],
								[77.6733398765893, 12.892919891179986],
								[77.67363860393742, 12.892866431500613],
								[77.6739641538037, 12.892826312553291],
								[77.67638993354169, 12.89278619198018],
								[77.6863560122056, 12.905833496550855],
								[77.68876387607766, 12.908143325403278],
								[77.70159722341592, 12.904741006572635],
								[77.707782007905, 12.903737741950936],
								[77.70923115408164, 12.908731939461054],
								[77.70941842106849, 12.914394919575097],
								[77.7091597933979, 12.914484118425175],
								[77.70907061431517, 12.914591187655086],
								[77.70882989157667, 12.914715979968108],
								[77.70750101583367, 12.915402703412788],
								[77.70656466163324, 12.915968990801035],
								[77.70532502836981, 12.9167805507724],
								[77.70412992918646, 12.91750291775493],
								[77.70367064851482, 12.91780613413886],
								[77.70537403048202, 12.91989749706425],
								[77.70557921021515, 12.920151666404418],
								[77.70779091718506, 12.921935281751573],
								[77.71030138416285, 12.924896152469358],
								[77.71083199715667, 12.925578363186],
								[77.71033702047275, 12.926314129020748],
								[77.70810745820275, 12.928766628293499],
								[77.70780428110383, 12.92932845767546],
								[77.70771955557863, 12.929502398519682],
								[77.70752337643478, 12.92976106156601],
								[77.70719783708427, 12.929979518617987],
								[77.70656911632204, 12.930545796278441],
								[77.705788756943, 12.931308356426014],
								[77.70461156915083, 12.932026269424316],
								[77.70313109175909, 12.93273079021804],
								[77.70129394125345, 12.933243558841792],
								[77.69914919220699, 12.933502231726052],
								[77.69746362268774, 12.933671670655839],
								[77.69836432586311, 12.938184246056018],
								[77.6993676609766, 12.943120471088072],
								[77.69941221073316, 12.943691293833862],
								[77.69943902793095, 12.944034569135964],
								[77.69939439083267, 12.94420853185969],
								[77.70262725851735, 12.94309821998964],
								[77.7065423008397, 12.94145720144832],
								[77.70664938803678, 12.941377010693417],
								[77.7044777513645, 12.956814366068471],
								[77.7010576566236, 12.95691251953463],
								[77.70001424216814, 12.953568139655301],
								[77.6992918389971, 12.9496397250045],
								[77.69921601696603, 12.949225023904841],
								[77.69903318782427, 12.948364390260112],
								[77.6988058084017, 12.947597449790567],
								[77.69856943114591, 12.94693305093993],
								[77.69831977568795, 12.946170493561072],
								[77.69819494774939, 12.945657753743385],
								[77.69802548202485, 12.944953178269156],
								[77.69796302423153, 12.944806023020583],
								[77.69704895260848, 12.944895215205802],
								[77.69556400702149, 12.945323276804073],
								[77.69475692700374, 12.945104788434488],
								[77.69348606333462, 12.944257570787622],
								[77.69171138999376, 12.94292425597901],
								[77.68970920311156, 12.941519674764645],
								[77.6886747680431, 12.941006839949202],
								[77.68803257656556, 12.941912081085114],
								[77.68680636584676, 12.941738203428775],
								[77.68549094372499, 12.941211971043113],
								[77.68538837911223, 12.941292276511682],
								[77.68510741798701, 12.941457206911513],
								[77.68475962277941, 12.941635594071588],
								[77.68425133836045, 12.941938864042648],
								[77.68372959746239, 12.942242008860072],
								[77.68286005300585, 12.942603248558775],
								[77.68202177883438, 12.94312937609512],
								[77.6814599263098, 12.943526229648175],
								[77.68076431210189, 12.943695703044424],
								[77.68054580692109, 12.943749266790261],
								[77.68041650337418, 12.943923145609755],
								[77.68031838983975, 12.94413714163693],
								[77.68016671995012, 12.944114906692628],
								[77.67998394697553, 12.944056870071769],
								[77.67972533843198, 12.94413275078584],
								[77.67938198224515, 12.94426650177698],
								[77.67890486391981, 12.944413620010481],
								[77.67766072964717, 12.94473024344923],
								[77.67655488884678, 12.945153823964906],
								[77.67599746475061, 12.94534108081237],
								[77.67565418448496, 12.94552397852475],
								[77.67534646327978, 12.945693395840419],
								[77.67494071034517, 12.945813815050908],
								[77.67425842350616, 12.945996576131416],
								[77.673776827319, 12.946121479949971],
								[77.67359404389809, 12.94624184198051],
								[77.67324621286338, 12.946433572196572],
								[77.67304114844335, 12.946478197413892],
								[77.67300541284979, 12.946611956111749],
								[77.67300541284979, 12.946964197246368],
								[77.67296976461796, 12.947169357530692],
								[77.67291183621697, 12.947387855088463],
								[77.67293857240577, 12.94758849103894],
								[77.67294748446729, 12.947757924318994],
								[77.67297867667698, 12.947909536960418],
								[77.673027780362, 12.94805674459954],
								[77.67314809303653, 12.948186052006383],
								[77.67300095682144, 12.948172649666654],
								[77.6728716445486, 12.948159235540754],
								[77.67258628328786, 12.948101296018349],
								[77.67257737119756, 12.948096817961765],
								[77.67206457531645, 12.948052254503231],
								[77.67147148013048, 12.948047834254304],
								[77.67121285189931, 12.948038853658499],
								[77.67118611529806, 12.948047838768614],
								[77.67093194251817, 12.9480567473334],
								[77.67093639862894, 12.947445775191794],
								[77.67093194251817, 12.947352177758859],
								[77.67093194251817, 12.94734769391266],
								[77.67092748640721, 12.947111389737332],
								[77.67061538344964, 12.947093562612327],
								[77.6686266240839, 12.946999931982836],
								[77.66862216788115, 12.94699101034821],
								[77.66835907685096, 12.946990954705036],
								[77.66839472655185, 12.946687758567839],
								[77.66840809518676, 12.946549571852662],
								[77.66812717876452, 12.946531678824375],
								[77.66735572534236, 12.946473727330986],
								[77.66682962408757, 12.946442490066207],
								[77.66679388651022, 12.946424659702096],
								[77.66676714885321, 12.946438041688676],
								[77.66663337309423, 12.946438035980595],
								[77.66660217911569, 12.946438098842705],
								[77.66660663539886, 12.946286467674698],
								[77.66661109168189, 12.946215112938145],
								[77.6666155479647, 12.946143758242929],
								[77.66662000424735, 12.946014459900306],
								[77.66542947144852, 12.945925288174056],
								[77.66505487696887, 12.945907403579161],
								[77.66484971015862, 12.945898470142904],
								[77.66478286484326, 12.945885104012834],
								[77.66473830127751, 12.945880648216255],
								[77.66471147574995, 12.94586729589475],
								[77.66469365031442, 12.945840577278338],
								[77.6646624557954, 12.94580037282039],
								[77.6646223485439, 12.945738014136808],
								[77.66457778491426, 12.945604220956211],
								[77.6644484629082, 12.945109238821052],
								[77.66433250987444, 12.944609810145833],
								[77.66432805350074, 12.944578642945705],
								[77.66430131525482, 12.94453399569493],
								[77.66428794612945, 12.944511715189392],
								[77.6642789459984, 12.944502835546313],
								[77.66426557687036, 12.944489436116845],
								[77.66422546947669, 12.944467172418413],
								[77.66418536206866, 12.944458187206408],
								[77.66414525464626, 12.944453771857296],
								[77.66409614706374, 12.94445375534287],
								[77.66398019366513, 12.944449340213689],
								[77.66289221813598, 12.944449300356002],
								[77.66271387337572, 12.944449320778736],
								[77.66267367804511, 12.944471630304331],
								[77.66266030872544, 12.944489443768816],
								[77.66264248296338, 12.944502819486884],
								[77.66262911363997, 12.944520632951248],
								[77.6626202007568, 12.944538486762344],
								[77.66260683143075, 12.944565267481595],
								[77.66259346210309, 12.944591961980363],
								[77.66258454921709, 12.94463654512266],
								[77.66259346210309, 12.94504239498591],
								[77.66261128787295, 12.945497216148196],
								[77.66262465719848, 12.946036757583483],
								[77.66260683143075, 12.9465717938913],
								[77.66260683143075, 12.94659860989043],
								[77.66257563633039, 12.946647648018704],
								[77.66250424582992, 12.946683298584],
								[77.66240166013513, 12.94673237625305],
								[77.66181760145892, 12.946950827076405],
								[77.66126464782893, 12.947147049911669],
								[77.66056008238242, 12.947396731568135],
								[77.66011416697417, 12.947552795823738],
								[77.66003840574369, 12.947575130462704],
								[77.65996255707869, 12.947588493481886],
								[77.65986451299092, 12.947597433393772],
								[77.65923570056293, 12.94756178893683],
								[77.65843753362378, 12.94750822225738],
								[77.6574119847483, 12.947450255751404],
								[77.65646664687368, 12.947396807889685],
								[77.65607873970174, 12.947369994341793],
								[77.65597169141724, 12.947374449822753],
								[77.6557576819281, 12.947401213139026],
								[77.65531174740862, 12.947508203968324],
								[77.65502188465025, 12.947601872637916],
								[77.6549684037097, 12.947619751801247],
								[77.65492374885255, 12.947646502694223],
								[77.65486581111507, 12.947673222338315],
								[77.65477667607585, 12.94770000302131],
								[77.65460723191867, 12.947717858291814],
								[77.65418803348847, 12.947708946255084],
								[77.65330069438821, 12.947646445039936],
								[77.65218587402435, 12.947543923642822],
								[77.65129851907905, 12.947463653336484],
								[77.65063417803478, 12.947401198515726],
								[77.64998757344193, 12.947329847004385],
								[77.64982257899831, 12.94731652662122],
								[77.64964867039726, 12.947316489280114],
								[77.64955953121077, 12.947329902987587],
								[77.64953715901167, 12.947343232451185],
								[77.64951041723658, 12.947352214329163],
								[77.64947921849092, 12.947370036440976],
								[77.64944801973657, 12.947387858547682],
								[77.64941682097357, 12.947410164205579],
								[77.64939453613752, 12.947427981387571],
							],
						],
						type: "Polygon",
					},
				],
				type: "GeometryCollection",
			},
			id: "000000000000000000a5",
			properties: {
				AREA_SQ_KM: 26.584250068115157,
				ASS_CONST1: "Mahadevapura",
				ASS_CONST_: "174",
				LAT: 12.9228741800138,
				LON: 77.6802085907247,
				LOST_AREA_SQ_KM: 1.4116822669438078,
				OBJECTID: 166,
				POP_F: 9708.0,
				POP_M: 10818.0,
				POP_SC: 5838.0,
				POP_ST: 101.0,
				POP_TOTAL: 20526.0,
				RESERVATIO: "Backward Category - B",
				WARD_NAME: "Bellanduru",
				WARD_NO: 150.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.70402292691686, 12.999528088395207],
						[77.70399174337896, 12.999193665427892],
						[77.70397392421053, 12.999122328047477],
						[77.70396055983234, 12.998957337174332],
						[77.70388919577263, 12.99845345267515],
						[77.70384010565851, 12.998288481695168],
						[77.70374646751613, 12.998096708760164],
						[77.70358591984635, 12.997873787487691],
						[77.70344327855074, 12.997793555163328],
						[77.70334518525742, 12.997704321661839],
						[77.70315345323715, 12.997695421577202],
						[77.70297508538502, 12.997468026537014],
						[77.70261389397768, 12.997236125941745],
						[77.70229279514405, 12.997160309772866],
						[77.70192269177727, 12.996955245839594],
						[77.7015525871817, 12.996883903689774],
						[77.70127612165373, 12.99399433727391],
						[77.70005433671732, 12.994275274874246],
						[77.69891281567908, 12.994609730908788],
						[77.6978693811528, 12.994984323754416],
						[77.69752608076072, 12.995073514137006],
						[77.69644690516552, 12.995220623890512],
						[77.69587167252864, 12.995305370454634],
						[77.69557291727808, 12.99536330002095],
						[77.69546590679636, 12.995385619403889],
						[77.69516715045914, 12.995465892070767],
						[77.69505122929064, 12.99551496019401],
						[77.6944626239405, 12.995769100431351],
						[77.69407021864856, 12.995947469439413],
						[77.69386956028903, 12.996027684311246],
						[77.69369117758718, 12.996108014834755],
						[77.69309365460829, 12.996308668908824],
						[77.69286626307861, 12.996380013032766],
						[77.69264778157577, 12.996460237256374],
						[77.6925318577898, 12.996567276802445],
						[77.69233565192141, 12.996652016410824],
						[77.6921795430878, 12.99670994513617],
						[77.69166229451913, 12.996866035480817],
						[77.69164001809557, 12.996870491625243],
						[77.69098902041333, 12.997120171465806],
						[77.69065460949972, 12.99728966020553],
						[77.69056096028686, 12.996825884224739],
						[77.69028892285543, 12.996259633821875],
						[77.68989204753302, 12.995572853512982],
						[77.68966464948046, 12.99500658825538],
						[77.68948626005775, 12.99452945430125],
						[77.68916521079483, 12.993691118389682],
						[77.68903591722544, 12.993396893235975],
						[77.68890216811198, 12.992736947503188],
						[77.68867922344653, 12.992308849675991],
						[77.68854984195339, 12.992143849672988],
						[77.68849192162799, 12.99207249974205],
						[77.68856775094686, 12.992036874427303],
						[77.6887683314775, 12.991911976501],
						[77.68925440579831, 12.991782697214186],
						[77.68977166552526, 12.991666725782359],
						[77.69027110149766, 12.991693446419355],
						[77.69081954374323, 12.991613210226905],
						[77.6913367962176, 12.991421493397526],
						[77.69188077795128, 12.991203016383036],
						[77.6922063620244, 12.991011194665159],
						[77.6927681604815, 12.990685735026673],
						[77.69357971009295, 12.990369102293752],
						[77.69419059601977, 12.99030227816361],
						[77.69448935497205, 12.990342346139002],
						[77.69481938659227, 12.990369156570598],
						[77.69546145166387, 12.990288894682235],
						[77.69583603159342, 12.990190762635642],
						[77.69589403546257, 12.986779532095902],
						[77.69584048671094, 12.984732813666],
						[77.69563983153532, 12.982703950247405],
						[77.69557737240613, 12.982414067062935],
						[77.6955372762474, 12.982293717762294],
						[77.69553282111778, 12.98228479279934],
						[77.69544363113216, 12.98207963530988],
						[77.69733879379089, 12.981705089136232],
						[77.6997511377099, 12.980701789741623],
						[77.69984032425084, 12.980906945373443],
						[77.70040217161296, 12.98223129070845],
						[77.70109329589005, 12.982476478487298],
						[77.70044226602265, 12.98282435282005],
						[77.70068754915619, 12.983720589341313],
						[77.70064291255774, 12.983800839178778],
						[77.70029516576491, 12.983974793474909],
						[77.69982695937708, 12.984616922836734],
						[77.70004988176812, 12.984630238811052],
						[77.70233297622391, 12.984790803327437],
						[77.70254698390528, 12.984991487492113],
						[77.7042191122241, 12.987167527111188],
						[77.70581103054033, 12.987078304050955],
						[77.70573075822159, 12.988099403667098],
						[77.70580212110194, 12.988099445157195],
						[77.7061677568808, 12.988094983546496],
						[77.70708192842474, 12.98790767376578],
						[77.7073271095992, 12.987149615853166],
						[77.70801827827746, 12.985009299793512],
						[77.70808518506453, 12.984237833855756],
						[77.7083884487443, 12.983711681536247],
						[77.70941842106848, 12.983934633206681],
						[77.71033256593464, 12.98415757892619],
						[77.71116198039033, 12.984331537605867],
						[77.71154096204536, 12.984144213735473],
						[77.71200029761137, 12.984014895647764],
						[77.71288760732705, 12.983412891488028],
						[77.71334248291947, 12.983033878816789],
						[77.71341384092267, 12.982761923452502],
						[77.71388199021311, 12.982271399195488],
						[77.71439485598178, 12.982088555078041],
						[77.71496108429608, 12.982084146941027],
						[77.71539813407462, 12.981825482521552],
						[77.71530005131005, 12.981486618593268],
						[77.71482736628515, 12.981401890840836],
						[77.71435913362998, 12.979002847869634],
						[77.71416296618321, 12.977834570570957],
						[77.71675818287619, 12.977379766977654],
						[77.71876922894188, 12.977268288804149],
						[77.72112809615335, 12.977027488542054],
						[77.72542666215128, 12.976621693210287],
						[77.72699626978523, 12.976554856147205],
						[77.7299392789309, 12.976233762574328],
						[77.73330582860434, 12.975970648768287],
						[77.73401482487779, 12.975961763115626],
						[77.73460793664742, 12.97605096752038],
						[77.73488440668721, 12.976117862419926],
						[77.73513852099347, 12.976282813003158],
						[77.73550423499489, 12.976630607794437],
						[77.73619540322426, 12.977963869488606],
						[77.73708714796727, 12.977723072440627],
						[77.73892431865264, 12.977205888776515],
						[77.73992763730183, 12.976702006955296],
						[77.74116723413447, 12.976046481405326],
						[77.74121630777313, 12.976033113921023],
						[77.74152847498429, 12.97707652359148],
						[77.74178711467687, 12.97771417929665],
						[77.74189408061251, 12.978280529512208],
						[77.74200113376403, 12.978726454923235],
						[77.74213045313476, 12.979141089914018],
						[77.74374907183166, 12.982307078327706],
						[77.74387838916478, 12.982677211736195],
						[77.7438561231931, 12.982824369387854],
						[77.74373571223363, 12.98314535838704],
						[77.74358858191353, 12.983765221938729],
						[77.744725627895, 12.98634257200663],
						[77.74378033153842, 12.986596724590328],
						[77.74208583316634, 12.987006932856392],
						[77.7412074011713, 12.987238802929953],
						[77.74118068136144, 12.98722993933205],
						[77.74059205803617, 12.987399328632664],
						[77.74058760471029, 12.987314653272316],
						[77.74059651136189, 12.987261155099983],
						[77.74058760471029, 12.98721207210452],
						[77.74055634410392, 12.987131815444897],
						[77.7404047562342, 12.987006923211917],
						[77.7401996407513, 12.986949021550636],
						[77.74000788493781, 12.98689106533962],
						[77.73962437231677, 12.986730462487488],
						[77.73848736137693, 12.986338073392327],
						[77.73845164676736, 12.98514752497351],
						[77.73803695477467, 12.985129689333236],
						[77.73792998512111, 12.985071713656735],
						[77.73787200315968, 12.985049456781702],
						[77.73732353072815, 12.985058296234955],
						[77.73705597369785, 12.985076189929012],
						[77.73706933410011, 12.984924554629131],
						[77.73603926856647, 12.98492013427615],
						[77.73603926856647, 12.9849156481182],
						[77.7344607942262, 12.984902287521827],
						[77.7335154104349, 12.984929062929682],
						[77.73350650321515, 12.985285717676373],
						[77.73351095682511, 12.985504284026751],
						[77.7334886887735, 12.985580092330057],
						[77.73342625087949, 12.985620169625294],
						[77.7333906219676, 12.985624685021056],
						[77.73328356051161, 12.985629078013876],
						[77.73252102846138, 12.98566481144027],
						[77.73225800084582, 12.985696028129468],
						[77.732137577304, 12.985794079525329],
						[77.73166941753743, 12.986489744392504],
						[77.73167387122105, 12.986494183806393],
						[77.73166487652648, 12.986511958957582],
						[77.73164260810475, 12.98661011177148],
						[77.7316069786207, 12.986726004450643],
						[77.73158907654718, 12.986859823330878],
						[77.73158907654718, 12.987069379498081],
						[77.7316069786207, 12.987221036745005],
						[77.73158907654718, 12.98742611759167],
						[77.73158016917296, 12.987608937290325],
						[77.73158016917296, 12.987760519184693],
						[77.73159361756123, 12.98790325165497],
						[77.7316069786207, 12.988126214442442],
						[77.73155344704597, 12.98813513947013],
						[77.73050996915879, 12.988300091332466],
						[77.72868628266615, 12.988638983255939],
						[77.7274242772345, 12.98888426849041],
						[77.72698282084052, 12.988995751187073],
						[77.72693382824241, 12.989009118619899],
						[77.7266796078366, 12.98906257358894],
						[77.72614452918431, 12.989196338237537],
						[77.72589039430491, 12.989267741007835],
						[77.72576105602118, 12.989334651093221],
						[77.72532404695946, 12.989637867702287],
						[77.72504318651171, 12.989784951227064],
						[77.72472651899913, 12.989941025765656],
						[77.72438321406618, 12.99005700743924],
						[77.72437430611436, 12.990065900382904],
						[77.72436985213818, 12.990065946931574],
						[77.72374105371014, 12.990248727458711],
						[77.7231302424988, 12.990422614452756],
						[77.72294736528383, 12.99048955892631],
						[77.72294291125048, 12.990471660670222],
						[77.72284483513775, 12.990502875313753],
						[77.7225237946481, 12.990609907263554],
						[77.72242117677706, 12.990632191630667],
						[77.7222963758514, 12.990658939968132],
						[77.72208231879412, 12.990703519265999],
						[77.72195743015341, 12.990748121691835],
						[77.72169437753666, 12.990850669643805],
						[77.7214357783916, 12.990971115490549],
						[77.72119944916378, 12.991104873466677],
						[77.72119490772508, 12.991100429580458],
						[77.72083822953626, 12.99130557748682],
						[77.72057517427235, 12.991461613057156],
						[77.72004006624289, 12.991796030303133],
						[77.71966548035692, 12.992005585001943],
						[77.71953613483262, 12.992059118286686],
						[77.71953168066267, 12.99206805084997],
						[77.71941578484443, 12.992121535215652],
						[77.71927307648939, 12.992148290329249],
						[77.7191259137651, 12.992166119336078],
						[77.71862206555106, 12.99217504824328],
						[77.71831437552989, 12.992161668775708],
						[77.71813157773427, 12.992161664643492],
						[77.717886333015, 12.992166187916032],
						[77.71715050827525, 12.992188465417184],
						[77.71653520658212, 12.99220182290406],
						[77.71652629800174, 12.99220182935139],
						[77.71652629800174, 12.992206315522644],
						[77.7163835859405, 12.992192865516888],
						[77.71626314520296, 12.992197387144214],
						[77.71608034403754, 12.992197381276188],
						[77.71578609728822, 12.992148319251044],
						[77.71535795770778, 12.991755891606978],
						[77.71535350337057, 12.991755937948595],
						[77.71449293954406, 12.990948797539234],
						[77.71354756063168, 12.99015064471331],
						[77.71306604664908, 12.990293313694503],
						[77.71188884842586, 12.990560838233954],
						[77.71155441285707, 12.990645559158223],
						[77.71137151661577, 12.990690147660336],
						[77.71110843897394, 12.990743678195466],
						[77.71114861687445, 12.99089527389303],
						[77.71137151661577, 12.991680100959826],
						[77.71164804443643, 12.99252287938972],
						[77.71114861687445, 12.992643293018958],
						[77.71085872426282, 12.992710122825775],
						[77.71063582349502, 12.992763671419143],
						[77.71003830399283, 12.992817199373263],
						[77.70882989157667, 12.99282612576641],
						[77.70740738076779, 12.992870721901413],
						[77.70613211923704, 12.992928636940947],
						[77.70509757443314, 12.992986650486875],
						[77.70478547962388, 12.993022303340137],
						[77.70481666293968, 12.994119254783312],
						[77.70496384457851, 12.995260750841142],
						[77.70504411744976, 12.99544359825173],
						[77.70514220924764, 12.995746783812262],
						[77.70524030095918, 12.996023225845594],
						[77.70542757465486, 12.99605891360101],
						[77.70561930276256, 12.99608126919311],
						[77.70581103054033, 12.996112424451008],
						[77.70612766453075, 12.996170422205605],
						[77.70629711793806, 12.996210521181062],
						[77.70654684287625, 12.996286374134163],
						[77.70664493335119, 12.996322030047946],
						[77.70680093458756, 12.996335432051145],
						[77.70700611169298, 12.99633539412993],
						[77.7071175657647, 12.996348739389699],
						[77.70725583504195, 12.99639338806057],
						[77.70740738076779, 12.996451352802415],
						[77.70754564968533, 12.996500401349882],
						[77.70761701140317, 12.996540561169798],
						[77.70766601250732, 12.996589574774122],
						[77.70775073807324, 12.996665399750254],
						[77.7078890065646, 12.996790196529211],
						[77.70789791583611, 12.996808049199812],
						[77.70776410199683, 12.996941854560006],
						[77.70772401022126, 12.996981955526111],
						[77.70783100893655, 12.997106812609868],
						[77.70800491438436, 12.99725841737287],
						[77.70811191282985, 12.997334271117236],
						[77.70821899851809, 12.997441274055088],
						[77.7082546354943, 12.99747248144439],
						[77.70829926904399, 12.997481428288701],
						[77.70837945216661, 12.997512605829675],
						[77.708432994895, 12.997557177978177],
						[77.70847762837323, 12.997619614915381],
						[77.70850435604439, 12.99770880474812],
						[77.70851326526667, 12.997802406601336],
						[77.70849544682137, 12.997864881312228],
						[77.70847762837323, 12.997896124643352],
						[77.7084641771899, 12.997927317687598],
						[77.70845972257688, 12.997962995224544],
						[77.70845972257688, 12.998011999225191],
						[77.70847317376074, 12.998056641602329],
						[77.70848653759765, 12.99809670726732],
						[77.7084909922096, 12.998185955535657],
						[77.70849990143297, 12.99830190297036],
						[77.7085355383193, 12.998471323164033],
						[77.70857126253948, 12.998649720418328],
						[77.70754119503557, 12.998921646853633],
						[77.70606966598577, 12.999278428269292],
						[77.70538293995466, 12.999367619580342],
						[77.70449565780082, 12.99947017715525],
						[77.70434847554361, 12.999505852127404],
						[77.70432174687546, 12.999514755681657],
						[77.70432174687546, 12.999510269398002],
						[77.70402292691686, 12.999528088395207],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000a6",
			properties: {
				AREA_SQ_KM: 6.786481541302311,
				ASS_CONST1: "Mahadevapura",
				ASS_CONST_: "174",
				LAT: 12.9859006423812,
				LON: 77.7172828232259,
				LOST_AREA_SQ_KM: 0.34109690826792993,
				OBJECTID: 167,
				POP_F: 9549.0,
				POP_M: 11371.0,
				POP_SC: 3635.0,
				POP_ST: 364.0,
				POP_TOTAL: 20920.0,
				RESERVATIO: "General",
				WARD_NAME: "Garudachar Playa",
				WARD_NO: 82.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.74059205803617, 12.987399328632664],
						[77.74118068136144, 12.98722993933205],
						[77.7412074011713, 12.987238802929953],
						[77.74208583316634, 12.987006932856392],
						[77.74378033153842, 12.986596724590328],
						[77.744725627895, 12.98634257200663],
						[77.74358858191353, 12.983765221938729],
						[77.74373571223363, 12.98314535838704],
						[77.7438561231931, 12.982824369387854],
						[77.74387838916478, 12.982677211736195],
						[77.74374907183166, 12.982307078327706],
						[77.74213045313476, 12.979141089914018],
						[77.74200113376403, 12.978726454923235],
						[77.74189408061251, 12.978280529512208],
						[77.74178711467687, 12.97771417929665],
						[77.74152847498429, 12.97707652359148],
						[77.74121630777313, 12.976033113921023],
						[77.74336111892777, 12.97540887692128],
						[77.74434658475727, 12.975752218622047],
						[77.74523843862929, 12.975680819368026],
						[77.74612129180628, 12.975234930961275],
						[77.74644235139588, 12.974619571194525],
						[77.74645125757739, 12.974606191605574],
						[77.74853816573928, 12.974267279678553],
						[77.74889038928306, 12.974374335335812],
						[77.74889038928306, 12.974476907902574],
						[77.74925160497757, 12.97460173065224],
						[77.74926496391106, 12.974690962713886],
						[77.75047782612558, 12.975020929027226],
						[77.75046446733825, 12.974846980612018],
						[77.75196273697749, 12.97469091501934],
						[77.75274312138062, 12.974610652837486],
						[77.75393368158188, 12.97453934146916],
						[77.75520455293571, 12.976742129726162],
						[77.75691683607515, 12.976724313885539],
						[77.75762576872447, 12.980416391679695],
						[77.76565219356115, 12.980313882913732],
						[77.76565219356115, 12.980122090238131],
						[77.7655139969948, 12.979747563199792],
						[77.76541141888859, 12.979422007126772],
						[77.76532219768397, 12.978967241282819],
						[77.76534899897314, 12.978302813192219],
						[77.77849883113397, 12.97707652340944],
						[77.77850328293523, 12.977112194487145],
						[77.77852117742866, 12.977125639173298],
						[77.778538984629, 12.977125620420372],
						[77.77850773473631, 12.977424385570346],
						[77.77835619881843, 12.977968346411767],
						[77.77817777722933, 12.978597140660648],
						[77.77802170174778, 12.978980584301787],
						[77.77755356127112, 12.979372960846145],
						[77.77732162892217, 12.979921484970316],
						[77.77719235064959, 12.98041198010798],
						[77.77693370635916, 12.980750874854053],
						[77.77641196407659, 12.981169958534307],
						[77.77614895335553, 12.981486558454082],
						[77.77597498010512, 12.98206630854888],
						[77.77589921043527, 12.98234718234821],
						[77.77587686361029, 12.982445259100333],
						[77.77606419271432, 12.983011605202842],
						[77.77620246338701, 12.983733954732655],
						[77.77636744521328, 12.984278022657074],
						[77.77657694559005, 12.984866569927883],
						[77.77637634898737, 12.985312542270664],
						[77.77575648747136, 12.98622215855769],
						[77.77568071765171, 12.98650752779335],
						[77.77568516956651, 12.987051586415534],
						[77.7757208721709, 12.987756066406126],
						[77.77594826868368, 12.988112857291524],
						[77.77593936487507, 12.9887059041465],
						[77.77594381677946, 12.989067047648419],
						[77.77579219005271, 12.989486190985687],
						[77.77558260089559, 12.989900945408532],
						[77.77553354248487, 12.99023539271173],
						[77.77555588937929, 12.990721383682942],
						[77.7755201867216, 12.991118248477688],
						[77.77549783981996, 12.991662264308895],
						[77.77537746326816, 12.992023445802754],
						[77.77556034129913, 12.992438109673145],
						[77.7757208721709, 12.99277258827803],
						[77.77591256615274, 12.99315609214752],
						[77.77591256615274, 12.993338851576455],
						[77.7758367964401, 12.993494975816652],
						[77.77549783981996, 12.994065738476413],
						[77.77523927885598, 12.99443132564933],
						[77.77519022029284, 12.994926369748693],
						[77.77522138383101, 12.995314255176513],
						[77.77506539146637, 12.995599631938116],
						[77.7748379058438, 12.995800329330251],
						[77.77428498987267, 12.996090159533514],
						[77.7742894418437, 12.99619719839618],
						[77.77432069292941, 12.996393353543073],
						[77.77436084794276, 12.996616336776329],
						[77.77436529991073, 12.996821488847392],
						[77.77437865581358, 12.997026546311204],
						[77.77437865581358, 12.997191534902392],
						[77.77434295277557, 12.997414542003053],
						[77.77433404883764, 12.997521551948552],
						[77.77441435884005, 12.997637457977135],
						[77.77462386291947, 12.997753372074833],
						[77.7747443275853, 12.997860398608983],
						[77.77482454999644, 12.99800313766716],
						[77.77488696456332, 12.998199309030142],
						[77.77488696456332, 12.998413313461288],
						[77.77482900194575, 12.998609551025293],
						[77.77483345389486, 12.998756718336063],
						[77.77480674219748, 12.999095615093072],
						[77.77473987563259, 12.99930066409946],
						[77.7746506619543, 12.999528123787101],
						[77.77462386291946, 12.99966189962803],
						[77.77457934333582, 12.99988932503462],
						[77.77457035212362, 13.00015235141094],
						[77.77460160312988, 13.000375350903221],
						[77.77463285412732, 13.000558137988458],
						[77.77469526880246, 13.000683035314083],
						[77.77478439515157, 13.000798932455165],
						[77.77478884710249, 13.000897087512996],
						[77.77473987563259, 13.000946106593629],
						[77.7746506619543, 13.001057589737968],
						[77.77455254428368, 13.001084340679027],
						[77.77436084794276, 13.001097758687918],
						[77.77419132385242, 13.001119996099101],
						[77.7740040788675, 13.00111999077608],
						[77.77380784229115, 13.00117349191145],
						[77.77369636788865, 13.001213673911298],
						[77.7736205966566, 13.001311729095871],
						[77.77357153737496, 13.001503474065265],
						[77.77357153737496, 13.00164171729669],
						[77.77355372937411, 13.00174873919352],
						[77.77346451473841, 13.001828983453969],
						[77.77329952857853, 13.001860237522921],
						[77.77311673410117, 13.001860269918351],
						[77.77292049594948, 13.001851293005844],
						[77.7727332488118, 13.00186466177524],
						[77.77259497379885, 13.002069772600748],
						[77.77245678590792, 13.002230359781894],
						[77.77235866628985, 13.002346251566028],
						[77.77234085809361, 13.002600405747453],
						[77.77241217815642, 13.002765424001225],
						[77.77254600135696, 13.002899239268762],
						[77.77274215287868, 13.00297500533674],
						[77.77298291159155, 13.003010692306418],
						[77.7732014097085, 13.003077595941832],
						[77.77340209936827, 13.003095414445296],
						[77.77370535917196, 13.002966053226862],
						[77.7740040788675, 13.002943827122493],
						[77.77413336087, 13.002930379433357],
						[77.77437420384614, 13.002899190221996],
						[77.77447232167509, 13.00285906372289],
						[77.77462831487684, 13.002867940615834],
						[77.77478884710249, 13.002961602562083],
						[77.77490032040312, 13.00302851260197],
						[77.77507874728461, 13.003135537518022],
						[77.77523927885598, 13.003269327150106],
						[77.77534184784555, 13.003434278055115],
						[77.77548448405236, 13.003675088247554],
						[77.7755068309567, 13.003831137783232],
						[77.77554689824652, 13.004036268992646],
						[77.77553354248487, 13.004455467578712],
						[77.77552463864286, 13.004629364203845],
						[77.77552463864285, 13.0047765004865],
						[77.77552909056395, 13.00492358954633],
						[77.77560040856955, 13.005146603832236],
						[77.7756629099907, 13.00535168139477],
						[77.7757208721709, 13.00548552081913],
						[77.77577438240967, 13.005601457913233],
						[77.77577438240967, 13.005721843209692],
						[77.77572532408408, 13.005837740590634],
						[77.77564946695058, 13.005904642182394],
						[77.77558260089559, 13.005949188264916],
						[77.7755201867216, 13.00598937204026],
						[77.77542652174817, 13.006007187036204],
						[77.77524373078856, 13.00602948378018],
						[77.77514116170794, 13.006029529756697],
						[77.77504304447277, 13.006029528303566],
						[77.77490032040312, 13.006038367330843],
						[77.77482009804697, 13.006060704117822],
						[77.77472198052676, 13.006087454808206],
						[77.7746194109619, 13.006145406302926],
						[77.77455254428368, 13.00620781561518],
						[77.77449012952795, 13.006252486497116],
						[77.77438310778084, 13.00631928761471],
						[77.77433404883764, 13.00634612530285],
						[77.77424937409799, 13.006368422891],
						[77.77420921904415, 13.006368413497796],
						[77.77408884108878, 13.006355013266077],
						[77.77405313795715, 13.006355042785826],
						[77.77401298283202, 13.006341657207123],
						[77.7739818189531, 13.006297027587145],
						[77.77394166380205, 13.006243513595951],
						[77.77391486459528, 13.00620340629127],
						[77.77388370068871, 13.006167751683796],
						[77.77383018953451, 13.00614095947365],
						[77.77371871515454, 13.006087422411598],
						[77.77362950065199, 13.006074035791132],
						[77.77354037337159, 13.006078516857354],
						[77.7734511587251, 13.00610085730224],
						[77.77340655137485, 13.006118672409599],
						[77.77337538732496, 13.006167761884893],
						[77.77329062455657, 13.006314841074097],
						[77.77327281651046, 13.006417465656464],
						[77.77327726852225, 13.006524427399366],
						[77.77328617254531, 13.006845533155271],
						[77.77327281651046, 13.007064009097546],
						[77.77328172053387, 13.007202250338604],
						[77.7733084325998, 13.007318210073137],
						[77.77331742391466, 13.007376112018292],
						[77.7733619440066, 13.007505431485347],
						[77.77337538732496, 13.007665981429831],
						[77.77337538732496, 13.007759615387247],
						[77.77337093531712, 13.007835432917005],
						[77.77335303998964, 13.007920104483187],
						[77.77329952857853, 13.008067289795262],
						[77.77326836449848, 13.008120781106653],
						[77.77321040103206, 13.008214422263054],
						[77.7731389941898, 13.008374996642992],
						[77.7731300901549, 13.008468639417158],
						[77.77312118611925, 13.008544418341117],
						[77.77306322257567, 13.00891896381621],
						[77.77305877055525, 13.009244532811344],
						[77.77309892602705, 13.00933369030671],
						[77.77315243753526, 13.009405014536558],
						[77.77327726852225, 13.009494226936184],
						[77.77335303998964, 13.009578950167455],
						[77.77335749199821, 13.009614631044476],
						[77.7733485879809, 13.009699299633752],
						[77.77333077994413, 13.009743933345693],
						[77.77328617254531, 13.009864272984997],
						[77.77321485304638, 13.009940106672001],
						[77.77316579358465, 13.00999809865626],
						[77.77314344620699, 13.010078331261324],
						[77.77316579358465, 13.010145240570813],
						[77.77321485304638, 13.010203236152035],
						[77.77329952857853, 13.01025671102335],
						[77.77335749199821, 13.01029683419065],
						[77.77343780271018, 13.010319119754486],
						[77.77373652312882, 13.010390449075848],
						[77.77382128555527, 13.010448415745211],
						[77.77387034472605, 13.010510899124787],
						[77.77391486459528, 13.010559962770738],
						[77.7739327598318, 13.010640210090603],
						[77.77391041260917, 13.010778437575286],
						[77.77385244947945, 13.010854216415622],
						[77.7737588703866, 13.01095238036191],
						[77.77369636788865, 13.011041492715206],
						[77.77362504865438, 13.01123771726048],
						[77.77363840464669, 13.01136253963027],
						[77.77366520392046, 13.011451754571333],
						[77.77374987910764, 13.011585490585919],
						[77.7737811303459, 13.011853043926513],
						[77.77382128555527, 13.012067082494655],
						[77.7737766783544, 13.01223653027635],
						[77.77376332237881, 13.012348001701563],
						[77.7737766783544, 13.012477313040899],
						[77.77382573754498, 13.012535310075757],
						[77.77390596062287, 13.01260221057837],
						[77.77419132385242, 13.01296788987359],
						[77.77425827804274, 13.013123914738877],
						[77.77427163395852, 13.013168477752174],
						[77.77430288504927, 13.013172983943372],
						[77.77426273001484, 13.013226481480116],
						[77.77424483483178, 13.01330226771406],
						[77.77421812299214, 13.013369177171947],
						[77.77416906397572, 13.013427170730601],
						[77.77411110098164, 13.013458332296445],
						[77.77404423399588, 13.013476211415187],
						[77.7739148645953, 13.013467299553177],
						[77.77386589273816, 13.013444949164349],
						[77.77373207113551, 13.013378088979856],
						[77.77364285664375, 13.013364699590586],
						[77.7735804413743, 13.01336915436176],
						[77.77337983933262, 13.013391463192901],
						[77.77322375707446, 13.013449460169777],
						[77.77303642315593, 13.01357872441689],
						[77.77293393932153, 13.013743695835936],
						[77.77290268784374, 13.01383740207259],
						[77.77290713987044, 13.014055874029996],
						[77.77298745090961, 13.014372455331712],
						[77.77297400754408, 13.014515166056134],
						[77.77295174742223, 13.014702421870334],
						[77.77289823581685, 13.014889745452948],
						[77.77288042770753, 13.01517068054457],
						[77.77301861506888, 13.015389127608605],
						[77.77302751911276, 13.015505112216427],
						[77.77303197113444, 13.015540708625043],
						[77.77144904789121, 13.016107018790695],
						[77.77100759219556, 13.015589776020846],
						[77.77047691769069, 13.014880842546175],
						[77.76987491898545, 13.014247575346014],
						[77.76957173597208, 13.01395776572166],
						[77.76944690080128, 13.013832904421825],
						[77.76937557882576, 13.01369911016041],
						[77.76925065613601, 13.013342438679818],
						[77.769188238387, 13.013119487641308],
						[77.76918378621056, 13.013030299162157],
						[77.76921949091468, 13.01296787527239],
						[77.76928636081855, 13.012909867750485],
						[77.76933542200169, 13.012807344864335],
						[77.76944690080128, 13.01247287867639],
						[77.76953157921922, 13.012205316381259],
						[77.76954947516523, 13.012102779000221],
						[77.76954048354393, 13.01193777594201],
						[77.76953157921922, 13.011750511735451],
						[77.76953603138168, 13.011233266610418],
						[77.76950041407707, 13.010756146495403],
						[77.76946470946363, 13.010506423481763],
						[77.76938448316304, 13.010073873368851],
						[77.76930862167836, 13.00974837066935],
						[77.76921058656411, 13.009471954327973],
						[77.7690901158684, 13.00922217390113],
						[77.76906340278332, 13.00891454433486],
						[77.76907230714572, 13.00868265277549],
						[77.76907230714572, 13.008441881120843],
						[77.76902324584587, 13.008223372388438],
						[77.76904114187414, 13.008031606594788],
						[77.76907230714572, 13.007906748454655],
						[77.76903215021152, 13.00781310506966],
						[77.7694602572983, 13.007010525445567],
						[77.76966095374905, 13.005904594403752],
						[77.76938448316304, 13.00496377257565],
						[77.76925956048335, 13.003978298860524],
						[77.76894301922502, 13.003247010828174],
						[77.76862193756361, 13.002979427593576],
						[77.76787283069397, 13.003095443004973],
						[77.76741800398008, 13.003247016736186],
						[77.76618725621306, 13.003478892710582],
						[77.76567000283447, 13.003661667015665],
						[77.76471135351206, 13.003880235739288],
						[77.76366792574049, 13.00435287260301],
						[77.76251300245606, 13.004660558446115],
						[77.76210721809777, 13.004825497417762],
						[77.76158104068301, 13.005195611210112],
						[77.76147845893117, 13.00519565102347],
						[77.76115299041177, 13.005119851842565],
						[77.76090766613672, 13.0050306056782],
						[77.76080071855127, 13.005008346500126],
						[77.76068032600287, 13.00501280277738],
						[77.76057774341633, 13.00501724318199],
						[77.76049297084118, 13.005021754033052],
						[77.76043500068033, 13.00503953448008],
						[77.76034141023608, 13.005088584643694],
						[77.7602165646509, 13.005177827739331],
						[77.76018085704156, 13.005213494260074],
						[77.76002929600917, 13.005316050065204],
						[77.75982412953454, 13.004165584817294],
						[77.75963240766822, 13.003331771691595],
						[77.75946740087811, 13.002653956012539],
						[77.75794688229072, 13.002734196552645],
						[77.75717989252495, 13.002346250149781],
						[77.7574117798032, 13.001111079275207],
						[77.75685441136955, 13.0012492947469],
						[77.75590904383454, 13.001173519783766],
						[77.75477193794988, 13.001405386910962],
						[77.75421455623241, 13.001601580704849],
						[77.75374640231601, 13.000054330159283],
						[77.75368842862362, 12.998997483825022],
						[77.75338520127141, 12.997976352044947],
						[77.75201625870584, 12.997374359013657],
						[77.75181116469949, 12.997392213376134],
						[77.7475616432503, 12.998226032304467],
						[77.74409240421738, 12.998636324777712],
						[77.74319172200579, 12.998649700008386],
						[77.74144368783703, 12.99855602549632],
						[77.73598582645194, 12.997490284139575],
						[77.73122789068196, 12.99646472062311],
						[77.73124579281368, 12.99619267210147],
						[77.73132159302723, 12.995635297566025],
						[77.73131268563155, 12.995461452730419],
						[77.73138403210437, 12.99542576805195],
						[77.73143747643343, 12.995421326027408],
						[77.73150436913586, 12.995269643079778],
						[77.73158907654718, 12.995051166689482],
						[77.73178530058077, 12.994788134908585],
						[77.73240069253129, 12.993860629256831],
						[77.7326191833615, 12.99335224994219],
						[77.73274851366241, 12.992536274592945],
						[77.73297591107142, 12.990948834751268],
						[77.73324793155393, 12.989321264199411],
						[77.73345751349375, 12.987867601218229],
						[77.7367706021076, 12.987501904373703],
						[77.73966453991207, 12.987582181131971],
						[77.74059205803617, 12.987399328632664],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000a7",
			properties: {
				AREA_SQ_KM: 11.478970983950985,
				ASS_CONST1: "Mahadevapura",
				ASS_CONST_: "174",
				LAT: 12.9906877302874,
				LON: 77.7579088952072,
				LOST_AREA_SQ_KM: 0.7354715385705685,
				OBJECTID: 168,
				POP_F: 10149.0,
				POP_M: 12056.0,
				POP_SC: 4557.0,
				POP_ST: 172.0,
				POP_TOTAL: 22205.0,
				RESERVATIO: "General",
				WARD_NAME: "Kadugodi",
				WARD_NO: 83.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				geometries: [
					{
						coordinates: [
							[77.73418431170913, 12.949247295611045],
							[77.73418431170913, 12.94924283650999],
						],
						type: "LineString",
					},
					{
						coordinates: [
							[
								[77.77030747494105, 12.962687037959164],
								[77.77042785701398, 12.962825244811595],
								[77.7709496275438, 12.963623422624112],
								[77.7712840587234, 12.964189732053683],
								[77.77142224808016, 12.964319036789895],
								[77.77153372477589, 12.964381513468043],
								[77.77185034591344, 12.964484076128924],
								[77.77210001068714, 12.964591057688338],
								[77.77256826156051, 12.964947786845936],
								[77.7731568895519, 12.965710330097775],
								[77.7733352319536, 12.965875270036051],
								[77.7738881526759, 12.966205217615983],
								[77.77443216670224, 12.966441573735795],
								[77.77562275545044, 12.967106004418893],
								[77.77616230904282, 12.967440411180318],
								[77.77637634898737, 12.967583148073478],
								[77.77692925449465, 12.967721319608618],
								[77.77739748490772, 12.967984453849606],
								[77.77752231109967, 12.968024530172682],
								[77.77767838728673, 12.968024590590613],
								[77.77784336690917, 12.967984439149161],
								[77.77821793084104, 12.967872948173042],
								[77.77881098052839, 12.967578644950738],
								[77.7789848620233, 12.967382419247024],
								[77.77898041024145, 12.967369095272828],
								[77.77900720821965, 12.967355698506244],
								[77.77906516864542, 12.966954353018073],
								[77.7790740722021, 12.96686074013902],
								[77.77908297575806, 12.96675815523487],
								[77.77910532193458, 12.96660660178032],
								[77.77914093614406, 12.966495102223979],
								[77.77930146181339, 12.966512970682594],
								[77.779528938246, 12.966459386108179],
								[77.77974742341921, 12.966374663343881],
								[77.78018884420241, 12.966312316414609],
								[77.78058574605805, 12.966330075940995],
								[77.78079532572352, 12.966419282471808],
								[77.78104051858824, 12.966290013348575],
								[77.78107621946191, 12.96629446446718],
								[77.78119658980422, 12.966196294629306],
								[77.78158013300755, 12.966031389526819],
								[77.78181196912718, 12.96602692400155],
								[77.78230688873286, 12.966178468175],
								[77.78294460726796, 12.966414847743884],
								[77.7831452792288, 12.966512948525526],
								[77.783185343748, 12.96655306337585],
								[77.78355997684913, 12.966606602008323],
								[77.78358668642989, 12.966593200390703],
								[77.7836936992605, 12.966593176551267],
								[77.78380516357493, 12.966606562901354],
								[77.78389882144357, 12.966624455061178],
								[77.783956779302, 12.966628837974575],
								[77.78397013404519, 12.96664672445577],
								[77.78397912449229, 12.966655604657113],
								[77.78398357607264, 12.966673501019889],
								[77.78397013404519, 12.966731442917052],
								[77.78394787613898, 12.966798284797397],
								[77.7839434245572, 12.966811704939532],
								[77.78390772461056, 12.96690085869863],
								[77.78392116664561, 12.96695437949994],
								[77.78392116664561, 12.966958865619436],
								[77.7839434245572, 12.966990028116996],
								[77.78396123088324, 12.967034610790323],
								[77.7839434245572, 12.967213040308062],
								[77.78391662777682, 12.967565313695983],
								[77.78388991827589, 12.967636603910167],
								[77.78380516357493, 12.967788226035182],
								[77.78380516357493, 12.967828342572124],
								[77.78394342455721, 12.96782833183824],
								[77.78393897297525, 12.967917585355682],
								[77.783876563523, 12.968104860973227],
								[77.78385866989731, 12.968265343194586],
								[77.78392116664561, 12.96835457470584],
								[77.7839880276528, 12.968394664997426],
								[77.78402817915202, 12.968412526455442],
								[77.78405043704204, 12.968434803350826],
								[77.7840459854644, 12.968465995831671],
								[77.78397467291178, 12.968733602122676],
								[77.78394787613898, 12.96888072273081],
								[77.78393006981078, 12.968992207053839],
								[77.78387211193835, 12.969072457482122],
								[77.783876563523, 12.969103640295705],
								[77.78388546669177, 12.969157206090165],
								[77.78384531514065, 12.969201806157475],
								[77.78371150562683, 12.969509418567258],
								[77.78364909604599, 12.96965657907037],
								[77.78358223483355, 12.969812673883164],
								[77.78357333164033, 12.96999549741162],
								[77.78358223483355, 12.97007132221703],
								[77.78357333164033, 12.970115849234219],
								[77.78355997684912, 12.97014265188808],
								[77.7835465347701, 12.970169364137933],
								[77.78351092198137, 12.97017388943415],
								[77.78348857669631, 12.970164980374786],
								[77.78346186709183, 12.970147064722937],
								[77.78344397339893, 12.970124821491286],
								[77.78339491847963, 12.97012034167739],
								[77.78334586353856, 12.970147092935079],
								[77.78332805711327, 12.970169371070238],
								[77.78331025068509, 12.970200621675037],
								[77.78325674409692, 12.970249679556565],
								[77.7831452792288, 12.97036112947139],
								[77.78306942719078, 12.970428023095348],
								[77.78305607233865, 12.97049934306],
								[77.78298467185996, 12.970646509734893],
								[77.78298022023934, 12.9706688164277],
								[77.78298467185996, 12.970686627146279],
								[77.7830025656273, 12.9706910980704],
								[77.78303372696152, 12.970691107151337],
								[77.7831452792288, 12.970722358513125],
								[77.78323885037076, 12.970780279765863],
								[77.78325229248728, 12.970798171182345],
								[77.78325229248728, 12.970815943662394],
								[77.78325229248728, 12.97082931616129],
								[77.78324784087745, 12.970833764150198],
								[77.78322994714986, 12.97084715198753],
								[77.783198785871, 12.970878374181027],
								[77.78317198890988, 12.970905144250748],
								[77.78316753729682, 12.970905192253252],
								[77.78315418245656, 12.970905163711771],
								[77.78312738548459, 12.970905188766844],
								[77.7831051274085, 12.970905169951253],
								[77.7830694271908, 12.970900718468906],
								[77.78301146886655, 12.970896248157347],
								[77.78292226186826, 12.970896250843216],
								[77.78282415154429, 12.970891776955217],
								[77.78241844173445, 12.970869456566211],
								[77.78219097125341, 12.970856095011635],
								[77.78186992808102, 12.970833815145173],
								[77.78176291348282, 12.97082486425834],
								[77.78171385781668, 12.970820383960865],
								[77.78162918873309, 12.970811542376874],
								[77.78151318325442, 12.970784738260482],
								[77.78137055483143, 12.970744594812414],
								[77.78125900077124, 12.970700056566995],
								[77.78112527541131, 12.97063764482965],
								[77.78101826009149, 12.970543973162608],
								[77.7809245997795, 12.970423585513862],
								[77.7809245997795, 12.970414613091783],
								[77.78079532572352, 12.970200571590428],
								[77.78068831008397, 12.970093614301126],
								[77.78062135979019, 12.970044545959764],
								[77.78053668986901, 12.970013359556182],
								[77.78040732807027, 12.969999985299562],
								[77.78030031205475, 12.970022264830813],
								[77.78009073144995, 12.97008023431592],
								[77.7797385199168, 12.970138176572258],
								[77.77948433334929, 12.970138213997977],
								[77.77934606678396, 12.970164965491694],
								[77.77926584765567, 12.970169361329654],
								[77.77917218585763, 12.970169410613902],
								[77.77913648436851, 12.970169358554012],
								[77.77900275643871, 12.970138177083292],
								[77.7789046426327, 12.970111443425312],
								[77.77886448927059, 12.970111439252724],
								[77.77882887497199, 12.970111391284112],
								[77.77878426979258, 12.970124807260554],
								[77.77875747176029, 12.970142603982074],
								[77.77871731834468, 12.970187202677996],
								[77.77847657212502, 12.970503805992474],
								[77.77844087041107, 12.970597445982502],
								[77.77842306319492, 12.97066432649285],
								[77.77840970778091, 12.970749103883097],
								[77.77842751499922, 12.970851663304936],
								[77.77856124362539, 12.971186095594968],
								[77.77878426979258, 12.971681001256886],
								[77.77896260311228, 12.972104686434669],
								[77.77899830465759, 12.97234992976482],
								[77.77900275643871, 12.97241674439463],
								[77.77896705489484, 12.972537217247812],
								[77.77889573906236, 12.972644183155792],
								[77.77883332675994, 12.972697735711055],
								[77.77877981800263, 12.972746792479104],
								[77.77869951117307, 12.972791387328561],
								[77.77865935773642, 12.97280915533166],
								[77.77852562922902, 12.972822575728493],
								[77.77840970778091, 12.972822604531254],
								[77.77830268983003, 12.97282253751672],
								[77.77820457540224, 12.972840406037506],
								[77.77808420179929, 12.972885000098572],
								[77.77802615356825, 12.972920641553303],
								[77.77800389446405, 12.972956339677065],
								[77.77794148165776, 12.973063382067187],
								[77.77791023159605, 12.973103485527242],
								[77.7779057797709, 12.973134678482573],
								[77.77789242429436, 12.973259575062436],
								[77.77788797246849, 12.973299654339693],
								[77.77787016516321, 12.973344277223855],
								[77.7778255595967, 12.973415583198863],
								[77.77773189657752, 12.973495866414813],
								[77.77761597429655, 12.97353152601392],
								[77.77758472414277, 12.973535998044449],
								[77.77753566662095, 12.973536002649148],
								[77.77746434986445, 12.97354047396136],
								[77.77742419597884, 12.973558327969519],
								[77.77734842730275, 12.973562760814282],
								[77.77724586017577, 12.973562731471118],
								[77.77716555223114, 12.973585066807226],
								[77.77712548552825, 12.973616297452331],
								[77.77707642780251, 12.973674278510703],
								[77.77703627377593, 12.973709905016364],
								[77.77696050483384, 12.973754541698797],
								[77.77690254330388, 12.973825904722066],
								[77.7767598216359, 12.974066663032488],
								[77.7767107637478, 12.974124644100593],
								[77.77669740812684, 12.974151360260656],
								[77.77669740812684, 12.9741647328752],
								[77.77670631187432, 12.974169209791322],
								[77.77671521562112, 12.974182573027536],
								[77.77671521562112, 12.974195945646334],
								[77.7766929562528, 12.974213784876957],
								[77.77667506146325, 12.974222685819324],
								[77.77666170583795, 12.974227143047276],
								[77.77661709978356, 12.974285162582111],
								[77.77654578243416, 12.97437435552121],
								[77.77647891692153, 12.974414496400641],
								[77.77619792420101, 12.97446795494076],
								[77.77619792420101, 12.974472441242415],
								[77.77618902041253, 12.974472450594355],
								[77.77609989519624, 12.974485826308307],
								[77.77603739402225, 12.974525915066357],
								[77.77602849022084, 12.974534897013667],
								[77.77599287500804, 12.97460619757811],
								[77.7759660762987, 12.974664198321696],
								[77.77595272058768, 12.97473103241952],
								[77.775970528202, 12.974806849641626],
								[77.77603739402225, 12.974989644269892],
								[77.77609090410775, 12.97506991482006],
								[77.77615785714724, 12.975248314020568],
								[77.7762069152808, 12.975324146256225],
								[77.77632729092905, 12.975440026268105],
								[77.77640306030536, 12.975515830659297],
								[77.77650117633297, 12.975640750062304],
								[77.77653242679322, 12.975676396651897],
								[77.77653687867371, 12.975725439902297],
								[77.77652343573992, 12.975761124908628],
								[77.77648336880418, 12.975810128563408],
								[77.77645657021378, 12.975850270791998],
								[77.7764387626778, 12.975890407838317],
								[77.77643431079336, 12.975926087686338],
								[77.77643431079336, 12.975952833298374],
								[77.77647891692153, 12.976037600344336],
								[77.77655913807347, 12.976117843806755],
								[77.77662600353754, 12.97617579886979],
								[77.77672411936719, 12.97631849211133],
								[77.77673747498494, 12.976327407744824],
								[77.77676872537837, 12.97635416811202],
								[77.77678653286117, 12.976363122223548],
								[77.77681333137156, 12.976389844123945],
								[77.77682223510972, 12.976416580545925],
								[77.77682668697852, 12.976461224041442],
								[77.77684449445198, 12.976519183317958],
								[77.7768712929483, 12.976541418874206],
								[77.77689355228068, 12.97655929798409],
								[77.7770140144723, 12.976590546088648],
								[77.77707197594377, 12.976594932836147],
								[77.77710313895125, 12.976590543195304],
								[77.77713884109748, 12.97656825058062],
								[77.77717000408613, 12.976528056055125],
								[77.77721015806411, 12.97647456970777],
								[77.77732608077086, 12.976291722432356],
								[77.77736178284506, 12.976265029681887],
								[77.77745099433454, 12.976211452898218],
								[77.77756691678862, 12.976184766295175],
								[77.77767838728673, 12.976193673425346],
								[77.77768283912106, 12.97620259837839],
								[77.77774525207344, 12.97619365044148],
								[77.77789687612004, 12.97624268127027],
								[77.77803514449927, 12.976300655780362],
								[77.77809755725265, 12.976354172204507],
								[77.77816442178568, 12.97645224602201],
								[77.77829369892079, 12.976621695867856],
								[77.77843196680335, 12.976884837961416],
								[77.77848547572914, 12.976982883252008],
								[77.77848547572914, 12.9769873696801],
								[77.77849883113397, 12.97707652340944],
								[77.76534899897314, 12.978302813192219],
								[77.76532219768397, 12.978967241282819],
								[77.76541141888859, 12.979422007126772],
								[77.7655139969948, 12.979747563199792],
								[77.76565219356115, 12.980122090238131],
								[77.76565219356115, 12.980313882913732],
								[77.75762576872447, 12.980416391679695],
								[77.75691683607515, 12.976724313885539],
								[77.75520455293571, 12.976742129726162],
								[77.75393368158188, 12.97453934146916],
								[77.75274312138062, 12.974610652837486],
								[77.75196273697749, 12.97469091501934],
								[77.75046446733825, 12.974846980612018],
								[77.75047782612558, 12.975020929027226],
								[77.74926496391106, 12.974690962713886],
								[77.74925160497757, 12.97460173065224],
								[77.74889038928306, 12.974476907902574],
								[77.74889038928306, 12.974374335335812],
								[77.74853816573928, 12.974267279678553],
								[77.74645125757739, 12.974606191605574],
								[77.74644235139588, 12.974619571194525],
								[77.74612129180628, 12.975234930961275],
								[77.74523843862929, 12.975680819368026],
								[77.74434658475727, 12.975752218622047],
								[77.74336111892777, 12.97540887692128],
								[77.74121630777313, 12.976033113921023],
								[77.74116723413447, 12.976046481405326],
								[77.73992763730183, 12.976702006955296],
								[77.73892431865264, 12.977205888776515],
								[77.73708714796727, 12.977723072440627],
								[77.73619540322426, 12.977963869488606],
								[77.73550423499489, 12.976630607794437],
								[77.73513852099347, 12.976282813003158],
								[77.73488440668721, 12.976117862419926],
								[77.73460793664742, 12.97605096752038],
								[77.73401482487779, 12.975961763115626],
								[77.73330582860434, 12.975970648768287],
								[77.7299392789309, 12.976233762574328],
								[77.72699626978523, 12.976554856147205],
								[77.72696945922499, 12.976412107366183],
								[77.72687584054043, 12.97522601716206],
								[77.72686693278835, 12.97462848358387],
								[77.72682676056422, 12.973794660369158],
								[77.72680449117286, 12.973130249615107],
								[77.72673759564096, 12.972452418824293],
								[77.72665288453103, 12.971368925888722],
								[77.72662616121906, 12.971368861879862],
								[77.7265904427953, 12.971074568957402],
								[77.72555591349214, 12.97121286698499],
								[77.72430295514113, 12.968033456544854],
								[77.72785237036557, 12.967493978687209],
								[77.72776766010534, 12.967016828477096],
								[77.72772303436498, 12.966709097595155],
								[77.72766068056075, 12.966379132741636],
								[77.72760269323803, 12.966200756463145],
								[77.72761605477741, 12.966196302867111],
								[77.7275937855442, 12.966111574992516],
								[77.72758042400213, 12.966000100155169],
								[77.7275580674314, 12.965964448053844],
								[77.72745554153775, 12.965576508510502],
								[77.72745108768504, 12.965473997235451],
								[77.727397554108, 12.965117199024258],
								[77.72741536952638, 12.965032480881769],
								[77.72735746940617, 12.964635626540266],
								[77.7272861203478, 12.963904328195008],
								[77.72721031738122, 12.963199820402915],
								[77.72718350687262, 12.963195354012742],
								[77.72709879610255, 12.962655775355922],
								[77.72709879610255, 12.962316885228635],
								[77.72705417009361, 12.961893329793726],
								[77.72696055148042, 12.961755099635688],
								[77.72685802503557, 12.961639130597757],
								[77.72665733841575, 12.961429567499358],
								[77.72637194010954, 12.961300245292124],
								[77.72604645589591, 12.961264584479961],
								[77.72585022172784, 12.961273452007807],
								[77.72576105602118, 12.96125567016266],
								[77.72575660210057, 12.961255630862395],
								[77.72523933477228, 12.961184283210878],
								[77.72523488083075, 12.961179845044349],
								[77.7251903414057, 12.961202136240557],
								[77.7251189909562, 12.961215562188686],
								[77.72460617462792, 12.961197728767724],
								[77.72457490952323, 12.961188737907799],
								[77.72456600158675, 12.961175376465608],
								[77.72465970954934, 12.960867687762128],
								[77.72458390479169, 12.960854336206058],
								[77.72430740911999, 12.960805305812821],
								[77.72416470704188, 12.960769624752078],
								[77.72404881605175, 12.960667080147532],
								[77.7241736150105, 12.957666069843883],
								[77.72399973482734, 12.95583787196057],
								[77.72370096768977, 12.953960557966473],
								[77.72373214570686, 12.953046451799498],
								[77.72390611364173, 12.95207436947954],
								[77.72417806899453, 12.951200353810188],
								[77.72447683485005, 12.950049911861267],
								[77.72472206503707, 12.948721085663628],
								[77.72493166305875, 12.947771337973135],
								[77.72502082943066, 12.947459152205695],
								[77.7250565483588, 12.946821539154781],
								[77.72511453700986, 12.94653616148682],
								[77.72546229361701, 12.946946374870132],
								[77.72547120148165, 12.947124801085103],
								[77.72634076283282, 12.947240709829684],
								[77.72765168554129, 12.947227346586573],
								[77.7285925780983, 12.947807001626884],
								[77.73031828393866, 12.947949678102715],
								[77.73138403210437, 12.947940781064908],
								[77.73201715363165, 12.947784730431144],
								[77.7323783369305, 12.947753497552892],
								[77.73265035887658, 12.947775814215134],
								[77.73263699794262, 12.947922916197435],
								[77.73260136877754, 12.947922949347246],
								[77.73253893038468, 12.948573976707925],
								[77.73274851366241, 12.948574010390159],
								[77.73274406002187, 12.948810282381736],
								[77.73301599374618, 12.948841478775167],
								[77.73302944196061, 12.949278518185222],
								[77.73312750908543, 12.94940340103116],
								[77.7335244049796, 12.949519273613292],
								[77.7335244049796, 12.94953264169623],
								[77.7335244049796, 12.949554979334986],
								[77.73360465724434, 12.949572848056192],
								[77.73363137887783, 12.949572823204338],
								[77.7339123049428, 12.949572794263142],
								[77.73398364974572, 12.9495817015993],
								[77.73418432313171, 12.949247281685508],
								[77.73418432313171, 12.949278502619716],
								[77.73418432313171, 12.94930972356222],
								[77.73417986954877, 12.949327537459196],
								[77.73417541596562, 12.94935432091486],
								[77.7341887767145, 12.949367719659545],
								[77.73421549820745, 12.949398915689112],
								[77.73418432313171, 12.949447975515032],
								[77.73418432313171, 12.949474711719313],
								[77.73421104462574, 12.949505907786007],
								[77.73424676059952, 12.9495193330463],
								[77.73428684281559, 12.949519338766308],
								[77.7343314659193, 12.949523786132053],
								[77.73435373380191, 12.949523722222281],
								[77.73442062474794, 12.949528191905701],
								[77.73448306208287, 12.949514855943466],
								[77.73456331365482, 12.949541607684788],
								[77.73471936306339, 12.949617409908342],
								[77.73480415534732, 12.949666456234183],
								[77.73487995313232, 12.949715506758281],
								[77.73491112801275, 12.949751140403183],
								[77.73502709499812, 12.949840391502255],
								[77.73507617135154, 12.949876012668087],
								[77.73513406744877, 12.949889369793484],
								[77.73520541146674, 12.949902761669863],
								[77.73525448774134, 12.949938469089442],
								[77.73526339482089, 12.949974166784449],
								[77.7352857498409, 12.949983076590259],
								[77.73530356399425, 12.950018679715182],
								[77.73539717557668, 12.95015249410093],
								[77.73541053617855, 12.95016589294349],
								[77.73544179823418, 12.950165911017141],
								[77.7354685194256, 12.950161401161957],
								[77.7355220491132, 12.950165840068113],
								[77.73556213086893, 12.950192582111377],
								[77.73560229993421, 12.950223813136589],
								[77.73577624928403, 12.950357647203228],
								[77.73582078446532, 12.9503843419879],
								[77.7358743139831, 12.950415603658277],
								[77.73590994209809, 12.950415570242704],
								[77.73594565752542, 12.950406657528841],
								[77.73596792508431, 12.95041107835632],
								[77.73599473347282, 12.95045124853207],
								[77.73604372207484, 12.950486951961494],
								[77.7360838909659, 12.950509213360611],
								[77.73609725148515, 12.950527097093051],
								[77.73611951901363, 12.950540401372185],
								[77.73614632736553, 12.950553834988655],
								[77.73617750189041, 12.950553848848124],
								[77.73622212423429, 12.950553811180384],
								[77.73626229306079, 12.950571673973526],
								[77.73629346755308, 12.950589454739097],
								[77.73633363635379, 12.950616200996615],
								[77.73636926432138, 12.950620738631297],
								[77.73640052610705, 12.950616271754395],
								[77.73651195072404, 12.950683142282326],
								[77.7366190090673, 12.9507455794286],
								[77.73663682300673, 12.950745562678614],
								[77.7366546369433, 12.950754429410095],
								[77.736659090427, 12.950767836695357],
								[77.73666354391052, 12.950772274249815],
								[77.736681445166, 12.950803483217221],
								[77.73672152650316, 12.950830311611208],
								[77.73675724166938, 12.950843650609938],
								[77.7367795090655, 12.950861526024013],
								[77.73680622993494, 12.95089272249753],
								[77.73681522421309, 12.95090608663624],
								[77.73683749159757, 12.950959582318005],
								[77.73688202635314, 12.950981878560118],
								[77.73690438104724, 12.950977420003651],
								[77.73692664841386, 12.950981927053837],
								[77.73694891577598, 12.951017569510768],
								[77.7369757239276, 12.951044285298337],
								[77.73699799127989, 12.951048792349287],
								[77.73702025862772, 12.951053213151022],
								[77.73704706676212, 12.951057677028956],
								[77.73706042716546, 12.951071075977028],
								[77.73707378756717, 12.951075505160071],
								[77.73710059568864, 12.951075570398903],
								[77.73712286301588, 12.951062137907222],
								[77.73713176994552, 12.951066614403974],
								[77.73718975229362, 12.95104435522627],
								[77.73726109499057, 12.951044292241372],
								[77.7373190772711, 12.951057653359241],
								[77.73735915837804, 12.951075512002229],
								[77.7373859664306, 12.95109334426169],
								[77.73740378024694, 12.951097812356368],
								[77.73742604751338, 12.951075496292114],
								[77.73744394864589, 12.951066600111336],
								[77.73747512280637, 12.951071098722846],
								[77.73752419807765, 12.951124573473104],
								[77.73759554055968, 12.951227145473087],
								[77.73760890089697, 12.951258311528294],
								[77.73761335434239, 12.95126283534925],
								[77.73773822538615, 12.951454541838782],
								[77.73781402116798, 12.951557153117305],
								[77.73792107825551, 12.95173102356941],
								[77.73794334541817, 12.951748812873738],
								[77.73792998512111, 12.951896008583287],
								[77.73799687391248, 12.952078840029769],
								[77.73804586163098, 12.952078836975982],
								[77.7381127503525, 12.952110043313375],
								[77.73813510479876, 12.952123438147941],
								[77.73816627876512, 12.952123451872012],
								[77.73822426057282, 12.952141211788183],
								[77.73824652767466, 12.952154602476021],
								[77.73827778893165, 12.952181357481887],
								[77.73829114918685, 12.952221493737218],
								[77.7382956026049, 12.95224378488154],
								[77.73832232310939, 12.952266098148042],
								[77.73836694459112, 12.952288398756371],
								[77.73838030483557, 12.952310681513572],
								[77.73839366507843, 12.952315196979274],
								[77.7384160194681, 12.952297283442794],
								[77.73842937970666, 12.952283945362874],
								[77.73845164676736, 12.95228396746839],
								[77.73847400114535, 12.952288392416406],
								[77.73851408183525, 12.952310736195304],
								[77.73854088960887, 12.952310715109505],
								[77.7385542498324, 12.952306260666925],
								[77.73857651686802, 12.952288429209796],
								[77.7385987838992, 12.952288365051633],
								[77.73862113824755, 12.95229736118676],
								[77.73865676548097, 12.952306211179232],
								[77.738674666415, 12.952315168396222],
								[77.73868802662241, 12.952306228991654],
								[77.73870584022981, 12.952279474944746],
								[77.73873256063554, 12.952248227529047],
								[77.73874600815756, 12.952230408616105],
								[77.73878608874924, 12.952221530189023],
								[77.73881734985427, 12.95222154797503],
								[77.73889759829308, 12.952194739076145],
								[77.73893767883, 12.952181375665027],
								[77.7389600331101, 12.952163548304174],
								[77.73901801450315, 12.95216354075866],
								[77.73907590854459, 12.952132306892596],
								[77.73910271618251, 12.952118917120705],
								[77.73912498310811, 12.952105570544417],
								[77.7391294364927, 12.95207878599927],
								[77.73915179073417, 12.95206544356619],
								[77.73917405764993, 12.952065465583674],
								[77.73919632456122, 12.952069886299597],
								[77.73921867878921, 12.95211450322044],
								[77.7392275855507, 12.952127863383982],
								[77.7392587592102, 12.95215909912913],
								[77.739276572726, 12.952185819470575],
								[77.73933010057732, 12.952337485304618],
								[77.7393613615291, 12.952377608943218],
								[77.73939253515104, 12.952413243487817],
								[77.73941480201849, 12.952431119119666],
								[77.73944160957456, 12.952448951552903],
								[77.73949513734627, 12.95252471826673],
								[77.7395307642991, 12.952551508071284],
								[77.7395709319283, 12.952578254679448],
								[77.73964672645855, 12.952640674820161],
								[77.73970470749283, 12.952676374509936],
								[77.73979831536377, 12.952689701744712],
								[77.73990091718385, 12.952725363307731],
								[77.73996780478737, 12.952774423252032],
								[77.74000343158848, 12.952796728112979],
								[77.74002569833334, 12.952810118810266],
								[77.74004805239429, 12.952814543673862],
								[77.74007922582334, 12.952814557190777],
								[77.74009703920743, 12.952810141529467],
								[77.7401060332189, 12.952819020944533],
								[77.74015056666299, 12.952863656056289],
								[77.74017737404132, 12.952877003545549],
								[77.74020409409276, 12.952881463151053],
								[77.7402487148179, 12.952885909969778],
								[77.74027988819056, 12.952890408460508],
								[77.74027543485215, 12.952903738306446],
								[77.74030215487997, 12.952921566645426],
								[77.7403200555486, 12.952930523852359],
								[77.74034232222995, 12.952934944557548],
								[77.74036904224165, 12.952935005403267],
								[77.7403869428995, 12.952934992610201],
								[77.74042256956604, 12.952939443729322],
								[77.74046273687242, 12.952939452877455],
								[77.74049391018497, 12.952939466347132],
								[77.74057869805799, 12.952952806002026],
								[77.74061432466294, 12.95295277209242],
								[77.74064558525298, 12.952952789686599],
								[77.74067675851416, 12.952952803131526],
								[77.7407080190866, 12.952957305720775],
								[77.74073473901025, 12.952948310265205],
								[77.74075700560837, 12.952930478415201],
								[77.74077490620364, 12.952921581832719],
								[77.74081053274561, 12.952917149133947],
								[77.74087296648642, 12.952921578865213],
								[77.74092649356771, 12.952930502078136],
								[77.74098002062317, 12.952952794053292],
								[77.74104236694886, 12.952966189619374],
								[77.741064720805, 12.952988468197837],
								[77.74108698733691, 12.95300634387567],
								[77.74110925386435, 12.953015249532653],
								[77.74114051431495, 12.953010782050127],
								[77.7412074011713, 12.953001881799768],
								[77.74126092809124, 12.953010804959003],
								[77.741341174763, 12.953028586372653],
								[77.74137680112314, 12.953046406161349],
								[77.74143032796137, 12.953046445509889],
								[77.74145268173935, 12.953055355291438],
								[77.74145713503052, 12.953050909144006],
								[77.74152847498429, 12.953050931449027],
								[77.74159090832113, 12.95305087599586],
								[77.74165334162282, 12.953046421754989],
								[77.74170232772725, 12.953042019255987],
								[77.74177366752336, 12.953059809066458],
								[77.74183610072224, 12.953108916309438],
								[77.74188962733888, 12.95314457701608],
								[77.7419431539297, 12.953166868895606],
								[77.74201449357096, 12.95320251255842],
								[77.74207247336801, 12.953233727111444],
								[77.7421170933418, 12.953251542486774],
								[77.742170532504, 12.953273830219166],
								[77.74222405895931, 12.953305092169424],
								[77.74226867887212, 12.95334076144921],
								[77.74229985167729, 12.953367512442156],
								[77.74234447155962, 12.953403181741955],
								[77.74237119108918, 12.953407641174536],
								[77.74241581094282, 12.953425456554728],
								[77.74243362395178, 12.953443293407716],
								[77.74250496327767, 12.953492392251876],
								[77.7425629428184, 12.953532490699391],
								[77.74263873530484, 12.953581542179622],
								[77.74266554208201, 12.953603859713663],
								[77.74267890180893, 12.953635026660612],
								[77.74270562125794, 12.953670708985424],
								[77.74273688125875, 12.953701949261328],
								[77.74276360069379, 12.95374203042142],
								[77.74286619977184, 12.95387584550964],
								[77.74293308558781, 12.953911536737039],
								[77.74297761789721, 12.95392934803267],
								[77.74300887782137, 12.953951618241977],
								[77.74307131032509, 12.954036347712204],
								[77.7430980296796, 12.95406314627953],
								[77.74310248290472, 12.954085438022258],
								[77.74320062844834, 12.954152283472375],
								[77.74317827502256, 12.954232557842017],
								[77.7431337427937, 12.95431281463356],
								[77.74316046213316, 12.95435746739258],
								[77.74321398811081, 12.954393128497362],
								[77.74324524796837, 12.954459991012163],
								[77.74335221249811, 12.954843455265506],
								[77.74339683195717, 12.954883610223325],
								[77.74343245765719, 12.954914799361223],
								[77.74349934313172, 12.955003967416642],
								[77.74358858191353, 12.955276016684596],
								[77.7436331139598, 12.955356275134587],
								[77.74370899303273, 12.95560155666998],
								[77.7437758783405, 12.955659501836019],
								[77.74383376989942, 12.955699597072824],
								[77.74384276360796, 12.955735301388355],
								[77.74385166999824, 12.955757546111338],
								[77.7438605763878, 12.955806615514781],
								[77.7438605763878, 12.955846723159866],
								[77.74389620193887, 12.955931475778806],
								[77.74395418075245, 12.955976060445696],
								[77.74402551910045, 12.956020675454312],
								[77.74403896598098, 12.95606970174395],
								[77.74404787235551, 12.956145509820548],
								[77.74423516800695, 12.956479920138232],
								[77.74429751250328, 12.956520054592048],
								[77.74433322523119, 12.95652899498101],
								[77.7443689379476, 12.956569159193487],
								[77.74442237602503, 12.956640526058774],
								[77.74446254188325, 12.956640448667388],
								[77.7445249735688, 12.956635994107783],
								[77.74457404572215, 12.956635994410183],
								[77.7446721026468, 12.95664047603555],
								[77.74467210264679, 12.956644961233621],
								[77.74467655580872, 12.956653884231166],
								[77.74473008105478, 12.956658322301411],
								[77.74484603777982, 12.956702898965071],
								[77.74501543217531, 12.95680995843559],
								[77.74503778523085, 12.956845607238813],
								[77.74511802917047, 12.956908068601635],
								[77.74539892616012, 12.957184476140947],
								[77.74545245105755, 12.957237992920339],
								[77.74548371028422, 12.957246894437288],
								[77.74550152280051, 12.957237993103815],
								[77.74550597592913, 12.95721569204631],
								[77.74550152280051, 12.95715777648284],
								[77.74550152280051, 12.957113182965585],
								[77.74551042905759, 12.95702838638313],
								[77.74666535498041, 12.958749649811232],
								[77.74773548653656, 12.958428589043002],
								[77.74820820617873, 12.958361661356625],
								[77.748052088426, 12.955886927421762],
								[77.74806553481827, 12.9558735059936],
								[77.74832861228194, 12.955820003711269],
								[77.74839104179087, 12.955811062658826],
								[77.74870310149443, 12.955797749484336],
								[77.74905095884152, 12.955811061733554],
								[77.74921144085408, 12.955793230405364],
								[77.74948350884945, 12.955806655343077],
								[77.74950132072316, 12.955811123048491],
								[77.74977330069416, 12.956765325451576],
								[77.75043774975877, 12.956974879675816],
								[77.7532603478446, 12.957844455665473],
								[77.75577083510456, 12.958611407060038],
								[77.75606515051861, 12.958758547794714],
								[77.7565243892643, 12.958945856918753],
								[77.75677854129536, 12.959084106144108],
								[77.75686776938643, 12.95911525036303],
								[77.75687222205836, 12.95911977446188],
								[77.75692128874512, 12.95905283555532],
								[77.75696581543491, 12.959026136703573],
								[77.75702832738489, 12.9590394522163],
								[77.75708621202219, 12.959106373782205],
								[77.75715317656484, 12.95915095363738],
								[77.75723786439002, 12.95917325658838],
								[77.7574117798032, 12.959182193991468],
								[77.75750092007728, 12.959226790331472],
								[77.75755443912999, 12.959271336413638],
								[77.75758569494326, 12.959324832830221],
								[77.75760350551447, 12.959400636629917],
								[77.75762576872447, 12.959409628221792],
								[77.75901707950854, 12.959949168174346],
								[77.75905715277175, 12.959962540915939],
								[77.76566555051642, 12.962517605281468],
								[77.7676365130935, 12.963485224580413],
								[77.76794415363858, 12.963048209321093],
								[77.77030747494105, 12.962687037959164],
							],
						],
						type: "Polygon",
					},
				],
				type: "GeometryCollection",
			},
			id: "000000000000000000a8",
			properties: {
				AREA_SQ_KM: 12.80355769672962,
				ASS_CONST1: "Mahadevapura",
				ASS_CONST_: "174",
				LAT: 12.9660702018756,
				LON: 77.7483647412138,
				LOST_AREA_SQ_KM: 0.8729834634961297,
				OBJECTID: 169,
				POP_F: 12392.0,
				POP_M: 13523.0,
				POP_SC: 3664.0,
				POP_ST: 211.0,
				POP_TOTAL: 25915.0,
				RESERVATIO: "Backward Category - B",
				WARD_NAME: "Hagadur",
				WARD_NO: 84.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.68896454361658, 12.962241080950282],
						[77.68896454361658, 12.962218830146359],
						[77.68953981185857, 12.96211181902098],
						[77.68969138166126, 12.961545531109756],
						[77.68979394232167, 12.961188806266348],
						[77.68977166552526, 12.961095118894123],
						[77.68990095824252, 12.955561348564498],
						[77.69400339080661, 12.956212434579566],
						[77.69403457713784, 12.956221337722035],
						[77.69381609792251, 12.957367325550813],
						[77.6940390323273, 12.957612528793689],
						[77.69427978700713, 12.957853321406855],
						[77.6944002515086, 12.9580049847228],
						[77.69456963538457, 12.958036171809066],
						[77.69494867346164, 12.95804958257076],
						[77.6953990797904, 12.958103047430734],
						[77.69563983153532, 12.958201196193857],
						[77.69591631103685, 12.958285869211858],
						[77.69596977239695, 12.958361723406465],
						[77.69596531728457, 12.95882989453975],
						[77.69589403546257, 12.95890126932638],
						[77.69561755590584, 12.958941388519865],
						[77.69554618650615, 12.958954717620022],
						[77.69498876983191, 12.958968162902677],
						[77.6949887698319, 12.960091823158182],
						[77.69338350606694, 12.959855481329411],
						[77.69323639649969, 12.960970232569506],
						[77.69435561239379, 12.961162020110299],
						[77.69555509676417, 12.961621280685426],
						[77.69720051221161, 12.962571061672822],
						[77.6977490077514, 12.962887686523562],
						[77.69801211693355, 12.96299027052926],
						[77.69848924096527, 12.963110646369076],
						[77.6994657577699, 12.963315716904756],
						[77.69973777282542, 12.963382673509498],
						[77.69984923416582, 12.963458450305252],
						[77.70018815981415, 12.96362346810052],
						[77.70109329589005, 12.96429673673691],
						[77.70191823690273, 12.96518862308523],
						[77.70164622716717, 12.96307939752004],
						[77.70132521289706, 12.961130796054885],
						[77.70111120287011, 12.959735067756414],
						[77.70108884098237, 12.959333741037167],
						[77.70106656644128, 12.958919052056613],
						[77.70127612165373, 12.958963706485129],
						[77.70142330796803, 12.958941396036511],
						[77.7014902187149, 12.959222290831136],
						[77.70150358338928, 12.959391794412888],
						[77.70153031273328, 12.959436362743777],
						[77.70162395273745, 12.959480928013273],
						[77.70185586864051, 12.959561232132375],
						[77.7019896022244, 12.959587957545585],
						[77.7019896022244, 12.959592442279462],
						[77.70222597222916, 12.959690552480701],
						[77.70234634079726, 12.959717244767063],
						[77.70296172088705, 12.959891195956777],
						[77.70358591984635, 12.960007092072606],
						[77.70400956254456, 12.960051681195496],
						[77.7044777513645, 12.956814366068471],
						[77.70661366320024, 12.956582487329943],
						[77.70997139837901, 12.956279297186475],
						[77.71451966577133, 12.955962673996614],
						[77.71462665795677, 12.954553605647481],
						[77.71488090591299, 12.954410979068493],
						[77.71501907821036, 12.954076542373201],
						[77.71534904903316, 12.950968511919497],
						[77.71579500592784, 12.947494896206871],
						[77.71719959252212, 12.943575299928655],
						[77.7184347267096, 12.944614266240645],
						[77.71946478075519, 12.945153831383568],
						[77.72125289839008, 12.945390155401846],
						[77.72300535503499, 12.946348849012292],
						[77.72499855967747, 12.946402411007668],
						[77.72511453700986, 12.94653616148682],
						[77.7250565483588, 12.946821539154781],
						[77.72502082943066, 12.947459152205695],
						[77.72493166305875, 12.947771337973135],
						[77.72472206503707, 12.948721085663628],
						[77.72447683485005, 12.950049911861267],
						[77.72417806899453, 12.951200353810188],
						[77.72390611364173, 12.95207436947954],
						[77.72373214570686, 12.953046451799498],
						[77.72370096768977, 12.953960557966473],
						[77.72399973482734, 12.95583787196057],
						[77.7241736150105, 12.957666069843883],
						[77.72404881605175, 12.960667080147532],
						[77.72416470704188, 12.960769624752078],
						[77.72430740911999, 12.960805305812821],
						[77.72458390479169, 12.960854336206058],
						[77.72465970954934, 12.960867687762128],
						[77.72456600158675, 12.961175376465608],
						[77.72457490952323, 12.961188737907799],
						[77.72460617462792, 12.961197728767724],
						[77.7251189909562, 12.961215562188686],
						[77.7251903414057, 12.961202136240557],
						[77.72523488083075, 12.961179845044349],
						[77.72523933477228, 12.961184283210878],
						[77.72575660210057, 12.961255630862395],
						[77.72576105602118, 12.96125567016266],
						[77.72585022172784, 12.961273452007807],
						[77.72604645589591, 12.961264584479961],
						[77.72637194010954, 12.961300245292124],
						[77.72665733841575, 12.961429567499358],
						[77.72685802503557, 12.961639130597757],
						[77.72696055148042, 12.961755099635688],
						[77.72705417009361, 12.961893329793726],
						[77.72709879610255, 12.962316885228635],
						[77.72709879610255, 12.962655775355922],
						[77.72718350687262, 12.963195354012742],
						[77.72721031738122, 12.963199820402915],
						[77.7272861203478, 12.963904328195008],
						[77.72735746940617, 12.964635626540266],
						[77.72741536952638, 12.965032480881769],
						[77.727397554108, 12.965117199024258],
						[77.72745108768504, 12.965473997235451],
						[77.72745554153775, 12.965576508510502],
						[77.7275580674314, 12.965964448053844],
						[77.72758042400213, 12.966000100155169],
						[77.7275937855442, 12.966111574992516],
						[77.72761605477741, 12.966196302867111],
						[77.72760269323803, 12.966200756463145],
						[77.72766068056075, 12.966379132741636],
						[77.72772303436498, 12.966709097595155],
						[77.72776766010534, 12.967016828477096],
						[77.72785237036557, 12.967493978687209],
						[77.72430295514113, 12.968033456544854],
						[77.72555591349214, 12.97121286698499],
						[77.7265904427953, 12.971074568957402],
						[77.72662616121906, 12.971368861879862],
						[77.72665288453103, 12.971368925888722],
						[77.72673759564096, 12.972452418824293],
						[77.72680449117286, 12.973130249615107],
						[77.72682676056422, 12.973794660369158],
						[77.72686693278835, 12.97462848358387],
						[77.72687584054043, 12.97522601716206],
						[77.72696945922499, 12.976412107366183],
						[77.72699626978523, 12.976554856147205],
						[77.72542666215128, 12.976621693210287],
						[77.72112809615335, 12.977027488542054],
						[77.71876922894188, 12.977268288804149],
						[77.71675818287619, 12.977379766977654],
						[77.71416296618321, 12.977834570570957],
						[77.71435913362998, 12.979002847869634],
						[77.71482736628515, 12.981401890840836],
						[77.71530005131005, 12.981486618593268],
						[77.71539813407462, 12.981825482521552],
						[77.71496108429608, 12.982084146941027],
						[77.71439485598178, 12.982088555078041],
						[77.71388199021311, 12.982271399195488],
						[77.71341384092267, 12.982761923452502],
						[77.71334248291947, 12.983033878816789],
						[77.71288760732705, 12.983412891488028],
						[77.71200029761137, 12.984014895647764],
						[77.71154096204536, 12.984144213735473],
						[77.71116198039033, 12.984331537605867],
						[77.71033256593464, 12.98415757892619],
						[77.70941842106848, 12.983934633206681],
						[77.7083884487443, 12.983711681536247],
						[77.70808518506453, 12.984237833855756],
						[77.70801827827746, 12.985009299793512],
						[77.7073271095992, 12.987149615853166],
						[77.70708192842474, 12.98790767376578],
						[77.7061677568808, 12.988094983546496],
						[77.70580212110194, 12.988099445157195],
						[77.70573075822159, 12.988099403667098],
						[77.70581103054033, 12.987078304050955],
						[77.7042191122241, 12.987167527111188],
						[77.70254698390528, 12.984991487492113],
						[77.70233297622391, 12.984790803327437],
						[77.70004988176812, 12.984630238811052],
						[77.69982695937708, 12.984616922836734],
						[77.70029516576491, 12.983974793474909],
						[77.70064291255774, 12.983800839178778],
						[77.70068754915619, 12.983720589341313],
						[77.70044226602265, 12.98282435282005],
						[77.70109329589005, 12.982476478487298],
						[77.70040217161296, 12.98223129070845],
						[77.69984032425084, 12.980906945373443],
						[77.6997511377099, 12.980701789741623],
						[77.69733879379089, 12.981705089136232],
						[77.69544363113216, 12.98207963530988],
						[77.69543917599879, 12.982075195867717],
						[77.69526525094699, 12.981740744342224],
						[77.69519396867766, 12.981544551402266],
						[77.69516269531474, 12.981433077813863],
						[77.69514041958998, 12.981348342947719],
						[77.69509141297988, 12.981156664697],
						[77.6948550278523, 12.981241384385372],
						[77.69452508369379, 12.9813216365236],
						[77.6944002515086, 12.98137062285798],
						[77.69433779168543, 12.981446481065513],
						[77.69428424218681, 12.981522247190261],
						[77.69372245136258, 12.981575814355734],
						[77.69345933206269, 12.981486565673556],
						[77.6931561151279, 12.98198153037845],
						[77.6924382101521, 12.981892384603743],
						[77.69180949351983, 12.9817630737436],
						[77.69032456556242, 12.981870098935323],
						[77.68887980377691, 12.978824538969583],
						[77.68870150046094, 12.97850791849764],
						[77.68692675105176, 12.979484430330652],
						[77.68665924761164, 12.979292757270878],
						[77.68544184551496, 12.978293902990412],
						[77.68472843385709, 12.977593767397119],
						[77.68429589415142, 12.976826840179031],
						[77.68408630705052, 12.97620253623992],
						[77.68411304056673, 12.97575219669327],
						[77.68468824643735, 12.975333060877372],
						[77.68548648819437, 12.97470428962952],
						[77.685696072668, 12.974316334907964],
						[77.68573171684115, 12.974151399598144],
						[77.68574062788264, 12.974093433051955],
						[77.6859190232895, 12.973923930301138],
						[77.68609733104853, 12.973758994603193],
						[77.68639611166262, 12.973544956272871],
						[77.68685092061847, 12.973161467529474],
						[77.686775177496, 12.97292514612426],
						[77.6867082579241, 12.972323113804377],
						[77.68659678345675, 12.971890594570436],
						[77.68643629846787, 12.971444697135498],
						[77.68624453738666, 12.970655417316838],
						[77.68606168699208, 12.970267537829457],
						[77.68605723148423, 12.970044545295629],
						[77.68621326151622, 12.969995501948286],
						[77.68639611166262, 12.970004404134501],
						[77.6862712703863, 12.9697814431251],
						[77.68639165616811, 12.969487133095457],
						[77.68665033664342, 12.969259712819605],
						[77.68733700328644, 12.969085841833026],
						[77.68792555885378, 12.969072450525465],
						[77.68814413696082, 12.967163962459495],
						[77.68856320817805, 12.966945489821404],
						[77.68863458204349, 12.966450476787202],
						[77.68896454361658, 12.962241080950282],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000a9",
			properties: {
				AREA_SQ_KM: 12.137400006708143,
				ASS_CONST1: "Mahadevapura",
				ASS_CONST_: "174",
				LAT: 12.9681828471219,
				LON: 77.707823685904,
				LOST_AREA_SQ_KM: 0.5680351993224201,
				OBJECTID: 170,
				POP_F: 10226.0,
				POP_M: 11790.0,
				POP_SC: 4283.0,
				POP_ST: 343.0,
				POP_TOTAL: 22016.0,
				RESERVATIO: "General",
				WARD_NAME: "Dodda Nekkundi",
				WARD_NO: 85.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.67314809303653, 12.948186052006383],
						[77.673027780362, 12.94805674459954],
						[77.67297867667698, 12.947909536960418],
						[77.67294748446729, 12.947757924318994],
						[77.67293857240577, 12.94758849103894],
						[77.67291183621697, 12.947387855088463],
						[77.67296976461796, 12.947169357530692],
						[77.67300541284979, 12.946964197246368],
						[77.67300541284979, 12.946611956111749],
						[77.67304114844335, 12.946478197413892],
						[77.67324621286338, 12.946433572196572],
						[77.67359404389809, 12.94624184198051],
						[77.673776827319, 12.946121479949971],
						[77.67425842350616, 12.945996576131416],
						[77.67494071034517, 12.945813815050908],
						[77.67534646327978, 12.945693395840419],
						[77.67565418448496, 12.94552397852475],
						[77.67599746475061, 12.94534108081237],
						[77.67655488884678, 12.945153823964906],
						[77.67766072964717, 12.94473024344923],
						[77.67890486391981, 12.944413620010481],
						[77.67938198224515, 12.94426650177698],
						[77.67972533843198, 12.94413275078584],
						[77.67998394697553, 12.944056870071769],
						[77.68016671995012, 12.944114906692628],
						[77.68031838983975, 12.94413714163693],
						[77.68041650337418, 12.943923145609755],
						[77.68054580692109, 12.943749266790261],
						[77.68076431210189, 12.943695703044424],
						[77.6814599263098, 12.943526229648175],
						[77.68202177883438, 12.94312937609512],
						[77.68286005300585, 12.942603248558775],
						[77.68372959746239, 12.942242008860072],
						[77.68425133836045, 12.941938864042648],
						[77.68475962277941, 12.941635594071588],
						[77.68510741798701, 12.941457206911513],
						[77.68538837911223, 12.941292276511682],
						[77.68549094372499, 12.941211971043113],
						[77.68680636584676, 12.941738203428775],
						[77.68803257656556, 12.941912081085114],
						[77.6886747680431, 12.941006839949202],
						[77.68970920311156, 12.941519674764645],
						[77.69171138999376, 12.94292425597901],
						[77.69348606333462, 12.944257570787622],
						[77.69475692700374, 12.945104788434488],
						[77.69556400702149, 12.945323276804073],
						[77.69704895260848, 12.944895215205802],
						[77.69796302423153, 12.944806023020583],
						[77.69802548202485, 12.944953178269156],
						[77.69819494774939, 12.945657753743385],
						[77.69831977568795, 12.946170493561072],
						[77.69856943114591, 12.94693305093993],
						[77.6988058084017, 12.947597449790567],
						[77.69903318782427, 12.948364390260112],
						[77.69921601696603, 12.949225023904841],
						[77.6992918389971, 12.9496397250045],
						[77.70001424216814, 12.953568139655301],
						[77.7010576566236, 12.95691251953463],
						[77.7044777513645, 12.956814366068471],
						[77.70400956254456, 12.960051681195496],
						[77.70358591984635, 12.960007092072606],
						[77.70296172088705, 12.959891195956777],
						[77.70234634079726, 12.959717244767063],
						[77.70222597222916, 12.959690552480701],
						[77.7019896022244, 12.959592442279462],
						[77.7019896022244, 12.959587957545585],
						[77.70185586864051, 12.959561232132375],
						[77.70162395273745, 12.959480928013273],
						[77.70153031273328, 12.959436362743777],
						[77.70150358338928, 12.959391794412888],
						[77.7014902187149, 12.959222290831136],
						[77.70142330796803, 12.958941396036511],
						[77.70127612165373, 12.958963706485129],
						[77.70106656644128, 12.958919052056613],
						[77.70108884098237, 12.959333741037167],
						[77.70111120287011, 12.959735067756414],
						[77.70132521289706, 12.961130796054885],
						[77.70164622716717, 12.96307939752004],
						[77.70191823690273, 12.96518862308523],
						[77.70109329589005, 12.96429673673691],
						[77.70018815981415, 12.96362346810052],
						[77.69984923416582, 12.963458450305252],
						[77.69973777282542, 12.963382673509498],
						[77.6994657577699, 12.963315716904756],
						[77.69848924096527, 12.963110646369076],
						[77.69801211693355, 12.96299027052926],
						[77.6977490077514, 12.962887686523562],
						[77.69720051221161, 12.962571061672822],
						[77.69555509676417, 12.961621280685426],
						[77.69435561239379, 12.961162020110299],
						[77.69323639649969, 12.960970232569506],
						[77.69338350606694, 12.959855481329411],
						[77.6949887698319, 12.960091823158182],
						[77.69498876983191, 12.958968162902677],
						[77.69554618650615, 12.958954717620022],
						[77.69561755590584, 12.958941388519865],
						[77.69589403546257, 12.95890126932638],
						[77.69596531728457, 12.95882989453975],
						[77.69596977239695, 12.958361723406465],
						[77.69591631103685, 12.958285869211858],
						[77.69563983153532, 12.958201196193857],
						[77.6953990797904, 12.958103047430734],
						[77.69494867346164, 12.95804958257076],
						[77.69456963538457, 12.958036171809066],
						[77.6944002515086, 12.9580049847228],
						[77.69427978700713, 12.957853321406855],
						[77.6940390323273, 12.957612528793689],
						[77.69381609792251, 12.957367325550813],
						[77.69403457713784, 12.956221337722035],
						[77.69400339080661, 12.956212434579566],
						[77.68990095824252, 12.955561348564498],
						[77.68958882090661, 12.955512315629473],
						[77.68962900656275, 12.955427648061924],
						[77.68911620156081, 12.955329549852431],
						[77.68808612906365, 12.955146677900721],
						[77.68793901251462, 12.95511551608224],
						[77.68783199471315, 12.955097649729089],
						[77.68777844209318, 12.955088714455314],
						[77.68773834313251, 12.955079817052576],
						[77.68771152312247, 12.955061980459194],
						[77.68768479046764, 12.955048546163143],
						[77.6876714241378, 12.955026263089907],
						[77.6876714241378, 12.955008411865006],
						[77.6876714241378, 12.95495942880769],
						[77.68768033502452, 12.954919235747315],
						[77.68776953121427, 12.954830093610475],
						[77.6880013885573, 12.954589335521268],
						[77.68881297282708, 12.95383568245772],
						[77.6890715603325, 12.95359938908526],
						[77.68920539660346, 12.95346115006628],
						[77.6892900488356, 12.953336261397274],
						[77.68935251148382, 12.953233771375853],
						[77.68938815448979, 12.95313121015525],
						[77.68940597598849, 12.95303754417265],
						[77.68942834021816, 12.952877002648345],
						[77.68948180468654, 12.95230625113992],
						[77.68950853691113, 12.951802354322373],
						[77.68952199038121, 12.951285063782754],
						[77.6894996261703, 12.950870412866825],
						[77.68947734931515, 12.950544934578199],
						[77.68946398319993, 12.950428915153077],
						[77.68945061708311, 12.950330832693663],
						[77.68943279559151, 12.950237194647451],
						[77.68941497409708, 12.95016589146875],
						[77.68937924373935, 12.950090026681925],
						[77.68932132384425, 12.949969642153016],
						[77.689196398478, 12.949786821167805],
						[77.68901809566765, 12.94956385839644],
						[77.6888218836227, 12.94938997203645],
						[77.68866131447093, 12.949305225893882],
						[77.68847409998335, 12.94923395578078],
						[77.68792110342028, 12.949104635120472],
						[77.68738601330145, 12.949019887586466],
						[77.68682864323483, 12.948975296600539],
						[77.68677963297522, 12.949024355325426],
						[77.68639165616811, 12.948993098255784],
						[77.68591011226222, 12.94894849816297],
						[77.68553104349263, 12.948912835528409],
						[77.6850539514242, 12.94886378966448],
						[77.68471952273484, 12.948837066538335],
						[77.68414431702526, 12.948783513930927],
						[77.68345308777683, 12.948734517250331],
						[77.68298489809474, 12.948698827203845],
						[77.68253452904588, 12.948663123808618],
						[77.68251670645064, 12.94866313626875],
						[77.68250333950235, 12.948658704675653],
						[77.6824944282026, 12.948654226851904],
						[77.68248551690212, 12.94865423308076],
						[77.6824721499501, 12.948649801486873],
						[77.68246323864787, 12.948654205535423],
						[77.68244087302341, 12.948649776001645],
						[77.6819192110365, 12.948618605337328],
						[77.68121023258466, 12.948565048641516],
						[77.68061709854243, 12.948529345990698],
						[77.68056808555764, 12.948524938937016],
						[77.68054580692109, 12.948520427184281],
						[77.6805011622674, 12.948480356294338],
						[77.68032284557651, 12.94834207428864],
						[77.68023809918074, 12.948297504004845],
						[77.6801132510229, 12.948252918259453],
						[77.67997949122528, 12.948252919964967],
						[77.6797565287509, 12.948257376493876],
						[77.67964504734691, 12.948252921788008],
						[77.67958703503626, 12.948235064543166],
						[77.67954247736759, 12.948226127155472],
						[77.67928832358054, 12.948226163760195],
						[77.67866853189474, 12.94824402837028],
						[77.67851685976626, 12.948244037187154],
						[77.67820923380913, 12.948266270195095],
						[77.67797290031335, 12.948306390217915],
						[77.67764736211807, 12.948346566193322],
						[77.67743330669583, 12.948377745604969],
						[77.67716132466403, 12.948422372703044],
						[77.67638993354169, 12.948556135490822],
						[77.67572102330598, 12.948680988082502],
						[77.67556943454986, 12.948716698051765],
						[77.67544012524675, 12.948747865971253],
						[77.67537765482041, 12.9487657529806],
						[77.67534200734471, 12.948765690493435],
						[77.67529299204705, 12.948756798326919],
						[77.67499418174636, 12.948676488792078],
						[77.67434763040971, 12.948529400657938],
						[77.67383038664913, 12.94838667107806],
						[77.6734112601785, 12.948270784000208],
						[77.67339780476858, 12.948270745520446],
						[77.67338889274296, 12.948275235304205],
						[77.67337106868958, 12.94827076312636],
						[77.67324175684462, 12.94822173654961],
						[77.67314809303653, 12.948186052006383],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000c4",
			properties: {
				AREA_SQ_KM: 3.146735042263895,
				ASS_CONST1: "Mahadevapura",
				ASS_CONST_: "174",
				LAT: 12.9507430630886,
				LON: 77.6914952440871,
				LOST_AREA_SQ_KM: 0.2232712784681432,
				OBJECTID: 197,
				POP_F: 10522.0,
				POP_M: 11967.0,
				POP_SC: 4126.0,
				POP_ST: 274.0,
				POP_TOTAL: 22489.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Marathahalli",
				WARD_NO: 86.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.6399724605271, 12.923834852695508],
						[77.63978070726556, 12.923460281475634],
						[77.63939719976017, 12.92261752868613],
						[77.63905380735697, 12.921957634805924],
						[77.63883539560794, 12.92153395638498],
						[77.63868821434183, 12.921101441750443],
						[77.63865701258783, 12.920976599161055],
						[77.6384697144818, 12.920338912313891],
						[77.63840285343969, 12.919995576295074],
						[77.63834481977086, 12.919313384496968],
						[77.63828687347224, 12.91770810855466],
						[77.63821555490982, 12.916459500814788],
						[77.63820218267432, 12.915942287539048],
						[77.6381263192428, 12.915946767586101],
						[77.63799705413027, 12.915946715197212],
						[77.63747090250905, 12.915986841537052],
						[77.6367574474621, 12.916093874430377],
						[77.63599486799625, 12.9161830435199],
						[77.63480435966513, 12.916294523896246],
						[77.63360037853326, 12.916406043704733],
						[77.63286906841556, 12.916477387589905],
						[77.63192824366772, 12.91657104247339],
						[77.6313976059465, 12.916628954495408],
						[77.63115234576128, 12.916655765385908],
						[77.62995724098724, 12.916718127079122],
						[77.6289584407606, 12.91677167660621],
						[77.62864185061606, 12.916793955397988],
						[77.62853040577824, 12.916802851509187],
						[77.62844562021981, 12.91682517501739],
						[77.6283341751875, 12.916860877426462],
						[77.62816469111884, 12.916905442200093],
						[77.62766978568742, 12.917052626035328],
						[77.62687156908848, 12.917288916441205],
						[77.62608235002, 12.917538648968996],
						[77.62577466758927, 12.91762332261264],
						[77.62557843219035, 12.91765453912372],
						[77.62536445217587, 12.917663444825163],
						[77.62507014158093, 12.917663510725076],
						[77.62477137227135, 12.917654571325091],
						[77.62457959307467, 12.91763669707367],
						[77.62439235892182, 12.917609989643239],
						[77.62398205105659, 12.917538656536314],
						[77.62365653092729, 12.917476203669354],
						[77.62338905127622, 12.917431609447199],
						[77.62296991113239, 12.917355801797953],
						[77.62281379304886, 12.917306791978358],
						[77.62277812886502, 12.917369214409094],
						[77.62272463256804, 12.917337954154792],
						[77.62273800664467, 12.917311184221802],
						[77.62274246466987, 12.917306742953743],
						[77.62276921281729, 12.917248807250092],
						[77.62277367084124, 12.917244365982501],
						[77.62325968180508, 12.916388177779485],
						[77.62402226024457, 12.91502366587756],
						[77.62424515874348, 12.91464025235122],
						[77.62445922829791, 12.914265639826574],
						[77.6251459264142, 12.913173150240787],
						[77.62610018158962, 12.91161250924602],
						[77.62682699046488, 12.910421879513592],
						[77.62755825247774, 12.909244740205121],
						[77.62828959714365, 12.908040718682063],
						[77.62901639186946, 12.906885869916271],
						[77.62937310082744, 12.906301663701573],
						[77.62976110029341, 12.905668533606436],
						[77.63068411233938, 12.904161339681337],
						[77.63100515443095, 12.903639644140283],
						[77.63171864600038, 12.903724292530525],
						[77.63196390495003, 12.903902682158353],
						[77.6322537398542, 12.903960643526062],
						[77.6323161469101, 12.903938369843166],
						[77.63233397749114, 12.90400075757185],
						[77.63237855393133, 12.904125613938803],
						[77.63255694692, 12.90438872244975],
						[77.63246779416342, 12.905013024269048],
						[77.63255694692, 12.905472259975255],
						[77.63254803164753, 12.906047564744426],
						[77.63252574346323, 12.906622751029783],
						[77.63259260800284, 12.906622754131782],
						[77.6332615134232, 12.906645033055629],
						[77.63393478504024, 12.906983915987153],
						[77.63467054577507, 12.907193482354614],
						[77.63511647491866, 12.907313884648822],
						[77.63523682828664, 12.907269294856984],
						[77.63579428033134, 12.90706422557415],
						[77.63642296195431, 12.90674318237114],
						[77.63698040811362, 12.906551382465894],
						[77.63760908309871, 12.906270499152924],
						[77.63810848958096, 12.906074288853814],
						[77.63866592737558, 12.905855765110402],
						[77.63894237284421, 12.905748807454595],
						[77.63912075541108, 12.905704139604946],
						[77.63962015517164, 12.905672976809798],
						[77.64028010409127, 12.905579314020628],
						[77.64063240737624, 12.905503553184566],
						[77.6411318004141, 12.905423258720141],
						[77.64163119123079, 12.905378645285573],
						[77.64197011848962, 12.905316206761688],
						[77.64240710416216, 12.905227071666257],
						[77.64315618104494, 12.904999661347116],
						[77.6437849962466, 12.904865889514284],
						[77.64425763223345, 12.904772244785146],
						[77.6447837520525, 12.90465184479951],
						[77.64531441392975, 12.904531401412903],
						[77.64581387353276, 12.904415502195324],
						[77.64612150108836, 12.904339670095547],
						[77.64634444314493, 12.904286174101864],
						[77.64669227032188, 12.904241562183149],
						[77.6468973829784, 12.904205918741045],
						[77.64724966549421, 12.904098858296152],
						[77.6475083490486, 12.904023104083684],
						[77.6475083490486, 12.903916056390365],
						[77.64759748986499, 12.903929441852663],
						[77.64797205530563, 12.903907132614451],
						[77.64799434042425, 12.903835795456],
						[77.64840456060145, 12.903831388721715],
						[77.64879249432293, 12.903822471403819],
						[77.64873455341754, 12.903528129320698],
						[77.64856064312963, 12.902756718780168],
						[77.64851607311294, 12.902199279194138],
						[77.64857401413119, 12.90153045991583],
						[77.64858292813133, 12.900843794024892],
						[77.6486944404655, 12.900001023522192],
						[77.64885052259037, 12.899505990829955],
						[77.64913585693245, 12.899742380149569],
						[77.64921171292332, 12.899742374772043],
						[77.64997420260887, 12.899434646320545],
						[77.6507589716803, 12.899077987792303],
						[77.65116026795212, 12.898917405473721],
						[77.65152153823685, 12.898404652307393],
						[77.65162850338086, 12.898279778065572],
						[77.65225281424455, 12.898163860362196],
						[77.65277452712388, 12.898061239512435],
						[77.65328732395018, 12.897989909425963],
						[77.65401413165756, 12.897940836343649],
						[77.65466071303153, 12.89798550433403],
						[77.6546517995145, 12.898261913536299],
						[77.65521806860096, 12.898346625685159],
						[77.65536522818536, 12.898373370418998],
						[77.65549901740233, 12.89835560884971],
						[77.65583790276447, 12.898190579466362],
						[77.65586909974088, 12.898382367557293],
						[77.6559047534176, 12.898716750848994],
						[77.65578442221326, 12.899193847239408],
						[77.65586018631994, 12.899555051537149],
						[77.6560251718791, 12.89969331206483],
						[77.65624818163448, 12.89973340870494],
						[77.65643545006358, 12.899746808384284],
						[77.65660934813799, 12.89974681526648],
						[77.65681889932847, 12.89949710648967],
						[77.65701508013373, 12.899711147105632],
						[77.65707310414574, 12.900246215349526],
						[77.65717560733451, 12.900424596178622],
						[77.65706419082056, 12.900736727428765],
						[77.6567565058957, 12.900910658318365],
						[77.65638633910123, 12.901200469064735],
						[77.65604299869403, 12.901601794281623],
						[77.65594495117675, 12.901686503723274],
						[77.65504862511096, 12.90276118391017],
						[77.65480787334765, 12.903140160162717],
						[77.65477667607587, 12.903483524891936],
						[77.65557478170193, 12.906386415019167],
						[77.65702399346283, 12.911447498324032],
						[77.66017218938131, 12.9115188174716],
						[77.6609302353883, 12.913980250736994],
						[77.66118879999422, 12.915139650359587],
						[77.66119325649284, 12.915585525750853],
						[77.66122899585773, 12.915893246152352],
						[77.66200040411293, 12.916022503020248],
						[77.66272278625142, 12.918038052718881],
						[77.66314195275167, 12.91806923564805],
						[77.66417199292945, 12.919772598903812],
						[77.66485416651156, 12.92067337907039],
						[77.66621421966465, 12.9220333753121],
						[77.66706589374954, 12.922804838577882],
						[77.66769466221024, 12.923794743731428],
						[77.66810935387288, 12.92433426671831],
						[77.66930885789523, 12.92722382474009],
						[77.66927766466237, 12.927277341603814],
						[77.66927766466237, 12.927321913540789],
						[77.66927320848554, 12.927415500552495],
						[77.66933113877052, 12.928566017650583],
						[77.66934896346756, 12.928833526270356],
						[77.66932222642093, 12.928882600605137],
						[77.66929994554387, 12.929792218547018],
						[77.66930885789523, 12.92981454190099],
						[77.66925538377643, 12.93022927151519],
						[77.66927320848554, 12.930951568946275],
						[77.66911706743232, 12.930996198696182],
						[77.66886743352195, 12.931045250928591],
						[77.6687559412631, 12.931040797289604],
						[77.66835462063754, 12.931022955727995],
						[77.66804242312698, 12.931014018700896],
						[77.66748950023803, 12.931063091954181],
						[77.66703469989153, 12.931054164690291],
						[77.6666601107832, 12.930965002335343],
						[77.66644612171437, 12.930889188487663],
						[77.66627669520761, 12.930813344335716],
						[77.66616520036807, 12.930746470283195],
						[77.66609381215622, 12.930697458524206],
						[77.66603588019565, 12.930563650189168],
						[77.6659377540462, 12.93038973243715],
						[77.6659199288059, 12.930336205623295],
						[77.66589764725155, 12.930300571259778],
						[77.665844171503, 12.93021585925251],
						[77.66579506466564, 12.930073161351432],
						[77.66578615203449, 12.9300241117743],
						[77.66577278308644, 12.929818975450065],
						[77.66558553026707, 12.929315095137461],
						[77.6653001504262, 12.928548159037842],
						[77.66513063480589, 12.92821815886084],
						[77.66498794439661, 12.928044275720694],
						[77.66490327376161, 12.927999718657398],
						[77.66484971015862, 12.92798181880533],
						[77.66471147574995, 12.927977382517145],
						[77.66453313388716, 12.927977413310277],
						[77.66422546947669, 12.927981881142527],
						[77.66395791172474, 12.927981881492187],
						[77.66369035333346, 12.928169133451132],
						[77.66358785610566, 12.928106655962532],
						[77.66356548670673, 12.927977392383543],
						[77.6634852714035, 12.92785700892292],
						[77.66339605584082, 12.927758870014666],
						[77.66327564530167, 12.927531478412948],
						[77.66283419713784, 12.926492535756973],
						[77.66249533293683, 12.925689882558004],
						[77.6622590536299, 12.924735658362577],
						[77.66197366531124, 12.923861602744523],
						[77.66175957990407, 12.9231348349117],
						[77.66156340740639, 12.922626426932583],
						[77.66155449443843, 12.922604191558237],
						[77.66128247381027, 12.921908549903979],
						[77.66121108248555, 12.921725750423569],
						[77.66122008286315, 12.921698938301866],
						[77.66124236534826, 12.921658880199768],
						[77.66126910432455, 12.921618689747389],
						[77.66128693030517, 12.921600832840747],
						[77.6613359517373, 12.921587480429286],
						[77.6614207124517, 12.921569625037774],
						[77.66212081602318, 12.921413606182503],
						[77.662843197385, 12.921221836627312],
						[77.66283865357099, 12.920954294010379],
						[77.66280745853521, 12.920312209870502],
						[77.66220994522025, 12.918314549567198],
						[77.66051106061074, 12.916530890485488],
						[77.65687237938604, 12.919112725196257],
						[77.65486581111506, 12.920575245810888],
						[77.65379566163844, 12.920664452767037],
						[77.65374209272476, 12.92066448348613],
						[77.65370643836017, 12.92066890312588],
						[77.65369306797054, 12.920677833926122],
						[77.6536752407819, 12.920686724528814],
						[77.6536752407819, 12.920704568696634],
						[77.65367078398427, 12.920717976279862],
						[77.65367078398427, 12.920735820452206],
						[77.65367078398427, 12.920771508805057],
						[77.65367078398427, 12.920802714571778],
						[77.6536752407819, 12.920856201131029],
						[77.65367078398427, 12.92089184932143],
						[77.65366187038852, 12.92093202612688],
						[77.65363067279783, 12.920954330138343],
						[77.6535771038053, 12.920967636167536],
						[77.65345677010235, 12.9209810329124],
						[77.65326058306935, 12.920998914097213],
						[77.6531223343615, 12.920998870245807],
						[77.6529707150074, 12.920994395474574],
						[77.65269867354283, 12.921021156889884],
						[77.65193620238063, 12.921128222168111],
						[77.65166415840618, 12.921150499554408],
						[77.65161513274343, 12.92115945305016],
						[77.65158393458324, 12.921181756654148],
						[77.65156610705925, 12.921204008586512],
						[77.6515616501778, 12.921239656607405],
						[77.65155719329618, 12.921324440690558],
						[77.65154382265027, 12.92177921730691],
						[77.65152153823685, 12.922354428773321],
						[77.65151253708036, 12.922675541982931],
						[77.65152153823685, 12.922836053567469],
						[77.65153045200275, 12.922929666271134],
						[77.65155273641439, 12.923032278972393],
						[77.65157056394051, 12.923134765297123],
						[77.6515973052244, 12.9232685384972],
						[77.65165970152863, 12.923558406927873],
						[77.6517489264356, 12.924048950038879],
						[77.6517533833096, 12.92417821270062],
						[77.65174446956142, 12.924204942129778],
						[77.65172664206294, 12.92423615990072],
						[77.6516999008099, 12.924254021406973],
						[77.65165970152863, 12.924258482183546],
						[77.65162404650188, 12.924262987410142],
						[77.65156610705925, 12.924271860093528],
						[77.65149470954502, 12.924280780128882],
						[77.65129851907905, 12.924316502584716],
						[77.65087048185113, 12.9243610730903],
						[77.65056277992724, 12.924410093114133],
						[77.65028636307983, 12.924472502281919],
						[77.6501793092648, 12.924508167838066],
						[77.65004997064177, 12.92455725219884],
						[77.64968878300805, 12.924641993212616],
						[77.64932313724086, 12.924691004079026],
						[77.64906900224359, 12.924691027925062],
						[77.64855618612876, 12.92465982388741],
						[77.64805673873285, 12.924619688312282],
						[77.64751726313344, 12.924584020014398],
						[77.64680824160578, 12.924508187739349],
						[77.64630878642866, 12.924481410387491],
						[77.64586290173092, 12.924472534342513],
						[77.64565778718345, 12.92447696427802],
						[77.64543038663764, 12.92447257150491],
						[77.64521189989901, 12.924454725272136],
						[77.64507364127104, 12.924432434212292],
						[77.6449175538922, 12.92438334430758],
						[77.64474809484037, 12.924329822874125],
						[77.64404360039204, 12.924097998817366],
						[77.64287083149756, 12.92377243483575],
						[77.64213066722338, 12.923585196274288],
						[77.64154204567559, 12.923415711184745],
						[77.6414394408019, 12.923389001409793],
						[77.64134583780722, 12.92338004825305],
						[77.64127897847753, 12.923384526942852],
						[77.64120311712452, 12.923393402991108],
						[77.64110951393282, 12.92342022374168],
						[77.64088656147845, 12.923504914657915],
						[77.64045848453321, 12.92366988894175],
						[77.6399724605271, 12.923834852695508],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000aa",
			properties: {
				AREA_SQ_KM: 7.073866655281148,
				ASS_CONST1: "Bommana Halli",
				ASS_CONST_: "175",
				LAT: 12.9137178618362,
				LON: 77.6464258750019,
				LOST_AREA_SQ_KM: 0.23439522345452402,
				OBJECTID: 171,
				POP_F: 11534.0,
				POP_M: 13215.0,
				POP_SC: 2723.0,
				POP_ST: 303.0,
				POP_TOTAL: 24749.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "HSR Layout",
				WARD_NO: 174.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.62107471386292, 12.912584579016956],
						[77.62132891218593, 12.91229472408921],
						[77.62132891218593, 12.912236718867476],
						[77.62123529244394, 12.912080723389321],
						[77.62118625350037, 12.912004860100796],
						[77.62117287923934, 12.9119424234161],
						[77.6211595049767, 12.911880072957718],
						[77.62114613071249, 12.91173730854598],
						[77.6211595049767, 12.911603579909041],
						[77.62116396306442, 12.911492092763048],
						[77.62116842115198, 12.911393964914065],
						[77.62113721453547, 12.91105065074013],
						[77.6210926336397, 12.910881228838038],
						[77.6210836300449, 12.910841065832287],
						[77.62103459103525, 12.910800885310794],
						[77.62095880343288, 12.91078312789157],
						[77.62088747387814, 12.910760757035979],
						[77.62081614427821, 12.910676072425384],
						[77.6207492727372, 12.910519979070346],
						[77.62067339752883, 12.910341650173727],
						[77.62061098401264, 12.91013207576531],
						[77.62057977724157, 12.909944764519567],
						[77.62055302857377, 12.9094186075873],
						[77.62054857046185, 12.909097521737758],
						[77.62055748668551, 12.908852316627772],
						[77.62064664888328, 12.90849554404095],
						[77.62086518338302, 12.908575799161124],
						[77.62092759675825, 12.908727453350243],
						[77.62127095711672, 12.908660520986032],
						[77.62144036415819, 12.90862043887777],
						[77.62141807377257, 12.908424247831023],
						[77.62173914231265, 12.908388569097884],
						[77.6221270806988, 12.908388577634959],
						[77.62214937094399, 12.908379645190257],
						[77.62210479044919, 12.90796045972039],
						[77.62224753538302, 12.90794711324683],
						[77.62224307733823, 12.907594830565174],
						[77.62220732556082, 12.907385242200274],
						[77.62238127664479, 12.90737183523592],
						[77.62251947578163, 12.907389728990449],
						[77.6228628312832, 12.907438799320206],
						[77.6228628312832, 12.907122152827776],
						[77.62285837326279, 12.906921472188573],
						[77.62285391524217, 12.906827834150159],
						[77.6227023424368, 12.906836795047742],
						[77.6225417659491, 12.906859115307537],
						[77.62242139899233, 12.906868015679818],
						[77.6223322382006, 12.90685013825641],
						[77.62226090951636, 12.906774334502954],
						[77.62217166118477, 12.906725340034837],
						[77.62205129383211, 12.906711918282014],
						[77.62188188771029, 12.906698566504218],
						[77.62182830357429, 12.906711950386335],
						[77.62160540028775, 12.904656319568433],
						[77.62120854393191, 12.904718716229253],
						[77.62084735098374, 12.904754375584485],
						[77.62052182178662, 12.90484353455835],
						[77.6202453310229, 12.904036492039477],
						[77.6201427941238, 12.90392050350084],
						[77.62002688273405, 12.90359057470128],
						[77.6196166463665, 12.902912802466092],
						[77.61950519258839, 12.902342040939313],
						[77.6192331574917, 12.902426773442505],
						[77.61862221252159, 12.90086606981008],
						[77.61940256760022, 12.900825873967984],
						[77.61975039075458, 12.900750104629106],
						[77.6199466363174, 12.900754566903085],
						[77.61971026745483, 12.898257436761341],
						[77.6196567696996, 12.898257466976288],
						[77.61948281440473, 12.898230716397562],
						[77.61934461153916, 12.898212912326828],
						[77.61925544831003, 12.898199518754499],
						[77.6192064085039, 12.89818166440394],
						[77.61915736867643, 12.89815941496928],
						[77.61915291050921, 12.898154893129723],
						[77.61913507783868, 12.898150421904496],
						[77.6190324525131, 12.898070200594635],
						[77.61896112173764, 12.898016724207746],
						[77.61894328903672, 12.898007771731807],
						[77.61890316544931, 12.89799887487404],
						[77.61874712914013, 12.89799883324702],
						[77.61864450346089, 12.898016682321954],
						[77.61837701189812, 12.898025622295227],
						[77.61810943228399, 12.897972078941578],
						[77.6180693083994, 12.897905184429643],
						[77.61775714583725, 12.896027906261171],
						[77.61717748799704, 12.895965491402134],
						[77.61719086273276, 12.89515840606248],
						[77.61719532097764, 12.894605460850508],
						[77.61719977922236, 12.894404800195629],
						[77.61704374055245, 12.894418202325493],
						[77.61645070421766, 12.894471658665811],
						[77.61652649485491, 12.89516286808468],
						[77.61655770274955, 12.895457182267917],
						[77.61625891084314, 12.8954794488473],
						[77.61547862139486, 12.895492847695557],
						[77.61548307970753, 12.894886389327528],
						[77.61507282678447, 12.894823963874435],
						[77.61464028063953, 12.894904211914794],
						[77.61456448873291, 12.89402135670981],
						[77.61449315512715, 12.893597680517589],
						[77.61435931707582, 12.89333905820559],
						[77.61423002464662, 12.892621180092705],
						[77.61506836845557, 12.892447254018553],
						[77.61507728511322, 12.892375897977933],
						[77.61435485871867, 12.89118530961853],
						[77.61357009769102, 12.890618996789499],
						[77.61333817384097, 12.890226614256987],
						[77.61281208170935, 12.890378223942657],
						[77.61257123944345, 12.889245646958011],
						[77.61245532026007, 12.888545584936963],
						[77.61245086182757, 12.888113061933526],
						[77.61250436300607, 12.886913480794082],
						[77.61316875460916, 12.886931332186029],
						[77.61376180822505, 12.886926904866684],
						[77.61430135841901, 12.88692242051638],
						[77.61484536436643, 12.88683775237553],
						[77.61525561811803, 12.886726275621404],
						[77.61526007643954, 12.886824376021963],
						[77.61526453476087, 12.887047335078117],
						[77.6153135762838, 12.887604691197014],
						[77.61599587190544, 12.887479833064363],
						[77.61591553519813, 12.88676639567375],
						[77.61587986882874, 12.886507738040082],
						[77.61630795193308, 12.886351699300247],
						[77.61664240984851, 12.886333799763227],
						[77.61686978122016, 12.886329400796196],
						[77.61734698861524, 12.886208965645643],
						[77.61741832041335, 12.886476564023788],
						[77.61862221252159, 12.88624466661935],
						[77.61868025636997, 12.886498792353615],
						[77.61871592185233, 12.886690584185311],
						[77.61880954368975, 12.886989321528256],
						[77.61892991450917, 12.88731484272614],
						[77.61897449626149, 12.887381684155123],
						[77.61901907799614, 12.887448611801634],
						[77.61907257605442, 12.887573440566653],
						[77.61916628501027, 12.887858828346658],
						[77.61925099014671, 12.888130861612265],
						[77.61928219728618, 12.888184398180243],
						[77.61930894625597, 12.888220056990713],
						[77.61939365128508, 12.888322593057284],
						[77.61949627628137, 12.88844744109904],
						[77.6198217210299, 12.888781893432093],
						[77.61990196753561, 12.888839839055532],
						[77.61999567580104, 12.889018205575372],
						[77.62010712909351, 12.889343739000344],
						[77.62018737539574, 12.889736118462514],
						[77.62017845914278, 12.890449630977235],
						[77.62016062663469, 12.891127391211487],
						[77.62011158722291, 12.891390463808614],
						[77.62006700592093, 12.891461841513662],
						[77.61992880377258, 12.891506431143874],
						[77.61963002081247, 12.89156886212723],
						[77.61979943032372, 12.892723750270335],
						[77.62012941973875, 12.894204132224472],
						[77.62027653788671, 12.894837308868468],
						[77.62048615687644, 12.895448224077349],
						[77.62075373084117, 12.89603683293532],
						[77.62083397668242, 12.896282132653551],
						[77.6209766358145, 12.896411402408267],
						[77.6210926336397, 12.896554135590723],
						[77.62117733732653, 12.896692358481562],
						[77.62125758286575, 12.896964374991844],
						[77.62135566066934, 12.897379006581629],
						[77.62144482223479, 12.897691178906463],
						[77.62152515499652, 12.897914166708913],
						[77.62159648414709, 12.898114749503849],
						[77.62176143263468, 12.898480440881578],
						[77.62188188771029, 12.898814880297092],
						[77.62199333913492, 12.899064607328485],
						[77.62210033239874, 12.89932317097753],
						[77.62206020993673, 12.899363310595133],
						[77.62214491289531, 12.899613054329183],
						[77.62247935346906, 12.900161533324004],
						[77.62275583874437, 12.900540574029947],
						[77.62317943769351, 12.901093488846996],
						[77.62352724917534, 12.901552727605214],
						[77.6237546940515, 12.902034315543228],
						[77.624031176193, 12.902551600543102],
						[77.62420057907903, 12.902984141227423],
						[77.62474016665406, 12.90314461554618],
						[77.62529303800366, 12.90334976355798],
						[77.62552939513965, 12.903354242593503],
						[77.62590394675857, 12.903389883178207],
						[77.62617150783981, 12.903510303846632],
						[77.62651931007112, 12.90366635820543],
						[77.62666650727358, 12.903706481961345],
						[77.62726394762306, 12.90341664617502],
						[77.6272684054689, 12.903648522033054],
						[77.62730415563793, 12.903920503211289],
						[77.62748246926981, 12.904362009481012],
						[77.62836537980769, 12.904063220609938],
						[77.62898964520754, 12.903974005192117],
						[77.62940439256637, 12.903933908033718],
						[77.62929731884257, 12.903595002126263],
						[77.6299885325636, 12.903519235548357],
						[77.63075097797447, 12.903496940503645],
						[77.63113897268519, 12.903425570260415],
						[77.63100515443095, 12.903639644140283],
						[77.63068411233938, 12.904161339681337],
						[77.62976110029341, 12.905668533606436],
						[77.62937310082744, 12.906301663701573],
						[77.62901639186946, 12.906885869916271],
						[77.62828959714365, 12.908040718682063],
						[77.62755825247774, 12.909244740205121],
						[77.62682699046488, 12.910421879513592],
						[77.62610018158962, 12.91161250924602],
						[77.6251459264142, 12.913173150240787],
						[77.62445922829791, 12.914265639826574],
						[77.62424515874348, 12.91464025235122],
						[77.62402226024457, 12.91502366587756],
						[77.62325968180508, 12.916388177779485],
						[77.62277367084124, 12.917244365982501],
						[77.62276921281729, 12.917248807250092],
						[77.62274246466987, 12.917306742953743],
						[77.62232332211754, 12.916174127396712],
						[77.6223768186053, 12.915848638178417],
						[77.6223857346841, 12.9153714758246],
						[77.62240356683964, 12.915246661078385],
						[77.62238127664479, 12.914689230535407],
						[77.62236790252577, 12.914408298049176],
						[77.62235452840515, 12.914368183260613],
						[77.62232778015915, 12.914336996301232],
						[77.62229657386412, 12.91432809202819],
						[77.62222970320276, 12.914328084225708],
						[77.6221538289925, 12.914328076925383],
						[77.62207804214383, 12.91429687272939],
						[77.62182384551288, 12.914171982835379],
						[77.62114167262406, 12.913766251209825],
						[77.62145373838746, 12.913431756138172],
						[77.62156973572093, 12.913342578840401],
						[77.6216009422175, 12.913306922854503],
						[77.62164998098038, 12.91324453859602],
						[77.62167227132007, 12.913191046031024],
						[77.62167672938747, 12.91312859917414],
						[77.62167227132007, 12.91299031013878],
						[77.62167227132007, 12.912950231953387],
						[77.62165889711677, 12.91290563590762],
						[77.62162769063627, 12.912874408703209],
						[77.62152515499652, 12.912829859873643],
						[77.62130662177823, 12.91273175830142],
						[77.62106133958862, 12.912647030166776],
						[77.62107471386292, 12.912584579016956],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000ab",
			properties: {
				AREA_SQ_KM: 1.837222282496958,
				ASS_CONST1: "Bommana Halli",
				ASS_CONST_: "175",
				LAT: 12.9007411057792,
				LON: 77.6212117570716,
				LOST_AREA_SQ_KM: 0.07967110863282449,
				OBJECTID: 172,
				POP_F: 11242.0,
				POP_M: 13065.0,
				POP_SC: 2767.0,
				POP_ST: 138.0,
				POP_TOTAL: 24307.0,
				RESERVATIO: "General",
				WARD_NAME: "Bommanahalli",
				WARD_NO: 175.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57325985429236, 12.90648898085208],
						[77.57329990665599, 12.906248155594122],
						[77.5733534263861, 12.905918185821044],
						[77.57337572626619, 12.90557930845619],
						[77.57337126629054, 12.905365303161352],
						[77.57336234633868, 12.904986238928268],
						[77.57330882661276, 12.904584958791439],
						[77.57325539431214, 12.90419255990378],
						[77.57323755438945, 12.90400971230606],
						[77.57323309440835, 12.903875934449445],
						[77.57322863442707, 12.903759993967224],
						[77.57324647435115, 12.903572740392132],
						[77.57331328659089, 12.903077797158767],
						[77.5734114060652, 12.902569450030564],
						[77.57345600579814, 12.902248368377153],
						[77.5734738456864, 12.902056636210117],
						[77.57346492574261, 12.901842595803156],
						[77.57338018624169, 12.901432333022305],
						[77.57322863442707, 12.900794744764813],
						[77.57313943476446, 12.900415723454536],
						[77.57298779517667, 12.899942975179464],
						[77.57285399537172, 12.89954165531087],
						[77.57281840284794, 12.899443613987913],
						[77.57275150286513, 12.899323219153413],
						[77.57250174257993, 12.898948653753836],
						[77.57215840873945, 12.898458104080216],
						[77.57189535449645, 12.898096949716166],
						[77.57117737421312, 12.897165025407872],
						[77.57079835574504, 12.89671904433017],
						[77.57064680047412, 12.896549616068352],
						[77.57003585489561, 12.895854016045755],
						[77.56925550836158, 12.894993396005164],
						[77.56868032402103, 12.894382496390646],
						[77.56847069628172, 12.894181854322058],
						[77.56835927946136, 12.89407932126731],
						[77.56842172186869, 12.894065944731262],
						[77.56870262482151, 12.893990150586587],
						[77.57064680047412, 12.894159533520199],
						[77.57179277367021, 12.894226448501977],
						[77.57188197439396, 12.894222022172565],
						[77.57189089446246, 12.894516265117282],
						[77.57244822244712, 12.894525232570317],
						[77.57275150286512, 12.894663414906473],
						[77.57314835473387, 12.894092650962145],
						[77.57323755438945, 12.893967854381671],
						[77.5734024861165, 12.894034695246466],
						[77.5733534263861, 12.894123848885188],
						[77.57326431427244, 12.894288888060302],
						[77.57422294355425, 12.894574284326605],
						[77.5746377174183, 12.894730302797145],
						[77.57517273235652, 12.895082592980291],
						[77.57640348448056, 12.89577376870766],
						[77.57772333189028, 12.89653177362977],
						[77.57890945870346, 12.897205134995605],
						[77.57926623873843, 12.897338901763085],
						[77.57938665174623, 12.896875148669215],
						[77.58018476725806, 12.897383518507612],
						[77.58086701295453, 12.897798188919568],
						[77.58097404523066, 12.897865099604376],
						[77.58169642300088, 12.897842753741134],
						[77.58240987691785, 12.89783833192389],
						[77.58271313063278, 12.897842778475141],
						[77.58266853454923, 12.898511636102752],
						[77.58266853454923, 12.898609782347341],
						[77.58272650945442, 12.898667707992395],
						[77.5828379995731, 12.898685543959145],
						[77.58306981122483, 12.898725688829991],
						[77.58351131010993, 12.898707805563825],
						[77.58382785211944, 12.898632061493265],
						[77.58381001386402, 12.89914038147441],
						[77.58372528211233, 12.900045586145762],
						[77.5837030717149, 12.901151469032822],
						[77.58361833988305, 12.901918383084121],
						[77.58416231888744, 12.902168064476975],
						[77.58479548581602, 12.902431153699958],
						[77.58530387056047, 12.902613983438792],
						[77.58580324660664, 12.902765617676197],
						[77.58575865174213, 12.904620603619824],
						[77.5849783262067, 12.90464292885585],
						[77.58496048813282, 12.905169114627315],
						[77.5845636777028, 12.905164661154684],
						[77.58424705031157, 12.905236005317434],
						[77.58306089203768, 12.90536977049207],
						[77.58303413447196, 12.9055123984579],
						[77.58251690773365, 12.905521386128],
						[77.58171426158977, 12.905597176679954],
						[77.58170534229568, 12.904816793340638],
						[77.58172764052958, 12.903809037451294],
						[77.5812817624603, 12.903893743445384],
						[77.5808536339129, 12.90400523715635],
						[77.58044788967598, 12.904121163705245],
						[77.58034085690188, 12.904170235118196],
						[77.58030963899036, 12.904205878841992],
						[77.58030963899036, 12.904250516117644],
						[77.580327477798, 12.904344178969453],
						[77.580327477798, 12.904415529769153],
						[77.57961847047294, 12.904553711693874],
						[77.5796363093896, 12.904772238653054],
						[77.57869993735453, 12.904990686862133],
						[77.57873561547476, 12.905552606299366],
						[77.57817377122097, 12.90554367549659],
						[77.5774692102108, 12.905552544870709],
						[77.57724175945543, 12.905548100350599],
						[77.57689398015764, 12.905548139565564],
						[77.57690735966762, 12.9053162020591],
						[77.57693857851808, 12.905124501138458],
						[77.57700101619318, 12.904892590338617],
						[77.5770366948492, 12.904687488720588],
						[77.57703223501781, 12.904477963682629],
						[77.57705007434231, 12.904299582181855],
						[77.57718378173878, 12.904076605177451],
						[77.5772551389243, 12.903902686127383],
						[77.57700993585824, 12.90385809730083],
						[77.57690735966762, 12.903924959283561],
						[77.57682262274433, 12.903996295841171],
						[77.57659971798574, 12.904170272027448],
						[77.57634550633435, 12.90431741232179],
						[77.57606016247601, 12.904477880793815],
						[77.57589068729709, 12.904651833499587],
						[77.57562309439253, 12.904910440427342],
						[77.57548046536691, 12.905053107687053],
						[77.57548046536691, 12.90518245145945],
						[77.57548492525945, 12.905882554679259],
						[77.57548492525945, 12.906466718155816],
						[77.57540910706221, 12.906466700711409],
						[77.57511484102889, 12.90647111284071],
						[77.57325985429236, 12.90648898085208],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000ac",
			properties: {
				AREA_SQ_KM: 1.3009984356932236,
				ASS_CONST1: "Bommana Halli",
				ASS_CONST_: "175",
				LAT: 12.9004208934461,
				LON: 77.5774880640308,
				LOST_AREA_SQ_KM: 0.036148950850931796,
				OBJECTID: 173,
				POP_F: 10780.0,
				POP_M: 12519.0,
				POP_SC: 830.0,
				POP_ST: 248.0,
				POP_TOTAL: 23299.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Jaraganahalli",
				WARD_NO: 186.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58575865174213, 12.904620603619824],
						[77.58580324660664, 12.902765617676197],
						[77.58530387056047, 12.902613983438792],
						[77.58479548581602, 12.902431153699958],
						[77.58416231888744, 12.902168064476975],
						[77.58361833988305, 12.901918383084121],
						[77.5837030717149, 12.901151469032822],
						[77.58372528211233, 12.900045586145762],
						[77.58381001386402, 12.89914038147441],
						[77.58382785211944, 12.898632061493265],
						[77.58351131010993, 12.898707805563825],
						[77.58306981122483, 12.898725688829991],
						[77.5828379995731, 12.898685543959145],
						[77.58272650945442, 12.898667707992395],
						[77.58266853454923, 12.898609782347341],
						[77.58266853454923, 12.898511636102752],
						[77.58271313063278, 12.897842778475141],
						[77.58240987691785, 12.89783833192389],
						[77.58169642300088, 12.897842753741134],
						[77.58097404523066, 12.897865099604376],
						[77.58086701295453, 12.897798188919568],
						[77.58018476725806, 12.897383518507612],
						[77.57938665174623, 12.896875148669215],
						[77.57945800754223, 12.89680377515609],
						[77.58002430513078, 12.89509595375962],
						[77.58018922696517, 12.8946991358336],
						[77.5803765345045, 12.894012412150518],
						[77.58062627740773, 12.893191939443113],
						[77.58069763242163, 12.892598843355177],
						[77.58072885022607, 12.892527530147664],
						[77.58079565804749, 12.89230903067313],
						[77.58084025486966, 12.89211281802731],
						[77.58089377103306, 12.892112796265485],
						[77.5812148674816, 12.892108357238847],
						[77.5817633176947, 12.892126179744954],
						[77.58185697019958, 12.892130664864128],
						[77.58190156660321, 12.891475200091083],
						[77.5819104858818, 12.891345865286514],
						[77.58191940515972, 12.891319150989323],
						[77.58193724371344, 12.891274511054915],
						[77.58198184008539, 12.891198755137403],
						[77.58216905721189, 12.890944578188972],
						[77.58239649805874, 12.890681470901388],
						[77.58253474619309, 12.89052989566354],
						[77.58299845770783, 12.890333676108614],
						[77.58360050159457, 12.890155279594945],
						[77.584028532299, 12.890030415322634],
						[77.58486683819879, 12.889727188459725],
						[77.5856828404321, 12.889477517587364],
						[77.58600838275672, 12.889397200615926],
						[77.58624473481584, 12.88933923848015],
						[77.58652122150295, 12.889299137150712],
						[77.5866727555072, 12.889294671741691],
						[77.58751558865251, 12.88917870998359],
						[77.5880238737219, 12.889094038586158],
						[77.58805063010638, 12.890052768504042],
						[77.58818441193375, 12.891051525610559],
						[77.58828251850649, 12.89155098001291],
						[77.58844305635087, 12.891640176078194],
						[77.58862143146597, 12.891742719900785],
						[77.58880872503376, 12.891756078631047],
						[77.58984766532106, 12.891207641644904],
						[77.59032481219072, 12.892215436130044],
						[77.59119428629563, 12.89190324696772],
						[77.59254543787087, 12.891430566964306],
						[77.59398129740471, 12.891229906787922],
						[77.5944360436547, 12.891158601483909],
						[77.5960234880391, 12.890953459984942],
						[77.59778935816621, 12.890632414269358],
						[77.59812378359533, 12.890578870306657],
						[77.59843591310172, 12.891345864748235],
						[77.59881938531348, 12.892416056926761],
						[77.59896653128133, 12.893067119424025],
						[77.59908692329414, 12.893615571159073],
						[77.59924744577809, 12.894369133988837],
						[77.59946593434766, 12.895225260572996],
						[77.59973792973085, 12.89613048638504],
						[77.60008126722919, 12.897370121111608],
						[77.60028200595991, 12.898074621071531],
						[77.60051832814555, 12.898734639221631],
						[77.60073235535823, 12.899327672013596],
						[77.60086612215997, 12.899858269896132],
						[77.60096867660056, 12.900286380263484],
						[77.60103555988115, 12.900808095572858],
						[77.60108014871287, 12.901378814774501],
						[77.60112027864633, 12.90190502904021],
						[77.60120945622513, 12.902324148590562],
						[77.60137889343068, 12.903024255140018],
						[77.60146361193803, 12.90334526847364],
						[77.60129863373346, 12.903380979620882],
						[77.60125404498811, 12.903389876623718],
						[77.6011693263234, 12.903421155260274],
						[77.60105339541596, 12.903447837862968],
						[77.60096421771378, 12.903479075382243],
						[77.60087058105064, 12.903496914365261],
						[77.6006431774023, 12.903523691395412],
						[77.60044698565112, 12.903554891893826],
						[77.60040685547816, 12.90356382972423],
						[77.60037118420134, 12.903572722403398],
						[77.60032659508944, 12.903595062813874],
						[77.60029538270062, 12.903612872758695],
						[77.60017044562926, 12.903635159685622],
						[77.60016152779242, 12.903648521238102],
						[77.60016152779244, 12.903661878694363],
						[77.6001793634654, 12.903675314135612],
						[77.60018836873057, 12.903679709257615],
						[77.60019728656529, 12.903684186372624],
						[77.60019728656529, 12.903693148798434],
						[77.60019282764803, 12.903702070184897],
						[77.60017044562926, 12.903715390616275],
						[77.60014815103585, 12.903693124056133],
						[77.60013031535794, 12.903688651034244],
						[77.60011693859768, 12.90368421905297],
						[77.60010802075661, 12.903688618181672],
						[77.60009018507236, 12.903693107580368],
						[77.6000723493853, 12.90371095443555],
						[77.60005897261816, 12.903710917484407],
						[77.60000100660886, 12.90368866728297],
						[77.59995641735067, 12.903688687726447],
						[77.59986723878143, 12.903724319727623],
						[77.59980035480825, 12.90375550336486],
						[77.59969779930572, 12.90378678945588],
						[77.59963983311086, 12.903795649086113],
						[77.59951052379967, 12.903862538429387],
						[77.5994793111851, 12.903866990775859],
						[77.59943026277337, 12.90387145124087],
						[77.59937675539076, 12.903871475603138],
						[77.59932770693445, 12.90385361618145],
						[77.59928311741018, 12.903822440376791],
						[77.59922960995783, 12.903795663662269],
						[77.59917610248009, 12.903777849354011],
						[77.59911813601724, 12.903764475179726],
						[77.59901557989465, 12.903697605393234],
						[77.59894423645007, 12.903621802173811],
						[77.5989353185163, 12.903586129036212],
						[77.59893531851631, 12.903559328065551],
						[77.59893531851631, 12.90353261327777],
						[77.59893085954917, 12.903505857433336],
						[77.59890856471083, 12.903398793061552],
						[77.5989174826467, 12.903291757693422],
						[77.598979908178, 12.90293504505749],
						[77.59908692329414, 12.902337537284513],
						[77.59920731517852, 12.901945166857166],
						[77.59926528159555, 12.90180247648433],
						[77.59931433007901, 12.901704343049294],
						[77.5993901322388, 12.901539411360833],
						[77.59943472172077, 12.901410041559583],
						[77.59947039329366, 12.901329795867344],
						[77.59947485223947, 12.901276322076557],
						[77.59947485223947, 12.901249521591993],
						[77.59946593434766, 12.901213849207409],
						[77.59943918066799, 12.901191542250073],
						[77.5994079680337, 12.901195994737458],
						[77.59937675539076, 12.90120044721973],
						[77.59932770693445, 12.901213870168649],
						[77.59926974054947, 12.90126288755743],
						[77.59868115710826, 12.901575026745645],
						[77.59817283257492, 12.901838093767688],
						[77.5974593907716, 12.90217700026145],
						[77.59667451214668, 12.902493612244248],
						[77.59637575497442, 12.902564969498876],
						[77.59625536006955, 12.902573856068724],
						[77.59615726042249, 12.90257838080682],
						[77.59592092910447, 12.902618514116474],
						[77.59528327798566, 12.902658565636548],
						[77.59419970873356, 12.902694242391089],
						[77.59402588902338, 12.902712117288635],
						[77.59376279821866, 12.904861406646832],
						[77.59366023723041, 12.90509330799493],
						[77.59351754352659, 12.905227028409207],
						[77.59341498231558, 12.905316221557717],
						[77.59175169401969, 12.905405379700122],
						[77.5917472347688, 12.905628362735714],
						[77.59028021911071, 12.905717516828629],
						[77.59015089907913, 12.90510223123793],
						[77.59009292798243, 12.904812317644959],
						[77.58993239248247, 12.904834616637478],
						[77.58854116269902, 12.904968412307008],
						[77.58857683771359, 12.90518693811553],
						[77.58828697789413, 12.905249315996617],
						[77.58829143728161, 12.905293909883909],
						[77.58828697789413, 12.90573095016015],
						[77.58808630527584, 12.905726505918269],
						[77.5866861338602, 12.905739816965188],
						[77.58648108638064, 12.905739815225639],
						[77.58647662692175, 12.904625104508709],
						[77.58575865174213, 12.904620603619824],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000ad",
			properties: {
				AREA_SQ_KM: 2.867443619884842,
				ASS_CONST1: "Bommana Halli",
				ASS_CONST_: "175",
				LAT: 12.8973177493566,
				LON: 77.5906030466232,
				LOST_AREA_SQ_KM: 0.05275281392155068,
				OBJECTID: 174,
				POP_F: 9450.0,
				POP_M: 11094.0,
				POP_SC: 1176.0,
				POP_ST: 383.0,
				POP_TOTAL: 20544.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Puttenahalli",
				WARD_NO: 187.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.60029092378723, 12.908513397570204],
						[77.60029984161385, 12.908401961933626],
						[77.60032213617728, 12.908143280970522],
						[77.60033551291323, 12.908013961238327],
						[77.60041131438697, 12.90767510952278],
						[77.60048265690396, 12.907416451071327],
						[77.6005272459542, 12.907336197466707],
						[77.60057629388909, 12.907255984968245],
						[77.60065655410018, 12.907157876095866],
						[77.60105339541596, 12.906685216103288],
						[77.60132092809953, 12.906364161732638],
						[77.60151265946583, 12.906118850647882],
						[77.60161967217974, 12.905985137524931],
						[77.60169101393261, 12.905891427953554],
						[77.60178019106027, 12.90575324175575],
						[77.60184707385974, 12.905623899242583],
						[77.60186936811742, 12.905543613215153],
						[77.60188274466992, 12.905503490732738],
						[77.60188720352039, 12.90544105203911],
						[77.60190058007078, 12.905351893811247],
						[77.60189612122082, 12.905240423590406],
						[77.60188274466992, 12.905075440660424],
						[77.60184707385974, 12.904883709882924],
						[77.60174006136154, 12.904451144386034],
						[77.60162413104061, 12.903974030959114],
						[77.60157062469847, 12.903742151765433],
						[77.60146361193803, 12.90334526847364],
						[77.60137889343068, 12.903024255140018],
						[77.60120945622513, 12.902324148590562],
						[77.60112027864633, 12.90190502904021],
						[77.60108014871287, 12.901378814774501],
						[77.60103555988115, 12.900808095572858],
						[77.60096867660056, 12.900286380263484],
						[77.60086612215997, 12.899858269896132],
						[77.60073235535823, 12.899327672013596],
						[77.60051832814555, 12.898734639221631],
						[77.60028200595991, 12.898074621071531],
						[77.60008126722919, 12.897370121111608],
						[77.59973792973085, 12.89613048638504],
						[77.59946593434766, 12.895225260572996],
						[77.59924744577809, 12.894369133988837],
						[77.59908692329414, 12.893615571159073],
						[77.59896653128133, 12.893067119424025],
						[77.59881938531348, 12.892416056926761],
						[77.59843591310172, 12.891345864748235],
						[77.59812378359533, 12.890578870306657],
						[77.59899328507306, 12.890436223778108],
						[77.59953281851907, 12.890346988814033],
						[77.59988507450092, 12.890074999217957],
						[77.60040239656917, 12.88987880046764],
						[77.60098651214594, 12.889660288045453],
						[77.6015617069723, 12.889473056871418],
						[77.602252916088, 12.889218902986395],
						[77.60262299886348, 12.889098466723182],
						[77.60315359735084, 12.888786311217222],
						[77.60316251495115, 12.888826460398167],
						[77.60316697375104, 12.889085088549207],
						[77.6032026441438, 12.889348224940136],
						[77.60323385572823, 12.889401675678787],
						[77.60330965525444, 12.889401679579628],
						[77.60340783611058, 12.889401719179679],
						[77.60349701188093, 12.889432951810662],
						[77.60355051730929, 12.889486434140329],
						[77.60381804407034, 12.889816404084788],
						[77.60400085365872, 12.889713812583183],
						[77.60422379178011, 12.889597887087936],
						[77.60446456445622, 12.889486432739016],
						[77.60470096530545, 12.889415095691707],
						[77.6050442876977, 12.889299149210764],
						[77.60626605931328, 12.888871028882116],
						[77.6074923637424, 12.888465262479505],
						[77.60845105227504, 12.888309257661689],
						[77.60840200776504, 12.887622484581916],
						[77.60842875931857, 12.886748562829078],
						[77.60896387647941, 12.886753012085327],
						[77.60957024044495, 12.88677085846731],
						[77.61050670618016, 12.886846604642106],
						[77.61020343970246, 12.885598124842948],
						[77.61010535218621, 12.884536786775383],
						[77.61084109389861, 12.884354005863651],
						[77.61247315398836, 12.88394819368786],
						[77.61222793997702, 12.886904589589385],
						[77.61250436300607, 12.886913480794082],
						[77.61245086182757, 12.888113061933526],
						[77.61245532026007, 12.888545584936963],
						[77.61257123944345, 12.889245646958011],
						[77.61281208170935, 12.890378223942657],
						[77.61333817384097, 12.890226614256987],
						[77.61357009769102, 12.890618996789499],
						[77.61435485871867, 12.89118530961853],
						[77.61507728511322, 12.892375897977933],
						[77.61506836845557, 12.892447254018553],
						[77.61423002464662, 12.892621180092705],
						[77.61435931707582, 12.89333905820559],
						[77.61449315512715, 12.893597680517589],
						[77.61456448873291, 12.89402135670981],
						[77.61464028063953, 12.894904211914794],
						[77.61507282678447, 12.894823963874435],
						[77.61548307970753, 12.894886389327528],
						[77.61547862139486, 12.895492847695557],
						[77.61625891084314, 12.8954794488473],
						[77.61655770274955, 12.895457182267917],
						[77.61652649485491, 12.89516286808468],
						[77.61645070421766, 12.894471658665811],
						[77.61704374055245, 12.894418202325493],
						[77.61719977922236, 12.894404800195629],
						[77.61719532097764, 12.894605460850508],
						[77.61719086273276, 12.89515840606248],
						[77.61717748799704, 12.895965491402134],
						[77.61775714583725, 12.896027906261171],
						[77.6180693083994, 12.897905184429643],
						[77.61810943228399, 12.897972078941578],
						[77.61837701189812, 12.898025622295227],
						[77.61864450346089, 12.898016682321954],
						[77.61874712914013, 12.89799883324702],
						[77.61890316544931, 12.89799887487404],
						[77.61894328903672, 12.898007771731807],
						[77.61896112173764, 12.898016724207746],
						[77.6190324525131, 12.898070200594635],
						[77.61913507783868, 12.898150421904496],
						[77.61915291050921, 12.898154893129723],
						[77.61915736867643, 12.89815941496928],
						[77.6192064085039, 12.89818166440394],
						[77.61925544831003, 12.898199518754499],
						[77.61934461153916, 12.898212912326828],
						[77.61948281440473, 12.898230716397562],
						[77.6196567696996, 12.898257466976288],
						[77.61971026745483, 12.898257436761341],
						[77.6199466363174, 12.900754566903085],
						[77.61975039075458, 12.900750104629106],
						[77.61940256760022, 12.900825873967984],
						[77.61862221252159, 12.90086606981008],
						[77.6192331574917, 12.902426773442505],
						[77.61950519258839, 12.902342040939313],
						[77.6196166463665, 12.902912802466092],
						[77.62002688273405, 12.90359057470128],
						[77.6201427941238, 12.90392050350084],
						[77.6202453310229, 12.904036492039477],
						[77.62052182178662, 12.90484353455835],
						[77.62084735098374, 12.904754375584485],
						[77.62120854393191, 12.904718716229253],
						[77.62160540028775, 12.904656319568433],
						[77.62182830357429, 12.906711950386335],
						[77.62188188771029, 12.906698566504218],
						[77.62205129383211, 12.906711918282014],
						[77.62217166118477, 12.906725340034837],
						[77.62226090951636, 12.906774334502954],
						[77.6223322382006, 12.90685013825641],
						[77.62242139899233, 12.906868015679818],
						[77.6225417659491, 12.906859115307537],
						[77.6227023424368, 12.906836795047742],
						[77.62285391524217, 12.906827834150159],
						[77.62285837326279, 12.906921472188573],
						[77.6228628312832, 12.907122152827776],
						[77.6228628312832, 12.907438799320206],
						[77.62251947578163, 12.907389728990449],
						[77.62238127664479, 12.90737183523592],
						[77.62220732556082, 12.907385242200274],
						[77.62224307733823, 12.907594830565174],
						[77.62224753538302, 12.90794711324683],
						[77.62210479044919, 12.90796045972039],
						[77.62214937094399, 12.908379645190257],
						[77.6221270806988, 12.908388577634959],
						[77.62173914231265, 12.908388569097884],
						[77.62141807377257, 12.908424247831023],
						[77.62144036415819, 12.90862043887777],
						[77.62127095711672, 12.908660520986032],
						[77.62092759675825, 12.908727453350243],
						[77.62086518338302, 12.908575799161124],
						[77.62064664888328, 12.90849554404095],
						[77.62055748668551, 12.908852316627772],
						[77.62054857046185, 12.909097521737758],
						[77.62055302857377, 12.9094186075873],
						[77.62057977724157, 12.909944764519567],
						[77.62061098401264, 12.91013207576531],
						[77.62067339752883, 12.910341650173727],
						[77.6207492727372, 12.910519979070346],
						[77.62081614427821, 12.910676072425384],
						[77.62088747387814, 12.910760757035979],
						[77.62095880343288, 12.91078312789157],
						[77.62103459103525, 12.910800885310794],
						[77.6210836300449, 12.910841065832287],
						[77.6210926336397, 12.910881228838038],
						[77.62113721453547, 12.91105065074013],
						[77.62116842115198, 12.911393964914065],
						[77.62116396306442, 12.911492092763048],
						[77.6211595049767, 12.911603579909041],
						[77.62114613071249, 12.91173730854598],
						[77.6211595049767, 12.911880072957718],
						[77.62117287923934, 12.9119424234161],
						[77.62118625350037, 12.912004860100796],
						[77.62123529244394, 12.912080723389321],
						[77.62132891218593, 12.912236718867476],
						[77.62132891218593, 12.91229472408921],
						[77.62107471386292, 12.912584579016956],
						[77.62090976436885, 12.912580141059372],
						[77.62071360789909, 12.912589035617676],
						[77.62026762164078, 12.912553320491243],
						[77.61985738615061, 12.912544394852258],
						[77.61970135116407, 12.912544433186671],
						[77.61959435561971, 12.912553366404937],
						[77.61954531596123, 12.912557830671037],
						[77.61949173071282, 12.912566731788036],
						[77.61943377469765, 12.91258904174264],
						[77.61934461153916, 12.912629165880883],
						[77.61911724516531, 12.912762919602661],
						[77.6185597978681, 12.913159797176068],
						[77.61845725943368, 12.913262371694403],
						[77.61827447329723, 12.913471947989104],
						[77.61814063973989, 12.913623532790075],
						[77.61802026807659, 12.913766194350542],
						[77.6177705205041, 12.913984721050632],
						[77.61759664971092, 12.9141318915424],
						[77.61720869571124, 12.914452923932762],
						[77.61678953255317, 12.914836415864508],
						[77.61666470117976, 12.91498360432946],
						[77.61661566024519, 12.915179753922558],
						[77.61658891063553, 12.91529121092936],
						[77.61654432793861, 12.91537147609997],
						[77.61649974522406, 12.91543389994145],
						[77.61647299558685, 12.91539383487528],
						[77.61640157577273, 12.915273372585542],
						[77.61637482611219, 12.91518417933996],
						[77.61633024333052, 12.915014752374436],
						[77.61629903537286, 12.914591189198608],
						[77.6161296205946, 12.91349865602766],
						[77.6159244517887, 12.91233038889621],
						[77.61572382832989, 12.91145193994275],
						[77.6155321211353, 12.910609175065796],
						[77.61526453476087, 12.90958360892522],
						[77.6151753683008, 12.909338370879984],
						[77.61493898942196, 12.908700675357442],
						[77.61490778107876, 12.90862489187507],
						[77.61470715580889, 12.908294950885317],
						[77.61461353056066, 12.90815671605982],
						[77.6144083589929, 12.90782225014795],
						[77.61431919185505, 12.907728613869395],
						[77.61417652428766, 12.907554681811376],
						[77.6141051904362, 12.907322798257157],
						[77.61389555956718, 12.906752066579012],
						[77.61373951631924, 12.906538025089661],
						[77.61356118091405, 12.906341792220486],
						[77.61322225544673, 12.905900362854844],
						[77.61300379353335, 12.905253808567808],
						[77.61272282588791, 12.904486805236534],
						[77.61125136223261, 12.904718685163095],
						[77.61021681526624, 12.904821236345587],
						[77.60934285434017, 12.905021952290548],
						[77.60945431817434, 12.905441123049693],
						[77.60938743988706, 12.905481187257816],
						[77.6089683350499, 12.905699690858377],
						[77.60864723010167, 12.905829030013788],
						[77.60852684829398, 12.905851366921535],
						[77.6084376765017, 12.905851324494865],
						[77.60832175306625, 12.905842418508232],
						[77.60820137091096, 12.905833471559244],
						[77.60800510488465, 12.905788921662035],
						[77.60774650529571, 12.905730962106047],
						[77.60744777744588, 12.90569969477061],
						[77.60704641255941, 12.905668515729905],
						[77.6065202910559, 12.905637314299337],
						[77.6061768857327, 12.905628383449391],
						[77.605913823259, 12.905650701161369],
						[77.60544111357602, 12.905690824471188],
						[77.60446456445622, 12.905762167057695],
						[77.60398301858999, 12.905793332635836],
						[77.60388938443278, 12.90581565410831],
						[77.60386709057435, 12.90584690472842],
						[77.60386263180214, 12.90588702349136],
						[77.60385817302975, 12.905940500134047],
						[77.60386709057435, 12.905985137091179],
						[77.60387600811823, 12.906047527120654],
						[77.6039027607457, 12.906435497050298],
						[77.60395180721288, 12.90693932761448],
						[77.60399193612471, 12.907710756754906],
						[77.60403652378777, 12.908705184506372],
						[77.60386709057435, 12.908696213581301],
						[77.60312684454568, 12.908633809137749],
						[77.60165088420217, 12.90858031076884],
						[77.60029092378723, 12.908513397570204],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000ae",
			properties: {
				AREA_SQ_KM: 4.278603005235816,
				ASS_CONST1: "Bommana Halli",
				ASS_CONST_: "175",
				LAT: 12.8991566741957,
				LON: 77.6101589622278,
				LOST_AREA_SQ_KM: 0.14368958003115184,
				OBJECTID: 175,
				POP_F: 10460.0,
				POP_M: 12050.0,
				POP_SC: 3988.0,
				POP_ST: 260.0,
				POP_TOTAL: 22510.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Bilekhalli",
				WARD_NO: 188.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.63100515443095, 12.903639644140283],
						[77.63113897268519, 12.903425570260415],
						[77.63075097797447, 12.903496940503645],
						[77.6299885325636, 12.903519235548357],
						[77.62929731884257, 12.903595002126263],
						[77.62940439256637, 12.903933908033718],
						[77.62898964520754, 12.903974005192117],
						[77.62836537980769, 12.904063220609938],
						[77.62748246926981, 12.904362009481012],
						[77.62730415563793, 12.903920503211289],
						[77.6272684054689, 12.903648522033054],
						[77.62726394762306, 12.90341664617502],
						[77.62666650727358, 12.903706481961345],
						[77.62651931007112, 12.90366635820543],
						[77.62617150783981, 12.903510303846632],
						[77.62590394675857, 12.903389883178207],
						[77.62552939513965, 12.903354242593503],
						[77.62529303800366, 12.90334976355798],
						[77.62474016665406, 12.90314461554618],
						[77.62420057907903, 12.902984141227423],
						[77.624031176193, 12.902551600543102],
						[77.6237546940515, 12.902034315543228],
						[77.62352724917534, 12.901552727605214],
						[77.62317943769351, 12.901093488846996],
						[77.62275583874437, 12.900540574029947],
						[77.62247935346906, 12.900161533324004],
						[77.62214491289531, 12.899613054329183],
						[77.62206020993673, 12.899363310595133],
						[77.62210033239874, 12.89932317097753],
						[77.62199333913492, 12.899064607328485],
						[77.62188188771029, 12.898814880297092],
						[77.62176143263468, 12.898480440881578],
						[77.62159648414709, 12.898114749503849],
						[77.62152515499652, 12.897914166708913],
						[77.62144482223479, 12.897691178906463],
						[77.62135566066934, 12.897379006581629],
						[77.62125758286575, 12.896964374991844],
						[77.62117733732653, 12.896692358481562],
						[77.6210926336397, 12.896554135590723],
						[77.6209766358145, 12.896411402408267],
						[77.62083397668242, 12.896282132653551],
						[77.62075373084117, 12.89603683293532],
						[77.62048615687644, 12.895448224077349],
						[77.62027653788671, 12.894837308868468],
						[77.62012941973875, 12.894204132224472],
						[77.61979943032372, 12.892723750270335],
						[77.61963002081247, 12.89156886212723],
						[77.61992880377258, 12.891506431143874],
						[77.62006700592093, 12.891461841513662],
						[77.62011158722291, 12.891390463808614],
						[77.62016062663469, 12.891127391211487],
						[77.62017845914278, 12.890449630977235],
						[77.62018737539574, 12.889736118462514],
						[77.62010712909351, 12.889343739000344],
						[77.61999567580104, 12.889018205575372],
						[77.61990196753561, 12.888839839055532],
						[77.62009375470423, 12.88882650189086],
						[77.62052182178662, 12.888772996886459],
						[77.62098109390946, 12.888692715955223],
						[77.62115058880073, 12.888665991444276],
						[77.62131553794184, 12.88865257880302],
						[77.6213601187493, 12.888661513837068],
						[77.62142253185006, 12.888697151347348],
						[77.62147157069066, 12.888764034160845],
						[77.62148503232943, 12.888813105095014],
						[77.62152961306975, 12.888830915827588],
						[77.62159202607651, 12.888826483495876],
						[77.62192646829338, 12.88878186449326],
						[77.62236790252577, 12.888728390568481],
						[77.62274692269487, 12.88869718596035],
						[77.62309919352471, 12.88863919742478],
						[77.62338459327665, 12.88859904363694],
						[77.62360749303888, 12.88855892346331],
						[77.62376806800569, 12.888545558826701],
						[77.62393747128787, 12.888545541111522],
						[77.62405792403408, 12.888549995430768],
						[77.62428528042639, 12.88857230356413],
						[77.62476691432657, 12.888590111425712],
						[77.62519496363207, 12.888581233748006],
						[77.62560963757512, 12.888550001717073],
						[77.62560517966354, 12.888402864776552],
						[77.62612247104765, 12.888398414743184],
						[77.62647027343058, 12.888407335963599],
						[77.62667096514312, 12.888389506030157],
						[77.62675566463035, 12.888389496030713],
						[77.6269741872647, 12.8883181412707],
						[77.62708117579164, 12.888291360453096],
						[77.62740222817045, 12.888211107962393],
						[77.62786593002923, 12.88810853206565],
						[77.62808890831958, 12.888059534996325],
						[77.62825393469586, 12.888059477614814],
						[77.62866413957036, 12.888081798420705],
						[77.62867751294083, 12.888139741338117],
						[77.62869980188806, 12.888536598309129],
						[77.62871317525429, 12.88883539665203],
						[77.62868197073064, 12.888995915017063],
						[77.6286507661983, 12.889160914610965],
						[77.62864185061606, 12.889223310175579],
						[77.62865968177985, 12.889727245231061],
						[77.62869534409897, 12.890097342043722],
						[77.62869980188806, 12.890146329506141],
						[77.62874883755644, 12.890208818313615],
						[77.62888711627794, 12.890396122424502],
						[77.62895398298176, 12.890494190407018],
						[77.62911900808848, 12.890676997107242],
						[77.62920370573194, 12.890743858701269],
						[77.62929286107718, 12.890797403440661],
						[77.62933743872327, 12.890864247342757],
						[77.62937755858965, 12.891024809890116],
						[77.62938656152066, 12.891096161745878],
						[77.62944451240887, 12.89135030021538],
						[77.62948908999485, 12.89169368293907],
						[77.62952029429451, 12.891756097826258],
						[77.62963619590319, 12.891863141767454],
						[77.62973435380866, 12.891947881569285],
						[77.62976555804025, 12.891974619646346],
						[77.62981013549894, 12.891992430059673],
						[77.62985025519666, 12.892001324031403],
						[77.62992603680883, 12.892019157859728],
						[77.62994832550856, 12.892037025402761],
						[77.62997961708741, 12.892086087353032],
						[77.63000636351383, 12.892166386938282],
						[77.63010889142281, 12.89234026969254],
						[77.63017575740002, 12.892465140660725],
						[77.63021587696727, 12.892549784064665],
						[77.63023370788143, 12.892643447068918],
						[77.63024716847164, 12.892768356285924],
						[77.63030066119082, 12.893142848437286],
						[77.62952029429451, 12.893205329395876],
						[77.62960499163569, 12.89424875700192],
						[77.63085359261034, 12.893918797349313],
						[77.63162494750058, 12.893762724753286],
						[77.63186129132772, 12.893722549544647],
						[77.63203522748069, 12.893722572686437],
						[77.63218687511302, 12.893722566767186],
						[77.63232060455563, 12.893753764441794],
						[77.63252128582585, 12.893807286943984],
						[77.63261043854001, 12.893807316333499],
						[77.63276208539625, 12.893811791333938],
						[77.63344873280816, 12.895202980934224],
						[77.63362266650444, 12.895109326343158],
						[77.63409980283484, 12.894899742665018],
						[77.63433614145761, 12.894850688420265],
						[77.63469729108523, 12.894859638667512],
						[77.63492025582518, 12.894980064835122],
						[77.63532606624894, 12.895051383971731],
						[77.63596812299495, 12.895194036014377],
						[77.63616434025843, 12.895243121922823],
						[77.63607073286724, 12.895390249493191],
						[77.63522791322676, 12.896759197976259],
						[77.63441192004541, 12.898083583436241],
						[77.63363603928502, 12.899345509861428],
						[77.6329448479926, 12.900491515278588],
						[77.63245442124382, 12.901271861147876],
						[77.63237409628812, 12.901405577396954],
						[77.63308757916795, 12.900901751219235],
						[77.63389912438328, 12.900549458639134],
						[77.6344520381006, 12.900268507921373],
						[77.63498266140031, 12.900090171138105],
						[77.63495591615806, 12.900701059078534],
						[77.63500049155827, 12.9015571869744],
						[77.63450998637728, 12.901521545371192],
						[77.63456356447074, 12.902243917599252],
						[77.63479990211812, 12.902257263026014],
						[77.63485785021538, 12.902948477419656],
						[77.63485785021538, 12.903287360111953],
						[77.63492025582518, 12.904847987527788],
						[77.6349470010759, 12.905374230911175],
						[77.6348355624892, 12.90540097420114],
						[77.63489351056809, 12.905989557909527],
						[77.63490242565449, 12.906100993553808],
						[77.63477761438044, 12.906105465287883],
						[77.63508081463674, 12.907238146221465],
						[77.63523682828664, 12.907269294856984],
						[77.63511647491866, 12.907313884648822],
						[77.63467054577507, 12.907193482354614],
						[77.63393478504024, 12.906983915987153],
						[77.6332615134232, 12.906645033055629],
						[77.63259260800284, 12.906622754131782],
						[77.63252574346323, 12.906622751029783],
						[77.63254803164753, 12.906047564744426],
						[77.63255694692, 12.905472259975255],
						[77.63246779416342, 12.905013024269048],
						[77.63255694692, 12.90438872244975],
						[77.63237855393133, 12.904125613938803],
						[77.63233397749114, 12.90400075757185],
						[77.6323161469101, 12.903938369843166],
						[77.6322537398542, 12.903960643526062],
						[77.63196390495003, 12.903902682158353],
						[77.63171864600038, 12.903724292530525],
						[77.63100515443095, 12.903639644140283],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000af",
			properties: {
				AREA_SQ_KM: 2.1614604087375486,
				ASS_CONST1: "Bommana Halli",
				ASS_CONST_: "175",
				LAT: 12.8967688850618,
				LON: 77.6275171672937,
				LOST_AREA_SQ_KM: 0.025350230421926464,
				OBJECTID: 176,
				POP_F: 10751.0,
				POP_M: 12307.0,
				POP_SC: 1529.0,
				POP_ST: 282.0,
				POP_TOTAL: 23058.0,
				RESERVATIO: "Backward Category - B (Woman)",
				WARD_NAME: "Hongasandra",
				WARD_NO: 189.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.65466071303153, 12.89798550433403],
						[77.65401413165756, 12.897940836343649],
						[77.65328732395018, 12.897989909425963],
						[77.65277452712388, 12.898061239512435],
						[77.65225281424455, 12.898163860362196],
						[77.65162850338086, 12.898279778065572],
						[77.65152153823685, 12.898404652307393],
						[77.65116026795212, 12.898917405473721],
						[77.6507589716803, 12.899077987792303],
						[77.64997420260887, 12.899434646320545],
						[77.64921171292332, 12.899742374772043],
						[77.64913585693245, 12.899742380149569],
						[77.64885052259037, 12.899505990829955],
						[77.6486944404655, 12.900001023522192],
						[77.64858292813133, 12.900843794024892],
						[77.64857401413119, 12.90153045991583],
						[77.64851607311294, 12.902199279194138],
						[77.64856064312963, 12.902756718780168],
						[77.64873455341754, 12.903528129320698],
						[77.64879249432293, 12.903822471403819],
						[77.64840456060145, 12.903831388721715],
						[77.64799434042425, 12.903835795456],
						[77.64797205530563, 12.903907132614451],
						[77.64759748986499, 12.903929441852663],
						[77.6475083490486, 12.903916056390365],
						[77.6475083490486, 12.904023104083684],
						[77.64724966549421, 12.904098858296152],
						[77.6468973829784, 12.904205918741045],
						[77.64669227032188, 12.904241562183149],
						[77.64634444314493, 12.904286174101864],
						[77.64612150108836, 12.904339670095547],
						[77.64581387353276, 12.904415502195324],
						[77.64531441392975, 12.904531401412903],
						[77.6447837520525, 12.90465184479951],
						[77.64425763223345, 12.904772244785146],
						[77.6437849962466, 12.904865889514284],
						[77.64315618104494, 12.904999661347116],
						[77.64240710416216, 12.905227071666257],
						[77.64197011848962, 12.905316206761688],
						[77.64163119123079, 12.905378645285573],
						[77.6411318004141, 12.905423258720141],
						[77.64063240737624, 12.905503553184566],
						[77.64028010409127, 12.905579314020628],
						[77.63962015517164, 12.905672976809798],
						[77.63912075541108, 12.905704139604946],
						[77.63894237284421, 12.905748807454595],
						[77.63866592737558, 12.905855765110402],
						[77.63810848958096, 12.906074288853814],
						[77.63760908309871, 12.906270499152924],
						[77.63698040811362, 12.906551382465894],
						[77.63642296195431, 12.90674318237114],
						[77.63579428033134, 12.90706422557415],
						[77.63523682828664, 12.907269294856984],
						[77.63508081463674, 12.907238146221465],
						[77.63477761438044, 12.906105465287883],
						[77.63490242565449, 12.906100993553808],
						[77.63489351056809, 12.905989557909527],
						[77.6348355624892, 12.90540097420114],
						[77.6349470010759, 12.905374230911175],
						[77.63492025582518, 12.904847987527788],
						[77.63485785021538, 12.903287360111953],
						[77.63485785021538, 12.902948477419656],
						[77.63479990211812, 12.902257263026014],
						[77.63456356447074, 12.902243917599252],
						[77.63450998637728, 12.901521545371192],
						[77.63500049155827, 12.9015571869744],
						[77.63495591615806, 12.900701059078534],
						[77.63498266140031, 12.900090171138105],
						[77.6344520381006, 12.900268507921373],
						[77.63389912438328, 12.900549458639134],
						[77.63308757916795, 12.900901751219235],
						[77.63237409628812, 12.901405577396954],
						[77.63245442124382, 12.901271861147876],
						[77.6329448479926, 12.900491515278588],
						[77.63363603928502, 12.899345509861428],
						[77.63441192004541, 12.898083583436241],
						[77.63522791322676, 12.896759197976259],
						[77.63607073286724, 12.895390249493191],
						[77.63616434025843, 12.895243121922823],
						[77.63596812299495, 12.895194036014377],
						[77.63532606624894, 12.895051383971731],
						[77.63492025582518, 12.894980064835122],
						[77.63469729108523, 12.894859638667512],
						[77.63433614145761, 12.894850688420265],
						[77.63409980283484, 12.894899742665018],
						[77.63362266650444, 12.895109326343158],
						[77.63344873280816, 12.895202980934224],
						[77.63276208539625, 12.893811791333938],
						[77.63261043854001, 12.893807316333499],
						[77.63252128582585, 12.893807286943984],
						[77.63232060455563, 12.893753764441794],
						[77.63218687511302, 12.893722566767186],
						[77.63203522748069, 12.893722572686437],
						[77.63186129132772, 12.893722549544647],
						[77.63162494750058, 12.893762724753286],
						[77.63085359261034, 12.893918797349313],
						[77.62960499163569, 12.89424875700192],
						[77.62952029429451, 12.893205329395876],
						[77.63030066119082, 12.893142848437286],
						[77.63024716847164, 12.892768356285924],
						[77.63101406982581, 12.89273711532484],
						[77.63181225701686, 12.892665740301005],
						[77.63297613873732, 12.892451770598846],
						[77.63435842928286, 12.892135143480171],
						[77.6343495141533, 12.892108348049323],
						[77.63432276876036, 12.891979099165766],
						[77.63421124215122, 12.891243343725105],
						[77.63418895429679, 12.890485219542638],
						[77.63420678458068, 12.890088366531634],
						[77.63421124215122, 12.889999214527686],
						[77.63428710822653, 12.889847628579387],
						[77.6343093960615, 12.88980301792478],
						[77.63415775129315, 12.889731673558238],
						[77.63331500470787, 12.889357134154952],
						[77.6331544433733, 12.889308028817998],
						[77.63282003454435, 12.889272350885745],
						[77.63237409628812, 12.889281336357792],
						[77.6317231036696, 12.8892768380161],
						[77.63160265913326, 12.889263425322953],
						[77.63151796329711, 12.889227760632753],
						[77.63144218275805, 12.889152018973883],
						[77.63200848153699, 12.887203394801519],
						[77.63210209231208, 12.887029478897938],
						[77.63244096091792, 12.886146590812947],
						[77.63425136027807, 12.886244690202625],
						[77.63474632413732, 12.886293701909867],
						[77.63475523923542, 12.886650454274832],
						[77.63516105025516, 12.88669504444733],
						[77.63504515434349, 12.887292566261017],
						[77.63499157647964, 12.887493211483868],
						[77.63498711894006, 12.887555647774073],
						[77.63497820386038, 12.887627006120416],
						[77.63498266140031, 12.887684955463234],
						[77.63502732419262, 12.887742925351569],
						[77.6350763571007, 12.887765168781112],
						[77.63514322012269, 12.887787486493716],
						[77.63520562557399, 12.88779640702109],
						[77.63527248851906, 12.887796405485382],
						[77.63533052377507, 12.88779193296763],
						[77.63544196187087, 12.88773399224574],
						[77.63553565718824, 12.88757343364382],
						[77.63563818005328, 12.887359436850982],
						[77.63565155259398, 12.887337151803326],
						[77.6364051320221, 12.88679759394336],
						[77.63632935477862, 12.886739606849577],
						[77.63653894384628, 12.88658801837131],
						[77.63716762130201, 12.886173318465548],
						[77.63785878661739, 12.885896887075674],
						[77.6384340552643, 12.88569171245204],
						[77.63835373458339, 12.88553561654042],
						[77.63810848958096, 12.885054114130211],
						[77.63895574499159, 12.884790975293427],
						[77.63908955377725, 12.885080860550769],
						[77.63916532915866, 12.885250268593344],
						[77.63921436026055, 12.885352824534028],
						[77.63940165712475, 12.885874569203892],
						[77.63943731603503, 12.885981573041926],
						[77.63972713166038, 12.886730708853277],
						[77.63980745135906, 12.886739619760924],
						[77.64061003339746, 12.886958133738165],
						[77.64115854418581, 12.887123092751848],
						[77.64110505663602, 12.887328228228705],
						[77.64352184690841, 12.888188865089404],
						[77.6437849962466, 12.890627914576434],
						[77.64386076845908, 12.890627990963658],
						[77.64534561383357, 12.89045854156396],
						[77.64637573015624, 12.890293536810264],
						[77.64715161028833, 12.890119607863417],
						[77.64853390112172, 12.889869930632004],
						[77.64870335445609, 12.889816381121605],
						[77.64917151275021, 12.891238844098872],
						[77.64938562220186, 12.891542055219176],
						[77.64961747169004, 12.892068201719912],
						[77.64996528871927, 12.892643481796279],
						[77.65028190614753, 12.893058186736834],
						[77.65052266762902, 12.893263304389189],
						[77.65097744761613, 12.893633398866887],
						[77.65146351135134, 12.893990074697285],
						[77.65186480510202, 12.894293331658938],
						[77.65219033088098, 12.89445388045168],
						[77.65247574411529, 12.894520733156833],
						[77.65304202419314, 12.89464112152036],
						[77.65349242454633, 12.894609908833933],
						[77.65401413165756, 12.894574247524357],
						[77.65449572546405, 12.894378095578297],
						[77.65487472461508, 12.894150599559845],
						[77.65526718007519, 12.893981228453727],
						[77.65563726317922, 12.893918780658359],
						[77.65606082550615, 12.893802857955654],
						[77.65638188241144, 12.893744883635444],
						[77.65677878926853, 12.89375377865125],
						[77.65699279680788, 12.893825156137732],
						[77.65723808791935, 12.894052552165224],
						[77.65716669401748, 12.89404363814094],
						[77.65720243466681, 12.894088259441821],
						[77.65519132818068, 12.896130523690983],
						[77.65466071303153, 12.89798550433403],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b0",
			properties: {
				AREA_SQ_KM: 3.5547815740339126,
				ASS_CONST1: "Bommana Halli",
				ASS_CONST_: "175",
				LAT: 12.8961667446936,
				LON: 77.6418230888484,
				LOST_AREA_SQ_KM: 0.1727187054568721,
				OBJECTID: 177,
				POP_F: 12766.0,
				POP_M: 14625.0,
				POP_SC: 1918.0,
				POP_ST: 159.0,
				POP_TOTAL: 27391.0,
				RESERVATIO: "Backward Category A (Women)",
				WARD_NAME: "Mangammanapalya",
				WARD_NO: 190.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.65723808791935, 12.894052552165224],
						[77.65699279680788, 12.893825156137732],
						[77.65677878926853, 12.89375377865125],
						[77.65638188241144, 12.893744883635444],
						[77.65606082550615, 12.893802857955654],
						[77.65563726317922, 12.893918780658359],
						[77.65526718007519, 12.893981228453727],
						[77.65487472461508, 12.894150599559845],
						[77.65449572546405, 12.894378095578297],
						[77.65401413165756, 12.894574247524357],
						[77.65349242454633, 12.894609908833933],
						[77.65304202419314, 12.89464112152036],
						[77.65247574411529, 12.894520733156833],
						[77.65219033088098, 12.89445388045168],
						[77.65186480510202, 12.894293331658938],
						[77.65146351135134, 12.893990074697285],
						[77.65097744761613, 12.893633398866887],
						[77.65052266762902, 12.893263304389189],
						[77.65028190614753, 12.893058186736834],
						[77.64996528871927, 12.892643481796279],
						[77.64961747169004, 12.892068201719912],
						[77.64938562220186, 12.891542055219176],
						[77.64917151275021, 12.891238844098872],
						[77.64870335445609, 12.889816381121605],
						[77.64853390112172, 12.889869930632004],
						[77.64715161028833, 12.890119607863417],
						[77.64637573015624, 12.890293536810264],
						[77.64534561383357, 12.89045854156396],
						[77.64386076845908, 12.890627990963658],
						[77.6437849962466, 12.890627914576434],
						[77.64352184690841, 12.888188865089404],
						[77.64110505663602, 12.887328228228705],
						[77.64115854418581, 12.887123092751848],
						[77.64061003339746, 12.886958133738165],
						[77.63980745135906, 12.886739619760924],
						[77.63972713166038, 12.886730708853277],
						[77.63943731603503, 12.885981573041926],
						[77.63940165712475, 12.885874569203892],
						[77.63921436026055, 12.885352824534028],
						[77.63916532915866, 12.885250268593344],
						[77.63908955377725, 12.885080860550769],
						[77.63895574499159, 12.884790975293427],
						[77.63810848958096, 12.885054114130211],
						[77.6373281769018, 12.882458916130181],
						[77.63732371945494, 12.882378650759865],
						[77.63728360242514, 12.881941662423458],
						[77.63729697477, 12.881772198330957],
						[77.6373058896657, 12.881678524618472],
						[77.63731480456066, 12.881629487337955],
						[77.63731480456066, 12.881593812715156],
						[77.63734600668752, 12.88155367860909],
						[77.6373638364704, 12.881522472463507],
						[77.63734600668752, 12.881326274744973],
						[77.63726577262953, 12.880639554854612],
						[77.63720328092158, 12.880108961604446],
						[77.63718099366068, 12.879908245658832],
						[77.63718990856557, 12.879854728298008],
						[77.6372211981281, 12.87980572339145],
						[77.63726577262953, 12.879738824847866],
						[77.63756005129603, 12.879234964312442],
						[77.63775180834904, 12.878945090767672],
						[77.63780529749594, 12.878860349174236],
						[77.63786770146848, 12.878820236662554],
						[77.63794802249694, 12.878762278777728],
						[77.63807728766591, 12.878659689740157],
						[77.6381218618276, 12.878592877521099],
						[77.63813969148732, 12.87856606646807],
						[77.63870604391165, 12.877812522055736],
						[77.63903597784237, 12.877379985464414],
						[77.63938382766536, 12.87684490096892],
						[77.63971375960487, 12.876323181906516],
						[77.6397851646149, 12.87618492222872],
						[77.63981190870736, 12.876149229658703],
						[77.63984756748737, 12.876051145616435],
						[77.63996800318517, 12.875672143121479],
						[77.64019541479647, 12.87490962265747],
						[77.64030239073719, 12.874570738821248],
						[77.64035587866934, 12.87441017227421],
						[77.64036925064839, 12.87436117710298],
						[77.64094450643566, 12.874249657029806],
						[77.6413993252423, 12.871632148051603],
						[77.6414126970971, 12.871467176714587],
						[77.64139041067156, 12.871306626543557],
						[77.6412432327542, 12.87085631059798],
						[77.64085981764197, 12.869995735204713],
						[77.64080178524283, 12.869817288005967],
						[77.64214403899106, 12.869585436721342],
						[77.64281734476354, 12.869826240775387],
						[77.64381619657557, 12.870053665510843],
						[77.64410154371973, 12.87013841017981],
						[77.64417285854353, 12.870512910906342],
						[77.64484178239317, 12.870423755057086],
						[77.64658084339136, 12.870236514699371],
						[77.64689292591144, 12.870990048702598],
						[77.64704009642062, 12.871302245793768],
						[77.64705792466397, 12.87184619614695],
						[77.64780260086664, 12.871872996496492],
						[77.64831542042634, 12.872376815198333],
						[77.64949704634665, 12.872309962475438],
						[77.6516999008099, 12.872256417103475],
						[77.65295734453002, 12.870749247920136],
						[77.65383577276585, 12.869607764217314],
						[77.65401858844152, 12.869710287729914],
						[77.65494166322986, 12.869737095798817],
						[77.65532957433705, 12.869741485094586],
						[77.65596723471094, 12.869732581080758],
						[77.6559047534176, 12.869050362293379],
						[77.65590029670862, 12.868992419220074],
						[77.65596277800445, 12.868987977830948],
						[77.65613667681225, 12.868952296086153],
						[77.65626600841395, 12.868934400964958],
						[77.65652012709936, 12.868952248439937],
						[77.65681444265584, 12.868978985028994],
						[77.65682781267317, 12.869549784330399],
						[77.6573405909572, 12.869549763220032],
						[77.65733613430534, 12.86907261870008],
						[77.65789802058481, 12.86908604435712],
						[77.65793367361603, 12.870379211961389],
						[77.65781325720522, 12.870392533589017],
						[77.65782662710338, 12.870446075791133],
						[77.65802289352928, 12.871400320433008],
						[77.65871847435427, 12.8718730043877],
						[77.65904843681348, 12.87170350529412],
						[77.6618041446552, 12.870160674993354],
						[77.66247305070101, 12.869563156962608],
						[77.66383304539636, 12.868934432198614],
						[77.66460897945686, 12.868501918148713],
						[77.66527778136937, 12.868002446472143],
						[77.66575495781987, 12.867369277986494],
						[77.66594221035585, 12.866976885668187],
						[77.6663390833374, 12.866120698722792],
						[77.66671812979874, 12.866138603863394],
						[77.667204125251, 12.866321424319096],
						[77.66802014199511, 12.866415041945189],
						[77.66944263045632, 12.86694122098652],
						[77.67130651732775, 12.867873174946672],
						[77.67264429923081, 12.868319073260114],
						[77.6729073801849, 12.868555425784553],
						[77.67285382041278, 12.868858616783319],
						[77.67289392471437, 12.869170733696588],
						[77.67313026894477, 12.869549811568579],
						[77.67333542057428, 12.869875277225837],
						[77.67374563533188, 12.870392555226202],
						[77.67402653764734, 12.870753760486501],
						[77.67488269559338, 12.87180165792689],
						[77.6752037858976, 12.872274302211181],
						[77.67541784558821, 12.872541860479167],
						[77.67543121338387, 12.872604281453496],
						[77.67544458117794, 12.87267557838045],
						[77.67544903710893, 12.872764807531759],
						[77.67540893372356, 12.872822795480035],
						[77.67528408017245, 12.872903044880456],
						[77.67517705025033, 12.873250849135767],
						[77.67503882859933, 12.87385285396364],
						[77.67493625439393, 12.87432099976291],
						[77.67476675337127, 12.875021057170517],
						[77.67466872225019, 12.875195046227343],
						[77.6746196192856, 12.87525743350603],
						[77.67459288349865, 12.875310972229308],
						[77.67429415869938, 12.876323148953674],
						[77.67407118488661, 12.876827038010818],
						[77.67369216333373, 12.877513712708598],
						[77.6735762198774, 12.877883854484937],
						[77.67198873479917, 12.880826863234999],
						[77.67435654235895, 12.880443344445878],
						[77.67443238127076, 12.881089931915735],
						[77.67442792529926, 12.881094374039893],
						[77.6744190133557, 12.881125578885339],
						[77.67441455738366, 12.881165699503331],
						[77.67441455738366, 12.881201378007663],
						[77.6744190133557, 12.88124157711601],
						[77.67442346932756, 12.881272727335665],
						[77.67442792529926, 12.88131292647049],
						[77.67444129321325, 12.881339673814201],
						[77.67446802903643, 12.881366366506395],
						[77.6745661477053, 12.882213611978205],
						[77.67453049997093, 12.882218124150965],
						[77.67455277980625, 12.882494530045145],
						[77.67461516332155, 12.88301630326164],
						[77.67462407524945, 12.883306123902651],
						[77.67464189910316, 12.883359627348375],
						[77.6746553543634, 12.88338637939848],
						[77.67469100205798, 12.883439867556344],
						[77.67472664974122, 12.88368959175886],
						[77.6747980324453, 12.88419344135977],
						[77.6745259566314, 12.88425144726487],
						[77.67408455284324, 12.884340623169264],
						[77.6728137160968, 12.884559143645086],
						[77.67248816288213, 12.88462150100746],
						[77.6718594213562, 12.884688414410306],
						[77.67315709245435, 12.891689232089146],
						[77.6733398765893, 12.892919891179986],
						[77.67149830403781, 12.893249936142867],
						[77.67057073483106, 12.893414931534545],
						[77.66982612256187, 12.89355759395155],
						[77.66983949102578, 12.893642262813492],
						[77.66959422751546, 12.893695845653214],
						[77.66838135791534, 12.893869760593532],
						[77.66825204030333, 12.893887571361084],
						[77.66695894334303, 12.894052577887404],
						[77.6659689482099, 12.894177436595736],
						[77.66496120630828, 12.894320062911262],
						[77.66471601948797, 12.894351321005894],
						[77.6634763585885, 12.894275456215487],
						[77.6634719021807, 12.894271021056122],
						[77.66343625091213, 12.894271048732502],
						[77.66338268660738, 12.894284445027262],
						[77.6633024711732, 12.894324584098847],
						[77.66312849610708, 12.894395857657509],
						[77.66300817266116, 12.894422625611286],
						[77.66267367804511, 12.89450289588324],
						[77.66227687945299, 12.89453857304464],
						[77.66202714290824, 12.89456530225991],
						[77.66125573483718, 12.894645638486605],
						[77.66075180012125, 12.894694658801825],
						[77.66064930020416, 12.894694693862945],
						[77.66053334323689, 12.894694691809121],
						[77.66041738614958, 12.894699085195303],
						[77.66032379899309, 12.894694631632518],
						[77.66023903744869, 12.894690167096975],
						[77.66011416697417, 12.894663453878028],
						[77.65993581779054, 12.894623336185443],
						[77.65909309002713, 12.894418184604008],
						[77.6586917347692, 12.894329014323626],
						[77.65827255161614, 12.89422641209465],
						[77.65780880057214, 12.894137287783613],
						[77.65746992116956, 12.89407927244523],
						[77.65723808791935, 12.894052552165224],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b1",
			properties: {
				AREA_SQ_KM: 9.476977094139622,
				ASS_CONST1: "Bommana Halli",
				ASS_CONST_: "175",
				LAT: 12.8811660685851,
				LON: 77.6576553298784,
				LOST_AREA_SQ_KM: 0.713586675412466,
				OBJECTID: 178,
				POP_F: 9741.0,
				POP_M: 11669.0,
				POP_SC: 2955.0,
				POP_ST: 148.0,
				POP_TOTAL: 21410.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Singasandra",
				WARD_NO: 191.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61250436300607, 12.886913480794082],
						[77.61222793997702, 12.886904589589385],
						[77.61247315398836, 12.88394819368786],
						[77.61084109389861, 12.884354005863651],
						[77.61010535218621, 12.884536786775383],
						[77.61020343970246, 12.885598124842948],
						[77.61050670618016, 12.886846604642106],
						[77.60957024044495, 12.88677085846731],
						[77.60896387647941, 12.886753012085327],
						[77.60842875931857, 12.886748562829078],
						[77.60840200776504, 12.887622484581916],
						[77.60845105227504, 12.888309257661689],
						[77.6074923637424, 12.888465262479505],
						[77.60626605931328, 12.888871028882116],
						[77.6050442876977, 12.889299149210764],
						[77.60470096530545, 12.889415095691707],
						[77.60446456445622, 12.889486432739016],
						[77.60422379178011, 12.889597887087936],
						[77.60400085365872, 12.889713812583183],
						[77.60381804407034, 12.889816404084788],
						[77.60355051730929, 12.889486434140329],
						[77.60349701188093, 12.889432951810662],
						[77.60340783611058, 12.889401719179679],
						[77.60330965525444, 12.889401679579628],
						[77.60323385572823, 12.889401675678787],
						[77.6032026441438, 12.889348224940136],
						[77.60316697375104, 12.889085088549207],
						[77.60316251495115, 12.888826460398167],
						[77.60315359735084, 12.888786311217222],
						[77.60262299886348, 12.889098466723182],
						[77.602252916088, 12.889218902986395],
						[77.6015617069723, 12.889473056871418],
						[77.60098651214594, 12.889660288045453],
						[77.60040239656917, 12.88987880046764],
						[77.59988507450092, 12.890074999217957],
						[77.59953281851907, 12.890346988814033],
						[77.59899328507306, 12.890436223778108],
						[77.59812378359533, 12.890578870306657],
						[77.59778935816621, 12.890632414269358],
						[77.5960234880391, 12.890953459984942],
						[77.5944360436547, 12.891158601483909],
						[77.59398129740471, 12.891229906787922],
						[77.59254543787087, 12.891430566964306],
						[77.59119428629563, 12.89190324696772],
						[77.59032481219072, 12.892215436130044],
						[77.58984766532106, 12.891207641644904],
						[77.58880872503376, 12.891756078631047],
						[77.58862143146597, 12.891742719900785],
						[77.58844305635087, 12.891640176078194],
						[77.58828251850649, 12.89155098001291],
						[77.58818441193375, 12.891051525610559],
						[77.58805063010638, 12.890052768504042],
						[77.5880238737219, 12.889094038586158],
						[77.5880461707094, 12.88908959118157],
						[77.58805063010638, 12.889040519248578],
						[77.58823346523077, 12.889009289134869],
						[77.5887641313553, 12.88891569195543],
						[77.59057899241066, 12.888674850454578],
						[77.5906949340754, 12.888661527743675],
						[77.59083763134558, 12.888630270559403],
						[77.59099361884155, 12.888585690603673],
						[77.59142616824299, 12.888420701703348],
						[77.59167588673084, 12.888340453025044],
						[77.59171156075546, 12.888331475334885],
						[77.59173831626653, 12.888327068479974],
						[77.59175169401969, 12.888309183275519],
						[77.59175615327038, 12.888282427395534],
						[77.5917472347688, 12.887983702835339],
						[77.591707101503, 12.887310400137714],
						[77.59167588673084, 12.886712833994586],
						[77.59164913120499, 12.886365009583319],
						[77.59158670161999, 12.885816618542503],
						[77.59142170897927, 12.885063000045873],
						[77.59129684952337, 12.884510078984947],
						[77.59120766411323, 12.884171166050592],
						[77.59109172297475, 12.883881292166611],
						[77.59073506770086, 12.883101031505905],
						[77.59059237030112, 12.882753188510474],
						[77.59055669592301, 12.882561415446586],
						[77.59058791100448, 12.882191304894825],
						[77.59068601549004, 12.881428841012891],
						[77.59071723053576, 12.881384239027273],
						[77.59075290486315, 12.881286173746979],
						[77.59073506770086, 12.8810899143221],
						[77.59064142255262, 12.880581585762625],
						[77.59042737620794, 12.879582783575087],
						[77.59001711958032, 12.87835206944442],
						[77.5897406414474, 12.877834754792628],
						[77.58968712947257, 12.877634125497675],
						[77.58968712947257, 12.87719714735388],
						[77.5896559141422, 12.876853793160148],
						[77.58916092703647, 12.876452428447683],
						[77.5886571064552, 12.876091307569382],
						[77.586855592861, 12.874918528637327],
						[77.58614216700182, 12.874280923198873],
						[77.58613770752953, 12.874008901547718],
						[77.58638743770655, 12.873808219322154],
						[77.58669059331083, 12.873634299569982],
						[77.58701167329482, 12.873308834599438],
						[77.58721234782156, 12.873081425799237],
						[77.58759139873311, 12.872929741562391],
						[77.58874629387898, 12.872595368622125],
						[77.5889469653251, 12.869897602423228],
						[77.58895588404776, 12.869852972568097],
						[77.593267829111, 12.873687787370192],
						[77.59355321696945, 12.873322177491866],
						[77.5943736156104, 12.873068022928686],
						[77.59444496194392, 12.873090299017663],
						[77.59574256551798, 12.872947588408564],
						[77.59585404279255, 12.872938698282551],
						[77.5959788972094, 12.872911924139764],
						[77.59603240620292, 12.872885188036797],
						[77.5961215878024, 12.872836119141306],
						[77.59616617857574, 12.872800427827292],
						[77.59621968748047, 12.87275585794429],
						[77.59624198284996, 12.872720221634028],
						[77.59629995079004, 12.872626585098864],
						[77.59646939536722, 12.87208252393882],
						[77.59684841521118, 12.870744846004035],
						[77.59692876550595, 12.87037469823913],
						[77.59693322455219, 12.870254298885522],
						[77.59694660168987, 12.87021418801388],
						[77.5969822740492, 12.870165148277458],
						[77.59701348735437, 12.870120547787991],
						[77.59705807777537, 12.870040316341342],
						[77.59718293086044, 12.869665747959827],
						[77.59725427541846, 12.869469542542912],
						[77.5972765705836, 12.869429426943032],
						[77.59730778380741, 12.86938482658402],
						[77.59733007896196, 12.869358064405684],
						[77.59738804634324, 12.86929561604635],
						[77.59742371856306, 12.869259929862505],
						[77.59745493174616, 12.869233162762528],
						[77.59750398101644, 12.869219825364924],
						[77.59759316145326, 12.86917075620356],
						[77.5976823418196, 12.869112813452116],
						[77.59772247296148, 12.869081561529987],
						[77.59774922704813, 12.869041486539453],
						[77.5977581450756, 12.868996855435732],
						[77.5977715221155, 12.868898765283971],
						[77.59784286630146, 12.868492956020676],
						[77.59790529242719, 12.868323549462069],
						[77.59794542348979, 12.868225444895232],
						[77.5979766365286, 12.868154051946687],
						[77.598003390555, 12.86810501750821],
						[77.59803014457506, 12.868029190352464],
						[77.59804352158271, 12.867926707221244],
						[77.5980479805849, 12.867801830133384],
						[77.5980479805849, 12.867583353753755],
						[77.59995195842389, 12.867476272191615],
						[77.59994749949692, 12.867293506944495],
						[77.59989845128868, 12.866816393044973],
						[77.60190058007078, 12.866758344136638],
						[77.60267204588718, 12.866722707436258],
						[77.60289944544566, 12.866437287526274],
						[77.60316251495115, 12.866080600559775],
						[77.60391613705704, 12.864814231629024],
						[77.60398747735742, 12.864818668967597],
						[77.60418812171031, 12.864916803024515],
						[77.60436647194652, 12.86504161371532],
						[77.6044957756914, 12.86541176562534],
						[77.6046117904871, 12.866869885193541],
						[77.60481689246386, 12.867066102431089],
						[77.60491944331237, 12.867186438258566],
						[77.60497294806586, 12.867583306913337],
						[77.60515575578209, 12.868078271033024],
						[77.60569534717875, 12.86891212854263],
						[77.60577114505047, 12.869117257154928],
						[77.605913823259, 12.869313429886617],
						[77.60598516229557, 12.869438310100119],
						[77.60606095997247, 12.869558664790972],
						[77.60614567496282, 12.869661266737275],
						[77.60620809649392, 12.869732565094433],
						[77.60628835269742, 12.869803939132558],
						[77.60636860884378, 12.86988866705867],
						[77.60639981955195, 12.86992435939143],
						[77.60647115828101, 12.870013486538035],
						[77.60656933638458, 12.870125002187635],
						[77.60666296831644, 12.870289975083098],
						[77.60669417894312, 12.870356855396023],
						[77.60668972028269, 12.870432716855317],
						[77.60669863760336, 12.870468379474827],
						[77.60672538956113, 12.870508511609888],
						[77.60674322419614, 12.870548649023796],
						[77.60671201358304, 12.87061108630273],
						[77.60669863760336, 12.870664553022399],
						[77.60671647224258, 12.870700210463724],
						[77.60673876553766, 12.870744782284161],
						[77.60676551748591, 12.870753726586964],
						[77.6068190213634, 12.870744821119754],
						[77.6068457732926, 12.870771599411936],
						[77.60686360790856, 12.870811736935872],
						[77.60687252521548, 12.870856273622415],
						[77.6068903598272, 12.870918725246902],
						[77.60693494634417, 12.871007869117758],
						[77.60735405874163, 12.870900875787497],
						[77.60748790511353, 12.870883052870527],
						[77.60761274665487, 12.870834042925198],
						[77.60826824990198, 12.870651224306714],
						[77.60862047859996, 12.870553098795707],
						[77.60904413072097, 12.870432705949588],
						[77.60898171076026, 12.869812860661304],
						[77.60896387647941, 12.869447232593204],
						[77.60891028619696, 12.869108330804638],
						[77.6088077389786, 12.868836362240069],
						[77.60875869462379, 12.868671364689858],
						[77.60875869462379, 12.868555402100094],
						[77.60875423604502, 12.86844396537728],
						[77.60879882182476, 12.86835028969795],
						[77.60894158362439, 12.868336939738576],
						[77.6093205615601, 12.868332444870322],
						[77.60966841584936, 12.868305701871986],
						[77.61140295045283, 12.868234386613112],
						[77.61336055324574, 12.867935564452225],
						[77.61347647149768, 12.86816302513159],
						[77.61399373120287, 12.869175196315338],
						[77.61410964880325, 12.869362511518489],
						[77.6143058167783, 12.869580965097477],
						[77.61442619241201, 12.869732566729642],
						[77.61454219698597, 12.869973390083002],
						[77.61458678047545, 12.870102682370614],
						[77.6146581140219, 12.870254358679281],
						[77.61477848927912, 12.87061997407621],
						[77.61450207183034, 12.870990103540121],
						[77.61456003038387, 12.871435972562686],
						[77.61519765992244, 12.87260426474494],
						[77.61539828431857, 12.874940816216863],
						[77.61539382600255, 12.876225082581566],
						[77.61573274493566, 12.876242886715579],
						[77.61576395305025, 12.876260745965741],
						[77.61578624455538, 12.876265169574873],
						[77.6160092467815, 12.876251854164217],
						[77.61664240984851, 12.876171510592805],
						[77.61744506982598, 12.876055651536642],
						[77.6177705205041, 12.876011032695725],
						[77.61785531410676, 12.876265203356413],
						[77.61790881271804, 12.876403459376634],
						[77.61781073191126, 12.876764624397518],
						[77.6177705205041, 12.876974147967694],
						[77.61780627369075, 12.877148091299622],
						[77.61794893665984, 12.877411142452319],
						[77.61824326587842, 12.877812481815353],
						[77.61831013890814, 12.87858838199969],
						[77.6184840085995, 12.878873777276484],
						[77.6180693083994, 12.878962906721734],
						[77.61810051586643, 12.879176961145932],
						[77.6181138904925, 12.879292886307095],
						[77.61811834870085, 12.879355309286481],
						[77.6183502627069, 12.879355343079181],
						[77.61863112889785, 12.879498072982377],
						[77.61879616914632, 12.880028704763033],
						[77.61892991450917, 12.880403266614838],
						[77.61902353616863, 12.880764455883146],
						[77.61897895443575, 12.881116726993351],
						[77.61882737641186, 12.881210309425496],
						[77.61865787802233, 12.881321820898627],
						[77.61855088148621, 12.88137082829478],
						[77.61841267747656, 12.881428858964457],
						[77.61840821927987, 12.881402107299357],
						[77.61839038649136, 12.881353003868057],
						[77.61824326587842, 12.881424395485425],
						[77.61773931294563, 12.881633946205506],
						[77.61765460667182, 12.881674107581077],
						[77.61763677376393, 12.88169634908112],
						[77.61763231553654, 12.881714231123267],
						[77.61765014844511, 12.881749892849703],
						[77.61766798135086, 12.881772198926784],
						[77.61769027247908, 12.881812295503154],
						[77.61771256360288, 12.881856872709449],
						[77.61773039649874, 12.88191037078376],
						[77.61774377116879, 12.881995106664704],
						[77.6177526876146, 12.882106599798366],
						[77.6177526876146, 12.882137791833138],
						[77.6177526876146, 12.882173464501932],
						[77.6177526876146, 12.88221809843624],
						[77.61772593827503, 12.88231617145166],
						[77.61772593827504, 12.882441026021251],
						[77.61772593827503, 12.882512371549666],
						[77.61774377116879, 12.882753195360939],
						[77.61776160405971, 12.882904751246219],
						[77.6177705205041, 12.882967216547788],
						[77.6177705205041, 12.883011764622122],
						[77.61777943694781, 12.883069749324633],
						[77.61778389516938, 12.883141135649076],
						[77.6177705205041, 12.883319465616207],
						[77.61774822939178, 12.883711926037288],
						[77.61776606228199, 12.884024012703279],
						[77.6177705205041, 12.88408195752375],
						[77.6177883533908, 12.88414889885108],
						[77.61781519013161, 12.884269262858732],
						[77.61785085588801, 12.884523435772437],
						[77.61787760519789, 12.884697392387473],
						[77.6178954380676, 12.884724093743626],
						[77.6179221873669, 12.884737519932463],
						[77.618127265117, 12.884728521948967],
						[77.6182119710352, 12.884732995027694],
						[77.61823880767504, 12.88474194463301],
						[77.61826109869023, 12.884791003910872],
						[77.61827893149922, 12.884844503536389],
						[77.61832351350932, 12.885027325472086],
						[77.61839038649136, 12.88526808258626],
						[77.6184438848484, 12.885446505358667],
						[77.6184750922116, 12.885629293287653],
						[77.6186177543332, 12.886222394563593],
						[77.61862221252159, 12.88624466661935],
						[77.61741832041335, 12.886476564023788],
						[77.61734698861524, 12.886208965645643],
						[77.61686978122016, 12.886329400796196],
						[77.61664240984851, 12.886333799763227],
						[77.61630795193308, 12.886351699300247],
						[77.61587986882874, 12.886507738040082],
						[77.61591553519813, 12.88676639567375],
						[77.61599587190544, 12.887479833064363],
						[77.6153135762838, 12.887604691197014],
						[77.61526453476087, 12.887047335078117],
						[77.61526007643954, 12.886824376021963],
						[77.61525561811803, 12.886726275621404],
						[77.61484536436643, 12.88683775237553],
						[77.61430135841901, 12.88692242051638],
						[77.61376180822505, 12.886926904866684],
						[77.61316875460916, 12.886931332186029],
						[77.61250436300607, 12.886913480794082],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b2",
			properties: {
				AREA_SQ_KM: 6.454403527052404,
				ASS_CONST1: "Bommana Halli",
				ASS_CONST_: "175",
				LAT: 12.8792199135841,
				LON: 77.602883732267,
				LOST_AREA_SQ_KM: 0.324585602142274,
				OBJECTID: 179,
				POP_F: 11574.0,
				POP_M: 13429.0,
				POP_SC: 3311.0,
				POP_ST: 258.0,
				POP_TOTAL: 25003.0,
				RESERVATIO: "General",
				WARD_NAME: "Arakere",
				WARD_NO: 193.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.51773954392094, 12.89320977138305],
						[77.52129346848439, 12.885508943809267],
						[77.52188201638823, 12.885624865194899],
						[77.52430336863121, 12.885602560268328],
						[77.5267558145548, 12.885821035615274],
						[77.52817833495985, 12.885950342941843],
						[77.52886054354211, 12.886289219484738],
						[77.52979251539162, 12.886530010410413],
						[77.53140667292459, 12.887020512709904],
						[77.53239216297149, 12.887158772455065],
						[77.53244570199983, 12.887002663178437],
						[77.5325839235578, 12.886900159144032],
						[77.53338210602848, 12.886182241661167],
						[77.53602182457479, 12.884581427354957],
						[77.53725256419891, 12.883689590174274],
						[77.53795711267875, 12.88323033618104],
						[77.53859919800415, 12.882325120453912],
						[77.53914321814096, 12.881286152446437],
						[77.54072618908103, 12.881303933070244],
						[77.54074403411848, 12.881304014630302],
						[77.5407574178947, 12.881317320593661],
						[77.54081095298388, 12.881321786370336],
						[77.54087332308046, 12.881299540677047],
						[77.54099377687278, 12.88122374506507],
						[77.54103838935605, 12.881170237493947],
						[77.5413951136413, 12.88106762388201],
						[77.54193019796934, 12.88106316383209],
						[77.54342399451701, 12.881116702127906],
						[77.54437824004941, 12.881094368217381],
						[77.54438270116562, 12.881094410067217],
						[77.54597906973723, 12.88470627210309],
						[77.5460102096353, 12.884768674352303],
						[77.54611727483761, 12.884871248854758],
						[77.54637592785515, 12.885125400833243],
						[77.54671925230663, 12.88549552672451],
						[77.54710718581991, 12.885928100704207],
						[77.54718302294935, 12.885999369318593],
						[77.54719194496127, 12.885999366892323],
						[77.54723646753958, 12.886003830365675],
						[77.54757095487818, 12.886360612897917],
						[77.54992983237574, 12.888875508592896],
						[77.5492431143825, 12.889704933452002],
						[77.54850286079646, 12.89113633133492],
						[77.54843603393246, 12.892050410618904],
						[77.54806586137649, 12.893067099277008],
						[77.54810601011235, 12.894471735866112],
						[77.54832450995532, 12.895283232973307],
						[77.54945715112025, 12.897954274936321],
						[77.55014832870835, 12.900281936387904],
						[77.55014832870835, 12.900469165473726],
						[77.55026868519549, 12.900553872217559],
						[77.55035790285795, 12.900540495582469],
						[77.55051840700553, 12.90050934806623],
						[77.55062538048846, 12.900638646767531],
						[77.55065660658018, 12.90082591221161],
						[77.55054071137864, 12.901044451546097],
						[77.55045149385988, 12.90119155191791],
						[77.55045149385988, 12.901365427466983],
						[77.55045595473749, 12.901481444098042],
						[77.55053625050438, 12.90169097220479],
						[77.55063876310024, 12.90163299905235],
						[77.55079489345447, 12.901468003236102],
						[77.55100000555419, 12.901164829837258],
						[77.55115604794383, 12.900870500286832],
						[77.55120065643507, 12.900781311519076],
						[77.55124526490877, 12.90070556407289],
						[77.55131209011893, 12.900544980439065],
						[77.5513433160217, 12.900424561403625],
						[77.55151274044805, 12.900330943448326],
						[77.55168225208871, 12.900308671591928],
						[77.55174015538782, 12.900464735414594],
						[77.55174461621478, 12.90064313297461],
						[77.55164210461774, 12.900866021227412],
						[77.55153058379057, 12.900977499702478],
						[77.55134777686425, 12.90111579634849],
						[77.55113820454243, 12.901383343152157],
						[77.55108467432137, 12.901664246426193],
						[77.55111143943506, 12.90205214539742],
						[77.5512897858974, 12.902119046914793],
						[77.55158857463438, 12.902114619126381],
						[77.55188290176336, 12.902069996837712],
						[77.55219944470927, 12.902078914926928],
						[77.55244024067225, 12.902074457088199],
						[77.55274348716787, 12.90198080430103],
						[77.55322061550515, 12.903474633276524],
						[77.55311801777538, 12.903501412617073],
						[77.5530556544041, 12.903581651448341],
						[77.5529129095038, 12.903688616761235],
						[77.55275240874273, 12.903795676688292],
						[77.55264534979823, 12.903880402647333],
						[77.55246254466768, 12.903902724657462],
						[77.55222620956003, 12.903982962070545],
						[77.55215046326667, 12.903982941373526],
						[77.55200771722768, 12.9039071505999],
						[77.5518873625847, 12.903826921249669],
						[77.5517669203469, 12.90382690690483],
						[77.55165994793992, 12.90382685043218],
						[77.55156180963323, 12.903826873751203],
						[77.5513790027572, 12.90386255029624],
						[77.55123634321544, 12.903938412926044],
						[77.55110697858322, 12.904014138855205],
						[77.55098216212825, 12.904130149781022],
						[77.55090632753672, 12.904237140822703],
						[77.55074582393772, 12.904388745994398],
						[77.55062538048847, 12.904455594607738],
						[77.55051840700553, 12.90445562353786],
						[77.55037574638202, 12.904455660629036],
						[77.55031329403548, 12.904402080605571],
						[77.55030883315227, 12.904348616520823],
						[77.55048271999942, 12.904219282327107],
						[77.55053625050438, 12.904134570261402],
						[77.55039350243506, 12.904049817555613],
						[77.55029545050158, 12.90400081695858],
						[77.55023299811087, 12.903991870298416],
						[77.55013494603874, 12.90399189713362],
						[77.54992983237574, 12.904036495405169],
						[77.54975585721286, 12.904081039030974],
						[77.54952843829723, 12.90413455993589],
						[77.54934562814624, 12.904179195560733],
						[77.54906476470433, 12.9042460876338],
						[77.54897554602549, 12.90396064630177],
						[77.54883288346295, 12.90387598355567],
						[77.54873474271191, 12.903768904068633],
						[77.54861438459838, 12.903706509741221],
						[77.5484449558459, 12.903514751941751],
						[77.54833343187752, 12.903430035231136],
						[77.54825313455247, 12.903394381584157],
						[77.54827543937067, 12.903617324351716],
						[77.54832004899394, 12.903733290496202],
						[77.54827990033378, 12.903960642198395],
						[77.54825313455247, 12.904152362560422],
						[77.54825313455247, 12.904241628348792],
						[77.5482219952695, 12.904379803369874],
						[77.5482665174439, 12.904705317472319],
						[77.54840480722989, 12.905499078390838],
						[77.54856977509077, 12.906020811073908],
						[77.54879273498415, 12.90668966207347],
						[77.54917620048508, 12.907559160664682],
						[77.54924757530759, 12.907737510361184],
						[77.54926095808183, 12.907786579468338],
						[77.5497647790226, 12.90894596910495],
						[77.5497647790226, 12.909030672073042],
						[77.549586430164, 12.909369526568064],
						[77.54922080975442, 12.909151090282734],
						[77.54830220514674, 12.909449783372049],
						[77.54767347015182, 12.90962368137009],
						[77.54708488077219, 12.909748582847604],
						[77.54712949086324, 12.910016124946205],
						[77.5471027248107, 12.91084997977817],
						[77.54640706764438, 12.910631518623847],
						[77.54568026623643, 12.91035951348631],
						[77.54551083310584, 12.910444166664904],
						[77.54539939372776, 12.910430792904405],
						[77.54518981042538, 12.910314898109382],
						[77.5449088492313, 12.910207892025193],
						[77.54479740926257, 12.910145402682561],
						[77.54457890263286, 12.910056221499065],
						[77.54440946785923, 12.910127603722339],
						[77.54437377893302, 12.910216793469031],
						[77.54468142060755, 12.911715059083715],
						[77.54498914892122, 12.911670492602335],
						[77.54510058870183, 12.912343747964524],
						[77.5454260727097, 12.912281336701668],
						[77.54582739381956, 12.912209992293944],
						[77.54594338130424, 12.912615742150413],
						[77.54637146681722, 12.912490966775707],
						[77.54656766484878, 12.913155294930025],
						[77.54673709640261, 12.913761811982518],
						[77.54703134863979, 12.914930055994544],
						[77.54607266434891, 12.915121765987752],
						[77.54578287068878, 12.915313513984088],
						[77.54531017216885, 12.915407187171192],
						[77.54533247756514, 12.915777246899182],
						[77.54532355540715, 12.915964585766961],
						[77.54574718219413, 12.915884321175445],
						[77.54600128753054, 12.916249939440963],
						[77.54637592785515, 12.916856351767288],
						[77.5465408986638, 12.917293344813984],
						[77.54672817435498, 12.91804698078011],
						[77.54640260660769, 12.918096030436496],
						[77.54620640829097, 12.918127262824779],
						[77.54592553708355, 12.918158470378536],
						[77.54567580517119, 12.91820306809946],
						[77.5448018703623, 12.91829664499606],
						[77.54472157054, 12.91753419867447],
						[77.54441839008902, 12.917552008310025],
						[77.54439608451322, 12.917551968855607],
						[77.5439456858903, 12.917587682530694],
						[77.54344630028372, 12.917623364087415],
						[77.54331246561783, 12.917618904253393],
						[77.54317871826804, 12.917654604917695],
						[77.54311180079719, 12.917694728692531],
						[77.54287107252377, 12.917855227837926],
						[77.54196580018848, 12.918483968628943],
						[77.54196580018849, 12.91851068218908],
						[77.54197918382081, 12.91870693888705],
						[77.54199702866147, 12.918791644269376],
						[77.54201933470834, 12.918862949149833],
						[77.54191681433123, 12.918889725244679],
						[77.54185435733261, 12.918903091261567],
						[77.54183651246954, 12.918898612886977],
						[77.54183205125335, 12.91888969463387],
						[77.54182312882041, 12.91887633912692],
						[77.54181420638679, 12.918858502625739],
						[77.5418097451697, 12.91884070855845],
						[77.54180528395246, 12.918827309315933],
						[77.54179190029964, 12.918805035572593],
						[77.54176075924516, 12.918782682496646],
						[77.54174737558712, 12.918773765565899],
						[77.54169830216075, 12.918751497077615],
						[77.54162246136924, 12.918733627328663],
						[77.54141733233119, 12.918599921394934],
						[77.54127466027444, 12.9185017957728],
						[77.5412256741192, 12.91847953151496],
						[77.54120336791652, 12.918470615899212],
						[77.54115429425522, 12.918452742228306],
						[77.54113644928222, 12.91844826384817],
						[77.54110522057272, 12.918443830495049],
						[77.54089562934793, 12.918350152512392],
						[77.54055674856149, 12.918153995471611],
						[77.5400841157719, 12.917895387285366],
						[77.53990129041952, 12.917819543171097],
						[77.53966492916919, 12.917699155857198],
						[77.5395311775259, 12.917636704512104],
						[77.53939742572518, 12.91760553339865],
						[77.53935281259773, 12.917601058717333],
						[77.53929035418986, 12.917601067428992],
						[77.53925028981492, 12.917601034146166],
						[77.53923244454293, 12.917601036628685],
						[77.53914321814096, 12.917632243059685],
						[77.53909860491373, 12.917672405107881],
						[77.539031772517, 12.917730368837411],
						[77.53891131664514, 12.91781065408929],
						[77.53871965420679, 12.91790431398283],
						[77.53847882915126, 12.91804248364565],
						[77.53840744756202, 12.918096005734176],
						[77.5383851408062, 12.918131726824315],
						[77.53838076693198, 12.918167363578215],
						[77.53838076693198, 12.918207519544385],
						[77.53838514080618, 12.918243146706184],
						[77.53838960215771, 12.918274383337026],
						[77.53838960215771, 12.918323415026165],
						[77.53839406350905, 12.91835017075011],
						[77.53842529296352, 12.91836356630092],
						[77.53844759971149, 12.91836800117681],
						[77.53847436780325, 12.918367997611083],
						[77.53851451992908, 12.918376911034844],
						[77.53854574935048, 12.918403663207918],
						[77.53863042740228, 12.91847504104404],
						[77.53879540945839, 12.91864895286207],
						[77.53883110013678, 12.91868462331935],
						[77.5388623294712, 12.918738088931473],
						[77.5388712521366, 12.918791600645301],
						[77.53885786813824, 12.918836196543012],
						[77.53884002280465, 12.918876355178382],
						[77.5388087934641, 12.91895214783503],
						[77.53878648678703, 12.919041382222064],
						[77.53872857688339, 12.919135020384655],
						[77.53865273410997, 12.919237532469246],
						[77.53861258203297, 12.919317807394066],
						[77.53860365934726, 12.919371321692894],
						[77.53861258203297, 12.91942035270809],
						[77.53862596606021, 12.91949174495806],
						[77.53865719545098, 12.919572010716617],
						[77.5386928861728, 12.919638876095886],
						[77.53872411554518, 12.919678985449098],
						[77.53875079609462, 12.919701296500893],
						[77.53877756411498, 12.919723611770076],
						[77.5387998707938, 12.919745884539903],
						[77.5388177161337, 12.919786038771367],
						[77.53884894547178, 12.919884142584095],
						[77.53895592994591, 12.920102573155145],
						[77.53896039127501, 12.920156129208568],
						[77.53897377526131, 12.920196241103088],
						[77.53896039127503, 12.92018732391265],
						[77.53889355879703, 12.920196255737162],
						[77.53855467204072, 12.92029437289915],
						[77.53805071389888, 12.920401342882993],
						[77.53761822313056, 12.920468236445455],
						[77.53759591621984, 12.92052619059355],
						[77.53631173060865, 12.920521810986504],
						[77.5342114260289, 12.92051283344759],
						[77.53110791859642, 12.92069117291239],
						[77.53042572348818, 12.920793756975868],
						[77.52981036214501, 12.921025628776675],
						[77.5288917756145, 12.921400231426675],
						[77.5283744769075, 12.921591930206489],
						[77.52834333217835, 12.921596374667113],
						[77.52828979121955, 12.921591984048652],
						[77.52826302073072, 12.921565186792023],
						[77.52823178848578, 12.921511719842663],
						[77.5282050179833, 12.921467085141426],
						[77.52819172021502, 12.921418015826484],
						[77.52817833495985, 12.92126639857128],
						[77.52825855898196, 12.921212961989411],
						[77.52841017085626, 12.921195108456102],
						[77.52844140305224, 12.921181706375641],
						[77.52847263523967, 12.921154947757044],
						[77.52848155872022, 12.92110591566207],
						[77.52850377993343, 12.920989966455585],
						[77.52864646801412, 12.920494985064588],
						[77.5288248497346, 12.92013382497005],
						[77.5289853842955, 12.919924240620333],
						[77.52916822719799, 12.919661185732908],
						[77.52919499747388, 12.919625508922122],
						[77.52920392089779, 12.919598709203196],
						[77.52811140866038, 12.913868800468677],
						[77.52847263523967, 12.913824164886375],
						[77.52811587041491, 12.912736200319028],
						[77.5279776434289, 12.912201093259727],
						[77.52778587507915, 12.91161696734062],
						[77.52752726724908, 12.910854454846003],
						[77.5273622688475, 12.910221221168266],
						[77.52723742631363, 12.909552358953592],
						[77.52718388483459, 12.909146627297444],
						[77.52710366005495, 12.908660515549954],
						[77.52702343521877, 12.908281504485705],
						[77.52702789701593, 12.908112067903774],
						[77.52684058887048, 12.90667183253962],
						[77.52656859344819, 12.906667348974748],
						[77.52652397528855, 12.906671834411119],
						[77.52455752189145, 12.906689643485905],
						[77.52450397915196, 12.90646666865072],
						[77.52457983135882, 12.906319543651765],
						[77.52555636932865, 12.905565902422449],
						[77.52562767149519, 12.905316201773545],
						[77.52569906110429, 12.904870322812831],
						[77.52569906110429, 12.904616155699044],
						[77.52569459925505, 12.904290607732593],
						[77.52573475589206, 12.904192512123393],
						[77.52583282901358, 12.90412563768186],
						[77.52588628364103, 12.90408995769831],
						[77.525908592848, 12.904081037685483],
						[77.52651505165453, 12.904232671818347],
						[77.52798656694824, 12.904723205367903],
						[77.52870893226782, 12.905030851964336],
						[77.5288828521661, 12.905084373216164],
						[77.52914591862992, 12.905075412855284],
						[77.52938667596472, 12.905030828152528],
						[77.52981482383292, 12.904914877834402],
						[77.53019826588725, 12.904758864206075],
						[77.53072894125982, 12.904464586231528],
						[77.53085377996328, 12.904393224390184],
						[77.53096969524502, 12.904312903154745],
						[77.53109899532184, 12.90428173388751],
						[77.53117038149887, 12.904277243394832],
						[77.53125506504291, 12.904277270117989],
						[77.53138436479541, 12.904326315151577],
						[77.5315939735542, 12.904460043506225],
						[77.53184364892566, 12.904736571995347],
						[77.53199079442052, 12.904874790224127],
						[77.53215132451439, 12.90502640461692],
						[77.53216470930217, 12.905030839925645],
						[77.53259284671589, 12.904928324208443],
						[77.53364962362606, 12.904794544247634],
						[77.53409551405063, 12.904709767156607],
						[77.5340508988426, 12.903438913277737],
						[77.53441656807072, 12.903363112131496],
						[77.53448795216563, 12.903273925452602],
						[77.53444779861775, 12.902136932723927],
						[77.53442549108503, 12.900558390026568],
						[77.53454140272697, 12.899363366518061],
						[77.53457263323975, 12.899077967330866],
						[77.5343318868804, 12.89902442836164],
						[77.5341178216481, 12.898957526174481],
						[77.53405536036419, 12.898797027922592],
						[77.53375215145938, 12.898574030737727],
						[77.53370316206251, 12.898498269305184],
						[77.53362285439839, 12.898413505827273],
						[77.53351140336318, 12.898337755271944],
						[77.53329733658651, 12.898377858565357],
						[77.5331234234366, 12.898239655843946],
						[77.5330208950365, 12.89817277781296],
						[77.5328469814639, 12.898056803405558],
						[77.53278005791064, 12.897954246944481],
						[77.53264629816789, 12.89778480674746],
						[77.53253484617575, 12.897655468175904],
						[77.53236985503558, 12.89740130064082],
						[77.53222717162436, 12.897044563168825],
						[77.531941804265, 12.896433701353045],
						[77.53176788904256, 12.896077015868794],
						[77.53151812602206, 12.895640006841345],
						[77.53128183482784, 12.895229752189918],
						[77.53128183482784, 12.894917621036367],
						[77.53123275688402, 12.894667916584924],
						[77.53111238023345, 12.894556417430914],
						[77.53094738703017, 12.894560886904335],
						[77.53066201646051, 12.89460100494437],
						[77.5304836376201, 12.894739227568373],
						[77.53022949759246, 12.89484184303947],
						[77.5299619719518, 12.894944331143007],
						[77.52968106060374, 12.894948822918398],
						[77.52946698661934, 12.895051383380697],
						[77.52930645273787, 12.895122747797052],
						[77.52915930377128, 12.895194066829143],
						[77.5290879163325, 12.895194078362762],
						[77.52893193112352, 12.895211902349581],
						[77.52872231746058, 12.895211897068117],
						[77.52832994694101, 12.895256512634475],
						[77.52820947973414, 12.895256488530052],
						[77.52799995222597, 12.895256486739992],
						[77.52776802800825, 12.8952520471819],
						[77.52750049658088, 12.895238657307317],
						[77.52721511736708, 12.895243103264518],
						[77.5269877408351, 12.895252063016452],
						[77.5266398949807, 12.895301058051421],
						[77.52635005178561, 12.895399193335788],
						[77.52618058984734, 12.895430409498694],
						[77.52604236051107, 12.895541871153252],
						[77.52600229146957, 12.89566675809727],
						[77.52592644021044, 12.895791559959594],
						[77.5257837487198, 12.895831731577157],
						[77.52564998075285, 12.895760335294963],
						[77.52556083118334, 12.895653352006091],
						[77.52548944148754, 12.895515089069086],
						[77.52540029179231, 12.895430419267399],
						[77.5253066801628, 12.89537689387851],
						[77.52513275480008, 12.895363526488575],
						[77.52494990541493, 12.895390306716191],
						[77.52477597950717, 12.895434832660605],
						[77.52466005791706, 12.895501792700623],
						[77.52450844104787, 12.895680151276759],
						[77.52442375248371, 12.895849583946559],
						[77.52442821438278, 12.895961020274436],
						[77.5244683714665, 12.896027911422507],
						[77.52455305999744, 12.89605025185661],
						[77.52466451980686, 12.896059148906314],
						[77.52475813196438, 12.896166175205284],
						[77.52482506023541, 12.896282083191178],
						[77.5249231341406, 12.89647383961876],
						[77.52497667668298, 12.896692313995537],
						[77.52498113856036, 12.896812711156436],
						[77.52495882917165, 12.896946465741408],
						[77.52490974850106, 12.897071350412661],
						[77.5248562934151, 12.897156049829414],
						[77.52475367007824, 12.897236314849673],
						[77.52455752189145, 12.89731664392646],
						[77.52442375248371, 12.89737008064727],
						[77.52418744655994, 12.897379037295885],
						[77.52404475288975, 12.89737906036375],
						[77.5237415173108, 12.897526170772727],
						[77.52357205148978, 12.89759305126944],
						[77.52340704735565, 12.897624306975812],
						[77.52327773854047, 12.897628764574382],
						[77.52319304906096, 12.897628736501227],
						[77.52308604976282, 12.897655461545112],
						[77.5230325063314, 12.897659948238479],
						[77.52296557700672, 12.897642080058992],
						[77.52292104492447, 12.897584109359792],
						[77.5228228818054, 12.897477120598252],
						[77.52270249626044, 12.897379055715609],
						[77.52261780635314, 12.897330006954183],
						[77.522537578356, 12.897325541316565],
						[77.52249742059139, 12.897329983163397],
						[77.52229225706321, 12.897374553481592],
						[77.52214509902986, 12.897441472890081],
						[77.52213180055375, 12.897450395480721],
						[77.52210502861686, 12.897450398766866],
						[77.52204702273201, 12.89745036279964],
						[77.52198464231309, 12.897459334456812],
						[77.52191325037514, 12.89749053017923],
						[77.52185533188282, 12.897530645194134],
						[77.52179732587079, 12.897561882323107],
						[77.52169478726748, 12.89760648258456],
						[77.5216010851013, 12.897628764133012],
						[77.52152531840076, 12.89762873434717],
						[77.52146293769609, 12.897633225918524],
						[77.52139154543114, 12.89764658797115],
						[77.52132024061204, 12.89765995420902],
						[77.52127115837321, 12.897664396843517],
						[77.52123546218623, 12.897664401061004],
						[77.52120869003865, 12.897668884120911],
						[77.52115523321534, 12.897664414741962],
						[77.52108830278746, 12.89764654609152],
						[77.52105269403397, 12.897597534085476],
						[77.52101253574581, 12.897561828869199],
						[77.52098576354582, 12.897535124955304],
						[77.52095899133957, 12.897512814788065],
						[77.52094560523408, 12.897468232834578],
						[77.5208921482873, 12.897374596459063],
						[77.52084752790336, 12.89733445504293],
						[77.52081629362418, 12.897276435626326],
						[77.52072714027044, 12.897169450155328],
						[77.52070036800339, 12.897115953265487],
						[77.52066475912864, 12.897044628338607],
						[77.5205710561151, 12.896857303449833],
						[77.52056213201455, 12.896799324777913],
						[77.52055320791331, 12.896759179412173],
						[77.5206246007037, 12.896491672104645],
						[77.52074052640161, 12.896277655323047],
						[77.52086091402036, 12.896117094675908],
						[77.52087430013582, 12.896068030239961],
						[77.52088322421191, 12.896027882952747],
						[77.5208921482873, 12.895983341995708],
						[77.52089661032474, 12.895956591691785],
						[77.52090553439908, 12.895929797774107],
						[77.52090999643599, 12.895903047480365],
						[77.52090553439908, 12.895769299369139],
						[77.52091892050929, 12.895715755234004],
						[77.52094560523408, 12.8956667281567],
						[77.52095899133957, 12.895581997632123],
						[77.52098130151188, 12.895483912767476],
						[77.52101253574581, 12.895372473582956],
						[77.52114184713291, 12.89484181218855],
						[77.52115077118805, 12.894672354462978],
						[77.52114184713291, 12.89451185880399],
						[77.52112399902056, 12.894427089917286],
						[77.52113292307709, 12.894021325237173],
						[77.52113292307709, 12.893896495081075],
						[77.52112399902056, 12.893798372418361],
						[77.52111953699203, 12.893731478274551],
						[77.52105715606511, 12.893579868452282],
						[77.52097683947778, 12.89353525376209],
						[77.52091445847272, 12.893539745795117],
						[77.520843065864, 12.893562067549276],
						[77.52077176070154, 12.893557601211509],
						[77.5207092920931, 12.893521943647917],
						[77.52066913368388, 12.893486240155712],
						[77.52060229046151, 12.893472856823852],
						[77.52019650576457, 12.8932320929913],
						[77.52004488295297, 12.893125034053336],
						[77.51973280005062, 12.892968985579643],
						[77.51961241123965, 12.892942255927483],
						[77.51949193480944, 12.892951101140978],
						[77.5193849320344, 12.892946725120762],
						[77.51918431249685, 12.892884258076398],
						[77.51902376411704, 12.8928396574832],
						[77.51894799570225, 12.892803962749754],
						[77.51885429127664, 12.892844162131425],
						[77.51879637122899, 12.892942252650638],
						[77.51873390153735, 12.893089400747975],
						[77.51869829204794, 12.893214237626347],
						[77.51864474655395, 12.89330340723508],
						[77.51856451575853, 12.893388104569572],
						[77.5184752731498, 12.89343273987168],
						[77.51840396650533, 12.893428272830931],
						[77.51817211023636, 12.893374724503863],
						[77.51788224549009, 12.893258854825238],
						[77.51773954392094, 12.89320977138305],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b3",
			properties: {
				AREA_SQ_KM: 9.15801881618304,
				ASS_CONST1: "Bangalore South",
				ASS_CONST_: "176",
				LAT: 12.9002644029525,
				LON: 77.5370809453946,
				LOST_AREA_SQ_KM: 0.5449480600374709,
				OBJECTID: 180,
				POP_F: 10384.0,
				POP_M: 11461.0,
				POP_SC: 3960.0,
				POP_ST: 449.0,
				POP_TOTAL: 21845.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Uttarahalli",
				WARD_NO: 184.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57023209944023, 12.908687330534729],
						[77.57021425904114, 12.908669498216863],
						[77.5674674162225, 12.906569236596496],
						[77.56033733027563, 12.900669857230936],
						[77.56013669555608, 12.900500390586101],
						[77.5601054720739, 12.90025065263031],
						[77.56098392440772, 12.89950157717742],
						[77.56123362262893, 12.899287554895398],
						[77.56008763008022, 12.896250902957492],
						[77.56016791902965, 12.896179537487512],
						[77.5599137579556, 12.8957782372829],
						[77.55985577136782, 12.8954750123805],
						[77.55974871912785, 12.894930942400014],
						[77.55972195605209, 12.894587603111045],
						[77.5596506752802, 12.894293307414852],
						[77.55962837269819, 12.894195221547408],
						[77.55948563606948, 12.893490654874123],
						[77.56060487183615, 12.893294496996186],
						[77.56080550572713, 12.893258803532609],
						[77.56098392440772, 12.893236518770987],
						[77.56103298949567, 12.89365571157857],
						[77.56127822715696, 12.893602168822245],
						[77.56141641362255, 12.893923195259173],
						[77.56150116207412, 12.893892021995692],
						[77.56180884477436, 12.893847419694287],
						[77.56193373677482, 12.894208649184042],
						[77.56241528685523, 12.894253206169422],
						[77.56342307202023, 12.894155080478242],
						[77.56444860207591, 12.894039190538074],
						[77.56578634249463, 12.893887521015111],
						[77.5674807968466, 12.893820671827068],
						[77.56809175620891, 12.893825154542121],
						[77.56812735021846, 12.893807302677043],
						[77.56835927946136, 12.89407932126731],
						[77.56847069628172, 12.894181854322058],
						[77.56868032402103, 12.894382496390646],
						[77.56925550836158, 12.894993396005164],
						[77.57003585489561, 12.895854016045755],
						[77.57064680047412, 12.896549616068352],
						[77.57079835574504, 12.89671904433017],
						[77.57117737421312, 12.897165025407872],
						[77.57189535449645, 12.898096949716166],
						[77.57215840873945, 12.898458104080216],
						[77.57250174257993, 12.898948653753836],
						[77.57275150286513, 12.899323219153413],
						[77.57281840284794, 12.899443613987913],
						[77.57285399537172, 12.89954165531087],
						[77.57298779517667, 12.899942975179464],
						[77.57313943476446, 12.900415723454536],
						[77.57322863442707, 12.900794744764813],
						[77.57338018624169, 12.901432333022305],
						[77.57346492574261, 12.901842595803156],
						[77.5734738456864, 12.902056636210117],
						[77.57345600579814, 12.902248368377153],
						[77.5734114060652, 12.902569450030564],
						[77.57331328659089, 12.903077797158767],
						[77.57324647435115, 12.903572740392132],
						[77.57322863442707, 12.903759993967224],
						[77.57323309440835, 12.903875934449445],
						[77.57323755438945, 12.90400971230606],
						[77.57325539431214, 12.90419255990378],
						[77.57330882661276, 12.904584958791439],
						[77.57336234633868, 12.904986238928268],
						[77.57337126629054, 12.905365303161352],
						[77.57337572626619, 12.90557930845619],
						[77.5733534263861, 12.905918185821044],
						[77.57329990665599, 12.906248155594122],
						[77.57325985429236, 12.90648898085208],
						[77.57309483490678, 12.906488991857525],
						[77.57250620258984, 12.906573722744135],
						[77.57219408892215, 12.906627168463405],
						[77.5720915083666, 12.90666291984247],
						[77.57194441485875, 12.906752030164734],
						[77.57174817328199, 12.906899188767348],
						[77.57156539895847, 12.907108778858202],
						[77.57101243924426, 12.907688466854982],
						[77.57077605535724, 12.90796045831695],
						[77.57044163646624, 12.908406381052703],
						[77.57023209944023, 12.908687330534729],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b4",
			properties: {
				AREA_SQ_KM: 1.6096168810011549,
				ASS_CONST1: "Bangalore South",
				ASS_CONST_: "176",
				LAT: 12.9002286761175,
				LON: 77.5670140722376,
				LOST_AREA_SQ_KM: 0.028590131133075788,
				OBJECTID: 181,
				POP_F: 13104.0,
				POP_M: 14896.0,
				POP_SC: 2108.0,
				POP_ST: 249.0,
				POP_TOTAL: 28000.0,
				RESERVATIO: "Backward Category - A",
				WARD_NAME: "Yelchenahalli",
				WARD_NO: 185.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.61862221252159, 12.88624466661935],
						[77.6186177543332, 12.886222394563593],
						[77.6184750922116, 12.885629293287653],
						[77.6184438848484, 12.885446505358667],
						[77.61839038649136, 12.88526808258626],
						[77.61832351350932, 12.885027325472086],
						[77.61827893149922, 12.884844503536389],
						[77.61826109869023, 12.884791003910872],
						[77.61823880767504, 12.88474194463301],
						[77.6182119710352, 12.884732995027694],
						[77.618127265117, 12.884728521948967],
						[77.6179221873669, 12.884737519932463],
						[77.6178954380676, 12.884724093743626],
						[77.61787760519789, 12.884697392387473],
						[77.61785085588801, 12.884523435772437],
						[77.61781519013161, 12.884269262858732],
						[77.6177883533908, 12.88414889885108],
						[77.6177705205041, 12.88408195752375],
						[77.61776606228199, 12.884024012703279],
						[77.61774822939178, 12.883711926037288],
						[77.6177705205041, 12.883319465616207],
						[77.61778389516938, 12.883141135649076],
						[77.61777943694781, 12.883069749324633],
						[77.6177705205041, 12.883011764622122],
						[77.6177705205041, 12.882967216547788],
						[77.61776160405971, 12.882904751246219],
						[77.61774377116879, 12.882753195360939],
						[77.61772593827503, 12.882512371549666],
						[77.61772593827504, 12.882441026021251],
						[77.61772593827503, 12.88231617145166],
						[77.6177526876146, 12.88221809843624],
						[77.6177526876146, 12.882173464501932],
						[77.6177526876146, 12.882137791833138],
						[77.6177526876146, 12.882106599798366],
						[77.61774377116879, 12.881995106664704],
						[77.61773039649874, 12.88191037078376],
						[77.61771256360288, 12.881856872709449],
						[77.61769027247908, 12.881812295503154],
						[77.61766798135086, 12.881772198926784],
						[77.61765014844511, 12.881749892849703],
						[77.61763231553654, 12.881714231123267],
						[77.61763677376393, 12.88169634908112],
						[77.61765460667182, 12.881674107581077],
						[77.61773931294563, 12.881633946205506],
						[77.61824326587842, 12.881424395485425],
						[77.61839038649136, 12.881353003868057],
						[77.61840821927987, 12.881402107299357],
						[77.61841267747656, 12.881428858964457],
						[77.61855088148621, 12.88137082829478],
						[77.61865787802233, 12.881321820898627],
						[77.61882737641186, 12.881210309425496],
						[77.61897895443575, 12.881116726993351],
						[77.61902353616863, 12.880764455883146],
						[77.61892991450917, 12.880403266614838],
						[77.61879616914632, 12.880028704763033],
						[77.61863112889785, 12.879498072982377],
						[77.6183502627069, 12.879355343079181],
						[77.61811834870085, 12.879355309286481],
						[77.6181138904925, 12.879292886307095],
						[77.61810051586643, 12.879176961145932],
						[77.6180693083994, 12.878962906721734],
						[77.6184840085995, 12.878873777276484],
						[77.61831013890814, 12.87858838199969],
						[77.61824326587842, 12.877812481815353],
						[77.61794893665984, 12.877411142452319],
						[77.61780627369075, 12.877148091299622],
						[77.6177705205041, 12.876974147967694],
						[77.61781073191126, 12.876764624397518],
						[77.61790881271804, 12.876403459376634],
						[77.61785531410676, 12.876265203356413],
						[77.6177705205041, 12.876011032695725],
						[77.61744506982598, 12.876055651536642],
						[77.61664240984851, 12.876171510592805],
						[77.6160092467815, 12.876251854164217],
						[77.61578624455538, 12.876265169574873],
						[77.61576395305025, 12.876260745965741],
						[77.61573274493566, 12.876242886715579],
						[77.61539382600255, 12.876225082581566],
						[77.61539828431857, 12.874940816216863],
						[77.61519765992244, 12.87260426474494],
						[77.61456003038387, 12.871435972562686],
						[77.61450207183034, 12.870990103540121],
						[77.61477848927912, 12.87061997407621],
						[77.6146581140219, 12.870254358679281],
						[77.61458678047545, 12.870102682370614],
						[77.61454219698597, 12.869973390083002],
						[77.61442619241201, 12.869732566729642],
						[77.6143058167783, 12.869580965097477],
						[77.61410964880325, 12.869362511518489],
						[77.61399373120287, 12.869175196315338],
						[77.61347647149768, 12.86816302513159],
						[77.61336055324574, 12.867935564452225],
						[77.61140295045283, 12.868234386613112],
						[77.60966841584936, 12.868305701871986],
						[77.6093205615601, 12.868332444870322],
						[77.60894158362439, 12.868336939738576],
						[77.60879882182476, 12.86835028969795],
						[77.60875423604502, 12.86844396537728],
						[77.60875869462379, 12.868555402100094],
						[77.60875869462379, 12.868671364689858],
						[77.6088077389786, 12.868836362240069],
						[77.60891028619696, 12.869108330804638],
						[77.60896387647941, 12.869447232593204],
						[77.60898171076026, 12.869812860661304],
						[77.60904413072097, 12.870432705949588],
						[77.60862047859996, 12.870553098795707],
						[77.60826824990198, 12.870651224306714],
						[77.60761274665487, 12.870834042925198],
						[77.60748790511353, 12.870883052870527],
						[77.60735405874163, 12.870900875787497],
						[77.60693494634417, 12.871007869117758],
						[77.6068903598272, 12.870918725246902],
						[77.60687252521548, 12.870856273622415],
						[77.60686360790856, 12.870811736935872],
						[77.6068457732926, 12.870771599411936],
						[77.6068190213634, 12.870744821119754],
						[77.60676551748591, 12.870753726586964],
						[77.60673876553766, 12.870744782284161],
						[77.60671647224258, 12.870700210463724],
						[77.60669863760336, 12.870664553022399],
						[77.60671201358304, 12.87061108630273],
						[77.60674322419614, 12.870548649023796],
						[77.60672538956113, 12.870508511609888],
						[77.60669863760336, 12.870468379474827],
						[77.60668972028269, 12.870432716855317],
						[77.60669417894312, 12.870356855396023],
						[77.60666296831644, 12.870289975083098],
						[77.60656933638458, 12.870125002187635],
						[77.60647115828101, 12.870013486538035],
						[77.60639981955195, 12.86992435939143],
						[77.60636860884378, 12.86988866705867],
						[77.60628835269742, 12.869803939132558],
						[77.60620809649392, 12.869732565094433],
						[77.60614567496282, 12.869661266737275],
						[77.60606095997247, 12.869558664790972],
						[77.60598516229557, 12.869438310100119],
						[77.605913823259, 12.869313429886617],
						[77.60577114505047, 12.869117257154928],
						[77.60569534717875, 12.86891212854263],
						[77.60515575578209, 12.868078271033024],
						[77.60497294806586, 12.867583306913337],
						[77.60491944331237, 12.867186438258566],
						[77.60481689246386, 12.867066102431089],
						[77.6046117904871, 12.866869885193541],
						[77.6044957756914, 12.86541176562534],
						[77.60436647194652, 12.86504161371532],
						[77.60418812171031, 12.864916803024515],
						[77.60398747735742, 12.864818668967597],
						[77.60391613705704, 12.864814231629024],
						[77.60316251495115, 12.866080600559775],
						[77.60289944544566, 12.866437287526274],
						[77.60267204588718, 12.866722707436258],
						[77.60190058007078, 12.866758344136638],
						[77.59989845128868, 12.866816393044973],
						[77.59994749949692, 12.867293506944495],
						[77.59995195842389, 12.867476272191615],
						[77.5980479805849, 12.867583353753755],
						[77.5980479805849, 12.867801830133384],
						[77.59804352158271, 12.867926707221244],
						[77.59803014457506, 12.868029190352464],
						[77.598003390555, 12.86810501750821],
						[77.5979766365286, 12.868154051946687],
						[77.59794542348979, 12.868225444895232],
						[77.59790529242719, 12.868323549462069],
						[77.59784286630146, 12.868492956020676],
						[77.5977715221155, 12.868898765283971],
						[77.5977581450756, 12.868996855435732],
						[77.59774922704813, 12.869041486539453],
						[77.59772247296148, 12.869081561529987],
						[77.5976823418196, 12.869112813452116],
						[77.59759316145326, 12.86917075620356],
						[77.59750398101644, 12.869219825364924],
						[77.59745493174616, 12.869233162762528],
						[77.59742371856306, 12.869259929862505],
						[77.59738804634324, 12.86929561604635],
						[77.59733007896196, 12.869358064405684],
						[77.59730778380741, 12.86938482658402],
						[77.5972765705836, 12.869429426943032],
						[77.59725427541846, 12.869469542542912],
						[77.59718293086044, 12.869665747959827],
						[77.59705807777537, 12.870040316341342],
						[77.59701348735437, 12.870120547787991],
						[77.5969822740492, 12.870165148277458],
						[77.59694660168987, 12.87021418801388],
						[77.59693322455219, 12.870254298885522],
						[77.59692876550595, 12.87037469823913],
						[77.59684841521118, 12.870744846004035],
						[77.59646939536722, 12.87208252393882],
						[77.59629995079004, 12.872626585098864],
						[77.59624198284996, 12.872720221634028],
						[77.59621968748047, 12.87275585794429],
						[77.59616617857574, 12.872800427827292],
						[77.5961215878024, 12.872836119141306],
						[77.59603240620292, 12.872885188036797],
						[77.5959788972094, 12.872911924139764],
						[77.59585404279255, 12.872938698282551],
						[77.59574256551798, 12.872947588408564],
						[77.59444496194392, 12.873090299017663],
						[77.5943736156104, 12.873068022928686],
						[77.59355321696945, 12.873322177491866],
						[77.593267829111, 12.873687787370192],
						[77.58895588404776, 12.869852972568097],
						[77.58868832206153, 12.869737066369007],
						[77.58819779010777, 12.869469479496596],
						[77.58806400829624, 12.869402610036156],
						[77.58799265793199, 12.869371374366432],
						[77.58794360453044, 12.869358003192666],
						[77.58790792931593, 12.86935802131819],
						[77.58783657885311, 12.869375890301983],
						[77.58762707404755, 12.869268875158069],
						[77.58739518430234, 12.868987976980733],
						[77.58659694481032, 12.867975758455762],
						[77.5863562214646, 12.86756995983392],
						[77.58618230224447, 12.867257809229898],
						[77.58772955307444, 12.865451859144464],
						[77.58907628673487, 12.86368603833606],
						[77.589254573412, 12.86341406704839],
						[77.58973618211715, 12.863454212046497],
						[77.59047196922987, 12.863485389797074],
						[77.59057453311348, 12.863360553804501],
						[77.59163129418421, 12.862031757531954],
						[77.59326336991998, 12.86136737501665],
						[77.59481061119561, 12.86102395114387],
						[77.59412399052249, 12.859953835262505],
						[77.59388765494818, 12.85949452498198],
						[77.59361118629006, 12.859191286698083],
						[77.59339268638782, 12.859057501623576],
						[77.59306716534115, 12.851682201655619],
						[77.59230463976391, 12.851753493985445],
						[77.5915153534806, 12.85197200623807],
						[77.59113185645923, 12.852047769053955],
						[77.59084209063238, 12.851378962615684],
						[77.59019995152232, 12.85037568120338],
						[77.58898709957157, 12.848739142771707],
						[77.5880729270886, 12.847584237542227],
						[77.58624473481584, 12.845492904427562],
						[77.58592811213364, 12.845229828572114],
						[77.58510319264501, 12.844931045439514],
						[77.58498278572472, 12.844783961539163],
						[77.58350685053387, 12.840293643596919],
						[77.5830118364957, 12.838068504494025],
						[77.58294494254052, 12.836646084399161],
						[77.58311440715009, 12.836525671380487],
						[77.5833418460953, 12.836351784922533],
						[77.58351131010993, 12.836218022570423],
						[77.58382339255586, 12.83597272883166],
						[77.58493819053652, 12.834965030914502],
						[77.58505413798926, 12.834862475929501],
						[77.5856650024694, 12.834296152527312],
						[77.58604851804687, 12.834148996207114],
						[77.58681099841172, 12.83401077574006],
						[77.5874264002573, 12.834091009494864],
						[77.58823346523077, 12.834519098175267],
						[77.58871507828867, 12.83494717736476],
						[77.58902277444533, 12.835205745263737],
						[77.58922781731263, 12.835326187474454],
						[77.58991009586721, 12.835477816759221],
						[77.59039170177773, 12.835584828160675],
						[77.59051210293454, 12.835589274745727],
						[77.5906904747828, 12.83558924973752],
						[77.59076182344326, 12.835584812907543],
						[77.59092227032754, 12.835531311497615],
						[77.591537649779, 12.835170151638884],
						[77.59182304200978, 12.834942690483953],
						[77.59173831626653, 12.837091945809314],
						[77.59215302587762, 12.839268061416053],
						[77.59236706895204, 12.841007112600938],
						[77.59267921437508, 12.8422601010755],
						[77.59288433804043, 12.843276741749621],
						[77.59320540042081, 12.845595463136258],
						[77.59341944150061, 12.847285521749964],
						[77.59357551286551, 12.848154993842646],
						[77.59412399052249, 12.848587534593875],
						[77.59471696942688, 12.848917545158946],
						[77.59521639129595, 12.849300977858869],
						[77.59595660178795, 12.849488307015875],
						[77.60090625216957, 12.848984378053313],
						[77.60215482165479, 12.849153886657454],
						[77.60432634316797, 12.848810492959075],
						[77.60491498458175, 12.848739139128536],
						[77.60503982897204, 12.848699004136064],
						[77.60515575578209, 12.84868118452371],
						[77.60719354779478, 12.848890770209998],
						[77.6092626003113, 12.848774847105778],
						[77.61083663540222, 12.848079216717743],
						[77.611264737672, 12.847298859376936],
						[77.6114609106008, 12.846951071656164],
						[77.61173742293246, 12.84696441049344],
						[77.61181321674111, 12.847254257172606],
						[77.6128700411324, 12.849630950290791],
						[77.61329804825648, 12.851164918514849],
						[77.61366818219105, 12.852591834783965],
						[77.61364589026671, 12.85332755549974],
						[77.61365480703698, 12.854397714304392],
						[77.61381530878096, 12.854990797583579],
						[77.61409181533404, 12.855204858794844],
						[77.61487211439024, 12.85560168394715],
						[77.61595574726772, 12.856052068265786],
						[77.61664686811511, 12.85655146886182],
						[77.61712398903826, 12.857207029296573],
						[77.6173246100026, 12.857612776822762],
						[77.61735590509248, 12.858263822029196],
						[77.61761002439685, 12.858691850280309],
						[77.61769473070419, 12.858910395002624],
						[77.61802918450051, 12.859012905549255],
						[77.61902353616863, 12.85908430288215],
						[77.62269788441002, 12.858968353702288],
						[77.62515484227359, 12.85880783661171],
						[77.62758054164682, 12.85840199765868],
						[77.62804878799341, 12.85831728284569],
						[77.6283564642028, 12.858312885758025],
						[77.62871317525429, 12.858446595914879],
						[77.62952920980712, 12.859574731999743],
						[77.6307019431793, 12.861055166261208],
						[77.63167852696947, 12.86180431299877],
						[77.63270404881376, 12.862067380696924],
						[77.6336137513165, 12.862116439661433],
						[77.63444312297847, 12.862183384686007],
						[77.63486230776009, 12.862611448172402],
						[77.63503623926839, 12.863017161440645],
						[77.6355088246753, 12.863418476276445],
						[77.63595029299056, 12.863632557780525],
						[77.63755559385837, 12.863641467781003],
						[77.63838502382174, 12.86382428969215],
						[77.63897357451894, 12.864390601096131],
						[77.639423943945, 12.865442913774928],
						[77.63953992273821, 12.865875522795518],
						[77.64009726602919, 12.866138544113937],
						[77.6403737079744, 12.866044942089465],
						[77.6413547523815, 12.865737230606362],
						[77.64164456305797, 12.865661408442065],
						[77.64180956952627, 12.865313674793654],
						[77.64197457575207, 12.86407850244731],
						[77.64197903301434, 12.86132269860932],
						[77.64232678632314, 12.857822361697934],
						[77.64275494354165, 12.853590639812731],
						[77.64293768987923, 12.852449122741264],
						[77.64314726661371, 12.851686656975682],
						[77.64328552761464, 12.851387824128592],
						[77.64347727489034, 12.851521678225241],
						[77.64375816570465, 12.851980935215948],
						[77.6441461154899, 12.852029948402823],
						[77.64440034903278, 12.85235104799434],
						[77.64457417837362, 12.852886119113373],
						[77.64516278402161, 12.853818070788254],
						[77.64596095806299, 12.85396074971528],
						[77.64634890023366, 12.85396074176009],
						[77.64656747215228, 12.853795804197265],
						[77.64681269867609, 12.853412271979279],
						[77.64686618350603, 12.853140331891684],
						[77.64697769754659, 12.853167080415751],
						[77.64717389556992, 12.853461304619719],
						[77.6474235778144, 12.853514861636082],
						[77.64764206024662, 12.85340341927787],
						[77.64772237428177, 12.853238375240952],
						[77.64796314125692, 12.853140310744216],
						[77.64805228171195, 12.852810273877079],
						[77.64841347461507, 12.853233894067396],
						[77.6486944404655, 12.85369325018939],
						[77.64900214751489, 12.854094518999707],
						[77.64944801973657, 12.85433529953584],
						[77.64972443865005, 12.854250642704374],
						[77.65018822313738, 12.853675365579385],
						[77.65037550168867, 12.85326960359666],
						[77.65065200570693, 12.853184863244323],
						[77.65098190452079, 12.853180453988724],
						[77.65128960529472, 12.85347471757591],
						[77.6513252604278, 12.854072249015415],
						[77.6511915536203, 12.854451266735323],
						[77.65111124206909, 12.854834716291673],
						[77.65105775926314, 12.855240548845446],
						[77.65117372603392, 12.855508052591382],
						[77.65150808019679, 12.855668584827871],
						[77.65165524465093, 12.855927232760116],
						[77.65198077103821, 12.856065462863507],
						[77.65272095772231, 12.85629291135184],
						[77.65413900892928, 12.856564865900074],
						[77.65530283394335, 12.856823539135402],
						[77.65696605681102, 12.857269444062892],
						[77.6577107545997, 12.857461172116345],
						[77.65792921698775, 12.857608275375721],
						[77.65839742414322, 12.857898108602454],
						[77.65870956115995, 12.858174589170195],
						[77.66002503610947, 12.859186852284983],
						[77.66111749599217, 12.859895851352373],
						[77.66171947161288, 12.860172308434812],
						[77.66300371623475, 12.861514487821228],
						[77.66394008616923, 12.862473204826234],
						[77.664488570222, 12.863262475705152],
						[77.66549631637783, 12.864716085008178],
						[77.66617411296905, 12.865741749103575],
						[77.6663390833374, 12.866120698722792],
						[77.66594221035585, 12.866976885668187],
						[77.66575495781987, 12.867369277986494],
						[77.66527778136937, 12.868002446472143],
						[77.66460897945686, 12.868501918148713],
						[77.66383304539636, 12.868934432198614],
						[77.66247305070101, 12.869563156962608],
						[77.6618041446552, 12.870160674993354],
						[77.65904843681348, 12.87170350529412],
						[77.65871847435427, 12.8718730043877],
						[77.65802289352928, 12.871400320433008],
						[77.65782662710338, 12.870446075791133],
						[77.65781325720522, 12.870392533589017],
						[77.65793367361603, 12.870379211961389],
						[77.65789802058481, 12.86908604435712],
						[77.65733613430534, 12.86907261870008],
						[77.6573405909572, 12.869549763220032],
						[77.65682781267317, 12.869549784330399],
						[77.65681444265584, 12.868978985028994],
						[77.65652012709936, 12.868952248439937],
						[77.65626600841395, 12.868934400964958],
						[77.65613667681225, 12.868952296086153],
						[77.65596277800445, 12.868987977830948],
						[77.65590029670862, 12.868992419220074],
						[77.6559047534176, 12.869050362293379],
						[77.65596723471094, 12.869732581080758],
						[77.65532957433705, 12.869741485094586],
						[77.65494166322986, 12.869737095798817],
						[77.65401858844152, 12.869710287729914],
						[77.65383577276585, 12.869607764217314],
						[77.65295734453002, 12.870749247920136],
						[77.6516999008099, 12.872256417103475],
						[77.64949704634665, 12.872309962475438],
						[77.64831542042634, 12.872376815198333],
						[77.64780260086664, 12.871872996496492],
						[77.64705792466397, 12.87184619614695],
						[77.64704009642062, 12.871302245793768],
						[77.64689292591144, 12.870990048702598],
						[77.64658084339136, 12.870236514699371],
						[77.64484178239317, 12.870423755057086],
						[77.64417285854353, 12.870512910906342],
						[77.64410154371973, 12.87013841017981],
						[77.64381619657557, 12.870053665510843],
						[77.64281734476354, 12.869826240775387],
						[77.64214403899106, 12.869585436721342],
						[77.64080178524283, 12.869817288005967],
						[77.64085981764197, 12.869995735204713],
						[77.6412432327542, 12.87085631059798],
						[77.64139041067156, 12.871306626543557],
						[77.6414126970971, 12.871467176714587],
						[77.6413993252423, 12.871632148051603],
						[77.64094450643566, 12.874249657029806],
						[77.64036925064839, 12.87436117710298],
						[77.64035587866934, 12.87441017227421],
						[77.64030239073719, 12.874570738821248],
						[77.64019541479647, 12.87490962265747],
						[77.63996800318517, 12.875672143121479],
						[77.63984756748737, 12.876051145616435],
						[77.63981190870736, 12.876149229658703],
						[77.6397851646149, 12.87618492222872],
						[77.63971375960487, 12.876323181906516],
						[77.63938382766536, 12.87684490096892],
						[77.63903597784237, 12.877379985464414],
						[77.63870604391165, 12.877812522055736],
						[77.63813969148732, 12.87856606646807],
						[77.6381218618276, 12.878592877521099],
						[77.63807728766591, 12.878659689740157],
						[77.63794802249694, 12.878762278777728],
						[77.63786770146848, 12.878820236662554],
						[77.63780529749594, 12.878860349174236],
						[77.63775180834904, 12.878945090767672],
						[77.63756005129603, 12.879234964312442],
						[77.63726577262953, 12.879738824847866],
						[77.6372211981281, 12.87980572339145],
						[77.63718990856557, 12.879854728298008],
						[77.63718099366068, 12.879908245658832],
						[77.63720328092158, 12.880108961604446],
						[77.63726577262953, 12.880639554854612],
						[77.63734600668752, 12.881326274744973],
						[77.6373638364704, 12.881522472463507],
						[77.63734600668752, 12.88155367860909],
						[77.63731480456066, 12.881593812715156],
						[77.63731480456066, 12.881629487337955],
						[77.6373058896657, 12.881678524618472],
						[77.63729697477, 12.881772198330957],
						[77.63728360242514, 12.881941662423458],
						[77.63732371945494, 12.882378650759865],
						[77.6373281769018, 12.882458916130181],
						[77.63810848958096, 12.885054114130211],
						[77.63835373458339, 12.88553561654042],
						[77.6384340552643, 12.88569171245204],
						[77.63785878661739, 12.885896887075674],
						[77.63716762130201, 12.886173318465548],
						[77.63653894384628, 12.88658801837131],
						[77.63632935477862, 12.886739606849577],
						[77.6364051320221, 12.88679759394336],
						[77.63565155259398, 12.887337151803326],
						[77.63563818005328, 12.887359436850982],
						[77.63553565718824, 12.88757343364382],
						[77.63544196187087, 12.88773399224574],
						[77.63533052377507, 12.88779193296763],
						[77.63527248851906, 12.887796405485382],
						[77.63520562557399, 12.88779640702109],
						[77.63514322012269, 12.887787486493716],
						[77.6350763571007, 12.887765168781112],
						[77.63502732419262, 12.887742925351569],
						[77.63498266140031, 12.887684955463234],
						[77.63497820386038, 12.887627006120416],
						[77.63498711894006, 12.887555647774073],
						[77.63499157647964, 12.887493211483868],
						[77.63504515434349, 12.887292566261017],
						[77.63516105025516, 12.88669504444733],
						[77.63475523923542, 12.886650454274832],
						[77.63474632413732, 12.886293701909867],
						[77.63425136027807, 12.886244690202625],
						[77.63244096091792, 12.886146590812947],
						[77.63210209231208, 12.887029478897938],
						[77.63200848153699, 12.887203394801519],
						[77.63144218275805, 12.889152018973883],
						[77.63151796329711, 12.889227760632753],
						[77.63160265913326, 12.889263425322953],
						[77.6317231036696, 12.8892768380161],
						[77.63237409628812, 12.889281336357792],
						[77.63282003454435, 12.889272350885745],
						[77.6331544433733, 12.889308028817998],
						[77.63331500470787, 12.889357134154952],
						[77.63415775129315, 12.889731673558238],
						[77.6343093960615, 12.88980301792478],
						[77.63428710822653, 12.889847628579387],
						[77.63421124215122, 12.889999214527686],
						[77.63420678458068, 12.890088366531634],
						[77.63418895429679, 12.890485219542638],
						[77.63421124215122, 12.891243343725105],
						[77.63432276876036, 12.891979099165766],
						[77.6343495141533, 12.892108348049323],
						[77.63435842928286, 12.892135143480171],
						[77.63297613873732, 12.892451770598846],
						[77.63181225701686, 12.892665740301005],
						[77.63101406982581, 12.89273711532484],
						[77.63024716847164, 12.892768356285924],
						[77.63023370788143, 12.892643447068918],
						[77.63021587696727, 12.892549784064665],
						[77.63017575740002, 12.892465140660725],
						[77.63010889142281, 12.89234026969254],
						[77.63000636351383, 12.892166386938282],
						[77.62997961708741, 12.892086087353032],
						[77.62994832550856, 12.892037025402761],
						[77.62992603680883, 12.892019157859728],
						[77.62985025519666, 12.892001324031403],
						[77.62981013549894, 12.891992430059673],
						[77.62976555804025, 12.891974619646346],
						[77.62973435380866, 12.891947881569285],
						[77.62963619590319, 12.891863141767454],
						[77.62952029429451, 12.891756097826258],
						[77.62948908999485, 12.89169368293907],
						[77.62944451240887, 12.89135030021538],
						[77.62938656152066, 12.891096161745878],
						[77.62937755858965, 12.891024809890116],
						[77.62933743872327, 12.890864247342757],
						[77.62929286107718, 12.890797403440661],
						[77.62920370573194, 12.890743858701269],
						[77.62911900808848, 12.890676997107242],
						[77.62895398298176, 12.890494190407018],
						[77.62888711627794, 12.890396122424502],
						[77.62874883755644, 12.890208818313615],
						[77.62869980188806, 12.890146329506141],
						[77.62869534409897, 12.890097342043722],
						[77.62865968177985, 12.889727245231061],
						[77.62864185061606, 12.889223310175579],
						[77.6286507661983, 12.889160914610965],
						[77.62868197073064, 12.888995915017063],
						[77.62871317525429, 12.88883539665203],
						[77.62869980188806, 12.888536598309129],
						[77.62867751294083, 12.888139741338117],
						[77.62866413957036, 12.888081798420705],
						[77.62825393469586, 12.888059477614814],
						[77.62808890831958, 12.888059534996325],
						[77.62786593002923, 12.88810853206565],
						[77.62740222817045, 12.888211107962393],
						[77.62708117579164, 12.888291360453096],
						[77.6269741872647, 12.8883181412707],
						[77.62675566463035, 12.888389496030713],
						[77.62667096514312, 12.888389506030157],
						[77.62647027343058, 12.888407335963599],
						[77.62612247104765, 12.888398414743184],
						[77.62560517966354, 12.888402864776552],
						[77.62560963757512, 12.888550001717073],
						[77.62519496363207, 12.888581233748006],
						[77.62476691432657, 12.888590111425712],
						[77.62428528042639, 12.88857230356413],
						[77.62405792403408, 12.888549995430768],
						[77.62393747128787, 12.888545541111522],
						[77.62376806800569, 12.888545558826701],
						[77.62360749303888, 12.88855892346331],
						[77.62338459327665, 12.88859904363694],
						[77.62309919352471, 12.88863919742478],
						[77.62274692269487, 12.88869718596035],
						[77.62236790252577, 12.888728390568481],
						[77.62192646829338, 12.88878186449326],
						[77.62159202607651, 12.888826483495876],
						[77.62152961306975, 12.888830915827588],
						[77.62148503232943, 12.888813105095014],
						[77.62147157069066, 12.888764034160845],
						[77.62142253185006, 12.888697151347348],
						[77.6213601187493, 12.888661513837068],
						[77.62131553794184, 12.88865257880302],
						[77.62115058880073, 12.888665991444276],
						[77.62098109390946, 12.888692715955223],
						[77.62052182178662, 12.888772996886459],
						[77.62009375470423, 12.88882650189086],
						[77.61990196753561, 12.888839839055532],
						[77.6198217210299, 12.888781893432093],
						[77.61949627628137, 12.88844744109904],
						[77.61939365128508, 12.888322593057284],
						[77.61930894625597, 12.888220056990713],
						[77.61928219728618, 12.888184398180243],
						[77.61925099014671, 12.888130861612265],
						[77.61916628501027, 12.887858828346658],
						[77.61907257605442, 12.887573440566653],
						[77.61901907799614, 12.887448611801634],
						[77.61897449626149, 12.887381684155123],
						[77.61892991450917, 12.88731484272614],
						[77.61880954368975, 12.886989321528256],
						[77.61871592185233, 12.886690584185311],
						[77.61868025636997, 12.886498792353615],
						[77.61862221252159, 12.88624466661935],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b5",
			properties: {
				AREA_SQ_KM: 18.997837826250365,
				ASS_CONST1: "Bangalore South",
				ASS_CONST_: "176",
				LAT: 12.8649996624606,
				LON: 77.6220696892561,
				LOST_AREA_SQ_KM: 1.8969426590966916,
				OBJECTID: 182,
				POP_F: 9388.0,
				POP_M: 9899.0,
				POP_SC: 3488.0,
				POP_ST: 148.0,
				POP_TOTAL: 19287.0,
				RESERVATIO: "Backward Category - B",
				WARD_NAME: "Begur",
				WARD_NO: 192.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.58613770752953, 12.874008901547718],
						[77.58614216700182, 12.874280923198873],
						[77.586855592861, 12.874918528637327],
						[77.5886571064552, 12.876091307569382],
						[77.58916092703647, 12.876452428447683],
						[77.5896559141422, 12.876853793160148],
						[77.58968712947257, 12.87719714735388],
						[77.58968712947257, 12.877634125497675],
						[77.5897406414474, 12.877834754792628],
						[77.59001711958032, 12.87835206944442],
						[77.59042737620794, 12.879582783575087],
						[77.59064142255262, 12.880581585762625],
						[77.59073506770086, 12.8810899143221],
						[77.59075290486315, 12.881286173746979],
						[77.59071723053576, 12.881384239027273],
						[77.59068601549004, 12.881428841012891],
						[77.59059237030112, 12.881402048028605],
						[77.58978523474043, 12.880978497292865],
						[77.58705180822868, 12.879444540419415],
						[77.58552229866653, 12.878570573899577],
						[77.58542427713871, 12.877665313679579],
						[77.5855713531191, 12.877460236574938],
						[77.58559811008423, 12.877170402379425],
						[77.58550900761688, 12.877023212181095],
						[77.58516562581244, 12.876969744031756],
						[77.58414002113369, 12.877023214526991],
						[77.58347117391891, 12.877161461574953],
						[77.58290926574826, 12.877255111525368],
						[77.58250798850285, 12.877330942802685],
						[77.58238757881848, 12.877009863129006],
						[77.58223595162612, 12.876751216372774],
						[77.58205319424397, 12.876461358652133],
						[77.58194616298924, 12.87601100470347],
						[77.581803454492, 12.875426875917043],
						[77.58168304405737, 12.87534211543812],
						[77.58122378681439, 12.875257429090043],
						[77.58077781931368, 12.875248501599781],
						[77.58018030755079, 12.875123687408411],
						[77.57961401074334, 12.874954188984095],
						[77.57921718155035, 12.875261887043358],
						[77.57869993735453, 12.87550265615161],
						[77.57840113265591, 12.875681078465119],
						[77.57819607015115, 12.875899566157797],
						[77.57815147228641, 12.875917419175588],
						[77.57794632188211, 12.876046650215459],
						[77.5777545505156, 12.876122504647986],
						[77.57747367002497, 12.876256295705415],
						[77.57733987552363, 12.876390060733625],
						[77.57733987552363, 12.876617499578096],
						[77.577228379985, 12.876590712288099],
						[77.57709913247017, 12.876541623823],
						[77.57680478338398, 12.876448020025947],
						[77.57646592245095, 12.876381146383462],
						[77.5762161703627, 12.876358855592612],
						[77.57568107290889, 12.87643016532913],
						[77.57523071110363, 12.876550586158892],
						[77.57496766400064, 12.876572886669175],
						[77.57479818718556, 12.876532726736828],
						[77.57468222921851, 12.876448004198924],
						[77.57458419829544, 12.876260759556613],
						[77.57449053976956, 12.87608681937336],
						[77.574356741741, 12.87603329111348],
						[77.57417397162936, 12.876024370458486],
						[77.5737368962613, 12.876126987998608],
						[77.57321525448215, 12.87623839268914],
						[77.57306361499596, 12.87628299578708],
						[77.57299225516745, 12.876332044931267],
						[77.57288967533518, 12.87647031528152],
						[77.5725106625996, 12.876764636411659],
						[77.57222530907278, 12.876974189637668],
						[77.57207812828729, 12.877058892886437],
						[77.57194887489064, 12.877116886327515],
						[77.57168573270896, 12.877143657960714],
						[77.57160991196683, 12.877161479055408],
						[77.57100351910654, 12.877259601655128],
						[77.57089201732575, 12.87689389800819],
						[77.57087417703065, 12.876648637438967],
						[77.57082957628054, 12.87628751761511],
						[77.57066018072258, 12.875462543497914],
						[77.57065572063995, 12.874865063603071],
						[77.57061557988821, 12.874454800582452],
						[77.57067356096948, 12.873852820333632],
						[77.57053975842942, 12.873344435530202],
						[77.57053529834205, 12.872746966757992],
						[77.57042825619195, 12.871859620053732],
						[77.57042379610017, 12.871195224189801],
						[77.57054421851664, 12.87040595519749],
						[77.57053975842943, 12.869960029528642],
						[77.570437176375, 12.869300079747845],
						[77.56997341337829, 12.868292317996618],
						[77.56977279572122, 12.86762794614601],
						[77.56963899211678, 12.866838682890164],
						[77.5695320365729, 12.866477454929527],
						[77.56995120028118, 12.86616974323052],
						[77.57015627772468, 12.866169821771292],
						[77.57184183407696, 12.8660672420889],
						[77.57239924975441, 12.866036021070313],
						[77.57232342949014, 12.864430766734504],
						[77.57230112940276, 12.864221153344175],
						[77.5722609892344, 12.86372177220041],
						[77.57219408892215, 12.862406319086993],
						[77.57214948869203, 12.861460964862511],
						[77.57212272854552, 12.861050699938067],
						[77.57213164859506, 12.860818897995955],
						[77.57218516887752, 12.860765381499197],
						[77.57511930093584, 12.860609273765698],
						[77.57803551775578, 12.860306093301114],
						[77.58204427497661, 12.860056368217183],
						[77.5804924866353, 12.854455717243615],
						[77.57818269079357, 12.847049121608928],
						[77.577932942495, 12.846612148417737],
						[77.57746029058195, 12.845956705748481],
						[77.57713035126773, 12.845265534802637],
						[77.57684046210188, 12.84469031372788],
						[77.57615819212052, 12.843481848761638],
						[77.57461095786003, 12.839120879804726],
						[77.5760512427361, 12.83879088036964],
						[77.57918150358202, 12.838122008392558],
						[77.58060843864722, 12.83786342401547],
						[77.58135311706063, 12.83758244664662],
						[77.58224933050421, 12.837176667668121],
						[77.58268191337615, 12.836837822931614],
						[77.58294494254052, 12.836646084399161],
						[77.5830118364957, 12.838068504494025],
						[77.58350685053387, 12.840293643596919],
						[77.58498278572472, 12.844783961539163],
						[77.58510319264501, 12.844931045439514],
						[77.58592811213364, 12.845229828572114],
						[77.58624473481584, 12.845492904427562],
						[77.5880729270886, 12.847584237542227],
						[77.58898709957157, 12.848739142771707],
						[77.59019995152232, 12.85037568120338],
						[77.59084209063238, 12.851378962615684],
						[77.59113185645923, 12.852047769053955],
						[77.5915153534806, 12.85197200623807],
						[77.59230463976391, 12.851753493985445],
						[77.59306716534115, 12.851682201655619],
						[77.59339268638782, 12.859057501623576],
						[77.59361118629006, 12.859191286698083],
						[77.59388765494818, 12.85949452498198],
						[77.59412399052249, 12.859953835262505],
						[77.59481061119561, 12.86102395114387],
						[77.59326336991998, 12.86136737501665],
						[77.59163129418421, 12.862031757531954],
						[77.59057453311348, 12.863360553804501],
						[77.59047196922987, 12.863485389797074],
						[77.58973618211715, 12.863454212046497],
						[77.589254573412, 12.86341406704839],
						[77.58907628673487, 12.86368603833606],
						[77.58772955307444, 12.865451859144464],
						[77.58618230224447, 12.867257809229898],
						[77.5863562214646, 12.86756995983392],
						[77.58659694481032, 12.867975758455762],
						[77.58739518430234, 12.868987976980733],
						[77.58762707404755, 12.869268875158069],
						[77.58783657885311, 12.869375890301983],
						[77.58790792931593, 12.86935802131819],
						[77.58794360453044, 12.869358003192666],
						[77.58799265793199, 12.869371374366432],
						[77.58806400829624, 12.869402610036156],
						[77.58819779010777, 12.869469479496596],
						[77.58868832206153, 12.869737066369007],
						[77.58895588404776, 12.869852972568097],
						[77.5889469653251, 12.869897602423228],
						[77.58874629387898, 12.872595368622125],
						[77.58759139873311, 12.872929741562391],
						[77.58721234782156, 12.873081425799237],
						[77.58701167329482, 12.873308834599438],
						[77.58669059331083, 12.873634299569982],
						[77.58638743770655, 12.873808219322154],
						[77.58613770752953, 12.874008901547718],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b6",
			properties: {
				AREA_SQ_KM: 6.38350762156928,
				ASS_CONST1: "Bangalore South",
				ASS_CONST_: "176",
				LAT: 12.8605912628746,
				LON: 77.5821324498369,
				LOST_AREA_SQ_KM: 0.5022184220365635,
				OBJECTID: 183,
				POP_F: 10124.0,
				POP_M: 11402.0,
				POP_SC: 3487.0,
				POP_ST: 891.0,
				POP_TOTAL: 21526.0,
				RESERVATIO: "Backward Category - B (Woman)",
				WARD_NAME: "Gottigere",
				WARD_NO: 194.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.57179277367021, 12.894226448501977],
						[77.5719042745639, 12.893138393369883],
						[77.57208258831389, 12.892139624979496],
						[77.5719221146967, 12.891314697240144],
						[77.57155647886431, 12.89027565854995],
						[77.57047731718997, 12.890266758239118],
						[77.57007599585286, 12.890222191866288],
						[77.57006707564138, 12.890168690186435],
						[77.56942499347441, 12.89005270244384],
						[77.5695810979592, 12.888888944553328],
						[77.56865356305462, 12.888857669693637],
						[77.56870262482151, 12.888237876458305],
						[77.5687160052997, 12.887716146091677],
						[77.5687739873536, 12.887190007678951],
						[77.56880074829152, 12.886926875489905],
						[77.56880966860277, 12.886614727468077],
						[77.5684440227201, 12.886663802868323],
						[77.56785090603215, 12.886677162581812],
						[77.5671463682249, 12.886637019187974],
						[77.56708392511449, 12.886222374216738],
						[77.56666037801025, 12.88624911447187],
						[77.56657563341707, 12.886253581954989],
						[77.56681193861583, 12.885682842972134],
						[77.56692344441372, 12.885566880883527],
						[77.56787320699597, 12.885459818014402],
						[77.56899244737447, 12.885406362181735],
						[77.57026778022998, 12.885290421371325],
						[77.57044609655733, 12.885268121277436],
						[77.57027224032791, 12.884728556510444],
						[77.57019641863923, 12.884438716285834],
						[77.57010275648312, 12.884104255510652],
						[77.56993335983783, 12.883685096110206],
						[77.57064680047412, 12.883573674159534],
						[77.57078051543516, 12.883529076352396],
						[77.57119521446046, 12.88345322445528],
						[77.57195333492234, 12.883341784373451],
						[77.57206028817907, 12.883332864175307],
						[77.57200676783755, 12.883047454954319],
						[77.57295657523633, 12.883002915987335],
						[77.57285845536776, 12.882204734421075],
						[77.5732420143704, 12.882177958868024],
						[77.57354520521129, 12.882088749001555],
						[77.57375027614454, 12.882048600136727],
						[77.5739465142505, 12.882008545007285],
						[77.57460203800588, 12.882004095221768],
						[77.57528422992073, 12.881981794251706],
						[77.57568553279353, 12.881905980487991],
						[77.57577473044947, 12.881772145412977],
						[77.5759620452981, 12.881419868075717],
						[77.57609138156025, 12.880938341321642],
						[77.5762384696787, 12.880675204272649],
						[77.57629198801918, 12.88029619142549],
						[77.57642578375966, 12.879957293159663],
						[77.57652390053546, 12.87959170320741],
						[77.57656403919194, 12.879154675066387],
						[77.57655957934192, 12.878744460971356],
						[77.57658633843938, 12.87836546512674],
						[77.57671558654002, 12.877986379024447],
						[77.57692073917602, 12.877544934361406],
						[77.57717040226149, 12.877192719987324],
						[77.5772863576788, 12.876916209413647],
						[77.57733987552363, 12.876621893287423],
						[77.57733987552363, 12.876617499578096],
						[77.57733987552363, 12.876390060733625],
						[77.57747367002497, 12.876256295705415],
						[77.5777545505156, 12.876122504647986],
						[77.57794632188211, 12.876046650215459],
						[77.57815147228641, 12.875917419175588],
						[77.57819607015115, 12.875899566157797],
						[77.57840113265591, 12.875681078465119],
						[77.57869993735453, 12.87550265615161],
						[77.57921718155035, 12.875261887043358],
						[77.57961401074334, 12.874954188984095],
						[77.58018030755079, 12.875123687408411],
						[77.58077781931368, 12.875248501599781],
						[77.58122378681439, 12.875257429090043],
						[77.58168304405737, 12.87534211543812],
						[77.581803454492, 12.875426875917043],
						[77.58194616298924, 12.87601100470347],
						[77.58205319424397, 12.876461358652133],
						[77.58223595162612, 12.876751216372774],
						[77.58238757881848, 12.877009863129006],
						[77.58250798850285, 12.877330942802685],
						[77.58290926574826, 12.877255111525368],
						[77.58347117391891, 12.877161461574953],
						[77.58414002113369, 12.877023214526991],
						[77.58516562581244, 12.876969744031756],
						[77.58550900761688, 12.877023212181095],
						[77.58559811008423, 12.877170402379425],
						[77.5855713531191, 12.877460236574938],
						[77.58542427713871, 12.877665313679579],
						[77.58552229866653, 12.878570573899577],
						[77.58705180822868, 12.879444540419415],
						[77.58978523474043, 12.880978497292865],
						[77.59059237030112, 12.881402048028605],
						[77.59068601549004, 12.881428841012891],
						[77.59058791100448, 12.882191304894825],
						[77.59055669592301, 12.882561415446586],
						[77.59059237030112, 12.882753188510474],
						[77.59073506770086, 12.883101031505905],
						[77.59109172297475, 12.883881292166611],
						[77.59120766411323, 12.884171166050592],
						[77.59129684952337, 12.884510078984947],
						[77.59142170897927, 12.885063000045873],
						[77.59158670161999, 12.885816618542503],
						[77.59164913120499, 12.886365009583319],
						[77.59167588673084, 12.886712833994586],
						[77.591707101503, 12.887310400137714],
						[77.5917472347688, 12.887983702835339],
						[77.59175615327038, 12.888282427395534],
						[77.59175169401969, 12.888309183275519],
						[77.59173831626653, 12.888327068479974],
						[77.59171156075546, 12.888331475334885],
						[77.59167588673084, 12.888340453025044],
						[77.59142616824299, 12.888420701703348],
						[77.59099361884155, 12.888585690603673],
						[77.59083763134558, 12.888630270559403],
						[77.5906949340754, 12.888661527743675],
						[77.59057899241066, 12.888674850454578],
						[77.5887641313553, 12.88891569195543],
						[77.58823346523077, 12.889009289134869],
						[77.58805063010638, 12.889040519248578],
						[77.5880461707094, 12.88908959118157],
						[77.5880238737219, 12.889094038586158],
						[77.58751558865251, 12.88917870998359],
						[77.5866727555072, 12.889294671741691],
						[77.58652122150295, 12.889299137150712],
						[77.58624473481584, 12.88933923848015],
						[77.58600838275672, 12.889397200615926],
						[77.5856828404321, 12.889477517587364],
						[77.58486683819879, 12.889727188459725],
						[77.584028532299, 12.890030415322634],
						[77.58360050159457, 12.890155279594945],
						[77.58299845770783, 12.890333676108614],
						[77.58253474619309, 12.89052989566354],
						[77.58239649805874, 12.890681470901388],
						[77.58216905721189, 12.890944578188972],
						[77.58198184008539, 12.891198755137403],
						[77.58193724371344, 12.891274511054915],
						[77.58191940515972, 12.891319150989323],
						[77.5819104858818, 12.891345865286514],
						[77.58190156660321, 12.891475200091083],
						[77.58185697019958, 12.892130664864128],
						[77.5817633176947, 12.892126179744954],
						[77.5812148674816, 12.892108357238847],
						[77.58089377103306, 12.892112796265485],
						[77.58084025486966, 12.89211281802731],
						[77.58079565804749, 12.89230903067313],
						[77.58072885022607, 12.892527530147664],
						[77.58069763242163, 12.892598843355177],
						[77.58062627740773, 12.893191939443113],
						[77.5803765345045, 12.894012412150518],
						[77.58018922696517, 12.8946991358336],
						[77.58002430513078, 12.89509595375962],
						[77.57945800754223, 12.89680377515609],
						[77.57938665174623, 12.896875148669215],
						[77.57926623873843, 12.897338901763085],
						[77.57890945870346, 12.897205134995605],
						[77.57772333189028, 12.89653177362977],
						[77.57640348448056, 12.89577376870766],
						[77.57517273235652, 12.895082592980291],
						[77.5746377174183, 12.894730302797145],
						[77.57422294355425, 12.894574284326605],
						[77.57326431427244, 12.894288888060302],
						[77.5733534263861, 12.894123848885188],
						[77.5734024861165, 12.894034695246466],
						[77.57323755438945, 12.893967854381671],
						[77.57314835473387, 12.894092650962145],
						[77.57275150286512, 12.894663414906473],
						[77.57244822244712, 12.894525232570317],
						[77.57189089446246, 12.894516265117282],
						[77.57188197439396, 12.894222022172565],
						[77.57179277367021, 12.894226448501977],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b7",
			properties: {
				AREA_SQ_KM: 3.395164252960697,
				ASS_CONST1: "Bangalore South",
				ASS_CONST_: "176",
				LAT: 12.8857748274409,
				LON: 77.5799014456152,
				LOST_AREA_SQ_KM: 0.1378614430040846,
				OBJECTID: 184,
				POP_F: 9519.0,
				POP_M: 10662.0,
				POP_SC: 1032.0,
				POP_ST: 173.0,
				POP_TOTAL: 20181.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Konankunte",
				WARD_NO: 195.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[77.5695320365729, 12.866477454929527],
						[77.56963899211678, 12.866838682890164],
						[77.56977279572122, 12.86762794614601],
						[77.56997341337829, 12.868292317996618],
						[77.570437176375, 12.869300079747845],
						[77.57053975842943, 12.869960029528642],
						[77.57054421851664, 12.87040595519749],
						[77.57042379610017, 12.871195224189801],
						[77.57042825619195, 12.871859620053732],
						[77.57053529834205, 12.872746966757992],
						[77.57053975842942, 12.873344435530202],
						[77.57067356096948, 12.873852820333632],
						[77.57061557988821, 12.874454800582452],
						[77.57065572063995, 12.874865063603071],
						[77.57066018072258, 12.875462543497914],
						[77.57082957628054, 12.87628751761511],
						[77.57087417703065, 12.876648637438967],
						[77.57089201732575, 12.87689389800819],
						[77.57100351910654, 12.877259601655128],
						[77.57160991196683, 12.877161479055408],
						[77.57168573270896, 12.877143657960714],
						[77.57194887489064, 12.877116886327515],
						[77.57207812828729, 12.877058892886437],
						[77.57222530907278, 12.876974189637668],
						[77.5725106625996, 12.876764636411659],
						[77.57288967533518, 12.87647031528152],
						[77.57299225516745, 12.876332044931267],
						[77.57306361499596, 12.87628299578708],
						[77.57321525448215, 12.87623839268914],
						[77.5737368962613, 12.876126987998608],
						[77.57417397162936, 12.876024370458486],
						[77.574356741741, 12.87603329111348],
						[77.57449053976956, 12.87608681937336],
						[77.57458419829544, 12.876260759556613],
						[77.57468222921851, 12.876448004198924],
						[77.57479818718556, 12.876532726736828],
						[77.57496766400064, 12.876572886669175],
						[77.57523071110363, 12.876550586158892],
						[77.57568107290889, 12.87643016532913],
						[77.5762161703627, 12.876358855592612],
						[77.57646592245095, 12.876381146383462],
						[77.57680478338398, 12.876448020025947],
						[77.57709913247017, 12.876541623823],
						[77.577228379985, 12.876590712288099],
						[77.57733987552363, 12.876617499578096],
						[77.57733987552363, 12.876621893287423],
						[77.5772863576788, 12.876916209413647],
						[77.57717040226149, 12.877192719987324],
						[77.57692073917602, 12.877544934361406],
						[77.57671558654002, 12.877986379024447],
						[77.57658633843938, 12.87836546512674],
						[77.57655957934192, 12.878744460971356],
						[77.57656403919194, 12.879154675066387],
						[77.57652390053546, 12.87959170320741],
						[77.57642578375966, 12.879957293159663],
						[77.57629198801918, 12.88029619142549],
						[77.5762384696787, 12.880675204272649],
						[77.57609138156025, 12.880938341321642],
						[77.5759620452981, 12.881419868075717],
						[77.57577473044947, 12.881772145412977],
						[77.57568553279353, 12.881905980487991],
						[77.57528422992073, 12.881981794251706],
						[77.57460203800588, 12.882004095221768],
						[77.5739465142505, 12.882008545007285],
						[77.57375027614454, 12.882048600136727],
						[77.57354520521129, 12.882088749001555],
						[77.5732420143704, 12.882177958868024],
						[77.57285845536776, 12.882204734421075],
						[77.57295657523633, 12.883002915987335],
						[77.57200676783755, 12.883047454954319],
						[77.57206028817907, 12.883332864175307],
						[77.57195333492234, 12.883341784373451],
						[77.57119521446046, 12.88345322445528],
						[77.57078051543516, 12.883529076352396],
						[77.57064680047412, 12.883573674159534],
						[77.56993335983783, 12.883685096110206],
						[77.57010275648312, 12.884104255510652],
						[77.57019641863923, 12.884438716285834],
						[77.57027224032791, 12.884728556510444],
						[77.57044609655733, 12.885268121277436],
						[77.57026778022998, 12.885290421371325],
						[77.56899244737447, 12.885406362181735],
						[77.56787320699597, 12.885459818014402],
						[77.56692344441372, 12.885566880883527],
						[77.56691006372377, 12.885281497523222],
						[77.5669189841839, 12.88488466580225],
						[77.5668208590837, 12.884518976285175],
						[77.56672719413604, 12.883943717171626],
						[77.56649980925366, 12.883591479613946],
						[77.56629026493486, 12.883216923157743],
						[77.56614307643581, 12.882820027453718],
						[77.56608063277237, 12.882548066380338],
						[77.56586216713559, 12.882565941494054],
						[77.5651219345603, 12.882721984229825],
						[77.56473843551265, 12.882811122979072],
						[77.56380648806167, 12.883029651246702],
						[77.56338738907448, 12.883105450456704],
						[77.56311093332239, 12.883096506532443],
						[77.56289237450136, 12.882873578997835],
						[77.56274526904717, 12.88253467073577],
						[77.56268282351125, 12.882240399008959],
						[77.56266052152581, 12.881986217293573],
						[77.56298604261912, 12.881772140688048],
						[77.56302172569124, 12.881433295740287],
						[77.56307971065947, 12.881263859208842],
						[77.56319113267202, 12.88122371175904],
						[77.56334724574707, 12.881259365150381],
						[77.56340523054875, 12.881259387345303],
						[77.56365492343573, 12.881277212440772],
						[77.56376634488272, 12.881201398116378],
						[77.56398044167275, 12.881089927256033],
						[77.56454226890737, 12.880978492916585],
						[77.56493468923534, 12.880773305113294],
						[77.56523344202157, 12.88061730031763],
						[77.56555449544034, 12.880447804119331],
						[77.5658041835912, 12.880389899466023],
						[77.5661118546084, 12.88035863643575],
						[77.56601381626524, 12.879466846198454],
						[77.56604049039912, 12.879052105104234],
						[77.56732031670809, 12.878824727032612],
						[77.56740943349973, 12.879043165508927],
						[77.5674986376763, 12.879172529053097],
						[77.56752539891556, 12.879257204686347],
						[77.56774394879962, 12.879190396801283],
						[77.56765920501708, 12.878980782419653],
						[77.56769934681671, 12.878967370516724],
						[77.56788658757215, 12.878838068244637],
						[77.56799363212465, 12.87868200850955],
						[77.56795349042932, 12.878392172062407],
						[77.56783306525794, 12.87800424319154],
						[77.5677305682066, 12.877674291588978],
						[77.56753431932725, 12.87722837379835],
						[77.56736491884025, 12.876831470375757],
						[77.56725341347695, 12.876630830883904],
						[77.56680747838163, 12.876528234404997],
						[77.56514423606133, 12.877107942616322],
						[77.5638466312264, 12.877513733684724],
						[77.56372628914752, 12.87751821947821],
						[77.56319559304856, 12.877540518062084],
						[77.56294589914955, 12.877522695892676],
						[77.56246435132357, 12.877375482770987],
						[77.56161267309807, 12.87661301574504],
						[77.56134067346669, 12.876336544969178],
						[77.56116671580399, 12.876109138408877],
						[77.56081442666782, 12.875324350582263],
						[77.56071638374179, 12.875306472390518],
						[77.5604310005048, 12.875310974359978],
						[77.56002964358137, 12.875538377076765],
						[77.55926715771994, 12.876091247017253],
						[77.55905760002834, 12.87614925916443],
						[77.55883019975788, 12.876113550600962],
						[77.5584243766036, 12.876118048930909],
						[77.55650246938043, 12.875895072948273],
						[77.55633305240514, 12.875877217409663],
						[77.55591392579083, 12.875841533534597],
						[77.5556463731627, 12.875903998191156],
						[77.55547249422852, 12.876131441318776],
						[77.55536097716615, 12.876354379632685],
						[77.5547188991473, 12.877058932546866],
						[77.55467875275079, 12.877116878813698],
						[77.55437105070399, 12.8771168561213],
						[77.55207462945582, 12.877179284924225],
						[77.551329933493, 12.876760140603983],
						[77.55113820454243, 12.876581757980762],
						[77.55113820454243, 12.876563926124513],
						[77.55111590028673, 12.876430194407332],
						[77.55112482198953, 12.876372216579579],
						[77.55116050879374, 12.876300877897558],
						[77.55120065643507, 12.876233974226052],
						[77.55124526490877, 12.876153673754647],
						[77.55126756913904, 12.876077903019686],
						[77.55125418660141, 12.87597983252707],
						[77.55122296067411, 12.875912951896243],
						[77.55121403897903, 12.875908475285414],
						[77.55118727388954, 12.87588169317366],
						[77.55110697858322, 12.875841575975414],
						[77.55100000555419, 12.875828176081795],
						[77.55092863183361, 12.875828198936556],
						[77.55087064065258, 12.87579699043405],
						[77.55086617979129, 12.875689958503205],
						[77.55091971011538, 12.875542808314414],
						[77.55095985784189, 12.87546703231169],
						[77.55099554469797, 12.87538225579076],
						[77.55103569239765, 12.875261857636866],
						[77.55107129176217, 12.875128147699371],
						[77.55108467432137, 12.875021110564528],
						[77.55108021346848, 12.874869457232037],
						[77.55108021346848, 12.874762467728862],
						[77.55108021346848, 12.874584065966108],
						[77.55101338812182, 12.874280912930242],
						[77.55100892726611, 12.874178361776922],
						[77.55102230983267, 12.874089156969124],
						[77.55104907496106, 12.874013342986006],
						[77.55110697858322, 12.87391972626695],
						[77.55114266539304, 12.873843909432452],
						[77.55120065643507, 12.873589728087033],
						[77.55119619558673, 12.873473825493832],
						[77.55112482198953, 12.873259786413557],
						[77.55083495375723, 12.87264436185891],
						[77.55065660658018, 12.872381349827963],
						[77.55042472859056, 12.872011197117269],
						[77.55032221580139, 12.871881894985782],
						[77.5502285372245, 12.871757065201827],
						[77.55001450194125, 12.871574177798212],
						[77.54989860608408, 12.87147610645474],
						[77.54980492715761, 12.871297784266607],
						[77.54980492715761, 12.871248684490668],
						[77.54980046625441, 12.87071362896119],
						[77.5498406143768, 12.870214222702211],
						[77.54987184068442, 12.870142846027026],
						[77.54989414518458, 12.870098261608584],
						[77.55029098961766, 12.86968357647889],
						[77.55045149385988, 12.8693847887834],
						[77.550491641752, 12.869237607283155],
						[77.55056301574739, 12.869001305056992],
						[77.55057193749364, 12.86876502442801],
						[77.55057193749364, 12.868577673772375],
						[77.5505674766206, 12.868278905460976],
						[77.55055409400042, 12.86803371865125],
						[77.55047379824613, 12.867833045363389],
						[77.55032221580139, 12.86765023157165],
						[77.54987630158479, 12.867280088560566],
						[77.54971579652926, 12.867186430526102],
						[77.5494928384488, 12.867092793798188],
						[77.54926541900622, 12.866994722524634],
						[77.54904692097416, 12.866950139001453],
						[77.54883288346295, 12.866950129460951],
						[77.54869013325157, 12.866927868272708],
						[77.54856977509077, 12.866901081541409],
						[77.54846717315671, 12.866829752096017],
						[77.54842265106097, 12.866758364316944],
						[77.54841819010348, 12.866744971631707],
						[77.54840034627169, 12.866620166298992],
						[77.54843157297546, 12.866245593344173],
						[77.54840926818792, 12.86605381953593],
						[77.54835127571982, 12.865830877090762],
						[77.54827543937067, 12.865630250250428],
						[77.54818184657466, 12.86540723892184],
						[77.54805693943325, 12.865117436858556],
						[77.54805247846139, 12.865019373791824],
						[77.54807032234784, 12.864934611045866],
						[77.54811493205172, 12.86488558515882],
						[77.54848947793286, 12.864653660063288],
						[77.54857869699369, 12.864582309899076],
						[77.54861884554819, 12.86439060350591],
						[77.54861884554819, 12.86427914545742],
						[77.54859654079745, 12.864185481963531],
						[77.54852516556561, 12.864100750390072],
						[77.5484449558459, 12.864047292888111],
						[77.54834681475951, 12.864020538961894],
						[77.5482665174439, 12.863922373679136],
						[77.54821753430411, 12.863761883991241],
						[77.54818630754146, 12.863637043633668],
						[77.54811493205172, 12.863574621207363],
						[77.54801241717666, 12.8635344793409],
						[77.54776268985391, 12.86345868566498],
						[77.54766454817776, 12.863391793559824],
						[77.54742820308014, 12.863191144843364],
						[77.54726323356059, 12.863043960765664],
						[77.54696005989489, 12.862892306382479],
						[77.54681284630824, 12.862852175593774],
						[77.54667464205441, 12.86277190800422],
						[77.54652751556897, 12.862647112871157],
						[77.54644721696677, 12.862513290074949],
						[77.54634470058603, 12.862361692902882],
						[77.54631347330832, 12.862232375751717],
						[77.5463045512274, 12.86206295660979],
						[77.54631347330832, 12.861942540795791],
						[77.54635808370242, 12.86144761136398],
						[77.54632685642838, 12.861344996133177],
						[77.54624655768431, 12.861206782456287],
						[77.54616634635494, 12.86107305200936],
						[77.54609496959546, 12.860974972868162],
						[77.5460146706874, 12.860872331937152],
						[77.54594338130424, 12.86078760752818],
						[77.5458853875767, 12.860658301577082],
						[77.54578733174982, 12.860488919840975],
						[77.54568918836642, 12.860301619022161],
						[77.54533693864387, 12.859770969333926],
						[77.54529232784864, 12.859614916765565],
						[77.54522541162295, 12.859436521386577],
						[77.54519873259389, 12.859186844273038],
						[77.54527002244447, 12.858923775329242],
						[77.54534586080082, 12.858709674080387],
						[77.545564365955, 12.858428768944224],
						[77.54595230341354, 12.857920419287838],
						[77.54609050854648, 12.857755472343301],
						[77.54617526844672, 12.85767521326379],
						[77.54623317455477, 12.857545866012973],
						[77.54624655768431, 12.857416538878864],
						[77.54620640829097, 12.857224787652305],
						[77.54615742426245, 12.85699738697876],
						[77.54613958007539, 12.856845807426934],
						[77.54613958007539, 12.856667435860471],
						[77.54614404112242, 12.856502455883737],
						[77.54620640829097, 12.856306229810228],
						[77.54627332393864, 12.856132356727016],
						[77.54640260660769, 12.855904971840502],
						[77.54656320381838, 12.855797943340969],
						[77.54669694718272, 12.855677484325275],
						[77.54672371333089, 12.855601682374228],
						[77.54673709640261, 12.85552592837978],
						[77.54673709640262, 12.855405522231615],
						[77.54668802513193, 12.855316355198529],
						[77.54651859350486, 12.855102308760117],
						[77.54627778498042, 12.854865981862512],
						[77.54610389169284, 12.854665330756484],
						[77.54605928119886, 12.854549334385915],
						[77.54597460868372, 12.854344259126188],
						[77.54594784235897, 12.854103459707746],
						[77.54595230341354, 12.853942961997799],
						[77.54597906973723, 12.853639702639576],
						[77.5459968264779, 12.85339001327018],
						[77.54598799184372, 12.853167041522417],
						[77.54596568657617, 12.853015425822337],
						[77.54592107602792, 12.852930694839399],
						[77.54585862123095, 12.852845970486355],
						[77.54573379900575, 12.852752316309152],
						[77.54559113237012, 12.852627491488576],
						[77.54550637203393, 12.852493727443205],
						[77.54539047157502, 12.852217227508936],
						[77.54533693864387, 12.852105801906477],
						[77.54531017216885, 12.851985409657916],
						[77.54530571108906, 12.851860487819549],
						[77.54530571108906, 12.85171338747552],
						[77.54530571108906, 12.851610813516954],
						[77.5452477170359, 12.851539481021794],
						[77.54516304391568, 12.851485990309254],
						[77.54511843305214, 12.851450265256933],
						[77.54504705563406, 12.851370023977902],
						[77.5449757656435, 12.851271959316747],
						[77.54491777142188, 12.851160407274334],
						[77.54491331032668, 12.851053400200705],
						[77.54496238236422, 12.850955330338053],
						[77.5450247501817, 12.850866165171986],
						[77.54521202837309, 12.850723471050845],
						[77.54538154942158, 12.850629791862682],
						[77.54544391700888, 12.850567411042874],
						[77.54555544381523, 12.850442533567803],
						[77.54564903877603, 12.850362270461199],
						[77.54572487687928, 12.850210621988946],
						[77.5457293379426, 12.850023345429609],
						[77.54575164325658, 12.8499074584421],
						[77.5456267334419, 12.849693403728995],
						[77.54544837808324, 12.849470417055],
						[77.54538154942158, 12.849376788059034],
						[77.54533247756514, 12.849283148263627],
						[77.54520756728945, 12.849002264108758],
						[77.5454528391574, 12.848721279995436],
						[77.54567134410577, 12.848569747377566],
						[77.54601020963528, 12.848502865461091],
						[77.5465408986638, 12.848529567267624],
						[77.54688422261651, 12.848556340106748],
						[77.5474104465633, 12.848703484085178],
						[77.5495908910756, 12.849109300459585],
						[77.5507725891313, 12.849189549745594],
						[77.5517669203469, 12.849149381659117],
						[77.55238679853207, 12.849051327034907],
						[77.55316262549539, 12.848890800657541],
						[77.55347033081955, 12.848676693603787],
						[77.55377803530922, 12.848427016870064],
						[77.55414810176948, 12.848119348001907],
						[77.5562350056043, 12.845568742043726],
						[77.55675663810055, 12.845015809758152],
						[77.55697074812888, 12.844618950231839],
						[77.55700197247424, 12.844342527910186],
						[77.556997511854, 12.843914375777995],
						[77.55697966937129, 12.842210998050703],
						[77.55703756996674, 12.842023785427081],
						[77.5571936915156, 12.841840924960257],
						[77.55747016169751, 12.841716093608706],
						[77.55787590080422, 12.841711637646599],
						[77.5584020737804, 12.841707168832206],
						[77.55936528936056, 12.841698272866303],
						[77.55983346882616, 12.841662522562405],
						[77.56007870908233, 12.841568920222082],
						[77.56041761904824, 12.841350441909569],
						[77.5614342554071, 12.84061025704608],
						[77.56279433337266, 12.839803115635249],
						[77.5631688307866, 12.83970948532159],
						[77.56347650895964, 12.839736243907675],
						[77.56388231402754, 12.839825391376863],
						[77.56519329934815, 12.840146476992544],
						[77.56587554794932, 12.840324875046571],
						[77.56634378797803, 12.84032037489447],
						[77.56699480806677, 12.840311428790159],
						[77.56764582441404, 12.840306959056893],
						[77.57004031500269, 12.840159860495788],
						[77.57461095786003, 12.839120879804726],
						[77.57615819212052, 12.843481848761638],
						[77.57684046210188, 12.84469031372788],
						[77.57713035126773, 12.845265534802637],
						[77.57746029058195, 12.845956705748481],
						[77.577932942495, 12.846612148417737],
						[77.57818269079357, 12.847049121608928],
						[77.5804924866353, 12.854455717243615],
						[77.58204427497661, 12.860056368217183],
						[77.57803551775578, 12.860306093301114],
						[77.57511930093584, 12.860609273765698],
						[77.57218516887752, 12.860765381499197],
						[77.57213164859506, 12.860818897995955],
						[77.57212272854552, 12.861050699938067],
						[77.57214948869203, 12.861460964862511],
						[77.57219408892215, 12.862406319086993],
						[77.5722609892344, 12.86372177220041],
						[77.57230112940276, 12.864221153344175],
						[77.57232342949014, 12.864430766734504],
						[77.57239924975441, 12.866036021070313],
						[77.57184183407696, 12.8660672420889],
						[77.57015627772468, 12.866169821771292],
						[77.56995120028118, 12.86616974323052],
						[77.5695320365729, 12.866477454929527],
					],
				],
				type: "Polygon",
			},
			id: "000000000000000000b8",
			properties: {
				AREA_SQ_KM: 12.110695275451096,
				ASS_CONST1: "Bangalore South",
				ASS_CONST_: "176",
				LAT: 12.8595876761862,
				LON: 77.5632856881322,
				LOST_AREA_SQ_KM: 1.1094719480278765,
				OBJECTID: 185,
				POP_F: 10293.0,
				POP_M: 10787.0,
				POP_SC: 2671.0,
				POP_ST: 226.0,
				POP_TOTAL: 21080.0,
				RESERVATIO: "General",
				WARD_NAME: "Anjanapura",
				WARD_NO: 196.0,
			},
			type: "Feature",
		},
		{
			geometry: {
				coordinates: [
					[
						[
							[77.55061645874639, 12.915830752695804],
							[77.55047379824613, 12.91587094154223],
							[77.54947053386978, 12.91612954611838],
							[77.54916727862911, 12.916218710674015],
							[77.54888186633724, 12.916312352371579],
							[77.54867675041007, 12.916365908265384],
							[77.54853408747204, 12.916414971378234],
							[77.5484983998421, 12.91641937280314],
							[77.54845825124501, 12.91642381813214],
							[77.54838696339601, 12.916428316508203],
							[77.54818184657466, 12.916450591069944],
							[77.54807924429002, 12.916464009747422],
							[77.54803472204217, 12.916468416926957],
							[77.54800795620305, 12.916486259600902],
							[77.54799903425526, 12.916504099017171],
							[77.54799903425526, 12.916526417799385],
							[77.54799903425526, 12.91657993119031],
							[77.54805247846139, 12.916798452069173],
							[77.54811047108211, 12.917048128467378],
							[77.54813723689709, 12.917128351015082],
							[77.54818630754146, 12.917239893647633],
							[77.54839588531331, 12.917217575527284],
							[77.54845387775862, 12.917467253065857],
							[77.54848055602292, 12.917583233898334],
							[77.54852962651891, 12.917815075917812],
							[77.54857423604231, 12.917948896166251],
							[77.54858315794489, 12.91799344658025],
							[77.54860100174794, 12.918069276636587],
							[77.54844941680234, 12.918087060030464],
							[77.54809262720197, 12.918131680065615],
							[77.54785182201567, 12.918167316879178],
							[77.5476868531116, 12.918171788147491],
							[77.54752634496057, 12.918176301593101],
							[77.54741490756041, 12.918207477202609],
							[77.54703134863979, 12.918283294917698],
							[77.54679062867372, 12.91832789668166],
							[77.54677724560831, 12.91824762799719],
							[77.54672817435498, 12.91804698078011],
							[77.5465408986638, 12.917293344813984],
							[77.54637592785515, 12.916856351767288],
							[77.54600128753054, 12.916249939440963],
							[77.54574718219413, 12.915884321175445],
							[77.54532355540715, 12.915964585766961],
							[77.54533247756514, 12.915777246899182],
							[77.54531017216885, 12.915407187171192],
							[77.54578287068878, 12.915313513984088],
							[77.54607266434891, 12.915121765987752],
							[77.54703134863979, 12.914930055994544],
							[77.54673709640261, 12.913761811982518],
							[77.54656766484878, 12.913155294930025],
							[77.54637146681722, 12.912490966775707],
							[77.54594338130424, 12.912615742150413],
							[77.54582739381956, 12.912209992293944],
							[77.5454260727097, 12.912281336701668],
							[77.54510058870183, 12.912343747964524],
							[77.54498914892122, 12.911670492602335],
							[77.54468142060755, 12.911715059083715],
							[77.54437377893302, 12.910216793469031],
							[77.54440946785923, 12.910127603722339],
							[77.54457890263286, 12.910056221499065],
							[77.54479740926257, 12.910145402682561],
							[77.5449088492313, 12.910207892025193],
							[77.54518981042538, 12.910314898109382],
							[77.54539939372776, 12.910430792904405],
							[77.54551083310584, 12.910444166664904],
							[77.54568026623643, 12.91035951348631],
							[77.54640706764438, 12.910631518623847],
							[77.5471027248107, 12.91084997977817],
							[77.54712949086324, 12.910016124946205],
							[77.54708488077219, 12.909748582847604],
							[77.54767347015182, 12.90962368137009],
							[77.54830220514674, 12.909449783372049],
							[77.54922080975442, 12.909151090282734],
							[77.549586430164, 12.909369526568064],
							[77.5497647790226, 12.909030672073042],
							[77.5497647790226, 12.90894596910495],
							[77.54926095808183, 12.907786579468338],
							[77.5494125419438, 12.90774647505652],
							[77.54995204939594, 12.90760378225458],
							[77.55037128550127, 12.907469954976397],
							[77.55105790920139, 12.907291592070122],
							[77.55141022864157, 12.907189012859392],
							[77.55164656544856, 12.907117737999695],
							[77.55201217804412, 12.906997270705245],
							[77.55255622140055, 12.906720885227964],
							[77.55357284076497, 12.906328478748694],
							[77.55399652423272, 12.907532433304956],
							[77.55412133737205, 12.907835624658992],
							[77.55432198273365, 12.908352884589384],
							[77.55452708845796, 12.908950412650427],
							[77.55457169562395, 12.909008393942987],
							[77.5546252242, 12.909088604816668],
							[77.55463860634006, 12.909133194226182],
							[77.55463860634006, 12.909195666921292],
							[77.55465198847855, 12.909360634942884],
							[77.55467429203918, 12.909543438211099],
							[77.55487047571893, 12.909543474139099],
							[77.55496415046372, 12.909695067613843],
							[77.55509788392892, 12.909949232954766],
							[77.55520940124994, 12.910136497307285],
							[77.55550809220104, 12.910760766249073],
							[77.55579794849504, 12.911385045546448],
							[77.55589608313767, 12.911541164923042],
							[77.5558603978229, 12.911527730532333],
							[77.55581133049677, 12.911509947613125],
							[77.55570882257402, 12.91149657609988],
							[77.55559730574399, 12.911501039857527],
							[77.55551701355849, 12.911505453229154],
							[77.5551201874019, 12.911581254693818],
							[77.55494630766115, 12.91159461226379],
							[77.5548749364228, 12.911594628602783],
							[77.55477234018903, 12.911554539427046],
							[77.55468321346221, 12.911514408308294],
							[77.55457169562395, 12.911465359375763],
							[77.55445580442097, 12.911420753457863],
							[77.55435766853236, 12.911389495745654],
							[77.5542862969237, 12.911371674587258],
							[77.55420609127559, 12.911371696984387],
							[77.55415256250177, 12.911376189974927],
							[77.55403667087064, 12.911402933298339],
							[77.55370220258611, 12.911465357173688],
							[77.55294413496608, 12.911648133602515],
							[77.55243131907287, 12.911759629572286],
							[77.55233772970361, 12.911786410052978],
							[77.55228866085395, 12.91180421489613],
							[77.55226635682435, 12.911835456550941],
							[77.55226189601791, 12.911844376170219],
							[77.55225297440448, 12.911857734538932],
							[77.55225297440448, 12.911875571852878],
							[77.55225297440448, 12.911888928297472],
							[77.55224851359752, 12.911897847919464],
							[77.55224851359752, 12.911906809664353],
							[77.55225297440448, 12.911915727361873],
							[77.55225297440448, 12.911924602937614],
							[77.55225297440448, 12.911933564684675],
							[77.55225297440448, 12.911942440261768],
							[77.55225743521127, 12.911955838837054],
							[77.55227973924262, 12.912009302938253],
							[77.55232880809616, 12.912089646577904],
							[77.55257852537316, 12.912553360789998],
							[77.55271672243911, 12.912780775934525],
							[77.55288168403293, 12.913141924272928],
							[77.55317600780795, 12.913788491425649],
							[77.55325184088277, 12.91391786290781],
							[77.55334989026191, 12.914038220557037],
							[77.55348817385425, 12.914207649265228],
							[77.55357730151988, 12.91431473859019],
							[77.55359068378355, 12.914363724940555],
							[77.5535817622746, 12.914408364367034],
							[77.55353278142871, 12.914430654754504],
							[77.55325184088277, 12.914564416016646],
							[77.55256068219542, 12.914858725103116],
							[77.55228866085395, 12.914970164220042],
							[77.55185167600906, 12.915086164988983],
							[77.55154842713027, 12.915144052915842],
							[77.550491641752, 12.915349247350521],
							[77.55061645874639, 12.915830752695804],
						],
					],
					[
						[
							[77.55491954345203, 12.89636685092935],
							[77.55516033364219, 12.896674457748652],
							[77.555311909624, 12.896848369255972],
							[77.55554823830406, 12.897133792513287],
							[77.55564637316272, 12.897249737661078],
							[77.55578902715969, 12.897410211584209],
							[77.55566421585516, 12.89749494637894],
							[77.55529406687612, 12.897749097621523],
							[77.55569097988858, 12.898043440586935],
							[77.55569990123166, 12.8985384359064],
							[77.55572220458626, 12.898886221965851],
							[77.55633305240514, 12.900852665980258],
							[77.55670319799367, 12.902128006499938],
							[77.55618593844021, 12.902248351404824],
							[77.55635981628073, 12.90298858671678],
							[77.55632413111186, 12.903046585198718],
							[77.55614133190898, 12.903104497034688],
							[77.55588270114593, 12.903149115831827],
							[77.55584701582696, 12.903166961257096],
							[77.55521832263086, 12.90334528201545],
							[77.55508004114739, 12.903398782737137],
							[77.55499537536147, 12.903403246013111],
							[77.55485709360622, 12.903403238056526],
							[77.55466090990333, 12.903376494655811],
							[77.5544513437006, 12.903345316998879],
							[77.55423731638197, 12.903318577798515],
							[77.55392069165562, 12.903345329297693],
							[77.55370220258611, 12.903340864303619],
							[77.55322061550515, 12.903474633276524],
							[77.55274348716787, 12.90198080430103],
							[77.55244024067225, 12.902074457088199],
							[77.55219944470927, 12.902078914926928],
							[77.55188290176336, 12.902069996837712],
							[77.55158857463438, 12.902114619126381],
							[77.5512897858974, 12.902119046914793],
							[77.55111143943506, 12.90205214539742],
							[77.55108467432137, 12.901664246426193],
							[77.55113820454243, 12.901383343152157],
							[77.55134777686425, 12.90111579634849],
							[77.55153058379057, 12.900977499702478],
							[77.55164210461774, 12.900866021227412],
							[77.55174461621478, 12.90064313297461],
							[77.55174015538782, 12.900464735414594],
							[77.55168225208871, 12.900308671591928],
							[77.55151274044805, 12.900330943448326],
							[77.5513433160217, 12.900424561403625],
							[77.55131209011893, 12.900544980439065],
							[77.55124526490877, 12.90070556407289],
							[77.55120065643507, 12.900781311519076],
							[77.55115604794383, 12.900870500286832],
							[77.55100000555419, 12.901164829837258],
							[77.55079489345447, 12.901468003236102],
							[77.55063876310024, 12.90163299905235],
							[77.55053625050438, 12.90169097220479],
							[77.55045595473749, 12.901481444098042],
							[77.55045149385988, 12.901365427466983],
							[77.55045149385988, 12.90119155191791],
							[77.55054071137864, 12.901044451546097],
							[77.55065660658018, 12.90082591221161],
							[77.55062538048846, 12.900638646767531],
							[77.55051840700553, 12.90050934806623],
							[77.55035790285795, 12.900540495582469],
							[77.55026868519549, 12.900553872217559],
							[77.55014832870835, 12.900469165473726],
							[77.55014832870835, 12.900281936387904],
							[77.54945715112025, 12.897954274936321],
							[77.54832450995532, 12.895283232973307],
							[77.54810601011235, 12.894471735866112],
							[77.54806586137649, 12.893067099277008],
							[77.54843603393246, 12.892050410618904],
							[77.54850286079646, 12.89113633133492],
							[77.5492431143825, 12.889704933452002],
							[77.54992983237574, 12.888875508592896],
							[77.54757095487818, 12.886360612897917],
							[77.54785628299543, 12.886217861168962],
							[77.54814169786563, 12.886075199344788],
							[77.54842265106097, 12.885928101567139],
							[77.54870351609152, 12.885771953372654],
							[77.54898000696109, 12.885611459620758],
							[77.54925203623252, 12.885442050177167],
							[77.54934116722501, 12.885406354480482],
							[77.54942592469858, 12.885357267835047],
							[77.54951068210886, 12.885308267302443],
							[77.5495908910756, 12.88525026110532],
							[77.549666726546, 12.885187823615547],
							[77.54973801359134, 12.885120946448174],
							[77.54980492715761, 12.885049638011086],
							[77.54986291888311, 12.88497385232504],
							[77.54991644968037, 12.88489354505108],
							[77.5499654320871, 12.884808798155062],
							[77.55001004104629, 12.884719620046834],
							[77.55004572820101, 12.884630444553283],
							[77.55007249355968, 12.884536791894044],
							[77.55009479802044, 12.8844431836598],
							[77.55011264158587, 12.884349533705922],
							[77.55012156336754, 12.884251406627047],
							[77.55012156336754, 12.884153368370566],
							[77.5501171024768, 12.884059682045592],
							[77.5501037198035, 12.883961604777804],
							[77.55008141534451, 12.883867923818856],
							[77.55005911088115, 12.88377432909504],
							[77.55002342373064, 12.883680695289645],
							[77.54998327567289, 12.8835959793111],
							[77.54993875417178, 12.883506746082938],
							[77.54988522338503, 12.8834264708403],
							[77.54985845798221, 12.883341794252583],
							[77.54983615347501, 12.883252593619003],
							[77.54981830986611, 12.88316343483288],
							[77.54980938806061, 12.88307427351102],
							[77.54980938806061, 12.8829850234986],
							[77.54981384896347, 12.882891421743528],
							[77.54982723167093, 12.882802211013779],
							[77.54984953617985, 12.882717477439313],
							[77.54987184068442, 12.882628350345193],
							[77.54990752788258, 12.88254356984917],
							[77.54994767596712, 12.88246331085771],
							[77.54999219746473, 12.88238308945224],
							[77.5500412673073, 12.88231170113602],
							[77.55009925891206, 12.88224039633257],
							[77.55016171137638, 12.882173526834636],
							[77.5502285372245, 12.88211108843367],
							[77.55030437226888, 12.882053131256383],
							[77.55037574638202, 12.881950507634798],
							[77.55044257210413, 12.881843530703208],
							[77.55050502437955, 12.881732036577167],
							[77.55056301574739, 12.881620586912831],
							[77.5506119978751, 12.881504656108852],
							[77.55065660658018, 12.88138420816502],
							[77.55069675439975, 12.881263804726613],
							[77.55072798047182, 12.881143404078347],
							[77.55075474566961, 12.881018568288242],
							[77.5507725891313, 12.880893735319436],
							[77.55078597172573, 12.880768860753276],
							[77.55079489345447, 12.880644030742971],
							[77.55079489345447, 12.880514723934397],
							[77.55078597172573, 12.880389899637088],
							[77.5507725891313, 12.880265033770714],
							[77.55075474566961, 12.88014012633972],
							[77.55073690220512, 12.879926143427094],
							[77.5507235196049, 12.879712116485873],
							[77.55071905873781, 12.879498001061865],
							[77.55071905873781, 12.879284013880296],
							[77.55072798047182, 12.87906993818738],
							[77.55074582393772, 12.878855946256646],
							[77.5507725891313, 12.878641865776629],
							[77.55080381518252, 12.878427827335086],
							[77.55084387548213, 12.878218261818883],
							[77.55088848409605, 12.878008656347014],
							[77.55094201440964, 12.87779913452941],
							[77.55100446641023, 12.877594003589618],
							[77.55107129176217, 12.87738891038324],
							[77.55114712624348, 12.877188212181233],
							[77.55122742152139, 12.876987555860449],
							[77.5513165509627, 12.876791372228954],
							[77.551329933493, 12.876760140603983],
							[77.55207462945582, 12.877179284924225],
							[77.55437105070399, 12.8771168561213],
							[77.55467875275079, 12.877116878813698],
							[77.5547188991473, 12.877058932546866],
							[77.55536097716615, 12.876354379632685],
							[77.55547249422852, 12.876131441318776],
							[77.5556463731627, 12.875903998191156],
							[77.55591392579083, 12.875841533534597],
							[77.55633305240514, 12.875877217409663],
							[77.55650246938043, 12.875895072948273],
							[77.5584243766036, 12.876118048930909],
							[77.55883019975788, 12.876113550600962],
							[77.55905760002834, 12.87614925916443],
							[77.55926715771994, 12.876091247017253],
							[77.56002964358137, 12.875538377076765],
							[77.5604310005048, 12.875310974359978],
							[77.56071638374179, 12.875306472390518],
							[77.56081442666782, 12.875324350582263],
							[77.56116671580399, 12.876109138408877],
							[77.56134067346669, 12.876336544969178],
							[77.56161267309807, 12.87661301574504],
							[77.56246435132357, 12.877375482770987],
							[77.56294589914955, 12.877522695892676],
							[77.56319559304856, 12.877540518062084],
							[77.56372628914752, 12.87751821947821],
							[77.5638466312264, 12.877513733684724],
							[77.56514423606133, 12.877107942616322],
							[77.56680747838163, 12.876528234404997],
							[77.56725341347695, 12.876630830883904],
							[77.56736491884025, 12.876831470375757],
							[77.56753431932725, 12.87722837379835],
							[77.5677305682066, 12.877674291588978],
							[77.56783306525794, 12.87800424319154],
							[77.56795349042932, 12.878392172062407],
							[77.56799363212465, 12.87868200850955],
							[77.56788658757215, 12.878838068244637],
							[77.56769934681671, 12.878967370516724],
							[77.56765920501708, 12.878980782419653],
							[77.56774394879962, 12.879190396801283],
							[77.56752539891556, 12.879257204686347],
							[77.5674986376763, 12.879172529053097],
							[77.56740943349973, 12.879043165508927],
							[77.56732031670809, 12.878824727032612],
							[77.56604049039912, 12.879052105104234],
							[77.56601381626524, 12.879466846198454],
							[77.5661118546084, 12.88035863643575],
							[77.5658041835912, 12.880389899466023],
							[77.56555449544034, 12.880447804119331],
							[77.56523344202157, 12.88061730031763],
							[77.56493468923534, 12.880773305113294],
							[77.56454226890737, 12.880978492916585],
							[77.56398044167275, 12.881089927256033],
							[77.56376634488272, 12.881201398116378],
							[77.56365492343573, 12.881277212440772],
							[77.56340523054875, 12.881259387345303],
							[77.56334724574707, 12.881259365150381],
							[77.56319113267202, 12.88122371175904],
							[77.56307971065947, 12.881263859208842],
							[77.56302172569124, 12.881433295740287],
							[77.56298604261912, 12.881772140688048],
							[77.56266052152581, 12.881986217293573],
							[77.56268282351125, 12.882240399008959],
							[77.56274526904717, 12.88253467073577],
							[77.56289237450136, 12.882873578997835],
							[77.56311093332239, 12.883096506532443],
							[77.56338738907448, 12.883105450456704],
							[77.56380648806167, 12.883029651246702],
							[77.56473843551265, 12.882811122979072],
							[77.5651219345603, 12.882721984229825],
							[77.56586216713559, 12.882565941494054],
							[77.56608063277237, 12.882548066380338],
							[77.56614307643581, 12.882820027453718],
							[77.56629026493486, 12.883216923157743],
							[77.56649980925366, 12.883591479613946],
							[77.56672719413604, 12.883943717171626],
							[77.5668208590837, 12.884518976285175],
							[77.5669189841839, 12.88488466580225],
							[77.56691006372377, 12.885281497523222],
							[77.56692344441372, 12.885566880883527],
							[77.56681193861583, 12.885682842972134],
							[77.56657563341707, 12.886253581954989],
							[77.56666037801025, 12.88624911447187],
							[77.56708392511449, 12.886222374216738],
							[77.5671463682249, 12.886637019187974],
							[77.56785090603215, 12.886677162581812],
							[77.5684440227201, 12.886663802868323],
							[77.56880966860277, 12.886614727468077],
							[77.56880074829152, 12.886926875489905],
							[77.5687739873536, 12.887190007678951],
							[77.5687160052997, 12.887716146091677],
							[77.56870262482151, 12.888237876458305],
							[77.56865356305462, 12.888857669693637],
							[77.5695810979592, 12.888888944553328],
							[77.56942499347441, 12.89005270244384],
							[77.57006707564138, 12.890168690186435],
							[77.57007599585286, 12.890222191866288],
							[77.57047731718997, 12.890266758239118],
							[77.57155647886431, 12.89027565854995],
							[77.5719221146967, 12.891314697240144],
							[77.57208258831389, 12.892139624979496],
							[77.5719042745639, 12.893138393369883],
							[77.57179277367021, 12.894226448501977],
							[77.57064680047412, 12.894159533520199],
							[77.56870262482151, 12.893990150586587],
							[77.56842172186869, 12.894065944731262],
							[77.56835927946136, 12.89407932126731],
							[77.56812735021846, 12.893807302677043],
							[77.56809175620891, 12.893825154542121],
							[77.5674807968466, 12.893820671827068],
							[77.56578634249463, 12.893887521015111],
							[77.56444860207591, 12.894039190538074],
							[77.56342307202023, 12.894155080478242],
							[77.56241528685523, 12.894253206169422],
							[77.56193373677482, 12.894208649184042],
							[77.56180884477436, 12.893847419694287],
							[77.56150116207412, 12.893892021995692],
							[77.56141641362255, 12.893923195259173],
							[77.56127822715696, 12.893602168822245],
							[77.56103298949567, 12.89365571157857],
							[77.56098392440772, 12.893236518770987],
							[77.56080550572713, 12.893258803532609],
							[77.56060487183615, 12.893294496996186],
							[77.55948563606948, 12.893490654874123],
							[77.55962837269819, 12.894195221547408],
							[77.55914226278175, 12.894284418539366],
							[77.55904867894941, 12.894864089811353],
							[77.55901745516758, 12.895064722485339],
							[77.55897731029262, 12.895136118007327],
							[77.55895500757816, 12.895167357656732],
							[77.55890148104558, 12.895189602864496],
							[77.55882573920955, 12.89521638227643],
							[77.55866515935291, 12.895243139478309],
							[77.55857157512679, 12.89525200277988],
							[77.55851804841319, 12.895252018591366],
							[77.55846006111163, 12.895252078785745],
							[77.55842883716772, 12.895234209803503],
							[77.55837531038675, 12.895087064502558],
							[77.55834408641951, 12.89500233556249],
							[77.55833070471664, 12.894957751848466],
							[77.55828618649113, 12.894904264046893],
							[77.558228199071, 12.89489088333604],
							[77.5581702116212, 12.894908692402797],
							[77.55806315778983, 12.894953268458778],
							[77.55785359787315, 12.895051340794364],
							[77.55739433144262, 12.89525650007688],
							[77.55705095182219, 12.895408115424543],
							[77.55695736626394, 12.89544825393518],
							[77.55670319799367, 12.895519628237924],
							[77.55651139065968, 12.895573058967313],
							[77.55644456851181, 12.895577519458685],
							[77.5561101073267, 12.89572025598956],
							[77.55529406687612, 12.896076983395623],
							[77.55491954345203, 12.89636685092935],
						],
					],
				],
				type: "MultiPolygon",
			},
			id: "000000000000000000bf",
			properties: {
				AREA_SQ_KM: 5.328113056641282,
				ASS_CONST1: "Bangalore South",
				ASS_CONST_: "176",
				LAT: 12.8914219220501,
				LON: 77.556846034107,
				LOST_AREA_SQ_KM: 0.3164937750638775,
				OBJECTID: 192,
				POP_F: 11465.0,
				POP_M: 13057.0,
				POP_SC: 2425.0,
				POP_ST: 483.0,
				POP_TOTAL: 24522.0,
				RESERVATIO: "General (Women)",
				WARD_NAME: "Vasanthpura",
				WARD_NO: 197.0,
			},
			type: "Feature",
		},
	],
};

export default wardsData;
