import React from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Card, CardHeader } from "@mui/material";
import env from "../../../../../../environments";
interface Props {
	longitude: number | undefined;
	latitude: number | undefined;
	mapRef: any;
}

function MapboxComponent({ longitude, latitude, mapRef }: Props) {
	return (
		<Card variant="outlined">
			<CardHeader title="Satellite View" />

			<Map
				attributionControl={false}
				mapboxAccessToken={env.MAPBOX_API_KEY}
				initialViewState={{
					latitude: latitude,
					longitude: longitude,
					zoom: 16,
				}}
				logoPosition="top-right"
				dragRotate={false}
				minZoom={9}
				ref={mapRef}
				style={{
					width: "100%",
					height: "200px",
				}}
				mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
			>
				<Marker latitude={latitude} longitude={longitude} />
			</Map>
		</Card>
	);
}
export default MapboxComponent;
