import { configureStore } from "@reduxjs/toolkit";
import redistributeReducer from "./redistribute/redistributeSlice";
import wardsReducer from "./wards/wardsSlice";
import realAndOptimalRoutesReducer from "./realAndOptimalRoutes/realAndOptimalRoutes";
import ambulanceAndHospitalData from "./ambulanceAndHospitalData/ambulanceAndHospitalDataSlice";

export const store = configureStore({
	reducer: {
		redistributable: redistributeReducer,
		wards: wardsReducer,
		realAndOptimisedRoutes: realAndOptimalRoutesReducer,
		ambulanceAndHospitalData: ambulanceAndHospitalData,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
