import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	ambulanceDataValue,
	hospitalDataValue,
	setAmbulanceData,
	setHospitalData,
} from "../../../../../store/ambulanceAndHospitalData/ambulanceAndHospitalDataSlice";

const Input = () => {
	const dispatch = useDispatch();

	const hospitalData = useSelector(hospitalDataValue);
	const ambulanceData = useSelector(ambulanceDataValue);

	const changeHospital = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(setHospitalData(event.target.checked));
	};
	const changeAmbulance = (event: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setAmbulanceData(event.target.checked));
	};
	return (
		<FormGroup row>
			<FormControlLabel
				control={
					<Checkbox
						checked={ambulanceData}
						onChange={changeAmbulance}
						name="Ambulance Assignment Data"
					/>
				}
				label="Ambulance Assignment Data"
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={hospitalData}
						onChange={changeHospital}
						name="Hospital Assignment Data"
					/>
				}
				label="Hospital Assignment Data"
			/>
		</FormGroup>
	);
};

export default Input;
