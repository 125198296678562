import {
	Card,
	Grid,
} from "@mui/material";
import ColumnComponent from "../../../shared/components/ColumnComponent/ColumnComponent";

export default function CitizenGrievencesComponent()
{
    return (
        <Grid item lg={6} md={6} sm={12}>
			<Card
				role="button"
				sx={{
					marginRight: "30px",
					position: "relative",
				}}
				// onMouseEnter={() => {
				// 	setShowIcon3(true);
				// }}
				// onMouseLeave={() => {
				// 	setShowIcon3(false);
				// }}
			>
				<div className="iframe-container">
					<div className="iframe-row">
                        <ColumnComponent 
					        message="Citizen Grievience Location" 
					        title="Live Trips" 
						    width="100%" 
						    height="400px"
						    src="https://analytics.iudx.org.in:13000/superset/explore/p/ydWGvZMG3Xo/?standalone=1&height=400"
                            TableComponent={<></>}
						    />
                    </div>
                </div>
                <div className="card-container__title">
                    <p>Citizen Grieviences</p>
                </div>

                {/* {showIcon3 && (
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                        }}
                    >
                        <IconButton
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/varanasi_swm");
                            }}
                        >
                            <LaunchIcon />
                        </IconButton>
                    </div>
                )} */}
            </Card>
        </Grid>

    );
}